import { toStatus, getPermission } from '../../../common/helpper';

const permission = getPermission('Master Finance', 'Calendar');
export const initSearchState = {
  criteria: {
    Status: '',
  },
  results: [],
};
export const searchReducer = (state = initSearchState, action) => {
  switch (action.type) {
    case 'UPDATE_CRITERIA':
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.name]: action.value,
        },
      };
    case 'SET_SEARCH_RESULT':
      return {
        ...state,
        results: [...action.payload],
      };
    case 'UPDATE_SEARCH_RESULT':
      return {
        ...state,
        results: state.results.map((m, i) => {
          if (i === action.index)
            return {
              ...action.payload,
            };
          return m;
        }),
      };
    default:
      return state;
  }
};

export const searchResultDTOption = {
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    { data: 'holidayId', className: 'text-center' },
    {
      data: 'calendarName',
      className: 'text-center',
      render: (d) => `<a href="javascript:void(0);" class="goto-cal">${d}</a>`,
    },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export const initHolidayState = {
  holidayId: null,
  calendarName: '',
  items: [],
  year: new Date().getFullYear(),
  forceUpdate: false,
};

export const holidayReducer = (state = initHolidayState, action) => {
  switch (action.type) {
    case 'SET':
      return {
        ...action.payload,
      };
    case 'UPDATE_ITEM_BY_ID':
      return {
        ...state,
        items: state.items.map((m) => {
          if (m.holidayDetailId === action.id)
            return {
              ...action.payload,
            };
          return m;
        }),
      };
    case 'UPDATE_CALENDAR_NAME':
      return {
        ...state,
        calendarName: action.payload,
      };
    case 'UPDATE_FORCE_UPDATE':
      return {
        ...state,
        forceUpdate: action.payload,
      };
    case 'SET_ITEMS_AFTER_FETCH':
      return {
        ...state,
        items: action.payload,
        forceUpdate: false,
      };
    case 'UPDATE_ACTIVE_IF_SAME_ID':
      return {
        ...state,
        forceUpdate: action.id === state.holidayId ? true : state.forceUpdate,
        isActive: action.id === state.holidayId ? action.payload : state.isActive,
      };
    default:
      return state;
  }
};
