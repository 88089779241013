import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Link from '../../../common/Link';
import Button from '../../../common/Button';
import Select2 from '../../../common/Select2';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import { getPermission } from '../../../common/helpper';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_COUNTRY, MAIN_MASTER_BANK } from '../../../reducers/master/action';

const permission = getPermission('Master Finance', 'Bank Branch');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const instanceList = [
  { Instance: 'CEP', Text: 'CEP | SAP ECC CHEMICAL' },
  { Instance: 'DynamicAX', Text: 'DynamicAX | DynamicAX' },
  { Instance: 'FocusOne', Text: 'FocusOne | FocusOne' },
  { Instance: 'NetSuite', Text: 'NetSuite | NetSuite' },
  { Instance: 'NonSAP', Text: 'NonSAP | NonSAP' },
  { Instance: 'SAPHANA', Text: 'SAPHANA | SAPHANA' },
  { Instance: 'PD1', Text: 'PD1 | SAP 4.7' },
  { Instance: 'PEP', Text: 'PEP | SAP ECC PACKAGING' },
  { Instance: 'REP', Text: 'REP | SAP ECC CBMR' },
  { Instance: 'SAP Outside', Text: 'SAP Outside | SAP Outside' },
  { Instance: 'TEP', Text: 'TEP | SAP ECC CERAMICS' },
  { Instance: 'WINSpeed', Text: 'WINSpeed | WINSpeed' },
  { Instance: 'PRD', Text: 'PRD | HANA-NPI' },
  { Instance: 'TSP', Text: 'TSP | HANA-TMMA' },
];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  tabRef,
  formDetailRef,
  formAddressRef,
  formConfigRef,
  formEmailRef,

  resultEffective,
  onClickClearInstance,
}) => {
  const textHeader =
    state.mode === 'create' ? 'Create Bank Branch' : `Bank Branch : ${state.BranchCode}`;
  const modeEdit = state.mode === 'edit';
  // let noEdit = !permission ? true : state.mode === 'create' ? false : state.setOverDueDate
  const noEdit = !permission;

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" />
            <Link txt="Bank Branch" href="/master/bank-branch" />
            <span className="icon icon-angle-double-right" /> Bank Branch Detail{' '}
          </small>
        </p>
      </div>

      <Card
        textHeader={textHeader}
        bgHeader="bg-primary"
        cardActions={['toggler']}
        footer={
          !permission ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={noEdit}
            />
          )
        }
      >
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            {state.mode === 'create' ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <DatePicker
                    value={state.EffectiveDateStr}
                    onChange={onChangeInputData}
                    name="EffectiveDateStr"
                    required
                    option={{
                      // startDate: "+1d",
                      // daysOfWeekDisabled: "0,6",
                      todayHighlight: true,
                    }}
                  />
                </FormGroup2>
              </Col2>
            ) : (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <Select2
                    value={state.TempEffectiveDateStr}
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="TempEffectiveDateStr"
                    required={false}
                  >
                    {permission ? (
                      <option value="New" key="New">
                        New Effective Date
                      </option>
                    ) : null}
                    {(resultEffective || []).map((m) => (
                      <option value={m} key={m}>
                        {m}
                      </option>
                    ))}
                  </Select2>
                </FormGroup2>
                {state.TempEffectiveDateStr === 'New' ? (
                  <FormGroup2 text="New Effective Date" required>
                    <DatePicker
                      value={state.EffectiveDateStr}
                      onChange={onChangeInputData}
                      name="EffectiveDateStr"
                      required
                      option={{
                        // startDate: "+1d",
                        // daysOfWeekDisabled: "0,6",
                        todayHighlight: true,
                      }}
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            )}

            {modeEdit ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Status" required>
                  <select
                    className="form-control"
                    value={state.IsActive}
                    onChange={onChangeInputData}
                    name="IsActive"
                    disabled={noEdit}
                  >
                    <option value>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </FormGroup2>
              </Col2>
            ) : null}
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Branch Code" required>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required
                  disabled={modeEdit}
                  value={state.BranchCode || ''}
                  name="BranchCode"
                />
              </FormGroup2>

              <FormGroup2 text="Branch Code TH" required={false}>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.BranchCodeTh || ''}
                  name="BranchCodeTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank Code" required>
                {modeEdit ? (
                  <MasterDropdown
                    masterType={MAIN_MASTER_BANK}
                    key="bankcode1"
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    disabled
                    value={state.BankCode}
                    isMainMaster
                    filter={(m) => m.isActive || m.bankCode === state.BankCode}
                    name="BankCode"
                  />
                ) : (
                  <MasterDropdown
                    masterType={MAIN_MASTER_BANK}
                    key="bankcode2"
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    disabled={false}
                    value={state.BankCodeCountry}
                    customeValue={(m) => `${m.bankCode}|${m.country}`}
                    isMainMaster
                    filter={(m) => m.isActive || m.bankCode === state.BankCode}
                    name="BankCodeCountry"
                  />
                )}
              </FormGroup2>

              <FormGroup2 text="Country" required>
                <MasterDropdown
                  masterType={MASTER_COUNTRY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled
                  value={state.Country}
                  name="Country"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Branch Name" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.BranchName || ''}
                  name="BranchName"
                />
              </FormGroup2>

              <FormGroup2 text="Branch Name TH" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.BranchNameTh || ''}
                  name="BranchNameTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="House Bank" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.HouseBank || ''}
                  name="HouseBank"
                />
              </FormGroup2>

              <FormGroup2 text="Instance Code" required={false}>
                <div className="row gutter-xs">
                  <div className="col-xs-9">
                    <Select2
                      value={state.InstanceCode || ''}
                      notMultiple
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      disabled={noEdit}
                      isChoose
                      name="InstanceCode"
                      required={false}
                    >
                      {instanceList.map((m) => (
                        <option value={m.Instance} key={m.Instance}>
                          {m.Text}
                        </option>
                      ))}
                    </Select2>
                  </div>
                  <div className="col-xs-3">
                    <button
                      className="btn btn-sm btn-clear"
                      onClick={(e) => onClickClearInstance(e)}
                    >
                      <i className="icon icon-eraser icon-fw icon-lg" />
                    </button>
                  </div>
                </div>
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>
    </div>
  );
};
