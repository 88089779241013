import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Button from '../../common/Button';
import Toggle from '../../common/Toggle';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toFixed, getPermission } from '../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12', 'col-md-10', 'col-sm-10'];
const required = true;
const permission = getPermission('Cash Management', 'Cash Position');

export default ({
  criteria,
  results,
  onChange,
  onClickProcess,
  isCheckAll,
  transferMethodAll,
  onChangeTransferMethodHeader,
  onChangeCheckAll,
  onChangeInputTable,
  onClickSave,

  isMillion,
  onChangeToggleMillion,
  onChangeSelect2,
}) => {
  results = results || [];

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Cash Management <span className="icon icon-angle-double-right" />
            <Link txt="Cash Position" href="/cash-management/cash-position" />
            <span className="icon icon-angle-double-right" /> Auto Transfer
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickProcess}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Value Date" required>
                <DatePicker
                  value={criteria.ValueDateStr}
                  onChange={onChange}
                  name="ValueDateStr"
                  option={{
                    startDate: 'd',
                    endDate: '+7d',
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                  required={required}
                />
              </FormGroup2>

              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={criteria.FinanceGroup}
                  saveLocalState={false}
                  noValidateOption
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChange({
                      target: e,
                    });
                  }}
                  required={required}
                  name="FinanceGroup"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  value={criteria.BusinessUnitId}
                  onChange={onChangeSelect2}
                  name="BusinessUnitId"
                />
              </FormGroup2>

              <FormGroup2 text="Currency" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  value={criteria.Currency}
                  noValidateOption
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChange({
                      target: e,
                    });
                  }}
                  required={required}
                  name="Currency"
                />
              </FormGroup2>
            </Col2>
          </Col2>
          <Col2 col={colX[0]}>
            <Col2 col={colX[2]}>
              <FormGroup2 text="Available Company">
                {/* <MasterDropdown
                                    masterType={MASTER_COMPANY}
                                    value={criteria.Companys}
                                    isMultileSelect={true}
                                    onChange={(e) => {
                                        onChange({
                                            target: e
                                        })
                                    }}
                                    saveLocalState={true}
                                    filter={(f, props) => props.financeGroupId}
                                    financeGroupId={criteria.FinanceGroup}
                                    name="Companys" /> */}
                {criteria.FinanceGroup ? (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    value={criteria.Companys}
                    onChange={onChangeSelect2}
                    name="Companys"
                    noValidateOption
                    saveLocalState
                    financeGroupId={criteria.FinanceGroup}
                    businessUnitId={criteria.BusinessUnitId}
                  />
                ) : (
                  <MasterDropdownUI
                    value={criteria.Companys}
                    onChange={onChangeSelect2}
                    name="Companys"
                    options={null}
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>
          {permission ? (
            <Col2 col={colX[2]}>
              <div>
                <Button txt="Process" icon="search" className="btn-info" type="submit" />
              </div>
            </Col2>
          ) : null}
        </form>
      </Card>

      <Card
        textHeader="Result"
        number="2"
        bgHeader="bg-primary"
        cardActions={['toggler']}
        footer={
          <Button
            txt="Confirm"
            icon="save"
            className="btn-success"
            onClick={onClickSave}
            disabled={!results.filter((f) => f.isChecked).length}
          />
        }
      >
        <div className="row">
          <div className="col-sm-6" style={{ marginBottom: '5px' }}>
            <span>
              <b>Million</b>{' '}
              <Toggle name="isMillion" checked={isMillion} onChange={onChangeToggleMillion} />
            </span>
          </div>
        </div>

        <div className="table-responsive" style={{ maxHeight: '500px' }}>
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th rowSpan="2" style={{ textAlign: 'center', width: '5%' }}>
                  Choose
                  <br />
                  <input
                    id="box-all"
                    type="checkbox"
                    className="label-checkbox"
                    checked={isCheckAll}
                    onChange={onChangeCheckAll}
                  />
                  <label htmlFor="box-all" className="label_checkbox" />
                </th>
                <th rowSpan="2" style={{ textAlign: 'center' }}>
                  Transfer Method <br />
                  <select
                    tabIndex="-1"
                    className="form-control"
                    value={transferMethodAll}
                    name="transferMethodAll"
                    onChange={onChangeTransferMethodHeader}
                  >
                    <option value="">--Choose--</option>
                    <option value="Bahtnet">Bahtnet</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Auto Deduct">Auto Deduct</option>
                    <option value="Internet Banking">Internet Banking</option>
                  </select>
                </th>
                <th rowSpan="2" style={{ textAlign: 'center' }}>
                  Transfer Amount
                </th>
                <th rowSpan="2" style={{ textAlign: 'center' }}>
                  Company
                  <br /> Code
                </th>
                <th rowSpan="2" style={{ textAlign: 'center' }}>
                  Company Name
                </th>
                <th colSpan="4" className="th-primary">
                  Applicant
                </th>
                <th colSpan="4" className="th-success">
                  Beneficiary
                </th>
              </tr>
              <tr>
                <th className="th-primary">Bank Account</th>
                <th className="th-primary">Min Balance</th>
                <th className="th-primary">Charge</th>
                <th className="th-primary">Charge Amount</th>
                <th className="th-success">Bank Account</th>
                <th className="th-success">Min Balance</th>
                <th className="th-success">Charge</th>
                <th className="th-success">Charge Amount</th>
              </tr>
            </thead>
            <tbody>
              {results.map((m, i) => (
                <tr key={i} className={m.isChecked ? 'checked-row' : ''}>
                  <td style={{ textAlign: 'center' }}>
                    <input
                      type="checkbox"
                      className="label-checkbox"
                      name="isChecked"
                      id={`b-${i}`}
                      onChange={(e) => onChangeInputTable(e, i)}
                      checked={m.isChecked || false}
                    />
                    <label htmlFor={`b-${i}`} className="label_checkbox" />
                  </td>
                  <td>
                    <div className="td-input-method">
                      <MasterDropdownUI
                        className="form-control"
                        onChange={(e) =>
                          onChangeInputTable(
                            {
                              target: e,
                            },
                            i
                          )
                        }
                        isChoose
                        notMultipleSelect2
                        required
                        value={m.transferMethod || ''}
                        name="transferMethod"
                        options={['Bahtnet', 'Cheque', 'Auto Deduct', 'Internet Banking']}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="td-input-number">
                      <InputMask
                        className="form-control"
                        onChange={(e) => onChangeInputTable(e, i)}
                        format="currency"
                        option={{
                          prefix: '',
                          digits: m.applicantCurrencyDigit,
                          digitsOptional: false,
                          placeholder: !m.applicantCurrencyDigit
                            ? '0'
                            : `0.${`000${m.applicantCurrencyDigit}`.slice(-3)}`,
                        }}
                        value={isMillion ? toMillion(m.amount) : m.amount || 0}
                        name="amount"
                      />
                    </div>
                  </td>
                  <td style={{ textAlign: 'center' }}>{m.companyCode}</td>
                  <td>{m.companyName}</td>
                  <td>
                    {setAccount(
                      m.applicantBankAbbreviate,
                      m.applicantBranchName,
                      m.applicantAccountTypeCode,
                      m.applicantCurrency,
                      m.applicantBankAccountNo
                    )}
                  </td>
                  <td className="td-input-number" style={{ textAlign: 'right' }}>
                    {setAmount(m.applicantMinimumBalance, m.applicantCurrencyDigit, isMillion)}
                  </td>
                  <td>
                    <div className="td-input-method">
                      <MasterDropdownUI
                        className="form-control"
                        onChange={(e) =>
                          onChangeInputTable(
                            {
                              target: e,
                            },
                            i
                          )
                        }
                        isChoose
                        notMultipleSelect2
                        required={false}
                        value={m.applicantFeeBy || ''}
                        name="applicantFeeBy"
                        options={['Applicant', 'Beneficiary']}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="td-input-number">
                      {m.transferMethod === 'Bahtnet' ? (
                        <InputMask
                          className="form-control"
                          onChange={(e) => onChangeInputTable(e, i)}
                          format="currency"
                          option={{
                            prefix: '',
                            digits: m.applicantCurrencyDigit,
                            digitsOptional: false,
                            placeholder: !m.applicantCurrencyDigit
                              ? '0'
                              : `0.${`000${m.applicantCurrencyDigit}`.slice(-3)}`,
                          }}
                          value={
                            isMillion ? toMillion(m.applicantFeeAmount) : m.applicantFeeAmount || 0
                          }
                          name="applicantFeeAmount"
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    {setAccount(
                      m.beneficiaryBankAbbreviate,
                      m.beneficiaryBranchName,
                      m.beneficiaryAccountTypeCode,
                      m.beneficiaryCurrency,
                      m.beneficiaryBankAccountNo
                    )}
                  </td>
                  <td className="td-input-number" style={{ textAlign: 'right' }}>
                    {setAmount(m.beneficiaryMinimumBalance, m.beneficiaryCurrencyDigit, isMillion)}
                  </td>
                  <td>
                    <div className="td-input-method">
                      <MasterDropdownUI
                        onChange={(e) =>
                          onChangeInputTable(
                            {
                              target: e,
                            },
                            i
                          )
                        }
                        isChoose
                        notMultipleSelect2
                        required={false}
                        value={m.beneficiaryFeeBy || ''}
                        name="beneficiaryFeeBy"
                        options={['Applicant', 'Beneficiary']}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="td-input-number">
                      {m.transferMethod === 'Bahtnet' ? (
                        <InputMask
                          className="form-control"
                          onChange={(e) => onChangeInputTable(e, i)}
                          format="currency"
                          option={{
                            prefix: '',
                            digits: m.beneficiaryCurrencyDigit,
                            digitsOptional: false,
                            placeholder: !m.beneficiaryCurrencyDigit
                              ? '0'
                              : `0.${`000${m.beneficiaryCurrencyDigit}`.slice(-3)}`,
                          }}
                          value={
                            isMillion
                              ? toMillion(m.beneficiaryFeeAmount)
                              : m.beneficiaryFeeAmount || 0
                          }
                          name="beneficiaryFeeAmount"
                        />
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  );
};

const toMillion = (value) => {
  if (!value) return 0;
  if (isNaN(value)) return 0;
  return Number(value) / 1000000;
};
const setAmount = (number, float, isMillion) => {
  if (isMillion) number = (Number(number) || 0) / 1000000;
  if (number < 0)
    return <span style={{ color: '#e64a19' }}>({toFixed(Math.abs(number, float))})</span>;
  return toFixed(number, float);
};

const setAccount = (bankShortName, bankBranch, bankType, bankCurrency, bankAccount) => {
  if ((bankAccount || '').length <= 13)
    return (
      <span>
        {bankShortName} | {bankBranch} | {bankType} | {bankCurrency}
        <br />
        {bankAccount}
      </span>
    );
  return (
    <span>
      {bankShortName} | {bankBranch} | {bankType} | {bankCurrency}
      <br />
      {bankAccount.substring(0, 13)}
      <br />
      {bankAccount.substring(13, bankAccount.length)}
    </span>
  );
};
