import qs from 'querystring';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import ExposurePosition from './ExposurePosition';
import ImportExcel from '../../common/ImportExcel';
import { addAlert } from '../../reducers/layout/action';
import {
  toFixed,
  toLower,
  compareDate,
  getPermissionStr,
  checkAfterValueDate,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByRowIndex,
  setCheckedCheckboxDataTableByInputElement,
} from '../../common/helpper';
import {
  automate,
  exportExcel,
  undoExposure,
  editCriteria,
  splitExposure,
  confirmMethod,
  clearCriteria,
  updateExposure,
  sendEmailToBank,
  manualAutoIncome,
  exportSpotSummary,
  updateSearchResult,
  uploadViewQuoteRate,
  uploadSaveQuoteRate,
  fetchEmailQuoteRate,
  saveExposureQuoteRate,
  uplodaViewInwardExcel,
  uplodaSaveInwardExcel,
  searchExposurePosition,
  changeStatusToWithdraw,
  changeQuoteRateMethodToSpot,
} from '../../reducers/exposurePosition/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-exposure-position';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const permission = getPermissionStr('FX Management', 'Exposure Position');
const viewInward = toLower(permission) === 'view (inward)';
const viewOutward = toLower(permission) === 'view (outward)';
const jwt = JSON.parse(localStorage.getItem('jwt')) || { username: '' };
const username = jwt.username || '';

const initModalEdit = {
  CompanyCode: '',
  BankCode: '',
  BankAbbreviate: '',
  BankName: '',
  BankAccountNo: '',
  ValueDateStr: '',
  Action: '',
  CanSplit: '',
};

const initExchangeRate = {
  ExchangeRate: '',
  ExposureId: '',
  Selected: false,
};

class ExposurePositionContainer extends Component {
  state = {
    results: [],
    tab: 'all',
    modalEdit: {
      ...initModalEdit,
    },
    modalSplit: {
      originalOutStandingAmount: 0,
      outStandingAmount: 0,
      splitNo: '',
      exposureId: '',
      split: [],
    },
    modalExchangeRate: {
      ...initExchangeRate,
    },
    modalExportSpotSummary: {
      FinanceGroupId: '',
    },
    selectedRowIdx: null,
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `td.col-label-checkbox-by-expos`, this.onClickCheckboxSelecteExposureNo);
    $('body').on('click', `button.edit-expp`, this.onClickColumnOpenModalEdit);
    $('body').on('click', `button.btn-split`, this.onClickColumnOpenModalSplit);
    $('body').on('click', `button.undo-split`, this.onClickColumnOpenModalUndo);

    if (toLower(permission).includes('view')) {
      this.props.editCriteria(
        'CashTypes',
        viewInward ? ['Inward'] : viewOutward ? ['Outward'] : ['']
      );
    }

    let query = this.props.routeProp.location.search.replace('?', '');
    query = qs.parse(query);
    if (query.s !== undefined) {
      clearCheckBoxAllTable(DATATABLE_ID);
      if (this.props.criteria.ValueDateStr || this.props.criteria.InformDateStr) {
        this.props.searchExposurePosition({ ...this.props.criteria });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchResult !== this.props.searchResult) {
      this.setState({
        results: this.filterSearch(),
      });
    }
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `td.col-label-checkbox-by-expos`, this.onClickCheckboxSelecteExposureNo);
    $('body').off('click', `button.edit-expp`, this.onClickColumnOpenModalEdit);
    $('body').off('click', `button.btn-split`, this.onClickColumnOpenModalSplit);
    $('body').off('click', `button.undo-split`, this.onClickColumnOpenModalUndo);
  }

  // - - - - - - Search - - - - - - -
  filterSearch = () => {
    const { tab } = this.state;
    const data = this.props.searchResult.filter((f) => {
      if (tab === 'all') return true;
      if (tab === 'fx') return f.sourceType === 'FX Management';
      if (tab === 'loan') return f.sourceType === 'Loan & Investment';
      if (tab === 'tt') return f.sourceType === 'T/T Remittance';
      return false;
    });
    return data;
  };

  onChangeSearchHeadler = (e) => {
    this.props.editCriteria(e.target.name, e.target.value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchExposurePosition({
      ...this.props.criteria,
    });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
    if (toLower(permission).includes('view')) {
      this.props.editCriteria(
        'CashTypes',
        viewInward ? ['Inward'] : viewOutward ? ['Outward'] : ['']
      );
    }
  };

  onChangeTab = (tabName) => {
    if (tabName === this.state.tab) return;
    this.setState(
      {
        tab: tabName,
      },
      () => {
        const data = this.filterSearch();
        this.setState({
          results: data,
        });
      }
    );
  };
  // - - - - - - End Search - - - - - - -

  // - - - - - - Check Box - - - - - - -
  onClickCheckboxSelecteExposureNo = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const result = setCheckedCheckboxDataTableByInputElement(e, $dt, 'col-label-checkbox-by-expos');
    setCheckedCheckboxDataTableByInputElement(e, $dt, 'col-label-checkbox', result.checked);

    const { exposureNo } = $dt.row(result.row).data();
    this.state.results.forEach((m, i) => {
      if (m.exposureNo === exposureNo) {
        m.IsSelected = result.checked;
        m.IsSelectedAll = result.checked;

        if (i !== result.row) {
          setCheckedCheckboxDataTableByRowIndex(
            i,
            $dt,
            'col-label-checkbox-by-expos',
            result.checked
          );
          setCheckedCheckboxDataTableByRowIndex(i, $dt, 'col-label-checkbox', result.checked);
        }
      }
    });
    this.forceUpdate();
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    const selected = this.state.results[result.row];
    selected.IsSelected = result.checked;
    this.forceUpdate();
  };
  // - - - - - - End Check Box - - - - - - -

  // - - - - - - Modal Edit - - - - - - -
  onClickColumnOpenModalEdit = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.state.results[row];
    // if (this.checkExposureNoAlreadySplitColumn(selectedData.exposureNo)) {
    this.setState(
      {
        modalEdit: {
          ...initModalEdit,
          CompanyCode: selectedData.companyCode,
          BankCode: selectedData.bankCode,
          BankAbbreviate: selectedData.bankAbbreviate,
          BankName: selectedData.bankName,
          BankAccountNo: selectedData.bankAccountNo,
          ValueDateStr: selectedData.valueDateStr,
          Action: 'edit',
          ExposureIds: [selectedData.exposureId],
          InformDateStr: selectedData.informDateStr,
          CanSplit: this.checkExposureNoAlreadySplitBoolean(selectedData.exposureNo),
        },
      },
      this.modalEditRef.open
    );
    // }
  };

  checkExposureNoAlreadySplitBoolean = (exposureNo) => {
    const lenghtItem = this.state.results.filter((f) => f.exposureNo === exposureNo);
    if (lenghtItem.length !== 1) {
      return true;
    }
    return false;
  };

  checkExposureNoAlreadySplitColumn = (exposureNo) => {
    const lenghtItem = this.state.results.filter((f) => f.exposureNo === exposureNo);
    if (lenghtItem.length !== 1) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Exposure No. ${exposureNo} can not edit because transaction already split.`,
      });
      return false;
    }
    return true;
  };

  // Modal Edit : Bank & Value Date
  onClickOpenModalEdit = (action) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Please select item for ${action}!`,
      });
      return;
    }

    let checkSourceType = true;
    selectedData.forEach((m) => {
      if (toLower(m.sourceType) === 't/t remittance') {
        checkSourceType = false;
      }
    });
    if (!checkSourceType) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item source type must not be T/T Remittance!',
      });
      return;
    }

    if (this.checkExposureNoAlreadySplit(selectedData)) {
      if (this.checkStatusWaitForSelectMethod(selectedData)) {
        if (action === 'change bank account') {
          const distinctCompany = selectedData
            .map((m) => m.companyCode)
            .filter((f, idx, self) => self.indexOf(f) === idx);
          if (distinctCompany.length > 1) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Please select item that in same company.',
            });
            return;
          }
        }

        let bankAbbreviate = '';
        let bankCode = '';
        let companyCode = '';
        let bankAccountNo = '';
        let valueDate = '';
        let informDate = '';

        if (selectedData.length === 1) {
          selectedData.forEach((m) => {
            bankAbbreviate = m.bankAbbreviate;
            bankCode = m.bankCode;
            bankAccountNo = m.bankAccountNo;
            companyCode = m.companyCode;
            valueDate = m.valueDateStr;
            informDate = m.informDateStr;
          });
        } else {
          selectedData.forEach((m) => {
            companyCode = m.companyCode;
            if (informDate && !compareDate(informDate, m.informDateStr)) {
              informDate = m.informDateStr;
            } else if (!informDate) {
              informDate = m.informDateStr;
            }
          });
        }

        this.setState(
          {
            modalEdit: {
              ...initModalEdit,
              CompanyCode: companyCode,
              BankAbbreviate: bankAbbreviate,
              BankCode: bankCode,
              BankAccountNo: bankAccountNo,
              ValueDateStr: valueDate,
              Action: action,
              ExposureIds: selectedData.map((m) => m.exposureId),
              InformDateStr: informDate,
            },
          },
          this.modalEditRef.open
        );
      }
    }
  };

  checkExposureNoAlreadySplit = (selectedData) => {
    const distinctExposureNo = selectedData
      .map((m) => m.exposureNo)
      .filter((f, i, s) => s.indexOf(f) === i);
    for (let i = 0; i < distinctExposureNo.length; i++) {
      const allItemInExposureNo = this.state.results.filter(
        (f) => f.exposureNo === distinctExposureNo[i]
      );
      if (allItemInExposureNo.length !== 1) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Exposure No. ${distinctExposureNo[i]} can not edit because transaction already split.`,
        });
        return false;
      }
    }
    return true;
  };

  onChangeModalEditHeadler = (e) => {
    this.setState({
      modalEdit: {
        ...this.state.modalEdit,
        [e.target.name]: e.target.value,
      },
    });
  };

  onClickSubmitModalEdit = (e) => {
    e.preventDefault();

    if (compareDate(this.state.modalEdit.InformDateStr, this.state.modalEdit.ValueDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Please select value date more than or equal at ${this.state.modalEdit.InformDateStr}`,
      });
      return;
    }

    this.props
      .updateExposure(
        {
          ...this.state.modalEdit,
        },
        { ...this.props.criteria }
      )
      .then(() => {
        this.modalEditRef.close();
      });
  };
  // - - - - - - End Modal Edit - - - - - - -

  // - - - - - - Change Status - - - - - - -
  onClickOpenModalChangeStatus = (modal) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        body: `Please select item for ${modal}!`,
        type: 'error',
      });
      return;
    }

    if (modal === 'Spot Rate') {
      if (this.checkFillData(selected)) {
        if (this.checkStatusWaitForSelectMethod(selected)) {
          this.modalSpotRateRef.open();
        }
      }
    } else if (modal === 'Confirm Method') {
      if (this.checkIsSelectAllItemInExposureNo(selected)) {
        if (this.checkFillData(selected)) {
          if (this.checkSelectMethod(selected)) {
            this.modalConfirmMethodRef.open();
          }
        }
      }
    } else if (modal === 'Confirm Quote Rate') {
      if (this.checkIsSelectAllItemInExposureNo(selected)) this.modalConfirmQuoteRateRef.open();
    } else if (modal === 'Withdraw') {
      this.modalWithdrawRef.open();
    } else if (modal === 'Send Email to Bank') {
      if (this.checkFillData(selected)) {
        if (this.checkSelectMethod(selected)) {
          if (this.checkValueDate(selected)) {
            this.modalMailRef.open();
          }
        }
      }
    } else if (modal === 'Export Quote Rate') {
      if (this.checkFillData(selected)) {
        if (this.checkSelectMethod(selected)) {
          if (this.checkValueDate(selected)) {
            this.modalExportQuoteRateRef.open();
          }
        }
      }
    }
  };

  checkFillData = (selected) => {
    let check = true;
    selected.forEach((m) => {
      if (!m.bankAbbreviate || !m.bankAccountNo || !m.valueDateStr) {
        check = false;
      }
    });
    if (!check) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Incomplete filling data "Bank, Bank Account and Value Date". Please fully fill details into the blank.',
      });
    }
    return check;
  };

  checkSelectMethod = (selected) => {
    let check = true;
    selected.forEach((m) => {
      if (!m.quoteRateMethod) {
        check = false;
      }
    });
    if (!check) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'You selected item not have quote rate method. Please select method.',
      });
    }
    return check;
  };

  checkValueDate = (selected) => {
    let check = true;
    selected.forEach((m) => {
      if (!checkAfterValueDate(m.valueDateStr)) {
        check = false;
      }
    });
    if (!check) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'You selected date overdue on value date. Please select other transaction.',
      });
    }
    return check;
  };

  checkStatusWaitForSelectMethod = (selected) => {
    let check = true;
    selected.forEach((m) => {
      if ((m.status || '').toLowerCase() !== 'wait for select method') {
        check = false;
      }
    });
    if (!check) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select status: Wait for select method.',
      });
    }
    return check;
  };

  checkIsSelectAllItemInExposureNo = (selectedData) => {
    const distinctExposureNo = selectedData
      .map((m) => m.exposureNo)
      .filter((f, i, s) => s.indexOf(f) === i);
    for (let i = 0; i < distinctExposureNo.length; i++) {
      const allItemInExposureNo = this.state.results.filter(
        (f) => f.exposureNo === distinctExposureNo[i]
      );
      const selectedItemInExposureNo = allItemInExposureNo.filter((f) => f.IsSelected);
      if (allItemInExposureNo.length !== selectedItemInExposureNo.length) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Please select all item in Exposure no. ${distinctExposureNo[i]} `,
        });
        return false;
      }
    }
    return true;
  };

  onClickModalSubmitChangeStatusHandler = (e, Status) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);
    const group = [];

    if (Status === 'SpotRate') {
      selected.forEach((m) => {
        if ((m.status || '').toLowerCase() === 'wait for select method') group.push(m);
      });
      this.onClickConfirmChangeQuoteRateMethodToSpot(group);
    } else if (Status === 'ConfirmMethod') {
      selected.forEach((m) => {
        if ((m.status || '').toLowerCase() === 'wait for select method') group.push(m);
      });
      this.onClickConfirmStatusConfirmMethod(group);
    } else if (Status === 'ExportExcel') {
      selected.forEach((m) => {
        if ((m.status || '').toLowerCase() === 'wait for quote rate') group.push(m);
      });
      if (group.length) this.onClickConfirmExportExcel(group);
      else {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.props.searchExposurePosition({ ...this.props.criteria });
      }
    } else if (Status === 'SendEmailToBank') {
      selected.forEach((m) => {
        if (
          (m.status || '').toLowerCase() === 'wait for quote rate' &&
          checkAfterValueDate(m.valueDateStr)
        )
          group.push(m);
      });
      if (group.length) this.onClickConfirmSendEmailToBank(group);
      else {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.props.searchExposurePosition({ ...this.props.criteria });
      }
    } else if (Status === 'WithDraw') {
      selected.forEach((m) => {
        if (
          m.status &&
          m.status.toLowerCase() !== 'cancelled' &&
          m.status.toLowerCase() !== 'wait for select method'
        )
          group.push(m);
      });

      // Condition Withdraw : case transaction already split will be alert "Do you confirm Withdraw?".
      const distinctExposureNo = group
        .map((m) => m.exposureNo)
        .filter((f, i, s) => s.indexOf(f) === i);
      for (let i = 0; i < distinctExposureNo.length; i++) {
        const allItemInExposureNo = this.state.results.filter(
          (f) => f.exposureNo === distinctExposureNo[i]
        );
        if (allItemInExposureNo.length !== 1) {
          this.props.addAlert({
            type: 'warning',
            title: 'Warning',
            body: `Transaction you selected has been split. If you want to withdraw, will return all transaction. Do you want to withdraw?`,
            buttons: [
              <button
                className="btn btn-warning btn-sm"
                data-dismiss="modal"
                onClick={(e) => this.onClickConfirmWithDraw(group)}
              >
                Confirm
              </button>,
              <button
                className="btn btn-default btn-sm"
                data-dismiss="modal"
                onClick={(e) => this.modalWithdrawRef.close()}
              >
                Cancel
              </button>,
            ],
          });
          return;
        }
      }

      this.onClickConfirmWithDraw(group);
    } else if (Status === 'ConfirmQuoteRate') {
      selected.forEach((m) => {
        if ((m.status || '').toLowerCase() === 'wait for confirm quote rate') group.push(m);
      });
    }

    this.modalSpotRateRef.close();
    this.modalConfirmMethodRef.close();
    this.modalConfirmQuoteRateRef.close();
    this.modalMailRef.close();
    this.modalExportQuoteRateRef.close();
  };

  onClickFetchEmailQuoteRate = () => {
    this.props.fetchEmailQuoteRate();
  };

  onClickUploadExcelQuoteRate = () => {
    this.importExcelQuoteRateRef.open();
  };

  // - - - - - - Modal Split - - - - - - -
  onClickColumnOpenModalSplit = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));

    const rowData = $td.row(row).data();

    if (!rowData.bankAbbreviate || !rowData.bankAccountNo || !rowData.valueDateStr) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Incomplete filling data "Bank, Bank Account and Value Date". Please fully fill details into the blank.',
      });
      return;
    }

    this.setState(
      {
        modalSplit: {
          originalOutStandingAmount: rowData.amountQuoteRate || 0,
          outStandingAmount: rowData.amountQuoteRate || 0,
          splitNo: rowData.splitNo,
          exposureId: rowData.exposureId,
          split: [
            {
              splitNo: 1,
              outStandingAmount: '0',
            },
          ],
        },
      },
      this.modalSplitRef.open
    );
  };

  onClickAddSplit = (e) => {
    this.setState((state) => {
      const { split } = state.modalSplit;
      return {
        modalSplit: {
          ...state.modalSplit,
          split: [
            ...state.modalSplit.split,
            {
              splitNo: split[split.length - 1].splitNo + 1,
              outStandingAmount: '0',
            },
          ],
        },
      };
    });
  };

  onChangeSplitAmount = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const modalSplit = {
        ...state.modalSplit,
        split: state.modalSplit.split.map((m, i) => {
          if (i === index)
            return {
              ...m,
              [name]: value,
            };
          return m;
        }),
      };
      const totalAmountSplit = modalSplit.split.reduce(
        (prev, cur, idx, self) => prev + Number(cur.outStandingAmount || 0),
        0
      );

      if (totalAmountSplit > modalSplit.originalOutStandingAmount) {
        this.props.addAlert({
          type: 'warning',
          title: 'Warning',
          body: 'Amount for split must less than original amount',
        });
        return;
      }

      modalSplit.outStandingAmount = modalSplit.originalOutStandingAmount - totalAmountSplit;
      return {
        modalSplit,
      };
    });
  };

  onSubmitModalSplit = (e) => {
    e.preventDefault();
    const { modalSplit } = this.state;
    const amount = modalSplit.split
      .filter((f) => f.outStandingAmount)
      .map((m) => m.outStandingAmount);
    const total = amount.reduce((prev, cur) => prev + Number(cur), 0);
    if (total >= modalSplit.originalOutStandingAmount) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Outstanding amount not enough for split',
      });
      return;
    }
    const submitdata = {
      ExposureId: modalSplit.exposureId,
      Amounts: amount,
    };
    this.props.splitExposure(submitdata).then((response) => {
      if (!response.error) {
        this.modalSplitRef.close();
        this.props.searchExposurePosition({ ...this.props.criteria });
      }
    });
  };
  // - - - - - - End Modal Split - - - - - - -

  // - - - - - - Forward Rate - - - - - - -
  onClickForwardRate = (e) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select item for forward rate!',
        type: 'error',
      });
      return;
    }

    const group1 = [];
    const group2 = [];
    let company = '';
    let bank = '';
    let bankCurrency = '';
    let currency = '';
    let cashType = '';
    let exposureType = '';

    let checkValue = true;
    let checkStatus = true;
    let checkAccountCurrencyToCurrency = '';

    selected.forEach((m) => {
      if (!m.bankAbbreviate || !m.bankAccountNo || !m.valueDateStr) {
        checkValue = false;
        return;
      }

      if ((m.status || '').toLowerCase() !== 'wait for select method') {
        checkStatus = false;
        return;
      }

      if (m.bankCurrency === m.currency) {
        checkAccountCurrencyToCurrency = `${m.exposureNo} can not quote rate method because bank account currency same currency.`;
        return;
      }

      if (company !== '') {
        if (
          (m.status || '').toLowerCase() === 'wait for select method' &&
          company === m.companyCode &&
          bank === m.bankAbbreviate &&
          bankCurrency === m.bankCurrency &&
          currency === m.currency &&
          cashType === m.cashType &&
          exposureType === m.exposureType
        )
          group1.push(m);
        else group2.push(m);
      } else if ((m.status || '').toLowerCase() === 'wait for select method') {
        company = m.companyCode;
        bank = m.bankAbbreviate;
        bankCurrency = m.bankCurrency;
        currency = m.currency;
        cashType = m.cashType;
        exposureType = m.exposureType;
        group1.push(m);
      } else {
        group2.push(m);
      }
    });

    if (!checkValue) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Incomplete filling data "Bank, Bank Account and Value Date". Please fully fill details into the blank.',
      });
      return;
    }

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select status: Wait for select method.',
      });
      return;
    }

    if (checkAccountCurrencyToCurrency !== '') {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: checkAccountCurrencyToCurrency,
      });
      return;
    }

    if (group2.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item that in same Company, Bank, Book Bank Currency, Currency, Cash Type and Exposure Type.',
      });
      return;
    }

    const exposureIds = group1
      .map((m) => m.exposureId)
      .filter((f, i, self) => self.indexOf(f) === i);
    this.props.routeProp.history.push(`/fx-management/forward-rate/${exposureIds.join(',')}`);
  };

  // - - - - - - Exchange Rate - - - - - - -
  onClickOpenModalExchangeRateSelected = (e) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select item for exchange rate!',
        type: 'error',
      });
      return;
    }

    let checkStatus = true;
    selected.forEach((m) => {
      if (!~['wait for quote rate', 'already send to bank'].indexOf(toLower(m.status))) {
        checkStatus = false;
      }
    });

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select item must be status is: Wait for quote rate or Already send to bank!',
        type: 'error',
      });
      return;
    }

    const exposures = selected.map((m) => m.exposureId);
    const exposureStr = exposures.toString();

    this.setState(
      {
        modalExchangeRate: {
          ...initExchangeRate,
          ExposureId: exposureStr,
          Selected: true,
        },
      },
      this.modalExchangeRateRef.open
    );
  };

  onClickOpenModalExchangeRate = (e, cell) => {
    const { row } = cell.index();
    const rowData = cell.row(row).data();
    this.setState(
      {
        modalExchangeRate: {
          ...initExchangeRate,
          ExposureId: rowData.exposureId,
          ExchangeRate: rowData.exchangeRate || '0',
        },
      },
      this.modalExchangeRateRef.open
    );
  };

  onChangeModalExchangeRate = (e) => {
    this.setState({
      modalExchangeRate: {
        ...this.state.modalExchangeRate,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitExchangeRate = (e) => {
    e.preventDefault();

    const submitData = { ...this.state.modalExchangeRate };

    if (!submitData.ExchangeRate || Number(submitData.ExchangeRate) <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Exchange rate must not be zero or empty',
      });
      return;
    }

    this.props.saveExposureQuoteRate(submitData).then((response) => {
      if (!response.error) {
        this.modalExchangeRateRef.close();
        this.props.searchExposurePosition({ ...this.props.criteria });
      }
    });
  };
  // - - - - - - End Modal Split - - - - - - -

  // - - - - - - Confirm Change Status - - - - - - -
  onClickConfirmChangeQuoteRateMethodToSpot = (selectedData) => {
    const submitData = {
      TransactionNo: selectedData.map((m) => m.exposureId),
    };
    this.props.changeQuoteRateMethodToSpot(submitData).then((response) => {
      if (!response.error) {
        this.modalSpotRateRef.close();
        this.props.searchExposurePosition({ ...this.props.criteria });
      }
    });
  };

  onClickConfirmStatusConfirmMethod = (selectedData) => {
    const submitData = {
      TransactionNo: selectedData.map((m) => m.exposureId),
    };
    this.props.confirmMethod(submitData).then((response) => {
      if (!response.error) {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.props.searchExposurePosition({ ...this.props.criteria });
        this.modalConfirmMethodRef.close();
      }
    });
  };

  onClickConfirmWithDraw = (selectedData) => {
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Warning',
        type: 'warning',
        body: 'Cannot withdraw the transaction, because current status is equal "Wait for select method".',
      });
      return;
    }
    const submitData = {
      TransactionNo: selectedData.map((m) => m.exposureId),
    };
    this.props.changeStatusToWithdraw(submitData).then((response) => {
      if (!response.error) {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.props.searchExposurePosition({ ...this.props.criteria });
        this.modalWithdrawRef.close();
      }
    });
  };

  onClickConfirmExportExcel = (selectedData) => {
    const bankGroup = [];
    const promiseArry = [];
    selectedData.forEach((m) => {
      const findGroup = bankGroup.find((f) => f.key === m.bankCode);
      if (!findGroup) {
        bankGroup.push({
          key: m.bankCode,
          datas: [{ ...m }],
        });
      } else {
        findGroup.datas.push({ ...m });
      }
    });

    bankGroup.forEach((m) => {
      const submitData = {
        TransactionNo: m.datas.map((m) => m.exposureId),
      };
      promiseArry.push(this.props.exportExcel(submitData));
    });

    Promise.all(promiseArry).then((responseArry) => {
      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchExposurePosition({ ...this.props.criteria });
      this.modalExportQuoteRateRef.close();
    });
  };

  onClickConfirmSendEmailToBank = (selectedData) => {
    const submitData = {
      TransactionNo: selectedData.map((m) => m.exposureId),
    };
    this.props.sendEmailToBank(submitData).then((response) => {
      if (!response.error) {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.props.searchExposurePosition({ ...this.props.criteria });
        this.modalMailRef.close();
      }
    });
  };
  // - - - - - - End Confirm Change Status - - - - - - -

  onClickUploadImportExcelQuoteRate = (file) => {
    const form = new FormData();
    form.append('file', file);
    this.props.uploadViewQuoteRate(form);
  };

  onClickSaveImportQuoteRate = () => {
    const data = this.props.importExcelQuoteRate.rows;
    if (!data.length) {
      return;
    }
    this.props.uploadSaveQuoteRate(data);
  };

  onClickOpenModalExportSpotSummary = () => {
    console.log(username);
    this.modalExportSpotSummaryRef.open();
  };

  onChangeModalExportSpotSummary = (e) => {
    this.setState({
      modalExportSpotSummary: {
        ...this.state.modalExportSpotSummary,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalExportSpotSummary = (e) => {
    e.preventDefault();
    const { FinanceGroupId } = this.state.modalExportSpotSummary;
    if (!FinanceGroupId) {
      this.props.addAlert({
        title: 'Warning',
        body: 'Please select finance group!',
        type: 'warning',
      });
      return;
    }
    this.props.exportSpotSummary(FinanceGroupId).then((response) => {
      if (response.error) return;

      this.setState(
        {
          modalExportSpotSummary: {
            financeGroupId: '',
          },
        },
        this.modalExportSpotSummaryRef.close
      );
    });
  };

  onClickColumnOpenModalUndo = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    const items = this.state.results.filter(
      (f) => f.exposureNo === rowData.exposureNo && f.splitNo === 1
    );
    if (!items.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Exposure No. ${rowData.exposureNo} don't have Line ID. 1 in result.`,
      });
      return;
    }

    if ((items[0].status || '').toLowerCase() !== 'wait for select method') {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Exposure No. ${rowData.exposureNo} at Line ID. 1 is not status: "Wait for select method".`,
      });
      return;
    }

    this.setState(
      {
        selectedExposureId: rowData.exposureId,
      },
      this.modalUndoRef.open
    );
  };

  onClickConfirmModalUndo = () => {
    const { selectedExposureId } = this.state;
    if (!selectedExposureId) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for undo.',
      });
      return;
    }

    this.props.undoExposure(selectedExposureId).then((response) => {
      if (response.error) return;

      this.setState(
        {
          selectedExposureId: null,
        },
        this.modalUndoRef.close
      );
      this.props.searchExposurePosition({ ...this.props.criteria });
    });
  };

  onClickOpenModalUploadCreditDedit = () => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select item for upload dedit/credit advice!',
        type: 'error',
      });
    }
  };

  onClickUploadExcelInward = () => {
    this.importExcelInwardRef.open();
  };

  onClickUploadImportExcelInward = (file) => {
    const form = new FormData();
    form.append('file', file);
    this.props.uplodaViewInwardExcel(form);
  };

  onClickSubmitImportInwardExcel = () => {
    const { rows } = this.props.importExcelInward;
    if (!rows || !rows.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'There no data for import',
      });
      return;
    }
    this.props.uplodaSaveInwardExcel(rows);
  };

  onClickManualAutoIncome = (e) => {
    this.modalManualRef.open();
  };

  onClickSubmitManual = (e) => {
    e.preventDefault();
    this.props
      .manualAutoIncome({
        Action: '',
        Transaction: '',
      })
      .then((response) => {
        if (response.error) return;

        this.modalManualRef.close();
        // this.props.searchExposurePosition({ ...this.props.criteria })
      });
  };

  onClickAutomate = () => {
    const selected = this.state.results
      .filter((f) => f.IsSelected)
      .map((m) => m.exposureNo)
      .filter((f, i, s) => s.indexOf(f) === i)
      .map((t) => ({
        transactionNo: t,
      }));
    this.props.automate(selected).then(() => {
      this.onClickSearchHandler({
        preventDefault: () => {},
      });
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      onClickUploadExcelInward: this.onClickUploadExcelInward,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      onChangeTab: this.onChangeTab,
      allSearchResult: this.props.searchResult,

      onClickOpenModalEdit: this.onClickOpenModalEdit,
      onChangeModalEdit: this.onChangeModalEditHeadler,
      onClickSubmitModalEdit: this.onClickSubmitModalEdit,

      onClickOpenModalChangeStatus: this.onClickOpenModalChangeStatus,
      onClickModalSubmitChangeStatus: this.onClickModalSubmitChangeStatusHandler,
      conditionChangeStatus,
      onClickModalSubmitWithdraw: this.onClickModalSubmitWithdraw,

      onClickFetchEmailQuoteRate: this.onClickFetchEmailQuoteRate,
      onClickExportExcelQuoteRate: this.onClickExportExcelQuoteRate,
      onClickUploadExcelQuoteRate: this.onClickUploadExcelQuoteRate,

      modalSplitRef: (e) => (this.modalSplitRef = e),
      onChangeSplitAmount: this.onChangeSplitAmount,
      onClickAddSplit: this.onClickAddSplit,
      onSubmitModalSplit: this.onSubmitModalSplit,

      onClickForwardRate: this.onClickForwardRate,

      onClickOpenModalExchangeRate: this.onClickOpenModalExchangeRate,
      onChangeModalExchangeRate: this.onChangeModalExchangeRate,
      onSubmitExchangeRate: this.onSubmitExchangeRate,

      onClickOpenModalExportSpotSummary: this.onClickOpenModalExportSpotSummary,
      onChangeModalExportSpotSummary: this.onChangeModalExportSpotSummary,
      onSubmitModalExportSpotSummary: this.onSubmitModalExportSpotSummary,

      onClickConfirmModalUndo: this.onClickConfirmModalUndo,

      onClickOpenModalUploadCreditDedit: this.onClickOpenModalUploadCreditDedit,
      onClickOpenModalExchangeRateSelected: this.onClickOpenModalExchangeRateSelected,

      onClickManualAutoIncome: this.onClickManualAutoIncome,
      onClickSubmitManual: this.onClickSubmitManual,
    };

    return (
      <div>
        <ImportExcel
          ref={(e) => (this.importExcelQuoteRateRef = e)}
          tableName="dt-import-excel-quote-rate-exposure"
          optionDt={opt}
          value={this.props.importExcelQuoteRate}
          onClickSaveImport={this.onClickSaveImportQuoteRate}
          onUpload={this.onClickUploadImportExcelQuoteRate}
        >
          <thead>
            <tr>
              <th>Exposure No</th>
              <th>Line Id</th>
              <th>CompanyCode</th>
              <th>Company Name</th>
              <th>Name Of Bank</th>
              <th>Payment Type</th>
              <th>Exposure Detail</th>
              <th>Exposure Type</th>
              <th>Exposure Amount</th>
              <th>Exposuer Due Date</th>
              <th>Quote Date</th>
              <th>Quote Rate</th>
              <th>FX Method</th>
              <th>Forward Number</th>
              <th>Exposure Currency</th>
              <th>
                Equivalent To
                <br />
                Currency
              </th>
              <th>Bank Refernce</th>
              <th>Error</th>
            </tr>
          </thead>
        </ImportExcel>
        <ImportExcel
          ref={(e) => (this.importExcelInwardRef = e)}
          tableName="dt-import-excel-inward"
          optionDt={optInward}
          value={this.props.importExcelInward}
          onClickSaveImport={this.onClickSubmitImportInwardExcel}
          onUpload={this.onClickUploadImportExcelInward}
        >
          <thead>
            <tr>
              <th>Bank Inform Date</th>
              <th>Company Code</th>
              <th>Customer/Vendor name</th>
              <th>External Doc. No.</th>
              <th>Payment Type</th>
              <th>Bank Ref. No.</th>
              <th>
                Transaction <br />
                Currency
              </th>
              <th>Invoice Amount</th>
              <th>Transaction Amount</th>
              <th>Bank Remark</th>
              <th>Bank Swift Code</th>
              <th>Department</th>
              <th>Payment Terms</th>
              <th>Error</th>
            </tr>
          </thead>
        </ImportExcel>
        <ExposurePosition
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          modalEditRef={(e) => (this.modalEditRef = e)}
          modalSpotRateRef={(e) => (this.modalSpotRateRef = e)}
          modalConfirmMethodRef={(e) => (this.modalConfirmMethodRef = e)}
          modalConfirmQuoteRateRef={(e) => (this.modalConfirmQuoteRateRef = e)}
          modalWithdrawRef={(e) => (this.modalWithdrawRef = e)}
          modalMailRef={(e) => (this.modalMailRef = e)}
          modalExportQuoteRateRef={(e) => (this.modalExportQuoteRateRef = e)}
          modalExchangeRateRef={(e) => (this.modalExchangeRateRef = e)}
          modalExportSpotSummaryRef={(e) => (this.modalExportSpotSummaryRef = e)}
          modalUndoRef={(e) => (this.modalUndoRef = e)}
          modalManualRef={(e) => (this.modalManualRef = e)}
          onClickAutoMate={this.onClickAutomate}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.exposurePosition,
  }),
  {
    searchExposurePosition,
    updateSearchResult,
    addAlert,
    updateExposure,
    splitExposure,
    changeQuoteRateMethodToSpot,
    confirmMethod,
    exportExcel,
    sendEmailToBank,
    uploadViewQuoteRate,
    uploadSaveQuoteRate,
    changeStatusToWithdraw,
    saveExposureQuoteRate,
    uplodaViewInwardExcel,
    uplodaSaveInwardExcel,
    undoExposure,
    exportSpotSummary,
    editCriteria,
    clearCriteria,
    fetchEmailQuoteRate,
    manualAutoIncome,
    automate,
  }
)(ExposurePositionContainer);

const conditionChangeStatus = {
  spot: (m = []) =>
    m.filter((f) => f.IsSelected && (f.status || '').toLowerCase() === 'wait for select method'),
  withDraw: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        (f.status || '').toLowerCase() !== 'cancelled' &&
        (f.status || '').toLowerCase() !== 'wait for select method'
    ),
  confimMethod: (m = []) =>
    m.filter((f) => f.IsSelected && (f.status || '').toLowerCase() === 'wait for select method'),
  mail: (m = []) =>
    m.filter((f) => f.IsSelected && (f.status || '').toLowerCase() === 'wait for quote rate'),
  confirmQuoteRate: (m = []) =>
    m.filter(
      (f) => f.IsSelected && (f.status || '').toLowerCase() === 'wait for confirm quote rate'
    ),
  exportQuoteRate: (m = []) =>
    m.filter((f) => f.IsSelected && (f.status || '').toLowerCase() === 'wait for quote rate'),
};

const opt = {
  columns: [
    { data: 'exposureNo' },
    { data: 'lineId' },
    { data: 'companyCode' },
    { data: 'companyName' },
    { data: 'bankName' },
    { data: 'paymentType' },
    { data: 'exposureDetail' },
    { data: 'exposureType' },
    {
      data: 'exposureAmount',
      className: 'text-right',
      render: (d) => toFixed(d, 2),
    },
    { data: 'exposureDueDateStr' },
    { data: 'quoteDateStr' },
    {
      data: 'quoteRateRate',
      className: 'text-right',
    },
    { data: 'fxMethod' },
    { data: 'forwardNumber' },
    { data: 'exposureCurrency' },
    { data: 'equivalentToCurrency' },
    { data: 'bankReference' },
    { data: 'errorMessage' },
  ],
};

const optInward = {
  columns: [
    { data: 'bankInformDateStr' },
    { data: 'companyCode' },
    { data: 'customerName' },
    { data: 'externalDocNo' },
    { data: 'paymentType' },
    { data: 'bankReference' },
    { data: 'transactionCurrency' },
    {
      data: 'invoiceAmount',
    },
    {
      data: 'transactionAmount',
      render: (d) => toFixed(d, 2),
    },
    { data: 'bankRemark' },
    { data: 'swiftCode' },
    { data: 'department' },
    { data: 'paymentTerm' },
    { data: 'errorMessage' },
  ],
};
