import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_DERIVATIES = '@@request/SEARCH_DERIVATIES';
export const RECEIVE_SEARCH_DERIVATIES = '@@receive/SEARCH_DERIVATIES';
export const FAIL_SEARCH_DERIVATIES = '@@fail/SEARCH_DERIVATIES';

export const UPDATE_SEARCH_RESULT_DERIVATIES = 'UPDATE_SEARCH_RESULT_DERIVATIES';

export const REQUEST_UPDATE_DERIVATIES = '@@request/UPDATE_DERIVATIES';
export const RECEIVE_UPDATE_DERIVATIES =
  '@@receive-The transaction was successfully saved/UPDATE_DERIVATIES';
export const FAIL_UPDATE_DERIVATIES = '@@fail/UPDATE_DERIVATIES';

export const REQUEST_CHANGE_POSTING_DATE = '@@request/CHANGE_POSTING_DATE';
export const RECEIVE_CHANGE_POSTING_DATE =
  '@@receive-The transaction was successfully saved/CHANGE_POSTING_DATE';
export const FAIL_CHANGE_POSTING_DATE = '@@fail/CHANGE_POSTING_DATE';

export const changePostingDate = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/accounting/ChangePostingDate', data),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_CHANGE_POSTING_DATE, RECEIVE_CHANGE_POSTING_DATE, FAIL_CHANGE_POSTING_DATE],
    },
  });

  if (!actionResponse.error) return await dispatch(searchDerivatives(criteria));

  return actionResponse;
};

export const updateDerivatives = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/accounting/savemoneymarket'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [REQUEST_UPDATE_DERIVATIES, RECEIVE_UPDATE_DERIVATIES, FAIL_UPDATE_DERIVATIES],
    },
  });

  if (!actionResponse.error) return await dispatch(searchDerivatives(criteria));

  return actionResponse;
};

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_DERIVATIES,
  payload: {
    name,
    value,
    index,
  },
});

export const searchDerivatives = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/SearchAccountDerivatives', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_DERIVATIES, RECEIVE_SEARCH_DERIVATIES, FAIL_SEARCH_DERIVATIES],
  },
});
