import React from 'react';

import CashFlow from './CashFlow';
import Schedule from './ScheduleTab';
import Stucture from './StructureTab';
import Tab from '../../../common/Tab';
import PosSchedule from './PosSchedule';
import IrsSchedule from './IrsSchedule';
import CcsSchedule from './CcsSchedule';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Link from '../../../common/Link';
import ScheduleModal from './ScheduleModal';
import Button from '../../../common/Button';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_FINANCE, MASTER_COMPANY } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  onChangeInputTable,
  onChangeInputHeader,
  onClickGetCashflow,

  formDetailRef,
  formStructorRef,
  formCashFlowRef,
  formResultCashFlowRef,
  tabRef,

  modalScheduleRef,
  onClickOpenModalSchedule,
  onChangeModalDataSchedule,
  onSubmitModalSchedule,
  onViewModalSchedule,
  onDeleteSchedule,

  inputMaskOpt,

  modalPosRef,
  modalIrsRef,
  modalCcsRef,
  onChangeSelect2,
}) => {
  const header =
    state.mode === 'edit' ? `Derivative No.: ${state.TransactionNo}` : 'Create Derivative';
  const noEdit = state.mode === 'edit';
  const disabled = false;
  const required = true;

  const tabContents = [];
  // ------------------ Tab Structure ----------------------
  tabContents.push({
    header: <span>Structure</span>,
    content: (
      <Stucture formRef={formStructorRef} state={state} onChangeInputData={onChangeInputData} />
    ),
    tabName: 'structure',
  });

  // ------------------ Tab Schedule ----------------------
  tabContents.push({
    header: <span>Schedule</span>,
    content: (
      <Schedule
        state={state}
        onChangeInputData={onChangeInputData}
        onClickSwapSchedule={onClickOpenModalSchedule}
        onViewModalSchedule={onViewModalSchedule}
        onDeleteSchedule={onDeleteSchedule}
      />
    ),
    tabName: 'schedule',
  });

  // ------------------ Tab Cash Flow ----------------------
  tabContents.push({
    header: <span>Cash Flow</span>,
    content: (
      <CashFlow
        formCashFlowRef={formCashFlowRef}
        formResultCashFlowRef={formResultCashFlowRef}
        state={state}
        onChangeInputData={onChangeInputData}
        onChangeInputTable={onChangeInputTable}
        onClickGetCashflow={onClickGetCashflow}
        onChangeInputHeader={onChangeInputHeader}
        onChangeSelect2={onChangeSelect2}
      />
    ),
    tabName: 'cashflow',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Funding & Investment <span className="icon icon-angle-double-right" />
            <Link txt="Create Derivative" href="/loan-investment/create-derivative" />
            <span className="icon icon-angle-double-right" /> Derivative Detail
          </small>
        </p>
      </div>

      <Card textHeader={header} bgHeader="bg-primary" cardActions={['toggler']}>
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                {noEdit ? (
                  <input
                    type="text"
                    className="form-control"
                    value={`${state.FinanceGroup || ''}`}
                    disabled
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_FINANCE}
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={required}
                    disabled={noEdit}
                    value={state.FinanceGroupId}
                    name="FinanceGroupId"
                  />
                )}
              </FormGroup2>

              <FormGroup2 text="Company" required>
                {noEdit || disabled || !state.FinanceGroupId ? (
                  <input
                    type="text"
                    className="form-control"
                    value={`${state.CompanyCode || ''} ${state.CompanyName || ''}`}
                    disabled
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    notMultiple={false}
                    required={required}
                    financeGroupId={state.FinanceGroupId}
                    value={state.CompanyCode}
                    name="CompanyCode"
                  />
                )}
              </FormGroup2>
            </Col2>

            <Col2 col={colX[0]}>
              <FormGroup2 text="Remark" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required={false}
                  value={state.Remark}
                  name="Remark"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !state.permission ? (
            ''
          ) : (
            <Button txt="SAVE" icon="save" className="btn-success" onClick={onSubmit} />
          )
        }
      />

      <ScheduleModal
        state={state.modalSchedule}
        modalRef={modalScheduleRef}
        onSubmitModal={onSubmitModalSchedule}
        onChangeInputData={onChangeModalDataSchedule}
        template={[]}
      />

      <PosSchedule
        state={state.modalSchedule}
        inputMaskRefOpt={state.inputMaskRefOpt}
        inputMaskSwapOpt={state.inputMaskSwapOpt}
        modalRef={modalPosRef}
        onSubmitModal={onSubmitModalSchedule}
        onChangeInputData={onChangeModalDataSchedule}
      />

      <IrsSchedule
        state={state.modalSchedule}
        inputMaskOpt={state.inputMaskRefOpt}
        modalRef={modalIrsRef}
        onSubmitModal={onSubmitModalSchedule}
        onChangeInputData={onChangeModalDataSchedule}
      />

      <CcsSchedule
        state={state.modalSchedule}
        modalRef={modalCcsRef}
        inputMaskRefOpt={state.inputMaskRefOpt}
        inputMaskSwapOpt={state.inputMaskSwapOpt}
        onSubmitModal={onSubmitModalSchedule}
        onChangeInputData={onChangeModalDataSchedule}
      />
    </div>
  );
};
