import { connect } from 'react-redux';
import React, { Component } from 'react';

import Derivative from './Derivative';
import { addAlert } from '../../../reducers/layout/action';
import { getCurrency } from '../../../reducers/common/action';
import { toUpperKey, compareDate, getPermission } from '../../../common/helpper';
import {
  getDerivative,
  saveDerivative,
  processCashflow,
} from '../../../reducers/derivative/action';

const permission = getPermission('Funding&Investment', 'Create Derivative');

const initInformation = {
  FinanceGroupId: '',
  CompanyCode: '',
  ProductType: '',
  Remark: '',
};

const initStructure = {
  DerivativeType: '',
  Partner: '',
  ContractDateStr: '',
  TermStartStr: '',
  TermEndStr: '',
  LoanRef: '',
  BusinessArea: '',
  Swap: '',
};

const initCashFlow = {
  BankCodeCashFlow: [''],
  resultCashFlowSwap: [],
  resultCashFlowReference: [],

  AccountNoAll: '',
  PaymentMethodAll: '',
};

const initModalSchedule = {
  // Schedule
  CalculateMethod: '',
  PremiumDiscount: '',

  RefInterestType: '',
  RefInterestRateName: '',
  RefInterestRate: '',
  RefMargin: '',
  RefInterest: '',

  SwapInterestType: '',
  SwapInterestRateName: '',
  SwapInterestRate: '',
  SwapMargin: '',
  SwapInterest: '',

  PeriodEndType: '',
  IsManualInclude: '',
  IsManualMonthEnd: '',
  ManualPeriodEnd: '',
  ManualDay: '',
  ManualWorkingDay: '',

  DueDateType: '',
  IsDueDateMonthEnd: '',
  ManualDueDate: '',
  DueDateDay: '',
  DueDateWorkingDay: '',

  // Reference Structure
  RefAmount: '',
  RefCurrency: '',
  RefCalculateMethod: '',
  // Swap Structure
  SwapAmount: '',
  SwapCurrency: '',
  SwapCalculateMethod: '',

  RepaymentDateStr: '',
  ValidFromStr: '',
  ValidToStr: '',
};

const { moment } = window;

class DerivativeContainer extends Component {
  state = {
    ...initInformation,
    ...initStructure,
    ...initCashFlow,
    mode: 'create',
    resultSchedule: [],
    permission,

    modalSchedule: {
      ...initModalSchedule,
    },
    inputMaskOpt: {
      prefix: '',
      digits: 3,
      digitsOptional: false,
      placeholder: '0.000',
    },
    inputMaskLoanRefOpt: {
      prefix: '',
      digits: 3,
      digitsOptional: false,
      placeholder: '0.000',
    },
    inputMaskRefOpt: {
      prefix: '',
      digits: 3,
      digitsOptional: false,
      placeholder: '0.000',
    },
    inputMaskSwapOpt: {
      prefix: '',
      digits: 3,
      digitsOptional: false,
      placeholder: '0.000',
    },
  };

  selectedScheduleIdx = null;

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;
    if (transactionNo && transactionNo !== 'create') {
      this.props.getDerivative(transactionNo).then(async (response) => {
        if (response.error && !response.payload) return;

        const s = response.payload;
        const resultCashFlow = s.cashFlow || [];
        const resultCashFlowSwap = [];
        const resultCashFlowReference = [];
        for (let i = 0; i < resultCashFlow.length; i++) {
          const row = resultCashFlow[i];
          if (row.cashFlowType === 'Swap') {
            resultCashFlowSwap.push(row);
          } else {
            resultCashFlowReference.push(row);
          }
        }

        const resultSchedule = s.schedule.map((m) => ({
          ...toUpperKey(m),
        }));

        this.setState({
          ...toUpperKey(response.payload),
          BankCodeCashFlow: (response.payload.bankCodeCashFlow || '').split(','),
          resultCashFlowSwap,
          resultCashFlowReference,
          resultSchedule,
          mode: 'edit',
        });

        const loanRefDigit = await this.getDigitCurrency(response.payload.loanRefCurrency);
        const swapDigit = await this.getDigitCurrency(response.payload.swapCurrency);

        this.setState({
          inputMaskLoanRefOpt: {
            prefix: '',
            digits: loanRefDigit,
            digitsOptional: false,
            placeholder: !loanRefDigit ? '0' : `0.${`000${loanRefDigit}`.slice(-3)}`,
          },
          inputMaskOpt: {
            prefix: '',
            digits: swapDigit,
            digitsOptional: false,
            placeholder: !swapDigit ? '0' : `0.${`000${swapDigit}`.slice(-3)}`,
          },
        });
      });
    }
  }

  async getDigitCurrency(cur) {
    const response = await this.props.getCurrency(cur);
    if (response.error) return 3;
    if (!response.payload || response.payload.length === 0) return 3;

    return response.payload[0].digit;
  }

  onChangeInputData = (e) => {
    const { name, value, datas = [] } = e.target;
    this.setState((state) => {
      let stateToUpdate = {
        [name]: value,
      };
      if (name === 'CompanyCode') {
        stateToUpdate.BusinessArea = '';
      } else if (name === 'LoanRef') {
        const find = datas.find((f) => f.transactionNo === value);
        stateToUpdate.LoanRefCurrency = (find || {}).currency;
        const obj = this.props.currencys.datas.find(
          (f) => f.currency === stateToUpdate.LoanRefCurrency
        );
        let { digit } = obj || {};
        if (digit === null || digit === undefined) digit = 3;
        stateToUpdate.inputMaskLoanRefOpt = {
          prefix: '',
          digits: digit,
          digitsOptional: false,
          placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
        };
      } else if (name === 'Partner') {
        stateToUpdate.BankCodeCashFlow = [value];
      } else if (name === 'ContractDateStr') {
        if (value === '') {
          stateToUpdate.ContractDateStr = '';
          stateToUpdate.ContractDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.TermStartStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Contract Date must be less than or equal Term Start.',
            });
            return {
              [name]: state.ContractDateStr,
            };
          }
        }
      } else if (name === 'TermStartStr') {
        if (value === '') {
          stateToUpdate.TermStartStr = '';
          stateToUpdate.TermStart = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.TermStartStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term Start must be more than or equal Contract Date.',
            });
            return {
              [name]: state.TermStartStr,
            };
          }
          if (compareDate(mergeState.TermStartStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term Start must be less than or equal Term End.',
            });
            return {
              [name]: state.TermStartStr,
            };
          }
        }
      } else if (name === 'TermEndStr') {
        if (value === '') {
          stateToUpdate.TermEndStr = '';
          stateToUpdate.TermEnd = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.TermStartStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term End must be more than or equal Term Start.',
            });
            return {
              [name]: state.TermEndStr,
            };
          }
          if (compareDate(mergeState.ContractDateStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term End must be more than or equal Contract Date.',
            });
            return {
              [name]: state.TermEndStr,
            };
          }
        }
      } else if (name === 'RefOutstanding' || name === 'SwapOutstanding') {
        stateToUpdate = {
          ...state,
          ...stateToUpdate,
        };
        stateToUpdate.resultSchedule = stateToUpdate.resultSchedule.map((m, i, s) => {
          const totalSwapRepayment = s.reduce((prev, cur) => {
            if (
              moment(cur.RepaymentDateStr, 'DD/MM/YYYY').isSameOrBefore(
                moment(m.ValidFromStr, 'DD/MM/YYYY'),
                'days'
              )
            )
              return prev + (Number(cur.SwapAmount) || 0);
            return prev;
          }, 0);
          const totalRefRepayment = s.reduce((prev, cur) => {
            if (
              moment(cur.RepaymentDateStr, 'DD/MM/YYYY').isSameOrBefore(
                moment(m.ValidFromStr, 'DD/MM/YYYY'),
                'days'
              )
            )
              return prev + (Number(cur.RefAmount) || 0);
            return prev;
          }, 0);

          return {
            ...m,
            RefOutstanding: (Number(stateToUpdate.RefOutstanding) || 0) - totalRefRepayment,
            SwapOutstanding:
              stateToUpdate.DerivativeType === 'IRS'
                ? (Number(stateToUpdate.RefOutstanding) || 0) - totalRefRepayment
                : (Number(stateToUpdate.SwapOutstanding) || 0) - totalSwapRepayment,
          };
        });
      } else if (name === 'SwapCurrency') {
        const obj = datas.find((f) => f.currency === value);
        let { digit } = obj || {};
        if (digit === null || digit === undefined) digit = 3;
        stateToUpdate.inputMaskOpt = {
          prefix: '',
          digits: digit,
          digitsOptional: false,
          placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
        };
      }

      return stateToUpdate;
    });
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      console.log(form);
      if (!form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmit = (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formStructorRef,
      this.formCashFlowRef,
      this.formResultCashFlowRef,
    ]);
    if (inValidFormName) return;

    const state = { ...this.state };

    // let textCheckDay = ''
    // if (checkWeekend(state.ContractDateStr))
    //     textCheckDay = 'Contract Date can not be Weekend.'
    // else if (checkWeekend(state.TermStartStr))
    //     textCheckDay = 'Term Start can not be Weekend.'
    // else if (checkWeekend(state.TermEndStr))
    //     textCheckDay = 'Term End can not be Weekend.'

    // if (textCheckDay !== '') {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: textCheckDay,
    //     })
    //     return
    // }

    // if (!(state.resultCashFlowReference || []).length && !(state.resultCashFlowSwap || []).length) {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: 'Please get cash flow.',
    //     })
    //     return
    // }

    // let ScheduleRef = [];
    // let ScheduleSwap = [];
    // state.resultSchedule.forEach(result => {
    //     ScheduleRef.push(result.Ref);
    //     ScheduleSwap.push(result.Swap);
    // });

    const submitData = {
      ...this.state,
      CashFlow: [...this.state.resultCashFlowReference, ...this.state.resultCashFlowSwap],
      Schedule: [...this.state.resultSchedule],
      BankCodeCashFlow: this.state.BankCodeCashFlow.toString(),
    };
    // delete submitData.resultDeposit
    this.props.saveDerivative(submitData).then((response) => {
      if (!response.error) {
        this.setState({
          resultDeposit: [],
          resultLoan: [],
        });
        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The transaction was successfully saved',
          buttons: [
            <button
              className="btn btn-success"
              onClick={this.onClickBackToPage}
              data-dismiss="modal"
            >
              Continue
            </button>,
          ],
        });
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/loan-investment/create-derivative?s=true');
  };

  onClickGetCashflow = () => {
    const inValidFormName = this.validateForms([this.formDetailRef, this.formStructorRef]);
    if (inValidFormName) return;

    const {
      CompanyCode,
      Partner,
      TransactionNo,
      LoanRef,
      Swap,
      BankCodeCashFlow,
      resultSchedule,
      DerivativeType,
    } = this.state;

    this.props
      .processCashflow({
        CompanyCode,
        Partner,
        TransactionNo,
        LoanRef,
        Swap,
        DerivativeType,
        BankCodeCashFlow: (BankCodeCashFlow || []).join(','),
        Schedule: resultSchedule,
      })
      .then((response) => {
        if (response.error) return;

        const cashFlowRef = [];
        const cashFlowSwap = [];
        response.payload.forEach((m) => {
          if ((m.cashFlowType || '').toLowerCase() === 'swap') cashFlowSwap.push({ ...m });
          else cashFlowRef.push({ ...m });
        });

        this.setState({
          resultCashFlowReference: cashFlowRef,
          resultCashFlowSwap: cashFlowSwap,
        });
      });
  };

  // Tab Cash Flow
  onChangeInputTable = (e, index, type) => {
    const { name, value, label } = e.target;
    this.setState((state) => ({
      resultCashFlowSwap: state.resultCashFlowSwap.map((m, i) => {
        if (i === index) {
          const obj = {
            ...m,
          };
          obj[name] = value;

          switch (name) {
            case 'bankAccountNo':
              const bankAccountCurrency = label.split('|')[2].trim();
              obj.localCurrency = bankAccountCurrency;
              if (obj.currency === bankAccountCurrency) {
                obj.localCurrencyAmount = obj.originalInterestAmount;
              } else {
                obj.localCurrencyAmount = 0;
              }
              break;
          }

          return obj;
        }
        return m;
      }),
    }));
  };

  onChangeInputHeader = (e, type) => {
    const { name, value, label } = e.target;
    this.setState((state) => {
      const keyForUpdate = 'resultCashFlowSwap';
      let keyInputAll = '';
      if (name === 'bankAccountNoAll') {
        keyInputAll = 'AccountNoAll';
      } else if (name === 'paymentMethodAll') {
        keyInputAll = 'PaymentMethodAll';
      }
      const result = state[keyForUpdate];

      return {
        [keyInputAll]: value,
        [keyForUpdate]: result.map((m, i) => {
          const obj = {
            ...m,
          };

          if (name === 'bankAccountNoAll') {
            obj.bankAccountNo = value;
            const bankAccountCurrency = label.split('|')[2].trim();
            obj.localCurrency = bankAccountCurrency;
            if (obj.currency === bankAccountCurrency) {
              obj.localCurrencyAmount = obj.amount;
            } else {
              obj.localCurrencyAmount = 0;
            }
          } else if (name === 'paymentMethodAll') {
            obj.paymentMethod = value;
          }

          return obj;
        }),
      };
    });
  };

  // Modal Schedule
  onClickOpenModalSchedule = (e, action) => {
    const derivativeType = this.state.DerivativeType;
    this.selectedScheduleIdx = null;
    if (!derivativeType) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select Derivative Type.',
      });
      return;
    }

    if (derivativeType === 'POS') {
      this.setState(
        {
          modalSchedule: {
            ...initModalSchedule,
            ScheduleType: derivativeType,
            TempId: this.uuid(),
          },
        },
        this.modalPosRef.open
      );
    } else if (derivativeType === 'IRS') {
      this.setState(
        {
          modalSchedule: {
            ...initModalSchedule,
            ScheduleType: derivativeType,
            TempId: this.uuid(),
          },
        },
        this.modalIrsRef.open
      );
    } else if (derivativeType === 'CCS') {
      this.setState(
        {
          modalSchedule: {
            ...initModalSchedule,
            ScheduleType: derivativeType,
            TempId: this.uuid(),
          },
        },
        this.modalCcsRef.open
      );
    }
  };

  onViewModalSchedule = (e, idx) => {
    const state = this.state.resultSchedule[idx];
    this.selectedScheduleIdx = idx;
    state.mode = 'view';
    if (state.ScheduleType === 'POS') {
      this.setState(
        {
          modalSchedule: { ...state },
          inputMaskRefOpt: {
            prefix: '',
            digits: state.RefDigit,
            digitsOptional: false,
            placeholder: !state.RefDigit ? '0' : `0.${`000${state.RefDigit}`.slice(-3)}`,
          },
          inputMaskSwapOpt: {
            prefix: '',
            digits: state.SwapDigit,
            digitsOptional: false,
            placeholder: !state.SwapDigit ? '0' : `0.${`000${state.SwapDigit}`.slice(-3)}`,
          },
        },
        this.modalPosRef.open
      );
    } else if (state.ScheduleType === 'IRS') {
      this.setState(
        {
          modalSchedule: { ...state },
          inputMaskRefOpt: {
            prefix: '',
            digits: state.SwapDigit,
            digitsOptional: false,
            placeholder: !state.SwapDigit ? '0' : `0.${`000${state.SwapDigit}`.slice(-3)}`,
          },
        },
        this.modalIrsRef.open
      );
    } else if (state.ScheduleType === 'CCS') {
      this.setState(
        {
          modalSchedule: { ...state },
          inputMaskRefOpt: {
            prefix: '',
            digits: state.RefDigit,
            digitsOptional: false,
            placeholder: !state.RefDigit ? '0' : `0.${`000${state.RefDigit}`.slice(-3)}`,
          },
          inputMaskSwapOpt: {
            prefix: '',
            digits: state.SwapDigit,
            digitsOptional: false,
            placeholder: !state.SwapDigit ? '0' : `0.${`000${state.SwapDigit}`.slice(-3)}`,
          },
        },
        this.modalCcsRef.open
      );
    }
  };

  onChangeModalDataSchedule = (e) => {
    const { name, value, datas } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        modalSchedule: {
          ...this.state.modalSchedule,
          [name]: value,
        },
      };

      if (name === 'RefInterestType') {
        stateToUpdate.modalSchedule.RefInterest = '';
        stateToUpdate.modalSchedule.RefInterestRate = '';
        stateToUpdate.modalSchedule.RefMargin = '';
      } else if (name === 'RefInterestRate') {
        stateToUpdate.modalSchedule.RefInterest =
          parseFloat(value) + parseFloat(state.modalSchedule.RefMargin);
      } else if (name === 'RefMargin') {
        stateToUpdate.modalSchedule.RefInterest =
          parseFloat(state.modalSchedule.RefInterestRate) + parseFloat(value);
      } else if (name === 'SwapInterestType') {
        stateToUpdate.modalSchedule.SwapInterest = '';
        stateToUpdate.modalSchedule.SwapInterestRate = '';
        stateToUpdate.modalSchedule.SwapMargin = '';
      } else if (name === 'SwapInterestRate') {
        stateToUpdate.modalSchedule.SwapInterest =
          parseFloat(value) + parseFloat(state.modalSchedule.SwapMargin);
      } else if (name === 'SwapMargin') {
        stateToUpdate.modalSchedule.SwapInterest =
          parseFloat(state.modalSchedule.SwapInterestRate) + parseFloat(value);
      } else if (name === 'IsManualInclude') {
        stateToUpdate.modalSchedule.IsManualInclude = !state.modalSchedule.IsManualInclude;
      } else if (name === 'PeriodEndType') {
        if (value === 'End of Term') {
          stateToUpdate.modalSchedule.ManualPeriodEnd = '';
          stateToUpdate.modalSchedule.IsManualMonthEnd = false;
          stateToUpdate.modalSchedule.ManualDay = '';
        }
      } else if (name === 'ManualPeriodEnd') {
        if (value === 'Month End') {
          stateToUpdate.modalSchedule.IsManualMonthEnd = true;
          stateToUpdate.modalSchedule.ManualDay = '';
        } else stateToUpdate.modalSchedule.IsManualMonthEnd = false;
      } else if (name === 'ManualDay') {
        if (value > 31) stateToUpdate.modalSchedule.ManualDay = 31;
        else if (value < 1) stateToUpdate.modalSchedule.ManualDay = 1;
      } else if (name === 'DueDateType') {
        if (value === 'End of Term') {
          stateToUpdate.modalSchedule.ManualDueDate = '';
          stateToUpdate.modalSchedule.DueDateDay = '';
          stateToUpdate.modalSchedule.IsDueDateMonthEnd = false;
        }
      } else if (name === 'ManualDueDate') {
        if (value === 'Month End') {
          stateToUpdate.modalSchedule.IsDueDateMonthEnd = true;
          stateToUpdate.modalSchedule.DueDateDay = '';
        } else stateToUpdate.modalSchedule.IsDueDateMonthEnd = false;
      } else if (name === 'DueDateDay') {
        if (value > 31) stateToUpdate.modalSchedule.DueDateDay = 31;
        else if (value < 1) stateToUpdate.modalSchedule.DueDateDay = 1;
      } else if (name === 'RefAmount' && stateToUpdate.modalSchedule.ScheduleType === 'IRS') {
        stateToUpdate.modalSchedule.SwapAmount = value;
      } else if (name === 'RefCurrency' && stateToUpdate.modalSchedule.ScheduleType === 'IRS') {
        stateToUpdate.modalSchedule.SwapCurrency = value;
      }

      if ((name === 'RefCurrency' || name === 'SwapCurrency') && value) {
        const obj = datas.find((f) => f.currency === value);
        let { digit } = obj || {};
        if (digit === null || digit === undefined) digit = 3;
        if (name === 'SwapCurrency') {
          stateToUpdate.inputMaskSwapOpt = {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          };
          stateToUpdate.modalSchedule.SwapDigit = digit;
        } else {
          stateToUpdate.inputMaskRefOpt = {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          };
          if (stateToUpdate.modalSchedule.ScheduleType === 'IRS')
            stateToUpdate.modalSchedule.SwapDigit = digit;
          stateToUpdate.modalSchedule.RefDigit = digit;
        }
      }

      return stateToUpdate;
    });
  };

  onDeleteSchedule = (e, idx) => {
    this.setState((state) => {
      const resultSchedule = state.resultSchedule
        .filter((x, i) => i !== idx)
        .sort((a, b) => {
          const mA = moment(a.ValidFromStr, 'DD/MM/YYYY');
          const mB = moment(b.ValidFromStr, 'DD/MM/YYYY');
          return mA.isAfter(mB, 'days') ? 1 : mA.isBefore(mB, 'days') ? -1 : 0;
        })
        .map((m, i, s) => {
          const totalSwapRepayment = s.reduce((prev, cur) => {
            if (
              moment(cur.RepaymentDateStr, 'DD/MM/YYYY').isSameOrBefore(
                moment(m.ValidFromStr, 'DD/MM/YYYY'),
                'days'
              )
            )
              return prev + (Number(cur.SwapAmount) || 0);
            return prev;
          }, 0);
          const totalRefRepayment = s.reduce((prev, cur) => {
            if (
              moment(cur.RepaymentDateStr, 'DD/MM/YYYY').isSameOrBefore(
                moment(m.ValidFromStr, 'DD/MM/YYYY'),
                'days'
              )
            )
              return prev + (Number(cur.RefAmount) || 0);
            return prev;
          }, 0);

          return {
            ...m,
            RefOutstanding: (Number(state.RefOutstanding) || 0) - totalRefRepayment,
            SwapOutstanding:
              state.DerivativeType === 'IRS'
                ? (Number(state.RefOutstanding) || 0) - totalRefRepayment
                : (Number(state.SwapOutstanding) || 0) - totalSwapRepayment,
          };
        });
      return {
        resultSchedule,
      };
    });
  };

  onSubmitModalSchedule = (e) => {
    e.preventDefault();

    this.setState((state) => {
      let resultSchedule = [];
      if (this.selectedScheduleIdx !== null)
        resultSchedule = state.resultSchedule.map((m, i) => {
          if (i === this.selectedScheduleIdx)
            return {
              ...state.modalSchedule,
            };
          return m;
        });
      else resultSchedule = [...state.resultSchedule, { ...state.modalSchedule }];

      resultSchedule = resultSchedule
        .sort((a, b) => {
          const mA = moment(a.ValidFromStr, 'DD/MM/YYYY');
          const mB = moment(b.ValidFromStr, 'DD/MM/YYYY');
          return mA.isAfter(mB, 'days') ? 1 : mA.isBefore(mB, 'days') ? -1 : 0;
        })
        .map((m, i, s) => {
          const totalSwapRepayment = s.reduce((prev, cur) => {
            if (
              moment(cur.RepaymentDateStr, 'DD/MM/YYYY').isSameOrBefore(
                moment(m.ValidFromStr, 'DD/MM/YYYY'),
                'days'
              )
            )
              return prev + (Number(cur.SwapAmount) || 0);
            return prev;
          }, 0);
          const totalRefRepayment = s.reduce((prev, cur) => {
            if (
              moment(cur.RepaymentDateStr, 'DD/MM/YYYY').isSameOrBefore(
                moment(m.ValidFromStr, 'DD/MM/YYYY'),
                'days'
              )
            )
              return prev + (Number(cur.RefAmount) || 0);
            return prev;
          }, 0);

          return {
            ...m,
            RefOutstanding: (Number(state.RefOutstanding) || 0) - totalRefRepayment,
            SwapOutstanding:
              state.DerivativeType === 'IRS'
                ? (Number(state.RefOutstanding) || 0) - totalRefRepayment
                : (Number(state.SwapOutstanding) || 0) - totalSwapRepayment,
          };
        });
      return {
        resultSchedule,
      };
    });
    this.modalPosRef.close();
    this.modalIrsRef.close();
    this.modalCcsRef.close();
  };

  uuid = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });

  onChangeSelect2 = (e) => {
    if (e.value.length < 1) {
      this.setState({
        ...this.state,
        [e.name]: [''],
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        ...this.state,
        [e.name]: e.value.filter((item) => item !== ''),
      });
    } else {
      this.setState({
        ...this.state,
        [e.name]: e.value,
      });
    }
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,

      onChangeInputTable: this.onChangeInputTable,
      onChangeInputHeader: this.onChangeInputHeader,

      onClickOpenModalSchedule: this.onClickOpenModalSchedule,
      onChangeModalDataSchedule: this.onChangeModalDataSchedule,
      onSubmitModalSchedule: this.onSubmitModalSchedule,
      onViewModalSchedule: this.onViewModalSchedule,
      onDeleteSchedule: this.onDeleteSchedule,
      onClickGetCashflow: this.onClickGetCashflow,
      formDetailRef: (e) => (this.formDetailRef = e),
      formStructorRef: (e) => (this.formStructorRef = e),
      formCashFlowRef: (e) => (this.formCashFlowRef = e),
      formResultCashFlowRef: (e) => (this.formResultCashFlowRef = e),

      tabRef: (e) => (this.tabRef = e),
      onChangeSelect2: this.onChangeSelect2,
    };

    return (
      <Derivative
        {...props}
        state={this.state}
        modalScheduleRef={(e) => (this.modalScheduleRef = e)}
        modalPosRef={(e) => (this.modalPosRef = e)}
        modalIrsRef={(e) => (this.modalIrsRef = e)}
        modalCcsRef={(e) => (this.modalCcsRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.derivative,
    currencys: state.master.currencys,
  }),
  {
    addAlert,
    saveDerivative,
    getDerivative,
    processCashflow,
    getCurrency,
  }
)(DerivativeContainer);
