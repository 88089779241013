import { connect } from 'react-redux';
import React, { Component } from 'react';

import BookingForward from './BookingForward';
import ImportExcel from '../../../common/ImportExcel';
import { addAlert } from '../../../reducers/layout/action';
import {
  toFixed,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../../common/helpper';
import {
  getPoSoCompany,
  saveImportExcel,
  exposureMatching,
  uploadImportExcel,
  updateSearchResult,
  saveExposureBooingForward,
  searchCreateExposureBooingForward,
} from '../../../reducers/bookingForward/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-booking-forward';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const initCriteria = {
  FinanceGroup: [''],
  BusinessUnit: [''],
  Company: [''],
  DocumentType: 'All',
  Currency: [''],
  RequestType: 'All',
  EntryDateFromStr: '',
  EntryDateToStr: '',
  CreateDateStr: '',
  EndDateStr: '',
  Bank: [''],
  Status: '',
};

const initModal = {
  idList: null,
  IdListTable: [],
  createDateStr: '',
  id: null,
};

const initModalEdit = {
  IdListTable: [],
  BankCode: '',
};

class BookingForwardContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    modalData: {
      ...initModal,
    },
    modalEdit: {
      ...initModalEdit,
    },
    compFilter: [],
    currencyFilter: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `button.active`, (e) => this.onClickActive(e));
    $('body').on('click', `button.inactive`, (e) => this.onClickInactive(e));
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
  }

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) {
      e.target.classList.remove('checked');
    } else {
      e.target.classList.add('checked');
    }
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    this.props.searchResult[result.row].IsSelected = result.checked;
    this.forceUpdate();
  };

  // search
  onChangeSearchHeadler = (e) => {
    if (e.target.name === 'CreateDateStr') {
      if (e.target.value) {
        const { value } = e.target;
        this.props.getPoSoCompany({ Mode: 'comp', CreateDateStr: value }).then((res) => {
          console.log(res.payload);
          const comp = [...new Set((res.payload || []).map((m) => m.companyCode))];
          const currency = [...new Set((res.payload || []).map((m) => m.currency))];

          this.setState({
            ...this.state,
            criteria: {
              ...this.state.criteria,
              [e.target.name]: value,
            },
            compFilter: comp,
            currencyFilter: currency,
          });
        });
      } else {
        this.setState({
          ...this.state,
          criteria: {
            ...this.state.criteria,
            [e.target.name]: e.target.value,
          },
          compFilter: [],
        });
      }
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchCreateExposureBooingForward({
      ...this.state.criteria,
    });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
      compFilter: [],
    });
  };

  onUploadImportExcel = (file) => {
    const form = new FormData();
    if (!file) return;
    form.append('file', file);
    this.props.uploadImportExcel(form);
  };

  onClickSaveImportExcel = () => {
    if (
      !this.props.importExcelResult ||
      !this.props.importExcelResult.rows ||
      !this.props.importExcelResult.rows.length
    ) {
      this.props.addAlert({
        title: 'Error',
        body: 'Data Empty',
        type: 'error',
      });
      return;
    }
    this.props.saveImportExcel(this.props.importExcelResult.rows).then(() => {
      this.importExcelRef.close();
    });
  };
  // end search

  onClickMatching = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Matching!',
      });
      return;
    }

    this.setState(
      {
        modalData: {
          IdListTable: selectedData.length ? selectedData.map((m) => m.id) : [],
          createDateStr: this.state.criteria.CreateDateStr,
        },
      },
      this.modalDataRef.open
    );
  };

  onSubmitMatching = () => {
    this.props.exposureMatching(this.state.modalData, this.state.criteria).then(() => {
      clearCheckBoxAllTable(DATATABLE_ID);
      this.modalDataRef.close();
    });
  };

  onClickActive = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'active',
          id: this.props.searchResult[row].id,
        },
      },
      this.modalActiveRef.open
    );
  };

  onClickInactive = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'inactive',
          id: this.props.searchResult[row].id,
        },
      },
      this.modalInactiveRef.open
    );
  };

  onSubmitConfirm = () => {
    this.props.saveExposureBooingForward({ ...this.state.modalData }).then((response) => {
      if (response.error) return;
      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchCreateExposureBooingForward({
        ...this.state.criteria,
      });
    });
  };

  onOpenEditBank = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Matching!',
      });
      return;
    }

    this.setState(
      {
        modalEdit: {
          IdListTable: selectedData.length ? selectedData.map((m) => m.id) : [],
          BankCode: '',
          mode: 'change bank',
        },
      },
      this.modalBankRef.open
    );
  };

  onChangeBank = (e) => {
    this.setState({
      modalEdit: {
        ...this.state.modalEdit,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSaveBank = (e) => {
    e.preventDefault();
    this.props.saveExposureBooingForward({ ...this.state.modalEdit }).then((response) => {
      if (response.error) return;
      this.modalBankRef.close();
      this.props.searchCreateExposureBooingForward({
        ...this.state.criteria,
      });
    });
  };

  onOpenClearInternalRef = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Clear Internal Ref!',
      });
      return;
    }

    this.setState(
      {
        modalEdit: {
          IdListTable: selectedData.length ? selectedData.map((m) => m.id) : [],
          BankCode: '',
          mode: 'clear internal',
        },
      },
      this.modalInternalRef.open
    );
  };

  onSaveInternalRef = (e) => {
    e.preventDefault();
    this.props.saveExposureBooingForward({ ...this.state.modalEdit }).then((response) => {
      if (response.error) return;
      this.modalInternalRef.close();
      this.props.searchCreateExposureBooingForward({
        ...this.state.criteria,
      });
    });
  };

  onOpenInactiveMulti = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Matching!',
      });
      return;
    }

    this.setState(
      {
        modalEdit: {
          IdListTable: selectedData.length ? selectedData.map((m) => m.id) : [],
          BankCode: '',
          mode: 'inactive multi',
        },
      },
      this.modelInactiveMultiRef.open
    );
  };

  onSaveInactiveMulti = (e) => {
    e.preventDefault();
    this.props.saveExposureBooingForward({ ...this.state.modalEdit }).then((response) => {
      if (response.error) return;
      this.modelInactiveMultiRef.close();
      this.props.searchCreateExposureBooingForward({
        ...this.state.criteria,
      });
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      onClikcOpenImportExcel: () => this.importExcelRef.open(),
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,
      onClickMatching: this.onClickMatching,
      onSubmitMatching: this.onSubmitMatching,
      onSubmitConfirm: this.onSubmitConfirm,
      onOpenEditBank: this.onOpenEditBank,
      onChangeBank: this.onChangeBank,
      onSaveBank: this.onSaveBank,
      onOpenClearInternalRef: this.onOpenClearInternalRef,
      onSaveInternalRef: this.onSaveInternalRef,
      onOpenInactiveMulti: this.onOpenInactiveMulti,
      onSaveInactiveMulti: this.onSaveInactiveMulti,
    };

    return (
      <div>
        <ImportExcel
          ref={(e) => (this.importExcelRef = e)}
          tableName="poso-import-excel"
          optionDt={importOpt}
          onUpload={this.onUploadImportExcel}
          value={this.props.importExcelResult || {}}
          onClickSaveImport={this.onClickSaveImportExcel}
        >
          <thead>
            <tr>
              <th>Company Code</th>
              <th>Company Name</th>
              <th>Document Type</th>
              <th>Date From</th>
              <th>Date To</th>
              <th>Currency</th>
              <th>Total Amount</th>
              <th>Hedge Amount</th>
              <th>End Date</th>
              <th>End Date Amount</th>
              <th>Internal Reference</th>
              <th>Request Type</th>
              <th>Department</th>
              <th>Error Message</th>
            </tr>
          </thead>
        </ImportExcel>
        <BookingForward
          {...props}
          {...this.state}
          modalDataRef={(e) => (this.modalDataRef = e)}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalBankRef={(e) => (this.modalBankRef = e)}
          modalInternalRef={(e) => (this.modalInternalRef = e)}
          modelInactiveMultiRef={(e) => (this.modelInactiveMultiRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.bookingForward,
  }),
  {
    searchCreateExposureBooingForward,
    updateSearchResult,
    addAlert,
    exposureMatching,
    uploadImportExcel,
    saveImportExcel,
    saveExposureBooingForward,
    getPoSoCompany,
  }
)(BookingForwardContainer);

const importOpt = {
  columns: [
    { data: 'companyCode' },
    { data: 'companyName' },
    { data: 'documentType' },
    { data: 'dateFromStr' },
    { data: 'dateToStr' },
    { data: 'currency' },
    {
      data: 'totalAmount',
      render: (d) => toFixed(d),
    },
    {
      data: 'hedgeAmount',
      render: (d) => toFixed(d),
    },
    {
      data: 'endDate',
      render: (d) => (d ? d.dateStr : ''),
    },
    {
      data: 'endDate',
      render: (d) => (d ? toFixed(d.amount) : ''),
    },
    { data: 'intrnalReference' },
    { data: 'requestType' },
    { data: 'department' },
    { data: 'errorMessage' },
  ],
};
