import { connect } from 'react-redux';
import React, { Component } from 'react';

import ImportExcel from '../../common/ImportExcel';
import BankTransferSummary from './BankTransferSummary';
import { addAlert } from '../../reducers/layout/action';
import {
  toLower,
  checkAfterValueDate,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../common/helpper';
import {
  getCheque,
  setSelected,
  changeStatus,
  editChequeNo,
  setSelectedAll,
  printTransferfee,
  editSearchResult,
  saveImportCheque,
  importChequeExcel,
  updateSearchResult,
  changeConfirmStatus,
  editBanktransferSummary,
  searchBankTransferSummary,
  changeApproverMultiTransaction,
} from '../../reducers/bankTransferSummary/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-bank-transfer-summary';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const initChangeStatus = {
  action: '',
  groupPass: [],

  icon: '',
  type: '',
  textheader: '',
  textContent: '',
  textBtn: 'Confirm',
  textBtnBack: 'Back',
  selectedLength: 0,
  passLength: 0,
  showFail: true,
  noSelect: false,
  resultFail: [],

  assignApproverLength: 0,
  approveLenght: 0,
};

class BankTransferSummaryContainer extends Component {
  state = {
    criteria: {
      FinanceGroupId: [''],
      BusinessUnitId: [''],
      CompanyCode: [''],
      ValueDateStr: '',
      Currency: [''],
      ApplicantBankAbbrivate: [''],
      BeneficiaryBankAbbrivate: [''],
      TransferMethod: [''],
      TransactionStatus: [''],
      Source: [''],
      SendingType: [''],
      PostTransactionStatus: [''],
      ApproveName: '',
      IsApprove: false,
    },
    modalEdit: {
      TransferMethod: '',
      SendingType: '',
    },
    modalApprove: {
      Approver1: '',
      Approver2: '',
      Reviewer: '',
      FinanceGroupId: '',
      setReviewer: false,
    },
    modalGetCheque: {
      ValueDate: '',
    },
    modalEditCheque: {
      ChequeNo: '',
    },
    modalChangeStatus: {
      ...initChangeStatus,
    },
    modalChangeApprover: {
      TransactionNo: [''],
      Approver1: '',
      Approver2: '',
      FinanceGroupId: '',
      Action: '',
      Reviewer: '',
    },
    isMillion: false,
    selectedRowIdx: null,
    modalChangeApproverMultiTrans: {
      transactionsData: [],
      resultFail: [],
      Approver: '',
      ApproverType: '',
      FinanceGroupId: '',
    },
  };

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `button.print`, this.onClickPrintHandler);
    $('body').on('click', `button.edit`, this.onClickOpenModalEditHandler);
    $('body').on('click', `a.change-approver-1`, (e) =>
      this.onClickOpenChangeApprover(e, 'approver1')
    );
    $('body').on('click', `a.change-approver-2`, (e) =>
      this.onClickOpenChangeApprover(e, 'approver2')
    );
    $('body').on('click', `a.change-reviewer`, (e) =>
      this.onClickOpenChangeApprover(e, 'reviewer')
    );
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.print`, this.onClickPrintHandler);
    $('body').off('click', `button.edit`, this.onClickOpenModalEditHandler);
    $('body').off('click', `a.change-approver-1`);
    $('body').off('click', `a.change-approver-2`);
    $('body').off('click', `a.change-reviewer`);
  }

  onChangeSearchHeadler = (e) => {
    if (e.target.name === 'IsApprove') {
      this.setState({
        criteria: {
          ...this.state.criteria,
          IsApprove: !this.state.criteria.IsApprove,
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault(e);

    this.props
      .searchBankTransferSummary({
        ...this.state.criteria,
      })
      .then((response) => {
        if (!response.error) this.props.editSearchResult('IsMillion', this.state.isMillion, null);

        clearCheckBoxAllTable(DATATABLE_ID);
      });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        FinanceGroupId: [''],
        BusinessUnitId: [''],
        CompanyCode: [''],
        ValueDateStr: '',
        Currency: [''],
        ApplicantBankAbbrivate: [''],
        BeneficiaryBankAbbrivate: [''],
        TransferMethod: [''],
        TransactionStatus: [''],
        Source: [''],
        SendingType: [''],
        PostTransactionStatus: [''],
        ApproveName: '',
        IsApprove: false,
      },
    });
  };

  onClickOpenModalEditHandler = (e, cell) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const data = this.props.searchResult[row];

    this.setState(
      {
        modalEdit: {
          TransactionNo: data.transactionNo,
          TransferMethod: data.transferMethod || '',
          SendingType: data.sendingType || '',
        },
      },
      this.modalEditRef.open
    );
  };

  onChangeModalEditHeadler = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      modalEdit: {
        ...state.modalEdit,
        [name]: value,
      },
    }));
  };

  onClickSaveModalEditHandler = (e) => {
    e.preventDefault();

    this.props
      .editBanktransferSummary({ ...this.state.modalEdit }, { ...this.state.criteria })
      .then(this.modalEditRef.close);
  };

  onClickPrintHandler = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const data = this.props.searchResult[row];
    this.props.printTransferfee(data.transactionNo);
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    if (result) {
      this.props.searchResult[result.row].IsSelected = result.checked;
      this.forceUpdate();
    }
  };

  // start status modal
  onClickModalSubmitStatusHandler = (e) => {
    e.preventDefault();

    const { modalChangeStatus } = this.state;
    const group = modalChangeStatus.groupPass;
    const Status = modalChangeStatus.action;

    const data = {
      TransactionNo: group.map((m) => m.transactionNo),
      ...this.state.modalApprove,
    };

    let promise = null;

    if (Status === 'Confirm') {
      promise = this.props.changeConfirmStatus(
        {
          ...data,
          Status: 'Confirmed',
        },
        this.state.criteria
      );
    } else if (Status === 'UnConfirm') {
      const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
      let checkAlert = false;
      selectedData.forEach((m) => {
        if (
          toLower(m.transferMethod) === 'bahtnet' &&
          (toLower(m.transactionStatus) === 'wait for generate file' ||
            toLower(m.transactionStatus) === 'accepted by bank')
        ) {
          checkAlert = true;
        } else if (
          toLower(m.transferMethod) === 'cheque' &&
          (toLower(m.transactionStatus) === 'exported' ||
            toLower(m.transactionStatus) === 'already send to bank')
        ) {
          checkAlert = true;
        }
      });

      if (checkAlert) {
        this.modalChangeStatusRef.close();
        this.modalCheckUnconfirmRef.open();
        return;
      }

      promise = this.props.changeConfirmStatus(
        {
          ...data,
          Status: 'Rejected',
        },
        this.state.criteria
      );
    } else if (Status === 'SendEmailToBank') {
      promise = this.props.changeConfirmStatus(
        {
          ...data,
          Action: 'SEND_EMAIL',
        },
        this.state.criteria
      );
    } else if (Status === 'Export') {
      promise = this.submitExportCheque(group);
    } else
      promise = this.props.changeStatus(
        {
          ...data,
          Status,
        },
        this.state.criteria
      );

    promise.then((response) => {
      clearCheckBoxAllTable(DATATABLE_ID);
      this.modalChangeStatusRef.close();
      this.modalConfirmRef.close();
    });
  };

  onSubmitCheckUnconfirm = (e) => {
    e.preventDefault();
    const { modalChangeStatus } = this.state;
    const group = modalChangeStatus.groupPass;

    const data = {
      TransactionNo: group.map((m) => m.transactionNo),
      ...this.state.modalApprove,
    };

    this.props
      .changeConfirmStatus(
        {
          ...data,
          Status: 'Rejected',
        },
        this.state.criteria
      )
      .then((response) => {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.modalCheckUnconfirmRef.close();
      });
  };

  submitExportCheque = (data) => {
    const groupTransaction = [];

    data.forEach((m) => {
      const find = groupTransaction.find(
        (f) =>
          f.beneficiaryBankCode === m.beneficiaryBankCode && f.financeGroupId === m.financeGroupId
      );

      if (find) find.transactionNo.push(m.transactionNo);
      else {
        groupTransaction.push({
          beneficiaryBankCode: m.beneficiaryBankCode,
          financeGroupId: m.financeGroupId,
          transactionNo: [m.transactionNo],
        });
      }
    });

    return new Promise((allReslove, reject) => {
      let chainPromise = null;
      // ต่อ chain promise โหลดเสร็จค่อยโหลดไฟล์หมต่อ ไม่ส่งไปพร้อมกันเปลือง
      groupTransaction.forEach((m, i) => {
        if (!chainPromise)
          chainPromise = this.props
            .changeConfirmStatus(
              {
                TransactionNo: m.transactionNo,
                Action: 'EXPORT',
              },
              groupTransaction.length === 1 ? this.state.criteria : null
            )
            .then(() => {
              if (groupTransaction.length === 1) allReslove();
            });
        else
          chainPromise = new Promise((reslove) => {
            chainPromise.then(() => {
              this.props
                .changeConfirmStatus(
                  {
                    TransactionNo: m.transactionNo,
                    Action: 'EXPORT',
                  },
                  groupTransaction.length - 1 === i ? this.state.criteria : null
                )
                .then(groupTransaction.length - 1 === i ? allReslove : reslove);
            });
          });
      });
    });
  };

  // - - Confirm & Approve - -
  onClickOpenModalConfirmHandler = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for change status!',
      });
      return;
    }

    const action = 'Confirm';
    const icon = 'icon-check';
    const type = 'success';
    const textHeader = 'Approve';
    const textContent = 'approve';
    const textBtn = 'Confirm';
    const textBtnBack = 'Back';
    const resultFail = [];

    const groupPass = [];
    const groupFail = [];
    const groupFail2 = [];
    const groupApprover = [];
    const groupConfirm = [];

    let checkFinanceGroup = true;
    let financeGroupId = '';
    let setReviewer = false;
    let checkgroupReviewer = false;
    let checkgroup = false;

    selectedData.forEach((m) => {
      if (toLower(m.transactionStatus) === 'create' && checkAfterValueDate(m.valueDateStr)) {
        groupPass.push(m);
        if (
          toLower(m.transferMethod) === 'bahtnet' &&
          (toLower(m.sendingType) === 'sftp' || toLower(m.sendingType) === 'paper with signature')
        )
          groupApprover.push(m);
        else groupConfirm.push(m);
      } else if (!checkAfterValueDate(m.valueDateStr)) {
        if (toLower(m.sendingType) === 'sftp' && toLower(m.transactionStatus) === 'create') {
          groupPass.push(m);
          groupApprover.push(m);
        } else if (
          toLower(m.sendingType) === 'paper' &&
          toLower(m.transactionStatus) === 'create'
        ) {
          groupPass.push(m);
          groupConfirm.push(m);
        } else {
          groupFail.push(m);
        }
      } else groupFail2.push(m);

      if (financeGroupId === '') {
        financeGroupId = m.financeGroupId;
      } else if (financeGroupId !== m.financeGroupId) {
        checkFinanceGroup = false;
      }

      if (m.financeGroupId === '005' || m.financeGroupId === '006' || m.financeGroupId === '008') {
        checkgroupReviewer = true;
        setReviewer = true;
      } else {
        checkgroup = true;
        setReviewer = false;
      }
    });

    if (checkgroupReviewer && checkgroup && groupApprover.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: (
          <span>
            Your selected item included finance group "Cementhai Ceramic, Ceramic, Siam Ware".
            <br />
            Please select a new item again.
          </span>
        ),
      });
      return;
    }

    if (!checkFinanceGroup && groupApprover.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select same Finance Group.',
      });
      return;
    }

    this.setState({
      modalApprove: {
        Reviewer: '',
        Approver1: '',
        Approver2: '',
        FinanceGroupId: financeGroupId,
        setReviewer,
      },
    });

    if (groupFail.length)
      resultFail.push(
        <span>
          {groupFail.length} item(s) selected,
          <br />
          because value date over due date.
        </span>
      );
    if (groupFail2.length)
      resultFail.push(
        <span>
          {groupFail2.length} item(s) selected,
          <br />
          because transaction status is not : Create.
        </span>
      );

    if (groupApprover.length)
      this.setState(
        {
          modalChangeStatus: {
            ...initChangeStatus,
            action,
            groupPass,

            icon,
            type,
            textHeader,
            textContent,
            textBtn,
            textBtnBack,
            showFail: true,
            noSelect: false,
            selectedLength: selectedData.length,
            passLength: groupPass.length,
            assignApproverLength: groupApprover.length,
            approveLenght: groupConfirm.length,
            resultFail,
          },
        },
        this.modalConfirmRef.open
      );
    else
      this.setState(
        {
          modalChangeStatus: {
            ...initChangeStatus,
            action,
            groupPass,

            icon,
            type,
            textHeader,
            textContent,
            textBtn,
            textBtnBack,
            showFail: true,
            noSelect: false,
            selectedLength: selectedData.length,
            passLength: groupPass.length,
            resultFail,
          },
        },
        this.modalChangeStatusRef.open
      );
  };

  onChangeModalApproveHeadler = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        modalApprove: {
          ...this.state.modalApprove,
          [name]: value,
        },
      };

      if (name === 'Approver1') {
        if (value === state.modalApprove.Approver2) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select User not same Assign Approver2.',
          });
          stateToUpdate.modalApprove.Approver1 = state.modalApprove.Approver1;
        }
      } else if (name === 'Approver2') {
        if (value === state.modalApprove.Approver1) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select User not same Assign Approver1.',
          });
          stateToUpdate.modalApprove.Approver2 = state.modalApprove.Approver2;
        }
      }

      return stateToUpdate;
    });
  };

  onClickOpenModalChangeStatus = (modal) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for change status!',
      });
      return;
    }

    let action = '';
    let icon = '';
    let type = '';
    let textHeader = '';
    let textContent = '';
    const textBtn = 'Confirm';
    const textBtnBack = 'Back';
    const resultFail = [];

    const groupPass = [];
    const groupFail = [];
    const groupFail2 = [];
    const groupFail3 = [];

    if (modal === 'unconfirm') {
      selected.forEach((m) => {
        if (
          toLower(m.transactionStatus) === 'approved' ||
          toLower(m.transactionStatus) === 'wait for approve' ||
          toLower(m.transactionStatus) === 'already send to bank' ||
          toLower(m.transactionStatus) === 'accepted by bank' ||
          toLower(m.transactionStatus) === 'wait for generate file' ||
          toLower(m.transactionStatus) === 'exported' ||
          toLower(m.transactionStatus) === 'rejected'
        )
          groupPass.push(m);
        // else if (!checkAfterValueDate(m.valueDateStr))
        //     groupFail.push(m)
        else groupFail2.push(m);
      });

      action = 'UnConfirm';
      icon = 'icon-refresh';
      type = 'warning';
      textHeader = 'Unconfirm';
      textContent = 'unconfirm';

      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because transaction status is not : Approved, Wait for approve, Already send to bank,
            Accepted by bank, Wait for generate file or Rejected.
          </span>
        );
    } else if (modal === 'complete') {
      selected.forEach((m) => {
        if (
          toLower(m.transactionStatus) === 'wait for bank receive' ||
          toLower(m.transactionStatus) === 'accepted by bank' ||
          toLower(m.transactionStatus) === 'failed'
        ) {
          if (toLower(m.transferMethod) !== 'cheque') groupPass.push(m);
          else if (toLower(m.transferMethod) === 'cheque') groupFail.push(m);
        } else if (
          toLower(m.transferMethod) !== 'cheque' &&
          toLower(m.transactionStatus) === 'approved' &&
          checkAfterValueDate(m.valueDateStr)
        )
          groupPass.push(m);
        else if (toLower(m.transferMethod) === 'cheque') groupFail.push(m);
        else if (toLower(m.transactionStatus) !== 'approved') groupFail2.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail3.push(m);
      });

      action = 'Completed';
      icon = 'icon-star';
      type = 'primary';
      textHeader = 'Complete';
      textContent = 'complete';

      if (groupFail3.length)
        resultFail.push(
          <span>
            {groupFail3.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because transfer method is : Cheque.
            <br />
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because transaction status is not : Approved, Wait for bank receive, Accepted by bank
            and Failed.
          </span>
        );
    } else if (modal === 'uncomplete') {
      selected.forEach((m) => {
        if (toLower(m.transferMethod) !== 'cheque' && toLower(m.transactionStatus) === 'completed')
          groupPass.push(m);
        else if (toLower(m.transferMethod) === 'cheque') groupFail.push(m);
        else if (toLower(m.transactionStatus) !== 'completed') groupFail2.push(m);
      });

      action = 'Uncompleted';
      icon = 'icon-close';
      type = 'danger';
      textHeader = 'Uncomplete';
      textContent = 'uncomplete';

      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because transfer method is : Cheque.
            <br />
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because transaction status is not : Completed.
          </span>
        );
    } else if (modal === 'delete') {
      selected.forEach((m) => {
        if (toLower(m.transactionStatus) === 'create') groupPass.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail.push(m);
        else groupFail2.push(m);
      });

      action = 'Deleted';
      icon = 'icon-close';
      type = 'danger';
      textHeader = 'Delete';
      textContent = 'delete';

      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because transaction status is not : Create.
          </span>
        );
    } else if (modal === 'cancel bahtnet') {
      const groupFail4 = [];
      selected.forEach((m) => {
        if (
          toLower(m.transferMethod) === 'bahtnet' &&
          toLower(m.transactionStatus) === 'accepted by bank' &&
          toLower(m.sendingType) === 'sftp' &&
          checkAfterValueDate(m.valueDateStr)
        )
          groupPass.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail4.push(m);
        else if (toLower(m.transferMethod) !== 'bahtnet') groupFail.push(m);
        else if (toLower(m.transactionStatus) !== 'accepted by bank') groupFail2.push(m);
        else if (toLower(m.sendingType) !== 'sftp') groupFail3.push(m);
      });

      action = 'Cancel Bahtnet';
      icon = 'icon-close';
      type = 'danger';
      textHeader = 'Cancel Bahtnet';
      textContent = 'cancel bahtnet';

      if (groupFail4.length)
        resultFail.push(
          <span>
            {groupFail4.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because transfer method is not : Bahtnet.
            <br />
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because transaction status is not : Accepted by Bank.
          </span>
        );
      if (groupFail3.length)
        resultFail.push(
          <span>
            {groupFail3.length} item(s) selected,
            <br />
            because sending type is not : SFTP.
          </span>
        );
    } else if (modal === 'send email to bank') {
      selected.forEach((m) => {
        if (
          toLower(m.transferMethod) === 'cheque' &&
          toLower(m.transactionStatus) === 'approved' &&
          checkAfterValueDate(m.valueDateStr)
        )
          groupPass.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail3.push(m);
        else if (toLower(m.transferMethod) !== 'cheque') groupFail.push(m);
        else if (toLower(m.transactionStatus) !== 'approved') groupFail2.push(m);
      });

      action = 'SendEmailToBank';
      icon = 'icon-envelope-o';
      type = 'info';
      textHeader = 'Send Email to Bank';
      textContent = 'send email to bank';

      if (groupFail3.length)
        resultFail.push(
          <span>
            {groupFail3.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because transfer method is not : Cheque.
            <br />
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because transaction status is not : Approved.
          </span>
        );
    } else if (modal === 'export cheque') {
      selected.forEach((m) => {
        if (
          toLower(m.transferMethod) === 'cheque' &&
          toLower(m.transactionStatus) === 'approved' &&
          checkAfterValueDate(m.valueDateStr)
        )
          groupPass.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail3.push(m);
        else if (toLower(m.transferMethod) !== 'cheque') groupFail.push(m);
        else if (toLower(m.transactionStatus) !== 'approved') groupFail2.push(m);
      });

      action = 'Export';
      icon = 'icon-credit-card-alt';
      type = 'info';
      textHeader = 'Export Cheque';
      textContent = 'export cheque';

      if (groupFail3.length)
        resultFail.push(
          <span>
            {groupFail3.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because transfer method is not : Cheque.
            <br />
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because transaction status is not : Approved.
          </span>
        );
    } else if (modal === 'void cheque') {
      selected.forEach((m) => {
        if (toLower(m.transferMethod) === 'cheque' && toLower(m.transactionStatus) === 'completed')
          groupPass.push(m);
        else if (toLower(m.transferMethod) !== 'cheque') groupFail.push(m);
        else if (toLower(m.transactionStatus) !== 'completed') groupFail2.push(m);
      });

      action = 'Voided';
      icon = 'icon-refresh';
      type = 'warning';
      textHeader = 'Void Cheque';
      textContent = 'void cheque';

      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because transfer method is not : Cheque.
            <br />
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because transaction status is not : Completed.
          </span>
        );
    }

    this.setState(
      {
        modalChangeStatus: {
          ...initChangeStatus,
          action,
          groupPass,

          icon,
          type,
          textHeader,
          textContent,
          textBtn,
          textBtnBack,
          showFail: true,
          noSelect: false,
          selectedLength: selected.length,
          passLength: groupPass.length,
          resultFail,
        },
      },
      this.modalChangeStatusRef.open
    );
  };

  // - - Get Cheque - -
  onClickGetChequeHandler = (e) => {
    this.modalGetChqeueRef.open();
  };

  onChangeModalGetCheque = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      modalGetCheque: {
        ...state.modalGetCheque,
        [name]: value,
      },
    }));
  };

  onSubmitGetCheque = (e) => {
    // e.preventDefault()

    this.props.getCheque(this.state.modalGetCheque).then((response) => {
      if (!response.error) this.modalGetChqeueRef.close();
    });
  };

  // - - Upload Cheque - -
  onClickUploadHandler = () => {
    this.importChequeExcel.open();
  };

  onSubmitUploadChequeExcel = (file) => {
    const form = new FormData();
    form.append('file', file);
    this.props.importChequeExcel(form);
  };

  onSubmitSaveImportCheque = () => {
    const chequeList = this.props.chequeImportExcel.rows.filter((f) => !f.isError);

    this.props.saveImportCheque(chequeList);
  };
  // end status modal

  onChangeToggleMillion = (e) => {
    const { checked } = e.target;
    this.setState(
      {
        [e.target.name]: checked,
      },
      () => {
        this.props.editSearchResult('IsMillion', checked, null);
      }
    );
  };

  onClickOpenModalEditCheque = (e, cell) => {
    const { row } = cell.index();
    const rowData = this.props.searchResult[row];
    this.setState(
      {
        selectedRowIdx: row,
        modalEditCheque: {
          ChequeNo: rowData.chequeNo,
        },
      },
      this.modalEditChequeRef.open
    );
  };

  onChangeModalEditCheque = (e) => {
    let { name, value } = e.target;
    value = value.trim();
    if (isNaN(value) || value.includes('.')) {
      this.setState((state) => ({
        modalEditCheque: {
          ...state.modalEditCheque,
          [name]: this.state.modalEditCheque.ChequeNo,
        },
      }));
    } else {
      this.setState((state) => ({
        modalEditCheque: {
          ...state.modalEditCheque,
          [name]: value,
        },
      }));
    }
  };

  onSubmitModalEditCheque = (e) => {
    e.preventDefault();
    // if transaction status is Completed must alert
    const rowData = this.props.searchResult[this.state.selectedRowIdx];
    const submitedData = {
      ChequeNo: this.state.modalEditCheque.ChequeNo,
      TransactionNo: rowData.transactionNo,
    };
    if (isNaN(submitedData.ChequeNo)) {
      this.props.addAlert({
        title: 'Warning',
        body: 'Cheque No. must be a number!',
        type: 'warning',
      });
    } else if (rowData.transactionStatus === 'Completed') {
      this.props.addAlert({
        title: 'Warning',
        type: 'warning',
        body: 'Transaction status is Completed. Do you want to save Cheque No.?',
        buttons: [
          <button
            data-dismiss="modal"
            className="btn btn-warning btn-sm"
            onClick={(e) => this.submitEditChequeNo(submitedData)}
          >
            Confirm
          </button>,
          <button data-dismiss="modal" className="btn btn-default btn-sm">
            Cancel
          </button>,
        ],
      });
    } else this.submitEditChequeNo(submitedData);
  };

  submitEditChequeNo = (submitData) => {
    this.props.editChequeNo(submitData, this.state.criteria).then((response) => {
      this.modalEditChequeRef.close();
    });
  };

  // Change Approver
  onClickOpenChangeApprover = (e, approver) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const data = this.props.searchResult[row];
    console.log(data);
    this.setState(
      {
        modalChangeApprover: {
          TransactionNo: [data.transactionNo],
          Approver1: data.approver1,
          Approver2: data.approver2,
          FinanceGroupId: data.financeGroupId,
          Action: approver,
          Status: approver,
          Reviewer: data.reviewer,
        },
      },
      this.modalChangeRef.open
    );
  };

  onChangeModalApproveChange = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        modalChangeApprover: {
          ...this.state.modalChangeApprover,
          [name]: value,
        },
      };

      if (name === 'Approver1') {
        if (value === state.modalChangeApprover.Approver2) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select User not same Assign Approver2.',
          });
          stateToUpdate.modalChangeApprover.Approver1 = state.modalChangeApprover.Approver1;
        }
      } else if (name === 'Approver2') {
        if (value === state.modalChangeApprover.Approver1) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select User not same Assign Approver1.',
          });
          stateToUpdate.modalChangeApprover.Approver2 = state.modalChangeApprover.Approver2;
        }
      }

      return stateToUpdate;
    });
  };

  onSubmitModalChangeApprover = (e) => {
    e.preventDefault();

    this.props
      .changeStatus(
        {
          ...this.state.modalChangeApprover,
        },
        this.state.criteria
      )
      .then((response) => {
        this.modalChangeRef.close();
      });
  };

  // Change Approver Multi Transaction

  onClickOpenModalChangeApproverMultiTransaction = () => {
    const selectedTransactions = this.props.searchResult.filter((f) => f.IsSelected);
    const transactionsNotBahtnetAndSftp = selectedTransactions.filter(
      (f) => toLower(f.transferMethod) !== 'bahtnet' || toLower(f.sendingType) !== 'sftp'
    );
    let error = '';
    if (transactionsNotBahtnetAndSftp.length)
      error = 'Please selecte transaction that transfer method is Bahtnet and sendingType is SFTP';

    if (!error) {
      const transactionNotInWaitForApprover = selectedTransactions.filter(
        (f) =>
          !(f.approver1 && toLower(f.approver1Status) === 'wait for approve') &&
          !(f.approver2 && toLower(f.approver2Status) === 'wait for approve') &&
          !(f.reviewer && toLower(f.reviewerStatus) === 'wait for approve')
      );
      if (transactionNotInWaitForApprover.length)
        error = `There transaction ${transactionNotInWaitForApprover[0].transactionNo} that cannot change approver.`;
    }

    if (!error) {
      const distinctFinanceGroup = selectedTransactions
        .map((m) => m.financeGroupId)
        .filter((f, i, s) => s.findIndex((sf) => sf === f) === i);
      if (distinctFinanceGroup.length > 1) error = 'Please select same financeGroup';
    }
    if (!error && !selectedTransactions.length) {
      error = 'Please select item for change approver.';
    }
    if (!error) {
      this.setState({
        modalChangeApproverMultiTrans: {
          transactionsData: selectedTransactions,
          resultFail: [],
          Approver: '',
          ApproverType: '',
          FinanceGroupId: selectedTransactions[0].financeGroupId,
        },
      });
      this.modalChangeApproverMultiTransRef.open();
    } else {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: error,
      });
    }
  };

  onSubmitChangeApproverMultiTrans = (e) => {
    e.preventDefault();
    const { Approver, ApproverType, transactionsData } = this.state.modalChangeApproverMultiTrans;
    const submitData = {
      Approver,
      ApproverType,
      TransactionNos: transactionsData.map((m) => m.transactionNo),
    };

    this.props.changeApproverMultiTransaction(submitData, this.state.criteria).then((res) => {
      if (!res.error) {
        this.modalChangeApproverMultiTransRef.close();
      }
    });
  };

  onChangeModalApproverMultiTrans = (e) => {
    const { name, value } = e.target;
    const { transactionsData, ApproverType, Approver } = this.state.modalChangeApproverMultiTrans;
    let resultFail = [];
    switch (name) {
      case 'ApproverType':
        const key = toLower(value);
        const transactionNoApprover = transactionsData.filter(
          (f) => !(f[key] && toLower(f[`${key}Status`]) === 'wait for approve')
        );
        if (transactionNoApprover.length) {
          resultFail = transactionNoApprover.map(
            (m) => `transaction ${m.transactionNo} cannot change ${value}.`
          );
        }
        break;
      case 'Approver':
        const checkDupKey = ['Approver1', 'Approver2'];
        if (checkDupKey.includes(ApproverType)) {
          const dupicateApprover = transactionsData.filter(
            (f) => (ApproverType === 'Approver1' ? f.approver2 : f.approver1) === value
          );

          if (dupicateApprover.length)
            resultFail = dupicateApprover.map(
              (m) => `transaction ${m.transactionNo} has dupicate approver.`
            );
        }

        break;
    }

    this.setState((state) => {
      const stateToUpdate = {
        ...state.modalChangeApproverMultiTrans,
        resultFail,
      };

      if (!resultFail.length) stateToUpdate[name] = value;
      if (name === 'ApproverType') stateToUpdate.Approver = '';

      return {
        modalChangeApproverMultiTrans: stateToUpdate,
      };
    });
  };

  render() {
    const props = {
      onChangeToggleMillion: this.onChangeToggleMillion,
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      onClickUpload: this.onClickUploadHandler,

      onClickOpenModalEdit: this.onClickOpenModalEditHandler,
      onChangeModalEdit: this.onChangeModalEditHeadler,
      onClickSaveModalEdit: this.onClickSaveModalEditHandler,

      onClickColumnLabelCheckbox: this.onClickColumnLabelCheckbox,
      onClickCheckboxSelecteAll: this.onClickCheckboxSelecteAll,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      onClickPrint: this.onClickPrintHandler,

      onClickModalSubmitChangeStatus: this.onClickModalSubmitStatusHandler,
      onClickOpenModalConfirm: this.onClickOpenModalConfirmHandler,

      onChangeModalApprove: this.onChangeModalApproveHeadler,

      onClickOpenModalChangeStatus: this.onClickOpenModalChangeStatus,

      onClickGetCheque: this.onClickGetChequeHandler,
      modalGetChqeueRef: (e) => (this.modalGetChqeueRef = e),
      onChangeModalGetCheque: this.onChangeModalGetCheque,
      onSubmitGetCheque: this.onSubmitGetCheque,

      results: this.props.searchResult,

      onClickOpenModalEditCheque: this.onClickOpenModalEditCheque,
      onChangeModalEditCheque: this.onChangeModalEditCheque,
      onSubmitModalEditCheque: this.onSubmitModalEditCheque,

      onChangeModalApproveChange: this.onChangeModalApproveChange,
      onSubmitModalChangeApprover: this.onSubmitModalChangeApprover,

      onClickOpenModalChangeApproverMultiTransaction:
        this.onClickOpenModalChangeApproverMultiTransaction,
      modalChangeApproverMultiTransRef: (e) => (this.modalChangeApproverMultiTransRef = e),
      onSubmitChangeApproverMultiTrans: this.onSubmitChangeApproverMultiTrans,
      onChangeModalApproverMultiTrans: this.onChangeModalApproverMultiTrans,

      onSubmitCheckUnconfirm: this.onSubmitCheckUnconfirm,
    };
    return (
      <div>
        <BankTransferSummary
          {...props}
          {...this.state}
          modalEditRef={(e) => (this.modalEditRef = e)}
          modalConfirmRef={(e) => (this.modalConfirmRef = e)}
          modalEditChequeRef={(e) => (this.modalEditChequeRef = e)}
          modalChangeStatusData={this.state.modalChangeStatus}
          modalChangeStatusRef={(e) => (this.modalChangeStatusRef = e)}
          modalChangeRef={(e) => (this.modalChangeRef = e)}
          modalCheckUnconfirmRef={(e) => (this.modalCheckUnconfirmRef = e)}
        />
        <ImportExcel
          tableName="import-excel-cheque"
          ref={(e) => (this.importChequeExcel = e)}
          optionDt={excelDtOption}
          value={this.props.chequeImportExcel}
          onUpload={this.onSubmitUploadChequeExcel}
          onClickSaveImport={this.onSubmitSaveImportCheque}
        >
          <thead>
            <tr>
              <th>Transaction No.</th>
              <th>Applicant Code</th>
              <th>Applicant Name</th>
              <th>Beneficiary Code</th>
              <th>Beneficiary Name</th>
              <th>Value Date</th>
              <th>Applicant Bank Branch</th>
              <th>Applicant Account No.</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>Beneficiary Bank Branch</th>
              <th>Account Type</th>
              <th>Beneficiary Account No.</th>
              <th>Payment Type</th>
              <th>Issued By</th>
              <th>Cheque No.</th>
            </tr>
          </thead>
        </ImportExcel>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.bankTransferSummary,
  }),
  {
    importChequeExcel,
    addAlert,
    searchBankTransferSummary,
    changeStatus,
    changeConfirmStatus,
    setSelected,
    setSelectedAll,
    printTransferfee,
    getCheque,
    editSearchResult,
    editBanktransferSummary,
    saveImportCheque,
    editChequeNo,
    updateSearchResult,
    changeApproverMultiTransaction,
  }
)(BankTransferSummaryContainer);

const excelDtOption = {
  columns: [
    { data: 'transactionNo' },
    { data: 'applicantCode' },
    { data: 'applicantName' },
    { data: 'beneficiaryCode' },
    { data: 'beneficiaryName' },
    { data: 'valueDate' },
    { data: 'applicantBankBranch' },
    { data: 'applicantAccountNo' },
    { data: 'amount' },
    { data: 'currency' },
    { data: 'beneficiaryBankBranch' },
    { data: 'accountType' },
    { data: 'beneficiaryAccountNo' },
    { data: 'paymentType' },
    { data: 'issueBy' },
    { data: 'chequeNo' },
  ],
};
