import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import RadioButton from '../../common/RadioButton';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import { DATATABLE_ID } from './ManageFXContractContainer';
import MasterDropdownUI, { MASTER_EXPOSURE_TYPE } from '../../common/MasterDropdownUI';
import { toBank, toLower, toNumber, toStatus, getPermission } from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('FX Management', 'Manage FX Contract');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const required = true;
const $ = window.jQuery;

export default ({
  criteria,
  results,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,

  dataTableRef,
  onClickOpenImportExcel,
  onClickExport,

  modalAssignToRef,
  modalAssignTo,
  onClickOpenModalAssignTo,
  onChangeModalAssignTo,
  onClickSubmitModalAssignTo,
  onClickRollOver,
  onClickUnwind,

  modalUploadFile,
  modalUploadFileRef,
  onClickOpenModalUploadFile,
  onChangeModalUploadFile,
  onSubmitSaveUploadFile,
  modalDeleteFileRef,
  onChangeAttachFile,
  inputAttachfileRef,
  onDrawDataTableDetail,
  onDrawDataTableProduct,
  onDrawDataTableSummary,

  modalUploadContractRef,
  onSubmitSaveUploadContractFile,
  onChangeAttacheContractFile,
  inputAttacheContractfileRef,

  onClickDownloadAttachFile,
  onClickDeleteAttachForwardContract,
  onClickConfirmDeleteAttachForwardContract,

  onClickFetchForward,

  modalActiveRef,
  modalInactiveRef,
  onClickOpenModalActiveInactive,
  onClickSubmitModalActiveInactive,

  modalCancelRolloverUnwindRef,
  onClickSubmitCancelRolloverUnwind,
}) => {
  const setProduct = criteria.SearchType === 'Product (Avg.)';
  if (!setProduct) {
    criteria.ProductAvg = '';
  }

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" /> Manage FX Contract
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  // isChoose={true}
                  required={required}
                  value={criteria.FinanceGroup}
                  name="FinanceGroup"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  name="Companies"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroup}
                  businessUnitId={criteria.BusinessUnits}
                />
              </FormGroup2>

              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.Banks}
                  companyCode={criteria.Companies}
                  name="Banks"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Product Type">
                <MasterDropdownUI
                  value={criteria.ProductType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="ProductType"
                  options={['Forward Main', 'Forward Special']}
                />
              </FormGroup2>

              <FormGroup2 text="Transaction Type">
                <MasterDropdownUI
                  value={criteria.TransactionType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="TransactionType"
                  options={['Buy', 'Sell']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Forward Type">
                <MasterDropdownUI
                  value={criteria.ForwardType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="ForwardType"
                  options={['Fixed', 'Par', 'Pro Rata']}
                />
              </FormGroup2>

              <FormGroup2 text="Purpose">
                <MasterDropdownUI
                  masterType={MASTER_EXPOSURE_TYPE}
                  value={criteria.Purposes}
                  onChange={onChangeSelect2}
                  name="Purposes"
                  options={null}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Contract Number">
                <input
                  className="form-control"
                  value={criteria.ContractNo}
                  onChange={onChangeSearch}
                  name="ContractNo"
                />
              </FormGroup2>

              <FormGroup2 text="Contract Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.ContractDateStr}
                  name="ContractDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Start Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.StartDateStr}
                  name="StartDateStr"
                />
              </FormGroup2>

              <FormGroup2 text="End Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.EndDateStr}
                  name="EndDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Forward Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.ForwardCurrency}
                  name="ForwardCurrency"
                />
              </FormGroup2>

              <FormGroup2 text="Book Bank Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.BookBankCurrency}
                  name="BookBankCurrency"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Dollar Linked">
                <MasterDropdownUI
                  value={criteria.DollarLinked ? 'Yes' : criteria.DollarLinked === null ? '' : 'No'}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="DollarLinked"
                  options={['Yes', 'No']}
                />
              </FormGroup2>

              <FormGroup2 text="Request Type">
                <MasterDropdownUI
                  value={criteria.RequestType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="RequestType"
                  options={['With Underlying', 'Without Underlying', 'Loan']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Search Type" required>
                <MasterDropdownUI
                  value={criteria.SearchType}
                  onChange={onChangeSearch}
                  notMultiple
                  isChoose
                  required
                  name="SearchType"
                  options={['Summary', 'Detail', 'Product (Avg.)']}
                />
              </FormGroup2>

              <FormGroup2 text="Report as of">
                <DatePicker
                  onChange={onChangeSearch}
                  value={criteria.ReportDateStr}
                  name="ReportDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  name="Status"
                >
                  <option value="">---All---</option>
                  <option value="Active">Active</option>
                  <option value="InActive">Inactive</option>
                  <option value="Wait for Contract No">Wait for Contract No</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </FormGroup2>

              <FormGroup2 text="Transaction No.">
                <input
                  className="form-control"
                  value={criteria.TransactionNo}
                  onChange={onChangeSearch}
                  name="TransactionNo"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Assign To">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.AssignTo}
                  name="AssignTo"
                />
              </FormGroup2>

              <FormGroup2 text="Value Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                />
              </FormGroup2>
            </Col2>

            {setProduct ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Product (AVG.)" required>
                  <MasterDropdownUI
                    value={criteria.Product}
                    onChange={onChangeSearch}
                    notMultiple
                    isChoose
                    required={required}
                    name="Product"
                    options={['Forward Utilize', 'Forward Rollover', 'Forward Unwind']}
                  />
                </FormGroup2>
              </Col2>
            ) : null}
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="UPLOAD EXCEL FORWARD CONTRACT"
                    icon="upload"
                    className="btn-success"
                    onClick={onClickOpenImportExcel}
                  />
                  &nbsp;
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/fx-management/forward-detail"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    CREATE
                  </Link>
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card
        textHeader={`${criteria.modeResult || 'Summary'}`}
        number="2"
        bgHeader="bg-primary"
        cardActions={['toggler']}
      >
        {permission ? (
          <div className="row">
            <div className="col-sm-6">
              <Button
                txt="Export"
                icon="credit-card-alt"
                className="btn-default"
                onClick={onClickExport}
              />
              &nbsp;
              <Button
                txt="Rollover"
                icon="mail-reply-all"
                className="btn-success"
                onClick={onClickRollOver}
              />
              &nbsp;
              <Button
                txt="Unwind"
                icon="stop-circle"
                className="btn-warning"
                onClick={onClickUnwind}
              />
            </div>

            <div className="col-sm-6 text-right">
              <Button
                txt="Fetch Forward"
                icon="undo"
                className="btn-info"
                onClick={onClickFetchForward}
              />
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-sm-6">
              <Button
                txt="Export"
                icon="credit-card-alt"
                className="btn-default"
                onClick={onClickExport}
              />
            </div>
          </div>
        )}
        {criteria.modeResult === 'Summary' || criteria.modeResult === '' ? (
          <Table
            key="summary"
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={opt}
            value={results}
            // drawChild={onDrawDataTableSummary}
            onClickContext={[
              {
                context: 'a.assign-to-comp',
                onClick: onClickOpenModalAssignTo,
              },
              {
                context: 'button.upload-file',
                onClick: onClickOpenModalUploadFile,
              },
            ]}
          >
            <thead>
              <tr>
                <th>
                  Choose <label className="label-checkbox fix choose-all" />
                </th>
                <th>Action</th>
                <th>Transaction No.</th>
                <th>
                  Finance
                  <br /> Group
                </th>
                <th>
                  Business
                  <br /> Unit
                </th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>
                  Company
                  <br /> Name
                </th>
                <th>Product Type</th>
                <th>Transaction Type</th>
                <th>Bank</th>
                <th>Forward Contract No.</th>
                <th>Rollover Reference</th>
                <th>Contract Date</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Forward Type</th>
                <th>
                  Forward
                  <br /> Currency
                </th>
                <th>
                  Book Bank
                  <br /> Currency
                </th>
                <th className="text-center">Original Amount</th>
                <th className="text-center">Outstanding</th>
                <th className="text-center">Spot Rate</th>
                <th className="text-center">Swap Rate</th>
                <th className="text-center">Base Rate</th>
                <th className="text-center">Forward Rate</th>
                <th className="text-center">
                  Settlement Rate
                  <br /> (Contract Rate)
                </th>
                <th>Value Date</th>
                <th>Payment Date</th>
                <th>Purpose</th>
                <th>Purpose Detail</th>
                <th>Status</th>
                <th>Business Area</th>
                <th>Dollar Linked</th>
                <th>Internal Ref No</th>
                <th>Request Type</th>
                <th>Contract Detail</th>
                <th>Dealer</th>
                <th>Department</th>
                <th>Status File</th>
                <th>Note 1</th>
                <th>Note 2</th>
                <th>Assign To</th>
                <th>Upload File Name</th>
                <th>History</th>
              </tr>
            </thead>
          </Table>
        ) : criteria.modeResult === 'Product (Avg.)' ? (
          <Table
            key="product"
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={optProduct}
            value={results}
            drawChild={onDrawDataTableProduct}
          >
            <thead>
              <tr>
                {/* <th>Choose <label className="label-checkbox fix choose-all" ></label></th> */}
                <th>Transaction No.</th>
                <th>
                  Finance
                  <br /> Group
                </th>
                <th>
                  Business
                  <br /> Unit
                </th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>
                  Company
                  <br /> Name
                </th>
                <th>Product Type</th>
                <th>Exposure No.</th>
                <th>Transaction Type</th>
                <th>Bank</th>
                <th>Forward Contract No.</th>
                <th>Rollover Reference</th>
                <th>Contract Date</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Forward Type</th>
                <th>
                  Forward
                  <br /> Currency
                </th>
                <th>
                  Book Bank
                  <br /> Currency
                </th>
                <th>Original Amount</th>
                <th>Spot Rate</th>
                <th>Swap Rate</th>
                <th>Base Rate</th>
                <th>Forward Rate</th>
                <th>
                  Settlement Rate
                  <br /> (Contract Rate)
                </th>
                <th>Value Date</th>
                <th>Payment Date</th>
                <th>Gain/Loss</th>
                <th>
                  Gain/Loss
                  <br /> Currency
                </th>
                <th>Purpose</th>
                <th>Purpose Detail</th>
                <th>Status</th>
                <th>Business Area</th>
                <th>Dollar Linked</th>
                <th>Internal Ref No</th>
                <th>Request Type</th>
                <th>Contract Detail</th>
                <th>Dealer</th>
                <th>Department</th>
                <th>Status File</th>
                <th>Note 1</th>
                <th>Note 2</th>
                <th>Assign To</th>
                <th>
                  Cancel
                  <br />
                  Unwind
                </th>
              </tr>
            </thead>
          </Table>
        ) : (
          <Table
            key="detail"
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={optDetail}
            value={results}
            drawChild={onDrawDataTableDetail}
          >
            <thead>
              <tr>
                <th>
                  Choose <label className="label-checkbox fix choose-all" />
                </th>
                <th>Transaction No.</th>
                <th>
                  Finance
                  <br /> Group
                </th>
                <th>
                  Business
                  <br /> Unit
                </th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>
                  Company
                  <br /> Name
                </th>
                <th>Product Type</th>
                <th>Exposure No.</th>
                <th>Req Pmt Ref</th>
                <th>Transaction Type</th>
                <th>Bank</th>
                <th>Forward Contract No.</th>
                <th>Rollover Reference</th>
                <th>Contract Date</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Forward Type</th>
                <th>
                  Forward
                  <br /> Currency
                </th>
                <th>
                  Book Bank
                  <br /> Currency
                </th>
                <th>Original Amount</th>
                <th>Spot Rate</th>
                <th>Swap Rate</th>
                <th>Base Rate</th>
                <th>Forward Rate</th>
                <th>
                  Settlement Rate
                  <br /> (Contract Rate)
                </th>
                <th>Value Date</th>
                <th>Payment Date</th>
                <th>Gain/Loss</th>
                <th>
                  Gain/Loss
                  <br /> Currency
                </th>
                <th>Purpose</th>
                <th>Purpose Detail</th>
                <th>Status</th>
                <th>Business Area</th>
                <th>Dollar Linked</th>
                <th>Internal Ref No</th>
                <th>Request Type</th>
                <th>Contract Detail</th>
                <th>Dealer</th>
                <th>Department</th>
                <th>Status File</th>
                <th>Note 1</th>
                <th>Note 2</th>
                <th>Assign To</th>
                <th>
                  Cancel
                  <br />
                  Unwind
                </th>
              </tr>
            </thead>
          </Table>
        )}
      </Card>

      <Modal size="modal-md" ref={modalAssignToRef} modalFooter>
        <form onSubmit={onClickSubmitModalAssignTo}>
          <div className="text-center">
            <h2 className="text-info">Assign To Company</h2>
            <br />
            <div className="row form-group">
              <label className="control-label col-md-4">
                Assign To<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <div className="col-md-8 text-left">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  isChoose
                  notMultipleSelect2
                  value={modalAssignTo.AssignTo}
                  onChange={(e) =>
                    onChangeModalAssignTo({
                      target: e,
                    })
                  }
                  required
                  name="AssignTo"
                />
              </div>
            </div>
            <div className="m-t-lg">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        size="modal-lg"
        textHeader="Upload File"
        bgHeader="bg-primary"
        ref={modalUploadFileRef}
      >
        {permission ? (
          <Card textHeader="Upload File" cardActions={['toggler']}>
            <form onSubmit={onSubmitSaveUploadFile} className="form-horizontal">
              <Col2 col="col-md-6">
                <div className="form-group">
                  <label className="control-label col-sm-4 col-md-4">
                    Upload Type<span style={{ color: '#e64a19' }}>*</span>
                  </label>
                  <div className="col-sm-8 col-md-8">
                    <RadioButton
                      checked={modalUploadFile.UploadType === 'Attach & Replace'}
                      text="Attach & Replace"
                      value="Attach & Replace"
                      onChange={onChangeModalUploadFile}
                      name="UploadType"
                      disabled={!modalUploadFile.CanReplace}
                      required
                    />
                    <RadioButton
                      checked={modalUploadFile.UploadType === 'Attach'}
                      text="Attach"
                      value="Attach"
                      onChange={onChangeModalUploadFile}
                      name="UploadType"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="control-label col-sm-4 col-md-4">
                    Select File<span style={{ color: '#e64a19' }}>*</span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="file"
                      name="File"
                      className="form-control"
                      onChange={onChangeAttachFile}
                      ref={inputAttachfileRef}
                    />
                  </div>
                </div>
              </Col2>
              <Col2 col="col-md-6">
                <div className="form-group">
                  <label className="control-label col-sm-4 col-md-4">Description</label>
                  <div className="col-sm-8 col-md-8">
                    <textarea
                      className="form-control"
                      rows="3"
                      value={modalUploadFile.Description}
                      onChange={onChangeModalUploadFile}
                      name="Description"
                    />
                  </div>
                </div>
              </Col2>

              <Col2 col="col-md-12">
                <center>
                  <Button txt="Save" className="btn-success" icon="save" type="submit" />
                </center>
              </Col2>
            </form>
          </Card>
        ) : (
          <div ref={inputAttachfileRef} />
        )}

        <Card textHeader="History File" cardActions={['toggler']}>
          <div className="table-responsive">
            <table className="table table-bordered table-nowrap">
              <thead style={{ color: 'white', backgroundColor: '#44B6AE' }}>
                <tr>
                  <th align="center">Action</th>
                  <th align="center">File Name</th>
                  <th align="center">Description</th>
                  <th align="center">Export</th>
                  <th align="center">Status</th>
                </tr>
              </thead>
              <tbody>
                {modalUploadFile.Historys.map((m, i) => (
                  <tr key={i}>
                    <td align="center">
                      {m.isActive ? null : (
                        <button
                          className="btn btn-warning btn-icon"
                          title="Export"
                          type="button"
                          onClick={(e) => onClickDeleteAttachForwardContract(m.fileName)}
                        >
                          <span className="icon icon-remove sq-24" />
                        </button>
                      )}
                    </td>
                    <td>{m.fileName}</td>
                    <td>{m.description}</td>
                    <td align="center">
                      <button
                        className="btn btn-primary btn-icon"
                        title="Export"
                        type="button"
                        onClick={(e) => onClickDownloadAttachFile(m.fileName)}
                      >
                        <span className="icon icon-file-excel-o sq-24" />
                      </button>
                    </td>
                    <td align="center">
                      {m.isActive ? (
                        <span className="label label-success">Active</span>
                      ) : (
                        <span className="label label-warning">InActive</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </Modal>
      <Modal
        size="modal-lg"
        textHeader="Upload Contract File"
        bgHeader="bg-primary"
        ref={modalUploadContractRef}
      >
        <Card textHeader="Upload File" cardActions={['toggler']}>
          <form onSubmit={onSubmitSaveUploadContractFile} className="form-horizontal">
            <Col2 col="col-md-6">
              <div className="form-group">
                <label className="control-label col-sm-4 col-md-4">
                  Select File<span style={{ color: '#e64a19' }}>*</span>
                </label>
                <div className="col-sm-8">
                  <input
                    type="file"
                    name="File"
                    className="form-control"
                    onChange={onChangeAttacheContractFile}
                    ref={inputAttacheContractfileRef}
                  />
                </div>
              </div>
            </Col2>
            <Col2 col="col-md-12">
              <center>
                <Button txt="Save" className="btn-success" icon="save" type="submit" />
              </center>
            </Col2>
          </form>
        </Card>
      </Modal>

      <ModalFunction
        textHeader="Delete"
        textContent="Delete"
        modalRef={modalDeleteFileRef}
        type="danger"
        icon="icon-trash-o"
        textBtn="Delete"
        noSelect
        onClick={onClickConfirmDeleteAttachForwardContract}
      />

      <ModalFunction
        textHeader="Active"
        textContent="an Active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onClickSubmitModalActiveInactive(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="a Inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onClickSubmitModalActiveInactive(e, 'Inactive')}
      />

      <ModalFunction
        textHeader="Cancel Unwind"
        textContent="unwind"
        modalRef={modalCancelRolloverUnwindRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={onClickSubmitCancelRolloverUnwind}
      />
    </div>
  );
};

const opt = {
  deferRender: true,
  scroller: true,
  paging: true,
  // colReorder: false,
  // ordering: false,
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''} /><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'canChangeStatus',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (r.amount !== r.outstanding) return '';
        if (d) {
          if (toLower(r.status) === 'cancelled')
            return `<button class="btn btn-icon btn-info fx-active"><span class="icon icon-refresh sq-24"></span></button>`;
          return '<button class="btn btn-icon btn-warning fx-inactive"><span class="icon icon-close sq-24"></span></button>';
        }
        return '';
      },
    },
    {
      data: 'forwardNo',
      render: (d) =>
        `<a target="_blank" href="/fx-management/forward-detail/${d}" class="edit-forward-detail"> ${d} </a>`,
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'productType', className: 'text-center' },
    { data: 'transactionType', className: 'text-center' },
    {
      data: 'bankAbbreviate',
      render: (d, r, s) => toBank(d, s.bankName),
    },
    { data: 'contractNo' },
    {
      data: 'rolloverRef',
      className: 'text-center',
      render: (d, r, s) => {
        if (d)
          return `<a target="_blank" href="/fx-management/forward-detail/${d}" class="btn btn-info-reverse">${s.rolloverRef2}</button>`;
        return '';
      },
    },
    { data: 'contractDateStr', className: 'text-center', type: 'date-black' },
    { data: 'startDateStr', className: 'text-center', type: 'date-black' },
    { data: 'endDateStr', className: 'text-center', type: 'date-black' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'bookBankCurrency', className: 'text-center' },
    { data: 'amount', className: 'text-right', render: (d, t, r) => toNumber(d, r.currencyDigit) },
    {
      data: 'outstanding',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'spotRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'swapRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'baseRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'forwardRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'settlementRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'paymentDateStr', className: 'text-center', type: 'date-black' },
    { data: 'purpose' },
    { data: 'purposeDetail' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    {
      data: 'businessArea',
      render: (d, t, r) => {
        if (!d) return '';
        return r.businessAreaName;
      },
    },
    { data: 'dollarLinked', className: 'text-center', render: (d) => (d ? 'Yes' : 'No') },
    { data: 'internalRefNo' },
    { data: 'requestType' },
    { data: 'contractDetail' },
    { data: 'dealer' },
    { data: 'department' },
    { data: 'statusFile', className: 'text-center' },
    { data: 'note1' },
    { data: 'note2' },
    {
      data: 'assignTo',
      render: (d, t, r) => {
        if (toLower(r.status) === 'inactive' || !permission) {
          if (d) return `${d} | ${r.assignToName}`;
          return '';
        }
        if (r.outstanding !== r.amount) {
          if (d) return `${d} | ${r.assignToName}`;
          return '';
        }
        if (d) return `<a href="#" class="assign-to-comp">${d} | ${r.assignToName}</a>`;
        if (r.productType !== 'Forward Special') return '';
        return '<center><a class="btn btn-icon btn-info assign-to-comp"><span class="icon icon-edit sq-24"></span></a></center>';
      },
    },
    {
      data: 'fileName',
      className: 'text-center',
      render: (d, r, s) => {
        if (!d) return '';
        // if (!permission) return d
        return `<button class="btn btn-info-reverse upload-file">${d}</button>`;
      },
    },
    {
      data: 'forwardNo',
      className: 'text-center',
      render: (d, t, r) =>
        `<a class="btn btn-icon btn-info" href="/fx-management/rolloverUnwindHistory/${d}" target="_blank"><span class="icon icon-file-text sq-24"></span></a>`,
    },
  ],
  drawCallback: () => {
    setTimeout(() => {
      $(window).trigger('resize');
    }, 1000);
  },
};

const optDetail = {
  colReorder: false,
  ordering: false,
  rowGroup: {
    startRender: null,
    endRender(rows, group) {
      const $tr = $('<tr />');
      const datas = rows.data().toArray();
      let totalAmount = 0;
      let forwardNo;
      let financeGroupName;
      let companyCode;
      let companyName;
      let businessUnitName;
      datas.forEach((m, i) => {
        forwardNo = m.forwardNo ? m.forwardNo : forwardNo;
        financeGroupName = m.financeGroupName ? m.financeGroupName : financeGroupName;
        companyCode = m.companyCode ? m.companyCode : companyCode;
        companyName = m.companyName ? m.companyName : companyName;
        businessUnitName = m.businessUnitName ? m.businessUnitName : businessUnitName;
        totalAmount += m.amount || 0;
      });
      $tr
        .append(`<td></td>`)
        .append(`<td>${forwardNo}</td>`)
        .append(`<td>${financeGroupName}</td>`)
        .append(`<td>${businessUnitName || ''}</td>`)
        .append(`<td align="center">${companyCode}</td>`)
        .append(`<td>${companyName}</td>`)
        .append(`<td align="center">Total</td>`)
        .append(`<td colspan="12"></td>`)
        .append(`<td align="right">${toNumber(totalAmount, 3)}</td>`)
        .append(`<td colspan="24"></td>`);
      return $tr;
    },
    dataSrc: 'forwardNo',
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        if (r.productType === 'Forward Main' || r.productType === 'Forward Special') {
          r.canSelect = true;
          return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''} /><label class="label_checkbox"></label>`;
        }
        r.canSelect = false;
        return '';
      },
    },
    {
      data: 'forwardNo',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special'
          ? `<a target="_blank" href="/fx-management/forward-detail/${d}" class="edit-forward-detail"> ${d} </a>`
          : `${d}`,
    },
    {
      data: 'financeGroupName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'businessUnitName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'companyCode',
      className: 'text-center',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'companyName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    { data: 'productType', className: 'text-center' },
    { data: 'exposureNo' },
    { data: 'reqPmtRef' },
    {
      data: 'transactionType',
      className: 'text-center',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'bankAbbreviate',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special'
          ? toBank(d, r.bankName)
          : toBank(d, r.bankName),
    },
    { data: 'contractNo' },
    {
      data: 'rolloverRef',
      className: 'text-center',
      render: (d, r, s) => {
        if (d)
          return `<a target="_blank" href="/fx-management/forward-detail/${d}" class="btn btn-info-reverse">${s.rolloverRef2}</button>`;
        return '';
      },
    },
    { data: 'contractDateStr', className: 'text-center', type: 'date-black' },
    { data: 'startDateStr', className: 'text-center', type: 'date-black' },
    { data: 'endDateStr', className: 'text-center', type: 'date-black' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'bookBankCurrency', className: 'text-center' },
    { data: 'amount', className: 'text-right', render: (d, t, r) => toNumber(d, r.currencyDigit) },
    { data: 'spotRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'swapRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'baseRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'forwardRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'settlementRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'paymentDateStr', className: 'text-center', type: 'date-black' },
    {
      data: 'gainLoss',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'gainLossCurrency', className: 'text-center' },
    { data: 'purpose' },
    { data: 'purposeDetail' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    {
      data: 'businessArea',
      render: (d, t, r) => {
        if (!d) return '';
        return r.businessAreaName;
      },
    },
    {
      data: 'dollarLinked',
      className: 'text-center',
      render: (d, t, r) => {
        if (r.productType === 'Forward Main' || r.productType === 'Forward Special')
          return d ? 'Yes' : !d ? 'No' : '';
        return d ? 'Yes' : !d ? 'No' : '';
      },
    },
    { data: 'internalRefNo' },
    { data: 'requestType' },
    { data: 'contractDetail' },
    { data: 'dealer' },
    { data: 'department' },
    { data: 'statusFile', className: 'text-center' },
    { data: 'note1' },
    { data: 'note2' },
    {
      data: 'assignTo',
      render: (d, t, r) => {
        if (d) return `${d} | ${r.assignToName}`;
        return '';
      },
    },
    {
      data: null,
      className: 'text-center',
      render: (d, t, r) => {
        if (!r.gainLossNo || r.productType === 'Rollover') return '';
        return '<button class="btn btn-warning btn-sm toggle-status-unwind"><i class="icon icon-close icon-fw icon-lg"></i></button>';
      },
    },
  ],
  drawCallback: () => {
    setTimeout(() => {
      $(window).trigger('resize');
    }, 1000);
  },
};

const optProduct = {
  order: [
    [4, 'asc'],
    [1, 'asc'],
  ],
  colReorder: false,
  ordering: false,
  rowGroup: {
    startRender: null,
    endRender(rows, group) {
      const $tr = $('<tr />');
      const datas = rows.data().toArray();
      let totalAmount = 0;
      let totalSpotMultiple = 0;
      let totalSettlementMultiple = 0;
      let forwardNo;
      let financeGroupName;
      let companyCode;
      let companyName;
      let businessUnitName;
      let currencyDigit = 0;
      datas.forEach((m, i) => {
        forwardNo = m.forwardNo ? m.forwardNo : forwardNo;
        financeGroupName = m.financeGroupName ? m.financeGroupName : financeGroupName;
        companyCode = m.companyCode ? m.companyCode : companyCode;
        companyName = m.companyName ? m.companyName : companyName;
        businessUnitName = m.businessUnitName ? m.businessUnitName : businessUnitName;
        if (
          toLower(m.productType) !== 'forward main' &&
          toLower(m.productType) !== 'forward special'
        ) {
          totalAmount += Math.abs(m.amount || 0);
          totalSpotMultiple += (m.spotRate || 0) * Math.abs(m.amount || 0);
          totalSettlementMultiple += (m.settlementRate || 0) * Math.abs(m.amount || 0);
          currencyDigit = m.currencyDigit;
        }
      });
      $tr // .append(`<td></td>`)
        .append(`<td>${forwardNo}</td>`)
        .append(`<td>${financeGroupName}</td>`)
        .append(`<td>${businessUnitName || ''}</td>`)
        .append(`<td align="center">${companyCode}</td>`)
        .append(`<td>${companyName}</td>`)
        .append(`<td align="center">Total</td>`)
        .append(`<td colspan="11"></td>`)
        .append(`<td align="right">${toNumber(totalAmount, currencyDigit)}</td>`)
        .append(`<td align="right">${toNumber(totalSpotMultiple / totalAmount, 8)}</td>`)
        .append(`<td colspan="3"></td>`)
        .append(`<td align="right">${toNumber(totalSettlementMultiple / totalAmount, 8)}</td>`)
        .append(`<td colspan="19"></td>`);

      return $tr;
    },
    dataSrc: 'forwardNo',
  },
  // rowCallback: (tr, r, idx, cells) => {
  //     if (r.IsSelected)
  //         tr.classList.add("checked-row")
  //     else
  //         tr.classList.remove("checked-row")
  // },
  columns: [
    // {
    //     data: 'IsSelected',
    //     className: `text-center col-label-checkbox`,
    //     render: (d, t, r) => {
    //         if (r.productType === 'Forward Main' || r.productType === 'Forward Special') {
    //             r.canSelect = true
    //             return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''} /><label class="label_checkbox"></label>`
    //         }
    //         r.canSelect = false;
    //         return '';
    //     },
    // },
    {
      data: 'forwardNo',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special'
          ? `<a target="_blank" href="/fx-management/forward-detail/${d}" class="edit-forward-detail"> ${d} </a>`
          : `${d}`,
    },
    {
      data: 'financeGroupName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'businessUnitName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'companyCode',
      className: 'text-center',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'companyName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    { data: 'productType', className: 'text-center' },
    { data: 'exposureNo' },
    {
      data: 'transactionType',
      className: 'text-center',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'bankAbbreviate',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special'
          ? toBank(d, r.bankName)
          : toBank(d, r.bankName),
    },
    { data: 'contractNo' },
    {
      data: 'rolloverRef',
      className: 'text-center',
      render: (d, r, s) => {
        if (d)
          return `<a target="_blank" href="/fx-management/forward-detail/${d}" class="btn btn-info-reverse">${s.rolloverRef2}</a>`;
        return '';
      },
    },
    { data: 'contractDateStr', className: 'text-center', type: 'date-black' },
    { data: 'startDateStr', className: 'text-center', type: 'date-black' },
    { data: 'endDateStr', className: 'text-center', type: 'date-black' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'bookBankCurrency', className: 'text-center' },
    { data: 'amount', className: 'text-right', render: (d, t, r) => toNumber(d, r.currencyDigit) },
    { data: 'spotRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'swapRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'baseRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'forwardRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'settlementRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'paymentDateStr', className: 'text-center', type: 'date-black' },
    {
      data: 'gainLoss',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'gainLossCurrency', className: 'text-center' },
    { data: 'purpose' },
    { data: 'purposeDetail' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    {
      data: 'businessArea',
      render: (d, t, r) => {
        if (!d) return '';
        return r.businessAreaName;
      },
    },
    {
      data: 'dollarLinked',
      className: 'text-center',
      render: (d, t, r) => {
        if (r.productType === 'Forward Main' || r.productType === 'Forward Special')
          return d ? 'Yes' : !d ? 'No' : '';
        return d ? 'Yes' : !d ? 'No' : '';
      },
    },
    { data: 'internalRefNo' },
    { data: 'requestType' },
    { data: 'contractDetail' },
    { data: 'dealer' },
    { data: 'department' },
    { data: 'statusFile', className: 'text-center' },
    { data: 'note1' },
    { data: 'note2' },
    {
      data: 'assignTo',
      render: (d, t, r) => {
        if (d) return `${d} | ${r.assignToName}`;
        return '';
      },
    },
    {
      data: null,
      className: 'text-center',
      render: (d, t, r) => {
        if (!r.gainLossNo || r.productType === 'Rollover') return '';
        return '<button class="btn btn-warning btn-sm toggle-status-unwind"><i class="icon icon-close icon-fw icon-lg"></i></button>';
      },
    },
  ],
  drawCallback: () => {
    setTimeout(() => {
      $(window).trigger('resize');
    }, 1000);
  },
};
