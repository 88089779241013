import React, { Fragment } from 'react';

import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import RadioButton from '../../../common/RadioButton';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { MASTER_BANK_ACCOUNT } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ state, modalRef, onChangeInputData, onSubmit }) => {
  const reqWithdrawAll = state.IsWithdrawAll;
  const reqRollOver = !state.IsWithdrawAll;
  const reqOncall = state.DepositType !== 'On Call' && !state.IsWithdrawAll;
  const enableTTAmount = ~['Bahtnet', 'T/T'].indexOf(state.PaymentMethodPrincipal || '');
  const isIntercompany = state.PartnerType === 'Intercompany';

  const newDepositAmount = calNewDepositAmount(state);
  return (
    <Modal textHeader="Withdraw" bgHeader="bg-primary" size="modal-lg" ref={modalRef}>
      <form onSubmit={onSubmit}>
        <Col2 col={colX[0]}>
          <div className="form-group">
            <div>
              <input
                id="IsWithdrawAll"
                type="checkbox"
                className="label-checkbox"
                onChange={onChangeInputData}
                name="IsWithdrawAll"
                checked={state.IsWithdrawAll}
              />
              <label htmlFor="IsWithdrawAll" className="label_checkbox" />
              <label htmlFor="IsWithdrawAll" className="control-label text_label_checkbox">
                Withdraw All
              </label>
            </div>
          </div>
          <FormGroup2 text="Withdraw Date" required={reqWithdrawAll}>
            <DatePicker
              onChange={onChangeInputData}
              option={{
                todayHighlight: true,
              }}
              required={reqWithdrawAll}
              disabled={!reqWithdrawAll}
              value={state.WithdrawDateStr}
              name="WithdrawDateStr"
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[0]}>
          <FormGroup2 text="Deposit Type" required={reqRollOver}>
            <div>
              <RadioButton
                text="On Call"
                value="On Call"
                checked={state.DepositType === 'On Call'}
                onChange={onChangeInputData}
                name="DepositType"
                disabled={!reqRollOver}
                required={reqRollOver}
              />
              <RadioButton
                text="Term"
                value="Term"
                checked={state.DepositType === 'Term'}
                onChange={onChangeInputData}
                disabled={!reqRollOver}
                name="DepositType"
              />
            </div>
          </FormGroup2>
          <FormGroup2 text="Rollover Date / Term Start" required={reqRollOver}>
            <DatePicker
              onChange={onChangeInputData}
              option={{
                todayHighlight: true,
              }}
              required={reqRollOver}
              disabled={!reqRollOver}
              value={state.RolloverDateStr}
              name="RolloverDateStr"
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <div>
              <FormGroup2 text="Existing Deposit Amount" required={reqRollOver}>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={
                    state.inputMaskAmount || {
                      prefix: '',
                      digits: 2,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }
                  }
                  required={reqRollOver}
                  disabled
                  value={state.Amount}
                  name="Amount"
                />
              </FormGroup2>

              <FormGroup2 text="Interest (%)" required={reqRollOver}>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={{
                    prefix: '',
                    digits: 5,
                    digitsOptional: false,
                    placeholder: '0.00000',
                  }}
                  required
                  disabled={false}
                  value={state.Interest}
                  name="Interest"
                />
              </FormGroup2>

              <FormGroup2 text="Interest Amount" required={reqRollOver}>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={
                    state.inputMaskAmount || {
                      prefix: '',
                      digits: 2,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }
                  }
                  required={reqRollOver}
                  disabled
                  value={state.InterestAmount}
                  name="InterestAmount"
                />
              </FormGroup2>

              <FormGroup2 text="Withholding Tax" required={reqRollOver}>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={
                    state.inputMaskAmount || {
                      prefix: '',
                      digits: 2,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }
                  }
                  required={reqRollOver}
                  disabled={false}
                  value={state.WithholdingTax}
                  name="WithholdingTax"
                />
              </FormGroup2>

              <div className="form-group">
                <div>
                  <input
                    id="IsIncluding"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeInputData}
                    name="IsIncluding"
                    checked={state.IsIncluding}
                    disabled={!reqRollOver || state.IsThaiSweep}
                  />
                  <label htmlFor="IsIncluding" className="label_checkbox" />
                  <label htmlFor="IsIncluding" className="control-label text_label_checkbox">
                    Including Interest From Last Period
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label" style={{ marginRight: '10px' }}>
                  Increase/Decrease
                </label>
                <label className="custom-label">
                  <div>
                    <RadioButton
                      text="Increase"
                      value="Increase"
                      checked={state.IncreaseDecrease === 'Increase'}
                      onChange={onChangeInputData}
                      name="IncreaseDecrease"
                      disabled={!reqRollOver}
                    />
                    <RadioButton
                      text="Decrease"
                      value="Decrease"
                      checked={state.IncreaseDecrease === 'Decrease'}
                      onChange={onChangeInputData}
                      disabled={!reqRollOver}
                      name="IncreaseDecrease"
                    />
                  </div>
                </label>
                <div className="form-group">
                  <InputMask
                    className="form-control"
                    format="currency"
                    onChange={onChangeInputData}
                    option={
                      state.inputMaskAmount || {
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }
                    }
                    required={reqRollOver}
                    disabled={!state.IncreaseDecrease}
                    value={state.IncreaseDecreaseAmount}
                    name="IncreaseDecreaseAmount"
                  />
                </div>
              </div>

              <FormGroup2 text="New Deposit Amount" required={reqRollOver}>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={
                    state.inputMaskAmount || {
                      prefix: '',
                      digits: 2,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }
                  }
                  required={reqRollOver}
                  disabled
                  value={newDepositAmount}
                />
              </FormGroup2>
            </div>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              {renderTermend()}
              {renderTTInterest()}
              <hr />
              {renderTTPrincipal()}
              {renderAdditionFee()}
            </div>
          </Col2>
        </Col2>

        <div className="row">
          <div className="col-sm-12 text-center">
            <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
          </div>
        </div>
      </form>
    </Modal>
  );

  function renderTermend() {
    return (
      <FormGroup2 text="Term End" required={reqOncall}>
        <DatePicker
          onChange={onChangeInputData}
          option={{
            // daysOfWeekDisabled: "0,6",
            todayHighlight: true,
          }}
          required={reqOncall}
          disabled={!reqOncall}
          value={state.TermEndStr}
          name="TermEndStr"
        />
      </FormGroup2>
    );
  }

  function renderAdditionFee() {
    return (
      <>
        <FormGroup2 text="Stamp Duty Fee" required>
          <InputMask
            className="form-control"
            format="currency"
            onChange={onChangeInputData}
            option={
              state.inputMaskAmount || {
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }
            }
            required
            disabled={false}
            value={state.StampDutyFee}
            name="StampDutyFee"
          />
        </FormGroup2>
        <FormGroup2 text="Bank Charge" required>
          <InputMask
            className="form-control"
            format="currency"
            option={
              state.inputMaskAmount || {
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }
            }
            onChange={onChangeInputData}
            required
            disabled={false}
            value={state.BankCharge}
            name="BankCharge"
          />
        </FormGroup2>
      </>
    );
  }

  function renderTTInterest() {
    const disabled = !!state.IsIncluding;
    return (
      <>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="control-label">
              Payment Method<span style={{ color: 'rgb(230, 74, 25)' }}>*</span>
            </label>
            <MasterDropdownUI
              onChange={(e) => onChangeInputData({ target: e })}
              value={state.PaymentMethodInterest}
              isChoose
              notMultipleSelect2
              required={!disabled && !state.DisabledPaymentMethodInterest}
              disabled={disabled || state.DisabledPaymentMethodInterest}
              name="PaymentMethodInterest"
              options={[
                'Bahtnet',
                'Cheque',
                'Internet Banking',
                'Auto Deduct',
                'T/T',
                'T/T Swaper',
              ]}
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label className="control-label">
              Bank Account<span style={{ color: 'rgb(230, 74, 25)' }}>*</span>
            </label>
            <MasterDropdown
              noValidateOption
              masterType={MASTER_BANK_ACCOUNT}
              isChoose
              notMultipleSelect2
              value={state.BankAccountNoInterest}
              onChange={(e) => onChangeInputData({ target: e })}
              saveLocalState
              // bankCode={state.BankCode}
              companyCode={state.CompanyCode}
              name="BankAccountNoInterest"
              required={!disabled}
              isOutFlow
              disabled={disabled}
              customeLabel={(m) =>
                `${m.bankCode} | ${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}${m.bankAccountNoOfBank ? ` (${m.bankAccountNoOfBank})` : ''}`
              }
              customeValue={(m) => `${m.bankAccountNo}`}
              isAccountBank={state.PartnerType === 'External'}
            />
          </div>
          {!isIntercompany ? null : (
            <div className="form-group col-md-12">
              <label className="control-label">
                Bank Account Partner<span style={{ color: 'rgb(230, 74, 25)' }}>*</span>
              </label>
              <MasterDropdown
                noValidateOption
                masterType={MASTER_BANK_ACCOUNT}
                isChoose
                notMultipleSelect2
                value={state.BankAccountNoPartnerInterest}
                onChange={(e) => onChangeInputData({ target: e })}
                saveLocalState
                // bankCode={state.BankCodePartner}
                companyCode={state.BusinessPartner}
                name="BankAccountNoPartnerInterest"
                required={!disabled}
                disabled={disabled}
                isOutFlow
                customeLabel={(m) =>
                  `${m.bankCode} | ${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}`
                }
                customeValue={(m) => `${m.bankAccountNo}`}
              />
            </div>
          )}
        </div>
      </>
    );
  }

  function renderTTPrincipal() {
    let AmountLabel = 'TT Amount';
    if (state.PaymentMethodPrincipal === 'Bahtnet') AmountLabel = 'BahtNet Amount';
    const disabledTTAmount = !!state.IsWithdrawAll;
    return (
      <>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="control-label">
              Payment Method<span style={{ color: 'rgb(230, 74, 25)' }}>*</span>
            </label>
            <MasterDropdownUI
              onChange={(e) => onChangeInputData({ target: e })}
              value={state.PaymentMethodPrincipal}
              isChoose
              notMultipleSelect2
              required={!state.DisabledPaymentMethodPrincipal}
              disabled={state.DisabledPaymentMethodPrincipal}
              name="PaymentMethodPrincipal"
              options={[
                'Bahtnet',
                'Cheque',
                'Internet Banking',
                'Auto Deduct',
                'T/T',
                'T/T Swaper',
              ]}
            />
          </div>
          <div className="form-group col-md-6">
            <label className="control-label">
              {AmountLabel}{' '}
              {enableTTAmount ? <span style={{ color: 'rgb(230, 74, 25)' }}>*</span> : null}
            </label>
            <InputMask
              className="form-control"
              format="currency"
              option={
                state.inputMaskAmount || {
                  prefix: '',
                  digits: 2,
                  digitsOptional: false,
                  placeholder: '0.00',
                }
              }
              onChange={onChangeInputData}
              value={state.TTAmount}
              name="TTAmount"
              required={enableTTAmount && !disabledTTAmount}
              disabled={!enableTTAmount || disabledTTAmount}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-12">
            <label className="control-label">
              Bank Account<span style={{ color: 'rgb(230, 74, 25)' }}>*</span>
            </label>
            <MasterDropdown
              noValidateOption
              masterType={MASTER_BANK_ACCOUNT}
              isChoose
              notMultipleSelect2
              value={state.BankAccountNoPrincipal}
              onChange={(e) => onChangeInputData({ target: e })}
              saveLocalState
              // bankCode={state.BankCode}
              companyCode={state.CompanyCode}
              name="BankAccountNoPrincipal"
              required
              isOutFlow
              customeLabel={(m) =>
                `${m.bankCode} | ${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}${m.bankAccountNoOfBank ? ` (${m.bankAccountNoOfBank})` : ''}`
              }
              customeValue={(m) => `${m.bankAccountNo}`}
              isAccountBank={state.PartnerType === 'External'}
            />
          </div>
          {!isIntercompany ? null : (
            <div className="form-group col-md-12">
              <label className="control-label">
                Bank Account Partner<span style={{ color: 'rgb(230, 74, 25)' }}>*</span>
              </label>
              <MasterDropdown
                noValidateOption
                masterType={MASTER_BANK_ACCOUNT}
                isChoose
                notMultipleSelect2
                value={state.BankAccountNoPartnerPrincipal}
                onChange={(e) => onChangeInputData({ target: e })}
                saveLocalState
                // bankCode={state.BankCodePartner}
                companyCode={state.BusinessPartner}
                name="BankAccountNoPartnerPrincipal"
                required
                isOutFlow
                customeLabel={(m) =>
                  `${m.bankCode} | ${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}`
                }
                customeValue={(m) => `${m.bankAccountNo}`}
              />
            </div>
          )}
        </div>
      </>
    );
  }
};

export function calNewDepositAmount(data) {
  const {
    Amount,
    IncreaseDecrease,
    IncreaseDecreaseAmount,
    IsIncluding,
    InterestAmount,
    WithholdingTax,
    BankCharge,
    StampDutyFee,
  } = data;

  let newPnAmount = Amount;

  if (IsIncluding) {
    newPnAmount +=
      InterestAmount -
      Number(WithholdingTax || 0) -
      Number(BankCharge || 0) -
      Number(StampDutyFee || 0);
  }

  if (IncreaseDecrease === 'Increase') newPnAmount += Number(IncreaseDecreaseAmount) || 0;
  else if (IncreaseDecrease === 'Decrease') newPnAmount -= Number(IncreaseDecreaseAmount) || 0;

  return newPnAmount;
}
