import { connect } from 'react-redux';
import React, { Component } from 'react';

import CashPlan from './CashPlan';
import { addAlert } from '../../reducers/layout/action';
import { toFixed, toUpperKey, checkWeekend } from '../../common/helpper';
import { getExchangeRate, getCurrencyDigit } from '../../reducers/master/action';
import {
  getCashplan,
  getTemplate,
  createCashPlan,
  getTemplateList,
} from '../../reducers/cashPlan/action';

const { moment } = window;
const init = {
  TransactionNo: '',

  DataFrom: '',
  TemplateName: '',
  NewTemplate: '##__MANUAL__##',

  // Information
  CashType: 'Inflow',
  ValueDateStr: '',
  CompanyCode: '',
  BankCode: '',
  BankAccountNo: '',
  BankCurrency: '',
  Currency: '',
  Amount: '',
  ExchangeRate: '',
  AmountInBankCurrency: '',

  // Business Partner
  PartnerType: 'Other',
  IntercomCompanyName: '',
  // IC
  IntercomCompanyCode: '',
  IntercomBankCode: '',
  IntercomBankAccountNo: '',
  IntercomBankCurrency: '',
  IntercomExchangeRate: '',
  IntercomAmountInBankCurrency: '',

  TransferMethod: '',
  PaymentMethod: '',
  TransactionDetail: '',

  mode: 'create',

  inputMaskAmount: {
    prefix: '',
    digits: 3,
  },
  inputMaskBankCurrency: {
    prefix: '',
    digits: 3,
  },
  inputMaskIntercom: {
    prefix: '',
    digits: 3,
  },
};

class CashPlanContainer extends Component {
  state = {
    ...init,
    createHistory: [],
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState({
        mode: 'create',
      });
    else {
      this.fetchCashPlan(id);
    }

    this.props.getTemplateList();
  }

  componentDidUpdate(prevProps, prevState) {
    // fetch transaction for edit
    const { id } = this.props.routeProp.match.params;
    const prevId = prevProps.routeProp.match.params.id;
    if (id !== prevId) {
      if (!id || id.toString().toLowerCase() === 'create')
        this.setState({
          mode: 'create',
        });
      else this.fetchCashPlan(id);
    }

    // get exchange rate
    const {
      mode,
      IntercomBankCurrency,
      BankCurrency,
      Currency,
      CashType,
      Amount,
      AmountInBankCurrency,
      IntercomAmountInBankCurrency,
      ValueDateStr,
      CompanyCode,
      IntercomCompanyCode,
    } = this.state;
    if (mode === 'create') {
      if (
        (prevState.IntercomBankCurrency !== IntercomBankCurrency ||
          prevState.Currency !== Currency ||
          prevState.ValueDateStr !== ValueDateStr ||
          prevState.IntercomCompanyCode !== IntercomCompanyCode) &&
        IntercomBankCurrency &&
        Currency &&
        ValueDateStr &&
        IntercomCompanyCode &&
        IntercomBankCurrency !== Currency
      ) {
        this.props
          .getExchangeRate({
            Type: CashType === 'Inflow' ? 'B' : 'G',
            CurrencyFrom: Currency,
            CurrencyTo: IntercomBankCurrency,
            ValueDateStr,
            CompanyCode: IntercomCompanyCode,
          })
          .then((response) => {
            if (response.error) return;

            this.setState({
              IntercomExchangeRate: response.payload,
            });
          });
      }

      if (
        (prevState.BankCurrency !== BankCurrency ||
          prevState.Currency !== Currency ||
          prevState.ValueDateStr !== ValueDateStr ||
          prevState.CompanyCode !== CompanyCode) &&
        BankCurrency &&
        Currency &&
        ValueDateStr &&
        CompanyCode &&
        BankCurrency !== Currency
      ) {
        this.props
          .getExchangeRate({
            Type: CashType === 'Inflow' ? 'G' : 'B',
            CurrencyFrom: Currency,
            CurrencyTo: BankCurrency,
            ValueDateStr,
            CompanyCode,
          })
          .then((response) => {
            if (response.error) return;

            this.setState({
              ExchangeRate: response.payload,
            });
          });
      }
    }

    // get currency digit
    if (mode === 'create' || mode === 'edit') {
      if (prevState.Currency !== Currency) {
        this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
          if (response.error) return;

          const { digit } = response.payload[0];
          this.setState({
            inputMaskAmount: {
              prefix: '',
              digits: digit,
              digitsOptional: false,
              placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
            },
            Amount: toFixed(Number((Amount.toString() || '').replace(/,/g, '') || 0), digit),
          });
        });
      }

      if (prevState.BankCurrency !== BankCurrency) {
        this.props.getCurrencyDigit({ criteria: BankCurrency }).then((response) => {
          if (response.error) return;

          const { digit } = response.payload[0];
          this.setState({
            inputMaskBankCurrency: {
              prefix: '',
              digits: digit,
              digitsOptional: false,
              placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
            },
            AmountInBankCurrency: toFixed(
              Number((AmountInBankCurrency.toString() || '').replace(/,/g, '') || 0),
              digit
            ),
          });
        });
      }

      if (prevState.IntercomBankCurrency !== IntercomBankCurrency) {
        this.props.getCurrencyDigit({ criteria: IntercomBankCurrency }).then((response) => {
          if (response.error) return;

          const { digit } = response.payload[0];
          this.setState({
            inputMaskIntercom: {
              prefix: '',
              digits: digit,
              digitsOptional: false,
              placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
            },
            IntercomAmountInBankCurrency: toFixed(
              Number((IntercomAmountInBankCurrency.toString() || '').replace(/,/g, '') || 0),
              digit
            ),
          });
        });
      }
    }
  }

  fetchCashPlan(transactionNo) {
    if (!transactionNo) return;

    this.props.getCashplan(transactionNo).then((response) => {
      if (response.error || !response.payload) return;
      // check param and fech transaction is equal
      if (response.payload.trNo !== this.props.routeProp.match.params.id) return;

      const fetchData = toUpperKey(response.payload);

      this.setState({
        ...fetchData,
        mode: 'edit',
      });
    });
  }

  onChangeInputData = (e) => {
    const { name, value, label } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'CompanyCode') {
        stateToUpdate.BankCode = '';
        stateToUpdate.BankAccountNo = '';
        stateToUpdate.BankCurrency = '';
        stateToUpdate.ExchangeRate = ' ';
        stateToUpdate.PaymentMethod = '';
        stateToUpdate.TransferMethod = '';
      } else if (name === 'BankCode') {
        stateToUpdate.BankAccountNo = '';
        stateToUpdate.BankCurrency = '';
        stateToUpdate.ExchangeRate = ' ';
        stateToUpdate.PaymentMethod = '';
        stateToUpdate.TransferMethod = '';
      } else if (name === 'BankAccountNo') {
        stateToUpdate.ExchangeRate = ' ';
        stateToUpdate.PaymentMethod = '';
        stateToUpdate.TransferMethod = '';
        stateToUpdate.BankCurrency = (label || '').split('|')[2].trim() || '';
      } else if (name === 'Currency') {
        stateToUpdate.PaymentMethod = '';
        stateToUpdate.TransferMethod = '';
        stateToUpdate.ExchangeRate = ' ';
        stateToUpdate.IntercomExchangeRate = ' ';
      } else if (name === 'IntercomCompanyCode') {
        stateToUpdate.IntercomBankCode = '';
        stateToUpdate.IntercomBankAccountNo = '';
        stateToUpdate.IntercomBankCurrency = '';
        stateToUpdate.IntercomExchangeRate = ' ';
      } else if (name === 'IntercomBankCode') {
        stateToUpdate.IntercomBankAccountNo = '';
        stateToUpdate.IntercomBankCurrency = '';
        stateToUpdate.IntercomExchangeRate = ' ';
      } else if (name === 'IntercomBankAccountNo') {
        stateToUpdate.IntercomBankCurrency = ((label || '|').split('|')[2] || '').trim() || '';
      } else if (name === 'PartnerType') {
        stateToUpdate.IntercomCompanyCode = '';
        stateToUpdate.IntercomBankCurrency = '';
        stateToUpdate.IntercomExchangeRate = ' ';
        stateToUpdate.PaymentMethod = '';
        stateToUpdate.TransferMethod = '';
        stateToUpdate.IntercomBankAccountNo = '';
        stateToUpdate.IntercomBankCode = '';
        stateToUpdate.IntercomCompanyCode = '';
      }

      if (name === 'CompanyCode' || name === 'IntercomCompanyCode') {
        const mergeState = {
          ...state,
          ...stateToUpdate,
        };
        if (
          mergeState.CompanyCode &&
          mergeState.IntercomCompanyCode &&
          mergeState.CompanyCode === mergeState.IntercomCompanyCode
        ) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Company must not same Intercompany.',
          });
          return {
            [name]: '',
          };
        }
      }

      return stateToUpdate;
    });

    if (name === 'NewTemplate') {
      if (value === '##__MANUAL__##' || value === 'New Template') {
        this.setState({
          ...init,
          [name]: value,
        });
      } else {
        this.props.getTemplate(value).then((response) => {
          if (!response.error) {
            const templateData = response.payload || init;

            this.setState({
              ...toUpperKey(templateData),
              mode: 'create',
              TemplateName: '',
            });
          }
        });
      }
    }
  };

  onSubmitCreateHandler = (e) => {
    e.preventDefault();

    const state = { ...this.state };
    if (checkWeekend(state.ValueDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Value date can not be weekend.',
      });
      return;
    }

    if (!state.Amount || (state.Amount.toString() || '').replace(/,/g, '') <= 0) {
      this.props.addAlert({
        title: 'Error',
        body: 'Amount must more than zero',
        type: 'error',
      });
      return;
    }

    if (
      state.BankCurrency !== state.Currency &&
      ((state.ExchangeRate.toString() || '').replace(/,/g, '') <= 0 || !state.ExchangeRate)
    ) {
      this.props.addAlert({
        title: 'Error',
        body: 'Exchange rate must more than zero',
        type: 'error',
      });
      return;
    }

    if (
      state.PartnerType === 'Intercom' &&
      state.IntercomBankCurrency !== state.Currency &&
      ((state.IntercomExchangeRate.toString() || '').replace(/,/g, '') <= 0 ||
        !state.IntercomExchangeRate)
    ) {
      this.props.addAlert({
        title: 'Error',
        body: 'Intercom exchange rate must more than zero',
        type: 'error',
      });
      return;
    }

    const saveTemplate = this.state.NewTemplate === 'New Template';
    const submitData = { ...this.state };
    if (submitData.mode !== 'edit') {
      if (
        submitData.ValueDateStr.trim() === moment().format('DD/MM/YYYY') ||
        (submitData.ValueDateStr.trim() === moment().add(1, 'days').format('DD/MM/YYYY') &&
          moment().hours() >= 12) ||
        (moment().format('e') === '5' &&
          moment().hours() >= 12 &&
          submitData.ValueDateStr.trim() === moment().add(3, 'days').format('DD/MM/YYYY'))
      ) {
        this.props.addAlert({
          type: 'warning',
          title: 'Warning',
          body: 'Please inform the finance team as the transaction is over the cut-off time.',
          buttons: [
            <button
              className="btn btn-warning btn-sm"
              data-dismiss="modal"
              onClick={(e) => this.submitData(submitData, saveTemplate)}
            >
              Confirm
            </button>,
            <button className="btn btn-default btn-sm" data-dismiss="modal">
              Cancel
            </button>,
          ],
        });

        return;
      }
    }
    this.submitData(submitData, saveTemplate);
  };

  submitData = (submitData, saveTemplate) => {
    this.props.createCashPlan(submitData, saveTemplate).then((response) => {
      if (!response.error) {
        // reset input
        this.setState((state) => ({
          ...init,
          createHistory: [
            ...state.createHistory,
            {
              ...response.payload,
            },
          ],
        }));
        if (submitData.mode !== 'edit' && submitData.NewTemplate === 'New Template') {
          // fetch new template list
          this.props.getTemplateList();
        }
      } else this.setState({ ...init });
    });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmitCreateHandler: this.onSubmitCreateHandler,
      template: this.props.template,
    };

    return <CashPlan {...props} state={this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.cashPlan,
  }),
  {
    createCashPlan,
    getCashplan,
    addAlert,
    getTemplate,
    getTemplateList,
    getExchangeRate,
    getCurrencyDigit,
  }
)(CashPlanContainer);
