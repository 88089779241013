import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { DATATABLE_ID, DATATABLE_ID_DETAIL } from './EformGroupingContainer';
import { toLower, toNumber, toStatus, getPermission } from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('FX Management', 'E-Form Grouping');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const $ = window.jQuery;

export default ({
  criteria,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  mode,
  dataTableRef,
  result,

  // function mode main
  onClickExport,
  dataTableDetailRef,
  eformDetail,
  modalDetailRef,
  onClickUnGrouping,

  // function mode detail
  onClickGrouping,
  onClickOpenModalEdit,
  modalDataRef,
  modalData,
  onChageModalData,
  onSubmitModal,
}) => {
  const selectedResultCount = result.filter((f) => f.IsSelected).length;
  const reqValueDate = !criteria.UpdateDateStr;
  const reqUpdateDate = !criteria.ValueDateStr;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" /> E-Form Grouping
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                  name="Companies"
                />
              </FormGroup2>

              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.BankCode}
                  companyCode={criteria.Companies}
                  name="BankCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.Currency}
                  name="Currency"
                />
              </FormGroup2>

              <FormGroup2 text="Forward Type">
                <MasterDropdownUI
                  value={criteria.ForwardType}
                  onChange={onChangeSelect2}
                  name="ForwardType"
                  options={['Forward', 'Dollar Link', 'Loan', 'Other']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Request Type">
                <MasterDropdownUI
                  value={criteria.RequestType}
                  onChange={onChangeSelect2}
                  name="RequestType"
                  options={['With Underlying', 'Without Underlying', 'Loan']}
                />
              </FormGroup2>

              <FormGroup2 text="Practice">
                <MasterDropdownUI
                  value={criteria.Practice}
                  onChange={onChangeSelect2}
                  name="Practice"
                  options={['Export', 'Import', 'Offshore']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Direction">
                <MasterDropdownUI
                  value={criteria.Direction}
                  onChange={onChangeSelect2}
                  name="Direction"
                  options={['Export', 'Import']}
                />
              </FormGroup2>

              <FormGroup2 text="Approved Date" required={reqValueDate}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required={reqValueDate}
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Created Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.CreatedDateStr}
                  name="CreatedDateStr"
                />
              </FormGroup2>

              <FormGroup2 text="As Of Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.AsOfDateStr}
                  name="AsOfDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <MasterDropdownUI
                  value={criteria.Status}
                  onChange={onChangeSelect2}
                  name="Status"
                  options={['Active', 'Inactive', 'Completed']}
                />
              </FormGroup2>

              <FormGroup2 text="Display">
                <select
                  className="form-control"
                  value={criteria.Mode}
                  onChange={onChangeSearch}
                  name="Mode"
                >
                  <option value="main">FWD Booking w/ grouping</option>
                  <option value="detail">FWD Booking w/o grouping</option>
                  <option value="all">All FWD Request</option>
                </select>
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="&nbsp;">
                <div>
                  <input
                    id="IsAmountZero"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeSearch}
                    value={criteria.IsAmountZero}
                    name="IsAmountZero"
                    checked={criteria.IsAmountZero}
                  />
                  <label htmlFor="IsAmountZero" className="label_checkbox" />
                  <label htmlFor="IsAmountZero" className="control-label text_label_checkbox">
                    Mapped Amount = 0
                  </label>
                </div>
              </FormGroup2>

              {criteria.IsAmountZero ? (
                <FormGroup2 text="Update Date" required={reqUpdateDate}>
                  <DateRangePicker
                    onChange={onChangeSearch}
                    value={criteria.UpdateDateStr}
                    required={reqUpdateDate}
                    name="UpdateDateStr"
                  />
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? <></> : null}
              <Link
                className="btn btn-warning btn-sm btn-labeled"
                to="/fx-management/maintain-grouping"
                target="_blank"
              >
                <span className="btn-label">
                  <i className="icon icon-plus-circle icon-fw icon-lg" />
                </span>
                MAINTAIN RULE
              </Link>
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      {mode === 'main' ? (
        <Card
          textHeader="Result FWD Booking w/ grouping"
          number="2"
          bgHeader="bg-primary"
          cardActions={['toggler']}
        >
          {permission ? (
            <Col2 col={colX[2]}>
              <div>
                <Button
                  txt="Export"
                  icon="credit-card-alt"
                  className="btn-default"
                  onClick={onClickExport}
                />
              </div>
            </Col2>
          ) : null}
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={opt}
            value={result}
          >
            <thead>
              <tr>
                <th>
                  Choose <label className="label-checkbox fix choose-all result-group" />
                </th>
                <th>Forward Booking No.</th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>
                  Company
                  <br /> Name
                </th>
                <th>Document Type</th>
                <th>Date From</th>
                <th>Date To</th>
                <th>Currency</th>
                <th>Request Amount</th>
                <th>Mapped Amount</th>
                <th>Hedge Amount</th>
                <th>Request Type</th>
                <th>Department</th>
                <th>
                  Forward Contract
                  <br /> Transaction No.
                </th>
              </tr>
            </thead>
          </Table>
        </Card>
      ) : null}

      {mode === 'detail' ? (
        <Card
          textHeader="Result FWD Booking w/o grouping"
          number="2"
          bgHeader="bg-primary"
          cardActions={['toggler']}
        >
          {permission ? (
            <Col2 col={colX[2]}>
              <div>
                <Button
                  txt="Auto Grouping"
                  icon="undo"
                  className="btn-warning"
                  onClick={(e) => onClickGrouping('Auto')}
                />
                &nbsp;
                <Button
                  txt="Manual Grouping"
                  icon="undo"
                  className="btn-primary"
                  onClick={(e) => onClickGrouping('Manual')}
                />
                &nbsp;
                <Button
                  txt="Change Bank"
                  icon="edit"
                  className="btn-info"
                  onClick={(e) => onClickOpenModalEdit(e)}
                />
              </div>
            </Col2>
          ) : null}
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={optUngroup}
            value={result}
          >
            <thead>
              <tr>
                <th>
                  Choose <label className="label-checkbox fix choose-all result-group" />
                </th>
                <th>Action</th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>Company Name</th>
                <th>Forward Request No.</th>
                <th>Item No.</th>
                <th>Bank</th>
                <th>
                  Forward
                  <br /> Currency
                </th>
                <th>
                  Book Bank
                  <br /> Currency
                </th>
                <th>Direction</th>
                <th>Request Type</th>
                <th>Forward Type</th>
                <th>Department</th>
                <th>Request Amount</th>
                <th>Mapped Amount</th>
                <th>Practice</th>
                <th>Bank Remark</th>
                <th>
                  Estimated
                  <br /> Payment Date
                </th>
                <th>Created Date</th>
                <th>Updated Date</th>
                <th>Approved Date</th>
                <th>E-form Update Date</th>
                <th>
                  Indicative Rate
                  <br /> at Created Date
                </th>
                <th>
                  Indicative Rate
                  <br /> at Approved Date
                </th>
                <th>Remark</th>
                <th>Q2O No.</th>
                <th>Status</th>
              </tr>
            </thead>
          </Table>
        </Card>
      ) : null}

      {mode === 'all' ? (
        <Card
          textHeader="Result All FWD Request"
          number="2"
          bgHeader="bg-primary"
          cardActions={['toggler']}
        >
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={optAll}
            value={result}
          >
            <thead>
              <tr>
                <th>Forward Booking No.</th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>Company Name</th>
                <th>Forward Request No.</th>
                <th>Item No.</th>
                <th>Bank</th>
                <th>
                  Forward
                  <br /> Currency
                </th>
                <th>
                  Book Bank
                  <br /> Currency
                </th>
                <th>Direction</th>
                <th>Request Type</th>
                <th>Forward Type</th>
                <th>Department</th>
                <th>Request Amount</th>
                <th>Mapped Amount</th>
                <th>Practice</th>
                <th>Bank Remark</th>
                <th>
                  Estimated
                  <br /> Payment Date
                </th>
                <th>Created Date</th>
                <th>Updated Date</th>
                <th>Approved Date</th>
                <th>E-form Update Date</th>
                <th>
                  Indicative Rate
                  <br /> at Created Date
                </th>
                <th>
                  Indicative Rate
                  <br /> at Approved Date
                </th>
                <th>Remark</th>
                <th>Q2O No.</th>
                <th>Status</th>
              </tr>
            </thead>
          </Table>
        </Card>
      ) : null}

      <Modal
        textHeader={`E-Form Grouping Detail : ${modalData.InternalReference}`}
        size="modal-xl"
        bgHeader="bg-primary"
        ref={modalDetailRef}
        modalFooter={false}
      >
        {permission ? (
          <Col2 col={colX[2]}>
            <div>
              <Button
                txt="Ungrouping"
                icon="undo"
                className="btn-warning"
                onClick={onClickUnGrouping}
              />
            </div>
          </Col2>
        ) : null}
        <Table
          ref={dataTableDetailRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID_DETAIL}
          option={optDetail}
          value={eformDetail}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all result-detail" />
              </th>
              <th>Forward Booking No.</th>
              <th>
                Company
                <br /> Code
              </th>
              <th>Company Name</th>
              <th>Forward Request No.</th>
              <th>Item No.</th>
              <th>Bank</th>
              <th>
                Forward
                <br /> Currency
              </th>
              <th>
                Book Bank
                <br /> Currency
              </th>
              <th>Direction</th>
              <th>Request Type</th>
              <th>Forward Type</th>
              <th>Department</th>
              <th>Request Amount</th>
              <th>Mapped Amount</th>
              <th>Practice</th>
              <th>Bank Remark</th>
              <th>
                Estimated
                <br /> Payment Date
              </th>
              <th>Created Date</th>
              <th>Updated Date</th>
              <th>Approved Date</th>
              <th>E-form Updated Date</th>
              <th>
                Indicative Rate
                <br /> at Created Date
              </th>
              <th>
                Indicative Rate
                <br /> at Approved Date
              </th>
              <th>Remark</th>
              <th>Q2O No.</th>
            </tr>
          </thead>
        </Table>
      </Modal>

      <Modal size="modal-md" ref={modalDataRef} modalFooter>
        <form onSubmit={onSubmitModal} className="form-horizontal">
          <div className="text-center">
            {modalData.action === 'all' ? (
              <span className="label label-info">{selectedResultCount} items</span>
            ) : null}
            <h2 className="text-info">Change Bank</h2>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-3">
              Bank<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-8">
              <MasterDropdown
                masterType={MASTER_BANK}
                value={modalData.BankCode}
                name="BankCode"
                companyCode={modalData.CompanyCode}
                isChoose
                notMultipleSelect2
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChageModalData({
                    target: e,
                  })
                }
                required
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const opt = {
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox result-group`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      data: 'internalReference',
      render: (d) => {
        if (!permission) return d;
        return `<a class="show-detail">${d}</a>`;
      },
    },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'documentType' },
    { data: 'dateFromStr', className: 'text-center', type: 'date-black' },
    { data: 'dateToStr', className: 'text-center', type: 'date-black' },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'requestAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'mappedAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'hedgeAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'requestType' },
    { data: 'departmentName' },
    { data: 'forwardNo' },
  ],
  drawCallback: () => {
    setTimeout(() => {
      $(window).trigger('resize');
    }, 1000);
  },
};

const optDetail = {
  order: [[3, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox result-detail`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    { data: 'internalReference' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'forwardRequestNo' },
    { data: 'itemNo', className: 'text-center' },
    { data: 'bankName' },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'bookBankCurrency', className: 'text-center' },
    { data: 'direction' },
    { data: 'requestType' },
    { data: 'forwardType' },
    { data: 'departmentName' },
    {
      data: 'requestAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'mappedAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'practice' },
    { data: 'bankRemark' },
    { data: 'estimatedPaymentDateStr', className: 'text-center', type: 'date-black' },
    { data: 'eformCreatedDateStr', type: 'date-time' },
    { data: 'updatedDateStr', type: 'date-time' },
    { data: 'approvedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'eformUpdateDateStr', type: 'date-time' },
    { data: 'indicativeRateCreated', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'indicativeRateApproved', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'remark' },
    { data: 'q2o' },
  ],
  drawCallback: () => {
    setTimeout(() => {
      $(window).trigger('resize');
    }, 1000);
  },
};

const optUngroup = {
  order: [[4, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox result-group`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'isEdit',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission || toLower(r.status) !== 'active') return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'forwardRequestNo' },
    { data: 'itemNo', className: 'text-center' },
    { data: 'bankName' },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'bookBankCurrency', className: 'text-center' },
    { data: 'direction' },
    { data: 'requestType' },
    { data: 'forwardType' },
    { data: 'departmentName' },
    {
      data: 'requestAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'mappedAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'practice' },
    { data: 'bankRemark' },
    { data: 'estimatedPaymentDateStr', className: 'text-center', type: 'date-black' },
    { data: 'eformCreatedDateStr', type: 'date-time' },
    { data: 'updatedDateStr', type: 'date-time' },
    { data: 'approvedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'eformUpdateDateStr', type: 'date-time' },
    { data: 'indicativeRateCreated', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'indicativeRateApproved', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'remark' },
    { data: 'q2o' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
  ],
  drawCallback: () => {
    setTimeout(() => {
      $(window).trigger('resize');
    }, 1000);
  },
};

const optAll = {
  order: [[0, 'asc']],
  fixedColumns: {
    leftColumns: 1,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    { data: 'internalReference' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'forwardRequestNo' },
    { data: 'itemNo', className: 'text-center' },
    { data: 'bankName' },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'bookBankCurrency', className: 'text-center' },
    { data: 'direction' },
    { data: 'requestType' },
    { data: 'forwardType' },
    { data: 'departmentName' },
    {
      data: 'requestAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'mappedAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'practice' },
    { data: 'bankRemark' },
    { data: 'estimatedPaymentDateStr', className: 'text-center', type: 'date-black' },
    { data: 'eformCreatedDateStr', type: 'date-time' },
    { data: 'updatedDateStr', type: 'date-time' },
    { data: 'approvedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'eformUpdateDateStr', type: 'date-time' },
    { data: 'indicativeRateCreated', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'indicativeRateApproved', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'remark' },
    { data: 'q2o' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
  ],
};
