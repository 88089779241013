import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';

export const REQUEST_SEARCH_BANK_TRANSFER_POSTING_JOURNAL =
  '@@request/SEARCH_BANK_TRANSFER_POSTING_JOURNAL';
export const RECEIVE_SEARCH_BANK_TRANSFER_POSTING_JOURNAL =
  '@@receive/SEARCH_BANK_TRANSFER_POSTING_JOURNAL';
export const FAIL_SEARCH_BANK_TRANSFER_POSTING_JOURNAL =
  '@@fail/SEARCH_BANK_TRANSFER_POSTING_JOURNAL';

export const searchBankTransferPostingJournal = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/SearchBankTransferPostingJournal', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_BANK_TRANSFER_POSTING_JOURNAL,
      RECEIVE_SEARCH_BANK_TRANSFER_POSTING_JOURNAL,
      FAIL_SEARCH_BANK_TRANSFER_POSTING_JOURNAL,
    ],
  },
});
