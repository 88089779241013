import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { MASTER_BUSINESS_AREA } from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ formRef, state, onChangeInputData, facilityList = [], viewOnly, isDeposit }) => {
  const noEdit = state.mode === 'edit';
  const required = true;
  return (
    <form ref={formRef} name="administrator">
      <Card textHeader="Position Assignment" cardActions={['toggler']}>
        {(state.PartnerType !== 'Intercompany' && state.Source !== 'Deposit') ||
        state.Source === 'Contract On Call' ? (
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Facility" required>
                <select
                  tabIndex="-1"
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={viewOnly}
                  value={state.FacilityNo}
                  name="FacilityNo"
                >
                  <option value="">---Choose---</option>
                  {facilityList.map((m) => (
                    <option value={m.transactionNo} key={m.transactionNo}>
                      {m.facilityName}
                    </option>
                  ))}
                </select>
              </FormGroup2>
              <FormGroup2 text="Outstanding" required={false}>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={{
                    prefix: '',
                    digits: 0,
                  }}
                  disabled
                  value={state.Outstanding}
                  name="Outstanding"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="New PN" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.NewPn || ''}
                  name="NewPn"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        ) : null}

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Business Area" required={state.BusinessArea}>
              {noEdit ? (
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  disabled
                  value={state.BusinessAreaName || ''}
                  name="BusinessAreaName"
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_BUSINESS_AREA}
                  noValidateOption={noEdit}
                  saveLocalState
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  companyCode={state.CompanyCode}
                  // notMultiple={true}
                  isChoose={!state.BusinessArea}
                  required={state.BusinessArea}
                  value={state.BusinessArea}
                  name="BusinessArea"
                  disabled={noEdit}
                />
              )}
            </FormGroup2>

            <FormGroup2 text="Gen Valn Class" required={false}>
              {state.Source === 'Deposit' ? (
                <MasterDropdownUI
                  value={state.GenValnClass}
                  name="GenValnClass"
                  isChoose
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  required={false}
                  disabled={false}
                  options={
                    isDeposit
                      ? ['<= 3 Months', '> 3 <= 12 Months', '> 12 Months', 'ON CALL']
                      : ['<3 Months', '3-12 Months', '>12 Months', 'ON CALL']
                  }
                />
              ) : (
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={required}
                  disabled
                  value={state.GenValnClass}
                  name="GenValnClass"
                />
              )}
            </FormGroup2>
          </Col2>

          {state.LoanType !== 'On Call' ? (
            <Col2 col={colX[1]}>
              <FormGroup2 text="Day(s)" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={required}
                  disabled
                  value={state.NDay}
                  name="NDay"
                />
              </FormGroup2>
            </Col2>
          ) : null}
        </Col2>

        {state.PartnerType === 'Intercompany' ? (
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Partner Business Area" required={state.BusinessAreaPartner}>
                {noEdit ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    disabled
                    value={state.BusinessAreaPartnerName || ''}
                    name="BusinessAreaPartnerName"
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_BUSINESS_AREA}
                    noValidateOption={noEdit}
                    saveLocalState
                    notMultipleSelect2
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    companyCode={state.BusinessPartner}
                    // notMultiple={true}
                    isChoose={!state.BusinessAreaPartner}
                    required={state.BusinessAreaPartner}
                    value={state.BusinessAreaPartner}
                    name="BusinessAreaPartner"
                    disabled={noEdit}
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>
        ) : null}
      </Card>

      <Card textHeader="Additional" cardActions={['toggler']}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2
              text={`Old ${state.Source === 'Deposit' ? 'Deposit' : 'PN'} No.`}
              required={false}
            >
              <div className="table-responsive">
                <table className="table table-bordered table-nowrap">
                  <thead>
                    <tr>
                      <th className="th-white">No.</th>
                      <th className="th-white">Transacion No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!(state.TransactionNoRef || []).length ? (
                      <tr>
                        <td colSpan="2" align="center">
                          No data available in table
                        </td>
                      </tr>
                    ) : null}
                    {state.TransactionNoRef.map((m, i) => (
                      <tr key={i}>
                        <td align="center">{i + 1}</td>
                        <td>{m.transactionNo}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </FormGroup2>

            <FormGroup2
              text={`New ${state.Source === 'Deposit' ? 'Deposit' : 'PN'} No.`}
              required={false}
            >
              <div className="table-responsive">
                <table className="table table-bordered table-nowrap">
                  <thead>
                    <tr>
                      <th className="th-white">No.</th>
                      <th className="th-white">Transacion No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!state.TransactionNoReference ? (
                      <tr>
                        <td colSpan="2" align="center">
                          No data available in table
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td align="center">{1}</td>
                        <td>{state.TransactionNoReference}</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </FormGroup2>
          </Col2>
        </Col2>
      </Card>
    </form>
  );
};
