import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_ROLE_CRITERIA = 'EDIT_MASTER_ROLE_CRITERIA';
export const CLEAR_MASTER_ROLE_CRITERIA = 'CLEAR_MASTER_ROLE_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_ROLE = 'UPDATE_SEARCH_RESULT_MASTER_ROLE';

export const REQUEST_SEARCH_MASTER_ROLE = '@@request/SARCH_MASTER_ROLE';
export const RECEIVE_SEARCH_MASTER_ROLE = '@@receive/SEARCH_MASTER_ROLE';
export const FAIL_SEARCH_MASTER_ROLE = '@@fail/SEARCH_MASTER_ROLE';

export const REQUEST_DELETE_MASTER_ROLE = '@@request/DELETE_MASTER_ROLE';
export const RECEIVE_DELETE_MASTER_ROLE =
  '@@receive-The record was successfully saved/DELETE_MASTER_ROLE';
export const FAIL_DELETE_MASTER_ROLE = '@@fail/DELETE_MASTER_ROLE';

export const REQUEST_SAVE_MASTER_ROLE = '@@request/SAVE_MASTER_ROLE';
export const RECEIVE_SAVE_MASTER_ROLE = '@@receive/SAVE_MASTER_ROLE';
export const FAIL_SAVE_MASTER_ROLE = '@@fail/SAVE_MASTER_ROLE';

export const REQUEST_GET_MASTER_ROLE_DETAIL = '@@request/SARCH_MASTER_ROLE';
export const RECEIVE_GET_MASTER_ROLE_DETAIL = '@@receive/GET_MASTER_ROLE_DETAIL';
export const FAIL_GET_MASTER_ROLE_DETAIL = '@@fail/GET_MASTER_ROLE_DETAIL';

export const REQUEST_GET_MASTER_PRODUCT = '@@request/SARCH_MASTER_ROLE';
export const RECEIVE_GET_MASTER_PRODUCT = '@@receive/GET_MASTER_PRODUCT';
export const FAIL_GET_MASTER_PRODUCT = '@@fail/GET_MASTER_PRODUCT';

export const getMasterProduct = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterproduct', criteria),
    method: 'GET',
    types: [REQUEST_GET_MASTER_PRODUCT, RECEIVE_GET_MASTER_PRODUCT, FAIL_GET_MASTER_PRODUCT],
  },
});

export const getMasterRoleDetail = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterroledetail', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_ROLE_DETAIL,
      RECEIVE_GET_MASTER_ROLE_DETAIL,
      FAIL_GET_MASTER_ROLE_DETAIL,
    ],
  },
});

export const saveMasterRoleDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterrole`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MASTER_ROLE, RECEIVE_SAVE_MASTER_ROLE, FAIL_SAVE_MASTER_ROLE],
  },
});

export const deleteMasterRole = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemasterrole`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [REQUEST_DELETE_MASTER_ROLE, RECEIVE_DELETE_MASTER_ROLE, FAIL_DELETE_MASTER_ROLE],
  },
});

export const searchMasterRole = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterrole', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_MASTER_ROLE, RECEIVE_SEARCH_MASTER_ROLE, FAIL_SEARCH_MASTER_ROLE],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_ROLE,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_ROLE_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_ROLE_CRITERIA,
  payload: {
    name,
    value,
  },
});
