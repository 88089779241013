import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import TableCashFlow from '../TableCashFlow';
import { toFixed } from '../../../common/helpper';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_BANK_CODE } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  formCashFlowRef,
  formResultCashFlowRef,

  state,
  onChangeInputData,

  onChangeInputTable,
  onClickGetCashflow,
  onChangeInputHeader,
  onChangeSelect2,
}) => (
  <>
    {state.permission ? (
      <>
        <Col2 col={colX[0]}>
          <Button
            txt="Get Cash Flow"
            icon="search"
            className="btn-info"
            onClick={onClickGetCashflow}
          />
        </Col2>
        <br />
      </>
    ) : null}

    {state.DerivativeType !== 'POS' ? (
      <Card textHeader="Reference" bgHeader="bg-info" cardActions={['toggler']}>
        <div className="table-responsive">
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th className="th-white">Period</th>
                <th className="th-white">Days</th>
                <th className="th-white">Payment Date</th>
                <th className="th-white">Direction</th>
                <th className="th-white">Flow Type</th>
                <th className="th-white">Amount</th>
                <th className="th-white">Currency</th>
                <th className="th-white">Bank Account</th>
                <th className="th-white">Payment Method</th>
                <th className="th-white">
                  Posting
                  <br />
                  Status
                </th>
                <th className="th-white">Exchange Rate</th>
                <th className="th-white">
                  Local Currency
                  <br />
                  Amount
                </th>
                <th className="th-white">
                  Local
                  <br />
                  Currency
                </th>
                <th className="th-white">Posting Date</th>
                <th className="th-white">Document No.</th>
              </tr>
            </thead>
            <tbody>
              {!(state.resultCashFlowReference || []).length ? (
                <tr>
                  <td colSpan="15" align="center">
                    No data available in table
                  </td>
                </tr>
              ) : null}
              {state.resultCashFlowReference.map((m, i) => (
                <tr key={i}>
                  <td align="center">
                    {m.startInterestDateStr} - {m.endInterestDateStr}
                  </td>
                  <td align="center">{m.interestDay}</td>
                  <td align="center">{m.paymentDateStr}</td>
                  <td align="center">{m.direction}</td>
                  <td align="center">{m.flowType}</td>
                  <td align="right">{setAmount(m.amount, m.digit)}</td>
                  <td align="center">{m.currency}</td>
                  <td>{m.bankAccountNo}</td>
                  <td>{m.paymentMethod}</td>
                  <td align="center">{m.postingStatus}</td>
                  <td align="right">{setAmount(m.exchangeRate, 8)}</td>
                  <td align="right">{setAmount(m.localCurrencyAmount, 3)}</td>
                  <td align="center">{m.localCurrency}</td>
                  <td align="center">{m.postingDateStr}</td>
                  <td align="center">
                    <a href="#">{m.documentNo}</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    ) : null}

    <Card textHeader="Swap" bgHeader="bg-warning" cardActions={['toggler']}>
      <form ref={formCashFlowRef} name="cashflow">
        <Col2 col={colX[2]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Bank" required>
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                noValidateOption
                saveLocalState={false}
                onChange={onChangeSelect2}
                // isChoose={true}
                // notMultipleSelect2={true}
                // notMultiple={false}
                required
                isGetAll
                customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                companyCode={state.CompanyCode}
                disabled={false}
                value={state.BankCodeCashFlow}
                name="BankCodeCashFlow"
              />
            </FormGroup2>
          </Col2>
        </Col2>
      </form>

      <TableCashFlow
        state={state.resultCashFlowSwap}
        onChangeInputTable={onChangeInputTable}
        resultType="cashflow"
        BankCode={state.BankCodeCashFlow}
        CompanyCode={state.CompanyCode}
        PartnerType={null}
        isBen={false}
        formRef={formResultCashFlowRef}
        formName="cashflow"
        onChangeInputHeader={onChangeInputHeader}
        bankAccountNoAll={state.AccountNoAll}
        paymentMethodAll={state.PaymentMethodAll}
        permission
        viewOnly={false}
      />
    </Card>
  </>
);

const setAmount = (number, float) => {
  if (float === null || float === undefined) float = 3;
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};
