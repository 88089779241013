import {
  RECEIVE_GET_MAINTAIN_FORM,
  EDIT_MAINTAIN_BENEFICIARY_CRITERIA,
  RECEIVE_SEARCH_MAINTAIN_BENEFICIARY,
  CLEAR_MAINTAIN_BENEFICIARY_CRITERIA,
  UPDATE_SEARCH_RESULT_MAINTAIN_BENEFICIARY,
} from './action';

const initCriteria = {
  BeneficiaryName: '',
  TaxId: '',
  FormNo: [''],
  IsActive: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
  formNoList: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_GET_MAINTAIN_FORM:
      return {
        ...state,
        formNoList: [...action.payload],
      };
    case UPDATE_SEARCH_RESULT_MAINTAIN_BENEFICIARY:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_MAINTAIN_BENEFICIARY_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MAINTAIN_BENEFICIARY_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_MAINTAIN_BENEFICIARY:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
