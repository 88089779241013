import React, { Component } from 'react';

class TablePaging extends Component {
  state = {
    pageIdx: 0,
    size: this.props.size || 10,
  };

  setPageIndex = (pageIdx) => {
    this.setState({
      pageIdx,
    });
  };

  renderRow = (noPaging) => {
    let { pageIdx, size } = this.state;
    const { value = [], renderRow, colNumber } = this.props;
    let data = [];
    let row = 0;
    if (!noPaging) {
      const startIdx = pageIdx * size;
      if (startIdx >= value.length) {
        if (value.length === 0)
          return (
            <tr>
              <td className="text-center" colSpan={colNumber}>
                No data available in table
              </td>
            </tr>
          );
        return null;
      }

      size = value.length - startIdx < size ? value.length - startIdx : size;
      const endIdx = startIdx + size;

      data = value.slice(startIdx, endIdx);
      row = startIdx;
    } else data = value;

    if (data.length === 0)
      return (
        <tr>
          <td className="text-center" colSpan={colNumber}>
            No data available in table
          </td>
        </tr>
      );

    return data.map((m) => renderRow(m, row++));
  };

  onClickChangePage = (page) => {
    this.setState({
      pageIdx: page,
    });
  };

  renderPageButton = () => {
    const { pageIdx } = this.state;

    const totalPage = this.getTotalPagenumber();
    const btns = [];
    for (let i = 0; i < totalPage; i++) {
      btns.push(
        <button
          key={i}
          className="btn btn-default btn-sm"
          disabled={pageIdx === i}
          onClick={(e) => this.onClickChangePage(i)}
        >
          {i + 1}
        </button>
      );
    }
    return btns;
  };

  onClickPrevBtn = () => {
    this.setState((state) => ({
      pageIdx: --state.pageIdx,
    }));
  };

  onClickNextBtn = () => {
    this.setState((state) => ({
      pageIdx: ++state.pageIdx,
    }));
  };

  getTotalPagenumber = () => {
    const { size } = this.state;
    const { value = [] } = this.props;

    const totalPage = Math.ceil(value.length / size);
    return totalPage;
  };

  render() {
    return (
      <div>
        <div className="table-responsive" style={{ maxHeight: '500px' }}>
          <table className="table table-bordered table-nowrap table-stripped">
            {this.props.children}
            <tbody>{this.renderRow(this.props.noPaging)}</tbody>
          </table>
        </div>
        {/* <div className="row" style={{paddingTop:'5px'}}>
                    <div className="col-sm-12 text-right">
                        <button className="btn btn-default btn-sm" onClick={this.onClickPrevBtn} disabled={pageIdx <= 0}>Prev</button>
                        {
                            this.renderPageButton()
                        }
                        <button className="btn btn-default btn-sm" onClick={this.onClickNextBtn} disabled={pageIdx >= this.getTotalPagenumber() - 1}>Next</button>
                    </div>
                </div> */}
      </div>
    );
  }
}

export default TablePaging;
