import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_REPORT_ACCOUNT_SAVING = '@@request/SEARCH_REPORT_ACCOUNT_SAVING';
export const RECEIVE_SEARCH_REPORT_ACCOUNT_SAVING = '@@receive/SEARCH_REPORT_ACCOUNT_SAVING';
export const FAIL_SEARCH_REPORT_ACCOUNT_SAVING = '@@fail/SEARCH_REPORT_ACCOUNT_SAVING';

export const REQUEST_DOWNLOAD_ACCOUNT_SAVING_REPORT = '@@request/DOWNLOAD_ACCOUNT_SAVING_REPORT';
export const RECEIVE_DOWNLOAD_ACCOUNT_SAVING_REPORT = '@@receive/DOWNLOAD_ACCOUNT_SAVING_REPORT';
export const FAIL_DOWNLOAD_ACCOUNT_SAVING_REPORT = '@@fail/DOWNLOAD_ACCOUNT_SAVING_REPORT';

export const getExportAccountSaving = (datas) => ({
  [RSAA]: {
    endpoint: endpoint('/report/ExportAccountSavingReport'),
    method: 'POST',
    body: JSON.stringify(datas),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DOWNLOAD_ACCOUNT_SAVING_REPORT,
      {
        type: RECEIVE_DOWNLOAD_ACCOUNT_SAVING_REPORT,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_DOWNLOAD_ACCOUNT_SAVING_REPORT,
    ],
  },
});

export const searchReportAccountSaving = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/getReportAccountSaving', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_REPORT_ACCOUNT_SAVING,
      RECEIVE_SEARCH_REPORT_ACCOUNT_SAVING,
      FAIL_SEARCH_REPORT_ACCOUNT_SAVING,
    ],
  },
});
