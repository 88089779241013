import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import { DATATABLE_ID } from './BookingForwardContainer';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { toNumber, toStatus, getPermission } from '../../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('FX Management', 'Create Exposure for Booking Forward');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        if (r.matchingNo || !r.isActive) {
          r.canSelect = false;
          return '';
        }
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (r.matchingNo) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'posoNo',
      render: (d) =>
        d
          ? `<a target="_blank" href="/fx-management/exposure-for-booking-forward/${d}" class="edit-exposure"> ${d} </a>`
          : '',
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'bankCode',
      render: (d, r, s) => (d ? `${s.bankCode} | ${s.bankAbbreviate} | ${s.bankName}` : 'Any'),
    },
    { data: 'productType' },
    { data: 'documentType' },
    { data: 'entryDateFromStr', className: 'text-center td-width-date', type: 'date-black' },
    { data: 'entryDateToStr', className: 'text-center td-width-date', type: 'date-black' },
    {
      data: 'hedgePortion',
      className: 'text-right',
      render: (d, t, r) => (d ? toNumber(Number(d), r.currencyDigit) : ''),
    },
    { data: 'currency', className: 'text-center' },
    { data: 'endDateStr', className: 'text-center td-width-date', type: 'date-black' },
    // { data: 'soPoNo' },
    { data: 'internalReference' },
    { data: 'requestType' },
    // { data: 'dealer' },
    // { data: 'department' },
    { data: 'gvc' },
    { data: 'characteristic' },
    { data: 'note1' },
    { data: 'note2' },
    { data: 'filename' },
    {
      data: 'status',
      className: 'text-center',
      render: (d, t, r) => {
        if (r.matchingNo) {
          return toStatus('Matching', true);
        }
        return r.isActive ? toStatus('Active', true) : toStatus('Inactive', true);
      },
    },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  results,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  dataTableRef,
  onClickMatching,
  modalDataRef,
  onSubmitMatching,
  onClikcOpenImportExcel,

  modalActiveRef,
  modalInactiveRef,
  onSubmitConfirm,

  onOpenEditBank,
  modalBankRef,
  modalEdit,
  onSaveBank,
  onChangeBank,

  onOpenClearInternalRef,
  modalInternalRef,
  onSaveInternalRef,
  compFilter,
  currencyFilter,

  modelInactiveMultiRef,
  onOpenInactiveMulti,
  onSaveInactiveMulti,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" /> Create Exposure for
            Booking Forward
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required={false}>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroup}
                  name="FinanceGroup"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnit}
                  name="BusinessUnit"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Company}
                  name="Company"
                  // saveLocalState={false}
                  // noValidateOption={false}
                  financeGroupId={criteria.FinanceGroup}
                  businessUnitId={criteria.BusinessUnit}
                  createDateStr={criteria.CreateDateStr}
                  filter={(m) => ~compFilter.indexOf(m.companyCode)}
                />
              </FormGroup2>
              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  onChange={onChangeSelect2}
                  // isAll={true}
                  isPoSo
                  isSearch
                  // notMultipleSelect2={true}
                  value={criteria.Bank}
                  name="Bank"
                  companyCode={criteria.Company}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Entry Date From">
                <DatePicker
                  onChange={onChangeSearch}
                  value={criteria.EntryDateFromStr}
                  name="EntryDateFromStr"
                />
              </FormGroup2>
              <FormGroup2 text="Entry Date To">
                <DatePicker
                  onChange={onChangeSearch}
                  value={criteria.EntryDateToStr}
                  name="EntryDateToStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  // isAll={true}
                  // notMultipleSelect2={true}
                  value={criteria.Currency}
                  createDateStr={criteria.CreateDateStr}
                  filter={(m) => ~currencyFilter.indexOf(m.currency)}
                  name="Currency"
                />
              </FormGroup2>
              <FormGroup2 text="Document Type">
                <MasterDropdownUI
                  value={criteria.DocumentType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="DocumentType"
                  options={['Sale Document', 'Purchase Document']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <MasterDropdownUI
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="Status"
                  options={['Active', 'Inactive', 'Matching']}
                />
              </FormGroup2>
              <FormGroup2 text="Create Date" required>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required
                  value={criteria.CreateDateStr}
                  name="CreateDateStr"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="End Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required={false}
                  value={criteria.EndDateStr}
                  name="EndDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div style={{ marginTop: 10 }}>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/fx-management/exposure-for-booking-forward/create"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    CREATE
                  </Link>
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div>
              <Button
                txt="Import Excel"
                icon="credit-card-alt"
                className="btn-default"
                onClick={onClikcOpenImportExcel}
              />
              &nbsp;
              <Button
                txt="Matching"
                icon="refresh"
                className="btn-info"
                onClick={onClickMatching}
              />
              &nbsp;
              <Button
                txt="Select Bank"
                icon="edit"
                className="btn-warning"
                onClick={onOpenEditBank}
              />
              &nbsp;
              <Button
                txt="Clear Internal Ref."
                icon="eraser"
                className="btn-clear"
                onClick={onOpenClearInternalRef}
              />
              &nbsp;
              <Button
                txt="Inactive"
                icon="close"
                className="btn-danger"
                onClick={onOpenInactiveMulti}
              />
              {/* &nbsp; */}
              {/* <Button txt="Unmatch" icon="undo" className="btn-warning" onClick={e=>console.log(e)}></Button> */}
            </div>
          </Col2>
        ) : null}

        <Table
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          ref={dataTableRef}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>Action</th>
              <th>Transaction No.</th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>
                Company
                <br />
                Name
              </th>
              <th>Bank</th>
              <th>Product Type</th>
              <th>Document Type</th>
              <th>Entry Date (From)</th>
              <th>Entry Date (To)</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>End Date</th>
              {/* <th>PO/SO No.</th> */}
              <th>Internal Ref. No.</th>
              <th>Request Type</th>
              {/* <th>Dealer</th>
                            <th>Department</th> */}
              <th>Purpose</th>
              <th>Purpose Detail</th>
              <th>Note 1</th>
              <th>Note 2</th>
              <th>Filename</th>
              <th>Status</th>
              <th>Created Date</th>
              <th>Created By</th>
              <th>Updated Date</th>
              <th>Updated By</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-md" ref={modalBankRef} modalFooter>
        <form onSubmit={onSaveBank} className="form-horizontal">
          <div className="text-center">
            {/* <span className="label label-info">{selectedResultCount} items</span> */}
            <h2 className="text-info">Change Bank</h2>
            <p>{selectedResultCount} items selected for select bank.</p>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-3">
              Bank<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-8">
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                notMultipleSelect2
                value={modalEdit.bankCode}
                onChange={(e) =>
                  onChangeBank({
                    target: e,
                  })
                }
                noValidateOption
                saveLocalState
                isAll
                isPoSo
                name="bankCode"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Matching"
        textContent="matching"
        modalRef={modalDataRef}
        type="info"
        icon="icon-refresh"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedResultCount}
        onClick={onSubmitMatching}
      />

      <ModalFunction
        textHeader="Clear Internal Ref."
        textContent="clear internal ref."
        modalRef={modalInternalRef}
        type="info"
        icon="icon-eraser"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedResultCount}
        onClick={onSaveInternalRef}
      />

      <ModalFunction
        textHeader="Inactive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        noSelect
        onClick={onSubmitConfirm}
      />

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        noSelect
        onClick={onSubmitConfirm}
      />

      <ModalFunction
        textHeader="Inactive"
        textContent="inactive"
        modalRef={modelInactiveMultiRef}
        type="danger"
        icon="icon-close"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedResultCount}
        onClick={onSaveInactiveMulti}
      />
    </div>
  );
};
