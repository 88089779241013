import { defaultImportExcelResult } from '../configuration';
import {
  RECEIVE_CANCEL_EXPOSURE,
  RECEIVE_ACTIVE_EXPOSURE,
  RECEIVE_SEARCH_CREATE_EXPOSURE,
  RECEIVE_VIEW_IMPORTEXCEL_EXPOSURE,
  RECEIVE_CREATE_EXPOSURE_IMPORT_EXCEL,
} from './action';

const init = {
  searchResult: [],
  importExcelResult: defaultImportExcelResult,
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_CREATE_EXPOSURE_IMPORT_EXCEL:
      return {
        ...state,
        importExcelResult: defaultImportExcelResult,
      };
    case RECEIVE_VIEW_IMPORTEXCEL_EXPOSURE:
      return {
        ...state,
        importExcelResult: action.payload,
      };
    case RECEIVE_SEARCH_CREATE_EXPOSURE:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    case RECEIVE_ACTIVE_EXPOSURE:
    case RECEIVE_CANCEL_EXPOSURE:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (i === action.payload.index)
            return {
              ...m,
              status:
                action.type === RECEIVE_ACTIVE_EXPOSURE ? 'Wait for Select Method' : 'Cancelled',
            };
          return m;
        }),
      };
    default:
      return state;
  }
};
