import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_REQUEST_BG_CRITERIA = 'EDIT_REQUEST_BG_CRITERIA';
export const CLEAR_REQUEST_BG_CRITERIA = 'CLEAR_REQUEST_BG_CRITERIA';

export const UPDATE_SEARCH_RESULT_REQUEST_BG = 'UPDATE_SEARCH_RESULT_REQUEST_BG';

export const REQUEST_SEARCH_REQUEST_BG = '@@request/SEARCH_REQUEST_BG';
export const RECEIVE_SEARCH_REQUEST_BG = '@@receive/SEARCH_REQUEST_BG';
export const FAIL_SEARCH_REQUEST_BG = '@@fail/SEARCH_REQUEST_BG';

export const REQUEST_SAVE_REQUEST_BG = '@@request/SAVE_REQUEST_BG';
export const RECEIVE_SAVE_REQUEST_BG = '@@receive/SAVE_REQUEST_BG';
export const FAIL_SAVE_REQUEST_BG = '@@fail/SAVE_REQUEST_BG';

export const REQUEST_DELETE_REQUEST_BG = '@@request/DELETE_REQUEST_BG';
export const RECEIVE_DELETE_REQUEST_BG =
  '@@receive-The record was successfully saved/DELETE_REQUEST_BG';
export const FAIL_DELETE_REQUEST_BG = '@@fail/DELETE_REQUEST_BG';

export const REQUEST_GET_MAINTAIN_BRANCH = '@@request/GET_MAINTAIN_BRANCH';
export const RECEIVE_GET_MAINTAIN_BRANCH = '@@receive/GET_MAINTAIN_BRANCH';
export const FAIL_GET_MAINTAIN_BRANCH = '@@fail/GET_MAINTAIN_BRANCH';

export const REQUEST_GET_MAINTAIN_BENEFICIARY = '@@request/GET_MAINTAIN_BENEFICIARY';
export const RECEIVE_GET_MAINTAIN_BENEFICIARY = '@@receive/GET_MAINTAIN_BENEFICIARY';
export const FAIL_GET_MAINTAIN_BENEFICIARY = '@@fail/GET_MAINTAIN_BENEFICIARY';

export const REQUEST_GET_MAINTAIN_REQUEST_BG = '@@request/GET_MAINTAIN_REQUEST_BG';
export const RECEIVE_GET_MAINTAIN_REQUEST_BG = '@@receive/GET_MAINTAIN_REQUEST_BG';
export const FAIL_GET_MAINTAIN_REQUEST_BG = '@@fail/GET_MAINTAIN_REQUEST_BG';

export const REQUEST_GET_BG_FIELD = '@@request/GET_BG_FIELD';
export const RECEIVE_GET_BG_FIELD = '@@receive/GET_BG_FIELD';
export const FAIL_GET_BG_FIELD = '@@fail/GET_BG_FIELD';

export const REQUEST_GET_BG_REQUEST = '@@request/GET_BG_REQUEST';
export const RECEIVE_GET_BG_REQUEST = '@@receive/GET_BG_REQUEST';
export const FAIL_GET_BG_REQUEST = '@@fail/GET_BG_REQUEST';

export const REQUEST_SAVE_ATTACHFILE_BG = '@@request/SAVE_ATTACHFILE_BG';
export const RECEIVE_SAVE_ATTACHFILE_BG = '@@receive/SAVE_ATTACHFILE_BG';
export const FAIL_SAVE_ATTACHFILE_BG = '@@fail/SAVE_ATTACHFILE_BG';

export const REQUEST_GET_ATTACHFILE_BG = '@@request/GET_ATTACHFILE_BG';
export const RECEIVE_GET_ATTACHFILE_BG = '@@receive/GET_ATTACHFILE_BG';
export const FAIL_GET_ATTACHFILE_BG = '@@fail/GET_ATTACHFILE_BG';

export const REQUEST_DOWNLOAD_ATTACHFILE_BG = '@@request/DOWNLOAD_ATTACHFILE_BG';
export const RECEIVE_DOWNLOAD_ATTACHFILE_BG = '@@receive/DOWNLOAD_ATTACHFILE_BG';
export const FAIL_DOWNLOAD_ATTACHFILE_BG = '@@fail/DOWNLOAD_ATTACHFILE_BG';

export const REQUEST_GET_DEFAULT_BG = '@@request/GET_DEFAULT_BG';
export const RECEIVE_GET_DEFAULT_BG = '@@receive/GET_DEFAULT_BG';
export const FAIL_GET_DEFAULT_BG = '@@fail/GET_DEFAULT_BG';

export const REQUEST_GET_MASTER_ROLE_USER_BG = '@@request/GET_MASTER_ROLE_USER_BG';
export const RECEIVE_GET_MASTER_ROLE_USER_BG = '@@receive/GET_MASTER_ROLE_USER_BG';
export const FAIL_GET_MASTER_ROLE_USER_BG = '@@fail/GET_MASTER_ROLE_USER_BG';

export const REQUEST_PRINT_REFUND_FEE = '@@request/PRINT_REFUND_FEE';
export const RECEIVE_PRINT_REFUND_FEE = '@@receive/PRINT_REFUND_FEE';
export const FAIL_PRINT_REFUND_FEE = '@@fail/PRINT_REFUND_FEE';

export const REQUEST_GET_FACILITY_LIST = '@@request-noload/GET_FACILITY_LIST';
export const RECEIVE_GET_FACILITY_LIST = '@@receive/GET_FACILITY_LIST';
export const FAIL_GET_FACILITY_LIST = '@@fail/GET_FACILITY_LIST';

export const getFacilityList = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/getfacilitylist', criteria),
    method: 'GET',
    types: [REQUEST_GET_FACILITY_LIST, RECEIVE_GET_FACILITY_LIST, FAIL_GET_FACILITY_LIST],
  },
});

export const downloadRefundFee = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/DownloadRefundFee'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_PRINT_REFUND_FEE,
      {
        type: RECEIVE_PRINT_REFUND_FEE,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_PRINT_REFUND_FEE,
    ],
  },
});

export const getRoleUserBg = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getroleuserbg', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_ROLE_USER_BG,
      RECEIVE_GET_MASTER_ROLE_USER_BG,
      FAIL_GET_MASTER_ROLE_USER_BG,
    ],
  },
});

export const getDefaultBg = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/company', criteria),
    method: 'GET',
    types: [REQUEST_GET_DEFAULT_BG, RECEIVE_GET_DEFAULT_BG, FAIL_GET_DEFAULT_BG],
  },
});

export const downloadAttachment = (id) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/DownloadAttachment/${id}`),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_ATTACHFILE_BG,
      {
        type: RECEIVE_DOWNLOAD_ATTACHFILE_BG,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_DOWNLOAD_ATTACHFILE_BG,
    ],
  },
});

export const getAttachment = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/getAttachment/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_ATTACHFILE_BG, RECEIVE_GET_ATTACHFILE_BG, FAIL_GET_ATTACHFILE_BG],
  },
});

export const saveAttachFile = (form, transactionNo, isAmend, oldTransactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(
      `/bankguarantee/SaveAttachFile/${transactionNo}/${isAmend || 'false'}/${
        oldTransactionNo || 'none'
      }`
    ),
    method: 'POST',
    body: form,
    types: [REQUEST_SAVE_ATTACHFILE_BG, RECEIVE_SAVE_ATTACHFILE_BG, FAIL_SAVE_ATTACHFILE_BG],
  },
});

export const getBgRequest = (transctionNo, id) => ({
  [RSAA]: {
    endpoint: endpoint(
      id
        ? `/bankguarantee/GetBgRequestDetail/${transctionNo}/${id}`
        : `/bankguarantee/GetBgRequestDetail/${transctionNo}`
    ),
    method: 'GET',
    types: [REQUEST_GET_BG_REQUEST, RECEIVE_GET_BG_REQUEST, FAIL_GET_BG_REQUEST],
  },
});

export const getBgField = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getbgfield', criteria),
    method: 'GET',
    types: [REQUEST_GET_BG_FIELD, RECEIVE_GET_BG_FIELD, FAIL_GET_BG_FIELD],
  },
});

export const getMaintainRequestBg = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getmaitainrequestbg', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_REQUEST_BG,
      RECEIVE_GET_MAINTAIN_REQUEST_BG,
      FAIL_GET_MAINTAIN_REQUEST_BG,
    ],
  },
});

export const getMaintainBeneficiary = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getmaitainbeneficiary', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_BENEFICIARY,
      RECEIVE_GET_MAINTAIN_BENEFICIARY,
      FAIL_GET_MAINTAIN_BENEFICIARY,
    ],
  },
});

export const getMaintainBranch = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getmaintainbranch', criteria),
    method: 'GET',
    types: [REQUEST_GET_MAINTAIN_BRANCH, RECEIVE_GET_MAINTAIN_BRANCH, FAIL_GET_MAINTAIN_BRANCH],
  },
});

export const deleteRequestBg = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/deleteRequestBg`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [REQUEST_DELETE_REQUEST_BG, RECEIVE_DELETE_REQUEST_BG, FAIL_DELETE_REQUEST_BG],
  },
});

export const saveBgRequest = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/savebgrequest`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_REQUEST_BG, RECEIVE_SAVE_REQUEST_BG, FAIL_SAVE_REQUEST_BG],
  },
});

export const searchRequestBg = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getbgrequest', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_REQUEST_BG, RECEIVE_SEARCH_REQUEST_BG, FAIL_SEARCH_REQUEST_BG],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_REQUEST_BG,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_REQUEST_BG_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_REQUEST_BG_CRITERIA,
  payload: {
    name,
    value,
  },
});
