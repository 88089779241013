import React, { Fragment } from 'react';

import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import RadioButton from '../../../common/RadioButton';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ state, modalRef }) => {
  const inputMask = state.inputMaskAmount || {
    prefix: '',
    digits: 2,
    digitsOptional: false,
    placeholder: '0.00',
  };
  return (
    <Modal textHeader="Withdraw History" bgHeader="bg-primary" size="modal-lg" ref={modalRef}>
      <form>
        <Col2 col={colX[0]}>
          <div className="form-group">
            <div>
              <input
                id="IsWithdrawAll"
                type="checkbox"
                className="label-checkbox"
                name="IsRepayAll"
                checked={state.IsRepayAll || false}
              />
              <label htmlFor="IsWithdrawAll" className="label_checkbox" />
              <label htmlFor="IsWithdrawAll" className="control-label text_label_checkbox">
                Withdraw All
              </label>
            </div>
          </div>
          <FormGroup2 text="Withdraw Date">
            <input
              type="text"
              className="form-control"
              value={state.RepaymentDateStr || ''}
              readOnly
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[0]}>
          <FormGroup2 text="Deposit Type">
            <div>
              <RadioButton
                text="On Call"
                value="On Call"
                checked={state.LoanType === 'On Call'}
                name="LoanType"
                readOnly
              />
              <RadioButton
                text="Term"
                value="Term"
                checked={state.LoanType === 'Term'}
                readOnly
                name="LoanType"
              />
            </div>
          </FormGroup2>
          <FormGroup2 text="Rollover Date / Term Start">
            <input
              type="text"
              className="form-control"
              value={state.RollOverDateStr || ''}
              readOnly
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <div>
              <FormGroup2 text="Existing Deposit Amount">
                <InputMask
                  className="form-control"
                  format="currency"
                  option={inputMask}
                  readOnly
                  value={state.Amount || ''}
                  name="Amount"
                />
              </FormGroup2>

              <FormGroup2 text="Interest (%)">
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{ ...inputMask, digits: 5 }}
                  readOnly
                  value={state.NewInterestRate || ''}
                  name="NewInterestRate"
                />
              </FormGroup2>

              <FormGroup2 text="Interest Amount">
                <InputMask
                  className="form-control"
                  format="currency"
                  option={inputMask}
                  readOnly
                  value={state.Interest || ''}
                  name="Interest"
                />
              </FormGroup2>

              <FormGroup2 text="Withholding Tax">
                <InputMask
                  className="form-control"
                  format="currency"
                  option={inputMask}
                  readOnly
                  value={state.WithHoldingTax || ''}
                  name="WithHoldingTax"
                />
              </FormGroup2>

              <div className="form-group">
                <div>
                  <input
                    id="IsIncluding"
                    type="checkbox"
                    className="label-checkbox"
                    name="IsIncluding"
                    checked={state.IsIncluding || false}
                    readOnly
                  />
                  <label htmlFor="IsIncluding" className="label_checkbox" />
                  <label htmlFor="IsIncluding" className="control-label text_label_checkbox">
                    Including Interest From Last Period
                  </label>
                </div>
              </div>

              <div className="form-group">
                <label className="control-label" style={{ marginRight: '10px' }}>
                  Increase/Decrease
                </label>
                <label className="custom-label">
                  <div>
                    <RadioButton
                      text="Increase"
                      value="Increase"
                      checked={state.IncreaseDecrease === 'Increase'}
                      name="IncreaseDecrease"
                      readOnly
                    />
                    <RadioButton
                      text="Decrease"
                      value="Decrease"
                      checked={state.IncreaseDecrease === 'Decrease'}
                      readOnly
                      name="IncreaseDecrease"
                    />
                  </div>
                </label>
                <div className="form-group">
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={inputMask}
                    readOnly
                    value={state.IncreaseDecreaseAmount || ''}
                    name="IncreaseDecreaseAmount"
                  />
                </div>
              </div>

              <FormGroup2 text="New Deposit Amount">
                <InputMask
                  className="form-control"
                  format="currency"
                  option={inputMask}
                  readOnly
                  value={state.NewPnAmount || ''}
                  name="NewPnAmount"
                />
              </FormGroup2>
            </div>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              {renderTermend()}
              {renderTTInterest()}
              <hr />
              {renderTTPrincipal()}
              {renderAdditionFee()}
            </div>
          </Col2>
        </Col2>
      </form>
    </Modal>
  );

  function renderTermend() {
    if (state.Source === 'Contract On Call') return null;
    return (
      <FormGroup2 text="Term End">
        <input type="text" className="form-control" value={state.TermEndStr || ''} readOnly />
      </FormGroup2>
    );
  }

  function renderAdditionFee() {
    return (
      <>
        <FormGroup2 text="Duty Fee">
          <InputMask
            className="form-control"
            format="currency"
            option={inputMask}
            readOnly
            value={state.StampDutyFee || ''}
          />
        </FormGroup2>
        <FormGroup2 text="Bank Charge">
          <InputMask
            className="form-control"
            format="currency"
            option={inputMask}
            readOnly
            value={state.BankCharge || ''}
          />
        </FormGroup2>
      </>
    );
  }

  function renderTTInterest() {
    return (
      <>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="control-label">Payment Method</label>
            <input disabled className="form-control" value={state.PaymentMethodInterest} />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="control-label">Bank Account</label>
            <input disabled className="form-control" value={state.BankAccountNoInterestFullName} />
          </div>
          <div className="form-group col-md-6">
            <label className="control-label">Bank Account Partner</label>
            <input
              disabled
              className="form-control"
              value={state.BankAccountNoPartnerInterestFullName}
            />
          </div>
        </div>
      </>
    );
  }

  function renderTTPrincipal() {
    let AmountLabel = 'TT Amount';
    if (state.PaymentMethodPrincipal === 'Bahtnet') AmountLabel = 'BahtNet Amount';
    return (
      <>
        <div className="row">
          <div className="form-group col-md-6">
            <label className="control-label">Payment Method</label>
            <input disabled className="form-control" value={state.PaymentMethodPrincipal} />
          </div>
          <div className="form-group col-md-6">
            <label className="control-label">{AmountLabel}</label>
            <InputMask
              className="form-control"
              format="currency"
              option={inputMask}
              readOnly
              value={state.TtAmount || ''}
            />
          </div>
        </div>

        <div className="row">
          <div className="form-group col-md-6">
            <label className="control-label">Bank Account</label>
            <input disabled className="form-control" value={state.BankAccountNoPrincipalFullName} />
          </div>
          <div className="form-group col-md-6">
            <label className="control-label">Bank Account Partner</label>
            <input
              disabled
              className="form-control"
              value={state.BankAccountNoPartnerPrincipalFullName}
            />
          </div>
        </div>
      </>
    );
  }
};
