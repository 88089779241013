import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import ModalClearing from './ModalClearing';
import ModalManualPost from './ModalManualPost';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import ModalFunction2 from '../../common/ModalFunction2';
import ButtonDropdown from '../../common/ButtonDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import { DATATABLE_ID, DATATABLE_ID_2 } from './ForeignExchangeContainer';
import { MASTER_COMPANY, MASTER_CURRENCY } from '../../reducers/master/action';
import { toLower, toNumber, toStatus, toAccount, getPermission } from '../../common/helpper';
import MasterDropdownUI, { INSTANCE_CODE, ACCOUNT_STANDARD } from '../../common/MasterDropdownUI';

const permission = getPermission('Accounting', 'Foreign Exchange');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  deferRender: true,
  scroller: true,
  paging: true,
  order: [[3, 'asc']],
  fixedColumns: {
    leftColumns: 1,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'accountingStandard' },
    { data: 'productType', className: 'text-center' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'activity', className: 'text-center' },
    { data: 'transactionType', className: 'text-center' },
    { data: 'partnerCode', className: 'text-center' },
    { data: 'partnerName' },
    {
      data: 'assignFrom',
      render: (d, t, r) => {
        if (d) return `${r.assignFrom} | ${r.assignFromName}`;
        return '';
      },
    },
    {
      data: 'transactionNo',
      render: (d) => `<a target="_blank" href="/fx-management/forward-detail/${d}"> ${d} </a>`,
    },
    { data: 'flowNo', className: 'text-center' },
    { data: 'flowName' },
    { data: 'postingProcess' },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    {
      data: 'drCr',
      className: 'text-center',
      render: (d, t, r) => {
        if (toLower(d) === 'dr') return '+';
        return '-';
      },
    },
    {
      data: 'forwardAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, 2),
    },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'spotRate', className: 'text-right td-width-date', render: (d) => toNumber(d, 8) },
    { data: 'baseRate', className: 'text-right td-width-date', render: (d) => toNumber(d, 8) },
    { data: 'forwardRate', className: 'text-right td-width-date', render: (d) => toNumber(d, 8) },
    { data: 'swapRate', className: 'text-right td-width-date', render: (d) => toNumber(d, 8) },
    { data: 'executeRate', className: 'text-right td-width-date', render: (d) => toNumber(d, 8) },
    {
      data: 'bookBankAmount',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, 2),
    },
    { data: 'bookBankCurrency', className: 'text-center' },
    { data: 'contractNo' },
    { data: 'postingDocumentNo' },
    { data: 'clearingDocumentNo' },
    { data: 'reverseDocumentNo' },
    {
      data: 'gainLossBankAccount',
      render: (d, r, s) => {
        if (d)
          return toAccount(
            s.gainLossBankAbbreviate,
            s.gainLossBranchName,
            s.gainLossAccountTypeCode,
            s.gainLossBankCurrency,
            d
          );
        return '';
      },
    },
    { data: 'paymentMethod', className: 'text-center' },
    { data: 'purpose' },
    { data: 'purposeDetails' },
    { data: 'instanceName' },
    { data: 'rolloverReference' },
    { data: 'postGlStatus', className: 'text-center', render: (d) => toStatus(d) },
    { data: 'postingDateStr', className: 'text-center', type: 'date-black' },
    { data: 'contractDateStr', className: 'text-center', type: 'date-black' },
    { data: 'startDateStr', className: 'text-center', type: 'date-black' },
    { data: 'endDateStr', className: 'text-center', type: 'date-black' },
    {
      data: 'businessArea',
      render: (d, t, r) => {
        if (!d) return '';
        return r.businessAreaName;
      },
    },
    { data: 'postingMessage' },
    { data: 'clearingPostingMessage' },
    { data: 'reversePostingMessage' },
    { data: 'postingStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    { data: 'clearingStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    { data: 'reverseStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    {
      orderable: false,
      data: null,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission || toLower(r.postGlStatus) !== 'completed' || r.isReverse === true)
          return '';
        return '<button class="btn btn-icon btn-warning action-reverse"><span class="icon icon-undo sq-24"></span></button>';
      },
    },
    // {
    //     orderable: false, data: null, className: 'text-center',
    //     render: (d, t, r) => {
    //         if (!permission || toLower(r.postGlStatus) !== 'completed' || r.isReverse === true) return ''
    //         return '<button class="btn btn-icon btn-primary action-clearing"><span class="icon icon-check sq-24"></span></button>'
    //     }
    // },
  ],
};

export default ({
  criteria,
  results,
  dataTableRef,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,

  modalPostRef,
  onClickOpenModalPost,
  onClickConfirmModalPost,
  modalPost,

  modalReverseRef,
  onClickConfirmModalReverse,
  modalClearingRef,
  onClickConfirmModalClearing,

  modalGenXmlRef,
  onClickOpenModalGenXml,
  onClickConfirmModalGenXml,

  modalEdit,
  modalEditRef,
  onClickOpenModalEdit,
  onChangeModalEdit,
  onSubmitModalEdit,

  modalManual,
  modalManualRef,
  onClickOpenModalManual,
  onChangeModalManual,
  onSubmitModalManual,

  onClickOpenModalFlag,
  onClickOpenModalUnFlag,
  onClickSubmitModalFlag,
  modalFlagRef,
  modalUnFlagRef,

  onClickOpenModalForwardClearing,
  modalForwardClearing,
  modalForwardClearingRef,
  onChangeModalForwardClearing,
  onClickSubmitModalForwardClearing,
  dataTableClearingRef,
  resultClearing,

  isAdmin,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Accounting <span className="icon icon-angle-double-right" /> Flow Posting{' '}
            <span className="icon icon-angle-double-right" /> Foreign Exchange
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  saveLocalState
                  name="Companies"
                />
              </FormGroup2>

              <FormGroup2 text="Forward Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.ForwardCurrency}
                  name="ForwardCurrency"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Book Bank Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.BookBankCurrency}
                  name="BookBankCurrency"
                />
              </FormGroup2>

              <FormGroup2 text="Instance" required={false}>
                <MasterDropdownUI
                  masterType={INSTANCE_CODE}
                  onChange={onChangeSearch}
                  isChoose
                  notMultiple
                  required={false}
                  value={criteria.InstanceCode}
                  name="InstanceCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Product Type" required={false}>
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.ProductType}
                  required={false}
                  name="ProductType"
                  options={['Forward Main', 'Forward Special']}
                />
              </FormGroup2>

              <FormGroup2 text="Transaction Type">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.TransactionType}
                  name="TransactionType"
                  options={['Buy', 'Sell']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Forward Type">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.ForwardType}
                  name="ForwardType"
                  options={['Fixed', 'Par', 'Pro Rata']}
                />
              </FormGroup2>

              <FormGroup2 text="Activity">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.Activity}
                  name="Activity"
                  options={['Main', 'Utilize', 'Unwind', 'Rollover', 'Main Rollover']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Posting Process">
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.PostingProcess}
                  name="PostingProcess"
                  options={['Daily', 'Month-End']}
                />
              </FormGroup2>

              <FormGroup2 text="Post G/L Status">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.PostGLStatus}
                  name="PostGLStatus"
                  options={['New', 'Waiting', 'Failed', 'Completed', 'No Post']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Purpose">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.GenValnClassTerm}
                  name="GenValnClassTerm"
                  options={[
                    'AR Trade',
                    'AP Trade',
                    'ST Loan',
                    'LT Loan',
                    'Accrued Interest',
                    'AP PPR (Project)',
                    'AP Investment',
                  ]}
                />
              </FormGroup2>

              <FormGroup2 text="Accounting Standard">
                <MasterDropdownUI
                  masterType={ACCOUNT_STANDARD}
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.AccountingStandard}
                  name="AccountingStandard"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Value Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required={false}
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                />
              </FormGroup2>

              <FormGroup2 text="Posting Date" required>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required
                  value={criteria.PostingDateStr}
                  name="PostingDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Transaction No.">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.TransactionNo}
                  name="TransactionNo"
                />
              </FormGroup2>

              <FormGroup2 text="Contract No.">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.ContractNo}
                  name="ContractNo"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Contract Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.ContractDateStr}
                  name="ContractDateStr"
                />
              </FormGroup2>

              <FormGroup2 text="Start Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.StartDateStr}
                  name="StartDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="End Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.EndDateStr}
                  name="EndDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>
          <br />
          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div>
              <Button
                txt="Post"
                icon="retweet"
                className="btn-primary"
                onClick={onClickOpenModalPost}
                disabled={false}
              />
              &nbsp;
              <Button
                txt="Change Posting Date"
                icon="calendar"
                className="btn-info"
                onClick={onClickOpenModalEdit}
                disabled={false}
              />
              &nbsp;
              <Button
                txt="Gen XML"
                icon="download"
                className="btn-default"
                onClick={onClickOpenModalGenXml}
                disabled
              />
              &nbsp;
              <ButtonDropdown
                className="btn-blank"
                dropdownMenu="left"
                text="Manual"
                icon="icon-reorder"
                buttons={[
                  {
                    text: 'Post',
                    icon: 'icon-retweet',
                    // disabled: true,
                    onClick: (e) => onClickOpenModalManual('post'),
                  },
                  // {
                  //     text: 'Clearing',
                  //     icon: 'icon-eraser',
                  //     // disabled: true,
                  //     onClick: e => onClickOpenModalManual("clearing")
                  // },
                  {
                    text: 'Reverse',
                    icon: 'icon-undo',
                    // disabled: true,
                    onClick: (e) => onClickOpenModalManual('reverse'),
                  },
                ]}
              />
              &nbsp;
              {isAdmin ? (
                <>
                  <Button
                    txt="Flag No Post"
                    icon="flag"
                    className="btn-success"
                    onClick={onClickOpenModalFlag}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Unflag"
                icon="undo"
                className="btn-warning"
                onClick={onClickOpenModalUnFlag}
                disabled={false}
              />
              &nbsp;
              <Button
                txt="Clearing"
                icon="eraser"
                className="btn-purple"
                onClick={onClickOpenModalForwardClearing}
                disabled={false}
              />
            </div>
          </Col2>
        ) : null}
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br /> Code
              </th>
              <th>Company Name</th>
              <th>
                Accounting
                <br /> Standard
              </th>
              <th>Product Type</th>
              <th>Forward Type</th>
              <th>Activity</th>
              <th>
                Transaction
                <br /> Type
              </th>
              <th>
                Partner
                <br /> Code
              </th>
              <th>Partner Name</th>
              <th>
                SP.Forward
                <br /> Assign From
              </th>
              <th>Transaction No.</th>
              <th>Flow No.</th>
              <th>Flow Name</th>
              <th>
                Posting
                <br /> Process
              </th>
              <th>Value Date</th>
              <th>D</th>
              <th className="text-center">
                Forward
                <br /> Amount
              </th>
              <th>
                Forward
                <br /> Currency
              </th>
              <th className="text-center">Spot Rate</th>
              <th className="text-center">Base Rate</th>
              <th className="text-center">Forward Rate</th>
              <th className="text-center">Swap Rate</th>
              <th className="text-center">Execute Rate</th>
              <th className="text-center">
                Book Bank
                <br /> Amount
              </th>
              <th>
                Book Bank
                <br /> Currency
              </th>
              <th>Contract No.</th>
              <th>Posting Doc. No.</th>
              <th>Clearing Doc No.</th>
              <th>Reverse Doc. No.</th>
              <th>
                Gain/Loss
                <br /> Bank Account
              </th>
              <th>Payment Method</th>
              <th>Purpose</th>
              <th>Purpose Details</th>
              <th>Instance Name</th>
              <th>Rollover Ref.</th>
              <th>Post G/L Status</th>
              <th>Posting Date</th>
              <th>Contract Date</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Business Area</th>
              <th>Posting Message</th>
              <th>
                Clearing
                <br /> Posting Message
              </th>
              <th>
                Reverse
                <br /> Posting Message
              </th>
              <th>
                Posting
                <br /> Status
              </th>
              <th>
                Clearing
                <br /> Status
              </th>
              <th>
                Reverse
                <br /> Status
              </th>
              <th>Reverse</th>
              {/* <th>Check Clearing</th> */}
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-md" ref={modalEditRef} modalFooter>
        <form onSubmit={onSubmitModalEdit} className="form-horizontal">
          <div className="text-center">
            <span className="label label-info">{selectedResultCount} items</span>
            <h2 className="text-info">Change Posting Date</h2>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Posting Date<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-7">
              <DatePicker
                onChange={onChangeModalEdit}
                name="PostingDateStr"
                value={modalEdit.PostingDateStr}
                option={{
                  // startDate: "d",
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                required
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction2
        textHeader="Post"
        textContent="post"
        modalRef={modalPostRef}
        type="primary"
        icon="icon-retweet"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail
        selectedLength={selectedResultCount}
        passLength={modalPost.passLength}
        result={modalPost.resultFail}
        onClick={onClickConfirmModalPost}
      />

      <ModalFunction
        textHeader="Reverse"
        textContent="reverse"
        modalRef={modalReverseRef}
        type="warning"
        icon="icon-undo"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={onClickConfirmModalReverse}
      />

      <ModalFunction
        textHeader="Clearing"
        textContent="clearing"
        modalRef={modalClearingRef}
        type="warning"
        icon="icon-undo"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={onClickConfirmModalClearing}
      />

      <ModalFunction
        textHeader="Gen XML"
        textContent="gen XML"
        modalRef={modalGenXmlRef}
        type="info"
        icon="icon-download"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onClickConfirmModalGenXml}
      />

      <ModalManualPost
        modalRef={modalManualRef}
        state={modalManual}
        onSubmit={onSubmitModalManual}
        onChageModal={onChangeModalManual}
      />

      <ModalFunction
        textHeader="Flag no post"
        textContent="flag no post"
        modalRef={modalFlagRef}
        type="success"
        icon="icon-flag"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onClickSubmitModalFlag}
      />

      <ModalFunction
        textHeader="Unflag"
        textContent="unflag"
        modalRef={modalUnFlagRef}
        type="warning"
        icon="icon-undo"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onClickSubmitModalFlag}
      />

      <ModalClearing
        modalRef={modalForwardClearingRef}
        state={modalForwardClearing}
        onSubmit={onClickSubmitModalForwardClearing}
        onChageModal={onChangeModalForwardClearing}
        DATATABLE_ID={DATATABLE_ID_2}
        dataTableRef={dataTableClearingRef}
        resultClearing={resultClearing}
      />
    </div>
  );
};
