import { connect } from 'react-redux';
import React, { Component } from 'react';

import InterestReport from './InterestReport';
import {
  editCriteria,
  clearCriteria,
  searchInterestReport,
  downloadInterestReport,
} from '../../../reducers/interestReport/action';
// import { toLower } from '../../../common/helpper';

export const DATATABLE_ID = 'table-result-interest-report';

class InterestReportContainer extends Component {
  state = {
    datas: [],
  };

  dataTableSearchResultRef = null;

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;

    if (name === 'PartnerType') {
      this.props.editCriteria('Partner', ['']);
    }
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchInterestReport({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchResult !== this.props.searchResult) {
      this.mapData(this.props.searchResult);
    }
  }

  onClickDownloadInterestReport = (idxGroup) => {
    const selected = this.state.datas[idxGroup];
    if (!selected) return;

    this.props.downloadInterestReport(selected.items);
  };

  mapData(datas = []) {
    const groups = [];
    datas.forEach((m) => {
      const {
        companyCode,
        companyAbbreviate,
        companyName,
        financeGroupName,
        businessUnitName,
        partnerCode,
        partnerAbbreviate,
        partnerName,
        transactionCurrency,
        productType,
      } = m;
      const findGroup = groups.find(
        (f) =>
          f.companyCode === companyCode &&
          f.partnerCode === partnerCode &&
          f.transactionCurrency === transactionCurrency &&
          f.productType === productType
      );

      if (!findGroup) {
        groups.push({
          companyCode,
          companyAbbreviate,
          companyName,
          partnerCode,
          partnerAbbreviate,
          partnerName,
          financeGroupName,
          businessUnitName,
          transactionCurrency,
          productType,
          items: [m],
        });
      } else {
        findGroup.items.push(m);
      }
    });

    groups.forEach((m) => {
      const { items } = m;
      let amount = 0;
      let amountInterest = 0;
      let amountAccrued = 0;
      let sumInterestAmount = 0;
      let transactionCurrency = '';
      let withHoldingTaxValue = 0;
      let withHoldingTaxAmount = 0;
      let netAmount = 0;
      let digit = 0;

      items.forEach((r) => {
        // r.sumInterestAmount =  (r.amountInterest||0) + (r.amountAccrued||0)
        // r.withHoldingTaxAmount = toLower(r.interestType) === 'loan' ? Math.abs(r.withHoldingTaxAmount) * (-1) : Math.abs(r.withHoldingTaxAmount)
        // r.netAmount = r.sumInterestAmount + (r.withHoldingTaxAmount||0)

        amount += r.amount;
        amountInterest += r.amountInterest;
        amountAccrued += r.amountAccrued;
        sumInterestAmount += r.sumInterestAmount;
        transactionCurrency = r.transactionCurrency;
        withHoldingTaxValue = r.withHoldingTaxValue;
        withHoldingTaxAmount += r.withHoldingTaxAmount;
        netAmount += r.netAmount;
        digit = r.digit;
      });

      m.total = {
        amount,
        amountInterest,
        amountAccrued,
        sumInterestAmount,
        transactionCurrency,
        withHoldingTaxValue,
        withHoldingTaxAmount,
        netAmount,
        digit,
      };
    });

    this.setState({
      datas: groups,
    });
  }

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      onClickDownloadInterestReport: this.onClickDownloadInterestReport,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
    };

    return (
      <div>
        <InterestReport {...props} {...this.state} criteria={this.props.criteria} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.interestReport,
  }),
  {
    searchInterestReport,
    editCriteria,
    clearCriteria,
    downloadInterestReport,
  }
)(InterestReportContainer);
