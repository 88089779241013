import React, { useEffect, useReducer, useCallback } from 'react';

import { store } from '../../Root';
import Card from '../../common/Card';
import Button from '../../common/Button';
import { toupperKey } from '../../common/helpper';
import {
  getInterestReceiptMaintain,
  submitInterestReceiptMaintain,
} from '../../reducers/printInterestReceipt/action';

const checkboxsName = ['IsHardCopy', 'IsElectronic'];
const inputCountMapping = {
  IsHardCopy: 'HardCopyCount',
  IsElectronic: 'ElectronicCount',
};
const copyTypes = ['Original For Customer', 'Copy  For Customer', 'Copy For Accounting'];
const copyTypeLabels = [
  'ต้นฉบับ สำหรับลูกค้า / Original For Customer',
  'สำเนา สำหรับลูกค้า / Copy  For Customer',
  'สำเนา สำหรับเก็บเป็นหลักฐานทางบัญชี / Copy For Accounting',
];
const templateTypes = ['Master', 'Member'];
const defaultTemplateItem = {
  Id: null,
  Type: '',
  IsHardCopy: false,
  IsElectronic: false,
  ElectronicCount: null,
  HardCopyCount: null,
};
const defaultTemplate = {
  Id: null,
  Type: '',
  IsIc: false,
  IsPrint: false,
  IsDms: false,
  IsAutoInterestIncome: false,
  PrintInterestReceiptMaintainTemplateItems: [],
};
const defaultState = {
  Type: '',
  Id: null,
  PrintInterestReceiptMaintainTemplates: [],
};

const getDefaultState = () => ({
  ...defaultState,
  PrintInterestReceiptMaintainTemplates: [
    {
      ...defaultTemplate,
      Type: 'Master',
      PrintInterestReceiptMaintainTemplateItems: [
        {
          ...defaultTemplateItem,
          Type: 'Original For Customer',
        },
        {
          ...defaultTemplateItem,
          Type: 'Copy  For Customer',
        },
        {
          ...defaultTemplateItem,
          Type: 'Copy For Accounting',
        },
      ],
    },
    {
      ...defaultTemplate,
      Type: 'Member',
      PrintInterestReceiptMaintainTemplateItems: [
        {
          ...defaultTemplateItem,
          Type: 'Original For Customer',
        },
        {
          ...defaultTemplateItem,
          Type: 'Copy  For Customer',
        },
        {
          ...defaultTemplateItem,
          Type: 'Copy For Accounting',
        },
      ],
    },
  ],
});

const reducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE_TEMPLATE':
      return {
        ...state,
        PrintInterestReceiptMaintainTemplates: state.PrintInterestReceiptMaintainTemplates.map(
          (m, index) => {
            if (m.Type === action.templateType)
              return {
                ...m,
                [action.name]: action.value,
              };
            return m;
          }
        ),
      };
    case 'CHANGE_TEMPLATE_ITEM':
      return {
        ...state,
        PrintInterestReceiptMaintainTemplates: state.PrintInterestReceiptMaintainTemplates.map(
          (m, index) => {
            if (m.Type === action.templateType)
              return {
                ...m,
                PrintInterestReceiptMaintainTemplateItems:
                  m.PrintInterestReceiptMaintainTemplateItems.map((itemM, mIndex) => {
                    if (itemM.Type === action.itemType) {
                      const newItem = {
                        ...itemM,
                        [action.name]: action.value,
                      };

                      if (checkboxsName.includes(action.name)) {
                        newItem[inputCountMapping[action.name]] = null;
                      }
                      return newItem;
                    }

                    return itemM;
                  }),
              };
            return m;
          }
        ),
      };
    case 'CHANGE_HEADER':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'SET':
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default ({ routeProp, ...props }) => {
  const { id } = routeProp.match.params;
  const [state, dispatch] = useReducer(reducer, getDefaultState());

  const onChangeHeader = useCallback((e) => {
    const { name, value } = e.target;
    dispatch({
      name,
      value,
      type: 'CHANGE_HEADER',
    });
  }, []);

  const onChangeTemplate = useCallback((e, type) => {
    const { name, checked } = e.target;
    dispatch({
      type: 'CHANGE_TEMPLATE',
      templateType: type,
      name,
      value: checked,
    });
  }, []);

  const onChangeTemplateItem = useCallback((e, templateType, itemType) => {
    const { name, checked, type, value } = e.target;
    dispatch({
      type: 'CHANGE_TEMPLATE_ITEM',
      itemType,
      templateType,
      name,
      value: type === 'checkbox' ? checked : value,
    });
  }, []);

  useEffect(() => {
    let ignore = false;
    async function fetch() {
      if (!id) return;
      const response = await store.dispatch(getInterestReceiptMaintain(id));
      if (ignore) return;
      if (response.error) return;
      const { payload } = response;
      const newData = {
        ...getDefaultState(),
        Id: payload.id,
        Type: payload.type,
      };

      // map exists data to ui data
      const { printInterestReceiptMaintainTemplates = [] } = payload;
      printInterestReceiptMaintainTemplates.forEach((template) => {
        const existsTemplate = newData.PrintInterestReceiptMaintainTemplates.find(
          (f) => f.Type === template.type
        );
        const { printInterestReceiptMaintainTemplateItems = [], ...templateData } = template;

        // map exists data
        for (const key in templateData) {
          existsTemplate[toupperKey(key)] = templateData[key];
        }

        existsTemplate.PrintInterestReceiptMaintainTemplateItems.forEach((existsTemplateItem) => {
          const templateItem = printInterestReceiptMaintainTemplateItems.find(
            (f) => f.type === existsTemplateItem.Type
          );
          if (templateItem) {
            for (const key in templateItem) {
              existsTemplateItem[toupperKey(key)] = templateItem[key];
            }
          }
        });
      });

      dispatch({
        type: 'SET',
        payload: newData,
      });
    }

    fetch();
    return () => {
      ignore = true;
    };
  }, [id]);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      async function submit() {
        const response = await store.dispatch(submitInterestReceiptMaintain(state));
        if (response.error) return;

        setTimeout(() => {
          routeProp.history.push('/master/print-interest-receipt-maintain-search');
        }, 1000);
      }

      submit();
    },
    [state]
  );

  return (
    <Card>
      <form className="form-horizontal" onSubmit={onSubmit}>
        <div className="form-group">
          <label className="control-label col-md-4">Type</label>
          <div className="col-md-8">
            <input
              type="text"
              className="form-control"
              name="Type"
              value={state.Type}
              onChange={onChangeHeader}
            />
          </div>
        </div>
        <div className="row">
          {templateTypes.map((type) => (
            <div className="col-md-6" key={type}>
              {renderTemplateType(type)}
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <Button type="submit" txt="save" icon="save" className="btn-success" />
          </div>
        </div>
      </form>
    </Card>
  );

  function renderTemplateType(type) {
    const template = state.PrintInterestReceiptMaintainTemplates.find((f) => f.Type === type) || {
      ...defaultTemplate,
      Type: type,
    };
    const { IsPrint, IsIc, IsDms, IsAutoInterestIncome } = template;
    return (
      <Card textHeader={type} bgHeader="bg-info" key={type}>
        <div className="form-group">
          <label className="control-label col-md-4">{type} Print</label>
          <div className="col-md-8">
            <input
              id={`${type}Print`}
              type="checkbox"
              className="label-checkbox"
              checked={IsPrint}
              name="IsPrint"
              onChange={(e) => onChangeTemplate(e, type)}
            />
            <label htmlFor={`${type}Print`} className="label_checkbox" />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-md-4">IC {type}</label>
          <div className="col-md-8">
            <input
              id={`IC${type}`}
              type="checkbox"
              className="label-checkbox"
              checked={IsIc}
              name="IsIc"
              onChange={(e) => onChangeTemplate(e, type)}
            />
            <label htmlFor={`IC${type}`} className="label_checkbox" />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-md-4">DMS {type}</label>
          <div className="col-md-8">
            <input
              id={`DMS${type}`}
              type="checkbox"
              className="label-checkbox"
              checked={IsDms}
              name="IsDms"
              onChange={(e) => onChangeTemplate(e, type)}
            />
            <label htmlFor={`DMS${type}`} className="label_checkbox" />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-md-4">{type} is auto interest income</label>
          <div className="col-md-8">
            <input
              id={`AutoInterestIncome${type}`}
              type="checkbox"
              className="label-checkbox"
              checked={IsAutoInterestIncome}
              name="IsAutoInterestIncome"
              onChange={(e) => onChangeTemplate(e, type)}
            />
            <label htmlFor={`AutoInterestIncome${type}`} className="label_checkbox" />
          </div>
        </div>

        {copyTypes.map((itemType, index) => renderTemplateItem(template, type, itemType, index))}
      </Card>
    );
  }

  function renderTemplateItem(templateData, type, itemType, index) {
    const templateItem = templateData.PrintInterestReceiptMaintainTemplateItems.find(
      (f) => f.Type === itemType
    ) || { ...defaultTemplateItem, Type: itemType };

    const { IsHardCopy, IsElectronic, HardCopyCount, ElectronicCount } = templateItem;
    return (
      <div key={`${type}${itemType}`}>
        <hr />
        <h5>
          <b>{copyTypeLabels[index]}</b>
        </h5>

        <div className="form-group">
          <label className="control-label col-sm-4">Hard Copy</label>
          <div className="col-sm-1">
            <input
              id={`HardCopy${type}${index}`}
              type="checkbox"
              name="IsHardCopy"
              checked={IsHardCopy}
              onChange={(e) => onChangeTemplateItem(e, type, itemType)}
              className="label-checkbox"
            />
            <label htmlFor={`HardCopy${type}${index}`} className="label_checkbox" />
          </div>
          <div className="col-sm-4">
            <input
              className="form-control"
              name="HardCopyCount"
              value={HardCopyCount === null ? '' : HardCopyCount}
              onChange={(e) => onChangeTemplateItem(e, type, itemType)}
              disabled={!IsHardCopy}
              required
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-4">Electronic</label>
          <div className="col-sm-1">
            <input
              id={`Electronic${type}${index}`}
              type="checkbox"
              name="IsElectronic"
              checked={IsElectronic}
              onChange={(e) => onChangeTemplateItem(e, type, itemType)}
              className="label-checkbox"
            />
            <label htmlFor={`Electronic${type}${index}`} className="label_checkbox" />
          </div>
          <div className="col-sm-4">
            <input
              className="form-control"
              name="ElectronicCount"
              value={ElectronicCount === null ? '' : ElectronicCount}
              onChange={(e) => onChangeTemplateItem(e, type, itemType)}
              disabled={!IsElectronic}
              required
            />
          </div>
        </div>
      </div>
    );
  }
};
