import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useReducer,
  useContext,
  forwardRef,
  useCallback,
  createContext,
  useImperativeHandle,
} from 'react';

import { store } from '../../Root';
import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import Select2 from '../../common/Select2';
import { toLower } from '../../common/helpper';
import FormGroup2 from '../../common/FormGroup2';
import DateRangePicker from '../../common/DateRangePicker';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { getLogError, searchLogError, downloadLogFile } from '../../reducers/logError/action';

const StateContext = createContext(null);
function LogError() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const stateContextValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state]
  );

  return (
    <>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Interface <span className="icon icon-angle-double-right" /> Log Error{' '}
          </small>
        </p>
      </div>
      <StateContext.Provider value={stateContextValue}>
        <Criteria />
        <SearchResult />
      </StateContext.Provider>
    </>
  );
}
export default LogError;

const logErrorType = [
  { name: 'Cheque', value: 'CHEQUE' },
  { name: 'Ending Balance', value: 'BANKENDINGBALANCE' },
  { name: 'Forward Contract', value: 'FXCONTRACT' },
  { name: 'Inward', value: 'INWARD' },
  { name: 'Job Cheque Send Mail to Bank', value: 'JOB CHEQUE SENDEMAILTOBANK' },
  { name: 'MTM Forward', value: 'MTMFORWARD' },
  { name: 'MTM Swap', value: 'MTMSWAP' },
  { name: 'Quote Rate', value: 'QUOTERATE' },
  { name: 'Receipt T/T', value: 'RECEIPT BKKBTH,RECEIPT KASITH,RECEIPT SICOTH,RECEIPT SMBCTH' },
  {
    name: 'Response File',
    value:
      'RESPONSE(Bahtnet) BKKBTH,RESPONSE(Bahtnet) SICOTH,RESPONSE(Bahtnet) SMBCTH,RESPONSE(BG) KASITH,RESPONSE(BG) SICOTH,RESPONSE(TT) BKKBTH,RESPONSE(TT) KASITH,RESPONSE(TT) SICOTH,RESPONSE(TT) SMBCTH,RESPONSE(TT_Remittance) BKKBTH,RESPONSE(TT_Remittance) KASITH,RESPONSE(TT_Remittance) SICOTH,RESPONSE(TT_Remittance) SMBCTH',
  },
  {
    name: 'Sent SFTP to Bank',
    value:
      'SFTP Bahtnet BKKBTH,SFTP Bahtnet SICOTH,SFTP Bahtnet SMBCTH,SFTP BANKENDINGBALANCE,SFTP TT BKKBTH,SFTP TT KASITH,SFTP TT SICOTH,SFTP TT SMBCTH,SFTP TT_Remittance BKKBTH,SFTP TT_Remittance KASITH,SFTP TT_Remittance SICOTH,SFTP TT_Remittance SMBCTH',
  },
  { name: 'SFTP Upload', value: 'SFTP UPLOAD' },
  {
    name: 'Validate File',
    value:
      'VALIDATE(Bahtnet) BKKBTH,VALIDATE(Bahtnet) SICOTH,VALIDATE(Bahtnet) SMBCTH,VALIDATE(BG) KASITH,VALIDATE(BG) SICOTH,VALIDATE(BNT) BKKBTH,VALIDATE(BNT) KASITH,VALIDATE(BNT) SICOTH,VALIDATE(BNT) SMBCTH,VALIDATE(TT_Remittance) BKKBTH,VALIDATE(TT_Remittance) KASITH,VALIDATE(TT_Remittance) SICOTH,VALIDATE(TT_Remittance) SMBCTH',
  },
  // { name: "T/T Remittance", value: "8"},
  // { name: "Bahtnet", value: "7"},
  // { name: "Vpay", value: "6"},
  // { name: "Inward", value: "5"},
];

function Criteria() {
  const { state, dispatch } = useContext(StateContext);
  const { criteria } = state;
  const onChange = useCallback((e) => {
    const { name, value, type } = e.target;
    dispatch({
      type: 'UPDATE_CRITERIA',
      name,
      value,
    });
  }, []);
  const onClickClearCriteria = useCallback(() => {
    dispatch({
      type: 'RESET_CRITERIA',
    });
  }, []);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      async function search() {
        const response = await store.dispatch(searchLogError(criteria));
        if (response.error) return;
        dispatch({
          type: 'SET_RESULTS',
          payload: response.payload || [],
        });
      }
      search();
    },
    [criteria]
  );

  return (
    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onSubmit}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Product">
              <Select2
                onChange={(e) => onChange({ target: e })}
                value={criteria.ProductType}
                name="ProductType"
              >
                {logErrorType.map((m) => (
                  <option value={m.value} key={m.value}>
                    {m.name}
                  </option>
                ))}
              </Select2>
              {/* <MasterDropdownUI
                            onChange={e => onChange({ target: e })}
                            value={criteria.ProductType}
                            name="ProductType"
                            options={["T/T Remittance", "Bahtnet", "Vpay", "Inward", "Forward Contract", "Ending Balance"]} /> */}
            </FormGroup2>

            <FormGroup2 text="Status">
              <MasterDropdownUI
                onChange={(e) => onChange({ target: e })}
                value={criteria.Status}
                name="Status"
                options={['Success', 'Fail', 'Warning']}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Date" required={false}>
              <DateRangePicker onChange={onChange} value={criteria.DateRange} name="DateRange" />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearCriteria}
            />
          </div>
        </Col2>
      </form>
    </Card>
  );
}

function SearchResult() {
  const modalRef = useRef(null);
  const { state, dispatch } = useContext(StateContext);
  const { results } = state;
  const onClickViewDetail = useCallback(
    (e, cell) => {
      const { row } = cell.index();
      const { id } = cell.row(row).data();
      if (!id) return;
      modalRef.current.open(id);
    },
    [state.results]
  );

  return (
    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        className="table table-bordered table-nowrap dataTable"
        id="dt-search-result-log-error"
        option={opt}
        value={results}
        onClickContext={[
          {
            onClick: onClickViewDetail,
            context: 'button.view-detail',
          },
        ]}
      >
        <thead>
          <tr>
            <th>Detail</th>
            <th>Type</th>
            <th>File Name</th>
            <th>Status</th>
            <th>Error Message</th>
            <th>Date</th>
            <th>By</th>
          </tr>
        </thead>
      </Table>
      <DetailModal ref={modalRef} />
    </Card>
  );
}

const DetailModal = forwardRef(({}, ref) => {
  const modalRef = useRef(null);
  const [logId, setLogId] = useState(null);
  const [detail, setDetail] = useState(null);
  useImperativeHandle(ref, () => ({
    open: (id) => {
      setLogId(id);
      modalRef.current.open();
    },
  }));
  useEffect(() => {
    if (!logId) return;
    let ignore = false;
    async function fetch() {
      const response = await store.dispatch(getLogError(logId));
      if (response.error || ignore) return;

      setDetail(response.payload);
    }

    fetch();
    return () => {
      ignore = true;
    };
  }, [logId]);
  const header = useMemo(() => {
    if (!detail || !detail.length) return [];
    return Object.keys(detail[0]);
  }, [detail]);

  const onClickDownloadFile = useCallback(
    (row) => {
      store.dispatch(downloadLogFile(logId, row));
    },
    [logId]
  );
  return (
    <div>
      <Modal ref={modalRef} bgHeader="bg-info" textHeader="Info" size="modal-xl">
        <div className="table-responsive" style={{ maxHeight: '500px' }}>
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                {header.map((m, i) => (
                  <th key={i}>{m}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!detail || !detail.length
                ? null
                : detail.map((obj, i) => (
                    <tr key={i} style={{ backgroundColor: obj.isError ? '#b0b0b0' : 'unset' }}>
                      {renderRow(obj)}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );

  function renderRow(row) {
    return header.map((key, ii) => {
      if (key === 'download' && row[key])
        return (
          <td key={ii}>
            <Button
              icon="download"
              className="btn-info"
              txt="download"
              onClick={(e) => onClickDownloadFile(row.row)}
            />
          </td>
        );
      return <td key={ii}>{getString(row[key])}</td>;
    });
  }
});

const getString = (t) => {
  if (t === true) return 'Yes';
  if (t === false) return 'No';
  return t;
};

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const opt = {
  paging: true,
  order: [[0, 'asc']],
  columns: [
    {
      data: null,
      className: 'text-center',
      render: (d) =>
        '<button class="btn btn-sm btn-info view-detail"><i class="icon icon-info icon-fw icon-lg"></i></button>',
    },
    { data: 'type' },
    { data: 'fileNameOriginal' },
    {
      data: 'status',
      className: 'text-center',
      render: (d) =>
        toLower(d) === 'success'
          ? `<span class="label label-success">${d}</span>`
          : toLower(d) === 'fail'
            ? `<span class="label label-danger">${d}</span>`
            : toLower(d) === 'warning'
              ? `<span class="label label-warning">${d}</span>`
              : `<span class="label label-default">Unknow</span>`,
    },
    { data: 'error' },
    { data: 'createdDate', className: 'text-center', type: 'date-time' },
    { data: 'createdBy' },
  ],
};
const initCriteria = {
  DateRange: '',
  ProductType: [],
  Status: [],
};
const initialState = {
  criteria: initCriteria,
  results: [],
};
const reducer = (state = {}, action) => {
  switch (action.type) {
    case 'RESET_CRITERIA':
      return {
        ...state,
        criteria: { ...initCriteria },
      };
    case 'UPDATE_CRITERIA':
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.name]: action.value,
        },
      };
    case 'SET_RESULTS':
      return {
        ...state,
        results: [...action.payload],
      };
    default:
      return state;
  }
};
