import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Button from '../../common/Button';
import Select2 from '../../common/Select2';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import RadioButton from '../../common/RadioButton';
import { getPermission } from '../../common/helpper';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import {
  MASTER_COMPANY,
  MASTER_FINANCE,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('Automate', 'Exposure');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ state, onChangeInputData, onSubmit, resultEffective }) => {
  const textHeader =
    state.Mode === 'create' ? 'Create Automate Exposure' : 'Edit Automate Exposure';
  const modeEdit = state.Mode === 'edit';
  const noEdit = !permission;
  const disabledOutward = state.CashType === 'Outward';
  const disabledInward = state.CashType === 'Inward';
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Automate <span className="icon icon-angle-double-right" />
            <Link txt="Exposure" href="/automate/exposure" />
            <span className="icon icon-angle-double-right" /> Exposure Detail
            {/* {
                            modeEdit ? (<>
                                {" "}<span className="icon icon-angle-double-right"></span> <SpanStatus status={state.IsActive ? "Active" : "Inactive"} />
                            </>) : null
                        } */}
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card
          textHeader={textHeader}
          bgHeader="bg-primary"
          cardActions={['toggler']}
          footer={
            !permission ? (
              ''
            ) : noEdit ? (
              ''
            ) : (
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={noEdit}
              />
            )
          }
        >
          <Col2 col={colX[0]}>
            {state.Mode === 'create' ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <DatePicker
                    value={state.EffectiveDateStr}
                    onChange={onChangeInputData}
                    name="EffectiveDateStr"
                    required
                    option={{
                      todayHighlight: true,
                    }}
                  />
                </FormGroup2>
              </Col2>
            ) : (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <Select2
                    value={state.TempEffectiveDateStr}
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="TempEffectiveDateStr"
                    required={false}
                    noValidateOption
                    saveLocalState
                  >
                    {permission && (
                      <option value="New" key="New">
                        New Effective Date
                      </option>
                    )}
                    {resultEffective.map((m) => (
                      <option value={m} key={m}>
                        {m}
                      </option>
                    ))}
                  </Select2>
                </FormGroup2>
                {state.TempEffectiveDateStr === 'New' ? (
                  <FormGroup2 text="New Effective Date" required>
                    <DatePicker
                      value={state.EffectiveDateStr}
                      onChange={onChangeInputData}
                      name="EffectiveDateStr"
                      required
                      option={{
                        todayHighlight: true,
                      }}
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            )}

            <Col2 col={colX[1]}>
              <FormGroup2 text="Cash Type" required>
                {permission ? (
                  <div>
                    <RadioButton
                      text="Inward"
                      value="Inward"
                      checked={state.CashType === 'Inward'}
                      onChange={onChangeInputData}
                      name="CashType"
                      required
                    />
                    <RadioButton
                      text="Outward"
                      value="Outward"
                      checked={state.CashType === 'Outward'}
                      onChange={onChangeInputData}
                      name="CashType"
                    />
                  </div>
                ) : (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    required
                    value={state.CashType}
                    name="CashType"
                    disabled={!permission}
                  />
                )}
              </FormGroup2>
              {modeEdit ? (
                <FormGroup2 text="Status" required>
                  <select
                    className="form-control"
                    value={state.IsActive}
                    onChange={onChangeInputData}
                    name="IsActive"
                    disabled={noEdit}
                  >
                    <option value>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  noValidateOption
                  saveLocalState
                  value={state.FinanceGroupId}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  required
                  disabled={modeEdit}
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit" required>
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  noValidateOption
                  saveLocalState
                  value={state.BuId}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  required
                  disabled={modeEdit}
                  name="BuId"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company" required>
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  noValidateOption
                  saveLocalState
                  // isChoose={true}
                  notMultipleSelect2
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  required
                  isAll
                  disabled={modeEdit}
                  businessUnitId={state.BuId}
                  value={state.CompanyCode}
                  name="CompanyCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency From" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  noValidateOption
                  saveLocalState
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  required
                  disabled={modeEdit}
                  value={state.CurrencyFrom}
                  name="CurrencyFrom"
                />
              </FormGroup2>

              <FormGroup2 text="Currency To" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  noValidateOption
                  saveLocalState
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  required
                  disabled={modeEdit}
                  value={state.CurrencyTo}
                  name="CurrencyTo"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Auto Assign Forward" required>
                {permission ? (
                  <div>
                    <RadioButton
                      text="Yes"
                      value
                      checked={state.AutoAssignForward}
                      onChange={onChangeInputData}
                      name="AutoAssignForward"
                      required
                    />
                    <RadioButton
                      text="No"
                      value={false}
                      checked={state.AutoAssignForward === false}
                      onChange={onChangeInputData}
                      name="AutoAssignForward"
                    />
                  </div>
                ) : (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    required
                    value={state.AutoAssignForward ? 'Yes' : 'No'}
                    name="AutoAssignForward"
                    disabled={!permission}
                  />
                )}
              </FormGroup2>
              {state.CashType === 'Outward' && (
                <FormGroup2 text="Auto Spot" required>
                  {permission ? (
                    <div>
                      <RadioButton
                        text="Yes"
                        value
                        checked={state.AutoSplitSpot}
                        onChange={onChangeInputData}
                        name="AutoSplitSpot"
                        required
                      />
                      <RadioButton
                        text="No"
                        value={false}
                        checked={state.AutoSplitSpot === false}
                        onChange={onChangeInputData}
                        name="AutoSplitSpot"
                      />
                    </div>
                  ) : (
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      required
                      value={state.AutoSplitSpot ? 'Yes' : 'No'}
                      name="AutoSplitSpot"
                      disabled={!permission}
                    />
                  )}
                </FormGroup2>
              )}
            </Col2>
            {state.AutoSplitSpot && state.CashType === 'Outward' ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Bank Spot" required>
                  <MasterDropdown
                    masterType={MASTER_BANK_CODE}
                    noValidateOption
                    saveLocalState
                    isChoose
                    notMultipleSelect2
                    onChange={(e) => {
                      onChangeInputData({
                        target: e,
                      });
                    }}
                    required
                    disabled={noEdit}
                    isGetAll
                    value={state.BankSpotCode}
                    name="BankSpotCode"
                  />
                </FormGroup2>
              </Col2>
            ) : null}
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Paid for">
                <div style={{ marginBottom: '5px' }}>
                  <Col2 col={colX[0]}>
                    <>
                      <input
                        id="PaidForService"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="PaidForService"
                        checked={state.PaidForService}
                        disabled={noEdit || disabledInward}
                      />
                      <label htmlFor="PaidForService" className="label_checkbox" />
                      <label htmlFor="PaidForService" className="control-label text_label_checkbox">
                        Service
                      </label>
                    </>
                    {state.PaidForService ? (
                      <MasterDropdownUI
                        onChange={(e) => {
                          onChangeInputData({
                            target: e,
                          });
                        }}
                        isTransfermethod
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        required
                        value={state.TransferMethodService}
                        disabled={noEdit || disabledInward}
                        name="TransferMethodService"
                        options={['SFTP', 'Paper']}
                      />
                    ) : null}
                  </Col2>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <Col2 col={colX[0]}>
                    <>
                      <input
                        id="PaidForGoods"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="PaidForGoods"
                        checked={state.PaidForGoods}
                        disabled={noEdit || disabledInward}
                      />
                      <label htmlFor="PaidForGoods" className="label_checkbox" />
                      <label htmlFor="PaidForGoods" className="control-label text_label_checkbox">
                        Goods
                      </label>
                    </>
                    {state.PaidForGoods ? (
                      <MasterDropdownUI
                        onChange={(e) => {
                          onChangeInputData({
                            target: e,
                          });
                        }}
                        isTransfermethod
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        required
                        value={state.TransferMethodGoods}
                        disabled={noEdit || disabledInward}
                        name="TransferMethodGoods"
                        options={['SFTP', 'Paper']}
                      />
                    ) : null}
                  </Col2>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <Col2 col={colX[0]}>
                    <>
                      <input
                        id="PaidForProject"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="PaidForProject"
                        checked={state.PaidForProject}
                        disabled={noEdit || disabledInward}
                      />
                      <label htmlFor="PaidForProject" className="label_checkbox" />
                      <label htmlFor="PaidForProject" className="control-label text_label_checkbox">
                        Project
                      </label>
                    </>
                    {state.PaidForProject ? (
                      <MasterDropdownUI
                        onChange={(e) => {
                          onChangeInputData({
                            target: e,
                          });
                        }}
                        isTransfermethod
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        required
                        value={state.TransferMethodProject}
                        disabled={noEdit || disabledInward}
                        name="TransferMethodProject"
                        options={['SFTP', 'Paper']}
                      />
                    ) : null}
                  </Col2>
                </div>

                <div>
                  <Col2 col={colX[0]}>
                    <>
                      <input
                        id="PaidForStLoan"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="PaidForStLoan"
                        checked={state.PaidForStLoan}
                        disabled={noEdit || disabledInward}
                      />
                      <label htmlFor="PaidForStLoan" className="label_checkbox" />
                      <label htmlFor="PaidForStLoan" className="control-label text_label_checkbox">
                        ST Loan
                      </label>
                    </>
                    {state.PaidForStLoan ? (
                      <MasterDropdownUI
                        onChange={(e) => {
                          onChangeInputData({
                            target: e,
                          });
                        }}
                        isTransfermethod
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        required
                        value={state.TransferMethodStLoan}
                        disabled={noEdit || disabledInward}
                        name="TransferMethodStLoan"
                        options={['SFTP', 'Paper']}
                      />
                    ) : null}
                  </Col2>
                </div>
              </FormGroup2>

              <FormGroup2 text="&nbsp;">
                <div style={{ marginBottom: '5px' }}>
                  <Col2 col={colX[0]}>
                    <>
                      <input
                        id="PaidForLtLoan"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="PaidForLtLoan"
                        checked={state.PaidForLtLoan}
                        disabled={noEdit || disabledInward}
                      />
                      <label htmlFor="PaidForLtLoan" className="label_checkbox" />
                      <label htmlFor="PaidForLtLoan" className="control-label text_label_checkbox">
                        LT Loan & Swap
                      </label>
                    </>
                    {state.PaidForLtLoan ? (
                      <MasterDropdownUI
                        onChange={(e) => {
                          onChangeInputData({
                            target: e,
                          });
                        }}
                        isTransfermethod
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        required
                        value={state.TransferMethodLtLoan}
                        disabled={noEdit || disabledInward}
                        name="TransferMethodLtLoan"
                        options={['SFTP', 'Paper']}
                      />
                    ) : null}
                  </Col2>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <Col2 col={colX[0]}>
                    <>
                      <input
                        id="PaidForCapital"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="PaidForCapital"
                        checked={state.PaidForCapital}
                        disabled={noEdit || disabledInward}
                      />
                      <label htmlFor="PaidForCapital" className="label_checkbox" />
                      <label htmlFor="PaidForCapital" className="control-label text_label_checkbox">
                        Capital Injection
                      </label>
                    </>
                    {state.PaidForCapital ? (
                      <MasterDropdownUI
                        onChange={(e) => {
                          onChangeInputData({
                            target: e,
                          });
                        }}
                        isTransfermethod
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        required
                        value={state.TransferMethodCapital}
                        disabled={noEdit || disabledInward}
                        name="TransferMethodCapital"
                        options={['SFTP', 'Paper']}
                      />
                    ) : null}
                  </Col2>
                </div>

                <div style={{ marginBottom: '5px' }}>
                  <Col2 col={colX[0]}>
                    <>
                      <input
                        id="PaidForDividend"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="PaidForDividend"
                        checked={state.PaidForDividend}
                        disabled={noEdit || disabledInward}
                      />
                      <label htmlFor="PaidForDividend" className="label_checkbox" />
                      <label
                        htmlFor="PaidForDividend"
                        className="control-label text_label_checkbox"
                      >
                        Dividend
                      </label>
                    </>
                    {state.PaidForDividend ? (
                      <MasterDropdownUI
                        onChange={(e) => {
                          onChangeInputData({
                            target: e,
                          });
                        }}
                        isTransfermethod
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        required
                        value={state.TransferMethodDividend}
                        disabled={noEdit || disabledInward}
                        name="TransferMethodDividend"
                        options={['SFTP', 'Paper']}
                      />
                    ) : null}
                  </Col2>
                </div>

                <div>
                  <Col2 col={colX[0]}>
                    <>
                      <input
                        id="PaidForOther"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="PaidForOther"
                        checked={state.PaidForOther}
                        disabled={noEdit || disabledInward}
                      />
                      <label htmlFor="PaidForOther" className="label_checkbox" />
                      <label htmlFor="PaidForOther" className="control-label text_label_checkbox">
                        Other
                      </label>
                    </>
                    {state.PaidForOther ? (
                      <MasterDropdownUI
                        onChange={(e) => {
                          onChangeInputData({
                            target: e,
                          });
                        }}
                        isTransfermethod
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        required
                        value={state.TransferMethodOther}
                        disabled={noEdit || disabledInward}
                        name="TransferMethodOther"
                        options={['SFTP', 'Paper']}
                      />
                    ) : null}
                  </Col2>
                </div>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Card
              textHeader="For Auto Assign Value date"
              bgHeader="bg-warning"
              cardActions={['toggler']}
            >
              {/* <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>  */}
              <FormGroup2 text="Set time auto assign value date" required={false}>
                <MasterDropdownUI
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  isChoose
                  noValidateOption
                  saveLocalState
                  notMultipleSelect2
                  required={false}
                  value={state.TimeAutoAssign}
                  disabled={noEdit || disabledOutward}
                  name="TimeAutoAssign"
                  options={[
                    '8:00',
                    '9:00',
                    '10:00',
                    '11:00',
                    '12:00',
                    '13:00',
                    '14:00',
                    '15:00',
                    '16:00',
                    '17:00',
                    '18:00',
                    '19:00',
                    '20:00',
                    '21:00',
                    '22:00',
                    '23:00',
                    '24:00',
                  ]}
                />
              </FormGroup2>
              {/* </Col2>
          </Col2> */}

              {/* <Col2 col={colX[0]}> */}
              {/* <Col2 col={colX[1]}>  */}
              <FormGroup2
                text="Invoice No."
                required={false}
                maxLength={3}
                length={(state.InvoiceNo || '').length}
              >
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit || disabledOutward}
                  value={state.InvoiceNo || ''}
                  name="InvoiceNo"
                  maxLength={3}
                />
                <div style={{ marginTop: 3, color: '#EE2424' }}>
                  <span>
                    Note: Please specific first 3 digit of Invoice No. only. If not, system will
                    auto Value Date all transaction.
                  </span>
                </div>
              </FormGroup2>
              {/* </Col2> */}
              {/* </Col2> */}

              {/* <Col2 col={colX[0]}>
            <Col2 col={colX[1]}> */}
              <FormGroup2 text="Bank Remark" required={false}>
                <Col2 col={colX[0]}>
                  <Col2 col={colX[1]}>
                    <>
                      <input
                        id="IsBankRemarkBen"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="IsBankRemarkBen"
                        checked={state.IsBankRemarkBen}
                        disabled={noEdit || disabledOutward}
                      />
                      <label htmlFor="IsBankRemarkBen" className="label_checkbox" />
                      <label
                        htmlFor="IsBankRemarkBen"
                        className="control-label text_label_checkbox"
                      >
                        BEN
                      </label>
                    </>
                    <>
                      <input
                        id="IsBankRemarkSha"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="IsBankRemarkSha"
                        checked={state.IsBankRemarkSha}
                        disabled={noEdit || disabledOutward}
                      />
                      <label htmlFor="IsBankRemarkSha" className="label_checkbox" />
                      <label
                        htmlFor="IsBankRemarkSha"
                        className="control-label text_label_checkbox"
                      >
                        SHA
                      </label>
                    </>
                  </Col2>
                  <Col2 col={colX[1]}>
                    <>
                      <input
                        id="IsBankRemarkOur"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="IsBankRemarkOur"
                        checked={state.IsBankRemarkOur}
                        disabled={noEdit || disabledOutward}
                      />
                      <label htmlFor="IsBankRemarkOur" className="label_checkbox" />
                      <label
                        htmlFor="IsBankRemarkOur"
                        className="control-label text_label_checkbox"
                      >
                        OUR
                      </label>
                    </>
                    <>
                      <input
                        id="IsBankRemarkBlank"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        name="IsBankRemarkBlank"
                        checked={state.IsBankRemarkBlank}
                        disabled={noEdit || disabledOutward}
                      />
                      <label htmlFor="IsBankRemarkBlank" className="label_checkbox" />
                      <label
                        htmlFor="IsBankRemarkBlank"
                        className="control-label text_label_checkbox"
                      >
                        Blank
                      </label>
                    </>
                  </Col2>
                </Col2>
              </FormGroup2>
              {/* </Col2>
          </Col2> */}
            </Card>
          </Col2>

          {state.Equivalent === 'Auto Assign' ? (
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Method" required>
                  <MasterDropdownUI
                    onChange={(e) => {
                      onChangeInputData({
                        target: e,
                      });
                    }}
                    isChoose
                    noValidateOption
                    saveLocalState
                    notMultipleSelect2
                    required
                    value={state.MethodEquivalent}
                    disabled={noEdit}
                    name="MethodEquivalent"
                    options={['Forward', 'Spot']}
                  />
                </FormGroup2>

                <FormGroup2 text="Transfer Method" required>
                  <MasterDropdownUI
                    onChange={(e) => {
                      onChangeInputData({
                        target: e,
                      });
                    }}
                    isChoose
                    noValidateOption
                    saveLocalState
                    notMultipleSelect2
                    required
                    value={state.TransferMethodEquivalent}
                    disabled={noEdit}
                    name="TransferMethodEquivalent"
                    options={['SFTP', 'Paper']}
                  />
                </FormGroup2>
              </Col2>
            </Col2>
          ) : null}
        </Card>
      </form>
    </div>
  );
};
