import {
  UPDATE_COPY_FROM,
  EDIT_MASTER_USER_CRITERIA,
  UPDATE_RESULT_MASTER_ROLE,
  RECEIVE_SEARCH_MASTER_USER,
  CLEAR_MASTER_USER_CRITERIA,
  UPDATE_RESULT_MASTER_COMPANY,
  RECEIVE_GET_MASTER_USER_DETAIL,
  UPDATE_SEARCH_RESULT_MASTER_USER,
  RECEIVE_GET_MASTER_USER_DETAIL_GROUP,
  RECEIVE_GET_MASTER_USER_DETAIL_GROUP_DETAIL,
} from './action';

const initCriteria = {
  Username: '',
  Name: '',
  Surname: '',
  IsActive: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
  resultRole: [],
  resultCompany: [],
  resultGroup: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_COPY_FROM: {
      return {
        ...state,
        resultCompany: action.payload.resultCompany,
        resultRole: action.payload.resultRole,
      };
    }
    case UPDATE_RESULT_MASTER_ROLE: {
      return {
        ...state,
        resultRole: state.resultRole.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'isActiveUserRole' && !m.canSelect) {
              return m;
            }
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    }
    case UPDATE_RESULT_MASTER_COMPANY: {
      return {
        ...state,
        resultCompany: state.resultCompany.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'isActiveUserCompany' && !m.canSelect) {
              return m;
            }
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    }
    case RECEIVE_GET_MASTER_USER_DETAIL:
      return {
        ...state,
        // resultCompany: action.payload.resultCompany,
        // resultRole: action.payload.resultRole,
      };
    case RECEIVE_GET_MASTER_USER_DETAIL_GROUP:
      return {
        ...state,
        resultGroup: action.payload.resultGroup,
      };
    case RECEIVE_GET_MASTER_USER_DETAIL_GROUP_DETAIL:
      return {
        ...state,
        resultCompany: action.payload.resultCompany,
        resultRole: action.payload.resultRole,
      };
    case UPDATE_SEARCH_RESULT_MASTER_USER:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_MASTER_USER_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MASTER_USER_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_MASTER_USER:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
