import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Card from '../../common/Card';
import Link from '../../common/Link';
import Col2 from '../../common/Col2';
import AttachFile from './AttachFile';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import ModalFunction from '../../common/ModalFunction';
import { DATATABLE_GROUP } from './UserDetailContainer';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_USER } from '../../reducers/master/action';
import { toLower, toStatus, getUsername, getPermission } from '../../common/helpper';

const permission = getPermission('Common Master', 'User');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const optGroup = {
  order: [[2, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      colReorder: false,
      className: `text-center`,
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      colReorder: false,
      className: `text-center`,
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'groupName' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
  ],
};

export default ({
  state,
  onChangeInputData,
  onSubmit,
  resultRole,

  dataTableGroupRef,
  resultGroup,

  onChangeCopyFrom,

  onClickApprove,

  attachFileRef,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,

  modalCopyRef,
  modalCopy,
  onClickCopy,
  onChangeInputDataModal,
  onSubmitCopyUser,
}) => {
  const text = state.mode === 'edit' ? `Username: ${state.Username}` : 'Create User';
  const modeEdit = state.mode === 'edit';
  const checkSave = !!(
    state.mode === 'edit' &&
    !state.IsActive &&
    state.ApproveStatus !== 'Rejected'
  );

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Common Master <span className="icon icon-angle-double-right" />
            <Link txt="User" href="/master/user" />
            <span className="icon icon-angle-double-right" /> User Detail{' '}
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card
          textHeader={text}
          bgHeader="bg-primary"
          number="1"
          cardActions={['toggler']}
          footer={
            !permission ? (
              ''
            ) : (
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            )
          }
        >
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Username" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={modeEdit}
                  value={state.Username || ''}
                  name="Username"
                />
              </FormGroup2>

              <FormGroup2 text="Name EN" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  value={state.NameEn || ''}
                  name="NameEn"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Surname EN" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  value={state.SurnameEn || ''}
                  name="SurnameEn"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Email" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  value={state.Email || ''}
                  name="Email"
                />
              </FormGroup2>

              <FormGroup2 text="Name TH" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  value={state.NameTh || ''}
                  name="NameTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Surname TH" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  value={state.SurnameTh || ''}
                  name="SurnameTh"
                />
              </FormGroup2>
            </Col2>
          </Col2>
          {/* {state.mode === "edit" ? (
            <>
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Copy From" required={false}>
                    <MasterDropdown
                      masterType={MASTER_USER}
                      isChoose={true}
                      notMultipleSelect2={true}
                      onChange={(e) =>
                        onChangeCopyFrom({
                          target: e,
                        })
                      }
                      noValidateOption={true}
                      saveLocalState={true}
                      required={false}
                      value={state.CopyFrom}
                      name="CopyFrom"
                    />
                  </FormGroup2>
                </Col2>
              </Col2>
            </>
          ) : null} */}
          {renderAttachFile()}
          {renderApproveButtonGroup()}
        </Card>
      </form>

      {state.mode === 'edit' ? (
        <Card
          textHeader="Authorize Group"
          number="2"
          bgHeader="bg-primary"
          cardActions={['toggler']}
        >
          <Col2 col={colX[2]}>
            <div>
              {permission ? (
                <>
                  <RouterLink
                    className="btn btn-warning btn-sm btn-labeled"
                    to={`/master/user-detail-group/${state.Username}&Id=create`}
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    CREATE GROUP
                  </RouterLink>
                  &nbsp;
                  <Button txt="Copy User" icon="copy" className="btn-info" onClick={onClickCopy} />
                </>
              ) : null}
            </div>
          </Col2>
          <Table
            ref={dataTableGroupRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_GROUP}
            option={optGroup}
            value={resultGroup}
          >
            <thead>
              <tr>
                <th>Action</th>
                <th>Edit</th>
                <th>Group Name</th>
                <th>Status</th>
              </tr>
            </thead>
          </Table>
        </Card>
      ) : null}

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />

      <Modal size="modal-md" ref={modalCopyRef} modalFooter>
        <form onSubmit={onSubmitCopyUser} className="form-horizontal">
          <div className="text-center">
            <h2 className="text-info">Copy User</h2>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Username<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-7">
              <MasterDropdown
                masterType={MASTER_USER}
                isChoose
                notMultipleSelect2
                value={modalCopy.UsernameCopy}
                onChange={(e) =>
                  onChangeInputDataModal({
                    target: e,
                  })
                }
                required
                noValidateOption
                saveLocalState
                name="UsernameCopy"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );

  function renderAttachFile() {
    if (state.mode === 'create') return null;

    return (
      <Card textHeader="Attach Files">
        <AttachFile
          ref={attachFileRef}
          limitFileCount={-1}
          masterType="masteruser"
          masterKey={state.Username.replace(/\./g, '_')}
          isExpired
        />
      </Card>
    );
  }

  function renderApproveButtonGroup() {
    if (
      state.ApproveStatus === 'Waiting for Approve' &&
      toLower(state.Approver1Status) === 'waiting for approve' &&
      toLower(state.Approver1) === toLower(getUsername())
    )
      return (
        <div className="row">
          <div className="col-md-4">
            <Card textHeader="Approve">
              <Button
                txt="Approve"
                icon="check"
                className="btn-success"
                onClick={() => onClickApprove(true)}
              />
              &nbsp;
              <Button
                txt="Reject"
                icon="close"
                className="btn-warning"
                onClick={() => onClickApprove(false)}
              />
            </Card>
          </div>
        </div>
      );

    return null;
  }
};
