import '../../css/custome.css';

import { connect } from 'react-redux';
import React, { Component } from 'react';

import Error from './Error';
import Sidebar from './Sidebar';
import Logo from '../../img/logo.png';
import LoadingSVG from '../../img/loading.svg';
import { setLayout, setLoading } from '../../reducers/layout/action';

const username = 'Wakanda Forever';

class Layout extends Component {
  state = {};

  onClickCollapse = (el) => {
    this.props.setLayout({
      ...this.props.layout,
      [el]: !this.props.layout[el],
    });
  };

  onClickLogout = () => {};

  render() {
    return (
      <>
        <Error />
        <div
          className="loading-ctn"
          style={{ display: this.props.layout.isLoading ? 'block' : 'none' }}
        >
          <img src={LoadingSVG} alt="loading" />
        </div>
        <div
          className={`layout layout-header-fixed ${!this.props.layout.isActiveSidebarSm ? 'layout-sidebar-collapsed' : ''}`}
        >
          <div className="layout-header">
            <div className="navbar navbar-default">
              <div className="navbar-header">
                <a className="navbar-brand navbar-brand-center" href="/">
                  <img alt="img" src={Logo} style={{ height: '75px', marginTop: '-29px' }} />
                </a>
                <button
                  className="navbar-toggler collapsed visible-xs-block"
                  onClick={() => this.onClickCollapse('isActiveSidebar')}
                  type="button"
                  data-toggle="collapse"
                  data-target="#sidenav"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="bars">
                    <span className="bar-line bar-line-1 out" />
                    <span className="bar-line bar-line-2 out" />
                    <span className="bar-line bar-line-3 out" />
                  </span>
                  <span className="bars bars-x">
                    <span className="bar-line bar-line-4" />
                    <span className="bar-line bar-line-5" />
                  </span>
                </button>
                <button
                  className="navbar-toggler collapsed visible-xs-block"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbar"
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="arrow-up" />
                  <span className="ellipsis ellipsis-vertical" />
                </button>
              </div>
              <div className="navbar-toggleable">
                <nav id="navbar" className="navbar-collapse collapse">
                  <button
                    className="sidenav-toggler hidden-xs"
                    type="button"
                    onClick={() => this.onClickCollapse('isActiveSidebarSm')}
                  >
                    <span className="sr-only">Toggle navigation</span>
                    <span className="bars">
                      <span className="bar-line bar-line-1 out" />
                      <span className="bar-line bar-line-2 out" />
                      <span className="bar-line bar-line-3 out" />
                      <span className="bar-line bar-line-4 in" />
                      <span className="bar-line bar-line-5 in" />
                      <span className="bar-line bar-line-6 in" />
                    </span>
                  </button>
                  <ul className="nav navbar-nav navbar-right">
                    <li>
                      <span className="navbar-text">
                        <span className="icon icon-user" />
                        &nbsp;{username}
                      </span>
                    </li>
                    <li>
                      <a href="javascirpt:(0);" onClick={this.onClickLogout}>
                        <span className="icon icon-sign-out" />
                        &nbsp;Sign out
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div className="layout-main">
            <Sidebar />
            <div className="layout-content">
              <div className="layout-content-body">{this.props.children}</div>
              <div className="layout-footer">
                <div className="layout-footer-body">
                  <small>Thank you for choosing our services.</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  (state) => ({
    layout: state.layout,
  }),
  {
    setLoading,
    setLayout,
  }
)(Layout);
