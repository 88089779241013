import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import DatePicker from '../../common/DatePicker';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import ButtonDropdown from '../../common/ButtonDropdown';
import { DATATABLE_ID } from './SearchRequestBgContainer';
import DateRangePicker from '../../common/DateRangePicker';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toLower, toStatus, toNumber, toBankCode, toStatusApprove } from '../../common/helpper';
import {
  GET_BG_FORM,
  GET_BG_BRANCH,
  MASTER_COMPANY,
  GET_BG_LG_TYPE,
  MASTER_USER_BG,
  MASTER_CURRENCY,
  MASTER_APPROVER,
  MASTER_BANK_CODE,
  GET_BG_BENEFICIARY,
  MAIN_MASTER_FINANCE_GROUP,
  MAIN_MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const $ = window.jQuery;
const { moment } = window;

// let permission = getPermissionStr('Bank Guarantee', 'Request') === 'View' ? false : true
const permission = true;

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-4', 'col-md-7'];

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[4, 'asc']],
  fixedColumns: {
    leftColumns: 5,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        if (~['wait for initialization'].indexOf(toLower(r.statusLg)) && r.isCreator) {
          r.canSelect = true;
          return `<input type="checkbox" class="label-checkbox" ${
            d ? 'checked="true"' : ''
          }/><label class="label_checkbox"></label>`;
        }
        if (~['wait for bu approve'].indexOf(toLower(r.statusLg)) && r.isCreator) {
          r.canSelect = true;
          return `<input type="checkbox" class="label-checkbox" ${
            d ? 'checked="true"' : ''
          }/><label class="label_checkbox"></label>`;
        }
        if (
          ~['rejected', 'inactive', 'draft'].indexOf(toLower(r.statusLg)) ||
          !r.isRoleComp ||
          !permission
        ) {
          r.canSelect = false;
          return ``;
        }
        if (~['wait for bu approve'].indexOf(toLower(r.statusLg)) && r.canApproverBu) {
          r.canSelect = true;
          return `<input type="checkbox" class="label-checkbox" ${
            d ? 'checked="true"' : ''
          }/><label class="label_checkbox"></label>`;
        }
        if (!r.isRoleFinancial) {
          r.canSelect = false;
          return ``;
        }
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${
          d ? 'checked="true"' : ''
        }/><label class="label_checkbox"></label>`;
      },
    },
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (
          !~['completed', 'inactive'].indexOf(toLower(r.statusLg)) ||
          !r.isRoleFinancial ||
          !permission
        )
          return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isPrint',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!~['inactive'].indexOf(toLower(r.statusLg)) || !r.isRoleComp || !r.isRoleFinancial)
          return '';
        return '<button class="btn btn-icon btn-info action-print"><span class="icon icon-print sq-24"></span></button>';
      },
    },
    {
      data: 'isAmend',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!r.isRoleComp || !r.isRoleFinancial) return '';
        if (~['completed', 'completed expire'].indexOf(toLower(r.statusLg)))
          return `<a target="_blank" href="/bank-guarantee/amend/${r.transactionNo}" class="btn btn-icon btn-info"><span class="icon icon-arrows-h sq-24"></span></a>`;
        if (~['amended'].indexOf(toLower(r.statusLg)))
          return `<a target="_blank" href="/bank-guarantee/request/${r.newTransactionNo}" class="btn btn-icon btn-default action-print"><span class="icon icon-eye sq-24"></span></a>`;
        if (r.oldTransactionNo && toLower(r.statusLg) !== 'cancelled')
          return '<button class="btn btn-icon btn-warning cancel-amend"><span class="icon icon-refresh sq-24"></span></button>';
        return '';
      },
    },
    {
      data: 'transactionNo',
      render: (d, t, r) =>
        `<a target="_blank" href="/bank-guarantee/request/${d}" class="edit-request-bg"> ${d} </a>`,
    },
    { data: 'financeGroupName' },
    { data: 'buName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'bankCode',
      render: (d, t, r) => toBankCode(d, r.bankAbbreviate, r.bankName),
    },
    {
      data: 'branchId',
      render: (d, t, r) => (r.branchCode ? `${r.branchCode} | ${r.branchName}` : ''),
    },
    { data: 'issueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'lgMethod' },
    {
      data: 'lgNo',
      render: (d, t, r) => {
        if (!permission) {
          return d || '';
        }
        if (d) {
          if (toLower(r.statusLg) === 'in progress')
            return `<center><button class="btn btn-info-reverse key-lg">${d}</button></center>`;
          return d;
        }
        if (toLower(r.statusLg) === 'in progress') {
          return '<center><button class="btn btn-icon btn-info key-lg"><span class="icon icon-edit sq-24"></span></button></center>';
        }
        return '';
      },
    },
    { data: 'beneficiaryNameTh' },
    {
      data: 'projectName',
      render: (d, t, r) => {
        const projectName = (d || '').length > 50 ? `${d.slice(0, 50)}...` : d;
        if (toLower(r.statusLg) !== 'wait for confirm' || !r.isRoleFinancial) return projectName;
        if (!d) return projectName;
        return `<a class="key-projectname">${projectName}</a>`;
      },
    },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => (d ? toNumber(Number(d), r.currencyDigit) : ''),
    },
    { data: 'currency', className: 'text-center' },
    {
      data: 'lgTypeId',
      render: (d, t, r) => (r.lgTypeCode ? `${r.lgTypeCode} | ${r.lgTypeName}` : ''),
    },
    { data: 'formFormat' },
    { data: 'bgFormName' },
    {
      data: 'statusLg',
      className: 'text-center',
      render: (d) => (d ? toStatus(d, true) : ''),
    },
    { data: 'costCenter' },
    { data: 'internalOrder' },
    {
      data: 'lgStatusDescription',
      render: (d, t, r) => {
        if (~['in progress', 'waiting for bank validation'].indexOf(toLower(r.statusLg))) {
          if (d) {
            return `<center><button class="btn btn-info-reverse key-lg-status">${d}</button></center>`;
          }
          return '<center><button class="btn btn-icon btn-info key-lg-status"><span class="icon icon-edit sq-24"></span></button></center>';
        }
        return '';
      },
    },
    { data: 'errorMessage' },
    { data: 'bankRemark' },
    {
      data: 'attachment',
      className: 'text-center',
      render: (d, t, r) =>
        '<button class="btn btn-icon btn-info attachment"><span class="icon icon-cloud-upload sq-24"></span></button>',
    },
    {
      data: 'companyBgLine',
      render: (d, t, r) => {
        if (d) {
          if (
            r.isRoleFinancial &&
            ~['confirmed', 'completed', 'inactive'].indexOf(toLower(r.statusLg))
          )
            return `<center><button class="btn btn-info-reverse key-company-bg-line">${r.companyBgLineFullName}</button></center>`;
          return r.companyBgLineFullName;
        }
        if (
          r.isRoleFinancial &&
          ~['confirmed', 'completed', 'inactive'].indexOf(toLower(r.statusLg))
        ) {
          return '<center><button class="btn btn-icon btn-info key-company-bg-line"><span class="icon icon-edit sq-24"></span></button></center>';
        }
        return '';
      },
    },
    { data: 'remarkFromFinance' },
    { data: 'initialName' },
    {
      data: 'initialStatus',
      render: (d, t, r) => toStatusApprove(r.initialName, d),
    },
    { data: 'approverBuName' },
    {
      data: 'approverBuStatus',
      render: (d, t, r) => toStatusApprove(r.approverBuName, d),
    },
    { data: 'approver1Name' },
    {
      data: 'approver1Status',
      render: (d, t, r) => toStatusApprove(r.approver1, d),
    },
    { data: 'approver2Name' },
    {
      data: 'approver2Status',
      render: (d, t, r) => toStatusApprove(r.approver2, d),
    },
    {
      data: 'expireDate',
      render: (d, t, r) => {
        if (r.termEndType === 'Open End') return '';
        if (!d) return '';

        const diff = moment().diff(moment(d), 'days');

        if (diff === 0) return `<span class="text-success2">${toNumber(diff, 0)}</span>`;
        if (diff > 0) return `<span class="text-danger2">+ ${toNumber(diff, 0)}</span>`;
        return `<span class="text-success2">- ${toNumber(Math.abs(diff))}</span>`;
      },
    },
    { data: 'inactiveByFullName' },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,

  onClickOpenModalFunction,
  onChangeModalData,
  onSubmitModalFunction,
  modalData,

  modalMethodRef,
  modalApproverRef,
  modalWithdrawRef,
  modalSendbackRef,
  modalRejectRef,
  modalBuApproveRef,

  isRoleFinancial,
  isRoleApproverBu,
  modalCancelRef,
  modalWithdrawBuRef,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;
  const selectedSftp = results.filter((f) => f.IsSelected && toLower(f.lgMethod) === 'sftp').length;

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Bank Guarantee <span className="icon icon-angle-double-right" /> Request
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MAIN_MASTER_FINANCE_GROUP}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  status
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MAIN_MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  status
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  name="Companies"
                  saveLocalState
                  country="TH"
                  isGetAll
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                />
              </FormGroup2>

              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  onChange={onChangeSelect2}
                  value={criteria.Banks}
                  name="Banks"
                  saveLocalState
                  isOutFlow
                  isGetAll
                  companyCode={criteria.Companies}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.Currencies}
                  filter={(m) =>
                    ~['thb', 'usd', 'cny', 'eur', 'gbp', 'sgd', 'jpy', 'hkd', 'chf', 'sek'].indexOf(
                      (m.currency || '').toLowerCase()
                    )
                  }
                  name="Currencies"
                  saveLocalState
                />
              </FormGroup2>

              <FormGroup2 text="Effective Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.EffectiveDateStr}
                  name="EffectiveDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Request Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.RequestDateStr}
                  name="RequestDateStr"
                />
              </FormGroup2>

              <FormGroup2 text="Expire Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.ExpireDateStr}
                  name="ExpireDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Beneficiary">
                <MasterDropdown
                  masterType={GET_BG_BENEFICIARY}
                  onChange={onChangeSelect2}
                  value={criteria.BeneficiaryId}
                  name="BeneficiaryId"
                  saveLocalState
                />
              </FormGroup2>

              <FormGroup2 text="Form Type">
                <MasterDropdown
                  masterType={GET_BG_LG_TYPE}
                  onChange={onChangeSelect2}
                  value={criteria.LgTypeId}
                  name="LgTypeId"
                  saveLocalState
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="LG Format">
                <select
                  className="form-control"
                  value={criteria.FormFormat}
                  onChange={onChangeSearch}
                  name="FormFormat"
                >
                  <option value="">---All---</option>
                  <option value="Paper">Paper</option>
                  <option value="Blockchain">Blockchain</option>
                </select>
              </FormGroup2>

              <FormGroup2 text="Form No">
                <MasterDropdown
                  masterType={GET_BG_FORM}
                  onChange={onChangeSelect2}
                  value={criteria.BgFormId}
                  name="BgFormId"
                  isActive
                  saveLocalState
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="LG Status">
                <MasterDropdownUI
                  value={criteria.StatusLg}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="StatusLg"
                  options={[
                    'Completed',
                    // "Confirmed",
                    'Created',
                    'Draft',
                    'In progress',
                    'Inactive',
                    'Rejected',
                    'Rejected by bank',
                    'Wait for approve',
                    'Wait for bank receive',
                    'Wait for BU approve',
                    'Wait for confirm',
                    'Wait for generate file',
                    'Wait for initialization',
                    'Waiting for bank validation',
                    'Validation failed by bank',
                    'Wait for BU to completed',
                    'Completed Expire',
                    'Inactive Completed',
                    'Amended',
                    'Cancelled',
                  ]}
                />
              </FormGroup2>

              <FormGroup2 text="LG Method">
                <MasterDropdownUI
                  value={criteria.LgMethod}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="LgMethod"
                  options={['Paper', 'SFTP']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Issue Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.IssueDateStr}
                  name="IssueDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/bank-guarantee/request"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    CREATE
                  </Link>
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <>
            {isRoleFinancial ? (
              <Col2 col={colX[2]}>
                <div>
                  {isRoleFinancial ? (
                    <>
                      <Button
                        txt="CONFIRM"
                        icon="check"
                        className="btn-success"
                        onClick={(e) => onClickOpenModalFunction('confirm')}
                      />
                      &nbsp;
                      <Button
                        txt="WITHDRAW"
                        icon="refresh"
                        className="btn-warning"
                        onClick={(e) => onClickOpenModalFunction('withdraw')}
                      />
                      &nbsp;
                    </>
                  ) : null}
                  <Button
                    txt="send back to edit"
                    icon="undo"
                    className="btn-default"
                    onClick={(e) => onClickOpenModalFunction('send back')}
                  />
                  &nbsp;
                  <Button
                    txt="reject"
                    icon="close"
                    className="btn-danger"
                    onClick={(e) => onClickOpenModalFunction('reject')}
                  />
                  &nbsp;
                  <Button
                    txt="WITHDRAW BU"
                    icon="refresh"
                    className="btn-lightbrown"
                    onClick={(e) => onClickOpenModalFunction('withdraw bu')}
                  />
                  &nbsp;
                  {true ? (
                    <>
                      <Button
                        txt="bu approve"
                        icon="check"
                        className="btn-info"
                        onClick={(e) => onClickOpenModalFunction('bu approve')}
                      />
                      &nbsp;
                    </>
                  ) : null}
                  {isRoleFinancial ? (
                    <ButtonDropdown
                      className="btn-blank"
                      text="Edit"
                      icon="icon-edit"
                      buttons={[
                        {
                          text: 'Select Bank',
                          icon: 'icon-university',
                          onClick: (e) => {
                            onClickOpenModalFunction('select bank');
                          },
                        },
                        {
                          text: 'Select Method',
                          icon: 'icon-credit-card-alt',
                          onClick: (e) => {
                            onClickOpenModalFunction('select method');
                          },
                        },
                      ]}
                    />
                  ) : null}
                  &nbsp;
                  <Button
                    txt="Change Issue Date"
                    icon="calendar"
                    className="btn-purple"
                    onClick={() => onClickOpenModalFunction('issue date')}
                    disabled={false}
                  />
                </div>
              </Col2>
            ) : (
              <Button
                txt="WITHDRAW BU"
                icon="refresh"
                className="btn-lightbrown"
                onClick={(e) => onClickOpenModalFunction('withdraw bu')}
              />
            )}
          </>
        ) : (
          <Button
            txt="WITHDRAW BU"
            icon="refresh"
            className="btn-lightbrown"
            onClick={(e) => onClickOpenModalFunction('withdraw bu')}
          />
        )}

        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>
                Active/
                <br />
                Inactive
              </th>
              <th>
                Print
                <br />
                Form
              </th>
              <th>Amend</th>
              <th>Transaction No.</th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>Company Name</th>
              <th>Bank</th>
              <th>Branch</th>
              <th>Issue Date</th>
              <th>LG Method</th>
              <th>LG No.</th>
              <th>Beneficiary Name (TH)</th>
              <th>Project Name</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>LG Format</th>
              <th>Form Type</th>
              <th>Form No.</th>
              <th>LG Status</th>
              <th>Cost Center</th>
              <th>Internal Order</th>
              <th>LG Status Description</th>
              <th>Error Message</th>
              <th>Bank Remark</th>
              <th>Attachment</th>
              <th>Company (BG Line)</th>
              <th>Remark from Initial/BU/Finance</th>
              <th>Initial</th>
              <th>Initial Status</th>
              <th>Approver BU</th>
              <th>Approver BU Status</th>
              <th>Approver 1</th>
              <th>Approver 1 Status</th>
              <th>Approver 2</th>
              <th>Approver 2 Status</th>
              <th>Expire Days</th>
              <th>Inactive By</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      {/* <ModalFunction
                textHeader="InActive"
                textContent="inactive"
                modalRef={modalInactiveRef}
                type="danger"
                icon="icon-close"
                textBtn="Yes"
                textBtnBack="No"
                noSelect={true}
                onClick={e => onSubmitModalChangeStatus(e, 'Inactive')}
            /> */}

      <Modal size="modal-md" ref={modalInactiveRef} modalFooter>
        <form onSubmit={onSubmitModalFunction} className="form-horizontal">
          <div className="text-center">
            <span className="text-danger icon icon-check icon-5x" />
            <h3 className="text-danger">Inactive</h3>
            <p>Do you want to inactive this item?</p>
          </div>
          <br />
          <FormGroup3 text="Inactive by" col={colX1} required>
            <MasterDropdown
              masterType={MASTER_USER_BG}
              isChoose
              notMultipleSelect2
              value={modalData.InactiveBy}
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              action="financial"
              noValidateOption
              saveLocalState
              required
              name="InactiveBy"
            />
          </FormGroup3>
          <div className="row" style={{ paddingTop: '10px' }}>
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalMethodRef} modalFooter>
        <form onSubmit={onSubmitModalFunction} className="form-horizontal">
          <div className="text-center">
            {modalData.Action === 'key lg' ? (
              <h2 className="text-info">LG No.</h2>
            ) : modalData.Action === 'bg line' ? (
              <h2 className="text-info">Company (BG Line)</h2>
            ) : modalData.Action === 'key project' ? (
              <h2 className="text-info">Project Name</h2>
            ) : modalData.Action === 'lg description' ? (
              <h2 className="text-info">LG Status Description</h2>
            ) : (
              <>
                <span className="label label-info">{selectedResultCount} items</span>
                <h2 className="text-info">
                  {modalData.Action === 'issue date'
                    ? 'Issue Date'
                    : modalData.Action === 'select method'
                      ? 'Select Method'
                      : 'Select Bank'}
                </h2>
              </>
            )}
          </div>
          <br />
          {modalData.Action === 'select bank' ? (
            <>
              <FormGroup3 text="Bank" col={colX1} required>
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  isChoose
                  notMultipleSelect2
                  value={modalData.BankCode}
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  noValidateOption
                  saveLocalState
                  required
                  isOutFlow
                  isGetAll
                  name="BankCode"
                />
              </FormGroup3>

              <FormGroup3 text="Branch" col={colX1} required>
                <MasterDropdown
                  masterType={GET_BG_BRANCH}
                  isChoose
                  notMultipleSelect2
                  value={modalData.BranchId}
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  noValidateOption
                  saveLocalState
                  required
                  bankCode={modalData.BankCode}
                  isActive
                  name="BranchId"
                />
              </FormGroup3>
            </>
          ) : modalData.Action === 'select method' ? (
            <FormGroup3 text="LG Method" col={colX1} required>
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={modalData.LgMethod}
                onChange={(e) =>
                  onChangeModalData({
                    target: e,
                  })
                }
                required
                name="LgMethod"
                options={['SFTP', 'Paper']}
              />
            </FormGroup3>
          ) : modalData.Action === 'key lg' ? (
            <FormGroup3 text="LG No." col={colX1} required>
              <input
                className="form-control"
                value={modalData.LgNo}
                onChange={onChangeModalData}
                required
                name="LgNo"
                // maxLength={12}
              />
            </FormGroup3>
          ) : modalData.Action === 'key project' ? (
            <FormGroup3 text="Project Name" col={colX1} required>
              <textarea
                className="form-control"
                rows="3"
                value={modalData.ProjectName}
                onChange={onChangeModalData}
                required
                name="ProjectName"
                maxLength={500}
              />
            </FormGroup3>
          ) : modalData.Action === 'bg line' ? (
            <FormGroup3 text="Company (BG Line)" col={colX1} required>
              <MasterDropdown
                masterType={MASTER_COMPANY}
                isChoose
                notMultipleSelect2
                onChange={(e) =>
                  onChangeModalData({
                    target: e,
                  })
                }
                noValidateOption
                saveLocalState
                required
                value={modalData.CompanyBgLine}
                name="CompanyBgLine"
              />
            </FormGroup3>
          ) : modalData.Action === 'issue date' ? (
            <FormGroup3 text="Issue Date" col={colX1} required>
              <DatePicker
                onChange={onChangeModalData}
                name="IssueDateStr"
                value={modalData.IssueDateStr}
                option={{
                  // startDate: "d",
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required
              />
            </FormGroup3>
          ) : modalData.Action === 'lg description' ? (
            <FormGroup3 text="LG Status Description" col={colX1} required>
              <input
                className="form-control"
                value={modalData.LgStatusDescription}
                onChange={onChangeModalData}
                required
                name="LgStatusDescription"
                // maxLength={12}
              />
            </FormGroup3>
          ) : null}
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalApproverRef} modalFooter>
        <form onSubmit={onSubmitModalFunction} className="form-horizontal">
          <div className="text-center">
            <span className="text-success icon icon-check icon-5x" />
            <h3 className="text-success">Confirm</h3>
            <p>{selectedResultCount} items selected, click to confirm?</p>
          </div>
          <br />
          {selectedSftp ? (
            <>
              <FormGroup3 text="Approver 1" col={colX1} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  isChoose
                  notMultipleSelect2
                  value={modalData.Approver1}
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  filter={(m) => m.roleName === 'Approver 1'}
                  noValidateOption
                  saveLocalState
                  required
                  financeGroupId={modalData.FinanceGroupId}
                  name="Approver1"
                />
              </FormGroup3>

              <FormGroup3 text="Approver 2" col={colX1} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  isChoose
                  notMultipleSelect2
                  value={modalData.Approver2}
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                  noValidateOption
                  saveLocalState
                  required
                  financeGroupId={modalData.FinanceGroupId}
                  name="Approver2"
                />
              </FormGroup3>
              <br />
            </>
          ) : null}
          <div className="row" style={{ paddingTop: '10px' }}>
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-success" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Withdraw"
        textContent="withdraw"
        modalRef={modalWithdrawRef}
        type="warning"
        icon="icon-refresh"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onSubmitModalFunction}
      />

      <ModalFunction
        textHeader="BU Approve"
        textContent="bu approve"
        modalRef={modalBuApproveRef}
        type="info"
        icon="icon-check"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onSubmitModalFunction}
      />

      <ModalFunction
        textHeader="Cancel"
        textContent="cancel"
        modalRef={modalCancelRef}
        type="warning"
        icon="icon-refresh"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onSubmitModalFunction}
      />

      <Modal size="modal-md" ref={modalSendbackRef} modalFooter>
        <form onSubmit={onSubmitModalFunction} className="form-horizontal">
          <div className="text-center">
            <span className="text-info icon icon-undo icon-5x" />
            <h3 className="text-info">Send back to edit</h3>
            <p>{selectedResultCount} items selected, click to send back to edit?</p>
          </div>
          <br />
          <FormGroup3 text="Remark" col={colX1} required>
            <textarea
              className="form-control"
              rows="3"
              value={modalData.RemarkFromFinance}
              onChange={onChangeModalData}
              required
              maxLength={255}
              name="RemarkFromFinance"
            />
          </FormGroup3>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalRejectRef} modalFooter>
        <form onSubmit={onSubmitModalFunction} className="form-horizontal">
          <div className="text-center">
            <span className="text-danger icon icon-close icon-5x" />
            <h3 className="text-danger">Reject</h3>
            <p>{selectedResultCount} items selected, click to reject?</p>
          </div>
          <br />
          <FormGroup3 text="Remark" col={colX1} required>
            <textarea
              className="form-control"
              rows="3"
              value={modalData.RemarkFromFinance}
              onChange={onChangeModalData}
              required
              maxLength={255}
              name="RemarkFromFinance"
            />
          </FormGroup3>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-danger" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalWithdrawBuRef} modalFooter>
        <form onSubmit={onSubmitModalFunction} className="form-horizontal">
          <div className="text-center">
            <span className="text-warning icon icon-close icon-5x" />
            <h3 className="text-warning">Withdraw BU</h3>
            <p>{selectedResultCount} items selected, click to reject?</p>
          </div>
          <br />
          <FormGroup3 text="Remark" col={colX1} required>
            <textarea
              className="form-control"
              rows="3"
              value={modalData.RemarkFromFinance}
              onChange={onChangeModalData}
              required
              maxLength={255}
              name="RemarkFromFinance"
            />
          </FormGroup3>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-warning" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};
