import React, {
  useRef,
  useState,
  useEffect,
  useReducer,
  useContext,
  forwardRef,
  useCallback,
  createContext,
  useImperativeHandle,
} from 'react';

import ChargeTab from './ChargeTab';
import Tab from '../../../common/Tab';
import { store } from '../../../Root';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Link from '../../../common/Link';
import InterestTab from './InterestTab';
import StructureTab from './StructureTab';
import { initialState } from './initState';
import FormGroup2 from '../../../common/FormGroup2';
import CashFlowTab, { mapCashflow } from './CashFlowTab';
import { addAlert } from '../../../reducers/layout/action';
import MasterDropdown from '../../../common/MasterDropdown';
import { useOnChangeInput } from '../../../common/customeHook';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { saveContractLoan } from '../../../reducers/contractLoan/action';
import { getLoan, getFacilityList } from '../../../reducers/common/action';
import { toFixed, toUpperKey, compareDate } from '../../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_HOLIDAY,
  getCurrencyDigit,
} from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
let tempId = 1;
let repaymentTempId = 1;
export const StateContext = createContext({});
export const FacilityList = createContext([]);
export const FormRefContext = createContext({});
export const InputMaskOptionContext = createContext({});
const { moment } = window;
const infomationReducer = (oldState, action) => {
  const { name, value, target } = action;
  const state = {
    ...oldState,
    [name]: value,
  };
  // Information
  switch (name) {
    case 'BusinessPartner':
    case 'CompanyCode':
      let find = {};
      if (state.PartnerType === 'External' && name !== 'CompanyCode')
        find = (target.datas || []).find((f) => f.bankCode === value);
      else find = (target.datas || []).find((f) => f.companyCode === value);

      const currency1 = (find || {}).currency1 || '';
      state[`${name}LocalCurrency`] = currency1;
      if (name === 'CompanyCode') state.BusinessArea = '';
      if (name === 'BusinessPartner') state.BusinessAreaPartner = '';
      updateLocalCurrency(name, currency1);
      break;
    case 'LoanType':
      if (value === 'On Call') state.TermEndStr = '';
      break;
    case 'PartnerType':
      state.TransactionType = '';
      state.IcType = '';
      state.BusinessPartner = '';
      if (value === 'External') state.TransactionType = 'Loan';
      break;
    default:
      break;
  }

  function updateLocalCurrency(company, cur) {
    const key = company === 'CompanyCode' ? 'CashflowLoan' : 'CashflowDeposit';
    const type = ['DrawdownList', 'RepaymentList'];
    type.forEach((m) => {
      state[key][m] = state[key][m].map((m) => ({ ...m, LocalCurrency: cur }));
    });
  }

  return state;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return infomationReducer(state, action);
    case 'UPDATE_CASHFLOW_CHARGE':
      const { cashFlowType, index, name, value, datas = [] } = action;
      const cashFlowUpdateKey = `CashFlowCharge${cashFlowType}`;
      const newState = {
        ...state,
      };
      const newCashFlows = newState[cashFlowUpdateKey].map((m, idx) => {
        if (index === null || index === idx) {
          const newData = {
            ...m,
            [name]: value,
          };
          switch (name) {
            case 'BankAccountNo':
              if (!value) {
                newData.ExchangeRate = null;
                newData.LocalCurrencyAmount = null;
              } else if (
                newState.Currency === newState.CompanyCurrency &&
                newState.Currency === newData.Currency
              ) {
                const bankAccountData = datas.find((f) => f.bankAccountNo === value);
                if (bankAccountData.currency === newState.Currency) {
                  newData.ExchangeRate = 1;
                  newData.LocalCurrencyAmount = newData.Amount;
                }
              }
              break;
          }

          return newData;
        }
        return m;
      });
      newState[cashFlowUpdateKey] = newCashFlows;
      return newState;
    case 'ADD_CASHFLOW_CHARGE':
      const cashFlowKey = `CashFlowCharge${action.cashFlowType}`;
      return {
        ...state,
        [cashFlowKey]: [...state[cashFlowKey], { ...action.payload }],
      };
    case 'UPDATE_FACILITY_SELECT':
      return {
        ...state,
        FacilityNo: action.FacilityNo,
        Outstanding: action.Outstanding,
      };
    case 'UPDATE_CASHFLOW':
      return {
        ...state,
        [`Cashflow${action.cashFlowType}`]: {
          ...state[`Cashflow${action.cashFlowType}`],
          [action.name]: action.value,
        },
      };
    case 'ADD_INTEREST':
      const dataToUpdate = {
        ...state,
        [`${action.structureType}List`]: [
          ...state[`${action.structureType}List`].filter((f) => {
            if (action.isRollover) {
              if (f.LineId === action.payload[0].LineId) return false;
              return true;
            }
            return true;
          }),
          ...action.payload.map((m) => ({
            ...m,
            TempId: tempId++,
          })),
        ],
      };
      return dataToUpdate;
    case 'EDIT_INTEREST':
      return {
        ...state,
        [`${action.structureType}List`]: [
          ...state[`${action.structureType}List`].map((m, i) => {
            if (i === action.index)
              return {
                ...action.payload,
              };
            return m;
          }),
        ],
      };
    case 'REMOVE_STRUCTOR':
      let removeId = null;
      let prevStructor = null;
      let stateToUpdate = {
        ...state,
        [`${action.structureType}List`]: [
          ...state[`${action.structureType}List`].filter((m, i) => {
            if (action.index === i) {
              if (m.Id) {
                // finde id for remove
                removeId = m.Id;
              }
              if (action.structureType === 'Drawdown') {
                // get prev structure to active
                const filter = (state.AllInterestStructors || [])
                  .filter((f) => f.LineId === m.LineId && f.Id !== m.Id)
                  .sort((a, b) => {
                    const aD = window.moment(a.ValidFromStr, 'DD/MM/YYYY');
                    const bD = window.moment(b.ValidFromStr, 'DD/MM/YYYY');
                    return aD.isBefore(bD, 'day') ? 1 : aD.isAfter(bD, 'day') ? -1 : 0;
                  });
                prevStructor = filter[0];
              }
              return false;
            }
            return true;
          }),
        ],
      };
      if (removeId !== null)
        stateToUpdate.RemoveInterestStructures = [
          ...stateToUpdate.RemoveInterestStructures,
          removeId,
        ];
      if (prevStructor) {
        // insert prev rollover
        stateToUpdate[`${action.structureType}List`] = [
          ...stateToUpdate[`${action.structureType}List`],
          { ...prevStructor },
        ];
        // remove from temp
        stateToUpdate.AllInterestStructors = (stateToUpdate.AllInterestStructors || []).filter(
          (f) => f !== prevStructor
        );
      }

      return stateToUpdate;
    case 'UPDATE_STRUCTOR':
      return {
        ...state,
        [`${action.structureType}List`]: action.payload,
      };
    case 'SET':
      return {
        ...initialState,
        ...action.payload,
      };
    case 'SET_CASHFLOW':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_REPAYMENT_STRUCTURE':
      return {
        ...state,
        RepaymentStructures: [...action.payload],
      };
    case 'ADD_REPAYMENT_STRCUTURE':
      return {
        ...state,
        RepaymentStructures: [
          ...state.RepaymentStructures,
          {
            ...action.payload,
            TempId: repaymentTempId++,
            InterestStructures: (action.payload.InterestStructures || []).map((m) => ({
              ...m,
              TempId: tempId++,
            })),
          },
        ],
      };
    case 'REMOVE_REPAYMENT_STRUCTURE':
      removeId = null;
      stateToUpdate = {
        ...state,
        RepaymentStructures: state.RepaymentStructures.filter((m, i) => {
          if (i === action.index) {
            removeId = m.Id;
            return false;
          }
          return true;
        }),
      };

      if (removeId)
        stateToUpdate.RemoveRepaymentStructures = [
          ...stateToUpdate.RemoveRepaymentStructures,
          removeId,
        ];
      return stateToUpdate;
    case 'EDIT_REPAYMENT_STRUCTURE':
      const removeIdxs = [];

      stateToUpdate = {
        ...state,
        RepaymentStructures: state.RepaymentStructures.map((m, repayIdx) => {
          if (repayIdx !== action.repayIdx) return m;

          return {
            ...action.payload,
            InterestStructures: action.payload.InterestStructures.map((m, i) => {
              // compare with old interest structure
              // and push id that was remove
              const oldCreated = (m.InterestStructures || []).filter((f) => f.Id);
              oldCreated.forEach((o) => {
                const stillExists = action.payload.InterestStructures.find(
                  (f) => f.Id && f.Id === o.Id
                );
                if (!stillExists) removeIdxs.push(o.Id);
              });
              if (!m.TempId) m.TempId = tempId++;
              return m;
            }),
          };
        }),
      };
      if (removeIdxs.length)
        stateToUpdate.RemoveInterestStructures = [
          ...stateToUpdate.RemoveInterestStructures,
          ...removeIdxs,
        ];
      return stateToUpdate;
    default:
      return state;
  }
};

export default function CreateContractLoan(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [flagFetchLoan, setFlagFetchLoan] = useState(0);
  const [facilityList, setFacilityList] = useState([]);
  const [isCreate, setIsCreate] = useState(true);
  const [inputMaskOption, setInputMaskOption] = useState(null);
  useEffect(() => {
    store
      .dispatch(
        getCurrencyDigit({
          criteria: state.Currency,
        })
      )
      .then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        setInputMaskOption({
          prefix: '',
          digits: digit,
          digitsOptional: false,
          placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
        });

        dispatch({
          type: 'UPDATE',
          name: 'Amount',
          value: toFixed(Number((state.Amount.toString() || '').replace(/,/g, '') || 0), digit),
        });
      });
  }, [state.Currency]);

  const tabRef = useRef(null);
  const formHeaderRef = useRef(null);
  const formStructureRef = useRef(null);
  const forms = [formHeaderRef, formStructureRef];
  const onClicktSaveLoan = useCallback(
    (e) => {
      function validateForm() {
        let formEle = null;
        for (let i = 0; i < forms.length; i++) {
          const form = forms[i].current;
          if (form && !form.checkValidity()) {
            i = forms.lengths;
            formEle = form;
          }
        }
        if (!formEle) return;

        const formName = formEle.name;
        if (tabRef.current.tab.state.currentTab !== formName && formName !== 'detail') {
          tabRef.current.tab.setTabActive(formName);
          setTimeout(() => {
            formEle.reportValidity();
          }, 500);
        } else formEle.reportValidity();
        return formName;
      }

      async function submit() {
        const submitData = {
          ...state,
          Source: 'Contract Loan',
        };
        delete submitData.DrawdownStart;
        delete submitData.DrawdownEnd;

        const response = await store.dispatch(saveContractLoan(submitData));
        if (response.error) return;
        if (response.payload) {
          window.location.href = `/loan-investment/contract-loan/${response.payload}`;
        }
      }

      if (!validateForm()) submit();
    },
    [state, tabRef, ...forms]
  );

  useEffect(() => {
    let ignore = false;
    const { id } = props.routeProp.match.params;
    async function fetchLoan() {
      setIsCreate(true);
      const response = await store.dispatch(getLoan(id));
      if (response.error) return;
      if (ignore) return;

      setIsCreate(!response.payload);
      if (response.payload) {
        const datToUpdate = toUpperKey(response.payload);
        const mapCashflowResult = mapCashflow(datToUpdate.CashFlow);
        mapCashflowResult.CashflowDeposit.DrawdownBankCode = (
          datToUpdate.BankCodeDeposit || ''
        ).split(',');
        mapCashflowResult.CashflowDeposit.RepaymentBankCode = (
          datToUpdate.BankCodeDepositRepayment || ''
        ).split(',');
        mapCashflowResult.CashflowLoan.DrawdownBankCode = (datToUpdate.BankCodeLoan || '').split(
          ','
        );
        mapCashflowResult.CashflowLoan.RepaymentBankCode = (
          datToUpdate.BankCodeLoanRepayment || ''
        ).split(',');
        const structureInterest = mapInterestStructure(
          datToUpdate.InterestStructures,
          datToUpdate.RepaymentStructures
        );
        datToUpdate.RepaymentStructures = (datToUpdate.RepaymentStructures || []).map((m) =>
          toUpperKey(m)
        );
        if (datToUpdate.AllInterestStructors && datToUpdate.AllInterestStructors !== null) {
          datToUpdate.AllInterestStructors = datToUpdate.AllInterestStructors.filter((m) => {
            // remove last active item from history
            const find = structureInterest.DrawdownList.find((f) => f.Id === m.id);
            return !find;
          }).map((m) => toUpperKey(m));
        }
        // cal repayment interest amount
        const totalDrawdown = structureInterest.DrawdownList.reduce(
          (prev, m) => prev + (Number(m.Amount) || 0),
          0
        );
        datToUpdate.RepaymentStructures.forEach((m, i, self) => {
          m.InterestStructures = (m.InterestStructures || []).map((interest, idx) => {
            const passedRepayment = self.reduce((prev, r) => {
              if (
                moment(interest.ValidFromStr, 'DD/MM/YYYY').isSameOrAfter(
                  moment(r.RepaymentDateStr, 'DD/MM/YYYY')
                )
              )
                return prev + (Number(r.RepaymentAmount) || 0);
              return prev;
            }, 0);
            const newData = {
              ...interest,
              Outstanding: totalDrawdown - passedRepayment,
            };
            newData.InterestAmount = calInterestAmount(newData);
            return newData;
          });
          m.InterestAmount = m.InterestStructures.reduce(
            (prev, cur) => prev + (Number(cur.InterestAmount) || 0),
            0
          );
          m.Ordering = i + 1;
        });
        delete datToUpdate.CashFlow;
        delete datToUpdate.BankCodeDeposit;
        delete datToUpdate.BankCodeDepositRepayment;
        delete datToUpdate.BankCodeLoan;
        delete datToUpdate.BankCodeLoanRepayment;
        delete datToUpdate.InterestStructures;
        dispatch({
          type: 'SET',
          payload: {
            ...datToUpdate,
            ...mapCashflowResult,
            ...structureInterest,
          },
        });
      }
    }

    if (id) fetchLoan();

    return () => {
      ignore = true;
    };
  }, [props.routeProp.match.params.id, flagFetchLoan]);

  useEffect(() => {
    let Outstanding = '';
    facilityList.forEach((m) => {
      if (m.transactionNo === state.FacilityNo) {
        Outstanding = m.outstanding;
      }
    });

    dispatch({
      type: 'UPDATE',
      name: 'Outstanding',
      value: Outstanding,
    });
  }, [facilityList, state.FacilityNo]);
  useEffect(() => {
    let ignore = false;
    async function getFacilitys() {
      const facilityCriteria = {
        CompanyCode: state.CompanyCode,
        BusinessPartnerCode: state.BusinessPartner,
        ProductType: 'Contract Loan',
        IsProject: state.IsProject,
        StartDateStr: state.TermStartStr,
        EndDateStr: state.TermEndStr,
        Currency: state.Currency,
      };
      const response = await store.dispatch(getFacilityList(facilityCriteria));
      if (!response.error && response.payload && !ignore) setFacilityList(response.payload || []);
    }
    getFacilitys();

    return () => {
      ignore = true;
    };
  }, [
    state.CompanyCode,
    state.BusinessPartner,
    state.TermStartStr,
    state.TermEndStr,
    state.Currency,
    state.IsProject,
  ]);

  useEffect(validateDate, [state.ContractDateStr, state.TermStartStr, state.TermEndStr]);
  return (
    <>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Funding & Investment <span className="icon icon-angle-double-right" />
            <Link txt="Create Contract Loan" href="/loan-investment/create-contract-loan" />
            <span className="icon icon-angle-double-right" /> Loan Detail
          </small>
        </p>
      </div>
      <StateContext.Provider value={{ state, dispatch }}>
        <FormRefContext.Provider
          value={{
            formHeaderRef,
            formStructureRef,
            onClicktSaveLoan,
            isCreate,
            setFlagFetchLoan,
          }}
        >
          <CardHeader />
          <FacilityList.Provider value={facilityList}>
            <InputMaskOptionContext.Provider value={inputMaskOption}>
              <Tabs ref={tabRef} />
            </InputMaskOptionContext.Provider>
          </FacilityList.Provider>
        </FormRefContext.Provider>
      </StateContext.Provider>
    </>
  );

  function calInterestAmount(data) {
    const {
      InterestRate,
      Outstanding,
      ValidFromStr,
      ValidToStr,
      IsManualInclude,
      CalculateMethod,
    } = data;

    let daysDiff = moment(ValidToStr, 'DD/MM/YYYY').diff(
      moment(ValidFromStr, 'DD/MM/YYYY'),
      'days'
    );
    if (IsManualInclude) daysDiff += 1;
    let totalDayinYear = 0;
    switch (CalculateMethod) {
      case 'Act/360':
        totalDayinYear = 360;
        break;
      case 'Act/366':
        totalDayinYear = 366;
        break;
      case 'Act/365':
        totalDayinYear = 365;
        break;
      default:
        totalDayinYear = isLeapYear(moment(ValidToStr, 'DD/MM/YYYY').year()) ? 366 : 365;
    }
    return (((Number(InterestRate) / 100) * Number(Outstanding)) / totalDayinYear) * daysDiff;
  }

  function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  function validateDate() {
    function update(name, value) {
      dispatch({
        type: 'UPDATE',
        name,
        value,
      });
    }
    if (compareDate(state.ContractDateStr, state.TermStartStr)) {
      store.dispatch(
        addAlert({
          title: 'Error',
          type: 'error',
          body: 'Contract Date must be less than or equal Term Start.',
        })
      );
      update('ContractDateStr', '');
    } else if (compareDate(state.TermStartStr, state.TermEndStr)) {
      store.dispatch(
        addAlert({
          title: 'Error',
          type: 'error',
          body: 'Term Start must be less than or equal Term End.',
        })
      );
      update('TermStartStr', '');
    } else if (compareDate(state.ContractDateStr, state.TermEndStr)) {
      store.dispatch(
        addAlert({
          title: 'Error',
          type: 'error',
          body: 'Term End must be more than or equal Contract Date.',
        })
      );
      update('TermEndStr', '');
    }
  }
}

function CardHeader(props) {
  const { state, dispatch } = useContext(StateContext);
  const { formHeaderRef, isCreate } = useContext(FormRefContext);
  const onChange = useOnChangeInput(dispatch);
  const header = !isCreate ? `Loan No.: ${state.TransactionNo}` : 'Create Contract Loan';
  const noEdit = !isCreate;
  return (
    <Card textHeader={header} bgHeader="bg-primary" cardActions={['toggler']}>
      <form name="detail" ref={formHeaderRef}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required>
              {noEdit ? (
                <input
                  type="text"
                  className="form-control"
                  value={state.FinanceGroupName || ''}
                  disabled
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  noValidateOption={noEdit}
                  saveLocalState
                  onChange={(e) =>
                    onChange({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={noEdit}
                  value={state.FinanceGroupId}
                  name="FinanceGroupId"
                />
              )}
            </FormGroup2>

            <FormGroup2 text="Company" required>
              {noEdit ? (
                <input
                  className="form-control"
                  value={`${state.CompanyCode} | ${state.CompanyName}`}
                  disabled
                />
              ) : state.FinanceGroupId ? (
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  noValidateOption={noEdit}
                  saveLocalState
                  onChange={(e) =>
                    onChange({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  disabled={noEdit}
                  financeGroupId={state.FinanceGroupId}
                  value={state.CompanyCode}
                  name="CompanyCode"
                />
              ) : (
                <MasterDropdownUI
                  onChange={(e) =>
                    onChange({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  value={state.CompanyCode}
                  name="CompanyCode"
                  options={null}
                />
              )}
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <div className="form-group">
              <div>
                <input
                  id="IsProject"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={(e) =>
                    onChange({
                      target: {
                        value: e.target.checked,
                        name: e.target.name,
                      },
                    })
                  }
                  name="IsProject"
                  checked={state.IsProject}
                />
                <label htmlFor="IsProject" className="label_checkbox" />
                <label htmlFor="IsProject" className="control-label text_label_checkbox">
                  Project
                </label>
              </div>
            </div>
            {/* <FormGroup2 text="Project Name" required={false}>
                        <input className="form-control"
                            onChange={onChange}
                            value={state.ProjectName}
                            name="ProjectName"
                        />
                    </FormGroup2> */}
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[0]}>
            <FormGroup2 text="Calendar" required={false}>
              <MasterDropdown
                masterType={MASTER_HOLIDAY}
                onChange={(e) =>
                  onChange({
                    target: e,
                  })
                }
                saveLocalState
                status
                isChoose
                notMultipleSelect2
                value={state.CalendarId}
                name="CalendarId"
              />
            </FormGroup2>
            <FormGroup2 text="Remark" required={false}>
              <textarea
                className="form-control"
                rows="2"
                onChange={onChange}
                value={state.Remark}
                name="Remark"
              />
            </FormGroup2>
          </Col2>
        </Col2>
      </form>
    </Card>
  );
}

const Tabs = forwardRef(({}, ref) => {
  const tabRef = useRef(null);
  useImperativeHandle(
    ref,
    () => ({
      tab: tabRef.current,
    }),
    [tabRef]
  );
  const { state } = useContext(StateContext);

  const tabContents = [];
  // ------------------ Tab Structure ----------------------
  tabContents.push({
    header: <span>Structure</span>,
    content: <StructureTab />,
    tabName: 'structure',
  });

  if (state.TransactionNo) {
    // ------------------ Tab Interest ----------------------
    tabContents.push({
      header: <span>Interest</span>,
      content: <InterestTab />,
      tabName: 'interest',
    });

    // ------------------ Tab Cash Flow ----------------------
    tabContents.push({
      header: <span>Cash Flow</span>,
      content: <CashFlowTab />,
      tabName: 'cashflow',
    });
    tabContents.push({
      header: <span>Charge</span>,
      content: <ChargeTab />,
      tabName: 'charge',
    });
  }

  return <Tab ref={tabRef} tabs={tabContents} />;
});

function mapInterestStructure(structure = [], repaymentStructure = []) {
  const state = {
    DrawdownList: [],
  };

  structure.forEach((m) => {
    const newData = { ...toUpperKey(m) };
    delete newData.ValidFrom;
    delete newData.ValidTo;
    delete newData.ContractDate;
    if (m.structureType.toLowerCase() === 'drawdown') state.DrawdownList.push(newData);
    else {
      const repayStructure = repaymentStructure.find((f) => f.id === m.repaymentId);
      if (repayStructure) {
        repayStructure.InterestStructures = [
          ...(repayStructure.InterestStructures || []),
          { ...newData },
        ];
      }
    }
  });
  return state;
}
