import { connect } from 'react-redux';
import React, { Component } from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import MarkToMarket from './MarkToMarket';
import { toFixed } from '../../common/helpper';
import ImportExcel from '../../common/ImportExcel';
import { addAlert } from '../../reducers/layout/action';
import { getMasterUserDetail } from '../../reducers/masterUser/action';
import {
  fetchEmail,
  editCriteria,
  clearCriteria,
  importExcelMtm,
  downloadFileMtm,
  saveImportExcel,
  genFlowFXMonthEnd,
  searchMarkToMarket,
  updateSearchResult,
  saveMTMMappingContract,
  downloadMTMForwardExcel,
} from '../../reducers/markToMarket/action';

export const DATATABLE_ID = 'table-result-marktomarket';

const jwt = JSON.parse(localStorage.getItem('jwt')) || { username: '' };
const username = jwt.username || '';
const $ = window.jQuery;
const canExportMtmRoles = ['Accountant-NonIC', 'Accountant-Posting', 'Admin Accounting'];
class MarkToMarketContainer extends Component {
  state = {
    results: [],
    modalMapping: {
      mappingContractNo: '',
    },
    exportCriteria: {
      FinanceGroup: [],
      BankCode: '',
      ReportDate: '',
      CompanyCode: [],
    },
    canGenFlowFxMonthEnd: false,
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    this.dataTableSearchResultRef.$dataTable.on(
      'click',
      `button.download`,
      this.onClickDownloadFile
    );
    this.dataTableSearchResultRef.$dataTable.on(
      'click',
      `.edit-mapping-contract`,
      this.onClickEditMappingContract
    );

    this.setState({
      results: this.props.searchResult.filter((f) => f.type === 'Forward'),
    });

    this.props.getMasterUserDetail(username).then((response) => {
      if (response.error || !response.payload) return;
      const roles = response.payload.resultRole || [];
      const findAdmin = roles.find((f) => canExportMtmRoles.includes(f.roleName));
      this.setState({
        canGenFlowFxMonthEnd: !!findAdmin,
      });
    });
  }

  componentWillUnmount() {
    this.dataTableSearchResultRef.$dataTable.off(
      'click',
      `button.download`,
      this.onClickDownloadFile
    );
    this.dataTableSearchResultRef.$dataTable.off(
      'click',
      `.edit-mapping-contract`,
      this.onClickEditMappingContract
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchResult !== prevProps.searchResult)
      this.setState({
        results: this.props.searchResult.filter((f) => f.type === 'Forward'),
      });
  }

  onClickGenFlowMonthEnd = () => {
    this.props.genFlowFXMonthEnd();
  };

  onClickDownloadFile = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const selected = this.state.results[row];
    const { originalFileName } = selected;
    if (!originalFileName)
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'File not found',
      });
    else this.props.downloadFileMtm(originalFileName);
  };

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMarkToMarket({
      ...this.props.criteria,
      Type: 'forward',
    });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickFetchForward = () => {
    this.props.fetchEmail();
  };

  onUploadViewImportExcel = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    this.props.importExcelMtm(formData);
  };

  onSubmitSaveImportExcel = () => {
    const data = this.props.importExcel.rows;
    this.props.saveImportExcel(data).then((res) => {
      if (res.error) return;
      this.importRef.close();
    });
  };

  onClickEditMappingContract = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    this.setState(
      {
        modalMapping: {
          mappingContractNo: rowData.mappingContractNo || '',
          id: rowData.id,
        },
      },
      this.modal.open
    );
  };

  onSubmitSaveMappingContract = (e) => {
    e.preventDefault();
    const contractNo = this.state.modalMapping.mappingContractNo;
    // ถ้าใส่ space มา
    if (contractNo && !contractNo.trim()) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Invalid Contract no.',
      });
      return;
    }
    this.props.saveMTMMappingContract(this.state.modalMapping).then((response) => {
      if (response.error) return;
      this.modal.close();
      this.onClickSearchHandler({ preventDefault: () => {} });
    });
  };

  onChangeExportCriteria = (e) => {
    const { name, value } = e.target;

    this.setState((state) => ({
      exportCriteria: {
        ...state.exportCriteria,
        [name]: value,
      },
    }));
  };

  onSubmitExport = (e) => {
    e.preventDefault();
    this.props.downloadMTMForwardExcel({ ...this.state.exportCriteria });
  };

  onClickOpenModalExport = () => {
    this.modalExport.open();
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      onClickFetchForward: this.onClickFetchForward,
      onClickEditMappingContract: this.onClickEditMappingContract,

      exportCriteria: this.state.exportCriteria,
      exportModalRef: (e) => (this.modalExport = e),
      onChangeExportCriteria: this.onChangeExportCriteria,
      onSubmitExport: this.onSubmitExport,
      onClickOpenModalExport: this.onClickOpenModalExport,
      onClickGenFlowMonthEnd: this.onClickGenFlowMonthEnd,
    };

    return (
      <>
        <ImportExcel
          ref={(e) => (this.importRef = e)}
          onUpload={this.onUploadViewImportExcel}
          onClickSaveImport={this.onSubmitSaveImportExcel}
          tableName="import-excel-mtm-fx-contract"
          optionDt={opt}
          value={this.props.importExcel}
        >
          <thead>
            <tr>
              <th>Bank Code</th>
              <th>Report Date</th>
              <th>Contract No</th>
              <th>Product Group</th>
              <th>Company Code</th>
              <th>Company Name</th>
              <th>Net MTM Amount</th>
              <th>NET MTM Currency</th>
              <th>Transaction Type</th>
              <th>CCy1</th>
              <th>Amount</th>
              <th>CCy2</th>
              <th>Agians Amount</th>
              <th>Market Fx Rate</th>
            </tr>
          </thead>
        </ImportExcel>
        <MarkToMarket
          {...props}
          {...this.state}
          importRef={this.importRef}
          criteria={this.props.criteria}
        />
        <Modal ref={(e) => (this.modal = e)} textHeader="Mapping Contract NO." bgHeader="bg-info">
          <form className="form-horizontal">
            <div className="form-group">
              <label className="control-label col-sm-6 col-md-4">Mapping Contract No.</label>
              <div className="col-md-8 col-sm-6">
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => {
                    const { value } = e.target;
                    this.setState((state) => ({
                      modalMapping: {
                        ...state.modalMapping,
                        mappingContractNo: value,
                      },
                    }));
                  }}
                  value={this.state.modalMapping.mappingContractNo}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-sm-12">
                <Button
                  txt="Save"
                  icon="save"
                  onClick={this.onSubmitSaveMappingContract}
                  className="btn-success"
                />
              </div>
            </div>
          </form>
        </Modal>
      </>
    );
  }
}

const opt = {
  columns: [
    { data: 'bankCode' },
    { data: 'reportDate' },
    { data: 'contractNo' },
    { data: 'productGroup' },
    { data: 'companyCode' },
    { data: 'companyName' },
    { data: 'netMtmAmount', render: (d, t, r) => toFixed(d, r.currencytDigit) },
    { data: 'netMtmCurrency' },
    { data: 'transactionType' },
    { data: 'ccy1' },
    { data: 'amount', render: (d, t, r) => toFixed(d, r.currencytDigit) },
    { data: 'ccy2' },
    { data: 'agiansAmount', render: (d, t, r) => toFixed(d, r.currencytDigit) },
    { data: 'marketFxRate' },
  ],
};

export default connect(
  (state) => ({
    ...state.markToMarket,
  }),
  {
    searchMarkToMarket,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    fetchEmail,
    downloadFileMtm,
    importExcelMtm,
    saveImportExcel,
    saveMTMMappingContract,
    downloadMTMForwardExcel,
    getMasterUserDetail,
    genFlowFXMonthEnd,
  }
)(MarkToMarketContainer);
