import { connect } from 'react-redux';
import React, { Component } from 'react';

import SearchRequestBg from './SearchRequestBg';
import AttachmentModal from './AttachmentModal';
import { addAlert } from '../../reducers/layout/action';
import {
  toLower,
  toUpperKey,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../common/helpper';
import {
  editCriteria,
  clearCriteria,
  saveBgRequest,
  getRoleUserBg,
  searchRequestBg,
  deleteRequestBg,
  downloadRefundFee,
  updateSearchResult,
} from '../../reducers/requestBg/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-request-bg';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const initData = {
  TransactionNo: '',
  Action: '',

  BankCode: '',
  BranchId: '',
  LgMethod: '',
  LgNo: '',

  Approver1: '',
  Approver2: '',
  RemarkFromFinance: '',
  InactiveBy: '',
  CompanyBgLine: '',
  ProjectName: '',
  IssueDateStr: '',
  LgStatusDescription: '',
};

class SearchRequestBgContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
    selectedTransaction: '',
    selectedStatus: '',
    selectedLgForm: '',
    selectedIsBuApprove: false,
    selectedCanAttachFile: false,

    isRoleFinancial: false,
    isRoleApproverBu: false,
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.attachment`, (e) => this.onClickOpenModalAttachment(e));
    $('body').on('click', `button.key-lg`, (e) => this.onClickOpenModalKeyLg(e));
    $('body').on('click', `button.action-print`, (e) => this.onClickPrint(e));
    $('body').on('click', `button.key-company-bg-line`, (e) => this.onClickCompanyBgLine(e));
    $('body').on('click', `a.key-projectname`, (e) => this.onClickOpenModalKeyProjectName(e));
    $('body').on('click', `button.key-lg-status`, (e) => this.onClickLgDescription(e));
    $('body').on('click', `button.cancel-amend`, (e) => this.onClickOpenModalCancel(e));

    this.props.getRoleUserBg().then((response) => {
      if (!response.error) {
        const r = response.payload;
        if (r) {
          this.setState({
            isRoleFinancial: r.isRoleFinancial,
            isRoleApproverBu: r.isRoleApproverBu,
          });
        }
      }
    });
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.attachment`);
    $('body').off('click', `button.action-print`);
    $('body').off('click', `button.key-company-bg-line`);
    $('body').off('click', `a.key-projectname`);
    $('body').off('click', `button.key-lg`);
    $('body').off('click', `button.key-lg-status`);
    $('body').off('click', `button.cancel-amend`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchRequestBg({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    this.props.searchResult[result.row].IsSelected = result.checked;
    this.forceUpdate();
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        TransactionNo: rowData.transactionNo,
        Action: modal === 'Inactive' ? 'inactive' : 'status',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onClickOpenModalCancel = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        TransactionNo: rowData.transactionNo,
        OldTransactionNo: rowData.oldTransactionNo,
        Action: 'cancel amend',
      },
    });

    this.modalCancelRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    this.props.saveBgRequest({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchRequestBg({ ...this.props.criteria });
    });
  };

  onClickOpenModalKeyLg = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState(
      {
        modalData: {
          ...initData,
          TransactionNo: rowData.transactionNo,
          LgNo: rowData.lgNo,
          Action: 'key lg',
        },
      },
      this.modalMethodRef.open()
    );
  };

  onClickCompanyBgLine = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState(
      {
        modalData: {
          ...initData,
          TransactionNo: rowData.transactionNo,
          CompanyBgLine: rowData.companyBgLine,
          Action: 'bg line',
        },
      },
      this.modalMethodRef.open()
    );
  };

  onClickLgDescription = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState(
      {
        modalData: {
          ...initData,
          TransactionNo: rowData.transactionNo,
          LgStatusDescription: rowData.lgStatusDescription,
          Action: 'lg description',
        },
      },
      this.modalMethodRef.open()
    );
  };

  onClickPrint = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.props.downloadRefundFee({ ...toUpperKey(rowData) });
  };

  onClickOpenModalFunction = (modal) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Please select item for ${modal}!`,
      });
      return;
    }

    const transactionNos = selectedData.map((m) => m.transactionNo);
    const transactionNo = transactionNos.toString();

    let dataFilter = [];

    if (modal === 'select bank' || modal === 'select method' || modal === 'confirm') {
      dataFilter = selectedData.filter((m) => toLower(m.statusLg) !== 'wait for confirm');
      if (dataFilter.length) {
        this.getAlertError(`Please select item status is Wait for confirm.`);
        return;
      }
    }

    if (modal === 'issue date') {
      dataFilter = selectedData.filter(
        (m) => toLower(m.statusLg) !== 'completed' && toLower(m.statusLg) !== 'confirmed'
      );
      if (dataFilter.length) {
        this.getAlertError(`Please select item status is Completed or Confirmed.`);
        return;
      }
    }

    if (modal === 'select method') {
      dataFilter = selectedData.filter((m) => m.lgNo);
      if (dataFilter.length) {
        this.getAlertError(
          `Transaction you selected can not change method because transaction has LG No.`
        );
        return;
      }
    }

    if (modal === 'reject' || modal === 'send back') {
      dataFilter = selectedData.filter(
        (m) =>
          toLower(m.statusLg) !== 'wait for confirm' &&
          toLower(m.statusLg) !== 'wait for bu approve'
      );
      if (dataFilter.length) {
        this.getAlertError(`Please select item status is Wait for confirm or Wait for bu approve.`);
        return;
      }

      dataFilter = selectedData.filter(
        (m) => !m.isRoleFinancial && toLower(m.statusLg) === 'wait for confirm'
      );
      if (dataFilter.length) {
        this.getAlertError(`Can not ${modal} because you can not authorize this transaction.`);
        return;
      }

      dataFilter = selectedData.filter(
        (m) => !m.isRoleApproverBu && toLower(m.statusLg) === 'wait for bu approve'
      );
      if (dataFilter.length) {
        this.getAlertError(`Can not ${modal} because you can not authorize this transaction.`);
        return;
      }
    }

    if (modal === 'bu approve') {
      dataFilter = selectedData.filter((m) => toLower(m.statusLg) !== 'wait for bu approve');
      if (dataFilter.length) {
        this.getAlertError(`Please select item status is Wait for BU approve.`);
        return;
      }

      dataFilter = selectedData.filter((m) => !m.canApproverBu);
      if (dataFilter.length) {
        this.getAlertError(`Can not approve because you not approver bu.`);
        return;
      }
    }

    if (modal === 'withdraw') {
      dataFilter = selectedData.filter(
        (m) =>
          ~[
            'rejected',
            'created',
            'wait for confirm',
            // "wait for initialization",
            'wait for bu approve',
            'waiting for bank validation',
            'wait for bank receive',
            'wait for generate file',
            'draft',
          ].indexOf(toLower(m.statusLg)) ||
          (toLower(m.statusLg) === 'confirmed' && toLower(m.lgMethod) === 'sftp')
      );
      if (dataFilter.length) {
        this.getAlertError(`Transaction you selected can not withdraw.`);
        return;
      }
    }

    if (modal === 'withdraw') {
      dataFilter = selectedData.filter(
        (m) => toLower(m.statusLg) === 'completed' && toLower(m.lgMethod) === 'sftp'
      );
      if (dataFilter.length) {
        this.getAlertError(
          `Transaction status is Completed and LG Method is SFTP can not withdraw.`
        );
        return;
      }
    }

    if (modal === 'withdraw bu') {
      dataFilter = selectedData.filter(
        (m) =>
          toLower(m.statusLg) !== 'wait for bu approve' &&
          toLower(m.statusLg) !== 'wait for initialization'
      );
      if (dataFilter.length) {
        this.getAlertError(
          `Please select item status is Wait for initialization or Wait for BU approve.`
        );
        return;
      }

      dataFilter = selectedData.filter((m) => !m.isCreator);
      if (dataFilter.length) {
        this.getAlertError(`Can not withdraw because you not creator.`);
        return;
      }
    }

    if (modal === 'select bank') {
      const banklist = [...new Set(selectedData.map((m) => m.bankCode))];
      const branchlist = [...new Set(selectedData.map((m) => m.branchId))];

      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            BankCode: banklist.toString(),
            BranchId: branchlist.toString(),
            Action: 'select bank',
          },
        },
        this.modalMethodRef.open()
      );
    } else if (modal === 'select method') {
      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            Action: 'select method',
          },
        },
        this.modalMethodRef.open()
      );
    } else if (modal === 'issue date') {
      const datelist = [...new Set(selectedData.map((m) => m.issueDateStr))];
      let issueDate = null;
      if (datelist.length === 1) {
        issueDate = datelist[0];
      }
      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            IssueDateStr: issueDate,
            Action: 'issue date',
          },
        },
        this.modalMethodRef.open()
      );
    } else if (modal === 'confirm') {
      let financeGroupId = '';
      let checkFinanceGroup = true;
      let checkLgMethod = true;

      selectedData.forEach((m) => {
        if (!m.lgMethod) {
          checkLgMethod = false;
        }

        if (!financeGroupId) {
          financeGroupId = m.financeGroupId;
        }

        if (toLower(m.lgMethod) === 'sftp' && financeGroupId !== m.financeGroupId) {
          checkFinanceGroup = false;
        }
      });

      if (!checkLgMethod) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Transaction you selected don't have LG Method.`,
        });
        return;
      }

      if (!checkFinanceGroup) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Please select item that in same Finance Group.`,
        });
        return;
      }

      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            FinanceGroupId: financeGroupId,
            Action: 'confirm',
          },
        },
        this.modalApproverRef.open()
      );
    } else if (modal === 'withdraw') {
      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            Action: 'withdraw',
          },
        },
        this.modalWithdrawRef.open()
      );
    } else if (modal === 'send back') {
      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            Action: 'send back',
          },
        },
        this.modalSendbackRef.open()
      );
    } else if (modal === 'reject') {
      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            Action: 'reject',
          },
        },
        this.modalRejectRef.open()
      );
    } else if (modal === 'bu approve') {
      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            Action: 'bu approve',
          },
        },
        this.modalBuApproveRef.open()
      );
    } else if (modal === 'withdraw bu') {
      this.setState(
        {
          modalData: {
            ...initData,
            TransactionNo: transactionNo,
            Action: 'withdraw bu',
          },
        },
        this.modalWithdrawBuRef.open()
      );
    }
  };

  onChangeModalFunction = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        modalData: {
          ...this.state.modalData,
          [name]: value,
        },
      };

      if (name === 'Approver1') {
        if (value === state.modalData.Approver2) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select user not same assign approver 2.',
          });
          stateToUpdate.modalData.Approver1 = state.modalData.Approver1;
        }
      } else if (name === 'Approver2') {
        if (value === state.modalData.Approver1) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select user not same assign approver 1.',
          });
          stateToUpdate.modalData.Approver2 = state.modalData.Approver2;
        }
      }

      return stateToUpdate;
    });
  };

  getAlertError(text) {
    this.props.addAlert({
      title: 'Error',
      type: 'error',
      body: text,
    });
  }

  onSubmitModalFunction = (e) => {
    e.preventDefault();

    this.props.saveBgRequest({ ...this.state.modalData }).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The transaction was successfully saved',
      });

      this.modalMethodRef.close();
      this.modalApproverRef.close();
      this.modalWithdrawRef.close();
      this.modalSendbackRef.close();
      this.modalRejectRef.close();
      this.modalBuApproveRef.close();
      this.modalInactiveRef.close();
      this.modalCancelRef.close();
      this.modalWithdrawBuRef.close();
      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchRequestBg({ ...this.props.criteria });
    });
  };

  onClickOpenModalAttachment = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState(
      {
        selectedTransaction: rowData.transactionNo,
        selectedStatus: rowData.statusLg,
        selectedLgForm: rowData.formFormat,
        selectedIsBuApprove: rowData.isBuApprove,
        selectedCanAttachFile: rowData.canAttachFile,
      },
      this.attachmentRef.open
    );
  };

  onClickOpenModalKeyProjectName = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState(
      {
        modalData: {
          ...initData,
          TransactionNo: rowData.transactionNo,
          ProjectName: rowData.projectName,
          Action: 'key project',
        },
      },
      this.modalMethodRef.open()
    );
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onClickOpenModalFunction: this.onClickOpenModalFunction,
      onChangeModalData: this.onChangeModalFunction,
      onSubmitModalFunction: this.onSubmitModalFunction,
    };

    return (
      <div>
        <SearchRequestBg
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalMethodRef={(e) => (this.modalMethodRef = e)}
          modalApproverRef={(e) => (this.modalApproverRef = e)}
          modalWithdrawRef={(e) => (this.modalWithdrawRef = e)}
          modalSendbackRef={(e) => (this.modalSendbackRef = e)}
          modalRejectRef={(e) => (this.modalRejectRef = e)}
          modalBuApproveRef={(e) => (this.modalBuApproveRef = e)}
          modalCancelRef={(e) => (this.modalCancelRef = e)}
          modalWithdrawBuRef={(e) => (this.modalWithdrawBuRef = e)}
        />
        <AttachmentModal
          ref={(e) => (this.attachmentRef = e)}
          transactionNo={this.state.selectedTransaction}
          statusLg={this.state.selectedStatus}
          formFormat={this.state.selectedLgForm}
          buApprove={this.state.selectedIsBuApprove}
          criteria={this.props.criteria}
          canAttachFile={this.state.selectedCanAttachFile}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.requestBg,
  }),
  {
    searchRequestBg,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    deleteRequestBg,
    addAlert,
    saveBgRequest,
    getRoleUserBg,
    downloadRefundFee,
  }
)(SearchRequestBgContainer);
