import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_CASH_POSITION = '@@request/SEARCH_CASH_POSITION';
export const RECEIVE_SEARCH_CASH_POSITION = '@@receive/SEARCH_CASH_POSITION';
export const FAIL_SEARCH_CASH_POSITION = '@@fail/SEARCH_CASH_POSITION';

export const REQUEST_SUBMIT_MANUAL_TRANSFER = '@@request/SUBMIT_MANUAL_TRANSFER';
export const RECEIVE_SUBMIT_MANUAL_TRANSFER =
  '@@receive-The transaction was successfully saved/SUBMIT_MANUAL_TRANSFER';
export const FAIL_SUBMIT_MANUAL_TRANSFER = '@@fail/SUBMIT_MANUAL_TRANSFER';

export const REQUEST_GET_BANK_CHARGE = '@@request/GET_BANK_CHARGE';
export const RECEIVE_GET_BANK_CHARGE = '@@receive/GET_BANK_CHARGE';
export const FAIL_GET_BANK_CHARGE = '@@fail/GET_BANK_CHARGE';

export const EDIT_CASH_POSITION_SEACH_RESULT = 'EDIT_CASH_POSITION_SEACH_RESULT';

export const REQUEST_GET_SWEEP_CASH_POSITION = '@@request/GET_SWEEP_CASH_POSITION';
export const RECEIVE_GET_SWEEP_CASH_POSITION = '@@receive/GET_SWEEP_CASH_POSITION';
export const FAIL_GET_SWEEP_CASH_POSITION = '@@fail/GET_SWEEP_CASH_POSITION';

export const REQUEST_PROGRESS_AUTOMATE = '@@request/PROGRESS_AUTOMATE';
export const RECEIVE_PROGRESS_AUTOMATE = '@@receive/PROGRESS_AUTOMATE';
export const FAIL_PROGRESS_AUTOMATE = '@@fail/PROGRESS_AUTOMATE';

export const REQUEST_SUBMIT_PROGRESS_AUTOMATE = '@@request/SUBMIT_PROGRESS_AUTOMATE';
export const RECEIVE_SUBMIT_PROGRESS_AUTOMATE = '@@receive/SUBMIT_PROGRESS_AUTOMATE';
export const FAIL_SUBMIT_PROGRESS_AUTOMATE = '@@fail/SUBMIT_PROGRESS_AUTOMATE';

export const REQUEST_GET_MANUAL_TRANSFER_FEE = '@@request/GET_MANUAL_TRANSFER_FEE';
export const RECEIVE_GET_MANUAL_TRANSFER_FEE = '@@receive/GET_MANUAL_TRANSFER_FEE';
export const FAIL_GET_MANUAL_TRANSFER_FEE = '@@fail/GET_MANUAL_TRANSFER_FEE';

export const getManualTransferFee = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/cashPosition/GetManualTransferFeeSetup', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MANUAL_TRANSFER_FEE,
      RECEIVE_GET_MANUAL_TRANSFER_FEE,
      FAIL_GET_MANUAL_TRANSFER_FEE,
    ],
  },
});

export const submitProgressAutomate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/automate/submitProgress'),
    body: JSON.stringify(data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SUBMIT_PROGRESS_AUTOMATE,
      RECEIVE_SUBMIT_PROGRESS_AUTOMATE,
      FAIL_SUBMIT_PROGRESS_AUTOMATE,
    ],
  },
});

export const progressAutomate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/automate/progress'),
    body: JSON.stringify(criteria),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [REQUEST_PROGRESS_AUTOMATE, RECEIVE_PROGRESS_AUTOMATE, FAIL_PROGRESS_AUTOMATE],
  },
});

export const getSweepCashPosition = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/cashPosition/getsweepcashposition', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_SWEEP_CASH_POSITION,
      RECEIVE_GET_SWEEP_CASH_POSITION,
      FAIL_GET_SWEEP_CASH_POSITION,
    ],
  },
});

export const getBankCharge = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/bankCharge', criteria),
    method: 'GET',
    types: [REQUEST_GET_BANK_CHARGE, RECEIVE_GET_BANK_CHARGE, FAIL_GET_BANK_CHARGE],
  },
});

export const editSearchResult = (name, value, index) => ({
  type: EDIT_CASH_POSITION_SEACH_RESULT,
  payload: {
    name,
    value,
    index,
  },
});

export const searchCashPosition = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/cashPosition', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_CASH_POSITION, RECEIVE_SEARCH_CASH_POSITION, FAIL_SEARCH_CASH_POSITION],
  },
});

export const submitManualTransfer = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/cashPosition/SubmitManualTransfer'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SUBMIT_MANUAL_TRANSFER,
      RECEIVE_SUBMIT_MANUAL_TRANSFER,
      FAIL_SUBMIT_MANUAL_TRANSFER,
    ],
  },
});
