import React from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import FormGroup3 from '../../common/FormGroup3';

const colX = ['col-md-4', 'col-md-5'];

export default ({ modalRef, state, onSubmit, onChageModal }) => (
  <Modal textHeader="Modal Split" ref={modalRef} bgHeader="bg-primary">
    <form onSubmit={onSubmit} className="form-horizontal">
      <FormGroup3 text="Amount" col={colX} required={false}>
        <InputMask
          className="form-control"
          format="currency"
          option={
            state.inputMaskAmount || {
              prefix: '',
              digits: 3,
              digitsOptional: false,
              placeholder: '0.000',
            }
          }
          onChange={onChageModal}
          value={state.OldAmount}
          required={false}
          readOnly
          name="OldAmount"
        />
      </FormGroup3>
      <FormGroup3 text="Split Amount" col={colX} required>
        <InputMask
          className="form-control"
          format="currency"
          option={
            state.inputMaskAmount || {
              prefix: '',
              digits: 3,
              digitsOptional: false,
              placeholder: '0.000',
            }
          }
          onChange={onChageModal}
          value={state.Amount}
          required
          name="Amount"
        />
      </FormGroup3>
      <br />
      <div>
        <center>
          <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
        </center>
      </div>
    </form>
  </Modal>
);
