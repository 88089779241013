import React, { Component } from 'react';

import InputMask from '../common/InputMask';

class Test extends Component {
  state = {
    value: 123456,
  };

  onChange = (e) => {
    console.log('event onchange');
    this.setState({
      value: e.target.value,
    });
  };

  render() {
    console.log(this.state);
    return (
      <div>
        <InputMask
          format="currency"
          option={{
            prefix: '฿ ',
            digits: 3,
          }}
          {...this.state}
          onChange={this.onChange}
        />

        <button
          onClick={() => {
            this.setState({
              value: 1111,
            });
          }}
        >
          click
        </button>
      </div>
    );
  }
}

export default Test;
