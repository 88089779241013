import { connect } from 'react-redux';
import React, { Component } from 'react';

import MtmSwap from './MtmSwap';
import { addAlert } from '../../../reducers/layout/action';
import {
  editCriteria,
  clearCriteria,
  fetchEmailSwap,
  downloadFileMtm,
  searchMarkToMarket,
  updateSearchResult,
} from '../../../reducers/markToMarket/action';

export const DATATABLE_ID = 'table-result-mtm-swap';
const $ = window.jQuery;
class MtmSwapContainer extends Component {
  state = {
    results: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    this.dataTableSearchResultRef.$dataTable.on(
      'click',
      `button.download`,
      this.onClickDownloadFile
    );
    this.setState({
      results: this.props.searchResult.filter((f) => f.type === 'Swap'),
    });
  }

  componentWillUnmount() {
    this.dataTableSearchResultRef.$dataTable.off(
      'click',
      `button.download`,
      this.onClickDownloadFile
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.searchResult !== prevProps.searchResult)
      this.setState({
        results: this.props.searchResult.filter((f) => f.type === 'Swap'),
      });
  }

  onClickDownloadFile = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const selected = this.state.results[row];
    const { originalFileName } = selected;
    if (!originalFileName)
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'File not found',
      });
    else this.props.downloadFileMtm(originalFileName);
  };

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMarkToMarket({
      ...this.props.criteria,
      Type: 'Swap',
    });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickFetchSwap = () => {
    this.props.fetchEmailSwap();
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      onClickFetchSwap: this.onClickFetchSwap,
    };

    return <MtmSwap {...props} {...this.state} criteria={this.props.criteria} />;
  }
}

export default connect(
  (state) => ({
    ...state.markToMarket,
  }),
  {
    searchMarkToMarket,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    fetchEmailSwap,
    downloadFileMtm,
  }
)(MtmSwapContainer);
