import React, { Component } from 'react';

import { store } from '../Root';
import { addAlert } from '../reducers/layout/action';
import { isScgHoliday } from '../reducers/masterHoliday/action';

const $ = window.jQuery;
export default class DatePicker extends Component {
  componentDidMount() {
    const defaultOpt = { autoclose: true, format: 'dd/mm/yyyy' };
    const option = {
      ...defaultOpt,
      ...(this.props.option || {}),
    };
    $(this.datePickerElement).datepicker(option);

    $(this.datePickerElement).on('change', this.onChangeHandler);

    if (this.props.setDate) {
      $(this.datePickerElement).datepicker('setDate', this.props.setDate.format('DD/MM/YYYY'));
    } else if (this.props.value) {
      $(this.datePickerElement).datepicker('setDate', this.props.value);
    }
  }

  componentWillUnmount() {
    $(this.datePickerElement).off('change', this.onChangeHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    // value update from prop
    if (this.datePickerElement.value !== this.props.value) {
      // prevent null or empty value update
      if (!this.datePickerElement.value && !this.props.value) return;

      this.datePickerElement.value = this.props.value;
      // $(this.datePickerElement).datepicker('setDate', this.props.value)
    }
  }

  onChangeHandler = (e) => {
    const date = this.datePickerElement.value;
    if (this.props.checkHoliday && date) {
      e.preventDefault();

      store.dispatch(isScgHoliday(date)).then((response) => {
        if (response.error) return;

        if (response.payload) {
          // alert(response.payload)
          store.dispatch(
            addAlert({
              title: 'Error',
              type: 'error',
              body: 'Date you selected is Holiday.',
            })
          );
          this.datePickerElement.value = '';
          this.props.onChange({
            target: {
              name: this.props.name,
              value: '',
            },
          });
        } else {
          this.props.onChange({
            target: {
              name: this.props.name,
              value: date,
            },
          });
        }
      });
    } else if (this.props.value !== date) this.props.onChange(e);
  };

  render() {
    return (
      <div>
        <input
          className={`form-control ${this.props.className || ''}`}
          autoComplete="off"
          name={this.props.name}
          ref={(el) => (this.datePickerElement = el)}
          type="text"
          disabled={!!this.props.disabled}
          required={!!this.props.required}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}
