import React, {
  useRef,
  useMemo,
  useState,
  useContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import { store } from '../../../Root';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import CashFlowTable from './CashFlowTable';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import { addAlert } from '../../../reducers/layout/action';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_BANK_CODE } from '../../../reducers/master/action';
import { StateContext, FormRefContext } from './CreateContractLoan';
import { setAmount, toUpperKey, getPermission } from '../../../common/helpper';
import {
  saveCashflowContractLoan,
  processCashflowContractLoan,
} from '../../../reducers/contractLoan/action';

const permission = getPermission('Funding&Investment', 'Create Contract Loan');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX2 = ['col-md-4', 'col-md-7'];
const { moment } = window;
export default function CashFlowTab(props) {
  const formRef = useRef(null);

  const [chargeAction, setChargeAction] = useState(null);
  const { state, dispatch } = useContext(StateContext);
  const { isCreate, setFlagFetchLoan } = useContext(FormRefContext);

  const onClickProcessCashFlow = useCallback(() => {
    async function submitProcessCashflow() {
      let repaymentList = [];
      (state.RepaymentStructures || []).forEach((m, i) => {
        m.Ordering = i + 1;
        if (m.InterestStructures && m.InterestStructures.length) {
          m.InterestStructures.forEach((e) => {
            e.RepaymentTempId = m.TempId;
            e.RepaymentId = m.Id;
          });
          repaymentList = [...repaymentList, ...m.InterestStructures];
        }
      });

      const submitData = {
        TransactionNo: state.TransactionNo,
        RepaymentStructures: state.RepaymentStructures,
        RemoveInterestStructures: state.RemoveInterestStructures,
        InterestStructures: [...state.DrawdownList, ...repaymentList],
      };

      const response = await store.dispatch(processCashflowContractLoan(submitData));
      if (response.error) return;

      const dataToUpdate = mapCashflow(response.payload || []);
      dataToUpdate.CashflowDeposit.DrawdownBankCode = state.CashflowDeposit.DrawdownBankCode;
      dataToUpdate.CashflowDeposit.RepaymentBankCode = state.CashflowDeposit.RepaymentBankCode;
      dataToUpdate.CashflowLoan.DrawdownBankCode = state.CashflowLoan.DrawdownBankCode;
      dataToUpdate.CashflowLoan.RepaymentBankCode = state.CashflowLoan.RepaymentBankCode;
      dispatch({
        type: 'SET_CASHFLOW',
        payload: dataToUpdate,
      });
    }
    submitProcessCashflow();
  }, [state]);
  const onSubmitSaveCashFlow = useCallback(
    (e) => {
      e.preventDefault();
      async function saveCashflow() {
        let repaymentList = [];
        (state.RepaymentStructures || []).forEach((m, i) => {
          if (m.InterestStructures && m.InterestStructures.length) {
            repaymentList = [...repaymentList, ...m.InterestStructures];
          }
        });

        const cashflows = [
          ...state.CashflowDeposit.DrawdownList,
          ...state.CashflowDeposit.RepaymentList,
          ...state.CashflowLoan.DrawdownList,
          ...state.CashflowLoan.RepaymentList,
        ];

        const errorValidate = validateCashflow(cashflows);
        if (errorValidate) {
          store.dispatch(
            addAlert({
              type: 'error',
              title: 'Error',
              body: errorValidate,
            })
          );
          return;
        }

        const submitData = {
          TransactionNo: state.TransactionNo,
          InterestStructures: [...state.DrawdownList, ...repaymentList],
          RepaymentStructures: state.RepaymentStructures,
          CashFlows: cashflows,
          DepositBankCode: (state.CashflowDeposit.DrawdownBankCode || []).join(','),
          DepositBankCodeRepayment: (state.CashflowDeposit.RepaymentBankCode || []).join(','),
          LoanBankCode: (state.CashflowLoan.DrawdownBankCode || []).join(','),
          LoanBankCodeRepayment: (state.CashflowLoan.RepaymentBankCode || []).join(','),
          RemoveInterestStructures: state.RemoveInterestStructures,
          RemoveRepaymentStructures: state.RemoveRepaymentStructures,
        };

        const response = await store.dispatch(saveCashflowContractLoan(submitData));
        if (response.error) return;

        setFlagFetchLoan((state) => state + 1);
      }

      saveCashflow();
    },
    [state]
  );

  return (
    <form onSubmit={onSubmitSaveCashFlow} ref={formRef}>
      {permission ? (
        <>
          <Col2 col={colX[0]}>
            <Button
              txt="Get Cash Flow"
              icon="search"
              className="btn-info"
              onClick={onClickProcessCashFlow}
              disabled={isCreate}
            />
          </Col2>
          <br />
        </>
      ) : null}
      <CashflowType
        state={state}
        dispatch={dispatch}
        cashflow={state.CashflowLoan}
        companyByCashFlow={state.CompanyCode}
        textHeader="Loan"
        bgHeader="bg-blank"
        type="Loan"
      />
      {state.PartnerType === 'Intercompany' ? (
        <CashflowType
          dispatch={dispatch}
          state={state}
          companyByCashFlow={state.BusinessPartner}
          cashflow={state.CashflowDeposit}
          textHeader="Deposit"
          bgHeader="bg-blank"
          type="Deposit"
        />
      ) : null}

      {permission ? (
        <div className="row">
          <div className="col-sm-12 text-center">
            <Button
              txt="Save Cash Flow"
              icon="save"
              type="submit"
              className="btn-success"
              disabled={isCreate}
            />
          </div>
        </div>
      ) : null}
    </form>
  );
}

const CashflowType = forwardRef(
  ({ cashflow, type, dispatch, state, companyByCashFlow, textHeader, bgHeader }, ref) => {
    const findSameAccountBetweenDepositAndLoan = useCallback(
      (bankAccount, cashFlowType) => {
        if (!bankAccount) return;
        const typeTarget = type === 'Loan' ? 'Deposit' : 'Loan';
        const targetBankAccounts = ((state[`Cashflow${typeTarget}`] || {})[cashFlowType] || [])
          .map((m) => m.BankAccountNo)
          .filter((f) => !!f);
        if (~targetBankAccounts.indexOf(bankAccount)) return true;
        return false;
      },
      [cashflow, type]
    );
    const onChangeTableInput = useCallback(
      (e, i, cashFlowType) => {
        const { name, value, datas } = e.target;
        if (name === 'BankAccountNo' && findSameAccountBetweenDepositAndLoan(value, cashFlowType)) {
          store.dispatch(
            addAlert({
              type: 'error',
              title: 'Error',
              body: 'Cannot select BankAccout that dupicate between Loan And Deposit Cashflow',
            })
          );
          dispatch({
            type: `UPDATE_CASHFLOW`,
            cashFlowType: type,
            name: cashFlowType,
            value: cashflow[cashFlowType].map((m, index) => ({ ...m })),
          });
          return;
        }
        dispatch({
          type: `UPDATE_CASHFLOW`,
          cashFlowType: type,
          name: cashFlowType,
          value: cashflow[cashFlowType].map((m, index) => {
            if (i === null || index === i) {
              const state = {
                ...m,
                [name]: value,
              };
              switch (name) {
                case 'PaymentMethod':
                  state.SwapTransactionNo = '';
                  state.LocalCurrencyAmount = state.OldLocalCurrencyAmount;
                  break;
                case 'SwapTransactionNo':
                  const find = datas.find((f) => f.id === value) || {};
                  state.LocalCurrencyAmount = find.amount;
                  break;
                case 'OriginalInterestAmount':
                  const amount = Number(value || 0) - Number(state.WhtAmount || 0);
                  state.Amount = amount;
                  const diffOriginalRawAmount =
                    Number(value || 0) - Number(m.OriginalInterestAmount || 0);
                  // originalAmount ไว้ดูค่า original ก่อน split แต่หัก wht แล้ว แต่ยังไม่หัก split amount
                  state.OriginalAmount = Number(state.OriginalAmount || 0) + diffOriginalRawAmount;
                  // OriginalInterestAmount คือค่า original raw amount คือค่าที่ยังไม่หักอะไรเลย แต่หัก split amount
                  state.LocalCurrencyAmount = state.LocalCurrencyAmount ? amount : null;
                  break;
                default:
                  break;
              }
              return state;
            }
            return m;
          }),
        });
      },
      [cashflow, type]
    );
    const onChangeHeader = useCallback(
      (e, structorType) => {
        const { name, value } = e.target;

        dispatch({
          type: `UPDATE_CASHFLOW`,
          cashFlowType: type,
          name,
          value,
          structorType,
        });
      },
      [type]
    );

    // condition Company code of cashflow for select bank code&bank account
    const depositCode =
      state.TransactionType === 'Loan' ? state.BusinessPartner : state.CompanyCode;
    const loanCode =
      state.TransactionType === 'Deposit' ? state.BusinessPartner : state.CompanyCode;

    const infomationProps = {
      companyCode: type === 'Loan' ? loanCode : depositCode,
      partnerType: state.PartnerType,
      isBen: true,
      type,
    };

    const repaymentCashflow = useMemo(
      () =>
        cashflow.RepaymentList.sort((a, b) => {
          const mA = moment(a.PaymentDateStr, 'DD/MM/YYYY');
          const mB = moment(b.PaymentDateStr, 'DD/MM/YYYY');
          return mA.isAfter(mB) ? 1 : mA.isBefore(mB) ? -1 : 0;
        }),
      [cashflow.RepaymentList]
    );
    return (
      <Card textHeader={textHeader} bgHeader={bgHeader} cardActions={['toggler']}>
        {/* DrawDown */}
        <Col2 col={colX[2]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Bank" required>
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeHeader({
                    target: e,
                    structorType: 'Drawdown',
                  })
                }
                required={!!cashflow.DrawdownList.length}
                isGetAll
                customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                companyCode={companyByCashFlow}
                value={cashflow.DrawdownBankCode}
                name="DrawdownBankCode"
              />
            </FormGroup2>
          </Col2>
        </Col2>
        <CashFlowTable
          bgHeader="bg-primary"
          textHeader="Drawdown"
          cashFlowType="DrawdownList"
          cashFlow={cashflow.DrawdownList}
          onChange={onChangeTableInput}
          bankCode={cashflow.DrawdownBankCode}
          {...infomationProps}
        />

        {/* Repayment */}
        <Col2 col={colX[2]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Bank" required>
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeHeader({
                    target: e,
                    structorType: 'Repayment',
                  })
                }
                required={!!cashflow.RepaymentList.length}
                isGetAll
                customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                companyCode={companyByCashFlow}
                disabled={false}
                value={cashflow.RepaymentBankCode}
                name="RepaymentBankCode"
              />
            </FormGroup2>
          </Col2>
        </Col2>
        <CashFlowTable
          bgHeader="bg-info"
          textHeader="Repayment"
          cashFlowType="RepaymentList"
          cashFlow={repaymentCashflow}
          onChange={onChangeTableInput}
          bankCode={cashflow.RepaymentBankCode}
          {...infomationProps}
        />
      </Card>
    );
  }
);

export function mapCashflow(cashflow = []) {
  const dataToUpdate = {
    CashflowDeposit: {
      RepaymentList: [],
      DrawdownList: [],
    },
    CashflowLoan: {
      RepaymentList: [],
      DrawdownList: [],
    },
    CashFlowChargeLoan: [],
    CashFlowChargeDeposit: [],
  };

  (cashflow || []).forEach((m) => {
    if (m.isManualCreate) {
      // charge
      const { cashFlowType } = m;
      const key = '';
      const cashFlow = {
        ...toUpperKey(m),
        OldLocalCurrencyAmount: m.localCurrencyAmount,
        OldSwapTransactionNo: m.swapTransactionNo,
      };
      if (cashFlowType.toLowerCase() === 'loan') dataToUpdate.CashFlowChargeLoan.push(cashFlow);
      else dataToUpdate.CashFlowChargeDeposit.push(cashFlow);
    } else {
      // normal
      const { structureType, cashFlowType } = m;
      let cashFlowKey = '';
      let structureKey = '';
      if (cashFlowType.toLowerCase() === 'loan') cashFlowKey = 'CashflowLoan';
      else cashFlowKey = 'CashflowDeposit';
      if (structureType.toLowerCase() === 'repayment') structureKey = 'RepaymentList';
      else structureKey = 'DrawdownList';

      const cashFlow = {
        ...toUpperKey(m),
        OldLocalCurrencyAmount: m.localCurrencyAmount,
        OldSwapTransactionNo: m.swapTransactionNo,
      };
      dataToUpdate[cashFlowKey][structureKey].push(cashFlow);
    }
  });
  return dataToUpdate;
}

export const SplitModal = forwardRef(({ originalAmount, onSubmit }, ref) => {
  useImperativeHandle(ref, () => ({
    open: () => {
      modalRef.current.open();
    },
    resetData: () => {
      setSplitAmount('');
    },
  }));
  const modalRef = useRef(null);
  const [splitAmount, setSplitAmount] = useState('');
  return (
    <Modal ref={modalRef} bgHeader="bg-info" textHeader="Split">
      <form className="form-horizontal">
        <div className="form-group">
          <label className="control-label col-sm-6 col-md-4">Original Amount</label>
          <div className="col-sm-6 col-md-">
            <input
              type="text"
              value={setAmount(originalAmount)}
              disabled
              className="form-control"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6 col-md-4">Split Amount</label>
          <div className="col-sm-6 col-md-">
            <InputMask
              value={splitAmount}
              className="form-control"
              required
              onChange={(e) => setSplitAmount(e.target.value)}
              format="currency"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <Button
              txt="Confirm"
              icon="save"
              className="btn-success"
              type="button"
              onClick={(e) => onSubmit(splitAmount)}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
});

function validateCashflow(cashflows = []) {
  console.log(cashflows);
  const interestCashflows = cashflows.filter((f) => f.FlowType.toLowerCase().includes('interest'));

  let error = false;
  interestCashflows.forEach((m, i, s) => {
    if (error) return;
    const samePaymentDate = s.filter(
      (f) =>
        f !== m &&
        f.PaymentDateStr === m.PaymentDateStr &&
        f.CashFlowType === m.CashFlowType &&
        f.StructureType === m.StructureType &&
        f.IsManualCreate === m.IsManualCreate
    );
    if (samePaymentDate.length) {
      const notSameAccountAndPaymentMethod = samePaymentDate.filter(
        (f) => f.PaymentMethod !== m.PaymentMethod || f.BankAccountNo !== m.BankAccountNo
      );
      if (notSameAccountAndPaymentMethod.length) {
        error = `At cashflow that payment date is ${m.PaymentDateStr},The payment method and bankaccount must be the same.`;
      }
    }
  });
  return error;
}
