import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';

export const EDIT_BG_REPORT_CRITERIA = 'EDIT_BG_REPORT_CRITERIA';
export const CLEAR_BG_REPORT_CRITERIA = 'CLEAR_BG_REPORT_CRITERIA';

export const REQUEST_SEARCH_BG_REPORT = '@@request/SEARCH_BG_REPORT';
export const RECEIVE_SEARCH_BG_REPORT = '@@receive/SEARCH_BG_REPORT';
export const FAIL_SEARCH_BG_REPORT = '@@fail/SEARCH_BG_REPORT';

export const searchBgReport = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/getBgReport', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_BG_REPORT, RECEIVE_SEARCH_BG_REPORT, FAIL_SEARCH_BG_REPORT],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_BG_REPORT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_BG_REPORT_CRITERIA,
  payload: {
    name,
    value,
  },
});
