import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { toLower, toFixed, toNumber } from '../../../common/helpper';
import {
  MASTER_BANK,
  MASTER_COMPANY,
  MASTER_FINANCE,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const $ = window.jQuery;

const opt = {
  order: [[0, 'asc']],
  colReorder: false,
  ordering: false,
  rowGroup: {
    startRender: null,
    endRender: (rows, group) => {
      let principalAmount = 0;
      let interestPaymentAmount = 0;
      let accruedInterestAmount = 0;
      let sumInterestAmount = 0;
      let currency = '';
      let wht = 0;
      let whtAmount = 0;
      let netAmount = 0;

      rows.data().each((m) => {
        principalAmount += m.amount || 0;
        interestPaymentAmount += m.amountInterest || 0;
        accruedInterestAmount += m.amountAccrued || 0;
        sumInterestAmount += (m.amountInterest || 0) + (m.amountAccrued || 0);
        currency = m.transactionCurrency || '';
        wht = m.withHoldingTaxValue || 0;
        whtAmount += m.withHoldingTaxAmount || 0;
        netAmount +=
          (m.amountInterest || 0) + (m.amountAccrued || 0) - (m.withHoldingTaxAmount || 0);
      });

      return $('<tr></tr>')
        .append(`<td colspan="10" align="right">Total</td>`)
        .append(`<td align="right">${toNumber(principalAmount, 2)}</td>`)
        .append(`<td colspan="3"></td>`)
        .append(`<td align="right">${toNumber(interestPaymentAmount, 2)}</td>`)
        .append(`<td align="right">${toNumber(accruedInterestAmount, 2)}</td>`)
        .append(`<td align="right">${toNumber(sumInterestAmount, 2)}</td>`)
        .append(`<td align="center">${currency}</td>`)
        .append(`<td align="right">${toNumber(wht, 2)}</td>`)
        .append(`<td align="right">${toNumber(whtAmount, 2)}</td>`)
        .append(`<td align="right">${toNumber(netAmount, 2)}</td>`);
    },
    dataSrc: 'companyCode',
  },
  columns: [
    { data: 'companyCode', className: 'text-center' },
    { data: 'partnerCode', className: 'text-center' },
    { data: 'partnerType', className: 'text-center' },
    { data: 'productType', className: 'text-center' },
    { data: 'interestType', className: 'text-center' },
    { data: 'transactionType', className: 'text-center' },
    {
      data: 'transactionNo',
      render: (d, t, r) => `<a target="_blank" href="${r.urls}">${d}</a>`,
    },
    { data: 'calFromStr', className: 'text-center', type: 'date-black' },
    { data: 'calToStr', className: 'text-center', type: 'date-black' },
    { data: 'interestDueDateStr', className: 'text-center', type: 'date-black' },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => (d == null ? '' : toNumber(d, r.digit)),
    },
    {
      data: 'interestRate',
      className: 'text-right',
      render: (d) => (d == null ? '' : toNumber(d, 5)),
    },
    {
      data: 'interestDay',
      className: 'text-right',
      render: (d) => (d == null ? '' : toNumber(d, 0)),
    },
    { data: 'baseDays', className: 'text-right', render: (d) => (d == null ? '' : toNumber(d, 0)) },
    {
      data: 'amountInterest',
      className: 'text-right',
      render: (d, t, r) => (d == null ? '' : toNumber(d, r.digit)),
    },
    {
      data: 'amountAccrued',
      className: 'text-right',
      render: (d, t, r) => (d == null ? '' : toNumber(d, r.digit)),
    },
    {
      data: 'sumInterestAmount',
      className: 'text-right',
      render: (d, t, r) => {
        const sumAmount = (r.amountInterest || 0) + (r.amountAccrued || 0);
        return toNumber(sumAmount, r.digit);
      },
    },
    { data: 'transactionCurrency', className: 'text-center' },
    {
      data: 'withHoldingTaxValue',
      className: 'text-right',
      render: (d) => (d == null ? '' : toNumber(d, 2)),
    },
    {
      data: 'withHoldingTaxAmount',
      className: 'text-right',
      render: (d, t, r) =>
        d == null
          ? ''
          : toLower(r.interestType) === 'loan'
            ? toNumber(Math.abs(d) * -1, r.digit)
            : toNumber(Math.abs(d), r.digit),
    },
    {
      data: 'netAmount',
      className: 'text-right',
      render: (d, t, r) => {
        const sumAmount = (r.amountInterest || 0) + (r.amountAccrued || 0);
        const netAmount = sumAmount - (r.withHoldingTaxAmount || 0);
        return toNumber(netAmount, r.digit);
      },
    },
  ],
  drawCallback: () => {
    setTimeout(() => {
      window.$(window).trigger('resize');
    }, 1000);
  },
};

export default ({
  criteria,
  datas,
  dataTableRef,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  onClickDownloadInterestReport,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Report <span className="icon icon-angle-double-right" /> Interest{' '}
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroups}
                required={false}
                name="FinanceGroups"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company" required={false}>
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.CompanyCode}
                financeGroupId={criteria.FinanceGroups}
                businessUnitId={criteria.BusinessUnits}
                name="CompanyCode"
              />
            </FormGroup2>

            <FormGroup2 text="Currency" required={false}>
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.Currency}
                name="Currency"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Partner Type">
              <MasterDropdownUI
                onChange={onChangeSearch}
                value={criteria.PartnerType}
                name="PartnerType"
                isAll
                notMultiple
                options={['Intercompany', 'External']}
              />
            </FormGroup2>

            <FormGroup2 text="Partner">
              {criteria.PartnerType === 'External' ? (
                <MasterDropdown
                  key="External"
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  // companyCode={criteria.CompanyCode}
                  value={criteria.Partner}
                  name="Partner"
                />
              ) : criteria.PartnerType === 'Intercompany' ? (
                <MasterDropdown
                  key="Intercompany"
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Partner}
                  isBusinessPartner
                  name="Partner"
                />
              ) : (
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.Partner}
                  name="Partner"
                />
              )}
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Product Type">
              <MasterDropdownUI
                onChange={onChangeSelect2}
                value={criteria.ProductType}
                name="ProductType"
                options={['PN', 'Deposit', 'Contract on Call', 'Contract Loan']}
              />
            </FormGroup2>

            <FormGroup2 text="Interest Type">
              <MasterDropdownUI
                onChange={onChangeSelect2}
                value={criteria.InterestType}
                name="InterestType"
                options={['Loan', 'Deposit']}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Transaction Type">
              <MasterDropdownUI
                onChange={onChangeSelect2}
                value={criteria.TransactionType}
                name="TransactionType"
                options={['On Call', 'Term']}
              />
            </FormGroup2>

            <FormGroup2 text="Interest Due Date" required={false}>
              <DateRangePicker
                onChange={onChangeSearch}
                required={false}
                value={criteria.InterestDueDateStr}
                name="InterestDueDateStr"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Interest Date" required>
              <DateRangePicker
                onChange={onChangeSearch}
                required
                value={criteria.InterestDateStr}
                name="InterestDateStr"
              />
            </FormGroup2>

            <FormGroup2 text="Sweep">
              <select
                className="form-control"
                value={criteria.IsSweep}
                onChange={onChangeSearch}
                name="IsSweep"
              >
                <option value={null}>---All---</option>
                <option value>Yes</option>
                <option value={false}>No</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>
        <br />
        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    {datas.map((r, i) => (
      <ReportInterest
        key={i}
        data={r}
        idx={i}
        onClickDownloadInterestReport={onClickDownloadInterestReport}
      />
    ))}
  </div>
);

function ReportInterest({ data, idx, onClickDownloadInterestReport }) {
  const textHeader = `${data.companyCode} | ${data.companyAbbreviate} | ${data.companyName}`;
  const t = data.total;

  return (
    <Card
      textHeader={textHeader}
      number={data.companyCode}
      bgHeader="bg-primary"
      cardActions={['toggler']}
    >
      <Col2 col={colX[0]}>
        <div className="row gutter-xs">
          <div className="col-md-3">
            <Button
              onClick={() => onClickDownloadInterestReport(idx)}
              txt="Download"
              icon="download"
              className="btn-success"
            />
          </div>
        </div>
      </Col2>
      <Col2 col={colX[0]}>
        <div className="row gutter-xs">
          <div className="col-md-3">
            <FormGroup2 text="Finance Group" />
          </div>
          <div className="col-md-6">
            <span>{data.financeGroupName}</span>
          </div>
        </div>
        <div className="row gutter-xs">
          <div className="col-md-3">
            <FormGroup2 text="Business Unit" />
          </div>
          <div className="col-md-6">
            <span>{data.businessUnitName}</span>
          </div>
        </div>
      </Col2>
      <Col2 col={colX[0]}>
        <div className="row gutter-xs">
          <div className="col-md-3">
            <FormGroup2 text="Company" />
          </div>
          <div className="col-md-9">
            <span>
              {data.companyCode} | {data.companyAbbreviate} | {data.companyName}
            </span>
          </div>
        </div>
      </Col2>
      <Col2 col={colX[0]}>
        <div className="row gutter-xs">
          <div className="col-md-3">
            <FormGroup2 text="Partner" />
          </div>
          <div className="col-md-9">
            <span>
              {data.partnerCode} | {data.partnerAbbreviate} | {data.partnerName}
            </span>
          </div>
        </div>
      </Col2>
      <div className="col-md-12" style={{ overflow: 'scroll', paddingLeft: 0, maxHeight: 500 }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th>Company</th>
              <th>Partner</th>
              <th>
                Partner
                <br /> Type
              </th>
              <th>
                Product
                <br /> Type
              </th>
              <th>
                Interest
                <br /> Type
              </th>
              <th>Trans. Type</th>
              <th>Sweep</th>
              <th>Trans. No.</th>
              <th>Int. Cal. From</th>
              <th>Int. Cal. To</th>
              <th>Int. Due Date</th>
              <th className="text-center">Principal Amount</th>
              <th className="text-center">Int. Rate (%)</th>
              <th className="text-center">Days</th>
              <th className="text-center">Base days</th>
              <th className="text-center">
                Int. Payment
                <br /> Amount
              </th>
              <th className="text-center">
                Accrued Int.
                <br /> Amount
              </th>
              <th className="text-center">
                Sum. Int.
                <br /> Amount
              </th>
              <th>Currency</th>
              <th className="text-center">WHT (%)</th>
              <th className="text-center">WHT Amount</th>
              <th className="text-center">Net Amount</th>
            </tr>
          </thead>
          <tbody>
            {data.items.map((m, i) => (
              <tr key={i}>
                <td className="text-center">{m.companyCode}</td>
                <td className="text-center">{m.partnerCode}</td>
                <td className="text-center">{m.partnerType}</td>
                <td className="text-center">{m.productType}</td>
                <td className="text-center">{m.interestType}</td>
                <td className="text-center">{m.transactionType}</td>
                <td className="text-center">{m.isSweep ? 'Yes' : 'No'}</td>
                <td>
                  <a target="_blank" href={`${m.urls}`} rel="noreferrer">
                    {m.transactionNo}
                  </a>
                </td>
                <td className="text-center">{m.calFromStr}</td>
                <td className="text-center">{m.calToStr}</td>
                <td className="text-center">{m.interestDueDateStr}</td>
                <td className="text-right">{setNumber(m.amount, m.digit)}</td>
                <td className="text-right">{setNumber(m.interestRate, 5)}</td>
                <td className="text-right">{setNumber(m.interestDay, 0)}</td>
                <td className="text-right">{setNumber(m.baseDays, 0)}</td>
                <td className="text-right">{setNumber(m.amountInterest, m.digit)}</td>
                <td className="text-right">{setNumber(m.amountAccrued, m.digit)}</td>
                <td className="text-right">{setNumber(m.sumInterestAmount, m.digit)}</td>
                <td className="text-center">{m.transactionCurrency}</td>
                <td className="text-right">{setNumber(m.withHoldingTaxValue, 2)}</td>
                <td className="text-right">{setNumber(m.withHoldingTaxAmount, m.digit)}</td>
                <td className="text-right">{setNumber(m.netAmount, m.digit)}</td>
              </tr>
            ))}
            <tr
              style={{
                backgroundColor: '#ddd',
                fontWeight: 'bold',
              }}
            >
              <td className="text-right" colSpan="11">
                Total
              </td>
              <td className="text-right">{setNumber(t.amount, t.digit)}</td>
              <td className="text-right" colSpan="3" />
              <td className="text-right">{setNumber(t.amountInterest, t.digit)}</td>
              <td className="text-right">{setNumber(t.amountAccrued, t.digit)}</td>
              <td className="text-right">{setNumber(t.sumInterestAmount, t.digit)}</td>
              <td className="text-center">{t.transactionCurrency}</td>
              <td className="text-right">{setNumber(t.withHoldingTaxValue, 2)}</td>
              <td className="text-right">{setNumber(t.withHoldingTaxAmount, t.digit)}</td>
              <td className="text-right">{setNumber(t.netAmount, t.digit)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export function setNumber(number, float = 2) {
  if (!number) return '';
  if (number >= 0 || !number) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
}
