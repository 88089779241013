import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import { DATATABLE_ID } from './UserContainer';
import FormGroup2 from '../../common/FormGroup2';
import ModalFunction from '../../common/ModalFunction';
import { toStatus, getPermission } from '../../common/helpper';

const permission = getPermission('Common Master', 'User');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[1, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'username',
      render: (d) => `<a target="_blank" href="/master/user-detail/${d}">${d}</a>`,
    },
    { data: 'nameEn' },
    { data: 'surnameEn' },
    { data: 'nameTh' },
    { data: 'surnameTh' },
    { data: 'email' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (r.approveStatus === 'Waiting for Approve') return toStatus('Wait for Approve', true);
        if (r.approveStatus === 'Rejected') return toStatus('rejected');
        return d ? toStatus('active') : toStatus('inactive');
      },
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Common Master <span className="icon icon-angle-double-right" /> User
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Username">
              <input
                className="form-control"
                value={criteria.Username}
                onChange={onChangeSearch}
                name="Username"
              />
            </FormGroup2>
            <FormGroup2 text="Name">
              <input
                className="form-control"
                value={criteria.Name}
                onChange={onChangeSearch}
                name="Name"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Surname">
              <input
                className="form-control"
                value={criteria.Surname}
                onChange={onChangeSearch}
                name="Surname"
              />
            </FormGroup2>
            <FormGroup2 text="Status">
              <select
                className="form-control"
                value={criteria.Status}
                onChange={onChangeSearch}
                name="Status"
              >
                <option value="">---All---</option>
                <option value="Waiting for Approve">Waiting for Approve</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Rejected">Rejected</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Link
                  className="btn btn-warning btn-sm btn-labeled"
                  to="/master/user-detail/create"
                  target="_blank"
                >
                  <span className="btn-label">
                    <i className="icon icon-plus-circle icon-fw icon-lg" />
                  </span>
                  CREATE USER
                </Link>
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Action</th>
            <th>User Name</th>
            <th>Name EN</th>
            <th>Surname EN</th>
            <th>Name TH</th>
            <th>Surname TH</th>
            <th>Email</th>
            <th>Status</th>
            <th>
              Created
              <br />
              By
            </th>
            <th>
              Created
              <br />
              Date
            </th>
            <th>
              Updated
              <br />
              By
            </th>
            <th>
              Updated
              <br />
              Date
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalFunction
      textHeader="Active"
      textContent="active"
      modalRef={modalActiveRef}
      type="info"
      icon="icon-refresh"
      textBtn="Active"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
    />

    <ModalFunction
      textHeader="InActive"
      textContent="inactive"
      modalRef={modalInactiveRef}
      type="danger"
      icon="icon-close"
      textBtn="Yes"
      textBtnBack="No"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
    />
  </div>
);
