import React from 'react';
// import ModalFunction from '../../common/ModalFunction'
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import { DATATABLE_ID } from './BankContainer';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import { toStatus, getPermission } from '../../common/helpper';
import {
  MAIN_MASTER_COMPANY,
  MAIN_MASTER_FINANCE_GROUP,
  MAIN_MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('Common Master', 'Company');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  columns: [
    // {
    //     data: 'isActive', orderable: false,
    //     className: 'text-center',
    //     render: (d, t, r) => {
    //         if (!permission) return ''
    //         if (d) return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>'
    //         return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>'
    //     }
    // },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    {
      data: 'companyCode',
      className: 'text-center',
      render: (d, t, r) => `<a target="_blank" href="/master/company-detail/${d}">${d}</a>`,
    },
    { data: 'companyAbbreviate', className: 'text-center' },
    { data: 'companyName' },
    { data: 'companyNameTh' },
    { data: 'countryCode', className: 'text-center' },
    { data: 'instanceCode', className: 'text-center' },
    { data: 'instanceName' },
    { data: 'coaId' },
    { data: 'ledgerId1' },
    { data: 'ledgerId2' },
    { data: 'ledgerId3' },
    { data: 'currency1', className: 'text-center' },
    { data: 'currency2', className: 'text-center' },
    { data: 'currency3', className: 'text-center' },
    { data: 'remark' },
    { data: 'companyType' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (r.approvalStatus === 'Waiting for Approve') return toStatus('Wait for Approve', true);
        if (r.approvalStatus === 'Rejected') return toStatus('rejected');
        return d ? toStatus('active') : toStatus('inactive');
      },
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  // modalActiveRef,
  // modalInactiveRef,
  // onSubmitModalChangeStatus,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Common Master <span className="icon icon-angle-double-right" /> Company
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MAIN_MASTER_FINANCE_GROUP}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroupId}
                name="FinanceGroupId"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MAIN_MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnit}
                name="BusinessUnit"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MAIN_MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.CompanyCode}
                financeGroupId={criteria.FinanceGroupId}
                businessUnitId={criteria.BusinessUnit}
                name="CompanyCode"
              />
            </FormGroup2>

            <FormGroup2 text="Company Name">
              <input
                className="form-control"
                onChange={onChangeSearch}
                value={criteria.CompanyName}
                name="CompanyName"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Status">
              <select
                className="form-control"
                value={criteria.Status}
                onChange={onChangeSearch}
                name="Status"
              >
                <option value="">---All---</option>
                <option value="Waiting for Approve">Waiting for Approve</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Rejected">Rejected</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Link
                  className="btn btn-warning btn-sm btn-labeled"
                  to="/master/company-detail/create"
                  target="_blank"
                >
                  <span className="btn-label">
                    <i className="icon icon-plus-circle icon-fw icon-lg" />
                  </span>
                  CREATE COMPANY
                </Link>
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            {/* <th>Action</th> */}
            <th>
              Finance
              <br />
              Group
            </th>
            <th>
              Business
              <br />
              Unit
            </th>
            <th>
              Company
              <br />
              Code
            </th>
            <th>
              Company
              <br />
              Short Name
            </th>
            <th>Company Name</th>
            <th>Company Name TH</th>
            <th>Country</th>
            <th>
              Instance
              <br /> Code
            </th>
            <th>
              Instance
              <br /> Name
            </th>
            <th>COA</th>
            <th>Leger 1</th>
            <th>Leger 2</th>
            <th>Leger 3</th>
            <th>Currency 1</th>
            <th>Currency 2</th>
            <th>Currency 3</th>
            <th>Remark</th>
            <th>Company Type</th>
            <th>Status</th>
            <th>
              Created
              <br />
              By
            </th>
            <th>
              Created
              <br />
              Date
            </th>
            <th>
              Updated
              <br />
              By
            </th>
            <th>
              Updated
              <br />
              Date
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    {/* <ModalFunction
                textHeader="Active"
                textContent="active"
                modalRef={modalActiveRef}
                type="info"
                icon="icon-refresh"
                textBtn="Active"
                noSelect={true}
                onClick={e => onSubmitModalChangeStatus(e, 'Active')}
            />

            <ModalFunction
                textHeader="InActive"
                textContent="inactive"
                modalRef={modalInactiveRef}
                type="danger"
                icon="icon-close"
                textBtn="Yes"
                textBtnBack="No"
                noSelect={true}
                onClick={e => onSubmitModalChangeStatus(e, 'Inactive')}
            /> */}
  </div>
);
