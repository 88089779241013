import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_INTEREST_RECEIPT_CRITERIA = 'EDIT_INTEREST_RECEIPT_CRITERIA';
export const CLEAR_INTEREST_RECEIPT_CRITERIA = 'CLEAR_INTEREST_RECEIPT_CRITERIA';

export const REQUEST_SEARCH_INTEREST_RECEIPT = '@@request/SEARCH_INTEREST_RECEIPT';
export const RECEIVE_SEARCH_INTEREST_RECEIPT = '@@receive/SEARCH_INTEREST_RECEIPT';
export const FAIL_SEARCH_INTEREST_RECEIPT = '@@fail/SEARCH_INTEREST_RECEIPT';

export const UPDATE_SEARCH_RESULT_INTEREST_RECEIPT = 'UPDATE_SEARCH_RESULT_INTEREST_RECEIPT';

export const REQUEST_SAVE_INTEREST_RECEIPT = '@@request/SAVE_INTEREST_RECEIPT';
export const RECEIVE_SAVE_INTEREST_RECEIPT = '@@receive/SAVE_INTEREST_RECEIPT';
export const FAIL_SAVE_INTEREST_RECEIPT = '@@fail/SAVE_INTEREST_RECEIPT';

export const REQUEST_PRINT_INTEREST_RECEIPT = '@@request/PRINT_INTEREST_RECEIPT';
export const RECEIVE_PRINT_INTEREST_RECEIPT = '@@receive/PRINT_INTEREST_RECEIPT';
export const FAIL_PRINT_INTEREST_RECEIPT = '@@fail/PRINT_INTEREST_RECEIPT';

export const printInterestReceive = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/PrintInterestReceipt', criteria),
    method: 'GET',
    types: [
      REQUEST_PRINT_INTEREST_RECEIPT,
      {
        type: RECEIVE_PRINT_INTEREST_RECEIPT,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_PRINT_INTEREST_RECEIPT,
    ],
  },
});

export const saveInterestReceiptReport = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/report/saveInterestReceiptReport`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_INTEREST_RECEIPT,
      RECEIVE_SAVE_INTEREST_RECEIPT,
      FAIL_SAVE_INTEREST_RECEIPT,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_INTEREST_RECEIPT,
  payload: {
    name,
    value,
    index,
  },
});

export const searchInterestReceiptReport = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/getInterestReceiptReport', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_INTEREST_RECEIPT,
      RECEIVE_SEARCH_INTEREST_RECEIPT,
      FAIL_SEARCH_INTEREST_RECEIPT,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_INTEREST_RECEIPT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_INTEREST_RECEIPT_CRITERIA,
  payload: {
    name,
    value,
  },
});
