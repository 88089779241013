import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import Select2 from '../../common/Select2';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import ModalManualPost from '../accounting/ModalManualPost';
import { DATATABLE_ID } from './SecuritiesJournalContainer';
import { toNumber, toStatus, toAccount, getPermission } from '../../common/helpper';
import MasterDropdownUI, { INSTANCE_CODE, ACCOUNT_STANDARD } from '../../common/MasterDropdownUI';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('Posting Journal', 'Securities');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  deferRender: true,
  scroller: true,
  paging: true,
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    { data: 'accountingStandard' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'productType', className: 'text-center' },
    { data: 'partnerType', className: 'text-center' },
    { data: 'transactionType', className: 'text-center' },
    { data: 'partnerCode', className: 'text-center' },
    { data: 'partnerName' },
    {
      data: 'transactionNo',
      render: (d) => `<a target="_blank" href="/loan-investment/bond/${d}"> ${d} </a>`,
    },
    { data: 'flowNo', className: 'text-center' },
    { data: 'flowName' },
    { data: 'postingProcess' },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'drCr', className: 'text-center' },
    { data: 'glAccount' },
    { data: 'customerVendorCode' },
    { data: 'spGlAccount' },
    { data: 'glAccountDescription' },
    { data: 'transactionAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'transactionCurrency', className: 'text-center' },
    {
      data: 'exchangeRate',
      className: 'text-right',
      render: (d) => toNumber(d, 8),
    },
    { data: 'localAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'localCurrency', className: 'text-center' },
    { data: 'gvcTerm' },
    { data: 'contractNo' },
    { data: 'postingDocumentNo' },
    { data: 'clearingDocumentNo' },
    { data: 'reverseDocumentNo' },
    {
      data: 'bankAccountNo',
      render: (d, r, s) => {
        if (d)
          return toAccount(
            s.gainLossBankAbbreviate,
            s.gainLossBranchName,
            s.gainLossAccountTypeCode,
            s.gainLossBankCurrency,
            d
          );
        return '';
      },
    },
    { data: 'paymentMethod' },
    { data: 'postGlStatus', className: 'text-center', render: (d) => toStatus(d) },
    { data: 'postingDateStr', className: 'text-center', type: 'date-black' },
    { data: 'assignment' },
    { data: 'interestText' },
    { data: 'dueOnStr', className: 'text-center', type: 'date-black' },
    { data: 'instructionKey' },
    { data: 'baseAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'interestRate', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'calFromStr', className: 'text-center', type: 'date-black' },
    { data: 'calToStr', className: 'text-center', type: 'date-black' },
    { data: 'numberOfDays', render: (d) => '' },
    { data: 'baseDays', render: (d) => '' },
    { data: 'domesticOverseas' },
    { data: 'withHoldingTaxType' },
    { data: 'withHoldingTaxCode' },
    { data: 'withHoldingTaxBase', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'withHoldingTaxAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'withHoldingTaxBaseLocal', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'withHoldingTaxAmountLocal', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'postingMessage' },
    { data: 'clearingPostingMessage' },
    { data: 'reversePostingMessage' },
    { data: 'postingStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    { data: 'clearingStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    { data: 'reverseStatus', className: 'text-center', render: (d) => toStatus(d, true) },
  ],
};

export default ({
  criteria,
  results,
  dataTableRef,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,

  modalManual,
  modalManualRef,
  onClickOpenModalManual,
  onChangeModalManual,
  onSubmitModalManual,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Accounting <span className="icon icon-angle-double-right" /> Posting Journal{' '}
          <span className="icon icon-angle-double-right" /> Securities
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroup}
                name="FinanceGroup"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companies}
                financeGroupId={criteria.FinanceGroup}
                businessUnitId={criteria.BusinessUnits}
                name="Companies"
              />
            </FormGroup2>

            <FormGroup2 text="Currency">
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.TransactionCurrency}
                name="TransactionCurrency"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Product Type" required={false}>
              <MasterDropdownUI
                onChange={onChangeSearch}
                value={criteria.ProductType}
                // isAll={true}
                notMultiple
                name="ProductType"
                options={['Bond']}
              />
            </FormGroup2>

            <FormGroup2 text="Transaction Type">
              <Select2
                onChange={onChangeSelect2}
                value={criteria.TransactionType}
                name="TransactionType"
              >
                <option value="Held & Sell">Held & Sell (FVOCI)</option>
                <option value="Held">Held (Amortized Cost)</option>
              </Select2>
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Partner">
              <MasterDropdown
                key="External"
                masterType={MASTER_BANK}
                onChange={onChangeSelect2}
                companyCode={criteria.Companies}
                value={criteria.Partner}
                name="Partner"
              />
            </FormGroup2>

            <FormGroup2 text="Post G/L Status">
              <MasterDropdownUI
                onChange={onChangeSearch}
                isAll
                notMultiple
                value={criteria.PostGLStatus}
                name="PostGLStatus"
                options={
                  criteria.TransferMethod === 'Auto Deduct'
                    ? ['New', 'Waiting', 'Failed', 'Completed', 'No Post']
                    : ['New', 'Waiting', 'Failed', 'Completed', 'No Post']
                }
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Posting Process">
              <MasterDropdownUI
                onChange={onChangeSearch}
                isAll
                notMultiple
                value={criteria.PostingProcess}
                name="PostingProcess"
                options={['Daily', 'Month-End']}
              />
            </FormGroup2>

            <FormGroup2 text="Gen. Valn Class/Term">
              <MasterDropdownUI
                onChange={onChangeSearch}
                isAll
                notMultiple
                value={criteria.GenValnClassTerm}
                name="GenValnClassTerm"
                options={['Held < 3 Months', 'Held 3-12 Months', 'Held & Sell']}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Value Date" required={false}>
              <DateRangePicker
                onChange={onChangeSearch}
                required={false}
                value={criteria.ValueDateStr}
                name="ValueDateStr"
              />
            </FormGroup2>

            <FormGroup2 text="Posting Date" required>
              <DateRangePicker
                onChange={onChangeSearch}
                required
                value={criteria.PostingDateStr}
                name="PostingDateStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Transaction No.">
              <input
                className="form-control"
                onChange={onChangeSearch}
                value={criteria.TransactionNo}
                name="TransactionNo"
              />
            </FormGroup2>

            <FormGroup2 text="Contract No.">
              <input
                className="form-control"
                onChange={onChangeSearch}
                value={criteria.ContractNo}
                name="ContractNo"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Accounting Standard">
              <MasterDropdownUI
                masterType={ACCOUNT_STANDARD}
                onChange={onChangeSearch}
                isAll
                notMultiple
                value={criteria.AccountingStandard}
                name="AccountingStandard"
              />
            </FormGroup2>

            <FormGroup2 text="Instance" required={false}>
              <MasterDropdownUI
                masterType={INSTANCE_CODE}
                onChange={onChangeSelect2}
                value={criteria.InstanceCode}
                name="InstanceCode"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      {permission ? (
        <Col2 col={colX[2]}>
          <div>
            <Button
              txt="Manual Clearing"
              icon="edit"
              className="btn-default"
              onClick={(e) => onClickOpenModalManual('clearing')}
              disabled={false}
            />
          </div>
        </Col2>
      ) : null}
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>
              Choose <label className="label-checkbox fix choose-all" />
            </th>
            <th>
              Accounting
              <br /> Standard
            </th>
            <th>
              Company
              <br /> Code
            </th>
            <th>Company Name</th>
            <th>Product Type</th>
            <th>Partner Type</th>
            <th>
              Transaction
              <br /> Type
            </th>
            <th>Partner Code</th>
            <th>Partner Name</th>
            <th>Transaction No.</th>
            <th>Flow No.</th>
            <th>Flow Name</th>
            <th>
              Posting
              <br /> Process
            </th>
            <th>Value Date</th>
            <th>Dr./Cr.</th>
            <th>G/L Account</th>
            <th>
              Customer/
              <br /> Vendor Code
            </th>
            <th>
              Sp. G/L
              <br /> Account
            </th>
            <th>
              G/L Account
              <br /> Description
            </th>
            <th className="text-center">
              Transaction
              <br /> Amount
            </th>
            <th>
              Transaction
              <br /> Currency
            </th>
            <th className="text-center">Exchange Rate</th>
            <th className="text-center">LC Amount</th>
            <th>
              Local
              <br /> Currency
            </th>
            <th>GVC / Term</th>
            <th>Contract No.</th>
            <th>Posting Doc. No.</th>
            <th>Clearing Doc. No.</th>
            <th>Reverse Doc. No.</th>
            <th>Bank Account</th>
            <th>Payment Method</th>
            <th>Post G/L Status</th>
            <th>Posting Date</th>
            <th>Assignment</th>
            <th>Text</th>
            <th>Due on</th>
            <th>Instruction Key</th>
            <th>Base Amount</th>
            <th>Interest Rate</th>
            <th>Calculate From</th>
            <th>Calculate To</th>
            <th>Number of days</th>
            <th>No. base days</th>
            <th>Domestic/Overseas</th>
            <th>W/Tax Type</th>
            <th>W/Tax Code</th>
            <th className="text-center">W/Tax Base</th>
            <th className="text-center">W/Tax Amt</th>
            <th className="text-center">W/Tax Base LC</th>
            <th className="text-center">W/Tax Amt Lc</th>
            <th>Posting Message</th>
            <th>
              Clearing
              <br /> Posting Message
            </th>
            <th>
              Reverse
              <br /> Posting Message
            </th>
            <th>
              Posting
              <br /> Status
            </th>
            <th>
              Clearing
              <br /> Status
            </th>
            <th>
              Reverse
              <br /> Status
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalManualPost
      modalRef={modalManualRef}
      state={modalManual}
      onSubmit={onSubmitModalManual}
      onChageModal={onChangeModalManual}
    />
  </div>
);
