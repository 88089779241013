import React from 'react';

import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import FormGroup3 from '../../../common/FormGroup3';
import DatePicker from '../../../common/DatePicker';

const colX = ['col-md-4', 'col-md-5'];

export default ({ modalRef, state, onSubmit, onChageModal }) => (
  <Modal textHeader="Interest Rate" ref={modalRef} bgHeader="bg-primary">
    <form onSubmit={onSubmit} className="form-horizontal">
      <FormGroup3 text="Interest Rate (%)" col={colX} required>
        <InputMask
          className="form-control"
          format="currency"
          option={{
            prefix: '',
            digits: 5,
            digitsOptional: false,
            placeholder: '0.00000',
            max: 100,
            min: 0,
          }}
          onChange={onChageModal}
          value={state.InterestRate}
          required
          name="InterestRate"
        />
      </FormGroup3>

      <FormGroup3 text="Valid From" col={colX} required>
        <DatePicker
          value={state.ValidFromStr}
          onChange={onChageModal}
          name="ValidFromStr"
          required
          option={{
            // startDate: "d",
            // daysOfWeekDisabled: "0,6",
            todayHighlight: true,
          }}
        />
      </FormGroup3>

      <br />
      <div>
        <center>
          <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
        </center>
      </div>
    </form>
  </Modal>
);
