import React, { Fragment } from 'react';

import AttachmentList from './Attachment';

const colX = ['col-md-6', 'col-sm-4', 'col-xs-12'];

export default ({ formRef, state, onChangeBuApprove, onChangeInputData, showTh, modeAmend }) => (
  // let checkStatus = toLower(state.StatusLg) === 'created' || !state.StatusLg
  <>
    {/* <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                <label className="custom-control" style={{marginTop:7}}>
                    <div>
                        <input id="is-bu-approve" type="checkbox" className="label-checkbox"
                            onChange={ onChangeBuApprove}
                            name="IsBuApprove"
                            checked={state.IsBuApprove}
                            disabled={!checkStatus}
                        />
                        <label htmlFor="is-bu-approve" className="label_checkbox"></label>
                        <label htmlFor="is-bu-approve" className="control-label text_label_checkbox">BU Approve</label>
                    </div>
                </label>

                {
                    state.IsBuApprove ? (
                        <MasterDropdown masterType={MASTER_USER_BG}
                            isChoose={true}
                            notMultipleSelect2={true}
                            value={state.ApproverBu}
                            onChange={e => onChangeInputData({
                                target: e
                            })}
                            noValidateOption={true}
                            saveLocalState={true}
                            required={true}
                            disabled={!checkStatus}
                            action={"approver bu"}
                            name="ApproverBu"
                        />
                    ) : null
                }
                </Col2>
            </Col2>
            <br /> */}
    <AttachmentList
      {...state.Attachment}
      ref={formRef}
      statusLg={state.StatusLg}
      modeAmend={modeAmend}
      showTh
    />
  </>
);
