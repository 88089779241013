import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_INSTANCE_CRITERIA = 'EDIT_MASTER_INSTANCE_CRITERIA';
export const CLEAR_MASTER_INSTANCE_CRITERIA = 'CLEAR_MASTER_INSTANCE_CRITERIA';

export const REQUEST_SEARCH_MASTER_INSTANCE = '@@request/SEARCH_MASTER_INSTANCE';
export const RECEIVE_SEARCH_MASTER_INSTANCE = '@@receive/SEARCH_MASTER_INSTANCE';
export const FAIL_SEARCH_MASTER_INSTANCE = '@@fail/SEARCH_MASTER_INSTANCE';

export const REQUEST_SAVE_MASTER_INSTANCE = '@@request/SAVE_MASTER_INSTANCE';
export const RECEIVE_SAVE_MASTER_INSTANCE =
  '@@receive-The record was successfully saved/SAVE_MASTER_INSTANCE';
export const FAIL_SAVE_MASTER_INSTANCE = '@@fail/SAVE_MASTER_INSTANCE';

export const saveMasterInstance = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveMasterInstance`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MASTER_INSTANCE, RECEIVE_SAVE_MASTER_INSTANCE, FAIL_SAVE_MASTER_INSTANCE],
  },
});

export const searchMasterInstance = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/GetMasterInstance', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_INSTANCE,
      RECEIVE_SEARCH_MASTER_INSTANCE,
      FAIL_SEARCH_MASTER_INSTANCE,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_INSTANCE_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_INSTANCE_CRITERIA,
  payload: {
    name,
    value,
  },
});
