import React, { Component } from 'react';

class ButtonDropdown extends Component {
  state = {
    isOpen: false,
  };

  leaveTimeoutId;

  onClickToggleDropdown = () => {
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  };

  onMouseOver = () => {
    if (this.leaveTimeoutId) clearTimeout(this.leaveTimeoutId);
    this.setState({ isOpen: true });
  };

  onMouseLeave = () => {
    this.leaveTimeoutId = setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 100);
  };

  onClickButtonItem = (callback) => {
    this.setState(
      {
        isOpen: false,
      },
      callback
    );
  };

  render() {
    const {
      buttons = [],
      text = '',
      className = '',
      icon = '',
      disabled,
      dropdownMenu = 'right',
    } = this.props;
    return (
      <div className={`btn-group dropup ${this.state.isOpen ? 'open' : ''}`}>
        <button
          className={`btn ${className} dropdown-toggle`}
          type="button"
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
          // onClick={this.onClickToggleDropdown}
          disabled={disabled}
        >
          <span className={`icon ${icon} icon-lg icon-fw`} />
          &nbsp;{text}&nbsp;
          <span className="caret" />
        </button>
        <ul
          className={`dropdown-menu dropdown-menu-${dropdownMenu}`}
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
        >
          {buttons.map((m, i) => (
            <li
              key={i}
              onClick={() => {
                if (!m.disabled) this.onClickButtonItem(m.onClick);
              }}
              className={m.disabled ? 'disabled' : ''}
            >
              <a href="javascript:void(0);">
                <div className="media">
                  <div className="media-left">
                    {m.icon ? (
                      <span className={`icon ${m.icon} icon-lg icon-fw text-default`} />
                    ) : null}
                  </div>
                  <div className="media-body">
                    <span className="d-b">{m.text}</span>
                  </div>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default ButtonDropdown;
