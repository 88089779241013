import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import TableCashFlow from './TableCashFlowBond';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_COMPANY, MASTER_BANK_CODE } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  formRef,
  state,
  onChangeInputData,
  onSubmit,
  onChangeInputTable,
  onClickGetCashflow,
  onChangeInputHeader,
}) => {
  const noEdit = state.mode === 'edit';

  return (
    <>
      <Col2 col={colX[0]}>
        {noEdit && state.permission ? (
          ''
        ) : (
          <Button
            txt="Get Cash Flow"
            icon="search"
            className="btn-info"
            onClick={onClickGetCashflow}
          />
        )}
      </Col2>
      <br />

      <form onSubmit={onSubmit} ref={formRef} name="cashflow">
        <Card cardActions={['toggler']}>
          {/* <Col2 col={colX[0]}>
                        <Button txt="Add Charge" icon="plus" className="btn-warning" onClick={e => onClickOpenModalCharge(e, 'Cashflow')} />
                    </Col2>
                    <br /> */}
          <Col2 col={colX[2]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank" required>
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  isGetAll
                  customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                  companyCode={state.CompanyCode}
                  disabled={false}
                  value={state.BankCodeCashFlow}
                  name="BankCodeCashFlow"
                />
              </FormGroup2>
              {state.PartnerType === 'Intercompany' &&
              state.TransactionType &&
              state.CompanyByLoan ? (
                <FormGroup2 text="Company for Loan">
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    saveLocalState
                    onChange={onChangeInputData}
                    notMultiple
                    disabled
                    isChoose
                    value={state.CompanyByLoan}
                    name="CompanyByLoan"
                  />
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>

          <TableCashFlow
            state={state.resultCashflow}
            onChangeInputTable={onChangeInputTable}
            resultType="cashflow"
            BankCode={state.BankCodeCashFlow}
            CompanyCode={state.CompanyCode}
            PartnerType={state.PartnerType}
            isBen={false}
            hasUnit
            onChangeInputHeader={onChangeInputHeader}
            bankAccountNoAll={state.AccountNoAll}
            paymentMethodAll={state.PaymentMethodAll}
            permission={state.permission}
          />

          {/* <div className="table-responsive">
                        <table className="table table-bordered table-nowrap">
                            <thead>
                                <tr>
                                    <th className="th-white">Action</th>
                                    <th className="th-white">Payment Date</th>
                                    <th className="th-white">D</th>
                                    <th className="th-white">Flow Type</th>
                                    <th className="th-white">Amount</th>
                                    <th className="th-white">Currency</th>
                                    <th className="th-white">Bank Account</th>
                                    <th className="th-white">Payment Method</th>
                                    <th className="th-white">Beneficary Bank Account</th>
                                    <th className="th-white">Posting<br />Status</th>
                                    <th className="th-white">Local Currency<br />Amount</th>
                                    <th className="th-white">Local<br />Currency</th>
                                    <th className="th-white">Posting Date</th>
                                    <th className="th-white">Document No.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !(state.resultCashflow || []).length ? (
                                        <tr>
                                            <td colSpan="18" align="center">No data available in table</td>
                                        </tr>
                                    ) : null
                                }
                                {
                                    state.resultCashflow.map((m, i) => (
                                        <tr key={i}>
                                            <td align="center"></td>
                                            <td className="width-input-table">
                                                <DatePicker
                                                   className="width-input-table"
                                                    value={m.paymentDateStr}
                                                    onChange={e => onChangeInputTable(e, i, 'loan')}
                                                    option={
                                                        {
                                                            daysOfWeekDisabled: "0,6",
                                                            todayHighlight: true,
                                                        }
                                                    }
                                                    required={true}
                                                    name="paymentDateStr" />
                                            </td>
                                            <td align="center">{m.direction}</td>
                                            <td align="center">{m.flowType}</td>
                                            <td align="right">{setAmount(m.amount, 3)}</td>
                                            <td align="center">{m.currency}</td>
                                            <td className="width-input-table-account">
                                                <MasterDropdown
                                                    noValidateOption={true}
                                                    masterType={MASTER_BANK_ACCOUNT}
                                                    isChoose={true}
                                                    notMultipleSelect2={true}
                                                    value={m.bankAccountNo}
                                                    onChange={e => onChangeInputTable({
                                                        target: e
                                                    }, i, 'loan')}
                                                    saveLocalState={true}
                                                    bankCode={state.BankCodeLoan}
                                                    companyCode={state.CompanyByLoan}
                                                    name="bankAccountNo"
                                                    required={true}
                                                    customeValue={m => `${m.bankAccountNo}`}
                                                    disabled={false} />
                                            </td>
                                            <td className="width-input-table">
                                                <MasterDropdownUI
                                                    onChange={e => onChangeInputTable({
                                                        target: e
                                                    }, i, 'loan')}
                                                    required={true}
                                                    value={m.paymentMethod}
                                                    isChoose={true}
                                                    notMultipleSelect2={true}
                                                    name="paymentMethod"
                                                    options={["Bahtnet", "Cheque", "Internet Banking", "Auto Deduct", "T/T", "T/T Swaper"]}
                                                />
                                            </td>
                                            {
                                                m.paymentMethod === 'Bahtnet' && state.PartnerType === 'External' ? (
                                                    <td className="width-input-table-account">
                                                        <MasterDropdown
                                                            noValidateOption={true}
                                                            masterType={MASTER_BANK_ACCOUNT}
                                                            isChoose={true}
                                                            notMultipleSelect2={true}
                                                            value={m.beneficiaryAccountNo}
                                                            onChange={e => onChangeInputTable({
                                                                target: e
                                                            }, i, 'loan')}
                                                            saveLocalState={true}
                                                            bankCode={state.BankCodeLoan}
                                                            companyCode={state.CompanyByLoan}
                                                            name="beneficiaryAccountNo"
                                                            required={true}
                                                            customeValue={m => `${m.bankAccountNo}`}
                                                            disabled={false} />
                                                    </td>
                                                ) :
                                                <td></td>
                                            }
                                            <td align="center">{m.postingStatus}</td>
                                            <td align="right">{setAmount(m.localCurrencyAmount, 3)}</td>
                                            <td align="center">{m.localCurrency}</td>
                                            <td align="center">{m.postingDateStr}</td>
                                            <td align="center"><a href="#">{m.documentNo}</a></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div> */}
        </Card>
      </form>
    </>
  );
};
