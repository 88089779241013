import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MAINTAIN_BRANCH_CRITERIA = 'EDIT_MAINTAIN_BRANCH_CRITERIA';
export const CLEAR_MAINTAIN_BRANCH_CRITERIA = 'CLEAR_MAINTAIN_BRANCH_CRITERIA';

export const UPDATE_SEARCH_RESULT_MAINTAIN_BRANCH = 'UPDATE_SEARCH_RESULT_MAINTAIN_BRANCH';

export const REQUEST_SEARCH_MAINTAIN_BRANCH = '@@request/SEARCH_MAINTAIN_BRANCH';
export const RECEIVE_SEARCH_MAINTAIN_BRANCH = '@@receive/SEARCH_MAINTAIN_BRANCH';
export const FAIL_SEARCH_MAINTAIN_BRANCH = '@@fail/SEARCH_MAINTAIN_BRANCH';

export const REQUEST_SAVE_MAINTAIN_BRANCH = '@@request/SAVE_MAINTAIN_BRANCH';
export const RECEIVE_SAVE_MAINTAIN_BRANCH =
  '@@receive-The record was successfully saved/SAVE_MAINTAIN_BRANCH';
export const FAIL_SAVE_MAINTAIN_BRANCH = '@@fail/SAVE_MAINTAIN_BRANCH';

export const saveMaintainBranch = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/savemaintainbranch`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MAINTAIN_BRANCH, RECEIVE_SAVE_MAINTAIN_BRANCH, FAIL_SAVE_MAINTAIN_BRANCH],
  },
});

export const searchMaintainBranch = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getmaintainbranch', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MAINTAIN_BRANCH,
      RECEIVE_SEARCH_MAINTAIN_BRANCH,
      FAIL_SEARCH_MAINTAIN_BRANCH,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MAINTAIN_BRANCH,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MAINTAIN_BRANCH_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MAINTAIN_BRANCH_CRITERIA,
  payload: {
    name,
    value,
  },
});
