import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import Select2 from '../../../common/Select2';
import FormGroup2 from '../../../common/FormGroup2';
import FormGroup3 from '../../../common/FormGroup3';
import { DATATABLE_ID } from './InterestReceiptContainer';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { toLower, toNumber, getPermission } from '../../../common/helpper';
import {
  MASTER_USER,
  MASTER_COMPANY,
  MASTER_FINANCE,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('Report', 'Interest Receipt');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-4', 'col-md-7'];

const minYear = 2015;
const dateC = new Date();
const maxYear = dateC.getFullYear();
export const printsSelectedName = [
  'IsSelectedOriginalForCustomerHardCopy',
  'IsSelectedOriginalForCustomerElectronic',
  'IsSelectedCopyForCustomerHardCopy',
  'IsSelectedCopyForCustomerElectronic',
  'IsSelectedCopyForAccountingHardCopy',
  'IsSelectedCopyForAccountingElectronic',
];

export const checkOtherPritsSelected = (ownPrintName, data) => {
  let hasOtherSelected = false;
  printsSelectedName.forEach((key) => {
    if (key === ownPrintName) return;

    if (data[key]) hasOtherSelected = true;
  });

  return hasOtherSelected;
};

const getPrintCout = (row, mode) => {
  if (row.printCounts) {
    const find = row.printCounts.find((f) => f.printMode === mode);
    if (!find) return null;
    return find.count;
  }
  return null;
};

const yearList = [];
for (let i = maxYear; i >= minYear; i--) {
  yearList.push(i);
}

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  colReorder: false,
  fixedColumns: {
    leftColumns: 7,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected || checkOtherPritsSelected('', r)) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        if (
          toLower(r.status) !== 'not generate no.' ||
          !r.postingDocumentNo ||
          toLower(r.status) === 'cancel'
        ) {
          r.canSelect = false;
          return '';
        }
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsSelectedOriginalForCustomerHardCopy',
      className: `text-center col-label-checkbox-print OriginalForCustomerHardCopy`,
      render: (d, t, r) => {
        if (
          toLower(r.status) === 'cancel' ||
          toLower(r.status) === 'not generate no.' ||
          !(r.canPrints || {}).originalForCustomerHardCopy ||
          checkOtherPritsSelected('IsSelectedOriginalForCustomerHardCopy', r)
        ) {
          r.canSelectOriginalForCustomerHardCopy = false;
          return '';
        }

        r.canSelectOriginalForCustomerHardCopy = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsSelectedOriginalForCustomerElectronic',
      className: `text-center col-label-checkbox-print OriginalForCustomerElectronic`,
      render: (d, t, r) => {
        if (
          toLower(r.status) === 'cancel' ||
          toLower(r.status) === 'not generate no.' ||
          !(r.canPrints || {}).originalForCustomerElectronic ||
          checkOtherPritsSelected('IsSelectedOriginalForCustomerElectronic', r)
        ) {
          r.canSelectOriginalForCustomerElectronic = false;
          return '';
        }
        r.canSelectOriginalForCustomerElectronic = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsSelectedCopyForCustomerHardCopy',
      className: `text-center col-label-checkbox-print CopyForCustomerHardCopy`,
      render: (d, t, r) => {
        if (
          toLower(r.status) === 'cancel' ||
          toLower(r.status) === 'not generate no.' ||
          !(r.canPrints || {}).copyForCustomerHardCopy ||
          checkOtherPritsSelected('IsSelectedCopyForCustomerHardCopy', r)
        ) {
          r.canSelectCopyForCustomerHardCopy = false;
          return '';
        }
        r.canSelectCopyForCustomerHardCopy = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsSelectedCopyForCustomerElectronic',
      className: `text-center col-label-checkbox-print CopyForCustomerElectronic`,
      render: (d, t, r) => {
        if (
          toLower(r.status) === 'cancel' ||
          toLower(r.status) === 'not generate no.' ||
          !(r.canPrints || {}).copyForCustomerElectronic ||
          checkOtherPritsSelected('IsSelectedCopyForCustomerElectronic', r)
        ) {
          r.canSelectCopyForCustomerElectronic = false;
          return '';
        }
        r.canSelectCopyForCustomerElectronic = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsSelectedCopyForAccountingHardCopy',
      className: `text-center col-label-checkbox-print CopyForAccountingHardCopy`,
      render: (d, t, r) => {
        if (
          toLower(r.status) === 'cancel' ||
          toLower(r.status) === 'not generate no.' ||
          !(r.canPrints || {}).copyForAccountingHardCopy ||
          checkOtherPritsSelected('IsSelectedCopyForAccountingHardCopy', r)
        ) {
          r.canSelectCopyForAccountingHardCopy = false;
          return '';
        }
        r.canSelectCopyForAccountingHardCopy = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsSelectedCopyForAccountingElectronic',
      className: `text-center col-label-checkbox-print CopyForAccountingElectronic`,
      render: (d, t, r) => {
        if (
          toLower(r.status) === 'cancel' ||
          toLower(r.status) === 'not generate no.' ||
          !(r.canPrints || {}).copyForAccountingElectronic ||
          checkOtherPritsSelected('IsSelectedCopyForAccountingElectronic', r)
        ) {
          r.canSelectCopyForAccountingElectronic = false;
          return '';
        }
        r.canSelectCopyForAccountingElectronic = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    { data: 'companyCode', className: 'text-center' },
    { data: 'partnerCode', className: 'text-center' },
    { data: 'interestReceiptType', className: 'text-center' },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'transactionNo' },
    { data: 'postingDocumentNo' },
    { data: 'status', className: 'text-center' },
    { data: 'amount', className: 'text-right', render: (d) => (d ? toNumber(d, 2) : '') },
    { data: 'wht', className: 'text-right', render: (d) => (d ? toNumber(d, 2) : '') },
    { data: 'netAmount', className: 'text-right', render: (d) => (d ? toNumber(d, 2) : '') },
    { data: 'transactionCurrency', className: 'text-center' },
    { data: 'source' },
    { data: 'isSweep', className: 'text-center', render: (d) => (d ? 'Yes' : 'No') },
    { data: 'issuedBy' },
    { data: 'receivedBy' },
    { data: 'method' },
    {
      data: 'referenceTransactionId',
      render: (d, t, r) => {
        if (toLower(r.source) === 'pn')
          return `<a target="_blank" href="/loan-investment/pn-loan/${d}"> ${d} </a>`;
        if (toLower(r.source) === 'contract loan')
          return `<a target="_blank" href="/loan-investment/contract-loan/${d}"> ${d} </a>`;
        if (toLower(r.source) === 'contract on call')
          return `<a target="_blank" href="/loan-investment/contract-on-call/${d}"> ${d} </a>`;
        if (toLower(r.source) === 'deposit')
          return `<a target="_blank" href="/loan-investment/deposit/${d}"> ${d} </a>`;
        return d;
      },
    },
    { data: 'headerText' },
    { data: 'companyName' },
    { data: 'partnerName' },
    { data: 'printBy' },
    { data: 'printType' },
    { data: 'instanceName' },
    {
      data: null,
      className: 'text-center',
      render: (d, t, r) => getPrintCout(r, 'OriginalForCustomerHardCopy'),
    },
    {
      data: null,
      className: 'text-center',
      render: (d, t, r) => getPrintCout(r, 'OriginalForCustomerElectronic'),
    },
    {
      data: null,
      className: 'text-center',
      render: (d, t, r) => getPrintCout(r, 'CopyForCustomerHardCopy'),
    },
    {
      data: null,
      className: 'text-center',
      render: (d, t, r) => getPrintCout(r, 'CopyForCustomerElectronic'),
    },
    {
      data: null,
      className: 'text-center',
      render: (d, t, r) => getPrintCout(r, 'CopyForAccountingHardCopy'),
    },
    {
      data: null,
      className: 'text-center',
      render: (d, t, r) => getPrintCout(r, 'CopyForAccountingElectronic'),
    },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalGenerateRef,
  onClickOpenModalGenerate,
  onChangeModalData,
  onSubmitModalGenerate,

  onClickOpenModalPrint,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;

  console.log(modalData);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Report <span className="icon icon-angle-double-right" /> Interest Receipt
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroup}
                  name="FinanceGroup"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnit}
                  name="BusinessUnit"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.CompanyCode}
                  name="CompanyCode"
                  financeGroupId={criteria.FinanceGroup}
                  businessUnitId={criteria.BusinessUnit}
                />
              </FormGroup2>

              <FormGroup2 text="Business Partner">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.PartnerCode}
                  name="PartnerCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Transaction No.">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.TransactionNo}
                  name="TransactionNo"
                />
              </FormGroup2>

              <FormGroup2 text="Product Type">
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.Source}
                  name="Source"
                  options={['PN', 'Contract Loan', 'Contract On Call']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Fisical Year" required>
                <select
                  className="form-control"
                  value={criteria.FisicalYear}
                  onChange={onChangeSearch}
                  required
                  name="FisicalYear"
                >
                  {yearList.map((m, i) => (
                    <option value={m} key={m}>
                      {m}
                    </option>
                  ))}
                  {/* <option value={2020}>2020</option>
                                    <option value={2019}>2019</option>
                                    <option value={2018}>2018</option>
                                    <option value={2017}>2017</option>
                                    <option value={2016}>2016</option>
                                    <option value={2015}>2015</option> */}
                </select>
              </FormGroup2>

              <FormGroup2 text="Month">
                <select
                  className="form-control"
                  value={criteria.Month}
                  onChange={onChangeSearch}
                  name="Month"
                >
                  <option value="">---None---</option>
                  <option value={1}>01</option>
                  <option value={2}>02</option>
                  <option value={3}>03</option>
                  <option value={4}>04</option>
                  <option value={5}>05</option>
                  <option value={6}>06</option>
                  <option value={7}>07</option>
                  <option value={8}>08</option>
                  <option value={9}>09</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Sweep">
                <select
                  className="form-control"
                  value={criteria.IsSweep}
                  onChange={onChangeSearch}
                  name="IsSweep"
                >
                  <option value="">---All---</option>
                  <option value>Yes</option>
                  <option value={false}>No</option>
                </select>
              </FormGroup2>

              <FormGroup2 text="Instance">
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.InstanceName}
                  name="InstanceName"
                  options={[
                    'SAP ECC CHEMICAL',
                    'DynamicAX',
                    'FocusOne',
                    'NetSuite',
                    'NonSAP',
                    'SAPHANA',
                    'SAP 4.7',
                    'SAP ECC PACKAGING',
                    'SAP ECC CBMR',
                    'SAP Outside',
                    'SAP ECC CERAMICS',
                    'WINSpeed',
                    'HANA-NPI',
                    'HANA-TMMA',
                  ]}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.Status}
                  name="Status"
                >
                  <option value="">---All---</option>.
                  <option value="Out of scope">Out of scope</option>
                  <option value="Not Generate No.">Not Generate No.</option>
                  <option value="Not Print">Not Print</option>
                  <option value="Printed">Printed</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Col2 col={colX[2]}>
          <div>
            <Button
              txt="GENERATE"
              icon="credit-card-alt"
              className="btn-warning"
              onClick={onClickOpenModalGenerate}
            />
            &nbsp;
            <Button txt="PRINT" icon="print" className="btn-info" onClick={onClickOpenModalPrint} />
          </div>
        </Col2>

        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          exportOptions={exportOptions}
          value={results}
          customize={exportExcel}
        >
          <thead>
            <tr>
              <th rowSpan="2">
                Generate
                <br />
                <label className="label-checkbox fix choose-all" />
              </th>
              <th colSpan="2">Original for Customer</th>
              <th colSpan="2">Copy for Customer</th>
              <th colSpan="2">Copy for Accounting</th>
              <th rowSpan="2">
                Company
                <br />
                Code
              </th>
              <th rowSpan="2">
                Partner
                <br />
                Code
              </th>
              <th rowSpan="2">Type</th>
              <th rowSpan="2">Payment Date</th>
              <th rowSpan="2">Receipt No.</th>
              <th rowSpan="2">
                Document
                <br />
                No.
              </th>
              <th rowSpan="2">Status</th>
              <th rowSpan="2">Interest Amount</th>
              <th rowSpan="2">WHT</th>
              <th rowSpan="2">Net Amount</th>
              <th rowSpan="2">Currency</th>
              <th rowSpan="2">
                Product
                <br />
                Type
              </th>
              <th rowSpan="2">Sweep</th>
              <th rowSpan="2">Issued by</th>
              <th rowSpan="2">Received by</th>
              <th rowSpan="2">Payment Method</th>
              <th rowSpan="2">Transaction No.</th>
              <th rowSpan="2">External Ref.</th>
              <th rowSpan="2">Company Name</th>
              <th rowSpan="2">Partner Name</th>
              <th rowSpan="2">Print By</th>
              <th rowSpan="2">Print Type</th>
              <th rowSpan="2">Instance</th>
              <th colSpan="2">Original for Customer</th>
              <th colSpan="2">Copy for Customer</th>
              <th colSpan="2">Copy for Accounting</th>
            </tr>
            <tr>
              <th>
                Hard Copy
                <br />
                <label
                  data-print="OriginalForCustomerHardCopy"
                  className="label-checkbox fix choose-all-print"
                />
              </th>
              <th>
                Electronic
                <br />
                <label
                  data-print="OriginalForCustomerElectronic"
                  className="label-checkbox fix choose-all-print"
                />
              </th>
              <th>
                Hard Copy
                <br />
                <label
                  data-print="CopyForCustomerHardCopy"
                  className="label-checkbox fix choose-all-print"
                />
              </th>
              <th>
                Electronic
                <br />
                <label
                  data-print="CopyForCustomerElectronic"
                  className="label-checkbox fix choose-all-print"
                />
              </th>
              <th>
                Hard Copy
                <br />
                <label
                  data-print="CopyForAccountingHardCopy"
                  className="label-checkbox fix choose-all-print"
                />
              </th>
              <th>
                Electronic
                <br />
                <label
                  data-print="CopyForAccountingElectronic"
                  className="label-checkbox fix choose-all-print"
                />
              </th>

              <th>Hard Copy</th>
              <th>Electronic</th>
              <th>Hard Copy</th>
              <th>Electronic</th>
              <th>Hard Copy</th>
              <th>Electronic</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-md" ref={modalGenerateRef} modalFooter>
        <form onSubmit={onSubmitModalGenerate} className="form-horizontal">
          <div className="text-center">
            <span className="label label-info">{selectedResultCount} items</span>
            <h2 className="text-info">Generate</h2>
          </div>
          <br />
          <FormGroup3 text="Payment Method" col={colX1} required>
            <Select2
              isChoose
              notMultiple
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              required
              value={modalData.Method}
              name="Method"
            >
              <option key="Transfer" value="Transfer">
                เงินโอนธนาคาร | Transfer
              </option>
              <option key="Cheque" value="Cheque">
                เช็ค | Cheque
              </option>
              <option key="Cash" value="Cash">
                เงินสด | Cash
              </option>
              <option key="Other" value="Other">
                อื่นๆ | Other
              </option>
            </Select2>
          </FormGroup3>

          <FormGroup3 text="Issued by" col={colX1} required>
            <MasterDropdown
              masterType={MASTER_USER}
              isChoose
              notMultipleSelect2
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              noValidateOption
              saveLocalState
              required
              value={modalData.IssuedBy}
              name="IssuedBy"
            />
          </FormGroup3>

          <FormGroup3 text="Received by" col={colX1} required>
            <MasterDropdown
              masterType={MASTER_USER}
              isChoose
              notMultipleSelect2
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              noValidateOption
              saveLocalState
              required
              value={modalData.ReceivedBy}
              name="ReceivedBy"
            />
          </FormGroup3>

          <FormGroup3 text="Form Version" col={colX1} required>
            <MasterDropdownUI
              isChoose
              notMultipleSelect2
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              required
              value={modalData.FormLang}
              name="FormLang"
              options={['TH', 'EN']}
            />
          </FormGroup3>

          {/* <FormGroup3 text="Electronic Document" col={colX1}>
                        <div>
                            <input id="ElectronicDocument" type="checkbox" className="label-checkbox"
                                onChange={onChangeModalData}
                                name="ElectronicDocument"
                                checked={modalData.ElectronicDocument}
                            />
                            <label htmlFor="ElectronicDocument" className="label_checkbox"></label>
                        </div>
                    </FormGroup3> */}
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const exportOptions = {
  columns: [
    function selectColumnExport(idx) {
      return idx > 6;
    },
  ],
};

const $ = window.jQuery;
function exportExcel(xlsx) {
  console.log(xlsx);
  // console.log(xlsx)
  const sheet = xlsx.xl.worksheets['sheet1.xml'];
  const rowToMove = 1;
  const rows = $('row', sheet);
  $.each(rows, (idx, row) => {
    const rowNum = $(row).attr('r');
    const rowTo = parseInt(rowNum) + rowToMove;
    $('c', row).each((ci, col) => {
      const colNum = $(col).attr('r').split(rowNum)[0];
      $(col).attr('r', colNum + rowTo.toString());
    });
    $(row).attr('r', rowTo);
  });

  const ws = sheet.firstChild;

  const $body = $(ws.children[1]);

  const c1 =
    '<c r="X1" t="inlineStr"  s="2"><is><t xml:space="preserve">Original for Customer</t></is></c>';
  const c2 = '<c r="Z1" t="inlineStr" s="2"><is><t>Copy for Customer</t></is></c>';
  const c3 = '<c r="AB1" t="inlineStr" s="2"><is><t>Copy for Accounting</t></is></c>';
  $body.prepend(`<row r="1">${c1}${c2}${c3}</row>`);

  $(ws).append('<mergeCells/>');
  $('mergeCells', ws).eq(1).remove();
  const $ms = $('mergeCells', ws);
  $ms.append(`<mergeCell ref="X1:Y1"/>`);
  $ms.append(`<mergeCell ref="Z1:AA1"/>`);
  $ms.append(`<mergeCell ref="AB1:AC1"/>`);
}
