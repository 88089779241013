import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import { toFixed, toNumber } from '../../../common/helpper';
import DateRangePicker from '../../../common/DateRangePicker';
import {
  MASTER_BANK,
  MASTER_COMPANY,
  MASTER_FINANCE,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const $ = window.jQuery;
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  colReorder: false,
  // order: [[0, 'asc']],
  columns: [
    { data: 'companyAbbreviate', orderable: false },
    {
      data: 'bankAbbreviate',
      orderable: false,
      render: (d, t, r) => `${d} | ${r.bankCode}`,
    },
    { data: 'bankAccountNo', orderable: false },
    { data: 'valueDateStr', orderable: false, className: 'text-center' },
    {
      data: 'depositAmount',
      orderable: false,
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.digit),
    },
    {
      data: 'interestRate',
      orderable: false,
      className: 'text-right',
      render: (d, t, r) => toNumber(d, 5),
    },
    {
      data: 'interestAmount',
      orderable: false,
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.digit),
    },
    {
      data: 'withHoldingTax',
      orderable: false,
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.digit),
    },
    {
      data: 'netInterestAmount',
      orderable: false,
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.digit),
    },
  ],
  rowGroup: {
    dataSrc: 'bankAccountNo',
    startRender: (rows, group) => {
      let interestAmount = 0;
      let netInterestAmount = 0;
      let digit = 0;
      const state = 'open';

      rows.data().each((m) => {
        interestAmount += m.interestAmount || 0;
        netInterestAmount += m.netInterestAmount || 0;
        digit = m.digit;
      });

      return $('<tr></tr>')
        .append(
          `<td colspan="6" align="right" style="cursor:pointer;" data-company="${group}" data-state=${state}>Total</td>`
        )
        .append(`<td align="right">${toNumber(interestAmount, digit)}</td>`)
        .append(`<td align="right"></td>`)
        .append(`<td align="right">${toNumber(netInterestAmount, digit)}</td>`);
    },
  },
  createdRow: (row, data) => {
    const $row = $(row);
    $row.show();
  },
  drawCallback: () => {
    setTimeout(() => {
      window.$(window).trigger('resize');
    }, 100);
  },
};

export default ({
  criteria,
  datas,

  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  onClickExport,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Report <span className="icon icon-angle-double-right" /> Loan Intercom{' '}
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group">
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroup}
                name="FinanceGroup"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnit}
                name="BusinessUnit"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.CompanyCode}
                financeGroupId={criteria.FinanceGroup}
                businessUnitId={criteria.BusinessUnit}
                name="CompanyCode"
              />
            </FormGroup2>

            <FormGroup2 text="Bank">
              <MasterDropdown
                masterType={MASTER_BANK}
                onChange={onChangeSelect2}
                value={criteria.BankCode}
                companyCode={criteria.CompanyCode}
                name="BankCode"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Currency">
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.Currency}
                name="Currency"
              />
            </FormGroup2>

            <FormGroup2 text="Value Date" required>
              <DateRangePicker
                onChange={onChangeSearch}
                required
                value={criteria.ValueDateStr}
                name="ValueDateStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Col2 col={colX[2]}>
        <div>
          <Button txt="Export" icon="download" className="btn-success" onClick={onClickExport} />
        </div>
      </Col2>
      <div className="col-md-12" style={{ overflow: 'scroll', paddingLeft: 0, maxHeight: 500 }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th>Company</th>
              <th>Bank Name</th>
              <th>Account Number</th>
              <th>Value date</th>
              <th className="text-center">Deposit Amount</th>
              <th className="text-center">Int%</th>
              <th className="text-center">Interest Amount</th>
              <th className="text-center">who. 1%</th>
              <th className="text-center">Net int amount</th>
            </tr>
          </thead>
          <tbody>
            {!datas.length ? (
              <tr>
                <td className="text-center" colSpan="9">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {datas.map((n, i) =>
              n.items.map((m, j) =>
                m.total ? (
                  <tr key={j} className="td-grey2">
                    <td className="text-right" colSpan="6">
                      Total
                    </td>
                    <td className="text-right">{setNumber(m.interestAmount, m.digit)}</td>
                    <td className="text-right" />
                    <td className="text-right">{setNumber(m.netInterestAmount, m.digit)}</td>
                  </tr>
                ) : (
                  <tr key={j}>
                    <td>{m.companyAbbreviate}</td>
                    <td>
                      {m.bankAbbreviate} | {m.bankCode}
                    </td>
                    <td>{m.bankAccountNo}</td>
                    <td className="text-center">{m.valueDateStr}</td>
                    <td className="text-right">{setNumber(m.depositAmount, m.digit)}</td>
                    <td className="text-right">{setNumber(m.interestRate, 5)}</td>
                    <td className="text-right">{setNumber(m.interestAmount, m.digit)}</td>
                    <td className="text-right">{setNumber(m.withHoldingTax, m.digit)}</td>
                    <td className="text-right">{setNumber(m.netInterestAmount, m.digit)}</td>
                  </tr>
                )
              )
            )}
          </tbody>
        </table>
      </div>
    </Card>
  </div>
);

export function setNumber(number, float) {
  // if (!number) return ''
  if (number >= 0 || !number) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
}

// {
//     n.items.map((m, j) => {
//         <tr key={j}>
//             <td>{m.companyAbbreviate}</td>
//             <td>{m.bankAbbreviate} | {m.bankCode}</td>
//             <td>{m.bankAccountNo}</td>
//             <td className="text-center">{m.valueDateStr}</td>
//             <td className='text-right'>{setNumber(m.depositAmount, m.digit)}</td>
//             <td className='text-right'>{setNumber(m.interestRate, 5)}</td>
//             <td className='text-right'>{setNumber(m.interestAmount, m.digit)}</td>
//             <td className='text-right'>{setNumber(m.withHoldingTax, m.digit)}</td>
//             <td className='text-right'>{setNumber(m.netInterestAmount, m.digit)}</td>
//         </tr>
//     })
// }
