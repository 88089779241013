import { connect } from 'react-redux';
import React, { Component } from 'react';

import POA from './POA';
import { addAlert } from '../../../reducers/layout/action';
import {
  toLower,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../../common/helpper';
import {
  searchPOA,
  createPOA,
  updateStatus,
  updateSearchResult,
  downloadAttachFile,
} from '../../../reducers/poa/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-poa';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const initCriteria = {
  FinanceGroups: '',
  BusinessUnits: '',
  Companys: '',
  SendDateStr: '',
  Banks: '',
  Status: '',
};

const initModalEdit = {
  TransactionNos: [],
  Bank: [],
  Approver: '',
  Action: '',
};

class POAContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    modalEdit: {
      ...initModalEdit,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `button.download`, this.onClickDownloadAttachFile);
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.download`, this.onClickDownloadAttachFile);
  }

  // search
  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onClickDownloadAttachFile = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    this.props.downloadAttachFile(rowData);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchPOA(this.state.criteria);
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
    });
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    this.props.searchResult[result.row].IsSelected = result.checked;
    this.forceUpdate();
  };
  // end search

  onClickCreate = () => {
    this.setState(
      {
        modalEmodalUploadFiledit: {
          SelectFile: [],
        },
      },
      this.modalCreateRef.open
    );
  };

  onChangeAttachFile = (e) => {
    const { files } = e.target;
    if (!files || !files.length) {
      this.props.addAlert({
        title: 'Warning',
        type: 'warning',
        body: 'Please select file',
      });
      return;
    }

    this.setState((state) => ({
      modalUploadFile: {
        ...state.modalUploadFile,
        SelectFile: files,
      },
    }));
  };

  onSubmitAttachFile = (e) => {
    e.preventDefault();
    const form = new FormData();
    for (let i = 0; i < this.state.modalUploadFile.SelectFile.length; i++) {
      form.append(`file${i}`, this.state.modalUploadFile.SelectFile[i]);
    }
    this.props.createPOA(form).then(() => {
      this.props.searchPOA({ ...this.state.criteria });
      clearCheckBoxAllTable(DATATABLE_ID);
      this.modalCreateRef.close();
    });
  };

  onClickSelectBank = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for select bank!',
      });
      return;
    }

    const checkStatus = selectedData.filter((f) => toLower(f.status) !== 'wait for select bank');
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for select bank!',
      });
      return;
    }

    this.setState(
      {
        modalEdit: {
          ...initModalEdit,
          TransactionNos: selectedData.map((m) => m.transactionNo),
          Action: 'bank',
        },
      },
      this.modalEditRef.open
    );
  };

  onClickConfirm = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for confirm!',
      });
      return;
    }

    const checkStatus = selectedData.filter((f) => toLower(f.status) !== 'wait for confirm');
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for confirm!',
      });
      return;
    }

    const finnanceGroupId = selectedData[0].financeGroupId;
    const checkFinance = selectedData.filter((f) => f.financeGroupId !== finnanceGroupId);
    if (checkFinance.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item same Finance Group!',
      });
      return;
    }

    this.setState(
      {
        modalEdit: {
          ...initModalEdit,
          TransactionNos: selectedData.map((m) => m.transactionNo),
          FinanceGroupId: finnanceGroupId,
          Action: 'confirm',
        },
      },
      this.modalEditRef.open
    );
  };

  onClickCancel = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for cancel!',
      });
      return;
    }

    const status = toLower(selectedData[0].status);
    const checkStatus = selectedData.filter((f) => toLower(f.status) !== status);
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item same Status!',
      });
      return;
    }

    const checkStatus2 = selectedData.filter(
      (f) =>
        toLower(f.status) !== 'wait for select bank' &&
        toLower(f.status) !== 'wait for confirm' &&
        toLower(f.status) !== 'wait for approve'
    );
    if (checkStatus2.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for select bank, Wait for confirm or Wait for approve!',
      });
      return;
    }

    this.setState(
      {
        modalEdit: {
          ...initModalEdit,
          TransactionNos: selectedData.map((m) => m.transactionNo),
          Action:
            status === 'wait for select bank'
              ? 'cancel'
              : status === 'wait for confirm'
                ? 'rbank'
                : status === 'wait for approve'
                  ? 'rconfirm'
                  : '',
        },
      },
      this.modalCancelRef.open
    );
  };

  onChangeModalEdit = (e) => {
    this.setState({
      modalEdit: {
        ...this.state.modalEdit,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Edit = (e) => {
    if (e.value.length < 1) {
      this.setState({
        modalEdit: {
          ...this.state.modalEdit,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        modalEdit: {
          ...this.state.modalEdit,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        modalEdit: {
          ...this.state.modalEdit,
          [e.name]: e.value,
        },
      });
    }
  };

  onSubmitModalEdit = (e) => {
    e.preventDefault();

    this.props
      .updateStatus(
        {
          ...this.state.modalEdit,
          Bank: this.state.modalEdit.Bank.join(','),
        },
        { ...this.state.criteria }
      )
      .then(() => {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.modalEditRef.close();
        this.modalCancelRef.close();
      });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onClickCreate: this.onClickCreate,
      onChangeAttachFile: this.onChangeAttachFile,
      onSubmitAttachFile: this.onSubmitAttachFile,

      onClickSelectBank: this.onClickSelectBank,
      onClickConfirm: this.onClickConfirm,
      onClickCancel: this.onClickCancel,

      onChangeModalEdit: this.onChangeModalEdit,
      onChangeSelect2Edit: this.onChangeSelect2Edit,
      onSubmitModalEdit: this.onSubmitModalEdit,
      onClickDownloadAttachFile: this.onClickDownloadAttachFile,
    };

    return (
      <div>
        <POA
          {...props}
          {...this.state}
          results={this.props.searchResult}
          modalEditRef={(e) => (this.modalEditRef = e)}
          modalCancelRef={(e) => (this.modalCancelRef = e)}
          modalCreateRef={(e) => (this.modalCreateRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.poa,
  }),
  {
    createPOA,
    searchPOA,
    addAlert,
    updateSearchResult,
    updateStatus,
    downloadAttachFile,
  }
)(POAContainer);
