import qs from 'querystring';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import TierDetail from './TierDetail';
import { addAlert } from '../../reducers/layout/action';
import { toLower, toUpperKey, checkAfterValueDate } from '../../common/helpper';
import {
  getMasterTierDetail,
  saveMasterTierDetail,
  getMasterTierEffectiveDate,
} from '../../reducers/masterTier/action';

const $ = window.jQuery;

const initHeader = {
  Type: 'fg',
  FinanceGroupId: '',
  BusinessUnitId: '',
};

const initData = {
  TierId: '',
  EffectiveDateStr: '',
  TempEffectiveDateStr: 'New',
  Mode: 'create',
  setOverDueDate: false,
};

const initTier = {
  TierLevels: [],
};

const initInterest = {
  InterestRateId: '',
  InterestTemplateName: '',
  InterestRate: '',
  CalculateMethod: '',

  PeriodEndType: '',
  IsManualInclude: '',
  IsManualMonthEnd: '',
  ManualPeriodEnd: '',
  ManualDay: '',
  ManualWorkingDay: '',

  DueDateType: '',
  IsDueDateMonthEnd: '',
  ManualDueDate: '',
  DueDateDay: '',
  DueDateWorkingDay: '',
  Frequency: '',
};

class TierDetailContainer extends Component {
  state = {
    ...initHeader,
    ...initData,
    ...initTier,
    ...initInterest,
    resultEffective: [],
  };

  componentDidMount() {
    $('body').on('click', `button.delete-tier`, (e) => this.onClickDeleteTier(e));

    const queryString = qs.parse((this.props.routeProp.location.search || '').substr(1));
    if (!queryString) return;
    const financeGroup = queryString.f || '';
    const businessUnit = queryString.b || '';
    if (financeGroup || businessUnit) {
      this.fetchEffectiveDate(financeGroup, businessUnit);
    }
  }

  componentWillUnmount() {
    $('body').off('click', `button.delete-tier`);
  }

  componentDidUpdate(prevProps, prevState) {
    const { FinanceGroupId, BusinessUnitId } = this.state;

    if (
      (prevState.FinanceGroupId !== FinanceGroupId ||
        prevState.BusinessUnitId !== BusinessUnitId) &&
      (FinanceGroupId || BusinessUnitId)
    ) {
      this.setState({
        ...this.state,
        ...initData,
        ...initTier,
        ...initInterest,
        resultEffective: [],
      });

      this.fetchEffectiveDate(FinanceGroupId, BusinessUnitId);
    }
  }

  fetchEffectiveDate(FinanceGroupId, BusinessUnitId) {
    this.props
      .getMasterTierEffectiveDate({
        FinanceGroups: FinanceGroupId,
        BusinessUnits: BusinessUnitId,
        Mode: 'date',
      })
      .then((response) => {
        if (response.error || !response.payload) return;

        if (!response.payload.length) {
          this.setState({
            ...this.state,
            EffectiveDateStr: '',
            TempEffectiveDateStr: 'New',
            setOverDueDate: false,
            resultEffective: [],
          });
        } else {
          const result = response.payload;
          this.setState({
            ...initTier,
            ...initInterest,
            resultEffective: result,
          });

          const result2 = result.filter((f) => {
            if (!checkAfterValueDate(f.effectiveDateStr, 0)) return true;
            return false;
          });
          if (result2.length === 0) {
            this.setTier(result[result.length - 1].tierId);
          } else {
            const { tierId } = result2[0];
            this.setTier(tierId);
          }
        }
      });
  }

  setTier(tierId) {
    if (!tierId) return;
    this.props
      .getMasterTierDetail({
        TierId: tierId,
        Mode: 'detail',
      })
      .then((response) => {
        if (response.error || !response.payload) return;
        const fetchData = toUpperKey(response.payload);
        delete fetchData.FiananceGroupId;
        delete fetchData.BusinessUnitId;

        const r = response.payload;
        this.setState({
          ...this.state,
          ...fetchData,
          Mode: 'edit',
          TempEffectiveDateStr: tierId,
          setOverDueDate: !checkAfterValueDate(fetchData.EffectiveDateStr, 0),
          ManualPeriodEnd:
            toLower(r.periodEndType) === 'end of term'
              ? ''
              : r.isManualMonthEnd
                ? 'Month End'
                : 'Day',
          ManualDueDate:
            toLower(r.dueDateType) === 'end of term'
              ? ''
              : r.isDueDateMonthEnd
                ? 'Month End'
                : 'Day',
          ManualDay: r.periodEndType === 'End of Term' ? null : r.manualDay,
          DueDateDay: r.dueDateType === 'End of Term' ? null : r.dueDateDay,
          IsManualInclude: r.isManualInclude == null ? false : r.isManualInclude,
        });
      });
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'Type') {
        if (value === 'fg') {
          stateToUpdate.BusinessUnitId = '';
        } else {
          stateToUpdate.FinanceGroupId = '';
        }
      } else if (name === 'IsManualInclude') {
        stateToUpdate.IsManualInclude = !state.IsManualInclude;
      } else if (name === 'PeriodEndType') {
        if (value === 'End of Term') {
          stateToUpdate.ManualPeriodEnd = '';
          stateToUpdate.IsManualMonthEnd = false;
          stateToUpdate.ManualDay = '';
        }
      } else if (name === 'ManualPeriodEnd') {
        if (value === 'Month End') {
          stateToUpdate.IsManualMonthEnd = true;
          stateToUpdate.ManualDay = '';
        } else stateToUpdate.IsManualMonthEnd = false;
      } else if (name === 'ManualDay') {
        if (value > 31) stateToUpdate.ManualDay = 31;
        else if (value < 1) stateToUpdate.ManualDay = 1;
      } else if (name === 'DueDateType') {
        if (value === 'End of Term') {
          stateToUpdate.ManualDueDate = '';
          stateToUpdate.DueDateDay = '';
          stateToUpdate.IsDueDateMonthEnd = false;
        }
      } else if (name === 'ManualDueDate') {
        if (value === 'Month End') {
          stateToUpdate.IsDueDateMonthEnd = true;
          stateToUpdate.DueDateDay = '';
        } else stateToUpdate.IsDueDateMonthEnd = false;
      } else if (name === 'DueDateDay') {
        if (value > 31) stateToUpdate.DueDateDay = 31;
        else if (value < 1) stateToUpdate.DueDateDay = 1;
      }

      return stateToUpdate;
    });

    if (name === 'TempEffectiveDateStr') {
      if (value === 'New') {
        this.setState({
          TierId: '',
          EffectiveDateStr: '',
          setOverDueDate: false,
        });
      } else {
        this.setTier(value);
      }
    }
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitHander = (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formSetDefaultRef,
      this.formConfigRef,
    ]);
    if (inValidFormName) return;

    this.props.saveMasterTierDetail(this.state).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (this.state.TempEffectiveDateStr === 'New') {
        window.location.href = `/master/tier-detail?f=${this.state.FinanceGroupId}&b=${this.state.BusinessUnitId}`;
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/master/tier-rate');
  };

  // - - - - - - Add Tier
  onClickAddTier = (e) => {
    this.setState({
      ...this.state,
      TierLevels: [
        ...this.state.TierLevels,
        {
          tier: this.state.TierLevels.length + 1,
          rateDeposit: 0,
          rateLoan: 0,
        },
      ],
    });
  };

  onClickDeleteTier = (e) => {
    const index = parseInt(e.currentTarget.attributes.index.value);
    const newResult = this.state.TierLevels.filter((item, i) => i !== index);
    this.setState({
      ...this.state,
      TierLevels: newResult,
    });
  };

  onChangeInputResult = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      TierLevels: this.state.TierLevels.map((m, i) => {
        if (i === index) {
          const obj = {
            ...m,
          };

          if (Number(value) > 100 || Number(value) <= 0) {
            this.props.addAlert({
              title: 'Error',
              body: `${name === 'depositRate' ? 'Deposit Rate' : 'Loan Rate'} must more than 0 and less than or equal 100.`,
              type: 'warning',
            });
            return m;
          }
          obj[name] = value;
          return obj;
        }
        return m;
      }),
    }));
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,

      onClickAddTier: this.onClickAddTier,
      onChangeInputResult: this.onChangeInputResult,

      tabRef: (e) => (this.tabRef = e),
      formDetailRef: (e) => (this.formDetailRef = e),
      formTierRef: (e) => (this.formTierRef = e),
      formPeriodRef: (e) => (this.formPeriodRef = e),
    };

    return (
      <div>
        <TierDetail {...props} state={this.state} resultEffective={this.state.resultEffective} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterBankAccount,
  }),
  {
    getMasterTierEffectiveDate,
    getMasterTierDetail,
    saveMasterTierDetail,
    addAlert,
  }
)(TierDetailContainer);
