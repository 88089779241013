import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_IMPORT_EXCEL = '@@request/IMPORT_EXCEL';
export const RECEIVE_IMPORT_EXCEL = '@@receive/IMPORT_EXCEL';
export const FAIL_IMPORT_EXCEL = '@@fail/IMPORT_EXCEL';

export const REQUEST_SEARCH_CASH_PLANT = '@@request/SARCH_CASH_PLANT';
export const RECEIVE_SEARCH_CASH_PLANT = '@@receive/SEARCH_CASH_PLANT';
export const FAIL_SEARCH_CASH_PLANT = '@@fail/SEARCH_CASH_PLANT';

export const REQUEST_CREATE_CASHPLAN = '@@request/CREATE_CASHPLAN';
export const RECEIVE_CREATE_CASHPLAN =
  '@@receive-The transaction was successfully saved/CREATE_CASHPLAN';
export const FAIL_CREATE_CASHPLAN = '@@fail/CREATE_CASHPLAN';

export const REQUEST_CHANGE_VALUE_DATE = '@@request/CHANGE_VALUE_DATE';
export const RECEIVE_CHANGE_VALUE_DATE =
  '@@receive-The transaction was successfully saved/CHANGE_VALUE_DATE';
export const FAIL_CHANGE_VALUE_DATE = '@@fail/CHANGE_VALUE_DATE';

export const REQUEST_DUPICATE_CASH_PLAN = '@@request/DUPICATE_CASH_PLAN';
export const RECEIVE_DUPICATE_CASH_PLAN =
  '@@receive-The transaction was successfully saved/DUPICATE_CASH_PLAN';
export const FAIL_DUPICATE_CASH_PLAN = '@@fail/DUPICATE_CASH_PLAN';

export const REQUEST_CHANGE_STATUS_CASH_PLAN = '@@request/CHANGE_STATUS_CASH_PLAN';
export const RECEIVE_CHANGE_STATUS_CASH_PLAN =
  '@@receive-The transaction was successfully change status/CHANGE_STATUS_CASH_PLAN';
export const FAIL_CHANGE_STATUS_CASH_PLAN = '@@fail/CHANGE_STATUS_CASH_PLAN';

export const REQUEST_SAVE_CASH_PLAN_IMPORT_EXCEL = '@@request/SAVE_CASH_PLAN_IMPORT_EXCEL';
export const RECEIVE_SAVE_CASH_PLAN_IMPORT_EXCEL =
  '@@receive-The transaction was successfully imported/SAVE_CASH_PLAN_IMPORT_EXCEL';
export const FAIL_SAVE_CASH_PLAN_IMPORT_EXCEL = '@@fail/SAVE_CASH_PLAN_IMPORT_EXCEL';

export const SET_SELECTED_CASH_PLAN = 'SET_SELECTED_CASH_PLAN';
export const SET_SELECTED_ALL_CASH_PLAN = 'SET_SELECTED_ALL_CASH_PLAN';

export const REQUEST_GET_CASH_PLAN = '@@request/GET_CASH_PLAN';
export const RECEIVE_GET_CASH_PLAN = '@@receive/GET_CASH_PLAN';
export const FAIL_GET_CASH_PLAN = '@@fail/GET_CASH_PLAN';

export const REQUEST_GET_TEMPLATE_LIST_CASH_PLAN = '@@request/GET_TEMPLATE_LIST_CASH_PLAN';
export const RECEIVE_GET_TEMPLATE_LIST_CASH_PLAN = '@@receive/GET_TEMPLATE_LIST_CASH_PLAN';
export const FAIL_GET_TEMPLATE_LIST_CASH_PLAN = '@@fail/GET_TEMPLATE_LIST_CASH_PLAN';

export const REQUEST_GET_TEMPLATE_CASH_PLAN = '@@request/GET_TEMPLATE_CASH_PLAN';
export const RECEIVE_GET_TEMPLATE_CASH_PLAN = '@@receive/GET_TEMPLATE_CASH_PLAN';
export const FAIL_GET_TEMPLATE_CASH_PLAN = '@@fail/GET_TEMPLATE_CASH_PLAN';

export const EDIT_CASH_PLAN_SEACH_RESULT = 'EDIT_CASH_PLAN_SEACH_RESULT';

export const getTemplateList = () => ({
  [RSAA]: {
    endpoint: endpoint('/cashplan/getcashplantemplatelist'),
    method: 'GET',
    types: [
      REQUEST_GET_TEMPLATE_LIST_CASH_PLAN,
      RECEIVE_GET_TEMPLATE_LIST_CASH_PLAN,
      FAIL_GET_TEMPLATE_LIST_CASH_PLAN,
    ],
  },
});

export const getTemplate = (templateName) => ({
  [RSAA]: {
    endpoint: endpoint(`/cashplan/getcashplantemplate?templateName=${templateName || ''}`),
    method: 'GET',
    types: [
      REQUEST_GET_TEMPLATE_CASH_PLAN,
      RECEIVE_GET_TEMPLATE_CASH_PLAN,
      FAIL_GET_TEMPLATE_CASH_PLAN,
    ],
  },
});

export const editSearchResult = (name, value, index) => ({
  type: EDIT_CASH_PLAN_SEACH_RESULT,
  payload: {
    name,
    value,
    index,
  },
});

export const getCashplan = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/CashPlan/get/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_CASH_PLAN, RECEIVE_GET_CASH_PLAN, FAIL_GET_CASH_PLAN],
  },
});

export const setSelectedAll = (isSelected) => ({
  type: SET_SELECTED_ALL_CASH_PLAN,
  payload: isSelected,
});

export const setSelected = (isSelected, index) => ({
  type: SET_SELECTED_CASH_PLAN,
  payload: {
    isSelected,
    index,
  },
});

export const saveImportExcel = (callBack) => (dispatch) =>
  dispatch({
    [RSAA]: {
      endpoint: endpoint('/Cashplan/Save'),
      method: 'POST',
      types: [
        REQUEST_SAVE_CASH_PLAN_IMPORT_EXCEL,
        {
          type: RECEIVE_SAVE_CASH_PLAN_IMPORT_EXCEL,
          payload: (action) => {
            callBack();
            return 'success';
          },
        },
        FAIL_SAVE_CASH_PLAN_IMPORT_EXCEL,
      ],
    },
  });

export const importExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/CashPlan'),
    method: 'POST',
    body: form,
    types: [REQUEST_IMPORT_EXCEL, RECEIVE_IMPORT_EXCEL, FAIL_IMPORT_EXCEL],
  },
});

export const changeValueDate = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/cashplan/changevaluedate'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_CHANGE_VALUE_DATE, RECEIVE_CHANGE_VALUE_DATE, FAIL_CHANGE_VALUE_DATE],
    },
  });

  if (!actionResponse.error) dispatch(searchCashPlan(criteria));

  return actionResponse;
};

export const changeStatus = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/cashplan/chanagestatus'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_CHANGE_STATUS_CASH_PLAN,
        RECEIVE_CHANGE_STATUS_CASH_PLAN,
        FAIL_CHANGE_STATUS_CASH_PLAN,
      ],
    },
  });

  if (!actionResponse.error) dispatch(searchCashPlan(criteria));

  return actionResponse;
};

export const dupicate = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/cashplan/duplicate'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_DUPICATE_CASH_PLAN, RECEIVE_DUPICATE_CASH_PLAN, FAIL_DUPICATE_CASH_PLAN],
    },
  });

  if (!actionResponse.error) dispatch(searchCashPlan(criteria));

  return actionResponse;
};

export const searchCashPlan = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/cashplan', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_CASH_PLANT, RECEIVE_SEARCH_CASH_PLANT, FAIL_SEARCH_CASH_PLANT],
  },
});

export const createCashPlan = (data, saveTemplate) => ({
  [RSAA]: {
    endpoint: endpoint(`/cashplan/new?saveTemplate=${saveTemplate ? 'true' : 'false'}`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_CREATE_CASHPLAN, RECEIVE_CREATE_CASHPLAN, FAIL_CREATE_CASHPLAN],
  },
});
