import React from 'react';

import Card from '../../common/Card';
import Link from '../../common/Link';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import TablePaging from '../../common/TablePaging';
import { DATATABLE_ID } from './ForwardDetailContainer';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toBank, toFixed, toNumber, toStatus, getPermission } from '../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_AREA,
} from '../../reducers/master/action';

const permission = getPermission('FX Management', 'Manage FX Contract');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  dataTableRef,
}) => {
  const setAmountForward = state.ForwardType === 'Pro Rata';
  const setAssignTo = state.ProductType === 'Forward Special';
  const reqPurposeDetail = !(state.Purpose === 'Trade AP' || state.Purpose === 'Trade AR');
  const isEdit = state.mode === 'edit';
  const header =
    state.mode === 'edit' ? `Transaction No.: ${state.ForwardNo}` : 'Create Forward Contract';
  const optionsPurpose =
    state.TransactionType === 'Buy'
      ? // ["Trade AP", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection"] :
        // ["Trade AR", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection"]
        ['Trade AP', 'Project', 'ST Loan', 'LT Loan & Swap', 'Capital Injection', 'Dividend']
      : ['Trade AR', 'Project', 'ST Loan', 'LT Loan & Swap', 'Capital Injection', 'Dividend'];

  const disable = isEdit;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" />
            <Link txt="Manage FX Contract" href="/fx-management/manage-fx-contract" />
            <span className="icon icon-angle-double-right" /> Forward Contract
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card textHeader={header} bgHeader="bg-primary" cardActions={['toggler']}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeInputData}
                  isChoose
                  notMultiple
                  required
                  value={state.FinanceGroupId}
                  disabled={isEdit || disable}
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Company" required>
                {state.FinanceGroupId ? (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption={isEdit}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    notMultiple={false}
                    value={state.CompanyCode}
                    name="CompanyCode"
                    required
                    saveLocalState
                    disabled={isEdit || disable}
                    financeGroupId={state.FinanceGroupId}
                  />
                ) : (
                  <MasterDropdownUI
                    isChoose
                    notMultipleSelect2
                    value={state.CompanyCode}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="CompanyCode"
                    options={null}
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>
        </Card>

        <Card
          footer={
            permission ? (
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            ) : null
          }
        >
          <Card textHeader="Structure" bgHeader="bg-info">
            <Card>
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Product Type" required>
                    <MasterDropdownUI
                      value={state.ProductType}
                      disabled={disable}
                      onChange={onChangeInputData}
                      notMultiple
                      isChoose
                      required
                      name="ProductType"
                      options={['Forward Main', 'Forward Special']}
                    />
                  </FormGroup2>
                  <FormGroup2 text="Forward Type" required>
                    <MasterDropdownUI
                      // disabled={disable}
                      value={state.ForwardType}
                      onChange={onChangeInputData}
                      notMultiple
                      isChoose
                      required
                      name="ForwardType"
                      options={['Fixed', 'Par', 'Pro Rata']}
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[1]}>
                  <FormGroup2 text="Transaction Type" required>
                    <MasterDropdownUI
                      value={state.TransactionType}
                      onChange={onChangeInputData}
                      notMultiple
                      isChoose
                      required
                      name="TransactionType"
                      disabled={isEdit || disable}
                      options={['Buy', 'Sell']}
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col="col-md-6">
                <FormGroup2 text="Bank" required>
                  {state.CompanyCode ? (
                    <MasterDropdown
                      masterType={MASTER_BANK_CODE}
                      noValidateOption={isEdit}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      isChoose
                      notMultipleSelect2
                      notMultiple={false}
                      required
                      saveLocalState
                      companyCode={state.CompanyCode}
                      value={state.BankCode}
                      isGetAll
                      customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                      disabled={disable}
                      name="BankCode"
                    />
                  ) : (
                    <MasterDropdownUI
                      isChoose
                      notMultipleSelect2
                      value={state.BankCode}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      name="BankCode"
                      options={null}
                    />
                  )}
                </FormGroup2>
                {setAssignTo ? (
                  <FormGroup2 text="Assign To">
                    <MasterDropdown
                      masterType={MASTER_COMPANY}
                      noValidateOption
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      isChoose
                      notMultipleSelect2
                      notMultiple={false}
                      value={state.AssignTo}
                      name="AssignTo"
                      disabled={state.isUseForward}
                    />
                  </FormGroup2>
                ) : null}
              </Col2>

              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Forward Contract No.">
                    <input
                      className="form-control"
                      value={state.ContractNo || ''}
                      onChange={onChangeInputData}
                      name="ContractNo"
                      // disabled={disable}
                    />
                  </FormGroup2>
                  <FormGroup2 text="Contract Date" required>
                    <DatePicker
                      // disabled={disable}
                      value={state.ContractDateStr}
                      onChange={onChangeInputData}
                      name="ContractDateStr"
                      required
                      option={{
                        // startDate: "d",
                        daysOfWeekDisabled: '0,6',
                        todayHighlight: true,
                      }}
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[1]}>
                  <FormGroup2 text="Start Date" required>
                    <DatePicker
                      // disabled={disable}
                      value={state.StartDateStr}
                      onChange={onChangeInputData}
                      name="StartDateStr"
                      required
                      option={{
                        // startDate: "d",
                        daysOfWeekDisabled: '0,6',
                        todayHighlight: true,
                      }}
                    />
                  </FormGroup2>
                  <FormGroup2 text="End Date" required>
                    <DatePicker
                      // disabled={disable}
                      value={state.EndDateStr}
                      onChange={onChangeInputData}
                      name="EndDateStr"
                      required
                      option={{
                        // startDate: "d",
                        daysOfWeekDisabled: '0,6',
                        todayHighlight: true,
                      }}
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Amount Forward" required>
                    <InputMask
                      className="form-control"
                      // disabled={disable}
                      format="currency"
                      option={state.inputMaskAmount}
                      onChange={onChangeInputData}
                      value={state.Amount}
                      required
                      name="Amount"
                    />
                  </FormGroup2>

                  <FormGroup2 text="Forward Currency" required>
                    <MasterDropdown
                      masterType={MASTER_CURRENCY}
                      isChoose
                      notMultipleSelect2
                      value={state.ForwardCurrency}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      noValidateOption
                      required
                      name="ForwardCurrency"
                      disabled={state.isUseForward}
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[1]}>
                  <FormGroup2 text="Book Bank Currency" required>
                    <MasterDropdown
                      masterType={MASTER_CURRENCY}
                      isChoose
                      notMultipleSelect2
                      value={state.BookBankCurrency}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      noValidateOption
                      required
                      name="BookBankCurrency"
                      disabled={state.isUseForward}
                    />
                  </FormGroup2>
                </Col2>
              </Col2>
            </Card>

            <Card>
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Spot Rate" required>
                    <InputMask
                      className="form-control"
                      format="currency"
                      option={{
                        prefix: '',
                        digits: 8,
                        digitsOptional: false,
                        placeholder: '0.00000000',
                      }}
                      onChange={onChangeInputData}
                      value={state.SpotRate}
                      required
                      // disabled={disable}
                      name="SpotRate"
                    />
                  </FormGroup2>
                  <FormGroup2 text="Swap Rate" required>
                    <InputMask
                      className="form-control"
                      format="currency"
                      option={{
                        prefix: '',
                        digits: 8,
                        digitsOptional: false,
                        placeholder: '0.00000000',
                      }}
                      onChange={onChangeInputData}
                      value={state.SwapRate}
                      required
                      // disabled={disable}
                      name="SwapRate"
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[1]}>
                  <FormGroup2 text="Forward Rate" required>
                    <InputMask
                      className="form-control"
                      format="currency"
                      option={{
                        prefix: '',
                        digits: 8,
                        digitsOptional: false,
                        placeholder: '0.00000000',
                      }}
                      onChange={onChangeInputData}
                      value={state.ForwardRate}
                      required
                      disabled
                      name="ForwardRate"
                    />
                  </FormGroup2>
                  {setAmountForward ? (
                    <FormGroup2 text="Base Rate" required>
                      <InputMask
                        className="form-control"
                        format="currency"
                        option={{
                          prefix: '',
                          digits: 8,
                          digitsOptional: false,
                          placeholder: '0.00000000',
                        }}
                        onChange={onChangeInputData}
                        value={state.BaseRate}
                        required
                        // disabled={disable}
                        name="BaseRate"
                      />
                    </FormGroup2>
                  ) : null}
                </Col2>
              </Col2>

              <Col2 col={colX[0]}>
                <Col2 col={colX[1]} />

                <Col2 col={colX[1]}>
                  <FormGroup2 text="Amount Book Bank (Forward Rate)" required>
                    <InputMask
                      className="form-control"
                      format="currency"
                      option={state.inputMaskAmount}
                      onChange={onChangeInputData}
                      value={state.AmountFixPar}
                      required
                      // disabled={disable}
                      name="AmountFixPar"
                    />
                  </FormGroup2>

                  {setAmountForward ? (
                    <FormGroup2 text="Amount Book Bank (Base Rate)" required>
                      <InputMask
                        className="form-control"
                        format="currency"
                        option={state.inputMaskAmount}
                        onChange={onChangeInputData}
                        value={state.AmountProRata}
                        required
                        // disabled={disable}
                        name="AmountProRata"
                      />
                    </FormGroup2>
                  ) : null}
                </Col2>
              </Col2>
            </Card>

            <Card>
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Purpose" required>
                    <MasterDropdownUI
                      value={state.Purpose}
                      onChange={onChangeInputData}
                      name="Purpose"
                      notMultiple
                      isChoose
                      required
                      disabled={state.isUseForward}
                      options={optionsPurpose}
                    />
                  </FormGroup2>

                  <FormGroup2 text="Business Area" required={state.BusinessArea}>
                    {isEdit ? (
                      <select
                        name="BusinessArea"
                        className="form-control"
                        onChange={onChangeInputData}
                        value={state.BusinessArea}
                        required={state.businessAreaList.length}
                        disabled={!state.businessAreaList.length}
                      >
                        {state.businessAreaList.length ? (
                          state.businessAreaList.map((m) => (
                            <option value={m.businessArea} key={m.businessArea}>
                              {m.businessArea} | {m.businessAreaName}
                            </option>
                          ))
                        ) : (
                          <option value="" key="">
                            ---Choose---
                          </option>
                        )}
                      </select>
                    ) : (
                      <MasterDropdown
                        masterType={MASTER_BUSINESS_AREA}
                        noValidateOption={isEdit}
                        onChange={onChangeInputData}
                        companyCode={state.CompanyCode}
                        notMultiple
                        isChoose={!state.BusinessArea}
                        required={state.BusinessArea}
                        value={state.BusinessArea}
                        name="BusinessArea"
                        disabled={!state.BusinessArea}
                      />
                    )}
                  </FormGroup2>
                </Col2>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Dollar Linked" required>
                    <MasterDropdownUI
                      value={state.DollarLinked ? 'Yes' : state.DollarLinked === false ? 'No' : ''}
                      onChange={onChangeInputData}
                      notMultiple
                      isChoose
                      required
                      name="DollarLinked"
                      // disabled={disable}
                      options={['Yes', 'No']}
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2
                    text="Purpose Detail"
                    required={reqPurposeDetail}
                    maxLength={200}
                    length={(state.PurposeDetail || '').length}
                  >
                    <textarea
                      className="form-control"
                      rows="3"
                      value={state.PurposeDetail || ''}
                      onChange={onChangeInputData}
                      required={reqPurposeDetail}
                      // disabled={disable}
                      maxLength={200}
                      name="PurposeDetail"
                    />
                  </FormGroup2>

                  <FormGroup2 text="Rollover Reference">
                    <input
                      className="form-control"
                      value={state.RolloverRef2 || ''}
                      onChange={onChangeInputData}
                      disabled
                      name="RolloverRef2"
                    />
                  </FormGroup2>
                </Col2>
              </Col2>
            </Card>
          </Card>

          <Card textHeader="Other Information" bgHeader="bg-warning">
            <Card>
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2
                    text="Internal Ref No"
                    maxLength={50}
                    length={(state.InternalRefNo || '').length}
                  >
                    <input
                      className="form-control"
                      value={state.InternalRefNo || ''}
                      // disabled={disable}
                      onChange={onChangeInputData}
                      maxLength={50}
                      name="InternalRefNo"
                    />
                  </FormGroup2>

                  <FormGroup2 text="Request Type">
                    <MasterDropdownUI
                      value={state.RequestType}
                      onChange={onChangeInputData}
                      // disabled={disable}
                      notMultiple
                      isChoose
                      name="RequestType"
                      options={['With Underlying', 'Without Underlying', 'Loan']}
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[1]}>
                  <FormGroup2
                    text="Contract Detail"
                    maxLength={200}
                    length={(state.ContractDetail1 || '').length}
                  >
                    <textarea
                      className="form-control"
                      rows="2"
                      value={state.ContractDetail1 || ''}
                      onChange={onChangeInputData}
                      maxLength={200}
                      // disabled={disable}
                      name="ContractDetail1"
                    />
                  </FormGroup2>
                  <FormGroup2 text="Dealer" maxLength={50} length={(state.Dealer || '').length}>
                    <input
                      className="form-control"
                      value={state.Dealer || ''}
                      onChange={onChangeInputData}
                      maxLength={50}
                      // disabled={disable}
                      name="Dealer"
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2
                    text="Department"
                    maxLength={50}
                    length={(state.Department || '').length}
                  >
                    <input
                      className="form-control"
                      value={state.Department || ''}
                      // disabled={disable}
                      onChange={onChangeInputData}
                      maxLength={50}
                      name="Department"
                    />
                  </FormGroup2>

                  <FormGroup2 text="Status">
                    <MasterDropdownUI
                      value={state.StatusFile}
                      onChange={onChangeInputData}
                      notMultiple
                      // disabled={disable}
                      isChoose
                      name="StatusFile"
                      options={['Matching', 'Import', 'Export']}
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[1]}>
                  <FormGroup2 text="Note 1" maxLength={200} length={(state.Note1 || '').length}>
                    <textarea
                      className="form-control"
                      rows="2"
                      value={state.Note1 || ''}
                      // disabled={disable}
                      onChange={onChangeInputData}
                      maxLength={200}
                      name="Note1"
                    />
                  </FormGroup2>

                  <FormGroup2 text="Note 2" maxLength={200} length={(state.Note2 || '').length}>
                    <textarea
                      className="form-control"
                      rows="2"
                      value={state.Note2 || ''}
                      // disabled={disable}
                      onChange={onChangeInputData}
                      maxLength={200}
                      name="Note2"
                    />
                  </FormGroup2>
                </Col2>
              </Col2>
            </Card>
          </Card>
        </Card>
      </form>

      {state.mode === 'edit' ? (
        <Card textHeader="History Transaction" bgHeader="bg-primary" cardActions={['toggler']}>
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={opt}
            value={state.historyDetail}
          >
            <thead>
              <tr>
                <th>Transaction No.</th>
                <th>
                  Finance
                  <br /> Group
                </th>
                <th>
                  Business
                  <br /> Unit
                </th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>
                  Company
                  <br /> Name
                </th>
                <th>Product Type</th>
                <th>Transaction Type</th>
                <th>Bank</th>
                <th>Forward Contract No.</th>
                <th>Rollover Reference</th>
                <th>Contract Date</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Forward Type</th>
                <th>
                  Forward
                  <br /> Currency
                </th>
                <th>
                  Book Bank
                  <br /> Currency
                </th>
                <th>Original Amount</th>
                <th>Spot Rate</th>
                <th>Swap Rate</th>
                <th>Base Rate</th>
                <th>Forward Rate</th>
                <th>
                  Settlement Rate
                  <br /> (Contract Rate)
                </th>
                <th>Value Date</th>
                <th>Payment Date</th>
                <th>Gain/Loss</th>
                <th>
                  Gain/Loss
                  <br /> Currency
                </th>
                <th>Purpose</th>
                <th>Purpose Detail</th>
                <th>Status</th>
                <th>Business Area</th>
                <th>Dollar Linked</th>
                <th>Internal Ref No</th>
                <th>Request Type</th>
                <th>Contract Detail</th>
                <th>Dealer</th>
                <th>Department</th>
                <th>Status File</th>
                <th>Note 1</th>
                <th>Note 2</th>
                <th>Assign To</th>
              </tr>
            </thead>
          </Table>
        </Card>
      ) : (
        <Card textHeader="Create History" bgHeader="bg-primary" cardActions={['toggler']}>
          <TablePaging colNumber={33} renderRow={renderRow()} value={state.createHistory}>
            <thead>
              <tr>
                <th className="th-success">Forward No.</th>
                <th className="th-success">Finance Group</th>
                <th className="th-success">Company Code</th>
                <th className="th-success">Company Name</th>
                <th className="th-success">Product Type</th>
                <th className="th-success">Transaction Type</th>
                <th className="th-success">Forward Type</th>
                <th className="th-success">Bank</th>
                <th className="th-success">Forward Contract No.</th>
                <th className="th-success">Contract Date</th>
                <th className="th-success">Start Date</th>
                <th className="th-success">End Date</th>
                <th className="th-success">
                  Forward
                  <br /> Currency
                </th>
                <th className="th-success">
                  Book Bank
                  <br /> Currency
                </th>
                <th className="th-success">Amount Forward</th>
                <th className="th-success">Spot Rate</th>
                <th className="th-success">Swap Rate</th>
                <th className="th-success">Forward Rate</th>
                <th className="th-success">Base Rate</th>
                <th className="th-success">Amount Book Bank (Forward Rate)</th>
                <th className="th-success">Amount Book Bank (Base Rate)</th>
                <th className="th-success">Purpose</th>
                <th className="th-success">Business Area</th>
                <th className="th-success">Dollar Linked</th>
                <th className="th-success">Purpose Detail</th>
                <th className="th-success">Rollover Reference</th>
                <th className="th-success">Internal Ref No.</th>
                <th className="th-success">Request Type</th>
                <th className="th-success">Contract Detail</th>
                <th className="th-success">Dealer</th>
                <th className="th-success">Department</th>
                <th className="th-success">Status</th>
                <th className="th-success">Note 1</th>
                <th className="th-success">Note 2</th>
                <th className="th-success">Assign To</th>
              </tr>
            </thead>
          </TablePaging>
        </Card>
      )}
    </div>
  );
};

const renderRow = () => (m, i) => (
  <tr key={i}>
    <td>
      <a href={`/fx-management/forward-detail/${m.forwardNo}`} target="_balnk">
        {m.forwardNo}
      </a>
    </td>
    <td>{m.financeGroupName}</td>
    <td style={{ textAlign: 'center' }}>{m.companyCode}</td>
    <td>{m.companyName}</td>
    <td style={{ textAlign: 'center' }}>{m.productType}</td>
    <td style={{ textAlign: 'center' }}>{m.transactionType}</td>
    <td style={{ textAlign: 'center' }}>{m.forwardType}</td>
    <td>
      {m.bankAbbreviate} | {m.bankName}
    </td>
    <td>{m.contractNo}</td>
    <td style={{ textAlign: 'center' }}>{m.contractDateStr}</td>
    <td style={{ textAlign: 'center' }}>{m.startDateStr}</td>
    <td style={{ textAlign: 'center' }}>{m.endDateStr}</td>
    <td style={{ textAlign: 'center' }}>{m.forwardCurrency}</td>
    <td style={{ textAlign: 'center' }}>{m.bookBankCurrency}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.amount, 3)}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.spotRate, 8)}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.swapRate, 8)}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.forwardRate, 8)}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.baseRate, 8)}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.amountFixPar, 3)}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.amountProRata, 3)}</td>
    <td style={{ textAlign: 'center' }}>{m.purpose}</td>
    <td>{m.businessArea ? m.businessAreaName : ''}</td>
    <td style={{ textAlign: 'center' }}>{m.dollarLinked ? 'Yes' : 'No'}</td>
    <td>{m.purposeDetail}</td>
    <td>{m.rolloverReference}</td>
    <td>{m.internalRefNo}</td>
    <td>{m.requestType}</td>
    <td>{m.contractDetail}</td>
    <td>{m.dealer}</td>
    <td>{m.department}</td>
    <td style={{ textAlign: 'center' }}>{m.status}</td>
    <td>{m.note}</td>
    <td>{m.note2}</td>
    <td>{m.assignToName}</td>
  </tr>
);

const setAmount = (number, float) => {
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};

const opt = {
  // order: [[1, 'asc']],
  // colReorder: false,
  // ordering: false,
  columns: [
    { data: 'forwardNo' },
    {
      data: 'financeGroupName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'businessUnitName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'companyCode',
      className: 'text-center',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'companyName',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    { data: 'productType', className: 'text-center' },
    {
      data: 'transactionType',
      className: 'text-center',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special' ? d : d,
    },
    {
      data: 'bankAbbreviate',
      render: (d, t, r) =>
        r.productType === 'Forward Main' || r.productType === 'Forward Special'
          ? toBank(d, r.bankName)
          : toBank(d, r.bankName),
    },
    { data: 'contractNo' },
    {
      data: 'rolloverRef',
      className: 'text-center',
      render: (d, r, s) => {
        if (d)
          return `<a target="_blank" href="/fx-management/forward-detail/${d}" class="btn btn-info-reverse">${s.rolloverRef2}</button>`;
        return '';
      },
    },
    { data: 'contractDateStr', className: 'text-center', type: 'date-black' },
    { data: 'startDateStr', className: 'text-center', type: 'date-black' },
    { data: 'endDateStr', className: 'text-center', type: 'date-black' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'forwardCurrency', className: 'text-center' },
    { data: 'bookBankCurrency', className: 'text-center' },
    { data: 'amount', className: 'text-right', render: (d, t, r) => toNumber(d, r.currencyDigit) },
    { data: 'spotRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'swapRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'baseRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'forwardRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'settlementRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'paymentDateStr', className: 'text-center', type: 'date-black' },
    {
      data: 'gainLoss',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'gainLossCurrency', className: 'text-center' },
    { data: 'purpose' },
    { data: 'purposeDetail' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    {
      data: 'businessArea',
      render: (d, t, r) => {
        if (!d) return '';
        return r.businessAreaName;
      },
    },
    {
      data: 'dollarLinked',
      className: 'text-center',
      render: (d, t, r) => {
        if (r.productType === 'Forward Main' || r.productType === 'Forward Special')
          return d ? 'Yes' : !d ? 'No' : '';
        return d ? 'Yes' : !d ? 'No' : '';
      },
    },
    { data: 'internalRefNo' },
    { data: 'requestType' },
    { data: 'contractDetail' },
    { data: 'dealer' },
    { data: 'department' },
    { data: 'statusFile', className: 'text-center' },
    { data: 'note' },
    { data: 'note2' },
    {
      data: 'assignTo',
      render: (d, t, r) => {
        if (d) return `${d} | ${r.assignToName}`;
        return '';
      },
    },
  ],
};
