import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Button from '../../common/Button';
import FormGroup2 from '../../common/FormGroup2';
import RadioButton from '../../common/RadioButton';
import { getPermission } from '../../common/helpper';

const permission = getPermission('Common Master', 'Role');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,
  onChangeInputTable,

  formDetailRef,
  formConfigRef,
}) => {
  const textHeader = state.mode === 'create' ? 'Create Role' : 'Edit Role';
  const checkSave = !!((state.mode === 'edit') & !state.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Common Master <span className="icon icon-angle-double-right" />
            <Link txt="Role" href="/master/role" />
            <span className="icon icon-angle-double-right" /> Role Detail{' '}
          </small>
        </p>
      </div>

      <Card
        textHeader={textHeader}
        bgHeader="bg-primary"
        cardActions={['toggler']}
        footer={
          !permission ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={checkSave}
            />
          )
        }
      >
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Role Name" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={false}
                  value={state.RoleName || ''}
                  name="RoleName"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>

      {state.mode === 'edit' ? (
        <Card textHeader="Configuration" bgHeader="bg-primary" cardActions={['toggler']}>
          <form ref={formConfigRef} name="configuration">
            <div className="table-responsive" style={{ height: '450px', overflowY: 'auto' }}>
              <table className="table table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th>Action</th>
                    <th>Permission</th>
                    <th>Product</th>
                    <th>Sub Product</th>
                  </tr>
                </thead>
                <tbody>
                  {!(state.ResultProduct || []).length ? (
                    <tr>
                      <td colSpan="4" align="center">
                        No data available in table
                      </td>
                    </tr>
                  ) : (
                    state.ResultProduct.map((m, i) => (
                      <tr key={i}>
                        <td align="center">
                          <div>
                            <input
                              id={`isActive${i}`}
                              type="checkbox"
                              className="label-checkbox"
                              onChange={(e) => onChangeInputTable(e, i)}
                              name="isActive"
                              checked={m.isActive}
                            />
                            <label htmlFor={`isActive${i}`} className="label_checkbox" />
                          </div>
                        </td>
                        <td align="center">
                          <div className="td-input-method">
                            {m.isActive ? (
                              <div className="form-group">
                                {m.subProduct === 'Plan & Approve Transaction' ? (
                                  <>
                                    <div className="form-group">
                                      <RadioButton
                                        text="All"
                                        value="All"
                                        checked={m.permission === 'All' && m.permission != null}
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                        required
                                      />

                                      <RadioButton
                                        text="Create"
                                        value="Create"
                                        checked={m.permission === 'Create' && m.permission != null}
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />
                                    </div>

                                    <br />

                                    <div className="form-group">
                                      <RadioButton
                                        text="View (All)"
                                        value="View"
                                        checked={m.permission === 'View' && m.permission != null}
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />

                                      <RadioButton
                                        text="View (Inflow)"
                                        value="View (Inflow)"
                                        checked={
                                          m.permission === 'View (Inflow)' && m.permission != null
                                        }
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />

                                      <RadioButton
                                        text="View (Outflow)"
                                        value="View (Outflow)"
                                        checked={
                                          m.permission === 'View (Outflow)' && m.permission != null
                                        }
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />
                                    </div>
                                  </>
                                ) : m.subProduct === 'Create Exposure' ? (
                                  <>
                                    <div className="form-group">
                                      <RadioButton
                                        text="All"
                                        value="All"
                                        checked={m.permission === 'All' && m.permission != null}
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                        required
                                      />

                                      <RadioButton
                                        text="Create"
                                        value="Create"
                                        checked={m.permission === 'Create' && m.permission != null}
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />
                                    </div>

                                    <br />

                                    <div className="form-group">
                                      <RadioButton
                                        text="View (All)"
                                        value="View"
                                        checked={m.permission === 'View' && m.permission != null}
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />

                                      <RadioButton
                                        text="View (Inward)"
                                        value="View (Inward)"
                                        checked={
                                          m.permission === 'View (Inward)' && m.permission != null
                                        }
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />

                                      <RadioButton
                                        text="View (Outward)"
                                        value="View (Outward)"
                                        checked={
                                          m.permission === 'View (Outward)' && m.permission != null
                                        }
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />
                                    </div>
                                  </>
                                ) : m.subProduct === 'Exposure Position' ? (
                                  <>
                                    <div className="form-group">
                                      <RadioButton
                                        text="All"
                                        value="All"
                                        checked={m.permission === 'All' && m.permission != null}
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                        required
                                      />

                                      <RadioButton
                                        text="View (All)"
                                        value="View"
                                        checked={m.permission === 'View' && m.permission != null}
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />
                                    </div>

                                    <br />

                                    <div className="form-group">
                                      <RadioButton
                                        text="View (Inward)"
                                        value="View (Inward)"
                                        checked={
                                          m.permission === 'View (Inward)' && m.permission != null
                                        }
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />

                                      <RadioButton
                                        text="View (Outward)"
                                        value="View (Outward)"
                                        checked={
                                          m.permission === 'View (Outward)' && m.permission != null
                                        }
                                        onChange={(e) => onChangeInputTable(e, i)}
                                        name={`permissionin${i}`}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <RadioButton
                                      text="All"
                                      value="All"
                                      checked={m.permission === 'All' && m.permission != null}
                                      onChange={(e) => onChangeInputTable(e, i)}
                                      name={`permissionin${i}`}
                                      required
                                    />
                                    <RadioButton
                                      text="View"
                                      value="View"
                                      checked={m.permission === 'View' && m.permission != null}
                                      onChange={(e) => onChangeInputTable(e, i)}
                                      name={`permissionin${i}`}
                                    />
                                  </>
                                )}
                              </div>
                            ) : null}
                          </div>
                        </td>
                        <td>{m.product}</td>
                        <td>{m.subProduct}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </form>
        </Card>
      ) : null}
    </div>
  );
};
