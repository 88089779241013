import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import { toNumber } from '../SweepPn/SweepPN';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import { DATATABLE_ID } from './BankGuaranteeContainer';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import { toStatus, toBankCode } from '../../../common/helpper';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import {
  MASTER_COMPANY,
  GET_BG_LG_TYPE,
  MASTER_FINANCE,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  GET_BG_BENEFICIARY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const $ = window.jQuery;
const { moment } = window;

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[0, 'asc']],
  // fixedColumns: {
  //     leftColumns: 1,
  // },
  columns: [
    {
      data: 'transactionNo',
      render: (d, t, r) =>
        `<a target="_blank" href="/bank-guarantee/report/${d}/${r.id}" class="edit-request-bg"> ${d} </a>`,
    },
    { data: 'lgNo' },
    {
      data: 'bankCode',
      render: (d, t, r) => (d ? toBankCode(d, r.bankAbbreviate, r.bankName) : ''),
    },
    { data: 'companyBgLineFullName' },
    { data: 'companyCode', render: (d, t, r) => (d ? `${d} | ${r.companyName}` : '') },
    { data: 'beneficiaryNameTh' },
    { data: 'beneficiaryName' },
    { data: 'lgTypeId', render: (d, t, r) => (d ? `${r.lgTypeCode} | ${r.lgTypeName}` : '') },
    { data: 'amount', className: 'text-right', render: (d) => (d ? toNumber(d, 2) : '') },
    { data: 'currency', className: 'text-center' },
    { data: 'effectiveDateStr', className: 'text-center', type: 'date-black' },
    { data: 'expireDateStr', className: 'text-center', type: 'date-black' },
    { data: 'remark' },
    { data: 'remarkForFinance' },
    { data: 'costCenter' },
    { data: 'internalOrder' },
    { data: 'inactiveDateStr', className: 'text-center', type: 'date-black' },
    { data: 'statusLg', className: 'text-center', render: (d) => (d ? toStatus(d, true) : '') },
    {
      data: 'internalMessage',
      render: (d) => {
        if (d) {
          if (d.length > 30) {
            const txt = `${d.slice(0, 30)}...`;
            return `<a href="#" class="remark-report" title="${d}">${txt}</a>`;
          }
          return `<a href="#" class="remark-report" title="${d}">${d}</a>`;
        }
        return '<center><a class="btn btn-icon btn-info remark-report"><span class="icon icon-edit sq-24"></span></a></center>';
      },
    },
    { data: 'createdBy', render: (d, t, r) => (d ? r.creatorFullName : '') },
    { data: 'requestorName', render: (d, t, r) => (d ? r.requestorFullName : '') },

    { data: 'issueDateStr', className: 'text-center', type: 'date-black' },
    {
      data: 'expireDate',
      render: (d, t, r) => {
        if (r.termEndType === 'Open End') return '';
        if (!d) return '';

        const diff = moment().diff(moment(d), 'days');

        if (diff === 0) return `<span class="text-success2">${toNumber(diff, 0)}</span>`;
        if (diff > 0) return `<span class="text-danger2">+ ${toNumber(diff, 0)}</span>`;
        return `<span class="text-success2">- ${toNumber(Math.abs(diff))}</span>`;
      },
    },
    {
      data: 'projectName',
      // render: (d) => {
      //     return `<div class='td-project-name'>${d}</div>`
      // }
      render: (d) => {
        let text = '';
        const l = 60;
        for (let i = 0; i < d.length / l; i++) {
          const start = i * l; // 0 20
          const end = i * l + l; // 20 40
          text += `<p class='p-margin'>${d.slice(start, end)}</p>`;
        }
        return text;
      },
    },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalDataRef,
  modalData,
  onChangeModal,
  onClickSubmitModal,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Report <span className="icon icon-angle-double-right" /> Bank Guarantee Management
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group">
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroup}
                name="FinanceGroup"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnit}
                name="BusinessUnit"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Issuer">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.CompanyCode}
                name="CompanyCode"
                country="TH"
                isGetAll
                financeGroupId={criteria.FinanceGroup}
                businessUnitId={criteria.BusinessUnit}
              />
            </FormGroup2>

            <FormGroup2 text="Company (BG Line)">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.CompanyBgLine}
                name="CompanyBgLine"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Issuing Bank">
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                onChange={onChangeSelect2}
                value={criteria.BankCode}
                name="BankCode"
                saveLocalState
                isOutFlow
                isGetAll
                companyCode={criteria.CompanyCode}
              />
            </FormGroup2>

            <FormGroup2 text="Currency">
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={(e) =>
                  onChangeSearch({
                    target: e,
                  })
                }
                // isChoose={true}
                notMultipleSelect2
                isChoose
                value={criteria.Currency}
                filter={(m) =>
                  ~['thb', 'usd', 'cny', 'eur', 'gbp', 'sgd', 'jpy', 'hkd', 'chf', 'sek'].indexOf(
                    (m.currency || '').toLowerCase()
                  )
                }
                name="Currency"
                saveLocalState
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Effective Date">
              <DateRangePicker
                onChange={onChangeSearch}
                value={criteria.EffectiveDateStr}
                name="EffectiveDateStr"
              />
            </FormGroup2>

            <FormGroup2 text="Beneficiary Name (TH/EN)">
              <MasterDropdown
                masterType={GET_BG_BENEFICIARY}
                onChange={onChangeSelect2}
                value={criteria.BeneficiaryId}
                name="BeneficiaryId"
                saveLocalState
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Form Type">
              <MasterDropdown
                masterType={GET_BG_LG_TYPE}
                onChange={onChangeSelect2}
                value={criteria.LgTypeId}
                name="LgTypeId"
                saveLocalState
              />
            </FormGroup2>

            <FormGroup2 text="LG Status">
              <MasterDropdownUI
                value={criteria.StatusLg}
                onChange={onChangeSearch}
                notMultiple
                isAll
                name="StatusLg"
                options={['Active', 'Inactive']}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="BG No.">
              <input
                className="form-control"
                onChange={onChangeSearch}
                value={criteria.TransactionNo}
                name="TransactionNo"
              />
            </FormGroup2>

            <FormGroup2 text="Amount">
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeSearch}
                option={{
                  prefix: '',
                  digits: 2,
                }}
                value={criteria.Amount}
                name="Amount"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="&nbsp;">
              <div>
                <input
                  id="IsExpired"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeSearch}
                  value={criteria.IsExpired}
                  name="IsExpired"
                  checked={criteria.IsExpired}
                />
                <label htmlFor="IsExpired" className="label_checkbox" />
                <label htmlFor="IsExpired" className="control-label text_label_checkbox">
                  Show only expired item
                </label>
              </div>
            </FormGroup2>

            <FormGroup2 text="Issue Date">
              <DateRangePicker
                onChange={onChangeSearch}
                value={criteria.IssueDateStr}
                name="IssueDateStr"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Report as of" required>
              <DatePicker
                onChange={onChangeSearch}
                required
                value={criteria.ReportAsOfStr}
                name="ReportAsOfStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Transaction No.</th>
            <th>BG No.</th>
            <th>Issuing Bank</th>
            <th>Company (BG Line)</th>
            <th>Issuer</th>
            <th>Beneficiary Name (TH)</th>
            <th>Beneficiary Name (EN)</th>
            <th>Form Type</th>
            <th>Amount</th>
            <th>Currency</th>
            <th>Effective Date</th>
            <th>Expire Date</th>
            <th>Remark for bank</th>
            <th>Remark for finance</th>
            <th>Cost Center</th>
            <th>Internal Order</th>
            <th>Inactive Date</th>
            <th>LG Status</th>
            <th>Internal Message</th>
            <th>Creator</th>
            <th>Requester</th>
            <th>Issue Date</th>
            <th>Expire Days</th>
            <th>Project Name</th>
          </tr>
        </thead>
      </Table>
    </Card>

    <Modal size="modal-md" ref={modalDataRef} modalFooter>
      <form onSubmit={onClickSubmitModal}>
        <div className="text-center">
          <h2 className="text-info">Edit</h2>
          <br />
          <div className="row form-group">
            <label className="control-label col-md-4">Internal Message</label>
            <div className="col-md-7">
              <textarea
                className="form-control"
                rows="2"
                value={modalData.InternalMessage || ''}
                onChange={onChangeModal}
                required={false}
                name="InternalMessage"
              />
            </div>
          </div>
          <div className="m-t-lg">
            <Button txt="Confirm" className="btn-info" type="submit" />
            &nbsp;
            <Button txt="Back" className="btn-default" data-dismiss="modal" />
          </div>
        </div>
      </form>
    </Modal>
  </div>
);
