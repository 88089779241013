import { defaultImportExcelResult } from '../configuration';
import {
  EDIT_MARK_TO_MARKET_CRITERIA,
  RECEIVE_SEARCH_MARK_TO_MARKET,
  CLEAR_MARK_TO_MARKET_CRITERIA,
  UPDATE_SEARCH_RESULT_MARK_TO_MARKET,
  RECEIVE_VIEW_IMPORT_EXCEL_MTM_FORWARD,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  Banks: [''],
  Currency: [''],
  Currency1: [''],
  Currency2: [''],
  ReportDateStr: '',
  MtmType: [''],
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
  importExcel: defaultImportExcelResult,
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_VIEW_IMPORT_EXCEL_MTM_FORWARD:
      return {
        ...state,
        importExcel: {
          ...action.payload,
        },
      };
    case UPDATE_SEARCH_RESULT_MARK_TO_MARKET:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_MARK_TO_MARKET_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MARK_TO_MARKET_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_MARK_TO_MARKET:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
