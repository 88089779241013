export const initInformation = {
  FinanceGroupId: '',
  CompanyCode: '',
  IsProject: false,
  ProjectName: '',
  CalendarId: '',
  Remark: '',
};

export const initStructure = {
  LoanDescription: '',
  LoanType: 'Term',
  PartnerType: '',
  IcType: '',
  TransactionType: '',
  BusinessPartner: '',
  ContractDateStr: '',
  TermStartStr: '',
  TermEndStr: '',
  Amount: '',
  Currency: '',
  AbsorbWitholdingTax: '',
  ExternalRef: '',
  DrawdownPeriodStr: '',

  FacilityNo: '',
  Outstanding: '',
  BusinessArea: '',
  BusinessAreaPartner: '',
  Guarantor: '',
};

export const initCharge = {
  PaymentDateStr: '',
  Direction: '',
  FlowType: '',
  Amount: '',
  Currency: '',
  PaymentMethod: '',
  Source: '',
};

export const initCashFlow = {
  RepaymentBankCode: [],
  DrawdownBankCode: [],
  DrawdownList: [],
  RepaymentList: [],
};

export const initialState = {
  ...initInformation,
  ...initStructure,
  DrawdownList: [],
  CashflowDeposit: {
    ...initCashFlow,
  },
  CashflowLoan: {
    ...initCashFlow,
  },
  AllInterestStructors: [],
  RemoveInterestStructures: [],
  RemoveRepaymentStructures: [],
  RepaymentStructures: [],
  CashFlowChargeLoan: [],
  CashFlowChargeDeposit: [],
};
