import React, { Component } from 'react';

class RadioButton extends Component {
  render() {
    const { text, textTh, ...rest } = this.props;
    return (
      <label className="custom-control custom-control-primary custom-radio">
        <input className="custom-control-input" type="radio" {...rest} />
        <span className="custom-control-indicator" />
        <span className="custom-control-label">{text}</span>
        {textTh && <p className="custom-control-label">{textTh}</p>}
      </label>
    );
  }
}
export default RadioButton;
