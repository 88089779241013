import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SUBMIT_PRINT_INTEREST_RECEIPT_TYPE =
  '@@request/SUBMIT_PRINT_INTEREST_RECEIPT_TYPE';
export const RECEIVE_SUBMIT_PRINT_INTEREST_RECEIPT_TYPE =
  '@@receive-Save Success/SUBMIT_PRINT_INTEREST_RECEIPT_TYPE';
export const FAIL_SUBMIT_PRINT_INTEREST_RECEIPT_TYPE = '@@fail/SUBMIT_PRINT_INTEREST_RECEIPT_TYPE';

export const REQUEST_SEARCH_PRINT_INTEREST_RECEIPT_TYPE =
  '@@request/SEARCH_PRINT_INTEREST_RECEIPT_TYPE';
export const RECEIVE_SEARCH_PRINT_INTEREST_RECEIPT_TYPE =
  '@@receive/SEARCH_PRINT_INTEREST_RECEIPT_TYPE';
export const FAIL_SEARCH_PRINT_INTEREST_RECEIPT_TYPE = '@@fail/SEARCH_PRINT_INTEREST_RECEIPT_TYPE';

export const searchInterestReceiptType = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/PrintInterestReceiptType', criteria),
    method: 'GET',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SEARCH_PRINT_INTEREST_RECEIPT_TYPE,
      RECEIVE_SEARCH_PRINT_INTEREST_RECEIPT_TYPE,
      FAIL_SEARCH_PRINT_INTEREST_RECEIPT_TYPE,
    ],
  },
});

export const submitInterestReceiptType = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/PrintInterestReceiptType'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SUBMIT_PRINT_INTEREST_RECEIPT_TYPE,
      RECEIVE_SUBMIT_PRINT_INTEREST_RECEIPT_TYPE,
      FAIL_SUBMIT_PRINT_INTEREST_RECEIPT_TYPE,
    ],
  },
});
