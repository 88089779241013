import { connect } from 'react-redux';
import React, { Component } from 'react';

import LoanReport from './LoanReport';
import { toLower } from '../../../common/helpper';
import {
  editCriteria,
  exportReport,
  clearCriteria,
  searchDeposit,
  searchLoanReport,
} from '../../../reducers/reportLoan/action';

export const DATATABLE_ID = 'table-result-deposit-report';

class LoanReportContainer extends Component {
  state = {
    datas: [],
    results: [],
    showProductType: '',
  };

  dataTableSearchResultRef = null;

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    if (this.props.criteria.ProductType === 'Deposit') {
      this.props
        .searchDeposit({
          ...this.props.criteria,
          PartnerType: 'External',
          Status: 'Active',
          Source: 'Deposit',
          BusinessPartner: this.props.criteria.Banks,
        })
        .then((response) => {
          if (!response.error) {
            this.setState({
              ...this.state,
              showProductType: this.props.criteria.ProductType,
            });
          }
        });
    } else {
      this.props.searchLoanReport({ ...this.props.criteria }).then((response) => {
        if (!response.error) {
          this.setState({
            ...this.state,
            showProductType: this.props.criteria.ProductType,
          });
        }
      });
    }
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchResult !== this.props.searchResult) {
      if (this.props.criteria.ProductType === 'Deposit') {
        this.setState({
          ...this.state,
          results: this.props.searchResult,
        });
      } else {
        this.mapData2(this.props.searchResult);
      }
    }
  }

  mapData2(datas = []) {
    const sections = [];
    datas.forEach((m) => {
      const { companyCode, companyName } = m;
      const findGroup = sections.find((f) => f.companyCode === companyCode);

      if (!findGroup) {
        sections.push({
          companyCode,
          companyName,
          items: [m],
          facilityGroup: [],
          results: [],
        });
      } else {
        findGroup.items.push(m);
      }
    });

    sections.forEach((c) => {
      const groups = [];
      c.items.forEach((m) => {
        const { businessPartner, facilityNo, currency, creditLine, digit, remark } = m;

        const findGroup = groups.find(
          (f) =>
            f.businessPartner === businessPartner &&
            f.facilityNo === facilityNo &&
            f.currency === currency
        );

        if (!findGroup) {
          groups.push({
            businessPartner,
            facilityNo,
            creditLine,
            currency,
            digit,
            remark,
            items: [m],
          });
        } else {
          findGroup.items.push(m);
        }
      });
      c.facilityGroup = groups;
    });

    sections.forEach((c) => {
      c.facilityGroup.forEach((f) => {
        const { items } = f;

        const result = [];

        const onCallList = items.filter((f) => toLower(f.loanType) === 'on call');
        if (onCallList.length) {
          let sumAmount = 0;
          let sumAmountInterest = 0;
          let beginDateStr = '';
          let exchangeRate = '';
          let currency = '';

          const i = 0;
          onCallList.forEach((m) => {
            sumAmount += m.amount;
            sumAmountInterest += m.amountInterest;

            if (i === 0) {
              beginDateStr = m.termStartStr;
              exchangeRate = m.exchangeRate;
              currency = m.currency;
            }
          });

          const allInRate = sumAmountInterest / sumAmount;
          const foreign = toLower(currency) === 'thb' ? '' : sumAmount;
          const thb = toLower(currency) === 'thb' ? sumAmount : sumAmount * exchangeRate;

          const m = onCallList[0];

          result.push({
            businessPartner: m.businessPartner,
            facilityNo: m.facilityNo,
            creditLine: m.creditLine,
            amount: toLower(currency) === 'thb' ? sumAmount : '',
            outstandingThb: thb,
            outstandingForeign: foreign,
            currency: m.currency,
            digit: m.digit,
            allInRate,
            term: m.loanType,
            beginDateStr,
            dueDateStr: '',
            remark: m.remark,
          });
        }

        const termList = items.filter((f) => toLower(f.loanType) !== 'on call');
        termList.forEach((m) => {
          const { currency, amount, exchangeRate } = m;

          const foreign = toLower(currency) === 'thb' ? '' : amount;
          const thb = toLower(currency) === 'thb' ? amount : amount * exchangeRate;

          result.push({
            businessPartner: m.businessPartner,
            facilityNo: m.facilityNo,
            creditLine: m.creditLine,
            amount: toLower(currency) === 'thb' ? amount : '',
            outstandingThb: thb,
            outstandingForeign: foreign,
            currency: m.currency,
            digit: m.digit,
            allInRate: m.interestRate,
            term: m.loanType,
            beginDateStr: m.termStartStr,
            dueDateStr: m.termEndStr,
            remark: m.remark,

            // contract loan
            exchangeRate: m.exchangeRate,
            amountUsd: toLower(m.currency) === 'usd' ? m.amount : '',
            amountJpy: toLower(m.currency) === 'jpy' ? m.amount : '',
            amountEur: toLower(m.currency) === 'eur' ? m.amount : '',
            amountOther: ~['thb', 'usd', 'eur', 'jpy'].indexOf(toLower(m.currency)) ? '' : m.amount,
            repaymentDateStr: m.repaymentDateStr,
            repaymentAmount: m.repaymentAmount,
            effectiveRate: m.effectiveRate,
            loanMaturityStr: m.loanMaturityStr,
            guarantor: m.guarantee,
          });
        });

        f.results = result.sort((a, b) => a.allInRate - b.allInRate);
        f.minRate = f.results[0].allInRate;
      });
    });

    sections.forEach((c) => {
      c.facilityGroup.sort((a, b) => (a.minRate || 99) - (b.minRate || 99));

      const results = [];
      let totalFacility = 0;
      let totalThb = 0;
      let totalAmountInterest = 0;
      let totalEffectiveRate = 0;

      c.facilityGroup.forEach((f) => {
        let i = 0;
        f.results.forEach((m) => {
          totalThb += m.outstandingThb;
          totalAmountInterest += m.outstandingThb * m.allInRate;
          totalEffectiveRate += m.outstandingThb * (m.effectiveRate || m.allInRate);
          if (i === 0) {
            if (toLower(m.remark) === 'not show facility in loan report') {
              m.creditLine = '';
            } else {
              totalFacility += m.creditLine;
            }

            results.push(m);
          } else {
            m.businessPartner = '';
            m.creditLine = '';
            results.push(m);
          }
          i++;
        });
      });

      c.results = results;
      c.total = {
        totalFacility,
        totalThb,
        totalAllInRate: totalAmountInterest / totalThb,
        totalEffectiveRate: totalEffectiveRate / totalThb,
      };
    });

    // console.log(sections)
    this.setState({
      ...this.state,
      datas: sections,
    });
  }

  onClickDownloadReportExcel = () => {
    const criteria = { ...this.props.criteria };
    if (!criteria.ProductType) {
      alert('Please select Product type');
      return;
    }
    if (!criteria.ValueDateStr) {
      alert('Please select valuedate');
      return;
    }

    this.props.exportReport(criteria);
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      onClickDownloadReportExcel: this.onClickDownloadReportExcel,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
    };

    return (
      <div>
        <LoanReport {...props} {...this.state} criteria={this.props.criteria} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.reportLoan,
  }),
  {
    searchLoanReport,
    editCriteria,
    clearCriteria,
    exportReport,
    searchDeposit,
  }
)(LoanReportContainer);
