import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import { DATATABLE_ID } from './MtmSwapContainer';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import { toNumber, getPermission } from '../../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('Funding&Investment', 'MTM Swap');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  columns: [
    { data: 'swiftCode' },
    { data: 'forwardNo' },
    { data: 'reportDateStr', className: 'text-center', type: 'date-black' },
    { data: 'contractNo' },
    { data: 'productGroup' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'netMtmAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.mtmCurrencyDigit),
    },
    { data: 'netMtmCurrency', className: 'text-center' },
    {
      data: 'reversSign',
      className: 'text-center',
      render: (d) => (d ? '<i class="text-success icon icon-check icon-lg icon-fw"></i>' : ''),
    },
    { data: 'fileName', className: 'text-center' },
    {
      data: 'fileName',
      className: 'text-center',
      render: (d) =>
        '<button class="btn btn-info btn-sm download"><i class="icon icon-download icon-fw icon-lg"></i></button>',
    },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,
  onClickFetchSwap,
  results,
  dataTableRef,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          FX Management <span className="icon icon-angle-double-right" /> Mark To Market Swap
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group">
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroups}
                name="FinanceGroups"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companies}
                saveLocalState
                financeGroupId={criteria.FinanceGroups}
                businessUnitId={criteria.BusinessUnits}
                name="Companies"
              />
            </FormGroup2>

            <FormGroup2 text="Bank">
              <MasterDropdown
                masterType={MASTER_BANK}
                onChange={onChangeSelect2}
                value={criteria.Banks}
                // companyCode={criteria.Companies}
                name="Banks"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Currency">
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.Currency}
                name="Currency"
              />
            </FormGroup2>

            <FormGroup2 text="Report Date" required={false}>
              <DateRangePicker
                onChange={onChangeSearch}
                required={false}
                value={criteria.ReportDateStr}
                name="ReportDateStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      {permission ? (
        <div className="row">
          <div className="col-sm-12">
            <Button txt="Fetch Swap" icon="undo" className="btn-info" onClick={onClickFetchSwap} />
          </div>
        </div>
      ) : null}

      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Bank Swift Code</th>
            <th>Transaction No</th>
            <th>Report Date</th>
            <th>Contract No.</th>
            <th>Product Group</th>
            <th>Finance Group</th>
            <th>Business Unit</th>
            <th>
              Company
              <br /> Code
            </th>
            <th>Company Name</th>
            <th className="text-center">Net MTM Amount</th>
            <th>
              Net MTM
              <br /> Currency
            </th>
            <th>Validation</th>
            <th>File Name</th>
            <th>Download</th>
            <th>
              Created
              <br /> Date
            </th>
          </tr>
        </thead>
      </Table>
    </Card>
  </div>
);
