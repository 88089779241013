import React from 'react';

export default ({ text, required, children, styleDisplay, length, maxLength, textTh }) => (
  <div className="form-group" style={styleDisplay}>
    <label className={text === '&nbsp;' ? 'control-label hidden-xs hidden-sm' : 'control-label'}>
      {text === '&nbsp;' ? '\u00A0' : text}
      {required ? <span style={{ color: '#e64a19' }}>*</span> : null}
    </label>
    {textTh ? (
      <p className={textTh === '&nbsp;' ? 'control-label hidden-xs hidden-sm' : 'control-label'}>
        <strong>{textTh === '&nbsp;' ? '\u00A0' : textTh}</strong>
        {maxLength ? (
          <span style={{ float: 'right' }}>
            ({length}/{maxLength})
          </span>
        ) : null}
      </p>
    ) : (
      <>
        {maxLength ? (
          <span style={{ float: 'right' }}>
            ({length}/{maxLength})
          </span>
        ) : null}
      </>
    )}

    <div>{children}</div>
  </div>
);
