import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Toggle from '../../common/Toggle';
import Table from '../../common/DataTable';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import MasterDropdown from '../../common/MasterDropdown';
import ButtonDropdown from '../../common/ButtonDropdown';
import ModalFunction2 from '../../common/ModalFunction2';
import DateRangePicker from '../../common/DateRangePicker';
import { DATATABLE_ID } from './BankTransferSummaryContainer';
import MasterDropdownUI, {
  MASTER_SENDING_TYPE,
  MASTER_TRANSFER_METHOD,
} from '../../common/MasterDropdownUI';
import {
  toLower,
  toNumber,
  toStatus,
  toAccount,
  getPermission,
  toStatusApprove,
} from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_APPROVER,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX2 = ['col-md-5', 'col-md-6'];
const required = true;
const approverTypes = ['Approver1', 'Approver2', 'Reviewer'];
const permission = getPermission('Cash Management', 'Bank Transfer Summary');

const opt = {
  order: [[3, 'asc']],
  fixedColumns: {
    leftColumns: 4,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      data: 'IsSelected',
      className: 'text-center col-label-checkbox',
      render: (d, t, r) => {
        // if (toLower(r.transactionStatus) === 'deleted') return ''
        // if (toLower(r.transactionStatus) === 'completed' &&
        //     (toLower(r.transferMethod) === 'auto deduct' || toLower(r.transferMethod) === 'internet banking' ||
        //     (toLower(r.transferMethod) === 'bahtnet' &&
        //     (toLower(r.sendingType) === 'paper' || toLower(r.sendingType) === 'paper with signature')))) {
        //         r.canSelect = true;
        //         return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`
        // }
        // if (!checkAfterValueDate(r.valueDateStr)) {
        //     r.canSelect = false;
        //     return ''
        // }
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsEdit',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (toLower(r.transactionStatus) !== 'create') return '';
        return '<button class="btn btn-icon btn-primary edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    {
      orderable: false,
      data: 'IsPrint',
      className: 'text-center',
      render: (d, t, r) => {
        // if (toLower(r.transferMethod) === 'bahtnet' && toLower(r.sendingType) === 'paper') {
        //     if (toLower(r.transactionStatus) === 'approved' || toLower(r.transactionStatus) === 'completed')
        //         return '<button class="btn btn-icon btn-info print" data-toggle="popover"><span class="icon icon-print sq-24"></span></button>'
        //     return '<button class="btn btn-icon btn-info" disabled="disabled"><span class="icon icon-print sq-24"></span></button>'
        // }
        // else if (toLower(r.transferMethod) === 'bahtnet' && toLower(r.sendingType) === 'sftp') {
        //     if (toLower(r.transactionStatus) === 'create' || toLower(r.transactionStatus) === 'rejected by bank')
        //         return '<button class="btn btn-icon btn-info" disabled="disabled"><span class="icon icon-print sq-24"></span></button>'
        //     return '<button class="btn btn-icon btn-info print" data-toggle="popover"><span class="icon icon-print sq-24"></span></button>'
        // }
        if (!permission) return '';
        if (toLower(r.transferMethod) === 'bahtnet')
          return '<button class="btn btn-icon btn-info print" data-toggle="popover"><span class="icon icon-print sq-24"></span></button>';
        return '';
      },
    },
    { data: 'transactionNo' },
    { data: 'valueDateStr', className: 'text-center td-width-date', type: 'date-black' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'sendingType' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'applicantBankAccountNo',
      render: (d, r, s) =>
        toAccount(
          s.applicantBankAbbreviate,
          s.applicantBranchName,
          s.applicantAccountTypeCode,
          s.applicantBankCurrency,
          d
        ),
    },
    { data: 'applicantFeeBy', className: 'text-center' },
    {
      data: 'applicantFeeAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.applicantCurrencyDigit, r.IsMillion),
    },
    {
      data: 'beneficiaryCompanyCode',
      className: 'text-center',
      render: (d, r, s) => {
        if (s.beneficiaryBankAccountNoOfBank) return s.beneficiaryBankCode;
        return d;
      },
    },
    {
      data: 'beneficiaryCompanyName',
      render: (d, r, s) => {
        if (s.beneficiaryBankAccountNoOfBank) return s.beneficiaryBankName;
        return d;
      },
    },
    {
      data: 'beneficiaryBankAccountNo',
      render: (d, r, s) =>
        toAccount(
          s.beneficiaryBankAbbreviate,
          s.beneficiaryBranchName,
          s.beneficiaryAccountTypeCode,
          s.beneficiaryBankCurrency,
          d,
          s.beneficiaryBankAccountNoOfBank
        ),
    },
    { data: 'beneficiaryFeeBy', className: 'text-center' },
    {
      data: 'beneficiaryFeeAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.beneficiaryCurrencyDigit, r.IsMillion),
    },
    { data: 'isTransferSameRegion', render: (d) => (d ? 'Same Region' : 'Different Region') },
    { data: 'transferMethod', className: 'td-width-date' },
    {
      data: 'amount',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
    },
    { data: 'currency', className: 'text-center' },
    { data: 'transactionStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    { data: 'postTransactionStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    {
      data: 'chequeNo',
      className: 'text-center',
      render: (d, r, s) => {
        if (!permission) {
          if (d) return d;
          return '';
        }
        if (
          (toLower(s.transactionStatus) === 'completed' ||
            toLower(s.transactionStatus) === 'already send to bank' ||
            toLower(s.transactionStatus) === 'exported') &&
          toLower(s.transferMethod) === 'cheque'
        ) {
          if (d) return `<button class="btn btn-info-reverse edit-cheque">${d}</button>`;
          return '<button class="btn btn-icon btn-info edit-cheque"><span class="icon icon-edit sq-24"></span></button>';
        }
        return d;
      },
    },
    {
      data: 'approverName1',
      render: (d, t, r) => {
        if (r.approver1Status && toLower(r.approver1Status) !== 'wait for approve') return d;
        if (r.approver1) return `<a href="javascript:;" class="change-approver-1">${d}</a>`;
        return '';
      },
    },
    { data: 'approver1Status', render: (d, t, r) => toStatusApprove(r.approverName1, d) },
    {
      data: 'approverName2',
      render: (d, t, r) => {
        if (r.approver2Status && toLower(r.approver2Status) !== 'wait for approve') return d;
        if (r.approver2) return `<a href="javascript:;" class="change-approver-2">${d}</a>`;
        return '';
      },
    },
    { data: 'approver2Status', render: (d, t, r) => toStatusApprove(r.approverName2, d) },
    {
      data: 'reviewerName',
      render: (d, t, r) => {
        if (r.reviewerStatus && toLower(r.reviewerStatus) !== 'wait for approve') return d;
        if (r.reviewerName) return `<a href="javascript:;" class="change-reviewer">${d}</a>`;
        return '';
      },
    },
    { data: 'reviewerStatus', render: (d, t, r) => toStatusApprove(r.reviewer, d) },
    { data: 'errorMessage' },
  ],
};

export default ({
  criteria,
  results,
  dataTableRef,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,
  onClickUpload,

  modalEditRef,
  modalEdit,
  onChangeModalEdit,
  onClickSaveModalEdit,

  modalApprove,
  onChangeModalApprove,

  modalConfirmRef,
  onClickOpenModalConfirm,
  onClickModalSubmitChangeStatus,

  onClickOpenModalChangeStatus,

  modalChangeStatusData,
  modalChangeStatusRef,

  modalGetChqeueRef,
  onClickGetCheque,
  onChangeModalGetCheque,
  modalGetCheque,
  onSubmitGetCheque,

  isMillion,
  onChangeToggleMillion,

  modalEditCheque,
  modalEditChequeRef,
  onClickOpenModalEditCheque,
  onChangeModalEditCheque,
  onSubmitModalEditCheque,

  modalChangeRef,
  modalChangeApprover,
  onChangeModalApproveChange,
  onSubmitModalChangeApprover,

  onClickOpenModalChangeApproverMultiTransaction,
  modalChangeApproverMultiTransRef,
  modalChangeApproverMultiTrans,
  onSubmitChangeApproverMultiTrans,
  onChangeModalApproverMultiTrans,

  modalCheckUnconfirmRef,
  onSubmitCheckUnconfirm,
}) => {
  if (modalEdit.TransferMethod !== 'Bahtnet') modalEdit.SendingType = '';

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Cash Management <span className="icon icon-angle-double-right" /> Bank Transfer Summary
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={criteria.FinanceGroupId}
                  onChange={onChangeSelect2}
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  value={criteria.BusinessUnitId}
                  onChange={onChangeSelect2}
                  name="BusinessUnitId"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  value={criteria.CompanyCode}
                  onChange={onChangeSelect2}
                  name="CompanyCode"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroupId}
                  businessUnitId={criteria.BusinessUnitId}
                />
              </FormGroup2>

              <FormGroup2 text="Value Date" required>
                <DateRangePicker
                  startDate={window.moment().add(0, 'days')}
                  endDate={window.moment().add(0, 'days')}
                  onChange={onChangeSearch}
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                  required={required}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  value={criteria.Currency}
                  onChange={onChangeSelect2}
                  name="Currency"
                />
              </FormGroup2>

              <FormGroup2 text="Applicant Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  value={criteria.ApplicantBankAbbrivate}
                  saveLocalState
                  companyCode={criteria.CompanyCode}
                  onChange={onChangeSelect2}
                  name="ApplicantBankAbbrivate"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Beneficiary Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  value={criteria.BeneficiaryBankAbbrivate}
                  saveLocalState
                  companyCode={criteria.CompanyCode}
                  onChange={onChangeSelect2}
                  name="BeneficiaryBankAbbrivate"
                />
              </FormGroup2>
              <FormGroup2 text="Transfer Method">
                <MasterDropdownUI
                  value={criteria.TransferMethod}
                  onChange={onChangeSelect2}
                  name="TransferMethod"
                  options={['Bahtnet', 'Cheque', 'Auto Deduct', 'Internet Banking', 'Sweep']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Source">
                <MasterDropdownUI
                  value={criteria.Source}
                  onChange={onChangeSelect2}
                  name="Source"
                  options={[
                    'Manual Transfer',
                    'Auto Transfer',
                    'Sweep',
                    'Auto Created Bank Transfer',
                  ]}
                />
              </FormGroup2>
              <FormGroup2 text="Sending Type">
                <MasterDropdownUI
                  masterType={MASTER_SENDING_TYPE}
                  value={criteria.SendingType}
                  onChange={onChangeSelect2}
                  name="SendingType"
                  options={['SFTP', 'Paper', 'Paper with Signature', 'N/A']}
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Post Transaction Status">
                <MasterDropdownUI
                  value={criteria.PostTransactionStatus}
                  onChange={onChangeSelect2}
                  name="PostTransactionStatus"
                  options={['In Progress', 'Failed', 'Completed', 'No Post']}
                />
              </FormGroup2>

              <FormGroup2 text="Transaction Status">
                <MasterDropdownUI
                  value={criteria.TransactionStatus}
                  onChange={onChangeSelect2}
                  name="TransactionStatus"
                  options={[
                    'Create',
                    'Wait for approve',
                    'Approved',
                    'Wait for bank receive',
                    'Accepted by bank',
                    'Rejected by bank',
                    'Already send to bank',
                    'Wait for generate file',
                    'Completed',
                    'Uncompleted',
                    'Exported',
                    'Voided',
                    'Disapproved',
                    'Deleted',
                    'Failed',
                    'Cancelled',
                    'Rejected',
                  ]}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Approver">
                <input
                  className="form-control"
                  value={criteria.ApproveName}
                  onChange={onChangeSearch}
                  name="ApproveName"
                />
              </FormGroup2>

              <div className="form-group">
                <div>
                  <input
                    id="IsApprove"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeSearch}
                    value={criteria.IsApprove}
                    name="IsApprove"
                    checked={criteria.IsApprove}
                  />
                  <label htmlFor="IsApprove" className="label_checkbox" />
                  <label htmlFor="IsApprove" className="control-label text_label_checkbox">
                    Approver
                  </label>
                </div>
              </div>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div>
              <Button
                txt="CONFIRM"
                icon="check"
                className="btn-info"
                onClick={onClickOpenModalConfirm}
              />
              &nbsp;
              <Button
                txt="UNCONFIRM"
                icon="refresh"
                className="btn-warning"
                onClick={(e) => onClickOpenModalChangeStatus('unconfirm')}
              />
              &nbsp;
              <ButtonDropdown
                className="btn-primary"
                text="Complete Status"
                icon="icon-star"
                buttons={[
                  {
                    text: 'Complete',
                    icon: 'icon-star',
                    onClick: (e) => onClickOpenModalChangeStatus('complete'),
                  },
                  {
                    text: 'Uncomplete',
                    icon: 'icon-close',
                    onClick: (e) => onClickOpenModalChangeStatus('uncomplete'),
                  },
                ]}
              />
              &nbsp;
              <ButtonDropdown
                className="btn-blank"
                text="Cheque"
                icon="icon-credit-card-alt"
                buttons={[
                  {
                    text: 'Send Email to Bank',
                    icon: 'icon-envelope-o',
                    onClick: (e) => onClickOpenModalChangeStatus('send email to bank'),
                  },
                  {
                    text: 'Upload Cheque',
                    icon: 'icon-upload',
                    onClick: onClickUpload,
                  },
                  {
                    text: 'Export Cheque',
                    icon: 'icon-credit-card-alt',
                    onClick: (e) => onClickOpenModalChangeStatus('export cheque'),
                  },
                  {
                    text: 'Get Cheque',
                    icon: 'icon-download',
                    onClick: onSubmitGetCheque,
                  },
                  {
                    text: 'Void Cheque',
                    icon: 'icon-refresh',
                    onClick: (e) => onClickOpenModalChangeStatus('void cheque'),
                  },
                ]}
              />
              &nbsp;
              <Button
                txt="CANCEL BAHTNET"
                icon="close"
                className="btn-default"
                onClick={(e) => onClickOpenModalChangeStatus('cancel bahtnet')}
                disabled
              />
              &nbsp;
              <Button
                txt="DELETE"
                icon="trash-o"
                className="btn-danger"
                onClick={(e) => onClickOpenModalChangeStatus('delete')}
              />
              &nbsp;
              <Button
                txt="Change Approver"
                icon="edit"
                className="btn-success"
                onClick={onClickOpenModalChangeApproverMultiTransaction}
              />
            </div>
          </Col2>
        ) : null}

        <Col2 col={colX[2]}>
          <div className="toggle-million">
            <span>
              <b>Million</b>{' '}
              <Toggle name="isMillion" checked={isMillion} onChange={onChangeToggleMillion} />
            </span>
          </div>
        </Col2>

        <Table
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
          ref={dataTableRef}
          onClickContext={[
            {
              context: 'button.edit-cheque',
              onClick: onClickOpenModalEditCheque,
            },
          ]}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>Edit</th>
              <th>Print</th>
              <th>Transaction No.</th>
              <th>Value Date</th>
              <th>
                Finance
                <br />
                Group
              </th>
              <th>
                Business
                <br />
                Unit
              </th>
              <th>Sending Type</th>
              <th style={{ backgroundColor: '#5C9BD1', color: '#fff' }}>
                Applicant
                <br />
                Company Code
              </th>
              <th style={{ backgroundColor: '#5C9BD1', color: '#fff' }}>
                Applicant
                <br />
                Company Name
              </th>
              <th style={{ backgroundColor: '#5C9BD1', color: '#fff' }}>
                Applicant
                <br />
                Bank Account
              </th>
              <th className="text-center" style={{ backgroundColor: '#5C9BD1', color: '#fff' }}>
                Applicant
                <br />
                Charge
              </th>
              <th className="text-center" style={{ backgroundColor: '#5C9BD1', color: '#fff' }}>
                Applicant
                <br />
                Charge Amount
              </th>
              <th style={{ backgroundColor: '#3FABA4', color: '#fff' }}>
                Beneficiary
                <br />
                Company Code
              </th>
              <th style={{ backgroundColor: '#3FABA4', color: '#fff' }}>
                Beneficiary
                <br />
                Company Name
              </th>
              <th style={{ backgroundColor: '#3FABA4', color: '#fff' }}>
                Beneficiary
                <br />
                Bank Account
              </th>
              <th className="text-center" style={{ backgroundColor: '#3FABA4', color: '#fff' }}>
                Beneficiary
                <br />
                Charge
              </th>
              <th className="text-center" style={{ backgroundColor: '#3FABA4', color: '#fff' }}>
                Beneficiary
                <br />
                Charge Amount
              </th>
              <th>Region</th>
              <th>
                Transfer
                <br />
                Method
              </th>
              <th className="text-center">Amount</th>
              <th>Currency</th>
              <th>
                Transaction
                <br />
                Status
              </th>
              <th>
                Post Transaction
                <br />
                Status
              </th>
              <th>
                Cheque
                <br />
                No.
              </th>
              <th>
                Assign
                <br />
                Approver 1
              </th>
              <th>Status</th>
              <th>
                Assign
                <br />
                Approver 2
              </th>
              <th>Status</th>
              <th>Reviewer</th>
              <th>Status</th>
              <th>
                Error
                <br />
                Message
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-sm" ref={modalEditChequeRef} modalFooter>
        <form onSubmit={onSubmitModalEditCheque}>
          <Col2 col={colX[2]}>
            <FormGroup2 text="Cheque No." required>
              <input
                className="form-control"
                required
                onChange={onChangeModalEditCheque}
                name="ChequeNo"
                value={modalEditCheque.ChequeNo || ''}
              />
            </FormGroup2>
          </Col2>
          <br />
          <div className="row">
            <center>
              <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>

      <Modal size="modal-sm" ref={modalEditRef}>
        <form onSubmit={onClickSaveModalEdit}>
          <Col2 col={colX[2]}>
            <FormGroup2 text="Transfer Method" required>
              <MasterDropdownUI
                masterType={MASTER_TRANSFER_METHOD}
                notMultiple
                isChoose
                value={modalEdit.TransferMethod}
                onChange={onChangeModalEdit}
                name="TransferMethod"
                required={required}
                options={null}
              />
            </FormGroup2>
          </Col2>
          {modalEdit.TransferMethod === 'Bahtnet' ? (
            <Col2 col={colX[2]}>
              <FormGroup2 text="Sending Type" required>
                <MasterDropdownUI
                  masterType={MASTER_SENDING_TYPE}
                  notMultiple
                  isChoose
                  value={modalEdit.SendingType}
                  onChange={onChangeModalEdit}
                  name="SendingType"
                  required={required}
                  options={null}
                />
              </FormGroup2>
            </Col2>
          ) : null}
          <br />
          <div className="row">
            <center>
              <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalConfirmRef} modalFooter>
        <form onSubmit={(e) => onClickModalSubmitChangeStatus(e)} className="form-horizontal">
          <div className="text-center">
            <span className="text-success icon icon-check icon-5x" />
            <h3 className="text-success">Approve</h3>
            <p>{modalChangeStatusData.selectedLength} item(s) selected, click to approve?</p>
            <div className="text-left">
              <div className="form-group col-xs-12">
                <div className="col-xs-3 text-right">
                  <span className="text-success icon icon-check icon-1x" />
                </div>
                <div className="col-xs-9">
                  assign approver : {modalChangeStatusData.assignApproverLength} item(s) selected.
                </div>
              </div>
              <div className="form-group col-xs-12">
                <div className="col-xs-3 text-right">
                  <span className="text-success icon icon-check icon-1x" />
                </div>
                <div className="col-xs-9">
                  approve : {modalChangeStatusData.approveLenght} item(s) selected.
                </div>
              </div>
              {modalChangeStatusData.resultFail.map((m, i) => (
                <div className="form-group col-xs-12" key={i}>
                  <div className="col-xs-3 text-right">
                    <span className="text-danger icon icon-close icon-1x" />
                  </div>
                  <div className="col-xs-9">fail {m}</div>
                </div>
              ))}
            </div>
            <br />
            {modalApprove.setReviewer ? (
              <FormGroup3 text="Reviewer" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  isChoose
                  notMultipleSelect2
                  required
                  filter={(m) => m.roleName === 'Reviewer'}
                  financeGroupId={modalApprove.FinanceGroupId}
                  value={modalApprove.Reviewer}
                  onChange={(e) =>
                    onChangeModalApprove({
                      target: e,
                    })
                  }
                  name="Reviewer"
                />
              </FormGroup3>
            ) : null}
            <FormGroup3 text="Assign Approver 1" col={colX2} required>
              <MasterDropdown
                masterType={MASTER_APPROVER}
                key="approver1"
                isChoose
                notMultipleSelect2
                required
                filter={(m) => m.roleName === 'Approver 1'}
                financeGroupId={modalApprove.FinanceGroupId}
                value={modalApprove.Approver1}
                onChange={(e) =>
                  onChangeModalApprove({
                    target: e,
                  })
                }
                name="Approver1"
              />
            </FormGroup3>
            <FormGroup3 text="Assign Approver 2" col={colX2} required>
              <MasterDropdown
                masterType={MASTER_APPROVER}
                key="approver2"
                isChoose
                notMultipleSelect2
                required
                filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                financeGroupId={modalApprove.FinanceGroupId}
                value={modalApprove.Approver2}
                onChange={(e) =>
                  onChangeModalApprove({
                    target: e,
                  })
                }
                name="Approver2"
              />
            </FormGroup3>

            <div className="m-t-lg">
              <Button txt="Approve" className="btn-success" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal textHeader="Get Cheque" bgHeader="bg-info" ref={modalGetChqeueRef}>
        <form onSubmit={onSubmitGetCheque} className="form-horizontal">
          <div className="form-group">
            <label className="control-label col-sm-6 col-md-4">Value Date</label>
            <div className="col-sm-6 col-md-8">
              <DatePicker
                onChange={onChangeModalGetCheque}
                name="ValueDate"
                value={modalGetCheque.ValueDate}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-right">
              <Button txt="Get Cheque" icon="save" type="submit" className="btn-success" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction2
        modalRef={modalChangeStatusRef}
        icon={modalChangeStatusData.icon}
        type={modalChangeStatusData.type}
        textHeader={modalChangeStatusData.textHeader}
        textContent={modalChangeStatusData.textContent}
        textBtn={modalChangeStatusData.textBtn}
        textBtnBack={modalChangeStatusData.textBtnBack}
        selectedLength={modalChangeStatusData.selectedLength}
        passLength={modalChangeStatusData.passLength}
        showFail={modalChangeStatusData.showFail}
        noSelect={modalChangeStatusData.noSelect}
        result={modalChangeStatusData.resultFail}
        onClick={(e) => onClickModalSubmitChangeStatus(e)}
      />

      <Modal size="modal-md" ref={modalCheckUnconfirmRef} modalFooter>
        <form onSubmit={(e) => onSubmitCheckUnconfirm(e)} className="form-horizontal">
          <div className="text-center">
            <span className="text-warning icon icon-warning icon-5x" />
            <h3 className="text-warning">Warning</h3>
            <br />
            <span>
              Please contact the bank to check transaction’s status before proceeding further.
            </span>
            <div className="m-t-lg">
              <Button txt="Continue" className="btn-warning" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalChangeRef} modalFooter>
        <form onSubmit={(e) => onSubmitModalChangeApprover(e)} className="form-horizontal">
          <div className="text-center">
            <span className="text-success icon icon-check icon-5x" />
            <h3 className="text-success">
              Change {modalChangeApprover.Action === 'reviewer' ? 'Reviewer' : 'Approver'}
            </h3>
            <br />
            {modalChangeApprover.Action === 'approver1' ? (
              <FormGroup3 text="Assign Approver 1" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  key="changeapprover1"
                  isChoose
                  notMultipleSelect2
                  required
                  saveLocalState
                  noValidateOption
                  filter={(m) => m.roleName === 'Approver 1'}
                  financeGroupId={modalChangeApprover.FinanceGroupId}
                  value={modalChangeApprover.Approver1}
                  onChange={(e) =>
                    onChangeModalApproveChange({
                      target: e,
                    })
                  }
                  name="Approver1"
                />
              </FormGroup3>
            ) : null}
            {modalChangeApprover.Action === 'approver2' ? (
              <FormGroup3 text="Assign Approver 2" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  key="changeapprover2"
                  isChoose
                  notMultipleSelect2
                  required
                  saveLocalState
                  noValidateOption
                  filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                  financeGroupId={modalChangeApprover.FinanceGroupId}
                  value={modalChangeApprover.Approver2}
                  onChange={(e) =>
                    onChangeModalApproveChange({
                      target: e,
                    })
                  }
                  name="Approver2"
                />
              </FormGroup3>
            ) : null}
            {modalChangeApprover.Action === 'reviewer' ? (
              <FormGroup3 text="Reviewer" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  key="changereviewer"
                  isChoose
                  notMultipleSelect2
                  required
                  saveLocalState
                  noValidateOption
                  filter={(m) => m.roleName === 'Reviewer'}
                  financeGroupId={modalChangeApprover.FinanceGroupId}
                  value={modalChangeApprover.Reviewer}
                  onChange={(e) =>
                    onChangeModalApproveChange({
                      target: e,
                    })
                  }
                  name="Reviewer"
                />
              </FormGroup3>
            ) : null}
            <div className="m-t-lg">
              <Button txt="Approve" className="btn-success" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      {renderModalChangeApproverMultiTrans()}
    </div>
  );

  function renderModalChangeApproverMultiTrans() {
    return (
      <Modal size="modal-md" ref={modalChangeApproverMultiTransRef} modalFooter>
        <form onSubmit={(e) => onSubmitChangeApproverMultiTrans(e)} className="form-horizontal">
          <div className="text-center">
            <span className="text-success icon icon-check icon-5x" />
            <h3 className="text-success">Approve</h3>
            <p>
              {modalChangeApproverMultiTrans.transactionsData.length} item(s) selected, click to
              approve?
            </p>
            <div className="text-left">
              {modalChangeApproverMultiTrans.resultFail.map((m, i) => (
                <div className="form-group col-xs-12" key={i}>
                  <div className="col-xs-3 text-right">
                    <span className="text-danger icon icon-close icon-1x" />
                  </div>
                  <div className="col-xs-9">{m}</div>
                </div>
              ))}
            </div>
            <br />
            <FormGroup3 text="Approver Type" col={colX2} required>
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                required
                value={modalChangeApproverMultiTrans.ApproverType}
                onChange={(e) =>
                  onChangeModalApproverMultiTrans({
                    target: e,
                  })
                }
                options={approverTypes}
                name="ApproverType"
              />
            </FormGroup3>
            <FormGroup3 text="Approver" col={colX2} required>
              <MasterDropdown
                masterType={MASTER_APPROVER}
                updateFilterFn
                isChoose
                notMultipleSelect2
                required
                filter={(m) => {
                  const roleNameFilter = [];
                  switch (modalChangeApproverMultiTrans.ApproverType) {
                    case 'Approver2':
                      roleNameFilter.push('Approver 2');
                    case 'Approver1':
                      roleNameFilter.push('Approver 1');
                      break;
                    case 'Reviewer':
                      roleNameFilter.push('Reviewer');
                      break;
                  }
                  return roleNameFilter.includes(m.roleName);
                }}
                financeGroupId={modalChangeApproverMultiTrans.FinanceGroupId}
                value={modalChangeApproverMultiTrans.Approver}
                onChange={(e) =>
                  onChangeModalApproverMultiTrans({
                    target: e,
                  })
                }
                name="Approver"
              />
            </FormGroup3>

            <div className="m-t-lg">
              {modalChangeApproverMultiTrans.resultFail.length ? null : (
                <Button txt="Approve" className="btn-success" type="submit" />
              )}
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    );
  }
};
