import { connect } from 'react-redux';
import React, { Component } from 'react';

import NavItem from './NavItem';
import { setLayout } from '../../reducers/layout/action';
import { getNotification } from '../../reducers/notification/action';

class SideBar extends Component {
  state = {
    sideMenuItem: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      sideMenuItem: [
        {
          text: 'Dashboard',
          path: '/',
          pageName: 'Dashboard',
          icon: 'icon-pie-chart',
        },
        {
          text: 'Cash Management',
          pageName: 'CashManagementHeader',
          icon: 'icon-money',
          subItems: [
            {
              text: 'Bank Ending Balance',
              path: '/cash-management/bank-ending-balance',
              pageName: 'Bank Ending Balance',
              menu: 'Cash Management',
            },
            {
              text: 'Plan & Approve Transaction',
              path: '/cash-management/plan-approve',
              pageName: 'Plan & Approve Transaction',
              menu: 'Cash Management',
              topic: ['Plan & Approve'],
              badgeNewLine: true,
            },
            {
              text: 'Cash Position',
              path: '/cash-management/cash-position',
              pageName: 'Cash Position',
              menu: 'Cash Management',
              topic: ['Cash Position'],
            },
            {
              text: 'Bank Transfer Summary',
              path: '/cash-management/bank-transfer-summary',
              pageName: 'Bank Transfer Summary',
              menu: 'Cash Management',
              topic: ['Bank Transfer Summary 1', 'Bank Transfer Summary 2'],
              badgeNewLine: true,
            },
            {
              text: 'Transfer Fee Set Up',
              path: '/cash-management/transfer-fee-set-up',
              pageName: 'Transfer Fee Set Up',
              menu: 'Cash Management',
            },
          ],
        },
        {
          text: 'Approver',
          pageName: 'ApproverHeader',
          icon: 'icon-check',
          subItems: [
            {
              text: 'Task List',
              path: '/approver/list-approve',
              pageName: 'Approver',
              menu: 'Approver',
            },
          ],
        },
        {
          text: 'Funding&Investment',
          pageName: 'FundingHeader',
          icon: 'icon-fa',
          subItems: [
            {
              text: 'Facility Set up',
              path: '/loan-investment/facility-set-up',
              pageName: 'Facility Set up',
              menu: 'Funding&Investment',
              topic: ['Facility Setup'],
            },
            {
              text: 'Maintain Interest Rate',
              path: '/loan-investment/maintain-interest-rate',
              pageName: 'Maintain Interest Rate',
              menu: 'Funding&Investment',
            },
            {
              text: 'Promissory Note Form',
              path: '/loan-investment/promissory-note-form',
              pageName: 'Promissory Note Form',
              menu: 'Funding&Investment',
            },
            {
              text: 'Create Contract Loan',
              path: '/loan-investment/create-contract-loan',
              pageName: 'Create Contract Loan',
              menu: 'Funding&Investment',
              topic: ['CONTRACT LOAN'],
            },
            {
              text: 'Create Promissory Note',
              path: '/loan-investment/create-pn-loan',
              pageName: 'Create Promissory Note',
              menu: 'Funding&Investment',
              topic: ['PN'],
            },
            {
              text: 'Create Contract on Call',
              path: '/loan-investment/create-contract-on-call',
              pageName: 'Create Contract on Call',
              menu: 'Funding&Investment',
              topic: ['CONTRACT ON CALL'],
            },
            {
              text: 'Create Deposit',
              path: '/loan-investment/create-deposit',
              pageName: 'Create Deposit',
              menu: 'Funding&Investment',
              topic: ['DEPOSIT'],
            },
            {
              text: 'Create Bond',
              path: '/loan-investment/create-bond',
              pageName: 'Create Bond',
              menu: 'Funding&Investment',
            },
            {
              text: 'Create Derivative',
              path: '/loan-investment/create-derivative',
              pageName: 'Create Derivative',
              menu: 'Funding&Investment',
            },
            {
              text: 'MTM Swap',
              path: '/loan-investment/mtm-swap',
              pageName: 'MTM Swap',
              menu: 'Funding&Investment',
            },
            {
              text: 'MTM Bond',
              path: '/loan-investment/mtm-bond',
              pageName: 'MTM Bond',
              menu: 'Funding&Investment',
            },
          ],
        },
        {
          text: 'FX Management',
          pageName: 'FXHeader',
          icon: 'icon-forward',
          subItems: [
            {
              text: 'Create Exposure',
              path: '/fx-management/create-exposure',
              pageName: 'Create Exposure',
              menu: 'FX Management',
              topic: ['Exposure Position 2'],
            },
            {
              text: 'Exposure Position',
              path: '/fx-management/exposure-position',
              pageName: 'Exposure Position',
              menu: 'FX Management',
              topic: ['Exposure Position 1'],
            },
            {
              text: 'Manage FX Contract',
              path: '/fx-management/manage-fx-contract',
              pageName: 'Manage FX Contract',
              menu: 'FX Management',
              topic: ['Manage FX Contract 1', 'Manage FX Contract 2'],
              badgeNewLine: true,
            },
            {
              text: 'E-Form Grouping',
              path: '/fx-management/e-form-grouping',
              pageName: 'E-Form Grouping',
              menu: 'FX Management',
            },
            {
              text: 'MTM Forward',
              path: '/fx-management/mark-to-market',
              pageName: 'MTM Forward',
              menu: 'FX Management',
            },
            {
              text: 'Create Exposure for Booking Forward',
              path: '/fx-management/create-exposure-for-booking-forward',
              pageName: 'Create Exposure for Booking Forward',
              menu: 'FX Management',
            },
            {
              text: 'Pricing Rate to Bank',
              path: '/fx-management/pricing-rate-to-bank',
              pageName: 'Pricing Rate to Bank',
              menu: 'FX Management',
            },
            {
              text: 'FX Confirmation',
              path: '/fx-management/fx-confirmation',
              pageName: 'FX Confirmation',
              menu: 'FX Management',
            },
          ],
        },
        {
          text: 'T/T Remittance',
          pageName: 'TTHeader',
          icon: 'icon-archive',
          subItems: [
            {
              text: 'Request',
              path: '/tt-remittance/request',
              pageName: 'Request',
              menu: 'T/T Remittance',
            },
            {
              text: 'Manage',
              path: '/tt-remittance/management',
              pageName: 'T/T Manage',
              menu: 'T/T Remittance',
              topic: ['Manage T/T 1', 'Manage T/T 2'],
            },
          ],
        },
        {
          text: 'Bank Guarantee',
          pageName: 'GuaranteeHeader',
          icon: 'icon-bank',
          subItems: [
            {
              text: 'Request',
              path: '/bank-guarantee/search-request',
              pageName: 'BG Request',
              menu: 'Bank Guarantee',
              topic: ['BG 1', 'BG 2', 'BG 3'],
            },
            {
              text: 'Maintain Form',
              path: '/bank-guarantee/search-maintain-form',
              pageName: 'Maintain Form',
              menu: 'Bank Guarantee',
            },
            {
              text: 'Maintain Beneficiary',
              path: '/bank-guarantee/maintain-bank',
              pageName: 'Maintain Beneficiary',
              menu: 'Bank Guarantee',
            },
            {
              text: 'Maintain Branch',
              path: '/bank-guarantee/maintain-branch',
              pageName: 'Maintain Branch',
              menu: 'Bank Guarantee',
            },
          ],
        },
        {
          text: 'POA Management',
          pageName: 'PoaHeader',
          icon: 'icon-book',
          subItems: [
            { text: 'POA', path: '/poa-management/poa', pageName: 'POA', menu: 'POA Management' },
          ],
        },
        {
          text: 'Interface',
          pageName: 'InterfaceHeader',
          icon: 'icon-exchange',
          subItems: [
            {
              text: 'Log Error',
              path: '/interface/log-error',
              pageName: 'Log Error',
              menu: 'Interface',
              topic: ['Interface WARNING', 'Interface FAIL'],
            },
          ],
        },
        {
          text: 'Automation',
          path: '/',
          pageName: 'AutomationHeader',
          icon: 'icon-at',
        },
        {
          text: 'Accounting',
          pageName: 'AccountingHeader',
          icon: 'icon-file',
          subItems: [
            {
              text: 'Bank Transfer Posting',
              path: '/accounting/bank-transfer-posting',
              pageName: 'Bank Transfer Posting',
              menu: 'Accounting',
              topic: ['BT 1', 'BT 2'],
            },
            {
              text: 'Money Market',
              path: '/accounting/money-market',
              pageName: 'Money Market',
              menu: 'Accounting',
              topic: ['MM 1', 'MM 2'],
            },
            {
              text: 'Securities',
              path: '/accounting/securities',
              pageName: 'Securities',
              menu: 'Accounting',
              topic: ['BD 1', 'BD 2'],
            },
            {
              text: 'Derivatives',
              path: '/accounting/derivatives',
              pageName: 'Derivatives',
              menu: 'Accounting',
              topic: ['DV 1', 'DV 2'],
            },
            {
              text: 'Foreign Exchange',
              path: '/accounting/foreign-exchange',
              pageName: 'Foreign Exchange',
              menu: 'Accounting',
              topic: ['FX 1', 'FX 2'],
            },
          ],
        },
        {
          text: 'Posting Journal',
          pageName: 'PostingJournalccountingHeader',
          icon: 'icon-file',
          subItems: [
            {
              text: 'Money Market',
              path: '/accounting-posting-journal/money-market',
              pageName: 'Money Market Journal',
              menu: 'Posting Journal',
            },
            {
              text: 'Securities',
              path: '/accounting-posting-journal/securities',
              pageName: 'Securities Journal',
              menu: 'Posting Journal',
            },
            {
              text: 'Derivatives',
              path: '/accounting-posting-journal/derivatives',
              pageName: 'Derivatives Journal',
              menu: 'Posting Journal',
            },
            {
              text: 'Foreign Exchange',
              path: '/accounting-posting-journal/foreign-exchange',
              pageName: 'Foreign Exchange Journal',
              menu: 'Posting Journal',
            },
          ],
        },
        {
          text: 'Report',
          pageName: 'ReportHeader',
          icon: 'icon-file',
          subItems: [
            {
              text: 'Loan&Investment',
              path: '/report/loan-investment',
              pageName: 'Report Loan&Investment',
              menu: 'Report',
            },
            {
              text: 'Loan Intercom',
              path: '/report/loan-intercom',
              pageName: 'Report Loan Intercom',
              menu: 'Report',
            },
            {
              text: 'Bank Guarantee Management',
              path: '/report/bank-guarantee-management',
              pageName: 'Report Bank Guarantee Management',
              menu: 'Report',
            },
            {
              text: 'Sweep PN',
              path: '/report/sweep-pn',
              pageName: 'Sweep PN',
              menu: 'Report',
              topic: ['Sweep BBL', 'Sweep SCB', 'Sweep KBANK'],
            },
            { text: 'Interest', path: '/report/interest', pageName: 'Interest', menu: 'Report' },
            {
              text: 'Account Saving',
              path: '/report/account-saving',
              pageName: 'Account Saving',
              menu: 'Report',
            },
            {
              text: 'Interest Receipt',
              path: '/report/interest-receipt',
              pageName: 'Interest Receipt',
              menu: 'Report',
            },
            { text: 'Other', path: '/report/other', pageName: 'Other', menu: 'Report' },
          ],
        },
        {
          text: 'Form',
          pageName: 'ReportHeader',
          icon: 'icon-columns',
          subItems: [
            {
              text: 'T/T Remittance',
              path: '/form/tt-remittance',
              pageName: 'Form T/T Remittance',
              menu: 'Form',
            },
            {
              text: 'Bank Guarantee Management',
              path: '/form/bank-guarantee-management',
              pageName: 'Form Bank Guarantee Management',
              menu: 'Form',
            },
            {
              text: 'File Upload',
              path: '/form/file-upload',
              pageName: 'File Upload',
              menu: 'Form',
            },
          ],
        },
        {
          text: 'Automate',
          pageName: 'AutomateHeader',
          icon: 'icon-exchange',
          subItems: [
            {
              text: 'Maintain Interest',
              path: '/automate/maintain-interest',
              pageName: 'Maintain Interest',
              menu: 'Automate',
            },
            {
              text: 'Maintain Balance',
              path: '/automate/maintain-balance',
              pageName: 'Maintain Balance',
              menu: 'Automate',
            },
            {
              text: 'Exposure',
              path: '/automate/exposure',
              pageName: 'Exposure',
              menu: 'Automate',
            },
          ],
        },
        {
          text: 'Common Master',
          pageName: 'MasterFinanceHeader',
          icon: 'icon-gear',
          subItems: [
            {
              text: 'Company',
              path: '/master/company',
              pageName: 'Company',
              menu: 'Common Master',
            },
            { text: 'Role', path: '/master/role', pageName: 'Role', menu: 'Common Master' },
            { text: 'User', path: '/master/user', pageName: 'User', menu: 'Common Master' },
            {
              text: 'Logo & Signature',
              path: '/master/logo-and-signature',
              pageName: 'Logo & Signature',
              menu: 'Common Master',
            },
          ],
        },
        {
          text: 'Master Accounting',
          pageName: 'MasterAccountingHeader',
          icon: 'icon-gear',
          subItems: [
            {
              text: 'Account Standard',
              path: '/master/account-standard',
              pageName: 'Account Standard',
              menu: 'Master Accounting',
            },
            {
              text: 'Business Area',
              path: '/master/business-area',
              pageName: 'Business Area',
              menu: 'Master Accounting',
            },
            { text: 'COA', path: '/master/coa', pageName: 'COA', menu: 'Master Accounting' },
            {
              text: 'Flow Accounting',
              path: '/master/flow-accounting',
              pageName: 'Flow Accounting',
              menu: 'Master Accounting',
            },
            {
              text: 'Flow Accounting Detail',
              path: '/master/flow-accounting-detail',
              pageName: 'Flow Accounting Detail',
              menu: 'Master Accounting',
            },
            {
              text: 'Flow Config',
              path: '/master/flow-config',
              pageName: 'Flow Config',
              menu: 'Master Accounting',
            },
            {
              text: 'GL Account',
              path: '/master/gl-account',
              pageName: 'GL Account',
              menu: 'Master Accounting',
            },
            {
              text: 'Instance',
              path: '/master/instance',
              pageName: 'Instance',
              menu: 'Master Accounting',
            },
            {
              text: 'Payment Method',
              path: '/master/payment-method',
              pageName: 'Payment Method',
              menu: 'Master Accounting',
            },
            {
              text: 'Withholding Tax',
              path: '/master/with-holding-tax',
              pageName: 'Withholding Tax',
              menu: 'Master Accounting',
            },
            {
              text: 'Print Int. Receipt Maintain',
              path: '/master/print-interest-receipt-maintain-search',
              pageName: 'Print Interest Receipt Maintain Search',
              menu: 'Master Accounting',
            },
            {
              text: 'Print Int. Receipt Type',
              path: '/master/print-interest-receipt-type',
              pageName: 'Print Interest Receipt Type',
              menu: 'Master Accounting',
            },
          ],
        },
        {
          text: 'Master Finance',
          pageName: 'MasterFinanceHeader',
          icon: 'icon-gear',
          subItems: [
            { text: 'Bank', path: '/master/bank', pageName: 'Bank', menu: 'Master Finance' },
            {
              text: 'Bank Account',
              path: '/master/bank-account',
              pageName: 'Bank Account',
              menu: 'Master Finance',
            },
            {
              text: 'Bank Branch',
              path: '/master/bank-branch',
              pageName: 'Bank Branch',
              menu: 'Master Finance',
            },
            {
              text: 'Bank Account of Bank',
              path: '/master/bank-account-of-bank',
              pageName: 'Bank Account of Bank',
              menu: 'Master Finance',
            },
            {
              text: 'Business Unit',
              path: '/master/business-unit',
              pageName: 'Business Unit',
              menu: 'Master Finance',
            },
            {
              text: 'Calendar',
              path: '/master/calendar',
              pageName: 'Calendar',
              menu: 'Master Finance',
            },
            {
              text: 'Currency',
              path: '/master/currency',
              pageName: 'Currency',
              menu: 'Master Finance',
            },
            {
              text: 'Exchange Rate',
              path: '/master/exchange-rate',
              pageName: 'Exchange Rate',
              menu: 'Master Finance',
            },
            {
              text: 'Finance Group',
              path: '/master/finance-group',
              pageName: 'Finance Group',
              menu: 'Master Finance',
            },
            {
              text: 'Interest Rate',
              path: '/master/interest-rate',
              pageName: 'Interest Rate',
              menu: 'Master Finance',
            },
            { text: 'Issuer', path: '/master/issuer', pageName: 'Issuer', menu: 'Master Finance' },
            {
              text: 'Tier Rate',
              path: '/master/tier-rate',
              pageName: 'Tier Rate',
              menu: 'Master Finance',
            },
          ],
        },
      ],
    };
  }

  render() {
    const { layout = {} } = this.props;
    return (
      <div className="layout-sidebar">
        <div className="layout-sidebar-backdrop" />
        <div className="layout-sidebar-body cus-scroll-nav-bar">
          <nav
            id="sidenav"
            className="sidenav-collapse collapse"
            style={{ display: layout.isActiveSidebar ? 'block' : '' }}
          >
            <ul className={`sidenav ${!layout.isActiveSidebarSm ? 'sidenav-collapsed' : ''}`}>
              <li className="sidenav-heading">AFMS Menu</li>
              {this.state.sideMenuItem.map((m, idx) => (
                <NavItem pageCurrent={layout.page} key={idx} subs={false} {...m} />
              ))}
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    layout: state.layout,
    notification: state.notification,
  }),
  {
    setLayout,
    getNotification,
  }
)(SideBar);
