import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';

export const SET_LOADING = 'SET_LOADING';
export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_PAGE_NAME_LAYOUT = 'SET_PAGE_NAME_LAYOUT';
export const ADD_ALERT = 'ADD_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REMOVE_ALL_ALERT = 'REMOVE_ALL_ALERT';

export const REQUEST_PING_TEST = '@@request/PING_TEST';
export const RECEIVE_PING_TEST = '@@receive/PING_TEST';
export const FAIL_PING_TEST = '@@fail/PING_TEST';

export const pingTest = () => ({
  [RSAA]: {
    endpoint: endpoint('Ping'),
    method: 'GET',
    types: [REQUEST_PING_TEST, RECEIVE_PING_TEST, FAIL_PING_TEST],
  },
});

export function setLoading(isLoading) {
  return {
    type: SET_LOADING,
    payload: isLoading,
  };
}
export function setLayout(layout) {
  return {
    type: SET_LAYOUT,
    payload: layout,
  };
}

export function setPageName(page) {
  return {
    type: SET_PAGE_NAME_LAYOUT,
    payload: page,
  };
}

export function addAlert(error) {
  return {
    type: ADD_ALERT,
    payload: error,
  };
}
export function removeError(index) {
  return {
    type: REMOVE_ALERT,
    index,
  };
}

export function removeAllAlert() {
  return {
    type: REMOVE_ALL_ALERT,
  };
}
