import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_FINANCE_GROUP_CRITERIA = 'EDIT_MASTER_FINANCE_GROUP_CRITERIA';
export const CLEAR_MASTER_FINANCE_GROUP_CRITERIA = 'CLEAR_MASTER_FINANCE_GROUP_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_FINANCE_GROUP =
  'UPDATE_SEARCH_RESULT_MASTER_FINANCE_GROUP';

export const REQUEST_SEARCH_MASTER_FINANCE_GROUP = '@@request/SARCH_MASTER_FINANCE_GROUP';
export const RECEIVE_SEARCH_MASTER_FINANCE_GROUP = '@@receive/SEARCH_MASTER_FINANCE_GROUP';
export const FAIL_SEARCH_MASTER_FINANCE_GROUP = '@@fail/SEARCH_MASTER_FINANCE_GROUP';

export const REQUEST_DELETE_MASTER_FINANCE_GROUP = '@@request/DELETE_MASTER_FINANCE_GROUP';
export const RECEIVE_DELETE_MASTER_FINANCE_GROUP =
  '@@receive-The record was successfully change status/DELETE_MASTER_FINANCE_GROUP';
export const FAIL_DELETE_MASTER_FINANCE_GROUP = '@@fail/DELETE_MASTER_FINANCE_GROUP';

export const REQUEST_SAVE_MASTER_FINANCE_GROUP = '@@request/SAVE_MASTER_FINANCE_GROUP';
export const RECEIVE_SAVE_MASTER_FINANCE_GROUP = '@@receive/SAVE_MASTER_FINANCE_GROUP';
export const FAIL_SAVE_MASTER_FINANCE_GROUP = '@@fail/SAVE_MASTER_FINANCE_GROUP';

export const REQUEST_GET_MASTER_FINANCE_GROUP_DETAIL = '@@request/SARCH_MASTER_FINANCE_GROUP';
export const RECEIVE_GET_MASTER_FINANCE_GROUP_DETAIL = '@@receive/GET_MASTER_FINANCE_GROUP_DETAIL';
export const FAIL_GET_MASTER_FINANCE_GROUP_DETAIL = '@@fail/GET_MASTER_FINANCE_GROUP_DETAIL';

export const getMasterFinanceGroupDetail = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterfinancegroupdetail', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_FINANCE_GROUP_DETAIL,
      RECEIVE_GET_MASTER_FINANCE_GROUP_DETAIL,
      FAIL_GET_MASTER_FINANCE_GROUP_DETAIL,
    ],
  },
});

export const saveMasterFinanceGroupDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterfinancegroup`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_FINANCE_GROUP,
      RECEIVE_SAVE_MASTER_FINANCE_GROUP,
      FAIL_SAVE_MASTER_FINANCE_GROUP,
    ],
  },
});

export const deleteMasterFinanceGroup = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemasterfinancegroup`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DELETE_MASTER_FINANCE_GROUP,
      RECEIVE_DELETE_MASTER_FINANCE_GROUP,
      FAIL_DELETE_MASTER_FINANCE_GROUP,
    ],
  },
});

export const searchMasterFinanceGroup = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterfinancegroup', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_FINANCE_GROUP,
      RECEIVE_SEARCH_MASTER_FINANCE_GROUP,
      FAIL_SEARCH_MASTER_FINANCE_GROUP,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_FINANCE_GROUP,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_FINANCE_GROUP_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_FINANCE_GROUP_CRITERIA,
  payload: {
    name,
    value,
  },
});
