import {
  EDIT_REQUEST_TT_REMITTANCE_CRITERIA,
  RECEIVE_SEARCH_REQUEST_TT_REMITTANCE,
  CLEAR_REQUEST_TT_REMITTANCE_CRITERIA,
  UPDATE_SEARCH_RESULT_REQUEST_TT_REMITTANCE,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  Currencies: [''],
  PreAdvice: '',
  ValueDateStr: '',
  Status: '',
  Equivalent: '',
  PaidFor: '',
  PoNo: '',
  InvoiceNo: '',
};
const init = {
  searchResult: [],
  criteria: {
    ...initCriteria,
  },
};

export default (state = init, action) => {
  switch (action.type) {
    case CLEAR_REQUEST_TT_REMITTANCE_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_REQUEST_TT_REMITTANCE_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_REQUEST_TT_REMITTANCE:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    case UPDATE_SEARCH_RESULT_REQUEST_TT_REMITTANCE:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    default:
      return state;
  }
};
