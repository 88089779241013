import { connect } from 'react-redux';
import React, { Component } from 'react';

import ExchangeRate from './ExchangeRate';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import {
  editCriteria,
  clearCriteria,
  updateSearchResult,
  searchMasterExchangeRate,
  updateMasterExchangeRate,
} from '../../reducers/masterExchangeRate/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-exchange-rate';

const initData = {
  ExchangeRateType: '',
  CurrencyFrom: '',
  CurrencyTo: '',
  ValidFromStr: '',
  Rate: '',
  RatioFrom: 0,
  RatioTo: 0,
  Mode: 'create',
};

class ExchangeRateContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMasterExchangeRate({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalData = (e) => {
    this.setState(
      {
        modalData: {
          ...initData,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          Mode: 'edit',
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;
    if (name === 'CurrencyFrom') {
      if (value === this.state.modalData.CurrencyTo) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please select Currency From not equal Currency To.',
        });
        return;
      }
    } else if (name === 'CurrencyTo') {
      if (value === this.state.modalData.CurrencyFrom) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please select Currency To not equal Currency From.',
        });
        return;
      }
    }
    this.setState({
      modalData: {
        ...this.state.modalData,
        [name]: value,
      },
    });
  };

  onClickSaveModalData = (e) => {
    e.preventDefault();
    const state = { ...this.state.modalData };

    if (!state.Rate || parseFloat((state.Rate.toString() || '').replace(/,/g, '')) <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Rate must more than zero.',
      });
      return;
    }

    if (!state.RatioFrom || parseFloat((state.RatioFrom.toString() || '').replace(/,/g, '')) <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Ratio From must more than zero.',
      });
      return;
    }

    if (!state.RatioTo || parseFloat((state.RatioTo.toString() || '').replace(/,/g, '')) <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Ratio To must more than zero.',
      });
      return;
    }

    this.props.updateMasterExchangeRate({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      if (this.props.criteria.ValidDateStr) {
        this.props.searchMasterExchangeRate({ ...this.props.criteria });
      }
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        ...toUpperKey(rowData),
        Mode: 'change',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    this.props.updateMasterExchangeRate({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMasterExchangeRate({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onChangeModalData: this.onChangeModalData,
      onClickSaveModalData: this.onClickSaveModalData,
      onClickOpenModalData: this.onClickOpenModalData,
    };

    return (
      <div>
        <ExchangeRate
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalDataRef={(e) => (this.modalDataRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterExchangeRate,
  }),
  {
    searchMasterExchangeRate,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    updateMasterExchangeRate,
  }
)(ExchangeRateContainer);
