import React from 'react';
import { Link } from 'react-router-dom';

import Tab from '../../common/Tab';
import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Toggle from '../../common/Toggle';
import Select2 from '../../common/Select2';
import Table from '../../common/DataTable';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import { DATATABLE_ID } from './PlanApproveContainer';
import MasterDropdown from '../../common/MasterDropdown';
import ModalFunction2 from '../../common/ModalFunction2';
import DateRangePicker from '../../common/DateRangePicker';
import { toLower, toStatus, toNumber, toAccount, getPermissionStr } from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const row = 'row gutter-xs';
const { moment } = window;

const permission = getPermissionStr('Cash Management', 'Plan & Approve Transaction');
const viewPremission = toLower(permission).includes('create');
const viewInflow = toLower(permission) === 'view (inflow)';
const viewOutflow = toLower(permission) === 'view (outflow)';

export default ({
  criteria,
  results,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,
  onClickUpload,

  modalChangeValueDateRef,
  modalValueDate,
  onClickOpenModalChangeValueDate,
  onChangeValueDate,
  onClickSaveModalChangeValueDate,

  onClickOpenModalChangeStatus,
  onClickConfirmChangeStatus,
  modalChangeStatusRef,
  modalChangeStatusData,

  conditionChangeStatus,

  dataTableRef,
  isMillion,
  onChangeToggleMillion,

  onChangeTab,
  allSearchResult,
  tab,
}) => {
  const selectedForChangeValueDateLength = conditionChangeStatus.changeValueDate(results).length;
  const selectedForChangeStatusApprove = conditionChangeStatus.approve(results).length;
  const selectedForChangeStatusWithdraw = conditionChangeStatus.withdraw(results).length;
  const selectedForChangeStatusDisApprove = conditionChangeStatus.disApprove(results).length;
  const selectedForChangeStatusCancel = conditionChangeStatus.cancel(results).length;
  const tabContents = [];
  // start tab1
  const headerAllTab = (
    <span>
      All <span className="badge badge-default">{allSearchResult.length}</span>
    </span>
  );
  const allTab = {
    header: headerAllTab,
    tabName: 'all',
  };
  tabContents.push(allTab);

  // tab2
  const headerCashTab = (
    <span>
      Cash Management{' '}
      <span className="badge badge-default">
        {allSearchResult.filter((f) => (f.sourceType || '').toLowerCase() === 'cash plan').length}
      </span>
    </span>
  );
  const cashTab = {
    header: headerCashTab,
    tabName: 'cash',
  };
  tabContents.push(cashTab);

  // tab3
  const headerLoanTab = (
    <span>
      Loan & Investment{' '}
      <span className="badge badge-default">
        {
          allSearchResult.filter((f) => (f.sourceType || '').toLowerCase() === 'loan & investment')
            .length
        }
      </span>
    </span>
  );
  const loanTab = {
    header: headerLoanTab,
    tabName: 'loan',
  };
  tabContents.push(loanTab);

  // tab4
  const headerInterfaceTab = (
    <span>
      Interface{' '}
      <span className="badge badge-default">
        {
          allSearchResult.filter(
            (f) =>
              (f.sourceType || '').toLowerCase() === 'interface' ||
              (f.sourceType || '').toLowerCase() === 'ap-invoice' ||
              (f.sourceType || '').toLowerCase() === 'ap-payment' ||
              (f.sourceType || '').toLowerCase() === 'blockchain'
          ).length
        }
      </span>
    </span>
  );
  const interfaceTab = {
    header: headerInterfaceTab,
    tabName: 'interface',
  };
  tabContents.push(interfaceTab);

  // tab5
  const headerFxTab = (
    <span>
      FX Management{' '}
      <span className="badge badge-default">
        {
          allSearchResult.filter((f) => (f.sourceType || '').toLowerCase() === 'fx management')
            .length
        }
      </span>
    </span>
  );
  const fxTab = {
    header: headerFxTab,
    tabName: 'fx',
  };
  tabContents.push(fxTab);

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Cash Management <span className="icon icon-angle-double-right" /> Plan & Approve
            Transaction
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form className="" onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={criteria.FinanceGroups}
                  onChange={onChangeSelect2}
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  value={criteria.BusinessUnits}
                  onChange={onChangeSelect2}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  value={criteria.Companys}
                  onChange={onChangeSelect2}
                  name="Companys"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                />
              </FormGroup2>

              <FormGroup2 text="Value Date" required>
                <DateRangePicker
                  startDate={window.moment().add(0, 'days')}
                  endDate={window.moment().add(0, 'days')}
                  onChange={onChangeSearch}
                  name="ValueDateStr"
                  value={criteria.ValueDateStr}
                  required
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  value={criteria.Currencys}
                  onChange={onChangeSelect2}
                  name="Currencys"
                />
              </FormGroup2>
              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  value={criteria.Banks}
                  onChange={onChangeSelect2}
                  name="Banks"
                  saveLocalState
                  companyCode={criteria.Companys}
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Cash Type">
                <select
                  className="form-control"
                  value={criteria.CashType}
                  onChange={onChangeSearch}
                  name="CashType"
                  disabled={viewInflow || viewOutflow}
                >
                  <option value="">---All---</option>
                  <option value="Inflow">Inflow</option>
                  <option value="Outflow">Outflow</option>
                </select>
              </FormGroup2>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  name="Status"
                >
                  <option value="">---All---</option>
                  <option value="Wait for approve">Wait for approve</option>
                  <option value="Approved">Approved</option>
                  <option value="Disapprove">Disapprove</option>
                  <option value="Cancel">Cancel</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Source Type">
                <Select2 value={criteria.SourceTypes} onChange={onChangeSelect2} name="SourceTypes">
                  <option value="cash plan">Cash Plan</option>
                  <option value="loan & investment">Loan & Investment</option>
                  <option value="fx management">FX Management</option>
                  <option value="ap-invoice">AP-Invoice</option>
                  <option value="ap-payment">AP-Payment</option>
                  <option value="blockchain">Blockchain</option>
                </Select2>
              </FormGroup2>
              <FormGroup2 text="Created By">
                <input
                  className="form-control"
                  value={criteria.CreatedBy}
                  onChange={onChangeSearch}
                  // readOnly={viewPremission}
                  name="CreatedBy"
                />
              </FormGroup2>
            </Col2>
          </Col2>
          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {toLower(permission) === 'create' || toLower(permission) === 'all' ? (
                <>
                  <Button
                    txt="UPLOAD EXCEL (CASH PLAN)"
                    icon="upload"
                    className="btn-success"
                    onClick={onClickUpload}
                  />
                  &nbsp;
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/cash-management/cash-plan/create"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    CREATE CASH PLAN
                  </Link>
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Tab tabs={tabContents} onChangeTab={onChangeTab} visualTab>
          {toLower(permission) === 'create' || toLower(permission) === 'all' ? (
            <div className={row}>
              <div className="col-md-12">
                {tab === 'all' || tab === 'cash' ? (
                  <>
                    <Button
                      txt="CHANGE VALUE DATE"
                      icon="edit"
                      className="btn-info"
                      onClick={onClickOpenModalChangeValueDate}
                      disabled={!selectedForChangeValueDateLength}
                    />
                    &nbsp;
                  </>
                ) : null}
                {toLower(permission) === 'all' ? (
                  <>
                    <Button
                      txt="APPROVE"
                      icon="check"
                      className="btn-success"
                      onClick={(e) => onClickOpenModalChangeStatus('approve')}
                      disabled={!selectedForChangeStatusApprove}
                    />
                    &nbsp;
                    <Button
                      txt="WITHDRAW APPROVE"
                      icon="refresh"
                      className="btn-warning"
                      onClick={(e) => onClickOpenModalChangeStatus('withdraw')}
                      disabled={!selectedForChangeStatusWithdraw}
                    />
                    &nbsp;
                    {tab === 'all' || tab === 'cash' || tab === 'interface' ? (
                      <>
                        <Button
                          txt="DISAPPROVE"
                          icon="close"
                          className="btn-danger"
                          onClick={(e) => onClickOpenModalChangeStatus('disapprove')}
                          disabled={!selectedForChangeStatusDisApprove}
                        />
                        &nbsp;
                      </>
                    ) : null}
                  </>
                ) : null}

                {tab === 'all' || tab === 'cash' ? (
                  <>
                    <Button
                      txt="CANCEL"
                      icon="close"
                      className="btn-default"
                      onClick={(e) => onClickOpenModalChangeStatus('cancel')}
                      disabled={!selectedForChangeStatusCancel}
                    />
                    &nbsp;
                  </>
                ) : null}
              </div>
            </div>
          ) : null}

          <Col2 col={colX[2]}>
            <div className="toggle-million">
              <span>
                <b>Million</b>{' '}
                <Toggle name="isMillion" checked={isMillion} onChange={onChangeToggleMillion} />
              </span>
            </div>
          </Col2>

          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={optAll}
            value={results}
          >
            <thead>
              <tr>
                <th>
                  Choose <label className="label-checkbox fix choose-all" />
                </th>
                <th>Action</th>
                <th>Transaction No.</th>
                <th>
                  Finance
                  <br /> Group
                </th>
                <th>
                  Business
                  <br /> Unit
                </th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>
                  Company
                  <br /> Name
                </th>
                <th>Source</th>
                <th>Partner Type</th>
                <th>
                  Vendor/Customer
                  <br /> Name/Intercom
                </th>
                <th>
                  Value
                  <br /> Date
                </th>
                <th>
                  Transaction
                  <br /> Details
                </th>
                <th>
                  Cash
                  <br /> Type
                </th>
                <th>
                  Transfer/Payment
                  <br /> Method
                </th>
                <th>
                  Bank
                  <br /> Account
                </th>
                <th className="text-center">Amount</th>
                <th>Currency</th>
                <th className="text-center">Exchange Rate</th>
                <th className="text-center">
                  Amount in
                  <br /> Bank Currency
                </th>
                <th>Status</th>
                <th>Instruction Key 1</th>
                <th>Payment Block</th>
                <th>Payment Term</th>
                <th>
                  Created
                  <br /> Date
                </th>
                <th>
                  Created
                  <br /> By
                </th>
                <th>
                  Updated
                  <br /> Date
                </th>
                <th>
                  Updated
                  <br /> By
                </th>
              </tr>
            </thead>
          </Table>
        </Tab>
      </Card>

      <Modal size="modal-md" ref={modalChangeValueDateRef} modalFooter>
        <form onSubmit={onClickSaveModalChangeValueDate} className="form-horizontal">
          <div className="text-center">
            <span className="label label-info">{selectedForChangeValueDateLength} item(s)</span>
            <h2 className="text-info">Change Value Date</h2>
            <br />
            <div className={row}>
              <div className="col-md-offset-4 col-md-5 text-center">
                <div className="input-with-icon">
                  <DatePicker
                    onChange={onChangeValueDate}
                    name="ValueDate"
                    value={modalValueDate}
                    option={{
                      startDate: 'd',
                      daysOfWeekDisabled: '0,6',
                      todayHighlight: true,
                    }}
                    required
                    setDate={window.moment().add(1, 'days')}
                  />
                  <span className="icon icon-calendar input-icon" />
                </div>
              </div>
            </div>
            <div className="m-t-lg">
              <Button txt="Save" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction2
        modalRef={modalChangeStatusRef}
        icon={modalChangeStatusData.icon}
        type={modalChangeStatusData.type}
        textHeader={modalChangeStatusData.textHeader}
        textContent={modalChangeStatusData.textContent}
        textBtn={modalChangeStatusData.textBtn}
        textBtnBack={modalChangeStatusData.textBtnBack}
        selectedLength={modalChangeStatusData.selectedLength}
        passLength={modalChangeStatusData.passLength}
        showFail={modalChangeStatusData.showFail}
        noSelect={modalChangeStatusData.noSelect}
        result={modalChangeStatusData.resultFail}
        onClick={(e) => onClickConfirmChangeStatus(e)}
      />
    </div>
  );
};

const optAll = {
  deferRender: true,
  scroller: true,
  paging: true,
  order: [[2, 'desc']],
  fixedColumns: {
    leftColumns: 3,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        if (
          r.status === null ||
          r.status.toLowerCase() === 'cancel' ||
          r.status.toLowerCase() === 'disapprove' ||
          !moment(r.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(-1, 'days'))
        ) {
          r.CanSelect = false;
          return '';
        }
        r.CanSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsAction',
      className: 'text-center',
      render: (d, t, r) => {
        if (toLower(permission) !== 'all' || toLower(r.sourceType) !== 'cash plan') return '';
        if (
          r.status !== null &&
          !moment(r.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(-1, 'days')) &&
          r.status.toLowerCase() === 'approved'
        )
          return '<button class="btn btn-icon btn-primary btn_popover_action" title="Duplicate" data-toggle="popover"><span class="icon icon-copy sq-24"></span></button>';
        return '<button class="btn btn-icon btn-primary" disabled="true"><span class="icon icon-copy sq-24"></span></button>';
      },
    },
    {
      data: 'transactionNo',
      render: (d, t, r) => `<a target="_blank" href="${r.urls}" class="edit-cash-plan"> ${d} </a>`,
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'sourceType', className: 'td-width-date' },
    { data: 'partnerType' },
    {
      data: 'intercomCompanyCode',
      render: (d, t, r) => (d ? `${d} | ${r.intercomCompanyName}` : r.intercomCompanyName),
    },
    { data: 'valueDateStr', className: 'text-center td-width-date', type: 'date-black' },
    { data: 'transactionDetail' },
    { data: 'cashType' },
    {
      data: 'transferMethod',
      render: (d, t, r) => {
        if (!d) return r.paymentMethod;
        return d;
      },
    },
    {
      data: 'bankAccountNo',
      render: (d, r, s) =>
        toAccount(s.bankAbbreviate, s.branchName, s.accountTypeCode, s.bankCurrency, d),
    },
    {
      data: 'amount',
      className: 'text-right td-width-number',
      type: 'amount-formal',
      render: (d, t, r) => {
        if (toLower(r.cashType) === 'outflow')
          return toNumber(-Math.abs(d), r.currencyDigit, r.IsMillion);
        return toNumber(Math.abs(d), r.currencyDigit, r.IsMillion);
      },
    },
    { data: 'currency', className: 'text-center' },
    {
      data: 'exchangeRate',
      className: 'text-right td-width-number',
      render: (d, t, r) => {
        if (!d) return '-';
        return toNumber(Math.abs(d), 8, r.IsMillion);
      },
    },
    {
      data: 'amountWithExchangeRate',
      className: 'text-right td-width-number',
      type: 'amount-formal',
      render: (d, t, r) => {
        if (r.exchangeRate === 0 || r.exchangeRate == null) return '-';
        if (toLower(r.cashType) === 'outflow')
          return toNumber(-Math.abs(d), r.bankCurrencyDigit, r.IsMillion);
        return toNumber(Math.abs(d), r.bankCurrencyDigit, r.IsMillion);
      },
    },
    { data: 'status', className: 'text-center td-width-date', render: (d) => toStatus(d) },
    { data: 'instructionKey' },
    { data: 'paymentBlock' },
    { data: 'paymentTerm' },
    { data: 'createdDateStr', className: 'text-center td-width-date', type: 'date-time' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center td-width-date', type: 'date-time' },
    { data: 'updatedBy' },
  ],
};
