import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import { toFixed } from '../../../common/helpper';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_COMPANY } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ state, onChangeInputData }) => (
  <>
    {state.Source !== 'Deposit' ? (
      <Card textHeader="Loan" bgHeader="bg-primary" cardActions={['toggler']}>
        {state.PartnerType === 'Intercompany' && state.TransactionType && state.CompanyByLoan ? (
          <Col2 col={colX[2]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company for Loan">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  noValidateOption
                  saveLocalState
                  onChange={onChangeInputData}
                  notMultiple
                  disabled
                  isChoose
                  value={state.CompanyByLoan}
                  name="CompanyByLoan"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        ) : null}

        {(state.cashFlowLoan || []).map((r, j) => (
          <Card textHeader={r.bankFullName || 'Bank'} bgHeader="bg-grey" key={j}>
            <div className="table-responsive">
              <table className="table table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th className="th-white">Period</th>
                    <th className="th-white">Days</th>
                    <th className="th-white">Payment Date</th>
                    <th className="th-white">Direction</th>
                    <th className="th-white">Flow Type</th>
                    <th className="th-white">Amount</th>
                    <th className="th-white">WHT</th>
                    <th className="th-white">Currency</th>
                    <th className="th-white">Bank Account</th>
                    <th className="th-white">Payment Method</th>
                    <th className="th-white">Exchange Rate</th>
                    <th className="th-white">
                      Local Currency
                      <br />
                      Amount
                    </th>
                    <th className="th-white">
                      Local
                      <br />
                      Currency
                    </th>
                    <th className="th-white">Posting Date</th>
                    <th className="th-white">Document No.</th>
                  </tr>
                </thead>
                <tbody>
                  {!(r.result || []).length ? (
                    <tr>
                      <td colSpan="16" align="center">
                        No data available in table
                      </td>
                    </tr>
                  ) : null}
                  {r.result.map((m, i) => (
                    <tr key={i}>
                      <td align="center">
                        {m.startInterestDateStr} - {m.endInterestDateStr}
                      </td>
                      <td align="center">{m.interestDay}</td>
                      <td align="center">{m.paymentDateStr}</td>
                      <td align="center">{m.direction}</td>
                      <td align="center">{m.flowType}</td>
                      <td align="right">{setAmount(m.amount, 2)}</td>
                      <td align="right">{setAmount(m.whtAmount, 2)}</td>
                      <td align="center">{m.currency}</td>
                      <td>
                        {setAccount(
                          m.bankAbbreviate,
                          m.branchName,
                          m.accountTypeCode,
                          m.bankCurrency,
                          m.bankAccountNo
                        )}
                      </td>
                      <td>{m.paymentMethod}</td>
                      <td align="right">{m.exchangeRate ? setAmount(m.exchangeRate, 8) : ''}</td>
                      <td align="right">
                        {m.localCurrencyAmount ? setAmount(m.localCurrencyAmount, 2) : ''}
                      </td>
                      <td align="center">{m.localCurrency}</td>
                      <td align="center">{m.postingDateStr}</td>
                      <td align="center">
                        <a href="#">{m.documentNo}</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        ))}
      </Card>
    ) : null}

    {state.PartnerType !== 'External' || state.Source === 'Deposit' ? (
      <Card textHeader="Deposit" bgHeader="bg-info" cardActions={['toggler']}>
        {state.PartnerType === 'Intercompany' && state.TransactionType && state.CompanyByDeposit ? (
          <Col2 col={colX[2]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company for Deposit">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  noValidateOption
                  saveLocalState
                  onChange={onChangeInputData}
                  notMultiple
                  disabled
                  isChoose
                  value={state.CompanyByDeposit}
                  name="CompanyByDeposit"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        ) : null}

        {(state.cashFlowDeposit || []).map((r, j) => (
          <Card textHeader={r.bankFullName || 'Bank'} bgHeader="bg-grey" key={j}>
            <div className="table-responsive">
              <table className="table table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th className="th-white">Period</th>
                    <th className="th-white">Days</th>
                    <th className="th-white">Payment Date</th>
                    <th className="th-white">Direction</th>
                    <th className="th-white">Flow Type</th>
                    <th className="th-white">Amount</th>
                    <th className="th-white">WHT</th>
                    <th className="th-white">Currency</th>
                    <th className="th-white">Bank Account</th>
                    <th className="th-white">Payment Method</th>
                    <th className="th-white">Exchange Rate</th>
                    <th className="th-white">
                      Local Currency
                      <br />
                      Amount
                    </th>
                    <th className="th-white">
                      Local
                      <br />
                      Currency
                    </th>
                    <th className="th-white">Posting Date</th>
                    <th className="th-white">Document No.</th>
                  </tr>
                </thead>
                <tbody>
                  {!(r.result || []).length ? (
                    <tr>
                      <td colSpan="15" align="center">
                        No data available in table
                      </td>
                    </tr>
                  ) : null}
                  {r.result.map((m, i) => (
                    <tr key={i}>
                      <td align="center">
                        {m.startInterestDateStr} - {m.endInterestDateStr}
                      </td>
                      <td align="center">{m.interestDay}</td>
                      <td align="center">{m.paymentDateStr}</td>
                      <td align="center">{m.direction}</td>
                      <td align="center">{m.flowType}</td>
                      <td align="right">{setAmount(m.amount, 2)}</td>
                      <td align="right">{setAmount(m.whtAmount, 2)}</td>
                      <td align="center">{m.currency}</td>
                      <td>
                        {setAccount(
                          m.bankAbbreviate,
                          m.branchName,
                          m.accountTypeCode,
                          m.bankCurrency,
                          m.bankAccountNo
                        )}
                      </td>
                      <td>{m.paymentMethod}</td>
                      <td align="right">{m.exchangeRate ? setAmount(m.exchangeRate, 8) : ''}</td>
                      <td align="right">
                        {m.localCurrencyAmount ? setAmount(m.localCurrencyAmount, 2) : ''}
                      </td>
                      <td align="center">{m.localCurrency}</td>
                      <td align="center">{m.postingDateStr}</td>
                      <td align="center">
                        <a href="#">{m.documentNo}</a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Card>
        ))}
      </Card>
    ) : null}
  </>
);

const setAmount = (number, float) => {
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};

const setAccount = (bankShortName, bankBranch, bankType, bankCurrency, bankAccount) => {
  if (!bankAccount) return '';
  if (bankShortName == null) {
    if ((bankAccount || '').length <= 13)
      return (
        <span>
          {bankBranch} | {bankType} | {bankCurrency} <br />
          {bankAccount}
        </span>
      );
    return (
      <span>
        {bankBranch} | {bankType} | {bankCurrency} <br />
        {bankAccount.substring(0, 13)}
        <br />
        {bankAccount.substring(13, bankAccount.length)}
      </span>
    );
  }
  if ((bankAccount || '').length <= 13)
    return (
      <span>
        {bankShortName} | {bankBranch} | {bankType} | {bankCurrency} <br />
        {bankAccount}
      </span>
    );
  return (
    <span>
      {bankShortName} | {bankBranch} | {bankType} | {bankCurrency} <br />
      {bankAccount.substring(0, 13)}
      <br />
      {bankAccount.substring(13, bankAccount.length)}
    </span>
  );
};
