import React from 'react';

export default ({ onChange, checked, name, bg = 'default' }) => (
  <label className={`switch switch-${bg}`}>
    <input
      className="switch-input"
      type="checkbox"
      checked={checked}
      name={name}
      onChange={onChange}
    />
    <span className="switch-track" />
    <span className="switch-thumb" />
  </label>
);
