import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_SWEEP_MONEY = '@@request/SEARCH_SWEEP_MONEY';
export const RECEIVE_SEARCH_SWEEP_MONEY = '@@receive/SEARCH_SWEEP_MONEY';
export const FAIL_SEARCH_SWEEP_MONEY = '@@fail/SEARCH_SWEEP_MONEY';

export const REQUEST_SUBMIT_SWEEP_MONEY = '@@request/SUBMIT_SWEEP_MONEY';
export const RECEIVE_SUBMIT_SWEEP_MONEY =
  '@@receive-The transaction was successfully saved/SUBMIT_SWEEP_MONEY';
export const FAIL_SUBMIT_SWEEP_MONEY = '@@fail/SUBMIT_SWEEP_MONEY';

export const searchSweep = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/cashposition/sweep'),
    method: 'POST',
    body: JSON.stringify(criteria),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SEARCH_SWEEP_MONEY, RECEIVE_SEARCH_SWEEP_MONEY, FAIL_SEARCH_SWEEP_MONEY],
  },
});

export const submitSweep = (datas, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/cashposition/submitsweep'),
      method: 'POST',
      body: JSON.stringify(datas),
      headers: jsonContentTypeHeader,
      types: [REQUEST_SUBMIT_SWEEP_MONEY, RECEIVE_SUBMIT_SWEEP_MONEY, FAIL_SUBMIT_SWEEP_MONEY],
    },
  });

  if (!actionResponse.error) return await dispatch(searchSweep(criteria));

  return actionResponse;
};
