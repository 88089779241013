import React from 'react';

import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Select2 from '../../common/Select2';
import Table from '../../common/DataTable';
import { toNumber } from '../../common/helpper';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_COMPANY } from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  // deferRender: true,
  // scroller: true,
  // paging: true,
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox-2 result-clearing`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    { data: 'forwardNo' },
    { data: 'contractNo' },
    {
      data: 'outstanding',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, 2),
    },
    {
      data: 'documentAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, 2),
    },
    { data: 'documentCurrency' },
    { data: 'documentGlAccount' },
    {
      data: 'localAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, 2),
    },
    { data: 'localCurrency' },
    { data: 'localGlAccount' },
  ],
};

export default ({
  modalRef,
  state,
  onSubmit,
  onChageModal,
  DATATABLE_ID,
  dataTableRef,
  resultClearing,
}) => (
  <Modal textHeader="Clearing" ref={modalRef} size="modal-xl" bgHeader="bg-primary">
    <form onSubmit={onSubmit}>
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Type" required>
            <Select2
              notMultiple
              onChange={(e) =>
                onChageModal({
                  target: e,
                })
              }
              value={state.Type}
              name="Type"
            >
              <option value="clear 0">Clear 0</option>
              <option value="clear residual">Clear Residual</option>
            </Select2>
          </FormGroup2>

          <FormGroup2 text="Company" required>
            <MasterDropdown
              notMultipleSelect2
              masterType={MASTER_COMPANY}
              onChange={(e) =>
                onChageModal({
                  target: e,
                })
              }
              value={state.CompanyCode}
              name="CompanyCode"
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[2]}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={resultClearing}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all-2 result-clearing" />
              </th>
              <th>Forward No.</th>
              <th>Contract No.</th>
              <th>Outstanding</th>
              <th className="text-center">
                Document
                <br />
                Amount
              </th>
              <th>
                Document
                <br />
                Currency
              </th>
              <th>
                Document
                <br />
                GL Account
              </th>
              <th className="text-center">
                Local
                <br />
                Amount
              </th>
              <th>
                Local
                <br />
                Currency
              </th>
              <th>
                Local
                <br />
                GL Account
              </th>
            </tr>
          </thead>
        </Table>
      </Col2>
      <br />
      <div>
        <center>
          <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
        </center>
      </div>
    </form>
  </Modal>
);
