import { connect } from 'react-redux';
import React, { Component } from 'react';

import TierRate from './TierRate';
import { addAlert } from '../../reducers/layout/action';
import { toLower, toUpperKey } from '../../common/helpper';
import {
  searchTier,
  editCriteria,
  clearCriteria,
  saveMasterTierDetail,
} from '../../reducers/masterTier/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-terirate';

const initData = {
  CompanyCode: '',
  PartnerCode: '',
  DepositRate: 0,
  LoanRate: 0,

  CalculateMethod: '',

  PeriodEndType: '',
  IsManualInclude: '',
  IsManualMonthEnd: '',
  ManualPeriodEnd: '',
  ManualDay: '',
  ManualWorkingDay: '',

  DueDateType: '',
  IsDueDateMonthEnd: '',
  ManualDueDate: '',
  DueDateDay: '',
  DueDateWorkingDay: '',
  Frequency: '',

  Mode: '',
};

class TierRateContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchTier({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    const r = rowData;
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          ManualPeriodEnd:
            toLower(r.periodEndType) === 'end of term'
              ? ''
              : r.isManualMonthEnd
                ? 'Month End'
                : 'Day',
          ManualDueDate:
            toLower(r.dueDateType) === 'end of term'
              ? ''
              : r.isDueDateMonthEnd
                ? 'Month End'
                : 'Day',
          ManualDay: r.periodEndType === 'End of Term' ? null : r.manualDay,
          DueDateDay: r.dueDateType === 'End of Term' ? null : r.dueDateDay,
          IsManualInclude: r.isManualInclude == null ? false : r.isManualInclude,
          Mode: 'update',
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;
    if (name === 'DepositRate') {
      if (
        parseFloat((value.toString() || '').replace(/,/g, '')) <= 0 ||
        parseFloat((value.toString() || '').replace(/,/g, '')) > 100
      ) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Rate Deposit must more than 0 and less than 100.',
        });
        return;
      }
    } else if (name === 'LoanRate') {
      if (
        parseFloat((value.toString() || '').replace(/,/g, '')) <= 0 ||
        parseFloat((value.toString() || '').replace(/,/g, '')) > 100
      ) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Rate Loan must more than 0 and less than 100.',
        });
        return;
      }
    }

    if (name === 'IsManualInclude') {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [name]: !this.state.modalData.IsManualInclude,
        },
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [name]: value,
        },
      });
    }
  };

  onClickSaveModalHandler = (e) => {
    e.preventDefault();
    const state = { ...this.state.modalData };

    if (
      !state.DepositRate ||
      parseFloat((state.DepositRate.toString() || '').replace(/,/g, '')) <= 0 ||
      parseFloat((state.DepositRate.toString() || '').replace(/,/g, '')) > 100
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Rate Deposit must more than 0 and less than 100.',
      });
      return;
    }
    if (
      !state.LoanRate ||
      parseFloat((state.LoanRate.toString() || '').replace(/,/g, '')) <= 0 ||
      parseFloat((state.LoanRate.toString() || '').replace(/,/g, '')) > 100
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Rate Loan must more than 0 and less than 100.',
      });
      return;
    }

    this.props.saveMasterTierDetail({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
      this.modalDataRef.close();
      this.props.searchTier({ ...this.props.criteria });
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    this.setState({
      modalData: {
        ...initData,
        ...toUpperKey(rowData),
        Mode: 'change',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e) => {
    this.props.saveMasterTierDetail({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchTier({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onChangeModalData: this.onChangeModalData,
      onClickSaveModalData: this.onClickSaveModalHandler,

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <div>
        <TierRate
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalDataRef={(e) => (this.modalDataRef = e)}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterTier,
  }),
  {
    searchTier,
    editCriteria,
    clearCriteria,
    addAlert,
    saveMasterTierDetail,
  }
)(TierRateContainer);
