import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_BUSINESS_AREA_CRITERIA = 'EDIT_MASTER_BUSINESS_AREA_CRITERIA';
export const CLEAR_MASTER_BUSINESS_AREA_CRITERIA = 'CLEAR_MASTER_BUSINESS_AREA_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_BUSINESS_AREA =
  'UPDATE_SEARCH_RESULT_MASTER_BUSINESS_AREA';

export const REQUEST_SEARCH_MASTER_BUSINESS_AREA = '@@request/SARCH_MASTER_BUSINESS_AREA';
export const RECEIVE_SEARCH_MASTER_BUSINESS_AREA = '@@receive/SEARCH_MASTER_BUSINESS_AREA';
export const FAIL_SEARCH_MASTER_BUSINESS_AREA = '@@fail/SEARCH_MASTER_BUSINESS_AREA';

export const REQUEST_SAVE_MASTER_BUSINESS_AREA = '@@request/SAVE_MASTER_BUSINESS_AREA';
export const RECEIVE_SAVE_MASTER_BUSINESS_AREA =
  '@@receive-The record was successfully saved/SAVE_MASTER_BUSINESS_AREA';
export const FAIL_SAVE_MASTER_BUSINESS_AREA = '@@fail/SAVE_MASTER_BUSINESS_AREA';

export const REQUEST_DELETE_MASTER_BUSINESS_AREA = '@@request/DELETE_MASTER_BUSINESS_AREA';
export const RECEIVE_DELETE_MASTER_BUSINESS_AREA =
  '@@receive-The record was successfully saved/DELETE_MASTER_BUSINESS_AREA';
export const FAIL_DELETE_MASTER_BUSINESS_AREA = '@@fail/DELETE_MASTER_BUSINESS_AREA';

export const deleteMasterBusinessArea = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemasterbusinessarea`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DELETE_MASTER_BUSINESS_AREA,
      RECEIVE_DELETE_MASTER_BUSINESS_AREA,
      FAIL_DELETE_MASTER_BUSINESS_AREA,
    ],
  },
});

export const saveMasterBusinessArea = (data, criteria) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterbusinessarea`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_BUSINESS_AREA,
      RECEIVE_SAVE_MASTER_BUSINESS_AREA,
      FAIL_SAVE_MASTER_BUSINESS_AREA,
    ],
  },
});

export const searchMasterBusinessArea = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbusinessarea', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_BUSINESS_AREA,
      RECEIVE_SEARCH_MASTER_BUSINESS_AREA,
      FAIL_SEARCH_MASTER_BUSINESS_AREA,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_BUSINESS_AREA,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_BUSINESS_AREA_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_BUSINESS_AREA_CRITERIA,
  payload: {
    name,
    value,
  },
});
