import { combineReducers } from 'redux';

import poa from './poa/reducer';
import bond from './bond/reducer';
import layout from './layout/reducer';
import master from './master/reducer';
import pnLoan from './pnLoan/reducer';
import issuer from './issuer/reducer';
import deposit from './deposit/reducer';
import mtmBond from './mtmBond/reducer';
import sweepMoney from './sweep/reducer';
import cashPlan from './cashPlan/reducer';
import taskList from './taskList/reducer';
import manageFx from './manageFx/reducer';
import reportBg from './reportBg/reducer';
import requestTt from './requestTt/reducer';
import requestBg from './requestBg/reducer';
import masterCoa from './masterCoa/reducer';
import derivative from './derivative/reducer';
import securities from './securities/reducer';
import sweeppn from './reportSweepPn/reducer';
import masterBank from './masterBank/reducer';
import masterRole from './masterRole/reducer';
import masterUser from './masterUser/reducer';
import masterTier from './masterTier/reducer';
import reportLoan from './reportLoan/reducer';
import masterLogo from './masterLogo/reducer';
import moneyMarket from './moneyMarket/reducer';
import reportOther from './reportOther/reducer';
import cashPosition from './cashPosition/reducer';
import autoTransfer from './autoTransfer/reducer';
import ttRemittance from './ttRemittance/reducer';
import contractLoan from './contractLoan/reducer';
import markToMarket from './markToMarket/reducer';
import maintainBank from './maintainBank/reducer';
import maintainForm from './maintainForm/reducer';
import notification from './notification/reducer';
import reportToBank from './reportToBank/reducer';
import eFormGrouping from './eFormGrouping/reducer';
import facilitySetUp from './facilitySetUp/reducer';
import masterCompany from './masterCompany/reducer';
import masterHoliday from './masterHoliday/reducer';
import createExposure from './createExposure/reducer';
import contractOnCall from './contractOnCall/reducer';
import masterCurrency from './masterCurrency/reducer';
import interestReport from './interestReport/reducer';
import masterInterest from './masterInterest/reducer';
import maintainBranch from './maintainBranch/reducer';
import masterInstance from './masterInstance/reducer';
import bookingForward from './bookingForward/reducer';
import fxConfirmation from './fxConfirmation/reducer';
import derivativesFlow from './derivatiesFlow/reducer';
import foreignExchange from './foreignExchange/reducer';
import automateBalance from './automateBalance/reducer';
import masterGlAccount from './masterGlAccount/reducer';
import transferFeeSetup from './transferFeeSetup/reducer';
import exposurePosition from './exposurePosition/reducer';
import masterBankBranch from './masterBankBranch/reducer';
import automateInterest from './automateInterest/reducer';
import masterFlowConfig from './masterFlowConfig/reducer';
import automateExposure from './automateExposure/reducer';
import bankEndingBalance from './bankEndingBalance/reducer';
import journalSecurities from './journalSecurities/reducer';
import masterBankAccount from './masterBankAccount/reducer';
import masterFlowAccount from './masterFlowAccount/reducer';
import journalMoneyMarket from './journalMoneyMarket/reducer';
import journalDerivatives from './journalDerivatives/reducer';
import masterFinanceGroup from './masterFinanceGroup/reducer';
import masterExchangeRate from './masterExchangeRate/reducer';
import masterBusinessUnit from './masterBusinessUnit/reducer';
import masterBusinessArea from './masterBusinessArea/reducer';
import reportLoanIntercom from './reportLoanIntercom/reducer';
import bankTransferSummary from './bankTransferSummary/reducer';
import bankTransferPosting from './bankTransferPosting/reducer';
import reportAccountSaving from './reportAccountSaving/reducer';
import masterPaymentMethod from './masterPaymentMethod/reducer';
import maintainInterestRate from './maintainInterestRate/reducer';
import masterWithHoldingTax from './masterWithHoldingTax/reducer';
import reportInterestReceipt from './reportInterestReceipt/reducer';
import masterAccountStandard from './masterAccountStandard/reducer';
import journalForeignExchange from './journalForeignExchange/reducer';
import masterFlowAccountDetail from './masterFlowAccountDetail/reducer';
import masterBankAccountOfBank from './masterBankAccountOfBank/reducer';
import journalBankTransferPosting from './journalBankTransferPosting/reducer';

const rootReducer = combineReducers({
  layout,
  bankEndingBalance,
  cashPlan,
  bankTransferSummary,
  master,
  cashPosition,
  autoTransfer,
  sweepMoney,
  transferFeeSetup,
  bankTransferPosting,
  taskList,
  createExposure,
  exposurePosition,
  manageFx,
  eFormGrouping,
  foreignExchange,
  moneyMarket,
  ttRemittance,
  maintainInterestRate,
  pnLoan,
  facilitySetUp,
  contractLoan,
  contractOnCall,
  deposit,
  bond,
  derivative,
  issuer,
  markToMarket,
  securities,
  derivativesFlow,
  journalForeignExchange,
  journalMoneyMarket,
  journalSecurities,
  journalDerivatives,
  journalBankTransferPosting,
  sweeppn,
  mtmBond,
  masterBank,
  masterBankAccount,
  masterFinanceGroup,
  masterCompany,
  masterExchangeRate,
  masterRole,
  masterUser,
  masterCurrency,
  masterWithHoldingTax,
  masterFlowAccount,
  masterFlowAccountDetail,
  masterBusinessUnit,
  masterBusinessArea,
  masterBankBranch,
  masterHoliday,
  masterTier,
  interestReport,
  requestTt,
  reportLoan,
  reportLoanIntercom,
  masterInterest,
  reportAccountSaving,
  maintainBank,
  maintainBranch,
  maintainForm,
  requestBg,
  automateInterest,
  automateBalance,
  reportBg,
  masterCoa,
  masterInstance,
  masterGlAccount,
  masterFlowConfig,
  masterPaymentMethod,
  reportInterestReceipt,
  masterLogo,
  masterAccountStandard,
  notification,
  bookingForward,
  fxConfirmation,
  automateExposure,
  reportToBank,
  masterBankAccountOfBank,
  poa,
  reportOther,
});
export default rootReducer;
