import React from 'react';

import Col2 from '../../common/Col2';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_CURRENCY } from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const fieldTh = {
  'Elec No.': '(เครื่องวัดหน่วยไฟฟ้าเลขที่)',
  'Elect Contract No.': '(สัญญาเลขที่/หมายเลขผู้ใช้ไฟฟ้า (CA No.))',
  'Security No.': '(เลขที่หลักประกัน)',
  'Bp No.': '(หมายเลขคู่ค้าทางธุรกิจ)',
  Address: '(สถานที่ใช้ไฟฟ้าเลขที่)',
  Street: '(ถนน)',
  Subdistrict: '(แขวง/ตำบล)',
  District: '(เขต/อำเภอ)',
  Province: '(จังหวัด)',

  'Project Name': '(ชื่อโครงการ)',
  'Project Description': '(รายละเอียดโครงการ)',
  'Contract No.': '(เลขที่เอกสารประกวดราคา)',
  'Contract Date': '(วันที่ลงนามในสัญญา)',
  'Contract Percent':
    '(จำนวนเงินค้ำประกันคิดเป็นอัตราร้อยละ (% ของมูลค่าสัญญา ที่ท่านได้จ่ายให้แก่ลูกค้าเป็นการล่วงหน้า))',

  'Shipping No.': '(เลขที่ตามใบขนสินค้าเลขที่)',
  'Shipping Count': '(จำนวน)',
  Resident: '(เข้าในราชอาณาจักรโดย)',
  'Import Date': '(วันที่นำเข้า)',
  'Cust Dept Date': '(วันที่ตามใบขนสินค้าที่ได้ยื่นต่อกรมศุลกากรเมื่อวันที่)',

  'Bid Maturity Date': '(วันที่สิ้นสุดการยื่นซอง)',
  'Project Value': '(มูลค่าโครงการ/จำนวนเงินให้กู้ยืม)',
};

export default ({ formRef, state, onChangeInputData, noEdit, facilityList = [], modeAmend }) => {
  const bgFields = state.bgFieldList;
  return (
    <form ref={formRef} name="structure">
      <Col2 col={colX[1]}>
        <Col2 col={colX[0]}>
          <FormGroup2 text="Branch Name" textTh="(ชื่อสาขา)" required={false}>
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BranchName || ''}
              name="BranchName"
              required={false}
              disabled
            />
          </FormGroup2>

          <FormGroup2 text="Branch Address" textTh="(ที่อยู่สาขา)" required={false}>
            <textarea
              className="form-control"
              rows="2"
              onChange={onChangeInputData}
              value={state.BranchAddress || ''}
              name="BranchAddress"
              required={false}
              disabled
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[0]}>
          <FormGroup2 text="Branch Tel." textTh="(เบอร์โทรศัพท์ของสาขา)" required={false}>
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BranchPhoneNo || ''}
              name="BranchPhoneNo"
              required={false}
              disabled
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[1]}>
        <Col2 col={colX[0]}>
          <FormGroup2 text="Issue Date" textTh="(วันที่ Issue LG)" required>
            <div className="input-with-icon">
              <DatePicker
                onChange={onChangeInputData}
                option={{
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required
                disabled={modeAmend ? false : noEdit}
                value={state.IssueDateStr}
                name="IssueDateStr"
                placeholder="Select Issue Date"
              />
              <span className="icon icon-calendar input-icon" />
            </div>
          </FormGroup2>

          <FormGroup2 text="Effective Date" textTh="(วันที่เริ่มค้ำประกัน)" required>
            <div className="input-with-icon">
              <DatePicker
                onChange={onChangeInputData}
                option={{
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required
                disabled={noEdit}
                value={state.EffectiveDateStr}
                name="EffectiveDateStr"
                placeholder="Select Effective Date"
              />
              <span className="icon icon-calendar input-icon" />
            </div>
          </FormGroup2>
        </Col2>

        <Col2 col={colX[0]}>
          <FormGroup2 text="Term End" textTh="(ประเภทสิ้นสุดสัญญา)" required>
            <select
              tabIndex="-1"
              className="form-control"
              value={state.TermEndType}
              onChange={onChangeInputData}
              required
              disabled={noEdit || state.TermEndDisabled}
              name="TermEndType"
            >
              <option value="">---Choose---</option>
              <option value="Open End">Open End</option>
              <option value="Close End">Close End</option>
            </select>
          </FormGroup2>

          <FormGroup2 text="Expire Date" textTh="(วันที่สิ้นสุดค้ำประกัน)" required>
            <div className="input-with-icon">
              <DatePicker
                onChange={onChangeInputData}
                option={{
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required
                value={state.TermEndType === 'Open End' ? '31/12/9999' : state.ExpireDateStr}
                disabled={state.TermEndType === 'Open End' ? true : modeAmend ? false : noEdit}
                name="ExpireDateStr"
                placeholder="Select Expire Date"
              />
              <span className="icon icon-calendar input-icon" />
            </div>
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[1]}>
        <Col2 col={colX[0]}>
          <FormGroup2 text="Amount" textTh="(จำนวนเงินค้ำประกัน (บาท))" required>
            <InputMask
              className="form-control"
              format="currency"
              onChange={onChangeInputData}
              option={{
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }}
              required
              disabled={noEdit}
              value={state.Amount}
              name="Amount"
            />
          </FormGroup2>

          <FormGroup2 text="Currency" textTh="(สกุลเงิน)" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.Currency || ''}
                name="Currency"
                readOnly
              />
            ) : (
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                filter={(m) =>
                  ~['thb', 'usd', 'cny', 'eur', 'gbp', 'sgd', 'jpy', 'hkd', 'chf', 'sek'].indexOf(
                    (m.currency || '').toLowerCase()
                  )
                }
                isChoose
                notMultipleSelect2
                required
                value={state.Currency}
                name="Currency"
              />
            )}
          </FormGroup2>
        </Col2>

        <Col2 col={colX[0]}>
          <FormGroup2
            text="Remark for Bank"
            textTh="(หมายเหตุสำหรับส่งให้ธนาคาร)"
            required={false}
            maxLength={1000}
            length={(state.Remark || '').length}
          >
            <textarea
              className="form-control"
              rows="2"
              onChange={onChangeInputData}
              value={state.Remark || ''}
              name="Remark"
              maxLength={1000}
              required={false}
              disabled={noEdit}
            />
          </FormGroup2>

          <FormGroup2
            text="Remark for Finance"
            textTh="(หมายเหตุสำหรับ Financial)"
            required={false}
            maxLength={1000}
            length={(state.RemarkForFinance || '').length}
          >
            <textarea
              className="form-control"
              rows="2"
              onChange={onChangeInputData}
              value={state.RemarkForFinance || ''}
              name="RemarkForFinance"
              maxLength={1000}
              required={false}
              disabled={noEdit}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[1]}>
        <Col2 col={colX[0]}>
          <FormGroup2 text="Facility" textTh="(วงเงินของ BG)" required={false}>
            <select
              tabIndex="-1"
              className="form-control"
              onChange={onChangeInputData}
              required={false}
              disabled={modeAmend ? false : noEdit}
              value={state.FacilityNo}
              name="FacilityNo"
            >
              <option value="">---Choose---</option>
              {facilityList.map((m) => (
                <option value={m.transactionNo} key={m.transactionNo}>
                  {m.facilityName}
                </option>
              ))}
            </select>
          </FormGroup2>
          <FormGroup2 text="Outstanding" textTh="(วงเงินของ BG ที่เหลืออยู่)" required={false}>
            <InputMask
              className="form-control"
              format="currency"
              onChange={onChangeInputData}
              option={{
                prefix: '',
                digits: 0,
              }}
              disabled
              value={state.Outstanding}
              name="Outstanding"
            />
          </FormGroup2>
        </Col2>

        {(modeAmend || state.OldTransactionNo) && (
          <Col2 col={colX[0]}>
            <FormGroup2
              text="Amend Description"
              textTh="(รายละเอียดขอ Amend)"
              required
              maxLength={1000}
              length={(state.AmendDescription || '').length}
            >
              <textarea
                className="form-control"
                rows="2"
                onChange={onChangeInputData}
                value={state.AmendDescription || ''}
                name="AmendDescription"
                maxLength={1000}
                required
                disabled={modeAmend ? false : noEdit}
              />
            </FormGroup2>
          </Col2>
        )}
      </Col2>

      {(bgFields || []).map((m, k) => (
        <Col2 col={colX[1]} key={k}>
          <Col2 col={colX[0]}>
            {m[0] ? (
              <>
                {(m[0].dataType || '') === 'varchar' && (m[0].dataLength || 0) <= 300 ? (
                  <FormGroup2
                    text={m[0].fieldName}
                    textTh={fieldTh[m[0].fieldName]}
                    required={m[0].isRequire}
                    maxLength={m[0].dataLength || 0}
                    length={(state[(m[0].fieldValue || '').trim()] || '').length}
                  >
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      value={state[(m[0].fieldValue || '').trim()] || ''}
                      name={`${(m[0].fieldValue || '').trim()}`}
                      maxLength={m[0].dataLength || 0}
                      required={m[0].isRequire}
                      disabled={modeAmend ? false : noEdit}
                    />
                  </FormGroup2>
                ) : (m[0].dataType || '') === 'varchar' && (m[0].dataLength || 0) > 300 ? (
                  <FormGroup2
                    text={m[0].fieldName}
                    textTh={fieldTh[m[0].fieldName]}
                    required={m[0].isRequire}
                    maxLength={m[0].dataLength || 0}
                    length={(state[(m[0].fieldValue || '').trim()] || '').length}
                  >
                    <textarea
                      className="form-control"
                      rows="2"
                      onChange={onChangeInputData}
                      value={state[(m[0].fieldValue || '').trim()] || ''}
                      name={`${(m[0].fieldValue || '').trim()}`}
                      maxLength={m[0].dataLength || 0}
                      required={m[0].isRequire}
                      disabled={modeAmend ? false : noEdit}
                    />
                  </FormGroup2>
                ) : (m[0].dataType || '') === 'decimal' ? (
                  <FormGroup2
                    text={m[0].fieldName}
                    textTh={fieldTh[m[0].fieldName]}
                    required={m[0].isRequire}
                  >
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChangeInputData}
                      option={{
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }}
                      value={state[(m[0].fieldValue || '').trim()]}
                      name={`${(m[0].fieldValue || '').trim()}`}
                      required={m[0].isRequire}
                      disabled={modeAmend ? false : noEdit}
                    />
                  </FormGroup2>
                ) : (m[0].dataType || '') === 'int' ? (
                  <FormGroup2
                    text={m[0].fieldName}
                    textTh={fieldTh[m[0].fieldName]}
                    required={m[0].isRequire}
                  >
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChangeInputData}
                      option={{
                        prefix: '',
                        digits: 0,
                        digitsOptional: false,
                        placeholder: '0',
                      }}
                      value={state[(m[0].fieldValue || '').trim()]}
                      name={`${(m[0].fieldValue || '').trim()}`}
                      required={m[0].isRequire}
                      disabled={modeAmend ? false : noEdit}
                    />
                  </FormGroup2>
                ) : (m[0].dataType || '') === 'datetime' || (m[0].dataType || '') === 'date' ? (
                  <FormGroup2
                    text={m[0].fieldName}
                    textTh={fieldTh[m[0].fieldName]}
                    required={m[0].isRequire}
                  >
                    <div className="input-with-icon">
                      <DatePicker
                        onChange={onChangeInputData}
                        option={{
                          // daysOfWeekDisabled: "0,6",
                          todayHighlight: true,
                        }}
                        value={state[(m[0].fieldValue || '').trim()]}
                        name={`${(m[0].fieldValue || '').trim()}`}
                        required={m[0].isRequire}
                        placeholder={`Select ${m[0].fieldName}`}
                        disabled={modeAmend ? false : noEdit}
                      />
                      <span className="icon icon-calendar input-icon" />
                    </div>
                  </FormGroup2>
                ) : null}
              </>
            ) : null}
            {m[1] ? (
              <>
                {(m[1].dataType || '') === 'varchar' && (m[1].dataLength || 0) <= 300 ? (
                  <FormGroup2
                    text={m[1].fieldName}
                    textTh={fieldTh[m[1].fieldName]}
                    required={m[1].isRequire}
                    maxLength={m[1].dataLength || 0}
                    length={(state[(m[1].fieldValue || '').trim()] || '').length}
                  >
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      value={state[(m[1].fieldValue || '').trim()] || ''}
                      name={`${(m[1].fieldValue || '').trim()}`}
                      maxLength={m[1].dataLength || 0}
                      required={m[1].isRequire}
                      disabled={modeAmend ? false : noEdit}
                    />
                  </FormGroup2>
                ) : (m[1].dataType || '') === 'varchar' && (m[1].dataLength || 0) > 300 ? (
                  <FormGroup2
                    text={m[1].fieldName}
                    textTh={fieldTh[m[1].fieldName]}
                    required={m[1].isRequire}
                    maxLength={m[1].dataLength || 0}
                    length={(state[(m[1].fieldValue || '').trim()] || '').length}
                  >
                    <textarea
                      className="form-control"
                      rows="2"
                      onChange={onChangeInputData}
                      value={state[(m[1].fieldValue || '').trim()] || ''}
                      name={`${(m[1].fieldValue || '').trim()}`}
                      maxLength={m[1].dataLength || 0}
                      required={m[1].isRequire}
                      disabled={modeAmend ? false : noEdit}
                    />
                  </FormGroup2>
                ) : (m[1].dataType || '') === 'decimal' ? (
                  <FormGroup2
                    text={m[1].fieldName}
                    textTh={fieldTh[m[1].fieldName]}
                    required={m[1].isRequire}
                  >
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChangeInputData}
                      option={{
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }}
                      value={state[(m[1].fieldValue || '').trim()]}
                      name={`${(m[1].fieldValue || '').trim()}`}
                      required={m[1].isRequire}
                      disabled={modeAmend ? false : noEdit}
                    />
                  </FormGroup2>
                ) : (m[1].dataType || '') === 'int' ? (
                  <FormGroup2
                    text={m[1].fieldName}
                    textTh={fieldTh[m[1].fieldName]}
                    required={m[1].isRequire}
                  >
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChangeInputData}
                      option={{
                        prefix: '',
                        digits: 0,
                        digitsOptional: false,
                        placeholder: '0',
                      }}
                      value={state[(m[1].fieldValue || '').trim()]}
                      name={`${(m[1].fieldValue || '').trim()}`}
                      required={m[1].isRequire}
                      disabled={modeAmend ? false : noEdit}
                    />
                  </FormGroup2>
                ) : (m[1].dataType || '') === 'datetime' || (m[1].dataType || '') === 'date' ? (
                  <FormGroup2
                    text={m[1].fieldName}
                    textTh={fieldTh[m[1].fieldName]}
                    required={m[1].isRequire}
                  >
                    <div className="input-with-icon">
                      <DatePicker
                        onChange={onChangeInputData}
                        option={{
                          // daysOfWeekDisabled: "0,6",
                          todayHighlight: true,
                        }}
                        value={state[(m[1].fieldValue || '').trim()]}
                        name={`${(m[1].fieldValue || '').trim()}`}
                        required={m[1].isRequire}
                        placeholder={`Select ${m[1].fieldName}`}
                        disabled={modeAmend ? false : noEdit}
                      />
                      <span className="icon icon-calendar input-icon" />
                    </div>
                  </FormGroup2>
                ) : null}
              </>
            ) : null}
          </Col2>

          {m[2] || m[3] ? (
            <Col2 col={colX[0]}>
              {m[2] ? (
                <>
                  {(m[2].dataType || '') === 'varchar' && (m[2].dataLength || 0) <= 300 ? (
                    <FormGroup2
                      text={m[2].fieldName}
                      textTh={fieldTh[m[2].fieldName]}
                      required={m[2].isRequire}
                      maxLength={m[2].dataLength || 0}
                      length={(state[(m[2].fieldValue || '').trim()] || '').length}
                    >
                      <input
                        className="form-control"
                        onChange={onChangeInputData}
                        value={state[(m[2].fieldValue || '').trim()] || ''}
                        name={`${(m[2].fieldValue || '').trim()}`}
                        maxLength={m[2].dataLength || 0}
                        required={m[2].isRequire}
                        disabled={modeAmend ? false : noEdit}
                      />
                    </FormGroup2>
                  ) : (m[2].dataType || '') === 'varchar' && (m[2].dataLength || 0) > 300 ? (
                    <FormGroup2
                      text={m[2].fieldName}
                      textTh={fieldTh[m[2].fieldName]}
                      required={m[2].isRequire}
                      maxLength={m[2].dataLength || 0}
                      length={(state[(m[2].fieldValue || '').trim()] || '').length}
                    >
                      <textarea
                        className="form-control"
                        rows="2"
                        onChange={onChangeInputData}
                        value={state[(m[2].fieldValue || '').trim()] || ''}
                        name={`${(m[2].fieldValue || '').trim()}`}
                        maxLength={m[2].dataLength || 0}
                        required={m[2].isRequire}
                        disabled={modeAmend ? false : noEdit}
                      />
                    </FormGroup2>
                  ) : (m[2].dataType || '') === 'decimal' ? (
                    <FormGroup2
                      text={m[2].fieldName}
                      textTh={fieldTh[m[2].fieldName]}
                      required={m[2].isRequire}
                    >
                      <InputMask
                        className="form-control"
                        format="currency"
                        onChange={onChangeInputData}
                        option={{
                          prefix: '',
                          digits: 2,
                          digitsOptional: false,
                          placeholder: '0.00',
                        }}
                        value={state[(m[2].fieldValue || '').trim()]}
                        name={`${(m[2].fieldValue || '').trim()}`}
                        required={m[2].isRequire}
                        disabled={modeAmend ? false : noEdit}
                      />
                    </FormGroup2>
                  ) : (m[2].dataType || '') === 'int' ? (
                    <FormGroup2
                      text={m[2].fieldName}
                      textTh={fieldTh[m[2].fieldName]}
                      required={m[2].isRequire}
                    >
                      <InputMask
                        className="form-control"
                        format="currency"
                        onChange={onChangeInputData}
                        option={{
                          prefix: '',
                          digits: 0,
                          digitsOptional: false,
                          placeholder: '0',
                        }}
                        value={state[(m[2].fieldValue || '').trim()]}
                        name={`${(m[2].fieldValue || '').trim()}`}
                        required={m[2].isRequire}
                        disabled={modeAmend ? false : noEdit}
                      />
                    </FormGroup2>
                  ) : (m[2].dataType || '') === 'datetime' || (m[2].dataType || '') === 'date' ? (
                    <FormGroup2
                      text={m[2].fieldName}
                      textTh={fieldTh[m[2].fieldName]}
                      required={m[2].isRequire}
                    >
                      <div className="input-with-icon">
                        <DatePicker
                          onChange={onChangeInputData}
                          option={{
                            // daysOfWeekDisabled: "0,6",
                            todayHighlight: true,
                          }}
                          value={state[(m[2].fieldValue || '').trim()]}
                          name={`${(m[2].fieldValue || '').trim()}`}
                          required={m[2].isRequire}
                          placeholder={`Select ${m[2].fieldName}`}
                          disabled={modeAmend ? false : noEdit}
                        />
                        <span className="icon icon-calendar input-icon" />
                      </div>
                    </FormGroup2>
                  ) : null}
                </>
              ) : null}
              {m[3] ? (
                <>
                  {(m[3].dataType || '') === 'varchar' && (m[3].dataLength || 0) <= 300 ? (
                    <FormGroup2
                      text={m[3].fieldName}
                      textTh={fieldTh[m[3].fieldName]}
                      required={m[3].isRequire}
                      maxLength={m[3].dataLength || 0}
                      length={(state[(m[3].fieldValue || '').trim()] || '').length}
                    >
                      <input
                        className="form-control"
                        onChange={onChangeInputData}
                        value={state[(m[3].fieldValue || '').trim()] || ''}
                        name={`${(m[3].fieldValue || '').trim()}`}
                        maxLength={m[3].dataLength || 0}
                        required={m[3].isRequire}
                        disabled={modeAmend ? false : noEdit}
                      />
                    </FormGroup2>
                  ) : (m[3].dataType || '') === 'varchar' && (m[3].dataLength || 0) > 300 ? (
                    <FormGroup2
                      text={m[3].fieldName}
                      textTh={fieldTh[m[3].fieldName]}
                      required={m[3].isRequire}
                      maxLength={m[3].dataLength || 0}
                      length={(state[(m[3].fieldValue || '').trim()] || '').length}
                    >
                      <textarea
                        className="form-control"
                        rows="2"
                        onChange={onChangeInputData}
                        value={state[(m[3].fieldValue || '').trim()] || ''}
                        name={`${(m[3].fieldValue || '').trim()}`}
                        maxLength={m[3].dataLength || 0}
                        required={m[3].isRequire}
                        disabled={modeAmend ? false : noEdit}
                      />
                    </FormGroup2>
                  ) : (m[3].dataType || '') === 'decimal' ? (
                    <FormGroup2
                      text={m[3].fieldName}
                      textTh={fieldTh[m[3].fieldName]}
                      required={m[3].isRequire}
                    >
                      <InputMask
                        className="form-control"
                        format="currency"
                        onChange={onChangeInputData}
                        option={{
                          prefix: '',
                          digits: 2,
                          digitsOptional: false,
                          placeholder: '0.00',
                        }}
                        value={state[(m[3].fieldValue || '').trim()]}
                        name={`${(m[3].fieldValue || '').trim()}`}
                        required={m[3].isRequire}
                        disabled={modeAmend ? false : noEdit}
                      />
                    </FormGroup2>
                  ) : (m[3].dataType || '') === 'int' ? (
                    <FormGroup2
                      text={m[3].fieldName}
                      textTh={fieldTh[m[3].fieldName]}
                      required={m[3].isRequire}
                    >
                      <InputMask
                        className="form-control"
                        format="currency"
                        onChange={onChangeInputData}
                        option={{
                          prefix: '',
                          digits: 0,
                          digitsOptional: false,
                          placeholder: '0',
                        }}
                        value={state[(m[3].fieldValue || '').trim()]}
                        name={`${(m[3].fieldValue || '').trim()}`}
                        required={m[3].isRequire}
                        disabled={modeAmend ? false : noEdit}
                      />
                    </FormGroup2>
                  ) : (m[3].dataType || '') === 'datetime' || (m[3].dataType || '') === 'date' ? (
                    <FormGroup2
                      text={m[3].fieldName}
                      textTh={fieldTh[m[3].fieldName]}
                      required={m[3].isRequire}
                    >
                      <div className="input-with-icon">
                        <DatePicker
                          onChange={onChangeInputData}
                          option={{
                            // daysOfWeekDisabled: "0,6",
                            todayHighlight: true,
                          }}
                          value={state[(m[3].fieldValue || '').trim()]}
                          name={`${(m[3].fieldValue || '').trim()}`}
                          required={m[3].isRequire}
                          placeholder={`Select ${m[3].fieldName}`}
                          disabled={modeAmend ? false : noEdit}
                        />
                        <span className="icon icon-calendar input-icon" />
                      </div>
                    </FormGroup2>
                  ) : null}
                </>
              ) : null}
            </Col2>
          ) : null}
        </Col2>
      ))}
    </form>
  );
};
