import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import { toNumber, toAccount } from '../../common/helpper';
import { DATATABLE_ID } from './BankTransferPostingJournalContainer';
import MasterDropdownUI, { MASTER_TRANSFER_METHOD } from '../../common/MasterDropdownUI';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_ACCOUNT,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const required = true;

const opt = {
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    { data: 'accountingStandard' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'productType', className: 'text-center' },
    { data: 'partnerType', className: 'text-center' },
    { data: 'transactionType', className: 'text-center' },
    { data: 'partnerCode', className: 'text-center' },
    { data: 'partnerName' },
    { data: 'transactionNo' },
    { data: 'flowNo', className: 'text-center' },
    { data: 'flowName' },
    { data: 'postingProcess' },
    { data: 'valueDateStr', className: 'text-center' },
    {
      data: 'postingKey',
      className: 'text-center',
      render: (d, t, r) => {
        if (d === '40') return '+';
        return '-';
      },
    },
    { data: 'drCr', className: 'text-center' },
    { data: 'postingKey', className: 'text-center' },
    { data: 'glAccount' },
    { data: 'customerVendorCode' },
    { data: 'spGlAccount' },
    { data: 'glAccountDescription' },
    { data: 'transactionAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'transactionCurrency', className: 'text-center' },
    { data: 'exchangeRate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'lcAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'localCurrency', className: 'text-center' },
    { data: 'gvcTerm' },
    { data: 'contractNo' },
    { data: 'postingDocumentNo' },
    { data: 'clearingDocumentNo' },
    { data: 'reversedDocNo' },
    {
      data: 'bankAccountNo',
      render: (d, r, s) => {
        if (d)
          return toAccount(
            s.gainLossBankAbbreviate,
            s.gainLossBranchName,
            s.gainLossAccountTypeCode,
            s.gainLossBankCurrency,
            d
          );
        return '';
      },
    },
    { data: 'paymentMethod', className: 'text-center' },
    { data: 'postGlStatus', className: 'text-center' },
    { data: 'postingDateStr', className: 'text-center' },
    { data: 'assignment' },
    { data: 'interestText' },
    { data: 'dueOnStr', className: 'text-center' },
    { data: 'instructionKey' },
    { data: 'baseAmount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'interestRate', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'calculFrom' },
    { data: 'calculTo' },
    { data: 'numberOfDays', className: 'text-right' },
    { data: 'noBaseDays' },
    { data: 'domesticOverseas' },
    { data: 'wTaxType' },
    { data: 'wTaxCode' },
    { data: 'wTaxBase' },
    { data: 'wTaxAmt' },
    { data: 'wTaxBaseLc' },
    { data: 'wTaxAmtLc' },
  ],
};

export default ({
  criteria,
  results,
  dataTableRef,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Accounting <span className="icon icon-angle-double-right" /> Posting Journal{' '}
          <span className="icon icon-angle-double-right" /> Bank Transfer Posting
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroup}
                name="FinanceGroup"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit" required={false}>
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnit}
                name="BusinessUnit"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companys}
                saveLocalState
                financeGroupId={criteria.FinanceGroup}
                businessUnitId={criteria.BusinessUnit}
                name="Companys"
              />
            </FormGroup2>

            <FormGroup2 text="Currency">
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={(e) =>
                  onChangeSearch({
                    target: e,
                  })
                }
                isAll
                notMultipleSelect2
                value={criteria.Currency}
                name="Currency"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Applicant Bank">
              <MasterDropdown
                masterType={MASTER_BANK}
                onChange={(e) =>
                  onChangeSearch({
                    target: e,
                  })
                }
                isAll
                notMultipleSelect2
                value={
                  criteria.ApplicantBank === '' || !criteria.ApplicantBank
                    ? 'all'
                    : criteria.ApplicantBank
                }
                saveLocalState
                companyCode={criteria.Companys}
                name="ApplicantBank"
              />
            </FormGroup2>

            <FormGroup2 text="Applicant Bank Account">
              <MasterDropdown
                masterType={MASTER_BANK_ACCOUNT}
                onChange={(e) =>
                  onChangeSearch({
                    target: e,
                  })
                }
                isAll
                notMultipleSelect2
                saveLocalState
                companyCode={criteria.Companys}
                bankAbbreviate={criteria.ApplicantBank === 'all' ? '' : criteria.ApplicantBank}
                customeValue={(m) => `${m.bankAccountNo}`}
                value={
                  criteria.ApplicantAccountNo === '' || !criteria.ApplicantAccountNo
                    ? 'all'
                    : criteria.ApplicantAccountNo
                }
                name="ApplicantAccountNo"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Beneficiary Bank">
              <MasterDropdown
                masterType={MASTER_BANK}
                onChange={(e) =>
                  onChangeSearch({
                    target: e,
                  })
                }
                isAll
                notMultipleSelect2
                saveLocalState
                companyCode={criteria.Companys}
                value={
                  criteria.BeneficiaryBank === '' || !criteria.BeneficiaryBank
                    ? 'all'
                    : criteria.BeneficiaryBank
                }
                name="BeneficiaryBank"
              />
            </FormGroup2>

            <FormGroup2 text="Beneficiary Bank Account">
              <MasterDropdown
                masterType={MASTER_BANK_ACCOUNT}
                onChange={(e) =>
                  onChangeSearch({
                    target: e,
                  })
                }
                isAll
                notMultipleSelect2
                saveLocalState
                companyCode={criteria.Companys}
                bankAbbreviate={criteria.BeneficiaryBank === 'all' ? '' : criteria.BeneficiaryBank}
                customeValue={(m) => `${m.bankAccountNo}`}
                value={
                  criteria.BeneficiaryAccountNo === '' || !criteria.BeneficiaryAccountNo
                    ? 'all'
                    : criteria.BeneficiaryAccountNo
                }
                name="BeneficiaryAccountNo"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Transfer Method">
              <MasterDropdownUI
                masterType={MASTER_TRANSFER_METHOD}
                onChange={onChangeSearch}
                isAll
                notMultiple
                value={criteria.TransferMethod}
                name="TransferMethod"
                options={null}
              />
            </FormGroup2>

            <FormGroup2 text="Sending Type">
              <MasterDropdownUI
                onChange={onChangeSearch}
                isAll
                notMultiple
                value={criteria.SendingType}
                name="SendingType"
                options={['N/A', 'SFTP', 'Paper', 'Paper with Signature']}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Post G/L Status">
              <MasterDropdownUI
                onChange={onChangeSearch}
                isAll
                notMultiple
                value={criteria.PostGLStatus}
                name="PostGLStatus"
                options={
                  criteria.TransferMethod === 'Auto Deduct'
                    ? ['New', 'In Progress', 'Failed', 'Completed', 'No Post']
                    : ['New', 'In Progress', 'Failed', 'Completed', 'No Post']
                }
              />
            </FormGroup2>

            <FormGroup2 text="Cheque Status">
              <MasterDropdownUI
                onChange={onChangeSearch}
                isAll
                notMultiple
                value={criteria.ChequeStatus}
                name="ChequeStatus"
                options={['Wait for Cheque No.', 'Failed', 'Completed']}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Value Date" required>
              <DateRangePicker
                startDate={window.moment().add(0, 'days')}
                endDate={window.moment().add(0, 'days')}
                onChange={onChangeSearch}
                required={required}
                value={criteria.ValueDate}
                name="ValueDate"
              />
            </FormGroup2>

            <FormGroup2 text="Instance" required>
              <MasterDropdownUI
                onChange={onChangeSelect2}
                required={required}
                value={criteria.InstanceCode}
                options={[
                  'SAP 4.7',
                  'SAP ECC CERAMICS',
                  'SAP ECC CBMR',
                  'SAP ECC PACKAGING',
                  'SAP ECC CHEMICAL',
                ]}
                name="InstanceCode"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Accounting Standard</th>
            <th>
              Company
              <br /> Code
            </th>
            <th>Company Name</th>
            <th>Product Type</th>
            <th>Partner Type</th>
            <th>Transaction Type</th>
            <th>Partner Code</th>
            <th>Partner Name</th>
            <th>Transaction No.</th>
            <th>Flow No.</th>
            <th>Flow Name</th>
            <th>Posting Process</th>
            <th>Value Date</th>
            <th>D</th>
            <th>Dr./Cr.</th>
            <th>Pt Key</th>
            <th>G/L Account</th>
            <th>Customer/Vendor Code</th>
            <th>Sp. G/L Account</th>
            <th>G/L Account Description</th>
            <th>Transaction Amount</th>
            <th>Transaction Currency</th>
            <th>Exchange Rate</th>
            <th>LC Amount</th>
            <th>Local Currency</th>
            <th>GVC / Term</th>
            <th>Contract No.</th>
            <th>Posting Doc. No.</th>
            <th>Clearing Doc. No.</th>
            <th>Reversed Doc. No.</th>
            <th>Bank Account</th>
            <th>Payment Method</th>
            <th>Post G/L Status</th>
            <th>Posting Date</th>
            <th>Assignment</th>
            <th>Text</th>
            <th>Due on</th>
            <th>Instruction Key</th>
            <th>Base Amount</th>
            <th>Interest Rate</th>
            <th>Calculate From</th>
            <th>Calculate To</th>
            <th>Number of days</th>
            <th>No. base days</th>
            <th>Domestic/Overseas</th>
            <th>W/Tax Type</th>
            <th>W/Tax Code</th>
            <th>W/Tax Base</th>
            <th>W/Tax Amt</th>
            <th>W/Tax Base LC</th>
            <th>W/Tax Amt Lc</th>
          </tr>
        </thead>
      </Table>
    </Card>
  </div>
);
