import { RECEIVE_SEARCH_REPORT_ACCOUNT_SAVING } from './action';

const init = {
  searchResult: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_SEARCH_REPORT_ACCOUNT_SAVING:
      return {
        ...state,
        searchResult: action.payload,
      };
    default:
      return state;
  }
};
