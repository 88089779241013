import React, { Component } from 'react';

import Card from './Card';
import Col2 from './Col2';
import Modal from './Modal';
import Button from './Button';
import DataTable from './DataTable';
import ModalAlert from './ModalAlert';

const allowExt = ['xlsx', 'xlx', 'csv'];
class ImportExcel extends Component {
  state = {
    selectedFile: null,
    view: 'total',
  };

  onClickChangeViewData = (view) => {
    this.setState({
      view,
    });
  };

  onSubmitFormUploadExcel = (e) => {
    e.preventDefault();
    const { selectedFile } = this.state;
    if (!selectedFile) {
      this.alert.open({
        type: 'warning',
        body: 'Please select file.',
        title: 'Warning',
      });
      return;
    }
    const s = selectedFile.name.split('.');
    const ext = s[s.length - 1].toLowerCase();
    if (!allowExt.includes(ext)) {
      this.alert.open({
        type: 'error',
        body: `Mime not support for upload, type : ${ext}`,
        title: 'Error',
      });
      return;
    }

    const { onUpload } = this.props;
    if (onUpload) onUpload(selectedFile);
  };

  open = () => {
    this.setState({
      selectedFile: null,
    });
    this.modal.open();
    setTimeout(() => {
      window.$(window).trigger('resize');
    }, 100);
  };

  close = () => {
    this.modal.close();
  };

  onClickSaveImport = () => {
    const { onClickSaveImport } = this.props;
    if (onClickSaveImport) onClickSaveImport();
  };

  onChangeFileUpload = (e) => {
    const { files } = e.target;
    if (files.length) {
      this.setState({
        selectedFile: files[0],
      });
    }
    e.target.value = '';
  };

  render() {
    const { value = {}, optionDt, tableName } = this.props;
    let { error, success, total, rows = [] } = value;
    const { selectedFile, view } = this.state;
    rows =
      view === 'success'
        ? rows.filter((f) => !f.isError)
        : view === 'error'
          ? rows.filter((f) => f.isError)
          : rows;
    return (
      <div>
        <Modal
          ref={(e) => (this.modal = e)}
          size="modal-xl"
          bgHeader="bg-primary"
          textHeader="Import Excel"
        >
          <form onSubmit={this.onSubmitFormUploadExcel} className="form-horizontal">
            <Col2 col="col-md-6">
              <div className="form-group">
                <label className="control-label col-sm-4 col-md-4">File</label>
                <div className="col-sm-8 col-md-8">
                  {/* <input type="file" onChange={this.onChangeFileUpload} ref={e => this.fileInput} className="form-control" /> */}

                  <div className="input-group">
                    <input
                      className="form-control"
                      readOnly
                      type="text"
                      placeholder="No file choosen"
                      value={(selectedFile || {}).name || ''}
                    />
                    <span className="input-group-btn">
                      <label className="btn btn-primary file-upload-btn">
                        <input
                          className="file-upload-input"
                          type="file"
                          name="file"
                          onChange={this.onChangeFileUpload}
                          ref={(e) => this.fileInput}
                        />
                        <span className="icon icon-paperclip icon-lg" />
                      </label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="col-sm-12 col-md-4 text-center">
                  <Button type="submit" className="btn-success" icon="upload" txt="View" />
                </div>
              </div>
            </Col2>
          </form>
          <Card bgHeader="bg-primary" textHeader="Valid data" cardActions={['toggler']}>
            <button
              type="button"
              className="btn btn-labeled btn-sm btn-info"
              onClick={(e) => this.onClickChangeViewData('total')}
            >
              <span className={view === 'total' ? 'btn-label-click' : 'btn-label'}>
                <span className="badge badge-info">{total}</span>
              </span>{' '}
              All
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-labeled btn-sm btn-success"
              onClick={(e) => this.onClickChangeViewData('success')}
            >
              <span className={view === 'success' ? 'btn-label-click' : 'btn-label'}>
                <span className="badge badge-success">{success}</span>
              </span>{' '}
              Success
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-labeled btn-sm btn-danger"
              onClick={(e) => this.onClickChangeViewData('error')}
            >
              <span className={view === 'error' ? 'btn-label-click' : 'btn-label'}>
                <span className="badge badge-danger">{error}</span>
              </span>{' '}
              Error
            </button>
            {success > 0 && error === 0 ? (
              <div className="row">
                <div className="col-sm-12">
                  <Button
                    txt="Confirm"
                    icon="save"
                    onClick={this.onClickSaveImport}
                    className="btn-info"
                  />
                </div>
              </div>
            ) : null}
            <DataTable
              id={`import-excel-success-dt-${tableName}`}
              option={optionDt}
              value={rows}
              className="table table-bordered table-nowrap"
            >
              {this.props.children}
            </DataTable>
          </Card>
          {/* <Card bgHeader="bg-warning" textHeader="Errors" cardActions={['toggler']}>
                        <DataTable id={`import-excel-error-dt-${tableName}`} option={errorOpt} value={Errors} className="table table-bordered table-nowrap">
                            <thead>
                                <tr>
                                    <th>Row</th>
                                    <th>Column</th>
                                    <th>Error</th>
                                </tr>
                            </thead>
                        </DataTable>
                    </Card> */}
        </Modal>
        <ModalAlert ref={(e) => (this.alert = e)} />
      </div>
    );
  }
}

export default ImportExcel;
