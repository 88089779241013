import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { DATATABLE_ID } from './SearchMaintainBalanceContainer';
import { toStatus, toBankCode, getPermission } from '../../common/helpper';
import { MASTER_FINANCE, MASTER_COMPANY, MASTER_BUSINESS_UNIT } from '../../reducers/master/action';

const permission = getPermission('Automate', 'Exposure');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-5', 'col-md-5'];
const colX2 = ['col-md-5', 'col-md-6'];

const opt = {
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    // {
    //     data: 'isActive', orderable: false, className: 'text-center',
    //     render: (d, t, r) => {
    //         if (!permission) return ''
    //         if (d) return '<button class="btn btn-icon btn-warning inactive" disabled><span class="icon icon-close sq-24"></span></button>'
    //         return '<button class="btn btn-icon btn-info active" disabled><span class="icon icon-refresh sq-24"></span></button>'
    //     }
    // },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<a class="btn btn-icon btn-info" target="_blank" href="/automate/exposure-detail/${r.buComp}"><span class="icon icon-edit sq-24"></span></a>`;
      },
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'cashType' },
    {
      data: 'autoAssignForward',
      className: 'text-center',
      render: (d) => (d ? 'Yes' : 'No'),
    },
    {
      data: 'autoSplitSpot',
      className: 'text-center',
      render: (d) => (d ? 'Yes' : 'No'),
    },
    {
      data: 'bankSpotCode',
      render: (d, t, r) => toBankCode(d, r.bankAbbreviate, r.bankName),
    },
    { data: 'currencyFrom', className: 'text-center' },
    { data: 'currencyTo', className: 'text-center' },
    { data: 'timeAutoAssign' },
    { data: 'invoiceNo' },
    { data: 'bankRemark' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  onClickSaveModalData,
  modalActiveRef,
  modalInactiveRef,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Automate <span className="icon icon-angle-double-right" /> Exposure
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroup}
                name="FinanceGroup"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit" required={false}>
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnit}
                name="BusinessUnit"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Company" required={false}>
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                financeGroupId={criteria.FinanceGroup}
                businessUnitId={criteria.BusinessUnit}
                value={criteria.CompanyCode}
                name="CompanyCode"
              />
            </FormGroup2>

            <FormGroup2 text="Cash Type" required={false}>
              <select
                className="form-control"
                value={criteria.CashType}
                onChange={onChangeSearch}
                name="CashType"
              >
                <option value={null}>---All---</option>
                <option value="Inward">Inward</option>
                <option value="Outward">Outward</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Status" required={false}>
              <select
                className="form-control"
                value={criteria.Status}
                onChange={onChangeSearch}
                name="Status"
              >
                <option value={null}>---All---</option>
                <option value>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Link
                  className="btn btn-warning btn-sm btn-labeled"
                  to="/automate/exposure-detail/create"
                  target="_blank"
                >
                  <span className="btn-label">
                    <i className="icon icon-plus-circle icon-fw icon-lg" />
                  </span>
                  Create
                </Link>
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            {/* <th>Action</th> */}
            <th>Edit</th>
            <th>Finance Group</th>
            <th>Business Unit</th>
            <th>
              Company
              <br /> Code
            </th>
            <th>Company Name</th>
            <th>Cash Type</th>
            <th>
              Auto
              <br />
              Assign Forward
            </th>
            <th>
              Auto
              <br />
              Split Spot
            </th>
            <th>Bank Spot</th>
            <th>
              Currency
              <br />
              From
            </th>
            <th>
              Currency
              <br />
              To
            </th>
            <th>
              Set time auto
              <br />
              assign value date
            </th>
            <th>Invoice No.</th>
            <th>Bank Remark</th>
            <th>Status</th>
            <th>
              Created
              <br /> By
            </th>
            <th>
              Created
              <br /> Date
            </th>
            <th>
              Updated
              <br /> By
            </th>
            <th>
              Updated
              <br /> Date
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalFunction
      textHeader="Active"
      textContent="active"
      modalRef={modalActiveRef}
      type="info"
      icon="icon-refresh"
      textBtn="Active"
      noSelect
      onClick={(e) => onClickSaveModalData(e)}
    />

    <ModalFunction
      textHeader="InActive"
      textContent="inactive"
      modalRef={modalInactiveRef}
      type="danger"
      icon="icon-close"
      textBtn="Yes"
      textBtnBack="No"
      noSelect
      onClick={(e) => onClickSaveModalData(e)}
    />
  </div>
);
