import React from 'react';

import CashFlow from './CashFlow';
import Stucture from './Structure';
import Tab from '../../../common/Tab';
import ModalSplit from '../ModalSplit';
import ModalIssuer from './ModalIssuer';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Link from '../../../common/Link';
import ModalCharge from '../ModalCharge';
import Administrator from './Administrator';
import Button from '../../../common/Button';
import FormGroup2 from '../../../common/FormGroup2';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { MASTER_FINANCE, MASTER_COMPANY } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,
  businessAreaList = [],

  onChangeInputTable,
  onClickGetCashflow,
  onChangeInputHeader,

  modalCharge,
  modalChargeRef,
  onClickOpenModalCharge,
  onChangeModalCharge,
  onSubmitModalCharge,

  modalSplitRef,
  onChangeModalSplit,
  onSubmitModalSplit,

  modalUndoSplitRef,
  onSubmitModalUndoSplit,

  modalIssuer,
  modalIssuerRef,
  onClickOpenModalIssuer,
  onChangeModalIssuer,
  onSubmitModalIssuer,

  modalCancelRef,
  onSubmitModalCancel,

  formDetailRef,
  formStructorRef,
  formAdministratorRef,
  formCashFlowRef,

  tabRef,
}) => {
  const header = state.mode === 'edit' ? `Bond No.: ${state.TransactionNo}` : 'Create Bond';
  const noEdit = state.mode === 'edit';
  const disabled = false;
  const required = true;

  const tabContents = [];
  // ------------------ Tab Structure ----------------------
  tabContents.push({
    header: <span>Structure</span>,
    content: (
      <Stucture
        formRef={formStructorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        onClickOpenModalIssuer={onClickOpenModalIssuer}
        onSubmit={onSubmit}
      />
    ),
    tabName: 'structure',
  });

  // ------------------ Tab Administrator ----------------------
  tabContents.push({
    header: <span>Administrator</span>,
    content: (
      <Administrator
        formRef={formAdministratorRef}
        state={state}
        businessAreaList={businessAreaList}
        onChangeInputData={onChangeInputData}
        onSubmit={onSubmit}
      />
    ),
    tabName: 'administrator',
  });

  // ------------------ Tab Cash Flow ----------------------
  tabContents.push({
    header: <span>Cash Flow</span>,
    content: (
      <CashFlow
        formRef={formCashFlowRef}
        state={state}
        onChangeInputData={onChangeInputData}
        onSubmit={onSubmit}
        onChangeInputTable={onChangeInputTable}
        onClickOpenModalCharge={onClickOpenModalCharge}
        onClickGetCashflow={onClickGetCashflow}
        onChangeInputHeader={onChangeInputHeader}
      />
    ),
    tabName: 'cashflow',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Funding & Investment <span className="icon icon-angle-double-right" />
            <Link txt="Create Bond" href="/loan-investment/create-bond" />
            <span className="icon icon-angle-double-right" /> Bond Detail
          </small>
        </p>
      </div>
      <Card textHeader={header} bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onSubmit} name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                {noEdit ? (
                  <input
                    type="text"
                    className="form-control"
                    value={state.FinanceGroupName || ''}
                    disabled
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_FINANCE}
                    noValidateOption={noEdit}
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={required}
                    disabled={noEdit}
                    value={state.FinanceGroupId}
                    name="FinanceGroupId"
                  />
                )}
              </FormGroup2>

              <FormGroup2 text="Company" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    value={`${state.CompanyCode} | ${state.CompanyName}`}
                    disabled
                  />
                ) : state.FinanceGroupId ? (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption={noEdit}
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    notMultiple={false}
                    required={required}
                    disabled={noEdit || disabled}
                    financeGroupId={state.FinanceGroupId}
                    value={state.CompanyCode}
                    name="CompanyCode"
                  />
                ) : (
                  <MasterDropdownUI
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    value={state.CompanyCode}
                    name="CompanyCode"
                    options={null}
                  />
                )}
              </FormGroup2>
            </Col2>

            <Col2 col={colX[3]}>
              <FormGroup2 text="Remark" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required={false}
                  value={state.Remark}
                  name="Remark"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !state.permission ? (
            ''
          ) : (
            <Button txt="SAVE" icon="save" className="btn-success" onClick={onSubmit} />
          )
        }
      />

      <ModalCharge
        text={modalCharge.Source}
        modalRef={modalChargeRef}
        state={modalCharge}
        onSubmit={onSubmitModalCharge}
        onChageModal={onChangeModalCharge}
      />

      <ModalIssuer
        text={modalIssuer.Source}
        modalRef={modalIssuerRef}
        state={modalIssuer}
        onSubmit={onSubmitModalIssuer}
        onChageModal={onChangeModalIssuer}
      />

      <ModalFunction
        textHeader="Cancel"
        textContent="cancel"
        modalRef={modalCancelRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalCancel(e)}
      />

      <ModalSplit
        modalRef={modalSplitRef}
        state={state.modalSplit}
        onSubmit={onSubmitModalSplit}
        onChageModal={onChangeModalSplit}
      />

      <ModalFunction
        textHeader="Undo Split"
        textContent="an Undo Split"
        modalRef={modalUndoSplitRef}
        type="brown"
        icon="icon-undo"
        textBtn="Confirm"
        noSelect
        onClick={(e) => onSubmitModalUndoSplit(e)}
      />
    </div>
  );
};
