import React from 'react';

import Card from '../../common/Card';
import Link from '../../common/Link';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import SpanStatus from '../../common/SpanStatus';
import RadioButton from '../../common/RadioButton';
import TablePaging from '../../common/TablePaging';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toFixed, toLower, getPermissionStr } from '../../common/helpper';
import {
  MASTER_COMPANY,
  MASTER_FORWARD,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BANK_ACCOUNT,
} from '../../reducers/master/action';

const permission = getPermissionStr('FX Management', 'Create Exposure');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const row = 'row';

export default ({
  state,
  onChangeInputData,
  onSubmitCreateHandler,
  onClickClearEquivalent,
  attachFileRef,
  onClickDownloadAttachFile,
  onCheckHoliday,
}) => {
  const reqType = toLower(state.CashType) === 'outward';
  const optionsExposure = reqType
    ? // ["Trade AP", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection"] :
      // ["Trade AR", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection"]
      ['Trade AP', 'Project', 'ST Loan', 'LT Loan & Swap', 'Capital Injection', 'Dividend']
    : ['Trade AR', 'Project', 'ST Loan', 'LT Loan & Swap', 'Capital Injection', 'Dividend'];

  const pageTitle =
    state.mode === 'edit' ? 'Edit Exposure Transaction' : 'Create Exposure Transaction';
  const headerTitle =
    state.mode === 'edit' ? `Exposure No.: ${state.ExposureNo}` : 'Create Exposure Transaction';
  const isEdit = state.mode === 'edit';
  const permissionView = toLower(permission || '').includes('view');
  const viewOnly = !!(
    state.mode === 'edit' &&
    (toLower(state.Status) !== 'wait for select method' || !state.IsActive || permissionView)
  );
  const setNotMultiSelect2 = state.mode !== 'edit';
  const reqInvoice = (state.CashType === 'Inward') & (state.TransactionType === 'From Bank');
  const reqInvoiceAmount =
    (state.CashType === 'Inward') & (state.TransactionType === 'From Bank') &&
    ~['DA', 'DP', 'LC'].indexOf(state.PaymentType || '');
  const status = !state.IsInactive ? 'Currently using' : state.IsActive ? 'Active' : 'Inactive';
  state.IsForward = permissionView ? false : state.IsForward;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" />
            <Link txt="Create Exposure" href="/fx-management/create-exposure" />
            <span className="icon icon-angle-double-right" /> {pageTitle}
            {isEdit ? (
              <>
                {' '}
                <span className="icon icon-angle-double-right" /> {state.ExposureNo}{' '}
                <span className="icon icon-angle-double-right" /> <SpanStatus status={status} />
              </>
            ) : null}
          </small>
        </p>
      </div>

      <Card textHeader={headerTitle} number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onCheckHoliday}>
          <Card textHeader="Information" bgHeader="bg-info">
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Cash Type" required>
                  {!isEdit ? (
                    <div>
                      <RadioButton
                        text=" Inward"
                        value="Inward"
                        checked={state.CashType === 'Inward'}
                        onChange={onChangeInputData}
                        name="CashType"
                        required
                      />
                      <RadioButton
                        text="Outward"
                        value="Outward"
                        checked={state.CashType === 'Outward'}
                        onChange={onChangeInputData}
                        name="CashType"
                      />
                    </div>
                  ) : (
                    <input
                      className="form-control"
                      type="text"
                      value={state.CashType}
                      name="CashType"
                      required
                      readOnly={isEdit}
                    />
                  )}
                </FormGroup2>

                <FormGroup2 text="Transaction Type" required>
                  {!isEdit ? (
                    <div>
                      <RadioButton
                        text="From Bank"
                        value="From Bank"
                        checked={state.TransactionType === 'From Bank'}
                        onChange={onChangeInputData}
                        name="TransactionType"
                        required
                      />
                      <RadioButton
                        text="Plan"
                        value="Plan"
                        checked={state.TransactionType === 'Plan'}
                        onChange={onChangeInputData}
                        name="TransactionType"
                      />
                    </div>
                  ) : (
                    <input
                      className="form-control"
                      type="text"
                      value={state.TransactionType}
                      name="TransactionType"
                      required
                      readOnly={isEdit}
                    />
                  )}
                </FormGroup2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Inform Date" required>
                  <DatePicker
                    value={state.InformDateStr}
                    onChange={onChangeInputData}
                    name="InformDateStr"
                    required
                    option={{
                      // startDate: "d",
                      daysOfWeekDisabled: '0,6',
                      todayHighlight: true,
                    }}
                    disabled={viewOnly}
                  />
                </FormGroup2>

                <FormGroup2 text="Value Date" required={reqType}>
                  <DatePicker
                    value={state.ValueDateStr}
                    onChange={onChangeInputData}
                    name="ValueDateStr"
                    required={reqType}
                    option={{
                      // startDate: "d",
                      daysOfWeekDisabled: '0,6',
                      todayHighlight: true,
                    }}
                    disabled={viewOnly}
                  />
                </FormGroup2>
              </Col2>
            </Col2>

            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Company" required>
                  <MasterDropdown
                    noValidateOption={isEdit}
                    masterType={MASTER_COMPANY}
                    isChoose
                    notMultipleSelect2={setNotMultiSelect2}
                    notMultiple={!setNotMultiSelect2}
                    value={state.CompanyCode}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="CompanyCode"
                    required
                    disabled={isEdit}
                  />
                </FormGroup2>
                <FormGroup2 text="Bank" required>
                  {state.CompanyCode ? (
                    <MasterDropdown
                      masterType={MASTER_BANK_CODE}
                      isChoose
                      notMultipleSelect2
                      value={state.SwiftCode}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      isGetAll
                      customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                      saveLocalState
                      noValidateOption
                      companyCode={state.CompanyCode}
                      name="SwiftCode"
                      required
                      disabled={viewOnly}
                    />
                  ) : (
                    <MasterDropdownUI
                      isChoose
                      notMultipleSelect2
                      value={state.SwiftCode}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      name="SwiftCode"
                      options={null}
                    />
                  )}
                </FormGroup2>
              </Col2>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Bank Account" required={reqType}>
                  {state.SwiftCode ? (
                    <MasterDropdown
                      noValidateOption
                      masterType={MASTER_BANK_ACCOUNT}
                      isChoose
                      notMultipleSelect2
                      value={state.BankAccountNoTemp}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      saveLocalState
                      bankCode={state.SwiftCode}
                      companyCode={state.CompanyCode}
                      name="BankAccountNoTemp"
                      required={reqType}
                      customeValue={(m) =>
                        `${m.branchName}|${m.accountTypeCode}|${m.currency}|${m.bankAccountNo}`
                      }
                      disabled={viewOnly}
                    />
                  ) : (
                    <MasterDropdownUI
                      isChoose
                      notMultipleSelect2
                      value={state.BankAccountNoTemp}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      name="BankAccountNoTemp"
                      options={null}
                    />
                  )}
                </FormGroup2>
              </Col2>
            </Col2>

            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Amount" required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={state.inputMaskAmount}
                    onChange={onChangeInputData}
                    value={state.Amount}
                    required
                    readOnly={viewOnly}
                    name="Amount"
                  />
                </FormGroup2>

                <FormGroup2 text="Currency" required>
                  {viewOnly ? (
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={state.Currency || ''}
                    />
                  ) : (
                    <MasterDropdown
                      masterType={MASTER_CURRENCY}
                      isChoose
                      notMultipleSelect2
                      value={state.Currency}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      noValidateOption={isEdit}
                      disabled={viewOnly}
                      name="Currency"
                      required
                    />
                  )}
                </FormGroup2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Equivalent" required={false}>
                  {viewOnly ? (
                    <input
                      className="form-control"
                      type="text"
                      disabled
                      value={state.Equivalent || ''}
                    />
                  ) : (
                    <MasterDropdown
                      masterType={MASTER_CURRENCY}
                      isChoose
                      notMultipleSelect2
                      value={state.Equivalent}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      noValidateOption={isEdit}
                      disabled={viewOnly}
                      name="Equivalent"
                      required={false}
                    />
                  )}
                </FormGroup2>

                {!viewOnly && !permissionView ? (
                  <FormGroup2 text="&nbsp;">
                    <Button
                      txt="Clear Equivalent"
                      icon="eraser"
                      className="btn-clear"
                      onClick={onClickClearEquivalent}
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            </Col2>
            <Col2 col={colX[0]}>
              <FormGroup2 text={state.AttachFileExposure ? 'Replace File' : 'Attach File'}>
                <input type="file" ref={attachFileRef} />
                {state.AttachFileExposure ? (
                  <span>
                    <br />
                    <Button
                      icon="download"
                      title={state.AttachFileExposureName}
                      txt="Download"
                      className="btn-info"
                      onClick={onClickDownloadAttachFile}
                    />
                  </span>
                ) : null}
              </FormGroup2>
            </Col2>
          </Card>

          <Card textHeader="Transaction Detail" bgHeader="bg-warning">
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Payment Type" required={reqType}>
                  <select
                    className="form-control"
                    value={state.PaymentType}
                    onChange={onChangeInputData}
                    name="PaymentType"
                    required={reqType}
                    disabled={viewOnly}
                  >
                    <option key="" value="">
                      ---Choose---
                    </option>
                    <option key="DA" value="DA">
                      DA
                    </option>
                    <option key="DP" value="DP">
                      DP
                    </option>
                    <option key="LC" value="LC">
                      LC
                    </option>
                    <option key="Auto Deduct" value="Auto Deduct">
                      Auto Deduct
                    </option>
                    <option key="TT" value="TT">
                      T/T
                    </option>
                  </select>
                </FormGroup2>

                <FormGroup2 text="Exposure Type" required>
                  <MasterDropdownUI
                    isChoose={!isEdit}
                    notMultiple
                    value={state.ExposureType}
                    onChange={onChangeInputData}
                    name="ExposureType"
                    required
                    disabled={viewOnly}
                    options={optionsExposure}
                  />
                </FormGroup2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2
                  text="Vendor/Customer Name"
                  required
                  maxLength={200}
                  length={(state.CustomerName || '').length}
                >
                  <input
                    className="form-control"
                    type="text"
                    value={state.CustomerName || ''}
                    onChange={onChangeInputData}
                    name="CustomerName"
                    maxLength={200}
                    required
                    disabled={viewOnly}
                  />
                </FormGroup2>

                <FormGroup2
                  text="Bank Reference No."
                  required={reqInvoice}
                  maxLength={30}
                  length={(state.ReferenceNo || '').length}
                >
                  <input
                    className="form-control"
                    type="text"
                    value={state.ReferenceNo || ''}
                    onChange={onChangeInputData}
                    name="ReferenceNo"
                    maxLength={30}
                    required={reqInvoice}
                    disabled={viewOnly}
                  />
                </FormGroup2>
              </Col2>
            </Col2>

            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2
                  text="Transaction Details"
                  maxLength={2000}
                  length={(state.TransactionDetail || '').length}
                >
                  <textarea
                    className="form-control"
                    rows="2"
                    value={state.TransactionDetail || ''}
                    onChange={onChangeInputData}
                    name="TransactionDetail"
                    maxLength={2000}
                    readOnly={viewOnly}
                  />
                </FormGroup2>

                <FormGroup2
                  text="Project Name"
                  maxLength={200}
                  length={(state.ProjectName || '').length}
                >
                  <textarea
                    className="form-control"
                    rows="2"
                    value={state.ProjectName || ''}
                    onChange={onChangeInputData}
                    name="ProjectName"
                    maxLength={200}
                    disabled={viewOnly}
                  />
                </FormGroup2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Request Forward Contract No.">
                  <div className="row gutter-xs">
                    <div className="col-sm-2 col-md-2 text-center">
                      <input
                        id="IsForward"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeInputData}
                        checked={state.IsForward}
                        name="IsForward"
                        disabled={viewOnly}
                      />
                      <label htmlFor="IsForward" className="label_checkbox" />
                    </div>
                    <div className="col-sm-10 col-md-10">
                      {state.IsForward ? (
                        <MasterDropdown
                          masterType={MASTER_FORWARD}
                          isChoose
                          notMultiple
                          value={state.ForwardNo}
                          onChange={onChangeInputData}
                          noValidateOption={isEdit}
                          disabled={false}
                          companyCode={state.CompanyCode}
                          bankCode={state.SwiftCode}
                          cashType={state.CashType}
                          exposureType={state.ExposureType}
                          bookBankCurrency={state.BankCurrency}
                          currency={state.Currency}
                          valueDateStr={state.ValueDateStr}
                          name="ForwardNo"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.ForwardNo || ''}
                          name="ForwardNo"
                          disabled
                        />
                      )}
                    </div>
                  </div>
                </FormGroup2>
              </Col2>
            </Col2>

            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2
                  text="Invoice No"
                  required={reqInvoice}
                  maxLength={~['TT', 'Auto Deduct'].indexOf(state.PaymentType) ? 200 : 10}
                  length={(state.InvoiceNo || '').length}
                >
                  {~['TT', 'Auto Deduct'].indexOf(state.PaymentType) ? (
                    <textarea
                      className="form-control"
                      rows="3"
                      value={state.InvoiceNo || ''}
                      onChange={onChangeInputData}
                      name="InvoiceNo"
                      maxLength={200}
                      required={reqInvoice}
                      disabled={viewOnly}
                    />
                  ) : (
                    <input
                      className="form-control"
                      type="text"
                      value={state.InvoiceNo || ''}
                      onChange={onChangeInputData}
                      maxLength={10}
                      name="InvoiceNo"
                      required={reqInvoice}
                      disabled={viewOnly}
                    />
                  )}
                </FormGroup2>

                <FormGroup2 text="Invoice Amount" required={reqInvoiceAmount}>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={state.inputMaskInvoiceAmount}
                    onChange={onChangeInputData}
                    required={reqInvoiceAmount}
                    disabled={viewOnly}
                    value={state.InvoiceAmount}
                    name="InvoiceAmount"
                  />
                </FormGroup2>
              </Col2>
            </Col2>
          </Card>
          {!viewOnly && !permissionView ? (
            <div className={row}>
              <br />
              <center>
                <Button
                  txt="SAVE"
                  icon="save"
                  type="submit"
                  className="btn-success"
                  disabled={false}
                />
              </center>
            </div>
          ) : null}
        </form>
      </Card>

      {!permissionView && state.createHistory.length ? (
        <Card
          textHeader="Create History"
          number="2"
          bgHeader="bg-primary"
          cardActions={['toggler']}
        >
          <TablePaging colNumber={19} renderRow={renderRow()} value={state.createHistory}>
            <thead>
              <tr>
                <th className="th-success">Exposure No.</th>
                <th className="th-success">Finance Group</th>
                <th className="th-success">Company Code</th>
                <th className="th-success">Company Name</th>
                <th className="th-success">Value Date</th>
                <th className="th-success">Inform Date</th>
                <th className="th-success">Cash Type</th>
                <th className="th-success">Transaction Type</th>
                <th className="th-success">
                  Vendor/Customer
                  <br /> Name
                </th>
                <th className="th-success">Transaction Details</th>
                <th className="th-success">Bank</th>
                <th className="th-success">Bank Account</th>
                <th className="th-success">Swift Code</th>
                <th className="th-success">Amount</th>
                <th className="th-success">Currency</th>
                <th className="th-success">Equivalent</th>
                <th className="th-success">Invoice Amount</th>
                <th className="th-success">Invoice No.</th>
                <th className="th-success">Payment Type</th>
                <th className="th-success">Exposure Type</th>
                <th className="th-success">Forward Contract No.</th>
                <th className="th-success">Project Name</th>
                <th className="th-success">Bank Reference No.</th>
                <th className="th-success">Create Date</th>
                <th className="th-success">Created By</th>
                <th className="th-success">Updated Date</th>
                <th className="th-success">Updated By</th>
              </tr>
            </thead>
          </TablePaging>
        </Card>
      ) : null}
    </div>
  );
};

const renderRow = () => (m, i) => (
  <tr key={i}>
    <td>
      <a
        href={`/fx-management/exposure-transaction/${m.exposureNo}`}
        target="_blank"
        rel="noreferrer"
      >
        {m.exposureNo}
      </a>
    </td>
    <td>{m.financeGroupName}</td>
    <td style={{ textAlign: 'center' }}>{m.companyCode}</td>
    <td>{m.companyName}</td>
    <td style={{ textAlign: 'center' }}>{m.valueDateStr}</td>
    <td style={{ textAlign: 'center' }}>{m.informDateStr}</td>
    <td style={{ textAlign: 'center' }}>{m.cashType}</td>
    <td style={{ textAlign: 'center' }}>{m.transactionType}</td>
    <td>{m.customerName}</td>
    <td>{m.transactionDetail}</td>
    <td>{setBank(m.bankAbbreviate, m.bankName)}</td>
    <td>{setAccount(m.branchName, m.accountTypeCode, m.bankCurrency, m.bankAccountNo)}</td>
    <td>{m.swiftCode}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.cashType, m.amount, m.currencyDigit)}</td>
    <td style={{ textAlign: 'center' }}>{m.currency}</td>
    <td style={{ textAlign: 'center' }}>{m.equivalent}</td>
    <td style={{ textAlign: 'right' }}>{setAmount('invoice', m.invoiceAmount, m.currencyDigit)}</td>
    <td>{m.invoiceNo}</td>
    <td>{m.paymentType}</td>
    <td>{m.exposureType}</td>
    <td>{m.forwardNo}</td>
    <td>{m.projectName}</td>
    <td>{m.referenceNo}</td>
    <td>{m.createdDateStr}</td>
    <td>{m.createdBy}</td>
    <td>{m.updatedDateStr}</td>
    <td>{m.updatedBy}</td>
  </tr>
);

const setAmount = (type, number, digit) => {
  if (type === 'invoice' && (number == null || number === '')) return '';

  number = Math.abs(number);
  if (toLower(type) === 'outward')
    return <span style={{ color: '#e64a19' }}>({toFixed(number, digit)})</span>;
  return toFixed(number, digit);
};

const setBank = (bankAbbreviate, bankName) => {
  if (!bankAbbreviate) return '';
  return `${bankAbbreviate} | ${bankName}`;
};

const setAccount = (bankBranch, bankType, bankCurrency, bankAccount) => {
  if (!bankAccount) return '';
  if ((bankAccount || '').length <= 13)
    return (
      <span>
        {bankBranch} | {bankType} | {bankCurrency}
        <br />
        {bankAccount}
      </span>
    );
  return (
    <span>
      {bankBranch} | {bankType} | {bankCurrency}
      <br />
      {bankAccount.substring(0, 13)}
      <br />
      {bankAccount.substring(13, bankAccount.length)}
    </span>
  );
};
