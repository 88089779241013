import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useReducer,
  forwardRef,
  useCallback,
  createContext,
  useImperativeHandle,
} from 'react';

import { store } from '../../../Root';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Calendar from '../../../common/Calendar';
import DataTable from '../../../common/DataTable';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import ImportExcel from '../../../common/ImportExcel';
import { getPermission } from '../../../common/helpper';
import { defaultImportExcelResult } from '../../../reducers/configuration';
import {
  searchReducer,
  holidayReducer,
  initSearchState,
  initHolidayState,
  searchResultDTOption,
} from './initState';
import {
  saveHoliday,
  searchHoliday,
  viewImportExcel,
  getHolidayDetail,
  saveHolidayDetail,
  saveImportHolidayExcel,
} from '../../../reducers/masterHoliday/action';

const permission = getPermission('Master Finance', 'Calendar');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const $ = window.jQuery;
const SearchContext = createContext(null);
const HolidayContext = createContext(null);
const optionDt = {
  columns: [
    { data: 'calendarName', className: 'text-center' },
    { data: 'dateStr', className: 'text-center' },
    { data: 'holidayName', className: 'text-center' },
    { data: 'isCompensateStr', className: 'text-center' },
  ],
};

function HolidayPage({ routeProp }) {
  const importRef = useRef(null);
  const [searchState, searchDispatch] = useReducer(searchReducer, initSearchState);
  const [holidayState, holidayDispatch] = useReducer(holidayReducer, initHolidayState);
  const [importData, setImportData] = useState(defaultImportExcelResult);
  const modalHolidayDetail = useRef(null);
  const modalCreateCalendarRef = useRef(null);
  const searchContextValue = useMemo(
    () => ({
      searchState,
      searchDispatch,
    }),
    [searchState]
  );
  const holidayContextValue = useMemo(
    () => ({
      holidayState,
      holidayDispatch,
    }),
    [holidayState]
  );
  const onClickSaveImport = useCallback(
    (e) => {
      async function submit() {
        const success = importData.rows.filter((f) => !f.isError);
        if (!success.length) return;
        const response = await store.dispatch(saveImportHolidayExcel(success));
        if (response.error) return;

        setImportData(defaultImportExcelResult);
      }

      submit();
    },
    [importData]
  );

  const onClickViewImportExcel = useCallback((file) => {
    async function submit() {
      const form = new FormData();
      form.append('file', file);
      const response = await store.dispatch(viewImportExcel(form));
      if (response.error) return;

      setImportData(response.payload || defaultImportExcelResult);
    }

    submit();
  }, []);
  return (
    <>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" /> Calendar
          </small>
        </p>
      </div>
      <SearchContext.Provider value={searchContextValue}>
        <HolidayContext.Provider value={holidayContextValue}>
          <Criteria
            routeProp={routeProp}
            modalCreateCalendarRef={modalCreateCalendarRef}
            importRef={importRef}
          />
          <SearchResult />
          <ModalHoliday ref={modalHolidayDetail} />
          <HolidayCalendar modalHolidayDetail={modalHolidayDetail} />
          <ModalCalendar ref={modalCreateCalendarRef} />
          <ImportExcel
            ref={importRef}
            onClickSaveImport={onClickSaveImport}
            onUpload={onClickViewImportExcel}
            tableName="calendar-import"
            value={importData}
            optionDt={optionDt}
          >
            <thead>
              <tr>
                <th>Calendar Name</th>
                <th>Date</th>
                <th>Holiday Name</th>
                <th>Is Compensate</th>
              </tr>
            </thead>
          </ImportExcel>
        </HolidayContext.Provider>
      </SearchContext.Provider>
    </>
  );
}
export default HolidayPage;

// SEARCH & RESULT
function Criteria({ routeProp, modalCreateCalendarRef, importRef }) {
  const { searchState, searchDispatch } = useContext(SearchContext);
  const { criteria } = searchState;
  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    searchDispatch({
      type: 'UPDATE_CRITERIA',
      name,
      value,
    });
  });
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      async function fetch() {
        const response = await store.dispatch(searchHoliday(criteria));
        if (response.error) return;
        searchDispatch({
          type: 'SET_SEARCH_RESULT',
          payload: response.payload || [],
        });
      }
      fetch();
    },
    [searchState]
  );

  return (
    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onSubmit}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Status" required>
              <select
                className="form-control"
                value={criteria.Status}
                onChange={onChange}
                name="Status"
              >
                <option value={null}>---All---</option>
                <option value>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </FormGroup2>

            <FormGroup2 text="&nbsp;">
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              {permission ? (
                <>
                  &nbsp;
                  <Button
                    txt="CREATE"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={(e) => modalCreateCalendarRef.current.modalRef.open()}
                  />
                  &nbsp;
                  <Button
                    txt="Import"
                    icon="upload"
                    className="btn-success"
                    onClick={(e) => importRef.current.open()}
                  />
                </>
              ) : null}
            </FormGroup2>
          </Col2>
        </Col2>
      </form>
    </Card>
  );
}

function SearchResult({}) {
  const { searchState, searchDispatch } = useContext(SearchContext);
  const { holidayState, holidayDispatch } = useContext(HolidayContext);
  const onClickSelectCalendar = useCallback(
    (e, cell) => {
      const { row } = cell.index();
      const selected = searchState.results[row];
      $('.layout-header-fixed').animate({ scrollTop: $('.calendar').offset().top });
      holidayDispatch({
        type: 'SET',
        payload: {
          ...selected,
          year: new Date().getFullYear(),
          items: [],
          forceUpdate: true,
        },
      });
    },
    [searchState]
  );
  const onClickActive = useCallback(
    (e, cell) => {
      const { row } = cell.index();
      const selected = searchState.results[row];
      saveHolidayHeader(
        {
          ...selected,
          isActive: 1,
        },
        row
      );
    },
    [searchState]
  );
  const onClickInActive = useCallback(
    (e, cell) => {
      const { row } = cell.index();
      const selected = searchState.results[row];
      saveHolidayHeader(
        {
          ...selected,
          isActive: 0,
        },
        row
      );
    },
    [searchState]
  );
  const saveHolidayHeader = useCallback(
    (data, index) => {
      async function submit() {
        const response = await store.dispatch(saveHoliday(data));
        if (response.error) return;
        searchDispatch({
          type: 'UPDATE_SEARCH_RESULT',
          payload: { ...data },
          index,
        });
        holidayDispatch({
          type: 'UPDATE_ACTIVE_IF_SAME_ID',
          id: data.holidayId,
          payload: data.isActive,
        });
      }
      submit();
    },
    [searchState]
  );

  return (
    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <DataTable
        className="table table-bordered table-nowrap dataTable"
        id="search-dt-result-calendar"
        option={searchResultDTOption}
        value={searchState.results}
        onClickContext={[
          {
            onClick: onClickInActive,
            context: 'button.inactive',
          },
          {
            onClick: onClickActive,
            context: 'button.active',
          },
          {
            onClick: onClickSelectCalendar,
            context: 'a.goto-cal',
          },
        ]}
      >
        <thead>
          <tr>
            <th>Action</th>
            <th>
              Holiday
              <br /> ID
            </th>
            <th>Calendar Name</th>
            <th>Status</th>
            <th>Created By</th>
            <th>Created Date</th>
            <th>Updated By</th>
            <th>Updated Date</th>
          </tr>
        </thead>
      </DataTable>
    </Card>
  );
}

// CALENDAR
const HolidayCalendar = forwardRef(({ modalHolidayDetail }, ref) => {
  const { holidayState, holidayDispatch } = useContext(HolidayContext);
  const calendarRef = useRef(null);
  const holidayDataRef = useRef(null);
  const flagNoUpdate = useRef(false);
  const calOption = useRef({
    enableContextMenu: true,
    contextMenuItems: [
      {
        text: 'Delete',
        click: () => {},
      },
    ],
    clickDay(e) {
      if (
        holidayDataRef.current === null ||
        holidayDataRef.current.holidayId === null ||
        holidayDataRef.current.holidayId === undefined
      )
        return;
      const { events } = e;
      if (!events.length) modalHolidayDetail.current.openCreate(e.date);
      else modalHolidayDetail.current.openEdit(events[0].holidayDetailId, e.date);
    },
    renderEnd(e) {
      const { year, holidayId, calendarName, forceUpdate } = holidayDataRef.current || {};
      if (!year || !holidayId || flagNoUpdate.current) return;

      if (forceUpdate || Number(year) !== e.currentYear) {
        fetchHolidayDetail({
          year: e.currentYear,
          holidayId,
          calendarName,
        });
      }
    },
    mouseOnDay(e) {
      if (e.events.length > 0) {
        let content = '';

        for (const i in e.events) {
          content +=
            `<div class="event-tooltip-content">` +
            `<div class="event-name" style="color:${e.events[i].color}">${e.events[i].name}</div>` +
            `</div>`;
        }

        $(e.element).popover({
          trigger: 'manual',
          container: 'body',
          html: true,
          content,
          placement: 'right',
        });

        $(e.element).popover('show');
      }
    },
    mouseOutDay(e) {
      if (e.events.length > 0) {
        $(e.element).popover('hide');
      }
    },
    dayContextMenu(e) {
      $(e.element).popover('hide');
    },
  });

  useEffect(() => {
    if (!calendarRef.current.caledarApi) return;
    // set static data
    holidayDataRef.current = holidayState;
    const calData = (holidayState.items || []).map((m) => ({
      name: m.holidayName,
      startDate: getDate(m.holidayDateStr),
      endDate: getDate(m.holidayDateStr),
      ...m,
    }));
    flagNoUpdate.current = true;
    calendarRef.current.caledarApi.setDataSource(calData);
    flagNoUpdate.current = false;
  }, [holidayState.items]);

  useEffect(() => {
    if (!calendarRef.current.caledarApi) return;
    // set static data
    holidayDataRef.current = holidayState;
    flagNoUpdate.current = false;
    calendarRef.current.caledarApi.setYear(holidayState.year);
  }, [holidayState.year, holidayState.forceUpdate]);

  const fetchHolidayDetail = useCallback((criteria) => {
    async function fetch() {
      const { year, holidayId } = criteria;
      const response = await store.dispatch(
        getHolidayDetail({
          year,
          holidayId,
        })
      );
      holidayDataRef.current.year = year;

      if (response.error) return;
      holidayDispatch({
        type: 'SET_ITEMS_AFTER_FETCH',
        payload: response.payload || [],
      });
    }

    fetch();
  });
  const onChangeCalendar = useCallback((e) => {
    const { name, value } = e.target;
    holidayDispatch({
      type: 'UPDATE_CALENDAR_NAME',
      payload: value,
    });
  });
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      async function submit() {
        const { calendarName, holidayId, isActive, isDelete } = holidayState;
        if (holidayId === null || holidayId === undefined) {
          alert('Invalid HolidayId');
          return;
        }
        const response = await store.dispatch(
          saveHoliday({
            calendarName,
            holidayId,
            isActive,
            isDelete,
          })
        );
        if (response.error) return;
      }
      submit();
    },
    [holidayState]
  );
  return (
    <Card textHeader="Holiday" number="3" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onSubmit}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Calendar Name" required>
              <input
                className="form-control"
                type="text"
                required
                onChange={onChangeCalendar}
                value={holidayState.calendarName}
                name="calendarName"
              />
            </FormGroup2>
            {permission ? (
              <FormGroup2 text="&nbsp;">
                <Button
                  txt="Update"
                  icon="edit"
                  className="btn-info"
                  type="submit"
                  disabled={!holidayState.holidayId}
                />
              </FormGroup2>
            ) : null}
          </Col2>
        </Col2>
      </form>

      <Calendar ref={calendarRef} option={calOption.current} />
    </Card>
  );
});

const ModalHoliday = forwardRef(({}, ref) => {
  const { holidayState, holidayDispatch } = useContext(HolidayContext);
  const modalRef = useRef(null);
  const [selectedData, setSelectedData] = useState(null);
  const [isCreate, setIsCreate] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      modalRef: modalRef.current,
      openEdit: (id, date) => {
        const find = holidayState.items.find((f) => f.holidayDetailId === id);
        setSelectedData(
          find || {
            holidayDateStr: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
          }
        );
        setIsCreate(!find);
        modalRef.current.open();
      },
      openCreate: (date) => {
        setSelectedData({
          holidayDateStr: `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`,
        });
        setIsCreate(true);
        modalRef.current.open();
      },
    }),
    [modalRef.current, holidayState]
  );

  const onChange = useCallback(
    (e) => {
      const { name, value, type, checked } = e.target;
      setSelectedData({
        ...(selectedData || {}),
        [name]: type === 'checkbox' ? checked : value,
      });
    },
    [selectedData]
  );
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      async function submit() {
        const { holidayId } = holidayState;
        if (holidayId === null || holidayId === undefined) {
          alert('holidayId is empty');
          return;
        }
        const response = await store.dispatch(
          saveHolidayDetail({
            ...selectedData,
            holidayId,
          })
        );
        if (response.error) return;
        modalRef.current.close();
        holidayDispatch({
          type: 'UPDATE_FORCE_UPDATE',
          payload: true,
        });
      }
      submit();
    },
    [selectedData, holidayState]
  );

  const onClickDelete = useCallback(() => {
    async function submit() {
      const { holidayId } = holidayState;
      if (holidayId === null || holidayId === undefined) {
        alert('holidayId is empty');
        return;
      }
      const response = await store.dispatch(
        saveHolidayDetail({
          ...selectedData,
          isActive: false,
          holidayId,
        })
      );
      if (response.error) return;
      modalRef.current.close();
      holidayDispatch({
        type: 'UPDATE_FORCE_UPDATE',
        payload: true,
      });
    }
    submit();
  }, [selectedData, holidayState]);

  const data = selectedData || {};
  return (
    <div>
      <Modal textHeader="Holiday" bgHeader="bg-info" size="modal-lg" ref={modalRef}>
        <form onSubmit={onSubmit}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Add Date" required>
                <DatePicker
                  required
                  onChange={onChange}
                  value={data.holidayDateStr || ''}
                  name="holidayDateStr"
                />
              </FormGroup2>
              <FormGroup2 text="Holiday Name" required>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChange}
                  required
                  value={data.holidayName || ''}
                  name="holidayName"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Is Compensate">
                <input
                  id="IsCompensate"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChange}
                  name="isCompensate"
                  checked={!!data.isCompensate}
                />
                <label htmlFor="IsCompensate" className="label_checkbox" />
              </FormGroup2>
            </Col2>
          </Col2>
          {permission ? (
            <Col2 col={colX[0]}>
              <span>
                <Button
                  txt={isCreate ? 'Add' : 'Update'}
                  icon="edit"
                  className="btn-green"
                  type="submit"
                />
                &nbsp;
                {isCreate ? null : (
                  <Button
                    txt="Delete"
                    icon="close"
                    className="btn-danger"
                    type="button"
                    onClick={onClickDelete}
                  />
                )}
              </span>
            </Col2>
          ) : null}
        </form>
      </Modal>
    </div>
  );
});

const ModalCalendar = forwardRef(({}, ref) => {
  const modalRef = useRef(null);
  useImperativeHandle(
    ref,
    () => ({
      modalRef: modalRef.current,
    }),
    []
  );
  const [calendarName, setcalendarName] = useState(null);
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      async function submit() {
        const response = await store.dispatch(
          saveHoliday({
            calendarName,
          })
        );
        if (response.error) return;
        modalRef.current.close();
      }
      submit();
    },
    [calendarName]
  );
  return (
    <div>
      <Modal textHeader="Create Calendar" bgHeader="bg-info" size="lg" ref={modalRef}>
        <form onSubmit={onSubmit}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Calendar Name" required>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setcalendarName(e.target.value)}
                  required
                  value={calendarName || ''}
                  name="calendarName"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <Button txt="Create" icon="edit" className="btn-green" type="submit" />
            </Col2>
          </Col2>
        </form>
      </Modal>
    </div>
  );
});
function getDate(txt) {
  if (!txt) return null;
  const s = txt.split('/');
  return new Date(s.reverse().join('/'));
}
