import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_TRANSFER_FEE_SET_UP = '@@request/SEARCH_TRANSFER_FEE_SET_UP';
export const RECEIVE_SEARCH_TRANSFER_FEE_SET_UP = '@@receive/SEARCH_TRANSFER_FEE_SET_UP';
export const FAIL_SEARCH_TRANSFER_FEE_SET_UP = '@@fail/SEARCH_TRANSFER_FEE_SET_UP';

export const REQUEST_UPDATE_TRANSFER_FEE_SET_UP = '@@request/UPDATE_TRANSFER_FEE_SET_UP';
export const RECEIVE_UPDATE_TRANSFER_FEE_SET_UP =
  '@@receive-The transaction was successfully change status/UPDATE_TRANSFER_FEE_SET_UP';
export const FAIL_UPDATE_TRANSFER_FEE_SET_UP = '@@fail/UPDATE_TRANSFER_FEE_SET_UP';

export const REQUEST_SUBMIT_TRANSFER_FEE_SET_UP = '@@request/SUBMIT_TRANSFER_FEE_SET_UP';
export const RECEIVE_SUBMIT_TRANSFER_FEE_SET_UP =
  '@@receive-The transaction was successfully saved/SUBMIT_TRANSFER_FEE_SET_UP';
export const FAIL_SUBMIT_TRANSFER_FEE_SET_UP = '@@fail/SUBMIT_TRANSFER_FEE_SET_UP';

export const UPDATE_TRANSFER_FEE_SETUP = 'UPDATE_TRANSFER_FEE_SETUP';

export const searchTransferFeeSetup = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/transferFeeSetup', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_TRANSFER_FEE_SET_UP,
      RECEIVE_SEARCH_TRANSFER_FEE_SET_UP,
      FAIL_SEARCH_TRANSFER_FEE_SET_UP,
    ],
  },
});

export const changeStatus = (data, index, status) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint(`/transferFeeSetup/ChangeStatus?s=${status || ''}`),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [
        REQUEST_UPDATE_TRANSFER_FEE_SET_UP,
        {
          type: RECEIVE_UPDATE_TRANSFER_FEE_SET_UP,
          payload: {
            index,
            data,
          },
        },
        FAIL_UPDATE_TRANSFER_FEE_SET_UP,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(updateTransferFee(data, index));

  return actionResponse;
};

export const updateTransferFee = (data, index) => ({
  type: UPDATE_TRANSFER_FEE_SETUP,
  payload: {
    data,
    index,
  },
});

export const submitTransferFeeSetup = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/transferFeeSetup'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_SUBMIT_TRANSFER_FEE_SET_UP,
      RECEIVE_SUBMIT_TRANSFER_FEE_SET_UP,
      FAIL_SUBMIT_TRANSFER_FEE_SET_UP,
    ],
  },
});
