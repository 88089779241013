import { connect } from 'react-redux';
import React, { Component } from 'react';

import PnLoan from './PnLoan';
import { addAlert } from '../../../reducers/layout/action';
import { getExchangeRate, getCurrencyDigit } from '../../../reducers/master/action';
import {
  toFixed,
  toUpperKey,
  compareDate,
  getCountDay,
  getCountMonth,
  getPermission,
  validateCashflow,
} from '../../../common/helpper';
import {
  getLoan,
  saveLoan,
  splitCashFlow,
  getInterestRate,
  processCashflow,
  getFacilityList,
  getFacilityForLoan,
  getTemplateInterestRateList,
} from '../../../reducers/pnLoan/action';

const $ = window.jQuery;

const permission = getPermission('Funding&Investment', 'Create Promissory Note');

const initInformation = {
  FinanceGroupId: '',
  CompanyCode: '',
  Remark: '',
  IsProject: false,
  CalendarId: '',
  Source: 'PN',
};

const initStructure = {
  LoanType: '',
  PartnerType: '',
  IcType: '',
  TransactionType: '',
  BusinessPartner: '',
  ContractDateStr: '',
  TermStartStr: '',
  TermEndStr: '',
  Amount: '',
  Currency: '',
  ExternalRef: '',
  IsThaiSweep: false,

  inputMaskAmount: {
    prefix: '',
    digits: 3,
    // digitsOptional: false,
    // placeholder: '0.000'
  },
};

const initInterest = {
  InterestRateId: '',
  InterestTemplateName: '',
  InterestRate: '',
  CalculateMethod: '',

  PeriodEndType: '',
  IsManualInclude: '',
  IsManualMonthEnd: '',
  ManualPeriodEnd: '',
  ManualDay: '',
  ManualWorkingDay: '',

  DueDateType: '',
  IsDueDateMonthEnd: '',
  ManualDueDate: '',
  DueDateDay: '',
  DueDateWorkingDay: '',
  Frequency: '',
};

const initAdministrator = {
  FacilityNo: '',
  Outstanding: '',
  BusinessArea: '',
  BusinessAreaPartner: '',
  GenValnClass: '',
  NDay: '',
  NewPn: '',
  TransactionNoRef: [],
  BusinessAreaName: '',
  BusinessAreaPartnerName: '',
};

const initCashFlow = {
  BankCodeLoan: [''],
  CompanyByLoan: '',
  resultLoan: [],
  AccountNoAllLoan: '',
  PaymentMethodAllLoan: '',

  BankCodeDeposit: [''],
  CompanyByDeposit: '',
  resultDeposit: [],
  AccountNoAllDeposit: '',
  PaymentMethodAllDeposit: '',
};

const initTabCharge = {
  BankCodeLoanCharge: '',
  resultLoanCharge: [],
  AccountNoAllLoanCharge: '',
  PaymentMethodAllLoanCharge: '',

  BankCodeDepositCharge: '',
  resultDepositCharge: [],
  AccountNoAllDepositCharge: '',
  PaymentMethodAllDepositCharge: '',
};

const initCharge = {
  PaymentDateStr: '',
  Direction: '',
  FlowType: '',
  Amount: '',
  Currency: '',
  PaymentMethod: '',
  Source: '',
  inputMaskAmount: {
    prefix: '',
    digits: 2,
    digitsOptional: false,
    placeholder: '0.00',
  },
};

const initModalSplit = {
  CashflowId: '',
  CashFlowType: '',
  Amount: '',
  OldAmount: '',
  Mode: '',
  Source: '',
  inputMaskAmount: {
    prefix: '',
    digits: 2,
    digitsOptional: false,
    placeholder: '0.00',
  },
};

const initDeleteCashFlow = {
  CashFlowId: '',
  Index: '',
  CashFlowType: '',
};

class PnLoanContainer extends Component {
  state = {
    ...initInformation,
    ...initStructure,
    ...initInterest,
    ...initAdministrator,
    ...initCashFlow,
    ...initTabCharge,
    permission,

    mode: 'create',

    cashFlowLoan: [],
    cashFlowDeposit: [],

    modalCharge: {
      ...initCharge,
    },
    modalSplit: {
      ...initModalSplit,
    },
    modalDelete: {
      ...initDeleteCashFlow,
    },
    needReProcess: false,
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;
    if (!transactionNo || transactionNo.toString().toLowerCase() === 'create') {
      this.setState({
        mode: 'create',
      });
    } else {
      this.fetchTransaction(transactionNo);
    }

    this.props.getTemplateInterestRateList();

    $('body').on('click', `button.delete-result`, (e) => this.onClickOpenModalDeleteCashFlow(e));
    $('body').on('click', `button.split-result`, (e) => this.onClickOpenModalSplit(e));
    $('body').on('click', `button.undo-split-result`, (e) => this.onClickOpenModalUndoSplit(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.delete-result`);
    $('body').off('click', `button.split-result`);
    $('body').off('click', `button.undo-split-result`);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      CompanyCode,
      BusinessPartner,
      IsProject,
      TermStartStr,
      TermEndStr,
      Currency,
      PartnerType,
      TransactionType,
      LoanType,
      Amount,
    } = this.state;

    // Get Facility
    if (
      (prevState.CompanyCode !== CompanyCode ||
        prevState.BusinessPartner !== BusinessPartner ||
        prevState.IsProject !== IsProject ||
        prevState.TermStartStr !== TermStartStr ||
        prevState.TermEndStr !== TermEndStr ||
        prevState.Currency !== Currency) &&
      CompanyCode &&
      BusinessPartner &&
      TermStartStr &&
      Currency
    ) {
      const facilityData = {
        CompanyCode,
        BusinessPartnerCode: BusinessPartner,
        ProductType: 'PN',
        IsProject,
        StartDateStr: TermStartStr,
        EndDateStr: TermEndStr,
        Currency,
      };

      this.props.getFacilityList(facilityData).then((response) => {
        if (response.error && !response.payload) return;

        const list = response.payload;
        for (let i = 0; i < list.length; i++) {
          const row = list[i];
          if (row.transactionNo === this.state.FacilityNo) {
            this.setState({
              FacilityNo: row.transactionNo,
              Outstanding: row.outstanding,
            });
          }
        }
      });
    }

    // get currency digit
    if (prevState.Currency !== Currency) {
      this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          Amount: toFixed(Number((Amount.toString() || '').replace(/,/g, '') || 0), digit),
        });
      });
    }

    // set GVC
    if (
      (prevState.LoanType !== LoanType ||
        prevState.TermStartStr !== TermStartStr ||
        prevState.TermEndStr !== TermEndStr) &&
      LoanType &&
      TermStartStr
    ) {
      this.setState({
        GenValnClass: this.getGalnVenClass(LoanType, TermStartStr, TermEndStr),
        NDay: this.getNDay(LoanType, TermStartStr, TermEndStr),
      });
    }

    // Set Company Cash Flow
    if (
      (prevState.CompanyCode !== CompanyCode ||
        prevState.PartnerType !== PartnerType ||
        prevState.TransactionType !== TransactionType ||
        prevState.BusinessPartner !== BusinessPartner) &&
      CompanyCode &&
      BusinessPartner &&
      PartnerType &&
      TransactionType
    ) {
      const companyByLoan = this.setCompanyLoan(
        PartnerType,
        TransactionType,
        CompanyCode,
        BusinessPartner
      );
      const companyByDeposit = this.setCompanyDeposit(
        PartnerType,
        TransactionType,
        CompanyCode,
        BusinessPartner
      );
      this.setState({
        CompanyByLoan: companyByLoan,
        CompanyByDeposit: companyByDeposit,
      });
    }
  }

  fetchTransaction(transactionNo) {
    if (!transactionNo) return;

    this.props.getLoan(transactionNo).then((response) => {
      if (response.error || !response.payload) return;

      const s = response.payload;

      const fetchData = {
        ...toUpperKey(s),
        mode: 'edit',

        ManualPeriodEnd:
          s.periodEndType === 'End of Term'
            ? null
            : s.isManualMonthEnd == null
              ? ''
              : s.isManualMonthEnd
                ? 'Month End'
                : 'Day',
        ManualDueDate:
          s.dueDateType === 'End of Term'
            ? null
            : s.isDueDateMonthEnd == null
              ? ''
              : s.isDueDateMonthEnd
                ? 'Month End'
                : 'Day',
        ManualDay: s.periodEndType === 'End of Term' ? null : s.manualDay,
        DueDateDay: s.dueDateType === 'End of Term' ? null : s.dueDateDay,
        IsManualInclude: s.isManualInclude == null ? false : s.isManualInclude,

        CompanyByLoan: this.setCompanyLoan(
          s.partnerType,
          s.transactionType,
          s.companyCode,
          s.businessPartner
        ),
        CompanyByDeposit: this.setCompanyDeposit(
          s.partnerType,
          s.transactionType,
          s.companyCode,
          s.businessPartner
        ),
        BankCodeLoan: (s.bankCodeLoan || '').split(','),
        BankCodeDeposit: (s.bankCodeDeposit || '').split(','),
      };

      // console.log(fetchData)
      this.setState(fetchData, () => {
        if (s.isSweep) {
          this.setCashFlowSweep(s.cashFlow || []);
        } else {
          this.setCashFlowTab(s.cashFlow || []);
        }

        const facilityData = {
          CompanyCode: s.companyCode,
          BusinessPartnerCode: s.businessPartner,
          ProductType: 'PN',
          IsProject: s.isProject,
          StartDateStr: s.termStartStr,
          EndDateStr: s.termEndStr,
          Currency: s.currency,
        };
        this.props.getFacilityList(facilityData).then((response) => {
          if (response.error && !response.payload) return;

          const list = response.payload;
          for (let i = 0; i < list.length; i++) {
            const row = list[i];
            if (row.transactionNo === this.state.FacilityNo) {
              this.setState({
                FacilityNo: row.transactionNo,
                Outstanding: row.outstanding,
              });
            }
          }
        });
      });
    });
  }

  setCashFlowTab(resultCashFlow) {
    const resultLoan = [];
    const resultDeposit = [];
    const resultLoanCharge = [];
    const resultDepositCharge = [];

    for (let i = 0; i < resultCashFlow.length; i++) {
      const r = resultCashFlow[i];

      const cashFlowType = (r.cashFlowType || '').toLowerCase();

      if (r.currency === r.localCurrency && r.currency === r.bankCurrency) {
        r.exchangeRate = 1;
      }

      if (r.isManualCreate) {
        if (cashFlowType === 'loan') {
          resultLoanCharge.push(r);
        } else if (cashFlowType === 'deposit') {
          resultDepositCharge.push(r);
        }
      } else if (cashFlowType === 'loan') {
        resultLoan.push(r);
      } else if (cashFlowType === 'deposit') {
        resultDeposit.push(r);
      }
    }

    this.setState({
      resultLoan,
      resultDeposit,
      resultLoanCharge,
      resultDepositCharge,
    });
  }

  setCashFlowSweep(resultCashFlow) {
    const resultLoan = [];
    const resultDeposit = [];
    const cashFlowLoan = [];
    const cashFlowDeposit = [];

    let cashType = 'deposit';
    let bankCode = '';
    let bankFullName = '';
    let result = [];

    for (let i = 0; i < resultCashFlow.length; i++) {
      const r = resultCashFlow[i];

      const cashFlowType = (r.cashFlowType || '').toLowerCase();

      if (cashFlowType === 'loan') {
        resultLoan.push(r);
      } else if (cashFlowType === 'deposit') {
        resultDeposit.push(r);
      }

      if (!r.isManualCreate) {
        if (!bankCode) {
          bankCode = r.bankCode;
          bankFullName = `${r.bankCode} | ${r.bankAbbreviate} | ${r.bankName}`;
          result.push(r);
        } else if (bankCode !== r.bankCode || cashType !== cashFlowType) {
          if (cashType === 'deposit') {
            cashFlowDeposit.push({
              bankCode,
              bankFullName,
              result,
            });
          } else {
            cashFlowLoan.push({
              bankCode,
              bankFullName,
              result,
            });
          }
          bankCode = r.bankCode;
          bankFullName = `${r.bankCode} | ${r.bankAbbreviate} | ${r.bankName}`;
          cashType = cashFlowType;
          result = [];
          result.push(r);
        } else {
          result.push(r);
        }
      }

      if ((result || []).length && i === resultCashFlow.length - 1) {
        if (cashFlowType === 'loan') {
          cashFlowLoan.push({
            bankCode,
            bankFullName,
            result,
          });
        } else {
          cashFlowDeposit.push({
            bankCode,
            bankFullName,
            result,
          });
        }
      }
    }

    this.setState({
      resultLoan,
      resultDeposit,
      cashFlowLoan,
      cashFlowDeposit,
    });
  }

  onChangeInputData = (e) => {
    const { name, value, type, checked } = e.target;

    this.setState((state) => {
      let stateToUpdate = {
        [name]: type === 'checkbox' ? checked : value,
        needReProcess: true,
      };

      // Information
      if (name === 'CompanyCode') {
        stateToUpdate.BusinessArea = '';
      } else if (name === 'BusinessPartner') {
        stateToUpdate.BusinessAreaPartner = '';
      } else if (name === 'IsProject') {
        stateToUpdate.IsProject = !state.IsProject;
      }

      // Tab Structure
      else if (name === 'LoanType') {
        if (value === 'On Call') {
          stateToUpdate.TermEndStr = '';
        }
      } else if (name === 'PartnerType') {
        stateToUpdate.TransactionType = '';
        stateToUpdate.IcType = '';
        stateToUpdate.BusinessPartner = '';
        if (value === 'External') {
          stateToUpdate.TransactionType = 'Loan';
        }
      } else if (name === 'ContractDateStr') {
        if (value === '') {
          stateToUpdate.ContractDateStr = '';
          stateToUpdate.ContractDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.TermStartStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Contract date must be less than or equal Term start.',
            });
            return {
              [name]: state.ContractDateStr,
            };
          }
          if (compareDate(mergeState.ContractDateStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Contract date must be less than or equal Term end.',
            });
            return {
              [name]: state.ContractDateStr,
            };
          }
        }
      } else if (name === 'TermStartStr') {
        if (value === '') {
          stateToUpdate.TermStartStr = '';
          stateToUpdate.TermStart = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.TermStartStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term start must be more than or equal Contract date.',
            });
            return {
              [name]: state.TermStartStr,
            };
          }
          if (compareDate(mergeState.TermStartStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term start must be less than or equal Term end.',
            });
            return {
              [name]: state.TermStartStr,
            };
          }
        }
      } else if (name === 'TermEndStr') {
        if (value === '') {
          stateToUpdate.TermEndStr = '';
          stateToUpdate.TermEnd = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.TermStartStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term end must be more than or equal Term start.',
            });
            return {
              [name]: state.TermEndStr,
            };
          }
          if (compareDate(mergeState.ContractDateStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term end must be more than or equal Contract date.',
            });
            return {
              [name]: state.TermEndStr,
            };
          }
        }
      } else if (name === 'IsThaiSweep') {
        if (checked) {
          stateToUpdate = {
            ...stateToUpdate,
            CalculateMethod: 'Act/365',
            InterestRateId: '',
            PeriodEndType: 'Manual Period End',
            ManualPeriodEnd: 'Day',
            ManualDay: '22',
            ManualWorkingDay: '0: No Shift',
            DueDateType: 'Manual Due Date',
            ManualDueDate: 'Month End',
            DueDateDay: '',
            Frequency: '',
            DueDateWorkingDay: '6: Next Month Modified',
            IsManualInclude: true,
          };
        }
      }
      // Tab Interest
      else if (name === 'IsManualInclude') {
        stateToUpdate.IsManualInclude = !state.IsManualInclude;
      } else if (name === 'PeriodEndType') {
        if (value === 'End of Term') {
          stateToUpdate.ManualPeriodEnd = '';
          stateToUpdate.IsManualMonthEnd = false;
          stateToUpdate.ManualDay = '';
        }
      } else if (name === 'ManualPeriodEnd') {
        if (value === 'Month End') {
          stateToUpdate.IsManualMonthEnd = true;
          stateToUpdate.ManualDay = '';
        } else stateToUpdate.IsManualMonthEnd = false;
      } else if (name === 'ManualDay') {
        if (value > 31) stateToUpdate.ManualDay = 31;
        else if (value < 1) stateToUpdate.ManualDay = 1;
      } else if (name === 'DueDateType') {
        if (value === 'End of Term') {
          stateToUpdate.ManualDueDate = '';
          stateToUpdate.DueDateDay = '';
          stateToUpdate.IsDueDateMonthEnd = false;
        }
      } else if (name === 'ManualDueDate') {
        if (value === 'Month End') {
          stateToUpdate.IsDueDateMonthEnd = true;
          stateToUpdate.DueDateDay = '';
        } else stateToUpdate.IsDueDateMonthEnd = false;
      } else if (name === 'DueDateDay') {
        if (value > 31) stateToUpdate.DueDateDay = 31;
        else if (value < 1) stateToUpdate.DueDateDay = 1;
      }

      // Facility
      if (name === 'FacilityNo') {
        if (value) {
          for (let i = 0; i < this.props.facilityList.length; i++) {
            const r = this.props.facilityList[i];
            if (r.transactionNo === value) {
              stateToUpdate.FacilityNo = r.transactionNo;
              stateToUpdate.Outstanding = r.outstanding;
            }
          }
        } else {
          stateToUpdate.FacilityNo = '';
          stateToUpdate.Outstanding = '';
        }
      }

      return stateToUpdate;
    });

    if (name === 'InterestRateId' && value) {
      this.props.getInterestRate(value).then((response) => {
        const templateData = response.payload || initInterest;
        if (!response.error) {
          this.setState({
            ...toUpperKey(templateData),
            ManualPeriodEnd:
              templateData.periodEndType === 'End of Term'
                ? null
                : templateData.isManualMonthEnd == null
                  ? ''
                  : templateData.isManualMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDueDate:
              templateData.dueDateType === 'End of Term'
                ? null
                : templateData.isDueDateMonthEnd == null
                  ? ''
                  : templateData.isDueDateMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDay: templateData.periodEndType === 'End of Term' ? null : templateData.manualDay,
            DueDateDay: templateData.dueDateType === 'End of Term' ? null : templateData.dueDateDay,
            IsManualInclude:
              response.payload.isManualInclude == null ? false : response.payload.isManualInclude,
          });
        }
      });
    }
  };

  onChangeSelect2 = (e) => {
    if (e.value.length < 1) {
      this.setState({
        ...this.state,
        [e.name]: [''],
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        ...this.state,
        [e.name]: e.value.filter((item) => item !== ''),
      });
    } else {
      this.setState({
        ...this.state,
        [e.name]: e.value,
      });
    }
  };

  getGalnVenClass = (loanType, startTerm, endTerm) => {
    if (loanType === 'On Call') {
      return 'ON CALL';
    }
    if (startTerm && endTerm) {
      const m = getCountMonth(startTerm, endTerm);
      if (m <= 12) {
        return 'SHORT TERM';
      }
      return 'LONG TERM';
    }
    return '';
  };

  getNDay = (loanType, startTerm, endTerm) => {
    if (loanType !== 'On Call' && startTerm && endTerm) {
      return getCountDay(startTerm, endTerm);
    }
    return '';
  };

  setCompanyLoan = (partnerType, transactionType, companyCode, businessPartner) => {
    if (partnerType === 'External') {
      return companyCode;
    }
    if (partnerType === 'Intercompany') {
      if (transactionType === 'Loan') {
        return companyCode;
      }
      if (transactionType === 'Deposit') {
        return businessPartner;
      }
      return '';
    }
    return '';
  };

  setCompanyDeposit = (partnerType, transactionType, companyCode, businessPartner) => {
    if (partnerType === 'External') {
      return '';
    }
    if (partnerType === 'Intercompany') {
      if (transactionType === 'Loan') {
        return businessPartner;
      }
      if (transactionType === 'Deposit') {
        return companyCode;
      }
      return '';
    }
    return '';
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmit = (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formStructorRef,
      this.formInterestRef,
      this.formAdministratorRef,
      this.formCashFlowLoanRef,
      this.formCashFlowDepositRef,
      this.formResultCashFlowLoanRef,
      this.formResultCashFlowDepositRef,
    ]);
    if (inValidFormName) return;

    const state = { ...this.state };
    if (!state.InterestRate || parseFloat(state.InterestRate) <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Interest rate (%) must more than zero.',
      });
      return;
    }
    if (state.InterestRate > 100) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Interest rate (%) must less than or eqaul 100%.',
      });
      return;
    }

    if (
      (!(state.resultDeposit || []).length && state.PartnerType === 'Intercompany') ||
      !(state.resultLoan || []).length
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please get cash flow.',
      });
      return;
    }

    const cashflow = [
      ...this.state.resultDeposit,
      ...this.state.resultLoan,
      ...this.state.resultDepositCharge,
      ...this.state.resultLoanCharge,
    ];

    const errorValidate = validateCashflow(cashflow);
    if (errorValidate) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: errorValidate,
      });
      return;
    }

    const submitData = {
      ...this.state,
      Source: 'PN',
      BankCodeDeposit: this.state.BankCodeDeposit.toString(),
      BankCodeLoan: this.state.BankCodeLoan.toString(),
      CashFlow: cashflow,
    };
    delete submitData.resultDeposit;
    delete submitData.resultLoan;
    delete submitData.resultDepositCharge;
    delete submitData.resultLoanCharge;
    delete submitData.modalSplit;
    delete submitData.modalDelete;
    delete submitData.modalCharge;

    this.props.saveLoan(submitData).then((response) => {
      if (!response.error) {
        const transactionNo = response.payload;
        this.fetchTransaction(transactionNo);

        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The transaction was successfully saved',
          buttons: [
            <button
              className="btn btn-success"
              onClick={this.onClickBackToPage}
              data-dismiss="modal"
            >
              Continue
            </button>,
          ],
        });

        if (response.payload !== this.props.routeProp.match.params.id) {
          window.location.href = `/loan-investment/pn-loan/${response.payload}`;
        }
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/loan-investment/create-pn-loan?s=true');
  };

  // Tab Cash Flow
  onClickGetCashflow = () => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formStructorRef,
      this.formInterestRef,
      this.formAdministratorRef,
      this.formCashFlowLoanRef,
      this.formCashFlowDepositRef,
    ]);

    if (inValidFormName) return;

    const state = { ...this.state };
    let error = '';
    if (!state.InterestRate || parseFloat(state.InterestRate) <= 0) {
      error = 'Interest rate (%) must more than zero.';
    }
    if (!state.Currency) {
      error = 'Please specific Currency for process cashflow.';
    }

    if (error) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: error,
      });
      return;
    }

    const processData = { ...this.state };
    processData.BankCodeDeposit = (processData.BankCodeDeposit || []).join(',');
    processData.BankCodeLoan = (processData.BankCodeLoan || []).join(',');

    this.props.processCashflow(processData).then((response) => {
      if (response.error) return;

      const loan = [];
      const deposit = [];
      response.payload.forEach((m) => {
        if (m.cashFlowType === 'DEPOSIT') deposit.push({ ...m });
        else loan.push({ ...m });
      });

      this.setState({
        resultDeposit: deposit,
        resultLoan: loan,
        needReProcess: false,
      });
    });
  };

  async getExchangeRate(rowData, idx, keyForUpdate) {
    const criteria = {
      CurrencyFrom: rowData.currency,
      CurrencyTo: rowData.localCurrency,
      Type: (rowData.cashFlowType || '').toLowerCase() === 'deposit' ? 'G' : 'B',
    };

    let exchangeRate = 1;
    if (criteria.CurrencyFrom !== criteria.CurrencyTo) {
      const response = await this.props.getExchangeRate(criteria);
      if (response.error) return;
      exchangeRate = response.payload;
    }

    this.setState((state) => ({
      [keyForUpdate]: state[keyForUpdate].map((m, i) => {
        if (i === idx)
          return {
            ...m,
            exchangeRate: exchangeRate || 1,
            localCurrencyAmount: rowData.amount * exchangeRate,
          };
        return m;
      }),
    }));
  }

  // onChangeInputTable = (e, index,type) => {
  //     let { name, value, label } = e.target
  //     this.setState(state => {
  //         let keyForUpdate = type === 'deposit' ? 'resultDeposit' : 'resultLoan'
  //         let result = state[keyForUpdate]
  //         return {
  //             [keyForUpdate]: result.map((m, i) => {
  //                 if (i === index) {
  //                     let obj = {
  //                         ...m,
  //                     }
  //                     if (name === 'bankAccountNo') {
  //                         obj.bankAccountNo = value
  //                         let bankAccountCurrency = label.split('|')[2].trim()
  //                         if (obj.currency === bankAccountCurrency && obj.localCurrency === bankAccountCurrency) {
  //                             obj.exchangeRate = 1
  //                             obj.localCurrencyAmount = obj.amount
  //                         }
  //                         else {
  //                             obj.exchangeRate = ''
  //                             obj.localCurrencyAmount = ''
  //                         }
  //                     }
  //                     else
  //                         obj[name] = value
  //                     return obj
  //                 }
  //                 else
  //                     return m
  //             })
  //         }
  //     })
  // }

  onChangeInputTable = (e, index, type) => {
    const { name, value, label } = e.target;

    let startDate = '';
    let endDate = '';
    let flowType = '';
    if (type === 'loan') {
      const r = this.state.resultLoan[index];
      startDate = r.startInterestDateStr;
      endDate = r.endInterestDateStr;
      flowType = r.flowType;
    } else if (type === 'deposit') {
      const r = this.state.resultDeposit[index];
      startDate = r.startInterestDateStr;
      endDate = r.endInterestDateStr;
      flowType = r.flowType;
    }

    let checkRollover = false;
    if (flowType.includes('Principal Rollover')) {
      checkRollover = true;
    }

    this.setState((state) => {
      const keyForUpdate = type === 'deposit' ? 'resultDeposit' : 'resultLoan';
      const result = state[keyForUpdate];

      const keyForUpdate2 = type === 'loan' ? 'resultDeposit' : 'resultLoan';
      const result2 = state[keyForUpdate2];
      return {
        [keyForUpdate]: result.map((m, i) => {
          if (i === index) {
            const obj = {
              ...m,
            };
            if (name === 'bankAccountNo') {
              // update only have value
              obj.bankAccountNo = value;

              const bankAccountCurrency = (label.split('|')[2] || '').trim();
              if (
                obj.currency === bankAccountCurrency &&
                obj.localCurrency === bankAccountCurrency
              ) {
                obj.exchangeRate = 1;
                obj.localCurrencyAmount = obj.amount;
              } else {
                obj.exchangeRate = '';
                obj.localCurrencyAmount = '';
              }
            } else if (name === 'originalInterestAmount') {
              const amount = Number(value || 0);
              if (obj.wht) obj.whtAmount = Math.round(amount * obj.wht * 100) / 100;
              obj[name] = value;
            } else obj[name] = value;

            return obj;
          }
          return m;
        }),
        [keyForUpdate2]: result2.map((m, i) => {
          let checkRollover2 = false;
          if (m.flowType.includes('Principal Rollover')) {
            checkRollover2 = true;
          }

          if (
            flowType.includes('Principal Rollover') &&
            m.flowType.includes('Principal Rollover') &&
            checkRollover === checkRollover2
          ) {
            const obj = {
              ...m,
            };
            if (name !== 'bankAccountNo') obj[name] = value;
            return obj;
          }
          if (
            flowType.includes('Principal') &&
            m.flowType.includes('Principal') &&
            checkRollover === checkRollover2
          ) {
            const obj = {
              ...m,
            };
            if (name !== 'bankAccountNo') obj[name] = value;
            return obj;
          }
          if (flowType.includes('Repayment') && m.flowType.includes('Repayment')) {
            const obj = {
              ...m,
            };
            if (name !== 'bankAccountNo') obj[name] = value;
            return obj;
          }
          if (
            flowType.includes('Interest') &&
            m.flowType.includes('Interest') &&
            startDate === m.startInterestDateStr &&
            endDate === m.endInterestDateStr
          ) {
            const obj = {
              ...m,
            };
            if (name !== 'bankAccountNo') obj[name] = value;
            return obj;
          }
          return m;
        }),
      };
    });
  };

  onChangeInputHeader = (e, type) => {
    const { name, value, label } = e.target;
    this.setState((state) => {
      const keyForUpdate =
        (type || '').toLowerCase() === 'deposit' ? 'resultDeposit' : 'resultLoan';
      let keyInputAll = '';
      let keyInputAll2 = '';
      if (name === 'bankAccountNoAll') {
        keyInputAll =
          (type || '').toLowerCase() === 'deposit' ? 'AccountNoAllDeposit' : 'AccountNoAllLoan';
      } else if (name === 'paymentMethodAll') {
        keyInputAll =
          (type || '').toLowerCase() === 'deposit'
            ? 'PaymentMethodAllDeposit'
            : 'PaymentMethodAllLoan';
        keyInputAll2 =
          (type || '').toLowerCase() === 'loan'
            ? 'PaymentMethodAllDeposit'
            : 'PaymentMethodAllLoan';
      }
      const result = state[keyForUpdate];

      const keyForUpdate2 = (type || '').toLowerCase() === 'loan' ? 'resultDeposit' : 'resultLoan';
      const result2 = state[keyForUpdate2];

      if (name === 'bankAccountNoAll') {
        return {
          [keyInputAll]: value,
          [keyForUpdate]: result.map((m, i) => {
            const obj = {
              ...m,
            };

            if (name === 'bankAccountNoAll') {
              obj.bankAccountNo = value;
              const bankAccountCurrency = label.split('|')[2].trim();
              if (
                obj.currency === bankAccountCurrency &&
                obj.localCurrency === bankAccountCurrency
              ) {
                obj.exchangeRate = 1;
                obj.localCurrencyAmount = obj.amount;
              } else {
                obj.exchangeRate = '';
                obj.localCurrencyAmount = '';
              }
            } else if (name === 'paymentMethodAll') {
              obj.paymentMethod = value;
            }

            return obj;
          }),
        };
      }
      return {
        [keyInputAll]: value,
        [keyForUpdate]: result.map((m, i) => {
          const obj = {
            ...m,
          };
          if (name === 'paymentMethodAll') {
            obj.paymentMethod = value;
          }
          return obj;
        }),
        [keyInputAll2]: value,
        [keyForUpdate2]: result2.map((m, i) => {
          const obj = {
            ...m,
          };
          if (name === 'paymentMethodAll') {
            obj.paymentMethod = value;
          }
          return obj;
        }),
      };
    });
  };

  // Cash Flow Tab Charge
  onChangeInputTableCharge = (e, index, type) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const keyForUpdate = type === 'deposit' ? 'resultDepositCharge' : 'resultLoanCharge';
      const result = state[keyForUpdate];
      return {
        [keyForUpdate]: result.map((m, i) => {
          if (i === index) {
            const obj = {
              ...m,
            };
            // if (name === 'bankAccountNo') {
            //     obj.bankAccountNo = value
            //     let bankAccountCurrency = label.split('|')[2].trim()
            //     if (obj.currency === bankAccountCurrency && obj.localCurrency === bankAccountCurrency) {
            //         obj.exchangeRate = 1
            //         obj.localCurrencyAmount = obj.amount
            //     }
            //     else {
            //         obj.exchangeRate = ''
            //         obj.localCurrencyAmount = ''
            //     }
            // }
            // else
            obj[name] = value;
            return obj;
          }
          return m;
        }),
      };
    });
  };

  onChangeInputHeaderCharge = (e, type) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const keyForUpdate =
        (type || '').toLowerCase() === 'deposit' ? 'resultDepositCharge' : 'resultLoanCharge';
      let keyInputAll = '';
      if (name === 'bankAccountNoAll') {
        keyInputAll =
          (type || '').toLowerCase() === 'deposit'
            ? 'AccountNoAllDepositCharge'
            : 'AccountNoAllLoanCharge';
      } else if (name === 'paymentMethodAll') {
        keyInputAll =
          (type || '').toLowerCase() === 'deposit'
            ? 'PaymentMethodAllDepositCharge'
            : 'PaymentMethodAllLoanCharge';
      }
      const result = state[keyForUpdate];

      return {
        [keyInputAll]: value,
        [keyForUpdate]: result.map((m, i) => {
          const obj = {
            ...m,
          };

          if (name === 'bankAccountNoAll') {
            obj.bankAccountNo = value;
            // let bankAccountCurrency = label.split('|')[2].trim()
            // if (obj.currency === bankAccountCurrency && obj.localCurrency === bankAccountCurrency) {
            //     obj.exchangeRate = 1
            //     obj.localCurrencyAmount = obj.amount
            // }
            // else {
            //     obj.exchangeRate = ''
            //     obj.localCurrencyAmount = ''
            // }
          } else if (name === 'paymentMethodAll') {
            obj.paymentMethod = value;
          }

          return obj;
        }),
      };
    });
  };

  // Charge
  onClickOpenModalCharge = (e, modal) => {
    this.setState(
      {
        modalCharge: {
          ...initCharge,
          Source: modal,
        },
      },
      this.modalChargeRef.open
    );
  };

  onChangeModalCharge = (e) => {
    const { name, value } = e.target;

    if (name === 'Currency') {
      this.props.getCurrencyDigit({ criteria: value }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          modalCharge: {
            ...this.state.modalCharge,
            Currency: value,
            inputMaskAmount: {
              prefix: '',
              digits: digit,
              digitsOptional: false,
              placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
            },
            Amount: toFixed(
              Number((this.state.modalCharge.Amount.toString() || '').replace(/,/g, '') || 0),
              digit
            ),
          },
        });
      });
    } else {
      this.setState({
        modalCharge: {
          ...this.state.modalCharge,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  onSubmitModalCharge = (e) => {
    e.preventDefault();
    this.setState((state) => {
      const charge = state.modalCharge;
      const keyForUpdate = charge.Source === 'deposit' ? 'resultDepositCharge' : 'resultLoanCharge';
      const result = state[keyForUpdate];
      return {
        [keyForUpdate]: [
          ...result,
          {
            paymentDateStr: charge.PaymentDateStr,
            direction: charge.Direction,
            flowType: charge.FlowType,
            amount: charge.Amount,
            currency: charge.Currency,
            paymentMethod: charge.PaymentMethod,
            isManualCreate: true,
            cashFlowType: (charge.Source || '').toUpperCase(),
            source: 'PN',
            transactionNo: state.TransactionNo || '',
          },
        ],
      };
    });
    this.modalChargeRef.close();
  };

  // Delete Cash Flow
  onClickOpenModalDeleteCashFlow = (e) => {
    this.setState(
      {
        modalDelete: {
          ...initDeleteCashFlow,
          CashFlowId: e.currentTarget.attributes.cashflowid.value,
          Index: e.currentTarget.attributes.index.value,
          CashFlowType: e.currentTarget.attributes.cashflowtype.value,
        },
      },
      this.modalDeleteRef.open
    );
  };

  onClickConfirmModalDeleteCashFlow = () => {
    const modalDelete = { ...this.state.modalDelete };

    const keyForUpdate =
      (modalDelete.CashFlowType || '').toLowerCase() === 'deposit'
        ? 'resultDepositCharge'
        : 'resultLoanCharge';
    const result = this.state[keyForUpdate];
    const index = parseInt(modalDelete.Index);
    const newResult = result.filter((item, i) => i !== index);

    if ((modalDelete.CashFlowType || '').toLowerCase() === 'deposit')
      this.setState(
        {
          resultDepositCharge: newResult,
        },
        this.modalDeleteRef.close
      );
    else
      this.setState(
        {
          resultLoanCharge: newResult,
        },
        this.modalDeleteRef.close
      );
  };

  // Modal Split
  onClickOpenModalSplit = (e) => {
    const row = e.currentTarget.attributes;
    const digit = row.currency.value;

    this.setState(
      {
        modalSplit: {
          ...initModalSplit,
          CashflowId: row.cashflowid.value,
          CashFlowType: row.cashflowtype.value,
          OldAmount: toFixed(
            Number((row.amount.value.toString() || '').replace(/,/g, '') || 0),
            digit
          ),
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
        },
      },
      this.modalSplitRef.open
    );
  };

  onChangeModalSplit = (e) => {
    this.setState({
      modalSplit: {
        ...this.state.modalSplit,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalSplit = (e) => {
    e.preventDefault();

    const modalSplit = { ...this.state.modalSplit };

    if (
      !modalSplit.Amount ||
      parseFloat((modalSplit.Amount.toString() || '').replace(/,/g, '')) === 0
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Split amount must not be zero.',
      });
      return;
    }
    if (
      parseFloat((modalSplit.Amount.toString() || '').replace(/,/g, '')) >
      parseFloat((modalSplit.OldAmount.toString() || '').replace(/,/g, ''))
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Split amount must less than amount.',
      });
      return;
    }

    const submitData = {
      CashflowId: modalSplit.CashflowId,
      Amount: modalSplit.Amount,
      Mode: 'split',
    };

    this.props.splitCashFlow(submitData).then((response) => {
      if (response.error && !response.payload) return;
      const resultCashFlow = response.payload || [];
      this.setCashFlowTab(resultCashFlow);
    });
    this.modalSplitRef.close();
  };

  // Modal Undo Split
  onClickOpenModalUndoSplit = (e) => {
    this.setState(
      {
        modalSplit: {
          ...initModalSplit,
          CashflowId: e.currentTarget.attributes.cashflowid.value,
          Amount: e.currentTarget.attributes.amount.value,
          CashFlowType: e.currentTarget.attributes.cashflowtype.value,
        },
      },
      this.modalUndoSplitRef.open
    );
  };

  onSubmitModalUndoSplit = () => {
    const modalSplit = { ...this.state.modalSplit };

    const submitData = {
      CashflowId: modalSplit.CashflowId,
      Amount: modalSplit.Amount,
      Mode: 'undo',
    };

    this.props.splitCashFlow(submitData).then((response) => {
      if (response.error && !response.payload) return;
      const resultCashFlow = response.payload || [];
      this.setCashFlowTab(resultCashFlow);
    });
    this.modalUndoSplitRef.close();
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,

      template: this.props.template,
      facilityList: this.props.facilityList,
      onChangeInputTable: this.onChangeInputTable,
      onClickGetCashflow: this.onClickGetCashflow,
      onChangeInputHeader: this.onChangeInputHeader,

      onChangeInputTableCharge: this.onChangeInputTableCharge,
      onChangeInputHeaderCharge: this.onChangeInputHeaderCharge,

      onClickOpenModalCharge: this.onClickOpenModalCharge,
      onChangeModalCharge: this.onChangeModalCharge,
      onSubmitModalCharge: this.onSubmitModalCharge,

      onChangeModalSplit: this.onChangeModalSplit,
      onSubmitModalSplit: this.onSubmitModalSplit,
      onSubmitModalUndoSplit: this.onSubmitModalUndoSplit,

      onClickConfirmModalDeleteCashFlow: this.onClickConfirmModalDeleteCashFlow,

      formDetailRef: (e) => (this.formDetailRef = e),
      formStructorRef: (e) => (this.formStructorRef = e),
      formInterestRef: (e) => (this.formInterestRef = e),
      formAdministratorRef: (e) => (this.formAdministratorRef = e),
      formCashFlowLoanRef: (e) => (this.formCashFlowLoanRef = e),
      formCashFlowDepositRef: (e) => (this.formCashFlowDepositRef = e),
      formResultCashFlowLoanRef: (e) => (this.formResultCashFlowLoanRef = e),
      formResultCashFlowDepositRef: (e) => (this.formResultCashFlowDepositRef = e),

      formCashFlowLoanChargeRef: (e) => (this.formCashFlowLoanChargeRef = e),
      formCashFlowDepositChargeRef: (e) => (this.formCashFlowDepositChargeRef = e),
      formResultCashFlowLoanChargeRef: (e) => (this.formResultCashFlowLoanChargeRef = e),
      formResultCashFlowDepositChargeRef: (e) => (this.formResultCashFlowDepositChargeRef = e),

      tabRef: (e) => (this.tabRef = e),
      onChangeSelect2: this.onChangeSelect2,
    };
    return (
      <PnLoan
        {...props}
        state={this.state}
        modalCharge={this.state.modalCharge}
        modalChargeRef={(e) => (this.modalChargeRef = e)}
        modalDeleteRef={(e) => (this.modalDeleteRef = e)}
        modalSplitRef={(e) => (this.modalSplitRef = e)}
        modalUndoSplitRef={(e) => (this.modalUndoSplitRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.pnLoan,
  }),
  {
    saveLoan,
    getLoan,
    getInterestRate,
    addAlert,
    getTemplateInterestRateList,
    processCashflow,
    getFacilityList,
    getFacilityForLoan,
    getExchangeRate,
    splitCashFlow,
    getCurrencyDigit,
  }
)(PnLoanContainer);
