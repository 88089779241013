import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Button from '../../common/Button';
import FormGroup2 from '../../common/FormGroup2';
import { getPermission } from '../../common/helpper';

const permission = getPermission('Bank Guarantee', 'Maintain Form');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onChangeSelect2,
  onChangeInputTable,
  onSubmit,

  masterLgTypeList,
}) => {
  const textHeader =
    state.Action === 'create'
      ? 'Create Maitain Form'
      : `Update Maintain Form : ${state.OldFormName}`;
  const noEdit = !permission;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Bank Guarantee <span className="icon icon-angle-double-right" />
            <Link txt="Maintain Form" href="/bank-guarantee/search-maintain-form" />
            <span className="icon icon-angle-double-right" /> Maintain Form Detail
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card
          textHeader={textHeader}
          bgHeader="bg-primary"
          cardActions={['toggler']}
          footer={
            !permission ? (
              ''
            ) : (
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={noEdit}
              />
            )
          }
        >
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2
                text="Form No."
                required
                maxLength={200}
                length={(state.FormNo || '').length}
              >
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.BgFormName}
                  name="BgFormName"
                  maxLength={200}
                  required
                />
              </FormGroup2>

              <FormGroup2 text="LG Format" required>
                <select
                  tabIndex="-1"
                  className="form-control"
                  value={state.FormFormat}
                  onChange={onChangeInputData}
                  required
                  name="FormFormat"
                >
                  <option value="">---Choose---</option>
                  <option value="Paper">Paper</option>
                  <option value="Blockchain">Blockchain</option>
                </select>
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="LG Type" required>
                <select
                  tabIndex="-1"
                  className="form-control"
                  value={state.LgTypeId}
                  onChange={onChangeInputData}
                  required
                  name="LgTypeId"
                >
                  <option value="">---Choose---</option>
                  {(masterLgTypeList || []).map((m, i) => (
                    <option
                      key={m.lgTypeId}
                      value={m.lgTypeId}
                    >{`${m.lgTypeCode} : ${m.lgTypeName}`}</option>
                  ))}
                </select>
              </FormGroup2>

              <FormGroup2 text="Language" required>
                <select
                  tabIndex="-1"
                  className="form-control"
                  value={state.Lang}
                  onChange={onChangeInputData}
                  required
                  name="Lang"
                >
                  <option value="">---Choose---</option>
                  <option value="TH">TH</option>
                  <option value="EN">EN</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2
                text="Remark"
                required={false}
                maxLength={50}
                length={(state.Remark || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  value={state.Remark || ''}
                  name="Remark"
                  maxLength={50}
                  required={false}
                />
              </FormGroup2>

              <div className="form-group">
                <div>
                  <input
                    id="IsCloseEnd"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeInputData}
                    value={state.IsCloseEnd}
                    name="IsCloseEnd"
                    checked={state.IsCloseEnd}
                  />
                  <label htmlFor="IsCloseEnd" className="label_checkbox" />
                  <label htmlFor="IsCloseEnd" className="control-label text_label_checkbox">
                    Close End
                  </label>
                </div>
              </div>
            </Col2>

            <Col2 col={colX[1]}>
              <div className="form-group">
                <div>
                  <input
                    id="IsOpenEnd"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeInputData}
                    value={state.IsOpenEnd}
                    name="IsOpenEnd"
                    checked={state.IsOpenEnd}
                  />
                  <label htmlFor="IsOpenEnd" className="label_checkbox" />
                  <label htmlFor="IsOpenEnd" className="control-label text_label_checkbox">
                    Open End
                  </label>
                </div>
              </div>

              {/* {
                                state.FormFormat === 'Paper' ? (
                                    <div className="form-group">
                                        <div>
                                            <input id="IsBeneficiaryForm" type="checkbox" className="label-checkbox"
                                                onChange={onChangeInputData}
                                                value={state.IsBeneficiaryForm}
                                                name="IsBeneficiaryForm"
                                                checked={state.IsBeneficiaryForm}
                                            />
                                            <label htmlFor="IsBeneficiaryForm" className="label_checkbox"></label>
                                            <label htmlFor="IsBeneficiaryForm" className="control-label text_label_checkbox">Beneficiary Form</label>
                                        </div>
                                    </div>
                                ) : null
                            } */}
            </Col2>
          </Col2>

          {/* {
                        toLower(state.FormFormat) === 'blockchain' ? ( */}
          <Col2 col={colX[2]}>
            <div className="table-responsive">
              <table className="table table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th>Choose</th>
                    <th>Field Code</th>
                    <th>Field Type</th>
                    <th>Name</th>
                    <th>Required</th>
                  </tr>
                </thead>
                <tbody>
                  {(state.BlockChains || []).map((m, i) => (
                    <tr key={i}>
                      <td align="center" style={{ width: '15%' }}>
                        <div>
                          <input
                            id={`isSelected${i}`}
                            type="checkbox"
                            className="label-checkbox"
                            onChange={(e) => onChangeInputTable(e, i)}
                            name="isSelected"
                            checked={m.isSelected}
                          />
                          <label htmlFor={`isSelected${i}`} className="label_checkbox" />
                        </div>
                      </td>
                      <td style={{ width: '20%' }}>{m.field}</td>
                      <td align="center" style={{ width: '20%' }}>
                        {m.isSelected ? (
                          <div>
                            <select
                              tabIndex="-1"
                              className="form-control"
                              value={m.fieldType || ''}
                              onChange={(e) => onChangeInputTable(e, i)}
                              required
                              name={`fieldType${i}`}
                            >
                              <option value="">---Choose---</option>
                              <option value="Common">Common</option>
                              <option value="Special">Special</option>
                            </select>
                          </div>
                        ) : null}
                      </td>
                      <td align="center" style={{ width: '30%' }}>
                        {m.isSelected ? (
                          <div>
                            {m.fieldType === 'Common' ? (
                              <select
                                tabIndex="-1"
                                className="form-control"
                                value={m.fieldName || ''}
                                onChange={(e) => onChangeInputTable(e, i)}
                                required
                                name={`fieldName${i}`}
                              >
                                <option value="">---Choose---</option>
                                {(state.commonList || []).map((m, i) => (
                                  <option key={m.field} value={m.field}>
                                    {m.fieldName}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <input
                                className="form-control"
                                value={m.fieldName || ''}
                                onChange={(e) => onChangeInputTable(e, i)}
                                required
                                name={`fieldName${i}`}
                              />
                            )}
                          </div>
                        ) : null}
                      </td>
                      <td align="center" style={{ width: '15%' }}>
                        {m.isSelected ? (
                          <div>
                            <input
                              id={`isRequire${i}`}
                              type="checkbox"
                              className="label-checkbox"
                              onChange={(e) => onChangeInputTable(e, i)}
                              name="isRequire"
                              checked={m.isRequire}
                            />
                            <label htmlFor={`isRequire${i}`} className="label_checkbox" />
                          </div>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Col2>
          {/* ) : null
                    } */}
        </Card>
      </form>
    </div>
  );
};
