import { RECEIVE_SEARCH_CASH_POSITION, EDIT_CASH_POSITION_SEACH_RESULT } from './action';

const init = {
  searchResult: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case EDIT_CASH_POSITION_SEACH_RESULT:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i)
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          return m;
        }),
      };
    case RECEIVE_SEARCH_CASH_POSITION:
      return {
        ...state,
        searchResult: [...action.payload],
      };

    default:
      return state;
  }
};
