import React, {
  useRef,
  useState,
  useEffect,
  useReducer,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import { store } from '../../Root';
import Button from '../../common/Button';
import DatePicker from '../../common/DatePicker';
import {
  downloadAttachFile,
  getMasterAttachFile,
  saveMasterAttachFile,
} from '../../reducers/masterAttachFile/action';

const limitFileSize = 1024 * 1024 * 10;
let tempId = 1;

function AttachFile({ limitFileCount, masterType, masterKey, notFetch, isExpired }, ref) {
  const [trigger, setTrigger] = useState(false);
  const fileRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    if (!masterType || !masterKey || !!notFetch) return;
    let ignore = false;
    async function fetch() {
      const res = await store.dispatch(getMasterAttachFile(masterType, masterKey));
      if (res.error || ignore) return;

      dispatch({
        type: 'SET',
        payload: res.payload,
      });
    }
    fetch();

    return () => {
      ignore = true;
    };
  }, [masterType, masterKey, trigger, notFetch]);

  const onClickRemove = useCallback((m) => {
    dispatch({
      type: 'REMOVE',
      payload: m,
    });
  }, []);
  const onChangeFile = useCallback((e) => {
    const file = e.target.files[0];
    console.log(file);
    if (file.size > limitFileSize) {
      alert(`File size more than the limit ${limitFileSize / (1024 * 1024)} MB.`);
      return;
    }

    const payload = {
      file,
      filename: file.name,
      fileTempId: `file_${tempId++}`,
    };
    dispatch({
      type: 'ADD',
      payload,
    });
  }, []);

  const onChangeInputData = useCallback((e, m) => {
    const { name, value } = e.target;
    dispatch({
      type: 'UPDATE',
      payload: {
        name,
        value,
        m,
      },
    });
  }, []);

  const submit = async () => {
    const form = new FormData();
    const data = state.map((m) => {
      if (m.file) form.append(m.fileTempId, m.file);
      return {
        Id: m.id,
        FileTempId: m.fileTempId,
        IsDelete: m.isDelete,
        ExpiredDateStr: m.expiredDateStr,
      };
    });
    form.append('data', JSON.stringify(data));
    const res = await store.dispatch(saveMasterAttachFile(masterType, masterKey, form));
    if (!res.error) setTrigger((state) => !state);

    return res;
  };

  useImperativeHandle(
    ref,
    () => ({
      submit,
      fileLength: state.filter((f) => !f.isDelete).length,
    }),
    [submit, state]
  );
  console.log(state);
  return (
    <>
      <div>
        {/* <Button icon="save" className="btn-success" onClick={onSubmit} txt="save" /> */}
        <Button
          icon="upload"
          className="btn-success"
          onClick={() => fileRef.current.click()}
          disabled={
            limitFileCount !== -1 && limitFileCount <= state.filter((f) => !f.isDelete).length
          }
          txt="Upload"
        />
        <input style={{ display: 'none' }} type="file" ref={fileRef} onChange={onChangeFile} />
      </div>
      <br />
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Remove</th>
              <th>Filename</th>
              <th>Download</th>
              {isExpired && <th>Expired Date</th>}
            </tr>
          </thead>
          <tbody>
            {state
              .filter((f) => !f.isDelete)
              .map((m, i) => (
                <tr key={i}>
                  <td align="center">
                    <button
                      type="button"
                      className="btn btn-warning btn-sm"
                      onClick={() => onClickRemove(m)}
                    >
                      <i className="icon icon-close icon-fw icon-lg" />
                    </button>
                  </td>
                  <td align="center">{m.filename}</td>
                  <td align="center">
                    {m.id ? (
                      <button
                        type="button"
                        className="btn btn-info btn-sm"
                        onClick={() => store.dispatch(downloadAttachFile(m.id))}
                      >
                        <i className="icon icon-download icon-fw icon-lg" />
                      </button>
                    ) : null}
                  </td>
                  {isExpired && (
                    <td align="center" style={{ width: 200 }}>
                      <DatePicker
                        value={m.expiredDateStr}
                        onChange={(e) => onChangeInputData(e, m)}
                        name="expiredDateStr"
                        option={{
                          todayHighlight: true,
                        }}
                      />
                    </td>
                  )}
                </tr>
              ))}
            {!state.length ? (
              <tr>
                <td colSpan={isExpired ? '4' : '3'} align="center">
                  <b>No data.</b>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default forwardRef(AttachFile);

const reducer = (state = [], action) => {
  switch (action.type) {
    case 'REMOVE':
      if (!action.payload.id) return state.filter((f) => f !== action.payload);
      return state.map((m, i) => {
        if (m === action.payload)
          return {
            ...m,
            isDelete: true,
          };
        return m;
      });
    case 'ADD':
      return [...state, action.payload];
    case 'SET':
      return action.payload;
    case 'UPDATE':
      return state.map((m, i) => {
        if (m === action.payload.m)
          return {
            ...m,
            [action.payload.name]: action.payload.value,
          };
        return m;
      });
    default:
      return state;
  }
};
