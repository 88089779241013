import { connect } from 'react-redux';
import React, { Component } from 'react';

import { toUpperKey } from '../../common/helpper';
import FinanceGroupDetail from './FinanceGroupDetail';
import { addAlert } from '../../reducers/layout/action';
import {
  getMasterFinanceGroupDetail,
  saveMasterFinanceGroupDetail,
} from '../../reducers/masterFinanceGroup/action';

const initData = {
  FinanceGroupId: '',
  FinanceGroupAbbreviate: '',
  FinanceGroupName: '',
  SendingType: '',
  EmailCc: '',
  EmailCcBg: '',
  mode: 'create',
  EmailCcQuoteRate: '',
  EmailCcCheque: '',
  EmailFinanceGroup: '',
  EmailFxMatching: '',
  BankGroup: [],
};

const initModal = {
  bankCode: '',
  type: '',
  emailTo: '',
  emailCc: '',
};

class FinanceGroupDetailContainer extends Component {
  state = {
    ...initData,
    modalForm: {
      ...initModal,
      Action: '',
    },
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;

    if (!id || id.toString().toLowerCase() === 'create') {
      this.setState({
        ...initData,
      });
    } else {
      this.fetchId(id);
    }
  }

  fetchId(id) {
    if (!id) return;

    this.props.getMasterFinanceGroupDetail({ FinanceGroupId: id }).then((response) => {
      if (response.error || !response.payload) return;

      const fetchData = toUpperKey(response.payload);
      this.setState({
        ...fetchData,
        mode: 'edit',
      });
    });
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;

    const pattern = /^[a-zA-Z0-9_-\s]+$/;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'FinanceGroupId') {
        stateToUpdate.FinanceGroupId = value.trim();
        if (!pattern.test(value) && value !== '') {
          return state.FinanceGroupId;
        }
        stateToUpdate.FinanceGroupId = (stateToUpdate.FinanceGroupId || '').toUpperCase();
      }

      return stateToUpdate;
    });
  };

  onSubmitHander = (e) => {
    e.preventDefault();

    this.props.saveMasterFinanceGroupDetail(this.state).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        window.location.href = `/master/finance-group-detail/${response.payload}`;
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/master/finance-group');
  };

  onClickAdd = () => {
    this.setState(
      {
        modalForm: {
          ...initModal,
          Action: 'Add',
        },
      },
      this.modalFormRef.open
    );
  };

  onClickEdit = (e, item) => {
    this.setState(
      {
        modalForm: {
          ...item,
          Action: 'Edit',
        },
      },
      this.modalFormRef.open
    );
  };

  onClickDelete = (e, item) => {
    this.setState(
      {
        modalForm: {
          ...item,
          Action: 'Delete',
        },
      },
      this.modalDeleteRef.open
    );
  };

  onDelete = () => {
    const filter = this.state.BankGroup.filter((f) => f.bankCode !== this.state.modalForm.bankCode);
    this.setState(
      {
        ...this.state,
        BankGroup: filter,
      },
      this.modalDeleteRef.close()
    );
  };

  onChangeModal = (e) => {
    this.setState({
      modalForm: {
        ...this.state.modalForm,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSaveModal = (e) => {
    e.preventDefault();
    let list = [];
    const find = this.state.BankGroup.find((f) => f.bankCode === this.state.modalForm.bankCode);
    if (find) {
      this.state.BankGroup.forEach((m) => {
        if (m.bankCode === this.state.modalForm.bankCode) {
          list.push({ ...this.state.modalForm });
        } else {
          list.push({ ...m });
        }
      });
    } else {
      list = [...this.state.BankGroup];
      list.push({ ...this.state.modalForm });
    }
    this.setState(
      {
        ...this.state,
        BankGroup: list,
      },
      this.modalFormRef.close()
    );
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,
      onClickAdd: this.onClickAdd,
      onClickEdit: this.onClickEdit,
      onClickDelete: this.onClickDelete,
      onDelete: this.onDelete,
      onChangeModal: this.onChangeModal,
      onSaveModal: this.onSaveModal,
    };

    return (
      <div>
        <FinanceGroupDetail
          {...props}
          state={this.state}
          modalFormRef={(e) => (this.modalFormRef = e)}
          modalDeleteRef={(e) => (this.modalDeleteRef = e)}
          modalForm={this.state.modalForm}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterFinanceGroup,
  }),
  {
    getMasterFinanceGroupDetail,
    saveMasterFinanceGroupDetail,
    addAlert,
  }
)(FinanceGroupDetailContainer);
