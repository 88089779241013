import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_AUTOMATE_BALANCE_CRITERIA = 'EDIT_AUTOMATE_BALANCE_CRITERIA';
export const CLEAR_AUTOMATE_BALANCE_CRITERIA = 'CLEAR_AUTOMATE_BALANCE_CRITERIA';
export const UPDATE_SEARCH_RESULT_AUTOMATE_BALANCE = 'UPDATE_SEARCH_RESULT_AUTOMATE_BALANCE';

export const REQUEST_SEARCH_AUTOMATE_BALANCE = '@@request/SARCH_AUTOMATE_BALANCE';
export const RECEIVE_SEARCH_AUTOMATE_BALANCE = '@@receive/SEARCH_AUTOMATE_BALANCE';
export const FAIL_SEARCH_AUTOMATE_BALANCE = '@@fail/SEARCH_AUTOMATE_BALANCE';

export const REQUEST_SAVE_AUTOMATE_BALANCE = '@@request/SAVE_AUTOMATE_BALANCE';
export const RECEIVE_SAVE_AUTOMATE_BALANCE =
  '@@receive-The record was successfully saved/SAVE_AUTOMATE_BALANCE';
export const FAIL_SAVE_AUTOMATE_BALANCE = '@@fail/SAVE_AUTOMATE_BALANCE';

export const saveMaintainBalance = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/automate/saveMaintainBalance`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_AUTOMATE_BALANCE,
      RECEIVE_SAVE_AUTOMATE_BALANCE,
      FAIL_SAVE_AUTOMATE_BALANCE,
    ],
  },
});

export const searchMaintainBalance = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/automate/getMaintainBalance', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_AUTOMATE_BALANCE,
      RECEIVE_SEARCH_AUTOMATE_BALANCE,
      FAIL_SEARCH_AUTOMATE_BALANCE,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_AUTOMATE_BALANCE,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_AUTOMATE_BALANCE_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_AUTOMATE_BALANCE_CRITERIA,
  payload: {
    name,
    value,
  },
});
