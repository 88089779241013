import qs from 'querystring';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import MaintainInterestRate from './MaintainInterestRate';
import { addAlert } from '../../../reducers/layout/action';
import {
  editCriteria,
  saveMaintainInterestRate,
  searchMaintainInterestRate,
} from '../../../reducers/maintainInterestRate/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-maintain-interest-rate';

class MaintainInterestRateContainer extends Component {
  state = {
    interestRateId: '',
    modal: '',
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    let query = this.props.routeProp.location.search.replace('?', '');
    query = qs.parse(query);
    if (query.s !== undefined && query.s === 'true') {
      this.props.searchMaintainInterestRate({ ...this.props.criteria });
    }
  }

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMaintainInterestRate({ ...this.props.criteria });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      interestRate: rowData,
      modal,
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const { interestRate } = this.state;
    interestRate.isActive = modal.toLowerCase() === 'active';
    this.props.saveMaintainInterestRate({ ...interestRate }).then((response) => {
      if (response.error) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The transaction was successfully saved',
      });
      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMaintainInterestRate({ ...this.props.criteria });
    });
    // .then(response => {
    //     if (!response.error) {
    //         this.setState({
    //             results: []
    //         })
    //         this.props.addAlert({
    //             title: 'Success',
    //             type: 'success',
    //             body: 'The transaction was successfully saved',
    //             buttons: [
    //                 <button className="btn btn-success" data-dismiss="modal">Ok</button>
    //             ]
    //         })
    //     }
    // })
    // this.modalActiveRef.close()
    // this.modalInactiveRef.close()
    // this.props.searchMaintainInterestRate({ ...this.props.criteria })
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onClickOpenModalChangeStatus: this.onClickOpenModalChangeStatus,
      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <MaintainInterestRate
        {...props}
        {...this.state}
        criteria={this.props.criteria}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInactiveRef={(e) => (this.modalInactiveRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.maintainInterestRate,
  }),
  {
    searchMaintainInterestRate,
    saveMaintainInterestRate,
    editCriteria,
    addAlert,
  }
)(MaintainInterestRateContainer);
