import {
  EDIT_MARK_TO_MARKET_CRITERIA,
  CLEAR_MARK_TO_MARKET_CRITERIA,
  RECEIVE_SEARCH_MARK_TO_MARKET_BOND,
  UPDATE_SEARCH_RESULT_MARK_TO_MARKET,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  BondId: '',
  MtmDateFromStr: '',
  MtmDateToStr: '',
  Currency: [''],
  Status: '',
  PostTransactionState: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
};

export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_RESULT_MARK_TO_MARKET:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_MARK_TO_MARKET_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MARK_TO_MARKET_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_MARK_TO_MARKET_BOND:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
