import React, {
  useRef,
  useState,
  useEffect,
  useReducer,
  forwardRef,
  useContext,
  useCallback,
  createContext,
  useImperativeHandle,
} from 'react';

import { store } from '../../Root';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import { toLower } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import {
  saveAttachFiles,
  getAtatchfileList,
  downloadAttachFile,
} from '../../reducers/ttRemittance/action';

const LIMIT_UPLOAD_SIZE = 1024 * 1024 * 10;

const AttachFileDispatch = createContext(null);

export default forwardRef(AttachFileList);
function AttachFileList(
  { transactionNo, btnPermission = {}, statusTt, isCheckAllpay, permission, callBackSave },
  ref
) {
  const [state, dispatch] = useReducer(reducer, []);
  const [toggleFetch, setToggleFetch] = useState([]);
  const modalRef = useRef(null);
  useImperativeHandle(ref, () => ({
    open: () => {
      setToggleFetch([]);
      modalRef.current.open();
    },
    save: (transactionNo, cb) => save(true, transactionNo, cb),
  }));
  const onChangeInputFile = useCallback(inputFileChange, []);
  useEffect(fetchData, [transactionNo, toggleFetch]);
  const onClickSaveAttachFile = useCallback(() => {
    save();
  }, [state, transactionNo, callBackSave]);

  const checkStatus =
    isCheckAllpay &&
    toLower(statusTt) !== 'wait for confirm' &&
    toLower(statusTt) !== 'rejected' &&
    toLower(statusTt) !== 'rejected by bank';

  // console.log(btnPermission)
  return (
    <Modal ref={modalRef} textHeader="Attach File" bgHeader="bg-info" size="modal-xl">
      {permission ? (
        <>
          <div className="row">
            <div className="col-sm-12">
              <input type="file" onChange={onChangeInputFile} className="form-control" multiple />
            </div>
          </div>
          <br />
        </>
      ) : null}
      <div className="table-responsive">
        <table className="table table-bordered table-nowarp">
          <thead>
            <tr>
              <th>Remove</th>
              <th>Replace</th>
              <th>Download</th>
              <th>File Name</th>
              {checkStatus ? (
                <>
                  <th>Send to Allpay</th>
                  <th>Status Allpay</th>
                </>
              ) : null}
              <th>Remark</th>
            </tr>
          </thead>

          <tbody>
            <AttachFileDispatch.Provider value={dispatch}>
              {state.map((m, i) =>
                m.isDeleted ? null : (
                  <AttachFile
                    key={i}
                    attachFile={m}
                    index={i}
                    btnPermission={btnPermission}
                    statusTt={statusTt}
                    isCheckAllpay={isCheckAllpay}
                    permission={permission}
                  />
                )
              )}
            </AttachFileDispatch.Provider>
          </tbody>
        </table>
      </div>
      {permission ? (
        <div className="row">
          <div className="col-sm-12 text-center">
            <Button
              onClick={onClickSaveAttachFile}
              txt="Save"
              icon="save"
              className="btn-success"
            />
          </div>
        </div>
      ) : null}
    </Modal>
  );

  function save(doNotAlert, tranNo, cb) {
    if (!transactionNo && !tranNo) {
      modalRef.current.close();
      return;
    }

    const submitData = state.filter((f) => {
      if (!f.id && f.isDeleted) return false;
      return f.isReplace || f.isDeleted || f.isEditRemark || !f.id || f.isCheckAllpay || f.isAllpay;
    });

    if (!submitData.length) {
      if (!doNotAlert)
        store.dispatch(
          addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'There is no data for update.',
          })
        );
      return;
    }

    const form = new FormData();
    async function doSave() {
      form.append(
        'data',
        JSON.stringify(
          submitData.map((m, idx) => {
            if (m.File) form.append(`file_${idx}`, m.File);
            const newData = { ...m };
            delete newData.File;
            return newData;
          })
        )
      );

      const response = await store.dispatch(saveAttachFiles(form, transactionNo || tranNo));
      if (response.error) return;
      if (cb) cb();
      else if (callBackSave) callBackSave();

      modalRef.current.close();
      // update currentData
      fetchData();
    }
    doSave();
  }

  function fetchData() {
    let ignore = false;
    async function fetch() {
      const response = await store.dispatch(getAtatchfileList(transactionNo));
      if (!ignore && !response.error) {
        dispatch({ type: 'UPDATE', payload: response.payload });
      }
    }

    if (transactionNo) fetch();
    else dispatch({ type: 'UPDATE', payload: [] });

    return () => {
      ignore = true;
    };
  }

  function inputFileChange(e) {
    const { files } = e.target;
    if (!files.length) return;
    // check size
    if (isErrorFileUpload(files)) return;

    const selectedFile = [];
    for (let i = 0; i < files.length; i++) {
      selectedFile.push(files[i]);
    }
    dispatch({ type: 'ADDS', payload: selectedFile });
    e.target.value = '';
  }
}

function isErrorFileUpload(files) {
  let isError = false;
  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    if (file.size > LIMIT_UPLOAD_SIZE) {
      isError = true;
      store.dispatch(
        addAlert({
          title: 'Warning',
          type: 'warning',
          body: `Limit Upload File is ${LIMIT_UPLOAD_SIZE / (1024 * 1024)}MB`,
        })
      );
      i = files.length;
    }
  }
  return isError;
}

const reducer = (state = [], action) => {
  switch (action.type) {
    case 'UPDATE':
      return action.payload;
    case 'ADDS':
      return [
        ...state,
        ...action.payload.map((m) => ({
          File: m,
          remark: '',
          isAllpay: false,
          type: '',
        })),
      ];
    case 'REPLACE':
      return state.map((m, i) => {
        if (i === action.index)
          return {
            ...m,
            isReplace: true,
            File: action.payload,
          };
        return m;
      });
    case 'REMOVE':
      return state.map((m, idx) => {
        if (idx === action.payload)
          return {
            ...m,
            isDeleted: true,
          };
        return m;
      });
    case 'EDIT_REMARK':
      return state.map((m, idx) => {
        if (idx === action.index)
          return {
            ...m,
            isEditRemark: true,
            remark: action.payload,
          };
        return m;
      });
    case 'CHECK_SEND_ALLPAY':
      return state.map((m, i) => {
        if (i === action.payload)
          return {
            ...m,
            isCheckAllpay: true,
            isAllpay: !m.isAllpay,
          };
        return m;
      });
    default:
      return state;
  }
};

function AttachFile({ attachFile, index, btnPermission, statusTt, isCheckAllpay, permission }) {
  const inputRef = useRef(null);
  const dispatch = useContext(AttachFileDispatch);
  const onChangeInputFile = useCallback(inputFileChange, []);
  const { canUpload, canReplace } = btnPermission;
  const onClickRemove = useCallback(
    (idx) => {
      dispatch({ type: 'REMOVE', payload: index });
    },
    [attachFile]
  );

  const onChangeRemark = useCallback((e) => {
    dispatch({ type: 'EDIT_REMARK', payload: e.target.value, index });
  });

  const onClickDownload = useCallback(
    (idx) => {
      store.dispatch(downloadAttachFile(attachFile.id));
    },
    [attachFile]
  );

  const onChangeSendAllpay = useCallback((e) => {
    dispatch({ type: 'CHECK_SEND_ALLPAY', payload: index });
  });

  const checkStatus =
    isCheckAllpay &&
    toLower(statusTt) !== 'wait for confirm' &&
    toLower(statusTt) !== 'rejected' &&
    toLower(statusTt) !== 'rejected by bank';

  return (
    <tr>
      <td align="center">
        {permission && canUpload && toLower(attachFile.type) !== 'sftp' ? (
          <button className="btn btn-warning btn-sm" onClick={onClickRemove}>
            <i className="icon icon-close icon-lg icon-fw" />
          </button>
        ) : null}
      </td>
      <td align="center">
        {permission &&
        canReplace &&
        toLower(attachFile.type) !== 'sftp' &&
        toLower(attachFile.statusAllpay) !== 'completed' ? (
          <>
            <button onClick={(e) => inputRef.current.click()} className="btn btn-success btn-sm">
              <i className="icon icon-reply icon-lg icon-fw" />
            </button>
            <input
              type="file"
              ref={inputRef}
              className="form-control"
              onChange={onChangeInputFile}
              style={{ display: 'none' }}
            />
          </>
        ) : null}
      </td>
      <td align="center">
        <button onClick={onClickDownload} className="btn btn-info btn-sm">
          <i className="icon icon-download icon-lg icon-fw" />
        </button>
      </td>
      <td align="center">{(attachFile.File || {}).name || attachFile.fileName || ''}</td>
      {checkStatus ? (
        <>
          <td align="center">
            {permission && toLower(attachFile.statusAllpay) !== 'completed' ? (
              <>
                <input
                  type="checkbox"
                  className="label-checkbox"
                  onChange={(e) => onChangeSendAllpay(e, index)}
                  id={`isAllpay-${index}`}
                  checked={attachFile.isAllpay}
                  name="isAllpay"
                />
                <label className="label_checkbox" htmlFor={`isAllpay-${index}`} />
              </>
            ) : (
              ''
            )}
          </td>
          <td align="center">
            {toLower(attachFile.statusAllpay || '') === 'completed' ? (
              <span className="badge badge-success">{attachFile.statusAllpay || ''}</span>
            ) : (
              <span className="badge badge-danger">{attachFile.statusAllpay || ''}</span>
            )}
          </td>
        </>
      ) : null}
      <td>
        {permission ? (
          <input
            onChange={onChangeRemark}
            type="text"
            className="form-control"
            value={attachFile.remark}
          />
        ) : (
          attachFile.remark
        )}
      </td>
    </tr>
  );

  function inputFileChange(e) {
    const { files } = e.target;
    if (!files.length) return;
    if (isErrorFileUpload(files)) return;

    dispatch({ type: 'REPLACE', payload: files[0], index });
  }
}
