import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import { toFixed } from '../../../common/helpper';
import DatePicker from '../../../common/DatePicker';
import FormGroup2 from '../../../common/FormGroup2';
import RadioButton from '../../../common/RadioButton';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import {
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  criteria,
  datas,
  borrowerList,
  totalAll,
  scc,
  showBuLabel,

  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  showProductType,
  onClickExportReport,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Report <span className="icon icon-angle-double-right" /> Loan Intercom{' '}
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Type" required>
              <RadioButton
                text="Business Unit"
                checked={criteria.Type === 'bu'}
                value="bu"
                onChange={onChangeSearch}
                required
                name="Type"
              />
              <RadioButton
                text="Company"
                checked={criteria.Type === 'comp'}
                value="comp"
                onChange={onChangeSearch}
                name="Type"
              />
            </FormGroup2>

            {criteria.Type === 'bu' ? (
              <FormGroup2 text="Business Unit" required key="bu">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  saveLocalState
                  noValidateOption
                  required
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            ) : (
              <FormGroup2 text="Company" required key="comp">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  required
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                  name="Companies"
                />
              </FormGroup2>
            )}
          </Col2>

          {criteria.Type === 'bu' ? (
            <Col2 col={colX[1]}>
              <FormGroup2 text="Term">
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.Term}
                  name="Term"
                  options={['Term', 'On Call']}
                />
              </FormGroup2>
            </Col2>
          ) : null}
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Currency" required>
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={(e) =>
                  onChangeSearch({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                saveLocalState
                noValidateOption
                required
                value={criteria.Currency}
                name="Currency"
              />
            </FormGroup2>

            <FormGroup2 text="Product Type">
              <MasterDropdownUI
                onChange={onChangeSelect2}
                value={criteria.ProductType}
                name="ProductType"
                options={['PN', 'Contract on Call', 'Contract Loan']}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Value Date" required>
              <DatePicker
                onChange={onChangeSearch}
                required
                value={criteria.ValueDateStr}
                name="ValueDateStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
            &nbsp;
            <Button
              txt="Export"
              icon="download"
              className="btn-warning"
              onClick={onClickExportReport}
            />
          </div>
        </Col2>
      </form>
    </Card>

    {showProductType === 'bu' ? (
      <ReportLoanBu
        data={datas}
        buCard={showBuLabel}
        borrowerList={borrowerList}
        totalAll={totalAll}
        scc={scc}
      />
    ) : showProductType === 'comp' ? (
      datas.map((r, i) => <ReportLoanCompany key={i} data={r} idx={i} />)
    ) : null}
  </div>
);

function ReportLoanBu({ data, borrowerList, totalAll, scc, buCard }) {
  return (
    <Card
      textHeader={buCard}
      number={data.companyCode}
      bgHeader="bg-primary"
      cardActions={['toggler']}
    >
      <div className="table-responsive" style={{ maxHeight: '500px' }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '5%' }}>
                Lender
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '5%' }}>
                Product
                <br />
                Type
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '5%' }}>
                Sweep
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '5%' }}>
                Type
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '5%' }}>
                Int. Rate
              </th>
              <th colSpan={borrowerList.length + 1} style={{ textAlign: 'center', width: '75%' }}>
                Borrower
              </th>
            </tr>
            <tr>
              {borrowerList.length
                ? borrowerList.map((m, i) => (
                    <th style={{ textAlign: 'center' }} key={i}>
                      {m}
                    </th>
                  ))
                : null}
              <th style={{ textAlign: 'center' }}>Grand Total</th>
            </tr>
          </thead>
          <tbody>
            {!data.length ? (
              <tr>
                <td className="text-center" colSpan={borrowerList.length + 1}>
                  {' '}
                </td>
              </tr>
            ) : null}
            {data.map((m, i) => (
              <tr key={i}>
                <td>{m.lenderAbbreviate}</td>
                <td>{m.source}</td>
                <td className="text-center">{m.isSweep ? 'Yes' : 'No'}</td>
                <td className="text-center">{m.loanType}</td>
                <td className="text-right">{setNumber(m.interestRate, 5)}%</td>
                {m.dataBorrower.length
                  ? m.dataBorrower.map((m, i) => (
                      <td className="text-right" key={i}>
                        {m ? setNumber(m, 2) : ''}
                      </td>
                    ))
                  : null}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="5" className="text-center td-grey">
                Total of PN Amount (MB)
              </td>
              {totalAll.length
                ? totalAll.map((m, i) => (
                    <td className="text-right td-grey" key={i}>
                      {m ? setNumber(m, 2) : ''}
                    </td>
                  ))
                : null}
            </tr>
          </tfoot>
        </table>
      </div>
      <hr />
      <Col2 col="col-md-6">
        <strong>SCC Status</strong>
      </Col2>
      <Col2 col="col-md-8">
        <Col2 col="col-sm-6">
          <div className="table-responsive">
            <table className="table table-bordered table-nowrap">
              <tbody>
                <tr>
                  <td>Lender</td>
                  <td className="text-right">{setNumber(scc.lender, 2)}</td>
                </tr>
                <tr>
                  <td>(Borrower)</td>
                  <td className="text-right">{setNumber(scc.borrower, 2)}</td>
                </tr>
                <tr>
                  <td>Net (Borrower)/Lender</td>
                  <td className="text-right">{setNumber(scc.total, 2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col2>
      </Col2>
    </Card>
  );
}

function ReportLoanCompany({ data, idx }) {
  const textHeader = data.companyCard;
  return (
    <Card
      textHeader={textHeader}
      number={data.companyCode}
      bgHeader="bg-primary"
      cardActions={['toggler']}
    >
      <div className="table-responsive" style={{ maxHeight: '500px' }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th colSpan="2" style={{ textAlign: 'center', width: '40%' }}>
                1. As Borrower
              </th>
              <th colSpan="2" style={{ textAlign: 'center', width: '30%' }}>
                CALL
              </th>
              <th colSpan="2" style={{ textAlign: 'center', width: '30%' }}>
                TERM
              </th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', width: '25%' }}>Lenders' Name</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Product Type</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Amt. (M)</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Int. Rate (%)</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Amt. (M)</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Int. Rate (%)</th>
            </tr>
          </thead>
          <tbody>
            {!data.dataBorrowerSweep.length && !data.dataBorrowerNoSweep.length ? (
              <tr>
                <td className="text-center" colSpan="6">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {!data.dataBorrowerSweep.length ? null : (
              <tr>
                <td className="" colSpan="6">
                  <strong>A. Sweeping Group</strong>
                </td>
              </tr>
            )}
            {data.dataBorrowerSweep.map((m, i) => (
              <tr key={i}>
                <td>
                  {m.lenderCode} | {m.lenderAbbreviate}
                </td>
                <td>{m.source}</td>
                <td className="text-right">{setNumber(m.amountOnCall, m.digit)}</td>
                <td className="text-right">{setNumber(m.interestRateOnCall, 5)}%</td>
                <td className="text-right">{setNumber(m.amountTerm, m.digit)}</td>
                <td className="text-right">{setNumber(m.interestRateTerm, 5)}%</td>
              </tr>
            ))}
            {!data.dataBorrowerNoSweep.length ? null : (
              <tr>
                <td className="" colSpan="6">
                  <strong>B. Non-Sweeping Group</strong>
                </td>
              </tr>
            )}
            {data.dataBorrowerNoSweep.map((m, i) => (
              <tr key={i}>
                <td>
                  {m.lenderCode} | {m.lenderAbbreviate}
                </td>
                <td>{m.source}</td>
                <td className="text-right">{setNumber(m.amountOnCall, m.digit)}</td>
                <td className="text-right">{setNumber(m.interestRateOnCall, 5)}%</td>
                <td className="text-right">{setNumber(m.amountTerm, m.digit)}</td>
                <td className="text-right">{setNumber(m.interestRateTerm, 5)}%</td>
              </tr>
            ))}
            <tr>
              <td colSpan="2" className="text-center td-grey">
                Total Borrowing
              </td>
              <td className="text-right td-grey">
                {setNumber(data.totalBorrower.amountOnCall, 2)}
              </td>
              <td className="text-center td-grey">-</td>
              <td className="text-right td-grey">{setNumber(data.totalBorrower.amountTerm, 2)}</td>
              <td className="text-center td-grey">-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <div className="table-responsive" style={{ maxHeight: '500px' }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th colSpan="2" style={{ textAlign: 'center', width: '40%' }}>
                2. As Lender
              </th>
              <th colSpan="2" style={{ textAlign: 'center', width: '30%' }}>
                CALL
              </th>
              <th colSpan="2" style={{ textAlign: 'center', width: '30%' }}>
                TERM
              </th>
            </tr>
            <tr>
              <th style={{ textAlign: 'center', width: '25%' }}>Borrowers' Name</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Product Type</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Amt. (M)</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Int. Rate (%)</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Amt. (M)</th>
              <th style={{ textAlign: 'center', width: '15%' }}>Int. Rate (%)</th>
            </tr>
          </thead>
          <tbody>
            {!data.dataLenderSweep.length && !data.dataLenderNoSweep.length ? (
              <tr>
                <td className="text-center" colSpan="6">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {!data.dataLenderSweep.length ? null : (
              <tr>
                <td className="" colSpan="6">
                  <strong>A. Sweeping Group</strong>
                </td>
              </tr>
            )}
            {data.dataLenderSweep.map((m, i) => (
              <tr key={i}>
                <td>
                  {m.borrowerCode} | {m.borrowerAbbreviate}
                </td>
                <td>{m.source}</td>
                <td className="text-right">{setNumber(m.amountOnCall, m.digit)}</td>
                <td className="text-right">{setNumber(m.interestRateOnCall, 5)}%</td>
                <td className="text-right">{setNumber(m.amountTerm, m.digit)}</td>
                <td className="text-right">{setNumber(m.interestRateTerm, 5)}%</td>
              </tr>
            ))}
            {!data.dataLenderNoSweep.length ? null : (
              <tr>
                <td className="" colSpan="6">
                  <strong>B. Non-Sweeping Group</strong>
                </td>
              </tr>
            )}
            {data.dataLenderNoSweep.map((m, i) => (
              <tr key={i}>
                <td>
                  {m.borrowerCode} | {m.borrowerAbbreviate}
                </td>
                <td>{m.source}</td>
                <td className="text-right">{setNumber(m.amountOnCall, 2)}</td>
                <td className="text-right">{setNumber(m.interestRateOnCall, 5)}%</td>
                <td className="text-right">{setNumber(m.amountTerm, 2)}</td>
                <td className="text-right">{setNumber(m.interestRateTerm, 5)}%</td>
              </tr>
            ))}
            <tr>
              <td colSpan="2" className="text-center td-grey">
                Total Lender
              </td>
              <td className="text-right td-grey">{setNumber(data.totalLender.amountOnCall, 2)}</td>
              <td className="text-center td-grey">-</td>
              <td className="text-right td-grey">{setNumber(data.totalLender.amountTerm, 2)}</td>
              <td className="text-center td-grey">-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr />
      <Col2 col="col-md-6">
        <strong>{textHeader} Status</strong>
      </Col2>
      <Col2 col="col-md-8">
        <Col2 col="col-sm-6">
          <div className="table-responsive">
            <table className="table table-bordered table-nowrap">
              <tbody>
                <tr>
                  <td>Lender</td>
                  <td className="text-right">{setNumber(data.totalAll.amountLender, 2)}</td>
                </tr>
                <tr>
                  <td>(Borrower)</td>
                  <td className="text-right">{setNumber(data.totalAll.amountBorrower, 2)}</td>
                </tr>
                <tr>
                  <td>Net (Borrower)/Lender</td>
                  <td className="text-right">{setNumber(data.totalAll.amountNet, 2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Col2>
      </Col2>
    </Card>
  );
}

export function setNumber(number, float) {
  // if (!number) return ''
  if (number >= 0 || !number) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
}
