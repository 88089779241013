import Decimal from 'decimal.js';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import CreatePnLoan from './CreatePnLoan';
import { calNewPnAmount } from '../ModalRolloverRepay';
import { addAlert } from '../../../reducers/layout/action';
import {
  toLower,
  isLeapYear,
  toUpperKey,
  compareDate,
  getPermission,
  toFixedNumber,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../../common/helpper';
import {
  searchLoan,
  editCriteria,
  changeStatus,
  clearCriteria,
  saveRollOverRepay,
  updateSearchResult,
  getInterestRepayment,
  downloadPromissoryNote,
  getRepayRolloverHistory,
} from '../../../reducers/pnLoan/action';

const $ = window.jQuery;
const { moment } = window;
export const DATATABLE_ID = 'table-result-pn-loan';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const permission = getPermission('Funding&Investment', 'Create Promissory Note');
window.Decimal = Decimal;
const initChangeStatus = {
  transactionNo: '',
  modal: '',
};

const initRolloverRepay = {
  transactionNos: [],
  TransactionType: '',
  CompanyCode: '',
  BusinessPartner: '',
  BankCode: '',
  BankCodePartner: '',
  PartnerType: '',
  LoanType: '',
  Amount: '',
  PaymentMethodPrincipal: '',
  PaymentMethodInterest: '',
  BankAccountNoInterest: '',
  BankAccountNoPrincipal: '',
  BankAccountNoPartnerInterest: '',
  BankAccountNoPartnerPrincipal: '',
  BankAccountCurrencyInterest: '',
  BankAccountCurrencyPrincipal: '',
  BankAccountCurrencyPartnerInterest: '',
  BankAccountCurrencyPartnerPrincipal: '',
  DisabledPaymentMethodPrincipal: false,
  DisabledPaymentMethodInterest: false,
  TTAmount: '',
  Interest: '',
  WithholdingTax: '',
  IsThaiSweep: null,
  IsIncluding: false,
  IsRepayAll: false,
  IncreaseDecrease: '',
  IncreaseDecreaseAmount: '',
  RolloverDateStr: '',
  TermStartStr: '',
  TermEndStr: '',
  RepaymentDateStr: '',

  OldTermStartStr: '',
  OldTermEndStr: '',
  Source: 'PN',
  StampDutyFee: '',
  BankCharge: '',
  inputMaskAmount: {
    prefix: '',
    digits: 0,
    digitsOptional: false,
    placeholder: '0',
  },
  repayRolloverHistory: {},
};
const paymentMethodEnableTT = ['Bahtnet', 'T/T'];
class CreatePnLoanContainer extends Component {
  state = {
    ...initRolloverRepay,
    modalChangeStatus: {
      ...initChangeStatus,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.rollover-repay`, this.onClickOpenModalRolloverRepayColumn);
    $('body').on(
      'click',
      `button.rollover-repay-history`,
      this.onClickOpenModalRepayRolloverHistory
    );
    $('body').on('click', `button.print-pdf`, this.onClickPrintPdf);

    // let query = this.props.routeProp.location.search.replace('?','')
    // query = qs.parse(query)
    // if(query.s !== undefined && query.s === 'true') {
    //     this.props.searchLoan({ ...this.props.criteria })
    // }
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.rollover-repay`, this.onClickOpenModalRolloverRepayColumn);
    $('body').off(
      'click',
      `button.rollover-repay-history`,
      this.onClickOpenModalRepayRolloverHistory
    );
    $('body').off('click', `button.print-pdf`, this.onClickPrintPdf);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.BankAccountCurrencyInterest !== this.state.BankAccountCurrencyInterest ||
      prevState.BankAccountCurrencyPartnerInterest !==
        this.state.BankAccountCurrencyPartnerInterest ||
      prevState.BankAccountCurrencyPrincipal !== this.state.BankAccountCurrencyPrincipal ||
      prevState.BankAccountCurrencyPartnerPrincipal !==
        this.state.BankAccountCurrencyPartnerPrincipal ||
      prevState.Currency !== this.state.Currency ||
      prevState.transactionNos !== this.state.transactionNos ||
      prevState.IsThaiSweep !== this.state.IsThaiSweep
    ) {
      // compute disabled payment method

      let DisabledPaymentMethodPrincipal = true;
      let DisabledPaymentMethodInterest = true;
      let { PaymentMethodPrincipal } = this.state;
      let { PaymentMethodInterest } = this.state;

      // only one transaction
      if (this.state.transactionNos.length === 1) {
        if (this.state.Currency === this.state.BankAccountCurrencyPrincipal) {
          if (
            this.state.PartnerType === 'Intercompany' &&
            this.state.Currency === this.state.BankAccountCurrencyPartnerPrincipal
          )
            DisabledPaymentMethodPrincipal = false;
          else if (this.state.PartnerType === 'External') DisabledPaymentMethodPrincipal = false;
        }

        if (this.state.Currency === this.state.BankAccountCurrencyInterest) {
          if (
            this.state.PartnerType === 'Intercompany' &&
            this.state.Currency === this.state.BankAccountCurrencyPartnerInterest
          )
            DisabledPaymentMethodInterest = false;
          else if (this.state.PartnerType === 'External') DisabledPaymentMethodInterest = false;
        }

        if (DisabledPaymentMethodPrincipal) PaymentMethodPrincipal = 'T/T';

        if (DisabledPaymentMethodInterest) PaymentMethodInterest = 'T/T';
      } else {
        DisabledPaymentMethodPrincipal = false;
        DisabledPaymentMethodInterest = false;
      }

      if (this.state.IsThaiSweep) {
        // DisabledPaymentMethodPrincipal = true
        DisabledPaymentMethodInterest = true;
        // PaymentMethodPrincipal = 'Auto Deduct'
        PaymentMethodInterest = 'Auto Deduct';
      }

      this.setState({
        DisabledPaymentMethodPrincipal,
        DisabledPaymentMethodInterest,
        PaymentMethodPrincipal,
        PaymentMethodInterest,
      });
    }
  }

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    if (result) {
      this.props.searchResult[result.row].IsSelected = result.checked;
      this.forceUpdate();
    }
  };

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchLoan({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalChangeStatus: {
        ...initChangeStatus,
        transactionNo: rowData.transactionNo,
        modal,
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const submitData = {
      TransactionNo: this.state.modalChangeStatus.transactionNo,
    };
    this.props.changeStatus(submitData).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchLoan({ ...this.props.criteria });
    });
  };

  onClickOpenModalRolloverRepay = (e) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select item for Rollover / Repay !',
        type: 'error',
      });
      return;
    }

    if (selected.length === 1) {
      this.props.addAlert({
        title: 'Error',
        body: 'For once transaction, Please use button in column instead.',
        type: 'error',
      });
      return;
    }

    if (!permission) {
      this.props.addAlert({
        title: 'Error',
        body: 'Can not Rollover/Repay because you can not authorize this transaction.',
        type: 'error',
      });
      return;
    }

    const checkStatusRollover = selected.filter((f) => toLower(f.status) !== 'active');
    if (checkStatusRollover.length) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select item status is "Active"!',
        type: 'error',
      });
      return;
    }

    const group1 = [];
    const group2 = [];
    let checkStatus = true;
    let companyCode = '';
    let transactionType = '';
    let facilityNo = '';
    let currency = '';
    let currencyDigit = 0;
    let maxtermstart = '';
    let totalAmount = 0;
    const totalInterestAmount = 0;
    const totalWht = 0;
    let isThaiSweep = false;

    selected.forEach((m) => {
      if (m.hadRepay) {
        checkStatus = false;
      }

      if (companyCode !== '') {
        if (
          companyCode === m.companyCode &&
          transactionType === m.transactionType &&
          facilityNo === (m.facilityNo || '') &&
          currency === m.currency &&
          isThaiSweep === m.isThaiSweep
        )
          group1.push(m);
        else group2.push(m);
      } else {
        companyCode = m.companyCode;
        isThaiSweep = m.isThaiSweep;
        transactionType = m.transactionType;
        facilityNo = m.facilityNo || '';
        currency = m.currency;
        currencyDigit = m.currencyDigit;
        group1.push(m);
      }

      if (maxtermstart === '') {
        maxtermstart = m.termStartStr;
      } else if (compareDate(m.termStartStr, maxtermstart)) {
        maxtermstart = m.termStartStr;
      }
    });

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: "Don't select transaction status is Repayment.",
      });
      return;
    }

    if (group1.length && group2.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'You select transaction different Company, Transaction Type, ThaiSweep, Currency or Facility No.',
      });
      return;
    }

    selected.forEach((m) => {
      totalAmount += m.amount;
    });

    const selectedData = selected[0];
    this.setState(
      {
        ...initRolloverRepay,
        Amount: toFixedNumber(totalAmount, currencyDigit),
        WithholdingTax: 0,
        Interest: 0,
        transactionNos: selected.map((m) => m.transactionNo),
        OldTermStartStr: maxtermstart,
        inputMaskAmount: {
          prefix: '',
          digits: currencyDigit,
          digitsOptional: false,
          placeholder: !currencyDigit ? '0' : `0.${`000${currencyDigit}`.slice(-3)}`,
        },
        CompanyCode: selectedData.companyCode,
        BusinessPartner: selectedData.businessPartner,
        BankCode:
          selectedData.transactionType === 'Loan'
            ? selectedData.bankCodeLoan
            : selectedData.bankCodeDeposit,
        BankCodePartner:
          selectedData.transactionType === 'Deposit'
            ? selectedData.bankCodeLoan
            : selectedData.bankCodeDeposit,
        PartnerType: selectedData.partnerType,
        LoanType: selectedData.loanType,
        TransactionType: selectedData.transactionType,

        Currency: selectedData.currency,
        IsThaiSweep: selected[0].isThaiSweep,
      },
      this.modalRolloverRepayRef.open
    );
  };

  calInterestAmount = (data, termEnd, isIncluding) => {
    let {
      interestRate,
      amount,
      termStartStr,
      endDateListArr,
      whtValue,
      calculateMethod,
      currencyDigit,
      periodEndType,
    } = data;
    amount = toFixedNumber(amount, currencyDigit);
    const mTermEnd = window.moment(termEnd, 'DD/MM/YYYY');
    if (endDateListArr && endDateListArr.length && periodEndType !== 'End of Term') {
      const filter = endDateListArr
        .map((m) => window.moment(m, 'DD/MM/YYYY'))
        .filter((f) => {
          const r = f.isBefore(mTermEnd, 'days');
          return r;
        })
        .sort((a, b) => (a.isBefore(b, 'days') ? 1 : a.isAfter(b, 'days') ? -1 : 0));
      if (filter.length) {
        termStartStr = filter[0].add('days', 1).format('DD/MM/YYYY');
      }
    }
    const daysDiff = mTermEnd.diff(moment(termStartStr, 'DD/MM/YYYY'), 'days');
    let totalDayinYear = 0;
    switch (calculateMethod) {
      case 'Act/360':
        totalDayinYear = 360;
        break;
      case 'Act/366':
        totalDayinYear = 366;
        break;
      case 'Act/365':
        totalDayinYear = 365;
        break;
      default:
        totalDayinYear = isLeapYear(moment(termStartStr, 'DD/MM/YYYY').year()) ? 366 : 365;
    }

    amount = new Decimal(amount);
    const interestAmount = new Decimal(
      amount.times(interestRate).dividedBy(100).dividedBy(totalDayinYear).times(daysDiff).toFixed(2)
    );
    let wht = new Decimal(0);
    if (whtValue !== null && whtValue !== undefined) wht = interestAmount.times(whtValue);
    return {
      wht: Number(wht.toFixed(2)),
      interestAmount: Number(interestAmount.toFixed(2)),
    };
  };

  onClickOpenModalRolloverRepayColumn = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];

    this.props
      .getInterestRepayment({
        TransactionNos: [selectedData.transactionNo],
        RepaymentDateStr: initRolloverRepay.RepaymentDateStr,
      })
      .then((response) => {
        if (response.error) return;
        const digit = selectedData.currencyDigit;
        const { payload } = response;

        this.setState(
          {
            ...initRolloverRepay,
            Amount: toFixedNumber(payload.totalAmount, digit),
            WithholdingTax: 0,
            Interest: 0,
            transactionNos: [selectedData.transactionNo],
            OldTermStartStr: selectedData.termStartStr,
            inputMaskAmount: {
              prefix: '',
              digits: selectedData.currencyDigit,
              digitsOptional: false,
              placeholder: !selectedData.currencyDigit
                ? '0'
                : `0.${`000${selectedData.currencyDigit}`.slice(-3)}`,
            },
            CompanyCode: selectedData.companyCode,
            BusinessPartner: selectedData.businessPartner,
            BankCode:
              selectedData.transactionType === 'Loan'
                ? selectedData.bankCodeLoan
                : selectedData.bankCodeDeposit,
            BankCodePartner:
              selectedData.transactionType === 'Deposit'
                ? selectedData.bankCodeLoan
                : selectedData.bankCodeDeposit,
            PartnerType: selectedData.partnerType,
            LoanType: selectedData.loanType,
            TransactionType: selectedData.transactionType,

            BankAccountNoInterest: payload.bankAccountNoInterest,
            BankAccountNoPartnerInterest: payload.bankAccountNoPartnerInterest,

            BankAccountNoPrincipal: payload.bankAccountNoPrincipal,
            BankAccountNoPartnerPrincipal: payload.bankAccountNoPartnerPrincipal,

            BankAccountCurrencyInterest: payload.bankAccountCurrencyInterest,
            BankAccountCurrencyPartnerInterest: payload.bankAccountCurrencyPartnerInterest,
            PaymentMethodInterest: payload.paymentMethodInterest,

            BankAccountCurrencyPrincipal: payload.bankAccountCurrencyPrincipal,
            BankAccountCurrencyPartnerPrincipal: payload.bankAccountCurrencyPartnerPrincipal,
            PaymentMethodPrincipal: payload.paymentMethodPrincipal,

            Currency: selectedData.currency,
            IsThaiSweep: selectedData.isThaiSweep,

            TTAmount: '',
          },
          this.modalRolloverRepayRef.open
        );
      });
  };

  onChangeModalRolloverRepay = (e) => {
    const { name, value, checked, type, datas } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: type === 'checkbox' ? checked : value,
      };
      let error = '';
      switch (name) {
        case 'BankAccountNoInterest':
        case 'BankAccountNoPrincipal':
        case 'BankAccountNoPartnerInterest':
        case 'BankAccountNoPartnerPrincipal':
          const selectedBankAccountCurrency = datas.find((f) => f.bankAccountNo === value).currency;
          stateToUpdate[name.replace('No', 'Currency')] = selectedBankAccountCurrency;
          break;
        case 'IsIncluding':
          if (state.transactionNos.length === 1)
            stateToUpdate.PaymentMethodInterest = 'Auto Deduct';
          break;
        case 'PaymentMethodPrincipal':
          if (!~paymentMethodEnableTT.indexOf(value || '')) stateToUpdate.TTAmount = '0';
          else if (state.IsRepayAll) stateToUpdate.TTAmount = state.Amount || '0';
          else stateToUpdate.TTAmount = state.IncreaseDecreaseAmount;
          break;
        case 'IncreaseDecreaseAmount':
          if (~paymentMethodEnableTT.indexOf(state.PaymentMethodPrincipal || ''))
            stateToUpdate.TTAmount = value;
          break;
        case 'TTAmount':
          if (
            !(state.IncreaseDecreaseAmount || 0) ||
            Number(state.IncreaseDecreaseAmount) < Number(value)
          )
            stateToUpdate.TTAmount = '0';
          break;
        case 'IsRepayAll':
          stateToUpdate.IsRepayAll = checked;
          stateToUpdate.LoanType = '';
          stateToUpdate.RolloverDateStr = '';
          stateToUpdate.TermEndStr = '';
          stateToUpdate.RepaymentDateStr = '';
          stateToUpdate.Interest = '';
          stateToUpdate.IsIncluding = false;
          stateToUpdate.IncreaseDecrease = '';
          stateToUpdate.IncreaseDecreaseAmount = '0';
          stateToUpdate.TTAmount = '0';
          stateToUpdate.StampDutyFee = '0';
          stateToUpdate.BankCharge = '0';
          stateToUpdate.TTAmount = '0';
          if (
            checked &&
            ~paymentMethodEnableTT.indexOf(state.PaymentMethodPrincipal || '') &&
            state.transactionNos.length === 1
          )
            stateToUpdate.TTAmount = state.Amount || '0';
          break;
        case 'LoanType':
          if (value === 'On Call') stateToUpdate.TermEndStr = '';
          break;
        case 'RepaymentDateStr':
          let mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.OldTermStartStr, mergeState.RepaymentDateStr)) {
            error = `Repayment Date must be more than or equal ${mergeState.OldTermStartStr}.`;
            stateToUpdate.RepaymentDateStr = state.RepaymentDateStr;
          }
          break;
        case 'RolloverDateStr':
          mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.OldTermStartStr, mergeState.RolloverDateStr)) {
            error = `Rollover Date must be more than or equal ${mergeState.OldTermStartStr}.`;
            stateToUpdate.RolloverDateStr = state.RolloverDateStr;
          } else if (compareDate(mergeState.RolloverDateStr, mergeState.TermEndStr)) {
            error = 'Rollover Date must be less than or equal Term End.';
            stateToUpdate.RolloverDateStr = state.RolloverDateStr;
          }
          break;
        case 'TermEndStr':
          mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.OldTermStartStr, mergeState.TermEndStr)) {
            error = `Term End must be more than or equal ${mergeState.OldTermStartStr}.`;
            stateToUpdate.TermEndStr = state.TermEndStr;
          } else if (compareDate(mergeState.RolloverDateStr, mergeState.TermEndStr)) {
            error = 'Term End must be more than or equal Rollover Date.';
            stateToUpdate.TermEndStr = state.TermEnd;
          }
          break;
        default:
          break;
      }

      if (
        (name === 'RolloverDateStr' || name === 'RepaymentDateStr' || name === 'IsIncluding') &&
        !error
      ) {
        let date = null;
        let isInclude = null;

        if (name === 'RolloverDateStr' || name === 'RepaymentDateStr') {
          date = value;
          isInclude = state.IsIncluding;
        } else {
          date = state.IsRepayAll ? state.RepaymentDateStr : state.RolloverDateStr;
          isInclude = checked;
        }

        if (date) {
          stateToUpdate.Interest = 0;
          const selected = this.props.searchResult.filter(
            (f) => state.transactionNos.indexOf(f.transactionNo) > -1
          );
          selected.forEach((m) => {
            const result = this.calInterestAmount(m, date, isInclude);
            stateToUpdate.Interest += result.interestAmount;
            stateToUpdate.WithholdingTax = Number(stateToUpdate.WithholdingTax || 0) + result.wht;
          });
        }
      }

      if (error)
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: error,
        });

      return stateToUpdate;
    });
  };

  onSubmitModalRolloverRepay = (e) => {
    e.preventDefault();
    this.state.TermStartStr = this.state.RolloverDateStr;

    if (
      compareDate(this.state.OldTermStartStr, this.state.RepaymentDateStr) &&
      this.state.IsRepayAll
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Repayment Date must be more than or equal Term Start.',
      });
      return;
    }

    if (
      compareDate(this.state.OldTermStartStr, this.state.RolloverDateStr) &&
      !this.state.IsRepayAll
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Rollover Date / Term Start must be more than or equal Term Start.',
      });
      return;
    }

    const { inputMaskAmount } = this.state;
    this.props
      .saveRollOverRepay({
        ...this.state,
        Digit: inputMaskAmount.digits,
        NewPnAmount: calNewPnAmount(this.state),
      })
      .then((response) => {
        if (response.error) return;
        clearCheckBoxAllTable(DATATABLE_ID);
        this.props.searchLoan({ ...this.props.criteria });
        this.modalRolloverRepayRef.close();
      });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // Print PDF
  onClickPrintPdf = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];
    this.props.downloadPromissoryNote(selectedData.transactionNo);
  };

  onClickPrintMulti = async () => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select item for print!',
        type: 'error',
      });
      return;
    }

    for (let i = 0; i < selected.length; i++) {
      const { transactionNo } = selected[i];
      if (!transactionNo) continue;
      await this.props.downloadPromissoryNote(transactionNo);
    }
    this.props.searchLoan({ ...this.props.criteria });
  };

  onClickOpenModalRepayRolloverHistory = async (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];
    const response = await this.props.getRepayRolloverHistory(selectedData.transactionNo);
    if (response.error) return;

    if (!response.payload) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Data not found.',
      });
      return;
    }

    this.setState(
      {
        repayRolloverHistory: {
          ...toUpperKey(response.payload),
          inputMaskAmount: {
            prefix: '',
            digits: response.payload.digit || 0,
            digitsOptional: false,
            placeholder: '0.00',
          },
        },
      },
      this.modalRolloverRepayReadOnlyRef.open
    );
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onClickOpenModalRolloverRepay: this.onClickOpenModalRolloverRepay,
      onChangeModalRolloverRepay: this.onChangeModalRolloverRepay,
      onSubmitModalRolloverRepay: this.onSubmitModalRolloverRepay,
      onClickPrintMulti: this.onClickPrintMulti,
    };

    return (
      <CreatePnLoan
        {...props}
        {...this.state}
        modalRolloverRepay={this.state}
        repayRolloverHistory={this.state.repayRolloverHistory}
        criteria={this.props.criteria}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInactiveRef={(e) => (this.modalInactiveRef = e)}
        modalRolloverRepayRef={(e) => (this.modalRolloverRepayRef = e)}
        modalRolloverRepayReadOnlyRef={(e) => (this.modalRolloverRepayReadOnlyRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.pnLoan,
  }),
  {
    searchLoan,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    getInterestRepayment,
    saveRollOverRepay,
    changeStatus,
    downloadPromissoryNote,
    getRepayRolloverHistory,
  }
)(CreatePnLoanContainer);
