import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';
import { downloadFile } from '../../common/helpper';

export const REQUEST_SEARCH_LOAN_REPORT_INTERCOM = '@@request/SEARCH_LOAN_REPORT_INTERCOM';
export const RECEIVE_SEARCH_LOAN_REPORT_INTERCOM = '@@receive/SEARCH_LOAN_REPORT_INTERCOM';
export const FAIL_SEARCH_LOAN_REPORT_INTERCOM = '@@fail/SEARCH_LOAN_REPORT_INTERCOM';

export const REQUEST_DOWNLOAD_EXPORT_REPORT_INTERCOM = '@@request/DOWNLOAD_EXPORT_REPORT_INTERCOM';
export const RECEIVE_DOWNLOAD_EXPORT_REPORT_INTERCOM = '@@receive/DOWNLOAD_EXPORT_REPORT_INTERCOM';
export const FAIL_DOWNLOAD_EXPORT_REPORT_INTERCOM = '@@fail/DOWNLOAD_EXPORT_REPORT_INTERCOM';

export const searchLoanIntercomReport = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/getLoanIntercomReport', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_LOAN_REPORT_INTERCOM,
      RECEIVE_SEARCH_LOAN_REPORT_INTERCOM,
      FAIL_SEARCH_LOAN_REPORT_INTERCOM,
    ],
  },
});

export const exportReport = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/ExportLoanIntercomReport', criteria),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_EXPORT_REPORT_INTERCOM,
      {
        type: RECEIVE_DOWNLOAD_EXPORT_REPORT_INTERCOM,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_EXPORT_REPORT_INTERCOM,
    ],
  },
});
