import { RECEIVE_UPLOAD_VIEW_REPORT_TO_BANK } from './action';

const init = {
  searchResult: [],
  importExcelReportToBank: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_UPLOAD_VIEW_REPORT_TO_BANK:
      return {
        ...state,
        importExcelReportToBank: action.payload,
      };
    default:
      return state;
  }
};
