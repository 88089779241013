import { connect } from 'react-redux';
import React, { Component } from 'react';

import BankTransferPostingJournal from './BankTransferPostingJournal';
import { searchBankTransferPostingJournal } from '../../reducers/journalBankTransferPosting/action';

export const DATATABLE_ID = 'table-result-bank-transfer-posting-s';

class BankTransferPostingJournalContainer extends Component {
  state = {
    criteria: {
      FinanceGroup: '',
      BusinessUnit: [''],
      Companys: [''],
      Currency: 'all',
      ApplicantBank: 'all',
      ApplicantAccountNo: 'all',
      BeneficiaryBank: 'all',
      BeneficiaryAccountNo: 'all',
      TransferMethod: '',
      SendingType: '',
      PostGLStatus: '',
      ChequeStatus: '',
      ValueDate: '',
      InstanceCode: '',
    },
    modalEdit: {
      PostingDateStr: '',
      TransactionIds: [],
    },
  };

  dataTableSearchResultRef = null;

  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchBankTransferPostingJournal({ ...this.state.criteria });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        FinanceGroup: '',
        BusinessUnit: [''],
        Companys: [''],
        Currency: 'all',
        ApplicantBank: 'all',
        ApplicantAccountNo: 'all',
        BeneficiaryBank: 'all',
        BeneficiaryAccountNo: 'all',
        TransferMethod: '',
        SendingType: '',
        PostGLStatus: '',
        ChequeStatus: '',
        ValueDate: '',
        InstanceCode: '',
      },
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,
    };

    return <BankTransferPostingJournal {...props} {...this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.journalDerivatives,
  }),
  {
    searchBankTransferPostingJournal,
  }
)(BankTransferPostingJournalContainer);
