import React, { Component } from 'react';

import Modal from './Modal';
import Button from './Button';

export default class ModalFunction extends Component {
  textColor = {
    success: 'text-success',
    warning: 'text-warning',
    info: 'text-info',
    danger: 'text-danger',
    primary: 'text-primary',
    brown: 'text-brown',
  };

  btnColor = {
    success: 'btn btn-success',
    warning: 'btn btn-warning',
    info: 'btn btn-info',
    danger: 'btn btn-danger',
    primary: 'btn btn-primary',
    brown: 'btn btn-lightbrown',
  };

  render() {
    const {
      icon = '',
      type = '',
      textHeader = '',
      textContent = '',
      selectedLength = 0,
      textBtn = 'Confirm',
      textBtnBack = 'Back',
      showFail = false,
      selectedResultCount = 0,
      noSelect = false,
      noShowCount = false,
    } = this.props;

    const failCount = selectedResultCount - selectedLength;
    // let size = !showFail || failCount === 0 ? "modal-sm" : "modal-md"
    const size = noSelect ? 'modal-sm' : 'modal-md';
    return (
      <div>
        <Modal size={size} ref={this.props.modalRef} modalFooter>
          <div className="text-center">
            <span className={`${this.textColor[type]} icon ${icon} icon-5x`} />
            <h3 className={this.textColor[type]}>{textHeader}</h3>
            {noShowCount ? (
              <p>Do you want to {textContent}?</p>
            ) : noSelect ? (
              <p>Do you want to {textContent} this item?</p>
            ) : !showFail || failCount === 0 ? (
              <p>
                {selectedLength} items selected, <br />
                click to {textContent}?
              </p>
            ) : (
              <div>
                <p>
                  {selectedResultCount} items selected, click to {textContent}?
                </p>
                <p>pass {selectedLength} items selected.</p>
                <p>fail {failCount} items selected.</p>
              </div>
            )}

            <div className="m-t-lg">
              {/* {
                                selectedLength ? <span>
                                    <Button txt={textBtn} className={this.btnColor[type]} onClick={this.props.onClick}></Button>&nbsp;
                            </span> : null
                            } */}
              <Button txt={textBtn} className={this.btnColor[type]} onClick={this.props.onClick} />
              &nbsp;
              <Button txt={textBtnBack} className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
