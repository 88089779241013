import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import ModalFunction from '../../common/ModalFunction';
import { DATATABLE_ID } from './LogoAndSignatureContainer';
import { toStatus, getPermission } from '../../common/helpper';

const permission = getPermission('Common Master', 'Logo & Signature');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-4', 'col-md-6'];

const opt = {
  order: [[2, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'type', className: 'text-center' },
    { data: 'name', className: 'text-center' },
    {
      data: 'fileName',
      className: 'text-center',
      render: (d, t, r) => {
        if (!d) return '';
        return '<button class="btn btn-icon btn-info view-img"><span class="icon icon-image sq-24"></span></button>';
      },
    },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,

  fileRef,
  modalViewLogoRef,
  viewImgUrl,
}) => {
  const modeEdit = modalData.Action === 'edit';
  const checkSave = !!((modalData.Action === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Accounting <span className="icon icon-angle-double-right" /> Logo & Signature
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Logo/Signature Name">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.Name}
                  name="Name"
                />
              </FormGroup2>

              <FormGroup2 text="Type">
                <select
                  className="form-control"
                  value={criteria.Type}
                  onChange={onChangeSearch}
                  name="Type"
                >
                  <option value="">---All---</option>
                  <option value="Logo">Logo</option>
                  <option value="Signature">Signature</option>
                </select>
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.IsActive}
                  onChange={onChangeSearch}
                  name="IsActive"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="Create Logo/Signature"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalData}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Type</th>
              <th>Logo/Signature Name</th>
              <th>Image</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal
        textHeader="View Logo/Signature"
        bgHeader="bg-primary"
        size="modal-lg"
        ref={modalViewLogoRef}
      >
        {viewImgUrl ? <img src={viewImgUrl} width="100%" /> : null}
      </Modal>

      <Modal
        textHeader="Master Logo/Signature"
        bgHeader="bg-primary"
        size="modal-md"
        ref={modalDataRef}
      >
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <FormGroup3 text="Name" col={colX1} required>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.Name || ''}
              required
              name="Name"
            />
          </FormGroup3>

          <FormGroup3 text="Type" col={colX1} required>
            <select
              className="form-control"
              value={modalData.Type}
              onChange={onChangeModalData}
              required
              name="Type"
            >
              <option value="">---Choose---</option>
              <option value="Logo">Logo</option>
              <option value="Signature">Signature</option>
            </select>
          </FormGroup3>

          <FormGroup3 text="Image" col={colX1} required>
            <input
              type="file"
              className="form-control"
              name="File"
              onChange={onChangeModalData}
              accept="image/png"
              required
              ref={fileRef}
            />
          </FormGroup3>
          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
