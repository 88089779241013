import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import { DATATABLE_ID } from './CurrencyContainer';
import ModalFunction from '../../common/ModalFunction';
import { toStatus, getPermission } from '../../common/helpper';

const permission = getPermission('Master Accounting', 'Instance');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-5', 'col-md-5'];

const opt = {
  order: [[2, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'instanceCode', className: 'text-center' },
    { data: 'instanceName' },
    { data: 'systemIdDev', className: 'text-center' },
    { data: 'systemIdQa', className: 'text-center' },
    { data: 'systemIdProd', className: 'text-center' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Action === 'edit';
  const checkSave = !!((modalData.Action === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Accounting <span className="icon icon-angle-double-right" /> Instance
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Instance Code">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.InstanceCode}
                  name="InstanceCode"
                />
              </FormGroup2>

              <FormGroup2 text="Instance Name">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.InstanceName}
                  name="InstanceName"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.IsActive}
                  onChange={onChangeSearch}
                  name="IsActive"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="Create Instance"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalData}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Instance Code</th>
              <th>Instance Name</th>
              <th>System ID Dev</th>
              <th>System ID QA</th>
              <th>System ID Prod</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Master Instance" bgHeader="bg-primary" size="modal-md" ref={modalDataRef}>
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <FormGroup3 text="Instance Code" col={colX1} required>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.InstanceCode || ''}
              required
              // readOnly={modeEdit}
              name="InstanceCode"
            />
          </FormGroup3>

          <FormGroup3 text="Instance Name" col={colX1} required>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.InstanceName || ''}
              required
              // readOnly={modeEdit}
              name="InstanceName"
            />
          </FormGroup3>

          <FormGroup3 text="System ID Dev" col={colX1}>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.SystemIdDev || ''}
              name="SystemIdDev"
            />
          </FormGroup3>

          <FormGroup3 text="System ID QA" col={colX1}>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.SystemIdQa || ''}
              name="SystemIdQa"
            />
          </FormGroup3>

          <FormGroup3 text="System ID Prod" col={colX1}>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.SystemIdProd || ''}
              name="SystemIdProd"
            />
          </FormGroup3>

          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
