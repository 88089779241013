import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_INTEREST_CRITERIA = 'EDIT_MASTER_INTEREST_CRITERIA';
export const CLEAR_MASTER_INTEREST_CRITERIA = 'CLEAR_MASTER_INTEREST_CRITERIA';
export const UPDATE_SEARCH_RESULT_MASTER_INTEREST = 'UPDATE_SEARCH_RESULT_MASTER_INTEREST';

export const REQUEST_SEARCH_MASTER_INTEREST = '@@request/SEARCH_MASTER_INTEREST';
export const RECEIVE_SEARCH_MASTER_INTEREST = '@@receive/SEARCH_MASTER_INTEREST';
export const FAIL_SEARCH_MASTER_INTEREST = '@@fail/SEARCH_MASTER_INTEREST';

export const REQUEST_SUBMIT_MASTER_INTEREST_RATE = '@@request/SUBMIT_MASTER_INTEREST_RATE';
export const RECEIVE_SUBMIT_MASTER_INTEREST_RATE =
  '@@receive-The record was successfully saved/SUBMIT_MASTER_INTEREST_RATE';
export const FAIL_SUBMIT_MASTER_INTEREST_RATE = '@@fail/SUBMIT_MASTER_INTEREST_RATE';

export const submitInterestRate = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterinterest`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SUBMIT_MASTER_INTEREST_RATE,
      RECEIVE_SUBMIT_MASTER_INTEREST_RATE,
      FAIL_SUBMIT_MASTER_INTEREST_RATE,
    ],
  },
});

export const searchMasterInterest = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterinterest', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_INTEREST,
      RECEIVE_SEARCH_MASTER_INTEREST,
      FAIL_SEARCH_MASTER_INTEREST,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_INTEREST,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_INTEREST_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_INTEREST_CRITERIA,
  payload: {
    name,
    value,
  },
});
