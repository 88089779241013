import { connect } from 'react-redux';
import React, { Component } from 'react';

import CreateDeposit from './CreateDeposit';
import { calNewDepositAmount } from './ModalWithdraw';
import { addAlert } from '../../../reducers/layout/action';
import { isLeapYear, toUpperKey, compareDate } from '../../../common/helpper';
import { getInterestRepayment, getRepayRolloverHistory } from '../../../reducers/pnLoan/action';
import {
  searchLoan,
  editCriteria,
  saveWithdraw,
  changeStatus,
  clearCriteria,
  updateSearchResult,
} from '../../../reducers/deposit/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-create-deposit';

const initChangeStatus = {
  transactionNo: '',
  modal: '',
};
const { moment } = window;
const initDeposit = {
  TransactionType: '',
  CompanyCode: '',
  BusinessPartner: '',
  BankCode: '',
  BankCodePartner: '',
  PartnerType: '',
  IsWithdrawAll: false,
  WithdrawDateStr: '',
  DepositType: '',
  Amount: '',
  PaymentMethodPrincipal: '',
  PaymentMethodInterest: '',
  BankAccountNoInterest: '',
  BankAccountNoPrincipal: '',
  BankAccountNoPartnerInterest: '',
  BankAccountNoPartnerPrincipal: '',
  BankAccountCurrencyInterest: '',
  BankAccountCurrencyPrincipal: '',
  BankAccountCurrencyPartnerInterest: '',
  BankAccountCurrencyPartnerPrincipal: '',
  DisabledPaymentMethodPrincipal: false,
  DisabledPaymentMethodInterest: false,
  TTAmount: '',
  Interest: '',
  InterestAmount: '',
  WithholdingTax: '',
  IsThaiSweep: null,
  IsIncluding: false,
  IncreaseDecrease: '',
  IncreaseDecreaseAmount: '',
  RolloverDateStr: window.moment().add(0, 'days').format('DD/MM/YYYY'),
  TermStartStr: '',
  TermEndStr: '',
  StampDutyFee: 0,
  BankCharge: 0,

  OldTermStartStr: '',
  OldTermEndStr: '',
  inputMaskAmount: {
    prefix: '',
    digits: 0,
    digitsOptional: false,
    placeholder: '0',
  },

  withdrawRolloverHistory: {},
};
const paymentMethodEnableTT = ['Bahtnet', 'T/T'];

class CreateDepositContainer extends Component {
  state = {
    ...initDeposit,
    modalChangeStatus: {
      ...initChangeStatus,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.deposit`, this.onClickOpenModalDepositColumn);
    $('body').on('click', `button.withdraw-history`, this.onClickOpenModalWithdrawRolloverHistory);

    // let query = this.props.routeProp.location.search.replace('?','')
    // query = qs.parse(query)
    // if(query.s !== undefined && query.s === 'true') {
    //     this.props.searchLoan({ ...this.props.criteria })
    // }
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.deposit`, this.onClickOpenModalDepositColumn);
    $('body').off('click', `button.withdraw-history`, this.onClickOpenModalWithdrawRolloverHistory);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.BankAccountCurrencyInterest !== this.state.BankAccountCurrencyInterest ||
      prevState.BankAccountCurrencyPartnerInterest !==
        this.state.BankAccountCurrencyPartnerInterest ||
      prevState.BankAccountCurrencyPrincipal !== this.state.BankAccountCurrencyPrincipal ||
      prevState.BankAccountCurrencyPartnerPrincipal !==
        this.state.BankAccountCurrencyPartnerPrincipal ||
      prevState.Currency !== this.state.Currency ||
      prevState.TransactionNo !== this.state.TransactionNo ||
      prevState.IsThaiSweep !== this.state.IsThaiSweep
    ) {
      // compute disabled payment method

      let DisabledPaymentMethodPrincipal = true;
      let DisabledPaymentMethodInterest = true;
      let { PaymentMethodPrincipal } = this.state;
      let { PaymentMethodInterest } = this.state;

      if (this.state.Currency === this.state.BankAccountCurrencyPrincipal) {
        if (
          this.state.PartnerType === 'Intercompany' &&
          this.state.Currency === this.state.BankAccountCurrencyPartnerPrincipal
        )
          DisabledPaymentMethodPrincipal = false;
        else if (this.state.PartnerType === 'External') DisabledPaymentMethodPrincipal = false;
      }

      if (this.state.Currency === this.state.BankAccountCurrencyInterest) {
        if (
          this.state.PartnerType === 'Intercompany' &&
          this.state.Currency === this.state.BankAccountCurrencyPartnerInterest
        )
          DisabledPaymentMethodInterest = false;
        else if (this.state.PartnerType === 'External') DisabledPaymentMethodInterest = false;
      }

      if (DisabledPaymentMethodPrincipal) PaymentMethodPrincipal = 'T/T';

      if (DisabledPaymentMethodInterest) PaymentMethodInterest = 'T/T';

      if (this.state.IsThaiSweep) {
        // DisabledPaymentMethodPrincipal = true
        DisabledPaymentMethodInterest = true;
        // PaymentMethodPrincipal = 'Auto Deduct'
        PaymentMethodInterest = 'Auto Deduct';
      }

      this.setState({
        DisabledPaymentMethodPrincipal,
        DisabledPaymentMethodInterest,
        PaymentMethodPrincipal,
        PaymentMethodInterest,
      });
    }
  }

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchLoan({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalChangeStatus: {
        ...initChangeStatus,
        transactionNo: rowData.transactionNo,
        modal,
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const submitData = {
      TransactionNo: this.state.modalChangeStatus.transactionNo,
    };
    this.props.changeStatus(submitData).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchLoan({ ...this.props.criteria });
    });
  };

  // Modal Withdraw
  onClickOpenModalDepositColumn = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];

    const termEnd = selectedData.termEndStr || window.moment().add(0, 'days').format('DD/MM/YYYY');

    this.props
      .getInterestRepayment({
        TransactionNos: [selectedData.transactionNo],
        RepaymentDateStr: initDeposit.RolloverDateStr,
        NewInterestRate: selectedData.interestRate, // default interest
      })
      .then((response) => {
        if (response.error) return;
        const { payload } = response;
        this.setState(
          {
            ...initDeposit,
            Amount: payload.totalAmount,
            Interest: selectedData.interestRate,
            InterestAmount: 0,
            TransactionNo: selectedData.transactionNo,
            OldTermStartStr: selectedData.termStartStr,
            OldTermEndStr: termEnd,
            RolloverDateStr: '',
            inputMaskAmount: {
              prefix: '',
              digits: selectedData.currencyDigit,
              digitsOptional: false,
              placeholder: !selectedData.currencyDigit
                ? '0'
                : `0.${`000${selectedData.currencyDigit}`.slice(-3)}`,
            },
            CompanyCode: selectedData.companyCode,
            BusinessPartner: selectedData.businessPartner,
            BankCode:
              selectedData.transactionType === 'Loan'
                ? selectedData.bankCodeLoan
                : selectedData.bankCodeDeposit,
            BankCodePartner:
              selectedData.transactionType === 'Deposit'
                ? selectedData.bankCodeLoan
                : selectedData.bankCodeDeposit,
            PartnerType: selectedData.partnerType,
            LoanType: selectedData.loanType,
            TransactionType: selectedData.transactionType,
            BankAccountNoInterest: payload.bankAccountNoInterest,
            BankAccountNoPartnerInterest: payload.bankAccountNoPartnerInterest,

            BankAccountNoPrincipal: payload.bankAccountNoPrincipal,
            BankAccountNoPartnerPrincipal: payload.bankAccountNoPartnerPrincipal,

            BankAccountCurrencyInterest: payload.bankAccountCurrencyInterest,
            BankAccountCurrencyPartnerInterest: payload.bankAccountCurrencyPartnerInterest,
            PaymentMethodInterest: payload.paymentMethodInterest,

            BankAccountCurrencyPrincipal: payload.bankAccountCurrencyPrincipal,
            BankAccountCurrencyPartnerPrincipal: payload.bankAccountCurrencyPartnerPrincipal,
            PaymentMethodPrincipal: payload.paymentMethodPrincipal,

            Currency: selectedData.currency,
            IsThaiSweep: selectedData.isThaiSweep,
            TTAmount: '',
          },
          this.modalDepositRef.open
        );
      });
  };

  onChangeModalDeposit = (e) => {
    const { name, value, checked, datas } = e.target;
    this.setState((state) => {
      let stateToUpdate = {
        ...state,
        [name]: value,
      };

      if (name === 'IsWithdrawAll') {
        stateToUpdate.IsWithdrawAll = !state.IsWithdrawAll;
        stateToUpdate.DepositType = '';
        stateToUpdate.RolloverDateStr = '';
        stateToUpdate.TermStartStr = '';
        stateToUpdate.TermEndStr = '';
        stateToUpdate.WithdrawDateStr = '';
        stateToUpdate.IsIncluding = false;
        stateToUpdate.IncreaseDecrease = '';
        stateToUpdate.IncreaseDecreaseAmount = '';
        stateToUpdate.StampDutyFee = '';
        stateToUpdate.BankCharge = '';

        if (stateToUpdate.IsWithdrawAll) {
          stateToUpdate.WithdrawDateStr = window.moment().add(0, 'days').format('DD/MM/YYYY');
          if (~paymentMethodEnableTT.indexOf(state.PaymentMethodPrincipal || ''))
            stateToUpdate.TTAmount = state.Amount || '0';
        } else {
          if (~paymentMethodEnableTT.indexOf(state.PaymentMethodPrincipal || ''))
            stateToUpdate.TTAmount = '0';
          stateToUpdate.RolloverDateStr = state.OldTermEndStr;
        }
      } else if (name === 'PaymentMethodPrincipal') {
        if (!~paymentMethodEnableTT.indexOf(value || '')) stateToUpdate.TTAmount = '0';
        else if (state.IsWithdrawAll) stateToUpdate.TTAmount = state.Amount || '0';
        else stateToUpdate.TTAmount = state.IncreaseDecreaseAmount;
      } else if (name === 'IncreaseDecreaseAmount') {
        if (~paymentMethodEnableTT.indexOf(state.PaymentMethodPrincipal || ''))
          stateToUpdate.TTAmount = value;
      } else if (name === 'TTAmount') {
        if (
          !(state.IncreaseDecreaseAmount || 0) ||
          Number(state.IncreaseDecreaseAmount) < Number(value)
        )
          stateToUpdate.TTAmount = '0';
      } else if (name === 'WithdrawDateStr') {
        if (compareDate(stateToUpdate.OldTermStartStr, stateToUpdate.WithdrawDateStr)) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: `Withdraw Date must be more than or equal ${stateToUpdate.OldTermStartStr}.`,
          });
          return {
            [name]: state.WithdrawDateStr,
          };
        }
      } else if (name === 'DepositType') {
        if (value === 'On Call') {
          stateToUpdate.TermEndStr = '';
        }
      } else if (name === 'IsIncluding') {
        stateToUpdate.IsIncluding = !state.IsIncluding;
        stateToUpdate.PaymentMethodInterest = 'Auto Deduct';
      } else if (name === 'RolloverDateStr') {
        if (value === '') {
          stateToUpdate.RolloverDateStr = '';
          stateToUpdate.RolloverDate = '';
        } else {
          if (compareDate(stateToUpdate.OldTermStartStr, stateToUpdate.RolloverDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: `Rollover Date must be more than or equal ${stateToUpdate.OldTermStartStr}.`,
            });
            return {
              [name]: state.RolloverDateStr,
            };
          }
          if (compareDate(stateToUpdate.RolloverDateStr, stateToUpdate.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Rollover Date must be less than or equal Term End.',
            });
            return {
              [name]: state.RolloverDateStr,
            };
          }
        }
      } else if (name === 'TermEndStr') {
        if (value === '' && name === 'TermEndStr') {
          stateToUpdate.TermEndStr = '';
          stateToUpdate.TermEnd = '';
        } else {
          if (compareDate(stateToUpdate.OldTermStartStr, stateToUpdate.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: `Term End must be more than or equal ${stateToUpdate.OldTermStartStr}.`,
            });
            return {
              [name]: state.TermEndStr,
            };
          }
          if (compareDate(stateToUpdate.RolloverDateStr, stateToUpdate.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term End must be more than or equal Rollover Date.',
            });
            return {
              [name]: state.TermEndStr,
            };
          }
        }
      } else if (
        [
          'BankAccountNoInterest',
          'BankAccountNoPrincipal',
          'BankAccountNoPartnerInterest',
          'BankAccountNoPartnerPrincipal',
        ].includes(name)
      ) {
        const selectedBankAccountCurrency = datas.find((f) => f.bankAccountNo === value).currency;
        stateToUpdate[name.replace('No', 'Currency')] = selectedBankAccountCurrency;
      }

      // cal new interest amount
      const {
        Interest,
        Amount,
        IsIncluding,
        IncreaseDecrease,
        IncreaseDecreaseAmount,
        StampDutyFee,
        BankCharge,
        IsWithdrawAll,
        WithdrawDateStr,
        RolloverDateStr,
        TransactionNo,
        WithholdingTax,
      } = stateToUpdate;
      let interestAmount = 0;
      let wht = 0;
      const date = IsWithdrawAll ? WithdrawDateStr : RolloverDateStr;
      const selected = this.props.searchResult.filter((f) => TransactionNo === f.transactionNo);

      selected.forEach((m) => {
        const result = this.calInterestAmount(m, date, IsIncluding, Interest);
        interestAmount += result.interestAmount;
        wht += result.wht;
      });
      // only use new wht when rollover date change
      if (name !== 'RolloverDateStr' && name !== 'WithdrawDateStr' && name !== 'IsWithdrawAll') {
        wht = WithholdingTax;
      }

      stateToUpdate = {
        ...stateToUpdate,
        WithholdingTax: wht,
        // InterestAmount: interestAmount - parseFloat((StampDutyFee.toString() || '0').replace(/,/g, '')) - parseFloat((BankCharge.toString() || '0').replace(/,/g, '')),
        InterestAmount: interestAmount,
      };
      return stateToUpdate;
    });
  };

  calInterestAmount = (data, termEnd, isIncluding, interestRate) => {
    let {
      amount,
      termStartStr,
      endDateListArr,
      whtValue,
      calculateMethod,
      periodEndType,
      currencyDigit,
    } = data;
    if (currencyDigit === null || currencyDigit === undefined) currencyDigit = 2;

    if (endDateListArr && endDateListArr.length && periodEndType !== 'End of Term') {
      const mTermEnd = window.moment(termEnd, 'DD/MM/YYYY');
      const filter = endDateListArr
        .map((m) => window.moment(m, 'DD/MM/YYYY'))
        .filter((f) => {
          const r = f.isBefore(mTermEnd, 'days');
          return r;
        })
        .sort((a, b) => (a.isBefore(b, 'days') ? 1 : a.isAfter(b, 'days') ? -1 : 0));
      if (filter.length) termStartStr = filter[0].add('days', 1).format('DD/MM/YYYY');
    }
    const daysDiff = moment(termEnd, 'DD/MM/YYYY').diff(moment(termStartStr, 'DD/MM/YYYY'), 'days');
    let totalDayinYear = 0;
    switch (calculateMethod) {
      case 'Act/360':
        totalDayinYear = 360;
        break;
      case 'Act/366':
        totalDayinYear = 366;
        break;
      case 'Act/365':
        totalDayinYear = 365;
        break;
      default:
        totalDayinYear = isLeapYear(moment(termStartStr, 'DD/MM/YYYY').year()) ? 366 : 365;
    }
    let interestAmount =
      (((Number(interestRate) / 100) * Number(amount)) / totalDayinYear) * daysDiff;
    interestAmount = Number(interestAmount.toFixed(currencyDigit));
    let wht = 0;
    if (whtValue !== null && whtValue !== undefined) wht = interestAmount * whtValue;
    wht = Number(wht.toFixed(currencyDigit));

    return {
      wht,
      interestAmount,
    };
  };

  onSubmitModalDeposit = (e) => {
    e.preventDefault();
    this.state.TermStartStr = this.state.RolloverDateStr;
    if (
      compareDate(this.state.OldTermStartStr, this.state.WithdrawDateStr) &&
      this.state.IsWithdrawAll
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Withdraw Date must be more than or equal Term Start.',
      });
      return;
    }

    if (
      compareDate(this.state.OldTermStartStr, this.state.RolloverDateStr) &&
      !this.state.IsRepayAll
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Rollover Date / Term Start must be more than or equal Term Start.',
      });
      return;
    }

    const { inputMaskAmount } = this.state;
    this.props
      .saveWithdraw({
        ...this.state,
        Digit: inputMaskAmount.digits,
        NewDepositAmount: calNewDepositAmount(this.state),
      })
      .then((response) => {
        if (response.error) return;

        this.modalDepositRef.close();
        this.props.searchLoan({ ...this.props.criteria });
      });
  };

  onClickOpenModalWithdrawRolloverHistory = async (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];
    const response = await this.props.getRepayRolloverHistory(selectedData.transactionNo);
    if (response.error) return;

    if (!response.payload) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Data not found.',
      });
      return;
    }

    this.setState(
      {
        withdrawRolloverHistory: {
          ...toUpperKey(response.payload),
          inputMaskAmount: {
            prefix: '',
            digits: response.payload.digit || 0,
            digitsOptional: false,
            placeholder: '0.00',
          },
        },
      },
      this.modalWithdrawHistoryRef.open
    );
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onChangeModalDeposit: this.onChangeModalDeposit,
      onSubmitModalDeposit: this.onSubmitModalDeposit,

      withdrawRolloverHistory: this.state.withdrawRolloverHistory,
      modalWithdrawHistoryRef: (e) => (this.modalWithdrawHistoryRef = e),
    };

    return (
      <CreateDeposit
        {...props}
        {...this.state}
        modalDeposit={this.state}
        criteria={this.props.criteria}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInactiveRef={(e) => (this.modalInactiveRef = e)}
        modalDepositRef={(e) => (this.modalDepositRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.deposit,
  }),
  {
    addAlert,
    searchLoan,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    saveWithdraw,
    getInterestRepayment,
    changeStatus,
    getRepayRolloverHistory,
  }
)(CreateDepositContainer);
