import React from 'react';

import Tab from '../../common/Tab';
import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Button from '../../common/Button';
import Select2 from '../../common/Select2';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import RadioButton from '../../common/RadioButton';
import MasterDropdown from '../../common/MasterDropdown';
import { toFixed, getPermission } from '../../common/helpper';
import { MAIN_MASTER_BUSINESS_UNIT, MAIN_MASTER_FINANCE_GROUP } from '../../reducers/master/action';
import MasterDropdownUI, {
  OPTION_FREQUENCY,
  OPTION_WORKING_DAY,
} from '../../common/MasterDropdownUI';

const permission = getPermission('Master Finance', 'Tier Rate');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  tabRef,
  formDetailRef,
  formTierRef,
  formPeriodRef,

  resultEffective,

  onClickAddTier,
  onChangeInputResult,
}) => {
  const textHeader = state.Mode === 'create' ? 'Create Tier' : 'Edit Tier';
  const noEdit = state.Mode === 'edit' && state.setOverDueDate;

  const tabContents = [];
  tabContents.push({
    header: <span>Tier</span>,
    content: (
      <TierTab
        formRef={formTierRef}
        state={state}
        onChangeInputData={onChangeInputData}
        onClickAddTier={onClickAddTier}
        onChangeInputResult={onChangeInputResult}
        noEdit={noEdit}
      />
    ),
    tabName: 'tier',
  });

  tabContents.push({
    header: <span>Period</span>,
    content: (
      <PeriodTab
        formRef={formPeriodRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'period',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" />
            <Link txt="Tier" href="/master/tier-rate" />
            <span className="icon icon-angle-double-right" /> Tier Detail{' '}
          </small>
        </p>
      </div>

      <Card textHeader={textHeader} bgHeader="bg-primary" cardActions={['toggler']}>
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Type" required>
                {/* {
                                    noEdit ? (
                                        <input className="form-control"
                                            readOnly={true}
                                            value={state.Type === 'fg' ? 'Finance Group' : 'Business Unit'}
                                        />
                                    ) : 
                                    <>
                                        <RadioButton
                                            text="Finance Group"
                                            checked={state.Type === 'fg'}
                                            value="fg"
                                            onChange={onChangeInputData}
                                            name="Type" />
                                        <RadioButton
                                            text="Business Unit"
                                            checked={state.Type === 'bu'}
                                            value="bu" onChange={onChangeInputData}
                                            name="Type" />
                                    </>
                                } */}
                <RadioButton
                  text="Finance Group"
                  checked={state.Type === 'fg'}
                  value="fg"
                  onChange={onChangeInputData}
                  name="Type"
                />
                <RadioButton
                  text="Business Unit"
                  checked={state.Type === 'bu'}
                  value="bu"
                  onChange={onChangeInputData}
                  name="Type"
                />
              </FormGroup2>

              {state.Type === 'bu' ? (
                <FormGroup2 text="Business Unit" required key="bu">
                  <MasterDropdown
                    masterType={MAIN_MASTER_BUSINESS_UNIT}
                    value={state.BusinessUnitId}
                    isChoose
                    notMultipleSelect2
                    onChange={(e) => {
                      onChangeInputData({
                        target: e,
                      });
                    }}
                    required
                    disabled={false}
                    status
                    name="BusinessUnitId"
                  />
                </FormGroup2>
              ) : (
                <FormGroup2 text="Finance Group" required key="fi">
                  <MasterDropdown
                    masterType={MAIN_MASTER_FINANCE_GROUP}
                    value={state.FinanceGroupId}
                    isChoose
                    notMultipleSelect2
                    onChange={(e) => {
                      onChangeInputData({
                        target: e,
                      });
                    }}
                    required
                    disabled={false}
                    status
                    name="FinanceGroupId"
                  />
                </FormGroup2>
              )}
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            {!(resultEffective || []).length ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <DatePicker
                    value={state.EffectiveDateStr}
                    onChange={onChangeInputData}
                    name="EffectiveDateStr"
                    required
                    option={{
                      startDate: '+1d',
                      // daysOfWeekDisabled: "0,6",
                      // todayHighlight: true
                    }}
                  />
                </FormGroup2>
              </Col2>
            ) : (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <Select2
                    value={state.TempEffectiveDateStr}
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="TempEffectiveDateStr"
                    required={false}
                  >
                    <option value="New" key="New">
                      New Effective Date
                    </option>
                    {resultEffective.map((m) => (
                      <option value={m.tierId} key={m.tierId}>
                        {m.effectiveDateStr}
                      </option>
                    ))}
                  </Select2>
                </FormGroup2>
                {state.TempEffectiveDateStr === 'New' ? (
                  <FormGroup2 text="New Effective Date" required>
                    <DatePicker
                      value={state.EffectiveDateStr}
                      onChange={onChangeInputData}
                      name="EffectiveDateStr"
                      required
                      option={{
                        startDate: '+1d',
                        // daysOfWeekDisabled: "0,6",
                        // todayHighlight: true
                      }}
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            )}
          </Col2>
        </form>
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !permission ? (
            ''
          ) : noEdit ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={noEdit}
            />
          )
        }
      />
    </div>
  );
};

function TierTab({
  formRef,
  state,
  onChangeInputData,
  onClickAddTier,
  onChangeInputResult,
  noEdit,
}) {
  return (
    <form ref={formRef} name="tier">
      <Card textHeader="Tier">
        {permission && !noEdit ? (
          <Col2 col={colX[1]}>
            <Button
              txt="Add Tier"
              icon="plus"
              className="btn-warning"
              onClick={onClickAddTier}
              disabled={noEdit}
            />
          </Col2>
        ) : null}

        <Col2 col="col-xs-12 col-sm-12 col-md-6">
          <div className="table-responsive">
            <table className="table table-bordered table-nowrap">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Tier</th>
                  <th>Rate Deposit (%)</th>
                  <th>Rate Loan (%)</th>
                </tr>
              </thead>
              <tbody>
                {!(state.TierLevels || []).length ? (
                  <tr>
                    <td colSpan="4" align="center">
                      No data available in table
                    </td>
                  </tr>
                ) : null}
                {state.TierLevels.map((m, i) => (
                  <tr key={i}>
                    <td align="center">
                      {i === state.TierLevels.length - 1 && !noEdit && permission ? (
                        <button
                          type="button"
                          className="btn btn-icon btn-default delete-tier"
                          index={i}
                          title="Cancel"
                        >
                          <span className="icon icon-close sq-24" />
                        </button>
                      ) : null}
                    </td>
                    <td align="center">{m.tier}</td>
                    <td align="right">
                      {noEdit ? (
                        setAmount(m.depositRate)
                      ) : (
                        <InputMask
                          className="form-control"
                          format="currency"
                          option={{
                            prefix: '',
                            digits: 8,
                            digitsOptional: false,
                            placeholder: '0.00000000',
                          }}
                          onChange={(e) => onChangeInputResult(e, i)}
                          value={m.depositRate}
                          required
                          name="depositRate"
                        />
                      )}
                    </td>
                    <td align="right">
                      {noEdit ? (
                        setAmount(m.loanRate)
                      ) : (
                        <InputMask
                          className="form-control"
                          format="currency"
                          option={{
                            prefix: '',
                            digits: 8,
                            digitsOptional: false,
                            placeholder: '0.00000000',
                          }}
                          onChange={(e) => onChangeInputResult(e, i)}
                          value={m.loanRate}
                          required
                          name="loanRate"
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Col2>
      </Card>
    </form>
  );
}

function PeriodTab({ formRef, state, onChangeInputData, noEdit }) {
  const disabled = false;
  const required = true;
  const setPeriodEndType = state.PeriodEndType !== 'End of Term';
  const setDueDateType = state.DueDateType !== 'End of Term';

  return (
    <form ref={formRef} name="period">
      <Card>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Cal. Method" required>
              <MasterDropdownUI
                onChange={onChangeInputData}
                isChoose
                notMultiple
                required
                disabled={noEdit}
                value={state.CalculateMethod}
                name="CalculateMethod"
                options={['Act/360', 'Act/365', 'Act/366', 'Actual']}
              />
            </FormGroup2>
          </Col2>
        </Col2>
      </Card>

      {noEdit ? (
        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Period End" required>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="PeriodEndType"
                      value={state.PeriodEndType}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Include" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="IsManualInclude"
                      value={state.IsManualInclude ? 'Yes' : 'No'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualPeriodEnd"
                      value={state.ManualPeriodEnd || '-'}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Day" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualDay"
                      value={state.ManualDay || '-'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Working Day" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="ManualWorkingDay"
                    value={state.ManualWorkingDay || ''}
                    readOnly
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="DueDateType"
                    value={
                      state.DueDateType === 'End of Term'
                        ? 'End of Term (Shift Back to Term End)'
                        : state.DueDateType
                    }
                    readOnly
                  />
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualDueDate"
                      value={state.ManualDueDate || '-'}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Day" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="DueDateDay"
                      value={state.DueDateDay || '-'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Frequency Month(s)" required={false}>
                  <input
                    className="form-control"
                    name="Frequency"
                    defaultValue={state.Frequency || ''}
                    readOnly
                  />
                </FormGroup2>

                <FormGroup2 text="Working Day" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="DueDateWorkingDay"
                    value={state.DueDateWorkingDay || ''}
                    readOnly
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>
      ) : (
        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Period End" required>
                  <div>
                    <RadioButton
                      text="End of Term"
                      value="End of Term"
                      checked={state.PeriodEndType === 'End of Term'}
                      onChange={onChangeInputData}
                      name="PeriodEndType"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Period End"
                      value="Manual Period End"
                      checked={state.PeriodEndType === 'Manual Period End'}
                      onChange={onChangeInputData}
                      name="PeriodEndType"
                    />
                    <label className="custom-control">
                      <div>
                        <input
                          id="IsManualInclude"
                          type="checkbox"
                          className="label-checkbox"
                          onChange={onChangeInputData}
                          name="IsManualInclude"
                          checked={state.IsManualInclude}
                          disabled={disabled}
                        />
                        <label htmlFor="IsManualInclude" className="label_checkbox" />
                        <label
                          htmlFor="IsManualInclude"
                          className="control-label text_label_checkbox"
                        >
                          Include
                        </label>
                      </div>
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualPeriodEnd === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEnd"
                      disabled={!setPeriodEndType}
                      required={setPeriodEndType}
                    />

                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualPeriodEnd === 'Day'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEnd"
                      disabled={!setPeriodEndType}
                    />

                    <label className="custom-control">
                      {state.ManualPeriodEnd === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualPeriodEnd === 'Day'}
                          disabled={state.ManualPeriodEnd !== 'Day'}
                          value={state.ManualDay}
                          name="ManualDay"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.ManualDay || ''}
                          name="ManualDay"
                          required={state.ManualPeriodEnd === 'Day'}
                          disabled={state.ManualPeriodEnd !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-8">
                <FormGroup2 text="Working Day" required>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.ManualWorkingDay}
                    name="ManualWorkingDay"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required>
                  <div>
                    <RadioButton
                      text="End of Term (Shift Back to Term End)"
                      value="End of Term"
                      checked={state.DueDateType === 'End of Term'}
                      onChange={onChangeInputData}
                      name="DueDateType"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Due Date"
                      value="Manual Due Date"
                      checked={state.DueDateType === 'Manual Due Date'}
                      onChange={onChangeInputData}
                      name="DueDateType"
                    />
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualDueDate === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualDueDate"
                      disabled={!setDueDateType}
                      required={setDueDateType}
                    />
                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualDueDate === 'Day'}
                      onChange={onChangeInputData}
                      disabled={!setDueDateType}
                      name="ManualDueDate"
                    />

                    <label className="custom-control">
                      {state.ManualDueDate === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualDueDate === 'Day'}
                          disabled={state.ManualDueDate !== 'Day'}
                          value={state.DueDateDay}
                          name="DueDateDay"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.DueDateDay || ''}
                          name="DueDateDay"
                          required={state.ManualDueDate === 'Day'}
                          disabled={state.ManualDueDate !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-6">
                <FormGroup2 text="Frequency Month(s)" required={false}>
                  <MasterDropdownUI
                    masterType={OPTION_FREQUENCY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    disabled={disabled}
                    value={state.Frequency}
                    name="Frequency"
                  />
                </FormGroup2>
                <FormGroup2 text="Working Day" required>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.DueDateWorkingDay}
                    name="DueDateWorkingDay"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>
      )}
    </form>
  );
}

const setAmount = (number, float = 8) => toFixed(number, float);
