import {
  EDIT_SEARCH_TASK_LIST,
  RECEIVE_SEARCH_TASK_LIST,
  UPDATE_SEARCH_RESULT_TASK_LIST,
} from './action';

const init = {
  searchResult: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_RESULT_TASK_LIST:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case RECEIVE_SEARCH_TASK_LIST:
      return {
        ...state,
        searchResult: action.payload,
      };
    case EDIT_SEARCH_TASK_LIST:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected')
              return {
                ...m,
                [action.payload.name]: m.canApprove ? action.payload.value : false,
              };
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    default:
      return state;
  }
};
