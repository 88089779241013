import React from 'react';

import Tab from '../../common/Tab';
import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import SpanStatus from '../../common/SpanStatus';
import RadioButton from '../../common/RadioButton';
import MasterDropdown from '../../common/MasterDropdown';
import { toLower, getPermission } from '../../common/helpper';
import MasterDropdownUI, {
  OPTION_FREQUENCY,
  OPTION_WORKING_DAY,
} from '../../common/MasterDropdownUI';
import {
  MASTER_COMPANY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_UNIT,
  MASTER_INTEREST_TEMPLEATE,
} from '../../reducers/master/action';

const permission = getPermission('Automate', 'Maintain Interest');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  tabRef,
  formDetailRef,
  formPeriodLoanRef,
  formPeriodDepositRef,
}) => {
  const textHeader =
    state.Action === 'create' ? 'Create Maintain Interest' : 'Edit Maintain Interest';
  const modeEdit = state.Action === 'edit';
  const noEdit =
    state.MaintainInterestId && !state.IsActive
      ? true
      : state.Action === 'create' && state.MaintainInterestId;

  const tabContents = [];
  tabContents.push({
    header: <span>Loan Period</span>,
    content: (
      <PeriodLoanTab
        formRef={formPeriodLoanRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'loan',
  });

  tabContents.push({
    header: <span>Deposit Period</span>,
    content: (
      <PeriodDepositTab
        formRef={formPeriodDepositRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'deposit',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Automate <span className="icon icon-angle-double-right" />
            <Link txt="Maintain Interest" href="/automate/maintain-interest" />
            <span className="icon icon-angle-double-right" /> Maintain Interest Detail
            {modeEdit ? (
              <>
                {' '}
                <span className="icon icon-angle-double-right" />{' '}
                <SpanStatus status={state.IsActive ? 'Active' : 'Inactive'} />
              </>
            ) : null}
          </small>
        </p>
      </div>

      <Card textHeader={textHeader} bgHeader="bg-primary" cardActions={['toggler']}>
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            {/* <Col2 col={colX[1]}>
                            <FormGroup2 text="Group" required={true}>
                                <RadioButton
                                    text="Finance Group"
                                    checked={state.Group === 'fg'}
                                    value="fg"
                                    onChange={onChangeInputData}
                                    name="Group" />
                                <RadioButton
                                    text="Business Unit"
                                    checked={state.Group === 'bu'}
                                    value="bu" onChange={onChangeInputData}
                                    name="Group" />
                            </FormGroup2>

                            {
                                state.Group === 'bu'
                                    ? (
                                        <FormGroup2 text="Business Unit" required={true} key="bu">
                                            <MasterDropdown
                                                masterType={MASTER_BUSINESS_UNIT}
                                                noValidateOption={true}
                                                saveLocalState={true}
                                                value={state.BusinessUnitId}
                                                isChoose={true}
                                                notMultipleSelect2={true}
                                                onChange={(e) => {
                                                    onChangeInputData({
                                                        target: e
                                                    })
                                                }}
                                                required={true}
                                                disabled={false}
                                                name="BusinessUnitId" />
                                        </FormGroup2>
                                    )
                                    :
                                    <FormGroup2 text="Finance Group" required={true} key="fi">
                                        <MasterDropdown
                                            masterType={MASTER_FINANCE}
                                            noValidateOption={true}
                                            saveLocalState={true}
                                            value={state.FinanceGroupId}
                                            isChoose={true}
                                            notMultipleSelect2={true}
                                            onChange={(e) => {
                                                onChangeInputData({
                                                    target: e
                                                })
                                            }}
                                            required={true}
                                            disabled={false}
                                            name="FinanceGroupId" />
                                    </FormGroup2>
                            }
                        </Col2> */}

            <Col2 col={colX[1]}>
              <FormGroup2 text="Business Unit" required>
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  noValidateOption
                  saveLocalState
                  value={state.BusinessUnitId}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  required
                  disabled={modeEdit}
                  name="BusinessUnitId"
                />
              </FormGroup2>

              <FormGroup2 text="Company" required>
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  noValidateOption
                  saveLocalState
                  // isChoose={true}
                  notMultipleSelect2
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  required
                  disabled={modeEdit}
                  isAll
                  financeGroupId={state.FinanceGroupId}
                  businessUnitId={state.BusinessUnitId}
                  value={state.CompanyCode}
                  name="CompanyCode"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank" required>
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  noValidateOption
                  saveLocalState
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChangeInputData({
                      target: e,
                    });
                  }}
                  required
                  disabled={modeEdit}
                  companyCode={toLower(state.CompanyCode) === 'all' ? null : state.CompanyCode}
                  isGetAll
                  value={state.BankCode}
                  name="BankCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !permission ? (
            ''
          ) : noEdit ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={noEdit}
            />
          )
        }
      />
    </div>
  );
};

function PeriodLoanTab({ formRef, state, onChangeInputData, noEdit }) {
  const disabled = false;
  const required = !!Number(state.InterestRateLoan);
  const setPeriodEndType = state.PeriodEndTypeLoan !== 'End of Term';
  const setDueDateType = state.DueDateTypeLoan !== 'End of Term';

  return (
    <form ref={formRef} name="loan">
      <Card>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Template Structure Name">
              <MasterDropdown
                masterType={MASTER_INTEREST_TEMPLEATE}
                noValidateOption
                saveLocalState
                value={state.InterestRateIdLoan}
                isChoose
                notMultipleSelect2
                onChange={(e) => {
                  onChangeInputData({
                    target: e,
                  });
                }}
                disabled={noEdit}
                name="InterestRateIdLoan"
              />
            </FormGroup2>

            <FormGroup2 text="Interest Rate">
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={{
                  prefix: '',
                  digits: 5,
                  digitsOptional: false,
                  placeholder: '0.00000',
                }}
                disabled={noEdit}
                value={state.InterestRateLoan}
                name="InterestRateLoan"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Cal. Method" required={required}>
              <MasterDropdownUI
                onChange={onChangeInputData}
                isChoose
                notMultiple
                required={required}
                disabled={noEdit}
                value={state.CalculateMethodLoan}
                name="CalculateMethodLoan"
                options={['Act/360', 'Act/365', 'Act/366', 'Actual']}
              />
            </FormGroup2>
            <FormGroup2 text="Loan Type" required={required}>
              <RadioButton
                text="On Call"
                value="ONCALL"
                checked={state.LoanType === 'ONCALL'}
                onChange={onChangeInputData}
                name="LoanType"
                required={required}
              />
              <RadioButton
                text="Term"
                value="TERM"
                checked={state.LoanType === 'TERM'}
                onChange={onChangeInputData}
                name="LoanType"
                required={required}
              />
            </FormGroup2>
          </Col2>
        </Col2>
      </Card>

      {noEdit ? (
        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Period End" required={required}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="PeriodEndTypeLoan"
                      value={state.PeriodEndTypeLoan}
                      readOnly={required}
                    />
                  </FormGroup2>
                  <FormGroup2 text="Include" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="IsManualIncludeLoan"
                      value={state.IsManualIncludeLoan ? 'Yes' : 'No'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualPeriodEndLoan"
                      value={state.ManualPeriodEndLoan || '-'}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Day" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualDayLoan"
                      value={state.ManualDayLoan || '-'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Working Day" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="ManualWorkingDayLoan"
                    value={state.ManualWorkingDayLoan || ''}
                    readOnly
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="DueDateTypeLoan"
                    value={
                      state.DueDateTypeLoan === 'End of Term'
                        ? 'End of Term (Shift Back to Term End)'
                        : state.DueDateTypeLoan
                    }
                    readOnly
                  />
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualDueDateLoan"
                      value={state.ManualDueDateLoan || '-'}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Day" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="DueDateDayLoan"
                      value={state.DueDateDayLoan || '-'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Frequency Month(s)" required={false}>
                  <input
                    className="form-control"
                    name="FrequencyLoan"
                    defaultValue={state.FrequencyLoan || ''}
                    readOnly
                  />
                </FormGroup2>

                <FormGroup2 text="Working Day" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="DueDateWorkingDayLoan"
                    value={state.DueDateWorkingDayLoan || ''}
                    readOnly
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>
      ) : (
        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Period End" required={required}>
                  <div>
                    <RadioButton
                      text="End of Term"
                      value="End of Term"
                      checked={state.PeriodEndTypeLoan === 'End of Term'}
                      onChange={onChangeInputData}
                      name="PeriodEndTypeLoan"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Period End"
                      value="Manual Period End"
                      checked={state.PeriodEndTypeLoan === 'Manual Period End'}
                      onChange={onChangeInputData}
                      name="PeriodEndTypeLoan"
                    />
                    <label className="custom-control">
                      <div>
                        <input
                          id="IsManualIncludeLoan"
                          type="checkbox"
                          className="label-checkbox"
                          onChange={onChangeInputData}
                          name="IsManualIncludeLoan"
                          checked={state.IsManualIncludeLoan}
                          disabled={disabled}
                        />
                        <label htmlFor="IsManualIncludeLoan" className="label_checkbox" />
                        <label
                          htmlFor="IsManualIncludeLoan"
                          className="control-label text_label_checkbox"
                        >
                          Include
                        </label>
                      </div>
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Period End" required={setPeriodEndType && required}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualPeriodEndLoan === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEndLoan"
                      disabled={!setPeriodEndType}
                      required={setPeriodEndType && required}
                    />

                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualPeriodEndLoan === 'Day'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEndLoan"
                      disabled={!setPeriodEndType}
                    />

                    <label className="custom-control">
                      {state.ManualPeriodEnd === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualPeriodEndLoan === 'Day' && required}
                          disabled={state.ManualPeriodEndLoan !== 'Day'}
                          value={state.ManualDayLoan}
                          name="ManualDayLoan"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.ManualDayLoan || ''}
                          name="ManualDayLoan"
                          required={state.ManualPeriodEndLoan === 'Day' && required}
                          disabled={state.ManualPeriodEndLoan !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-8">
                <FormGroup2 text="Working Day" required={required}>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.ManualWorkingDayLoan}
                    name="ManualWorkingDayLoan"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required={required}>
                  <div>
                    <RadioButton
                      text="End of Term (Shift Back to Term End)"
                      value="End of Term"
                      checked={state.DueDateTypeLoan === 'End of Term'}
                      onChange={onChangeInputData}
                      name="DueDateTypeLoan"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Due Date"
                      value="Manual Due Date"
                      checked={state.DueDateTypeLoan === 'Manual Due Date'}
                      onChange={onChangeInputData}
                      name="DueDateTypeLoan"
                    />
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Due Date" required={setDueDateType && required}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualDueDateLoan === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualDueDateLoan"
                      disabled={!setDueDateType}
                      required={setDueDateType && required}
                    />
                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualDueDateLoan === 'Day'}
                      onChange={onChangeInputData}
                      disabled={!setDueDateType}
                      name="ManualDueDateLoan"
                    />

                    <label className="custom-control">
                      {state.ManualDueDate === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualDueDateLoan === 'Day' && required}
                          disabled={state.ManualDueDateLoan !== 'Day'}
                          value={state.DueDateDayLoan}
                          name="DueDateDayLoan"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.DueDateDayLoan || ''}
                          name="DueDateDayLoan"
                          required={state.ManualDueDateLoan === 'Day' && required}
                          disabled={state.ManualDueDateLoan !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-6">
                <FormGroup2 text="Frequency Month(s)" required={false}>
                  <MasterDropdownUI
                    masterType={OPTION_FREQUENCY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    disabled={disabled}
                    value={state.FrequencyLoan}
                    name="FrequencyLoan"
                  />
                </FormGroup2>
                <FormGroup2 text="Working Day" required={required}>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.DueDateWorkingDayLoan}
                    name="DueDateWorkingDayLoan"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>
      )}
    </form>
  );
}

function PeriodDepositTab({ formRef, state, onChangeInputData, noEdit }) {
  const disabled = false;
  const required = !!Number(state.InterestRateDeposit);
  const setPeriodEndType = state.PeriodEndTypeDeposit !== 'End of Term';
  const setDueDateType = state.DueDateTypeDeposit !== 'End of Term';

  return (
    <form ref={formRef} name="deposit">
      <Card>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Template Structure Name">
              <MasterDropdown
                masterType={MASTER_INTEREST_TEMPLEATE}
                noValidateOption
                saveLocalState
                value={state.InterestRateIdDeposit}
                isChoose
                notMultipleSelect2
                onChange={(e) => {
                  onChangeInputData({
                    target: e,
                  });
                }}
                disabled={noEdit}
                name="InterestRateIdDeposit"
              />
            </FormGroup2>

            <FormGroup2 text="Interest Rate">
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={{
                  prefix: '',
                  digits: 5,
                  digitsOptional: false,
                  placeholder: '0.00000',
                }}
                disabled={noEdit}
                value={state.InterestRateDeposit}
                name="InterestRateDeposit"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Cal. Method" required={required}>
              <MasterDropdownUI
                onChange={onChangeInputData}
                isChoose
                notMultiple
                required={required}
                disabled={noEdit}
                value={state.CalculateMethodDeposit}
                name="CalculateMethodDeposit"
                options={['Act/360', 'Act/365', 'Act/366', 'Actual']}
              />
            </FormGroup2>
          </Col2>
        </Col2>
      </Card>

      {noEdit ? (
        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Period End" required>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="PeriodEndTypeDeposit"
                      value={state.PeriodEndTypeDeposit}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Include" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="IsManualIncludeDeposit"
                      value={state.IsManualIncludeDeposit ? 'Yes' : 'No'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualPeriodEndDeposit"
                      value={state.ManualPeriodEndDeposit || '-'}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Day" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualDayDeposit"
                      value={state.ManualDayDeposit || '-'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Working Day" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="ManualWorkingDayDeposit"
                    value={state.ManualWorkingDayDeposit || ''}
                    readOnly
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="DueDateTypeDeposit"
                    value={
                      state.DueDateTypeDeposit === 'End of Term'
                        ? 'End of Term (Shift Back to Term End)'
                        : state.DueDateTypeDeposit
                    }
                    readOnly
                  />
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualDueDateDeposit"
                      value={state.ManualDueDateDeposit || '-'}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Day" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="DueDateDayDeposit"
                      value={state.DueDateDayDeposit || '-'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Frequency Month(s)" required={false}>
                  <input
                    className="form-control"
                    name="FrequencyDeposit"
                    defaultValue={state.FrequencyDeposit || ''}
                    readOnly
                  />
                </FormGroup2>

                <FormGroup2 text="Working Day" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="DueDateWorkingDayDeposit"
                    value={state.DueDateWorkingDayDeposit || ''}
                    readOnly
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>
      ) : (
        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Period End" required={required}>
                  <div>
                    <RadioButton
                      text="End of Term"
                      value="End of Term"
                      checked={state.PeriodEndTypeDeposit === 'End of Term'}
                      onChange={onChangeInputData}
                      name="PeriodEndTypeDeposit"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Period End"
                      value="Manual Period End"
                      checked={state.PeriodEndTypeDeposit === 'Manual Period End'}
                      onChange={onChangeInputData}
                      name="PeriodEndTypeDeposit"
                    />
                    <label className="custom-control">
                      <div>
                        <input
                          id="IsManualIncludeDeposit"
                          type="checkbox"
                          className="label-checkbox"
                          onChange={onChangeInputData}
                          name="IsManualIncludeDeposit"
                          checked={state.IsManualIncludeDeposit}
                          disabled={disabled}
                        />
                        <label htmlFor="IsManualIncludeDeposit" className="label_checkbox" />
                        <label
                          htmlFor="IsManualIncludeDeposit"
                          className="control-label text_label_checkbox"
                        >
                          Include
                        </label>
                      </div>
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Period End" required={setPeriodEndType && required}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualPeriodEndDeposit === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEndDeposit"
                      disabled={!setPeriodEndType}
                      required={setPeriodEndType && required}
                    />

                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualPeriodEndDeposit === 'Day'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEndDeposit"
                      disabled={!setPeriodEndType}
                    />

                    <label className="custom-control">
                      {state.ManualPeriodEnd === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualPeriodEndDeposit === 'Day' && required}
                          disabled={state.ManualPeriodEndDeposit !== 'Day'}
                          value={state.ManualDayDeposit}
                          name="ManualDayDeposit"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.ManualDayDeposit || ''}
                          name="ManualDayDeposit"
                          required={state.ManualPeriodEndDeposit === 'Day' && required}
                          disabled={state.ManualPeriodEndDeposit !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-8">
                <FormGroup2 text="Working Day" required={required}>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.ManualWorkingDayDeposit}
                    name="ManualWorkingDayDeposit"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required={required}>
                  <div>
                    <RadioButton
                      text="End of Term (Shift Back to Term End)"
                      value="End of Term"
                      checked={state.DueDateTypeDeposit === 'End of Term'}
                      onChange={onChangeInputData}
                      name="DueDateTypeDeposit"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Due Date"
                      value="Manual Due Date"
                      checked={state.DueDateTypeDeposit === 'Manual Due Date'}
                      onChange={onChangeInputData}
                      name="DueDateTypeDeposit"
                    />
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Due Date" required={setDueDateType && required}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualDueDateDeposit === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualDueDateDeposit"
                      disabled={!setDueDateType}
                      required={setDueDateType && required}
                    />
                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualDueDateDeposit === 'Day'}
                      onChange={onChangeInputData}
                      disabled={!setDueDateType}
                      name="ManualDueDateDeposit"
                    />

                    <label className="custom-control">
                      {state.ManualDueDate === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualDueDateDeposit === 'Day' && required}
                          disabled={state.ManualDueDateDeposit !== 'Day'}
                          value={state.DueDateDayDeposit}
                          name="DueDateDayDeposit"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.DueDateDayDeposit || ''}
                          name="DueDateDayDeposit"
                          required={state.ManualDueDateDeposit === 'Day' && required}
                          disabled={state.ManualDueDateDeposit !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-6">
                <FormGroup2 text="Frequency Month(s)" required={false}>
                  <MasterDropdownUI
                    masterType={OPTION_FREQUENCY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    disabled={disabled}
                    value={state.FrequencyDeposit}
                    name="FrequencyDeposit"
                  />
                </FormGroup2>
                <FormGroup2 text="Working Day" required={required}>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.DueDateWorkingDayDeposit}
                    name="DueDateWorkingDayDeposit"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>
      )}
    </form>
  );
}
