import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_FLOW_ACCOUNT_CRITERIA = 'EDIT_MASTER_FLOW_ACCOUNT_CRITERIA';
export const CLEAR_MASTER_FLOW_ACCOUNT_CRITERIA = 'CLEAR_MASTER_FLOW_ACCOUNT_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_FLOW_ACCOUNT = 'UPDATE_SEARCH_RESULT_MASTER_FLOW_ACCOUNT';

export const REQUEST_SEARCH_MASTER_FLOW_ACCOUNT = '@@request/SARCH_MASTER_FLOW_ACCOUNT';
export const RECEIVE_SEARCH_MASTER_FLOW_ACCOUNT = '@@receive/SEARCH_MASTER_FLOW_ACCOUNT';
export const FAIL_SEARCH_MASTER_FLOW_ACCOUNT = '@@fail/SEARCH_MASTER_FLOW_ACCOUNT';

export const REQUEST_UPDATE_MASTER_FLOW_ACCOUNT = '@@request/UPDATE_MASTER_FLOW_ACCOUNT';
export const RECEIVE_UPDATE_MASTER_FLOW_ACCOUNT =
  '@@receive-The record was successfully saved/UPDATE_MASTER_FLOW_ACCOUNT';
export const FAIL_UPDATE_MASTER_FLOW_ACCOUNT = '@@fail/UPDATE_MASTER_FLOW_ACCOUNT';

export const updateMasterFlowAccount = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/UpdateMasterFlowAccount`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_UPDATE_MASTER_FLOW_ACCOUNT,
      RECEIVE_UPDATE_MASTER_FLOW_ACCOUNT,
      FAIL_UPDATE_MASTER_FLOW_ACCOUNT,
    ],
  },
});

export const searchMasterFlowAccount = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/GetMasterFlowAccount', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_FLOW_ACCOUNT,
      RECEIVE_SEARCH_MASTER_FLOW_ACCOUNT,
      FAIL_SEARCH_MASTER_FLOW_ACCOUNT,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_FLOW_ACCOUNT,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_FLOW_ACCOUNT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_FLOW_ACCOUNT_CRITERIA,
  payload: {
    name,
    value,
  },
});
