import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { DATATABLE_ID } from './SearchMaintainBalanceContainer';
import { toStatus, toNumber, getPermission } from '../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('Automate', 'Maintain Balance');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-5', 'col-md-5'];
const colX2 = ['col-md-5', 'col-md-6'];

const opt = {
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'financeGroupName' },
    { data: 'buName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'minBalanceAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currenyDigit),
    },
    { data: 'currency', className: 'text-center' },
    {
      data: 'intgralMultiple',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currenyDigit),
    },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
}) => {
  const modeEdit = modalData.Mode === 'edit';
  const checkSave = !!((modalData.Mode === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Automate <span className="icon icon-angle-double-right" /> Maintain Balance
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required={false}>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroup}
                  name="FinanceGroup"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit" required={false}>
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnit}
                  name="BusinessUnit"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company" required={false}>
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  // onChange={e => onChangeSearch({
                  //     target: e
                  // })}
                  // isChoose={true}
                  // notMultipleSelect2={true}
                  // required={true}
                  onChange={onChangeSelect2}
                  financeGroupId={criteria.FinanceGroup}
                  businessUnitId={criteria.BusinessUnit}
                  value={criteria.CompanyCode}
                  name="CompanyCode"
                />
              </FormGroup2>

              <FormGroup2 text="Currency" required={false}>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  // onChange={e => onChangeSearch({
                  //     target: e
                  // })}
                  // isChoose={true}
                  // notMultipleSelect2={true}
                  // required={true}
                  onChange={onChangeSelect2}
                  value={criteria.Currency}
                  name="Currency"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="Maintain Balance"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalData}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br /> Code
              </th>
              <th>Company Name</th>
              <th>Min Balance Amount</th>
              <th>Currency</th>
              <th>Integral multiple</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Maintain Balance" bgHeader="bg-primary" size="modal-md" ref={modalDataRef}>
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <FormGroup3 text="Company" col={colX2} required>
            <MasterDropdown
              masterType={MASTER_COMPANY}
              noValidateOption
              saveLocalState
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              isChoose
              notMultipleSelect2
              required
              value={modalData.CompanyCode}
              name="CompanyCode"
            />
          </FormGroup3>

          <FormGroup3 text="Min Balance Amount" col={colX1} required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }}
              onChange={onChangeModalData}
              value={modalData.MinBalanceAmount}
              required
              name="MinBalanceAmount"
            />
          </FormGroup3>

          <FormGroup3 text="Currency" col={colX1} required>
            <MasterDropdown
              masterType={MASTER_CURRENCY}
              noValidateOption
              saveLocalState
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              isChoose
              notMultipleSelect2
              required
              value={modalData.Currency}
              name="Currency"
            />
          </FormGroup3>

          <FormGroup3 text="Integral Multiple" col={colX1} required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }}
              onChange={onChangeModalData}
              value={modalData.IntgralMultiple}
              required
              name="IntgralMultiple"
            />
          </FormGroup3>
          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onClickSaveModalData(e)}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onClickSaveModalData(e)}
      />
    </div>
  );
};
