import { connect } from 'react-redux';
import React, { Component } from 'react';

import RequestTt from './RequestTt';
import { addAlert } from '../../reducers/layout/action';
import { searchMasterUser } from '../../reducers/masterUser/action';
import { getAtatchfileList, downloadAttachFile } from '../../reducers/ttRemittance/action';
import {
  toLower,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../common/helpper';
import {
  editCriteria,
  changeStatus,
  clearCriteria,
  searchTtRemittance,
  updateSearchResult,
  getAccoutingVerify,
  saveTtRemittanceManual,
} from '../../reducers/requestTt/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-tt-remittance-request';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const jwt = JSON.parse(localStorage.getItem('jwt'));
const USERNAME = jwt ? jwt.username : '';

const initFunciton = {
  mode: '',
  transactions: '',

  textHeader: '',
  textContent: '',
  type: '',
  icon: '',
  textBtn: 'Yes',
  textBtnBack: 'No',
  selectedLength: 0,
};

class RequestTtContainer extends Component {
  state = {
    modalFunction: {
      ...initFunciton,
    },
    isAccountingVerify: false,
    isAccountApprove: false,
    duplicateTransaction: '',
    modalDuplicate: {},
    email: '',
    attachFiles: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);

    $('body').on('click', `button.action-duplicate`, this.onClickDuplicate);
    $('body').on('click', `button.attach-file-result`, this.onClickAttachFile);

    this.props.searchMasterUser({ Username: USERNAME }).then((response) => {
      if (response.error) return;
      const users = response.payload[0];
      this.setState({
        ...this.state,
        email: users.email,
      });
    });
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.action-duplicate`, this.onClickDuplicate);
  }

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    if (result) {
      this.props.searchResult[result.row].IsSelected = result.checked;
      this.forceUpdate();
    }
  };

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchTtRemittance({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };
  // ---------------- End Search ----------------

  // ---------------- Button Function ----------------
  onClickOpenModalFunction = (modal) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Please select item for ${modal}!`,
      });
      return;
    }

    const statuslist = [...new Set(selectedData.map((m) => m.statusTt))];
    if (statuslist.length > 1) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please choose same status.',
      });
      return;
    }

    if (
      toLower(statuslist[0]) === 'create' ||
      toLower(statuslist[0]) === 'rejected' ||
      toLower(statuslist[0] === 'wait for confirm')
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Can not for ${modal} because status is ${statuslist[0]}.`,
      });
      return;
    }

    let groupPass = [];
    const resultFail = [];
    if (modal === 'approve' || modal === 'disapprove') {
      selectedData.forEach((m) => {
        if (
          toLower(m.statusTt) === 'wait for initial' &&
          toLower(m.initial) === toLower(USERNAME)
        ) {
          groupPass.push(m);
        } else if (
          toLower(m.statusTt) === 'wait for approve' &&
          toLower(m.approver) === toLower(USERNAME)
        ) {
          groupPass.push(m);
        } else if (
          toLower(m.statusTt) === 'wait for accounting verify' &&
          this.state.isAccountingVerify
        ) {
          groupPass.push(m);
        } else if (
          toLower(m.statusTt) === 'wait for accounting approve' &&
          this.state.isAccountApprove
        ) {
          groupPass.push(m);
        }
      });
    } else {
      groupPass = selectedData;
    }

    const failLength = selectedData.length - groupPass.length;
    if (failLength) {
      resultFail.push(
        <span>
          {failLength} item(s) selected,
          <br />
          because you have no permission to perform this action.
        </span>
      );
    }

    const transactionNos = groupPass.map((m) => m.transactionNo);
    // console.log(transactionNos)

    let textHeader = '';
    let textContent = '';
    let type = '';
    let icon = '';
    if (modal === 'approve') {
      textHeader = 'Approve';
      textContent = 'approve';
      type = 'success';
      icon = 'icon-check';
    } else if (modal === 'reject') {
      textHeader = 'Reject';
      textContent = 'reject';
      type = 'danger';
      icon = 'icon-close';
    } else if (modal === 'disapprove') {
      textHeader = 'Disapprove';
      textContent = 'disapprove';
      type = 'warning';
      icon = 'icon-refresh';
    }

    this.setState(
      {
        modalFunction: {
          ...initFunciton,
          mode: modal,
          transactions: transactionNos.join(','),

          textHeader,
          textContent,
          type,
          icon,

          showFail: !!failLength,
          passLength: groupPass.length,
          resultFail,
        },
      },
      this.modalFunctionRef.open
    );
  };

  onSubmitModalChangeStatus = (e) => {
    const d = this.state.modalFunction;
    const data = {
      TransactionNo: d.transactions,
      Mode: d.mode,
    };
    this.props.changeStatus(data).then(() => {
      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchTtRemittance({ ...this.props.criteria });
      this.modalFunctionRef.close();
    });
  };
  // ---------------- End Button Function ----------------

  onClickDuplicate = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const m = this.props.searchResult[row];

    this.setState(
      {
        duplicateTransaction: m.transactionNo,
        modalDuplicate: {
          CompanyCode: m.companyCode,
          ValueDateStr: m.valueDateStr,
          BeneficiaryCode: m.beneficiaryCode,
          BeneficiaryName: m.beneficiaryName,
          BeneficiaryAddress: m.beneficiaryAddress,
          BeneficiaryCountry: m.beneficiaryCountry,
          AccountNo: m.accountNo,
          IbanNo: m.ibanNo,
          AbaNo: m.abaNo,
          BsbNo: m.bsbNo,
          IfscCode: m.ifscCode,
          BeneficiaryBankName: m.beneficiaryBankName,
          BeneficiaryBankAddress: m.beneficiaryBankAddress,
          BeneficiaryBankSwiftCode: m.beneficiaryBankSwiftCode,
          BeneficiaryBankCountry: m.beneficiaryBankCountry,
          IntermediateBankName: m.intermediateBankName,
          IntermediateBankAddress: m.intermediateBankAddress,
          IntermediateBankSwiftCode: m.intermediateBankSwiftCode,
          IntermediateBankCountry: m.intermediateBankCountry,
          IntenalMessage: m.intenalMessage,
          CreatorName: USERNAME,
          CreatorEmail: this.state.email,
          mode: 'create',
          type: 'save',
          DuplicateTransaction: m.transactionNo,
        },
      },
      this.modalDuplicateRef.open
    );
  };

  onChangeInputData = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      modalDuplicate: {
        ...this.state.modalDuplicate,
        [name]: value,
      },
    });

    if (name === 'CompanyCode') {
      this.props.getAccoutingVerify({ CompanyCode: value }).then((response) => {
        if (response.error) return;

        const users = response.payload;
        this.setState({
          ...this.state,
          modalDuplicate: {
            ...this.state.modalDuplicate,
            AccountingVerifierName: users.map((m) => m.username).join(','),
          },
        });
      });
    }
  };

  onClickConfirmDuplicate = (e) => {
    e.preventDefault();

    this.props.saveTtRemittanceManual({ ...this.state.modalDuplicate }).then((response) => {
      if (response.error || !response.payload) return;

      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchTtRemittance({ ...this.props.criteria });
      this.modalDuplicateRef.close();
    });
  };

  onClickAttachFile = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const m = this.props.searchResult[row];
    this.props.getAtatchfileList(m.refTransactionNo).then((response) => {
      if (response.error || !response.payload) return;
      const result = (response.payload || []).filter((f) => f.isDtt !== true);
      this.setState(
        {
          ...this.state,
          attachFiles: result,
        },
        this.modalAttachFileRef.open
      );
    });
  };

  onClickDownloadFile = (e, id) => {
    this.props.downloadAttachFile(id);
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onClickOpenModalFunction: this.onClickOpenModalFunction,
      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onClickConfirmDuplicate: this.onClickConfirmDuplicate,
      onChangeInputData: this.onChangeInputData,

      onClickDownloadFile: this.onClickDownloadFile,
    };
    return (
      <RequestTt
        {...props}
        {...this.state}
        criteria={this.props.criteria}
        modalFunctionRef={(e) => (this.modalFunctionRef = e)}
        modalDuplicateRef={(e) => (this.modalDuplicateRef = e)}
        modalAttachFileRef={(e) => (this.modalAttachFileRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.requestTt,
  }),
  {
    searchTtRemittance,
    updateSearchResult,
    addAlert,
    editCriteria,
    clearCriteria,
    changeStatus,
    getAccoutingVerify,
    saveTtRemittanceManual,
    searchMasterUser,
    getAtatchfileList,
    downloadAttachFile,
  }
)(RequestTtContainer);
