import { connect } from 'react-redux';
import React, { Component } from 'react';

import BankAccountDetail from './BankAccountDetail';
import { addAlert } from '../../reducers/layout/action';
import { toFixed, toUpperKey, compareDate, checkAfterValueDate } from '../../common/helpper';
import { getBankCountry, getCurrencyDigit, getInstanceCompany } from '../../reducers/master/action';
import {
  getCompanyDetail,
  getMasterCompanyEffectiveDate,
} from '../../reducers/masterCompany/action';
import {
  getBankAccountDetail,
  saveBankAccountDetail,
  approveMasterBankAccount,
  getMasterBankAccountffectiveDate,
} from '../../reducers/masterBankAccount/action';

const initData = {
  EffectiveDateStr: '',
  TempEffectiveDateStr: 'New',

  FinanceGroupId: '',
  BankAccountNo: '',
  CompanyCode: '',
  BankCode: '',
  BranchCode: '',
  Instance: '',
  InstanceCode: '',
  Currency: '',
  Country: '',
  Region: '',
  HouseBank: '',
  GlAcctBal: '',
  GlAcctDep: '',
  GlAcctPay: '',
  AccountType: '',
  AccountTypeCode: '',
  TransferTo: '',

  // set default
  IsDefaultMainSweep: false,
  IsDefaultMainAutoTransfer: false,
  IsGainLossFx: false,
  IsInwardExposure: false,
  IsTt: false,
  IsPnLoan: false,
  IsContractLoan: false,
  IsBond: false,
  IsDepositLoan: false,
  IsOncallLoan: false,
  IsBg: false,

  // config
  DisplayCashPosition: '',
  CanSweep: '',
  IsMainSweep: '',
  MinimumBalanceSweep: 0,
  OverDraft: 0,
  CanAutoTransfer: '',
  IsMainAutoTransfer: '',
  MinimumBalanceAutoTransfer: 0,
  MinimumAutoTransfer: 0,
  TransferMethodMainToMember: '',
  TransferMethodMemberToMain: '',

  inputMaskAmount: {
    prefix: '',
    digits: 2,
    digitsOptional: false,
    placeholder: '0.00',
  },

  mode: 'create',
  IsActive: true,
  BankAccountNoSap: '',
  IsElectronicBank: '',
  Remark: '',
  Approver1: '',
  IsTypeComp: 1,
  SameRegionFundTransferFee: false,
};

class BankAccountDetailContainer extends Component {
  state = {
    ...initData,
    resultEffective: [],
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState({
        ...initData,
      });
    else {
      this.fetchId(id);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.routeProp.match.params;
    const prevId = prevProps.routeProp.match.params.id;
    if (id !== prevId) {
      if (!id || id.toString().toLowerCase() === 'create')
        this.setState({
          ...initData,
        });
      else {
        this.fetchId(id);
      }
    }

    const {
      Currency,
      OverDraft,
      CanSweep,
      CanAutoTransfer,
      MinimumBalanceSweep,
      MinimumBalanceAutoTransfer,
      MinimumAutoTransfer,
      CompanyCode,
      BankCode,
      EffectiveDateStr,
    } = this.state;

    if (prevState.Currency !== Currency) {
      this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          OverDraft: toFixed(Number(OverDraft || 0), digit),
          MinimumBalanceSweep: CanSweep ? toFixed(Number(MinimumBalanceSweep || 0), digit) : '',
          MinimumBalanceAutoTransfer: CanAutoTransfer
            ? toFixed(Number(MinimumBalanceAutoTransfer || 0), digit)
            : '',
          MinimumAutoTransfer: CanAutoTransfer
            ? toFixed(Number(MinimumAutoTransfer || 0), digit)
            : '',
        });
      });
    }

    if (prevState.BankCode !== BankCode) {
      this.props.getBankCountry({ BankCode }).then((response) => {
        if (response.error) return;

        const r = response.payload[0] || { country: '' };
        this.setState({
          ...this.state,
          Country: r.country || '',
        });
      });
    }

    if (
      (prevState.CompanyCode !== CompanyCode || prevState.EffectiveDateStr !== EffectiveDateStr) &&
      CompanyCode &&
      EffectiveDateStr
    ) {
      this.props.getMasterCompanyEffectiveDate({ CompanyCode, Mode: 'date' }).then((response) => {
        if (response.error || !response.payload) return;
        const r = response.payload;
        const effectiveDates = r.map((m) => m.effectiveDateStr);
        let effectiveDate = '';
        let checkDate = false;
        effectiveDates.forEach((m) => {
          if ((compareDate(EffectiveDateStr, m) || m === EffectiveDateStr) && !checkDate) {
            effectiveDate = m;
            checkDate = true;
          }
        });
        this.props
          .getCompanyDetail({ CompanyCode, EffectiveDateStr: effectiveDate })
          .then((response) => {
            if (response.error || !response.payload) return;
            const rp = response.payload;
            const instance = `${rp.instanceCode}|${rp.instanceName}`;
            this.setState({
              ...this.state,
              InstanceCode: instance,
              Instance: rp.instanceCode,
            });
          });
      });
    }
  }

  fetchId(id) {
    if (!id) return;

    this.props
      .getMasterBankAccountffectiveDate({ BankAccountNo: id, Mode: 'date' })
      .then((response) => {
        if (response.error || !response.payload) return;

        const result = response.payload.map((m) => m.effectiveDateStr);
        this.setState({
          ...initData,
          resultEffective: result,
        });

        const result2 = result.filter((f) => {
          if (!checkAfterValueDate(f, 0)) return true;
          return false;
        });

        if (result2.length === 0) {
          this.setDataBankAccount(result[result.length - 1]);
        } else {
          const effectiveDate = result2[0];
          this.setDataBankAccount(effectiveDate);
        }
      });
  }

  setDataBankAccount(effectiveDateStr) {
    const { id } = this.props.routeProp.match.params;

    this.props
      .getBankAccountDetail({ BankAccountNo: id, EffectiveDateStr: effectiveDateStr })
      .then((response) => {
        if (response.error || !response.payload) return;

        const fetchData = toUpperKey(response.payload);
        this.setState({
          ...fetchData,
          IsDefaultMainSweep: fetchData.IsMainSweep,
          IsDefaultMainAutoTransfer: fetchData.IsMainAutoTransfer,
          mode: 'edit',
          TempEffectiveDateStr: fetchData.EffectiveDateStr,
          setOverDueDate: !checkAfterValueDate(fetchData.EffectiveDateStr, 0),
          SameRegionFundTransferFee: fetchData.SameRegionFundTransferFee || false,
        });
      });
  }

  onChangeInputData = (e) => {
    const { name, value, datas } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'BankAccountNo') {
        stateToUpdate.BankAccountNo = value.trim();
        stateToUpdate.BankAccountNoSap = value.trim();
        // if (isNaN(value) || value.includes('.')) {
        //     stateToUpdate.BankAccountNo = state.BankAccountNo
        // }
      } else if (name === 'GlAcctBal' || name === 'GlAcctDep' || name === 'GlAcctPay') {
        stateToUpdate[name] = value.trim();
        if (isNaN(value) || value.includes('.') || value.length > 10) {
          stateToUpdate[name] = state[name];
        }
      } else if (name === 'CompanyCode') {
        const { financeGroupId } = datas.find((f) => f.companyCode === value) || {};
        stateToUpdate.FinanceGroupId = financeGroupId;
      } else if (name === 'SameRegionFundTransferFee') {
        stateToUpdate.SameRegionFundTransferFee = !this.state.SameRegionFundTransferFee;
      } else if (name === 'IsMainSweep' || name === 'IsDefaultMainSweep') {
        const s = !this.state[name];
        stateToUpdate.IsMainSweep = s;
        stateToUpdate.IsDefaultMainSweep = s;
      } else if (name === 'IsMainAutoTransfer' || name === 'IsDefaultMainAutoTransfer') {
        const s = !this.state[name];
        stateToUpdate.IsMainAutoTransfer = s;
        stateToUpdate.IsDefaultMainAutoTransfer = s;
      } else if (
        ~[
          'DisplayCashPosition',
          'CanSweep',
          'CanAutoTransfer',
          'IsBg',
          'IsElectronicBank',
          'IsGainLossFx',
          'IsInwardExposure',
          'IsTt',
          'IsPnLoan',
          'IsContractLoan',
          'IsBond',
          'IsDepositLoan',
          'IsOncallLoan',
        ].indexOf(name)
      ) {
        stateToUpdate[name] = !this.state[name];

        if (name === 'CanSweep') {
          if (stateToUpdate.CanSweep) stateToUpdate.MinimumBalanceSweep = 0;
          else stateToUpdate.MinimumBalanceSweep = '';
        }
        if (name === 'CanAutoTransfer') {
          if (stateToUpdate.CanAutoTransfer) {
            stateToUpdate.MinimumAutoTransfer = 0;
            stateToUpdate.MinimumBalanceAutoTransfer = 0;
          } else {
            stateToUpdate.MinimumAutoTransfer = '';
            stateToUpdate.MinimumBalanceAutoTransfer = '';
          }
        }
      }
      // else if(name ==='CompanyCode')
      // {
      //     const companyMaster = datas.find(f=>f.companyCode===value)
      //     console.log(companyMaster)
      //     if(companyMaster){
      //         stateToUpdate.Instance = companyMaster.instanceCode
      //     }
      // }

      return stateToUpdate;
    });

    if (name === 'TempEffectiveDateStr') {
      if (value === 'New') {
        this.setState({
          EffectiveDateStr: '',
          setOverDueDate: false,
          IsActive: true,
        });
      } else {
        this.setDataBankAccount(value);
      }
    }
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitHander = async (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formSetDefaultRef,
      this.formConfigRef,
      this.formApproveRef,
    ]);
    if (inValidFormName) return;

    const submitData = { ...this.state };
    submitData.Mode = submitData.mode;

    delete submitData.mode;
    delete submitData.inputMaskAmount;
    delete submitData.resultEffective;
    if (this.state.mode === 'create' && !this.attachFileRef.fileLength) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'The Attach file is required.',
      });
      return;
    }
    const res = await this.attachFileRef.submit();
    if (res.error) {
      return;
    }
    this.props.saveBankAccountDetail(submitData).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        window.location.href = `/master/bank-account-detail/${response.payload}`;
      } else {
        this.fetchId(response.payload);
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/master/bank-account');
  };

  onClickClearTransferTo = () => {
    this.setState({
      ...this.state,
      TransferTo: '',
    });
  };

  onChangeApprover = (e) => {
    const { name, value } = e.target;

    const { Approver1, Approver2 } = this.state;
    const approverToCheck = name === 'Approver2' ? Approver1 : Approver2;
    // ceck dupicate
    if (approverToCheck === value) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Dupicate approver',
      });
      return;
    }

    this.setState({
      [name]: value,
    });
  };

  onClickApprove = (approve) => {
    this.props
      .approveMasterBankAccount({
        BankAccountNo: this.state.BankAccountNo,
        IsApprove: approve,
      })
      .then((res) => {
        if (res.error) return;

        window.location.reload();
      });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,
      onClickClearTransferTo: this.onClickClearTransferTo,

      tabRef: (e) => (this.tabRef = e),
      formDetailRef: (e) => (this.formDetailRef = e),
      formConfigRef: (e) => (this.formConfigRef = e),
      formSetDefaultRef: (e) => (this.formSetDefaultRef = e),
      formApproveRef: (e) => (this.formApproveRef = e),

      onChangeApprover: this.onChangeApprover,
      onClickApprove: this.onClickApprove,

      attachFileRef: (e) => (this.attachFileRef = e),
    };

    return (
      <div>
        <BankAccountDetail
          {...props}
          state={this.state}
          resultEffective={this.state.resultEffective}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterBankAccount,
  }),
  {
    getMasterBankAccountffectiveDate,
    getBankAccountDetail,
    addAlert,
    saveBankAccountDetail,
    getCurrencyDigit,
    getInstanceCompany,
    getBankCountry,
    getMasterCompanyEffectiveDate,
    getCompanyDetail,
    approveMasterBankAccount,
  }
)(BankAccountDetailContainer);
