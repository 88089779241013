import { connect } from 'react-redux';
import React, { Component } from 'react';

import CreateBond from './CreateBond';
import { compareDate } from '../../../common/helpper';
import { addAlert } from '../../../reducers/layout/action';
import {
  saveBond,
  sellBond,
  searchBond,
  editCriteria,
  clearCriteria,
  updateSearchResult,
} from '../../../reducers/bond/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-created-bond';

const initSell = {
  Unit: '',
  Amount: '',
  SellingPricePerUnit: '',
  ValueDateStr: '',
  Source: '',
  inputMaskAmount: {
    prefix: '',
    digits: 0,
    digitsOptional: false,
    placeholder: '0',
  },
};

class CreateBondContainer extends Component {
  state = {
    modalSell: {
      ...initSell,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.sell`, (e) => this.onClickOpenModalSell(e));
  }

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchBond({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickOpenModalSell = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState(
      {
        modalSell: {
          ...initSell,
          Source: rowData.transactionNo,
          rowData,
          inputMaskAmount: {
            prefix: '',
            digits: rowData.currencyDigit,
            digitsOptional: false,
            placeholder: !rowData.currencyDigit
              ? '0'
              : `0.${`000${rowData.currencyDigit}`.slice(-3)}`,
          },
        },
      },
      this.modalSellRef.open
    );
  };

  onChangeModalSell = (e) => {
    if (e.target.name === 'Amount' || e.target.name === 'Unit') {
      const ppu =
        e.target.name === 'Amount'
          ? e.target.value / this.state.modalSell.Unit
          : this.state.modalSell.Amount / e.target.value;

      this.setState({
        modalSell: {
          ...this.state.modalSell,
          SellingPricePerUnit: ppu,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      this.setState({
        modalSell: {
          ...this.state.modalSell,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  onSubmitModalSell = (e) => {
    e.preventDefault();

    const state = { ...this.state };
    let textCheckDay = '';

    if (state.modalSell.Amount <= 0) {
      textCheckDay = 'Amount must be more then zero.';
    } else if (state.modalSell.Unit > state.modalSell.rowData.outstandingUnit) {
      textCheckDay = 'Unit must be less than or equal Outstanding Unit.';
    } else if (
      compareDate(state.modalSell.ValueDateStr, state.modalSell.rowData.endDateStr) ||
      state.modalSell.rowData.endDateStr === state.modalSell.ValueDateStr
    ) {
      textCheckDay = 'Value date must be less than or equal end date.';
    }

    if (textCheckDay !== '') {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: textCheckDay,
      });
      return;
    }

    this.props
      .sellBond({
        ...this.state.modalSell,
        TransactionNo: this.state.modalSell.Source,
      })
      .then((response) => {
        if (response.error) return;

        this.modalSellRef.close();
        this.props.searchBond({ ...this.props.criteria });
      });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      transactionNo: rowData.transactionNo,
      modal,
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const submit = {
      transactionNo: this.state.transactionNo,
      status: modal,
      mode: 'change',
    };
    this.props.saveBond({ ...submit }).then((response) => {
      if (response.error) {
        this.modalInactiveRef.close();
        return;
      }
      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchBond({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      onClickOpenModalSell: this.onClickOpenModalSell,
      onChangeModalSell: this.onChangeModalSell,
      onSubmitModalSell: this.onSubmitModalSell,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,
      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <CreateBond
        {...props}
        {...this.state}
        criteria={this.props.criteria}
        modalSell={this.state.modalSell}
        modalSellRef={(e) => (this.modalSellRef = e)}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInactiveRef={(e) => (this.modalInactiveRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.bond,
  }),
  {
    searchBond,
    saveBond,
    editCriteria,
    clearCriteria,
    sellBond,
    updateSearchResult,
    addAlert,
  }
)(CreateBondContainer);
