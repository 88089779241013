import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import ModalWithdraw from './ModalWithdraw';
import Table from '../../../common/DataTable';
import FormGroup2 from '../../../common/FormGroup2';
import { DATATABLE_ID } from './CreateDepositContainer';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import ModalWithdrawReadOnly from './ModalWithdrawReadOnly';
import DateRangePicker from '../../../common/DateRangePicker';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import {
  toLower,
  toStatus,
  toNumber,
  getPermission,
  checkAfterValueDate,
} from '../../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('Funding&Investment', 'Create Deposit');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (toLower(r.status) === 'withdraw')
          return '<button title="cancel withdraw" class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
        if (!checkAfterValueDate(r.termEndStr) && r.termEndStr) return '';
        if (toLower(r.status) === 'active')
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        if (toLower(r.status) === 'inactive' && !r.isHideBtn)
          return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
        return '';
      },
    },
    {
      data: 'isDeposit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (toLower(r.status) !== 'active')
          return '<button class="btn btn-icon btn-warning withdraw-history"><span class="icon icon-money sq-24"></span></button>';
        if (!permission) return '';
        return '<button class="btn btn-icon btn-primary deposit"><span class="icon icon-money sq-24"></span></button>';
      },
    },
    {
      data: 'transactionNo',
      render: (d) => `<a target="_blank" href="/loan-investment/deposit/${d}"> ${d} </a>`,
    },
    { data: 'transactionType', className: 'text-center' },
    { data: 'term', className: 'text-center' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'businessPartner', className: 'text-center' },
    { data: 'businessPartnerName' },
    { data: 'contractDateStr', className: 'text-center', type: 'date-black' },
    { data: 'termStartStr', className: 'text-center', type: 'date-black' },
    { data: 'termEndStr', className: 'text-center', type: 'date-black' },
    { data: 'calculateMethod' },
    // { data: 'interestRate', className: 'text-right', render: d => toNumber(d, 5) + "%" },
    {
      data: 'interestRate',
      className: 'text-right',
      render: (d, t) => toNumber(d, 5),
    },
    // { data: 'amount', className: 'text-right', render: d => toNumber(d, 3) },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'currency', className: 'text-center' },
    { data: 'externalRef' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    {
      data: 'transactionNoReference',
      render: (d) =>
        d ? `<a target="_blank" href="/loan-investment/deposit/${d}"> ${d} </a>` : '',
    },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  results,
  dataTableRef,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,

  modalDeposit,
  modalDepositRef,
  onChangeModalDeposit,
  onSubmitModalDeposit,

  withdrawRolloverHistory,
  modalWithdrawHistoryRef,
}) => {
  const required = false;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Funding & Investment <span className="icon icon-angle-double-right" /> Create Deposit
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required={false}>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  required={false}
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  saveLocalState
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                  name="Companies"
                />
              </FormGroup2>

              <FormGroup2 text="Contract Date" required={required}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required={required}
                  value={criteria.ContractDateStr}
                  name="ContractDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Term">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  value={criteria.Term}
                  name="Term"
                  isAll
                  notMultiple
                  options={['ON CALL', 'Short Term', 'Long Term']}
                />
              </FormGroup2>

              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessPartner}
                  // customeLabel={m => `${m.bankCode} | ${m.bankName}`}
                  // companyCode={criteria.Companies}
                  name="BusinessPartner"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Start Date" required={required}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required={required}
                  value={criteria.TermStartStr}
                  name="TermStartStr"
                />
              </FormGroup2>

              <FormGroup2 text="End Date" required={required}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required={required}
                  value={criteria.TermEndStr}
                  name="TermEndStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  value={criteria.Status}
                  name="Status"
                  isAll
                  notMultiple
                  options={['Active', 'Inactive', 'Withdraw', 'Rollover']}
                />
              </FormGroup2>

              <FormGroup2 text="Deposit No.">
                <input
                  className="form-control"
                  value={criteria.TransactionNo}
                  onChange={onChangeSearch}
                  name="TransactionNo"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="External Ref.">
                <input
                  className="form-control"
                  value={criteria.ExternalRef}
                  onChange={onChangeSearch}
                  name="ExternalRef"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/loan-investment/deposit"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    CREATE
                  </Link>
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Deposit</th>
              <th>Deposit No.</th>
              <th>
                Transaction
                <br />
                Type
              </th>
              <th>Term</th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>Company Name</th>
              <th>
                Business
                <br />
                Partner Code
              </th>
              <th>
                Business
                <br />
                Partner
              </th>
              <th>Contract Date</th>
              <th>Term Start</th>
              <th>Term End</th>
              <th>Cal. Method</th>
              <th className="text-center">Interest Rate (%)</th>
              <th className="text-center">Amount</th>
              <th>Currency</th>
              <th>External Ref</th>
              <th>Status</th>
              <th>Transaction No Ref.</th>
              <th>
                Created
                <br />
                Date
              </th>
              <th>
                Created
                <br />
                By
              </th>
              <th>
                Updated
                <br />
                Date
              </th>
              <th>
                Updated
                <br />
                By
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />

      <ModalWithdraw
        state={modalDeposit}
        modalRef={modalDepositRef}
        onChangeInputData={onChangeModalDeposit}
        onSubmit={onSubmitModalDeposit}
      />

      <ModalWithdrawReadOnly state={withdrawRolloverHistory} modalRef={modalWithdrawHistoryRef} />
    </div>
  );
};
