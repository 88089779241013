import { connect } from 'react-redux';
import React, { Component } from 'react';

import ModalAlert from '../../common/ModalAlert';
import { removeError, removeAllAlert } from '../../reducers/layout/action';

class Error extends Component {
  onClickRemoveError = (idx) => {
    this.props.removeError(idx);
  };

  componentDidMount() {
    window.onkeypress = (e) => {
      if (e.code === 'Enter' && this.props.alerts.length) {
        this.props.removeAllAlert();
      }
    };
  }

  componentWillUnmount() {
    window.onkeypress = null;
  }

  render() {
    const { alerts = [] } = this.props;
    return (
      <>
        {alerts.map((m, i) => (
          <ModalAlert
            open
            title={m.title}
            type={m.type}
            body={m.body}
            key={i}
            buttons={m.buttons}
            onClose={(e) => this.onClickRemoveError(i)}
          />
        ))}
      </>
    );
  }
}

export default connect(
  (state) => ({
    alerts: state.layout.alerts,
  }),
  {
    removeError,
    removeAllAlert,
  }
)(Error);
