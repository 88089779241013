import { connect } from 'react-redux';
import React, { Component } from 'react';

import LogoAndSignature from './LogoAndSignature';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import {
  editCriteria,
  downloadLogo,
  clearCriteria,
  saveMasterLogo,
  searchMasterLogo,
} from '../../reducers/masterLogo/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-logo';

const initData = {
  LogoId: '',
  Name: '',
  Type: '',
  Action: 'create',
};
const LIMIT_FILE_SIZE = 1024 * 1024 * 5;
class LogoAndSignatureContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
    viewImgUrl: null,
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
    $('body').on('click', `button.view-img`, (e) => this.onClickOpenModalViewImage(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
    $('body').off('click', `button.view-img`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMasterLogo({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalData = (e) => {
    this.fileRef.value = '';
    this.setState(
      {
        modalData: {
          ...initData,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.fileRef.value = '';
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          Action: 'edit',
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModalData = (e) => {
    let { name, value, type } = e.target;
    if (type === 'file') {
      const allowExt = ['png'];
      value = e.target.files[0];
      if (value.size > LIMIT_FILE_SIZE) {
        this.props.addAlert({
          title: 'Error',
          type: 'warning',
          body: `Size of file muse less than ${LIMIT_FILE_SIZE / (1024 * 1024)}Mb`,
        });
        return;
      }
    }
    this.setState({
      modalData: {
        ...this.state.modalData,
        [name]: value,
      },
    });
  };

  onClickSaveModalData = (e) => {
    e.preventDefault();
    const form = new FormData();
    const modalData = { ...this.state.modalData };
    const file = modalData.File;
    delete modalData.File;
    form.append('data', JSON.stringify(modalData));
    form.append('file', file);

    this.props.saveMasterLogo(form).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      this.props.searchMasterLogo({ ...this.props.criteria });
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        ...toUpperKey(rowData),
        Action: 'status',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const form = new FormData();
    const modalData = { ...this.state.modalData };
    delete modalData.File;
    form.append('data', JSON.stringify(modalData));
    this.props.saveMasterLogo(form).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMasterLogo({ ...this.props.criteria });
    });
  };

  onClickOpenModalViewImage = async (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    const { fileName } = rowData;
    const response = await this.props.downloadLogo(fileName);
    if (response.error) {
      return;
    }
    const blob = await response.payload.blob();
    const url = URL.createObjectURL(blob);
    this.setState(
      {
        viewImgUrl: url,
      },
      this.modalViewLogoRef.open
    );
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onChangeModalData: this.onChangeModalData,
      onClickSaveModalData: this.onClickSaveModalData,
      onClickOpenModalData: this.onClickOpenModalData,
      fileRef: (e) => (this.fileRef = e),
      modalViewLogoRef: (e) => (this.modalViewLogoRef = e),
    };

    return (
      <div>
        <LogoAndSignature
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalDataRef={(e) => (this.modalDataRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterLogo,
  }),
  {
    searchMasterLogo,
    editCriteria,
    clearCriteria,
    addAlert,
    saveMasterLogo,
    downloadLogo,
  }
)(LogoAndSignatureContainer);
