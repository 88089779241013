import {
  EDIT_MASTER_ACCOUNT_STANDARD_CRITERIA,
  RECEIVE_SEARCH_MASTER_ACCOUNT_STANDARD,
  CLEAR_MASTER_ACCOUNT_STANDARD_CRITERIA,
} from './action';

const initCriteria = {
  LedgerName: '',
  AccountPrinciple: '',
  IsActive: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
};

export default (state = init, action) => {
  switch (action.type) {
    case CLEAR_MASTER_ACCOUNT_STANDARD_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MASTER_ACCOUNT_STANDARD_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_MASTER_ACCOUNT_STANDARD:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
