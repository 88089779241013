import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MARK_TO_MARKET_CRITERIA = 'EDIT_MARK_TO_MARKET_CRITERIA';
export const CLEAR_MARK_TO_MARKET_CRITERIA = 'CLEAR_MARK_TO_MARKET_CRITERIA';

export const UPDATE_SEARCH_RESULT_MARK_TO_MARKET = 'UPDATE_SEARCH_RESULT_MARK_TO_MARKET';

export const REQUEST_SEARCH_MARK_TO_MARKET_BOND = '@@request/SARCH_MARK_TO_MARKET';
export const RECEIVE_SEARCH_MARK_TO_MARKET_BOND = '@@receive/SEARCH_MARK_TO_MARKET_BOND';
export const FAIL_SEARCH_MARK_TO_MARKET_BOND = '@@fail/SEARCH_MARK_TO_MARKET_BOND';

export const REQUEST_FETCH_EMAIL_MTM_FORWARD = '@@request/FETCH_EMAIL_MTM_FORWARD';
export const RECEIVE_FETCH_EMAIL_MTM_FORWARD =
  '@@receive-The transaction was successfully fetched/FETCH_EMAIL_MTM_FORWARD';
export const FAIL_FETCH_EMAIL_MTM_FORWARD = '@@fail/FETCH_EMAIL_MTM_FORWARD';

export const REQUEST_FETCH_EMAIL_MTM_SWAP = '@@request/FETCH_EMAIL_MTM_SWAP';
export const RECEIVE_FETCH_EMAIL_MTM_SWAP =
  '@@receive-The transaction was successfully fetched/FETCH_EMAIL_MTM_SWAP';
export const FAIL_FETCH_EMAIL_MTM_SWAP = '@@fail/FETCH_EMAIL_MTM_SWAP';

export const REQUEST_SAVE_MTM_BOND = '@@request/SAVE_MTM_BOND';
export const RECEIVE_SAVE_MTM_BOND =
  '@@receive-The transaction was successfully saved/SAVE_MTM_BOND';
export const FAIL_SAVE_MTM_BOND = '@@fail/SAVE_MTM_BOND';

export const saveMtmBond = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/SaveMTMBond`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MTM_BOND, RECEIVE_SAVE_MTM_BOND, FAIL_SAVE_MTM_BOND],
  },
});

export const fetchEmail = () => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/FetchEmailMTMForward'),
    method: 'GET',
    types: [
      REQUEST_FETCH_EMAIL_MTM_FORWARD,
      RECEIVE_FETCH_EMAIL_MTM_FORWARD,
      FAIL_FETCH_EMAIL_MTM_FORWARD,
    ],
  },
});

export const fetchEmailSwap = () => ({
  [RSAA]: {
    endpoint: endpoint('/loan/FetchEmailMTMSwap'),
    method: 'GET',
    types: [REQUEST_FETCH_EMAIL_MTM_SWAP, RECEIVE_FETCH_EMAIL_MTM_SWAP, FAIL_FETCH_EMAIL_MTM_SWAP],
  },
});

export const searchMarkToMarket = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetMarkToMarketBond', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MARK_TO_MARKET_BOND,
      RECEIVE_SEARCH_MARK_TO_MARKET_BOND,
      FAIL_SEARCH_MARK_TO_MARKET_BOND,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MARK_TO_MARKET,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MARK_TO_MARKET_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MARK_TO_MARKET_CRITERIA,
  payload: {
    name,
    value,
  },
});
