import qs from 'querystring';
import React, { useState, useCallback } from 'react';

import { endpoint } from '../reducers/configuration';

export default function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      async function submit() {
        const data = {
          client_id: '0682dd88-43a5-48a0-adf6-aa1b74070c10',
          grant_type: 'password',
          username,
          password,
        };
        setLoading(true);
        const response = await fetch(endpoint('/oauth2/token', null, true), {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          method: 'POST',
          body: qs.stringify(data),
        });
        setLoading(false);
        const dataResponse = await response.json();

        if (response.ok) {
          localStorage.setItem('jwt', JSON.stringify(dataResponse));
          localStorage.setItem('lt', new Date().getTime());
          let { search } = window.location;
          if (search && search[0] === '?') search = search.slice(1);
          const param = qs.parse(search);
          if (param.p) window.location.href = param.p;
          else window.location.href = '/';
        } else {
          const { statusText } = response;
          const { error } = dataResponse;
          if (error) setError(error);
          else setError(statusText);
        }
      }

      submit();
    },
    [username, password]
  );

  return (
    <div className="body-main">
      <div className="bg-login">
        <div className="position-login">
          <form onSubmit={onSubmit}>
            <div className="row row-header">
              <div className="col-md-12">
                <h1 style={{ fontWeight: 1000, textShadow: '1px 1px #A9A9A9' }}>AFMS</h1>
                <h4>(Advanced Financial Management System)</h4>
              </div>
            </div>
            <div className="row row-header">
              <div className="col-md-12">
                <h3 style={{ fontWeight: 900 }}>Sign in</h3>
              </div>
            </div>
            {error ? <p className="text-danger">{error}</p> : null}
            <div className="row row-body">
              <div className="col-md-5 col-sm-10">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="icon icon-user icon-fw icon-lg" />
                  </span>
                  <input
                    className="form-control"
                    type="text"
                    onChange={(e) => setUsername(e.target.value)}
                    name="Username"
                    autoComplete="username"
                    placeholder="USERNAME"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row row-body">
              <div className="col-md-5 col-sm-10">
                <div className="input-group">
                  <span className="input-group-addon">
                    <i className="icon icon-key icon-fw icon-lg" />
                  </span>
                  <input
                    className="form-control"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    name="Password"
                    autoComplete="current-password"
                    placeholder="PASSWORD"
                    required
                  />
                </div>
              </div>
            </div>
            {/* <div className="row row-body">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <div>
                                        <input id="IsCheck" type="checkbox" className="label-checkbox text-mem" />
                                        <label htmlFor="IsCheck" className="label_checkbox text-mem"></label>
                                        <label htmlFor="IsCheck" className="control-label text_label_checkbox text-mem">Keep me signed in</label>
                                    </div>
                                </div>
                            </div>
                        </div> */}
            <br />
            <div className="row row-footer">
              <div className="col-md-5 col-sm-10">
                <button type="submit" className="btn btn-block" disabled={loading}>
                  SIGN IN
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="position-link-login">
          <a className="link-login" href="/คู่มือกฏแบงค์ชาติ.PPTX">
            ความรู้เพิ่มเติมเกี่ยวกับกฎแบงค์ชาติ
          </a>
        </div>
        <div className="position-link-login-2">
          <a
            className="link-login"
            target="_blank"
            href="https://scgo365.sharepoint.com/sites/CorporatePlanningandFinance"
            rel="noreferrer"
          >
            นโยบายประกาศ และคู่มือทำงานที่เกี่ยวข้อง สำนักงานวางแผนและการเงิน
          </a>
        </div>
        <div className="position-link-login-3">
          <a
            className="link-login"
            target="_blank"
            href="https://scgo365.sharepoint.com/sites/TreasurySCGC/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FTreasurySCGC%2FShared%20Documents%2FIC%20Rate%20Announcement&viewid=0841a496%2Da74e%2D4b51%2Db0e3%2Dbf9d6f4e8a6f"
            rel="noreferrer"
          >
            ประกาศอัตราดอกเบี้ย SCGC
          </a>
        </div>
      </div>
    </div>
  );
}
