import React from 'react';

import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import FormGroup3 from '../../../common/FormGroup3';
import DatePicker from '../../../common/DatePicker';

const colX = ['col-md-4', 'col-md-5'];

export default ({ modalRef, state, onSubmit, onChageModal, inputMaskAmount }) => (
  <Modal textHeader="Credit Line" ref={modalRef} bgHeader="bg-primary">
    <form onSubmit={onSubmit} className="form-horizontal">
      <FormGroup3 text="Credit Line" col={colX} required>
        <InputMask
          className="form-control"
          format="currency"
          option={inputMaskAmount}
          onChange={onChageModal}
          value={state.CreditLine}
          required
          name="CreditLine"
        />
      </FormGroup3>

      {/* {
                    state.mode === 'create' ? ( */}
      <div>
        <FormGroup3 text="Valid From" col={colX} required>
          <DatePicker
            value={state.StartTermStr}
            onChange={onChageModal}
            name="StartTermStr"
            required
            option={{
              // startDate: "d",
              // daysOfWeekDisabled: "0,6",
              todayHighlight: true,
            }}
          />
        </FormGroup3>

        <FormGroup3 text="Valid To" col={colX} required={false}>
          <DatePicker
            value={state.EndTermStr}
            onChange={onChageModal}
            name="EndTermStr"
            required={false}
            option={{
              // startDate: "d",
              // daysOfWeekDisabled: "0,6",
              todayHighlight: true,
            }}
          />
        </FormGroup3>
      </div>
      {/* ) : null
                } */}

      <br />
      <div>
        <center>
          <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
        </center>
      </div>
    </form>
  </Modal>
);
