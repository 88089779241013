import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import { toFixed, getPermission } from '../../../common/helpper';

const permission = getPermission('Funding&Investment', 'Facility Set up');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onClickOpenModalDelete,
  onClickOpenModalCreditLine,
  onClickOpenModalCreditLineEdit,
  onClickOpenModalAssign,
  onClickOpenModalAssignEdit,
}) => (
  <div>
    <Card textHeader="Credit Line" bgHeader="bg-primary" cardActions={['toggler']}>
      {permission ? (
        <>
          <Col2 col={colX[0]}>
            <Button
              txt="Credit Line"
              icon="plus"
              className="btn-warning"
              onClick={onClickOpenModalCreditLine}
            />
          </Col2>
          <br />
        </>
      ) : null}
      <div className="table-responsive">
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th className="th-white">Cancel</th>
              <th className="th-white">Edit</th>
              <th className="th-white">Credit Line</th>
              <th className="th-white">Outstanding</th>
              <th className="th-white">Vaild From</th>
              <th className="th-white">Valid To</th>
              <th className="th-white">Status</th>
            </tr>
          </thead>
          <tbody>
            {!(state.resultCreditLine || []).length ? (
              <tr>
                <td colSpan="6" align="center">
                  No data available in table
                </td>
              </tr>
            ) : (
              state.resultCreditLine.map((m, i) => (
                <tr key={i}>
                  <td align="center">
                    {!permission ? (
                      ''
                    ) : m.isActive ? (
                      <button
                        className="btn btn-icon btn-default cancel-credit-line"
                        onClick={(e) => onClickOpenModalDelete(e, i, 'credit line')}
                      >
                        <span className="icon icon-close sq-24" />
                      </button>
                    ) : (
                      ''
                    )}
                  </td>
                  <td align="center">
                    {!permission ? (
                      ''
                    ) : m.isActive ? (
                      <button
                        className="btn btn-icon btn-info edit-credit-line"
                        onClick={(e) => onClickOpenModalCreditLineEdit(e, i)}
                      >
                        <span className="icon icon-edit sq-24" />
                      </button>
                    ) : (
                      ''
                    )}
                  </td>
                  <td align="right">{setAmount(m.creditLine, state.CurrencyDigit)}</td>
                  <td align="right">{setAmount(m.outstanding, state.CurrencyDigit)}</td>
                  <td align="center">{m.startTermStr}</td>
                  <td align="center">{m.endTermStr}</td>
                  <td align="center">{setStatus(m.status || 'Active')}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </Card>

    <Card textHeader="Assign" bgHeader="bg-info" cardActions={['toggler']}>
      {permission ? (
        <>
          <Col2 col={colX[0]}>
            <Button
              txt="Assign"
              icon="plus"
              className="btn-warning"
              onClick={onClickOpenModalAssign}
            />
          </Col2>
          <br />
        </>
      ) : null}
      <div className="table-responsive">
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th className="th-white">Cancel</th>
              <th className="th-white">Edit</th>
              <th className="th-white">Assign</th>
              <th className="th-white">Product Type</th>
              <th className="th-white">Valid From</th>
              <th className="th-white">Valid To</th>
              <th className="th-white">Beginning Amount</th>
              <th className="th-white">Outstanding</th>
              <th className="th-white">Used Amount</th>
              <th className="th-white">Status</th>
            </tr>
          </thead>
          <tbody>
            {!(state.resultAssign || []).length ? (
              <tr>
                <td colSpan="10" align="center">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {state.resultAssign.map((m, i) => (
              <tr key={i}>
                <td align="center">
                  {!permission ? (
                    ''
                  ) : !m.used ? (
                    <button
                      className="btn btn-icon btn-default cancel-assign"
                      onClick={(e) => onClickOpenModalDelete(e, i, 'assign')}
                    >
                      <span className="icon icon-close sq-24" />
                    </button>
                  ) : (
                    ''
                  )}
                </td>
                <td align="center">
                  {!permission ? (
                    ''
                  ) : m.isActive ? (
                    <button
                      className="btn btn-icon btn-info edit-credit-line"
                      onClick={(e) => onClickOpenModalAssignEdit(e, i)}
                    >
                      <span className="icon icon-edit sq-24" />
                    </button>
                  ) : (
                    ''
                  )}
                </td>
                <td>{m.assign ? `${m.assign} | ${m.companyName}` : ''}</td>
                <td>{m.productType}</td>
                <td align="center">{m.validFromStr}</td>
                <td align="center">{m.validToStr}</td>
                <td align="right">{setAmount(m.amount, state.CurrencyDigit)}</td>
                <td align="right">{setAmount(m.amount - m.used, state.CurrencyDigit)}</td>
                <td align="right">{setAmount(m.used, state.CurrencyDigit)}</td>
                <td align="center">{setStatus(m.status || 'Active')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  </div>
);

const setAmount = (number, float) => {
  if (!number) return toFixed(0, float);
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};

const setStatus = (status) => {
  if ((status || '').toLowerCase() === 'active')
    return <span className="badge badge-success">{status}</span>;
  return <span className="badge badge-danger">{status}</span>;
};
