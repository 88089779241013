import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_HOLIDAY_CRITERIA = 'EDIT_MASTER_HOLIDAY_CRITERIA';
export const CLEAR_MASTER_HOLIDAY_CRITERIA = 'CLEAR_MASTER_HOLIDAY_CRITERIA';

export const REQUEST_SEARCH_MASTER_HOLIDAY = '@@request/SARCH_MASTER_HOLIDAY';
export const RECEIVE_SEARCH_MASTER_HOLIDAY = '@@receive/SEARCH_MASTER_HOLIDAY';
export const FAIL_SEARCH_MASTER_HOLIDAY = '@@fail/SEARCH_MASTER_HOLIDAY';

export const REQUEST_UPDATE_MASTER_HOLIDAY = '@@request/UPDATE_MASTER_HOLIDAY';
export const RECEIVE_UPDATE_MASTER_HOLIDAY =
  '@@receive-The record was successfully saved/UPDATE_MASTER_HOLIDAY';
export const FAIL_UPDATE_MASTER_HOLIDAY = '@@fail/UPDATE_MASTER_HOLIDAY';

export const REQUEST_UPDATE_MASTER_HOLIDAY_DETAIL = '@@request/UPDATE_MASTER_HOLIDAY_DETAIL';
export const RECEIVE_UPDATE_MASTER_HOLIDAY_DETAIL =
  '@@receive-The record was successfully saved/UPDATE_MASTER_HOLIDAY_DETAIL';
export const FAIL_UPDATE_MASTER_HOLIDAY_DETAIL = '@@fail/UPDATE_MASTER_HOLIDAY_DETAIL';

export const REQUEST_GET_MASTER_HOLIDAY_DETAIL = '@@request/GET_MASTER_HOLIDAY_DETAIL';
export const RECEIVE_GET_MASTER_HOLIDAY_DETAIL = '@@receive/GET_MASTER_HOLIDAY_DETAIL';
export const FAIL_GET_MASTER_HOLIDAY_DETAIL = '@@fail/GET_MASTER_HOLIDAY_DETAIL';

export const REQUEST_CHECK_SCG_HOLODAY = '@@request/CHECK_SCG_HOLODAY';
export const RECEIVE_CHECK_SCG_HOLODAY = '@@receive/CHECK_SCG_HOLODAY';
export const FAIL_CHECK_SCG_HOLODAY = '@@fail/CHECK_SCG_HOLODAY';

export const REQUEST_VIEW_IMPORT_EXCEL_HOLIDAY = '@@request/VIEW_IMPORT_EXCEL_HOLIDAY';
export const RECEIVE_VIEW_IMPORT_EXCEL_HOLIDAY = '@@receive/VIEW_IMPORT_EXCEL_HOLIDAY';
export const FAIL_VIEW_IMPORT_EXCEL_HOLIDAY = '@@fail/VIEW_IMPORT_EXCEL_HOLIDAY';

export const REQUEST_SAVE_IMPORT_EXCEL_HOLIDAY = '@@request/SAVE_IMPORT_EXCEL_HOLIDAY';
export const RECEIVE_SAVE_IMPORT_EXCEL_HOLIDAY = '@@receive-Save Success/SAVE_IMPORT_EXCEL_HOLIDAY';
export const FAIL_SAVE_IMPORT_EXCEL_HOLIDAY = '@@fail/SAVE_IMPORT_EXCEL_HOLIDAY';

export const saveImportHolidayExcel = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveImportHolidayExcel`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_IMPORT_EXCEL_HOLIDAY,
      RECEIVE_SAVE_IMPORT_EXCEL_HOLIDAY,
      FAIL_SAVE_IMPORT_EXCEL_HOLIDAY,
    ],
  },
});

export const viewImportExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint(`/ImportExcel/Calendar`),
    method: 'POST',
    body: form,
    types: [
      REQUEST_VIEW_IMPORT_EXCEL_HOLIDAY,
      RECEIVE_VIEW_IMPORT_EXCEL_HOLIDAY,
      FAIL_VIEW_IMPORT_EXCEL_HOLIDAY,
    ],
  },
});

export const isScgHoliday = (date) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/IsScgHoliday?date=${date}`),
    method: 'GET',
    types: [REQUEST_CHECK_SCG_HOLODAY, RECEIVE_CHECK_SCG_HOLODAY, FAIL_CHECK_SCG_HOLODAY],
  },
});

export const saveHoliday = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/saveholiday`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_UPDATE_MASTER_HOLIDAY,
      RECEIVE_UPDATE_MASTER_HOLIDAY,
      FAIL_UPDATE_MASTER_HOLIDAY,
    ],
  },
});

export const saveHolidayDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/saveholidayDetail`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_UPDATE_MASTER_HOLIDAY_DETAIL,
      RECEIVE_UPDATE_MASTER_HOLIDAY_DETAIL,
      FAIL_UPDATE_MASTER_HOLIDAY_DETAIL,
    ],
  },
});

export const searchHoliday = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getholiday', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_HOLIDAY,
      RECEIVE_SEARCH_MASTER_HOLIDAY,
      FAIL_SEARCH_MASTER_HOLIDAY,
    ],
  },
});

export const getHolidayDetail = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getholidaydeatil', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_HOLIDAY_DETAIL,
      RECEIVE_GET_MASTER_HOLIDAY_DETAIL,
      FAIL_GET_MASTER_HOLIDAY_DETAIL,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_HOLIDAY_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_HOLIDAY_CRITERIA,
  payload: {
    name,
    value,
  },
});
