import { connect } from 'react-redux';
import React, { Component } from 'react';

import MaintainInterest from './MaintainInterest';
import { addAlert } from '../../reducers/layout/action';
import { toLower, toUpperKey } from '../../common/helpper';
import { getInterestRate } from '../../reducers/pnLoan/action';
import { getMaintainInterest, saveMaintainInterest } from '../../reducers/automateInterest/action';

const initData = {
  Group: 'fg',
  FinanceGroupId: '',
  BusinessUnitId: '',
  CompanyCode: '',
  BankCode: '',
  Action: 'create',
  LoanType: 'ONCALL',

  InterestRateIdLoan: '',
  InterestRateLoan: '',
  CalculateMethodLoan: '',
  PeriodEndTypeLoan: '',
  IsManualIncludeLoan: '',
  IsManualMonthEndLoan: '',
  ManualPeriodEndLoan: '',
  ManualDayLoan: '',
  ManualWorkingDayLoan: '',
  DueDateTypeLoan: '',
  IsDueDateMonthEndLoan: '',
  ManualDueDateLoan: '',
  DueDateDayLoan: '',
  DueDateWorkingDayLoan: '',
  FrequencyLoan: '',

  InterestRateIdDeposit: '',
  InterestRateDeposit: '',
  CalculateMethodDeposit: '',
  PeriodEndTypeDeposit: '',
  IsManualIncludeDeposit: '',
  IsManualMonthEndDeposit: '',
  ManualPeriodEndDeposit: '',
  ManualDayDeposit: '',
  ManualWorkingDayDeposit: '',
  DueDateTypeDeposit: '',
  IsDueDateMonthEndDeposit: '',
  ManualDueDateDeposit: '',
  DueDateDayDeposit: '',
  DueDateWorkingDayDeposit: '',
  FrequencyDeposit: '',
};

class MaintainInterestContainer extends Component {
  state = {
    ...initData,
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState({
        ...initData,
      });
    else {
      this.fetchId(id);
    }
  }

  fetchId(id) {
    if (!id) return;

    this.props.getMaintainInterest({ MaintainInterestId: id }).then((response) => {
      if (response.error || !response.payload) return;
      const r = response.payload[0];
      const fetchData = toUpperKey(r);
      this.setState({
        ...fetchData,
        Action: 'edit',

        ManualPeriodEndLoan:
          toLower(r.periodEndTypeLoan) === 'end of term'
            ? ''
            : r.isManualMonthEndLoan
              ? 'Month End'
              : 'Day',
        ManualDueDateLoan:
          toLower(r.dueDateTypeLoan) === 'end of term'
            ? ''
            : r.isDueDateMonthEndLoan
              ? 'Month End'
              : 'Day',
        ManualPeriodEndDeposit:
          toLower(r.periodEndTypeDeposit) === 'end of term'
            ? ''
            : r.isManualMonthEndDeposit
              ? 'Month End'
              : 'Day',
        ManualDueDateDeposit:
          toLower(r.dueDateTypeDeposit) === 'end of term'
            ? ''
            : r.isDueDateMonthEndDeposit
              ? 'Month End'
              : 'Day',
      });
    });
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'Group') {
        if (value === 'fg') {
          stateToUpdate.BusinessUnitId = '';
        } else {
          stateToUpdate.FinanceGroupId = '';
        }
      }

      // loan
      else if (name === 'IsManualIncludeLoan') {
        stateToUpdate.IsManualIncludeLoan = !state.IsManualIncludeLoan;
      } else if (name === 'PeriodEndTypeLoan') {
        if (value === 'End of Term') {
          stateToUpdate.ManualPeriodEndLoan = '';
          stateToUpdate.IsManualMonthEndLoan = false;
          stateToUpdate.ManualDayLoan = '';
        }
      } else if (name === 'ManualPeriodEndLoan') {
        if (value === 'Month End') {
          stateToUpdate.IsManualMonthEndLoan = true;
          stateToUpdate.ManualDayLoan = '';
        } else stateToUpdate.IsManualMonthEndLoan = false;
      } else if (name === 'ManualDayLoan') {
        if (value > 31) stateToUpdate.ManualDayLoan = 31;
        else if (value < 1) stateToUpdate.ManualDayLoan = 1;
      } else if (name === 'DueDateTypeLoan') {
        if (value === 'End of Term') {
          stateToUpdate.ManualDueDateLoan = '';
          stateToUpdate.DueDateDayLoan = '';
          stateToUpdate.IsDueDateMonthEndLoan = false;
        }
      } else if (name === 'ManualDueDateLoan') {
        if (value === 'Month End') {
          stateToUpdate.IsDueDateMonthEndLoan = true;
          stateToUpdate.DueDateDayLoan = '';
        } else stateToUpdate.IsDueDateMonthEndLoan = false;
      } else if (name === 'DueDateDayLoan') {
        if (value > 31) stateToUpdate.DueDateDayLoan = 31;
        else if (value < 1) stateToUpdate.DueDateDayLoan = 1;
      }

      // deposit
      else if (name === 'IsManualIncludeDeposit') {
        stateToUpdate.IsManualIncludeDeposit = !state.IsManualIncludeDeposit;
      } else if (name === 'PeriodEndTypeDeposit') {
        if (value === 'End of Term') {
          stateToUpdate.ManualPeriodEndDeposit = '';
          stateToUpdate.IsManualMonthEndDeposit = false;
          stateToUpdate.ManualDayDeposit = '';
        }
      } else if (name === 'ManualPeriodEndDeposit') {
        if (value === 'Month End') {
          stateToUpdate.IsManualMonthEndDeposit = true;
          stateToUpdate.ManualDayDeposit = '';
        } else stateToUpdate.IsManualMonthEndDeposit = false;
      } else if (name === 'ManualDayDeposit') {
        if (value > 31) stateToUpdate.ManualDayDeposit = 31;
        else if (value < 1) stateToUpdate.ManualDayDeposit = 1;
      } else if (name === 'DueDateTypeDeposit') {
        if (value === 'End of Term') {
          stateToUpdate.ManualDueDateDeposit = '';
          stateToUpdate.DueDateDayDeposit = '';
          stateToUpdate.IsDueDateMonthEndDeposit = false;
        }
      } else if (name === 'ManualDueDateDeposit') {
        if (value === 'Month End') {
          stateToUpdate.IsDueDateMonthEndDeposit = true;
          stateToUpdate.DueDateDayDeposit = '';
        } else stateToUpdate.IsDueDateMonthEndDeposit = false;
      } else if (name === 'DueDateDayDeposit') {
        if (value > 31) stateToUpdate.DueDateDayDeposit = 31;
        else if (value < 1) stateToUpdate.DueDateDayDeposit = 1;
      }

      return stateToUpdate;
    });

    if (name === 'InterestRateIdLoan' && value) {
      this.props.getInterestRate(value).then((response) => {
        const r = response.payload;
        if (!response.error) {
          this.setState({
            ...this.toUpperKeyTempleate(r, 'Loan'),
            ManualPeriodEndLoan:
              r.periodEndType === 'End of Term'
                ? null
                : r.isManualMonthEnd == null
                  ? ''
                  : r.isManualMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDueDateLoan:
              r.dueDateType === 'End of Term'
                ? null
                : r.isDueDateMonthEnd == null
                  ? ''
                  : r.isDueDateMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDayLoan: r.periodEndType === 'End of Term' ? null : r.manualDay,
            DueDateDayLoan: r.dueDateType === 'End of Term' ? null : r.dueDateDay,
            IsManualIncludeLoan: r.isManualInclude == null ? false : r.isManualInclude,
          });
        }
      });
    } else if (name === 'InterestRateIdDeposit' && value) {
      this.props.getInterestRate(value).then((response) => {
        const r = response.payload;
        if (!response.error) {
          this.setState({
            ...this.toUpperKeyTempleate(r, 'Deposit'),
            ManualPeriodEndDeposit:
              r.periodEndType === 'End of Term'
                ? null
                : r.isManualMonthEnd == null
                  ? ''
                  : r.isManualMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDueDateDeposit:
              r.dueDateType === 'End of Term'
                ? null
                : r.isDueDateMonthEnd == null
                  ? ''
                  : r.isDueDateMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDayDeposit: r.periodEndType === 'End of Term' ? null : r.manualDay,
            DueDateDayDeposit: r.dueDateType === 'End of Term' ? null : r.dueDateDay,
            IsManualIncludeDeposit: r.isManualInclude == null ? false : r.isManualInclude,
          });
        }
      });
    }
  };

  toUpperKeyTempleate(obj, type) {
    const newObj = {};
    for (const key in obj) {
      newObj[`${key[0].toUpperCase()}${key.substr(1)}${type}`] = obj[key];
    }

    return newObj;
  }

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitHander = (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formPeriodLoanRef,
      this.formPeriodDepositRef,
    ]);
    if (inValidFormName) return;

    const state = { ...this.state };
    if (state.InterestRateLoan && parseFloat(state.InterestRateLoan) < 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Interest rate loan (%) must more than zero.',
      });
      return;
    }
    if (state.InterestRateLoan > 100) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Interest rate loan (%) must less than or eqaul 100%.',
      });
      return;
    }

    if (state.InterestRateDeposit && parseFloat(state.InterestRateDeposit) < 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Interest rate deposit (%) must more than zero.',
      });
      return;
    }
    if (state.InterestRateDeposit > 100) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Interest rate deposit (%) must less than or eqaul 100%.',
      });
      return;
    }

    this.props.saveMaintainInterest(this.state).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      // if (this.state.TempEffectiveDateStr === 'New') {
      //     window.location.href = `/master/tier-detail?f=${this.state.FinanceGroupId}&b=${this.state.BusinessUnitId}`
      // }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/automate/maintain-interest');
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,

      tabRef: (e) => (this.tabRef = e),
      formDetailRef: (e) => (this.formDetailRef = e),
      formPeriodLoanRef: (e) => (this.formPeriodLoanRef = e),
      formPeriodDepositRef: (e) => (this.formPeriodDepositRef = e),
    };

    return (
      <div>
        <MaintainInterest {...props} state={this.state} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.automateInterest,
  }),
  {
    addAlert,
    saveMaintainInterest,
    getMaintainInterest,
    getInterestRate,
  }
)(MaintainInterestContainer);
