import { connect } from 'react-redux';
import React, { Component } from 'react';

import WithHoldingTax from './WithHoldingTax';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import {
  editCriteria,
  clearCriteria,
  getMasterCompany,
  updateSearchResult,
  searchMasterWithHoldingTax,
  updateMasterWithHoldingTax,
} from '../../reducers/masterWithHoldingTax/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-withholidng-tax';

const initData = {
  ValidFromStr: '',
  NewValidFromStr: '',
  Coa: '',
  CompanyCode: 'All',
  CompanyCountry: '',
  PartnerType: '',
  PartnerCode: 'All',
  PartnerCountry: '',
  ProductType: 'All',
  Gvc: 'All',
  FlowNo: '',
  FlowName: '',
  WhtType: '',
  WhtTypeDescription: '',
  WhtCode: '',
  WhtCodeDescription: '',
  WhtValue: '',
  GlAccount: '',
  Assignment: '',
  Remark: '',
  Text: '',
  Action: 'create',
};

class WithHoldingTaxContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
  }

  componentDidUpdate(prevProps, prevState) {
    const { CompanyCode, PartnerCode } = this.state.modalData;

    if (prevState.modalData.CompanyCode !== CompanyCode) {
      if (CompanyCode === 'All') {
        this.setState({
          modalData: {
            ...this.state.modalData,
            CompanyCountry: '',
          },
        });
      } else {
        this.props.getMasterCompany({ CompanyCode }).then((response) => {
          if (response.error) return;

          const r = response.payload[0];
          this.setState({
            modalData: {
              ...this.state.modalData,
              CompanyCountry: r.countryCode,
            },
          });
        });
      }
    }

    if (prevState.modalData.PartnerCode !== PartnerCode) {
      if (PartnerCode === 'All') {
        this.setState({
          modalData: {
            ...this.state.modalData,
            PartnerCountry: '',
          },
        });
      } else {
        this.props.getMasterCompany({ CompanyCode: PartnerCode }).then((response) => {
          if (response.error) return;

          const r = response.payload[0];
          this.setState({
            modalData: {
              ...this.state.modalData,
              PartnerCountry: r.countryCode,
            },
          });
        });
      }
    }
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMasterWithHoldingTax({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalData = (e) => {
    this.setState(
      {
        modalData: {
          ...initData,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          NewValidFromStr: rowData.validFromStr,
          Action: 'edit',
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;

    if (name === 'PartnerType' && value === 'External') {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [name]: value,
          PartnerCode: 'All',
        },
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [name]: value,
        },
      });
    }
  };

  onClickSaveModalData = (e) => {
    e.preventDefault();
    this.props.updateMasterWithHoldingTax({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      this.props.searchMasterWithHoldingTax({ ...this.props.criteria });
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        ...toUpperKey(rowData),
        Action: 'status',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    this.props.updateMasterWithHoldingTax({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMasterWithHoldingTax({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onChangeModalData: this.onChangeModalData,
      onClickSaveModalData: this.onClickSaveModalData,
      onClickOpenModalData: this.onClickOpenModalData,
    };

    return (
      <div>
        <WithHoldingTax
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalDataRef={(e) => (this.modalDataRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterWithHoldingTax,
  }),
  {
    searchMasterWithHoldingTax,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    updateMasterWithHoldingTax,
    getMasterCompany,
  }
)(WithHoldingTaxContainer);
