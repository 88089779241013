import React from 'react';

import Card from '../../../common/Card';
import { toFixed } from '../../../common/helpper';
import TablePaging from '../../../common/TablePaging';

export default ({ state }) => (
  <Card textHeader="Profile" cardActions={['toggler']}>
    <TablePaging colNumber={9} renderRow={renderRowProfile()} value={state.resultProfile}>
      <thead>
        <tr>
          <th className="th-white">No.</th>
          <th className="th-white">Company Code</th>
          <th className="th-white">Company Name</th>
          <th className="th-white">Product Type</th>
          <th className="th-white">Valid From</th>
          <th className="th-white">Valid To</th>
          <th className="th-white">Amount</th>
          <th className="th-white">Loan No.</th>
          <th className="th-white">Status</th>
        </tr>
      </thead>
    </TablePaging>
  </Card>
);

const renderRowProfile = () => (m, i) => (
  <tr key={i}>
    <td align="center">{i + 1}</td>
    <td align="center">{m.companyCode}</td>
    <td>{m.companyName}</td>
    <td align="center">{m.productType}</td>
    <td align="center">{m.validFromStr}</td>
    <td align="center">{m.validToStr}</td>
    <td align="right">{setAmount(m.amount, 3)}</td>
    <td>{m.loanNo}</td>
    <td align="center">{setStatus(m.status)}</td>
  </tr>
);

const setAmount = (number, float) => {
  if (!number) return toFixed(0, float);
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};

const setStatus = (status) => {
  const s = (status || '').toLowerCase();
  if (s === 'repay' || s === 'withdraw' || s === 'rollover' || s === 'repayment')
    return <span className="label label-info">{status}</span>;
  if (s === 'active') return <span className="badge badge-success">{status}</span>;
  return <span className="badge badge-danger">{status}</span>;
};
