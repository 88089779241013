import React from 'react';

import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import FormGroup3 from '../../../common/FormGroup3';

const colX = ['col-md-4', 'col-md-5'];

export default ({ modalRef, state, onSubmit, onChageModal }) => (
  <Modal textHeader="Issuer" ref={modalRef} bgHeader="bg-primary">
    <form onSubmit={onSubmit} className="form-horizontal">
      <FormGroup3 text="Issuer Name" col={colX} required>
        <input
          className="form-control"
          type="text"
          value={state.IssuerName}
          onChange={onChageModal}
          name="IssuerName"
          required
        />
      </FormGroup3>
      <FormGroup3 text="Country" col={colX} required>
        <input
          className="form-control"
          type="text"
          value={state.IssuerCountry}
          onChange={onChageModal}
          name="IssuerCountry"
          required
        />
      </FormGroup3>

      <br />
      <div>
        <center>
          <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
        </center>
      </div>
    </form>
  </Modal>
);
