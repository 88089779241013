import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_TIER_CRITERIA = 'EDIT_MASTER_TIER_CRITERIA';
export const CLEAR_MASTER_TIER_CRITERIA = 'CLEAR_MASTER_TIER_CRITERIA';

export const REQUEST_SEARCH_MASTER_TIER = '@@request/SEARCH_MASTER_TIER';
export const RECEIVE_SEARCH_MASTER_TIER = '@@receive/SEARCH_MASTER_TIER';
export const FAIL_SEARCH_MASTER_TIER = '@@fail/SEARCH_MASTER_TIER';

export const REQUEST_GET_TIER_EFFECTIVE_DATE = '@@request/GET_TIER_EFFECTIVE_DATE';
export const RECEIVE_GET_TIER_EFFECTIVE_DATE = '@@receive/GET_TIER_EFFECTIVE_DATE';
export const FAIL_GET_TIER_EFFECTIVE_DATE = '@@fail/GET_TIER_EFFECTIVE_DATE';

export const REQUEST_GET_MASTER_TIER_DETAIL = '@@request/GET_MASTER_TIER_DETAIL';
export const RECEIVE_GET_MASTER_TIER_DETAIL = '@@receive/GET_MASTER_TIER_DETAIL';
export const FAIL_GET_MASTER_TIER_DETAIL = '@@fail/GET_MASTER_TIER_DETAIL';

export const REQUEST_SAVE_MASTER_TIER_DETAIL = '@@request/SAVE_MASTER_TIER_DETAIL';
export const RECEIVE_SAVE_MASTER_TIER_DETAIL = '@@receive/SAVE_MASTER_TIER_DETAIL';
export const FAIL_SAVE_MASTER_TIER_DETAIL = '@@fail/SAVE_MASTER_TIER_DETAIL';

export const saveMasterTierDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemastertier`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_TIER_DETAIL,
      RECEIVE_SAVE_MASTER_TIER_DETAIL,
      FAIL_SAVE_MASTER_TIER_DETAIL,
    ],
  },
});

export const getMasterTierDetail = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmastertierdetail/`, criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_TIER_DETAIL,
      RECEIVE_GET_MASTER_TIER_DETAIL,
      FAIL_GET_MASTER_TIER_DETAIL,
    ],
  },
});

export const getMasterTierEffectiveDate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmastertier', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_TIER_EFFECTIVE_DATE,
      RECEIVE_GET_TIER_EFFECTIVE_DATE,
      FAIL_GET_TIER_EFFECTIVE_DATE,
    ],
  },
});

export const searchTier = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmastertier', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_MASTER_TIER, RECEIVE_SEARCH_MASTER_TIER, FAIL_SEARCH_MASTER_TIER],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_TIER_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_TIER_CRITERIA,
  payload: {
    name,
    value,
  },
});
