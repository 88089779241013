import { connect } from 'react-redux';
import React, { Component } from 'react';

import MtmBond from './MtmBond';
import { toUpperKey } from '../../../common/helpper';
import { addAlert } from '../../../reducers/layout/action';
import {
  saveMtmBond,
  editCriteria,
  clearCriteria,
  searchMarkToMarket,
  updateSearchResult,
} from '../../../reducers/mtmBond/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-mtm-bond';

const initData = {
  TransactionNo: '',
  Outstanding: '',
  PricePerUnit: '',
  MtmPrice: '',

  inputMaskAmount: {
    prefix: '',
    digits: 2,
    digitsOptional: false,
    placeholder: '0.00',
  },
  mode: 'create',
};

class MtmBondContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.edit`);
  }

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMarkToMarket({
      ...this.props.criteria,
      Type: 'Bond',
    });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          Mode: 'edit',
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;
    this.setState({
      modalData: {
        ...this.state.modalData,
        [name]: value,
      },
    });
  };

  onClickSaveModalData = (e) => {
    e.preventDefault();
    this.props.saveMtmBond({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      this.props.searchMarkToMarket({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onChangeModalData: this.onChangeModalData,
      onClickSaveModalData: this.onClickSaveModalData,
    };

    return (
      <MtmBond
        {...props}
        {...this.state}
        results={this.props.searchResult}
        criteria={this.props.criteria}
        modalData={this.state.modalData}
        modalDataRef={(e) => (this.modalDataRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.mtmBond,
  }),
  {
    searchMarkToMarket,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    saveMtmBond,
  }
)(MtmBondContainer);
