import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Toggle from '../../common/Toggle';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import { toNumber, toAccount, getPermission } from '../../common/helpper';
import {
  DATATABLE_ID,
  POPOVER_ENDING_BALANCE_CONTEXT,
  POPOVER_ADJUST_BALANCE_CONTEXT,
} from './BankEndingBalanceContainer';
import {
  MASTER_BANK,
  MASTER_BRANCH,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_ACCOUNT_TYPE,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('Cash Management', 'Bank Ending Balance');

const { moment } = window;
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  deferRender: true,
  scroller: true,
  paging: true,
  columns: [
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'bankAccountNo',
      render: (d, r, s) => toAccount(s.bankName, s.branchName, s.accountTypeCode, s.currency, d),
    },
    { data: 'accountTypeName' },
    { data: 'valueDateStr', className: 'text-center td-width-date', type: 'date-black' },
    {
      data: 'endingBalanceFromBank',
      className: 'text-right td-width-number',
      render: (d, t, r, s) => {
        if (
          !moment(r.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add('-2', 'days')) ||
          !permission
        ) {
          if (r.flagNa)
            return `<span style="background-color:#FAF93D">${toNumber(0, r.currencyDigit, r.IsMillion)}</span>`;
          return toNumber(d, r.currencyDigit, r.IsMillion);
        }
        if (r.flagNa)
          return `<span style="background-color:#FAF93D"><a class="editable-click ending_balance" data-toggle="popover">${toNumber(0, r.currencyDigit, r.IsMillion)}</a></span>`;
        return `<a class="editable-click ending_balance" data-toggle="popover">${toNumber(d, r.currencyDigit, r.IsMillion)}</a>`;
      },
    },
    {
      data: 'endingBalanceFromSystem',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
    },
    {
      data: 'different',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
    },
    {
      data: 'beginningBalanceAdjust',
      className: 'text-right td-width-number',
      render: (d, t, r, s) => {
        if (
          !moment(r.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add('-2', 'days')) ||
          !permission
        )
          return toNumber(d, r.currencyDigit, r.IsMillion);
        return `<a class="editable-click adjust_balance" data-toggle="popover">${toNumber(d, r.currencyDigit, r.IsMillion)}</a>`;
      },
    },
    { data: 'currency', className: 'text-center' },
    { data: 'sourceDateToString', className: 'text-center td-width-date', type: 'date-black' },
    { data: 'sourceBy' },
    { data: 'updatedDateStr', className: 'text-center td-width-date', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  results,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,
  onClickUpload,
  onClickFetchEndingBalance,
  onClickOpenPopoverEndingBalance,
  onClickOpenPopoverAdjustBalance,
  isMillion,
  onChangeToggleMillion,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Cash Management <span className="icon icon-angle-double-right" /> Bank Ending Balance
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group">
              <MasterDropdown
                masterType={MASTER_FINANCE}
                value={criteria.FinanceGroups}
                onChange={onChangeSelect2}
                name="FinanceGroups"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                value={criteria.BusinessUnits}
                onChange={onChangeSelect2}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                value={criteria.Companys}
                onChange={onChangeSelect2}
                name="Companys"
                saveLocalState
                financeGroupId={criteria.FinanceGroups}
                businessUnitId={criteria.BusinessUnits}
              />
            </FormGroup2>

            <FormGroup2 text="Bank">
              <MasterDropdown
                masterType={MASTER_BANK}
                value={criteria.Banks}
                onChange={onChangeSelect2}
                name="Banks"
                saveLocalState
                companyCode={criteria.Companys}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Branch">
              <MasterDropdown
                masterType={MASTER_BRANCH}
                value={criteria.Branchs}
                onChange={onChangeSelect2}
                name="Branchs"
                saveLocalState
                bankAbbreviate={criteria.Banks}
                companyCode={criteria.Companys}
              />
            </FormGroup2>
            <FormGroup2 text="Account Type">
              <MasterDropdown
                masterType={MASTER_ACCOUNT_TYPE}
                value={criteria.AccountTypes}
                onChange={onChangeSelect2}
                name="AccountTypes"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Ending Date" required>
              <DateRangePicker
                startDate={window.moment().add(-1, 'days')}
                endDate={window.moment().add(-1, 'days')}
                onChange={onChangeSearch}
                value={criteria.EndingDate}
                name="EndingDate"
                required
              />
            </FormGroup2>
            <FormGroup2 text="Ending Balance (From Bank)">
              <select
                className="form-control"
                value={criteria.EndingBalanceFromBank}
                onChange={onChangeSearch}
                name="EndingBalanceFromBank"
              >
                <option value={null}>---All---</option>
                <option value>Yes</option>
                <option value={false}>No</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Button
                  txt="UPLOAD BANK ENDING BALANCE"
                  icon="upload"
                  className="btn-success"
                  onClick={onClickUpload}
                />
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <div className="row">
        <div className="col-sm-6" style={{ marginBottom: '5px' }}>
          <span>
            <b>Million</b>{' '}
            <Toggle name="isMillion" checked={isMillion} onChange={onChangeToggleMillion} />
          </span>
        </div>
        {permission ? (
          <div className="col-sm-6 text-right">
            <Button
              txt="FETCH ENDING BALANCE"
              icon="undo"
              className="btn-warning"
              onClick={onClickFetchEndingBalance}
            />
          </div>
        ) : null}
      </div>
      <Table
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
        onClickContext={[
          {
            onClick: onClickOpenPopoverEndingBalance,
            context: POPOVER_ENDING_BALANCE_CONTEXT,
          },
          {
            onClick: onClickOpenPopoverAdjustBalance,
            context: POPOVER_ADJUST_BALANCE_CONTEXT,
          },
        ]}
      >
        <thead>
          <tr>
            <th>
              Finance
              <br />
              Group
            </th>
            <th>
              Business
              <br />
              Unit
            </th>
            <th>
              Company
              <br />
              Code
            </th>
            <th>
              Company
              <br />
              Name
            </th>
            <th>
              Bank
              <br />
              Account
            </th>
            <th>
              Account
              <br />
              Type
            </th>
            <th>
              Ending
              <br />
              Date
            </th>
            <th className="text-center">
              Ending
              <br />
              Balance
              <br />
              (From Bank)
            </th>
            <th className="text-center">
              Ending
              <br />
              Balance
              <br />
              (From System)
            </th>
            <th className="text-center">Different</th>
            <th className="text-center">
              Beginning
              <br />
              Balance
              <br />
              (adjusted)
            </th>
            <th>Currency</th>
            <th>
              Source
              <br />
              Data
              <br />
              Date
            </th>
            <th>
              Source
              <br />
              Data
              <br />
              By
            </th>
            <th>
              Updated
              <br />
              Date
            </th>
            <th>
              Updated
              <br />
              By
            </th>
          </tr>
        </thead>
      </Table>
    </Card>
  </div>
);
