import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_DEPOSIT_CRITERIA = 'EDIT_DEPOSIT_CRITERIA';
export const CLEAR_DEPOSIT_CRITERIA = 'CLEAR_DEPOSIT_CRITERIA';

export const UPDATE_SEARCH_RESULT_DEPOSIT = 'UPDATE_SEARCH_RESULT_DEPOSIT';

export const REQUEST_SEARCH_DEPOSIT = '@@request/SARCH_DEPOSIT';
export const RECEIVE_SEARCH_DEPOSIT = '@@receive/SEARCH_DEPOSIT';
export const FAIL_SEARCH_DEPOSIT = '@@fail/SEARCH_DEPOSIT';

export const REQUEST_SAVE_DEPOSIT = '@@request/SAVE_DEPOSIT';
export const RECEIVE_SAVE_DEPOSIT = '@@receive/SAVE_DEPOSIT';
export const FAIL_SAVE_DEPOSIT = '@@fail/SAVE_DEPOSIT';

export const REQUEST_GET_DEPOSIT = '@@request/GET_DEPOSIT';
export const RECEIVE_GET_DEPOSIT = '@@receive/GET_DEPOSIT';
export const FAIL_GET_DEPOSIT = '@@fail/GET_DEPOSIT';

export const REQUEST_GET_MAINTAIN_INTEREST_RATE = '@@request/GET_MAINTAIN_INTEREST_RATE';
export const RECEIVE_GET_MAINTAIN_INTEREST_RATE = '@@receive/GET_MAINTAIN_INTEREST_RATE';
export const FAIL_GET_MAINTAIN_INTEREST_RATE = '@@fail/GET_MAINTAIN_INTEREST_RATE';

export const REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE = '@@request/GET_TEMPLATE_LIST_INTEREST_RATE';
export const RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE = '@@receive/GET_TEMPLATE_LIST_INTEREST_RATE';
export const FAIL_GET_TEMPLATE_LIST_INTEREST_RATE = '@@fail/GET_TEMPLATE_LIST_INTEREST_RATE';

export const REQUEST_PROCESS_CASH_FLOW = '@@request/PROCESS_CASH_FLOW';
export const RECEIVE_PROCESS_CASH_FLOW = '@@receive/PROCESS_CASH_FLOW';
export const FAIL_PROCESS_CASH_FLOW = '@@fail/PROCESS_CASH_FLOW';

export const REQUEST_SAVE_WITH_DRAW = '@@request/SAVE_WITH_DRAW';
export const RECEIVE_SAVE_WITH_DRAW =
  '@@receive-The transaction was successfully saved/SAVE_WITH_DRAW';
export const FAIL_SAVE_WITH_DRAW = '@@fail/SAVE_WITH_DRAW';

export const REQUEST_CHANGE_STATUS_DEPOSIT = '@@request/CHANGE_STATUS_DEPOSIT';
export const RECEIVE_CHANGE_STATUS_DEPOSIT = '@@receive/CHANGE_STATUS_DEPOSIT';
export const FAIL_CHANGE_STATUS_DEPOSIT = '@@fail/CHANGE_STATUS_DEPOSIT';

export const REQUEST_SPLIT_CASH_FLOW = '@@request/SPLIT_CASH_FLOW';
export const RECEIVE_SPLIT_CASH_FLOW =
  '@@receive-The transaction was successfully saved/SPLIT_CASH_FLOW';
export const FAIL_SPLIT_CASH_FLOW = '@@fail/SPLIT_CASH_FLOW';

export const splitCashFlow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/splitcashflow', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SPLIT_CASH_FLOW, RECEIVE_SPLIT_CASH_FLOW, FAIL_SPLIT_CASH_FLOW],
  },
});

export const changeStatus = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/changestatusloan', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CHANGE_STATUS_DEPOSIT,
      RECEIVE_CHANGE_STATUS_DEPOSIT,
      FAIL_CHANGE_STATUS_DEPOSIT,
    ],
  },
});

export const saveWithdraw = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/SaveDepositWithdraw'),
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    method: 'POST',
    types: [REQUEST_SAVE_WITH_DRAW, RECEIVE_SAVE_WITH_DRAW, FAIL_SAVE_WITH_DRAW],
  },
});

export const processCashflow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/processCashFlow'),
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    method: 'POST',
    types: [REQUEST_PROCESS_CASH_FLOW, RECEIVE_PROCESS_CASH_FLOW, FAIL_PROCESS_CASH_FLOW],
  },
});

export const getTemplateInterestRateList = () => ({
  [RSAA]: {
    endpoint: endpoint('/loan/gettemplateinterestlist'),
    method: 'GET',
    types: [
      REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE,
      RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE,
      FAIL_GET_TEMPLATE_LIST_INTEREST_RATE,
    ],
  },
});

export const getInterestRate = (interestRateId) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getinterestrate/${interestRateId}`),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_INTEREST_RATE,
      RECEIVE_GET_MAINTAIN_INTEREST_RATE,
      FAIL_GET_MAINTAIN_INTEREST_RATE,
    ],
  },
});

export const getLoan = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getloan/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_DEPOSIT, RECEIVE_GET_DEPOSIT, FAIL_GET_DEPOSIT],
  },
});

export const saveLoan = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/saveloan'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_DEPOSIT, RECEIVE_SAVE_DEPOSIT, FAIL_SAVE_DEPOSIT],
  },
});

export const searchLoan = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/searchloan', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_DEPOSIT, RECEIVE_SEARCH_DEPOSIT, FAIL_SEARCH_DEPOSIT],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_DEPOSIT,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_DEPOSIT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_DEPOSIT_CRITERIA,
  payload: {
    name,
    value,
  },
});
