import { defaultImportExcelResult } from '../configuration';
import {
  EDIT_TT_REMITTANCE_CRITERIA,
  RECEIVE_SEARCH_TT_REMITTANCE,
  CLEAR_TT_REMITTANCE_CRITERIA,
  RECEIVE_VIEW_UPLOAD_TT_REMITTANCE,
  RECEIVE_SAVE_UPLOAD_TT_REMITTANCE,
  UPDATE_SEARCH_RESULT_TT_REMITTANCE,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  Banks: [''],
  Currencies: [''],
  PreAdvice: '',
  ValueDateStr: '',
  ConfirmDateStr: '',
  Status: '',
  BankStatus: '',
  TransferMethod: '',
  Equivalent: '',
  PaidFor: '',
  PoNo: '',
  InvoiceNo: '',
  ReferenceNo: '',
};
const init = {
  searchResult: [],
  importExcel: defaultImportExcelResult,
  criteria: {
    ...initCriteria,
  },
};

export default (state = init, action) => {
  switch (action.type) {
    case CLEAR_TT_REMITTANCE_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_TT_REMITTANCE_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SAVE_UPLOAD_TT_REMITTANCE:
      return {
        ...state,
        importExcel: {
          ...defaultImportExcelResult,
        },
      };
    case RECEIVE_VIEW_UPLOAD_TT_REMITTANCE:
      return {
        ...state,
        importExcel: action.payload,
      };
    case RECEIVE_SEARCH_TT_REMITTANCE:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    case UPDATE_SEARCH_RESULT_TT_REMITTANCE:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    default:
      return state;
  }
};
