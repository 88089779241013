import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import { getPermission } from '../../common/helpper';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_BANK } from '../../reducers/master/action';
import MasterDropdownUI from '../../common/MasterDropdownUI';

const permission = getPermission('Master Finance', 'Finance Group');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colXModal = ['col-md-4', 'col-md-7'];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  onClickAdd,
  onClickEdit,
  onClickDelete,
  modalFormRef,
  modalDeleteRef,
  modalForm,
  onDelete,
  onChangeModal,
  onSaveModal,
}) => {
  const textHeader = state.mode === 'create' ? 'Create Finance Group' : 'Edit Finance Group';
  const modeEdit = state.mode === 'edit';
  const noEdit = !permission ? true : state.mode === 'create' ? false : !state.IsActive;

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" />
            <Link txt="Finance Group" href="/master/finance-group" />
            <span className="icon icon-angle-double-right" /> Finance Group Detail
            {modeEdit ? (
              <>
                {' '}
                <span className="icon icon-angle-double-right" /> {state.FinanceGroupId}{' '}
                <span className="icon icon-angle-double-right" />{' '}
                {state.IsActive ? 'Active' : 'Inactive'}
              </>
            ) : null}
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card
          textHeader={textHeader}
          bgHeader="bg-primary"
          cardActions={['toggler']}
          footer={
            noEdit ? (
              ''
            ) : (
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={!state.IsActive && state.mode === 'edit'}
              />
            )
          }
        >
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group ID" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={modeEdit}
                  maxLength={3}
                  value={state.FinanceGroupId || ''}
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Finance Group Short Name" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  maxLength={20}
                  value={state.FinanceGroupAbbreviate || ''}
                  name="FinanceGroupAbbreviate"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group Name" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.FinanceGroupName || ''}
                  name="FinanceGroupName"
                />
              </FormGroup2>

              <FormGroup2 text="Sending Type" required>
                <MasterDropdownUI
                  value={state.SendingType}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  name="SendingType"
                  isChoose
                  required
                  notMultipleSelect2
                  disabled={noEdit}
                  options={['SFTP', 'Paper', 'Paper with Signature', 'N/A']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[2]}>
              <FormGroup2 text="Email CC" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.EmailCc || ''}
                  name="EmailCc"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[2]}>
              <FormGroup2 text="Email CC [BG]" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.EmailCcBg || ''}
                  name="EmailCcBg"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[2]}>
              <FormGroup2 text="Email CC [Quote Rate]" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.EmailCcQuoteRate || ''}
                  name="EmailCcQuoteRate"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[2]}>
              <FormGroup2 text="Email CC [Cheque]" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.EmailCcCheque || ''}
                  name="EmailCcCheque"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[2]}>
              <FormGroup2 text="Email Finance Group" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.EmailFinanceGroup || ''}
                  name="EmailFinanceGroup"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="Email FX Matching" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.EmailFxMatching || ''}
                  name="EmailFxMatching"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Finance Group Bank" bgHeader="bg-primary" cardActions={['toggler']}>
              {!noEdit && (
                <div style={{ marginBottom: 10 }}>
                  <Button
                    txt="Add Bank"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickAdd}
                    disabled={false}
                  />
                </div>
              )}
              <div className="table-responsive">
                <table className="table table-bordered table-nowrap">
                  <thead>
                    <tr>
                      {!noEdit && <th className="th-white">Action</th>}
                      {!noEdit && <th className="th-white">Edit</th>}
                      <th className="th-white">Bank</th>
                      <th className="th-white">Type</th>
                      <th className="th-white">Email To</th>
                      <th className="th-white">Email CC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!(state.BankGroup || []).length ? (
                      <tr>
                        <td colSpan={noEdit ? 4 : 6} align="center">
                          No data available in table
                        </td>
                      </tr>
                    ) : (
                      state.BankGroup.map((m, i) => (
                        <tr key={i}>
                          {!noEdit && (
                            <td align="center">
                              {!permission ? (
                                ''
                              ) : (
                                <button
                                  className="btn btn-icon btn-warning"
                                  type="button"
                                  onClick={(e) => onClickDelete(e, m)}
                                >
                                  <span className="icon icon-close sq-24" />
                                </button>
                              )}
                            </td>
                          )}
                          {!noEdit && (
                            <td align="center">
                              {!permission ? (
                                ''
                              ) : (
                                <button
                                  className="btn btn-icon btn-info"
                                  type="button"
                                  onClick={(e) => onClickEdit(e, m)}
                                >
                                  <span className="icon icon-edit sq-24" />
                                </button>
                              )}
                            </td>
                          )}
                          <td align="center">{m.bankCode}</td>
                          <td align="center">{m.type}</td>
                          <td align="left">{m.emailTo}</td>
                          <td align="left">{m.emailCc}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </Col2>
        </Card>
      </form>

      <ModalFunction
        textHeader="Delete"
        textContent="a Delete"
        modalRef={modalDeleteRef}
        type="danger"
        icon="icon-trash-o"
        textBtn="Delete"
        noSelect
        onClick={onDelete}
      />

      <Modal textHeader="Bank" ref={modalFormRef} bgHeader="bg-primary">
        <form onSubmit={onSaveModal} className="form-horizontal">
          <FormGroup3 text="Bank" col={colXModal} required>
            <MasterDropdown
              masterType={MASTER_BANK}
              isChoose
              notMultipleSelect2
              value={modalForm.bankCode}
              onChange={(e) =>
                onChangeModal({
                  target: e,
                })
              }
              // filter={m => {
              //     return state.BankGroup.map(m => m.bankCode).indexOf(m.bankCode) < 0
              // }}
              noValidateOption
              saveLocalState
              required
              name="bankCode"
            />
          </FormGroup3>

          <FormGroup3 text="Type" col={colXModal} required>
            <MasterDropdownUI
              isChoose
              notMultipleSelect2
              onChange={(e) =>
                onChangeModal({
                  target: e,
                })
              }
              value={modalForm.type}
              required
              name="type"
              options={['POA']}
            />
          </FormGroup3>

          <FormGroup3 text="Email To" col={colXModal} required>
            <textarea
              className="form-control"
              rows="2"
              onChange={onChangeModal}
              value={modalForm.emailTo}
              required
              name="emailTo"
            />
          </FormGroup3>

          <FormGroup3 text="Email CC" col={colXModal} required={false}>
            <textarea
              className="form-control"
              rows="2"
              onChange={onChangeModal}
              value={modalForm.emailCc}
              required={false}
              name="emailCc"
            />
          </FormGroup3>

          <br />
          <div>
            <center>
              <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>
    </div>
  );
};
