import { connect } from 'react-redux';
import React, { Component } from 'react';

import { toUpperKey } from '../../common/helpper';
import PrintInterestReceiptType from './PrintInterestReceiptType';
import {
  searchInterestReceiptType,
  submitInterestReceiptType,
} from '../../reducers/printInterestReceiptType/action';

const $ = window.jQuery;
const defaultCreateState = {
  ValidFromStr: '',
  MasterCompanyCode: '',
  MemberCompanyCode: '',
  Id: null,
  MaintainId: null,
};

const defaultCriteria = {
  MasterCompanyCode: '',
  MemberCompanyCode: '',
  AsOf: '',
  IsActive: null,
};
class PrintInterestReceiptTypeContainer extends Component {
  state = {
    searchResults: [],
    maintainData: {
      ...defaultCreateState,
    },
    criteria: {
      ...defaultCriteria,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.edit`, this.onClickColumnEdit);
    $('body').on('click', `button.status`, this.onClickColumnToggleActive);
  }

  search() {
    this.props.dispatch(searchInterestReceiptType(this.state.criteria)).then((response) => {
      if (response.error) return;
      this.setState({
        searchResults: response.payload || [],
      });
    });
  }

  componentWillUnmount() {
    $('body').off('click', `button.edit`);
  }

  onClickColumnToggleActive = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    if (!rowData) return;

    this.props
      .dispatch(
        submitInterestReceiptType({
          ...rowData,
          isActive: !rowData.isActive,
        })
      )
      .then((response) => {
        if (response.error) return;

        rowData.isActive = !rowData.isActive;
        $dt.row(row).invalidate().draw(false);
      });
  };

  onClickColumnEdit = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    if (!rowData) return;

    this.setState(
      (state) => ({
        maintainData: {
          ...defaultCreateState,
          ...toUpperKey(rowData),
        },
      }),
      this.modalMaintainRef.open
    );
  };

  onChangeCriteria = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      criteria: {
        ...state.criteria,
        [name]: value,
      },
    }));
  };

  onClickOpenModalCreate = () => {
    this.setState(
      {
        maintainData: {
          ...defaultCreateState,
        },
      },
      this.modalMaintainRef.open
    );
  };

  onChangeMaintainData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      maintainData: {
        ...state.maintainData,
        [name]: value,
      },
    }));
  };

  onSubmitMaintainFrom = (e) => {
    e.preventDefault();

    this.props.dispatch(submitInterestReceiptType(this.state.maintainData)).then((response) => {
      if (response.error) return;

      this.modalMaintainRef.close();
      this.search();
    });
  };

  onSubmitSearchForm = (e) => {
    e.preventDefault();
    this.search();
  };

  render() {
    return (
      <PrintInterestReceiptType
        {...this.state}
        onChangeCriteria={this.onChangeCriteria}
        modalMaintainRef={(e) => (this.modalMaintainRef = e)}
        onClickOpenModalCreate={this.onClickOpenModalCreate}
        dataTableRef={(e) => (this.dataTableSearchResultRef = e)}
        onChangeMaintainData={this.onChangeMaintainData}
        onSubmitMaintainFrom={this.onSubmitMaintainFrom}
        onSubmitSearchForm={this.onSubmitSearchForm}
      />
    );
  }
}
export default connect()(PrintInterestReceiptTypeContainer);
