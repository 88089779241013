import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import TableCashFlow from './TableCashFlow';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_COMPANY, MASTER_BANK_CODE } from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  formCashFlowLoanRef,
  formCashFlowDepositRef,
  formResultCashFlowLoanRef,
  formResultCashFlowDepositRef,
  state,
  onChangeInputData,

  onChangeInputTable,
  onChangeInputHeader,

  onClickOpenModalCharge,
  viewOnly,
}) => (
  <>
    {state.Source !== 'Deposit' ? (
      <Card textHeader="Loan" bgHeader="bg-primary" cardActions={['toggler']}>
        {state.permission && !viewOnly ? (
          <>
            <Col2 col={colX[0]}>
              <Button
                txt="Add Charge"
                icon="plus"
                className="btn-warning"
                onClick={(e) => onClickOpenModalCharge(e, 'loan')}
                disabled={viewOnly}
              />
            </Col2>
            <br />
          </>
        ) : null}

        <form ref={formCashFlowLoanRef} name="cashflow">
          <Col2 col={colX[2]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank" required>
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  isGetAll
                  customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                  companyCode={state.CompanyByLoan}
                  disabled={viewOnly}
                  value={state.BankCodeLoanCharge}
                  name="BankCodeLoanCharge"
                />
              </FormGroup2>
              {state.PartnerType === 'Intercompany' &&
              state.TransactionType &&
              state.CompanyByLoan ? (
                <FormGroup2 text="Company for Loan">
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    saveLocalState
                    onChange={onChangeInputData}
                    notMultiple
                    disabled
                    isChoose
                    value={state.CompanyByLoan}
                    name="CompanyByLoan"
                  />
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>
        </form>

        <TableCashFlow
          state={state.resultLoanCharge}
          onChangeInputTable={onChangeInputTable}
          resultType="loan"
          BankCode={state.BankCodeLoanCharge}
          CompanyCode={state.CompanyByLoan}
          PartnerType={state.PartnerType}
          isBen
          isCharge
          isEndOfTerms={false}
          formRef={formResultCashFlowLoanRef}
          formName="charge"
          onChangeInputHeader={onChangeInputHeader}
          bankAccountNoAll={state.AccountNoAllLoanCharge}
          paymentMethodAll={state.PaymentMethodAllLoanCharge}
          permission={state.permission}
          viewOnly={viewOnly}
        />
      </Card>
    ) : null}

    {state.PartnerType !== 'External' || state.Source === 'Deposit' ? (
      <Card textHeader="Deposit" bgHeader="bg-info" cardActions={['toggler']}>
        {state.Source !== 'Deposit' && state.permission && !viewOnly ? (
          <>
            <Col2 col={colX[0]}>
              <Button
                txt="Add Charge"
                icon="plus"
                className="btn-warning"
                onClick={(e) => onClickOpenModalCharge(e, 'deposit')}
                disabled={viewOnly}
              />
            </Col2>
            <br />
          </>
        ) : null}

        <form ref={formCashFlowDepositRef} name="cashflow">
          <Col2 col={colX[2]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank" required>
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  noValidateOption
                  saveLocalState={state.PartnerType === 'External'}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  isGetAll
                  customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                  companyCode={state.CompanyByDeposit}
                  disabled={viewOnly}
                  value={state.BankCodeDepositCharge}
                  name="BankCodeDepositCharge"
                />
              </FormGroup2>
              {state.PartnerType === 'Intercompany' &&
              state.TransactionType &&
              state.CompanyByDeposit ? (
                <FormGroup2 text="Company for Deposit">
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    saveLocalState
                    onChange={onChangeInputData}
                    notMultiple
                    disabled
                    isChoose
                    value={state.CompanyByDeposit}
                    name="CompanyByDeposit"
                  />
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>
        </form>

        <TableCashFlow
          state={state.resultDepositCharge}
          onChangeInputTable={onChangeInputTable}
          resultType="deposit"
          BankCode={state.BankCodeDepositCharge}
          CompanyCode={state.CompanyByDeposit}
          PartnerType={state.PartnerType}
          isBen={false}
          isCharge
          isEndOfTerms={false}
          formRef={formResultCashFlowDepositRef}
          formName="charge"
          onChangeInputHeader={onChangeInputHeader}
          bankAccountNoAll={state.AccountNoAllDepositCharge}
          paymentMethodAll={state.PaymentMethodAllDepositCharge}
          permission={state.permission}
          viewOnly={viewOnly}
        />
      </Card>
    ) : null}
  </>
);
