import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import { DATATABLE_ID } from './IssuerContainer';
import FormGroup2 from '../../../common/FormGroup2';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_COUNTRY } from '../../../reducers/master/action';
import { toStatus, getPermission } from '../../../common/helpper';

const permission = getPermission('Master Finance', 'Issuer');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    { data: 'issuerName', render: (d) => `<a href="#" class="config-issuer">${d}</a>` },
    { data: 'issuerCountry', className: 'text-center' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  results,
  dataTableRef,
  onChangeSearch,
  onClickSearch,

  modalData,
  modalRef,
  onClickOpenModal,
  onChangeModal,
  onClickSaveModal,

  modalActiveRef,
  modalInactiveRef,
  onClickOpenModalChangeStatus,
  onSubmitModalChangeStatus,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Master Finance <span className="icon icon-angle-double-right" /> Issuer
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Status">
              <select
                className="form-control"
                value={criteria.Status}
                onChange={onChangeSearch}
                name="Status"
              >
                <option value="">---All---</option>
                <option value="Active">Active</option>
                <option value="InActive">Inactive</option>
              </select>
            </FormGroup2>
            <FormGroup2 text="&nbsp;">
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              {permission ? (
                <>
                  &nbsp;
                  <Button
                    txt="CREATE ISSUER"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={(e) => onClickOpenModal(e, 'create')}
                  />
                </>
              ) : null}
            </FormGroup2>
          </Col2>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
        onClickContext={[
          {
            context: 'button.active',
            onClick: (e) => onClickOpenModalChangeStatus(e, 'Active'),
          },
          {
            context: 'button.inactive',
            onClick: (e) => onClickOpenModalChangeStatus(e, 'Inactive'),
          },
          {
            context: 'a.config-issuer',
            onClick: onClickOpenModal,
          },
        ]}
      >
        <thead>
          <tr>
            <th>Action</th>
            <th>Issuer Name</th>
            <th>Country</th>
            <th>Status</th>
            <th>
              Created
              <br /> By
            </th>
            <th>
              Created
              <br /> Date
            </th>
            <th>
              Updated
              <br /> By
            </th>
            <th>
              Updated
              <br /> Date
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalFunction
      textHeader="Active"
      textContent="active"
      modalRef={modalActiveRef}
      type="info"
      icon="icon-refresh"
      textBtn="Active"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
    />

    <ModalFunction
      textHeader="InActive"
      textContent="inactive"
      modalRef={modalInactiveRef}
      type="danger"
      icon="icon-close"
      textBtn="Yes"
      textBtnBack="No"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
    />

    <Modal
      textHeader="Configure Data [Issuer]"
      bgHeader="bg-primary"
      size="modal-lg"
      ref={modalRef}
    >
      <form onSubmit={onClickSaveModal}>
        <Card
          textHeader={modalData.issuerNameHeader}
          bgHeader="bg-warning"
          cardActions={['toggler']}
          footer={
            !permission ? (
              ''
            ) : (
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            )
          }
        >
          <Col2 col={colX[0]}>
            <FormGroup2 text="Country" required>
              <MasterDropdown
                masterType={MASTER_COUNTRY}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeModal({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                required
                disabled={false}
                value={modalData.issuerCountry || ''}
                name="issuerCountry"
              />
            </FormGroup2>

            <FormGroup2 text="Issuer Name" required>
              <input
                className="form-control"
                type="text"
                onChange={onChangeModal}
                required
                value={modalData.issuerName}
                name="issuerName"
              />
            </FormGroup2>
          </Col2>
        </Card>
      </form>
    </Modal>
  </div>
);
