import React, { Fragment } from 'react';

import Tab from '../../common/Tab';
import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Select2 from '../../common/Select2';
import Table from '../../common/DataTable';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import ButtonDropdown from '../../common/ButtonDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import { DATATABLE_ID } from './ExposurePositionContainer';
import MasterDropdownUI, { MASTER_EXPOSURE_TYPE } from '../../common/MasterDropdownUI';
import {
  toBank,
  toLower,
  toStatus,
  toNumber,
  toAccount,
  getPermissionStr,
} from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_ACCOUNT,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permissionStr = getPermissionStr('FX Management', 'Exposure Position');
const permission = toLower(permissionStr) === 'all';
const viewInward = toLower(permissionStr) === 'view (inward)';
const viewOutward = toLower(permissionStr) === 'view (outward)';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  criteria,
  results,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  onClickUploadExcelInward,

  dataTableRef,
  onChangeTab,
  allSearchResult,

  modalEdit,
  modalEditRef,
  onClickOpenModalEdit,
  onChangeModalEdit,
  onClickSubmitModalEdit,
  modalEditResultRef,

  modalSplitRef,
  modalSplit,
  onClickAddSplit,
  onSubmitModalSplit,

  modalSpotRateRef,
  modalConfirmMethodRef,
  modalConfirmQuoteRateRef,
  modalWithdrawRef,
  modalMailRef,
  onClickOpenModalChangeStatus,
  onClickModalSubmitChangeStatus,
  conditionChangeStatus,
  onClickModalSubmitWithdraw,

  onClickFetchEmailQuoteRate,
  modalExportQuoteRateRef,
  onClickExportExcelQuoteRate,
  onClickUploadExcelQuoteRate,
  onChangeSplitAmount,

  onClickForwardRate,

  modalExchangeRateRef,
  modalExchangeRate,
  onClickOpenModalExchangeRate,
  onChangeModalExchangeRate,
  onSubmitExchangeRate,

  modalExportSpotSummaryRef,
  modalExportSpotSummary,
  onClickOpenModalExportSpotSummary,
  onChangeModalExportSpotSummary,
  onSubmitModalExportSpotSummary,

  modalUndoRef,
  onClickConfirmModalUndo,

  onClickOpenModalUploadCreditDedit,
  onClickOpenModalExchangeRateSelected,

  onClickManualAutoIncome,
  onClickSubmitManual,
  modalManualRef,

  onClickAutoMate,
}) => {
  const setDisabledValueDate = !(
    criteria.ValueDateStr !== '' ||
    (criteria.ValueDateStr === '' && criteria.InformDateStr === '')
  );
  const setDisabledInformDate = !(
    criteria.InformDateStr !== '' ||
    (criteria.ValueDateStr === '' && criteria.InformDateStr === '')
  );

  const selectedResultCount = results.filter((f) => f.IsSelected).length;
  const selectedSpotRateLength = conditionChangeStatus.spot(results).length;
  const selectedConfirmMethodLength = conditionChangeStatus.confimMethod(results).length;
  const selectedConfirmQuoteRateLength = conditionChangeStatus.confirmQuoteRate(results).length;
  const selectedWithdrawLength = conditionChangeStatus.withDraw(results).length;
  const selectedMailLength = conditionChangeStatus.mail(results).length;
  const selectedExportQuoteRateLength = conditionChangeStatus.exportQuoteRate(results).length;

  const tabContents = [];
  // start tab1
  const headerAllTab = (
    <span>
      All <span className="badge badge-default">{allSearchResult.length}</span>
    </span>
  );
  const allTab = {
    header: headerAllTab,
    tabName: 'all',
  };
  tabContents.push(allTab);

  // tab2
  const headerFxTab = (
    <span>
      FX Management{' '}
      <span className="badge badge-default">
        {allSearchResult.filter((f) => f.sourceType === 'FX Management').length}
      </span>
    </span>
  );
  const fxTab = {
    header: headerFxTab,
    tabName: 'fx',
  };
  tabContents.push(fxTab);

  // tab3
  const headerLoanTab = (
    <span>
      Loan & Investment{' '}
      <span className="badge badge-default">
        {allSearchResult.filter((f) => f.sourceType === 'Loan & Investment').length}
      </span>
    </span>
  );
  const loanTab = {
    header: headerLoanTab,
    tabName: 'loan',
  };
  tabContents.push(loanTab);

  // tab4
  const headerTtTab = (
    <span>
      T/T Remittance{' '}
      <span className="badge badge-default">
        {allSearchResult.filter((f) => f.sourceType === 'T/T Remittance').length}
      </span>
    </span>
  );
  const ttTab = {
    header: headerTtTab,
    tabName: 'tt',
  };
  tabContents.push(ttTab);

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" /> Exposure Position
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  // isChoose={true}
                  required
                  value={criteria.FinanceGroup}
                  name="FinanceGroup"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  name="Companies"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroup}
                  businessUnitId={criteria.BusinessUnits}
                />
              </FormGroup2>

              <FormGroup2 text="Inform Date" required={!setDisabledInformDate}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  // disabled={setDisabledInformDate}
                  required={!setDisabledInformDate}
                  value={criteria.InformDateStr}
                  name="InformDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.Currencies}
                  name="Currencies"
                />
              </FormGroup2>

              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.Banks}
                  companyCode={criteria.Companies}
                  name="Banks"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Exposure Type">
                <MasterDropdownUI
                  masterType={MASTER_EXPOSURE_TYPE}
                  value={criteria.ExposureType}
                  onChange={onChangeSelect2}
                  name="ExposureType"
                  options={null}
                />
              </FormGroup2>

              <FormGroup2 text="Value Date" required={!setDisabledValueDate}>
                <DateRangePicker
                  // startDate={window.moment()}
                  // endDate={window.moment().add(1,'years')}
                  onChange={onChangeSearch}
                  // disabled={setDisabledValueDate}
                  required={!setDisabledValueDate}
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Cash Type">
                <MasterDropdownUI
                  value={criteria.CashTypes}
                  onChange={onChangeSelect2}
                  name="CashTypes"
                  disabled={viewInward || viewOutward}
                  options={['Inward', 'Outward']}
                />
              </FormGroup2>

              <FormGroup2 text="Transaction Type">
                <MasterDropdownUI
                  value={criteria.TransactionTypes}
                  onChange={onChangeSelect2}
                  name="TransactionTypes"
                  options={['Plan', 'From Bank', 'T/T']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Source Type">
                <MasterDropdownUI
                  value={criteria.SourceType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="SourceType"
                  options={['FX Management', 'Loan & Investment', 'T/T Remittance']}
                />
              </FormGroup2>

              <FormGroup2 text="Quote Rate Method">
                <MasterDropdownUI
                  value={criteria.QuoteRateMethods}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="QuoteRateMethods"
                  options={['Spot', 'Forward', 'No Quote']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <MasterDropdownUI
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="Status"
                  options={[
                    'Wait for select method',
                    'Wait for quote rate',
                    'Already send To bank',
                    'Completed',
                    'Cancelled',
                  ]}
                />
              </FormGroup2>

              <FormGroup2 text="Payment Type">
                <Select2 value={criteria.PaymentType} onChange={onChangeSelect2} name="PaymentType">
                  <option key="" value="">
                    ---Choose---
                  </option>
                  <option key="DA" value="DA">
                    DA
                  </option>
                  <option key="DP" value="DP">
                    DP
                  </option>
                  <option key="LC" value="LC">
                    LC
                  </option>
                  <option key="Auto Deduct" value="Auto Deduct">
                    Auto Deduct
                  </option>
                  <option key="TT" value="TT">
                    T/T
                  </option>
                </Select2>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="UPLOAD EXCEL INWARD"
                    icon="upload"
                    className="btn-success"
                    onClick={onClickUploadExcelInward}
                  />
                  &nbsp;
                  <Button
                    txt="Export Spot Summary"
                    icon="credit-card-alt"
                    className="btn-default"
                    onClick={onClickOpenModalExportSpotSummary}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
              {permission || viewInward ? (
                <>
                  &nbsp;
                  <Button
                    txt="Manual Auto Income"
                    icon="asterisk"
                    className="btn-warning"
                    onClick={onClickManualAutoIncome}
                  />
                </>
              ) : null}
              &nbsp;
              <Button
                disabled={!results.filter((f) => f.IsSelected).length}
                txt="Automate"
                icon="asterisk"
                className="btn-warning"
                onClick={onClickAutoMate}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <>
            <Col2 col={colX[2]}>
              <div>
                <ButtonDropdown
                  className="btn-info"
                  dropdownMenu="left"
                  text="Edit"
                  icon="icon-edit"
                  buttons={[
                    {
                      text: 'Bank',
                      icon: 'icon-university',
                      onClick: (e) => onClickOpenModalEdit('change bank account'),
                    },
                    {
                      text: 'Value Date',
                      icon: 'icon-calendar',
                      onClick: (e) => onClickOpenModalEdit('change value date'),
                    },
                  ]}
                />
                &nbsp;
                <ButtonDropdown
                  className="btn-blank"
                  text="Quote Rate Method"
                  icon="icon-credit-card-alt"
                  buttons={[
                    {
                      text: 'Spot Rate',
                      icon: 'icon-credit-card-alt',
                      onClick: (e) => onClickOpenModalChangeStatus('Spot Rate'),
                    },
                    {
                      text: 'Forward Rate',
                      icon: 'icon-credit-card-alt',
                      onClick: onClickForwardRate,
                    },
                  ]}
                />
                &nbsp;
                <Button
                  txt="Withdraw"
                  icon="refresh"
                  className="btn-warning"
                  onClick={(e) => onClickOpenModalChangeStatus('Withdraw')}
                />
                &nbsp;
                <ButtonDropdown
                  className="btn-lightbrown"
                  text="Excel quote rate"
                  icon="icon-credit-card-alt"
                  buttons={[
                    {
                      text: 'Fetch Email',
                      icon: 'icon-undo',
                      onClick: onClickFetchEmailQuoteRate,
                    },
                    {
                      text: 'Export Excel',
                      icon: 'icon-credit-card-alt',
                      onClick: (e) => {
                        onClickOpenModalChangeStatus('Export Quote Rate');
                      },
                    },
                    {
                      text: 'Upload Excel',
                      icon: 'icon-upload',
                      onClick: onClickUploadExcelQuoteRate,
                    },
                    {
                      text: 'Send Email to Bank',
                      icon: 'icon-envelope-o',
                      onClick: (e) => onClickOpenModalChangeStatus('Send Email to Bank'),
                    },
                  ]}
                />
                &nbsp;
                <Button
                  txt="Upload Credit/Dedit"
                  icon="upload"
                  className="btn-purple"
                  onClick={onClickOpenModalUploadCreditDedit}
                />
                &nbsp;
                <Button
                  txt="Key Exchange Rate"
                  icon="edit"
                  className="btn-info"
                  onClick={onClickOpenModalExchangeRateSelected}
                />
              </div>
            </Col2>
            <br />
          </>
        ) : null}
        <Tab tabs={tabContents} onChangeTab={onChangeTab} visualTab>
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={optAll}
            value={results}
            onClickContext={[
              {
                context: 'a.a-exchange-rate',
                onClick: onClickOpenModalExchangeRate,
              },
            ]}
          >
            <thead>
              <tr>
                <th>
                  Choose By <br />
                  Expos. No.
                </th>
                <th>
                  Choose <label className="label-checkbox fix choose-all" />
                </th>
                <th>Edit</th>
                <th>Exposure No.</th>
                <th>Req. Pmt. Ref</th>
                <th>
                  Debit/Credit
                  <br /> Advice
                </th>
                <th>Split</th>
                <th>Undo</th>
                <th>
                  Quote Rate
                  <br /> Method
                </th>
                <th>Forward Contract No.</th>
                <th>Exchange Rate</th>
                <th>Line ID</th>
                <th>
                  Finance
                  <br /> Group
                </th>
                <th>
                  Business
                  <br /> Unit
                </th>
                <th>
                  Company
                  <br /> Code
                </th>
                <th>
                  Company
                  <br /> Name
                </th>
                <th>Source</th>
                <th>
                  Vendor/Customer
                  <br /> Name
                </th>
                <th>
                  Value
                  <br /> Date
                </th>
                <th>
                  Inform
                  <br /> Date
                </th>
                <th>
                  Cash
                  <br /> Type
                </th>
                <th>
                  Transaction
                  <br /> Type
                </th>
                <th>
                  Transaction
                  <br /> Details
                </th>
                <th>Swift Code</th>
                <th>Bank</th>
                <th>
                  Bank
                  <br /> Account
                </th>
                <th className="text-center">
                  Amount
                  <br /> (Quote Rate)
                </th>
                <th className="text-center">Amount</th>
                <th className="text-center">Invoice Amount</th>
                <th>Currency</th>
                <th>Equivalent</th>
                <th>Invoice No.</th>
                <th>Payment Type</th>
                <th>Exposure Type</th>
                <th>Status</th>
                <th>Project Name</th>
                <th>Reference No.</th>
                <th>Q2O No.</th>
                <th>Bank Remark</th>
                <th>Bank File Name</th>
                <th>Sent Auto Incoming</th>
                <th>Error Message</th>
                <th>Department</th>
                <th>Payment Term</th>
                <th>
                  Created
                  <br /> Date
                </th>
                <th>
                  Created
                  <br /> By
                </th>
                <th>
                  Updated
                  <br /> Date
                </th>
                <th>
                  Updated
                  <br /> By
                </th>
              </tr>
            </thead>
          </Table>
        </Tab>
      </Card>

      <Modal size="modal-md" ref={modalEditRef} modalFooter>
        <form onSubmit={onClickSubmitModalEdit} className="form-horizontal">
          <div className="text-center">
            {modalEdit.Action !== 'edit' ? (
              <span className="label label-info">{selectedResultCount} items</span>
            ) : null}
            <h2 className="text-info">
              {modalEdit.Action === 'edit'
                ? 'EDIT'
                : modalEdit.Action === 'change value date'
                  ? 'Change Value Date'
                  : 'Change Bank Account'}
            </h2>
          </div>
          <br />
          {modalEdit.Action === 'edit' || modalEdit.Action === 'change bank account' ? (
            <>
              <div className="form-group">
                <label className="control-label col-md-4">
                  Bank<span style={{ color: '#e64a19' }}>*</span>
                </label>
                <div className="col-md-8">
                  {modalEdit.CanSplit ? (
                    <input
                      className="form-control"
                      value={`${modalEdit.BankCode} | ${modalEdit.BankAbbreviate} | ${
                        modalEdit.BankName
                      }`}
                      onChange={onChangeModalEdit}
                      name="BankCode"
                      readOnly
                    />
                  ) : (
                    <MasterDropdown
                      masterType={MASTER_BANK}
                      isChoose
                      notMultipleSelect2
                      value={modalEdit.BankCode}
                      onChange={(e) =>
                        onChangeModalEdit({
                          target: e,
                        })
                      }
                      noValidateOption
                      saveLocalState
                      companyCode={modalEdit.CompanyCode}
                      required
                      name="BankCode"
                    />
                  )}
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-4">
                  Bank Account<span style={{ color: '#e64a19' }}>*</span>
                </label>
                <div className="col-md-8">
                  <MasterDropdown
                    masterType={MASTER_BANK_ACCOUNT}
                    isChoose
                    notMultipleSelect2
                    value={modalEdit.BankAccountNo}
                    onChange={(e) =>
                      onChangeModalEdit({
                        target: e,
                      })
                    }
                    customeValue={(m) => m.bankAccountNo}
                    noValidateOption
                    saveLocalState
                    bankCode={modalEdit.BankCode}
                    companyCode={modalEdit.CompanyCode}
                    required
                    name="BankAccountNo"
                  />
                </div>
              </div>
            </>
          ) : null}
          {modalEdit.Action === 'edit' || modalEdit.Action === 'change value date' ? (
            <div className="form-group">
              <label className="control-label col-md-4">
                Value Date<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <div className="col-md-8">
                <DatePicker
                  onChange={onChangeModalEdit}
                  name="ValueDateStr"
                  value={modalEdit.ValueDateStr}
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                  required
                  disabled={modalEdit.CanSplit}
                />
              </div>
            </div>
          ) : null}
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal textHeader="Split" ref={modalSplitRef}>
        <div className="row">
          <div className="col-sm-6">
            <Button
              txt="Split"
              icon="code-fork"
              className="btn-warning"
              onClick={onClickAddSplit}
            />
          </div>
          <div className="col-sm-6">
            <label>Outstanding: {toNumber(modalSplit.originalOutStandingAmount, 0)}</label>
          </div>
        </div>
        <br />
        <form onSubmit={onSubmitModalSplit}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Split No.</th>
                  <th>Outstanding</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">{`0000${modalSplit.splitNo}`.slice(-4)}</td>
                  <td>
                    <input
                      value={toNumber(modalSplit.outStandingAmount || 0)}
                      className="form-control text-right"
                      disabled
                    />
                  </td>
                </tr>
                {modalSplit.split.map((m, i) => (
                  <tr key={i}>
                    <td align="center">{`0000${m.splitNo}`.slice(-4)}</td>
                    <td>
                      <InputMask
                        required
                        format="currency"
                        name="outStandingAmount"
                        value={m.outStandingAmount}
                        onChange={(e) => onChangeSplitAmount(e, i)}
                        className="form-control"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button type="submit" className="btn-success" icon="save" txt="Confirm" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Spot Rate"
        textContent="spot rate"
        modalRef={modalSpotRateRef}
        type="primary"
        icon="icon-credit-card-alt"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedSpotRateLength}
        onClick={(e) => onClickModalSubmitChangeStatus(e, 'SpotRate')}
      />

      <ModalFunction
        textHeader="Withdraw"
        textContent="withdraw"
        modalRef={modalWithdrawRef}
        type="warning"
        icon="icon-refresh"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedWithdrawLength}
        onClick={(e) => onClickModalSubmitChangeStatus(e, 'WithDraw')}
      />

      <ModalFunction
        textHeader="Confirm Method"
        textContent="confirm method"
        modalRef={modalConfirmMethodRef}
        type="success"
        icon="icon-check"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedConfirmMethodLength}
        onClick={(e) => onClickModalSubmitChangeStatus(e, 'ConfirmMethod')}
      />

      <ModalFunction
        textHeader="Confirm Quote Rate"
        textContent="confirm quote rate"
        modalRef={modalConfirmQuoteRateRef}
        type="success"
        icon="icon-check"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedConfirmQuoteRateLength}
        onClick={(e) => onClickModalSubmitChangeStatus(e, 'ConfirmQuoteRate')}
      />

      <ModalFunction
        textHeader="Export Excel Quote Rate"
        textContent="export excel quote rate"
        modalRef={modalExportQuoteRateRef}
        type="primary"
        icon="icon-credit-card-alt"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedExportQuoteRateLength}
        onClick={(e) => onClickModalSubmitChangeStatus(e, 'ExportExcel')}
      />

      <ModalFunction
        textHeader="Send Email to Bank"
        textContent="send email to bank"
        modalRef={modalMailRef}
        type="info"
        icon="icon-envelope-o"
        showFail
        selectedResultCount={selectedResultCount}
        selectedLength={selectedMailLength}
        onClick={(e) => onClickModalSubmitChangeStatus(e, 'SendEmailToBank')}
      />

      <Modal size="modal-md" ref={modalExchangeRateRef} modalFooter>
        <form onSubmit={onSubmitExchangeRate} className="form-horizontal">
          <div className="text-center">
            {modalExchangeRate.Selected ? (
              <span className="label label-info">{selectedResultCount} items</span>
            ) : null}
            <h2 className="text-info">Exchange Rate</h2>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Exchange Rate<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-8">
              <InputMask
                className="form-control"
                onChange={onChangeModalExchangeRate}
                format="currency"
                option={{
                  prefix: '',
                  digits: 8,
                }}
                value={modalExchangeRate.ExchangeRate}
                required
                name="ExchangeRate"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalExportSpotSummaryRef} modalFooter>
        <form onSubmit={onSubmitModalExportSpotSummary} className="form-horizontal">
          <div className="text-center">
            <h2 className="text-info">Export Spot Summary</h2>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Finance Group<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-8">
              <MasterDropdown
                masterType={MASTER_FINANCE}
                // isChoose={true}
                value={modalExportSpotSummary.FinanceGroupId}
                onChange={(e) =>
                  onChangeModalExportSpotSummary({
                    target: e,
                  })
                }
                noValidateOption
                required
                name="FinanceGroupId"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Undo Split"
        textContent="undo split"
        modalRef={modalUndoRef}
        type="warning"
        icon="icon-undo"
        noSelect
        onClick={onClickConfirmModalUndo}
      />

      <Modal size="modal-md" ref={modalManualRef} modalFooter>
        <form onSubmit={onClickSubmitManual} className="form-horizontal">
          <div className="text-center">
            <h2 className="text-warning">Manual Auto Income</h2>
            <br />
            <span>You want to manual auto income?</span>
          </div>
          <br />
          <div className="row" style={{ paddingTop: '10px' }}>
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-warning" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

const optAll = {
  deferRender: false,
  scroller: true,
  paging: true,
  order: [[3, 'asc']],
  fixedColumns: {
    leftColumns: 4,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      data: 'IsSelectedAll',
      className: `text-center col-label-checkbox-by-expos pointer-cur`,
      render: (d, t, r) => {
        if (r.splitNo === 1)
          return `<input type="checkbox" class="label-checkbox" ${
            d ? 'checked="true"' : ''
          }/><label class="label_checkbox"></label>`;
        return '';
      },
    },
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${
          d ? 'checked="true"' : ''
        }/><label class="label_checkbox"></label>`;
      },
    },
    {
      orderable: false,
      data: 'IsEdit',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        // if (toLower(r.sourceType) === 't/t remittance')
        //     return '<button class="btn btn-icon btn-info" disabled="true"><span class="icon icon-edit sq-24"></span></button>'
        if (toLower(r.status) === 'wait for select method')
          return '<button class="btn btn-icon btn-info edit-expp"><span class="icon icon-edit sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info" disabled="true"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    {
      data: 'exposureNo',
      render: (d, t, r) => {
        if (toLower(r.vPayNo).includes('dtt'))
          return `<a target="_blank" href="/tt-remittance/create-tt/${r.vPayNo}"> ${d} </a>`;
        if (toLower(r.sourceType) === 't/t remittance')
          return `<a target="_blank" href="/tt-remittance/view-detail-tt/${d}"> ${d} </a>`;
        return `<a target="_blank" href="/fx-management/exposure-transaction/${d}" class="edit-exposure-transaciton"> ${d} </a>`;
      },
    },
    { data: 'vPayNo' },
    {
      orderable: false,
      data: 'IsDownload',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-primary"><span class="icon icon-download sq-24"></span></button>';
      },
    },
    {
      orderable: false,
      data: 'IsSplit',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (
          toLower(r.status) !== 'wait for select method' ||
          (toLower(r.quoteRateMethod) === 'forward' && r.forwardNo)
        )
          return '<button class="btn btn-icon btn-warning" disabled="true"><span class="icon icon-code-fork sq-24"></span></button>';
        return '<button class="btn btn-icon btn-warning btn-split"><span class="icon icon-code-fork sq-24"></span></button>';
      },
    },
    {
      orderable: false,
      data: 'IsUndo',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (r.splitNo !== 1 && toLower(r.status) === 'wait for select method')
          return '<button class="btn btn-icon btn-lightbrown undo-split"><span class="icon icon-undo sq-24"></span></button>';
        return '';
      },
    },
    { data: 'quoteRateMethod', className: 'text-center' },
    { data: 'forwardNo' },
    {
      data: 'exchangeRate',
      className: 'text-right',
      render: (d, t, r) => {
        if (d) {
          if (!permission) return toNumber(d, 8);
          if (
            toLower(r.status) === 'wait for quote rate' ||
            toLower(r.status) === 'wait for confirm quote rate' ||
            toLower(r.status) === 'already send to bank'
          )
            return `<a href="#" class="a-exchange-rate">${toNumber(d, 8)}</a>`;
          return toNumber(d, 8);
        }
        if (!permission) return '';
        if (
          toLower(r.status) === 'wait for quote rate' ||
          toLower(r.status) === 'wait for confirm quote rate' ||
          toLower(r.status) === 'already send to bank'
        )
          return '<center><a class="btn btn-icon btn-info a-exchange-rate"><span class="icon icon-edit sq-24"></span></a></center>';
        return '';
      },
    },
    { data: 'splitNo', className: 'text-center' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'sourceType', className: 'text-center' },
    { data: 'customerName' },
    {
      data: 'valueDateStr',
      className: 'text-center td-width-date',
      type: 'date-black',
    },
    {
      data: 'informDateStr',
      className: 'text-center td-width-date',
      type: 'date-black',
    },
    { data: 'cashType', className: 'text-center' },
    { data: 'transactionType', className: 'text-center' },
    { data: 'transactionDetail' },
    { data: 'swiftCode', className: 'text-center' },
    {
      data: 'bankAbbreviate',
      render: (d, r, s) => toBank(d, s.bankName),
    },
    {
      data: 'bankAccountNo',
      render: (d, r, s) => {
        if (d) return toAccount(null, s.branchName, s.accountTypeCode, s.bankCurrency, d);
        return '';
      },
    },
    {
      data: 'amountQuoteRate',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'amount',
      className: 'text-right td-width-number',
      render: (d, t, r) => {
        if (toLower(r.cashType) === 'outward') return toNumber(d * -1, r.currencyDigit);
        return toNumber(d, r.currencyDigit);
      },
    },
    {
      data: 'invoiceAmount',
      className: 'text-right',
      render: (d, t, r) => {
        if (!d) return '';
        if ((d || '').includes('|')) {
          const l = d.split('|');
          let a = '';
          for (let i = 0; i < l.length; i++) {
            a = `${a + toNumber(Number(l[i], r.currencyDigit))}<br />`;
          }
          return a;
        }
        return toNumber(Number(d), r.currencyDigit);
      },
    },
    { data: 'currency', className: 'text-center' },
    { data: 'remittedToCurrency', className: 'text-center' },
    {
      data: 'invoiceNo',
      render: (d) => {
        if (!d) return '';
        if ((d || '').includes('|')) {
          const l = d.split('|');
          let a = '';
          for (let i = 0; i < l.length; i++) {
            a = `${a + l[i]}<br />`;
          }
          return a;
        }
        return d;
      },
    },
    {
      data: 'paymentType',
      render: (d) => {
        if (toLower(d) === 'tt') return 'T/T';
        return d;
      },
    },
    { data: 'exposureType' },
    {
      data: 'status',
      className: 'text-center',
      render: (d) => toStatus(d, true),
    },
    { data: 'projectName' },
    {
      data: 'referenceNo',
      className: 'text-center',
      render: (d, t) => (t === 'export' ? `\u200C${d || ''}` : d),
    },
    { data: 'q2oNo', className: 'text-center' },
    { data: 'bankRemark' },
    { data: 'bankFileNameWithOutGUID' },
    {
      data: 'importExposureStatus',
      className: 'text-center',
      render: (d) => {
        if (toLower(d) === 'success') return `<span class="label label-success">${d}</span>`;
        if (toLower(d) === 'error') return `<span class="label label-danger">${d}</span>`;
        return d;
      },
    },
    { data: 'importExposureMessage' },
    { data: 'department' },
    { data: 'paymentTerm' },
    {
      data: 'createdDateStr',
      className: 'text-center td-width-date',
      type: 'date-time',
    },
    { data: 'createdBy' },
    {
      data: 'updatedDateStr',
      className: 'text-center td-width-date',
      type: 'date-black',
    },
    { data: 'updatedBy' },
  ],
};
