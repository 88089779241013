import { connect } from 'react-redux';
import React, { Component } from 'react';

import MaintainGrouping from './MaintainGrouping';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import {
  maintainEform,
  getMasterGroupId,
  getEformDepartment,
  getMaintainEformGrouping,
  getMaintainEformRounding,
  getMaintainEformDuration,
  saveMaintainEformGrouping,
  saveMaintainEformRounding,
  saveMaintainEformDuration,
  getMaintainEformPercentage,
  saveMaintainEformPercentage,
} from '../../reducers/eFormGrouping/action';

// const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-maintain-grouping';
export const DATATABLE_ID_PERCENT = 'table-result-maintain-grouping-percent';
export const DATATABLE_ID_ROUNDING = 'table-result-maintain-grouping-rounding';
export const DATATABLE_ID_DURATION = 'table-result-maintain-grouping-duration';

const initModal = {
  // All
  RequestType: '',
  ForwardType: '',

  // Grouping
  GroupId: 'NEW',
  DepartmentCode: ['All'],

  // Percentage
  Amount: '',
  Percentage: '',

  // Rounding Rule & Forward Duration
  Currency: 'All',

  // Rounding Rule
  Rounding: '',

  // Forward Duration
  CompanyCode: 'All',
  Duration: '',
  StartTerm: '',
  StartShift: '',
  EndShift: '',
  CalendarType: '',

  Type: '',
  Mode: 'create',

  EformRoundingId: '',
  EformPercentId: '',
  EformDurationId: '',
};

class MaintainGroupingContainer extends Component {
  state = {
    criteria: {},

    modalData: {
      ...initModal,
    },
  };

  dataTableGrouping = null;

  dataTablePercentage = null;

  dataTableRounding = null;

  dataTableDuration = null;

  componentDidMount() {
    this.props.maintainEform(this.state.criteria);

    this.props.getEformDepartment();
    this.props.getMasterGroupId(this.state.criteria);
  }

  onClickOpenModalHandler = (e, action) => {
    this.setState(
      {
        modalData: {
          ...initModal,
          Type: action,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEdit = (e, action) => {
    const id = e.currentTarget.attributes.id.value;

    if (action === 'grouping') {
      this.props
        .getMaintainEformGrouping({
          GroupId: id,
        })
        .then((response) => {
          if (response.error && !response.payload) return;
          const departmentCode = response.payload[0].departmentCode.split(',');

          this.setState(
            {
              modalData: {
                ...initModal,
                ...toUpperKey(response.payload[0] || initModal),
                Type: action,
                Mode: 'edit',
                DepartmentCode: departmentCode,
              },
            },
            this.modalDataRef.open
          );
        });
    } else if (action === 'percent') {
      this.props
        .getMaintainEformPercentage({
          GroupEformId: id,
        })
        .then((response) => {
          if (response.error && !response.payload) return;
          this.setState(
            {
              modalData: {
                ...initModal,
                ...toUpperKey(response.payload[0] || initModal),
                Type: action,
                Mode: 'edit',
              },
            },
            this.modalDataRef.open
          );
        });
    } else if (action === 'rounding') {
      this.props
        .getMaintainEformRounding({
          GroupEformId: id,
        })
        .then((response) => {
          if (response.error && !response.payload) return;
          this.setState(
            {
              modalData: {
                ...initModal,
                ...toUpperKey(response.payload[0] || initModal),
                Type: action,
                Mode: 'edit',
              },
            },
            this.modalDataRef.open
          );
        });
    } else if (action === 'duration') {
      this.props
        .getMaintainEformDuration({
          GroupEformId: id,
        })
        .then((response) => {
          if (response.error && !response.payload) return;
          this.setState(
            {
              modalData: {
                ...initModal,
                ...toUpperKey(response.payload[0] || initModal),
                Type: action,
                Mode: 'edit',
              },
            },
            this.modalDataRef.open
          );
        });
    }
  };

  onChangeModalHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'Amount') {
      if (parseFloat((value.toString() || '').replace(/,/g, '')) < 0) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Amount From must more than or equal zero.',
        });

        this.setState({
          modalData: {
            ...this.state.modalData,
            Amount: this.state.modalData.Amount,
          },
        });
        return;
      }
    } else if (name === 'Percentage') {
      if (
        parseFloat((value.toString() || '').replace(/,/g, '')) < 0 ||
        parseFloat((value.toString() || '').replace(/,/g, '')) > 100
      ) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Percentage From must more than or equal 0 and less than or equal 100.',
        });

        this.setState({
          modalData: {
            ...this.state.modalData,
            Percentage: this.state.modalData.Percentage,
          },
        });
        return;
      }
    }
    this.setState({
      modalData: {
        ...this.state.modalData,
        [name]: value,
      },
    });
  };

  onChangeModalSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: ['All'],
        },
      });
    } else if (e.value.indexOf('All') !== -1) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: e.value.filter((item) => item !== 'All'),
        },
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: e.value,
        },
      });
    }
  };

  onSubmitModalHandler = (e) => {
    e.preventDefault();
    const data = { ...this.state.modalData };

    if (parseFloat((data.Amount.toString() || '').replace(/,/g, '')) < 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Amount From must more than or equal zero.',
      });
      return;
    }

    if (
      parseFloat((data.Percentage.toString() || '').replace(/,/g, '')) < 0 ||
      parseFloat((data.Percentage.toString() || '').replace(/,/g, '')) > 100
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Percentage From must more than or equal 0 and less than or equal 100.',
      });
      return;
    }

    if (data.Type === 'grouping') {
      const groupId = data.GroupId === 'NEW' ? '' : data.GroupId;
      const departmentCode = data.DepartmentCode.toString();

      const submitData = {
        GroupId: groupId,
        RequestType: data.RequestType,
        ForwardType: data.ForwardType,
        DepartmentCode: departmentCode,
        Mode: 'create',
      };
      this.props.saveMaintainEformGrouping(submitData, this.state.criteria);
    } else if (data.Type === 'percent') {
      this.props.saveMaintainEformPercentage(data, this.state.criteria);
    } else if (data.Type === 'rounding') {
      this.props.saveMaintainEformRounding(data, this.state.criteria);
    } else if (data.Type === 'duration') {
      this.props.saveMaintainEformDuration(data, this.state.criteria);
    }

    this.modalDataRef.close();
  };

  onClickOpenModalDelete = (e, action) => {
    const id = e.currentTarget.attributes.id.value;

    if (action === 'grouping') {
      this.setState(
        {
          modalData: {
            ...initModal,
            GroupId: id,
            Type: action,
            Mode: 'delete',
          },
        },
        this.modalDeleteRef.open
      );
    } else if (action === 'percent') {
      this.setState(
        {
          modalData: {
            ...initModal,
            EformPercentId: id,
            Type: action,
            Mode: 'delete',
          },
        },
        this.modalDeleteRef.open
      );
    } else if (action === 'rounding') {
      this.setState(
        {
          modalData: {
            ...initModal,
            EformRoundingId: id,
            Type: action,
            Mode: 'delete',
          },
        },
        this.modalDeleteRef.open
      );
    } else if (action === 'duration') {
      this.setState(
        {
          modalData: {
            ...initModal,
            EformDurationId: id,
            Type: action,
            Mode: 'delete',
          },
        },
        this.modalDeleteRef.open
      );
    }
  };

  onClickDeleteModal = () => {
    const data = { ...this.state.modalData };

    if (data.Type === 'grouping') {
      const submitData = {
        GroupId: data.GroupId,
        Mode: 'delete',
      };
      this.props.saveMaintainEformGrouping(submitData, this.state.criteria);
    } else if (data.Type === 'percent') {
      this.props.saveMaintainEformPercentage(data, this.state.criteria);
    } else if (data.Type === 'rounding') {
      this.props.saveMaintainEformRounding(data, this.state.criteria);
    } else if (data.Type === 'duration') {
      this.props.saveMaintainEformDuration(data, this.state.criteria);
    }

    this.modalDeleteRef.close();
  };

  render() {
    const props = {
      dataTableGrouping: (e) => (this.dataTableGrouping = e),
      dataTablePercentage: (e) => (this.dataTablePercentage = e),
      dataTableRounding: (e) => (this.dataTableRounding = e),
      dataTableDuration: (e) => (this.dataTableDuration = e),

      resultGroupings: this.props.resultGroupings,
      resultPercentRules: this.props.resultPercentRules,
      resultRoundingRules: this.props.resultRoundingRules,
      resultForwardDuration: this.props.resultForwardDuration,

      onClickOpenModal: this.onClickOpenModalHandler,
      onClickOpenModalEdit: this.onClickOpenModalEdit,
      onChangeModal: this.onChangeModalHandler,
      onChangeModalSelect2: this.onChangeModalSelect2Handler,
      onSubmitModal: this.onSubmitModalHandler,

      onClickOpenModalDelete: this.onClickOpenModalDelete,
      onClickDeleteModal: this.onClickDeleteModal,

      masterDepartment: this.props.masterDepartment,
      masterGroupId: this.props.masterGroupId,
    };

    return (
      <MaintainGrouping
        {...props}
        {...this.state}
        modalDataRef={(e) => (this.modalDataRef = e)}
        modalDeleteRef={(e) => (this.modalDeleteRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.eFormGrouping,
  }),
  {
    maintainEform,
    getMaintainEformGrouping,
    getMaintainEformPercentage,
    getMaintainEformRounding,
    getMaintainEformDuration,
    saveMaintainEformGrouping,
    saveMaintainEformPercentage,
    saveMaintainEformRounding,
    saveMaintainEformDuration,
    getEformDepartment,
    getMasterGroupId,
    addAlert,
  }
)(MaintainGroupingContainer);
