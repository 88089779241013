import React, {
  memo,
  useRef,
  useState,
  useEffect,
  useReducer,
  forwardRef,
  useImperativeHandle,
} from 'react';

import Card from './Card';
import Col2 from './Col2';
import { store } from '../Root';
import InputMask from './InputMask';
import FormGroup2 from './FormGroup2';
import RadioButton from './RadioButton';
import { useOnChangeInput } from './customeHook';
import { toUpperKey, getPermission } from './helpper';
import MasterDropdownUI, { OPTION_WORKING_DAY } from './MasterDropdownUI';
import { getInterestRate, getTemplateInterestRateList } from '../reducers/common/action';

const permission = getPermission('Funding&Investment', 'Create Contract Loan');

const Interest = memo(({ myRef }) => {
  const [state, dispatch] = useReducer(reducer, initInterest);
  const onChange = useOnChangeInput(dispatch);
  const [templateList, setTemplateList] = useState([]);
  const formRef = useRef(null);
  useImperativeHandle(
    myRef,
    () => ({
      resetData: () => {
        dispatch({
          type: 'UPDATE_INTEREST',
          payload: { ...initInterest },
        });
      },
      setData: (data) => {
        dispatch({
          type: 'UPDATE_INTEREST',
          payload: { ...initInterest, ...data },
        });
      },
      formRef,
      state,
    }),
    [state]
  );

  useEffect(() => {
    async function fetchInterestTemplate() {
      const response = await store.dispatch(getTemplateInterestRateList());
      if (response.error) return;
      setTemplateList(response.payload);
    }
    fetchInterestTemplate();
  }, []);

  useEffect(() => {
    let ignore = false;
    async function getInterest() {
      const response = await store.dispatch(getInterestRate(state.InterestRateId));
      if (response.error) return;

      if (!ignore) {
        const templateData = toUpperKey(response.payload || {});
        delete templateData.InterestRateId;
        dispatch({
          type: 'UPDATE_INTEREST',
          payload: {
            ...templateData,
            ManualPeriodEnd:
              templateData.periodEndType === 'End of Term'
                ? null
                : templateData.isManualMonthEnd == null
                  ? ''
                  : templateData.isManualMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDueDate:
              templateData.dueDateType === 'End of Term'
                ? null
                : templateData.isDueDateMonthEnd == null
                  ? ''
                  : templateData.isDueDateMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDay: templateData.periodEndType === 'End of Term' ? null : templateData.manualDay,
            DueDateDay: templateData.dueDateType === 'End of Term' ? null : templateData.dueDateDay,
            IsManualInclude:
              templateData.isManualInclude == null ? false : templateData.isManualInclude,
          },
        });
      }
    }

    if (state.InterestRateId) getInterest();
    return () => {
      ignore = true;
    };
  }, [state.InterestRateId]);

  const setPeriodEndType = state.PeriodEndType !== 'End of Term';
  const setDueDateType = state.DueDateType !== 'End of Term';
  const isUseManualDayInterest =
    state.ManualPeriodEnd === 'Day' && state.PeriodEndType === 'Manual Period End';
  const isUseManualDayPayment =
    state.ManualDueDate === 'Day' && state.DueDateType === 'Manual Due Date';
  return (
    <form ref={formRef}>
      <Card>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Template Structure Name" required={false}>
              <select
                tabIndex="-1"
                className="form-control"
                onChange={onChange}
                required={false}
                value={state.InterestRateId}
                disabled={!permission}
                name="InterestRateId"
              >
                <option value="">---Choose---</option>
                {templateList.map((m) => (
                  <option value={m.interestRateId} key={m.interestRateId}>
                    {m.interestTemplateName}
                  </option>
                ))}
              </select>
            </FormGroup2>
            <FormGroup2 text="Cal. Method" required>
              <MasterDropdownUI
                onChange={onChange}
                isChoose
                notMultiple
                required
                value={state.CalculateMethod}
                name="CalculateMethod"
                options={['Act/360', 'Act/365', 'Act/366', 'Actual']}
                disabled={!permission}
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Interest Type" required>
              {permission ? (
                <div>
                  <RadioButton
                    text="Fix Rate"
                    value="Fix Rate"
                    checked={state.InterestType === 'Fix Rate'}
                    onChange={onChange}
                    name="InterestType"
                    required
                  />
                  <RadioButton
                    text="Float Rate"
                    value="Float Rate"
                    checked={state.InterestType === 'Float Rate'}
                    onChange={onChange}
                    name="InterestType"
                  />
                </div>
              ) : (
                <input
                  className="form-control"
                  onChange={onChange}
                  required
                  value={
                    state.InterestType === 'Fix Rate'
                      ? 'Fix Rate'
                      : state.InterestType === 'Float Rate'
                        ? 'Float Rate'
                        : ''
                  }
                  name="InterestType"
                  disabled={!permission}
                />
              )}
            </FormGroup2>

            {state.InterestType !== 'Fix Rate' ? (
              // <Col2 col={colX[1]}>
              <FormGroup2 text="Ref Interest Rate Name" required>
                <input
                  className="form-control"
                  onChange={onChange}
                  required
                  value={state.RefInterestRateName || ''}
                  name="RefInterestRateName"
                  disabled={!permission}
                />
              </FormGroup2>
            ) : // {/* </Col2> */}
            null}
          </Col2>
        </Col2>

        {state.InterestType !== 'Fix Rate' ? (
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Ref Interest Rate" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChange}
                  option={{
                    prefix: '',
                    digits: 5,
                    digitsOptional: false,
                    placeholder: '0.00000',
                  }}
                  required
                  value={state.RefInterestRate}
                  name="RefInterestRate"
                  disabled={!permission}
                />
              </FormGroup2>

              <FormGroup2 text="+/-" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChange}
                  option={{
                    prefix: '',
                    digits: 5,
                    digitsOptional: false,
                    placeholder: '0.00000',
                  }}
                  required
                  value={state.Margin}
                  name="Margin"
                  disabled={!permission}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Interest" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChange}
                  option={{
                    prefix: '',
                    digits: 5,
                    digitsOptional: false,
                    placeholder: '0.00000',
                  }}
                  required
                  value={state.InterestRate}
                  name="InterestRate"
                  disabled={!permission}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        ) : (
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Interest" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChange}
                  option={{
                    prefix: '',
                    digits: 5,
                    digitsOptional: false,
                    placeholder: '0.00000',
                  }}
                  required
                  value={state.InterestRate}
                  name="InterestRate"
                  disabled={!permission}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        )}
      </Card>

      <Col2 col={colX[0]}>
        <Col2 col={colX[2]}>
          <Card textHeader="Interest Period End" cardActions={['toggler']}>
            <Col2 col={colX[2]}>
              <FormGroup2 text="Period End" required>
                <div>
                  <RadioButton
                    text="End of Term"
                    value="End of Term"
                    checked={state.PeriodEndType === 'End of Term'}
                    onChange={onChange}
                    name="PeriodEndType"
                    disabled={!permission}
                    required
                  />
                  <RadioButton
                    text="Manual Period End"
                    value="Manual Period End"
                    checked={state.PeriodEndType === 'Manual Period End'}
                    onChange={onChange}
                    disabled={!permission}
                    name="PeriodEndType"
                  />
                  <label className="custom-control">
                    <div>
                      <input
                        id="IsManualInclude"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={(e) =>
                          onChange({
                            target: {
                              name: e.target.name,
                              value: e.target.checked,
                            },
                          })
                        }
                        name="IsManualInclude"
                        checked={state.IsManualInclude}
                        disabled={!permission}
                      />
                      <label htmlFor="IsManualInclude" className="label_checkbox" />
                      <label
                        htmlFor="IsManualInclude"
                        className="control-label text_label_checkbox"
                      >
                        Include
                      </label>
                    </div>
                  </label>
                </div>
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                <div>
                  <RadioButton
                    text="Month End"
                    value="Month End"
                    checked={state.ManualPeriodEnd === 'Month End'}
                    onChange={onChange}
                    name="ManualPeriodEnd"
                    disabled={permission ? !setPeriodEndType : true}
                    required={setPeriodEndType}
                  />

                  <RadioButton
                    text="Day"
                    value="Day"
                    checked={state.ManualPeriodEnd === 'Day'}
                    onChange={onChange}
                    name="ManualPeriodEnd"
                    disabled={permission ? !setPeriodEndType : true}
                  />

                  <label className="custom-control">
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChange}
                      option={isUseManualDayInterest ? dayFix : dayNormal}
                      required={isUseManualDayInterest}
                      disabled={permission ? !isUseManualDayInterest : true}
                      value={state.ManualDay}
                      name="ManualDay"
                    />
                  </label>
                </div>
              </FormGroup2>
            </Col2>

            <Col2 col="col-md-8">
              <FormGroup2 text="Working Day" required>
                <MasterDropdownUI
                  masterType={OPTION_WORKING_DAY}
                  onChange={onChange}
                  isChoose
                  notMultiple
                  required
                  value={state.ManualWorkingDay}
                  name="ManualWorkingDay"
                  disabled={!permission}
                />
              </FormGroup2>
            </Col2>
          </Card>
        </Col2>

        <Col2 col={colX[2]}>
          <Card textHeader="Interest Due Date" cardActions={['toggler']}>
            <Col2 col={colX[2]}>
              <FormGroup2 text="Due Date" required>
                <div>
                  <RadioButton
                    text="End of Term (Shift Back to Term End)"
                    value="End of Term"
                    checked={state.DueDateType === 'End of Term'}
                    onChange={onChange}
                    name="DueDateType"
                    disabled={!permission}
                    required
                  />
                  <RadioButton
                    text="Manual Due Date"
                    value="Manual Due Date"
                    checked={state.DueDateType === 'Manual Due Date'}
                    onChange={onChange}
                    disabled={!permission}
                    name="DueDateType"
                  />
                </div>
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                <div>
                  <RadioButton
                    text="Month End"
                    value="Month End"
                    checked={state.ManualDueDate === 'Month End'}
                    onChange={onChange}
                    name="ManualDueDate"
                    disabled={permission ? !setDueDateType : true}
                    required={setDueDateType}
                  />
                  <RadioButton
                    text="Day"
                    value="Day"
                    checked={state.ManualDueDate === 'Day'}
                    onChange={onChange}
                    disabled={permission ? !setDueDateType : true}
                    name="ManualDueDate"
                  />

                  <label className="custom-control">
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChange}
                      option={isUseManualDayPayment ? dayFix : dayNormal}
                      required={isUseManualDayPayment}
                      disabled={permission ? !isUseManualDayPayment : true}
                      value={state.DueDateDay}
                      name="DueDateDay"
                    />
                  </label>
                </div>
              </FormGroup2>
            </Col2>

            <Col2 col="col-md-8">
              <FormGroup2 text="Working Day" required>
                <MasterDropdownUI
                  masterType={OPTION_WORKING_DAY}
                  onChange={onChange}
                  isChoose
                  notMultiple
                  required
                  value={state.DueDateWorkingDay}
                  name="DueDateWorkingDay"
                  disabled={!permission}
                />
              </FormGroup2>
            </Col2>
          </Card>
        </Col2>
      </Col2>
    </form>
  );
});

export default forwardRef((props, ref) => <Interest {...props} myRef={ref} />);

const dayFix = {
  prefix: '',
  digits: 0,
  max: 31,
  min: 1,
};
const dayNormal = {
  prefix: '',
  digits: 0,
};

const initInterest = {
  InterestRate: '',
  InterestTemplateName: '',
  InterestType: 'Fix Rate',
  RefInterestRateName: '',
  RefInterestRate: '',
  Margin: '',
  Interest: '',

  PeriodEndType: '',
  IsManualInclude: '',
  IsManualMonthEnd: '',
  ManualPeriodEnd: '',
  ManualDay: '',
  ManualWorkingDay: '',

  DueDateType: '',
  IsDueDateMonthEnd: '',
  ManualDueDate: '',
  DueDateDay: '',
  DueDateWorkingDay: '',
};

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const interestInfomationReducer = (oldState, action) => {
  const state = {
    ...oldState,
    [action.name]: action.value,
  };

  switch (action.name) {
    case 'ManualPeriodEnd':
    case 'PeriodEndType':
      state.ManualDay = '';
      break;
    case 'ManualDueDate':
    case 'DueDateType':
      state.DueDateDay = '';
      break;
    case 'Margin':
    case 'RefInterestRate':
      state.InterestRate = parseFloat(state.RefInterestRate) + parseFloat(state.Margin);
      break;
    default:
      break;
  }

  return state;
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return interestInfomationReducer(state, action);
    case 'UPDATE_INTEREST':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
