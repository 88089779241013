import React, { memo, useRef, useMemo, useState, useContext, useCallback } from 'react';

import { store } from '../../../Root';
import Card from '../../../common/Card';
import { SplitModal } from './CashFlowTab';
import InputMask from '../../../common/InputMask';
import DatePicker from '../../../common/DatePicker';
import { addAlert } from '../../../reducers/layout/action';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { StateContext, InputMaskOptionContext } from './CreateContractLoan';
import { SWAP_LOAN, MASTER_BANK_ACCOUNT } from '../../../reducers/master/action';
import { toFixed, setAmount, isEmptyArray, getPermission } from '../../../common/helpper';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const permission = getPermission('Funding&Investment', 'Create Contract Loan');

function CashflowTable({
  bgHeader,
  textHeader,
  cashFlow = [],
  onChange,
  isBen,
  cashFlowType,
  bankCode,
  companyCode,
  partnerType,
  type,
}) {
  const splitRef = useRef(null);
  const { state, dispatch } = useContext(StateContext);
  const inputMaskOption = useContext(InputMaskOptionContext);
  const [bankAccountNoAll, setBankAccountNoAll] = useState('');
  const [selectedIdx, setSelectedIdx] = useState(null);
  const selectedData = selectedIdx !== null ? cashFlow[selectedIdx] : null;

  const findSameAccountBetweenDepositAndLoan = useCallback(
    (bankAccount) => {
      if (!bankAccount) return;
      const typeTarget = type === 'Loan' ? 'Deposit' : 'Loan';
      const targetBankAccounts = ((state[`Cashflow${typeTarget}`] || {})[cashFlowType] || [])
        .map((m) => m.BankAccountNo)
        .filter((f) => !!f);
      if (~targetBankAccounts.indexOf(bankAccount)) return true;
      return false;
    },
    [type, cashFlowType, state]
  );
  const onChangeBankAccountAll = useCallback(
    (e) => {
      const { value } = e;
      if (findSameAccountBetweenDepositAndLoan(value)) {
        store.dispatch(
          addAlert({
            type: 'error',
            title: 'Error',
            body: 'Cannot select BankAccout that dupicate between Loan And Deposit Cashflow',
          })
        );
        return;
      }

      setBankAccountNoAll(value);
      onChange(
        {
          target: e,
        },
        null,
        cashFlowType
      );
    },
    [onChange]
  );
  const onClickOpenSplitModal = useCallback((index) => {
    setSelectedIdx(index);
    splitRef.current.resetData();
    splitRef.current.open();
  }, []);

  const onClickSaveSplit = useCallback(
    (splitAmount) => {
      if (!splitAmount || splitAmount <= 0) {
        store.dispatch(
          addAlert({
            type: 'error',
            title: 'Error',
            body: 'Split Amount must more than Zero.',
          })
        );
        return;
      }
      if (Number(selectedData.Amount) <= Number(splitAmount)) {
        store.dispatch(
          addAlert({
            type: 'error',
            title: 'Error',
            body: 'Split Amount must less than Original Amount',
          })
        );
        return;
      }
      if (!selectedData.Id) {
        store.dispatch(
          addAlert({
            type: 'error',
            title: 'Error',
            body: 'Cannot Split item that not save yet.',
          })
        );
        return;
      }
      const cashFlows = state[`Cashflow${type}`][`${cashFlowType}`].map((m, index) => {
        if (index === selectedIdx) {
          const currentAmount = Number(selectedData.Amount) - Number(splitAmount);
          return {
            ...m,
            Amount: currentAmount,
            LocalCurrencyAmount: m.LocalCurrencyAmount ? currentAmount : null,
            OriginalInterestAmount: currentAmount + Number(m.WhtAmount || 0),
          };
        }

        return m;
      });
      cashFlows.splice(selectedIdx + 1, 0, {
        ...selectedData,
        Amount: Number(splitAmount) - Number(selectedData.WhtAmount || 0),
        OriginalInterestAmount: Number(splitAmount),
        Id: null,
        ParentCashFlowId: selectedData.Id,
      });

      dispatch({
        type: `UPDATE_CASHFLOW`,
        cashFlowType: type,
        name: cashFlowType,
        value: cashFlows,
      });
    },
    [state.CashflowLoan, state.CashflowDeposit, selectedIdx]
  );

  const onClickUndoSplit = useCallback(
    (idx) => {
      const selected = state[`Cashflow${type}`][`${cashFlowType}`][idx];
      const parentId = (selected || {}).ParentCashFlowId;

      if (!parentId) {
        alert('parentId is null');
        return;
      }

      const cashflows = state[`Cashflow${type}`][`${cashFlowType}`]
        .filter((f, i) => i !== idx)
        .map((m, i) => {
          if (m.Id === parentId) {
            const currenctAmount = Number(m.OriginalInterestAmount) + Number(selected.Amount);
            return {
              ...m,
              Amount: currenctAmount,
              OriginalInterestAmount: currenctAmount + Number(m.WhtAmount || 0),
              LocalCurrencyAmount: m.LocalCurrencyAmount ? currenctAmount : null,
            };
          }
          return m;
        });

      dispatch({
        type: `UPDATE_CASHFLOW`,
        cashFlowType: type,
        name: cashFlowType,
        value: cashflows,
      });
    },
    [state]
  );

  const tBody = useMemo(() => {
    const digit = (inputMaskOption || {}).digits || 2;
    return cashFlow.map((m, i) => {
      // set direct to object
      m.Amount = Number(Number(m.Amount || 0).toFixed(digit));
      return renderBody(i, m, digit);
    });
  }, [cashFlow, (inputMaskOption || {}).digits, bankCode, companyCode]);
  return (
    <>
      <SplitModal
        ref={splitRef}
        onSubmit={onClickSaveSplit}
        originalAmount={selectedData !== null ? selectedData.Amount : 0}
      />
      <Card textHeader={textHeader} bgHeader={bgHeader} cardActions={['toggler']}>
        <div className="table-responsive">
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th className="th-white">Split</th>
                <th className="th-white">
                  Undo
                  <br />
                  Split
                </th>
                <th className="th-white">Period</th>
                <th className="th-white">Days</th>
                <th className="th-white">Payment Date</th>
                <th className="th-white">D</th>
                <th className="th-white">Flow Type</th>
                <th className="th-white">Net Amount</th>
                <th className="th-white">Original Amount</th>
                <th className="th-white">Wht</th>
                <th className="th-white">Currency</th>
                <th className="th-white">
                  Bank Account<span style={{ color: '#e64a19' }}>*</span>
                  {cashFlow.length && !isEmptyArray(bankCode) ? (
                    <MasterDropdown
                      noValidateOption
                      masterType={MASTER_BANK_ACCOUNT}
                      isChoose
                      notMultipleSelect2
                      onChange={onChangeBankAccountAll}
                      saveLocalState
                      bankCode={bankCode}
                      companyCode={companyCode}
                      name="BankAccountNo"
                      value={bankAccountNoAll}
                      customeValue={(m) => `${m.bankAccountNo}`}
                      isAccountBank={partnerType === 'External'}
                      disabled={false}
                    />
                  ) : null}
                </th>
                <th className="th-white">Payment Method</th>
                <th className="th-white">Swap Ref</th>
                <th className="th-white">Beneficary Bank Account</th>
                <th className="th-white">
                  Posting
                  <br />
                  Status
                </th>
                <th className="th-white">Exchange Rate</th>
                <th className="th-white">
                  Local Currency
                  <br />
                  Amount
                </th>
                <th className="th-white">
                  Local
                  <br />
                  Currency
                </th>
                <th className="th-white">Posting Date</th>
                <th className="th-white">Document No.</th>
              </tr>
            </thead>
            <tbody>
              {!(cashFlow || []).length ? (
                <tr>
                  <td colSpan={isBen ? '18' : '17'} align="center">
                    No data available in table
                  </td>
                </tr>
              ) : (
                tBody
              )}
            </tbody>
          </table>
        </div>
      </Card>
    </>
  );

  function renderBody(index, m, digit) {
    return (
      <tr key={index}>
        <td align="center">
          {!m.IsManualCreate && m.Id && !m.ParentCashFlowId && permission ? (
            <button
              className="btn btn-icon btn-warning split-result"
              type="button"
              onClick={(e) => onClickOpenSplitModal(index)}
            >
              <span className="icon icon-object-ungroup sq-24" />
            </button>
          ) : null}
        </td>
        <td align="center">
          {m.ParentCashFlowId && permission ? (
            <button
              className="btn btn-icon btn-lightbrown undo-split-result"
              type="button"
              onClick={(e) => onClickUndoSplit(index)}
            >
              <span className="icon icon-undo sq-24" />
            </button>
          ) : null}
        </td>
        <td align="center">
          {m.StartInterestDateStr} - {m.EndInterestDateStr}
        </td>
        <td align="center">{m.InterestDay}</td>
        <td>
          <div className="td-input-date">
            <DatePicker
              value={m.PaymentDateStr}
              onChange={(e) => onChange(e, index, cashFlowType)}
              option={{
                daysOfWeekDisabled: '0,6',
                todayHighlight: true,
              }}
              required
              name="PaymentDateStr"
            />
          </div>
        </td>
        <td align="center">{m.Direction}</td>
        <td align="center">{m.FlowType}</td>
        <td align="right">{setAmount(m.Amount, digit)}</td>
        <td>
          {~m.FlowType.toLowerCase().indexOf('interest') ? (
            <InputMask
              className="form-control width-input-table"
              format="currency"
              onChange={(e) => onChange(e, index, cashFlowType)}
              option={inputMaskOption}
              value={toFixed(m.OriginalInterestAmount || '0', digit, true)}
              name="OriginalInterestAmount"
            />
          ) : null}
        </td>
        <td align="right">{setAmount(m.WhtAmount, digit)}</td>
        <td align="center">{m.Currency}</td>
        <td>
          <div className="td-input-account">{renderBankAccountNoDropdown(m, index)}</div>
        </td>

        <td>
          <div className="td-input-method">
            <MasterDropdownUI
              onChange={(e) =>
                onChange(
                  {
                    target: e,
                  },
                  index,
                  cashFlowType
                )
              }
              required
              value={m.PaymentMethod}
              isChoose
              notMultipleSelect2
              name="PaymentMethod"
              options={[
                'Bahtnet',
                'Cheque',
                'Internet Banking',
                'Auto Deduct',
                'T/T',
                'T/T Swaper',
              ]}
            />
          </div>
        </td>
        <td>
          {m.PaymentMethod === 'T/T Swaper' ? (
            <MasterDropdown
              noValidateOption
              masterType={SWAP_LOAN}
              isChoose
              notMultipleSelect2
              onChange={(e) =>
                onChange(
                  {
                    target: e,
                  },
                  index,
                  cashFlowType
                )
              }
              includeId={m.OldSwapTransactionNo}
              saveLocalState
              transactionNo={state.TransactionNo}
              currency={m.Currency}
              type={m.FlowType}
              amount={m.Amount}
              paymentDateStr={m.PaymentDateStr}
              name="SwapTransactionNo"
              value={m.SwapTransactionNo}
            />
          ) : null}
        </td>
        {renderColumnBankAccountNoBen(m, index)}
        <td align="center">{m.PostingStatus}</td>
        <td align="right">{setAmount(m.ExchangeRate, 8)}</td>
        <td align="right">{setAmount(m.LocalCurrencyAmount, 3)}</td>
        <td align="center">{m.LocalCurrency}</td>
        <td align="center">{m.PostingDateStr}</td>
        <td align="center">
          <a href="#">{m.DocumentNo}</a>
        </td>
      </tr>
    );
  }

  function renderBankAccountNoDropdown(m, index) {
    if (isEmptyArray(bankCode))
      return (
        <MasterDropdownUI
          onChange={(e) =>
            onChange(
              {
                target: e,
              },
              index,
              cashFlowType
            )
          }
          value={m.BankAccountNo}
          isChoose
          noValidateOption
          notMultipleSelect2
          name="BankAccountNo"
          options={null}
        />
      );

    return (
      <MasterDropdown
        noValidateOption
        masterType={MASTER_BANK_ACCOUNT}
        isChoose
        notMultipleSelect2
        value={m.BankAccountNo}
        onChange={(e) =>
          onChange(
            {
              target: e,
            },
            index,
            cashFlowType
          )
        }
        saveLocalState
        bankCode={bankCode}
        companyCode={companyCode}
        name="BankAccountNo"
        required
        isAccountBank={partnerType === 'External'}
        customeValue={(m) => `${m.bankAccountNo}`}
      />
    );
  }

  function renderBankAccountNoBenDropdown(m, index) {
    if (isEmptyArray(isEmptyArray))
      return (
        <MasterDropdownUI
          onChange={(e) =>
            onChange(
              {
                target: e,
              },
              index,
              cashFlowType
            )
          }
          value={m.BeneficiaryAccountNo}
          isChoose
          notMultipleSelect2
          name="BeneficiaryAccountNo"
          options={null}
        />
      );
    return (
      <MasterDropdown
        noValidateOption
        masterType={MASTER_BANK_ACCOUNT}
        isChoose
        notMultipleSelect2
        value={m.BeneficiaryAccountNo}
        onChange={(e) =>
          onChange(
            {
              target: e,
            },
            index,
            cashFlowType
          )
        }
        saveLocalState
        bankCode={bankCode}
        companyCode={companyCode}
        name="BeneficiaryAccountNo"
        required
        isAccountBank={partnerType === 'External'}
        customeValue={(m) => `${m.bankAccountNo}`}
      />
    );
  }

  function renderColumnBankAccountNoBen(m, index) {
    if (!isBen) return null;
    if (m.PaymentMethod === 'Bahtnet' && partnerType === 'External')
      return (
        <td>
          <div className="td-input-account">{renderBankAccountNoBenDropdown(m, index)}</div>
        </td>
      );

    return <td />;
  }
}

export default memo(CashflowTable);
