import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SAVE_AUTOMATE_EXPOSURE = '@@request/SAVE_AUTOMATE_EXPOSURE';
export const RECEIVE_SAVE_AUTOMATE_EXPOSURE = '@@receive/SAVE_AUTOMATE_EXPOSURE';
export const FAIL_SAVE_AUTOMATE_EXPOSURE = '@@fail/SAVE_AUTOMATE_EXPOSURE';

export const REQUEST_GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE =
  '@@request/GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE';
export const RECEIVE_GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE =
  '@@receive/GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE';
export const FAIL_GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE =
  '@@fail/GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE';

export const REQUEST_GET_AUTOMATE_EXPOSURE_DETAIL = '@@request/GET_AUTOMATE_EXPOSURE_DETAIL';
export const RECEIVE_GET_AUTOMATE_EXPOSURE_DETAIL = '@@receive/GET_AUTOMATE_EXPOSURE_DETAIL';
export const FAIL_GET_AUTOMATE_EXPOSURE_DETAIL = '@@fail/GET_AUTOMATE_EXPOSURE_DETAIL';

export const EDIT_AUTOMATE_EXPOSURE_CRITERIA = 'EDIT_AUTOMATE_EXPOSURE_CRITERIA';
export const CLEAR_AUTOMATE_EXPOSURE_CRITERIA = 'CLEAR_AUTOMATE_EXPOSURE_CRITERIA';
export const UPDATE_SEARCH_RESULT_AUTOMATE_EXPOSURE = 'UPDATE_SEARCH_RESULT_AUTOMATE_EXPOSURE';

export const REQUEST_SEARCH_AUTOMATE_EXPOSURE = '@@request/SARCH_AUTOMATE_EXPOSURE';
export const RECEIVE_SEARCH_AUTOMATE_EXPOSURE = '@@receive/SEARCH_AUTOMATE_EXPOSURE';
export const FAIL_SEARCH_AUTOMATE_EXPOSURE = '@@fail/SEARCH_AUTOMATE_EXPOSURE';

export const searchAutomateExposure = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/automate/getAutomateExposure', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_AUTOMATE_EXPOSURE,
      RECEIVE_SEARCH_AUTOMATE_EXPOSURE,
      FAIL_SEARCH_AUTOMATE_EXPOSURE,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_AUTOMATE_EXPOSURE,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_AUTOMATE_EXPOSURE_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_AUTOMATE_EXPOSURE_CRITERIA,
  payload: {
    name,
    value,
  },
});

export const getAutomateExposure = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/automate/getAutomateExposureDetail/`, data),
    method: 'GET',
    types: [
      REQUEST_GET_AUTOMATE_EXPOSURE_DETAIL,
      RECEIVE_GET_AUTOMATE_EXPOSURE_DETAIL,
      FAIL_GET_AUTOMATE_EXPOSURE_DETAIL,
    ],
  },
});

export const getAutomateExposureffectiveDate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/automate/getAutomateExposure', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE,
      RECEIVE_GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE,
      FAIL_GET_AUTOMATE_EXPOSURE_EFFECTIVE_DATE,
    ],
  },
});

export const saveAutomateExposure = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/automate/saveAutomateExposure`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_AUTOMATE_EXPOSURE,
      RECEIVE_SAVE_AUTOMATE_EXPOSURE,
      FAIL_SAVE_AUTOMATE_EXPOSURE,
    ],
  },
});
