import React from 'react';

import Profile from './Profile';
import CashFlow from './CashFlow';
import Stucture from './Structure';
import Tab from '../../../common/Tab';
import ModalAssign from './ModalAssign';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Link from '../../../common/Link';
import ModalCharge from '../ModalCharge';
import Button from '../../../common/Button';
import ModalCreditLine from './ModalCreditLine';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import RadioButton from '../../../common/RadioButton';
import { getPermission } from '../../../common/helpper';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
} from '../../../reducers/master/action';

const permission = getPermission('Funding&Investment', 'Facility Set up');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
export default ({
  state,
  onChangeInputData,
  onSubmit,
  onSubmitAll,

  modalDeleteRef,
  onClickOpenModalDelete,
  onClickDeleteModal,

  onChangeInputDataCashFlow,

  modalCreditLine,
  modalCreditLineRef,
  onClickOpenModalCreditLine,
  onClickOpenModalCreditLineEdit,
  onChangeModalCreditLine,
  onSubmitModalCreditLine,

  modalAssign,
  modalAssignRef,
  onClickOpenModalAssign,
  onClickOpenModalAssignEdit,
  onChangeModalAssign,
  onSubmitModalAssign,

  modalCharge,
  modalChargeRef,
  onClickOpenModalCharge,
  onChangeModalCharge,
  onSubmitModalCharge,

  modalDeleteCashFlowRef,
  onClickConfirmModalDeleteCashFlow,

  tabRef,
  formDetailRef,
  formCashFlowRef,
}) => {
  const header =
    state.mode === 'update' ? `Transaciton No.: ${state.TransactionNo}` : 'Create Facility Set Up';
  const noEdit = state.mode === 'update';
  const required = true;

  const tabContents = [];
  // ------------------ Tab Structure ----------------------
  tabContents.push({
    header: <span>Structure</span>,
    content: (
      <Stucture
        state={state}
        onClickOpenModalDelete={onClickOpenModalDelete}
        onClickOpenModalCreditLine={onClickOpenModalCreditLine}
        onClickOpenModalCreditLineEdit={onClickOpenModalCreditLineEdit}
        onClickOpenModalAssign={onClickOpenModalAssign}
        onClickOpenModalAssignEdit={onClickOpenModalAssignEdit}
      />
    ),
    tabName: 'structure',
  });

  // ------------------ Tab Profile ----------------------
  tabContents.push({
    header: <span>Profile</span>,
    content: <Profile state={state} />,
    tabName: 'profile',
  });

  // ------------------ Tab Cash Flow ----------------------
  tabContents.push({
    header: <span>Cash Flow</span>,
    content: (
      <CashFlow
        formRef={formCashFlowRef}
        state={state}
        onChangeInputData={onChangeInputData}
        onSubmit={onSubmitAll}
        onChangeInputDataCashFlow={onChangeInputDataCashFlow}
        onClickOpenModalCharge={onClickOpenModalCharge}
      />
    ),
    tabName: 'cashflow',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Funding & Investment <span className="icon icon-angle-double-right" />
            <Link txt="Facility Set Up" href="/loan-investment/facility-set-up" />
            <span className="icon icon-angle-double-right" /> Facility Set Up Detail
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit} name="detail" ref={formDetailRef}>
        <Card
          textHeader={header}
          bgHeader="bg-primary"
          cardActions={['toggler']}
          footer={
            !permission ? (
              ''
            ) : state.mode === 'update' ? (
              ''
            ) : (
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            )
          }
        >
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                {state.mode === 'update' ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.FinanceGroupName}
                    name="FinanceGroupName"
                    disabled
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_FINANCE}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={required}
                    disabled={noEdit}
                    value={state.FinanceGroupId}
                    name="FinanceGroupId"
                  />
                )}
              </FormGroup2>

              <FormGroup2 text="Company" required={false}>
                {state.FinanceGroupId ? (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={false}
                    disabled={noEdit && state.CheckCompanyCode}
                    financeGroupId={state.FinanceGroupId}
                    value={state.CompanyCode}
                    name="CompanyCode"
                  />
                ) : (
                  <MasterDropdownUI
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    value={state.CompanyCode}
                    name="CompanyCode"
                    options={null}
                  />
                )}
              </FormGroup2>
            </Col2>

            <Col2 col={colX[0]}>
              <FormGroup2 text="Business Partner Type" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.BusinessPartnerType}
                    name="BusinessPartnerType"
                    readOnly
                  />
                ) : (
                  <div>
                    <RadioButton
                      text="External"
                      value="External"
                      checked={state.BusinessPartnerType === 'External'}
                      onChange={onChangeInputData}
                      name="BusinessPartnerType"
                      required
                    />
                    <RadioButton
                      text="Intercompany"
                      value="Intercompany"
                      checked={state.BusinessPartnerType === 'Intercompany'}
                      onChange={onChangeInputData}
                      name="BusinessPartnerType"
                      required
                    />
                  </div>
                )}
              </FormGroup2>
              <FormGroup2 text="Business Partner" required>
                {state.BusinessPartnerType === 'Intercompany' ? (
                  <MasterDropdown
                    key="company"
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    required={required}
                    isChoose
                    notMultipleSelect2
                    disabled={noEdit}
                    isGetAll
                    value={state.BusinessPartnerCode}
                    name="BusinessPartnerCode"
                  />
                ) : (
                  <MasterDropdown
                    key="bank"
                    masterType={MASTER_BANK_CODE}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    required={required}
                    isChoose
                    notMultipleSelect2
                    isGetAll
                    isOutFlow
                    customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                    // companyCode={state.CompanyCode}
                    disabled={noEdit}
                    value={state.BusinessPartnerCode}
                    name="BusinessPartnerCode"
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Facility Name" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.FacilityName}
                  name="FacilityName"
                  readOnly={noEdit}
                />
              </FormGroup2>
              <FormGroup2 text="Facility Type" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.FacilityType}
                    name="FacilityType"
                    readOnly
                  />
                ) : (
                  <MasterDropdownUI
                    onChange={onChangeInputData}
                    isChoose={!noEdit}
                    notMultiple
                    required={required}
                    disabled={noEdit}
                    value={state.FacilityType}
                    name="FacilityType"
                    options={['Bilateral', 'Syndicate']}
                  />
                )}
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.Currency}
                    name="Currency"
                    readOnly
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_CURRENCY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={required}
                    disabled={noEdit}
                    value={state.Currency}
                    name="Currency"
                  />
                )}
              </FormGroup2>

              {noEdit ? (
                <FormGroup2 text="Project" required={false}>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.IsProject ? 'Yes' : 'No'}
                    name="IsProject"
                    readOnly
                  />
                </FormGroup2>
              ) : (
                <div className="form-group">
                  <div>
                    <input
                      id="IsProject"
                      type="checkbox"
                      className="label-checkbox"
                      onChange={onChangeInputData}
                      name="IsProject"
                      checked={state.IsProject}
                    />
                    <label htmlFor="IsProject" className="label_checkbox" />
                    <label htmlFor="IsProject" className="control-label text_label_checkbox">
                      Project
                    </label>
                  </div>
                </div>
              )}
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Remark">
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  value={state.Remark}
                  name="Remark"
                />
              </FormGroup2>

              <FormGroup2 text="Start Date" required>
                <DatePicker
                  onChange={onChangeInputData}
                  option={{
                    // daysOfWeekDisabled: "0,6",
                    todayHighlight: true,
                  }}
                  required={required}
                  disabled={noEdit}
                  value={state.StartDateStr}
                  name="StartDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="End Date" required={false}>
                <DatePicker
                  onChange={onChangeInputData}
                  option={{
                    // daysOfWeekDisabled: "0,6",
                    todayHighlight: true,
                  }}
                  required={false}
                  disabled={false}
                  value={state.EndDateStr}
                  name="EndDateStr"
                />
              </FormGroup2>
              {state.mode === 'create' ? (
                <FormGroup2 text="Credit Line" required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    onChange={onChangeInputData}
                    option={state.inputMaskAmount}
                    required={required}
                    value={state.Outstanding}
                    disabled={false}
                    name="Outstanding"
                  />
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>
        </Card>
      </form>
      {state.mode === 'update' ? (
        <Tab
          tabs={tabContents}
          ref={tabRef}
          footer={
            !permission ? (
              ''
            ) : state.mode === 'update' ? (
              <Button txt="SAVE" icon="save" className="btn-success" onClick={onSubmitAll} />
            ) : (
              ''
            )
          }
        />
      ) : null}

      <ModalFunction
        textHeader="Delete"
        textContent="a Delete"
        modalRef={modalDeleteRef}
        type="danger"
        icon="icon-trash-o"
        textBtn="Delete"
        noSelect
        onClick={onClickDeleteModal}
      />

      <ModalFunction
        textHeader="Delete"
        textContent="a Delete"
        modalRef={modalDeleteCashFlowRef}
        type="danger"
        icon="icon-trash-o"
        textBtn="Delete"
        noSelect
        onClick={onClickConfirmModalDeleteCashFlow}
      />

      <ModalCreditLine
        modalRef={modalCreditLineRef}
        state={modalCreditLine}
        inputMaskAmount={state.inputMaskAmount}
        onSubmit={onSubmitModalCreditLine}
        onChageModal={onChangeModalCreditLine}
      />

      <ModalAssign
        modalRef={modalAssignRef}
        state={modalAssign}
        onSubmit={onSubmitModalAssign}
        onChageModal={onChangeModalAssign}
        inputMaskAmount={state.inputMaskAmount}
      />

      <ModalCharge
        modalRef={modalChargeRef}
        state={modalCharge}
        onSubmit={onSubmitModalCharge}
        onChageModal={onChangeModalCharge}
      />
    </div>
  );
};
