import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import TableCashFlow from './TableCashFlow';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_COMPANY, MASTER_BANK_CODE } from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  formCashFlowLoanRef,
  formCashFlowDepositRef,
  formResultCashFlowLoanRef,
  formResultCashFlowDepositRef,
  state,
  onChangeInputData,

  onChangeInputTable,
  onClickGetCashflow,
  onChangeInputHeader,
  viewOnly,
  onChangeSelect2,
}) => {
  const isEndOfTerms = state.PeriodEndType === 'End of Term' && state.DueDateType === 'End of Term';

  return (
    <>
      {state.permission && !viewOnly ? (
        <>
          <Col2 col={colX[0]}>
            <Button
              txt="Get Cash Flow"
              icon="search"
              className="btn-info"
              onClick={onClickGetCashflow}
              disabled={viewOnly}
            />
          </Col2>
          <br />
        </>
      ) : null}

      {state.Source !== 'Deposit' ? (
        <Card textHeader="Loan" bgHeader="bg-primary" cardActions={['toggler']}>
          <form ref={formCashFlowLoanRef} name="cashflow">
            <Col2 col={colX[2]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Bank" required>
                  <MasterDropdown
                    masterType={MASTER_BANK_CODE}
                    noValidateOption
                    saveLocalState
                    onChange={onChangeSelect2}
                    required
                    isGetAll
                    isOutFlow
                    customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                    companyCode={state.CompanyByLoan}
                    disabled={viewOnly}
                    value={state.BankCodeLoan}
                    name="BankCodeLoan"
                  />
                </FormGroup2>
                {state.PartnerType === 'Intercompany' &&
                state.TransactionType &&
                state.CompanyByLoan ? (
                  <FormGroup2 text="Company for Loan">
                    <MasterDropdown
                      masterType={MASTER_COMPANY}
                      noValidateOption
                      saveLocalState
                      onChange={onChangeInputData}
                      notMultiple
                      disabled
                      isChoose
                      value={state.CompanyByLoan}
                      isGetAll
                      name="CompanyByLoan"
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            </Col2>
          </form>

          <TableCashFlow
            state={state.resultLoan}
            onChangeInputTable={onChangeInputTable}
            resultType="loan"
            BankCode={state.BankCodeLoan}
            CompanyCode={state.CompanyByLoan}
            PartnerType={state.PartnerType}
            isBen
            isCharge={false}
            isEndOfTerms={isEndOfTerms}
            formRef={formResultCashFlowLoanRef}
            formName="cashflow"
            onChangeInputHeader={onChangeInputHeader}
            bankAccountNoAll={state.AccountNoAllLoan}
            paymentMethodAll={state.PaymentMethodAllLoan}
            permission={state.permission}
            viewOnly={viewOnly}
            status={state.Status}
            isAccountBank={
              (state.Source === 'Deposit' || state.Source === 'PN') &&
              state.PartnerType === 'External'
            }
          />
        </Card>
      ) : null}

      {state.PartnerType !== 'External' || state.Source === 'Deposit' ? (
        <Card textHeader="Deposit" bgHeader="bg-info" cardActions={['toggler']}>
          <form ref={formCashFlowDepositRef} name="cashflow">
            <Col2 col={colX[2]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Bank" required>
                  <MasterDropdown
                    masterType={MASTER_BANK_CODE}
                    noValidateOption
                    saveLocalState={state.PartnerType === 'External'}
                    onChange={onChangeSelect2}
                    // isChoose={true}
                    // notMultipleSelect2={true}
                    // notMultiple={false}
                    required
                    isGetAll
                    isOutFlow
                    customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                    companyCode={state.CompanyByDeposit}
                    disabled={viewOnly}
                    value={state.BankCodeDeposit}
                    name="BankCodeDeposit"
                  />
                </FormGroup2>
                {state.PartnerType === 'Intercompany' &&
                state.TransactionType &&
                state.CompanyByDeposit ? (
                  <FormGroup2 text="Company for Deposit">
                    <MasterDropdown
                      masterType={MASTER_COMPANY}
                      noValidateOption
                      saveLocalState
                      onChange={onChangeInputData}
                      notMultiple
                      disabled
                      isChoose
                      value={state.CompanyByDeposit}
                      isGetAll
                      name="CompanyByDeposit"
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            </Col2>
          </form>

          <TableCashFlow
            state={state.resultDeposit}
            onChangeInputTable={onChangeInputTable}
            resultType="deposit"
            BankCode={state.BankCodeDeposit}
            CompanyCode={state.CompanyByDeposit}
            PartnerType={state.PartnerType}
            isBen={state.Source === 'Deposit'}
            isCharge={false}
            isEndOfTerms={isEndOfTerms}
            formRef={formResultCashFlowDepositRef}
            formName="cashflow"
            onChangeInputHeader={onChangeInputHeader}
            bankAccountNoAll={state.AccountNoAllDeposit}
            paymentMethodAll={state.PaymentMethodAllDeposit}
            permission={state.permission}
            viewOnly={viewOnly}
            status={state.Status}
            isAccountBank={
              (state.Source === 'Deposit' || state.Source === 'PN') &&
              state.PartnerType === 'External'
            }
          />
        </Card>
      ) : null}
    </>
  );
};
