import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_GET_MAINTAIN_INTEREST_RATE = '@@request/GET_MAINTAIN_INTEREST_RATE';
export const RECEIVE_GET_MAINTAIN_INTEREST_RATE = '@@receive/GET_MAINTAIN_INTEREST_RATE';
export const FAIL_GET_MAINTAIN_INTEREST_RATE = '@@fail/GET_MAINTAIN_INTEREST_RATE';

export const REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE = '@@request/GET_TEMPLATE_LIST_INTEREST_RATE';
export const RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE = '@@receive/GET_TEMPLATE_LIST_INTEREST_RATE';
export const FAIL_GET_TEMPLATE_LIST_INTEREST_RATE = '@@fail/GET_TEMPLATE_LIST_INTEREST_RATE';

export const REQUEST_GET_FACILITY_LIST = '@@request-noload/GET_FACILITY_LIST';
export const RECEIVE_GET_FACILITY_LIST = '@@receive/GET_FACILITY_LIST';
export const FAIL_GET_FACILITY_LIST = '@@fail/GET_FACILITY_LIST';

export const REQUEST_PROCESS_CASH_FLOW = '@@request/PROCESS_CASH_FLOW';
export const RECEIVE_PROCESS_CASH_FLOW = '@@receive/PROCESS_CASH_FLOW';
export const FAIL_PROCESS_CASH_FLOW = '@@fail/PROCESS_CASH_FLOW';

export const REQUEST_GET_LOAN = '@@request/GET_LOAN';
export const RECEIVE_GET_LOAN = '@@receive/GET_LOAN';
export const FAIL_GET_LOAN = '@@fail/GET_LOAN';

export const REQUEST_GET_CURRENCY1 = '@@request/GET_CURRENCY1';
export const RECEIVE_GET_CURRENCY1 = '@@receive/GET_CURRENCY1';
export const FAIL_GET_CURRENCY1 = '@@fail/GET_CURRENCY1';

export const getCurrency = (cur) => ({
  [RSAA]: {
    endpoint: endpoint('/master/currency', {
      criteria: cur,
    }),
    method: 'GET',
    types: [REQUEST_GET_CURRENCY1, RECEIVE_GET_CURRENCY1, FAIL_GET_CURRENCY1],
  },
});

export const processCashflow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/processCashFlow'),
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    method: 'POST',
    types: [REQUEST_PROCESS_CASH_FLOW, RECEIVE_PROCESS_CASH_FLOW, FAIL_PROCESS_CASH_FLOW],
  },
});

export const getFacilityList = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/getfacilitylist', criteria),
    method: 'GET',
    types: [REQUEST_GET_FACILITY_LIST, RECEIVE_GET_FACILITY_LIST, FAIL_GET_FACILITY_LIST],
  },
});

export const getTemplateInterestRateList = () => ({
  [RSAA]: {
    endpoint: endpoint('/loan/gettemplateinterestlist'),
    method: 'GET',
    types: [
      REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE,
      RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE,
      FAIL_GET_TEMPLATE_LIST_INTEREST_RATE,
    ],
  },
});

export const getInterestRate = (interestRateId) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getinterestrate/${interestRateId}`),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_INTEREST_RATE,
      RECEIVE_GET_MAINTAIN_INTEREST_RATE,
      FAIL_GET_MAINTAIN_INTEREST_RATE,
    ],
  },
});

export const getLoan = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getloan/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_LOAN, RECEIVE_GET_LOAN, FAIL_GET_LOAN],
  },
});
