import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import { store } from '../../Root';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Attachment, { appendForm } from './Attachment';
import { addAlert } from '../../reducers/layout/action';
import { getAttachment, saveAttachFile, searchRequestBg } from '../../reducers/requestBg/action';

const initRequiredTypes = [
  {
    type: 'Approved Memo',
    required: true,
  },
  {
    type: 'Supporting Document',
    required: true,
  },
  // {
  //     type: 'Ticket',
  //     required: false,
  // },
  {
    type: 'Copy BG',
    required: true,
  },
  {
    type: 'Others',
    required: false,
  },
];

const initRequiredTypes2 = [
  {
    type: 'Approved Memo',
    required: true,
  },
  {
    type: 'Supporting Document',
    required: true,
  },
  {
    type: 'Copy BG',
    required: true,
  },
  {
    type: 'Others',
    required: false,
  },
];

function AttachmentModal(
  { transactionNo, statusLg, formFormat, buApprove, criteria, canAttachFile },
  ref
) {
  const [requiredTypes, setRequiredTypes] = useState([...initRequiredTypes]);
  const [values, setValues] = useState([]);
  const attachRef = useRef(null);
  const modalRef = useRef(null);
  useEffect(() => {
    let ignore = false;

    async function fetch() {
      if (!transactionNo) {
        setValues([]);
        return;
      }
      const response = await store.dispatch(getAttachment(transactionNo));
      if (!response || response.error) return;

      if (!ignore) setValues(response.payload || []);
    }

    fetch();

    return () => {
      ignore = true;
    };
  }, [transactionNo]);

  const onClickSave = useCallback(() => {
    const attachment = attachRef.current.getAttachments();
    const form = new FormData();
    appendForm(form, attachment);
    async function save() {
      const response = await store.dispatch(saveAttachFile(form, transactionNo));
      if (!response || response.error) return;

      setValues(response.payload);

      modalRef.current.close();

      store.dispatch(
        addAlert({
          title: 'Success',
          type: 'success',
          body: 'The transaction was successfully saved',
        })
      );

      await store.dispatch(searchRequestBg(criteria));
    }
    save();
  });

  const onClickAddOther = useCallback(() => {
    attachRef.current.addFileType('Others');
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setRequiredTypes(
          formFormat === 'Blockchain' ? [...initRequiredTypes2] : [...initRequiredTypes]
        );
        modalRef.current.open();
      },
      getAttachment: () => attachRef.current.getAttachments(),
    }),
    []
  );

  return (
    <div>
      <Modal ref={modalRef} bgHeader="bg-info" textHeader="Attachment" size="modal-lg">
        <Button txt="Add Other" icon="plus" className="btn-info" onClick={onClickAddOther} />
        <Attachment
          ref={attachRef}
          requiredTypes={
            formFormat === 'Blockchain' ? [...initRequiredTypes2] : [...initRequiredTypes]
          }
          values={values}
          statusLg={statusLg}
          isModal
          isBuApprove={buApprove}
          canAttachFile={canAttachFile}
        />
        {
          // ~['wait for confirm', 'confirmed', 'created'].indexOf((statusLg||'').toLowerCase()) ? (
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Save" icon="save" className="btn-success" onClick={onClickSave} />
            </div>
          </div>
          // ) : null
        }
      </Modal>
    </div>
  );
}

export default forwardRef(AttachmentModal);
