import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@request/SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const RECEIVE_SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@receive-Save Success/SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const FAIL_SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@fail/SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN';

export const REQUEST_GET_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@request/GET_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const RECEIVE_GET_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@receive/GET_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const FAIL_GET_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@fail/GET_PRINT_INTEREST_RECEIPT_MAINTAIN';

export const REQUEST_SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@request/SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const RECEIVE_SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@receive/SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const FAIL_SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@fail/SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN';

export const REQUEST_CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@request/CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const RECEIVE_CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@receive-Save Success/CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const FAIL_CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN =
  '@@fail/CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN';

export const changeStatusInterestReceiptMaintain = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/PrintInterestReceiptMaintain/ChangeStatus'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN,
      RECEIVE_CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN,
      FAIL_CHANGE_STATUS_PRINT_INTEREST_RECEIPT_MAINTAIN,
    ],
  },
});

export const searchInterestReceiptMaintain = () => ({
  [RSAA]: {
    endpoint: endpoint('/PrintInterestReceiptMaintain/search'),
    method: 'GET',
    types: [
      REQUEST_SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN,
      RECEIVE_SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN,
      FAIL_SEARCH_PRINT_INTEREST_RECEIPT_MAINTAIN,
    ],
  },
});

export const submitInterestReceiptMaintain = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/PrintInterestReceiptMaintain'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN,
      RECEIVE_SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN,
      FAIL_SUBMIT_PRINT_INTEREST_RECEIPT_MAINTAIN,
    ],
  },
});

export const getInterestReceiptMaintain = (id) => ({
  [RSAA]: {
    endpoint: endpoint(`/PrintInterestReceiptMaintain?id=${id}`),
    method: 'GET',
    types: [
      REQUEST_GET_PRINT_INTEREST_RECEIPT_MAINTAIN,
      RECEIVE_GET_PRINT_INTEREST_RECEIPT_MAINTAIN,
      FAIL_GET_PRINT_INTEREST_RECEIPT_MAINTAIN,
    ],
  },
});
