import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_POA = '@@request/SEARCH_POA';
export const RECEIVE_SEARCH_POA = '@@receive/SEARCH_POA';
export const FAIL_SEARCH_POA = '@@fail/SEARCH_POA';

export const REQUEST_CREATE_POA = '@@request/CREATE_POA';
export const RECEIVE_CREATE_POA = '@@receive-The transaction was successfully saved/CREATE_POA';
export const FAIL_CREATE_POA = '@@fail/CREATE_POA';

export const UPDATE_SEARCH_RESULT_POA = 'UPDATE_SEARCH_RESULT_POA';

export const REQUEST_UPDATE_STATUS_POA = '@@request/UPDATE_STATUS_POA';
export const RECEIVE_UPDATE_STATUS_POA =
  '@@receive-The transaction was successfully saved/UPDATE_STATUS_POA';
export const FAIL_UPDATE_STATUS_POA = '@@fail/UPDATE_STATUS_POA';

export const REQUEST_DOWNLOAD_FILE = '@@request/DOWNLOAD_FILE';
export const RECEIVE_DOWNLOAD_FILE = '@@receive/DOWNLOAD_FILE';
export const FAIL_DOWNLOAD_FILE = '@@fail/DOWNLOAD_FILE';

export const searchPOA = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poa', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_POA, RECEIVE_SEARCH_POA, FAIL_SEARCH_POA],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_POA,
  payload: {
    name,
    value,
    index,
  },
});

export const createPOA = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/poa/create'),
    method: 'POST',
    body: data,
    types: [REQUEST_CREATE_POA, RECEIVE_CREATE_POA, FAIL_CREATE_POA],
  },
});

export const updateStatus = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/poa/updatestatus'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [REQUEST_UPDATE_STATUS_POA, RECEIVE_UPDATE_STATUS_POA, FAIL_UPDATE_STATUS_POA],
    },
  });

  if (!actionResponse.error && criteria) return await dispatch(searchPOA(criteria));
  return actionResponse;
};

export const downloadAttachFile = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/poa/download-file'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DOWNLOAD_FILE,
      {
        type: RECEIVE_DOWNLOAD_FILE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_FILE,
    ],
  },
});
