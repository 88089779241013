import {
  EDIT_BG_REPORT_CRITERIA,
  RECEIVE_SEARCH_BG_REPORT,
  CLEAR_BG_REPORT_CRITERIA,
} from './action';

const initCriteria = {
  FinanceGroup: [''],
  BusinessUnit: [''],
  CompanyCode: [''],
  CompanyBgLine: [''],
  BankCode: [''],
  Currency: '',
  EffectiveDateStr: '',
  BeneficiaryId: [''],
  LgTypeId: [''],
  StatusLg: '',
  TransactionNo: '',
  Amount: '',
  IsExpired: false,
  IssueDateStr: '',
  ReportAsOfStr: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_SEARCH_BG_REPORT:
      return {
        ...state,
        searchResult: action.payload,
      };
    case CLEAR_BG_REPORT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_BG_REPORT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
