import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_BUSINESS_UNIT_CRITERIA = 'EDIT_MASTER_BUSINESS_UNIT_CRITERIA';
export const CLEAR_MASTER_BUSINESS_UNIT_CRITERIA = 'CLEAR_MASTER_BUSINESS_UNIT_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_BUSINESS_UNIT =
  'UPDATE_SEARCH_RESULT_MASTER_BUSINESS_UNIT';

export const REQUEST_SEARCH_MASTER_BUSINESS_UNIT = '@@request/SARCH_MASTER_BUSINESS_UNIT';
export const RECEIVE_SEARCH_MASTER_BUSINESS_UNIT = '@@receive/SEARCH_MASTER_BUSINESS_UNIT';
export const FAIL_SEARCH_MASTER_BUSINESS_UNIT = '@@fail/SEARCH_MASTER_BUSINESS_UNIT';

export const REQUEST_SAVE_MASTER_BUSINESS_UNIT = '@@request/SAVE_MASTER_BUSINESS_UNIT';
export const RECEIVE_SAVE_MASTER_BUSINESS_UNIT =
  '@@receive-The record was successfully saved/SAVE_MASTER_BUSINESS_UNIT';
export const FAIL_SAVE_MASTER_BUSINESS_UNIT = '@@fail/SAVE_MASTER_BUSINESS_UNIT';

export const REQUEST_DELETE_MASTER_BUSINESS_UNIT = '@@request/DELETE_MASTER_BUSINESS_UNIT';
export const RECEIVE_DELETE_MASTER_BUSINESS_UNIT =
  '@@receive-The record was successfully change status/DELETE_MASTER_BUSINESS_UNIT';
export const FAIL_DELETE_MASTER_BUSINESS_UNIT = '@@fail/DELETE_MASTER_BUSINESS_UNIT';

export const deleteMasterBusinessUnit = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemasterbusinessunit`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DELETE_MASTER_BUSINESS_UNIT,
      RECEIVE_DELETE_MASTER_BUSINESS_UNIT,
      FAIL_DELETE_MASTER_BUSINESS_UNIT,
    ],
  },
});

export const saveMasterBusinessUnit = (data, criteria) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterbusinessunit`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_BUSINESS_UNIT,
      RECEIVE_SAVE_MASTER_BUSINESS_UNIT,
      FAIL_SAVE_MASTER_BUSINESS_UNIT,
    ],
  },
});

export const searchMasterBusinessUnit = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbusinessunit', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_BUSINESS_UNIT,
      RECEIVE_SEARCH_MASTER_BUSINESS_UNIT,
      FAIL_SEARCH_MASTER_BUSINESS_UNIT,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_BUSINESS_UNIT,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_BUSINESS_UNIT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_BUSINESS_UNIT_CRITERIA,
  payload: {
    name,
    value,
  },
});
