import qs from 'querystring';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import FacilitySetUp from './FacilitySetUp';
import {
  editCriteria,
  clearCriteria,
  updateSearchResult,
  searchFacilitySetUp,
  saveFacilitySetUpDetail,
} from '../../../reducers/facilitySetUp/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-facilitysetup';

class FacilitySetUpContainer extends Component {
  state = {
    transactionNo: '',
    modal: '',
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );

    let query = this.props.routeProp.location.search.replace('?', '');
    query = qs.parse(query);
    if (query.s !== undefined && query.s === 'true') {
      this.props.searchFacilitySetUp({ ...this.props.criteria });
    }
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
  }

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchFacilitySetUp({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      transactionNo: rowData.transactionNo,
      modal,
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const submit = {
      transactionNo: this.state.transactionNo,
      status: modal,
      mode: 'change',
    };
    this.props.saveFacilitySetUpDetail({ ...submit }).then((response) => {
      if (response.error) {
        this.modalInactiveRef.close();
        return;
      }
      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchFacilitySetUp({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <FacilitySetUp
        {...props}
        {...this.state}
        criteria={this.props.criteria}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInactiveRef={(e) => (this.modalInactiveRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.facilitySetUp,
  }),
  {
    searchFacilitySetUp,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    saveFacilitySetUpDetail,
  }
)(FacilitySetUpContainer);
