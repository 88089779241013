import InputMask from 'inputmask';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import PlanApprove from './PlanApprove';
import ImportExcel from '../../common/ImportExcel';
import { addAlert } from '../../reducers/layout/action';
import {
  toFixed,
  toLower,
  getPermissionStr,
  checkAfterValueDate,
  clearCheckBoxAllTable,
} from '../../common/helpper';
import {
  dupicate,
  importExcel,
  setSelected,
  changeStatus,
  searchCashPlan,
  setSelectedAll,
  changeValueDate,
  saveImportExcel,
  editSearchResult,
} from '../../reducers/cashPlan/action';

const $ = window.jQuery;
const { moment } = window;
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';
export const POPOVER_ACTION_CONTEXT = 'button.btn_popover_action';
export const BTN_CONFIRM_ACTION_CLASS = 'btn-cf-action';
export const DATATABLE_ID = 'table-result-plan-and-approve';

const permission = getPermissionStr('Cash Management', 'Plan & Approve Transaction');
const viewInflow = toLower(permission) === 'view (inflow)';
const viewOutflow = toLower(permission) === 'view (outflow)';

const jwt = JSON.parse(localStorage.getItem('jwt')) || { username: '' };
const username = jwt.username || '';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companys: [''],
  ValueDateStr: '',
  Currencys: [''],
  Banks: [''],
  CashType: '',
  Status: '',
  SourceTypes: [''],
  CreatedBy: '',
};

const initChangeStatus = {
  action: '',
  groupPass: [],

  icon: '',
  type: '',
  textheader: '',
  textContent: '',
  textBtn: 'Confirm',
  textBtnBack: 'Back',
  selectedLength: 0,
  passLength: 0,
  showFail: true,
  noSelect: false,
  resultFail: [],
};

class PlanApproveContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    modalValueDate: '',
    isMillion: false,
    tab: 'all',
    results: [],
    modalChangeStatus: {
      ...initChangeStatus,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').popover({
      selector: POPOVER_ACTION_CONTEXT,
      html: true,
      container: 'body',
      title: 'Duplicate',
      content() {
        const $tr = $(this).closest('tr');
        const $dataTableApi = $(`#${DATATABLE_ID}`).DataTable();
        const $row = $dataTableApi.row($tr);
        const rowData = $row.data();
        const $form = $(`<form class="form form-horizontal" data-row="${$row.index()}">
                <div class="form-group col-sm-12">
                    <label class="col-sm-4 control-label">Value Date</label>
                    <div class="col-sm-8"><input class="form-control in-pop input-value-date" /></div>
                </div>
                <div class="form-group col-sm-12">
                    <label class="col-sm-4 control-label">Amount<span style="color:#e64a19">*</span></label>
                    <div class="col-sm-8"><input class="form-control in-pop input-amount" type="text" /></div>
                </div>
                <div class="form-group col-sm-12">
                    <div class="col-sm-6"><button type="button" class="btn btn-labeled btn-sm btn-primary ${BTN_CONFIRM_ACTION_CLASS}" aria-label="Help"><span class="btn-label"><span class="icon icon-check icon-lg icon-fw"></span></span>SAVE</button></div>
                    <div class="col-sm-6"><button type="button" class="btn btn-labeled btn-sm btn-default dismise-popover" aria-label="Help"><span class="btn-label"><span class="icon icon-close icon-lg icon-fw"></span></span>CANCEL</button></div>
                </div>
                </form>`);
        const $inputs = $form.find('input');
        InputMask('currency', { prefix: '', digits: rowData.currencyDigit }).mask($inputs.eq(1));
        $inputs
          .eq(0)
          .datepicker({
            startDate: 'd',
            daysOfWeekDisabled: '0,6',
            todayHighlight: true,
            format: 'dd/mm/yyyy',
          })
          .datepicker('setDate', window.moment().add(1, 'days').format('DD/MM/YYYY'));
        return $form;
      },
    });
    $('body').on('click', `.${BTN_CONFIRM_ACTION_CLASS}`, this.onClickConfirmAction);
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);

    if (toLower(permission).includes('create')) {
      this.setState({
        criteria: {
          ...initCriteria,
          CreatedBy: username,
        },
      });
    } else if (toLower(permission).includes('view')) {
      this.setState({
        criteria: {
          ...initCriteria,
          CashType: viewInflow ? 'Inflow' : viewOutflow ? 'Outflow' : '',
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchResult !== this.props.searchResult) {
      this.setState({
        results: this.filterSearch(),
      });
    }
  }

  componentWillUnmount() {
    $(`#${POPOVER_ACTION_CONTEXT}`).popover('destroy');
    $('body').off('click', `.${BTN_CONFIRM_ACTION_CLASS}`, this.onClickConfirmAction);
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
  }

  filterSearch = () => {
    const { tab } = this.state;
    const data = this.props.searchResult.filter((f) => {
      if (tab === 'all') return true;
      if (tab === 'cash') return (f.sourceType || '').toLowerCase() === 'cash plan';
      if (tab === 'loan') return (f.sourceType || '').toLowerCase() === 'loan & investment';
      if (tab === 'interface')
        return (
          (f.sourceType || '').toLowerCase() === 'interface' ||
          (f.sourceType || '').toLowerCase() === 'ap-invoice' ||
          (f.sourceType || '').toLowerCase() === 'ap-payment' ||
          (f.sourceType || '').toLowerCase() === 'blockchain'
        );
      if (tab === 'fx') return (f.sourceType || '').toLowerCase() === 'fx management';
      return false;
    });
    return data;
  };

  onClickConfirmAction = (e) => {
    const $e = $(e.currentTarget);
    const $ctx = $e.closest('div.popover');
    const $form = $e.closest('form');
    const rowIndex = $form.data().row;
    const $inputValueDate = $form.find('input.input-value-date');
    const $inputAmount = $form.find('input.input-amount');
    if (!$inputValueDate.val()) {
      this.props.addAlert({
        body: 'Please specific Value Date',
        type: 'error',
        title: 'Error',
      });
      return;
    }
    if (!$inputAmount.val() || $inputAmount.val() <= 0) {
      this.props.addAlert({
        body: 'Amount must not less than 0.',
        type: 'error',
        title: 'Error',
      });
      return;
    }
    const currentData = this.state.results[rowIndex];
    const submitedData = {
      ValueDateStr: $inputValueDate.val(),
      TransactionNo: currentData.transactionNo,
      Amount: $inputAmount[0].inputmask.unmaskedvalue(),
    };

    if (
      submitedData.ValueDateStr.trim() === moment().format('DD/MM/YYYY') ||
      (submitedData.ValueDateStr.trim() === moment().add(1, 'days').format('DD/MM/YYYY') &&
        moment().hours() >= 12) ||
      (moment().format('e') === '5' &&
        moment().hours() >= 12 &&
        submitedData.ValueDateStr.trim() === moment().add(3, 'days').format('DD/MM/YYYY'))
    ) {
      this.props.addAlert({
        type: 'warning',
        title: 'Warning',
        body: 'Please inform the finance team as the transaction is over the cut-off time.',
        buttons: [
          <button
            className="btn btn-warning btn-sm"
            data-dismiss="modal"
            onClick={(e) => this.submitDupicate(submitedData, $ctx)}
          >
            Confirm
          </button>,
          <button className="btn btn-default btn-sm" data-dismiss="modal">
            Cancel
          </button>,
        ],
      });
    } else {
      this.submitDupicate(submitedData, $ctx);
    }
  };

  submitDupicate = (submitedData, $ctx) => {
    this.props.dupicate(submitedData, this.state.criteria);
    $ctx.popover('hide');
  };

  onClickOpenPopoverAction = (e, cell, data) => {};

  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props
      .searchCashPlan({
        ...this.state.criteria,
      })
      .then((response) => {
        if (!response.error) this.props.editSearchResult('IsMillion', this.state.isMillion, null);

        clearCheckBoxAllTable(DATATABLE_ID);
      });
  };

  onClickClearSearch = () => {
    if (toLower(permission).includes('create')) {
      this.setState({
        criteria: {
          ...initCriteria,
          CreatedBy: username,
        },
      });
    } else if (toLower(permission).includes('view')) {
      this.setState({
        criteria: {
          ...initCriteria,
          CashType: viewInflow ? 'Inflow' : viewOutflow ? 'Outflow' : '',
        },
      });
    } else {
      this.setState({
        criteria: {
          ...initCriteria,
        },
      });
    }
  };

  onClickUploadHandler = () => {
    this.importExcel.open();
  };

  onSubmitUploadExcel = (file) => {
    const form = new FormData();
    form.append('file', file);

    this.props.importExcel(form);
  };

  // Change Value Date
  onClickOpenModalChangeValueDateHandler = (e, cell) => {
    const selectedData = this.state.results.filter((f) => f.IsSelected);
    const group1 = [];
    const group2 = [];
    const group3 = [];
    const group4 = [];

    const valuesDate = [];
    selectedData.forEach((m) => {
      valuesDate.push(m.valueDateStr);
      if (
        toLower(m.status) === 'wait for approve' &&
        checkAfterValueDate(m.valueDateStr) &&
        toLower(m.sourceType) === 'cash plan'
      )
        group1.push(m);
      else if (toLower(m.sourceType) !== 'cash plan') group2.push(m);
      else if (!checkAfterValueDate(m.valueDateStr)) group4.push(m);
      else group3.push(m);
    });

    if (group2.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select transaction that source type is "Cash Plan" only!',
      });
      return;
    }

    if (group4.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select transaction that value date not over due date.',
      });
      return;
    }

    if (group3.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select transaction that status is "Wait for approve" only!',
      });
      return;
    }

    const distinctValuedate = valuesDate.filter((m, i) => valuesDate.indexOf(m) === i);

    this.setState(
      {
        modalValueDate:
          distinctValuedate.length > 1 || distinctValuedate.length === 0
            ? moment().add(1, 'days').format('DD/MM/YYYY')
            : distinctValuedate[0],
      },
      this.modalChangeValueDateRef.open
    );
  };

  onChangeValueDateHeadler = (e) => {
    this.setState({
      modalValueDate: e.target.value,
    });
  };

  onClickSaveModalChangeValueDateHandler = (e) => {
    e.preventDefault();

    if (!this.state.modalValueDate) {
      this.props.addAlert({
        body: 'Please specific value date',
        type: 'error',
        title: 'Error',
      });
      return;
    }
    const { modalValueDate } = this.state;
    if (
      modalValueDate.trim() === moment().format('DD/MM/YYYY') ||
      (modalValueDate.trim() === moment().add(1, 'days').format('DD/MM/YYYY') &&
        moment().hours() >= 12) ||
      (moment().format('e') === '5' &&
        moment().hours() >= 12 &&
        modalValueDate.trim() === moment().add(3, 'days').format('DD/MM/YYYY'))
    ) {
      this.props.addAlert({
        type: 'warning',
        title: 'Warning',
        body: 'Please inform the finance team as the transaction is over the cut-off time.',
        buttons: [
          <button
            className="btn btn-warning btn-sm"
            data-dismiss="modal"
            onClick={(e) => this.submitChangeValueDate(modalValueDate)}
          >
            Confirm
          </button>,
          <button className="btn btn-default btn-sm" data-dismiss="modal">
            Cancel
          </button>,
        ],
      });
    } else {
      this.submitChangeValueDate();
    }
  };

  submitChangeValueDate = () => {
    const selectedData = conditionChangeStatus.changeValueDate(this.state.results);
    const selectedTransectionNo = selectedData.map((m) => m.transactionNo);
    this.props
      .changeValueDate(
        {
          TransactionNo: selectedTransectionNo,
          ValueDateStr: this.state.modalValueDate,
        },
        this.state.criteria
      )
      .then((response) => {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.modalChangeValueDateRef.close();
        this.setState({
          modalValueDate: '',
        });
      });
  };

  onClickOpenModalChangeStatusHandler = (modalname) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);

    let action = '';
    let icon = '';
    let type = '';
    let textHeader = '';
    let textContent = '';
    let textBtn = 'Confirm';
    const textBtnBack = 'Back';
    const resultFail = [];

    const groupPass = [];
    const groupFail = [];
    const groupFail2 = [];
    const groupFail3 = [];

    if (modalname === 'approve') {
      selected.forEach((m) => {
        if (toLower(m.status) === 'wait for approve' && checkAfterValueDate(m.valueDateStr))
          groupPass.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail.push(m);
        else groupFail2.push(m);
      });

      action = 'Approve';
      icon = 'icon-check';
      type = 'success';
      textHeader = 'Approve';
      textContent = 'approve';
      textBtn = 'Confirm';

      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because status is not : Wait for approve.
          </span>
        );
    } else if (modalname === 'withdraw') {
      selected.forEach((m) => {
        if (toLower(m.status) === 'approved' && checkAfterValueDate(m.valueDateStr))
          groupPass.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail.push(m);
        else groupFail2.push(m);
      });

      action = 'Withdraw';
      icon = 'icon-refresh';
      type = 'warning';
      textHeader = 'Withdraw';
      textContent = 'withdraw';
      textBtn = 'Confirm';

      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because status is not : Approved.
          </span>
        );
    } else if (modalname === 'disapprove') {
      selected.forEach((m) => {
        if (
          toLower(m.status) === 'wait for approve' &&
          checkAfterValueDate(m.valueDateStr) &&
          toLower(m.sourceType) !== 'loan & investment' &&
          toLower(m.sourceType) !== 'fx management'
        )
          groupPass.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail.push(m);
        else if (
          toLower(m.sourceType) === 'loan & investment' ||
          toLower(m.sourceType) === 'fx management'
        )
          groupFail3.push(m);
        else groupFail2.push(m);
      });

      action = 'Disapprove';
      icon = 'icon-close';
      type = 'danger';
      textHeader = 'Disapprove';
      textContent = 'disapprove';
      textBtn = 'Confirm';

      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail3.length)
        resultFail.push(
          <span>
            {groupFail3.length} item(s) selected,
            <br />
            because source type is : Loan & Investment or FX Manament.
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because status is not : Wait for approve.
          </span>
        );
    } else if (modalname === 'cancel') {
      selected.forEach((m) => {
        if (
          toLower(m.status) === 'wait for approve' &&
          checkAfterValueDate(m.valueDateStr) &&
          toLower(m.sourceType) === 'cash plan'
        )
          groupPass.push(m);
        else if (!checkAfterValueDate(m.valueDateStr)) groupFail.push(m);
        else if (toLower(m.sourceType) !== 'cash plan') groupFail3.push(m);
        else groupFail2.push(m);
      });

      action = 'Cancel';
      icon = 'icon-close';
      type = 'danger';
      textHeader = 'Cancel';
      textContent = 'cancel';
      textBtn = 'Confirm';

      if (groupFail.length)
        resultFail.push(
          <span>
            {groupFail.length} item(s) selected,
            <br />
            because value date over due date.
          </span>
        );
      if (groupFail3.length)
        resultFail.push(
          <span>
            {groupFail3.length} item(s) selected,
            <br />
            because source type is not : Cash Plan.
          </span>
        );
      if (groupFail2.length)
        resultFail.push(
          <span>
            {groupFail2.length} item(s) selected,
            <br />
            because status is not : Wait for approve.
          </span>
        );
    }

    this.setState(
      {
        modalChangeStatus: {
          ...initChangeStatus,
          action,
          groupPass,

          icon,
          type,
          textHeader,
          textContent,
          textBtn,
          textBtnBack,
          showFail: true,
          noSelect: false,
          selectedLength: selected.length,
          passLength: groupPass.length,
          resultFail,
        },
      },
      this.modalChangeStatusRef.open
    );
  };

  onClickConfirmChangeStatusHandler = (e) => {
    const { modalChangeStatus } = this.state;
    const selectedData = modalChangeStatus.groupPass;
    const status = modalChangeStatus.action;

    const selectedTransectionNo = selectedData.map((m) => m.trNo);

    this.props
      .changeStatus(
        {
          TransactionNo: selectedTransectionNo,
          Status: status,
        },
        this.state.criteria
      )
      .then((response) => {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.modalChangeStatusRef.close();
      });
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.setSelectedAll(!checked);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const $td = $(e.currentTarget);
    const row = $dt.fixedColumns().rowIndex($td.closest('tr'));
    const checkbox = $td.find('input');
    if (!checkbox.length) return;

    const checked = !checkbox[0].checked;
    const selected = this.state.results[row];
    selected.IsSelected = checked;
    $dt.row(row).invalidate().draw(false);
    this.forceUpdate();
  };

  onClickNavToEditCashPlan = (e, cell) => {
    const data = cell.row(cell.index().row).data();
    this.props.routeProp.history.push(`/cash-management/cash-plan/${data.transactionNo}`);
  };

  onClickSaveImport = () => {
    const { importExcelResult } = this.props;

    const invalidValueDate = importExcelResult.rows.filter((f) => {
      if (!f.valueDateStr) return true;

      const valueDateMoment = moment(f.valueDateStr.trim(), 'DD/MM/YYYY');
      // ลยอนหลัง
      return (
        valueDateMoment.isBefore(moment(), 'day') ||
        // weekend
        ~['6', '0'].indexOf(valueDateMoment.format('e'))
      );
    });

    if (invalidValueDate.length) {
      this.props.addAlert({
        type: 'error',
        title: 'Error',
        body: 'Value date is invalids',
      });
      return;
    }

    const errorRow = importExcelResult.rows.filter((f) => {
      if (!f.valueDateStr) return true;
      return moment(f.valueDateStr.trim(), 'DD/MM/YYYY').isBefore(moment(), 'day');
    });
    let warningRow = [];
    if (!errorRow.length)
      warningRow = importExcelResult.rows.filter((f) => {
        if (!f.valueDateStr) return true;
        return (
          f.valueDateStr.trim() === moment().format('DD/MM/YYYY') ||
          (f.valueDateStr.trim() === moment().add(1, 'days').format('DD/MM/YYYY') &&
            moment().hours() >= 12) ||
          (moment().format('e') === '5' &&
            moment().hours() >= 12 &&
            f.valueDateStr.trim() === moment().add(3, 'days').format('DD/MM/YYYY'))
        );
      });

    if (errorRow.length) {
      this.props.addAlert({
        type: 'error',
        title: 'Error',
        body: 'Value date must not before today',
      });
    } else if (warningRow.length)
      this.props.addAlert({
        type: 'warning',
        title: 'Warning',
        body: 'Please inform the finance team as the transaction is over the cut-off time.',
        buttons: [
          <button
            className="btn btn-warning btn-sm"
            data-dismiss="modal"
            onClick={(e) =>
              this.props.saveImportExcel().then((response) => {
                this.importExcel.close();
              })
            }
          >
            Confirm
          </button>,
          <button className="btn btn-default btn-sm" data-dismiss="modal">
            Cancel
          </button>,
        ],
      });
    else
      this.props.saveImportExcel().then((response) => {
        this.importExcel.close();
      });
  };

  onChangeToggleMillion = (e) => {
    const { checked } = e.target;
    this.setState(
      {
        [e.target.name]: checked,
      },
      () => {
        this.props.editSearchResult('IsMillion', checked, null);
      }
    );
  };

  onChangeTab = (tabName) => {
    if (tabName === this.state.tab) return;
    this.setState(
      {
        tab: tabName,
      },
      () => {
        const data = this.filterSearch();
        this.setState({
          results: data,
        });
      }
    );
  };

  render() {
    const props = {
      onChangeToggleMillion: this.onChangeToggleMillion,
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      onClickUpload: this.onClickUploadHandler,

      onClickOpenModalChangeValueDate: this.onClickOpenModalChangeValueDateHandler,
      onChangeValueDate: this.onChangeValueDateHeadler,
      onClickSaveModalChangeValueDate: this.onClickSaveModalChangeValueDateHandler,

      onClickConfirmChangeStatus: this.onClickConfirmChangeStatusHandler,
      onClickOpenModalChangeStatus: this.onClickOpenModalChangeStatusHandler,

      onClickOpenPopoverAction: this.onClickOpenPopoverAction,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      conditionChangeStatus,
      onChangeTab: this.onChangeTab,

      allSearchResult: this.props.searchResult,
    };

    return (
      <div>
        <PlanApprove
          {...props}
          {...this.state}
          modalChangeValueDateRef={(e) => (this.modalChangeValueDateRef = e)}
          modalChangeStatusData={this.state.modalChangeStatus}
          modalChangeStatusRef={(e) => (this.modalChangeStatusRef = e)}
        />
        <ImportExcel
          tableName="import-excel-cash-plan"
          value={this.props.importExcelResult}
          optionDt={importDtOption}
          ref={(e) => (this.importExcel = e)}
          onUpload={this.onSubmitUploadExcel}
          onClickSaveImport={this.onClickSaveImport}
        >
          <thead>
            <tr>
              <th>Company Code</th>
              <th>Value Date</th>
              <th>Bank Account</th>
              <th>Cash Type</th>
              <th>Amount</th>
              <th>Currency</th>
              <th>Exchange Rate</th>
              <th>Partner Type</th>
              <th>Company Code Intercom</th>
              <th>Vendor/Customer Name/Intercom</th>
              <th>Bank Account Intercom</th>
              <th>Exchange Rate Intercom</th>
              <th>Transfer Method</th>
              <th>Transaction Details</th>
              <th>Error</th>
            </tr>
          </thead>
        </ImportExcel>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.cashPlan,
  }),
  {
    importExcel,
    searchCashPlan,
    dupicate,
    changeStatus,
    changeValueDate,
    addAlert,
    saveImportExcel,
    setSelected,
    setSelectedAll,
    editSearchResult,
  }
)(PlanApproveContainer);

const importDtOption = {
  columns: [
    { data: 'companyCode', className: 'text-center' },
    { data: 'valueDateStr', className: 'text-center' },
    { data: 'bankAccountNo' },
    { data: 'cashType' },
    { data: 'amount', className: 'text-right', render: (d) => toFixed(d) },
    { data: 'currency', className: 'text-center' },
    { data: 'exchangeRate', className: 'text-right', render: (d) => toFixed(d, 8) },
    { data: 'partnerType' },
    { data: 'intercomCompanyCode', className: 'text-center' },
    { data: 'intercomCompanyName' },
    { data: 'intercomBankAccountNo' },
    { data: 'intercomExchangeRate', className: 'text-right', render: (d) => toFixed(d, 8) },
    { data: 'transferMethod' },
    { data: 'transactionDetail' },
    { data: 'errorMessage' },
  ],
};

const conditionChangeStatus = {
  approve: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        f.status.toLowerCase() === 'wait for approve' &&
        moment(f.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(-1, 'days'), 'day')
    ),
  withdraw: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        f.status.toLowerCase() === 'approved' &&
        moment(f.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(-1, 'days'), 'day')
    ),
  disApprove: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        f.status.toLowerCase() === 'wait for approve' &&
        moment(f.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(-1, 'days'), 'day')
    ),
  cancel: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        f.status.toLowerCase() === 'wait for approve' &&
        moment(f.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(-1, 'days'), 'day')
    ),
  changeValueDate: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        f.status.toLowerCase() === 'wait for approve' &&
        moment(f.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(-1, 'days'), 'day')
    ),
};
