import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_WITH_HOLDING_TAX_CRITERIA = 'EDIT_MASTER_WITH_HOLDING_TAX_CRITERIA';
export const CLEAR_MASTER_WITH_HOLDING_TAX_CRITERIA = 'CLEAR_MASTER_WITH_HOLDING_TAX_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_WITH_HOLDING_TAX =
  'UPDATE_SEARCH_RESULT_MASTER_WITH_HOLDING_TAX';

export const REQUEST_SEARCH_MASTER_WITH_HOLDING_TAX = '@@request/SARCH_MASTER_WITH_HOLDING_TAX';
export const RECEIVE_SEARCH_MASTER_WITH_HOLDING_TAX = '@@receive/SEARCH_MASTER_WITH_HOLDING_TAX';
export const FAIL_SEARCH_MASTER_WITH_HOLDING_TAX = '@@fail/SEARCH_MASTER_WITH_HOLDING_TAX';

export const REQUEST_UPDATE_MASTER_WITH_HOLDING_TAX = '@@request/UPDATE_MASTER_WITH_HOLDING_TAX';
export const RECEIVE_UPDATE_MASTER_WITH_HOLDING_TAX =
  '@@receive-The record was successfully saved/UPDATE_MASTER_WITH_HOLDING_TAX';
export const FAIL_UPDATE_MASTER_WITH_HOLDING_TAX = '@@fail/UPDATE_MASTER_WITH_HOLDING_TAX';

export const REQUEST_SEARCH_MASTER_COMPANY = '@@request/SARCH_MASTER_COMPANY';
export const RECEIVE_SEARCH_MASTER_COMPANY = '@@receive/SEARCH_MASTER_COMPANY';
export const FAIL_SEARCH_MASTER_COMPANY = '@@fail/SEARCH_MASTER_COMPANY';

export const getMasterCompany = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/company', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_COMPANY,
      RECEIVE_SEARCH_MASTER_COMPANY,
      FAIL_SEARCH_MASTER_COMPANY,
    ],
  },
});

export const updateMasterWithHoldingTax = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveMasterWithHoldingTax`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_UPDATE_MASTER_WITH_HOLDING_TAX,
      RECEIVE_UPDATE_MASTER_WITH_HOLDING_TAX,
      FAIL_UPDATE_MASTER_WITH_HOLDING_TAX,
    ],
  },
});

export const searchMasterWithHoldingTax = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/GetMasterWithHoldingTax', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_WITH_HOLDING_TAX,
      RECEIVE_SEARCH_MASTER_WITH_HOLDING_TAX,
      FAIL_SEARCH_MASTER_WITH_HOLDING_TAX,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_WITH_HOLDING_TAX,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_WITH_HOLDING_TAX_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_WITH_HOLDING_TAX_CRITERIA,
  payload: {
    name,
    value,
  },
});
