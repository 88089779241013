import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { DATATABLE_ID } from './MaintainBranchContainer';
import { MASTER_BANK } from '../../reducers/master/action';
import { toStatus, getPermission } from '../../common/helpper';

const permission = getPermission('Bank Guarantee', 'Maintain Branch');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-4', 'col-md-7'];
const colX2 = ['col-md-5', 'col-md-6'];

const opt = {
  order: [[2, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'bankCode', className: 'text-center' },
    { data: 'bankName' },
    { data: 'isHeadOffice', className: 'text-center', render: (d) => (d ? 'Yes' : '') },
    { data: 'isHeadOfficePaper', className: 'text-center', render: (d) => (d ? 'Yes' : '') },
    { data: 'branchCode' },
    { data: 'branchName' },
    { data: 'address' },
    { data: 'phoneNo' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Mode === 'edit';
  const checkSave = !!((modalData.Mode === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Bank Guarantee <span className="icon icon-angle-double-right" /> Maintain Branch
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Branch Code">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.BranchCode}
                  name="BranchCode"
                />
              </FormGroup2>

              <FormGroup2 text="Branch Name">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.BranchName}
                  name="BranchName"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.BankCode}
                  // filter={m => {
                  //     return m.bankCode === 'SICOTH' || m.bankCode === 'KASITH'
                  // }}
                  name="BankCode"
                />
              </FormGroup2>

              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.IsActive}
                  onChange={onChangeSearch}
                  name="IsActive"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="Maintain Branch"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalData}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Swift Code</th>
              <th>Bank Name</th>
              <th>
                Head Office
                <br /> [Blockchain]
              </th>
              <th>
                Head Office
                <br /> [Paper]
              </th>
              <th>Branch Code</th>
              <th>Branch Name</th>
              <th>Address</th>
              <th>Telephone</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Maintain Branch" bgHeader="bg-primary" size="modal-md" ref={modalDataRef}>
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <FormGroup3 text="Bank" col={colX1} required>
            <MasterDropdown
              masterType={MASTER_BANK}
              isChoose
              notMultipleSelect2
              value={modalData.BankCode}
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              // filter={m => {
              //     return m.bankCode === 'SICOTH' || m.bankCode === 'KASITH'
              // }}
              noValidateOption
              saveLocalState
              required
              name="BankCode"
            />
          </FormGroup3>

          <FormGroup3 text="Branch Code" col={colX1} required>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.BranchCode || ''}
              disabled={modeEdit}
              required
              name="BranchCode"
              maxLength={4}
            />
          </FormGroup3>

          <FormGroup3 text="Branch Name" col={colX1} required>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.BranchName || ''}
              disabled={modeEdit}
              required
              name="BranchName"
            />
          </FormGroup3>

          <FormGroup3 text="Address" col={colX1} required>
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeModalData}
              value={modalData.Address || ''}
              disabled={modeEdit}
              required
              name="Address"
            />
          </FormGroup3>

          <FormGroup3 text="Telephone" col={colX1} required>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.PhoneNo || ''}
              disabled={modeEdit}
              required
              name="PhoneNo"
            />
          </FormGroup3>

          <FormGroup3 text="Head Office [Blockchain]" col={colX2}>
            <div>
              <input
                id="IsHeadOffice"
                type="checkbox"
                className="label-checkbox"
                onChange={onChangeModalData}
                name="IsHeadOffice"
                checked={modalData.IsHeadOffice}
                disabled={modeEdit}
              />
              <label htmlFor="IsHeadOffice" className="label_checkbox" />
            </div>
          </FormGroup3>

          <FormGroup3 text="Head Office [Paper]" col={colX2}>
            <div>
              <input
                id="IsHeadOfficePaper"
                type="checkbox"
                className="label-checkbox"
                onChange={onChangeModalData}
                name="IsHeadOfficePaper"
                checked={modalData.IsHeadOfficePaper}
                disabled={modeEdit}
              />
              <label htmlFor="IsHeadOfficePaper" className="label_checkbox" />
            </div>
          </FormGroup3>
          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
