import React from 'react';

import Card from '../../../common/Card';
import Table from '../../../common/DataTable';
import { DATATABLE_ID } from './FileUploadContainer';
import { getPermission } from '../../../common/helpper';

const permission = getPermission('Form', 'File Upload');

const opt = {
  order: [[1, 'asc']],
  columns: [
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) =>
        // if (!permission) return ''
        `<a class="btn btn-icon btn-info" href="${r.pathFile}"><span class="icon icon-download sq-24"></span></a>`,
    },
    { data: 'templateName' },
    { data: 'formatFile' },
  ],
};

export default ({ dataTableRef, results }) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Form <span className="icon icon-angle-double-right" /> File Upload
        </small>
      </p>
    </div>

    <Card textHeader="File Templeate" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Download</th>
            <th>Template Name</th>
            <th>Format File</th>
          </tr>
        </thead>
      </Table>
    </Card>
  </div>
);
