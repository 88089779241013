import React, { Component } from 'react';

import FileUpload from './FileUpload';

export const DATATABLE_ID = 'table-result-file-upload';

class FileUploadContainer extends Component {
  state = {
    results: [
      {
        templateName: 'Create Cash Plan',
        pathFile: '/CP_001_CASHPLAN_01072019.xlsx',
        formatFile: 'CP_FinanceGroupCode_CASHPLAN_DDMMYYY.xlsx',
      },
      {
        templateName: 'Create Exposure',
        pathFile: '/EX_FinanceGroupCode_EXPOSURE_DDMMYYY.xlsx',
        formatFile: 'EX_FinanceGroupCode_EXPOSURE_DDMMYYY.xlsx',
      },
      {
        templateName: 'Create Exposure Inward',
        pathFile: '/IW_001_ Inward_XXXXTH_01072019.xlsx',
        formatFile: 'IW_FinanceGroupCode_ Inward_XXXXTH_DDMMYYY.xlsx',
      },
      {
        templateName: 'Forward Contract',
        pathFile: '/FinanceGroupCode_SwiftCode_Confirmation_DDMMYYY.xlsx',
        formatFile: 'FinanceGroupCode_SwiftCode_Confirmation_DDMMYYY.xlsx',
      },
      {
        templateName: 'Upload T/T Remittance',
        pathFile: '/TT_Upload Tempalate.xlsx',
        formatFile: '-',
      },
      {
        templateName: 'MTM Forward',
        pathFile: '/BankSWIFTCode_MTMForward_ReportDate(YYYYMMDD)_FileNumber.xlsx',
        formatFile: 'BankSWIFTCode_MTMForward_ReportDate(YYYYMMDD)_FileNumber.xlsx',
      },
      {
        templateName: 'MTM Swap',
        pathFile: '/BankSWIFTCode_MTMSwap_ReportDate(YYYYMMDD)_FileNumber.xlsx',
        formatFile: 'BankSWIFTCode_MTMSwap_ReportDate(YYYYMMDD)_FileNumber.xlsx',
      },
      {
        templateName: 'Upload Calendar',
        pathFile: '/Calendar Year YYYY_Bank TH.xlsx',
        formatFile: '-',
      },
    ],
  };

  dataTableSearchResultRef = null;

  render() {
    const props = {
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
    };

    return (
      <div>
        <FileUpload {...props} {...this.state} />
      </div>
    );
  }
}

export default FileUploadContainer;
