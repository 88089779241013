import { connect } from 'react-redux';
import React, { Component } from 'react';

import ForwardRate from './ForwardRate';
import InputMask from '../../common/InputMask';
import { addAlert } from '../../reducers/layout/action';
import {
  getExposureForwardRate,
  saveExposureForwardRate,
} from '../../reducers/exposurePosition/action';

class ForwardRateContainer extends Component {
  state = {
    ExposureNos: [''],
    results: [],
    theads: [],
    sumOutstandingExposure: [],
    exposureMainKeys: [],
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.t;
    if (!transactionNo) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select transaction no for RollOVer',
        type: 'error',
      });
      return;
    }

    this.props
      .getExposureForwardRate({
        ExposureIds: transactionNo.split(','),
      })
      .then((response) => {
        if (!response.error) {
          if ((response.payload || []).length) {
            const theads = this.renderThExposure(response.payload[0]);
            const exposureMainKeys = this.getMainExposureKeys(response.payload[0]);
            const results = response.payload.map((m) => {
              const newData = {
                ...m,
                usedAmount: this.calUsedAmount(m, exposureMainKeys),
              };
              // convert amount from decimal to string for use inputmask
              exposureMainKeys.forEach((key) => {
                newData[`${key}_Amount`] = (m[`${key}_Amount`] || 0).toString();
              });

              return newData;
            });
            const sumOutstandingExposure = exposureMainKeys.map((key) => {
              const firstOutstandingExposure = results[0][`${key}_Outstanding`];
              const totalExposure = results.reduce(
                (prev, cur, slef) => prev + Number(cur[`${key}_Amount`] || 0),
                0
              );
              return firstOutstandingExposure - totalExposure;
            });
            this.setState({
              theads,
              results,
              exposureMainKeys,
              sumOutstandingExposure,
            });
          } else
            this.setState({
              results: [],
            });
        }
      });
  }

  onClickBackToExposurePositionPage = () => {
    this.props.routeProp.history.push('/fx-management/exposure-position?s');
  };

  calUsedAmount(row, exposureMainKeys) {
    const total = exposureMainKeys.reduce((prev, m) => prev + Number(row[`${m}_Amount`] || 0), 0);
    return total;
  }

  onChangeInputTable = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const { exposureMainKeys } = state;
      const results = state.results.map((m, i, s) => {
        if (i === index) {
          const newData = {
            ...m,
            [name]: value,
          };
          newData.usedAmount = this.calUsedAmount(newData, [...this.state.exposureMainKeys]);
          const mainKey = name.split('_').slice(0, 4).join('_');
          // sum ยอด exposure ที่กรอกในแต่ละ forward
          const sumTotalExposure = s.reduce((prev, cur, curIdx) => {
            if (curIdx === i)
              // ใช้ยอดที่เพิ่งกรอกตะกี่
              return prev + Number(value || 0);
            return prev + Number(cur[`${mainKey}_Amount`] || 0);
          }, 0);

          // validate amount
          if (
            m.outstanding < newData.usedAmount ||
            Number(m[`${mainKey}_Outstanding`] || 0) < Number(value || 0) ||
            sumTotalExposure > Number(m[`${mainKey}_Outstanding`] || 0)
          )
            return m;
          return newData;
        }
        return m;
      });
      const sumOutstandingExposure = exposureMainKeys.map((key) => {
        const firstOutstandingExposure = results[0][`${key}_Outstanding`];
        const totalExposure = results.reduce(
          (prev, cur, slef) => prev + Number(cur[`${key}_Amount`] || 0),
          0
        );
        return firstOutstandingExposure - totalExposure;
      });
      return {
        results,
        sumOutstandingExposure,
      };
    });
  };

  onClickSaveHandler = (e) => {
    e.preventDefault();

    const submitData = [];
    const { exposureMainKeys, results } = this.state;
    results.forEach((m) => {
      exposureMainKeys.forEach((key) => {
        submitData.push({
          ExposureId: m[`${key}_Id`],
          Amount: m[`${key}_Amount`],
          ForwardNo: m.forwardNo,
        });
      });
    });
    this.props.saveExposureForwardRate(submitData);
    this.props.addAlert({
      title: 'Success',
      body: 'The transaction was successfully saved',
      buttons: [
        <button
          className="btn btn-success"
          onClick={this.onClickBackToExposurePositionPage}
          data-dismiss="modal"
        >
          Continue
        </button>,
      ],
    });
  };

  getMainExposureKeys = (row) => {
    const keys = Object.keys(row);
    return keys
      .filter((f) => ~f.indexOf('exposure_'))
      .map((m) => m.split('_').slice(0, 4).join('_'))
      .filter((f, i, s) => s.indexOf(f) === i);
  };

  renderThExposure(row) {
    const thead = [];
    this.getMainExposureKeys(row).forEach((m) => {
      const s = m.split('_');
      thead.push(
        <th key={m} className="th-primary">
          Exposure {s[1]}
          <br />
          FW Vpay {s[3]}
          <br />
          Line ID {s[2]}
        </th>
      );
    });
    return thead;
  }

  renderTd = (row, i) => {
    const exposuresKey = this.state.exposureMainKeys;
    const td = [];
    exposuresKey.forEach((n) => {
      td.push(
        <td key={n}>
          <InputMask
            key={`${row.forwardNo}_${n}`}
            className={`form-control width-input-table ${row.isForward ? 'input-bg-coral' : ''}`}
            onChange={(e) => this.onChangeInputTable(e, i)}
            format="currency"
            option={{
              prefix: '',
              digits: 2,
              max: row.outstanding || 0,
              digitsOptional: false,
              placeholder: '0.00',
            }}
            value={row[`${n}_Amount`]}
            required
            name={`${n}_Amount`}
          />
        </td>
      );
    });
    return td;
  };

  onClickBackToSearchExposurePosition = (e) => {
    this.props.routeProp.history.push('/fx-management/exposure-position?s');
  };

  render() {
    const props = {
      onChangeInputTable: this.onChangeInputTable,
      onClickSave: this.onClickSaveHandler,
      renderTd: this.renderTd,
      onClickBackToSearchExposurePosition: this.onClickBackToSearchExposurePosition,
    };

    return <ForwardRate {...props} {...this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.exposurePosition,
  }),
  {
    getExposureForwardRate,
    saveExposureForwardRate,
    addAlert,
  }
)(ForwardRateContainer);
