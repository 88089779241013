import React, { useContext } from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import RadioButton from '../../../common/RadioButton';
import { getPermission } from '../../../common/helpper';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import { useOnChangeInput } from '../../../common/customeHook';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import {
  FacilityList,
  StateContext,
  FormRefContext,
  InputMaskOptionContext,
} from './CreateContractLoan';
import {
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_AREA,
} from '../../../reducers/master/action';

const permission = getPermission('Funding&Investment', 'Create Contract Loan');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
export default function StructoreTab({}) {
  const { state, dispatch } = useContext(StateContext);
  const inputMaskOption = useContext(InputMaskOptionContext);
  const { formStructureRef, onClicktSaveLoan, isCreate } = useContext(FormRefContext);
  const facilityList = useContext(FacilityList);
  const onChange = useOnChangeInput(dispatch);

  return (
    <form ref={formStructureRef} name="structure">
      <Card textHeader="Partner" cardActions={['toggler']}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Loan Description" required>
              <input
                className="form-control"
                onChange={onChange}
                name="LoanDescription"
                required
                value={state.LoanDescription}
                readOnly={false}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Loan Type" required>
              <div>
                <RadioButton
                  text="Term"
                  value="Term"
                  checked={state.LoanType === 'Term'}
                  onChange={onChange}
                  name="LoanType"
                  required
                />
              </div>
            </FormGroup2>

            <FormGroup2 text="Partner Type" required>
              <div>
                <RadioButton
                  text="Intercompany"
                  value="Intercompany"
                  checked={state.PartnerType === 'Intercompany'}
                  onChange={onChange}
                  name="PartnerType"
                  required
                  disabled={!isCreate}
                />
                <RadioButton
                  text="External"
                  value="External"
                  checked={state.PartnerType === 'External'}
                  onChange={onChange}
                  name="PartnerType"
                  disabled={!isCreate}
                />
              </div>
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Transaction Type" required>
              <div>
                {state.PartnerType !== 'External' ? (
                  <div>
                    <RadioButton
                      text="Loan"
                      value="Loan"
                      checked={state.TransactionType === 'Loan'}
                      onChange={onChange}
                      name="TransactionType"
                      required
                      disabled={!isCreate}
                    />
                    <RadioButton
                      text="Deposit"
                      value="Deposit"
                      checked={state.TransactionType === 'Deposit'}
                      onChange={onChange}
                      name="TransactionType"
                      disabled={!isCreate}
                    />
                  </div>
                ) : (
                  <input
                    className="form-control"
                    onChange={onChange}
                    name="TransactionType"
                    required
                    value={state.TransactionType}
                    readOnly
                    disabled={!isCreate}
                  />
                )}
              </div>
            </FormGroup2>

            {state.PartnerType === 'Intercompany' ? (
              <FormGroup2 text="IC Type" required>
                <div>
                  <RadioButton
                    text="Master"
                    value="Master"
                    checked={state.IcType === 'Master'}
                    onChange={onChange}
                    name="IcType"
                    required
                  />
                  <RadioButton
                    text="Other"
                    value="Other"
                    checked={state.IcType === 'Other'}
                    onChange={onChange}
                    name="IcType"
                  />
                </div>
              </FormGroup2>
            ) : null}
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Business Partner" required>
              {state.PartnerType === 'External' ? (
                <MasterDropdown
                  key="External"
                  masterType={MASTER_BANK_CODE}
                  noValidateOption={!isCreate}
                  saveLocalState
                  onChange={(e) =>
                    onChange({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                  // companyCode={state.CompanyCode}
                  disabled={!isCreate}
                  value={state.BusinessPartner}
                  name="BusinessPartner"
                  isGetAll
                  isOutFlow
                  isAllBank
                />
              ) : state.PartnerType === 'Intercompany' ? (
                <MasterDropdown
                  key="Intercompany"
                  masterType={MASTER_COMPANY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChange({
                      target: e,
                    })
                  }
                  onlyMainCompany={state.IcType === 'Master'}
                  companyCode={state.IcType === 'Master' ? state.CompanyCode : null}
                  isGetAll={state.IcType === 'Other'}
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  disabled={!isCreate}
                  value={state.BusinessPartner}
                  name="BusinessPartner"
                />
              ) : (
                <MasterDropdownUI
                  onChange={(e) =>
                    onChange({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  disabled={!isCreate}
                  value={state.BusinessPartner}
                  name="BusinessPartner"
                />
              )}
            </FormGroup2>

            <FormGroup2 text="Contract Date" required>
              <DatePicker
                onChange={onChange}
                option={{
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required
                disabled={false}
                value={state.ContractDateStr}
                name="ContractDateStr"
                disabled={!isCreate}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Term Start" required>
              <DatePicker
                onChange={onChange}
                option={{
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required
                disabled={false}
                value={state.TermStartStr}
                name="TermStartStr"
                disabled={!isCreate}
              />
            </FormGroup2>
            {state.LoanType !== 'On Call' ? (
              <FormGroup2 text="Term End" required>
                <DatePicker
                  onChange={onChange}
                  option={{
                    // daysOfWeekDisabled: "0,6",
                    todayHighlight: true,
                  }}
                  required
                  value={state.TermEndStr}
                  name="TermEndStr"
                />
              </FormGroup2>
            ) : null}
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Amount" required>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChange}
                option={
                  inputMaskOption || {
                    prefix: '',
                    digits: 3,
                    // digitsOptional: false,
                    // placeholder: '0.000'
                  }
                }
                required
                value={state.Amount}
                name="Amount"
              />
            </FormGroup2>

            <FormGroup2 text="Currency" required>
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChange({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                required
                value={state.Currency}
                name="Currency"
                disabled={!isCreate}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="External Ref" required={false}>
              <input
                className="form-control"
                onChange={onChange}
                value={state.ExternalRef}
                name="ExternalRef"
                required={false}
              />
            </FormGroup2>
            <div className="form-group">
              <div>
                <input
                  id="AbsorbWitholdingTax"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChange}
                  name="AbsorbWitholdingTax"
                  checked={state.AbsorbWitholdingTax || false}
                />
                <label htmlFor="AbsorbWitholdingTax" className="label_checkbox" />
                <label htmlFor="AbsorbWitholdingTax" className="control-label text_label_checkbox">
                  Absorb Witholding Tax
                </label>
              </div>
            </div>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Drawdown Period" required>
              <DateRangePicker
                onChange={onChange}
                required
                disabled={false}
                value={state.DrawdownPeriodStr}
                name="DrawdownPeriodStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>
      </Card>

      <Card textHeader="Position Assignment" cardActions={['toggler']}>
        {state.PartnerType !== 'Intercompany' ? (
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Facility" required>
                <select
                  tabIndex="-1"
                  className="form-control"
                  onChange={onChange}
                  required={false}
                  value={state.FacilityNo}
                  disabled={!isCreate}
                  name="FacilityNo"
                >
                  <option value="">---Choose---</option>
                  {facilityList.map((m) => (
                    <option value={m.transactionNo} key={m.transactionNo}>
                      {m.facilityName}
                    </option>
                  ))}
                </select>
              </FormGroup2>
              {isCreate ? (
                <FormGroup2 text="Outstanding" required={false}>
                  <InputMask
                    className="form-control"
                    format="currency"
                    onChange={onChange}
                    option={{
                      prefix: '',
                      digits: 0,
                    }}
                    readOnly
                    value={state.Outstanding}
                    name="Outstanding"
                  />
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>
        ) : null}

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Business Area" required={state.BusinessArea}>
              {!isCreate ? (
                <input
                  className="form-control"
                  onChange={onChange}
                  disabled
                  value={state.BusinessAreaName || ''}
                  name="BusinessAreaName"
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_BUSINESS_AREA}
                  noValidateOption={!isCreate}
                  saveLocalState
                  onChange={(e) =>
                    onChange({
                      target: e,
                    })
                  }
                  companyCode={state.CompanyCode}
                  notMultipleSelect2
                  notMultiple={false}
                  isGetAll
                  isChoose={!state.BusinessArea}
                  required={state.BusinessArea}
                  value={state.BusinessArea}
                  name="BusinessArea"
                />
              )}
            </FormGroup2>

            <FormGroup2 text="Guarantor" required={false}>
              <input
                className="form-control"
                onChange={onChange}
                required={false}
                value={state.Guarantor}
                name="Guarantor"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        {state.PartnerType === 'Intercompany' ? (
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Partner Business Area" required={state.BusinessAreaPartner}>
                {!isCreate ? (
                  <input
                    className="form-control"
                    onChange={onChange}
                    disabled
                    value={state.BusinessAreaPartnerName || ''}
                    name="BusinessAreaPartnerName"
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_BUSINESS_AREA}
                    noValidateOption={!isCreate}
                    saveLocalState
                    onChange={(e) =>
                      onChange({
                        target: e,
                      })
                    }
                    companyCode={state.BusinessPartner}
                    notMultipleSelect2
                    notMultiple={false}
                    isGetAll
                    isChoose={!state.BusinessAreaPartner}
                    required={state.BusinessAreaPartner}
                    value={state.BusinessAreaPartner}
                    name="BusinessAreaPartner"
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>
        ) : null}
      </Card>

      {permission ? (
        <div className="row">
          <div className="col-sm-12 text-center">
            <Button
              type="button"
              txt="Save Loan"
              icon="save"
              className="btn-success"
              onClick={onClicktSaveLoan}
            />
          </div>
        </div>
      ) : null}
    </form>
  );
}
