import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import Select2 from '../../common/Select2';
import FormGroup2 from '../../common/FormGroup2';
import ModalFunction from '../../common/ModalFunction';
import { DATATABLE_ID } from './SearchMaintainFormContainer';
import { toStatus, getPermission } from '../../common/helpper';

const permission = getPermission('Bank Guarantee', 'Maintain Form');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[1, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'bgFormName',
      render: (d, t, r) =>
        `<a target="_blank" href="/bank-guarantee/maintain-form/${r.bgFormId}" class="edit-maintain-form"> ${d} </a>`,
    },
    { data: 'formFormat' },
    { data: 'lgTypeName' },
    { data: 'lang', className: 'text-center' },
    { data: 'isOpenEnd', className: 'text-center', render: (d) => (d ? 'Yes' : 'No') },
    { data: 'isCloseEnd', className: 'text-center', render: (d) => (d ? 'Yes' : 'No') },
    { data: 'remark' },
    // {
    //     data: 'isBeneficiaryForm', className: 'text-center',
    //     render: (d, t, r) => {
    //         if (toLower(r.formFormat) === 'paper') {
    //             if (d) return 'Yes'
    //             return 'No'
    //         }
    //         return ''
    //     }
    // },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,

  masterLgTypeList,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Bank Guarantee <span className="icon icon-angle-double-right" /> Maintain Form
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Form No.">
              <input
                className="form-control"
                onChange={onChangeSearch}
                value={criteria.BgFormName}
                name="BgFormName"
              />
            </FormGroup2>

            <FormGroup2 text="LG Format">
              <select
                className="form-control"
                value={criteria.FormFormat}
                onChange={onChangeSearch}
                name="FormFormat"
              >
                <option value="">---All---</option>
                <option value="Paper">Paper</option>
                <option value="Blockchain">Blockchain</option>
              </select>
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Form Type">
              <Select2 value={criteria.LgTypeId} onChange={onChangeSelect2} name="LgTypeId">
                {(masterLgTypeList || []).map((m, i) => (
                  <option
                    key={m.lgTypeId}
                    value={m.lgTypeId}
                  >{`${m.lgTypeCode} : ${m.lgTypeName}`}</option>
                ))}
              </Select2>
            </FormGroup2>

            <FormGroup2 text="Language">
              <select
                className="form-control"
                value={criteria.Lang}
                onChange={onChangeSearch}
                name="Lang"
              >
                <option value="">---All---</option>
                <option value="TH">TH</option>
                <option value="EN">EN</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Status">
              <select
                className="form-control"
                value={criteria.IsActive}
                onChange={onChangeSearch}
                name="IsActive"
              >
                <option value="">---All---</option>
                <option value>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </FormGroup2>

            <FormGroup2 text="Open End">
              <select
                className="form-control"
                value={criteria.IsOpenEnd}
                onChange={onChangeSearch}
                name="IsOpenEnd"
              >
                <option value="">---All---</option>
                <option value>Yes</option>
                <option value={false}>No</option>
              </select>
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Close End">
              <select
                className="form-control"
                value={criteria.IsCloseEnd}
                onChange={onChangeSearch}
                name="IsCloseEnd"
              >
                <option value="">---All---</option>
                <option value>Yes</option>
                <option value={false}>No</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Link
                  className="btn btn-warning btn-sm btn-labeled"
                  to="/bank-guarantee/maintain-form"
                  target="_blank"
                >
                  <span className="btn-label">
                    <i className="icon icon-plus-circle icon-fw icon-lg" />
                  </span>
                  MAINTAIN FORM
                </Link>
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Action</th>
            <th>Form No</th>
            <th>LG Format</th>
            <th>Form Type</th>
            <th>Language</th>
            <th>Open End</th>
            <th>Close End</th>
            <th>Remark</th>
            {/* <th>Beneficiary Form</th> */}
            <th>Status</th>
            <th>
              Created
              <br /> By
            </th>
            <th>
              Created
              <br /> Date
            </th>
            <th>
              Updated
              <br /> By
            </th>
            <th>
              Updated
              <br /> Date
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalFunction
      textHeader="Active"
      textContent="active"
      modalRef={modalActiveRef}
      type="info"
      icon="icon-refresh"
      textBtn="Active"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
    />

    <ModalFunction
      textHeader="InActive"
      textContent="inactive"
      modalRef={modalInactiveRef}
      type="danger"
      icon="icon-close"
      textBtn="Yes"
      textBtnBack="No"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
    />
  </div>
);
