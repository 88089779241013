import React from 'react';

import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import FormGroup3 from '../../../common/FormGroup3';
import DatePicker from '../../../common/DatePicker';

const colX = ['col-md-4', 'col-md-5'];

export default ({ modalRef, state, onSubmit, onChageModal, text }) => (
  <Modal textHeader={`Sell : ${text}`} ref={modalRef} bgHeader="bg-primary">
    <form onSubmit={onSubmit} className="form-horizontal">
      <FormGroup3 text="Unit" col={colX} required>
        <InputMask
          className="form-control"
          format="currency"
          option={{
            prefix: '',
            digits: 0,
            // digitsOptional: false,
            // placeholder: '0.000'
          }}
          onChange={onChageModal}
          value={state.Unit}
          required
          name="Unit"
        />
      </FormGroup3>
      <FormGroup3 text="Amount" col={colX} required>
        <InputMask
          className="form-control"
          format="currency"
          option={
            state.inputMaskAmount || {
              prefix: '',
              digits: 3,
              // digitsOptional: false,
              // placeholder: '0.000'
            }
          }
          onChange={onChageModal}
          value={state.Amount}
          required
          name="Amount"
        />
      </FormGroup3>
      <FormGroup3 text="Selling price per unit" col={colX} required={false}>
        <InputMask
          className="form-control"
          format="currency"
          option={
            state.inputMaskAmount || {
              prefix: '',
              digits: 2,
              // digitsOptional: false,
              // placeholder: '0.000'
            }
          }
          required={false}
          readOnly
          value={state.SellingPricePerUnit}
          name="SellingPricePerUnit"
        />
      </FormGroup3>
      <FormGroup3 text="Value Date" col={colX} required>
        <DatePicker
          value={state.ValueDateStr}
          onChange={onChageModal}
          name="ValueDateStr"
          required
          option={{
            // startDate: "d",
            daysOfWeekDisabled: '0,6',
            todayHighlight: true,
          }}
        />
      </FormGroup3>

      <br />
      <div>
        <center>
          <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
        </center>
      </div>
    </form>
  </Modal>
);
