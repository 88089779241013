import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_ISSUER, MASTER_CURRENCY, MASTER_BANK_CODE } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
export default ({ state, onChangeInputData, formRef }) => {
  let disabled = false;
  const required = true;
  const { IsSell } = state;
  disabled = Boolean(IsSell);
  return (
    <Card textHeader="Partner" cardActions={['toggler']}>
      <form ref={formRef} name="structure">
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Bond Type" required>
              <select
                tabIndex="-1"
                className="form-control"
                onChange={onChangeInputData}
                required
                value={state.BondType}
                disabled={disabled}
                name="BondType"
              >
                <option value="">---Choose---</option>
                <option value="Held">Held</option>
                <option value="Held and Sell">Held and Sell</option>
              </select>
            </FormGroup2>

            <FormGroup2 text="Bond ID" required>
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.BondId}
                name="BondId"
                required
                disabled={disabled}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Issuer" required>
              <MasterDropdown
                masterType={MASTER_ISSUER}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                required={required}
                disabled={disabled}
                value={state.Issuer}
                name="Issuer"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Partner" required>
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                notMultiple={false}
                required
                isGetAll
                customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                disabled={disabled}
                value={state.Partner}
                name="Partner"
              />
            </FormGroup2>
            <FormGroup2 text="Currency" required>
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                required={required}
                disabled={disabled}
                value={state.Currency}
                name="Currency"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Start Date" required>
              <DatePicker
                onChange={onChangeInputData}
                option={{
                  todayHighlight: true,
                }}
                required={required}
                disabled={disabled}
                value={state.StartDateStr}
                name="StartDateStr"
              />
            </FormGroup2>
            <FormGroup2 text="End Date" required>
              <DatePicker
                onChange={onChangeInputData}
                option={{
                  todayHighlight: true,
                }}
                required={required}
                disabled={disabled}
                value={state.EndDateStr}
                name="EndDateStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Unit" required>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={{
                  prefix: '',
                  digits: 0,
                }}
                required={required}
                disabled={disabled}
                value={state.Unit}
                name="Unit"
              />
            </FormGroup2>

            <FormGroup2 text="Outstanding Unit">
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={{
                  prefix: '',
                  digits: 0,
                }}
                readOnly
                value={state.OutstandingUnit}
                name="OutstandingUnit"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]} />
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Nominal Amount" required>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={
                  state.inputMaskAmount || {
                    prefix: '',
                    digits: 3,
                  }
                }
                required={required}
                disabled={disabled}
                value={state.NormalAmount}
                name="NormalAmount"
              />
            </FormGroup2>
            <FormGroup2 text="Price Per Unit (Normal Amount)" required={false}>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={
                  state.inputMaskAmount || {
                    prefix: '',
                    digits: 3,
                  }
                }
                required={required}
                readOnly
                value={state.PricePerUnitNormalAmount}
                name="PricePerUnitNormalAmount"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]} />
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Actual Amount" required>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={
                  state.inputMaskAmount || {
                    prefix: '',
                    digits: 3,
                  }
                }
                required={required}
                disabled={disabled}
                value={state.ActualAmount}
                name="ActualAmount"
              />
            </FormGroup2>
            <FormGroup2 text="Price Per Unit (Actual Amount)" required={false}>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={{
                  prefix: '',
                  digits: 6,
                }}
                required={required}
                readOnly
                value={state.PricePerUnitActualAmount}
                name="PricePerUnitActualAmount"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]} />
        </Col2>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Base Day" required>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={{
                  prefix: '',
                  digits: 0,
                }}
                required={required}
                disabled={disabled}
                value={state.BaseDay}
                name="BaseDay"
              />
            </FormGroup2>
            <FormGroup2 text="EIR(Yield) Per Year (%)" required={false}>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={{
                  prefix: '',
                  digits: 6,
                  digitsOptional: false,
                  placeholder: '0.00',
                }}
                required={required}
                disabled={disabled}
                value={state.EirPerYear}
                name="EirPerYear"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]} />
        </Col2>
      </form>
    </Card>
  );
};
