import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_BOND_CRITERIA = 'EDIT_BOND_CRITERIA';
export const CLEAR_BOND_CRITERIA = 'CLEAR_BOND_CRITERIA';

export const UPDATE_SEARCH_RESULT_BOND = 'UPDATE_SEARCH_RESULT_BOND';

export const REQUEST_SEARCH_BOND = '@@request/SARCH_BOND';
export const RECEIVE_SEARCH_BOND = '@@receive/SEARCH_BOND';
export const FAIL_SEARCH_BOND = '@@fail/SEARCH_BOND';

export const REQUEST_SAVE_BOND_RATE = '@@request/SAVE_BOND_RATE';
export const RECEIVE_SAVE_BOND_RATE = '@@receive/SAVE_BOND_RATE';
export const FAIL_SAVE_BOND_RATE = '@@fail/SAVE_BOND_RATE';

export const REQUEST_SELL_BOND_RATE = '@@request/SELL_BOND_RATE';
export const RECEIVE_SELL_BOND_RATE = '@@receive/SELL_BOND_RATE';
export const FAIL_SELL_BOND_RATE = '@@fail/SELL_BOND_RATE';

export const REQUEST_SAVE_ISSUER_RATE = '@@request/SAVE_ISSUER_RATE';
export const RECEIVE_SAVE_ISSUER_RATE = '@@receive/SAVE_ISSUER_RATE';
export const FAIL_SAVE_ISSUER_RATE = '@@fail/SAVE_ISSUER_RATE';

export const REQUEST_CANCEL_CASHFLOW_RATE = '@@request/CANCEL_CASHFLOW_RATE';
export const RECEIVE_CANCEL_CASHFLOW_RATE = '@@receive/CANCEL_CASHFLOW_RATE';
export const FAIL_CANCEL_CASHFLOW_RATE = '@@fail/CANCEL_CASHFLOW_RATE';

export const REQUEST_GET_BOND_RATE = '@@request/GET_BOND_RATE';
export const RECEIVE_GET_BOND_RATE = '@@receive/GET_BOND_RATE';
export const FAIL_GET_BOND_RATE = '@@fail/GET_BOND_RATE';

export const REQUEST_SPLIT_CASH_FLOW = '@@request/SPLIT_CASH_FLOW';
export const RECEIVE_SPLIT_CASH_FLOW =
  '@@receive-The transaction was successfully saved/SPLIT_CASH_FLOW';
export const FAIL_SPLIT_CASH_FLOW = '@@fail/SPLIT_CASH_FLOW';

export const splitCashFlow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/splitcashflow', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SPLIT_CASH_FLOW, RECEIVE_SPLIT_CASH_FLOW, FAIL_SPLIT_CASH_FLOW],
  },
});

export const searchBond = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/searchbond', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_BOND, RECEIVE_SEARCH_BOND, FAIL_SEARCH_BOND],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_BOND,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_BOND_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_BOND_CRITERIA,
  payload: {
    name,
    value,
  },
});

export const getBond = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getbond/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_BOND_RATE, RECEIVE_GET_BOND_RATE, FAIL_GET_BOND_RATE],
  },
});

export const saveBond = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savebond'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_BOND_RATE, RECEIVE_SAVE_BOND_RATE, FAIL_SAVE_BOND_RATE],
  },
});

export const sellBond = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/sellbond'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SELL_BOND_RATE, RECEIVE_SELL_BOND_RATE, FAIL_SELL_BOND_RATE],
  },
});

export const saveIssuer = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/master/saveissuer'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_ISSUER_RATE, RECEIVE_SAVE_ISSUER_RATE, FAIL_SAVE_ISSUER_RATE],
  },
});

export const cancelCashflow = (cashflowId) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/cancelbondcashflow'),
    method: 'POST',
    body: JSON.stringify(cashflowId),
    headers: jsonContentTypeHeader,
    types: [REQUEST_CANCEL_CASHFLOW_RATE, RECEIVE_CANCEL_CASHFLOW_RATE, FAIL_CANCEL_CASHFLOW_RATE],
  },
});
