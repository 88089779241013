import React from 'react';

import Tab from '../../../common/Tab';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Link from '../../../common/Link';
import Button from '../../../common/Button';
import Select2 from '../../../common/Select2';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import RadioButton from '../../../common/RadioButton';
import { getPermission } from '../../../common/helpper';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_COUNTRY } from '../../../reducers/master/action';

const permission = getPermission('Master Finance', 'Bank');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  tabRef,
  formDetailRef,
  formAddressRef,
  formConfigRef,
  formEmailRef,

  resultEffective,
}) => {
  const textHeader =
    state.mode === 'create'
      ? 'Create Bank'
      : `${state.BankCode} | ${state.BankAbbreviate} | ${state.BankName}`;
  const modeEdit = state.mode === 'edit';
  // let noEdit = !permission ? true : state.mode === 'create' ? false : state.setOverDueDate
  const noEdit = !permission;

  const tabContents = [];

  tabContents.push({
    header: <span>Address</span>,
    content: (
      <AddressTab
        formRef={formAddressRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'address',
  });

  tabContents.push({
    header: <span>Configuration</span>,
    content: (
      <ConfigTab
        formRef={formConfigRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'configuration',
  });

  tabContents.push({
    header: <span>Email</span>,
    content: (
      <EmailTab
        formRef={formEmailRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'email',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" />
            <Link txt="Bank" href="/master/bank" />
            <span className="icon icon-angle-double-right" /> Bank Detail{' '}
          </small>
        </p>
      </div>

      <Card textHeader={textHeader} bgHeader="bg-primary" cardActions={['toggler']}>
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            {state.mode === 'create' ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <DatePicker
                    value={state.EffectiveDateStr}
                    onChange={onChangeInputData}
                    name="EffectiveDateStr"
                    required
                    option={{
                      // startDate: "+1d",
                      // daysOfWeekDisabled: "0,6",
                      todayHighlight: true,
                    }}
                  />
                </FormGroup2>
              </Col2>
            ) : (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <Select2
                    value={state.TempEffectiveDateStr}
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="TempEffectiveDateStr"
                    required={false}
                  >
                    {permission ? (
                      <option value="New" key="New">
                        New Effective Date
                      </option>
                    ) : null}
                    {resultEffective.map((m) => (
                      <option value={m} key={m}>
                        {m}
                      </option>
                    ))}
                  </Select2>
                </FormGroup2>
                {state.TempEffectiveDateStr === 'New' ? (
                  <FormGroup2 text="New Effective Date" required>
                    <DatePicker
                      value={state.EffectiveDateStr}
                      onChange={onChangeInputData}
                      name="EffectiveDateStr"
                      required
                      option={{
                        // startDate: "+1d",
                        // daysOfWeekDisabled: "0,6",
                        todayHighlight: true,
                      }}
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            )}

            {modeEdit ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Status" required>
                  <select
                    className="form-control"
                    value={state.IsActive}
                    onChange={onChangeInputData}
                    name="IsActive"
                    disabled={noEdit}
                  >
                    <option value>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </FormGroup2>
              </Col2>
            ) : null}
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2
                text="Swift Code"
                required
                maxLength={8}
                length={(state.BankCode || '').length}
              >
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required
                  disabled={modeEdit}
                  value={state.BankCode || ''}
                  maxLength={8}
                  name="BankCode"
                />
              </FormGroup2>

              <FormGroup2 text="Bank Code TH" required={false}>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.BankCodeTh || ''}
                  name="BankCodeTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank Short Name" required>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.BankAbbreviate || ''}
                  name="BankAbbreviate"
                />
              </FormGroup2>

              <FormGroup2 text="Country" required>
                <MasterDropdown
                  key={noEdit ? 'country1' : 'country2'}
                  masterType={MASTER_COUNTRY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={noEdit}
                  value={state.Country}
                  name="Country"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank Name (Inter)" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.BankName || ''}
                  name="BankName"
                />
              </FormGroup2>

              <FormGroup2 text="Bank Name (Local)" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.BankNameTh || ''}
                  name="BankNameTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Tax ID">
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  disabled={noEdit}
                  value={state.TaxId || ''}
                  name="TaxId"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !permission ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={noEdit}
            />
          )
        }
      />
    </div>
  );
};

function AddressTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <form ref={formRef} name="address">
      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <Card textHeader="Address (Inter)">
            <Col2 col={colX[2]}>
              <FormGroup2 text="Street" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.Street || ''}
                  name="Street"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="District" required>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.District || ''}
                  name="District"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="City" required>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.City || ''}
                  name="City"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="Zip Code" required>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.ZipCode || ''}
                  name="ZipCode"
                />
              </FormGroup2>
            </Col2>
          </Card>

          <Card textHeader="Address (Local)">
            <Col2 col={colX[2]}>
              <FormGroup2 text="Street" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.StreetTh || ''}
                  name="StreetTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="District" required={false}>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.DistrictTh || ''}
                  name="DistrictTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="City" required={false}>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.CityTh || ''}
                  name="CityTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[2]}>
              <FormGroup2 text="Zip Code" required={false}>
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.ZipCodeTh || ''}
                  name="ZipCodeTh"
                />
              </FormGroup2>
            </Col2>
          </Card>
        </Col2>
      </Col2>
    </form>
  );
}

function ConfigTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <Card textHeader="Configuration">
      <form ref={formRef} name="configuration">
        {noEdit ? (
          <>
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Ending Balance Notification" required>
                  <input
                    className="form-control"
                    type="text"
                    onChange={onChangeInputData}
                    required
                    disabled={noEdit}
                    value={state.IsEndingBalanceNoti ? 'Yes' : 'No'}
                    name="IsEndingBalanceNoti"
                  />
                </FormGroup2>

                <FormGroup2 text="Print With Signature" required>
                  <input
                    className="form-control"
                    type="text"
                    onChange={onChangeInputData}
                    required
                    disabled={noEdit}
                    value={state.IsPrint ? 'Yes' : 'No'}
                    name="IsPrint"
                  />
                </FormGroup2>
              </Col2>
            </Col2>

            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="SFTP for T/T" required>
                  <input
                    className="form-control"
                    type="text"
                    onChange={onChangeInputData}
                    required
                    disabled={noEdit}
                    value={state.IsSftp ? 'Yes' : 'No'}
                    name="IsSftp"
                  />
                </FormGroup2>

                <FormGroup2 text="SFTP for Bahtnet" required>
                  <input
                    className="form-control"
                    type="text"
                    onChange={onChangeInputData}
                    required
                    disabled={noEdit}
                    value={state.IsFtp ? 'Yes' : 'No'}
                    name="IsFtp"
                  />
                </FormGroup2>
              </Col2>
            </Col2>
          </>
        ) : (
          <>
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Ending Balance Notification" required>
                  <>
                    <RadioButton
                      text="Yes"
                      value
                      onChange={onChangeInputData}
                      checked={state.IsEndingBalanceNoti}
                      name="IsEndingBalanceNoti"
                      required
                    />
                    <RadioButton
                      text="No"
                      value={false}
                      onChange={onChangeInputData}
                      checked={!state.IsEndingBalanceNoti}
                      name="IsEndingBalanceNoti"
                    />
                  </>
                </FormGroup2>

                {/* <FormGroup2 text="External Reference" required={true}>
                                        <>
                                            <RadioButton text="From Bank" value="From Bank"
                                                onChange={onChangeInputData}
                                                checked={state.ExternalRef === "From Bank"}
                                                name="ExternalRef"
                                                required={true}
                                            />
                                            <RadioButton text="From System" value="From System"
                                                onChange={onChangeInputData}
                                                checked={state.ExternalRef === "From System"}
                                                name="ExternalRef"
                                            />
                                        </>
                                    </FormGroup2> */}

                <FormGroup2 text="Print With Signature" required>
                  <>
                    <RadioButton
                      text="Yes"
                      value
                      onChange={onChangeInputData}
                      checked={state.IsPrint}
                      name="IsPrint"
                      required
                    />
                    <RadioButton
                      text="No"
                      value={false}
                      onChange={onChangeInputData}
                      checked={!state.IsPrint}
                      name="IsPrint"
                    />
                  </>
                </FormGroup2>
              </Col2>
            </Col2>

            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="SFTP for T/T" required>
                  <>
                    <RadioButton
                      text="Yes"
                      value
                      onChange={onChangeInputData}
                      checked={state.IsSftp}
                      name="IsSftp"
                      required
                    />
                    <RadioButton
                      text="No"
                      value={false}
                      onChange={onChangeInputData}
                      checked={!state.IsSftp}
                      name="IsSftp"
                    />
                  </>
                </FormGroup2>

                <FormGroup2 text="SFTP for Bahtnet" required>
                  <>
                    <RadioButton
                      text="Yes"
                      value
                      onChange={onChangeInputData}
                      checked={state.IsFtp}
                      name="IsFtp"
                      required
                    />
                    <RadioButton
                      text="No"
                      value={false}
                      onChange={onChangeInputData}
                      checked={!state.IsFtp}
                      name="IsFtp"
                    />
                  </>
                </FormGroup2>
              </Col2>
            </Col2>
          </>
        )}
      </form>
    </Card>
  );
}

function EmailTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <Card textHeader="Email">
      <form ref={formRef} name="email">
        <Col2 col={colX[2]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Email Cheque" required={false}>
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeInputData}
                required={false}
                disabled={noEdit}
                value={state.EmailForCheque || ''}
                name="EmailForCheque"
              />
            </FormGroup2>

            <FormGroup2 text="Email Quote Rate" required={false}>
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeInputData}
                required={false}
                disabled={noEdit}
                value={state.EmailForQuoteRate || ''}
                name="EmailForQuoteRate"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <Col2 col={colX[1]}>
            {/* <FormGroup2 text="Email for PN With Signature" required={false}>
                            <textarea className="form-control" rows="3"
                                onChange={onChangeInputData}
                                required={false}
                                disabled={noEdit}
                                value={state.EmailForPn || ""}
                                name="EmailForPn" />
                        </FormGroup2> */}

            <FormGroup2 text="Email for Bahtnet With Signature" required={false}>
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeInputData}
                required={false}
                disabled={noEdit}
                value={state.EmailForBahtnet || ''}
                name="EmailForBahtnet"
              />
            </FormGroup2>

            <FormGroup2 text="Email FX Matching" required={false}>
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeInputData}
                required={false}
                disabled={noEdit}
                value={state.EmailFxMatching || ''}
                name="EmailFxMatching"
              />
            </FormGroup2>
          </Col2>
        </Col2>
      </form>
    </Card>
  );
}
