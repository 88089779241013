import { connect } from 'react-redux';
import React, { Component } from 'react';

import FxConfirmation from './FxConfirmation';
import { addAlert } from '../../../reducers/layout/action';
import {
  toLower,
  compareDate,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../../common/helpper';
import {
  clearResult,
  getBuMatching,
  saveFxMatching,
  getMatchingDate,
  getMatchingTime,
  searchFxMatching,
  createFxMatching,
  updateSearchResult,
  sendEmailFxMatching,
  changeDateFxMatching,
  getExportFxConfirmation,
  getFxConfirmationCompany,
} from '../../../reducers/fxConfirmation/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-fx-confirmation';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';
export const DATATABLE_ID_BU = 'table-result-bu-matching';
export const DATATABLE_ID_SUM = 'table-result-matching-sum';

const initCriteria = {
  FinanceGroup: '',
  BusinessUnit: [''],
  Company: [''],
  Bank: [''],
  ProductType: '',
  TransactionType: '',
  ForwardType: '',
  BuyCurrency: [''],
  SellCurrency: [''],
  Status: [''],
  MatchingDateStr: '',
  EntryDateStr: '',
  Currency: 'All',
  StatusFile: '',
  MatchingTime: '',
  EndDateStr: '',
};

const initModal = {
  id: '',
  bankCode: '',
  mode: '',
  transactionNo: '',
  bankFilter: [],
  successList: [],
  errorList: [],
  idListTable: [],
};

const initDate = {
  IdListTable: [],
  StartDateStr: '',
  EndDateStr: '',
};

const initCreate = {
  FinanceGroupId: '',
  BusinessUnitId: '',
  CompanyCode: '',
  TransactionType: '',
  ProductType: '',
  Amount: '',
  Currency: '',
  InternalReference: '',
  ContractDateStr: '',
  StartDateStr: '',
  EndDateStr: '',
  Note1: '',
  Gvc: '',
  Characteristic: '',
  BankCode: '',
  BusinessPartnerCode: '',

  mode: 'create',
  inputMaskAmount: {
    prefix: '',
    digits: 3,
  },
};

class FxConfirmationContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    modalData: {
      ...initModal,
    },
    modalDate: {
      ...initDate,
    },
    modalCreate: {
      ...initCreate,
    },
    modalGroup: {},
    modalAutoGroup: [],
    buMatching: [],
    matchingDate: [],
    summaryList: [],
    matchingTime: [],
    compFilter: [],
    currencyFilter: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `button.active`, (e) => this.onClickActive(e));
    $('body').on('click', `button.inactive`, (e) => this.onClickInactive(e));
    $('body').on('click', `button.ungroup`, (e) => this.onClickUnGroup(e));
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.ungroup`);
  }

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) {
      e.target.classList.remove('checked');
    } else {
      e.target.classList.add('checked');
    }
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    this.props.searchResult[result.row].IsSelected = result.checked;
    this.forceUpdate();
  };

  // search
  onChangeSearchHeadler = async (e) => {
    const { name, value } = e.target;

    if (name === 'MatchingDateStr') {
      const res = await this.props.getMatchingTime({
        Type: null,
        MatchingDateStr: value,
      });

      const _matchingTime = res.payload || [];
      const defaultTime = _matchingTime.length ? _matchingTime[0].time || '' : '';

      const res2 = await this.props.getMatchingDate({
        Type: null,
        MatchingDateStr: value,
        MatchingTime: defaultTime,
      });

      const _matchingDate = res2.payload || [];
      const defaultDate = _matchingDate.length ? _matchingDate[0].dateFromTo || '' : '';

      const res3 = await this.props.getFxConfirmationCompany({
        Mode: 'comp',
        MatchingDateStr: value,
        MatchingTime: defaultTime,
      });
      const comp = [...new Set((res3.payload || []).map((m) => m.companyCode))];
      const currency = [...new Set((res3.payload || []).map((m) => m.currency))];

      this.setState({
        ...this.state,
        matchingTime: _matchingTime,
        matchingDate: _matchingDate,
        criteria: {
          ...this.state.criteria,
          EntryDateStr: defaultDate,
          MatchingTime: defaultTime,
          MatchingDateStr: value,
        },
        compFilter: comp,
        currencyFilter: currency,
      });
    } else if (name === 'MatchingTime') {
      const res2 = await this.props.getMatchingDate({
        Type: null,
        MatchingDateStr: this.state.criteria.MatchingDateStr,
        MatchingTime: value,
      });

      const _matchingDate = res2.payload || [];
      const defaultDate = _matchingDate.length ? _matchingDate[0].dateFromTo || '' : '';

      const res3 = await this.props.getFxConfirmationCompany({
        Mode: 'comp',
        MatchingDateStr: this.state.criteria.MatchingDateStr,
        MatchingTime: value,
      });
      const comp = [...new Set((res3.payload || []).map((m) => m.companyCode))];
      const currency = [...new Set((res3.payload || []).map((m) => m.currency))];

      this.setState({
        ...this.state,
        matchingDate: _matchingDate,
        criteria: {
          ...this.state.criteria,
          EntryDateStr: defaultDate,
          MatchingTime: value,
        },
        compFilter: comp,
        currencyFilter: currency,
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [name]: value,
          Currency:
            name === 'Currency'
              ? value
              : name === 'TransactionType' && !value
                ? ['']
                : this.state.criteria.Currency,
        },
      });
    }
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.onSearch();
  };

  onSearch = () => {
    const { criteria } = this.state;
    if (criteria.TransactionType === 'Buy') {
      criteria.BuyCurrency = criteria.Currency;
      criteria.SellCurrency = [''];
    } else if (criteria.TransactionType === 'Sell') {
      criteria.SellCurrency = criteria.Currency;
      criteria.BuyCurrency = [''];
    } else {
      criteria.BuyCurrency = [''];
      criteria.SellCurrency = [''];
    }
    clearCheckBoxAllTable(DATATABLE_ID);
    if (this.state.modalData.mode === 'unmatch') {
      this.props.clearResult();
      this.setState({
        ...this.state,
        summaryList: [],
      });
    } else {
      this.props.searchFxMatching({
        ...criteria,
      });
      this.props.getBuMatching({ ...this.state.criteria, mode: 'sum' }).then((res) => {
        this.setState({
          ...this.state,
          summaryList: res.payload,
        });
      });
    }
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
      compFilter: [],
    });
  };

  // end search
  onClickBuMatching = () => {
    if (!this.state.criteria.EntryDateStr) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select Entry Date!',
      });
      return;
    }

    this.props.getBuMatching({ ...this.state.criteria, mode: 'bu' }).then((res) => {
      this.setState(
        {
          ...this.state,
          buMatching: res.payload,
        },
        this.modalBuRef.open
      );
    });
  };

  onClickUnmatch = () => {
    if (!this.state.criteria.EntryDateStr) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select Entry Date!',
      });
      return;
    }

    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'unmatch',
          transactionNo: this.props.searchResult[0].transactionNo,
        },
      },
      this.modalUnmatchRef.open
    );
  };

  onClickConfirm = () => {
    if (!this.state.criteria.EntryDateStr) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select Entry Date!',
      });
      return;
    }

    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'confirm bank',
          transactionNo: this.props.searchResult[0].transactionNo,
        },
      },
      this.modalConfirmBankRef.open
    );
  };

  onClickWithdraw = () => {
    if (!this.state.criteria.EntryDateStr) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select Entry Date!',
      });
      return;
    }

    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'withdraw',
          transactionNo: this.props.searchResult[0].transactionNo,
        },
      },
      this.modalWithdrawRef.open
    );
  };

  onClickActive = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'active',
          id: this.props.searchResult[row].id,
        },
      },
      this.modalActiveRef.open
    );
  };

  onClickInactive = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'inactive',
          id: this.props.searchResult[row].id,
        },
      },
      this.modalInactiveRef.open
    );
  };

  onClickUnGroup = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'ungroup',
          id: this.props.searchResult[row].id,
        },
      },
      this.modalUngroupRef.open
    );
  };

  onSubmitConfirm = async () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (this.state.modalData.mode === 'send email') {
      const group = {};
      selectedData.forEach((m) => {
        const key = `${m.businessPartnerCode}`;
        group[key] = group[key] || [];
        group[key].push(m);
      });

      for (const key in group) {
        const arr = group[key];

        const _data = {
          IdListTable: arr.map((m) => m.id),
          BankAbbreviate: arr[0].businessPartnerCode,
          CompanyCode: arr[0].companyCode,
        };
        await this.props.sendEmailFxMatching(_data);
      }
    }

    this.props.saveFxMatching(this.state.modalData).then(() => {
      this.onSearch();
      this.modalConfirmBankRef.close();
      this.modalWithdrawRef.close();
      this.modalUnmatchRef.close();
      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.modalEmailRef.close();
      this.modalUngroupRef.close();

      if (this.state.modalData.mode === 'unmatch') {
        this.props
          .getMatchingTime({
            Type: null,
            MatchingDateStr: this.state.criteria.MatchingDateStr,
          })
          .then((res) => {
            const _matchingTime = res.payload || [];
            const defaultTime = _matchingTime.length ? _matchingTime[0].time : '';

            this.props
              .getMatchingDate({
                Type: null,
                MatchingDateStr: this.state.criteria.MatchingDateStr,
                MatchingTime: this.state.criteria.MatchingTime,
              })
              .then((res2) => {
                const _matchingDate = res2.payload || [];
                const defaultDate = _matchingDate.length ? _matchingDate[0].dateFromTo : '';
                this.setState({
                  ...this.state,
                  matchingTime: _matchingTime,
                  matchingDate: _matchingDate,
                  criteria: {
                    ...this.state.criteria,
                    EntryDateStr: defaultDate,
                    MatchingTime: defaultTime,
                  },
                });
              });
          });
      }

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
    });
  };

  onClickSelectBank = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Select bank!',
      });
      return;
    }

    const checkStatus = selectedData.filter(
      (f) => toLower(f.status) !== 'wait for confirm' || !f.isActive
    );
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for confirm!',
      });
      return;
    }

    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'select bank',
          idList: selectedData.map((m) => m.id).toString(),
        },
      },
      this.modalEditRef.open
    );
  };

  onChangeInput = (e) => {
    this.setState({
      modalData: {
        ...this.state.modalData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitSelectBank = (e) => {
    e.preventDefault();
    this.props.saveFxMatching(this.state.modalData).then(() => {
      this.onSearch();
      this.modalEditRef.close();
      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
    });
  };

  onClickSelectBusinessPartner = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Select Business Partner!',
      });
      return;
    }

    const checkStatus = selectedData.filter(
      (f) => toLower(f.status) !== 'wait for quote rate' || !f.isActive
    );
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for quote rate!',
      });
      return;
    }

    let bankFilter = [];
    selectedData.map((m) => {
      if (m.swapRateBbl != null && (m.bankCode === 'BKKBTH' || !m.bankCode)) {
        bankFilter.push('BKKBTH');
      }
      if (m.swapRateKbank != null && (m.bankCode === 'KASITH' || !m.bankCode)) {
        bankFilter.push('KASITH');
      }
      if (m.swapRateScb != null && (m.bankCode === 'SICOTH' || !m.bankCode)) {
        bankFilter.push('SICOTH');
      }
      if (m.swapRateSmbc != null && (m.bankCode === 'SMBCTH' || !m.bankCode)) {
        bankFilter.push('SMBCTH');
      }
    });

    bankFilter = [...new Set(bankFilter)];

    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'buisness partner',
          idList: selectedData.map((m) => m.id).toString(),
          idListTable: selectedData.map((m) => m.id),
          bankFilter,
        },
      },
      this.modalBuisnessPartnerRef.open
    );
  };

  onSubmitSelectBusinessPartner = (e) => {
    e.preventDefault();
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    const successList = [];
    const errorList = [];
    const { bankCode } = this.state.modalData;
    let bankName = '';
    selectedData.map((m) => {
      if (
        m.swapRateBbl != null &&
        bankCode === 'BKKBTH' &&
        (m.bankCode === 'BKKBTH' || !m.bankCode)
      ) {
        successList.push(m.id);
        bankName = 'BBL';
      } else if (
        m.swapRateKbank != null &&
        bankCode === 'KASITH' &&
        (m.bankCode === 'KASITH' || !m.bankCode)
      ) {
        successList.push(m.id);
        bankName = 'Kbank';
      } else if (
        m.swapRateScb != null &&
        bankCode === 'SICOTH' &&
        (m.bankCode === 'SICOTH' || !m.bankCode)
      ) {
        successList.push(m.id);
        bankName = 'SCB';
      } else if (
        m.swapRateSmbc != null &&
        bankCode === 'SMBCTH' &&
        (m.bankCode === 'SMBCTH' || !m.bankCode)
      ) {
        successList.push(m.id);
        bankName = 'SMBC';
      } else {
        errorList.push(m.id);
      }
    });

    if (errorList.length) {
      this.modalBuisnessPartnerRef.close();
      this.setState(
        {
          modalData: {
            ...this.state.modalData,
            mode: 'buisness partner',
            idList: successList.toString(),
            successList,
            errorList,
            bankName,
          },
        },
        this.modalAlertRef.open
      );
    } else {
      const _modalData = this.state.modalData;
      _modalData.idList = successList.toString();
      this.props.saveFxMatching({ ..._modalData }).then(() => {
        this.onSearch();
        this.modalBuisnessPartnerRef.close();
        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The record was successfully saved',
        });
      });
    }
  };

  onSaveBusinessPartner = (e) => {
    e.preventDefault();
    this.props.saveFxMatching(this.state.modalData).then(() => {
      this.onSearch();
      this.modalAlertRef.close();
      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
    });
  };

  onClickSendEmail = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Send email to bank!',
      });
      return;
    }

    const checkStatus = selectedData.filter(
      (f) => toLower(f.status) !== 'wait for send email to bank'
    );
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for send email to bank!',
      });
      return;
    }

    this.setState(
      {
        modalData: {
          ...initModal,
          mode: 'send email',
          idList: selectedData.map((m) => m.id).toString(),
        },
      },
      this.modalEmailRef.open
    );
  };

  onClickExport = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Export!',
      });
      return;
    }

    const checkStatus = selectedData.filter(
      (f) =>
        toLower(f.status) !== 'wait for send email to bank' && toLower(f.status) !== 'completed'
    );
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for send email to bank or Completed!',
      });
      return;
    }

    const bankCode = ['BKKBTH', 'KASITH', 'SICOTH', 'SMBCTH'];
    bankCode.map((m) => {
      const bankFilter = selectedData.filter((f) => toLower(f.businessPartnerCode) === toLower(m));
      if (bankFilter.length) {
        const idlist = bankFilter.map((m) => m.id);

        const _data = {
          IdListTable: idlist,
          FinanceGroupId: bankFilter[0].financeGroupId,
          BankAbbreviate: m,
          CompanyCode: bankFilter[0].companyCode,
        };
        this.props.getExportFxConfirmation(_data);
      }
    });
    this.onSearch();
  };

  onClickSelectDate = (e, mode) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Select Date!',
      });
      return;
    }

    const checkStatus = selectedData.filter((f) => toLower(f.status) === 'wait for quote rate');
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is not Wait for quote rate!',
      });
      return;
    }

    this.setState(
      {
        modalDate: {
          ...initDate,
          IdListTable: selectedData.map((m) => m.id),
          StartDateStr: '',
          EndDateStr2: '',
          Mode: mode,
        },
      },
      this.modalDateRef.open
    );
  };

  onChangeDateModal = (e) => {
    this.setState({
      modalDate: {
        ...this.state.modalDate,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSaveSelectDate = (e) => {
    e.preventDefault();
    this.props.changeDateFxMatching(this.state.modalDate).then(() => {
      this.onSearch();
      this.modalDateRef.close();
      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
    });
  };

  onCreateFxMatching = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Create FX Confirmation!',
      });
      return;
    }

    const checkStatus = selectedData.filter((f) => toLower(f.status) !== 'wait for confirm');
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for confirm!',
      });
      return;
    }

    const checkMatching = selectedData.filter((f) => toLower(f.statusFile) === 'matching');
    if (checkMatching.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status file is not Matching!',
      });
      return;
    }

    const { transactionType } = selectedData[0];
    const { statusFile } = selectedData[0];
    const bank = selectedData[0].bankCode;
    const { contractDateStr } = selectedData[0];
    const currency =
      transactionType === '102 Forward Transaction - Buy'
        ? selectedData[0].buyCurrency
        : selectedData[0].sellCurrency;
    const digit = selectedData[0].currencyDigit;
    let amount = 0;

    let checkTransaction = true;
    let checkCurrency = true;
    let checkStatusFile = true;
    let checkBank = true;
    let checkContractDate = true;
    selectedData.map((m) => {
      if (transactionType !== m.transactionType) {
        checkTransaction = false;
      }

      if (transactionType === '102 Forward Transaction - Buy') {
        if (currency !== m.buyCurrency) {
          checkCurrency = false;
        }
      } else if (currency !== m.sellCurrency) {
        checkCurrency = false;
      }

      if (statusFile !== m.statusFile) {
        checkStatusFile = false;
      }

      if (bank !== m.bankCode) {
        checkBank = false;
      }

      if (contractDateStr !== m.contractDateStr) {
        checkContractDate = false;
      }

      amount += m.amount || 0;
    });

    if (
      !checkTransaction ||
      !checkCurrency ||
      !checkStatusFile ||
      !checkBank ||
      !checkContractDate
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select same Bank, Transaction Type, Contract Date, Currency and Status File!',
      });
      return;
    }

    let trans1 = '';
    let trans2 = '';
    if (transactionType === '102 Forward Transaction - Buy') {
      trans1 = 'Sale Document';
      trans2 = '202 Forward Transaction - Sell';
    } else {
      trans1 = 'Purchase Document';
      trans2 = '102 Forward Transaction - Buy';
    }

    this.setState(
      {
        modalCreate: {
          ...initCreate,
          IdListTable: selectedData.map((m) => m.id),
          TransactionNo: selectedData[0].transactionNo,
          TransactionType: trans1,
          TransactionType2: trans2,
          Currency: currency,
          Amount: amount,
          BankCode: bank,
          BusinessPartnerCode: bank,
          ContractDateStr: contractDateStr,
          inputMaskAmount: {
            prefix: '',
            digits: digit,
          },
        },
      },
      this.modalCreateRef.open
    );
  };

  onSaveCreateFxConfirmation = (e) => {
    e.preventDefault();
    this.props.createFxMatching(this.state.modalCreate).then(() => {
      this.onSearch();
      this.modalCreateRef.close();
      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
    });
  };

  onChangeCreateModal = (e) => {
    const { name, value } = e.target;

    if (name === 'ContractDateStr') {
      if (compareDate(value, this.state.modalCreate.StartDateStr)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Contract Date must be less than or equal Start Date.',
        });
        return;
      }
    } else if (name === 'StartDateStr') {
      if (compareDate(this.state.modalCreate.ContractDateStr, value)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Start Date must be more than or equal Contract Date.',
        });
        return;
      }
      if (compareDate(value, this.state.modalCreate.EndDateStr)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Start Date must be less than or equal End Date.',
        });
        return;
      }
    } else if (name === 'EndDateStr') {
      if (compareDate(this.state.modalCreate.ContractDateStr, value)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'End Date must be more than or equal Contract Date.',
        });
        return;
      }
      if (compareDate(this.state.modalCreate.StartDateStr, value)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'End Date must be more than or equal Start Date.',
        });
        return;
      }
    }

    this.setState({
      modalCreate: {
        ...this.state.modalCreate,
        [name]: value,
      },
    });
  };

  onClickGroup = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      // this.props.addAlert({
      //     title: "Error",
      //     type: 'error',
      //     body: 'Please select item for Grouping!',
      // })
      this.onClickAutoGroup();
      return;
    }

    const checkStatus = selectedData.filter(
      (f) =>
        toLower(f.status) !== 'wait for confirm' &&
        toLower(f.status) !== 'wait for send email to bank'
    );
    if (checkStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is Wait for confirm or Wait for send email to bank!',
      });
      return;
    }

    const data = selectedData[0];
    let amount = 0;
    let checkSame = true;

    selectedData.map((m) => {
      if (
        m.companyCode !== data.companyCode ||
        m.bankCode !== data.bankCode ||
        m.productType !== data.productType ||
        m.transactionType !== data.transactionType ||
        m.businessPartnerCode !== data.businessPartnerCode ||
        m.contractNo !== data.contractNo ||
        m.contractDateStr !== data.contractDateStr ||
        m.startDateStr !== data.startDateStr ||
        m.endDateStr !== data.endDateStr ||
        m.forwardType !== data.forwardType ||
        m.buyCurrency !== data.buyCurrency ||
        m.sellCurrency !== data.sellCurrency ||
        m.spotRate !== data.spotRate ||
        m.baseRate !== data.baseRate ||
        m.swapRateBase !== data.swapRateBase ||
        m.forwardRate !== data.forwardRate ||
        m.swapRate !== data.swapRate ||
        m.gvc !== data.gvc ||
        m.portfolio !== data.portfolio ||
        m.assignment !== data.assignment ||
        m.characteristic !== data.characteristic ||
        m.internalReference !== data.internalReference ||
        m.requestType !== data.requestType ||
        m.contractDetail1 !== data.contractDetail1 ||
        m.contractDetail2 !== data.contractDetail2 ||
        m.contractDetail3 !== data.contractDetail3 ||
        m.contractDetail4 !== data.contractDetail4 ||
        m.dealer !== data.dealer ||
        m.department !== data.department ||
        m.statusFile !== data.statusFile ||
        m.note1 !== data.note1 ||
        m.note2 !== data.note2 ||
        m.status !== data.status ||
        m.isActive !== data.isActive
      ) {
        checkSame = false;
      }
      amount += m.amount || 0;
    });

    if (!checkSame) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item is same all data for Grouping!',
      });
      return;
    }

    let trans1 = '';
    if (data.transactionType === '102 Forward Transaction - Buy') {
      trans1 = 'Purchase Document';
    } else {
      trans1 = 'Sale Document';
    }

    this.setState(
      {
        modalGroup: {
          IdListTable: selectedData.map((m) => m.id),

          TransactionNo: data.transactionNo,
          CompanyCode: data.companyCode,
          BankCode: data.bankCode,
          ProductType: data.productType,
          ForwardType: data.forwardType,
          TransactionType: trans1,

          BusinessPartnerCode: data.businessPartnerCode,
          ContractNo: data.contractNo,
          ContractDateStr: data.contractDateStr,
          StartDateStr: data.startDateStr,
          EndDateStr: data.endDateStr,
          Currency: data.currency,
          SpotRate: data.spotRate,
          BaseRate: data.baseRate,
          SwapRateBase: data.swapRateBase,
          ForwardRate: data.forwardRate,
          SwapRate: data.swapRate,
          Gvc: data.gvc,
          Portfolio: data.portfolio,
          Assignment: data.assignment,
          Characteristic: data.characteristic,
          InternalReference: data.internalReference,
          RequestType: data.requestType,
          ContractDetail1: data.contractDetail1,
          ContractDetail2: data.contractDetail2,
          ContractDetail3: data.contractDetail3,
          ContractDetail4: data.contractDetail4,
          Dealer: data.dealer,
          Department: data.department,
          StatusFile: data.statusFile,
          Note1: data.note1,
          Note2: data.note2,
          Status: data.status,

          Amount: amount,
          CurrencyDigit: data.currencyDigit,
          mode: 'group',
        },
      },
      this.modalGroupRef.open
    );
  };

  onClickAutoGroup = () => {
    const selectedData = this.props.searchResult;

    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Grouping!',
      });
      return;
    }

    const filterStatus = selectedData.filter(
      (f) =>
        toLower(f.status) === 'wait for confirm' ||
        toLower(f.status) === 'wait for send email to bank'
    );
    if (!filterStatus.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: "Can't auto grouping because transaction status is not Wait for confirm or Wait for send email to bank!",
      });
      return;
    }
    console.log(filterStatus);

    const data = [];

    filterStatus.forEach((m, i) => {
      if (!data.length) {
        data.push({
          group: i,
          items: [{ ...m }],
        });
      } else {
        let checkSame = false;
        data.forEach((d) => {
          const items = d.items[0];

          if (
            m.companyCode !== items.companyCode ||
            m.bankCode !== items.bankCode ||
            m.productType !== items.productType ||
            m.transactionType !== items.transactionType ||
            m.businessPartnerCode !== items.businessPartnerCode ||
            m.contractNo !== items.contractNo ||
            m.contractDateStr !== items.contractDateStr ||
            m.startDateStr !== items.startDateStr ||
            m.endDateStr !== items.endDateStr ||
            m.forwardType !== items.forwardType ||
            m.buyCurrency !== items.buyCurrency ||
            m.sellCurrency !== items.sellCurrency ||
            m.spotRate !== items.spotRate ||
            m.baseRate !== items.baseRate ||
            m.swapRateBase !== items.swapRateBase ||
            m.forwardRate !== items.forwardRate ||
            m.swapRate !== items.swapRate ||
            m.gvc !== items.gvc ||
            m.portfolio !== items.portfolio ||
            m.assignment !== items.assignment ||
            m.characteristic !== items.characteristic ||
            m.internalReference !== items.internalReference ||
            m.requestType !== items.requestType ||
            m.contractDetail1 !== items.contractDetail1 ||
            m.contractDetail2 !== items.contractDetail2 ||
            m.contractDetail3 !== items.contractDetail3 ||
            m.contractDetail4 !== items.contractDetail4 ||
            m.dealer !== items.dealer ||
            m.department !== items.department ||
            m.statusFile !== items.statusFile ||
            m.note1 !== items.note1 ||
            m.note2 !== items.note2 ||
            m.status !== items.status ||
            m.isActive !== items.isActive
          ) {
          } else {
            checkSame = true;
            d.items.push({ ...m });
          }
        });

        if (!checkSame) {
          data.push({
            group: i,
            items: [{ ...m }],
          });
        }
      }
    });

    console.log('data', data);

    const dataGroup = [];
    data.forEach((m) => {
      if (m.items.length > 1) {
        const item = m.items[0];

        const amountList = m.items.map((m) => m.amount);
        let amount = 0;
        amountList.forEach((f) => {
          amount += f * 1000;
        });
        amount /= 1000;

        let trans1 = '';
        if (item.transactionType === '102 Forward Transaction - Buy') {
          trans1 = 'Purchase Document';
        } else {
          trans1 = 'Sale Document';
        }

        const group = {
          IdListTable: m.items.map((m) => m.id),

          TransactionNo: item.transactionNo,
          CompanyCode: item.companyCode,
          BankCode: item.bankCode,
          ProductType: item.productType,
          ForwardType: item.forwardType,
          TransactionType: trans1,

          BusinessPartnerCode: item.businessPartnerCode,
          ContractNo: item.contractNo,
          ContractDateStr: item.contractDateStr,
          StartDateStr: item.startDateStr,
          EndDateStr: item.endDateStr,
          Currency: item.currency,
          SpotRate: item.spotRate,
          BaseRate: item.baseRate,
          SwapRateBase: item.swapRateBase,
          ForwardRate: item.forwardRate,
          SwapRate: item.swapRate,
          Gvc: item.gvc,
          Portfolio: item.portfolio,
          Assignment: item.assignment,
          Characteristic: item.characteristic,
          InternalReference: item.internalReference,
          RequestType: item.requestType,
          ContractDetail1: item.contractDetail1,
          ContractDetail2: item.contractDetail2,
          ContractDetail3: item.contractDetail3,
          ContractDetail4: item.contractDetail4,
          Dealer: item.dealer,
          Department: item.department,
          StatusFile: item.statusFile,
          Note1: item.note1,
          Note2: item.note2,
          Status: item.status,

          Amount: amount,
          CurrencyDigit: item.currencyDigit,
          mode: 'group',
        };

        dataGroup.push(group);
      }
    });

    console.log('group', dataGroup);

    if (!dataGroup.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Can’t auto grouping because transaction is not same!',
      });
      return;
    }

    this.setState(
      {
        modalAutoGroup: dataGroup,
      },
      this.modalAutoGroupRef.open
    );
  };

  onSaveAutoGrouping = (e) => {
    e.preventDefault();

    const promiseArry = [];
    this.state.modalAutoGroup.map((m) => {
      promiseArry.push(this.props.createFxMatching({ ...m }));
    });
    console.log(promiseArry);

    Promise.all(promiseArry).then((responseArry) => {
      this.onSearch();
      this.modalAutoGroupRef.close();
      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
    });
  };

  onSaveGrouping = (e) => {
    e.preventDefault();
    this.props.createFxMatching(this.state.modalGroup).then(() => {
      this.onSearch();
      this.modalGroupRef.close();
      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,
      onClickConfirm: this.onClickConfirm,
      onSubmitConfirm: this.onSubmitConfirm,
      onClickSelectBank: this.onClickSelectBank,
      onSubmitSelectBank: this.onSubmitSelectBank,
      onChangeInput: this.onChangeInput,
      onClickWithdraw: this.onClickWithdraw,
      onClickBuMatching: this.onClickBuMatching,
      onClickUnMatch: this.onClickUnmatch,
      onClickSelectBusinessPartner: this.onClickSelectBusinessPartner,
      onSubmitSelectBusinessPartner: this.onSubmitSelectBusinessPartner,
      onSaveBusinessPartner: this.onSaveBusinessPartner,
      onClickSendEmail: this.onClickSendEmail,
      onClickExport: this.onClickExport,
      onClickSelectDate: this.onClickSelectDate,
      onChangeDateModal: this.onChangeDateModal,
      onSaveSelectDate: this.onSaveSelectDate,
      onCreateFxMatching: this.onCreateFxMatching,
      onSaveCreateFxConfirmation: this.onSaveCreateFxConfirmation,
      onChangeCreateModal: this.onChangeCreateModal,
      onClickGroup: this.onClickGroup,
      onSaveGrouping: this.onSaveGrouping,
      onSaveAutoGrouping: this.onSaveAutoGrouping,
    };

    return (
      <div>
        <FxConfirmation
          {...props}
          {...this.state}
          modalConfirmBankRef={(e) => (this.modalConfirmBankRef = e)}
          modalWithdrawRef={(e) => (this.modalWithdrawRef = e)}
          modalEditRef={(e) => (this.modalEditRef = e)}
          modalBuRef={(e) => (this.modalBuRef = e)}
          modalUnmatchRef={(e) => (this.modalUnmatchRef = e)}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalBuisnessPartnerRef={(e) => (this.modalBuisnessPartnerRef = e)}
          modalAlertRef={(e) => (this.modalAlertRef = e)}
          modalEmailRef={(e) => (this.modalEmailRef = e)}
          modalDateRef={(e) => (this.modalDateRef = e)}
          modalCreateRef={(e) => (this.modalCreateRef = e)}
          modalGroupRef={(e) => (this.modalGroupRef = e)}
          modalUngroupRef={(e) => (this.modalUngroupRef = e)}
          modalAutoGroupRef={(e) => (this.modalAutoGroupRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.fxConfirmation,
  }),
  {
    searchFxMatching,
    updateSearchResult,
    saveFxMatching,
    getBuMatching,
    addAlert,
    getMatchingDate,
    getMatchingTime,
    getExportFxConfirmation,
    clearResult,
    sendEmailFxMatching,
    changeDateFxMatching,
    createFxMatching,
    getFxConfirmationCompany,
  }
)(FxConfirmationContainer);
