import { connect } from 'react-redux';
import React, { Component } from 'react';

import Modal from '../../common/Modal';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import SearchMaintainInterest from './SearchMaintainInterest';
import {
  editCriteria,
  clearCriteria,
  checkInterest,
  updateSearchResult,
  saveMaintainInterest,
  searchMaintainInterest,
} from '../../reducers/automateInterest/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-automate-interest';

const initData = {
  Action: 'create',
};

class SearchMaintainInterestContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
    checkInterests: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMaintainInterest({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalData = (e) => {
    this.setState(
      {
        modalData: {
          ...initData,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          Mode: 'edit',
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        ...toUpperKey(rowData),
        Action: 'status',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    this.props.saveMaintainInterest({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMaintainInterest({ ...this.props.criteria });
    });
  };

  /* ----------------------------- Check Interest ----------------------------- */
  handlerOpenModalCheckInterest = () => {
    this.props.checkInterest().then((res) => {
      this.setState(
        {
          checkInterests: res.payload,
        },
        () => {
          this.modalCheckInterestRef.open();
        }
      );
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <div>
        <SearchMaintainInterest
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          onClickOpenModalCheckInterest={this.handlerOpenModalCheckInterest}
        />
        <Modal
          ref={(e) => (this.modalCheckInterestRef = e)}
          textHeader="Check Interest"
          bgHeader="bg-primary"
          size="modal-lg"
        >
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th>Transaction NO.</th>
                  <th>Bank</th>
                  <th>Interest Rate</th>
                  <th> Amount</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {this.state.checkInterests.map((m, i) => (
                  <tr key={i}>
                    <td>{m.transactionNo}</td>
                    <td align="center"> {m.bankCode}</td>
                    <td align="right">{m.interest}</td>
                    <td align="right">{Number(m.amount || 0).toLocaleString()}</td>
                    <td align="center">{window.moment(m.startDate).format('DD/MM/YYYY')}</td>
                    <td align="center">
                      {!!m.endDate && window.moment(m.endDate).format('DD/MM/YYYY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.automateInterest,
  }),
  {
    searchMaintainInterest,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    saveMaintainInterest,
    checkInterest,
  }
)(SearchMaintainInterestContainer);
