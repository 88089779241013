import { connect } from 'react-redux';
import React, { Component } from 'react';

import Deposit from './Deposit';
import { addAlert } from '../../../reducers/layout/action';
import { getExchangeRate, getCurrencyDigit } from '../../../reducers/master/action';
import {
  toFixed,
  toUpperKey,
  compareDate,
  getCountDay,
  getCountMonth,
  getPermission,
  validateCashflow,
} from '../../../common/helpper';
import {
  getLoan,
  saveLoan,
  splitCashFlow,
  getInterestRate,
  processCashflow,
  getTemplateInterestRateList,
} from '../../../reducers/deposit/action';

const $ = window.jQuery;

const permission = getPermission('Funding&Investment', 'Create Deposit');

const initInformation = {
  FinanceGroupId: '',
  CompanyCode: '',
  Remark: '',
  IsProject: false,
  CalendarId: '',
  Source: 'Deposit',
};

const initStructure = {
  LoanType: '',
  PartnerType: 'External',
  IcType: '',
  TransactionType: 'Deposit',
  BusinessPartner: '',
  ContractDateStr: '',
  TermStartStr: '',
  TermEndStr: '',
  Amount: '',
  Currency: '',
  IsThaiSweep: false,
  ExternalRef: '',

  inputMaskAmount: {
    prefix: '',
    digits: 3,
    // digitsOptional: false,
    // placeholder: '0.000'
  },
};

const initInterest = {
  InterestRateId: '',
  InterestTemplateName: '',
  InterestRate: '',
  CalculateMethod: '',

  PeriodEndType: '',
  IsManualInclude: '',
  IsManualMonthEnd: '',
  ManualPeriodEnd: '',
  ManualDay: '',
  ManualWorkingDay: '',

  DueDateType: '',
  IsDueDateMonthEnd: '',
  ManualDueDate: '',
  DueDateDay: '',
  DueDateWorkingDay: '',
  Frequency: '',
};

const initAdministrator = {
  BusinessArea: '',
  GenValnClass: '',
  NDay: '',
  TransactionNoRef: [],
};

const initCashFlow = {
  BankCodeLoan: [''],
  CompanyByLoan: '',
  resultLoan: [],
  AccountNoAllLoan: '',
  PaymentMethodAllLoan: '',

  BankCodeDeposit: [''],
  CompanyByDeposit: '',
  resultDeposit: [],
  AccountNoAllDeposit: '',
  PaymentMethodAllDeposit: '',
};

const initModalSplit = {
  CashflowId: '',
  CashFlowType: '',
  Amount: '',
  OldAmount: '',
  Mode: '',
  Source: '',
  inputMaskAmount: {
    prefix: '',
    digits: 2,
    digitsOptional: false,
    placeholder: '0.00',
  },
};

class DepositContainer extends Component {
  state = {
    ...initInformation,
    ...initStructure,
    ...initInterest,
    ...initAdministrator,
    ...initCashFlow,
    permission,
    mode: 'create',

    modalSplit: {
      ...initModalSplit,
    },
    modalDelete: {
      Row: [],
    },
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;
    if (!transactionNo || transactionNo.toString().toLowerCase() === 'create') {
      this.setState({
        mode: 'create',
      });
    } else {
      this.fetchTransaction(transactionNo);
    }

    this.props.getTemplateInterestRateList();

    $('body').on('click', `button.split-result`, (e) => this.onClickOpenModalSplit(e));
    $('body').on('click', `button.undo-split-result`, (e) => this.onClickOpenModalUndoSplit(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.split-result`);
    $('body').off('click', `button.undo-split-result`);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      CompanyCode,
      BusinessPartner,
      TermStartStr,
      TermEndStr,
      Currency,
      PartnerType,
      TransactionType,
      LoanType,
      Amount,
    } = this.state;

    // get currency digit
    if (prevState.Currency !== Currency) {
      this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          Amount: toFixed(Number((Amount.toString() || '').replace(/,/g, '') || 0), digit),
        });
      });
    }

    // set GVC
    if (
      (prevState.LoanType !== LoanType ||
        prevState.TermStartStr !== TermStartStr ||
        prevState.TermEndStr !== TermEndStr) &&
      LoanType &&
      TermStartStr
    ) {
      this.setState({
        // GenValnClass: this.getGalnVenClass(LoanType, TermStartStr, TermEndStr),
        NDay: this.getNDay(LoanType, TermStartStr, TermEndStr),
      });
    }

    // Set Company Cash Flow
    if (
      (prevState.CompanyCode !== CompanyCode ||
        prevState.PartnerType !== PartnerType ||
        prevState.TransactionType !== TransactionType ||
        prevState.BusinessPartner !== BusinessPartner) &&
      CompanyCode &&
      BusinessPartner &&
      PartnerType &&
      TransactionType
    ) {
      const companyByDeposit = this.setCompanyDeposit(
        PartnerType,
        TransactionType,
        CompanyCode,
        BusinessPartner
      );
      this.setState({
        CompanyByDeposit: companyByDeposit,
      });
    }
  }

  fetchTransaction(transactionNo) {
    if (!transactionNo) return;

    this.props.getLoan(transactionNo).then((response) => {
      if (response.error || !response.payload) return;

      const s = response.payload;

      this.setState({
        ...toUpperKey(s),
        mode: 'edit',
        ManualPeriodEnd:
          s.periodEndType === 'End of Term'
            ? null
            : s.isManualMonthEnd == null
              ? ''
              : s.isManualMonthEnd
                ? 'Month End'
                : 'Day',
        ManualDueDate:
          s.dueDateType === 'End of Term'
            ? null
            : s.isDueDateMonthEnd == null
              ? ''
              : s.isDueDateMonthEnd
                ? 'Month End'
                : 'Day',
        ManualDay: s.periodEndType === 'End of Term' ? null : s.manualDay,
        DueDateDay: s.dueDateType === 'End of Term' ? null : s.dueDateDay,
        IsManualInclude: s.isManualInclude == null ? false : s.isManualInclude,

        BankCodeDeposit: s.bankCodeDeposit.split(','),
        BankCodeLoan: s.bankCodeLoan.split(','),
        CompanyByDeposit: this.setCompanyDeposit(
          s.partnerType,
          s.transactionType,
          s.companyCode,
          s.businessPartner
        ),
      });

      this.setCashFlowTab(s.cashFlow || []);
    });
  }

  setCashFlowTab(resultCashFlow) {
    const resultLoan = [];
    const resultDeposit = [];

    for (let i = 0; i < resultCashFlow.length; i++) {
      const r = resultCashFlow[i];

      const cashFlowType = (r.cashFlowType || '').toLowerCase();

      if (r.currency === r.localCurrency && r.currency === r.bankCurrency) {
        r.exchangeRate = 1;
      }

      if (cashFlowType === 'loan') {
        resultLoan.push(r);
      } else if (cashFlowType === 'deposit') {
        resultDeposit.push(r);
      }
    }

    this.setState({
      resultLoan,
      resultDeposit,
    });
  }

  onChangeInputData = (e) => {
    const { name, value, type, checked } = e.target;
    this.setState((state) => {
      let stateToUpdate = {
        [name]: type === 'checkbox' ? checked : value,
      };

      // Information
      if (name === 'CompanyCode') {
        stateToUpdate.BusinessArea = '';
      } else if (name === 'IsProject') {
        stateToUpdate.IsProject = !state.IsProject;
      }

      // Tab Structure
      else if (name === 'LoanType') {
        if (value === 'On Call') {
          stateToUpdate.TermEndStr = '';
        }
        stateToUpdate.GenValnClass = this.getGalnVenClass(
          value,
          this.state.TermStartStr,
          this.state.TermEndStr
        );
      } else if (name === 'PartnerType') {
        stateToUpdate.TransactionType = '';
        stateToUpdate.IcType = '';
        stateToUpdate.BusinessPartner = '';
        if (value === 'External') {
          stateToUpdate.TransactionType = 'Loan';
        }
      } else if (name === 'BusinessPartner') {
        if (state.PartnerType !== 'External') {
          stateToUpdate.BankCodeDeposit = '';
        }
      } else if (name === 'ContractDateStr') {
        if (value === '') {
          stateToUpdate.ContractDateStr = '';
          stateToUpdate.ContractDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.TermStartStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Contract date must be less than or equal Term start.',
            });
            return {
              [name]: state.ContractDateStr,
            };
          }
          if (compareDate(mergeState.ContractDateStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Contract date must be less than or equal Term end.',
            });
            return {
              [name]: state.ContractDateStr,
            };
          }
        }
      } else if (name === 'TermStartStr') {
        if (value === '') {
          stateToUpdate.TermStartStr = '';
          stateToUpdate.TermStart = '';
        } else {
          stateToUpdate.GenValnClass = this.getGalnVenClass(
            this.state.LoanType,
            value,
            this.state.TermEndStr
          );
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.TermStartStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term start must be more than or equal Contract date.',
            });
            return {
              [name]: state.TermStartStr,
            };
          }
          if (compareDate(mergeState.TermStartStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term start must be less than or equal Term end.',
            });
            return {
              [name]: state.TermStartStr,
            };
          }
        }
      } else if (name === 'TermEndStr') {
        if (value === '') {
          stateToUpdate.TermEndStr = '';
          stateToUpdate.TermEnd = '';
        } else {
          stateToUpdate.GenValnClass = this.getGalnVenClass(
            this.state.LoanType,
            this.state.TermStartStr,
            value
          );
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.TermStartStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term end must be more than or equal Term start.',
            });
            return {
              [name]: state.TermEndStr,
            };
          }
          if (compareDate(mergeState.ContractDateStr, mergeState.TermEndStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Term end must be more than or equal Contract date.',
            });
            return {
              [name]: state.TermEndStr,
            };
          }
        }
      } else if (name === 'IsThaiSweep') {
        if (checked) {
          stateToUpdate = {
            ...stateToUpdate,
            CalculateMethod: 'Act/365',
            InterestRateId: '',
            PeriodEndType: 'Manual Period End',
            ManualPeriodEnd: 'Day',
            ManualDay: '22',
            ManualWorkingDay: '0: No Shift',
            DueDateType: 'Manual Due Date',
            ManualDueDate: 'Month End',
            DueDateDay: '',
            Frequency: '',
            DueDateWorkingDay: '6: Next Month Modified',
            IsManualInclude: true,
          };
        }
      }

      // Tab Interest
      else if (name === 'IsManualInclude') {
        stateToUpdate.IsManualInclude = !state.IsManualInclude;
      } else if (name === 'PeriodEndType') {
        if (value === 'End of Term') {
          stateToUpdate.ManualPeriodEnd = '';
          stateToUpdate.IsManualMonthEnd = false;
          stateToUpdate.ManualDay = '';
        }
      } else if (name === 'ManualPeriodEnd') {
        if (value === 'Month End') {
          stateToUpdate.IsManualMonthEnd = true;
          stateToUpdate.ManualDay = '';
        } else stateToUpdate.IsManualMonthEnd = false;
      } else if (name === 'ManualDay') {
        if (value > 31) stateToUpdate.ManualDay = 31;
        else if (value < 1) stateToUpdate.ManualDay = 1;
      } else if (name === 'DueDateType') {
        if (value === 'End of Term') {
          stateToUpdate.ManualDueDate = '';
          stateToUpdate.DueDateDay = '';
          stateToUpdate.IsDueDateMonthEnd = false;
        }
      } else if (name === 'ManualDueDate') {
        if (value === 'Month End') {
          stateToUpdate.IsDueDateMonthEnd = true;
          stateToUpdate.DueDateDay = '';
        } else stateToUpdate.IsDueDateMonthEnd = false;
      } else if (name === 'DueDateDay') {
        if (value > 31) stateToUpdate.DueDateDay = 31;
        else if (value < 1) stateToUpdate.DueDateDay = 1;
      }

      return stateToUpdate;
    });

    if (name === 'InterestRateId' && value) {
      this.props.getInterestRate(value).then((response) => {
        const templateData = response.payload || initInterest;

        if (!response.error) {
          this.setState({
            ...toUpperKey(templateData),
            ManualPeriodEnd:
              templateData.periodEndType === 'End of Term'
                ? null
                : templateData.isManualMonthEnd == null
                  ? ''
                  : templateData.isManualMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDueDate:
              templateData.dueDateType === 'End of Term'
                ? null
                : templateData.isDueDateMonthEnd == null
                  ? ''
                  : templateData.isDueDateMonthEnd
                    ? 'Month End'
                    : 'Day',
            ManualDay: templateData.periodEndType === 'End of Term' ? null : templateData.manualDay,
            DueDateDay: templateData.dueDateType === 'End of Term' ? null : templateData.dueDateDay,
            IsManualInclude:
              response.payload.isManualInclude == null ? false : response.payload.isManualInclude,
          });
        }
      });
    }
  };

  onChangeSelect2 = (e) => {
    if (e.value.length < 1) {
      this.setState({
        ...this.state,
        [e.name]: [''],
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        ...this.state,
        [e.name]: e.value.filter((item) => item !== ''),
      });
    } else {
      this.setState({
        ...this.state,
        [e.name]: e.value,
      });
    }
  };

  getGalnVenClass = (loanType, startTerm, endTerm) => {
    if (loanType === 'On Call') {
      return 'ON CALL';
    }
    if (startTerm && endTerm) {
      const m = getCountMonth(startTerm, endTerm);
      if (m <= 3.1) {
        return '<= 3 Months';
      }
      if (m <= 12.13) {
        return '> 3 <= 12 Months';
      }
      return '> 12 Months';
    }
    return '';
  };

  getNDay = (loanType, startTerm, endTerm) => {
    if (loanType !== 'On Call' && startTerm && endTerm) {
      const m = getCountDay(startTerm, endTerm);
      if (m <= 3) {
        return m;
      }
      if (m <= 12) {
        return m;
      }
      return m;
    }
    return '';
  };

  setCompanyDeposit = (partnerType, transactionType, companyCode, businessPartner) =>
    // if (partnerType === 'External') {
    //     return ''
    // }
    // else if (partnerType === 'Intercompany') {
    //     if (transactionType === 'Loan') {
    //         return businessPartner
    //     }
    //     else if (transactionType === 'Deposit') {
    //         return companyCode
    //     }
    //     return ''
    // }
    // return ''
    companyCode;

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmit = (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formStructorRef,
      this.formInterestRef,
      this.formAdministratorRef,
      this.formCashFlowDepositRef,
      this.formResultCashFlowDepositRef,
    ]);
    if (inValidFormName) return;

    const state = { ...this.state };
    if (!state.InterestRate || parseFloat(state.InterestRate) <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Interest rate (%) must more than zero.',
      });
      return;
    }
    if (state.InterestRate > 100) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Interest rate (%) must less than or eqaul 100%.',
      });
      return;
    }

    if (!(state.resultDeposit || []).length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please get cash flow.',
      });
      return;
    }

    const cashflow = [...this.state.resultDeposit];

    const errorValidate = validateCashflow(cashflow);
    if (errorValidate) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: errorValidate,
      });
      return;
    }

    const submitData = {
      ...this.state,
      Source: 'Deposit',
      BankCodeDeposit: this.state.BankCodeDeposit.toString(),
      BankCodeLoan: this.state.BankCodeLoan.toString(),
      CashFlow: cashflow,
    };
    delete submitData.resultDeposit;

    this.props.saveLoan(submitData).then((response) => {
      if (!response.error) {
        const transactionNo = response.payload;
        this.fetchTransaction(transactionNo);

        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The transaction was successfully saved',
          buttons: [
            <button
              className="btn btn-success"
              onClick={this.onClickBackToPage}
              data-dismiss="modal"
            >
              Continue
            </button>,
          ],
        });

        if (response.payload !== this.props.routeProp.match.params.id) {
          window.location.href = `/loan-investment/deposit/${response.payload}`;
        }
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/loan-investment/create-deposit?s=true');
  };

  // Tab Cash Flow
  onClickGetCashflow = () => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formStructorRef,
      this.formInterestRef,
      this.formAdministratorRef,
      this.formCashFlowDepositRef,
    ]);
    if (inValidFormName) return;

    const state = { ...this.state };
    let error = '';
    if (!state.InterestRate || parseFloat(state.InterestRate) <= 0) {
      error = 'Interest rate (%) must more than zero.';
    }
    if (!state.Currency) {
      error = 'Please specific Currency for process cashflow.';
    }

    if (error) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: error,
      });
      return;
    }

    state.BankCodeDeposit = (state.BankCodeDeposit || []).join(',');
    state.BankCodeLoan = (state.BankCodeLoan || []).join(',');
    this.props.processCashflow(state).then((response) => {
      if (response.error) return;

      const deposit = [];
      response.payload.forEach((m) => {
        if (m.cashFlowType === 'DEPOSIT') deposit.push({ ...m });
      });

      this.setState({
        resultDeposit: deposit,
      });
    });
  };

  async getExchangeRate(rowData, idx, keyForUpdate) {
    const criteria = {
      CurrencyFrom: rowData.currency,
      CurrencyTo: rowData.localCurrency,
      Type: (rowData.cashFlowType || '').toLowerCase() === 'deposit' ? 'G' : 'B',
    };

    let exchangeRate = 1;
    if (criteria.CurrencyFrom !== criteria.CurrencyTo) {
      const response = await this.props.getExchangeRate(criteria);
      if (response.error) return;
      exchangeRate = response.payload;
    }

    this.setState((state) => ({
      [keyForUpdate]: state[keyForUpdate].map((m, i) => {
        if (i === idx)
          return {
            ...m,
            exchangeRate: exchangeRate || 1,
            localCurrencyAmount: rowData.amount * exchangeRate,
          };
        return m;
      }),
    }));
  }

  onChangeInputTable = (e, index, type) => {
    const { name, value, label } = e.target;
    this.setState((state) => {
      const keyForUpdate = type === 'deposit' ? 'resultDeposit' : 'resultLoan';
      const result = state[keyForUpdate];
      return {
        [keyForUpdate]: result.map((m, i) => {
          if (i === index) {
            const obj = {
              ...m,
            };
            if (name === 'bankAccountNo') {
              obj.bankAccountNo = value;
              const bankAccountCurrency = label.split('|')[2].trim();
              if (
                obj.currency === bankAccountCurrency &&
                obj.localCurrency === bankAccountCurrency
              ) {
                obj.exchangeRate = 1;
                obj.localCurrencyAmount = obj.amount;
              } else {
                obj.exchangeRate = '';
                obj.localCurrencyAmount = '';
              }
            } else if (name === 'originalInterestAmount') {
              const amount = Number(value || 0);
              if (obj.wht) obj.whtAmount = Math.round(amount * obj.wht * 100) / 100;
              obj[name] = value;
            } else obj[name] = value;
            return obj;
          }
          return m;
        }),
      };
    });
  };

  onChangeInputHeader = (e, type) => {
    const { name, value, label } = e.target;
    this.setState((state) => {
      const keyForUpdate =
        (type || '').toLowerCase() === 'deposit' ? 'resultDeposit' : 'resultLoan';
      let keyInputAll = '';
      if (name === 'bankAccountNoAll') {
        keyInputAll =
          (type || '').toLowerCase() === 'deposit' ? 'AccountNoAllDeposit' : 'AccountNoAllLoan';
      } else if (name === 'paymentMethodAll') {
        keyInputAll =
          (type || '').toLowerCase() === 'deposit'
            ? 'PaymentMethodAllDeposit'
            : 'PaymentMethodAllLoan';
      }
      const result = state[keyForUpdate];

      return {
        [keyInputAll]: value,
        [keyForUpdate]: result.map((m, i) => {
          const obj = {
            ...m,
          };

          if (name === 'bankAccountNoAll') {
            obj.bankAccountNo = value;
            const bankAccountCurrency = label.split('|')[2].trim();
            if (obj.currency === bankAccountCurrency && obj.localCurrency === bankAccountCurrency) {
              obj.exchangeRate = 1;
              obj.localCurrencyAmount = obj.amount;
            } else {
              obj.exchangeRate = '';
              obj.localCurrencyAmount = '';
            }
          } else if (name === 'paymentMethodAll') {
            obj.paymentMethod = value;
          }

          return obj;
        }),
      };
    });
  };

  // Modal Split
  onClickOpenModalSplit = (e) => {
    const row = e.currentTarget.attributes;
    const digit = row.currency.value;

    this.setState(
      {
        modalSplit: {
          ...initModalSplit,
          CashflowId: row.cashflowid.value,
          CashFlowType: row.cashflowtype.value,
          OldAmount: toFixed(
            Number((row.amount.value.toString() || '').replace(/,/g, '') || 0),
            digit
          ),
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
        },
      },
      this.modalSplitRef.open
    );
  };

  onChangeModalSplit = (e) => {
    this.setState({
      modalSplit: {
        ...this.state.modalSplit,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalSplit = (e) => {
    e.preventDefault();

    const modalSplit = { ...this.state.modalSplit };

    if (
      !modalSplit.Amount ||
      parseFloat((modalSplit.Amount.toString() || '').replace(/,/g, '')) === 0
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Split amount must not be zero.',
      });
      return;
    }
    if (
      parseFloat((modalSplit.Amount.toString() || '').replace(/,/g, '')) >
      parseFloat((modalSplit.OldAmount.toString() || '').replace(/,/g, ''))
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Split amount must less than amount.',
      });
      return;
    }

    const submitData = {
      CashflowId: modalSplit.CashflowId,
      Amount: modalSplit.Amount,
      Mode: 'split',
    };

    this.props.splitCashFlow(submitData).then((response) => {
      if (response.error && !response.payload) return;
      const resultCashFlow = response.payload || [];
      this.setDataCashFlow(resultCashFlow);
    });
    this.modalSplitRef.close();
  };

  // Modal Undo Split
  onClickOpenModalUndoSplit = (e) => {
    this.setState(
      {
        modalSplit: {
          ...initModalSplit,
          CashflowId: e.currentTarget.attributes.cashflowid.value,
          Amount: e.currentTarget.attributes.amount.value,
          CashFlowType: e.currentTarget.attributes.cashflowtype.value,
        },
      },
      this.modalUndoSplitRef.open
    );
  };

  onSubmitModalUndoSplit = () => {
    const modalSplit = { ...this.state.modalSplit };

    const submitData = {
      CashflowId: modalSplit.CashflowId,
      Amount: modalSplit.Amount,
      Mode: 'undo',
    };

    this.props.splitCashFlow(submitData).then((response) => {
      if (response.error && !response.payload) return;
      const resultCashFlow = response.payload || [];
      this.setDataCashFlow(resultCashFlow);
    });
    this.modalUndoSplitRef.close();
  };

  async setDataCashFlow(resultCashFlow) {
    this.state.resultLoan = [];
    this.state.resultDeposit = [];

    for (let i = 0; i < resultCashFlow.length; i++) {
      const r = resultCashFlow[i];

      const { currency } = r;
      const localCurrency = r.bankAccountCurrency;

      let exchangeRate = 1;
      if (currency === localCurrency) {
        r.exchangeRate = exchangeRate;
        r.localCurrencyAmount = r.amount;
      } else {
        const type = 'deposit';
        const criteria = {
          CurrencyFrom: currency,
          CurrencyTo: localCurrency,
          Type: (type || '').toLowerCase() === 'deposit' ? 'G' : 'B',
        };
        const response = await this.props.getExchangeRate(criteria);
        if (response.error) return;
        exchangeRate = response.payload;

        r.exchangeRate = exchangeRate;
        r.localCurrencyAmount = r.amount * exchangeRate;
      }

      this.state.resultDeposit.push(r);
    }
  }

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,

      template: this.props.template,
      onChangeInputTable: this.onChangeInputTable,
      onClickGetCashflow: this.onClickGetCashflow,
      onChangeInputHeader: this.onChangeInputHeader,

      onChangeModalSplit: this.onChangeModalSplit,
      onSubmitModalSplit: this.onSubmitModalSplit,
      onSubmitModalUndoSplit: this.onSubmitModalUndoSplit,

      formDetailRef: (e) => (this.formDetailRef = e),
      formStructorRef: (e) => (this.formStructorRef = e),
      formInterestRef: (e) => (this.formInterestRef = e),
      formAdministratorRef: (e) => (this.formAdministratorRef = e),
      formCashFlowDepositRef: (e) => (this.formCashFlowDepositRef = e),
      formResultCashFlowDepositRef: (e) => (this.formResultCashFlowDepositRef = e),

      tabRef: (e) => (this.tabRef = e),
      onChangeSelect2: this.onChangeSelect2,
    };

    return (
      <Deposit
        {...props}
        state={this.state}
        modalSplitRef={(e) => (this.modalSplitRef = e)}
        modalUndoSplitRef={(e) => (this.modalUndoSplitRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.deposit,
  }),
  {
    saveLoan,
    getLoan,
    addAlert,
    getInterestRate,
    getTemplateInterestRateList,
    processCashflow,
    getExchangeRate,
    splitCashFlow,
    getCurrencyDigit,
  }
)(DepositContainer);
