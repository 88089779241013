import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';

export const REQUEST_SEARCH_DERIVATIES_JOURNAL = '@@request/SEARCH_DERIVATIES_JOURNAL';
export const RECEIVE_SEARCH_DERIVATIES_JOURNAL = '@@receive/SEARCH_DERIVATIES_JOURNAL';
export const FAIL_SEARCH_DERIVATIES_JOURNAL = '@@fail/SEARCH_DERIVATIES_JOURNAL';

export const UPDATE_SEARCH_RESULT_DERIVATIES_JOURNAL = 'UPDATE_SEARCH_RESULT_DERIVATIES_JOURNAL';

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_DERIVATIES_JOURNAL,
  payload: {
    name,
    value,
    index,
  },
});

export const searchDerivatiesJournal = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/SearchDerivativesJournal', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_DERIVATIES_JOURNAL,
      RECEIVE_SEARCH_DERIVATIES_JOURNAL,
      FAIL_SEARCH_DERIVATIES_JOURNAL,
    ],
  },
});
