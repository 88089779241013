import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';
import { downloadFile } from '../../common/helpper';

export const REQUEST_SEARCH_LOG_ERROR = '@@request/SARCH_LOG_ERROR';
export const RECEIVE_SEARCH_LOG_ERROR = '@@receive/SEARCH_LOG_ERROR';
export const FAIL_SEARCH_LOG_ERROR = '@@fail/SEARCH_LOG_ERROR';

export const REQUEST_GET_LOG_ERROR = '@@request/GET_LOG_ERROR';
export const RECEIVE_GET_LOG_ERROR = '@@receive/GET_LOG_ERROR';
export const FAIL_GET_LOG_ERROR = '@@fail/GET_LOG_ERROR';

export const REQUEST_DOWNLOAD_LOG_FILE = '@@request/DOWNLOAD_LOG_FILE';
export const RECEIVE_DOWNLOAD_LOG_FILE = '@@receive/DOWNLOAD_LOG_FILE';
export const FAIL_DOWNLOAD_LOG_FILE = '@@fail/DOWNLOAD_LOG_FILE';

export const downloadLogFile = (logId, row) => ({
  [RSAA]: {
    endpoint: endpoint(`/report/DownloadLogFile?logId=${logId}&row=${row}`),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_LOG_FILE,
      {
        type: RECEIVE_DOWNLOAD_LOG_FILE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_LOG_FILE,
    ],
  },
});

export const getLogError = (id) => ({
  [RSAA]: {
    endpoint: endpoint(`/report/GetLog/${id}`),
    method: 'GET',
    types: [REQUEST_GET_LOG_ERROR, RECEIVE_GET_LOG_ERROR, FAIL_GET_LOG_ERROR],
  },
});

export const searchLogError = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/SearchLog', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_LOG_ERROR, RECEIVE_SEARCH_LOG_ERROR, FAIL_SEARCH_LOG_ERROR],
  },
});
