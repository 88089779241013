import {
  RECEIVE_GET_FACILITY_LIST,
  EDIT_CONTRACT_LOAN_CRITERIA,
  RECEIVE_SEARCH_CONTRACT_LOAN,
  CLEAR_CONTRACT_LOAN_CRITERIA,
  RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  ContractDateStr: '',
  Status: '',
  Source: 'Contract Loan',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
  template: [],
  facilityList: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_GET_FACILITY_LIST:
      return {
        ...state,
        facilityList: [...action.payload],
      };
    case RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE:
      return {
        ...state,
        template: [...action.payload],
      };
    case CLEAR_CONTRACT_LOAN_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_CONTRACT_LOAN_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_CONTRACT_LOAN:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
