import React from 'react';

import InputMask from '../../common/InputMask';
import { toFixed } from '../../common/helpper';
import DatePicker from '../../common/DatePicker';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { MASTER_BANK_ACCOUNT } from '../../reducers/master/action';

let inputMaskOpt = {
  prefix: '',
  digits: 3,
  digitsOptional: false,
  placeHolder: '0.00000',
};

export default ({
  state,
  onChangeInputTable,
  resultType,
  BankCode,
  CompanyCode,
  PartnerType,
  isBen,
  isCharge,
  isEndOfTerms,

  bankAccountNoAll,
  paymentMethodAll,
  onChangeInputHeader,

  formRef,
  formName,
  permission,
  viewOnly,
  status,
  isAccountBank,
}) => {
  const first = state[0];
  if (first) {
    let { currencyDigit } = first;
    if (currencyDigit === null || currencyDigit === undefined) currencyDigit = 3;
    if (currencyDigit !== inputMaskOpt.digits)
      inputMaskOpt = {
        ...inputMaskOpt,
        digits: currencyDigit,
      };
  }

  const viewAll = !!(viewOnly || !permission);
  const checkPaymentDate = (status || '').toLowerCase() === 'rollover' ? false : viewOnly;
  return (
    <form ref={formRef} name={formName}>
      <div className="table-responsive">
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              {isCharge ? <th className="th-white">Action</th> : null}
              {!isCharge ? (
                <>
                  <th className="th-white">Split</th>
                  <th className="th-white">
                    Undo
                    <br />
                    Split
                  </th>
                  <th className="th-white">Period</th>
                  <th className="th-white">Days</th>
                </>
              ) : null}
              <th className="th-white">Payment Date</th>
              <th className="th-white">Direction</th>
              <th className="th-white">Flow Type</th>
              <th className="th-white td-width-number">Net Amount</th>
              <th className="th-white td-width-number">Original Amount</th>
              <th className="th-white td-width-number">WHT</th>
              <th className="th-white td-width-number">Duty Fee</th>
              <th className="th-white td-width-number">Bank Charge</th>
              <th className="th-white">Currency</th>
              <th className="th-white">
                Bank Account{viewAll ? null : <span style={{ color: '#e64a19' }}>*</span>}
                <br />
                <div className={viewAll ? 'td-width-account' : 'td-input-account'}>
                  {viewAll ? null : BankCode ? (
                    <MasterDropdown
                      noValidateOption
                      masterType={MASTER_BANK_ACCOUNT}
                      isChoose
                      notMultipleSelect2
                      value={bankAccountNoAll}
                      onChange={(e) =>
                        onChangeInputHeader(
                          {
                            target: e,
                          },
                          resultType
                        )
                      }
                      saveLocalState
                      bankCode={BankCode}
                      disabled={viewOnly}
                      companyCode={CompanyCode}
                      name="bankAccountNoAll"
                      isOutFlow
                      customeLabel={(m) =>
                        `${m.bankCode} | ${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}${m.bankAccountNoOfBank ? ` (${m.bankAccountNoOfBank})` : ''}`
                      }
                      customeValue={(m) => `${m.bankAccountNo}`}
                      isAccountBank={isAccountBank}
                    />
                  ) : (
                    <MasterDropdownUI
                      onChange={(e) =>
                        onChangeInputHeader(
                          {
                            target: e,
                          },
                          resultType
                        )
                      }
                      value={bankAccountNoAll}
                      isChoose
                      disabled={viewOnly}
                      notMultipleSelect2
                      name="bankAccountNoAll"
                      options={null}
                    />
                  )}
                </div>
              </th>
              <th className="th-white">
                Payment Method <br />
                {viewAll ? null : (
                  <div className="td-input-method" style={{ fontWeight: '0' }}>
                    <MasterDropdownUI
                      onChange={(e) =>
                        onChangeInputHeader(
                          {
                            target: e,
                          },
                          resultType
                        )
                      }
                      value={paymentMethodAll}
                      isChoose
                      notMultipleSelect2
                      disabled={viewOnly}
                      name="paymentMethodAll"
                      options={[
                        'Bahtnet',
                        'Cheque',
                        'Internet Banking',
                        'Auto Deduct',
                        'T/T',
                        'T/T Swaper',
                      ]}
                    />
                  </div>
                )}
              </th>
              {isBen ? <th className="th-white">Beneficary Bank Account</th> : null}
              <th className="th-white">
                Posting
                <br />
                Status
              </th>
              <th className="th-white">Exchange Rate</th>
              <th className="th-white">
                Local Currency
                <br />
                Amount
              </th>
              <th className="th-white">
                Local
                <br />
                Currency
              </th>
              <th className="th-white">Posting Date</th>
              <th className="th-white">Document No.</th>
            </tr>
          </thead>
          <tbody>
            {!(state || []).length ? (
              <tr>
                <td colSpan={isCharge ? '15' : isBen ? '21' : '20'} align="center">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {state.map((m, i) => (
              <tr key={i}>
                {isCharge ? (
                  <td align="center">
                    {m.isManualCreate && permission && !viewOnly ? (
                      <button
                        type="button"
                        className="btn btn-icon btn-default delete-result"
                        cashflowid={m.id || ''}
                        index={i}
                        cashflowtype={m.cashFlowType}
                        title="Cancel"
                      >
                        <span className="icon icon-close sq-24" />
                      </button>
                    ) : null}
                  </td>
                ) : null}
                {!isCharge ? (
                  <>
                    <td align="center">
                      {/* {
                                                        !m.isManualCreate && m.id && !m.parentCashFlowId && permission && !viewOnly ? (
                                                            <button type="button" className="btn btn-icon btn-warning split-result" cashflowid={m.id || ''} cashflowtype={m.cashFlowType} amount={m.amount} currency={m.currencyDigit} title="Split"><span className="icon icon-object-ungroup sq-24"></span></button>
                                                        ) : null
                                                    } */}
                    </td>
                    <td align="center">
                      {/* {
                                                        !m.isManualCreate && m.parentCashFlowId && permission ? (
                                                            <button type="button" className="btn btn-icon btn-lightbrown undo-split-result" cashflowid={m.id || ''} cashflowtype={m.cashFlowType} amount={m.amount} title="Undo"><span className="icon icon-undo sq-24"></span></button>
                                                        ) : null
                                                    } */}
                    </td>
                    <td align="center">
                      {m.startInterestDateStr} - {m.endInterestDateStr}
                    </td>
                    <td align="center">{m.interestDay}</td>
                  </>
                ) : null}
                <td align="center">
                  {!permission || checkPaymentDate ? (
                    m.paymentDateStr
                  ) : (
                    <div className="td-input-date">
                      <DatePicker
                        value={m.paymentDateStr}
                        onChange={(e) => onChangeInputTable(e, i, resultType)}
                        option={{
                          daysOfWeekDisabled: '0,6',
                          todayHighlight: true,
                        }}
                        required={!isEndOfTerms}
                        disabled={false}
                        name="paymentDateStr"
                      />
                    </div>
                  )}
                </td>
                <td align="center">{m.direction}</td>
                <td align="center">{m.flowType}</td>
                <td align="right">
                  {setAmount(
                    m.originalInterestAmount -
                      (m.dutyFee || 0) -
                      (m.bankCharge || 0) -
                      (m.whtAmount || 0),
                    m.currencyDigit
                  )}
                </td>
                <td>
                  {~m.flowType.toLowerCase().indexOf('interest') ? (
                    <InputMask
                      className="form-control width-input-table"
                      format="currency"
                      onChange={(e) => onChangeInputTable(e, i, resultType)}
                      option={inputMaskOpt}
                      value={toFixed(m.originalInterestAmount || '0', m.currencyDigit, true)}
                      name="originalInterestAmount"
                    />
                  ) : null}
                </td>
                <td align="right">{setAmount(m.whtAmount, 2)}</td>
                <td>
                  {~m.flowType.toLowerCase().indexOf('interest') ? (
                    <InputMask
                      className="form-control width-input-table"
                      format="currency"
                      onChange={(e) => onChangeInputTable(e, i, resultType)}
                      option={inputMaskOpt}
                      value={toFixed(m.dutyFee || '0', 5, true)}
                      name="dutyFee"
                    />
                  ) : null}
                </td>
                <td>
                  {~m.flowType.toLowerCase().indexOf('interest') ? (
                    <InputMask
                      className="form-control width-input-table"
                      format="currency"
                      onChange={(e) => onChangeInputTable(e, i, resultType)}
                      option={inputMaskOpt}
                      value={toFixed(m.bankCharge || '0', 5, true)}
                      name="bankCharge"
                    />
                  ) : null}
                </td>
                <td align="center">{m.currency}</td>
                <td>
                  {viewAll ? (
                    setAccount(
                      m.bankCode,
                      m.branchName,
                      m.accountTypeCode,
                      m.bankCurrency,
                      m.bankAccountNo
                    )
                  ) : (
                    <div className="td-input-account">
                      {BankCode ? (
                        <MasterDropdown
                          noValidateOption
                          masterType={MASTER_BANK_ACCOUNT}
                          isChoose
                          notMultipleSelect2
                          value={m.bankAccountNo}
                          onChange={(e) =>
                            onChangeInputTable(
                              {
                                target: e,
                              },
                              i,
                              resultType
                            )
                          }
                          saveLocalState
                          bankCode={BankCode}
                          companyCode={CompanyCode}
                          name="bankAccountNo"
                          required
                          isOutFlow
                          customeLabel={(m) =>
                            `${m.bankCode} | ${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}${m.bankAccountNoOfBank ? ` (${m.bankAccountNoOfBank})` : ''}`
                          }
                          customeValue={(m) => `${m.bankAccountNo}`}
                          disabled={false}
                          isAccountBank={isAccountBank}
                        />
                      ) : (
                        <MasterDropdownUI
                          onChange={(e) =>
                            onChangeInputTable(
                              {
                                target: e,
                              },
                              i,
                              resultType
                            )
                          }
                          value={m.bankAccountNo}
                          isChoose
                          notMultipleSelect2
                          name="bankAccountNo"
                          options={null}
                        />
                      )}
                    </div>
                  )}
                </td>
                <td>
                  {viewAll ? (
                    m.paymentMethod
                  ) : (
                    <div className="td-input-method">
                      <MasterDropdownUI
                        onChange={(e) =>
                          onChangeInputTable(
                            {
                              target: e,
                            },
                            i,
                            resultType
                          )
                        }
                        required
                        value={m.paymentMethod}
                        isChoose
                        notMultipleSelect2
                        disabled={false}
                        name="paymentMethod"
                        options={[
                          'Bahtnet',
                          'Cheque',
                          'Internet Banking',
                          'Auto Deduct',
                          'T/T',
                          'T/T Swaper',
                        ]}
                      />
                    </div>
                  )}
                </td>
                {isBen ? (
                  m.paymentMethod === 'Bahtnet' && PartnerType === 'External' ? (
                    // && toLower(m.flowType).includes('interest expense')
                    <td>
                      {viewAll ? (
                        m.beneficiaryBankAccountNo
                      ) : (
                        <div className="td-input-account">
                          <MasterDropdown
                            noValidateOption
                            masterType={MASTER_BANK_ACCOUNT}
                            isChoose
                            notMultipleSelect2
                            value={m.beneficiaryBankAccountNo}
                            onChange={(e) =>
                              onChangeInputTable(
                                {
                                  target: e,
                                },
                                i,
                                resultType
                              )
                            }
                            saveLocalState
                            // bankCode={BankCode}
                            companyCode={CompanyCode}
                            name="beneficiaryBankAccountNo"
                            required
                            disabled={false}
                            customeLabel={(m) =>
                              `${m.bankCode} | ${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}${m.bankAccountNoOfBank ? ` (${m.bankAccountNoOfBank})` : ''}`
                            }
                            customeValue={(m) => `${m.bankAccountNo}`}
                            isAccountBank={isAccountBank}
                          />
                        </div>
                      )}
                    </td>
                  ) : (
                    <td />
                  )
                ) : null}
                <td align="center">{m.postingStatus}</td>
                <td align="right">{m.exchangeRate ? setAmount(m.exchangeRate, 8) : ''}</td>
                <td align="right">
                  {m.localCurrencyAmount
                    ? setAmount(m.localCurrencyAmount, m.localCurrencyDigit)
                    : ''}
                </td>
                <td align="center">{m.localCurrency}</td>
                <td align="center">{m.postingDateStr}</td>
                <td align="center">
                  <a href="#">{m.documentNo}</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );
};

const setAmount = (number, float) => {
  if (float === null || float === undefined) float = 3;
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};

const setAccount = (bankShortName, bankBranch, bankType, bankCurrency, bankAccount) => {
  if (!bankAccount) return '';
  if (bankShortName == null) {
    if ((bankAccount || '').length <= 13)
      return (
        <span>
          {bankBranch} | {bankType} | {bankCurrency} <br />
          {bankAccount}
        </span>
      );
    return (
      <span>
        {bankBranch} | {bankType} | {bankCurrency} <br />
        {bankAccount.substring(0, 13)}
        <br />
        {bankAccount.substring(13, bankAccount.length)}
      </span>
    );
  }
  if ((bankAccount || '').length <= 13)
    return (
      <span>
        {bankShortName} | {bankBranch} | {bankType} | {bankCurrency} <br />
        {bankAccount}
      </span>
    );
  return (
    <span>
      {bankShortName} | {bankBranch} | {bankType} | {bankCurrency} <br />
      {bankAccount.substring(0, 13)}
      <br />
      {bankAccount.substring(13, bankAccount.length)}
    </span>
  );
};
