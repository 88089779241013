import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_IMPORT_Cheque_EXCEL = '@@request/IMPORT_Cheque_EXCEL';
export const RECEIVE_IMPORT_Cheque_EXCEL = '@@receive/IMPORT_Cheque_EXCEL';
export const FAIL_IMPORT_Cheque_EXCEL = '@@fail/Cheque_EXCEL';

export const REQUEST_SEARCH_BANK_TRANSFER_SUMMARY = '@@request/SEARCH_BANK_TRANSFER_SUMMARY';
export const RECEIVE_SEARCH_BANK_TRANSFER_SUMMARY = '@@receive/SEARCH_BANK_TRANSFER_SUMMARY';
export const FAIL_SEARCH_BANK_TRANSFER_SUMMARY = '@@fail/BANK_TRANSFER_SUMMARY';

export const REQUEST_CHANGE_STATUS_BANK_TRANSFER_SUMMARY =
  '@@request/CHANGE_STATUS_BANK_TRANSFER_SUMMARY';
export const RECEIVE_CHANGE_STATUS_BANK_TRANSFER_SUMMARY =
  '@@receive-The transaction was successfully change status/CHANGE_STATUS_BANK_TRANSFER_SUMMARY';
export const FAIL_CHANGE_STATUS_BANK_TRANSFER_SUMMARY =
  '@@fail/CHANGE_STATUS_BANK_TRANSFER_SUMMARY';

export const REQUEST_CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY =
  '@@request/CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY';
export const RECEIVE_CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY =
  '@@receive-The transaction was successfully change status/CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY';
export const FAIL_CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY =
  '@@fail/CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY';

export const SET_SELECTED_ALL_BANK_TRANSFER_SUMMARY = 'SET_SELECTED_ALL_BANK_TRANSFER_SUMMARY';
export const SET_SELECTED_BANK_TRANSFER_SUMMARY = 'SET_SELECTED_BANK_TRANSFER_SUMMARY';

export const REQUEST_PRINT_TRANSFER_SUMMARY = '@@request/PRINT_TRANSFER_SUMMARY';
export const RECEIVE_PRINT_TRANSFER_SUMMARY = '@@receive/PRINT_TRANSFER_SUMMARY';
export const FAIL_PRINT_TRANSFER_SUMMARY = '@@fil/PRINT_TRANSFER_SUMMARY';

export const REQUEST_GET_CHEQUE_BANK_TRANSFER_SUMMARY =
  '@@request/GET_CHEQUE_BANK_TRANSFER_SUMMARY';
export const RECEIVE_GET_CHEQUE_BANK_TRANSFER_SUMMARY =
  '@@receive-The transaction was successfully get cheque/GET_CHEQUE_BANK_TRANSFER_SUMMARY';
export const FAIL_GET_CHEQUE_BANK_TRANSFER_SUMMARY = '@@fail/GET_CHEQUE_BANK_TRANSFER_SUMMARY';

export const REQUEST_EDIT_BANK_TRANSFER_SUMMRAY = '@@request/EDIT_BANK_TRANSFER_SUMMRAY';
export const RECEIVE_EDIT_BANK_TRANSFER_SUMMRAY =
  '@@receive-The transaction was successfully saved/EDIT_BANK_TRANSFER_SUMMRAY';
export const FAIL_EDIT_BANK_TRANSFER_SUMMRAY = '@@fail/EDIT_BANK_TRANSFER_SUMMRAY';

export const REQUEST_SAVE_IMPORT_CHEQUE = '@@request/SAVE_IMPORT_CHEQUE';
export const RECEIVE_SAVE_IMPORT_CHEQUE =
  '@@receive-The transaction was successfully saved/SAVE_IMPORT_CHEQUE';
export const FAIL_SAVE_IMPORT_CHEQUE = '@@fail/SAVE_IMPORT_CHEQUE';

export const REQUEST_UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY =
  '@@request/UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY';
export const RECEIVE_UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY =
  '@@receive-The transaction was successfully saved/UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY';
export const FAIL_UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY =
  '@@fail/UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY';

export const REQUEST_CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY =
  '@@request/CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY';
export const RECEIVE_CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY =
  '@@receive-The transaction was successfully saved/CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY';
export const FAIL_CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY =
  '@@fail/CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY';

export const EDIT_TRANSFER_SUMMARY_SEACH_RESULT = 'EDIT_TRANSFER_SUMMARY_SEACH_RESULT';
export const RESET_IMPORT_CHEQUE_EXCEL = 'RESET_IMPORT_CHEQUE_EXCEL';
export const UPDATE_SEARCH_RESULT_BANK_TRANSFER_SUMMARY =
  'UPDATE_SEARCH_RESULT_BANK_TRANSFER_SUMMARY';

export const changeApproverMultiTransaction = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/BankSummary/ChangeApproverMultiTransaction'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [
        REQUEST_CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY,
        RECEIVE_CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY,
        FAIL_CHANGE_APPROVER_MULTITRANSACTION_BANKTRANSFER_SUMMARY,
      ],
    },
  });

  if (!actionResponse.error && criteria) return await dispatch(searchBankTransferSummary(criteria));

  return actionResponse;
};

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_BANK_TRANSFER_SUMMARY,
  payload: {
    name,
    value,
    index,
  },
});

export const saveImportCheque = (data) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/banksummary/importCheque'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_SAVE_IMPORT_CHEQUE, RECEIVE_SAVE_IMPORT_CHEQUE, FAIL_SAVE_IMPORT_CHEQUE],
    },
  });

  if (!actionResponse.error)
    dispatch({
      type: RESET_IMPORT_CHEQUE_EXCEL,
    });

  return actionResponse;
};

export const editSearchResult = (name, value, index) => ({
  type: EDIT_TRANSFER_SUMMARY_SEACH_RESULT,
  payload: {
    name,
    value,
    index,
  },
});

export const editBanktransferSummary = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/banksummary/changeTransferMethod'),
      body: JSON.stringify(data),
      method: 'POST',
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_EDIT_BANK_TRANSFER_SUMMRAY,
        RECEIVE_EDIT_BANK_TRANSFER_SUMMRAY,
        FAIL_EDIT_BANK_TRANSFER_SUMMRAY,
      ],
    },
  });

  if (!actionResponse.error && criteria) return await dispatch(searchBankTransferSummary(criteria));
  return actionResponse;
};

export const editChequeNo = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/banksummary/updateChequeNo'),
      body: JSON.stringify(data),
      method: 'POST',
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY,
        RECEIVE_UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY,
        FAIL_UPDATE_CHEQUE_NO_BANK_TRANSFER_SUMMARY,
      ],
    },
  });

  if (!actionResponse.error && criteria) return await dispatch(searchBankTransferSummary(criteria));
  return actionResponse;
};

export const getCheque = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/banksummary/getcheque'),
    body: JSON.stringify(data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_GET_CHEQUE_BANK_TRANSFER_SUMMARY,
      RECEIVE_GET_CHEQUE_BANK_TRANSFER_SUMMARY,
      FAIL_GET_CHEQUE_BANK_TRANSFER_SUMMARY,
    ],
  },
});

export const printTransferfee = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/banksummary/Document/${transactionNo}`),
    method: 'GET',
    types: [
      REQUEST_PRINT_TRANSFER_SUMMARY,
      {
        type: RECEIVE_PRINT_TRANSFER_SUMMARY,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_PRINT_TRANSFER_SUMMARY,
    ],
  },
});

export const setSelected = (isSelected, index) => ({
  type: SET_SELECTED_BANK_TRANSFER_SUMMARY,
  payload: {
    index,
    isSelected,
  },
});

export const setSelectedAll = (isSelected) => ({
  type: SET_SELECTED_ALL_BANK_TRANSFER_SUMMARY,
  payload: isSelected,
});

export const importChequeExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/Cheque'),
    method: 'POST',
    body: form,
    types: [REQUEST_IMPORT_Cheque_EXCEL, RECEIVE_IMPORT_Cheque_EXCEL, FAIL_IMPORT_Cheque_EXCEL],
  },
});

export const changeStatus = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/BankSummary/ChangeStatus'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [
        REQUEST_CHANGE_STATUS_BANK_TRANSFER_SUMMARY,
        RECEIVE_CHANGE_STATUS_BANK_TRANSFER_SUMMARY,
        FAIL_CHANGE_STATUS_BANK_TRANSFER_SUMMARY,
      ],
    },
  });

  if (!actionResponse.error && criteria) return await dispatch(searchBankTransferSummary(criteria));

  return actionResponse;
};

export const changeConfirmStatus = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/BankSummary/confirmstatus'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [
        REQUEST_CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY,
        {
          type: RECEIVE_CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY,
          payload: (action, state, res) => {
            if (data.Action === 'EXPORT') downloadFile(res);
            return true;
          },
        },
        FAIL_CHANGE_CONFIRM_STATUS_BANK_TRANSFER_SUMMARY,
      ],
    },
  });

  if (!actionResponse.error && criteria) return await dispatch(searchBankTransferSummary(criteria));

  return actionResponse;
};

export const searchBankTransferSummary = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/BankSummary', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_BANK_TRANSFER_SUMMARY,
      RECEIVE_SEARCH_BANK_TRANSFER_SUMMARY,
      FAIL_SEARCH_BANK_TRANSFER_SUMMARY,
    ],
  },
});
