import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD =
  '@@request/SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD';
export const RECEIVE_SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD =
  '@@receive/SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD';
export const FAIL_SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD =
  '@@fail/SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD';

export const REQUEST_CREATE_EXPOSURE_BOOKING_FORWARD = '@@request/CREATE_EXPOSURE_BOOKING_FORWARD';
export const RECEIVE_CREATE_EXPOSURE_BOOKING_FORWARD =
  '@@receive-The transaction was successfully saved/CREATE_EXPOSURE_BOOKING_FORWARD';
export const FAIL_CREATE_EXPOSURE_BOOKING_FORWARD = '@@fail/CREATE_EXPOSURE_BOOKING_FORWARD';

export const REQUEST_GET_EXPOSURE_BOOKING_FORWARD = '@@request/GET_EXPOSURE_BOOKING_FORWARD';
export const RECEIVE_GET_EXPOSURE_BOOKING_FORWARD = '@@receive/GET_EXPOSURE_BOOKING_FORWARD';
export const FAIL_GET_EXPOSURE_BOOKING_FORWARD = '@@fail/GET_EXPOSURE_BOOKING_FORWARD';

export const REQUEST_VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD =
  '@@request/VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD';
export const RECEIVE_VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD =
  '@@receive/VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD';
export const FAIL_VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD =
  '@@fail/VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD';

export const UPDATE_SEARCH_RESULT_BOOKING_FORWARD = 'UPDATE_SEARCH_RESULT_BOOKING_FORWARD';

export const REQUEST_EXPOSURE_MATCHING = '@@request/EXPOSURE_MATCHING';
export const RECEIVE_EXPOSURE_MATCHING =
  '@@receive-The transaction was successfully matching/EXPOSURE_MATCHING';
export const FAIL_EXPOSURE_MATCHING = '@@fail/EXPOSURE_MATCHING';

export const REQUEST_GET_PO_SO_COMPANY = '@@request/GET_PO_SO_COMPANY';
export const RECEIVE_GET_PO_SO_COMPANY = '@@receive/GET_PO_SO_COMPANY';
export const FAIL_GET_PO_SO_COMPANY = '@@fail/GET_PO_SO_COMPANY';

export const getPoSoCompany = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SearchPoSo', criteria),
    method: 'GET',
    types: [REQUEST_GET_PO_SO_COMPANY, RECEIVE_GET_PO_SO_COMPANY, FAIL_GET_PO_SO_COMPANY],
  },
});

export const exposureMatching = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/poso/ExposureMatching'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [REQUEST_EXPOSURE_MATCHING, RECEIVE_EXPOSURE_MATCHING, FAIL_EXPOSURE_MATCHING],
    },
  });

  if (!actionResponse.error) return await dispatch(searchCreateExposureBooingForward(criteria));

  return actionResponse;
};

export const REQUEST_SAVE_IMPORT_EXCEL = '@@request/SAVE_POSO_IMPORT_EXCEL';
export const RECEIVE_SAVE_IMPORT_EXCEL =
  '@@receive-The transaction was successfully imported/SAVE_POSO_IMPORT_EXCEL';
export const FAIL_SAVE_IMPORT_EXCEL = '@@fail/SAVE_POSO_IMPORT_EXCEL';

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_BOOKING_FORWARD,
  payload: {
    name,
    value,
    index,
  },
});

export const saveImportExcel = (data) => (dispatch) =>
  dispatch({
    [RSAA]: {
      endpoint: endpoint('/PoSo/SaveImportExcel'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [REQUEST_SAVE_IMPORT_EXCEL, RECEIVE_SAVE_IMPORT_EXCEL, FAIL_SAVE_IMPORT_EXCEL],
    },
  });

export const uploadImportExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/PoSo'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD,
      RECEIVE_VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD,
      FAIL_VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD,
    ],
  },
});

export const getExposureBooingForward = (posoNo) => ({
  [RSAA]: {
    endpoint: endpoint(`poso/GetPoSo/${posoNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_EXPOSURE_BOOKING_FORWARD,
      RECEIVE_GET_EXPOSURE_BOOKING_FORWARD,
      FAIL_GET_EXPOSURE_BOOKING_FORWARD,
    ],
  },
});

export const searchCreateExposureBooingForward = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SearchPoSo', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD,
      RECEIVE_SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD,
      FAIL_SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD,
    ],
  },
});

export const saveExposureBooingForward = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SavePoSo'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CREATE_EXPOSURE_BOOKING_FORWARD,
      RECEIVE_CREATE_EXPOSURE_BOOKING_FORWARD,
      FAIL_CREATE_EXPOSURE_BOOKING_FORWARD,
    ],
  },
});
