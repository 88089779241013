import thunkMiddleware from 'redux-thunk';
import { RSAA } from 'redux-api-middleware';
import { createStore, applyMiddleware } from 'redux';

import _mockup from './_mockup';
import rootReducer from './reducers';

const beforeCallAPIMiddleware = () => (next) => (action) => {
  if (action[RSAA]) {
    const { endpoint } = action[RSAA];
    if (endpoint) {
      const url = new URL(endpoint);
      const mockup = _mockup.find((mock) => mock.path.toLowerCase() === url.pathname.toLowerCase());
      if (mockup) {
        return Promise.resolve({ payload: mockup.payload });
      }
    }

    return Promise.resolve({ payload: [] });
  }
  return next(action);
};

/* -------------------------------------------------------------------------- */

const logMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    console.log('-------------Action---------------');
    console.log(action);
    console.log('-------------State---------------');
    const result = next(action);
    console.log(getState());
    return result;
  };

/* -------------------------------------------------------------------------- */

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(logMiddleware, thunkMiddleware, beforeCallAPIMiddleware)
  );
}
