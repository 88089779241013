import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Button from '../../common/Button';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import RadioButton from '../../common/RadioButton';
import TablePaging from '../../common/TablePaging';
import MasterDropdown from '../../common/MasterDropdown';
import { toFixed, getPermission } from '../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const required = true;
const permission = getPermission('Cash Management', 'Cash Position');

export default ({
  criteria,
  results,
  onChange,
  onClickProcess,

  onClickSave,
}) => {
  const isSweepBU = criteria.SweepType === 'bu';

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Cash Management <span className="icon icon-angle-double-right" />{' '}
            <Link txt="Cash Position" href="/cash-management/cash-position" />{' '}
            <span className="icon icon-angle-double-right" /> Sweep Money
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickProcess}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Value Date" required>
                <DatePicker
                  value={criteria.ValueDate}
                  onChange={onChange}
                  name="ValueDate"
                  option={{
                    startDate: 'd',
                    endDate: '+7d',
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                  required={required}
                />
              </FormGroup2>
              <FormGroup2 text="Sweep Type" required>
                <RadioButton
                  text="Finance Group"
                  checked={criteria.SweepType === 'fg'}
                  value="fg"
                  onChange={onChange}
                  name="SweepType"
                />
                <RadioButton
                  text="Business Unit"
                  checked={criteria.SweepType === 'bu'}
                  value="bu"
                  onChange={onChange}
                  name="SweepType"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              {isSweepBU ? (
                <FormGroup2 text="Business Unit" required key="bu">
                  <MasterDropdown
                    masterType={MASTER_BUSINESS_UNIT}
                    value={criteria.BusinessUnitId}
                    isChoose
                    notMultipleSelect2
                    onChange={(e) => {
                      onChange({
                        target: e,
                      });
                    }}
                    required
                    name="BusinessUnitId"
                  />
                </FormGroup2>
              ) : (
                <FormGroup2 text="Finance Group" required key="fi">
                  <MasterDropdown
                    masterType={MASTER_FINANCE}
                    value={criteria.FinanceGroupId}
                    isChoose
                    notMultipleSelect2
                    onChange={(e) => {
                      onChange({
                        target: e,
                      });
                    }}
                    required
                    name="FinanceGroupId"
                  />
                </FormGroup2>
              )}

              <FormGroup2 text="Currency" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  value={criteria.Currency}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) => {
                    onChange({
                      target: e,
                    });
                  }}
                  required
                  name="Currency"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          {permission ? (
            <Col2 col={colX[2]}>
              <div>
                <Button txt="Process" icon="search" className="btn-info" type="submit" />
              </div>
            </Col2>
          ) : null}
        </form>
      </Card>

      <Card
        textHeader="Result"
        number="2"
        bgHeader="bg-primary"
        cardActions={['toggler']}
        footer={
          <Button
            txt="Confirm"
            icon="save"
            className="btn-success"
            onClick={onClickSave}
            disabled={!results.length}
          />
        }
      >
        <TablePaging noPaging colNumber={9} renderRow={renderRow()} value={results}>
          <thead>
            <tr>
              <th colSpan="5" className="th-primary">
                Member
              </th>
              <th colSpan="4" className="th-success">
                Master
              </th>
            </tr>
            <tr>
              <th className="th-primary">Company Code</th>
              <th className="th-primary">Company Name</th>
              <th className="th-primary">Bank Account</th>
              <th className="th-primary">Min Balance</th>
              <th className="th-primary">Transfer Amount</th>
              <th className="th-success">Company Code</th>
              <th className="th-success">Company Name</th>
              <th className="th-success">Bank Account</th>
              <th className="th-success">Transfer Amount</th>
            </tr>
          </thead>
        </TablePaging>
      </Card>
    </div>
  );
};

const renderRow = () => (m, i) => (
  <tr key={i}>
    <td style={{ textAlign: 'center' }}>{m.applicantCompanyCode}</td>
    <td>{m.applicantCompanyName}</td>
    <td>
      {setAccount(
        m.applicantBankAbbreviate,
        m.applicantBranchName,
        m.applicantAccountTypeCode,
        m.applicantCurrency,
        m.applicantBankAccountNo
      )}
    </td>
    <td className="td-input-number" style={{ textAlign: 'right' }}>
      {setAmount(m.applicantMinimumBalance, m.applicantDigit)}
    </td>
    <td className="td-input-number" style={{ textAlign: 'right' }}>
      {setAmount(m.applicantTransferAmount, m.applicantDigit)}
    </td>
    <td style={{ textAlign: 'center' }}>{m.beneficiaryCompanyCode}</td>
    <td>{m.beneficiaryCompanyName}</td>
    <td>
      {setAccount(
        m.beneficiaryBankAbbreviate,
        m.beneficiaryBranchName,
        m.beneficiaryAccountTypeCode,
        m.beneficiaryCurrency,
        m.beneficiaryBankAccountNo
      )}
    </td>
    <td className="td-input-number" style={{ textAlign: 'right' }}>
      {setAmount(m.beneficiaryTransferAmount, m.beneficiaryDigit)}
    </td>
  </tr>
);

const setAmount = (number, float) => {
  if (number < 0)
    return <span style={{ color: '#e64a19' }}>({toFixed(Math.abs(number, float))})</span>;
  return toFixed(number, float);
};

const setAccount = (bankShortName, bankBranch, bankType, bankCurrency, bankAccount) => {
  if ((bankAccount || '').length <= 13)
    return (
      <span>
        {bankShortName} | {bankBranch} | {bankType} | {bankCurrency}
        <br />
        {bankAccount}
      </span>
    );
  return (
    <span>
      {bankShortName} | {bankBranch} | {bankType} | {bankCurrency}
      <br />
      {bankAccount.substring(0, 13)}
      <br />
      {bankAccount.substring(13, bankAccount.length)}
    </span>
  );
};
