import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import ModalFunction from '../../common/ModalFunction';
import { DATATABLE_ID } from './FlowAccountingContainer';
import MasterDropdownUI from '../../common/MasterDropdownUI';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[0, 'asc']],
  columns: [
    // {
    //     data: 'isActive', orderable: false, className: 'text-center',
    //     render: (d, t, r) => {
    //         if (d) return '<button class="btn btn-icon btn-warning inactive" disabled="true"><span class="icon icon-close sq-24"></span></button>'
    //         return '<button class="btn btn-icon btn-info active" disabled="true"><span class="icon icon-refresh sq-24"></span></button>'
    //     }
    // },
    // {
    //     data: 'isEdit', orderable: false, className: 'text-center',
    //     render: (d, t, r) => {
    //         return '<button class="btn btn-icon btn-info edit" disabled="true"><span class="icon icon-edit sq-24"></span></button>'
    //     }
    // },
    { data: 'flowNo' },
    { data: 'flowName' },
    { data: 'productCategory' },
    { data: 'transactionType' },
    { data: 'flowType' },
    { data: 'postingProcess' },
    { data: 'documentType' },
    { data: 'postingLogic' },
    { data: 'condition1' },
    { data: 'flowAction' },
    { data: 'remark' },
    {
      data: 'isPost',
      className: 'text-center',
      render: (d) => {
        if (d == null) return '';
        if (d) return 'Yes';
        return 'No';
      },
    },
    {
      data: 'isAbleSwapGlAccount',
      className: 'text-center',
      render: (d) => {
        if (d == null) return '';
        if (d) return 'Yes';
        return 'No';
      },
    },
    {
      data: 'isPostAllLedger',
      className: 'text-center',
      render: (d) => {
        if (d == null) return '';
        if (d) return 'Yes';
        return 'No';
      },
    },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Mode === 'edit';
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Accounting <span className="icon icon-angle-double-right" /> Flow Accounting
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Product Category" required={false}>
                <MasterDropdownUI
                  value={criteria.ProductCategory}
                  onChange={onChangeSelect2}
                  options={[
                    'Foreign Exchange',
                    'Money Market',
                    'Secutities',
                    'Derivatives',
                    'Facility',
                  ]}
                  name="ProductCategory"
                />
              </FormGroup2>

              <FormGroup2 text="Transaction Type" required={false}>
                <MasterDropdownUI
                  value={criteria.TransactionType}
                  onChange={onChangeSelect2}
                  options={['Loan', 'Deposit', 'Buy', 'Sell', 'Held', 'Held and Sell']}
                  name="TransactionType"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Flow Type" required={false}>
                <MasterDropdownUI
                  value={criteria.FlowType}
                  onChange={onChangeSelect2}
                  options={['Main', 'Additional']}
                  name="FlowType"
                />
              </FormGroup2>

              <FormGroup2 text="Posting Process" required={false}>
                <MasterDropdownUI
                  value={criteria.PostingProcess}
                  onChange={onChangeSelect2}
                  options={['Daily', 'Month-end']}
                  name="PostingProcess"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Flow No." required={false}>
                <input
                  className="form-control"
                  value={criteria.FlowNo}
                  onChange={onChangeSearch}
                  name="FlowNo"
                />
              </FormGroup2>

              <FormGroup2 text="Flow Name" required={false}>
                <input
                  className="form-control"
                  value={criteria.FlowName}
                  onChange={onChangeSearch}
                  name="FlowName"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              {/* &nbsp;
                            <Button txt="Create Currency" icon="plus-circle" className="btn-warning" onClick={onClickOpenModalData} disabled={true}></Button> */}
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              {/* <th>Action</th>
                            <th>Edit</th> */}
              <th>Flow No</th>
              <th>Flow Name</th>
              <th>Product Category</th>
              <th>Transaction Type</th>
              <th>Flow Type</th>
              <th>Posting Process</th>
              <th>Document Type</th>
              <th>Posting Logic</th>
              <th>Condition 1</th>
              <th>Flow Action</th>
              <th>Remark</th>
              <th>Post</th>
              <th>
                Able Swap
                <br /> GL Account
              </th>
              <th>Post All Ledger</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Master Currency" bgHeader="bg-primary" size="modal-md" ref={modalDataRef}>
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <br />
          <div>
            <center>
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
