import { connect } from 'react-redux';
import React, { Component } from 'react';

import RequestBg from './RequestBg';
import { appendForm } from './Attachment';
import { addAlert } from '../../reducers/layout/action';
import { getMasterUserDetail } from '../../reducers/masterUser/action';
import { toLower, toUpperKey, removeFeild, compareDataNotEqual } from '../../common/helpper';
import {
  getBgField,
  getBgRequest,
  getDefaultBg,
  saveBgRequest,
  getAttachment,
  saveAttachFile,
  getFacilityList,
  getMaintainBranch,
  getMaintainRequestBg,
  getMaintainBeneficiary,
} from '../../reducers/requestBg/action';

const jwt = JSON.parse(localStorage.getItem('jwt'));
const USERNAME = jwt ? jwt.username : '';

const buApproveNotRequiredType = 'Approved Memo';
const initData = {
  TransactionNo: '',
  FinanceGroupId: '',
  CompanyCode: '',
  LgMethod: '',
  BeneficiaryId: '',
  LgTypeId: '',
  FormFormat: '',
  BgFormId: '',
  Lang: '',
  LgNo: '',
  BankCode: '',
  BranchId: '',
  RemarkFormNo: '',
  RemarkForFinance: '',

  // Information
  BranchName: '',
  BranchAddress: '',
  BranchPhoneNo: '',
  // RequestDateStr: '',
  IssueDateStr: '',
  ModifyDateStr: '',
  EffectiveDateStr: '',
  TermEndType: '',
  TermEndDisabled: false,
  ExpireDateStr: '',
  Amount: '',
  Currency: '',
  Remark: '',

  Email: '',
  PhoneNo: '',
  MobileNo: '',
  MobileNoNoti: '',
  EmailNoti: '',

  Action: 'create',

  Attachment: {
    values: [],
    requiredTypes: [
      {
        type: 'Approved Memo',
        required: true,
      },
      {
        type: 'Supporting Document',
        required: true,
      },
      {
        type: 'Copy BG',
        required: true,
      },
      {
        type: 'Others',
        required: false,
      },
    ],
  },
  IsBuApprove: false,
  ApproverBu: '',

  CreatorFullName: '',
  CreatorName: '',
  CreatorEmail: '',
  CreatorTel: '',
  RequestorName: '',
  RequestorEmail: '',
  RequestorTel: '',
  Initial: '',

  MethodOfReceiving: '',
  AddressSpecify: '',
  CostCenter: '',

  CreatorDepartment: '',
  RequestorDepartment: '',
  StatusLg: '',
  InternalOrder: '',
  FacilityNo: '',
  Outstanding: '',
  ManagerFullName: '',
  ManagerName: '',
  ManagerEmail: '',
  ManagerTel: '',
  ManagerDepartment: '',
  AmendDescription: '',
};

const initBgField = {
  ProjectName: '',
  ContractNo: '',
  ContractDateStr: '',
  ContractPercent: '',
  ShippingNo: '',
  ShippingCount: '',
  Resident: '',
  ImportDateStr: '',
  CustDeptDateStr: '',
  ProjectValue: '',
  BidMaturityNoStr: '',

  ElecNo: '',
  ElecContractNo: '',
  SecurityNo: '',
  BqNo: '',
  ElecAddress: '',
  ElecSubdistrict: '',
  ElecDistrict: '',
  ElecProvince: '',

  ReferenceNumber: '',
  ReferenceName: '',
  ReferenceDescription: '',
  ReferenceDateStr: '',
  LgDescription1: '',
  LgDescription2: '',
  LgDescription3: '',
  LgDescription4: '',
  LgDescription5: '',
  LgDescription6: '',
  LgDescription7: '',
  LgDescription8: '',
  LgDescription9: '',
  LgDescription10: '',

  defaultBranch: false,
};

const initFunciton = {
  mode: '',

  textHeader: '',
  textContent: '',
  type: '',
  icon: '',
  textBtn: 'Yes',
  textBtnBack: 'No',
  selectedLength: 0,

  Remark: '',
};

class RequestBgContainer extends Component {
  state = {
    ...initData,

    beneficiaryList: [],
    lgTypeList: [],
    formNoList: [],
    branchList: [],
    bgFieldList: [],

    modalFunction: {
      ...initFunciton,
    },
    isConsoleCompany: false,
    isConsoleBen: false,
  };

  componentDidMount() {
    const { id, reportId } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.props.getMasterUserDetail(USERNAME).then((response) => {
        if (!response.error) {
          const r = response.payload;
          this.setState({
            ...initData,
            CreatorName: r.username,
            CreatorEmail: r.email,
            CreatorFullName: `${r.nameEn || ''} ${r.surnameEn || ''}`,
          });
        }
      });
    else {
      this.fetchId(id, reportId);
    }

    this.props
      .getMaintainBeneficiary({
        IsActive: true,
      })
      .then((response) => {
        if (response.error && !response.payload) return;

        this.setState({
          ...this.state,
          beneficiaryList: response.payload || [],
        });
      });
  }

  async fetchId(id, reportId) {
    if (!id) return;

    const response = await this.props.getBgRequest(id, reportId);
    if (response.error || !response.payload) return;
    const fetchData = toUpperKey(response.payload);

    // attachement
    const attachmentsResponse = await this.props.getAttachment(id);
    const attachments = {
      ...initData.Attachment,
    };
    if (!attachmentsResponse.error && attachmentsResponse.payload) {
      attachments.values = attachmentsResponse.payload;
    }
    if (fetchData.IsBuApprove) {
      attachments.requiredTypes.forEach((m) => {
        if (m.type === buApproveNotRequiredType) m.required = false;
      });
    }
    // end attachement

    if (fetchData.RequestorName) {
      const option = new Option(fetchData.RequestorFullName, fetchData.RequestorName, false, true);
      this.requestorRef.$select2.append(option);
    }

    if (fetchData.ManagerName) {
      const option = new Option(fetchData.ManagerFullName, fetchData.ManagerName, false, true);
      this.managerRef.$select2.append(option);
    }

    const isAmend = window.location.pathname.includes('amend');

    this.setState(
      {
        ...fetchData,
        TermEndDisabled:
          fetchData.IsOpenEnd && fetchData.IsCloseEnd
            ? false
            : !!(fetchData.IsOpenEnd || fetchData.IsCloseEnd),
        Action: 'edit',
        Attachment: attachments,
        IssueDateStr: isAmend ? '' : fetchData.IssueDateStr,
        ExpireDateStr: isAmend ? '' : fetchData.ExpireDateStr,
      },
      () => {
        this.getBgFormList(fetchData.LgTypeId, fetchData.BgFormId);

        // apply name for init and apprver bu
        if (fetchData.Initial) {
          const option = new Option(fetchData.InitialName, fetchData.Initial, false, true);
          this.initialRef.$select2.append(option);
        }
        if (fetchData.ApproverBu && fetchData.ApproverBu !== '0') {
          const option = new Option(fetchData.ApproverBuName, fetchData.ApproverBu, false, true);
          this.approverBuRef.$select2.append(option);
        }

        const facilityData = {
          CompanyCode: fetchData.CompanyCode,
          BusinessPartnerCode: fetchData.BankCode,
          ProductType: 'BG',
          IsProject: false,
          StartDateStr: fetchData.EffectiveDateStr,
          EndDateStr: fetchData.ExpireDateStr,
          Currency: fetchData.Currency,
        };

        this.props.getFacilityList(facilityData).then((response) => {
          if (response.error && !response.payload) return;

          const list = response.payload;
          for (let i = 0; i < list.length; i++) {
            const row = list[i];
            if (row.transactionNo === this.state.FacilityNo) {
              this.setState({
                FacilityNo: row.transactionNo,
                Outstanding: row.outstanding,
              });
            }
          }
        });
      }
    );
  }

  getBgFormList(LgTypeId, BgFormId, isChange) {
    this.props
      .getBgField({
        LgTypeId,
        BgFormId,
      })
      .then((response) => {
        if (response.error && !response.payload) return;

        const bgFields = response.payload;

        const bgFieldList = [];
        let bg = [];

        let i = 1;
        bgFields.forEach((m) => {
          if (i === 4) {
            bg.push(m);
            bgFieldList.push(bg);

            bg = [];
            i = 1;
          } else {
            m.fieldIndex = i;
            bg.push(m);
            i++;
          }
        });

        if (bg.length) {
          bgFieldList.push(bg);
        }

        if (isChange) {
          this.setState({
            ...this.state,
            ...initBgField,
            bgFieldList,
          });
        } else {
          this.setState({
            ...this.state,
            bgFieldList,
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      BeneficiaryId,
      LgTypeId,
      FormFormat,
      BankCode,
      BranchId,
      BgFormId,
      CompanyCode,
      Action,
      defaultBranch,
      MethodOfReceiving,
      EffectiveDateStr,
      ExpireDateStr,
      Currency,
    } = this.state;

    if (prevState.BeneficiaryId !== BeneficiaryId && BeneficiaryId) {
      this.props
        .getMaintainRequestBg({
          BeneficiaryId,
          Action: 'lg type',
        })
        .then((response) => {
          if (response.error && !response.payload) return;

          this.setState({
            ...this.state,
            lgTypeList: response.payload || [],
          });
        });
    }

    if ((prevState.LgTypeId !== LgTypeId || prevState.FormFormat !== FormFormat) && LgTypeId) {
      this.props
        .getMaintainRequestBg({
          BeneficiaryId,
          LgTypeId,
          // FormFormat: FormFormat,
          Action: 'bg form',
        })
        .then((response) => {
          if (response.error && !response.payload) return;

          this.setState({
            ...this.state,
            formNoList: response.payload || [],
          });
        });
    }

    if (prevState.BankCode !== BankCode && BankCode) {
      this.props
        .getMaintainBranch({
          BankCode,
          IsActive: true,
        })
        .then((response) => {
          if (response.error && !response.payload) return;

          this.setState({
            ...this.state,
            branchList: response.payload,
            // BranchName: '',
            // BranchAddress: '',
            // BranchPhoneNo: '',
          });
        });
    }

    // if ((prevState.LgTypeId !== LgTypeId || prevState.BgFormId !== BgFormId) && LgTypeId && BgFormId) {
    //     this.props.getBgField({
    //         LgTypeId: LgTypeId,
    //         BgFormId: BgFormId,
    //     }).then(response => {
    //         if (response.error && !response.payload) return

    //         let bgFields = response.payload

    //         let bgFieldList = []
    //         let bg = []

    //         let i = 1
    //         bgFields.forEach(m => {
    //             if (i === 4) {
    //                 bg.push(m)
    //                 bgFieldList.push(bg)

    //                 bg = []
    //                 i = 1
    //             }
    //             else {
    //                 m.fieldIndex = i
    //                 bg.push(m)
    //                 i++
    //             }
    //         })

    //         if (bg.length) {
    //             bgFieldList.push(bg)
    //         }

    //         this.setState({
    //             ...this.state,
    //             ...initBgField,
    //             bgFieldList: bgFieldList,
    //         })
    //     })
    // }

    // if ((prevState.defaultBranch !== defaultBranch
    //     || prevState.BankCode !== BankCode
    //     || prevState.MethodOfReceiving !== MethodOfReceiving
    //     || prevState.FormFormat !== FormFormat)) {

    //     if ((defaultBranch || toLower(FormFormat) === 'blockchain') && BankCode && (MethodOfReceiving || toLower(FormFormat) === 'blockchain')) {
    //         this.props.getMaintainBranch({
    //             BankCode: BankCode,
    //             IsHeadOffice: true,
    //             IsActive: true
    //         }).then(response => {
    //             if (response.error && !response.payload) return

    //             let r = response.payload
    //             if (r.length) {
    //                 this.setState({
    //                     ...this.state,
    //                     BranchId: r[0].branchId,
    //                     BranchName: r[0].branchName || '',
    //                     BranchAddress: r[0].address || '',
    //                     BranchPhoneNo: r[0].phoneNo || '',
    //                 })
    //             }
    //             else {
    //                 this.setState({
    //                     ...this.state,
    //                     BranchId: '',
    //                     BranchName: '',
    //                     BranchAddress: '',
    //                     BranchPhoneNo: '',
    //                 })
    //             }
    //         })
    //     }
    //     else {
    //         this.setState({
    //             ...this.state,
    //             BranchId: '',
    //             BranchName: '',
    //             BranchAddress: '',
    //             BranchPhoneNo: '',
    //         })
    //     }
    // }

    if (prevState.BranchId !== BranchId && BranchId) {
      const d = (this.state.branchList || []).find(
        (f) => (f.branchId || '').toString() === (BranchId || '').toString()
      );
      if (d) {
        this.setState({
          ...this.state,
          BranchName: d.branchName || '',
          BranchAddress: d.address || '',
          BranchPhoneNo: d.phoneNo || '',
        });
      }
    }

    // if (prevState.BgFormId !== BgFormId && BgFormId) {
    //     let d = (this.state.formNoList || []).find(f => (f.bgFormId || '').toString() === (BgFormId || '').toString())
    //     if (d) {
    //         this.setState({
    //             ...this.state,
    //             Lang: d.lang || '',
    //             TermEndType: d.isOpenEnd && d.isCloseEnd ? '' : d.isOpenEnd ? 'Open End' : d.isCloseEnd ? 'Close End' : '',
    //             TermEndDisabled: d.isOpenEnd && d.isCloseEnd ? false : d.isOpenEnd || d.isCloseEnd ? true : false,
    //             RemarkFormNo: d.remark || '',
    //             FormFormat: d.formFormat,
    //             Currency: toLower(d.formFormat) === 'blockchain' ? 'THB' : '',
    //             defaultBranch: toLower(d.formFormat) === 'blockchain' ? true : false,
    //         })
    //     }
    // }

    if (prevState.CompanyCode !== CompanyCode && Action === 'create') {
      this.props
        .getDefaultBg({
          CompanyCode,
        })
        .then((response) => {
          if (response.error && !response.payload) return;

          const r = response.payload[0];
          const bankCode = r.defaultBg;
          this.setState({
            ...this.state,
            FinanceGroupId: r.financeGroupId,
            BankCode: bankCode || '',
            defaultBranch: true,
          });
        });
    }

    // Get Facility
    if (
      (prevState.CompanyCode !== CompanyCode ||
        prevState.BankCode !== BankCode ||
        // || prevState.IsProject !== IsProject
        prevState.EffectiveDateStr !== EffectiveDateStr ||
        prevState.ExpireDateStr !== ExpireDateStr ||
        prevState.Currency !== Currency) &&
      CompanyCode &&
      BankCode &&
      EffectiveDateStr &&
      Currency
    ) {
      const facilityData = {
        CompanyCode,
        BusinessPartnerCode: BankCode,
        ProductType: 'BG',
        IsProject: false,
        StartDateStr: EffectiveDateStr,
        EndDateStr: ExpireDateStr,
        Currency,
      };

      this.props.getFacilityList(facilityData).then((response) => {
        if (response.error && !response.payload) return;

        const list = response.payload;
        for (let i = 0; i < list.length; i++) {
          const row = list[i];
          if (row.transactionNo === this.state.FacilityNo) {
            this.setState({
              FacilityNo: row.transactionNo,
              Outstanding: row.outstanding,
            });
          } else {
            this.setState({
              FacilityNo: '',
              Outstanding: '',
            });
          }
        }

        if (!list.length) {
          this.setState({
            FacilityNo: '',
            Outstanding: '',
          });
        }
      });
    }
  }

  onChangeInputData = (e) => {
    let { name, value, label } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'BgFormId' && value && this.state.LgTypeId) {
        this.getBgFormList(this.state.LgTypeId, value, true);
      } else if (name === 'LgTypeId' && value && this.state.BgFormId) {
        this.getBgFormList(value, this.state.BgFormId, true);

        stateToUpdate.BgFormId = '';
        stateToUpdate.FormFormat = '';
        stateToUpdate.Lang = '';
        stateToUpdate.TermEndType = '';
        stateToUpdate.TermEndDisabled = false;
        stateToUpdate.RemarkFormNo = '';
      } else if (name === 'FormFormat' && value === 'Blockchain') {
        stateToUpdate.Currency = 'THB';
      }
      // else if (name === 'BankCode') {
      //     if (toLower(value) === 'kasith') {
      //         stateToUpdate.BranchId = 83
      //     }
      //     else if (toLower(value) === 'sicoth') {
      //         stateToUpdate.BranchId = 85
      //     }
      // }
      else if (name === 'EffectiveDateStr') {
        if (value === '') {
          stateToUpdate.EffectiveDateStr = '';
          stateToUpdate.EffectiveDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDataNotEqual(mergeState.EffectiveDateStr, mergeState.ExpireDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Effective date must be less than Expire date.',
            });
            return {
              [name]: state.EffectiveDateStr,
            };
          }
        }
      } else if (name === 'ExpireDateStr') {
        if (value === '') {
          stateToUpdate.ExpireDateStr = '';
          stateToUpdate.ExpireDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDataNotEqual(mergeState.EffectiveDateStr, mergeState.ExpireDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Expire date must be more than Effective date.',
            });
            return {
              [name]: state.ExpireDateStr,
            };
          }
        }
      } else if (name === 'MethodOfReceiving') {
        if (
          toLower(value) === 'send via ems' ||
          toLower(value) === 'pick up at corporate cash management 19th floor, hq3 building' ||
          toLower(value) === 'Pick up at Finance Dept.'
        ) {
          stateToUpdate.defaultBranch = true;
        } else {
          stateToUpdate.defaultBranch = false;
          if (toLower(state.FormFormat) !== 'blockchain') {
            stateToUpdate.BranchId = '';
          }
        }

        if (toLower(value) !== 'send via ems') {
          stateToUpdate.AddressSpecify = '';
          stateToUpdate.CostCenter = '';
        }
      } else if (name === 'BeneficiaryId') {
        stateToUpdate.LgTypeId = '';
        stateToUpdate.BgFormId = '';
        stateToUpdate.FormFormat = '';
        stateToUpdate.Lang = '';
        stateToUpdate.TermEndType = '';
        stateToUpdate.TermEndDisabled = false;
        stateToUpdate.RemarkFormNo = '';
      }

      if (
        (name === 'MethodOfReceiving' && this.state.BankCode) ||
        (name === 'BankCode' && this.state.MethodOfReceiving) ||
        name === 'BgFormId' ||
        (name === 'BankCode' && toLower(this.state.FormFormat) === 'blockchain')
      ) {
        const bankCode = name === 'BankCode' ? value : this.state.BankCode;
        const methodR = name === 'MethodOfReceiving' ? value : this.state.MethodOfReceiving;
        let formFormat = name === 'BgFormId' ? value : this.state.FormFormat;

        if (name === 'BgFormId') {
          const d = (this.state.formNoList || []).find(
            (f) => (f.bgFormId || '').toString() === (value || '').toString()
          );
          if (d) {
            stateToUpdate.Lang = d.lang || '';
            stateToUpdate.TermEndType =
              d.isOpenEnd && d.isCloseEnd
                ? ''
                : d.isOpenEnd
                  ? 'Open End'
                  : d.isCloseEnd
                    ? 'Close End'
                    : '';
            stateToUpdate.TermEndDisabled =
              d.isOpenEnd && d.isCloseEnd ? false : !!(d.isOpenEnd || d.isCloseEnd);
            stateToUpdate.RemarkFormNo = d.remark || '';
            stateToUpdate.FormFormat = d.formFormat;
            stateToUpdate.Currency = toLower(d.formFormat) === 'blockchain' ? 'THB' : '';
            stateToUpdate.MethodOfReceiving =
              toLower(d.formFormat) === 'blockchain' ? '' : this.state.MethodOfReceiving;

            formFormat = d.formFormat;
          }
        }

        if (
          (toLower(methodR) !== 'pick up at bank branch' && methodR) ||
          (toLower(formFormat) === 'blockchain' && bankCode)
        ) {
          this.props
            .getMaintainBranch({
              BankCode: bankCode,
              IsHeadOffice: toLower(formFormat) === 'blockchain' ? true : '',
              IsHeadOfficePaper: toLower(formFormat) === 'paper' ? true : '',
              IsActive: true,
            })
            .then((response) => {
              if (response.error && !response.payload) return;

              const r = response.payload;
              if (r.length) {
                this.setState({
                  ...this.state,
                  BranchId: r[0].branchId,
                  BranchName: r[0].branchName || '',
                  BranchAddress: r[0].address || '',
                  BranchPhoneNo: r[0].phoneNo || '',
                });
              } else {
                this.setState({
                  ...this.state,
                  BranchId: '',
                  BranchName: '',
                  BranchAddress: '',
                  BranchPhoneNo: '',
                });
              }
            });
        } else {
          stateToUpdate.BranchId = '';
          stateToUpdate.BranchName = '';
          stateToUpdate.BranchAddress = '';
          stateToUpdate.BranchPhoneNo = '';
        }
      }

      // Facility
      if (name === 'FacilityNo') {
        if (value) {
          for (let i = 0; i < this.props.facilityList.length; i++) {
            const r = this.props.facilityList[i];
            if (r.transactionNo === value) {
              stateToUpdate.FacilityNo = r.transactionNo;
              stateToUpdate.Outstanding = r.outstanding;
            }
          }
        } else {
          stateToUpdate.FacilityNo = '';
          stateToUpdate.Outstanding = '';
        }
      }

      return stateToUpdate;
    });

    if (name === 'CompanyCode') {
      this.props
        .getDefaultBg({
          CompanyCode: value,
        })
        .then((response) => {
          if (response.error || !response.payload) return;

          let isConsoleCompany = response.payload[0].isConsole;
          if (isConsoleCompany && this.state.isConsoleBen) {
            value = '';
            isConsoleCompany = false;

            this.setState({
              ...this.state,
              [name]: value,
              isConsoleCompany,
              FinanceGroupId: '',
              BankCode: '',
              defaultBranch: false,
            });

            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'No policy to issue BG among SCG consolidated companies, please contact finance team.',
            });
          } else {
            this.setState({
              ...this.state,
              [name]: value,
              isConsoleCompany,
            });
          }
        });
    }

    if (name === 'BeneficiaryId') {
      this.props
        .getMaintainBeneficiary({
          BeneficiaryId: value,
        })
        .then((response) => {
          if (response.error || !response.payload) return;

          let isConsoleBen = response.payload[0].isConsole;
          if (this.state.isConsoleCompany && isConsoleBen) {
            value = '';
            isConsoleBen = false;

            this.setState({
              ...this.state,
              [name]: value,
              isConsoleBen,
              lgTypeList: [],
            });

            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'No policy to issue BG among SCG consolidated companies, please contact finance team.',
            });
          } else {
            this.setState({
              ...this.state,
              [name]: value,
              isConsoleBen,
            });
          }
        });
    }
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitHandler = async (e, action) => {
    if (action === 'submit') {
      const inValidFormName = this.validateForms([
        this.formDetailRef,
        this.formStructerRef,
        this.formContractRef,
        this.formCreatorRef,
        this.formRequestorRef,
        this.formManagerRef,
        this.formAttachmentRef,
      ]);
      if (inValidFormName) return;

      if (parseFloat(this.state.Amount || '0') <= 0) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Amount must more than zero.',
        });
        return;
      }

      if (this.state.IsBuApprove && !this.state.ApproverBu) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please select approver bu.',
        });
        return;
      }
    } else if (!this.state.CompanyCode) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select company.',
      });
      return;
    }

    this.state.Action = action;
    const dataSubmit = removeFeild({ ...this.state }, [
      'Attachment',
      'beneficiaryList',
      'lgTypeList',
      'formNoList',
      'branchList',
      'bgFieldList',
      'modalFunction',
      'defaultBranch',
    ]);

    const isAmend = window.location.pathname.includes('amend');
    if (isAmend) {
      dataSubmit.OldTransactionNo = dataSubmit.TransactionNo;
      dataSubmit.TransactionNo = '';
    }

    const response = await this.props.saveBgRequest(dataSubmit);
    if (response.error) return;

    const splits = response.payload.split('|');
    const _id = splits[0];
    const _error = splits[1] || '';

    await this.saveAttachFile(_id, isAmend ? 'true' : 'false', dataSubmit.OldTransactionNo);

    if (_error) {
      this.props.addAlert({
        title: 'Warning',
        body: 'The transaction was successfully saved but Outstanding facility is not enought!',
        type: 'warning',
        buttons: [
          <button
            className="btn btn-warning"
            onClick={(e) => this.onClickHerePage(e, _id)}
            data-dismiss="modal"
          >
            Continue
          </button>,
        ],
      });
    } else if (_id !== this.props.routeProp.match.params.id) {
      window.location.href = `/bank-guarantee/request/${_id}`;
    } else {
      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The transaction was successfully saved',
        buttons: [
          <button
            className="btn btn-success"
            onClick={(e) => this.onClickHerePage(e, _id)}
            data-dismiss="modal"
          >
            Here in this page
          </button>,
          <button className="btn btn-default" onClick={this.onClickBackToPage} data-dismiss="modal">
            Back Page
          </button>,
        ],
      });
    }
  };

  onClickHerePage = (e, id) => {
    window.location.href = `/bank-guarantee/request/${id}`;
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/bank-guarantee/search-request/');
  };

  async saveAttachFile(transactionNo, isAmend, oldTransactionNo) {
    const form = new FormData();
    const attachments = this.formAttachmentRef.getAttachments();
    appendForm(form, attachments);
    await this.props.saveAttachFile(form, transactionNo, isAmend, oldTransactionNo);
  }

  onChangeBuApprove = (e) => {
    this.setState((state) => ({
      IsBuApprove: !state.IsBuApprove,
      ApproverBu: '',
      Initial: '',
      Attachment: {
        ...state.Attachment,
        requiredTypes: state.Attachment.requiredTypes.map((m) => {
          if (m.type === buApproveNotRequiredType)
            return {
              ...m,
              required: state.IsBuApprove,
            };
          return m;
        }),
      },
    }));
  };

  onClickCopyCreator = (e) => {
    const { CreatorName, CreatorTel, CreatorEmail, CreatorFullName, CreatorDepartment } =
      this.state;
    const option = new Option(CreatorFullName, CreatorName, false, true);
    this.requestorRef.$select2.append(option);

    this.setState((state) => ({
      ...state,
      RequestorName: CreatorName,
      RequestorTel: CreatorTel,
      RequestorEmail: CreatorEmail,
      RequestorDepartment: CreatorDepartment,
    }));
  };

  // ---------------- Button Function ----------------
  onClickOpenModalFunction = (e, modal) => {
    let textHeader = '';
    let textContent = '';
    let type = '';
    let icon = '';
    if (modal === 'approve') {
      textHeader = 'Approve';
      textContent = 'approve';
      type = 'success';
      icon = 'icon-check';
    } else if (modal === 'reject') {
      textHeader = 'Reject';
      textContent = 'reject';
      type = 'danger';
      icon = 'icon-close';
    } else if (modal === 'send back') {
      textHeader = 'Send Back';
      textContent = 'send back to edit';
      type = 'info';
      icon = 'icon-undo';
    }

    this.setState(
      {
        ...this.state,
        modalFunction: {
          ...initFunciton,
          mode: modal,

          textHeader,
          textContent,
          type,
          icon,
        },
      },
      this.modalFunctionRef.open
    );
  };

  onChangeInputModal = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      modalFunction: {
        ...this.state.modalFunction,
        [name]: value,
      },
    });
  };

  onSubmitModalFunction = (e) => {
    e.preventDefault();

    const d = this.state.modalFunction;
    const data = {
      TransactionNo: this.state.TransactionNo,
      RemarkFromFinance: d.Remark,
      Action:
        d.mode === 'approve' && toLower(this.state.StatusLg) === 'wait for initialization'
          ? 'initial approve'
          : d.mode === 'approve' && toLower(this.state.StatusLg) === 'wait for bu approve'
            ? 'bu approve'
            : d.mode,
    };

    this.props.saveBgRequest(data).then((response) => {
      if (response.error || !response.payload) return;

      this.modalFunctionRef.close();

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The transaction was successfully change status',
        buttons: [
          <button
            className="btn btn-success"
            onClick={(e) => this.onClickHerePage(e, this.props.routeProp.match.params.id)}
            data-dismiss="modal"
          >
            Here in this page
          </button>,
          <button className="btn btn-default" onClick={this.onClickBackToPage} data-dismiss="modal">
            Back Page
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        // window.location.href = `/tt-remittance/create-tt/${response.payload}`
      } else {
        // this.fetchId(response.payload)
      }
    });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHandler,
      onChangeBuApprove: this.onChangeBuApprove,
      onClickCopyCreator: this.onClickCopyCreator,

      tabRef: (e) => (this.tabRef = e),
      formDetailRef: (e) => (this.formDetailRef = e),
      formStructerRef: (e) => (this.formStructerRef = e),
      formContractRef: (e) => (this.formContractRef = e),
      formCreatorRef: (e) => (this.formCreatorRef = e),
      formRequestorRef: (e) => (this.formRequestorRef = e),
      formManagerRef: (e) => (this.formManagerRef = e),
      formAttachmentRef: (e) => (this.formAttachmentRef = e),

      onClickOpenModalFunction: this.onClickOpenModalFunction,
      onChangeInputModal: this.onChangeInputModal,
      onSubmitModalFunction: this.onSubmitModalFunction,

      requestorRef: (e) => (this.requestorRef = e),
      creatorRef: (e) => (this.creatorRef = e),
      managerRef: (e) => (this.managerRef = e),
      approverBuRef: (e) => (this.approverBuRef = e),
      initialRef: (e) => (this.initialRef = e),

      facilityList: this.props.facilityList,
    };

    return (
      <div>
        <RequestBg
          {...props}
          state={this.state}
          modalFunctionRef={(e) => (this.modalFunctionRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.requestBg,
  }),
  {
    addAlert,
    getMaintainBeneficiary,
    getMaintainRequestBg,
    getMaintainBranch,
    getBgField,
    saveBgRequest,
    getBgRequest,
    saveAttachFile,
    getDefaultBg,
    getAttachment,
    getMasterUserDetail,
    getFacilityList,
  }
)(RequestBgContainer);
