import { connect } from 'react-redux';
import React, { Component } from 'react';

import Button from '../../../common/Button';
import FacilitySetUpDetail from './FacilitySetUpDetail';
import { addAlert } from '../../../reducers/layout/action';
import { getCurrencyDigit } from '../../../reducers/master/action';
import { toFixed, toUpperKey, compareDate } from '../../../common/helpper';
import {
  saveCashFlow,
  saveCreditLine,
  saveFacilityAssign,
  getFacilitySetUpDetail,
  saveFacilitySetUpDetail,
  saveFacilitySetUpDetailAll,
} from '../../../reducers/facilitySetUp/action';

const $ = window.jQuery;

const initInformation = {
  TransactionNo: '',
  FinanceGroupId: '',
  CompanyCode: '',
  CheckCompanyCode: '',
  BusinessPartnerType: 'External',
  BusinessPartnerCode: '',
  Currency: '',
  FacilityName: '',
  FacilityType: '',
  Remark: '',
  IsProject: false,

  CreditLine: '',
  StartDateStr: '',
  EndDateStr: '',

  Outstanding: '',
  inputMaskAmount: {
    prefix: '',
    digits: 0,
    digitsOptional: false,
    placeholder: '0',
  },

  mode: 'create',
};

const initCreditLine = {
  CreditLine: '',
  StartTermStr: '',
  EndTermStr: '',
};

const initAssign = {
  Assign: '',
  ProductTypes: '',
  ProductType: '',
  ValidFromStr: '',
  ValidToStr: '',
  Amount: '',

  mode: '',
};

const initCharge = {
  PaymentDateStr: '',
  Direction: '',
  FlowType: '',
  Amount: '',
  Currency: '',
  PaymentMethod: '',
  Source: 'Facility',
};

const initDeleteCashFlow = {
  CashFlowId: '',
  Index: '',
  CashFlowType: '',
};

class FacilitySetUpDetailContainer extends Component {
  state = {
    ...initInformation,
    resultCreditLine: [],
    resultAssign: [],
    resultProfile: [],

    BankCodeCashFlow: '',
    CashFlow: [],

    modalDelete: {
      Action: '',
      Row: [],
    },
    modalCreditLine: {
      ...initCreditLine,
    },
    modalAssign: {
      ...initAssign,
    },
    modalCharge: {
      ...initCharge,
    },
    modalDeleteCashFlow: {
      ...initDeleteCashFlow,
    },
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;
    if (!transactionNo || transactionNo.toString().toLowerCase() === 'create') {
      this.setState({
        ...initInformation,
        resultCreditLine: [],
        resultAssign: [],
        resultProfile: [],

        BankCodeCashFlow: '',
        CashFlow: [],
      });
    } else {
      this.fetchId(transactionNo);
    }

    $('body').on('click', `button.delete-result`, (e) => this.onClickOpenModalDeleteCashFlow(e));
  }

  componentDidUpdate(prevProps, prevState) {
    const { id } = this.props.routeProp.match.params;
    const prevId = prevProps.routeProp.match.params.id;
    if (id !== prevId) {
      if (!id || id.toString().toLowerCase() === 'create')
        this.setState({
          ...initInformation,
          resultCreditLine: [],
          resultAssign: [],
          resultProfile: [],

          BankCodeCashFlow: '',
          CashFlow: [],
        });
      else {
        this.fetchId(id);
      }
    }

    const { Currency, Outstanding } = this.state;

    if (prevState.Currency !== Currency) {
      this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          Outstanding: toFixed(
            Number((Outstanding.toString() || '').replace(/,/g, '') || 0),
            digit
          ),
        });
      });
    }
  }

  fetchId(id) {
    if (!id) return;

    this.props.getFacilitySetUpDetail(id).then((response) => {
      if (response.error && !response.payload) return;

      const s = response.payload;
      this.setState({
        ...toUpperKey(s.facilityHead),
        CheckCompanyCode: s.facilityHead.companyCode,
        BankCodeCashFlow: s.facilityHead.bankCodeCashFlow,
        resultCreditLine: s.creditLine || [],
        resultAssign: s.assign || [],
        resultProfile: s.profile || [],
        CashFlow: s.cashFlow || [],
        mode: 'update',
      });
    });
  }

  componentWillUnmount() {
    $('body').off('click', `button.delete-result`);
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'IsProject') {
        stateToUpdate.IsProject = !state.IsProject;
      } else if (name === 'BusinessPartnerType') {
        stateToUpdate.BusinessPartnerCode = '';
      } else if (name === 'StartDateStr') {
        if (compareDate(value, state.EndDateStr)) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select Start Date must be more than or equal End Date.',
          });
          return;
        }
      } else if (name === 'EndDateStr') {
        if (compareDate(state.StartDateStr, value)) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select End Date must be less than or equal Start Date.',
          });
          return;
        }
      } else if (name === 'Outstanding') {
        if (value <= 0 && value !== '') {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Credit Line must be more than 0.',
          });
          return {
            [name]: state.Outstanding,
          };
        }
      } else if (name === 'BankCodeCashFlow') {
        state.CashFlow.forEach((m) => {
          m.localCurrency = '';
          m.localCurrencyAmount = 0;
        });
        stateToUpdate.CashFlow = state.CashFlow;
      }

      return stateToUpdate;
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    // let state = { ...this.state }
    // if (checkWeekend(state.StartDateStr)) {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: 'Start Date can not be Weekend.'
    //     })
    //     return
    // }
    // if (checkWeekend(state.EndDateStr)) {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: 'End Date can not be Weekend.'
    //     })
    //     return
    // }

    this.props.saveFacilitySetUpDetail({ ...this.state }).then((response) => {
      if (response.error || !response.payload) return;

      if (response.payload !== this.props.routeProp.match.params.id) {
        const information = response.payload.facilityHead;
        window.location.href = `/loan-investment/facility-set-up-detail/${information.transactionNo}`;
      }
    });
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (!form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitAll = (e) => {
    const inValidFormName = this.validateForms([this.formDetailRef, this.formCashFlowRef]);
    if (inValidFormName) return;

    // let state = { ...this.state }
    // if (checkWeekend(state.StartDateStr)) {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: 'Start Date can not be Weekend.'
    //     })
    //     return
    // }
    // if (checkWeekend(state.EndDateStr)) {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: 'End Date can not be Weekend.'
    //     })
    //     return
    // }
    this.props.saveFacilitySetUpDetailAll({ ...this.state }).then((response) => {
      if (response.error) return;
      if (!response.payload) return;
      // let information = response.payload.facilityHead
      // this.fetchId(information.transactionNo)

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The transaction was successfully saved',
        buttons: [
          <Button
            txt="Continue"
            data-dismiss="modal"
            className="btn-success"
            onClick={(e) => this.props.routeProp.history.push('/loan-investment/facility-set-up')}
          />,
        ],
      });
    });
  };

  onClickOpenModalDelete = (e, i, action) => {
    let row = [];
    if (action === 'credit line') {
      row = this.state.resultCreditLine[i];
    } else if (action === 'assign') {
      row = this.state.resultAssign[i];
    }
    // else if (action === 'cash flow') {
    //     row = this.state.CashFlow[i]
    // }

    this.setState(
      (state) => ({
        modalDelete: {
          ...state.modalDelete,
          Action: action,
          Row: row,
        },
      }),
      this.modalDeleteRef.open
    );
  };

  onClickDeleteModal = () => {
    const modalDelete = { ...this.state.modalDelete };
    const row = modalDelete.Row;
    if (modalDelete.Action === 'credit line') {
      const submitData = {
        FacilityCreditLineId: row.facilityCreditLineId,
        TransactionNo: row.transactionNo,
        IsActive: 0,
        mode: 'change',
      };
      this.props.saveCreditLine({ ...submitData }).then((response) => {
        if (!response.error) {
          this.setState({
            resultCreditLine: response.payload || [],
          });
        }
      }, this.modalDeleteRef.close());
    } else if (modalDelete.Action === 'assign') {
      const submitData = {
        FacilityAssignId: row.facilityAssignId,
        TransactionNo: row.transactionNo,
        Assign: row.assign,
        ProductTypes: row.productType.split(','),
        ProductType: row.productType,
        ValidFromStr: row.validFromStr,
        ValidToStr: row.validToStr,
        Amount: row.amount,
        IsActive: 0,
        mode: 'change',
      };
      this.props.saveFacilityAssign({ ...submitData }).then((response) => {
        if (!response.error) {
          this.setState({
            resultCreditLine: response.payload.creditLine || [],
            resultAssign: response.payload.assign || [],
          });
        }
      }, this.modalDeleteRef.close());
    }
  };

  onChangeInputDataCashFlow = (e, index) => {
    const { name, value, label } = e.target;
    this.setState((state) => ({
      CashFlow: state.CashFlow.map((m, i) => {
        if (i === index) {
          const obj = {
            ...m,
          };
          if (name === 'bankAccountNo') {
            obj.bankAccountNo = value;
            const bankAccountCurrency = label.split('|')[2].trim();
            obj.localCurrency = bankAccountCurrency;
            if (obj.currency === bankAccountCurrency) {
              obj.localCurrencyAmount = obj.amount;
            } else {
              obj.localCurrencyAmount = 0;
            }
          }

          obj[name] = value;
          return obj;
        }
        return m;
      }),
    }));
  };

  // Credit Line
  onClickOpenModalCreditLine = () => {
    this.setState(
      {
        modalCreditLine: {
          ...initCreditLine,
          TransactionNo: this.state.TransactionNo,
          mode: 'create',
        },
      },
      this.modalCreditLineRef.open
    );
  };

  onClickOpenModalCreditLineEdit = (e, i) => {
    const row = this.state.resultCreditLine[i];
    this.setState(
      {
        modalCreditLine: {
          ...initCreditLine,
          FacilityCreditLineId: row.facilityCreditLineId,
          TransactionNo: row.transactionNo,
          CreditLine: row.creditLine,
          StartTermStr: row.startTermStr,
          EndTermStr: row.endTermStr,
          mode: 'update',
        },
      },
      this.modalCreditLineRef.open
    );
  };

  onChangeModalCreditLine = (e) => {
    this.setState({
      modalCreditLine: {
        ...this.state.modalCreditLine,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalCreditLine = (e) => {
    e.preventDefault();

    const modalCreditLine = { ...this.state.modalCreditLine };
    if (
      !modalCreditLine.CreditLine ||
      parseFloat((modalCreditLine.CreditLine.toString() || '').replace(/,/g, '')) <= 0
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Credit Line must more than 0.',
      });
      return;
    }

    if (compareDate(modalCreditLine.StartTermStr, modalCreditLine.EndTermStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Valid To must more than or equal Valid From.',
      });
      return;
    }

    this.props.saveCreditLine({ ...this.state.modalCreditLine }).then((response) => {
      if (!response.error) {
        this.setState({
          resultCreditLine: response.payload,
        });
        this.modalCreditLineRef.close();
      }
    });
  };

  // Assign
  onClickOpenModalAssign = () => {
    this.setState(
      {
        modalAssign: {
          ...initAssign,
          TransactionNo: this.state.TransactionNo,
          mode: 'create',
        },
      },
      this.modalAssignRef.open
    );
  };

  onClickOpenModalAssignEdit = (e, i) => {
    const row = this.state.resultAssign[i];
    this.setState(
      {
        modalAssign: {
          ...initAssign,
          FacilityAssignId: row.facilityAssignId,
          CreditLineId: row.creditLineId,
          TransactionNo: row.transactionNo,
          Assign: row.assign,
          ProductTypes: row.productType.split(','),
          ProductType: row.productType,
          ValidFromStr: row.validFromStr,
          ValidToStr: row.validToStr,
          Amount: row.amount,
          OrgAmount: row.amount,
          Used: row.used,
          IsActive: row.isActive,
          IsDelete: row.isDelete,
          mode: 'update',
        },
      },
      this.modalAssignRef.open
    );
  };

  onChangeModalAssign = (e) => {
    this.setState({
      modalAssign: {
        ...this.state.modalAssign,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalAssign = (e) => {
    e.preventDefault();
    const modalAssign = { ...this.state.modalAssign };
    const productTypes = modalAssign.ProductType.toString();
    this.setState({
      modalAssign: {
        ...this.state.modalAssign,
        ProductType: productTypes,
      },
    });

    if (
      parseFloat((modalAssign.Amount.toString() || '').replace(/,/g, '')) < (modalAssign.Used || 0)
    ) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Amount must more than or equal Used Amount.',
      });
      return;
    }

    if (compareDate(this.state.StartDateStr, modalAssign.ValidFromStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Valid From must less than or equal Start Date.',
      });
      return;
    }

    if (this.state.EndDateStr) {
      if (compareDate(modalAssign.ValidFromStr, this.state.EndDateStr)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Valid From must less than or equal End Date.',
        });
        return;
      }
    }

    if (modalAssign.ValidToStr) {
      if (compareDate(modalAssign.ValidFromStr, modalAssign.ValidToStr)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Valid To must less than or equal Valid From.',
        });
        return;
      }

      if (compareDate(modalAssign.ValidToStr, this.state.EndDateStr)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Valid To must less than or equal End Date.',
        });
        return;
      }
    }

    this.setState(
      {
        modalAssign: {
          ...this.state.modalAssign,
        },
      },
      this.saveFacilityAssign
    );
  };

  saveFacilityAssign = () => {
    this.props.saveFacilityAssign({ ...this.state.modalAssign }).then((response) => {
      if (!response.error) {
        this.setState({
          resultCreditLine: response.payload.creditLine || [],
          resultAssign: response.payload.assign || [],
        });
        this.modalAssignRef.close();
      }
    });
  };

  // Charge
  onClickOpenModalCharge = () => {
    this.setState(
      {
        modalCharge: {
          ...initCharge,
        },
      },
      this.modalChargeRef.open()
    );
  };

  onChangeModalCharge = (e) => {
    this.setState({
      modalCharge: {
        ...this.state.modalCharge,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalCharge = (e) => {
    e.preventDefault();
    this.setState((state) => {
      const charge = state.modalCharge;
      return {
        CashFlow: [
          ...state.CashFlow,
          {
            paymentDateStr: charge.PaymentDateStr,
            direction: charge.Direction,
            flowType: charge.FlowType,
            amount: charge.Amount,
            currency: charge.Currency,
            paymentMethod: charge.PaymentMethod,
            isManualCreate: true,
          },
        ],
      };
    });
    this.modalChargeRef.close();
  };

  // Delete Cash Flow
  onClickOpenModalDeleteCashFlow = (e) => {
    this.setState(
      {
        modalDeleteCashFlow: {
          ...initDeleteCashFlow,
          Index: e.currentTarget.attributes.index.value,
        },
      },
      this.modalDeleteCashFlowRef.open
    );
  };

  onClickConfirmModalDeleteCashFlow = () => {
    const modalDelete = { ...this.state.modalDeleteCashFlow };

    const result = this.state.CashFlow;
    const index = parseInt(modalDelete.Index);
    const newResult = result.filter((item, i) => i !== index);

    this.setState(
      {
        CashFlow: newResult,
      },
      this.modalDeleteCashFlowRef.close
    );
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,
      onSubmitAll: this.onSubmitAll,

      onClickOpenModalDelete: this.onClickOpenModalDelete,
      onClickDeleteModal: this.onClickDeleteModal,
      onClickConfirmModalDeleteCashFlow: this.onClickConfirmModalDeleteCashFlow,

      onChangeInputDataCashFlow: this.onChangeInputDataCashFlow,

      onClickOpenModalCreditLine: this.onClickOpenModalCreditLine,
      onClickOpenModalCreditLineEdit: this.onClickOpenModalCreditLineEdit,
      onChangeModalCreditLine: this.onChangeModalCreditLine,
      onSubmitModalCreditLine: this.onSubmitModalCreditLine,

      onClickOpenModalAssign: this.onClickOpenModalAssign,
      onClickOpenModalAssignEdit: this.onClickOpenModalAssignEdit,
      onChangeModalAssign: this.onChangeModalAssign,
      onSubmitModalAssign: this.onSubmitModalAssign,

      onClickOpenModalCharge: this.onClickOpenModalCharge,
      onChangeModalCharge: this.onChangeModalCharge,
      onSubmitModalCharge: this.onSubmitModalCharge,

      formDetailRef: (e) => (this.formDetailRef = e),
      formCashFlowRef: (e) => (this.formCashFlowRef = e),
      tabRef: (e) => (this.tabRef = e),
    };

    return (
      <FacilitySetUpDetail
        {...props}
        state={this.state}
        modalDeleteRef={(e) => (this.modalDeleteRef = e)}
        modalDeleteCashFlowRef={(e) => (this.modalDeleteCashFlowRef = e)}
        modalCreditLine={this.state.modalCreditLine}
        modalCreditLineRef={(e) => (this.modalCreditLineRef = e)}
        modalAssign={this.state.modalAssign}
        modalAssignRef={(e) => (this.modalAssignRef = e)}
        modalCharge={this.state.modalCharge}
        modalChargeRef={(e) => (this.modalChargeRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.facilitySetUp,
  }),
  {
    addAlert,
    saveFacilitySetUpDetail,
    getFacilitySetUpDetail,
    saveFacilityAssign,
    saveCreditLine,
    saveFacilitySetUpDetailAll,
    saveCashFlow,
    getCurrencyDigit,
  }
)(FacilitySetUpDetailContainer);
