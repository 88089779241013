import { connect } from 'react-redux';
import React, { Component } from 'react';

import Company from './Company';
import {
  editCriteria,
  clearCriteria,
  updateSearchResult,
  searchMasterCompany,
  deleteMasterCompany,
} from '../../reducers/masterCompany/action';

// const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-company';

const initChangeStatus = {
  CompanyCode: '',
};

class CompanyContainer extends Component {
  state = {
    modalChangeStatus: {
      ...initChangeStatus,
    },
  };

  dataTableSearchResultRef = null;

  // componentDidMount() {
  //     $('body').on('click', `button.active`, e => this.onClickOpenModalChangeStatus(e, 'Active'))
  //     $('body').on('click', `button.inactive`, e => this.onClickOpenModalChangeStatus(e, 'Inactive'))
  // }

  // componentWillUnmount() {
  //     $('body').off('click', `button.active`)
  //     $('body').off('click', `button.inactive`)
  // }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMasterCompany({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // onClickOpenModalChangeStatus = (e, modal) => {
  //     let $dt = this.dataTableSearchResultRef.$dataTable
  //     let row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'))
  //     let rowData = $dt.row(row).data()

  //     this.setState({
  //         modalChangeStatus: {
  //             ...initChangeStatus,
  //             CompanyCode: rowData.companyCode,
  //             modal: modal
  //         }
  //     })

  //     if (modal === 'Active')
  //         this.modalActiveRef.open()
  //     else if (modal === 'Inactive')
  //         this.modalInactiveRef.open()
  // }

  // onSubmitModalChangeStatus = (e, modal) => {
  //     let submitData = {
  //         companyCode: this.state.modalChangeStatus.CompanyCode,
  //     }
  //     this.props.deleteMasterCompany(submitData, this.props.criteria)
  //     .then(response=>{
  //        if(response.error) return

  //        this.modalActiveRef.close()
  //        this.modalInactiveRef.close()
  //        this.props.searchMasterCompany({ ...this.props.criteria })
  //    })
  // }

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      // onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <div>
        <Company
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}

          // modalActiveRef={e => this.modalActiveRef = e}
          // modalInactiveRef={e => this.modalInactiveRef = e}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterCompany,
  }),
  {
    searchMasterCompany,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    deleteMasterCompany,
  }
)(CompanyContainer);
