import React, { Component } from 'react';

const $ = window.jQuery;
export default class MonthPicker extends Component {
  componentDidMount() {
    const defaultOpt = {
      autoclose: true,
      format: 'MM yyyy',
      startView: 'months',
      minViewMode: 'months',
      orientation: 'bottom auto',
    };
    const option = {
      ...defaultOpt,
      ...(this.props.option || {}),
    };
    $(this.datePickerElement).datepicker(option);

    $(this.datePickerElement).on('change', this.onChangeHandler);

    if (this.props.setDate) {
      $(this.datePickerElement).datepicker('setDate', this.props.setDate.format('MM yyyy'));
    } else if (this.props.value) {
      $(this.datePickerElement).datepicker('setDate', this.props.value);
    }
  }

  componentWillUnmount() {
    $(this.datePickerElement).off('change', this.onChangeHandler);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.datePickerElement.value !== this.props.value) {
      // prevent null or empty value update
      if (!this.datePickerElement.value && !this.props.value) return;

      $(this.datePickerElement).datepicker('setDate', this.props.value);
    }
  }

  onChangeHandler = (e) => {
    if (this.props.value !== this.datePickerElement.value) this.props.onChange(e);
  };

  render() {
    return (
      <div>
        <input
          className="form-control"
          autoComplete="off"
          name={this.props.name}
          ref={(el) => (this.datePickerElement = el)}
          type="text"
          disabled={!!this.props.disabled}
          required={!!this.props.required}
        />
      </div>
    );
  }
}
