import { connect } from 'react-redux';
import React, { Component } from 'react';

import BankGuarantee from './BankGuarantee';
import { addAlert } from '../../../reducers/layout/action';
import { saveBgRequest } from '../../../reducers/requestBg/action';
import { editCriteria, clearCriteria, searchBgReport } from '../../../reducers/reportBg/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-report-bg';

const initModal = {
  TransactionNo: '',
  InternalMessage: '',
  Id: '',
  Action: 'report',
};

class BankGuaranteeContainer extends Component {
  state = {
    modalData: {
      ...initModal,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `a.remark-report`, this.onClickOpenModalEdit);
  }

  componentWillUnmount() {
    $('body').off('click', `a.remark-report`, this.onClickOpenModalEdit);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    if (name === 'IsExpired') {
      this.props.editCriteria(name, !this.props.criteria.IsExpired);
    } else {
      this.props.editCriteria(name, value);
    }
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchBgReport({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickOpenModalEdit = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    this.setState(
      {
        modalData: {
          ...initModal,
          TransactionNo: rowData.transactionNo,
          InternalMessage: rowData.internalMessage,
          Id: rowData.id,
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModal = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      modalData: {
        ...state.modalData,
        [name]: value,
      },
    }));
  };

  onClickSubmitModal = (e) => {
    e.preventDefault();
    const submitData = { ...this.state.modalData };
    this.props.saveBgRequest(submitData).then((response) => {
      if (!response.error) {
        this.props.addAlert({
          type: 'success',
          title: 'Success',
          body: 'The transaction was successfully.',
        });
        this.modalDataRef.close();
        this.props.searchBgReport({ ...this.props.criteria });
      }
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onChangeModal: this.onChangeModal,
      onClickSubmitModal: this.onClickSubmitModal,
    };

    return (
      <div>
        <BankGuarantee
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalDataRef={(e) => (this.modalDataRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.reportBg,
  }),
  {
    searchBgReport,
    editCriteria,
    clearCriteria,
    saveBgRequest,
    addAlert,
  }
)(BankGuaranteeContainer);
