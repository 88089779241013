import { connect } from 'react-redux';
import React, { Component } from 'react';

import SweepMoney from './SweepMoney';
import { searchSweep, submitSweep } from '../../reducers/sweep/action';

class SweepMoneyContainer extends Component {
  state = {
    criteria: {
      ValueDate: '',
      SweepType: 'fg',
      Currency: '',
      FinanceGroupId: '',
      BusinessUnitId: '',
    },
  };

  onChangeHeadler = (e) => {
    if (e.target.name === 'SweepType') {
      this.setState({
        criteria: {
          ...this.state.criteria,
          SweepType: e.target.value,
          FinanceGroupId: '',
          BusinessUnitId: '',
        },
      });
    } else
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.target.name]: e.target.value,
        },
      });
  };

  onClickProcessHandler = (e) => {
    e.preventDefault();
    this.props.searchSweep(this.state.criteria);
  };

  onClickSaveHandler = (e, index) => {
    this.props.submitSweep([...this.props.searchResult], { ...this.state.criteria });
  };

  render() {
    const props = {
      onChange: this.onChangeHeadler,
      onClickProcess: this.onClickProcessHandler,
      results: this.props.searchResult,
      onClickSave: this.onClickSaveHandler,
    };

    return <SweepMoney {...props} {...this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.sweepMoney,
  }),
  {
    searchSweep,
    submitSweep,
  }
)(SweepMoneyContainer);
