import { connect } from 'react-redux';
import React, { Component } from 'react';

import ExposureTransaction from './ExposureTransaction';
import { addAlert } from '../../reducers/layout/action';
import { getCurrencyDigit } from '../../reducers/master/action';
import { toLower, toFixed, toUpperKey } from '../../common/helpper';
import { searchHoliday, getHolidayDetail } from '../../reducers/masterHoliday/action';
import {
  getExposure,
  createExposure,
  downloadAttachFileExposure,
} from '../../reducers/createExposure/action';

const init = {
  ExposureNo: '',
  CashType: '',
  TransactionType: '',
  ValueDateStr: '',
  InformDateStr: '',
  CompanyCode: '',
  SwiftCode: '',
  BankAccountNo: '',
  BankCurrency: '',
  Currency: '',
  Equivalent: '',
  Amount: '',
  BankAccountNoTemp: '',

  PaymentType: '',
  ExposureType: '',
  CustomerName: '',
  ReferenceNo: '',
  TransactionDetail: '',
  ForwardNo: '',
  IsForward: false,
  ProjectName: '',
  BranchName: '',
  AccountTypeCode: '',
  InvoiceNo: '',
  InvoiceAmount: '',
  mode: 'create',

  inputMaskAmount: {
    prefix: '',
    digits: 3,
  },
  inputMaskInvoiceAmount: {
    prefix: '',
    digits: 2,
  },
};

class ExposureTransactionContainer extends Component {
  state = {
    ...init,
    createHistory: [],
  };

  selectedFile = null;

  componentDidMount() {
    const exposureNo = this.props.routeProp.match.params.id;
    if (exposureNo && exposureNo !== 'create') {
      this.props.getExposure(exposureNo).then((response) => {
        if (response.error && !response.payload) return;

        const r = response.payload;
        this.setState({
          ...toUpperKey(response.payload),
          mode: 'edit',
          IsForward: !(toLower(r.status) !== 'wait for select method' || !r.isActive),
          PaymentType: toLower(r.paymentType) === 't/t' ? 'TT' : r.paymentType,
          InvoiceAmount: r.invoiceAmount ? Number(r.invoiceAmount) : '',
        });
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { Currency, Amount } = this.state;

    if (prevState.Currency !== Currency) {
      this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          Amount: toFixed(Number((Amount.toString() || '').replace(/,/g, '') || 0), digit),
          inputMaskInvoiceAmount: {
            prefix: '',
            digits: digit,
          },
        });
      });
    }
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'IsForward') {
        stateToUpdate.IsForward = !state.IsForward;
        if (!stateToUpdate.IsForward) {
          stateToUpdate.ForwardNo = '';
        }
      } else if (name === 'CashType') {
        if (value === 'Inward') {
          stateToUpdate.InformDateStr = '';
          if (state.ExposureType === 'Trade AP' || state.ExposureType === '') {
            stateToUpdate.ExposureType = 'Trade AR';
          }
        } else if (value === 'Outward') {
          stateToUpdate.InformDateStr = window.moment().add(0, 'days').format('DD/MM/YYYY');
          if (state.ExposureType === 'Trade AR' || state.ExposureType === '') {
            stateToUpdate.ExposureType = 'Trade AP';
          }
        }
      } else if (name === 'ValueDateStr' || name === 'InformDateStr') {
        const newState = {
          ...state,
          ...stateToUpdate,
        };

        if (
          newState.ValueDateStr &&
          newState.InformDateStr &&
          window
            .moment(newState.ValueDateStr, 'DD/MM/YYYY')
            .isBefore(window.moment(newState.InformDateStr, 'DD/MM/YYYY'), 'day')
        ) {
          this.props.addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'Value date must not before Inform date.',
          });
          return {
            [name]: '',
          };
        }
      } else if (name === 'CompanyCode') {
        stateToUpdate.SwiftCode = '';
        stateToUpdate.BankAccountNoTemp = '';
      } else if (name === 'SwiftCode') {
        stateToUpdate.BankAccountNoTemp = '';
      } else if (name === 'BankAccountNoTemp') {
        const s = (value || '').split('|');
        stateToUpdate.BranchName = s[0] || '';
        stateToUpdate.AccountTypeCode = s[1] || '';
        stateToUpdate.BankCurrency = s[2] || '';
        stateToUpdate.BankAccountNo = s[3] || '';
      } else if (name === 'Currency' || name === 'Equivalent') {
        const newState = {
          ...state,
          ...stateToUpdate,
        };

        if (newState.Currency && newState.Equivalent && newState.Currency === newState.Equivalent) {
          this.props.addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'Equivalent must be not equal currency.',
          });
          return {
            [name]: state[name],
          };
        }
      }

      return stateToUpdate;
    });
  };

  onCheckHoliday = (e) => {
    e.preventDefault();
    this.props.searchHoliday({ status: true }).then((response) => {
      if (!response.error) {
        const r = response.payload;
        let calendarId = null;
        const tt = r.find((f) => f.calendarName === 'T/T Request');
        if (tt) {
          calendarId = tt.holidayId;
        }
        this.setState({
          ...this.state,
          CalendarId: calendarId,
        });

        this.checkHoliday();
      }
    });
  };

  checkHoliday = () => {
    const { CalendarId, ValueDateStr } = this.state;
    if (ValueDateStr === '' || CalendarId === '') {
      this.onSubmitCreateHandler();
    } else {
      const s = ValueDateStr.split('/');
      const year = Number.parseInt(s[2] || 0);
      this.props
        .getHolidayDetail({
          year,
          holidayId: CalendarId,
        })
        .then((response) => {
          if (response.error || !response.payload) return;

          let isHoliday = false;
          let description = '';
          const l = response.payload;
          for (let i = 0; i < l.length; i++) {
            const d = l[i];
            if (d.holidayDateStr === ValueDateStr) {
              isHoliday = true;
              description = d.holidayName;
            }
          }

          if (isHoliday) {
            this.props.addAlert({
              title: 'Warning',
              type: 'warning',
              body: `Value date is Holiday [${description}]. Please confirm that you want to proceed this transaction??`,
              buttons: [
                <button
                  data-dismiss="modal"
                  className="btn btn-warning btn-sm"
                  onClick={(e) => this.onSubmitCreateHandler()}
                >
                  Confirm
                </button>,
                <button data-dismiss="modal" className="btn btn-default btn-sm">
                  Cancel
                </button>,
              ],
            });
          } else {
            this.onSubmitCreateHandler();
          }
        });
    }
  };

  onSubmitCreateHandler = (e) => {
    if (!this.state.Amount || this.state.Amount <= 0) {
      this.props.addAlert({
        title: 'Error',
        body: 'Amount must more than zero.',
        type: 'warning',
      });
      return;
    }

    if (this.state.InformDateStr !== '') {
      const informdate = window.moment(this.state.InformDateStr, 'DD/MM/YYYY').format('dddd');
      if (informdate === 'Saturday' || informdate === 'Sunday') {
        this.props.addAlert({
          title: 'Error',
          body: 'Inform date can not be weekend.',
          type: 'warning',
        });
        return;
      }
    }

    if (this.state.ValueDateStr !== '') {
      const valuedate = window.moment(this.state.ValueDateStr, 'DD/MM/YYYY').format('dddd');
      if (valuedate === 'Saturday' || valuedate === 'Sunday') {
        this.props.addAlert({
          title: 'Error',
          body: 'Value date can not be weekend.',
          type: 'warning',
        });
        return;
      }
    }

    const form = new FormData();
    form.append('data', JSON.stringify({ ...this.state }));
    if (this.fileRef.files[0]) form.append('file', this.fileRef.files[0]);
    this.props.createExposure(form).then((response) => {
      if (!response.error) {
        this.setState((state) => {
          const stateUpdate = {
            ...init,
          };

          if (response.payload)
            stateUpdate.createHistory = [...state.createHistory, response.payload];

          return stateUpdate;
        });
      }
    });
  };

  onClickClearEquivalent = () => {
    this.setState({
      ...this.state,
      Equivalent: '',
    });
  };

  onClickDownloadAttachFile = () => {
    const { AttachFileExposure } = this.state;
    if (!AttachFileExposure) return;

    this.props.downloadAttachFileExposure(AttachFileExposure);
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmitCreateHandler: this.onSubmitCreateHandler,
      onClickClearEquivalent: this.onClickClearEquivalent,
      attachFileRef: (e) => (this.fileRef = e),
      onClickDownloadAttachFile: this.onClickDownloadAttachFile,
      onCheckHoliday: this.onCheckHoliday,
    };

    return <ExposureTransaction {...props} state={this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.exposureTransaction,
  }),
  {
    addAlert,
    createExposure,
    getExposure,
    downloadAttachFileExposure,
    getCurrencyDigit,
    searchHoliday,
    getHolidayDetail,
  }
)(ExposureTransactionContainer);
