import { connect } from 'react-redux';
import React, { Component } from 'react';

import InterestRate from './InterestRate';
import { addAlert } from '../../../reducers/layout/action';
import { toLower, toUpperKey } from '../../../common/helpper';
import {
  getInterestRate,
  saveMaintainInterestRate,
} from '../../../reducers/maintainInterestRate/action';

const initStructure = {
  InterestRateNo: '',
  InterestTemplateName: '',
  CalculateMethod: '',

  PeriodEndType: '',
  IsManualInclude: '',
  IsManualMonthEnd: '',
  ManualPeriodEnd: '',
  ManualDay: '',
  ManualWorkingDay: '',

  DueDateType: '',
  IsDueDateMonthEnd: '',
  ManualDueDate: '',
  DueDateDay: '',
  DueDateWorkingDay: '',
  Frequency: '',
};

class InterestRateContainer extends Component {
  state = {
    ...initStructure,
    mode: 'create',
  };

  componentDidMount() {
    const interestRateId = this.props.routeProp.match.params.id;
    if (interestRateId && interestRateId !== 'create') {
      this.props.getInterestRate(interestRateId).then((response) => {
        if (response.error && !response.payload) return;

        this.setState({
          ...toUpperKey(response.payload),
          mode: 'edit',
          ManualPeriodEnd:
            toLower(response.payload.periodEndType) === 'end of term'
              ? ''
              : response.payload.isManualMonthEnd
                ? 'Month End'
                : 'Day',
          ManualDueDate:
            toLower(response.payload.dueDateType) === 'end of term'
              ? ''
              : response.payload.isDueDateMonthEnd
                ? 'Month End'
                : 'Day',
        });
      });
    }
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'IsManualInclude') {
        stateToUpdate.IsManualInclude = !state.IsManualInclude;
      } else if (name === 'PeriodEndType') {
        if (value === 'End of Term') {
          stateToUpdate.ManualPeriodEnd = '';
          stateToUpdate.IsManualMonthEnd = false;
          stateToUpdate.ManualDay = '';
        }
      } else if (name === 'ManualPeriodEnd') {
        if (value === 'Month End') {
          stateToUpdate.IsManualMonthEnd = true;
          stateToUpdate.ManualDay = '';
        } else stateToUpdate.IsManualMonthEnd = false;
      } else if (name === 'ManualDay') {
        if (value > 31) stateToUpdate.ManualDay = 31;
        else if (value < 1) stateToUpdate.ManualDay = 1;
      } else if (name === 'DueDateType') {
        if (value === 'End of Term') {
          stateToUpdate.ManualDueDate = '';
          stateToUpdate.DueDateDay = '';
          stateToUpdate.IsDueDateMonthEnd = false;
        }
      } else if (name === 'ManualDueDate') {
        if (value === 'Month End') {
          stateToUpdate.IsDueDateMonthEnd = true;
          stateToUpdate.DueDateDay = '';
        } else stateToUpdate.IsDueDateMonthEnd = false;
      } else if (name === 'DueDateDay') {
        if (value > 31) stateToUpdate.DueDateDay = 31;
        else if (value < 1) stateToUpdate.DueDateDay = 1;
      }

      return stateToUpdate;
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.saveMaintainInterestRate({ ...this.state }).then((response) => {
      if (response.error || !response.payload) return;

      if (!response.error) {
        this.setState({
          results: [],
        });
        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The transaction was successfully saved',
          buttons: [
            <button
              className="btn btn-success"
              onClick={this.onClickBackToPage}
              data-dismiss="modal"
            >
              Continue
            </button>,
          ],
        });
      }

      if (response.payload !== this.props.routeProp.match.params.id) {
        window.location.href = `/loan-investment/interest-rate/${response.payload}`;
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/loan-investment/maintain-interest-rate?s=true');
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,
    };

    return <InterestRate {...props} state={this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.maintainInterestRate,
  }),
  {
    saveMaintainInterestRate,
    addAlert,
    getInterestRate,
  }
)(InterestRateContainer);
