import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import RadioButton from '../../common/RadioButton';
import { compareDate } from '../../common/helpper';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { MASTER_COMPANY, MASTER_CURRENCY, MASTER_BANK_CODE } from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
export default ({ state, onChangeInputData, formRef, viewOnly }) => {
  const noEdit = state.mode === 'edit';
  const textType = state.Source === 'Deposit' ? 'Deposit Type' : 'Loan Type';
  const required = true;
  const checkTermStart =
    state.mode === 'create'
      ? false
      : viewOnly
        ? true
        : compareDate(window.moment().add(1, 'days').format('DD/MM/YYYY'), state.TermStartStr);

  return (
    <Card textHeader="Partner" cardActions={['toggler']}>
      <form ref={formRef} name="structure">
        {noEdit ? (
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text={textType} required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.LoanType || ''}
                  name="LoanType"
                  readOnly
                />
              </FormGroup2>

              <FormGroup2 text="Partner Type" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.PartnerType || ''}
                  name="PartnerType"
                  readOnly
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Transaction Type" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.TransactionType || ''}
                  name="TransactionType"
                  readOnly
                />
              </FormGroup2>

              {state.PartnerType === 'Intercompany' ? (
                <FormGroup2 text="IC Type" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.IcType || ''}
                    name="IcType"
                    readOnly
                  />
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>
        ) : (
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text={textType} required>
                {state.Source === 'Contract On Call' ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.LoanType || ''}
                    name="LoanType"
                    readOnly
                  />
                ) : (
                  <div>
                    <RadioButton
                      text="On Call"
                      value="On Call"
                      checked={state.LoanType === 'On Call'}
                      onChange={onChangeInputData}
                      name="LoanType"
                      required
                    />
                    <RadioButton
                      text="Term"
                      value="Term"
                      checked={state.LoanType === 'Term'}
                      onChange={onChangeInputData}
                      name="LoanType"
                      required
                    />
                  </div>
                )}
              </FormGroup2>

              <FormGroup2 text="Partner Type" required>
                {state.Source === 'Deposit' ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.PartnerType || ''}
                    name="PartnerType"
                    readOnly
                  />
                ) : (
                  <div>
                    <RadioButton
                      text="Intercompany"
                      value="Intercompany"
                      checked={state.PartnerType === 'Intercompany'}
                      onChange={onChangeInputData}
                      name="PartnerType"
                      required
                    />
                    <RadioButton
                      text="External"
                      value="External"
                      checked={state.PartnerType === 'External'}
                      onChange={onChangeInputData}
                      name="PartnerType"
                    />
                  </div>
                )}
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Transaction Type" required>
                <div>
                  {state.PartnerType !== 'External' ? (
                    <div>
                      <RadioButton
                        text="Loan"
                        value="Loan"
                        checked={state.TransactionType === 'Loan'}
                        onChange={onChangeInputData}
                        name="TransactionType"
                        required
                      />
                      <RadioButton
                        text="Deposit"
                        value="Deposit"
                        checked={state.TransactionType === 'Deposit'}
                        onChange={onChangeInputData}
                        name="TransactionType"
                      />
                    </div>
                  ) : (
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="TransactionType"
                      required
                      value={state.TransactionType}
                      readOnly
                    />
                  )}
                </div>
              </FormGroup2>

              {state.PartnerType === 'Intercompany' ? (
                <FormGroup2 text="IC Type" required>
                  <div>
                    <RadioButton
                      text="Master"
                      value="Master"
                      checked={state.IcType === 'Master'}
                      onChange={onChangeInputData}
                      name="IcType"
                      required
                    />
                    <RadioButton
                      text="Other"
                      value="Other"
                      checked={state.IcType === 'Other'}
                      onChange={onChangeInputData}
                      name="IcType"
                    />
                  </div>
                </FormGroup2>
              ) : null}
            </Col2>
          </Col2>
        )}

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Business Partner" required>
              {noEdit ? (
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={`${state.BusinessPartner} | ${state.BusinessPartnerName}`}
                  name="BusinessPartner"
                  readOnly
                />
              ) : state.PartnerType === 'External' ? (
                <MasterDropdown
                  key="External"
                  masterType={MASTER_BANK_CODE}
                  noValidateOption={noEdit}
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required={required}
                  customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                  // companyCode={state.CompanyCode}
                  disabled={false}
                  value={state.BusinessPartner}
                  name="BusinessPartner"
                  isGetAll
                  isOutFlow
                />
              ) : state.PartnerType === 'Intercompany' ? (
                <MasterDropdown
                  key="Intercompany"
                  masterType={MASTER_COMPANY}
                  noValidateOption={noEdit}
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  onlyMainCompany={state.IcType === 'Master'}
                  companyCode={state.IcType === 'Master' ? state.CompanyCode : null}
                  isGetAll={state.IcType === 'Other'}
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required={required}
                  disabled={false}
                  value={state.BusinessPartner}
                  name="BusinessPartner"
                />
              ) : (
                <MasterDropdownUI
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required={required}
                  disabled={false}
                  value={state.BusinessPartner}
                  name="BusinessPartner"
                />
              )}
            </FormGroup2>

            <FormGroup2 text="Contract Date" required={state.Source !== 'PN'}>
              <DatePicker
                onChange={onChangeInputData}
                option={{
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required={state.Source !== 'PN'}
                disabled={checkTermStart}
                value={state.ContractDateStr}
                name="ContractDateStr"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Term Start" required>
              <DatePicker
                onChange={onChangeInputData}
                option={{
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required={required}
                disabled={checkTermStart}
                value={state.TermStartStr}
                name="TermStartStr"
              />
            </FormGroup2>
            {state.LoanType !== 'On Call' || state.TermEndStr ? (
              <FormGroup2 text="Term End" required>
                <DatePicker
                  onChange={onChangeInputData}
                  option={{
                    // daysOfWeekDisabled: "0,6",
                    todayHighlight: true,
                  }}
                  required
                  disabled={viewOnly || state.LoanType === 'On Call'}
                  value={state.TermEndStr}
                  name="TermEndStr"
                />
              </FormGroup2>
            ) : null}
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Amount" required>
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInputData}
                option={
                  state.inputMaskAmount || {
                    prefix: '',
                    digits: 3,
                    // digitsOptional: false,
                    // placeholder: '0.000'
                  }
                }
                required={required}
                disabled={viewOnly}
                value={state.Amount}
                name="Amount"
              />
            </FormGroup2>

            <FormGroup2 text="Currency" required>
              {noEdit ? (
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.Currency || ''}
                  name="Currency"
                  readOnly
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required={required}
                  value={state.Currency}
                  name="Currency"
                />
              )}
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="External Ref" required={false}>
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.ExternalRef || ''}
                name="ExternalRef"
                disabled={viewOnly}
                required={false}
              />
            </FormGroup2>
            <FormGroup2>
              <input
                id="IsThaiSweep"
                type="checkbox"
                className="label-checkbox"
                onChange={onChangeInputData}
                name="IsThaiSweep"
                checked={state.IsThaiSweep || false}
              />
              <label htmlFor="IsThaiSweep" className="label_checkbox" />
              <label htmlFor="IsThaiSweep" className="control-label text_label_checkbox">
                Thai Sweep Logic for Interest Cal.
              </label>
            </FormGroup2>
          </Col2>
        </Col2>
      </form>
    </Card>
  );
};
