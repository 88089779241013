import { connect } from 'react-redux';
import React, { Component } from 'react';

import BankBranchDetail from './BankBranchDetail';
import { addAlert } from '../../../reducers/layout/action';
import { toUpperKey, checkAfterValueDate } from '../../../common/helpper';
import {
  getBankBranchDetail,
  saveMasterBankBranch,
  getMasterBankBranchffectiveDate,
} from '../../../reducers/masterBankBranch/action';

const initData = {
  EffectiveDateStr: '',
  TempEffectiveDateStr: 'New',

  BranchCode: '',
  BranchCodeTh: '',
  BankCodeCountry: '',
  BankCode: '',
  Country: '',
  BranchName: '',
  BranchNameTh: '',
  InstanceCode: '',
  InstanceName: '',
  HouseBank: '',
  mode: 'create',
  IsActive: true,
  setOverDueDate: false,
};

class BankBranchDetailContainer extends Component {
  state = {
    ...initData,
    resultEffective: [],
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState({
        ...initData,
      });
    else {
      this.fetchId(id);
    }
  }

  fetchId(id) {
    if (!id) return;

    const idSplit = id.split('&');

    this.props
      .getMasterBankBranchffectiveDate({
        BankCode: idSplit[0] || '',
        BranchCode: idSplit[1] || '',
        Country: idSplit[2] || '',
        Mode: 'date',
      })
      .then((response) => {
        if (response.error || !response.payload) return;

        const result = response.payload.map((m) => m.effectiveDateStr);
        this.setState({
          ...initData,
          resultEffective: result,
        });

        const result2 = result.filter((f) => {
          if (!checkAfterValueDate(f, 0)) return true;
          return false;
        });

        if (result2.length === 0) {
          this.setDataBank(result[result.length - 1]);
        } else {
          const effectiveDate = result2[0];
          this.setDataBank(effectiveDate);
        }
      });
  }

  setDataBank(effectiveDateStr) {
    const { id } = this.props.routeProp.match.params;

    const idSplit = id.split('&');

    this.props
      .getBankBranchDetail({
        BankCode: idSplit[0] || '',
        BranchCode: idSplit[1] || '',
        Country: idSplit[2] || '',
        EffectiveDateStr: effectiveDateStr,
      })
      .then((response) => {
        if (response.error || !response.payload) return;

        const fetchData = toUpperKey(response.payload);
        this.setState({
          ...fetchData,
          mode: 'edit',
          TempEffectiveDateStr: fetchData.EffectiveDateStr,
          setOverDueDate: !checkAfterValueDate(fetchData.EffectiveDateStr, 0),
        });
      });
  }

  onChangeInputData = (e) => {
    const { name, value, label } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'BankCodeCountry') {
        const s = (value || '').split('|');
        stateToUpdate.BankCode = s[0] || '';
        stateToUpdate.Country = s[1] || '';
      } else if (name === 'InstanceCode' && value) {
        stateToUpdate.InstanceCode = value;
        stateToUpdate.InstanceName = (label || '').split('|')[1].trim() || '';
      }
      return stateToUpdate;
    });

    if (name === 'TempEffectiveDateStr') {
      if (value === 'New') {
        this.setState({
          EffectiveDateStr: '',
          setOverDueDate: false,
          IsActive: true,
        });
      } else {
        this.setDataBank(value);
      }
    }
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitHander = (e) => {
    const inValidFormName = this.validateForms([this.formDetailRef]);
    if (inValidFormName) return;

    this.props.saveMasterBankBranch(this.state).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        window.location.href = `/master/bank-branch-detail/${response.payload}`;
      } else {
        this.fetchId(response.payload);
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/master/bank-branch');
  };

  onClickClearInstance = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      InstanceCode: '',
      InstanceName: '',
    });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,
      onClickClearInstance: this.onClickClearInstance,

      tabRef: (e) => (this.tabRef = e),
      formDetailRef: (e) => (this.formDetailRef = e),
    };

    return (
      <div>
        <BankBranchDetail
          {...props}
          state={this.state}
          resultEffective={this.state.resultEffective}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterBank,
  }),
  {
    addAlert,
    getMasterBankBranchffectiveDate,
    getBankBranchDetail,
    saveMasterBankBranch,
  }
)(BankBranchDetailContainer);
