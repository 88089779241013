import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_REPORT_OTHER_CRITERIA = 'EDIT_REPORT_OTHER_CRITERIA';
export const CLEAR_REPORT_OTHER_CRITERIA = 'CLEAR_REPORT_OTHER_CRITERIA';
export const UPDATE_SEARCH_RESULT_REPORT_OTHER = 'UPDATE_SEARCH_RESULT_REPORT_OTHER';

export const REQUEST_SEARCH_REPORT_OTHER = '@@request/SEARCH_REPORT_OTHER';
export const RECEIVE_SEARCH_REPORT_OTHER = '@@receive/SEARCH_REPORT_OTHER';
export const FAIL_SEARCH_REPORT_OTHER = '@@fail/SEARCH_REPORT_OTHER';

export const REQUEST_SUBMIT_REPORT_OTHER_RATE = '@@request/SUBMIT_REPORT_OTHER_RATE';
export const RECEIVE_SUBMIT_REPORT_OTHER_RATE =
  '@@receive-The record was successfully saved/SUBMIT_REPORT_OTHER_RATE';
export const FAIL_SUBMIT_REPORT_OTHER_RATE = '@@fail/SUBMIT_REPORT_OTHER_RATE';

export const saveReportOther = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/report/SaveReportOther`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SUBMIT_REPORT_OTHER_RATE,
      RECEIVE_SUBMIT_REPORT_OTHER_RATE,
      FAIL_SUBMIT_REPORT_OTHER_RATE,
    ],
  },
});

export const searchReportOther = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/GetReportOther', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_REPORT_OTHER, RECEIVE_SEARCH_REPORT_OTHER, FAIL_SEARCH_REPORT_OTHER],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_REPORT_OTHER,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_REPORT_OTHER_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_REPORT_OTHER_CRITERIA,
  payload: {
    name,
    value,
  },
});
