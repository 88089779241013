import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MARK_TO_MARKET_CRITERIA = 'EDIT_MARK_TO_MARKET_CRITERIA';
export const CLEAR_MARK_TO_MARKET_CRITERIA = 'CLEAR_MARK_TO_MARKET_CRITERIA';

export const UPDATE_SEARCH_RESULT_MARK_TO_MARKET = 'UPDATE_SEARCH_RESULT_MARK_TO_MARKET';

export const REQUEST_VIEW_IMPORT_EXCEL_MTM_FORWARD = '@@request/VIEW_IMPORT_EXCEL_MTM_FORWARD';
export const RECEIVE_VIEW_IMPORT_EXCEL_MTM_FORWARD = '@@receive/VIEW_IMPORT_EXCEL_MTM_FORWARD';
export const FAIL_VIEW_IMPORT_EXCEL_MTM_FORWARD = '@@fail/VIEW_IMPORT_EXCEL_MTM_FORWARD';

export const REQUEST_SEARCH_MARK_TO_MARKET = '@@request/SARCH_MARK_TO_MARKET';
export const RECEIVE_SEARCH_MARK_TO_MARKET = '@@receive/SEARCH_MARK_TO_MARKET';
export const FAIL_SEARCH_MARK_TO_MARKET = '@@fail/SEARCH_MARK_TO_MARKET';

export const REQUEST_FETCH_EMAIL_MTM_FORWARD = '@@request/FETCH_EMAIL_MTM_FORWARD';
export const RECEIVE_FETCH_EMAIL_MTM_FORWARD =
  '@@receive-The transaction was successfully fetched/FETCH_EMAIL_MTM_FORWARD';
export const FAIL_FETCH_EMAIL_MTM_FORWARD = '@@fail/FETCH_EMAIL_MTM_FORWARD';

export const REQUEST_FETCH_EMAIL_MTM_SWAP = '@@request/FETCH_EMAIL_MTM_SWAP';
export const RECEIVE_FETCH_EMAIL_MTM_SWAP =
  '@@receive-The transaction was successfully fetched/FETCH_EMAIL_MTM_SWAP';
export const FAIL_FETCH_EMAIL_MTM_SWAP = '@@fail/FETCH_EMAIL_MTM_SWAP';

export const REQUEST_DOWNLOAD_MTM_FILE = '@@request/DOWNLOAD_MTM_FILE';
export const RECEIVE_DOWNLOAD_MTM_FILE = '@@receive/DOWNLOAD_MTM_FILE';
export const FAIL_DOWNLOAD_MTM_FILE = '@@fail/DOWNLOAD_MTM_FILE';

export const REQUEST_SAVE_IMPORT_MTM_FORWARD = '@@request/SAVE_IMPORT_MTM_FORWARD';
export const RECEIVE_SAVE_IMPORT_MTM_FORWARD = '@@receive-Save Success/SAVE_IMPORT_MTM_FORWARD';
export const FAIL_SAVE_IMPORT_MTM_FORWARD = '@@fail/SAVE_IMPORT_MTM_FORWARD';

export const REQUEST_SAVE_MTM_MAPPING_CONTRACT = '@@request/SAVE_MTM_MAPPING_CONTRACT';
export const RECEIVE_SAVE_MTM_MAPPING_CONTRACT =
  '@@receive-The contract no. was successfully saved./SAVE_MTM_MAPPING_CONTRACT';
export const FAIL_SAVE_MTM_MAPPING_CONTRACT = '@@fail/SAVE_MTM_MAPPING_CONTRACT';

export const REQUEST_DOWNLOAD_EXCEL_MTM_FORWARD = '@@request/DOWNLOAD_EXCEL_MTM_FORWARD';
export const RECEIVE_DOWNLOAD_EXCEL_MTM_FORWARD = '@@receive/DOWNLOAD_EXCEL_MTM_FORWARD';
export const FAIL_DOWNLOAD_EXCEL_MTM_FORWARD = '@@fail/DOWNLOAD_EXCEL_MTM_FORWARD';

export const REQUEST_GEN_FLOW_MONTH_END = '@@request/GEN_FLOW_MONTH_END';
export const RECEIVE_GEN_FLOW_MONTH_END = '@@receive-Gen flow Success/GEN_FLOW_MONTH_END';
export const FAIL_GEN_FLOW_MONTH_END = '@@fail/GEN_FLOW_MONTH_END';

export const genFlowFXMonthEnd = () => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/GenFlowFXMonthEnd?is_daily=true&flow_action=MTM'),
    method: 'GET',
    types: [REQUEST_GEN_FLOW_MONTH_END, RECEIVE_GEN_FLOW_MONTH_END, FAIL_GEN_FLOW_MONTH_END],
  },
});

export const downloadMTMForwardExcel = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/ExportMTMForward`, criteria),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_EXCEL_MTM_FORWARD,
      {
        type: RECEIVE_DOWNLOAD_EXCEL_MTM_FORWARD,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_EXCEL_MTM_FORWARD,
    ],
  },
});

export const saveImportExcel = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/SaveUploadMTMForward'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_IMPORT_MTM_FORWARD,
      RECEIVE_SAVE_IMPORT_MTM_FORWARD,
      FAIL_SAVE_IMPORT_MTM_FORWARD,
    ],
  },
});

export const saveMTMMappingContract = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/SaveMtmMappingContract'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MTM_MAPPING_CONTRACT,
      RECEIVE_SAVE_MTM_MAPPING_CONTRACT,
      FAIL_SAVE_MTM_MAPPING_CONTRACT,
    ],
  },
});

export const importExcelMtm = (formData) => ({
  [RSAA]: {
    endpoint: endpoint('/importExcel/MTMForward'),
    method: 'POST',
    body: formData,
    types: [
      REQUEST_VIEW_IMPORT_EXCEL_MTM_FORWARD,
      RECEIVE_VIEW_IMPORT_EXCEL_MTM_FORWARD,
      FAIL_VIEW_IMPORT_EXCEL_MTM_FORWARD,
    ],
  },
});

export const downloadFileMtm = (filename) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/DownloadMtmExcelFile?filename=${filename}`),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_MTM_FILE,
      {
        type: RECEIVE_DOWNLOAD_MTM_FILE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_MTM_FILE,
    ],
  },
});

export const fetchEmail = () => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/FetchEmailMTMForward'),
    method: 'GET',
    types: [
      REQUEST_FETCH_EMAIL_MTM_FORWARD,
      RECEIVE_FETCH_EMAIL_MTM_FORWARD,
      FAIL_FETCH_EMAIL_MTM_FORWARD,
    ],
  },
});

export const fetchEmailSwap = () => ({
  [RSAA]: {
    endpoint: endpoint('/loan/FetchEmailMTMSwap'),
    method: 'GET',
    types: [REQUEST_FETCH_EMAIL_MTM_SWAP, RECEIVE_FETCH_EMAIL_MTM_SWAP, FAIL_FETCH_EMAIL_MTM_SWAP],
  },
});

export const searchMarkToMarket = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetMarkToMarket', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MARK_TO_MARKET,
      RECEIVE_SEARCH_MARK_TO_MARKET,
      FAIL_SEARCH_MARK_TO_MARKET,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MARK_TO_MARKET,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MARK_TO_MARKET_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MARK_TO_MARKET_CRITERIA,
  payload: {
    name,
    value,
  },
});
