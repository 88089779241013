const URI_DEV = 'http://localhost:1726/api';
// const URI_PROD = 'http://172.26.64.123:8889/api'
const URI_PROD = 'https://afms-service.scg.com/api';
// const URI_PROD = 'http://liverdev.online:88/api'
// const URI_PROD = 'http://10.100.13.22:88/api'

export const endpoint = (patch = '', query, noApiPath) => {
  let url = '';
  if (process.env.NODE_ENV === 'development') url = URI_DEV;
  else url = URI_PROD;
  if (noApiPath) url = url.substr(0, url.length - 3);

  if (patch) {
    if (patch[0] === '/') url += patch;
    else return (url = `${url}/${patch}`);
  }

  if (query) {
    url += '?';
    for (const key in query) {
      url += `${encodeURIComponent(key)}=${encodeURIComponent(
        query[key] === undefined || query[key] === null ? '' : query[key]
      )}`;
      url += '&';
    }
    url = url.slice(0, url.length - 1);
  }

  return url;
};

export const defaultImportExcelResult = {
  error: 0,
  hasError: false,
  rows: [],
  success: 0,
  total: 0,
};

export const jsonContentTypeHeader = {
  'Content-Type': 'application/json; charset=utf-8',
};
