import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_CREATE_EXPOSURE = '@@request/SEARCH_CREATE_EXPOSURE';
export const RECEIVE_SEARCH_CREATE_EXPOSURE = '@@receive/SEARCH_CREATE_EXPOSURE';
export const FAIL_SEARCH_CREATE_EXPOSURE = '@@fail/SEARCH_CREATE_EXPOSURE';

export const REQUEST_CREATE_EXPOSURE = '@@request/CREATE_EXPOSURE';
export const RECEIVE_CREATE_EXPOSURE =
  '@@receive-The transaction was successfully saved/CREATE_EXPOSURE';
export const FAIL_CREATE_EXPOSURE = '@@fail/CREATE_EXPOSURE';

export const REQUEST_CREATE_EXPOSURE_IMPORT_EXCEL = '@@request/CREATE_EXPOSURE_IMPORT_EXCEL';
export const RECEIVE_CREATE_EXPOSURE_IMPORT_EXCEL =
  '@@receive-The transaction was successfully imported/CREATE_EXPOSURE_IMPORT_EXCEL';
export const FAIL_CREATE_EXPOSURE_IMPORT_EXCEL = '@@fail/CREATE_EXPOSURE_IMPORT_EXCEL';

export const REQUEST_GET_EXPOSURE = '@@request/GET_EXPOSURE';
export const RECEIVE_GET_EXPOSURE = '@@receive/GET_EXPOSURE';
export const FAIL_GET_EXPOSURE = '@@fail/GET_EXPOSURE';

export const REQUEST_CANCEL_EXPOSURE = '@@request/CANCEL_EXPOSURE';
export const RECEIVE_CANCEL_EXPOSURE =
  '@@receive-The transaction was successfully change status/CANCEL_EXPOSURE';
export const FAIL_CANCEL_EXPOSURE = '@@fail/CANCEL_EXPOSURE';

export const REQUEST_ACTIVE_EXPOSURE = '@@request/ACTIVE_EXPOSURE';
export const RECEIVE_ACTIVE_EXPOSURE =
  '@@receive-The transaction was successfully change status/ACTIVE_EXPOSURE';
export const FAIL_ACTIVE_EXPOSURE = '@@fail/ACTIVE_EXPOSURE';

export const REQUEST_VIEW_IMPORTEXCEL_EXPOSURE = '@@request/VIEW_IMPORTEXCEL_EXPOSURE';
export const RECEIVE_VIEW_IMPORTEXCEL_EXPOSURE = '@@receive/VIEW_IMPORTEXCEL_EXPOSURE';
export const FAIL_VIEW_IMPORTEXCEL_EXPOSURE = '@@fail/VIEW_IMPORTEXCEL_EXPOSURE';

export const REQUEST_DOWNLOAD_EXPOSURE_ATTACH_FILE = '@@request/DOWNLOAD_EXPOSURE_ATTACH_FILE';
export const RECEIVE_DOWNLOAD_EXPOSURE_ATTACH_FILE = '@@receive/DOWNLOAD_EXPOSURE_ATTACH_FILE';
export const FAIL_DOWNLOAD_EXPOSURE_ATTACH_FILE = '@@fail/DOWNLOAD_EXPOSURE_ATTACH_FILE';

export const downloadAttachFileExposure = (filename) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/DownloadExposureAttachFile?filename=${filename}`),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_EXPOSURE_ATTACH_FILE,
      {
        type: RECEIVE_DOWNLOAD_EXPOSURE_ATTACH_FILE,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_DOWNLOAD_EXPOSURE_ATTACH_FILE,
    ],
  },
});

export const uploadImportExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/Exposure'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_VIEW_IMPORTEXCEL_EXPOSURE,
      RECEIVE_VIEW_IMPORTEXCEL_EXPOSURE,
      FAIL_VIEW_IMPORTEXCEL_EXPOSURE,
    ],
  },
});

export const activeExposure = (exposureNo, index) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/activeexposure/${exposureNo}`),
    method: 'POST',
    types: [
      REQUEST_ACTIVE_EXPOSURE,
      {
        type: RECEIVE_ACTIVE_EXPOSURE,
        payload: {
          index,
        },
      },
      FAIL_ACTIVE_EXPOSURE,
    ],
  },
});

export const cancelExposure = (exposureNo, index) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/cancelexposure/${exposureNo}`),
    method: 'POST',
    types: [
      REQUEST_CANCEL_EXPOSURE,
      {
        type: RECEIVE_CANCEL_EXPOSURE,
        payload: {
          index,
        },
      },
      FAIL_CANCEL_EXPOSURE,
    ],
  },
});

export const getExposure = (exposureNo) => ({
  [RSAA]: {
    endpoint: endpoint(`fxmanagement/get/${exposureNo}`),
    method: 'GET',
    types: [REQUEST_GET_EXPOSURE, RECEIVE_GET_EXPOSURE, FAIL_GET_EXPOSURE],
  },
});

export const searchCreateExposure = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_CREATE_EXPOSURE,
      RECEIVE_SEARCH_CREATE_EXPOSURE,
      FAIL_SEARCH_CREATE_EXPOSURE,
    ],
  },
});

export const createExposure = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/saveexposure'),
    method: 'POST',
    body: data,
    types: [REQUEST_CREATE_EXPOSURE, RECEIVE_CREATE_EXPOSURE, FAIL_CREATE_EXPOSURE],
  },
});

export const createExposureImportExcel = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/saveExposureImportExcel'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CREATE_EXPOSURE_IMPORT_EXCEL,
      RECEIVE_CREATE_EXPOSURE_IMPORT_EXCEL,
      FAIL_CREATE_EXPOSURE_IMPORT_EXCEL,
    ],
  },
});
