import React, {
  useRef,
  useState,
  useReducer,
  forwardRef,
  useContext,
  useCallback,
  createContext,
  useImperativeHandle,
} from 'react';

import { store } from '../../../Root';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Interest from '../../../common/Interest';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import { StateContext } from './CreateContractLoan';
import { addAlert } from '../../../reducers/layout/action';
import { setAmount, getPermission } from '../../../common/helpper';

const permission = getPermission('Funding&Investment', 'Create Contract Loan');

const InterestContext = createContext(null);
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const reducerInterest = (state, action) => {
  switch (action.type) {
    case 'ADD_INTEREST':
      return [...state, { ...action.payload }];
    case 'EDIT_INTEREST':
      return state.map((m, i) => {
        if (i === action.index)
          return {
            ...action.payload,
          };
        return m;
      });
    case 'REMOVE_INTEREST':
      return state.filter((f, i) => i !== action.index);
    case 'SET':
      return [...action.payload];
    case 'RESET':
      return [];
    default:
      return state;
  }
};
const { moment } = window;

export default forwardRef(({ action, editIndex }, ref) => {
  const { state, dispatch } = useContext(StateContext);
  const modalRef = useRef(null);
  const [interestState, dispatchInterest] = useReducer(reducerInterest, []);
  const interestRef = useRef(null);
  const [editIdx, setEditIdx] = useState(null);
  const [tempId, setTempId] = useState(null);
  const [id, setId] = useState(null);
  // for repayment
  const [repaymentAmount, setRepaymentAmount] = useState('');
  const [repaymentDate, setRepaymentDate] = useState('');
  const onClickOpenInterestModalForAdd = useCallback(() => {
    if (!validateRepayment()) return;
    interestRef.current.resetData();
    interestRef.current.open();
  });
  const onClickSaveInterest = useCallback(() => {
    if (!validateRepayment()) return;
    const newInterestData = interestState.map((m) => ({
      ...m,
      ContractDateStr: repaymentDate,
      Amount: repaymentAmount,
    }));
    const saveData = {
      RepaymentAmount: repaymentAmount,
      RepaymentDateStr: repaymentDate,
      InterestStructures: newInterestData,
      TempId: tempId,
      Id: id,
    };

    saveData.InterestAmount = newInterestData.reduce(
      (prev, m) => prev + Number(m.InterestAmount) || 0,
      0
    );

    if (editIdx === null || editIdx === undefined)
      dispatch({
        type: 'ADD_REPAYMENT_STRCUTURE',
        payload: saveData,
      });
    else {
      dispatch({
        type: 'EDIT_REPAYMENT_STRUCTURE',
        repayIdx: editIdx,
        payload: saveData,
      });

      const totalDrawdown = state.DrawdownList.reduce(
        (prev, m) => prev + (Number(m.Amount) || 0),
        0
      );

      const repaymentStructures = (state.RepaymentStructures || [])
        .map((e, i) => {
          if (i === editIdx) return { ...saveData };
          return e;
        })
        .map((m, i, self) => {
          m.Ordering = i + 1;
          m.InterestStructures = (m.InterestStructures || []).map((interest, idx) => {
            const passedRepayment = self.reduce((prev, r, ri) => {
              if (
                moment(interest.ValidFromStr, 'DD/MM/YYYY').isSameOrAfter(
                  moment(r.RepaymentDateStr, 'DD/MM/YYYY')
                )
              )
                return prev + (Number(r.RepaymentAmount) || 0);
              return prev;
            }, 0);
            const newData = {
              ...interest,
              Outstanding: totalDrawdown - passedRepayment,
            };
            newData.InterestAmount = calInterestAmount(newData);
            return newData;
          });
          m.InterestAmount = m.InterestStructures.reduce(
            (prev, cur) => prev + (Number(cur.InterestAmount) || 0),
            0
          );
          return m;
        })
        .sort((a, b) => {
          const mA = moment(a.RepaymentDateStr, 'DD/MM/YYYY');
          const mB = moment(b.RepaymentDateStr, 'DD/MM/YYYY');
          return mA.isAfter(mB) ? 1 : mA.isBefore(mB) ? -1 : 0;
        });

      dispatch({
        type: 'SET_REPAYMENT_STRUCTURE',
        payload: repaymentStructures,
      });
    }

    modalRef.current.close();
  }, [interestState, state.DrawdownList, repaymentAmount, repaymentDate, editIdx, tempId]);

  useImperativeHandle(
    ref,
    () => ({
      open: (editIndex) => {
        setEditIdx(editIndex);
        if (editIndex == null || editIndex === undefined) {
          setId(null);
          setTempId(null);
        }
        modalRef.current.open();
      },
      setData: (data) => {
        setRepaymentAmount(data.RepaymentAmount);
        setRepaymentDate(data.RepaymentDateStr);
        setTempId(data.TempId);
        setId(data.Id);
        interestRef.current.resetData();
        dispatchInterest({
          type: 'SET',
          payload: data.InterestStructures,
        });
      },
      resetData: () => {
        setRepaymentAmount('');
        setRepaymentDate('');
        setId(null);
        setTempId(null);
        interestRef.current.resetData();
        dispatchInterest({ type: 'RESET' });
      },
    }),
    []
  );

  return (
    <InterestContext.Provider
      value={{
        interestState,
        dispatchInterest,
        repaymentDate,
        repaymentAmount,
      }}
    >
      <Modal textHeader={action} size="modal-xl" ref={modalRef} bgHeader="bg-primary">
        {renderRepaymentCard()}
        {permission ? (
          <Col2 col="col-sm-12">
            <Button
              txt="Add Interest"
              className="btn-info"
              icon="plus"
              onClick={onClickOpenInterestModalForAdd}
            />
          </Col2>
        ) : null}
        <InterestStructureTable interestRef={interestRef} />
        {permission ? (
          <Col2 col="col-sm-12 text-center">
            <Button txt="Save" className="btn-success" icon="save" onClick={onClickSaveInterest} />
          </Col2>
        ) : null}
      </Modal>
      <InterestStructure ref={interestRef} />
    </InterestContext.Provider>
  );
  function calInterestAmount(data) {
    const {
      InterestRate,
      Outstanding,
      ValidFromStr,
      ValidToStr,
      IsManualInclude,
      CalculateMethod,
    } = data;

    let daysDiff = moment(ValidToStr, 'DD/MM/YYYY').diff(
      moment(ValidFromStr, 'DD/MM/YYYY'),
      'days'
    );
    if (IsManualInclude) daysDiff += 1;

    let totalDayinYear = 0;
    switch (CalculateMethod) {
      case 'Act/360':
        totalDayinYear = 360;
        break;
      case 'Act/366':
        totalDayinYear = 366;
        break;
      case 'Act/365':
        totalDayinYear = 365;
        break;
      default:
        totalDayinYear = isLeapYear(moment(ValidToStr, 'DD/MM/YYYY').year()) ? 366 : 365;
    }
    return (((Number(InterestRate) / 100) * Number(Outstanding)) / totalDayinYear) * daysDiff;
  }

  function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }
  function validateRepayment() {
    if (!repaymentDate) {
      store.dispatch(
        addAlert({
          type: 'warning',
          title: 'Error',
          body: 'Please specific Repayment Date',
        })
      );
      return false;
    }
    if (!repaymentAmount) {
      store.dispatch(
        addAlert({
          type: 'warning',
          title: 'Error',
          body: 'Please specific Repayment Amount',
        })
      );
      return false;
    }

    let error = '';
    interestState.find((m) => {
      if (!m.ValidFromStr || !m.ValidToStr) {
        error = 'Valid From , Valid To must specific.';
        return true;
      }
      if (moment(m.ValidToStr, 'DD/MM/YYYY').isAfter(moment(repaymentDate, 'DD/MM/YYYY'))) {
        error = 'Valid from muse before or same as Repayment Date';
        return true;
      }
      return false;
    });
    if (error) {
      store.dispatch(
        addAlert({
          type: 'warning',
          title: 'Error',
          body: error,
        })
      );
      return false;
    }

    return true;
  }

  function renderRepaymentCard() {
    return (
      <Card textHeader="Repayment" bgHader="bg-info" cardActions={['toggler']}>
        <div className="row">
          <div className="col-md-4">
            <FormGroup2 text="Repayment Amount" required>
              <InputMask
                className="form-control"
                format="currency"
                onChange={(e) => setRepaymentAmount(e.target.value)}
                option={{
                  prefix: '',
                  digits: 3,
                }}
                type="text"
                required
                disabled={!permission}
                value={repaymentAmount}
              />
            </FormGroup2>
          </div>
          <div className="col-md-4">
            <FormGroup2 text="Repayment Date" required>
              <DatePicker
                value={repaymentDate}
                onChange={(e) => setRepaymentDate(e.target.value)}
                disabled={!permission}
                required
              />
            </FormGroup2>
          </div>
        </div>
      </Card>
    );
  }
});

const InterestStructure = forwardRef((props, ref) => {
  const { dispatchInterest, repaymentDate, repaymentAmount } = useContext(InterestContext);
  const { state } = useContext(StateContext);
  const modalRef = useRef(null);
  const formValid = useRef(null);
  const interestRef = useRef(null);
  const [validFromStr, setValidFromStr] = useState('');
  const [validToStr, setValidToStr] = useState('');
  const [editIdx, setEditIdx] = useState(null);
  useImperativeHandle(
    ref,
    () => ({
      open: (idx) => {
        setEditIdx(idx === undefined ? null : idx);
        modalRef.current.open();
      },
      setData: (data) => {
        interestRef.current.setData(data);
        setValidFromStr(data.ValidFromStr || '');
        setValidToStr(data.ValidToStr || '');
      },
      resetData: () => {
        interestRef.current.resetData();
        setValidFromStr('');
        setValidToStr('');
      },
    }),
    []
  );

  const onClickSaveInterest = useCallback(() => {
    const totalDrawdown = state.DrawdownList.reduce((prev, m) => prev + (Number(m.Amount) || 0), 0);
    const repaymentPassed = [
      ...state.RepaymentStructures,
      {
        RepaymentDateStr: repaymentDate,
        RepaymentAmount: repaymentAmount,
      },
    ].reduce((prev, m) => {
      if (
        moment(validFromStr, 'DD/MM/YYYY').isSameOrAfter(moment(m.RepaymentDateStr, 'DD/MM/YYYY'))
      )
        return prev + (Number(m.RepaymentAmount) || 0);
      return prev;
    }, 0);
    // cal outstanding
    const saveData = {
      ...interestRef.current.state,
      ValidFromStr: validFromStr,
      ValidToStr: validToStr,
      ContractDateStr: repaymentDate,
      Amount: repaymentAmount,
      Outstanding: totalDrawdown - repaymentPassed,
      StructureType: 'Repayment',
    };
    if (!validateInterest(saveData)) return;
    saveData.InterestAmount = calInterestAmount(saveData);
    if (editIdx !== null)
      dispatchInterest({
        type: 'EDIT_INTEREST',
        index: editIdx,
        payload: saveData,
      });
    else
      dispatchInterest({
        type: 'ADD_INTEREST',
        payload: saveData,
      });

    modalRef.current.close();
  }, [editIdx, validFromStr, validToStr, repaymentDate, repaymentAmount]);

  return (
    <Modal ref={modalRef} textHeader="Interest Structure" bgHeader="bg-info" size="modal-lg">
      <Interest ref={interestRef} />
      <Card textHeader="Repayment Structure" bgHeader="bg-default" cardActions={['toggler']}>
        <form ref={formValid}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Valid From" required>
                <DatePicker
                  onChange={(e) => setValidFromStr(e.target.value)}
                  option={{
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                  required
                  value={validFromStr}
                />
              </FormGroup2>
              <FormGroup2 text="Valid To" required>
                <DatePicker
                  onChange={(e) => setValidToStr(e.target.value)}
                  option={{
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                  required
                  value={validToStr}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>
      <div className="row">
        <div className="col-sm-12">
          <Button txt="Save" icon="save" className="btn-success" onClick={onClickSaveInterest} />
        </div>
      </div>
    </Modal>
  );

  function validateInterest(data) {
    let isvalid = true;
    const { ValidFromStr, ValidToStr, InterestRate } = data;
    const forms = [formValid.current, interestRef.current.formRef.current];
    for (const i in forms) {
      const form = forms[i];
      if (!form.checkValidity()) {
        form.reportValidity();
        return false;
      }
    }

    let error = '';
    if (!InterestRate) error = 'Interest Rate must more than Zero.';
    if (moment(ValidToStr, 'DD/MM/YYYY').isAfter(moment(repaymentDate, 'DD/MM/YYYY')))
      error = 'Valid To muse less than Repayment Date.';
    const daysDiff = moment(ValidToStr, 'DD/MM/YYYY').diff(
      moment(ValidFromStr, 'DD/MM/YYYY'),
      'days'
    );
    if (daysDiff < 0) error = 'ValidFrom must less or equal from ValidTo.';

    if (error) {
      store.dispatch(
        addAlert({
          type: 'error',
          title: 'Error',
          body: error,
        })
      );
      isvalid = false;
    }

    return isvalid;
  }

  function calInterestAmount(data) {
    const {
      InterestRate,
      Outstanding,
      ValidFromStr,
      ValidToStr,
      IsManualInclude,
      CalculateMethod,
    } = data;

    let daysDiff = moment(ValidToStr, 'DD/MM/YYYY').diff(
      moment(ValidFromStr, 'DD/MM/YYYY'),
      'days'
    );
    if (IsManualInclude) daysDiff += 1;
    let totalDayinYear = 0;
    switch (CalculateMethod) {
      case 'Act/360':
        totalDayinYear = 360;
        break;
      case 'Act/366':
        totalDayinYear = 366;
        break;
      case 'Act/365':
        totalDayinYear = 365;
        break;
      default:
        totalDayinYear = isLeapYear(moment(ValidToStr, 'DD/MM/YYYY').year()) ? 366 : 365;
    }

    return (((Number(InterestRate) / 100) * Number(Outstanding)) / totalDayinYear) * daysDiff;
  }

  function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }
});

function InterestStructureTable({ interestRef }) {
  const { interestState, dispatchInterest } = useContext(InterestContext);
  const onClickRemoveInterest = useCallback((index) => {
    dispatchInterest({
      type: 'REMOVE_INTEREST',
      index,
    });
  });
  const onClickEditInterest = useCallback(
    (index) => {
      const selectedData = interestState[index];
      interestRef.current.setData(selectedData);
      interestRef.current.open(index);
    },
    [interestState]
  );
  return (
    <div className="table-responsive">
      <table className="table table-bordered table-nowrap">
        <thead>
          <tr>
            <th>Remove</th>
            <th>Edit</th>
            <th className="th-white">Valid From</th>
            <th className="th-white">Valid To</th>
            <th className="th-white">Ref. Interest Rate</th>
            <th className="th-white">Margin</th>
            <th className="th-white">Interest</th>
            <th className="th-white">Withholding tax</th>
            <th className="th-white">
              Interest Amount
              <br />
              pay to Bank
            </th>
            <th className="th-white">Cal. Method</th>
          </tr>
        </thead>
        <tbody>
          {interestState.length === 0 ? (
            <tr>
              <td align="center" colSpan="10">
                No data.
              </td>
            </tr>
          ) : null}
          {interestState.map((m, i) => (
            <tr key={i}>
              <td align="center">
                {permission ? (
                  <button
                    onClick={() => {
                      onClickRemoveInterest(i);
                    }}
                    type="button"
                    className="btn btn-sm btn-warning"
                  >
                    <i className="icon icon-close icon-fw icon-lg" />
                  </button>
                ) : null}
              </td>
              <td align="center">
                {permission ? (
                  <button
                    onClick={() => {
                      onClickEditInterest(i);
                    }}
                    type="button"
                    className="btn btn-sm btn-info"
                  >
                    <i className="icon icon-edit icon-fw icon-lg" />
                  </button>
                ) : null}
              </td>
              <td align="center">{m.ValidFromStr}</td>
              <td align="center">{m.ValidToStr}</td>
              <td align="right">{setAmount(m.RefInterestRate, 5)}%</td>
              <td align="right">
                {!m.Margin ? '' : m.Margin >= 0 ? '+' : '-'}
                {`${setAmount(Math.abs(m.Margin), 5)}%`}
              </td>
              <td align="right">{setAmount(m.InterestRate, 5)}%</td>
              <td align="right">{setAmount(m.WithholdingTax, 5)}%</td>
              <td align="right">{setAmount(m.InterestAmount, 3)}</td>
              <td align="center">{m.CalculateMethod || ''}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
