import { connect } from 'react-redux';
import React, { Component } from 'react';

import FxConfirmationDetail from './FxConfirmationDetail';
import { addAlert } from '../../../reducers/layout/action';
import { getCurrencyDigit } from '../../../reducers/master/action';
import { getFxMatching, saveFxMatching } from '../../../reducers/fxConfirmation/action';
import { toFixed, toLower, toUpperKey, compareDate, checkWeekend } from '../../../common/helpper';

export const DATATABLE_ID = 'table-result-forward-detail-result';

const init = {
  TransactionNo: '',
  FinanceGroupId: '',
  FinanceGroupName: '',
  BusinessUnitName: '',
  CompanyCode: '',
  CompanyName: '',
  BankCode: '',
  ProductType: '',
  ForwardType: '',
  TransactionType: '',

  BusinessPartnerCode: '',
  ContractNo: '',
  ContractDateStr: '',
  StartDateStr: '',
  EndDateStr: '',
  Amount: '',
  BuyCurrency: '',
  SellCurrency: '',
  SpotRate: '',
  BaseRate: '',
  SwapRateBase: '',
  ForwardRate: '',
  SwapRate: '',
  Gvc: '',
  Portfolio: '',
  Assignment: '',
  Characteristic: '',
  InternalReference: '',
  RequestType: '',
  ContractDetail1: '',
  ContractDetail2: '',
  ContractDetail3: '',
  ContractDetail4: '',
  Dealer: '',
  Department: '',
  StatusFile: '',
  Note1: '',
  Note2: '',

  mode: 'create',
  isAfterEndDate: false,

  inputMaskAmount: {
    prefix: '',
    digits: 3,
  },
  IdListTable: [],
};

class FxConfirmationDetailContainer extends Component {
  state = {
    ...init,
    createHistory: [],
    historyDetail: [],
    businessAreaList: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;
    if (!transactionNo || transactionNo === 'create') return;
    this.fetchTransaction(transactionNo);
  }

  componentDidUpdate(prevProps, prevState) {
    const { SellCurrency, Amount } = this.state;

    if (prevState.SellCurrency !== SellCurrency) {
      this.props.getCurrencyDigit({ criteria: SellCurrency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          Amount: Amount
            ? toFixed(Number((Amount.toString() || '').replace(/,/g, '') || 0), digit)
            : '',
        });
      });
    }
  }

  fetchTransaction(transactionNo) {
    this.props.getFxMatching(transactionNo).then((response) => {
      if (!response.error) {
        this.setState({
          ...init,
          ...toUpperKey(response.payload),
          OldStartDateStr: response.payload.startDateStr,
          IdListTable: [],
          mode: 'edit',
        });
      }
    });
  }

  onChangeInputData = (e) => {
    const { name, value, label, datas } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'ForwardType') {
        if (value === '1 Fixed Forward') {
          stateToUpdate.StartDateStr = state.EndDateStr;
        } else {
          stateToUpdate.StartDateStr = state.OldStartDateStr;
        }
        const forwardRate = this.calForwardRate(state.SwapRate || 0, state.SpotRate || 0);
        stateToUpdate.ForwardRate = forwardRate;
        stateToUpdate.BaseRate = value === '3 Pro Rata Forward' ? state.SpotRate || 0 : forwardRate;
        stateToUpdate.SwapRateBase = value === '3 Pro Rata Forward' ? 0 : state.SwapRate || 0;
      } else if (name === 'SpotRate') {
        const forwardRate = this.calForwardRate(state.SwapRate || 0, value);
        stateToUpdate.ForwardRate = forwardRate;
        stateToUpdate.BaseRate = state.ForwardType === '3 Pro Rata Forward' ? value : forwardRate;
        stateToUpdate.SwapRateBase =
          state.ForwardType === '3 Pro Rata Forward' ? state.SwapRate || 0 : 0;
      } else if (name === 'SwapRate') {
        const forwardRate = this.calForwardRate(value, state.SpotRate || 0);
        stateToUpdate.ForwardRate = forwardRate;
        stateToUpdate.BaseRate =
          state.ForwardType === '3 Pro Rata Forward' ? state.SpotRate || 0 : forwardRate;
        stateToUpdate.SwapRateBase = state.ForwardType === '3 Pro Rata Forward' ? 0 : value;
      } else if (name === 'ContractDateStr') {
        if (value === '') {
          stateToUpdate.ContractDateStr = '';
          stateToUpdate.ContractDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.StartDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Contract Date must be less than or equal Start Date.',
            });
            return {
              [name]: state.ContractDateStr,
            };
          }
        }
      } else if (name === 'StartDateStr') {
        if (value === '') {
          stateToUpdate.StartDateStr = '';
          stateToUpdate.StartDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.StartDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Start Date must be more than or equal Contract Date.',
            });
            return {
              [name]: state.StartDateStr,
            };
          }
          if (compareDate(mergeState.StartDateStr, mergeState.EndDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Start Date must be less than or equal End Date.',
            });
            return {
              [name]: state.StartDateStr,
            };
          }
        }
      } else if (name === 'EndDateStr') {
        if (value === '') {
          stateToUpdate.EndDateStr = '';
          stateToUpdate.EndDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.StartDateStr, mergeState.EndDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'End Date must be more than or equal Start Date.',
            });
            return {
              [name]: state.EndDateStr,
            };
          }
          if (compareDate(mergeState.ContractDateStr, mergeState.EndDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'End Date must be more than or equal Contract Date.',
            });
            return {
              [name]: state.EndDateStr,
            };
          }
        }
      } else if (name === 'BuyCurrency' || name === 'SellCurrency') {
        const mergeState = {
          ...state,
          ...stateToUpdate,
        };

        if (
          mergeState.BuyCurrency &&
          mergeState.SellCurrency &&
          mergeState.BuyCurrency === mergeState.SellCurrency
        ) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Buy Currency and Sell Currency must not same Currency.',
          });
          return {
            [name]: '',
          };
        }
      }
      return stateToUpdate;
    });
  };

  calForwardRate = (swap, spot) =>
    parseFloat((spot.toString() || '').replace(/,/g, '')) +
    parseFloat((swap.toString() || '').replace(/,/g, ''));

  calBaseRate = (forwardType, spot) => {
    if (forwardType === 'Pro Rata') return spot;
    return '';
  };

  onSubmit = (e) => {
    e.preventDefault();

    let textCheckDay = '';
    if (checkWeekend(this.state.ContractDateStr))
      textCheckDay = 'Contract Date can not be Weekend.';
    else if (checkWeekend(this.state.StartDateStr)) textCheckDay = 'Start Date can not be Weekend.';
    else if (checkWeekend(this.state.EndDateStr)) textCheckDay = 'End Date can not be Weekend.';

    // if (textCheckDay !== '') {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: textCheckDay,
    //     })
    //     return
    // }

    if (!this.state.Amount || this.state.Amount <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Amount must more than zero',
      });
      return;
    }

    if (toLower(this.state.status) === 'wait for quote rate') {
      if (!this.state.SpotRate || parseFloat(this.state.SpotRate || 0) === 0) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Spot Rate must be not equal zero',
        });
        return;
      }

      if (!this.state.ForwardRate || parseFloat(this.state.ForwardRate || 0) === 0) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Forward Rate must be not equal zero',
        });
        return;
      }

      if (this.state.ForwardType === 'Pro Rata') {
        if (!this.state.BaseRate || parseFloat(this.state.BaseRate || 0) === 0) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Base Rate must be not equal zero',
          });
          return;
        }
      }
    }

    this.props
      .saveFxMatching({
        ...this.state,
      })
      .then((response) => {
        if (response.error || !response.payload) return;

        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The transaction was successfully saved',
          buttons: [
            <button
              className="btn btn-success"
              onClick={this.onClickBackToPage}
              data-dismiss="modal"
            >
              Continue
            </button>,
          ],
        });

        if (response.payload !== this.props.routeProp.match.params.id) {
          window.location.href = `/fx-management/fx-confirmation-detail/${response.payload}`;
        } else {
          this.fetchTransaction(response.payload);
        }
      });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/fx-management/fx-confirmation');
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,
    };

    return <FxConfirmationDetail {...props} state={this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.fxConfirmation,
  }),
  {
    addAlert,
    getFxMatching,
    saveFxMatching,
    getCurrencyDigit,
  }
)(FxConfirmationDetailContainer);
