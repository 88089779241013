import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import {
  MASTER_BUSINESS_AREA,
  // GET_FACILITY
} from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ formRef, state, onChangeInputData, onSubmit }) => {
  const noEdit = state.mode === 'edit';
  const disabled = false;
  const required = true;
  return (
    <form onSubmit={onSubmit} ref={formRef} name="administrator">
      <Card textHeader="Position Assignment" cardActions={['toggler']}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Business Area" required={state.BusinessArea}>
              <MasterDropdown
                masterType={MASTER_BUSINESS_AREA}
                noValidateOption={noEdit}
                onChange={onChangeInputData}
                companyCode={state.CompanyCode}
                notMultiple
                isChoose={!state.BusinessArea}
                required={state.BusinessArea}
                value={state.BusinessArea}
                name="BusinessArea"
                disabled={disabled}
              />
            </FormGroup2>

            <FormGroup2 text="Gen Valn Class" required={false}>
              {/* <input
                                className="form-control"
                                onChange={onChangeInputData}
                                required={required}
                                disabled={true}
                                value={state.GenValnClass}
                                name="GenValnClass"
                            /> */}
              <MasterDropdownUI
                value={state.GenValnClass}
                name="GenValnClass"
                isChoose
                notMultipleSelect2
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required={required}
                disabled={disabled}
                options={['<= 3 Months', '> 3 <= 12 Months', '> 12 Months', 'ON CALL']}
              />
            </FormGroup2>
          </Col2>
        </Col2>
      </Card>
    </form>
  );
};
