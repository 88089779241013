import {
  RECEIVE_SEARCH_SECURITIES_JOURNAL,
  UPDATE_SEARCH_RESULT_SECURITIES_JOURNAL,
} from './action';

const init = {
  searchResult: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_SEARCH_SECURITIES_JOURNAL:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    case UPDATE_SEARCH_RESULT_SECURITIES_JOURNAL:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    default:
      return state;
  }
};
