import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import { toNumber, getPermission } from '../../common/helpper';
import {
  DATATABLE_ID,
  DATATABLE_ID_PERCENT,
  DATATABLE_ID_ROUNDING,
  DATATABLE_ID_DURATION,
} from './MaintainGroupingContainer';

const permission = getPermission('FX Management', 'E-Form Grouping');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  result,
  tabName,
  dataTableRef,
  onClickOpenModal,
  onClickOpenModalEdit,
  onClickOpenModalDelete,
}) => (
  <>
    {tabName === 'grouping' ? (
      <Card textHeader="Grouping" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div className="form-group">
              <Button
                txt="Create Grouping"
                icon="plus"
                className="btn-warning"
                onClick={(e) => onClickOpenModal(e, 'grouping')}
              />
            </div>
          </Col2>
        ) : null}
        <Col2 col={colX[2]}>
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID}
            option={optGrouping}
            value={result}
            onClickContext={[
              {
                context: 'button.edit-item',
                onClick: (e) => onClickOpenModalEdit(e, 'grouping'),
              },
              {
                context: 'button.delete-item',
                onClick: (e) => onClickOpenModalDelete(e, 'grouping'),
              },
            ]}
          >
            <thead>
              <tr>
                <th className="th-white">Edit</th>
                <th className="th-white">Delete</th>
                <th className="th-white">Group ID</th>
                <th className="th-white">Request Type</th>
                <th className="th-white">Forward Type</th>
                <th className="th-white">Department</th>
              </tr>
            </thead>
          </Table>
        </Col2>
      </Card>
    ) : tabName === 'percent' ? (
      <Card textHeader="Percentage" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div className="form-group">
              <Button
                txt="Create Percentage"
                icon="plus"
                className="btn-warning"
                onClick={(e) => onClickOpenModal(e, 'percent')}
              />
            </div>
          </Col2>
        ) : null}
        <Col2 col={colX[2]}>
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID_PERCENT}
            option={optPercent}
            value={result}
            onClickContext={[
              {
                context: 'button.edit-item',
                onClick: (e) => onClickOpenModalEdit(e, 'percent'),
              },
              {
                context: 'button.delete-item',
                onClick: (e) => onClickOpenModalDelete(e, 'percent'),
              },
            ]}
          >
            <thead>
              <tr>
                <th className="th-white">Edit</th>
                <th className="th-white">Delete</th>
                <th className="th-white">Request Type</th>
                <th className="th-white">Forward Type</th>
                <th className="th-white">Amount From</th>
                <th className="th-white">Percentage (%)</th>
              </tr>
            </thead>
          </Table>
          {/* <div className="table-responsive" style={{maxHeight: '500px'}}>
                                <form>
                                    <table className="table table-bordered table-nowrap">
                                        <thead>
                                            <tr>
                                                <th className="th-white">Action</th>
                                                <th className="th-white">Request Type</th>
                                                <th className="th-white">Forward Type</th>
                                                <th className="th-white">Amount From</th>
                                                <th className="th-white">Percentage (%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !(result || []).length ? (
                                                    <tr>
                                                        <td colSpan="5" align="center">No data available in table</td>
                                                    </tr>
                                                ) : null
                                            }
                                            {
                                                result.map((m, i) => (
                                                    <tr key={i}>
                                                        <td className="text-center">
                                                            <button className="btn btn-icon btn-primary" onClick={e => onClickOpenModalDelete(e, i)}>
                                                                <span className="icon icon-trash-o sq-24"></span>
                                                            </button>
                                                        </td>
                                                        <td className="text-center">{m.requestType}</td>
                                                        <td className="text-center">{m.forwardType}</td>
                                                        <td className="text-right">
                                                            <InputMask className="form-control" 
                                                                onChange={e => onChangeInputTable(e, i)} 
                                                                format='currency'
                                                                option={{
                                                                    prefix: '',
                                                                    digits: 2
                                                                }}
                                                                value={m.amount}
                                                                name="amount" />
                                                        </td>
                                                        <td className="text-right">
                                                            <InputMask className="form-control" 
                                                                onChange={e => onChangeInputTable(e, i)} 
                                                                format='currency'
                                                                option={{
                                                                    prefix: '',
                                                                    digits: 2
                                                                }}
                                                                value={m.percentage}
                                                                name="percentage" />
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </form>
                            </div> */}
        </Col2>
      </Card>
    ) : tabName === 'rounding' ? (
      <Card textHeader="Rounding" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div className="form-group">
              <Button
                txt="Create Rounding"
                icon="plus"
                className="btn-warning"
                onClick={(e) => onClickOpenModal(e, 'rounding')}
              />
            </div>
          </Col2>
        ) : null}
        <Col2 col={colX[2]}>
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID_ROUNDING}
            option={optRounding}
            value={result}
            onClickContext={[
              {
                context: 'button.edit-item',
                onClick: (e) => onClickOpenModalEdit(e, 'rounding'),
              },
              {
                context: 'button.delete-item',
                onClick: (e) => onClickOpenModalDelete(e, 'rounding'),
              },
            ]}
          >
            <thead>
              <tr>
                <th className="th-white">Edit</th>
                <th className="th-white">Delete</th>
                <th className="th-white">Forward Currency</th>
                <th className="th-white">Request Type</th>
                <th className="th-white">Forward Type</th>
                <th className="th-white">Rounding</th>
              </tr>
            </thead>
          </Table>

          {/* <div className="table-responsive" style={{maxHeight: '500px'}}>
                                <form>
                                    <table className="table table-bordered table-nowrap">
                                        <thead>
                                            <tr>
                                                <th className="th-white">Action</th>
                                                <th className="th-white">Forward Currency</th>
                                                <th className="th-white">Request Type</th>
                                                <th className="th-white">Forward Type</th>
                                                <th className="th-white">Rounding</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !(result || []).length ? (
                                                    <tr>
                                                        <td colSpan="5" align="center">No data available in table</td>
                                                    </tr>
                                                ) : null
                                            }
                                            {
                                                result.map((m, i) => (
                                                    <tr key={i}>
                                                        <td className="text-center">
                                                            <button className="btn btn-icon btn-primary" onClick={e => onClickOpenModalDelete(e, i)}>
                                                                <span className="icon icon-trash-o sq-24"></span>
                                                            </button>
                                                        </td>
                                                        <td className="text-center">{m.forwardCurrency}</td>
                                                        <td className="text-center">{m.requestType}</td>
                                                        <td className="text-center">{m.forwardType}</td>
                                                        <td className="text-right">
                                                            <select tabIndex="-1" className="form-control" 
                                                                value={m.rounding}
                                                                onChange={e => onChangeInputTable(e, i)}
                                                                name="rounding"
                                                            >
                                                                <option value="10">10</option>
                                                                <option value="100">100</option>
                                                                <option value="1,000">1,000</option>
                                                                <option value="10,000">10,000</option>
                                                                <option value="100,000">100,000</option>
                                                            </select>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </form>
                            </div> */}
        </Col2>
      </Card>
    ) : tabName === 'duration' ? (
      <Card textHeader="Forward Duration" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div className="form-group">
              <Button
                txt="Create Forward Duration"
                icon="plus"
                className="btn-warning"
                onClick={(e) => onClickOpenModal(e, 'duration')}
              />
            </div>
          </Col2>
        ) : null}
        <Col2 col={colX[2]}>
          <Table
            ref={dataTableRef}
            className="table table-bordered table-nowrap dataTable"
            id={DATATABLE_ID_DURATION}
            option={optDuraiton}
            value={result}
            onClickContext={[
              {
                context: 'button.edit-item',
                onClick: (e) => onClickOpenModalEdit(e, 'duration'),
              },
              {
                context: 'button.delete-item',
                onClick: (e) => onClickOpenModalDelete(e, 'duration'),
              },
            ]}
          >
            <thead>
              <tr>
                <th className="th-white">Edit</th>
                <th className="th-white">Delete</th>
                <th className="th-white">Forward Currency</th>
                <th className="th-white">Company</th>
                <th className="th-white">Request Type</th>
                <th className="th-white">Forward Type</th>
                <th className="th-white">Forward Duration</th>
                <th className="th-white">Start Term</th>
                <th className="th-white">Start Shift</th>
                <th className="th-white">End Shift</th>
                <th className="th-white">Fac. Cal.</th>
              </tr>
            </thead>
          </Table>

          {/* <div className="table-responsive" style={{maxHeight: '500px'}}>
                                <form>
                                    <table className="table table-bordered table-nowrap">
                                        <thead>
                                            <tr>
                                                <th className="th-white">Action</th>
                                                <th className="th-white">Forward Currency</th>
                                                <th className="th-white">Request Type</th>
                                                <th className="th-white">Forward Type</th>
                                                <th className="th-white">Forward Duration</th>
                                                <th className="th-white">Start Term</th>
                                                <th className="th-white">Start Shift</th>
                                                <th className="th-white">End Shift</th>
                                                <th className="th-white">Fac. Cal.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !(result || []).length ? (
                                                    <tr>
                                                        <td colSpan="9" align="center">No data available in table</td>
                                                    </tr>
                                                ) : null
                                            }
                                            {
                                                result.map((m, i) => (
                                                    <tr key={i}>
                                                        <td className="text-center">
                                                            <button className="btn btn-icon btn-primary" onClick={e => onClickOpenModalDelete(e, i)}>
                                                                <span className="icon icon-trash-o sq-24"></span>
                                                            </button>
                                                        </td>
                                                        <td className="text-center">{m.forwardCurrency}</td>
                                                        <td className="text-center">{m.requestType}</td>
                                                        <td className="text-center">{m.forwardType}</td>
                                                        <td className="text-right">
                                                            <InputMask className="form-control" 
                                                                onChange={e => onChangeInputTable(e, i)} 
                                                                format='currency'
                                                                option={{
                                                                    prefix: '',
                                                                    digits: 0
                                                                }}
                                                                value={m.forwardDuration}
                                                                name="forwardDuration" />
                                                        </td>
                                                        <td className="text-right">
                                                            <InputMask className="form-control" 
                                                                onChange={e => onChangeInputTable(e, i)} 
                                                                format='currency'
                                                                option={{
                                                                    prefix: '',
                                                                    digits: 0
                                                                }}
                                                                value={m.startTerm}
                                                                name="startTerm" />
                                                        </td>
                                                        <td className="text-right">
                                                            <select tabIndex="-1" className="form-control" 
                                                                value={m.startShift}
                                                                onChange={e => onChangeInputTable(e, i)}
                                                                name="startShift"
                                                            >
                                                                <option value="Next Working Day">Next Working Day</option>
                                                                <option value="No Shift">No Shift</option>
                                                            </select>
                                                        </td>
                                                        <td className="text-right">
                                                            <select tabIndex="-1" className="form-control" 
                                                                value={m.endShift}
                                                                onChange={e => onChangeInputTable(e, i)}
                                                                name="endShift"
                                                            >
                                                                <option value="Next Working Day Modified">Next Working Day Modified</option>
                                                            </select>
                                                        </td>
                                                        <td>{m.facCal}</td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </form>
                            </div> */}
        </Col2>
      </Card>
    ) : null}
  </>
);

const optGrouping = {
  order: [[2, 'asc']],
  columns: [
    {
      orderable: false,
      data: 'IsEdit',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<button class="btn btn-icon btn-info edit-item" id=${r.groupId}><span class="icon icon-pencil sq-24"></span></button>`;
      },
    },
    {
      orderable: false,
      data: 'IsDelete',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<button class="btn btn-icon btn-danger delete-item" id=${r.groupId}><span class="icon icon-trash-o sq-24"></span></button>`;
      },
    },
    { data: 'groupId', className: 'text-center' },
    { data: 'requestType', className: 'text-center' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'department' },
  ],
};

const optPercent = {
  order: [[2, 'asc']],
  columns: [
    {
      orderable: false,
      data: 'IsEdit',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<button class="btn btn-icon btn-info edit-item" id=${r.eformPercentId}><span class="icon icon-pencil sq-24"></span></button>`;
      },
    },
    {
      orderable: false,
      data: 'IsDelete',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<button class="btn btn-icon btn-danger delete-item" id=${r.eformPercentId}><span class="icon icon-trash-o sq-24"></span></button>`;
      },
    },
    { data: 'requestType', className: 'text-center' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'amount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'percentage', className: 'text-right', render: (d) => toNumber(d, 2) },
  ],
};

const optRounding = {
  order: [[2, 'asc']],
  columns: [
    {
      orderable: false,
      data: 'IsEdit',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<button class="btn btn-icon btn-info edit-item" id=${r.eformRoundingId}><span class="icon icon-pencil sq-24"></span></button>`;
      },
    },
    {
      orderable: false,
      data: 'IsDelete',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<button class="btn btn-icon btn-danger delete-item" id=${r.eformRoundingId}><span class="icon icon-trash-o sq-24"></span></button>`;
      },
    },
    { data: 'currency', className: 'text-center' },
    { data: 'requestType', className: 'text-center' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'rounding', className: 'text-right', render: (d) => toNumber(d, 0) },
  ],
};

const optDuraiton = {
  order: [[2, 'asc']],
  columns: [
    {
      orderable: false,
      data: 'IsEdit',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<button class="btn btn-icon btn-info edit-item" id=${r.eformDurationId}><span class="icon icon-pencil sq-24"></span></button>`;
      },
    },
    {
      orderable: false,
      data: 'IsDelete',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<button class="btn btn-icon btn-danger delete-item" id=${r.eformDurationId}><span class="icon icon-trash-o sq-24"></span></button>`;
      },
    },
    { data: 'currency', className: 'text-center' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'requestType', className: 'text-center' },
    { data: 'forwardType', className: 'text-center' },
    { data: 'duration', className: 'text-right' },
    { data: 'startTerm', className: 'text-right' },
    { data: 'startShift' },
    { data: 'endShift' },
    {
      data: 'calendarType',
      className: 'text-center',
      render: (d, t, r) => {
        if (r.calendarName) return r.calendarName;
        return d;
      },
    },
  ],
};
