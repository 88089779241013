import { getTypes, getMaster } from './action';

const initMasterState = {
  isFetch: false,
  datas: [],
};

export default (state = {}, action) => {
  const reducer = {};

  for (const key in getMaster) {
    const master = getMaster[key];
    reducer[master.state] = generateMasterReducer(key)(state[master.state], action);
  }

  return reducer;
};

const generateMasterReducer =
  (type) =>
  (state = initMasterState, action) => {
    const [request, receive] = getTypes(type);
    switch (action.type) {
      case receive:
        return {
          isFetch: true,
          datas: action.payload,
        };
      default:
        return state;
    }
  };
