import React from 'react';

import Tab from '../../common/Tab';
import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import AttachFile from './AttachFile';
import Button from '../../common/Button';
import Select2 from '../../common/Select2';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toLower, getUsername, getPermission } from '../../common/helpper';
import {
  MASTER_LOGO,
  MASTER_COUNTRY,
  MASTER_CURRENCY,
  MASTER_APPROVER,
  MAIN_MASTER_BANK,
  MASTER_INSTAND_CODE,
  MAIN_MASTER_FINANCE_GROUP,
  MAIN_MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('Common Master', 'Company');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  tabRef,
  formDetailRef,
  formLedgerRef,
  formAddressRef,
  formReportRef,

  onClickAddLedger,
  onChangeInputLedger,

  resultCompanyAdd,
  onClickClearCompany,
  onClickClearDefaultBg,

  resultEffective,

  onChangeModalApprove,
  formApproveRef,
  onClickApprove,

  attachFileRef,
}) => {
  const textHeader =
    state.mode === 'create' ? 'Create Company' : `${state.CompanyCode} | ${state.CompanyName}`;
  const modeEdit = state.mode === 'edit';
  // let noEdit = !permission ? true : state.mode === 'create' ? false : !state.IsActive ? true : state.setOverDueDate
  // let noEdit = !permission ? true : state.mode === 'create' ? false : state.setOverDueDate
  const noEdit = !permission;

  const tabContents = [];
  tabContents.push({
    header: <span>Hierachy</span>,
    content: (
      <HierachyTab
        resultCompanyAdd={resultCompanyAdd}
        state={state}
        onChangeInputData={onChangeInputData}
        onClickClearCompany={onClickClearCompany}
        noEdit={noEdit}
      />
    ),
    tabName: 'hierachy',
  });

  tabContents.push({
    header: <span>Ledger</span>,
    content: (
      <LedgerTab
        formRef={formLedgerRef}
        state={state}
        onClickAddLedger={onClickAddLedger}
        onChangeInputLedger={onChangeInputLedger}
        noEdit={noEdit}
      />
    ),
    tabName: 'ledger',
  });

  tabContents.push({
    header: <span>Address</span>,
    content: (
      <AddressTab
        formRef={formAddressRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'address',
  });

  tabContents.push({
    header: <span>Report</span>,
    content: (
      <ReportTab
        formRef={formReportRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'report',
  });

  tabContents.push({
    header: <span>Approval</span>,
    content: (
      <ApprovalTab
        onChangeModalApprove={onChangeModalApprove}
        formApproveRef={formApproveRef}
        noEdit={noEdit}
        state={state}
      />
    ),
    tabName: 'approval',
  });
  tabContents.push({
    header: <span>Attach File</span>,
    content: <AttachFileTab noEdit={noEdit} state={state} attachFileRef={attachFileRef} />,
    tabName: 'attachFile',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Common Master <span className="icon icon-angle-double-right" />
            <Link txt="Company" href="/master/company" />
            <span className="icon icon-angle-double-right" /> Company Detail{' '}
          </small>
        </p>
      </div>
      <Card textHeader={textHeader} bgHeader="bg-primary" cardActions={['toggler']}>
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            {state.mode === 'create' ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <DatePicker
                    value={state.EffectiveDateStr}
                    onChange={onChangeInputData}
                    name="EffectiveDateStr"
                    required
                    option={{
                      // startDate: "+1d",
                      // daysOfWeekDisabled: "0,6",
                      todayHighlight: true,
                    }}
                  />
                </FormGroup2>
              </Col2>
            ) : (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <Select2
                    value={state.TempEffectiveDateStr}
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="TempEffectiveDateStr"
                    required={false}
                  >
                    {permission ? (
                      <option value="New" key="New">
                        New Effective Date
                      </option>
                    ) : null}
                    {resultEffective.map((m) => (
                      <option value={m} key={m}>
                        {m}
                      </option>
                    ))}
                  </Select2>
                </FormGroup2>
                {state.TempEffectiveDateStr === 'New' ? (
                  <FormGroup2 text="New Effective Date" required>
                    <DatePicker
                      value={state.EffectiveDateStr}
                      onChange={onChangeInputData}
                      name="EffectiveDateStr"
                      required
                      option={{
                        // startDate: "+1d",
                        // daysOfWeekDisabled: "0,6",
                        todayHighlight: true,
                      }}
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            )}

            {modeEdit ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Status" required>
                  <select
                    className="form-control"
                    value={state.IsActive}
                    onChange={onChangeInputData}
                    name="IsActive"
                    disabled={noEdit}
                  >
                    <option value>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </FormGroup2>
              </Col2>
            ) : null}
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company Code" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={modeEdit}
                  value={state.CompanyCode || ''}
                  name="CompanyCode"
                />
              </FormGroup2>

              <FormGroup2 text="Company Short Name" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.CompanyAbbreviate || ''}
                  name="CompanyAbbreviate"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company Name" required>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.CompanyName || ''}
                  name="CompanyName"
                />
              </FormGroup2>

              <FormGroup2 text="Company Name TH" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.CompanyNameTh || ''}
                  name="CompanyNameTh"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                {noEdit ? (
                  <input className="form-control" value={state.FinanceGroupName} disabled />
                ) : (
                  <MasterDropdown
                    masterType={MAIN_MASTER_FINANCE_GROUP}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    status
                    value={state.FinanceGroupId}
                    name="FinanceGroupId"
                  />
                )}
              </FormGroup2>

              <FormGroup2 text="Business Unit" required>
                {noEdit ? (
                  <input className="form-control" value={state.BusinessUnitName} disabled />
                ) : (
                  <MasterDropdown
                    masterType={MAIN_MASTER_BUSINESS_UNIT}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    disabled={false}
                    status
                    value={state.BuId}
                    name="BuId"
                  />
                )}
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Instance Code" required>
                <MasterDropdown
                  masterType={MASTER_INSTAND_CODE}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  noValidateOption
                  name="Instance"
                  value={state.Instance || ''}
                  required
                  notMultipleSelect2
                  disabled={noEdit}
                  isChoose
                />
              </FormGroup2>

              <FormGroup2 text="Vendor Customer" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.Vendor || ''}
                  name="Vendor"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Chart of Account" required>
                <MasterDropdownUI
                  value={state.CoaId || []}
                  required
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  disabled={noEdit}
                  options={['SCG 4.7', 'SCG ECC6.0', 'SCG ECC6.0 VAS']}
                  name="CoaId"
                />
              </FormGroup2>

              <FormGroup2 text="Tax Id" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.TaxId || ''}
                  name="TaxId"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Default BG" required={false}>
                {noEdit ? (
                  <input className="form-control" value={state.DefaultBgName || ''} disabled />
                ) : (
                  <MasterDropdown
                    masterType={MAIN_MASTER_BANK}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={false}
                    disabled={false}
                    status
                    value={state.DefaultBg}
                    filter={(m) => m.isActive || m.bankCode === state.DefaultBg}
                    name="DefaultBg"
                  />
                )}
              </FormGroup2>
              <FormGroup2 text="&nbsp;">
                <>
                  {!permission ? (
                    ''
                  ) : (
                    <Button
                      txt="Clear BG"
                      icon="eraser"
                      className="btn-clear"
                      onClick={onClickClearDefaultBg}
                      disabled={noEdit}
                    />
                  )}
                </>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <div className="form-group">
                <div>
                  <input
                    id="IsFetchEmailForward"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeInputData}
                    name="IsFetchEmailForward"
                    checked={state.IsFetchEmailForward}
                    disabled={noEdit}
                  />
                  <label htmlFor="IsFetchEmailForward" className="label_checkbox" />
                  <label
                    htmlFor="IsFetchEmailForward"
                    className="control-label text_label_checkbox"
                  >
                    Fetch Email Forward
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div>
                  <input
                    id="IsConsole"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeInputData}
                    name="IsConsole"
                    checked={state.IsConsole}
                    disabled={noEdit}
                  />
                  <label htmlFor="IsConsole" className="label_checkbox" />
                  <label htmlFor="IsConsole" className="control-label text_label_checkbox">
                    Consolidation to SCG
                  </label>
                </div>
              </div>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company Type" required>
                <MasterDropdownUI
                  value={state.CompanyType || ''}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  required
                  isChoose
                  notMultipleSelect2
                  disabled={noEdit}
                  options={[
                    'Sweep - Full',
                    'Sweep - Partial',
                    'Non Sweep – Full',
                    'Non Sweep – Partial',
                    'AFMS - Full',
                    'AFMS - Partial',
                    'AFMS – Mirror',
                  ]}
                  name="CompanyType"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="Remark" required={false}>
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeInputData}
                required={false}
                disabled={noEdit}
                value={state.Remark || ''}
                name="Remark"
              />
            </FormGroup2>
          </Col2>
        </form>

        {renderApproveButtonGroup()}
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !permission ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={noEdit}
            />
          )
        }
      />
    </div>
  );

  function renderApproveButtonGroup() {
    if (
      state.ApprovalStatus === 'Waiting for Approve' &&
      toLower(state.Approver1Status) === 'waiting for approve' &&
      toLower(state.Approver1) === toLower(getUsername())
    )
      return (
        <div className="row">
          <div className="col-sm-12">
            <Button
              txt="Approve"
              icon="check"
              className="btn-success"
              onClick={() => onClickApprove(true)}
            />
            &nbsp;
            <Button
              txt="Reject"
              icon="close"
              className="btn-warning"
              onClick={() => onClickApprove(false)}
            />
          </div>
        </div>
      );

    return null;
  }
};

function HierachyTab({ resultCompanyAdd, state, onChangeInputData, onClickClearCompany, noEdit }) {
  return (
    <Card textHeader="Main Company">
      <Col2 col="col-md-8">
        <Col2 col={colX[1]}>
          <FormGroup2 text="Company" required={false}>
            <Select2
              value={state.MainCompanyCode || ''}
              notMultiple
              onChange={(e) =>
                onChangeInputData({
                  target: e,
                })
              }
              isChoose
              disabled={noEdit}
              name="MainCompanyCode"
              required
            >
              {resultCompanyAdd.map((m) => (
                <option value={m.companyCode} key={m.companyCode}>
                  {`${m.companyCode} | ${m.companyAbbreviate} | ${m.companyName}`}
                </option>
              ))}
            </Select2>
          </FormGroup2>
          <FormGroup2 text="&nbsp;">
            <>
              {!permission ? (
                ''
              ) : (
                <Button
                  txt="Clear"
                  icon="eraser"
                  className="btn-clear"
                  onClick={onClickClearCompany}
                  disabled={noEdit}
                />
              )}
            </>
          </FormGroup2>
        </Col2>
      </Col2>
    </Card>
  );
}

function AddressTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <form ref={formRef} name="address">
      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <Card textHeader="Address Local">
            <Col2 col={colX[2]}>
              <FormGroup2 text="Address" required={false}>
                <textarea
                  className="form-control"
                  rows="3"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.AddressTh || ''}
                  name="AddressTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="District" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.DistrictTh || ''}
                  name="DistrictTh"
                />
              </FormGroup2>

              <FormGroup2 text="Province" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.ProvinceTh || ''}
                  name="ProvinceTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Post Code" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.PostCodeTh || ''}
                  name="PostCodeTh"
                />
              </FormGroup2>

              <FormGroup2 text="Country" required={false}>
                {noEdit ? (
                  <input
                    className="form-control"
                    disabled={noEdit}
                    value={state.CountryCodeTh || ''}
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_COUNTRY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={false}
                    disabled={noEdit}
                    value={state.CountryCodeTh || ''}
                    name="CountryCodeTh"
                  />
                )}
              </FormGroup2>
            </Col2>
          </Card>

          <Card textHeader="Address Inter">
            <Col2 col={colX[2]}>
              <FormGroup2 text="Address" required>
                <textarea
                  className="form-control"
                  rows="3"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.Address || ''}
                  name="Address"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="District" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.District || ''}
                  name="District"
                />
              </FormGroup2>

              <FormGroup2 text="Province" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.Province || ''}
                  name="Province"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Post Code" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.PostCode || ''}
                  name="PostCode"
                />
              </FormGroup2>

              <FormGroup2 text="Country" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    disabled={noEdit}
                    value={state.CountryCode || ''}
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_COUNTRY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    disabled={noEdit}
                    value={state.CountryCode || ''}
                    name="CountryCode"
                  />
                )}
              </FormGroup2>
            </Col2>
          </Card>
        </Col2>
      </Col2>
    </form>
  );
}

function LedgerTab({ formRef, state, onClickAddLedger, onChangeInputLedger, noEdit }) {
  return (
    <Card textHeader="Ledger">
      {(state.resultLedger || []).length < 4 && permission ? (
        <Col2 col={colX[1]}>
          <Button
            txt="Add"
            icon="plus"
            className="btn-warning"
            onClick={onClickAddLedger}
            disabled={noEdit}
          />
        </Col2>
      ) : null}

      <Col2 col="col-xs-12 col-sm-12 col-md-6">
        <form ref={formRef} name="ledger">
          <div className="table-responsive">
            <table className="table table-bordered table-nowrap">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>No.</th>
                  <th>Ledger Name</th>
                  <th>Currency</th>
                </tr>
              </thead>
              <tbody>
                {!(state.resultLedger || []).length ? (
                  <tr>
                    <td colSpan="4" align="center">
                      No data available in table
                    </td>
                  </tr>
                ) : null}
                {state.resultLedger.map((m, i) => (
                  <tr key={i}>
                    <td align="center">
                      {i === state.resultLedger.length - 1 &&
                      !state.setOverDueDate &&
                      permission ? (
                        <button
                          type="button"
                          className="btn btn-icon btn-default delete-ledger"
                          index={i}
                          title="Cancel"
                        >
                          <span className="icon icon-close sq-24" />
                        </button>
                      ) : null}
                    </td>
                    <td align="center">{i + 1}</td>
                    <td>
                      {noEdit ? (
                        m.Ledger || ''
                      ) : (
                        <MasterDropdownUI
                          value={m.Ledger}
                          notMultipleSelect2
                          onChange={(e) =>
                            onChangeInputLedger(
                              {
                                target: e,
                              },
                              i
                            )
                          }
                          isChoose
                          required={i === 0}
                          options={['IAS39', 'SCG Standard', 'TFRS9', 'VN GAAP']}
                          name="Ledger"
                        />
                      )}
                    </td>
                    <td align="center">
                      {noEdit ? (
                        m.Currency || ''
                      ) : (
                        <MasterDropdown
                          masterType={MASTER_CURRENCY}
                          noValidateOption
                          saveLocalState
                          onChange={(e) =>
                            onChangeInputLedger(
                              {
                                target: e,
                              },
                              i
                            )
                          }
                          isChoose
                          notMultipleSelect2
                          value={m.Currency}
                          name="Currency"
                          required={i === 0}
                        />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </form>
      </Col2>
    </Card>
  );
}

function ReportTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <form ref={formRef} name="report">
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Logo Name" required={false}>
            {noEdit ? (
              <input className="form-control" disabled={noEdit} value={state.LogoName || ''} />
            ) : (
              <MasterDropdown
                key="logo"
                masterType={MASTER_LOGO}
                value={state.LogoId}
                notMultipleSelect2
                saveLocalState
                noValidateOption
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                required={false}
                disabled={noEdit}
                isActive
                type="Logo"
                name="LogoId"
              />
            )}
          </FormGroup2>

          <FormGroup2 text="Signature" required={false}>
            {noEdit ? (
              <input className="form-control" disabled={noEdit} value={state.SignatureName || ''} />
            ) : (
              <MasterDropdown
                key="signature"
                masterType={MASTER_LOGO}
                value={state.SignatureId}
                notMultipleSelect2
                saveLocalState
                noValidateOption
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                required={false}
                disabled={noEdit}
                isActive
                type="Signature"
                name="SignatureId"
              />
            )}
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function ApprovalTab({ onChangeModalApprove, formApproveRef, state, noEdit }) {
  return (
    <form ref={formApproveRef} name="approval">
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Approver1" required>
            {noEdit ? (
              <input className="form-control" disabled value={state.Approver1 || ''} />
            ) : (
              <MasterDropdown
                masterType={MASTER_APPROVER}
                isChoose
                notMultipleSelect2
                value={state.Approver1}
                onChange={(e) =>
                  onChangeModalApprove({
                    target: e,
                  })
                }
                filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                noValidateOption
                saveLocalState
                required
                financeGroupId="all"
                name="Approver1"
              />
            )}
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function AttachFileTab({ state, noEdit, attachFileRef }) {
  return (
    <AttachFile
      ref={attachFileRef}
      limitFileCount={-1}
      masterKey={state.CompanyCode}
      notFetch={state.mode === 'create'}
      masterType="company"
    />
  );
}
