import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_FLOW_ACCOUNT_DETAIL_CRITERIA = 'EDIT_MASTER_FLOW_ACCOUNT_DETAIL_CRITERIA';
export const CLEAR_MASTER_FLOW_ACCOUNT_DETAIL_CRITERIA =
  'CLEAR_MASTER_FLOW_ACCOUNT_DETAIL_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_FLOW_ACCOUNT_DETAIL =
  'UPDATE_SEARCH_RESULT_MASTER_FLOW_ACCOUNT_DETAIL';

export const REQUEST_SEARCH_MASTER_FLOW_ACCOUNT_DETAIL =
  '@@request/SARCH_MASTER_FLOW_ACCOUNT_DETAIL';
export const RECEIVE_SEARCH_MASTER_FLOW_ACCOUNT_DETAIL =
  '@@receive/SEARCH_MASTER_FLOW_ACCOUNT_DETAIL';
export const FAIL_SEARCH_MASTER_FLOW_ACCOUNT_DETAIL = '@@fail/SEARCH_MASTER_FLOW_ACCOUNT_DETAIL';

export const REQUEST_UPDATE_MASTER_FLOW_ACCOUNT_DETAIL =
  '@@request/UPDATE_MASTER_FLOW_ACCOUNT_DETAIL';
export const RECEIVE_UPDATE_MASTER_FLOW_ACCOUNT_DETAIL =
  '@@receive-The record was successfully saved/UPDATE_MASTER_FLOW_ACCOUNT_DETAIL';
export const FAIL_UPDATE_MASTER_FLOW_ACCOUNT_DETAIL = '@@fail/UPDATE_MASTER_FLOW_ACCOUNT_DETAIL';

export const updateMasterFlowAccountDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveMasterFlowAccountDetail`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_UPDATE_MASTER_FLOW_ACCOUNT_DETAIL,
      RECEIVE_UPDATE_MASTER_FLOW_ACCOUNT_DETAIL,
      FAIL_UPDATE_MASTER_FLOW_ACCOUNT_DETAIL,
    ],
  },
});

export const searchMasterFlowAccountDetail = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/GetMasterFlowAccountDetail', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_FLOW_ACCOUNT_DETAIL,
      RECEIVE_SEARCH_MASTER_FLOW_ACCOUNT_DETAIL,
      FAIL_SEARCH_MASTER_FLOW_ACCOUNT_DETAIL,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_FLOW_ACCOUNT_DETAIL,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_FLOW_ACCOUNT_DETAIL_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_FLOW_ACCOUNT_DETAIL_CRITERIA,
  payload: {
    name,
    value,
  },
});
