import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import FormGroup2 from '../../../common/FormGroup2';
import { DATATABLE_ID } from './FacilitySetUpContainer';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { toStatus, toNumber, getPermission } from '../../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('Funding&Investment', 'Facility Set up');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    {
      orderable: false,
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (r.status) {
          if (d)
            return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
          return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
        }
        return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
      },
    },
    {
      data: 'transactionNo',
      render: (d) =>
        `<a target="_blank" href="/loan-investment/facility-set-up-detail/${d}" class="facility-set-up-detail"> ${d} </a>`,
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'facilityName' },
    { data: 'facilityType', className: 'text-center' },
    { data: 'businessPartnerType', className: 'text-center' },
    {
      data: 'businessPartnerCode',
      render: (d, t, r) => `${d} | ${r.businessPartnerName}`,
    },
    { data: 'currency', className: 'text-center' },
    { data: 'startDateStr', className: 'text-center', type: 'date-black' },
    { data: 'endDateStr', className: 'text-center', type: 'date-black' },
    {
      data: 'creditAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'isProject', className: 'text-center', render: (d) => (d ? 'Yes' : 'No') },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  results,
  dataTableRef,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Funding & Investment <span className="icon icon-angle-double-right" /> Facility Set Up
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group">
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroup}
                name="FinanceGroup"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companies}
                financeGroupId={criteria.FinanceGroup}
                businessUnitId={criteria.BusinessUnits}
                name="Companies"
              />
            </FormGroup2>

            <FormGroup2 text="Facility Type">
              <MasterDropdownUI
                value={criteria.FacilityType}
                onChange={onChangeSearch}
                name="FacilityType"
                isAll
                notMultiple
                options={['Bilateral', 'Syndicate']}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Bank">
              <MasterDropdown
                masterType={MASTER_BANK}
                onChange={onChangeSelect2}
                value={criteria.Banks}
                // companyCode={criteria.Companies}
                name="Banks"
              />
            </FormGroup2>

            <FormGroup2 text="Start Date" required={false}>
              <DateRangePicker
                value={criteria.StartDateStr}
                onChange={onChangeSearch}
                required={false}
                name="StartDateStr"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="End Date" required={false}>
              <DateRangePicker
                value={criteria.EndDateStr}
                onChange={onChangeSearch}
                required={false}
                name="EndDateStr"
              />
            </FormGroup2>

            <FormGroup2 text="Project">
              <select
                className="form-control"
                value={criteria.IsProject}
                onChange={onChangeSearch}
                name="IsProject"
              >
                <option value={null}>---All---</option>
                <option value>Yes</option>
                <option value={false}>No</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Status">
              <MasterDropdownUI
                value={criteria.Status}
                onChange={onChangeSearch}
                name="Status"
                isAll
                notMultiple
                options={['Active', 'Inactive']}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Link
                  className="btn btn-warning btn-sm btn-labeled"
                  to="/loan-investment/facility-set-up-detail/create"
                  target="_blank"
                >
                  <span className="btn-label">
                    <i className="icon icon-plus-circle icon-fw icon-lg" />
                  </span>
                  CREATE
                </Link>
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Action</th>
            <th>Transaction No.</th>
            <th>Finance Group</th>
            <th>Business Unit</th>
            <th className="text-center">
              Company
              <br />
              Code
            </th>
            <th>Company Name</th>
            <th>Facility Name</th>
            <th>Facility Type</th>
            <th>
              Business Partner
              <br />
              Type
            </th>
            <th>Business Partner Name</th>
            <th>Currency</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Credit Amount</th>
            <th>Project</th>
            <th>Status</th>
            <th>
              Created
              <br />
              Date
            </th>
            <th>
              Created
              <br />
              By
            </th>
            <th>
              Updated
              <br />
              Date
            </th>
            <th>
              Updated
              <br />
              By
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalFunction
      textHeader="Active"
      textContent="active"
      modalRef={modalActiveRef}
      type="info"
      icon="icon-refresh"
      textBtn="Active"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
    />

    <ModalFunction
      textHeader="InActive"
      textContent="inactive"
      modalRef={modalInactiveRef}
      type="danger"
      icon="icon-close"
      textBtn="Yes"
      textBtnBack="No"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
    />
  </div>
);
