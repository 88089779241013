import React, { Component } from 'react';

const $ = window.jQuery;
export default class DataTable extends Component {
  timeoutDrawChild = null;

  componentDidMount() {
    $.extend($.fn.dataTableExt.oSort, {
      'date-black-pre': function (a) {
        if (a === null || a === '') {
          return window.moment(0);
        }

        return window.moment(a, 'DD/MM/YYYY');
      },

      'date-black-asc': function (a, b) {
        return a.isBefore(b, 'days') ? -1 : a.isAfter(b, 'days') ? 1 : 0;
      },

      'date-black-desc': function (a, b) {
        return a.isBefore(b, 'days') ? 1 : a.isAfter(b, 'days') ? -1 : 0;
      },
      'date-time-pre': function (a) {
        if (a === null || a === '') {
          return 0;
        }

        return new Date(a);
      },

      'date-time-asc': function (a, b) {
        return a < b ? -1 : a > b ? 1 : 0;
      },

      'date-time-desc': function (a, b) {
        return a < b ? 1 : a > b ? -1 : 0;
      },

      'amount-formal-pre': function (a) {
        if (a === null || a === undefined || a === '' || a === '-') return -1;
        let b = a.indexOf('>');
        if (a.indexOf('</span>') !== -1) {
          b++;
          let s = a.substr(b, a.length - b);
          s = s.substr(0, s.indexOf('<'));
          a = s;
        }

        if (a[0] === '(') a = `-${a.substr(1, a.length - 2)}`;
        a = a.replace(/,/g, '');
        return Number(a);
      },
    });

    $.extend(true, $.fn.dataTable.Buttons.defaults, {
      dom: {
        button: {
          className: 'btn btn-outline-primary btn-sm btn-dataTable',
        },
      },
    });
    this.createDataTable();
    this.drawData();
    if (this.props.drawChild)
      this.$dataTable.on('page.dt', () => {
        this.props.drawChild(this.$dataTable.rows({ page: 'current' }), this.$dataTable);
      });
    if (this.props.tiggerOnChangeInput)
      this.$dataTable
        .rows()
        .nodes()
        .each((m, i) => {
          $(m)
            .find('input[type=number]')
            .each(function () {
              if (Number(this.value)) {
                $(this).change();
              }
            });
        });

    // alert if id not specific
    if (!this.props.id) alert('please specific id for table');
  }

  createDataTable = () => {
    const opt = {
      dom: "<'row'<'col-sm-6'B><'col-sm-6'f>>t <'row'<'col-sm-6'i><'col-sm-6'p>>  ",
      buttons: this.props.noColvis
        ? [
            {
              extend: 'excelHtml5',
              exportOptions: {
                orthogonal: 'export',
                ...(this.props.exportOptions || {}),
              },
              title: null,
              customize: this.props.customize || null,
            },
          ]
        : [
            {
              extend: 'excelHtml5',
              exportOptions: {
                orthogonal: 'export',
                ...(this.props.exportOptions || {}),
              },
              title: null,
              customize: this.props.customize || null,
            },
            'colvis',
          ],
      stateSave: true,
      stateDuration: 0,
      colReorder: true,
      scrollX: true,
      scrollY: 500,
      scrollCollapse: true,
      paging: false,
      ...this.props.option,
    };

    this.$dataTable = $(this.TableElement).DataTable(opt);
    $(this.TableElement).on('change', 'input:not(.noonchange),select', this.onInputChange);
    this.$dataTable.clear();
  };

  onInputChange = (e) => {
    if (this.props.onInputChange) {
      const $cell = $(e.target).closest('td');
      const cell = this.$dataTable.cell($cell[0]);
      const row = this.$dataTable.row($cell.closest('tr'));
      this.props.onInputChange(e, row.index(), cell, row, this.$dataTable, $(e.target).data());
    }
  };

  componentWillUnmount() {
    this.$dataTable.off('page.dt', '**');
    $(this.TableElement).off('change', 'input:not(.noonchange),select', this.onInputChange);
    this.$dataTable.destroy();
  }

  shouldComponentUpdate(p) {
    this.updateOption = p.option !== this.props.option;
    return p.value !== this.props.value;
  }

  componentDidUpdate() {
    if (this.updateOption) {
      this.$dataTable.destroy();
      this.createDataTable();
    }
    this.drawData();
  }

  drawData = () => {
    // cancel timeout
    if (this.timeoutDrawChild) window.clearTimeout(this.timeoutDrawChild);

    const paging = this.props.isChangePage === undefined ? true : this.props.isChangePage;
    this.$dataTable.clear().rows.add(this.props.value).draw(paging);
    setTimeout(() => {
      window.$(window).trigger('resize');
      this.$dataTable.draw(paging);
    }, 200);
    // if reset paging must scroll to fix fiedcolumn height
    if (this.props.option.fixedColumns && paging) {
      $(this.$dataTable.table().node())
        .closest('.dataTables_scroll')
        .next('div')
        .find('.DTFC_LeftBodyLiner')
        .scrollTop(1);
    }
    $(this.$dataTable.rows().nodes())
      .find('.datepicker.startDate')
      .datepicker({ format: 'dd/mm/yyyy', autoclose: true, startDate: new Date() });
    $(this.$dataTable.rows().nodes())
      .find('.datepicker')
      .datepicker({ format: 'dd/mm/yyyy', autoclose: true });

    if (this.props.onClickLinkColumns) {
      console.log(
        'onClickLinkColumns is depecated, please use onClickContext, if there any question plase ask BOY lNW ZA.'
      );
      // this.props.onClickLinkColumns.forEach(m => {
      //     this.$dataTable.columns(m.column).every(function () {
      //         $(this.nodes()).on('click', m.context, function (e) {
      //             m.onClick(e, this.cell($(e.currentTarget).closest('td')), $(e.currentTarget).data())
      //         }.bind(this))
      //         return true
      //     })
      // })
    }

    if (this.props.onClickContext) {
      this.props.onClickContext.forEach((m) => {
        this.$dataTable.columns().every(function () {
          // onclick context
          $(this.nodes()).on('click', m.context, (e) => {
            m.onClick(e, this.cell($(e.currentTarget).closest('td')), $(e.currentTarget).data());
          });
          return true;
        });
      });
    }
    if (this.props.onClickColumnContext) {
      this.props.onClickColumnContext.forEach((m) => {
        this.$dataTable.cells(m.context).every(function () {
          $(this.nodes()).on('click', (e) => {
            m.onClick(e, this.cell($(e.currentTarget).closest('td')), $(e.currentTarget).data());
          });
          return true;
        });
      });
    }

    if (this.props.onDrawTable)
      this.props.onDrawTable(this.$dataTable.rows().nodes(), this.$dataTable);
    if (this.props.drawChild) {
      this.timeoutDrawChild = setTimeout(() => {
        this.triggerOnDrawChild();
      }, 1000);
    }
  };

  triggerOnDrawChild = () => {
    this.props.drawChild(this.$dataTable.rows({ page: 'current' }), this.$dataTable);
  };

  render() {
    return (
      <div>
        <table
          ref={(el) => (this.TableElement = el)}
          className={this.props.className}
          cellSpacing="0"
          width="100%"
          id={this.props.id || ''}
        >
          {this.props.children}
        </table>
      </div>
    );
  }
}
