import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import { DATATABLE_ID } from './CurrencyContainer';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toStatus, getPermission } from '../../common/helpper';

const permission = getPermission('Master Accounting', 'Flow Config');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-5', 'col-md-5'];

const opt = {
  order: [[2, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'productCategory' },
    { data: 'flowNo' },
    { data: 'transactionType' },
    { data: 'flow' },
    { data: 'postingProcess' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Action === 'edit';
  const checkSave = !!((modalData.Action === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Accounting <span className="icon icon-angle-double-right" /> Flow Config
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Product Category">
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.ProductCategory}
                  name="ProductCategory"
                  options={[
                    'Money Market',
                    'Derivatives',
                    'Foreign Exchange',
                    'Secutities',
                    'Facility',
                  ]}
                />
              </FormGroup2>

              <FormGroup2 text="Posting Process">
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.PostingProcess}
                  name="PostingProcess"
                  options={['Daily', 'Month End']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.IsActive}
                  onChange={onChangeSearch}
                  name="IsActive"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {/* {
                                permission ? ( <>
                                    <Button txt="Create Flow Config" icon="plus-circle" className="btn-warning" onClick={onClickOpenModalData} disabled={false}></Button>
                                    &nbsp;
                                </> ) : null
                            } */}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Product Category</th>
              <th>Flow No.</th>
              <th>Transaction Type</th>
              <th>Flow</th>
              <th>Posting Process</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal
        textHeader="Master Flow Config"
        bgHeader="bg-primary"
        size="modal-md"
        ref={modalDataRef}
      >
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          {/* <FormGroup3 text="Product Category" col={colX1} required={true}>
                        <input className="form-control"
                            onChange={onChangeModalData}
                            value={modalData.ProductCategory || ""}
                            required={true}
                            readOnly={modeEdit}
                            name="ProductCategory"
                        />
                    </FormGroup3>

                    <FormGroup3 text="Flow No." col={colX1} required={true}>
                        <input className="form-control"
                            onChange={onChangeModalData}
                            value={modalData.FlowNo || ""}
                            required={true}
                            readOnly={modeEdit}
                            name="FlowNo"
                        />
                    </FormGroup3>

                    <FormGroup3 text="Transaction Type" col={colX1} required={true}>
                        <input className="form-control"
                            onChange={onChangeModalData}
                            value={modalData.TransactionType || ""}
                            required={true}
                            readOnly={modeEdit}
                            name="TransactionType"
                        />
                    </FormGroup3> */}

          <FormGroup3 text="Flow" col={colX1} required>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.Flow || ''}
              required
              // readOnly={modeEdit}
              name="Flow"
            />
          </FormGroup3>
          {/* 
                    <FormGroup3 text="Posting Process" col={colX1} required={true}>
                        <input className="form-control"
                            onChange={onChangeModalData}
                            value={modalData.PostingProcess || ""}
                            required={true}
                            readOnly={modeEdit}
                            name="PostingProcess"
                        />
                    </FormGroup3> */}

          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
