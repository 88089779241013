import { connect } from 'react-redux';
import React, { Component } from 'react';

import CreateTt from './CreateTt';
import AttachFileList from './AttachFileListRequest';
import { addAlert } from '../../reducers/layout/action';
import { getMasterUserDetail } from '../../reducers/masterUser/action';
import { toLower, toUpperKey, getNewPermission } from '../../common/helpper';
import { searchHoliday, getHolidayDetail } from '../../reducers/masterHoliday/action';
import { getAtatchfileList, downloadAttachFile } from '../../reducers/ttRemittance/action';
import {
  changeStatus,
  getTtRemittance,
  getAccoutingVerify,
  saveTtRemittanceManual,
} from '../../reducers/requestTt/action';

// let permission = getPermissionStr('T/T Remittance', 'Request') === 'View' ? false : true
const newPermission = getNewPermission('T/T Remittance', 'Request');
const { permission } = newPermission;

const jwt = JSON.parse(localStorage.getItem('jwt'));
const USERNAME = jwt ? jwt.username : '';

const init = {
  CompanyCode: '',
  PaymentBy: '',
  PoNo: '',
  InvoiceNo: '',
  InvoiceAmount: '',
  Currency: '',
  ValueDateStr: '',
  Equivalent: 'Normal',
  RemittedToCurrency: '',
  PreAdvice: '',
  PaidFor: '',
  PurposeDetail: '',
  ForwardContractNo: '',
  BankChargeOutside: 'Beneficiary Account',
  BankChargeInside: 'Our Account',
  SpecialMessage: '',
  AbsorbWht: 'Our Company',

  IsCreator: false,
  CreatorFullName: '',
  CreatorName: '',
  CreatorDepartment: '',
  CreatorTel: '',
  CreatorEmail: '',

  RequestorName: '',
  RequestorDepartment: '',
  RequestorTel: '',
  RequestorEmail: '',

  BeneficiaryCode: '',
  BeneficiaryName: '',
  BeneficiaryAddress: '',
  BeneficiaryCountry: '',
  AccountNo: '',
  IbanNo: '',
  AbaNo: '',
  BsbNo: '',
  IfscCode: '',
  BeneficiaryBankName: '',
  BeneficiaryBankAddress: '',
  BeneficiaryBankSwiftCode: '',
  BeneficiaryBankCountry: '',
  IntermediateBankName: '',
  IntermediateBankAddress: '',
  IntermediateBankSwiftCode: '',
  IntermediateBankCountry: '',
  IntenalMessage: '',

  BasedAmount: 0,
  WhtAmount: 0,
  VatAmount: 0,
  NetPaidAmount: 0,
  Remark: '',
  FiDoc: '',

  Initial: '',
  Doa: '',
  Approver: '',
  AccountingVerifierName: '',

  IsConfirmCreator: false,
  CommentBox: '',
  IsConfirmDoa: false,
  IsConfirmApprover: false,

  mode: 'create',
  ResultAccountingVerify: [],
  isAccountingVerify: false,
  isAccountApprove: false,
  showFunction: false,
  CalendarId: '',

  attachfilelist: [],
  debitFiles: [],
  RefTransactionNo: '',
  DraftTransaction: '',
};

const initFunciton = {
  mode: '',

  textHeader: '',
  textContent: '',
  type: '',
  icon: '',
  textBtn: 'Yes',
  textBtnBack: 'No',
  selectedLength: 0,

  Remark: '',
};

class CreateTtContainer extends Component {
  state = {
    ...init,
    modalFunction: {
      ...initFunciton,
    },
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;

    if (!transactionNo || transactionNo === 'create') {
      const min = 0;
      const max = 1000;
      const rand = min + Math.random() * (max - min);
      const draftTran = `draft${USERNAME}${parseInt(rand)}`;
      this.props.getMasterUserDetail(USERNAME).then((response) => {
        if (!response.error) {
          const r = response.payload;

          this.props.getAccoutingVerify({ IsVerifier: true }).then((response) => {
            if (response.error) return;

            const users = response.payload;
            const fullname = `${r.nameEn || ''} ${r.surnameEn || ''}`;
            this.creatorRef.value = fullname;
            this.setState({
              ...init,
              CreatorFullName: fullname,
              CreatorName: r.username,
              CreatorEmail: r.email,
              isAccountingVerify: !!users.length,
              DraftTransaction: draftTran,
            });
          });
        }
      });
    } else {
      this.props.getTtRemittance(transactionNo).then((response) => {
        if (!response.error) {
          const r = response.payload;
          const status = r.statusTt;
          let showFunction = false;

          if (
            toLower(status) === 'wait for initialization' &&
            toLower(r.initial) === toLower(USERNAME)
          ) {
            showFunction = true;
          } else if (
            toLower(status) === 'wait for doa approval' &&
            toLower(r.doa) === toLower(USERNAME)
          ) {
            showFunction = true;
          } else if (
            toLower(status) === 'wait for approval' &&
            toLower(r.approver) === toLower(USERNAME)
          ) {
            showFunction = true;
          }
          const fetchData = toUpperKey(response.payload);

          if (fetchData.RequestorName) {
            const option = new Option(
              fetchData.RequestorFullName,
              fetchData.RequestorName,
              false,
              true
            );
            this.requestorRef.$select2.append(option);
          }

          this.setState(
            {
              ...fetchData,
              mode: 'edit',
              showFunction,
            },
            () => {
              if (fetchData.Initial) {
                const option = new Option(fetchData.InitialName, fetchData.Initial, false, true);
                this.initialRef.$select2.append(option);
              }
              if (fetchData.Doa && !fetchData.DuplicateTransaction) {
                const option = new Option(fetchData.DoaName, fetchData.Doa, false, true);
                this.doaRef.$select2.append(option);
              }
              if (fetchData.Approver) {
                const option = new Option(fetchData.ApproverName, fetchData.Approver, false, true);
                this.approverRef.$select2.append(option);
              }
            }
          );

          this.props
            .getAccoutingVerify({ IsVerifier: true, CompanyCode: fetchData.CompanyCode })
            .then((response) => {
              if (response.error) return;

              const users = response.payload;
              this.setState({
                ...this.state,
                isAccountingVerify: !!users.length,
              });
            });

          this.props
            .getAccoutingVerify({ isApprove: true, CompanyCode: fetchData.CompanyCode })
            .then((response) => {
              if (response.error) return;

              const users = response.payload;
              this.setState({
                ...this.state,
                isAccountApprove: !!users.length,
              });
            });
        }
      });

      this.props.getAtatchfileList(transactionNo).then((response) => {
        if (!response.error) {
          this.setState({
            ...this.state,
            attachfilelist: response.payload,
          });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { CompanyCode, RefTransactionNo } = this.state;

    // get accounting verifier
    if (prevState.CompanyCode !== CompanyCode) {
      this.props.getAccoutingVerify({ CompanyCode }).then((response) => {
        if (response.error) return;

        const users = response.payload;
        this.setState({
          ...this.state,
          ResultAccountingVerify: users,
          AccountingVerifierName: users.map((m) => m.username).join(','),
        });
      });
    }

    if (prevState.RefTransactionNo !== RefTransactionNo && RefTransactionNo) {
      this.props.getAtatchfileList(RefTransactionNo).then((response) => {
        if (!response.error) {
          const result = (response.payload || []).filter((f) => f.isDtt !== true);
          this.setState({
            ...this.state,
            debitFiles: result,
          });
        }
      });
    }
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'Equivalent' && value === 'Normal') {
        stateToUpdate.RemittedToCurrency = '';
      } else if (name === 'InvoiceAmount') {
        if (value < 0) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Invoice amount must be more than 0.',
          });
          return;
        }
        stateToUpdate.BasedAmount = value;
        stateToUpdate.NetPaidAmount = value;
      } else if (name === 'BasedAmount') {
        if (value < 0) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Based amount must be more than 0.',
          });
          return;
        }
        stateToUpdate.NetPaidAmount = this.calNetPaidAmount(value, state.WhtAmount || 0);
      } else if (name === 'VatAmount') {
        if (value < 0) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'VAT amount must be more than 0.',
          });
          return;
        }
      } else if (name === 'WhtAmount') {
        if (value < 0) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'WHT amount must be more than 0.',
          });
          return;
        }
        stateToUpdate.NetPaidAmount = this.calNetPaidAmount(state.BasedAmount || 0, value);
      } else if (name === 'BeneficiaryBankSwiftCode') {
        stateToUpdate.BeneficiaryBankSwiftCode = (value || '').toUpperCase();
      } else if (name === 'IntermediateBankSwiftCode') {
        stateToUpdate.IntermediateBankSwiftCode = (value || '').toUpperCase();
      }
      return stateToUpdate;
    });
  };

  calNetPaidAmount(basedAmount, whtAmount) {
    const v =
      parseFloat((basedAmount.toString() || '').replace(/,/g, '')) -
      parseFloat((whtAmount.toString() || '').replace(/,/g, ''));
    return v;
  }

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();
    return formName;
  }

  onCheckHoliday = (e, type) => {
    this.props.searchHoliday({ status: true }).then((response) => {
      if (!response.error) {
        const r = response.payload;
        let calendarId = null;
        const tt = r.find((f) => f.calendarName === 'T/T Request');
        if (tt) {
          calendarId = tt.holidayId;
        }
        this.setState({
          ...this.state,
          CalendarId: calendarId,
        });
        this.checkHoliday(e, type);
      }
    });
  };

  checkHoliday = (e, type) => {
    const { CalendarId, ValueDateStr } = this.state;
    if (ValueDateStr === '' || CalendarId === '') {
      this.onSubmitHander(e, type);
    } else {
      const s = ValueDateStr.split('/');
      const year = s[2];
      this.props
        .getHolidayDetail({
          year,
          holidayId: CalendarId,
        })
        .then((response) => {
          if (response.error || !response.payload) return;

          let isHoliday = false;
          let description = '';
          const l = response.payload;
          for (let i = 0; i < l.length; i++) {
            const d = l[i];
            if (d.holidayDateStr === ValueDateStr) {
              isHoliday = true;
              description = d.holidayName;
            }
          }

          if (isHoliday) {
            this.props.addAlert({
              title: 'Warning',
              type: 'warning',
              body: `Value date is Holiday [${description}]. Please confirm that you want to proceed this transaction??`,
              buttons: [
                <button
                  data-dismiss="modal"
                  className="btn btn-warning btn-sm"
                  onClick={(e) => this.onSubmitHander(e, type)}
                >
                  Confirm
                </button>,
                <button data-dismiss="modal" className="btn btn-default btn-sm">
                  Cancel
                </button>,
              ],
            });
          } else {
            this.onSubmitHander(e, type);
          }
        });
    }
  };

  onSubmitHander = (e, type) => {
    const m = { ...this.state };

    if (type === 'submit') {
      const inValidFormName = this.validateForms([
        this.formGeneralRef,
        this.formCreatorRef,
        this.formRequestorRef,
        this.formBeneficiaryRef,
        this.formAccountingRef,
        this.formApproveRef,
      ]);
      if (inValidFormName) return;

      if (m.BeneficiaryBankSwiftCode.length < 8) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Beneficiary Bank SWIFT Code must be 8 - 11 characters.',
        });
        return;
      }
      if (m.IntermediateBankSwiftCode.length < 8 && m.IntermediateBankSwiftCode) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Intermediate Bank SWIFT Code must be 8 - 11 characters.',
        });
        return;
      }
    } else if (type === 'send') {
      const inValidFormName = this.validateForms([
        this.formGeneralRef,
        this.formCreatorRef,
        this.formRequestorRef,
        this.formBeneficiaryRef,
        this.formAccountingRef,
      ]);
      if (inValidFormName) return;

      if (m.BeneficiaryBankSwiftCode.length < 8) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Beneficiary Bank SWIFT Code must be 8 - 11 characters.',
        });
        return;
      }
      if (m.IntermediateBankSwiftCode.length < 8 && m.IntermediateBankSwiftCode) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Intermediate Bank SWIFT Code must be 8 - 11 characters.',
        });
        return;
      }
    } else {
      if (!m.CompanyCode) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please select company in general information tab.',
        });
        return;
      }

      if (!m.ValueDateStr) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please select value date in general information tab.',
        });
        return;
      }
    }

    const data = {
      ...this.state,
      Type: type,
    };
    this.save(data);
  };

  save = (data) => {
    this.props.saveTtRemittanceManual(data).then((response) => {
      if (response.error || !response.payload) return;

      // ถ้า update ไม่ต้อง manual save attachfile เพราะ มันจะถูก save ไปตอน btn ใน modal แล้ว
      if (this.state.TransactionNo) this.alertAfterSaveSuccess(response);
      else {
        // ถ้าเพิ่ง create ให้ save attachfile ก่อน ค่อยแสดง success
        this.attachFileRef.save(response.payload, () => {
          // this.alertAfterSaveSuccess(response)
        });
        this.alertAfterSaveSuccess(response);
      }
    });
  };

  alertAfterSaveSuccess = (response) => {
    this.props.addAlert({
      title: 'Success',
      type: 'success',
      body: 'The record was successfully saved',
      buttons: [
        <button
          className="btn btn-success"
          onClick={(e) => this.onClickContinue(response.payload)}
          data-dismiss="modal"
        >
          Continue
        </button>,
      ],
    });

    if (response.payload !== this.props.routeProp.match.params.id) {
      window.location.href = `/tt-remittance/create-tt/${response.payload}`;
    } else {
      // this.fetchId(response.payload)
    }
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/tt-remittance/request');
  };

  onClickCopyCreator = (e) => {
    const { CreatorName, CreatorTel, CreatorEmail, CreatorFullName } = this.state;
    const option = new Option(CreatorFullName, CreatorName, false, true);
    this.requestorRef.$select2.append(option);

    this.setState({
      ...this.state,
      RequestorName: this.state.CreatorName,
      RequestorDepartment: this.state.CreatorDepartment,
      RequestorTel: this.state.CreatorTel,
      RequestorEmail: this.state.CreatorEmail,
    });
  };

  // ---------------- Button Function ----------------
  onClickOpenModalFunction = (e, modal) => {
    const inValidFormName = this.validateForms([
      this.formGeneralRef,
      this.formCreatorRef,
      this.formRequestorRef,
      this.formBeneficiaryRef,
      this.formAccountingRef,
      this.formApproveRef,
    ]);
    if (inValidFormName) return;

    let textHeader = '';
    let textContent = '';
    let type = '';
    let icon = '';
    if (modal === 'approve') {
      textHeader = 'Approve';
      textContent = 'approve';
      type = 'success';
      icon = 'icon-check';
    } else if (modal === 'reject') {
      textHeader = 'Reject';
      textContent = 'reject';
      type = 'danger';
      icon = 'icon-close';
    } else if (modal === 'send back') {
      textHeader = 'Send Back';
      textContent = 'send back to edit';
      type = 'warning';
      icon = 'icon-refresh';
    }

    this.setState(
      {
        ...this.state,
        modalFunction: {
          ...initFunciton,
          mode: modal,

          textHeader,
          textContent,
          type,
          icon,
        },
      },
      this.modalFunctionRef.open
    );
  };

  onChangeInputModal = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      modalFunction: {
        ...this.state.modalFunction,
        [name]: value,
      },
    });
  };

  onSubmitModalFunction = (e) => {
    e.preventDefault();

    const status = toLower(this.state.StatusTt);
    if (this.state.DuplicateTransaction) {
      this.changeStatus();
    } else if (
      ((status === 'wait for initialization' &&
        !this.state.IsConfirmCreator &&
        this.state.Initial === this.state.Doa) ||
        (status === 'wait for doa approval' && !this.state.IsConfirmDoa) ||
        (status === 'wait for approval' && !this.state.IsConfirmApprover)) &&
      this.state.modalFunction.mode === 'approve'
    ) {
      this.props.addAlert({
        title: 'Warning',
        type: 'warning',
        body: 'Please confirm your approval without checking beneficiary information via telephone.',
        buttons: [
          <button
            className="btn btn-success"
            onClick={(e) =>
              this.changeStatus(
                'Confirm approval without checking beneficiary information via telephone.'
              )
            }
            data-dismiss="modal"
          >
            Yes
          </button>,
          <button className="btn btn-danger" data-dismiss="modal">
            No
          </button>,
        ],
      });
    } else {
      this.changeStatus();
    }
  };

  changeStatus(remark) {
    const d = this.state.modalFunction;
    const data = {
      TransactionNo: this.state.TransactionNo,
      Mode: d.mode,
      StatusTt: this.state.StatusTt,
      BasedAmount: this.state.BasedAmount,
      WhtAmount: this.state.WhtAmount,
      VatAmount: this.state.VatAmount,
      NetPaidAmount: this.state.NetPaidAmount,
      Remark: remark || (d.mode === 'approve' ? this.state.Remark : d.Remark),
      FiDoc: this.state.FiDoc,
      ValueDateStr: this.state.ValueDateStr,
      Initial: this.state.Initial,
      Doa: this.state.Doa,
      Approver: this.state.Approver,
      IsConfirmCreator: this.state.IsConfirmCreator,
      IsConfirmDoa: this.state.IsConfirmDoa,
      IsConfirmApprover: this.state.IsConfirmApprover,
    };

    this.props.changeStatus(data).then((response) => {
      if (response.error || !response.payload) return;

      this.modalFunctionRef.close();

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The transaction was successfully change status',
        buttons: [
          <button
            className="btn btn-success"
            onClick={(e) => this.onClickContinue(response.payload)}
            data-dismiss="modal"
          >
            Continue
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        // window.location.href = `/tt-remittance/create-tt/${response.payload}`
      } else {
        // this.fetchId(response.payload)
      }
    });
  }

  onClickContinue = (id) => {
    window.location.href = `/tt-remittance/create-tt/${id}`;
  };

  onClickClearInitial = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      Initial: '',
    });
  };

  onClickClearDoa = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      Doa: '',
    });
  };

  onClickClearBankCountryIntermediate = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      IntermediateBankCountry: '',
    });
  };

  onClickDownloadDebit = (e, id) => {
    this.props.downloadAttachFile(id);
  };

  callBackSave = () => {
    this.props
      .getAtatchfileList(this.state.TransactionNo || this.state.DraftTransaction)
      .then((response) => {
        if (!response.error) {
          this.setState({
            ...this.state,
            attachfilelist: response.payload,
          });
        }
      });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,
      onCheckHoliday: this.onCheckHoliday,
      onClickCopyCreator: this.onClickCopyCreator,
      onClickClearInitial: this.onClickClearInitial,
      onClickClearDoa: this.onClickClearDoa,
      onClickClearBankCountryIntermediate: this.onClickClearBankCountryIntermediate,

      tabRef: (e) => (this.tabRef = e),
      formGeneralRef: (e) => (this.formGeneralRef = e),
      formCreatorRef: (e) => (this.formCreatorRef = e),
      formRequestorRef: (e) => (this.formRequestorRef = e),
      formBeneficiaryRef: (e) => (this.formBeneficiaryRef = e),
      formAccountingRef: (e) => (this.formAccountingRef = e),
      formApproveRef: (e) => (this.formApproveRef = e),
      requestorRef: (e) => (this.requestorRef = e),
      creatorRef: (e) => (this.creatorRef = e),
      initialRef: (e) => (this.initialRef = e),
      doaRef: (e) => (this.doaRef = e),
      approverRef: (e) => (this.approverRef = e),

      onClickOpenModalFunction: this.onClickOpenModalFunction,
      onChangeInputModal: this.onChangeInputModal,
      onSubmitModalFunction: this.onSubmitModalFunction,
      onClickSelectFile: (e) => this.attachFileRef.open(),
      onClickDownloadDebit: this.onClickDownloadDebit,
    };

    return (
      <>
        <CreateTt
          {...props}
          state={this.state}
          modalFunctionRef={(e) => (this.modalFunctionRef = e)}
        />
        <AttachFileList
          ref={(e) => (this.attachFileRef = e)}
          transactionNo={this.state.TransactionNo || this.state.DraftTransaction}
          permission={permission}
          callBackSave={this.callBackSave}
          btnPermission={{
            canUpload: this.state.IsCreator,
            canReplace: this.state.IsCreator,
          }}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    ...state.requestTt,
  }),
  {
    getTtRemittance,
    saveTtRemittanceManual,
    addAlert,
    getAccoutingVerify,
    changeStatus,
    getMasterUserDetail,
    getAtatchfileList,
    getHolidayDetail,
    searchHoliday,
    downloadAttachFile,
  }
)(CreateTtContainer);
