import { useCallback } from 'react';

export function useOnChangeInput(dispatch) {
  const onChange = useCallback(
    (e) => {
      const { value, name } = e.target;
      dispatch({
        type: 'UPDATE',
        name,
        value,
        target: e.target,
      });
    },
    [dispatch]
  );

  return onChange;
}
