import React, {
  useRef,
  useState,
  useReducer,
  forwardRef,
  useContext,
  useCallback,
  useImperativeHandle,
} from 'react';

import { store } from '../../../Root';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Interest from '../../../common/Interest';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import { StateContext } from './CreateContractLoan';
import { getPermission } from '../../../common/helpper';
import { addAlert } from '../../../reducers/layout/action';

const permission = getPermission('Funding&Investment', 'Create Contract Loan');

export function InterestCreateModal({ action, editIndex }, ref) {
  const { state, dispatch } = useContext(StateContext);
  const modalRef = useRef(null);
  const interestRef = useRef(null);
  const interestPeriodRef = useRef(null);
  const [isEdit, setIsEdit] = useState(false);
  useImperativeHandle(
    ref,
    () => ({
      open: (isEdit) => {
        setIsEdit(isEdit);
        modalRef.current.open();
      },
      resetData: () => {
        interestRef.current.resetData();
        interestPeriodRef.current.resetPeriod();
      },
      setInterest: (data) => {
        interestRef.current.setData(data);
      },
      setInterestPeriod: (data) => {
        interestPeriodRef.current.setData(data);
      },
    }),
    []
  );

  const onClickSave = useCallback(
    (e) => {
      if (!interestRef.current.formRef.current.checkValidity()) {
        interestRef.current.formRef.current.reportValidity();
        return;
      }

      const isValid = validateInterestPeriod(interestPeriodRef.current.state);
      let errorMsg = '';
      const data = {
        ...interestRef.current.state,
        ...interestPeriodRef.current.state,
        StructureType: action === 'Rollover' ? 'Drawdown' : action,
      };
      const result = calInterestAmount(data);
      if (isNaN(result)) errorMsg = result;
      else data.InterestAmount = result;

      let totalExistsDrawdownAmount = state.DrawdownList.filter((f, i) => {
        if (isEdit && i === editIndex) return false;
        if (action === 'Rollover' && f.Id === data.RollOverRefId) return false;
        return true;
      }).reduce((prev, cur) => Number(prev) + (Number(cur.Amount) || 0), 0);
      totalExistsDrawdownAmount += Number(data.Amount) || 0;
      if (errorMsg)
        store.dispatch(
          addAlert({
            title: 'Warning',
            body: errorMsg,
            type: 'warning',
          })
        );
      else if (!isValid)
        store.dispatch(
          addAlert({
            title: 'Warning',
            body: 'Please specific valid data',
            type: 'warning',
          })
        );
      else if (totalExistsDrawdownAmount > (Number(state.Amount.replace(/,/g, '')) || 0))
        store.dispatch(
          addAlert({
            title: 'Warning',
            body: 'Total Drawdown Amount is more than Loan amount',
            type: 'warning',
          })
        );
      else {
        if (isEdit)
          dispatch({
            type: 'EDIT_INTEREST',
            structureType: action === 'Rollover' ? 'Drawdown' : action,
            index: editIndex,
            payload: data,
          });
        else
          dispatch({
            type: 'ADD_INTEREST',
            structureType: action === 'Rollover' ? 'Drawdown' : action,
            isRollover: action === 'Rollover',
            payload: [data],
          });
        modalRef.current.close();
      }
    },
    [action, isEdit, editIndex]
  );
  return (
    <Modal textHeader={action} size="modal-xl" ref={modalRef} bgHeader="bg-primary">
      <Interest ref={interestRef} />
      <InterestPeriod action={action} ref={interestPeriodRef} />
      {permission ? (
        <Col2 col="col-sm-12 text-center">
          <Button txt="Save" className="btn-success" icon="save" onClick={onClickSave} />
        </Col2>
      ) : null}
    </Modal>
  );

  function calInterestAmount(data) {
    const { moment } = window;
    const { InterestRate, Amount, ValidFromStr, ValidToStr, IsManualInclude, CalculateMethod } =
      data;
    if (!ValidFromStr) return 'Please specific ValidFrom';
    if (!ValidToStr) return 'Please specific ValidTo';

    let daysDiff = moment(ValidToStr, 'DD/MM/YYYY').diff(
      moment(ValidFromStr, 'DD/MM/YYYY'),
      'days'
    );
    if (daysDiff < 0) return 'ValidFrom must less or equal from ValidTo.';
    if (!Amount) return 'Amount must more than Zero.';
    if (!InterestRate || !Number(InterestRate)) return 'Interest must more than Zero.';
    if (IsManualInclude) daysDiff += 1;

    let totalDayinYear = 0;
    switch (CalculateMethod) {
      case 'Act/360':
        totalDayinYear = 360;
        break;
      case 'Act/366':
        totalDayinYear = 366;
        break;
      case 'Act/365':
        totalDayinYear = 365;
        break;
      default:
        totalDayinYear = isLeapYear(moment(ValidToStr, 'DD/MM/YYYY').year()) ? 366 : 365;
    }

    return (((Number(InterestRate) / 100) * Number(Amount)) / totalDayinYear) * daysDiff;
  }

  function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }

  function validateInterestPeriod(period) {
    let isValid = true;
    for (const key in period) {
      if (period[key] === null || period[key] === '') isValid = false;
    }
    return isValid;
  }
}
export default forwardRef(InterestCreateModal);

const InterestPeriod = forwardRef(({ action }, ref) => {
  const [state, dispatch] = useReducer(reducer, { ...initInterestPeriod });

  useImperativeHandle(
    ref,
    () => ({
      resetPeriod: () => {
        dispatch({
          type: 'RESET',
        });
      },
      state,
      setData: (datas) => {
        dispatch({
          type: 'SET_ITEM',
          payload: datas,
        });
      },
    }),
    [state]
  );

  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    dispatch({
      type: 'EDIT',
      name,
      value,
    });
  });
  const isRollover = (action || '').toLowerCase() === 'rollover';
  return (
    <Card textHeader={`${action} Structure`} cardActions={['toggler']}>
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text={`${action} Date`} required>
            <DatePicker
              onChange={onChange}
              option={{
                daysOfWeekDisabled: '0,6',
                todayHighlight: true,
              }}
              required
              value={state.ContractDateStr}
              name="ContractDateStr"
              disabled={!permission}
            />
          </FormGroup2>
          <FormGroup2 text="Amount" required>
            <InputMask
              className="form-control"
              format="currency"
              onChange={onChange}
              option={{
                prefix: '',
                digits: 3,
              }}
              disabled={isRollover}
              type="text"
              required={!isRollover}
              value={state.Amount}
              name="Amount"
              disabled={!permission}
            />
          </FormGroup2>
        </Col2>
      </Col2>
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Valid From" required>
            <DatePicker
              onChange={onChange}
              option={{
                daysOfWeekDisabled: '0,6',
                todayHighlight: true,
              }}
              required
              value={state.ValidFromStr}
              name="ValidFromStr"
              disabled={!permission}
            />
          </FormGroup2>
          <FormGroup2 text="Valid To" required>
            <DatePicker
              onChange={onChange}
              option={{
                daysOfWeekDisabled: '0,6',
                todayHighlight: true,
              }}
              required
              value={state.ValidToStr}
              name="ValidToStr"
              disabled={!permission}
            />
          </FormGroup2>
        </Col2>
      </Col2>
    </Card>
  );
});

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const initInterestPeriod = {
  Amount: '',
  ContractDateStr: '',
  ValidFromStr: '',
  ValidToStr: '',
};
const reducer = (state = [], action) => {
  switch (action.type) {
    case 'RESET':
      return { ...initInterestPeriod };
    case 'EDIT':
      return {
        ...state,
        [action.name]: action.value,
      };
    case 'SET_ITEM':
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};
