import React, { useRef, useMemo, forwardRef, useImperativeHandle } from 'react';

import Modal from './Modal';
import DataTable from './DataTable';

function ModalReport({ opt = {}, value = {}, children, dataTableId }, ref) {
  const modalRef = useRef(null);
  const optionDt = useMemo(
    () => ({
      ...opt,
      createdRow: (row, data) => {
        if (data.isError) row.classList.add('bg-danger');
        else row.classList.remove('bg-danger');
      },
    }),
    [opt]
  );

  useImperativeHandle(
    ref,
    () => ({
      open: modalRef.current.open,
      close: modalRef.current.close,
    }),
    []
  );

  const { rows, error, success } = value;
  return (
    <Modal size="modal-lg" ref={modalRef} bgHeader="bg-info" textHeader="Report">
      <DataTable
        id={dataTableId}
        className="table table-bordered table-nowrap"
        option={optionDt}
        value={rows || []}
      >
        {children}
      </DataTable>
    </Modal>
  );
}
export default forwardRef(ModalReport);
