import qs from 'querystring';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import Management from './Management';
import AttachFileList from './AttachFileList';
import { addAlert } from '../../reducers/layout/action';
import ImportExcelTTRemittance from './ImportExcelTTRemittance';
import { automate } from '../../reducers/exposurePosition/action';
import {
  toLower,
  compareDate,
  checkWeekend,
  getPermission,
  checkAfterValueDate,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByRowIndex,
  setCheckedCheckboxDataTableByInputElement,
} from '../../common/helpper';
import {
  exportPdf,
  changeStatus,
  editCriteria,
  getAtatchfile,
  clearCriteria,
  saveAttachFile,
  clearQuoteRate,
  splitTtRemittance,
  mergeTtRemittance,
  searchTtRemittance,
  updateSearchResult,
  updateTtRemittance,
  undoSplitTtRemittance,
} from '../../reducers/ttRemittance/action';

const permission = getPermission('T/T Remittance', 'Manage');

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-tt-remittance';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const initModalEdit = {
  CompanyCode: '',
  BankCode: '',
  SwiftCode: '',
  BankAccountNo: '',
  TransferMethod: '',
  ValueDateStr: '',
  PaidFor: '',
  PreAdvice: '',
  RemittedToCurrency: '',
  Action: '',
  TransactionNos: [],
  BankAbbreviate: '',
};

const initModalChangeStatus = {
  AssignType: '',
  Approver1: '',
  Approver2: '',
  Reviewer: '',
  Remark: '',
  Action: '',
  FinanceGroupId: '',
  setAssign2Only: true,
  setReviewer: false,
  TransactionNos: [],
  TransactionNosForVpay: [],
  ValueDateStrs: [],
};

class ManagementContainer extends Component {
  state = {
    modalEdit: {
      ...initModalEdit,
    },
    modalSplit: {
      originalAmount: 0,
      amount: 0,
      splitNo: '',
      transactionNo: '',
      split: [],
    },
    modalChangeStatus: {
      ...initModalChangeStatus,
    },
    modalUploadFile: {
      TransactionNos: [''],
      SelectFile: null,
      Action: '',
    },
    modalForward: {
      ForwardContractNo: '',
    },
    modalMerge: {
      SpecialMessage: '',
    },
    modalChangeApprover: {
      TransactionNos: [''],
      Approver1: '',
      Approver2: '',
      FinanceGroupId: '',
      Mode: '',
      Reviewer: '',
      Verifier: '',
    },
    modalChangeApproverMultiTrans: {
      transactionsData: [],
      resultFail: [],
      Approver: '',
      ApproverType: '',
      FinanceGroupId: '',
    },
    lastSearchCriteria: null,
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `td.col-label-checkbox-by-group`, this.onClickCheckboxSelectedGroupNo);
    $('body').on('click', `button.edit-result`, this.onClickColumnOpenModalEdit);
    $('body').on('click', `button.print-result`, (e) => this.onClickFunctionColumn(e, 'print'));
    $('body').on('click', `button.split-result`, (e) => this.onClickFunctionColumn(e, 'split'));
    $('body').on('click', `button.forward-result`, (e) => this.onClickOpenModalForward(e));
    $('body').on('click', `button.undo-split-result`, (e) =>
      this.onClickFunctionColumn(e, 'undo split')
    );
    $('body').on('click', `button.attach-file-result`, (e) =>
      this.onClickFunctionColumn(e, 'attach file')
    );
    $('body').on('click', `button.download-file-result`, (e) =>
      this.onClickFunctionColumn(e, 'download file')
    );
    $('body').on('click', `a.change-approver-1`, (e) =>
      this.onClickOpenChangeApprover(e, 'approver1')
    );
    $('body').on('click', `a.change-approver-2`, (e) =>
      this.onClickOpenChangeApprover(e, 'approver2')
    );
    $('body').on('click', `a.change-reviewer`, (e) =>
      this.onClickOpenChangeApprover(e, 'reviewer')
    );
    $('body').on('click', `a.change-verify`, (e) => this.onClickOpenChangeApprover(e, 'verify'));

    let query = this.props.routeProp.location.search.replace('?', '');
    query = qs.parse(query);
    if (query.s !== undefined) {
      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchTtRemittance({ ...this.props.criteria });
    }
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `td.col-label-checkbox-by-group`, this.onClickCheckboxSelectedGroupNo);
    $('body').off('click', `button.edit-result`, this.onClickColumnOpenModalEdit);
    $('body').off('click', `button.print-result`);
    $('body').off('click', `button.split-result`);
    $('body').off('click', `button.forward-result`);
    $('body').off('click', `button.undo-split-result`);
    $('body').off('click', `button.attach-file-result`);
    $('body').off('click', `button.download-file-result`);
    $('body').off('click', `a.change-approver-1`);
    $('body').off('click', `a.change-approver-2`);
    $('body').off('click', `a.change-reviewer`);
    $('body').off('click', `a.change-verify`);
  }

  setImportExcelRef = (e) => {
    this.importExcel = e;
  };

  onClickCheckboxSelectedGroupNo = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const result = setCheckedCheckboxDataTableByInputElement(e, $dt, 'col-label-checkbox-by-group');
    if (result) {
      setCheckedCheckboxDataTableByInputElement(e, $dt, 'col-label-checkbox', result.checked);

      const { groupTtNo } = $dt.row(result.row).data();
      this.props.searchResult.forEach((m, i) => {
        if (m.groupTtNo === groupTtNo) {
          m.IsSelected = result.checked;
          m.IsSelectedAll = result.checked;

          if (i !== result.row) {
            setCheckedCheckboxDataTableByRowIndex(
              i,
              $dt,
              'col-label-checkbox-by-group',
              result.checked
            );
            setCheckedCheckboxDataTableByRowIndex(i, $dt, 'col-label-checkbox', result.checked);
          }
        }
      });
      this.forceUpdate();
    }
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    if (result) {
      this.props.searchResult[result.row].IsSelected = result.checked;
      this.forceUpdate();
    }
  };

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchTtRemittance({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };
  // ---------------- End Search ----------------

  // ---------------- Edit Value ----------------
  onClickColumnOpenModalEdit = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];
    this.setState(
      {
        modalEdit: {
          ...initModalEdit,
          CompanyCode: selectedData.companyCode,
          BankAccountNo: selectedData.bankAccountNo,
          BankCode: selectedData.bankCode,
          SwiftCode: selectedData.bankCode,
          BankAbbreviate: selectedData.bankAbbreviate,
          ValueDateStr: selectedData.valueDateStr,
          TransferMethod: selectedData.transferMethod,
          PaidFor: selectedData.paidFor,
          PreAdvice: selectedData.preAdvice,
          RemittedToCurrency: selectedData.remittedToCurrency,
          Action: 'edit',
          RemittedToCurrency: selectedData.remittedToCurrency,
          TransactionNos: [selectedData.transactionNo],
          OldValueDateStr: selectedData.valueDateStr,
          OldTransferMethod: selectedData.transferMethod,
        },
      },
      this.modalEditRef.open
    );
  };

  onClickOpenModalEdit = (action) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Please select item for ${action}!`,
      });
      return;
    }

    let checkStatus = true;
    let checkGroup = true;
    let checkCompany = true;
    let company = '';
    selectedData.forEach((m) => {
      if (toLower(m.statusTt) !== 'wait for confirm') {
        checkStatus = false;
      }

      if (m.groupTtNo) {
        checkGroup = false;
      }

      if (!company) {
        company = m.companyCode;
      } else if (company !== m.companyCode) {
        checkCompany = false;
      }
    });

    if (!checkGroup && (action === 'change method' || action === 'change bank')) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Transaction already merge can not be ${action}.`,
      });
      return;
    }

    if (!checkCompany && action === 'change bank') {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item that in same company for change bank.',
      });
      return;
    }

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected has some transactions that status not "Wait for confirm".',
      });
      return;
    }

    this.setState(
      {
        modalEdit: {
          ...initModalEdit,
          Action: action,
          TransferMethod: selectedData[0].transferMethod,
          SwiftCode: selectedData[0].bankCode,
          CompanyCode: selectedData[0].companyCode,
          TransactionNos: selectedData.map((m) => m.transactionNo),
        },
      },
      this.modalEditRef.open
    );
  };

  onChangeModalEditHeadler = (e) => {
    const { name, value, label } = e.target;

    if (name === 'SwiftCode') {
      const bankAbbreviate = (label || '').split('|')[1].trim() || '';
      this.setState({
        modalEdit: {
          ...this.state.modalEdit,
          [name]: value,
          BankAbbreviate: bankAbbreviate,
          BankAccountNo: '',
        },
      });
    } else {
      this.setState({
        modalEdit: {
          ...this.state.modalEdit,
          [name]: value,
        },
      });
    }
  };

  onClickClearCurrency = (e) => {
    this.setState({
      modalEdit: {
        ...this.state.modalEdit,
        RemittedToCurrency: '',
      },
    });
  };

  onClickSubmitModalEdit = (e) => {
    e.preventDefault();

    const submitData = { ...this.state.modalEdit };

    if (checkWeekend(submitData.ValueDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Value date can not be weekend.',
      });
      return;
    }

    if (toLower(submitData.Action) === 'edit') {
      if (
        !checkAfterValueDate(submitData.ValueDateStr) &&
        toLower(submitData.TransferMethod) === 'sftp' &&
        (submitData.ValueDateStr !== submitData.OldValueDateStr ||
          submitData.TransferMethod !== submitData.OldTransferMethod)
      ) {
        this.props.addAlert({
          title: 'Error',
          type: 'warning',
          body: (
            <span>
              Can not change value date or transfer method
              <br />
              when date overdue on value date and transfer method is SFTP.
            </span>
          ),
        });
        return;
      }

      if (toLower(submitData.TransferMethod) === 'sftp' && submitData.RemittedToCurrency) {
        this.props.addAlert({
          title: 'Error',
          type: 'warning',
          body: (
            <span>
              Can not change transfer method or remitted to currency
              <br />
              when transfer method is SFTP, transaction have not remitted to currency.
            </span>
          ),
        });
        return;
      }

      if (
        toLower(submitData.TransferMethod) === 'sftp' &&
        !~['sicoth', 'bkkbth', 'kasith', 'smbcth', 'ayudth', ''].indexOf(
          toLower(submitData.SwiftCode)
        )
      ) {
        this.props.addAlert({
          title: 'Error',
          type: 'warning',
          body: (
            <span>
              Can not change transfer method or bank
              <br />
              because transfer method is SFTP, bank must be SCB, BBL, KBANK, SMBC or AYUD.
            </span>
          ),
        });
        return;
      }
    } else if (toLower(submitData.Action) === 'change value date') {
      const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
      for (let i = 0; i < selectedData.length; i++) {
        const row = selectedData[i];
        if (
          toLower(row.transferMethod) === 'sftp' &&
          !checkAfterValueDate(submitData.ValueDateStr)
        ) {
          this.props.addAlert({
            title: 'Error',
            type: 'warning',
            body: `Transaction No. ${row.transactionNo} transfer method is SFTP can not edit when date overdue on value date.`,
          });
          return;
        }
      }
    } else if (toLower(submitData.Action) === 'change method') {
      const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
      for (let i = 0; i < selectedData.length; i++) {
        const row = selectedData[i];
        if (
          toLower(submitData.TransferMethod) === 'sftp' &&
          !checkAfterValueDate(row.valueDateStr)
        ) {
          this.props.addAlert({
            title: 'Error',
            type: 'warning',
            body: `Transaction No. ${row.transactionNo} can not change transfer method is SFTP because date overdue on value date ${row.valueDateStr}.`,
          });
          return;
        }

        if (toLower(submitData.TransferMethod) === 'sftp' && row.remittedToCurrency) {
          this.props.addAlert({
            title: 'Error',
            type: 'warning',
            body: `Transaction No. ${row.transactionNo} can not change transfer method is SFTP because transaction have Remitted To Currency.`,
          });
          return;
        }

        if (
          toLower(submitData.TransferMethod) === 'sftp' &&
          !~['sicoth', 'bkkbth', 'kasith', 'smbcth', 'ayudth'].indexOf(toLower(row.bankCode)) &&
          row.bankCode
        ) {
          this.props.addAlert({
            title: 'Error',
            type: 'warning',
            body: `Transaction No. ${row.transactionNo} can not change transfer method is SFTP because bank is not SCB, BBL, KBANK, SMBC or AYUD.`,
          });
          return;
        }
      }
    } else if (toLower(submitData.Action) === 'change bank') {
      const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
      for (let i = 0; i < selectedData.length; i++) {
        const row = selectedData[i];
        if (
          toLower(row.transferMethod) === 'sftp' &&
          !~['sicoth', 'bkkbth', 'kasith', 'smbcth', 'ayudth'].indexOf(
            toLower(submitData.SwiftCode)
          ) &&
          row.transferMethod
        ) {
          this.props.addAlert({
            title: 'Error',
            type: 'warning',
            body: `Transaction No. ${row.transactionNo} can not change bank because transfer method is SFTP, bank must be SCB, BBL, KBANK, SMBC or AYUD.`,
          });
          return;
        }
      }
    }

    delete submitData.CompanyCode;
    delete submitData.BankAbbreviate;
    this.props
      .updateTtRemittance(
        {
          ...submitData,
        },
        { ...this.props.criteria }
      )
      .then(() => {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.modalEditRef.close();
      });
  };
  // ---------------- End Edit Value ----------------

  // ---------------- Button Function ----------------
  onClickOpenModalFunction = (modal) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Please select item for ${modal}!`,
      });
      return;
    }

    if (modal === 'merge') {
      if (selectedData.length === 1) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please select item more than 1 item for merge.',
        });
        return;
      }
      if (this.onCheckAlreadySplitAndMerge(selectedData)) {
        this.onClickOpenModalMerge(selectedData);
      }
    } else if (modal === 'unmerge') {
      if (this.checkSelectedAllGroup(selectedData)) {
        this.onClickOpenModalUnmerge(selectedData);
      }
    } else if (modal === 'attach file') this.onClickOpenModalAttachFile(selectedData);
    else if (modal === 'confirm') {
      if (this.checkSelectedAllGroup(selectedData)) {
        this.onClickOpenModalConfirm(selectedData, modal);
      }
    } else if (modal === 'reject') {
      if (this.checkSelectedAllGroup(selectedData)) {
        if (this.onCheckAlreadySplitAndMerge(selectedData)) {
          this.onClickOpenModalChangeStatus(selectedData, modal);
        }
      }
    } else if (modal === 'spot') {
      if (this.checkMerge(selectedData)) {
        this.onClickOpenModalChangeStatus(selectedData, modal);
      }
    } else if (modal === 'completed') {
      if (this.checkSelectedAllGroup(selectedData)) {
        this.onClickOpenModalChangeStatus(selectedData, modal);
      }
    } else if (this.checkSelectedAllGroup(selectedData)) {
      this.onClickOpenModalChangeStatus(selectedData, modal);
    }
  };

  checkMerge = (selected) => {
    for (let i = 0; i < selected.length; i++) {
      const r = selected[i];
      if (r.groupTtNo) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Please undo merge that Group ID : ${r.groupTtNo}.`,
        });
        return false;
      }
    }
    return true;
  };

  onCheckAlreadySplitAndMerge = (selected) => {
    for (let i = 0; i < selected.length; i++) {
      const r = selected[i];
      if (r.groupTtNo) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Please undo merge that Group ID : ${r.groupTtNo}.`,
        });
        return false;
      }

      if (r.spiltRef) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Please undo split that Transaction No. ${r.transactionNo}.`,
        });
        return false;
      }

      if (r.amount !== r.netPaidAmount) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: (
            <span>
              Transaction No. {r.transactionNo} already split.
              <br />
              Please Undo Split transaction that Split.
            </span>
          ),
        });
        return false;
      }
    }
    return true;
  };

  checkSelectedAllGroup = (selectedData) => {
    const distinctGroupNo = selectedData
      .map((m) => m.groupTtNo)
      .filter((f, i, s) => s.indexOf(f) === i);
    for (let i = 0; i < distinctGroupNo.length; i++) {
      if (distinctGroupNo[i]) {
        const allItemInGroupNo = this.props.searchResult.filter(
          (f) => f.groupTtNo === distinctGroupNo[i]
        );
        const selectedItemInGroupNo = allItemInGroupNo.filter((f) => f.IsSelected);
        if (allItemInGroupNo.length !== selectedItemInGroupNo.length) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: `Please select all item in Gruop ID ${distinctGroupNo[i]}.`,
          });
          return false;
        }
      }
    }
    return true;
  };

  onClickOpenModalUnmerge = (selectedData) => {
    let checkGroup = true;
    let checkStatus = true;

    selectedData.forEach((m) => {
      if (!m.groupTtNo) {
        checkGroup = false;
      }
      if (toLower(m.statusTt) !== 'wait for confirm') {
        checkStatus = false;
      }
    });

    if (!checkGroup) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Can not unmerge because you selected item at no merge.',
      });
      return;
    }

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item at status: Wait for confirm.',
      });
      return;
    }

    this.modalUnmergeRef.open();
  };

  onClickConfirmModalUnmerge = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    const submitData = {
      TransactionNos: selectedData.map((m) => m.transactionNo),
    };
    this.props.mergeTtRemittance(submitData, 'u').then((response) => {
      if (!response.error) {
        this.modalUnmergeRef.close();
        this.props.searchTtRemittance({ ...this.props.criteria });
      }
    });
  };

  onClickOpenModalConfirm = (selectedData, modal) => {
    const group = [];
    let checkStatus = true;
    let checkSFTP = false;
    let checkgroup1 = false;
    let checkgroup2 = false;
    let checkgroup3 = false;
    let checkValue = true;
    let setAssign2Only = true;
    let setReviewer = false;
    let checkDate = true;
    let checkFinanceGroup = true;
    let financeGroupId = '';
    const transactionNosForVpay = [];

    selectedData.forEach((m) => {
      if (!m.transferMethod || !m.bankAbbreviate || !m.valueDateStr || !m.bankAccountNo) {
        checkValue = false;
      }

      if (toLower(m.transferMethod) === 'sftp' && toLower(m.statusTt) === 'wait for confirm') {
        checkSFTP = true;
      }

      if (toLower(m.transferMethod) === 'sftp' && !checkAfterValueDate(m.valueDateStr)) {
        checkDate = false;
      }

      if (toLower(m.statusTt) === 'wait for confirm') {
        group.push(m);
      } else {
        checkStatus = false;
      }

      if (m.financeGroupId === '002' || m.financeGroupId === '003') {
        checkgroup1 = true;
        setAssign2Only = false;
      } else if (
        m.financeGroupId === '005' ||
        m.financeGroupId === '006' ||
        m.financeGroupId === '008'
      ) {
        checkgroup3 = true;
        setReviewer = true;
      } else {
        checkgroup2 = true;
        setAssign2Only = true;
        setReviewer = false;
      }

      if (financeGroupId === '') {
        financeGroupId = m.financeGroupId;
      } else if (financeGroupId !== m.financeGroupId) {
        checkFinanceGroup = false;
      }

      if (toLower(m.statusTt) === 'wait for confirm') {
        if (m.vPayId) {
          transactionNosForVpay.push({
            TransactionNo: m.transactionNo,
            VPayId: m.vPayId,
            VPayNo: m.vPayNo,
          });
        }
      }
    });

    if (!checkValue) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: (
          <span>
            Incomplete filling data "Transfer Method, Bank, Bank Account and Value Date".
            <br />
            Please fully fill details into the blank.
          </span>
        ),
      });
      return;
    }

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected has some transactions that status not "Wait for confirm".',
      });
      return;
    }

    if (!checkDate) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: "There're some transaction that date overdue on value date.",
      });
      return;
    }

    if ((checkgroup1 && checkgroup2) || (checkgroup1 && checkgroup3)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: (
          <span>
            Your selected item included finance group "SCG International".
            <br />
            Please select a new item again.
          </span>
        ),
      });
      return;
    }

    if (checkgroup2 && checkgroup3) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: (
          <span>
            Your selected item included finance group "Cementhai Ceramic, Ceramic, Siam Ware".
            <br />
            Please select a new item again.
          </span>
        ),
      });
      return;
    }

    if (!checkFinanceGroup && checkSFTP) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select same finance group.',
      });
      return;
    }

    this.setState({
      modalChangeStatus: {
        ...initModalChangeStatus,
        AssignType: 'Assign Approver',
        Approver1: '',
        Approver2: '',
        Reviewer: '',
        Remark: '',
        Action: modal,
        setAssign2Only,
        setReviewer,
        FinanceGroupId: financeGroupId,
        TransactionNos: group.map((m) => m.transactionNo),
        TransactionNosForVpay: transactionNosForVpay,
      },
    });

    if (checkSFTP) this.modalConfirmRef.open();
    else this.modalApproveRef.open();
  };

  onClickOpenModalChangeStatus = (selectedData, modal) => {
    const transactionNos = [];
    const valueDates = [];
    const transactionNosForVpay = [];
    let groupError = false;
    const completedError = false;
    let checkTransferMethod = true;

    if (modal === 'unconfirm') {
      selectedData.forEach((m) => {
        transactionNos.push(m.transactionNo);
        valueDates.push(m.valueDateStr);
      });
      // noItemChangeStatus = 'Please select at least 1 item Status: Confirmed or Disapprove.'
      // selectedData.forEach(m => {
      //     if (toLower(m.statusTt) === 'confirmed' || toLower(m.statusTt) === 'disapprove') {
      //         transactionNos.push(m.transactionNo)
      //     }
      // })
    } else if (modal === 'reject') {
      selectedData.forEach((m) => {
        if (toLower(m.statusTt) === 'wait for confirm') {
          transactionNos.push(m.transactionNo);
          valueDates.push(m.valueDateStr);
          if (m.vPayId) {
            transactionNosForVpay.push({
              TransactionNo: m.transactionNo,
              VPayId: m.vPayId,
              VPayNo: m.vPayNo,
            });
          }
        } else {
          groupError = true;
        }
      });

      // if (!transactionNos.length) {
      //     this.props.addAlert({
      //         title: 'Error',
      //         type: 'error',
      //         body: 'Please select transaction must be V-Pay Id.'
      //     })
      //     return
      // }
    } else if (modal === 'spot') {
      selectedData.forEach((m) => {
        if (!m.transferMethod) {
          checkTransferMethod = false;
        }

        if (toLower(m.statusTt) === 'wait for confirm') {
          transactionNos.push(m.transactionNo);
          valueDates.push(m.valueDateStr);
        } else {
          groupError = true;
        }
      });
    } else if (modal === 'completed') {
      selectedData.forEach((m) => {
        if (
          toLower(m.statusTt) === 'wait for bank receive' ||
          toLower(m.statusTt) === 'failed' ||
          toLower(m.statusTt) === 'accepted by bank'
        ) {
          transactionNos.push(m.transactionNo);
        }
      });
    }

    if (groupError) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected has some transactions that status not "Wait for confirm".',
      });
      return;
    }

    // if (completedError) {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: 'Transaction you selected has some transactions that status not "Wait for bank receive, Accepted by bank and Failed ".'
    //     })
    //     return
    // }

    if (!checkTransferMethod) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected must be transfer method.',
      });
      return;
    }

    this.setState({
      modalChangeStatus: {
        ...initModalChangeStatus,
        AssignType: '',
        Approver1: '',
        Approver2: '',
        Remark: '',
        Action: modal,
        TransactionNos: transactionNos,
        TransactionNosForVpay: transactionNosForVpay,
        ValueDateStrs: valueDates,
      },
    });

    if (modal === 'unconfirm') this.modalUnconfirmRef.open();
    else if (modal === 'not confirm') this.modalNotConfirmRef.open();
    else if (modal === 'reject') this.modalRejectRef.open();
    else if (modal === 'spot') this.modalSpotRateRef.open();
    else if (modal === 'completed') this.modalCompletedRef.open();
  };

  onChangeModalChangeStatus = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        modalChangeStatus: {
          ...this.state.modalChangeStatus,
          [name]: value,
        },
      };

      if (name === 'Approver1') {
        if (value === state.modalChangeStatus.Approver2) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select user not same assign approver2.',
          });
          stateToUpdate.modalChangeStatus.Approver1 = state.modalChangeStatus.Approver1;
        }
      } else if (name === 'Approver2') {
        if (value === state.modalChangeStatus.Approver1) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select user not same assign approver1.',
          });
          stateToUpdate.modalChangeStatus.Approver2 = state.modalChangeStatus.Approver2;
        }
      }

      return stateToUpdate;
    });
  };

  onSubmitModalChangeStatus = (e, modal) => {
    e.preventDefault();
    this.props
      .changeStatus({
        ...this.state.modalChangeStatus,
        Action: modal,
      })
      .then((response) => {
        if (!response.error) {
          this.modalApproveRef.close();
          this.modalConfirmRef.close();
          this.modalUnconfirmRef.close();
          this.modalNotConfirmRef.close();
          this.modalRejectRef.close();
          this.modalSpotRateRef.close();
          this.modalCompletedRef.close();
          clearCheckBoxAllTable(DATATABLE_ID);
          this.props.searchTtRemittance({ ...this.props.criteria });
        }
      });
  };

  // Merge
  onClickOpenModalMerge = (selectedData) => {
    let check = true;
    let checkPreAdvice = true;
    let checkGroup = true;
    let checkStatus = true;
    let checkSameValue = true;

    let company = '';
    let transfermethod = '';
    let bank = '';
    let bankAccount = '';
    let accountNo = '';
    let ibanNo = '';
    let preadvice = '';

    selectedData.forEach((m) => {
      if (
        !m.transferMethod ||
        !m.bankAbbreviate ||
        !m.bankAccountNo ||
        !m.valueDateStr ||
        !m.preAdvice ||
        (!m.accountNo && !m.ibanNo)
      ) {
        check = false;
      }

      if (m.groupTtNo) {
        checkGroup = false;
      }

      if (toLower(m.preAdvice) !== 'none' && toLower(m.preAdvice) !== '1 day') {
        checkPreAdvice = false;
      }

      if (toLower(m.statusTt) !== 'wait for confirm') {
        checkStatus = false;
      }

      if (company !== '') {
        if (
          company !== m.companyCode ||
          transfermethod !== m.transferMethod ||
          bank !== m.bankAbbreviate ||
          bankAccount !== m.bankAccountNo ||
          accountNo !== m.accountNo ||
          ibanNo !== m.ibanNo ||
          preadvice !== m.preAdvice
        ) {
          checkSameValue = false;
        }
      } else {
        company = m.companyCode;
        transfermethod = m.transferMethod;
        bank = m.bankAbbreviate;
        bankAccount = m.bankAccountNo;
        accountNo = m.accountNo;
        ibanNo = m.ibanNo;
        preadvice = m.preAdvice;
      }
    });

    if (!check) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: (
          <span>
            Incomplete filling data "Transfer Method, Bank, Bank Account, Value Date and Beneficiary
            Account No. or IBAN No.".
            <br />
            Please fully fill details into the blank.
          </span>
        ),
      });
      return;
    }

    if (!checkGroup) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you select already to merge.',
      });
      return;
    }

    if (!checkPreAdvice) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected must be pre advice : None or 1 Day.',
      });
      return;
    }

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected must be status : Wait for confirm.',
      });
      return;
    }

    if (!checkSameValue) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item that in same Company, Bank, Bank Account No., Transfer Method and Beneficiary Account No. or IBAN No.',
      });
      return;
    }

    this.setState({
      modalMerge: {
        SpecialMessage: '',
      },
    });
    this.modalMergeRef.open();
  };

  onClickConfirmModalMerge = (e) => {
    e.preventDefault();
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);

    let checkValueDate = true;
    let valuedate = '';

    selectedData.forEach((m) => {
      if (valuedate !== '') {
        if (valuedate !== m.valueDateStr) {
          checkValueDate = false;
          if (compareDate(valuedate, m.valueDateStr)) {
            valuedate = m.valueDateStr;
          }
        }
      } else {
        valuedate = m.valueDateStr;
      }
    });

    if (!checkValueDate) {
      this.props.addAlert({
        type: 'warning',
        title: 'Warning',
        body: `Transaction you selected different value date. Do you want to merge at value date is ${valuedate}?`,
        buttons: [
          <button
            className="btn btn-warning btn-sm"
            data-dismiss="modal"
            onClick={(e) => this.onClickConfirmMerge(selectedData, valuedate)}
          >
            Confirm
          </button>,
          <button
            className="btn btn-default btn-sm"
            data-dismiss="modal"
            onClick={(e) => this.modalMergeRef.close()}
          >
            Cancel
          </button>,
        ],
      });
      return;
    }
    this.onClickConfirmMerge(selectedData, valuedate);
  };

  onChangeModalMerge = (e) => {
    this.setState({
      modalMerge: {
        ...this.state.modalMerge,
        [e.target.name]: e.target.value,
      },
    });
  };

  onClickConfirmMerge = (selectedData, valuedate) => {
    const submitData = {
      TransactionNos: selectedData.map((m) => m.transactionNo),
      // ValueDateStr: valuedate,
      SpecialMessage: this.state.modalMerge.SpecialMessage,
    };
    this.props.mergeTtRemittance(submitData, 'm').then((response) => {
      if (!response.error) {
        this.modalMergeRef.close();
        clearCheckBoxAllTable(DATATABLE_ID);
        this.props.searchTtRemittance({ ...this.props.criteria });
      }
    });
  };

  // ---------------- End Button Function ----------------

  // ---------------- Column Function ----------------
  onClickFunctionColumn = (e, modal) => {
    if (modal === 'print') this.onClickPrintColumn(e);
    else if (modal === 'split') this.onClickColumnOpenModalSplit(e);
    else if (modal === 'undo split') this.onClickOpenModalUndoSplitColumn(e);
    else if (modal === 'attach file') this.onClickAttachFileColumn(e);
    else if (modal === 'download file') this.onClickDownloadFileColumn(e);
  };

  onClickPrintColumn = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.props.exportPdf([rowData.transactionNo]);
  };

  onClickColumnOpenModalSplit = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalSplit: {
          originalAmount: rowData.amount || 0,
          amount: rowData.amount || 0,
          splitNo: rowData.splitNo,
          transactionNo: rowData.transactionNo,
          split: [
            {
              splitNo: 1,
              amount: '0',
            },
          ],
        },
      },
      this.modalSplitRef.open
    );
  };

  onClickAddSplit = (e) => {
    this.setState((state) => {
      const { split } = state.modalSplit;
      return {
        modalSplit: {
          ...state.modalSplit,
          split: [
            ...state.modalSplit.split,
            {
              splitNo: split[split.length - 1].splitNo + 1,
              amount: '0',
            },
          ],
        },
      };
    });
  };

  onChangeSplitAmount = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const modalSplit = {
        ...state.modalSplit,
        split: state.modalSplit.split.map((m, i) => {
          if (i === index)
            return {
              ...m,
              [name]: value,
            };
          return m;
        }),
      };
      const totalAmountSplit = modalSplit.split.reduce(
        (prev, cur, idx, self) => prev + Number(cur.amount || 0),
        0
      );

      if (totalAmountSplit > modalSplit.originalAmount) {
        this.props.addAlert({
          type: 'warning',
          title: 'Warning',
          body: 'Amount for split must less than original amount',
        });
        return;
      }

      modalSplit.amount = modalSplit.originalAmount - totalAmountSplit;
      return {
        modalSplit,
      };
    });
  };

  onSubmitModalSplit = (e) => {
    e.preventDefault();
    const { modalSplit } = this.state;
    const amount = modalSplit.split.filter((f) => f.amount).map((m) => m.amount);
    const total = amount.reduce((prev, cur) => prev + Number(cur), 0);
    if (total >= modalSplit.originalAmount) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Net paid amount not enough for split',
      });
      return;
    }
    const submitdata = {
      TransactionNo: modalSplit.transactionNo,
      Amounts: amount,
    };
    this.props.splitTtRemittance(submitdata).then((response) => {
      if (!response.error) {
        this.modalSplitRef.close();
        this.props.searchTtRemittance({ ...this.props.criteria });
      }
    });
  };

  onClickOpenModalUndoSplitColumn = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    this.setState(
      {
        selectedTransactionNo: rowData.transactionNo,
      },
      this.modalUndoRef.open
    );
  };

  onClickConfirmModalUndo = (e) => {
    const { selectedTransactionNo } = this.state;
    if (!selectedTransactionNo) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for undo split.',
      });
      return;
    }
    this.props.undoSplitTtRemittance(selectedTransactionNo).then((response) => {
      if (!response.error) {
        this.modalUndoRef.close();
        this.props.searchTtRemittance({ ...this.props.criteria });
      }
    });
  };
  // ---------------- End Column Function ----------------

  // ---------------- Attach / Download File ----------------
  onClickAttachFileColumn = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        selectedAttachFilesTransactionNo: rowData.transactionNo,
        statusTt: rowData.statusTt,
        isCheckAllpay: rowData.isCheckAllpay,
      },
      this.attachFileRef.open
    );
    // this.inputAttachfileRef.value = ''
    // this.setState({
    //     modalUploadFile: {
    //         TransactionNos: [rowData.transactionNo],
    //         SelectFile: null,
    //         Action: 'column'
    //     }
    // })
    // this.modalUploadFileRef.open()
  };

  onClickDownloadFileColumn = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    if (!rowData.attachFile) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Attach file not found.',
      });
      return;
    }
    this.props.getAtatchfile(rowData.attachFile);
  };

  onClickOpenModalAttachFile = (selectedData) => {
    this.inputAttachfileRef.value = '';
    this.setState({
      modalUploadFile: {
        TransactionNos: selectedData.map((m) => m.transactionNo),
        SelectFile: null,
        Action: 'selected',
      },
    });

    this.modalUploadFileRef.open();
  };

  onChangeAttachFile = (e) => {
    const { files } = e.target;

    if (!files || !files.length) {
      this.props.addAlert({
        title: 'Warning',
        type: 'warning',
        body: 'Please select file',
      });
      return;
    }

    this.setState((state) => ({
      modalUploadFile: {
        ...state.modalUploadFile,
        SelectFile: files[0],
      },
    }));
  };

  onSubmitSaveUploadFile = (e) => {
    e.preventDefault();

    const modalUploadFile = { ...this.state.modalUploadFile };
    if (!modalUploadFile.SelectFile) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select file for upload.',
      });
      return;
    }

    const form = new FormData();
    form.append('file', modalUploadFile.SelectFile);
    delete modalUploadFile.SelectFile;
    form.append('data', JSON.stringify(modalUploadFile));
    this.props.saveAttachFile(form).then((response) => {
      if (!response.error) {
        this.modalUploadFileRef.close();
        this.props.searchTtRemittance({ ...this.props.criteria });
      }
    });
  };
  // ---------------- End Attach / Download File ----------------

  // ---------------- Select Forward ----------------
  onClickOpenModalForward = (e) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];
    const { transactionNo } = selectedData;

    if (!selectedData.transferMethod) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected must be transfer method.',
      });
      return;
    }

    this.props.routeProp.history.push(`/tt-remittance/forward-tt/${transactionNo}`);
  };

  onClickAssignForward = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for assign forward!',
      });
      return;
    }

    let checkStatus = true;
    let checkTransferMethod = true;
    const transactionNos = [];
    selectedData.forEach((m) => {
      if (toLower(m.statusTt) !== 'wait for confirm') {
        checkStatus = false;
      }
      if (!m.transferMethod) {
        checkTransferMethod = false;
      }
      transactionNos.push(m.transactionNo);
    });

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected must be status : Wait for confirm.',
      });
      return;
    }

    if (!checkTransferMethod) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected must be transfer method.',
      });
      return;
    }

    this.props.routeProp.history.push(`/tt-remittance/forward-tt/${transactionNos}`);
  };
  // ---------------- End Select Forward ----------------

  onClickOpenModalImportExcel = () => {
    this.importExcel.importExcelRef.open();
  };

  onClickMultiPrint = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for print!',
      });
      return;
    }

    let checkSame = true;
    let valuedate = '';
    let company = '';
    let bank = '';

    selectedData.forEach((m) => {
      if (valuedate !== '') {
        if (valuedate !== m.valueDateStr || company !== m.companyCode || bank !== m.bankCode) {
          checkSame = false;
        }
      } else {
        valuedate = m.valueDateStr;
        company = m.companyCode;
        bank = m.bankCode;
      }
    });

    if (!checkSame) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item that in same value date, company and bank.',
      });
      return;
    }

    const transactionNos = selectedData.map((m) => m.transactionNo);
    this.props.exportPdf(transactionNos);
  };

  onClickOpenModalClearQuoteRate = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for clear quote rate!',
      });
      return;
    }

    const dataFilter = selectedData.filter((f) => toLower(f.statusTt) !== 'wait for confirm');
    if (dataFilter.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item status is wait for confirm!',
      });
      return;
    }

    this.modalClearQuoteRateRef.open();
  };

  onClickConfirmClearQuoteRate = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    const transactionNos = selectedData.map((m) => m.transactionNo).join(',');
    this.props.clearQuoteRate({ TransactionNo: transactionNos }).then((response) => {
      if (response.error) return;
      this.modalClearQuoteRateRef.close();
      this.onClickSearchHandler({ preventDefault: () => {} });
    });
  };

  // Change Approver
  onClickOpenChangeApprover = (e, approver) => {
    const row = this.dataTableSearchResultRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const data = this.props.searchResult[row];
    this.setState(
      {
        modalChangeApprover: {
          TransactionNos: [data.transactionNo],
          Approver1: data.approver1,
          Approver2: data.approver2,
          FinanceGroupId: data.financeGroupId,
          Mode: approver,
          Reviewer: data.reviewer,
          Verifier: data.verify,
        },
      },
      this.modalChangeRef.open
    );
  };

  onChangeModalApproveChange = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        modalChangeApprover: {
          ...this.state.modalChangeApprover,
          [name]: value,
        },
      };

      if (name === 'Approver1') {
        if (value === state.modalChangeApprover.Approver2) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select User not same Assign Approver2.',
          });
          stateToUpdate.modalChangeApprover.Approver1 = state.modalChangeApprover.Approver1;
        }
      } else if (name === 'Approver2') {
        if (value === state.modalChangeApprover.Approver1) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Please select User not same Assign Approver1.',
          });
          stateToUpdate.modalChangeApprover.Approver2 = state.modalChangeApprover.Approver2;
        }
      }

      return stateToUpdate;
    });
  };

  onSubmitModalChangeApprover = (e) => {
    e.preventDefault();
    const { TransactionNos, Mode, ...modalChangeApprover } = this.state.modalChangeApprover;
    const submitData = {
      TransactionNos,
      Mode,
    };
    const mapKey = {
      approver1: 'Approver1',
      approver2: 'Approver2',
      reviewer: 'Reviewer',
      verify: 'Verifier',
    };

    submitData[mapKey[Mode]] = modalChangeApprover[mapKey[Mode]];

    this.props.changeStatus(submitData, this.state.criteria).then((response) => {
      if (response.error) return;
      this.modalChangeRef.close();
      this.onClickSearchHandler({ preventDefault: () => {} });
    });
  };

  // change approver multi transaction
  onClickOpenModalChangeApproverMultiTransaction = () => {
    const selectedTransactions = this.props.searchResult.filter((f) => f.IsSelected);
    const transactionsNotBahtnetAndSftp = selectedTransactions.filter(
      (f) => toLower(f.transferMethod) !== 'sftp'
    );
    let error = '';
    if (transactionsNotBahtnetAndSftp.length)
      error = 'Please selecte transaction that transfer method is SFTP.';

    if (!error) {
      const transactionNotInWaitForApprover = selectedTransactions.filter(
        (f) =>
          !(f.approver1 && toLower(f.approver1Status) === 'wait for approve') &&
          !(f.approver2 && toLower(f.approver2Status) === 'wait for approve') &&
          !(f.reviewer && toLower(f.reviewerStatus) === 'wait for approve') &&
          !(f.verify && toLower(f.verifyStatus) === 'wait for approve')
      );
      if (transactionNotInWaitForApprover.length)
        error = `There transaction ${transactionNotInWaitForApprover[0].transactionNo} that cannot change approver.`;
    }

    if (!error) {
      const distinctFinanceGroup = selectedTransactions
        .map((m) => m.financeGroupId)
        .filter((f, i, s) => s.findIndex((sf) => sf === f) === i);
      if (distinctFinanceGroup.length > 1) error = 'Please select same financeGroup';
    }
    if (!error && !selectedTransactions.length) {
      error = 'Please select item for change approver.';
    }
    if (!error) {
      this.setState({
        modalChangeApproverMultiTrans: {
          transactionsData: selectedTransactions,
          resultFail: [],
          Approver: '',
          ApproverType: '',
          FinanceGroupId: selectedTransactions[0].financeGroupId,
        },
      });
      this.modalChangeApproverMultiTransRef.open();
    } else {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: error,
      });
    }
  };

  onSubmitChangeApproverMultiTrans = (e) => {
    e.preventDefault();
    const { Approver, ApproverType, transactionsData } = this.state.modalChangeApproverMultiTrans;
    const submitData = {
      TransactionNos: transactionsData.map((m) => m.transactionNo),
      Mode: ApproverType,
      [ApproverType === 'Verify' ? 'Verifier' : ApproverType]: Approver,
    };
    this.props.changeStatus(submitData).then((response) => {
      if (!response.error) {
        this.modalChangeApproverMultiTransRef.close();
        this.onClickSearchHandler({ preventDefault: () => {} });
      }
    });
  };

  onChangeModalApproverMultiTrans = (e) => {
    const { name, value } = e.target;
    const { transactionsData, ApproverType, Approver } = this.state.modalChangeApproverMultiTrans;
    let resultFail = [];
    switch (name) {
      case 'ApproverType':
        const key = toLower(value);
        const transactionNoApprover = transactionsData.filter(
          (f) => !(f[key] && toLower(f[`${key}Status`]) === 'wait for approve')
        );
        if (transactionNoApprover.length) {
          resultFail = transactionNoApprover.map(
            (m) => `transaction ${m.transactionNo} cannot change ${value}.`
          );
        }
        break;
      case 'Approver':
        const checkDupKey = ['Approver1', 'Approver2'];
        if (checkDupKey.includes(ApproverType)) {
          const dupicateApprover = transactionsData.filter(
            (f) => (ApproverType === 'Approver1' ? f.approver2 : f.approver1) === value
          );

          if (dupicateApprover.length)
            resultFail = dupicateApprover.map(
              (m) => `transaction ${m.transactionNo} has dupicate approver.`
            );
        }

        break;
    }

    this.setState((state) => {
      const stateToUpdate = {
        ...state.modalChangeApproverMultiTrans,
        resultFail,
      };

      if (!resultFail.length) stateToUpdate[name] = value;
      if (name === 'ApproverType') stateToUpdate.Approver = '';

      return {
        modalChangeApproverMultiTrans: stateToUpdate,
      };
    });
  };

  onClickAutomate = () => {
    const selected = this.props.searchResult
      .filter((f) => f.IsSelected)
      .map((m) => m.transactionNo)
      .filter((f, i, s) => s.indexOf(f) === i)
      .map((t) => ({
        transactionNo: t,
      }));
    this.props.automate(selected).then(() => {
      this.onClickSearchHandler({
        preventDefault: () => {},
      });
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onClickOpenModalEdit: this.onClickOpenModalEdit,
      onChangeModalEdit: this.onChangeModalEditHeadler,
      onClickSubmitModalEdit: this.onClickSubmitModalEdit,
      onClickClearCurrency: this.onClickClearCurrency,

      onChangeSplitAmount: this.onChangeSplitAmount,
      onClickAddSplit: this.onClickAddSplit,
      onSubmitModalSplit: this.onSubmitModalSplit,
      onClickConfirmModalUndo: this.onClickConfirmModalUndo,

      inputAttachefileRef: (e) => (this.inputAttachfileRef = e),
      onChangeAttachFile: this.onChangeAttachFile,
      onSubmitSaveUploadFile: this.onSubmitSaveUploadFile,

      onClickOpenModalFunction: this.onClickOpenModalFunction,
      onClickConfirmModalMerge: this.onClickConfirmModalMerge,
      onChangeModalMerge: this.onChangeModalMerge,
      onClickConfirmModalUnmerge: this.onClickConfirmModalUnmerge,
      onClickConfirmChangeStatus: this.onClickConfirmChangeStatus,
      onChangeModalChangeStatus: this.onChangeModalChangeStatus,
      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
      conditionChangeStatus,

      onClickAssignForward: this.onClickAssignForward,
      onClickOpenModalImportExcel: this.onClickOpenModalImportExcel,

      onClickMultiPrint: this.onClickMultiPrint,

      onClickOpenModalClearQuoteRate: this.onClickOpenModalClearQuoteRate,
      onClickConfirmClearQuoteRate: this.onClickConfirmClearQuoteRate,

      onChangeModalApproveChange: this.onChangeModalApproveChange,
      onSubmitModalChangeApprover: this.onSubmitModalChangeApprover,

      onClickOpenModalChangeApproverMultiTransaction:
        this.onClickOpenModalChangeApproverMultiTransaction,
      modalChangeApproverMultiTransRef: (e) => (this.modalChangeApproverMultiTransRef = e),
      onSubmitChangeApproverMultiTrans: this.onSubmitChangeApproverMultiTrans,
      onChangeModalApproverMultiTrans: this.onChangeModalApproverMultiTrans,
    };
    return (
      <>
        <ImportExcelTTRemittance ref={this.setImportExcelRef} />
        <Management
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          modalEditRef={(e) => (this.modalEditRef = e)}
          modalMergeRef={(e) => (this.modalMergeRef = e)}
          modalUnmergeRef={(e) => (this.modalUnmergeRef = e)}
          modalApproveRef={(e) => (this.modalApproveRef = e)}
          modalConfirmRef={(e) => (this.modalConfirmRef = e)}
          modalNotConfirmRef={(e) => (this.modalNotConfirmRef = e)}
          modalUnconfirmRef={(e) => (this.modalUnconfirmRef = e)}
          modalRejectRef={(e) => (this.modalRejectRef = e)}
          modalSplitRef={(e) => (this.modalSplitRef = e)}
          modalUndoRef={(e) => (this.modalUndoRef = e)}
          modalUploadFileRef={(e) => (this.modalUploadFileRef = e)}
          modalForwardRef={(e) => (this.modalForwardRef = e)}
          modalSpotRateRef={(e) => (this.modalSpotRateRef = e)}
          modalClearQuoteRateRef={(e) => (this.modalClearQuoteRateRef = e)}
          modalChangeRef={(e) => (this.modalChangeRef = e)}
          modalCompletedRef={(e) => (this.modalCompletedRef = e)}
          onClickAutomate={this.onClickAutomate}
        />
        <AttachFileList
          ref={(e) => (this.attachFileRef = e)}
          callBackSave={() => {
            this.props.searchTtRemittance({ ...this.props.criteria });
          }}
          transactionNo={this.state.selectedAttachFilesTransactionNo}
          statusTt={this.state.statusTt}
          isCheckAllpay={this.state.isCheckAllpay}
          btnPermission={{
            canUpload: permission,
            canReplace: permission,
          }}
          permission={permission}
        />
      </>
    );
  }
}

export default connect(
  (state) => ({
    ...state.ttRemittance,
  }),
  {
    searchTtRemittance,
    updateSearchResult,
    updateTtRemittance,
    splitTtRemittance,
    addAlert,
    changeStatus,
    saveAttachFile,
    undoSplitTtRemittance,
    mergeTtRemittance,
    exportPdf,
    getAtatchfile,
    editCriteria,
    clearCriteria,
    automate,
    clearQuoteRate,
  }
)(ManagementContainer);

const conditionChangeStatus = {
  confirm: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        toLower(f.transferMethod) === 'sftp' &&
        toLower(f.statusTt) === 'wait for confirm'
    ),
  approve: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        toLower(f.transferMethod) !== 'sftp' &&
        toLower(f.statusTt) === 'wait for confirm'
    ),
  reject: (m = []) => m.filter((f) => f.IsSelected && toLower(f.statusTt) === 'wait for confirm'),
  notconfirm: (m = []) =>
    m.filter((f) => f.IsSelected && toLower(f.statusTt) === 'wait for confirm'),
  unconfirm: (m = []) =>
    m.filter(
      (f) => f.IsSelected
      // && (toLower(f.statusTt) === 'not confirm' || toLower(f.statusTt) === 'confirmed' || toLower(f.statusTt) === 'disapprove')
    ),
  completed: (m = []) =>
    m.filter(
      (f) =>
        f.IsSelected &&
        (toLower(f.statusTt) === 'wait for bank receive' ||
          toLower(f.statusTt) === 'failed' ||
          toLower(f.statusTt) === 'accepted by bank')
    ),
};
