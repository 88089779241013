import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import RadioButton from '../../common/RadioButton';
import { DATATABLE_ID } from './TierRateContainer';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { toFixed, toStatus, getPermission } from '../../common/helpper';
import { MASTER_FINANCE, MASTER_COMPANY, MASTER_BUSINESS_UNIT } from '../../reducers/master/action';
import MasterDropdownUI, {
  OPTION_FREQUENCY,
  OPTION_WORKING_DAY,
} from '../../common/MasterDropdownUI';

const permission = getPermission('Master Finance', 'Tier Rate');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-4', 'col-md-5'];
const colX2 = ['col-md-4', 'col-md-7'];

const opt = {
  order: [[5, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'effectiveDateStr', className: 'text-center', type: 'date-black' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'tier', className: 'text-center' },
    { data: 'partnerCode', className: 'text-center' },
    { data: 'partnerName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'depositRate', className: 'text-right', render: (d) => toFixed(d, 8) },
    { data: 'loanRate', className: 'text-right', render: (d) => toFixed(d, 8) },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const noEdit = !!((modalData.data === 'update') & !modalData.IsActive);
  const checkSave = !!((modalData.Mode === 'update') & !modalData.IsActive);

  const setPeriodEndType = modalData.PeriodEndType !== 'End of Term';
  const setDueDateType = modalData.DueDateType !== 'End of Term';

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" /> Tier Rate
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required={false}>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/master/tier-detail/create"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    MAINTAIN TIER
                  </Link>
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Effective Date</th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>Tier</th>
              <th className="th-success">
                Master
                <br /> Company Code
              </th>
              <th className="th-success">
                Master
                <br /> Company Name
              </th>
              <th className="th-primary">
                Member
                <br /> Company Code
              </th>
              <th className="th-primary">
                Member
                <br /> Company Name
              </th>
              <th className="text-center">
                Rate
                <br /> Deposit (%)
              </th>
              <th className="text-center">
                Rate
                <br /> Loan (%)
              </th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Tier" bgHeader="bg-primary" size="modal-xl" ref={modalDataRef}>
        <form onSubmit={onClickSaveModalData}>
          <Card textHeader="Detail" bgHeader="bg-info">
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Master Company" required>
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    value={modalData.PartnerCode}
                    disabled
                    required
                    name="PartnerCode"
                  />
                </FormGroup2>

                <FormGroup2 text="Member Company" required>
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    value={modalData.CompanyCode}
                    disabled
                    required
                    name="CompanyCode"
                  />
                </FormGroup2>
              </Col2>
            </Col2>
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Rate Deposit (%)" required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 8,
                      digitsOptional: false,
                      placeholder: '0.00000000',
                    }}
                    onChange={onChangeModalData}
                    value={modalData.DepositRate}
                    required
                    disabled={noEdit}
                    name="DepositRate"
                  />
                </FormGroup2>

                <FormGroup2 text="Rate Loan (%)" required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 8,
                      digitsOptional: false,
                      placeholder: '0.00000000',
                    }}
                    onChange={onChangeModalData}
                    value={modalData.LoanRate}
                    required
                    disabled={noEdit}
                    name="LoanRate"
                  />
                </FormGroup2>
              </Col2>
            </Col2>
          </Card>

          <Card textHeader="Period" bgHeader="bg-warning">
            <Card>
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Cal. Method" required>
                    <MasterDropdownUI
                      onChange={onChangeModalData}
                      isChoose
                      notMultiple
                      required
                      disabled={noEdit}
                      value={modalData.CalculateMethod}
                      name="CalculateMethod"
                      options={['Act/360', 'Act/365', 'Act/366', 'Actual']}
                    />
                  </FormGroup2>
                </Col2>
              </Col2>
            </Card>

            {noEdit ? (
              <Col2 col={colX[0]}>
                <Col2 col={colX[2]}>
                  <Card textHeader="Interest Period End" cardActions={['toggler']}>
                    <Col2 col={colX[2]}>
                      <Col2 col={colX[1]}>
                        <FormGroup2 text="Period End" required>
                          <input
                            className="form-control"
                            onChange={onChangeModalData}
                            name="PeriodEndType"
                            value={modalData.PeriodEndType}
                            readOnly
                          />
                        </FormGroup2>
                        <FormGroup2 text="Include" required={false}>
                          <input
                            className="form-control"
                            onChange={onChangeModalData}
                            name="IsManualInclude"
                            value={modalData.IsManualInclude ? 'Yes' : 'No'}
                            readOnly
                          />
                        </FormGroup2>
                      </Col2>
                    </Col2>

                    <Col2 col={colX[2]}>
                      <Col2 col={colX[1]}>
                        <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                          <input
                            className="form-control"
                            onChange={onChangeModalData}
                            name="ManualPeriodEnd"
                            value={modalData.ManualPeriodEnd || '-'}
                            readOnly
                          />
                        </FormGroup2>
                        <FormGroup2 text="Day" required={false}>
                          <input
                            className="form-control"
                            onChange={onChangeModalData}
                            name="ManualDay"
                            value={modalData.ManualDay || '-'}
                            readOnly
                          />
                        </FormGroup2>
                      </Col2>
                    </Col2>

                    <Col2 col={colX[1]}>
                      <FormGroup2 text="Working Day" required>
                        <input
                          className="form-control"
                          onChange={onChangeModalData}
                          name="ManualWorkingDay"
                          value={modalData.ManualWorkingDay || ''}
                          readOnly
                        />
                      </FormGroup2>
                    </Col2>
                  </Card>
                </Col2>

                <Col2 col={colX[2]}>
                  <Card textHeader="Interest Due Date" cardActions={['toggler']}>
                    <Col2 col={colX[2]}>
                      <FormGroup2 text="Due Date" required>
                        <input
                          className="form-control"
                          onChange={onChangeModalData}
                          name="DueDateType"
                          value={
                            modalData.DueDateType === 'End of Term'
                              ? 'End of Term (Shift Back to Term End)'
                              : modalData.DueDateType
                          }
                          readOnly
                        />
                      </FormGroup2>
                    </Col2>

                    <Col2 col={colX[2]}>
                      <Col2 col={colX[1]}>
                        <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                          <input
                            className="form-control"
                            onChange={onChangeModalData}
                            name="ManualDueDate"
                            value={modalData.ManualDueDate || '-'}
                            readOnly
                          />
                        </FormGroup2>
                        <FormGroup2 text="Day" required={false}>
                          <input
                            className="form-control"
                            onChange={onChangeModalData}
                            name="DueDateDay"
                            value={modalData.DueDateDay || '-'}
                            readOnly
                          />
                        </FormGroup2>
                      </Col2>
                    </Col2>

                    <Col2 col={colX[1]}>
                      <FormGroup2 text="Frequency Month(s)" required={false}>
                        <input
                          className="form-control"
                          name="Frequency"
                          defaultValue={modalData.Frequency || ''}
                          readOnly
                        />
                      </FormGroup2>

                      <FormGroup2 text="Working Day" required>
                        <input
                          className="form-control"
                          onChange={onChangeModalData}
                          name="DueDateWorkingDay"
                          value={modalData.DueDateWorkingDay || ''}
                          readOnly
                        />
                      </FormGroup2>
                    </Col2>
                  </Card>
                </Col2>
              </Col2>
            ) : (
              <Col2 col={colX[0]}>
                <Col2 col={colX[2]}>
                  <Card textHeader="Interest Period End" cardActions={['toggler']}>
                    <Col2 col={colX[2]}>
                      <FormGroup2 text="Period End" required>
                        <div>
                          <RadioButton
                            text="End of Term"
                            value="End of Term"
                            checked={modalData.PeriodEndType === 'End of Term'}
                            onChange={onChangeModalData}
                            name="PeriodEndType"
                            required
                          />
                          <RadioButton
                            text="Manual Period End"
                            value="Manual Period End"
                            checked={modalData.PeriodEndType === 'Manual Period End'}
                            onChange={onChangeModalData}
                            name="PeriodEndType"
                          />
                          <label className="custom-control">
                            <div>
                              <input
                                id="IsManualInclude"
                                type="checkbox"
                                className="label-checkbox"
                                onChange={onChangeModalData}
                                name="IsManualInclude"
                                checked={modalData.IsManualInclude}
                                disabled={noEdit}
                              />
                              <label htmlFor="IsManualInclude" className="label_checkbox" />
                              <label
                                htmlFor="IsManualInclude"
                                className="control-label text_label_checkbox"
                              >
                                Include
                              </label>
                            </div>
                          </label>
                        </div>
                      </FormGroup2>
                    </Col2>

                    <Col2 col={colX[2]}>
                      <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                        <div>
                          <RadioButton
                            text="Month End"
                            value="Month End"
                            checked={modalData.ManualPeriodEnd === 'Month End'}
                            onChange={onChangeModalData}
                            name="ManualPeriodEnd"
                            disabled={!setPeriodEndType}
                            required={setPeriodEndType}
                          />

                          <RadioButton
                            text="Day"
                            value="Day"
                            checked={modalData.ManualPeriodEnd === 'Day'}
                            onChange={onChangeModalData}
                            name="ManualPeriodEnd"
                            disabled={!setPeriodEndType}
                          />

                          <label className="custom-control">
                            {modalData.ManualPeriodEnd === 'Day' ? (
                              <InputMask
                                className="form-control"
                                format="currency"
                                onChange={onChangeModalData}
                                option={{
                                  prefix: '',
                                  digits: 0,
                                  max: 31,
                                  min: 1,
                                }}
                                required={modalData.ManualPeriodEnd === 'Day'}
                                disabled={modalData.ManualPeriodEnd !== 'Day'}
                                value={modalData.ManualDay}
                                name="ManualDay"
                              />
                            ) : (
                              <input
                                className="form-control"
                                onChange={onChangeModalData}
                                value={modalData.ManualDay || ''}
                                name="ManualDay"
                                required={modalData.ManualPeriodEnd === 'Day'}
                                disabled={modalData.ManualPeriodEnd !== 'Day'}
                              />
                            )}
                          </label>
                        </div>
                      </FormGroup2>
                    </Col2>

                    <Col2 col="col-md-8">
                      <FormGroup2 text="Working Day" required>
                        <MasterDropdownUI
                          masterType={OPTION_WORKING_DAY}
                          onChange={onChangeModalData}
                          isChoose
                          notMultiple
                          required
                          disabled={noEdit}
                          value={modalData.ManualWorkingDay}
                          name="ManualWorkingDay"
                        />
                      </FormGroup2>
                    </Col2>
                  </Card>
                </Col2>

                <Col2 col={colX[2]}>
                  <Card textHeader="Interest Due Date" cardActions={['toggler']}>
                    <Col2 col={colX[2]}>
                      <FormGroup2 text="Due Date" required>
                        <div>
                          <RadioButton
                            text="End of Term (Shift Back to Term End)"
                            value="End of Term"
                            checked={modalData.DueDateType === 'End of Term'}
                            onChange={onChangeModalData}
                            name="DueDateType"
                            required
                          />
                          <RadioButton
                            text="Manual Due Date"
                            value="Manual Due Date"
                            checked={modalData.DueDateType === 'Manual Due Date'}
                            onChange={onChangeModalData}
                            name="DueDateType"
                          />
                        </div>
                      </FormGroup2>
                    </Col2>

                    <Col2 col={colX[2]}>
                      <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                        <div>
                          <RadioButton
                            text="Month End"
                            value="Month End"
                            checked={modalData.ManualDueDate === 'Month End'}
                            onChange={onChangeModalData}
                            name="ManualDueDate"
                            disabled={!setDueDateType}
                            required={setDueDateType}
                          />
                          <RadioButton
                            text="Day"
                            value="Day"
                            checked={modalData.ManualDueDate === 'Day'}
                            onChange={onChangeModalData}
                            disabled={!setDueDateType}
                            name="ManualDueDate"
                          />

                          <label className="custom-control">
                            {modalData.ManualDueDate === 'Day' ? (
                              <InputMask
                                className="form-control"
                                format="currency"
                                onChange={onChangeModalData}
                                option={{
                                  prefix: '',
                                  digits: 0,
                                  max: 31,
                                  min: 1,
                                }}
                                required={modalData.ManualDueDate === 'Day'}
                                disabled={modalData.ManualDueDate !== 'Day'}
                                value={modalData.DueDateDay}
                                name="DueDateDay"
                              />
                            ) : (
                              <input
                                className="form-control"
                                onChange={onChangeModalData}
                                value={modalData.DueDateDay || ''}
                                name="DueDateDay"
                                required={modalData.ManualDueDate === 'Day'}
                                disabled={modalData.ManualDueDate !== 'Day'}
                              />
                            )}
                          </label>
                        </div>
                      </FormGroup2>
                    </Col2>

                    <Col2 col="col-md-6">
                      <FormGroup2 text="Frequency Month(s)" required={false}>
                        <MasterDropdownUI
                          masterType={OPTION_FREQUENCY}
                          onChange={onChangeModalData}
                          isChoose
                          notMultiple
                          disabled={noEdit}
                          value={modalData.Frequency}
                          name="Frequency"
                        />
                      </FormGroup2>
                      <FormGroup2 text="Working Day" required>
                        <MasterDropdownUI
                          masterType={OPTION_WORKING_DAY}
                          onChange={onChangeModalData}
                          isChoose
                          notMultiple
                          required
                          disabled={noEdit}
                          value={modalData.DueDateWorkingDay}
                          name="DueDateWorkingDay"
                        />
                      </FormGroup2>
                    </Col2>
                  </Card>
                </Col2>
              </Col2>
            )}
          </Card>
          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
