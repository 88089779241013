import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_COA_CRITERIA = 'EDIT_MASTER_COA_CRITERIA';
export const CLEAR_MASTER_COA_CRITERIA = 'CLEAR_MASTER_COA_CRITERIA';

export const REQUEST_SEARCH_MASTER_COA = '@@request/SEARCH_MASTER_COA';
export const RECEIVE_SEARCH_MASTER_COA = '@@receive/SEARCH_MASTER_COA';
export const FAIL_SEARCH_MASTER_COA = '@@fail/SEARCH_MASTER_COA';

export const REQUEST_SAVE_MASTER_COA = '@@request/SAVE_MASTER_COA';
export const RECEIVE_SAVE_MASTER_COA =
  '@@receive-The record was successfully saved/SAVE_MASTER_COA';
export const FAIL_SAVE_MASTER_COA = '@@fail/SAVE_MASTER_COA';

export const saveMasterCoa = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveMasterCoa`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MASTER_COA, RECEIVE_SAVE_MASTER_COA, FAIL_SAVE_MASTER_COA],
  },
});

export const searchMasterCoa = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/GetMasterCoa', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_MASTER_COA, RECEIVE_SEARCH_MASTER_COA, FAIL_SEARCH_MASTER_COA],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_COA_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_COA_CRITERIA,
  payload: {
    name,
    value,
  },
});
