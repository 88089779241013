import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import { DATATABLE_ID } from './POAContainer';
import FormGroup2 from '../../../common/FormGroup2';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { toLower, toStatus, getPermissionStr } from '../../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_APPROVER,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermissionStr('POA Management', 'POA');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  deferRender: true,
  scroller: true,
  paging: true,
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        if (
          toLower(r.status) === 'cancelled' ||
          toLower(r.status) === 'accepted by bank' ||
          toLower(r.status) === 'rejected by bank' ||
          toLower(r.status) === 'wait for bank response'
        ) {
          r.canSelect = false;
          return '';
        }
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${
          d ? 'checked="true"' : ''
        }/><label class="label_checkbox"></label>`;
      },
    },
    {
      data: 'transactionNo',
      render: (d) => d,
      // `<a target="_blank" href="/fx-management/exposure-transaction/${d}" class="edit-exposure"> ${d} </a>`,
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'bank', className: 'text-center' },
    // {
    //   data: "bankAbbreviate",
    //   render: (d, r, s) => {
    //     return toBank(d, s.bankName);
    //   },
    // },
    { data: 'sendDateStr', className: 'text-center', type: 'date-black' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    { data: 'signer' },
    { data: 'filename' },
    {
      data: 'attachFileExposure',
      render: (d) =>
        '<button class="btn btn-info btn-sm download"><i class="icon icon-download icon-fw icon-lg"></i></button>',
      className: 'text-center',
    },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  results,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,

  dataTableRef,
  onClickDownloadAttachFile,

  modalCreateRef,
  onClickCreate,
  onChangeAttachFile,
  onSubmitAttachFile,

  onClickSelectBank,
  onClickConfirm,
  onClickCancel,

  modalEditRef,
  modalEdit,
  modalCancelRef,
  onSubmitModalEdit,
  onChangeModalEdit,
  onChangeSelect2Edit,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            POA Management <span className="icon icon-angle-double-right" /> POA
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companys}
                  name="Companys"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                />
              </FormGroup2>

              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.Banks}
                  name="Banks"
                  saveLocalState
                  companyCode={criteria.Companies}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <MasterDropdownUI
                  value={criteria.Status}
                  onChange={onChangeSelect2}
                  name="Status"
                  options={[
                    'Wait for select bank',
                    'Wait for confirm',
                    'Wait for approve',
                    'Wait for bank response',
                    'Accepted by bank',
                    'Rejected by bank',
                    'Cancelled',
                  ]}
                />
              </FormGroup2>

              <FormGroup2 text="Send Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.SendDateStr}
                  name="SendDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {toLower(permission) === 'all' ? (
          <Col2 col={colX[2]}>
            <div>
              <Button txt="ADD POA" icon="plus" className="btn-warning" onClick={onClickCreate} />
              &nbsp;
              <Button
                txt="SELECT BANK"
                icon="edit"
                className="btn-info"
                onClick={onClickSelectBank}
              />
              &nbsp;
              <Button txt="CONFIRM" icon="check" className="btn-success" onClick={onClickConfirm} />
              &nbsp;
              <Button txt="UNCONFIRM" icon="undo" className="btn-default" onClick={onClickCancel} />
            </div>
          </Col2>
        ) : null}

        <Table
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          ref={dataTableRef}
          option={opt}
          value={results}
          // onClickContext={[
          //   {
          //     onClick: onClickDownloadAttachFile,
          //     context: "button.download",
          //   },
          // ]}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>Transaction No.</th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>
                Company
                <br />
                Name
              </th>
              <th>Bank</th>
              <th>Send Date</th>
              <th>Status</th>
              <th>Signer</th>
              <th>File Name</th>
              <th>Download</th>
              <th>Created Date</th>
              <th>Created By</th>
              <th>Updated Date</th>
              <th>Updated By</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-md" ref={modalCreateRef} modalFooter>
        <form onSubmit={onSubmitAttachFile} className="form-horizontal">
          <div className="text-center">
            <h2 className="text-info">Add POA</h2>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Attach File<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-8">
              <input
                type="file"
                name="File"
                className="form-control"
                multiple
                onChange={onChangeAttachFile}
                accept="application/pdf"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Save" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Cancel" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalEditRef} modalFooter>
        <form onSubmit={onSubmitModalEdit} className="form-horizontal">
          <div className="text-center">
            <span className="label label-info">{selectedResultCount} items</span>
            <h2 className="text-info">{modalEdit.Action === 'bank' ? 'Select Bank' : 'Confirm'}</h2>
          </div>
          <br />
          {modalEdit.Action === 'bank' && (
            <div className="form-group">
              <label className="control-label col-md-4">
                Bank<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <div className="col-md-7">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2Edit}
                  value={modalEdit.Bank}
                  name="Bank"
                  saveLocalState
                  required

                  // masterType={MASTER_BANK}
                  // isChoose={true}
                  // notMultipleSelect2={true}
                  // value={modalEdit.Bank}
                  // onChange={(e) =>
                  //   onChangeModalEdit({
                  //     target: e,
                  //   })
                  // }
                  // noValidateOption={true}
                  // saveLocalState={true}
                  // required={true}
                  // name="Bank"
                />
              </div>
            </div>
          )}
          {modalEdit.Action === 'confirm' && (
            <div className="form-group">
              <label className="control-label col-md-4">
                Signer<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <div className="col-md-7">
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  isChoose
                  notMultipleSelect2
                  required
                  noValidateOption
                  saveLocalState
                  filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                  financeGroupId={modalEdit.FinanceGroupId}
                  value={modalEdit.Approver}
                  onChange={(e) =>
                    onChangeModalEdit({
                      target: e,
                    })
                  }
                  name="Approver"
                />
              </div>
            </div>
          )}
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Unconfirm"
        textContent="Unconfirm"
        modalRef={modalCancelRef}
        type="info"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onSubmitModalEdit}
      />
    </div>
  );
};
