import InputMasks from 'inputmask';
import React, { Component } from 'react';
// สำหรับการใช้ inputmask อ่านเพิ่มได้ที่ https://github.com/RobinHerbots/Inputmask   นาจา
class InputMask extends Component {
  createPromise;

  componentDidMount() {
    this.createInputMask();
  }

  createInputMask() {
    const { value, format, option = {} } = this.props;
    this.inputRef.value = value;
    let opt = {
      ...option,
    };
    if (format === 'currency')
      opt = {
        digitsOptional: true,
        placeholder: '',
        prefix: '',
        ...opt,
      };

    // ต้อง create คนละ thead ไม่งั้นช้ามาก
    this.createPromise = new Promise((resolve, reject) => {
      setTimeout(() => {
        if (!this.inputRef) {
          resolve();
          return;
        }

        InputMasks(format, opt).mask(this.inputRef);
        window.jQuery(this.inputRef).change();
        resolve();
      });
    });
  }

  compareObj(o1, o2) {
    if (!o1 && !o2) return false;
    if (o1 && !o2) return true;
    if (!o1 && o2) return true;

    for (const key in o1) {
      if (o1[key] !== o2[key]) return true;
    }

    for (const key in o2) {
      if (o1[key] !== o2[key]) return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    this.createPromise.then(() => {
      if (!this.inputRef) return;

      if (this.compareObj(prevProps.option, this.props.option)) this.createInputMask();

      this.createPromise.then(() => {
        if (!this.inputRef) return;
        if (Number(value) !== Number(this.inputRef.inputmask.unmaskedvalue())) {
          if (isNaN(value)) InputMasks.setValue(this.inputRef, value);
          else InputMasks.setValue(this.inputRef, value || ' ');
        }
      });
    });
  }

  componentWillUnmount() {
    InputMasks.remove(this.inputRef);
  }

  onChange = (e) => {
    const { onChange, name, type } = this.props;
    const { inputmask, value } = e.target;
    if (onChange)
      onChange({
        target: {
          name,
          type,
          value: inputmask.unmaskedvalue(),
          maskValue: value,
        },
      });
  };

  render() {
    const {
      className,
      name,
      type = 'text',
      readOnly,
      required = false,
      max,
      disabled,
    } = this.props;
    return (
      <div>
        <input
          max={max}
          disabled={disabled}
          onClick={(e) => e.target.select()}
          onBlur={this.onChange}
          name={name}
          type={type}
          className={className}
          ref={(e) => (this.inputRef = e)}
          required={required}
          readOnly={readOnly}
        />
      </div>
    );
  }
}

export default InputMask;
