import { connect } from 'react-redux';
import React, { Component } from 'react';

import ForwardDetail from './ForwardDetail';
import { addAlert } from '../../reducers/layout/action';
import { getCurrencyDigit } from '../../reducers/master/action';
import { toFixed, toUpperKey, compareDate, checkWeekend } from '../../common/helpper';
import {
  saveFxContract,
  getBusinessArea,
  getHistoryDetail,
  getManageFxContract,
} from '../../reducers/manageFx/action';

export const DATATABLE_ID = 'table-result-forward-detail-result';

const init = {
  ForwardNo: '',

  FinanceGroupId: '',
  CompanyCode: '',

  ProductType: '',
  AssignTo: '',
  ForwardType: '',
  TransactionType: '',
  BankCode: '',
  ContractNo: '',
  ContractDateStr: '',
  StartDateStr: '',
  EndDateStr: '',
  Amount: '',
  ForwardCurrency: '',
  BookBankCurrency: '',

  SpotRate: '',
  SwapRate: '',
  ForwardRate: '',
  BaseRate: '',
  AmountFixPar: '',
  AmountProRata: '',

  Purpose: '',
  PurposeDetail: '',
  DollarLinked: '',
  RolloverRef2: '',
  BusinessArea: '',
  BusinessAreaName: '',

  InternalRefNo: '',
  RequestType: '',
  ContractDetail1: '',
  Dealer: '',
  Department: '',
  StatusFile: '',
  Note1: '',
  Note2: '',

  mode: 'create',
  isUseForward: false,
  isAfterEndDate: false,

  inputMaskAmount: {
    prefix: '',
    digits: 3,
  },
};

class ForwardDetailContainer extends Component {
  state = {
    ...init,
    createHistory: [],
    historyDetail: [],
    businessAreaList: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    const fowardNo = this.props.routeProp.match.params.id;
    if (!fowardNo) return;
    this.fetchTransaction(fowardNo);
  }

  componentDidUpdate(prevProps, prevState) {
    const { ForwardCurrency, Amount, AmountFixPar, AmountProRata, CompanyCode, mode } = this.state;

    if (prevState.ForwardCurrency !== ForwardCurrency) {
      this.props.getCurrencyDigit({ criteria: ForwardCurrency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          Amount: Amount
            ? toFixed(Number((Amount.toString() || '').replace(/,/g, '') || 0), digit)
            : '',
          AmountFixPar: AmountFixPar
            ? toFixed(Number((AmountFixPar.toString() || '').replace(/,/g, '') || 0), digit)
            : '',
          AmountProRata: AmountProRata
            ? toFixed(Number((AmountProRata.toString() || '').replace(/,/g, '') || 0), digit)
            : '',
        });
      });
    }

    if (mode === 'edit' && prevState.CompanyCode !== CompanyCode) {
      this.props
        .getBusinessArea({
          CompanyCode,
        })
        .then((response) => {
          if (response.error) return;

          this.setState({
            businessAreaList: response.payload || [],
          });
        });
    }
  }

  fetchTransaction(fowardNo) {
    this.props.getManageFxContract(fowardNo).then((response) => {
      if (!response.error) {
        this.setState({
          ...init,
          ...toUpperKey(response.payload),
          mode: 'edit',
        });
        if (response.payload.amount !== response.payload.outstanding) {
          this.setState({
            isUseForward: true,
          });
        }
      }
    });

    this.props
      .getHistoryDetail({
        TransactionNo: fowardNo,
        SearchType: 'Detail',
      })
      .then((response) => {
        if (!response.error) {
          this.setState({
            historyDetail: response.payload,
          });
        }
      });
  }

  onChangeInputData = (e) => {
    const { name, value, label, datas } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'FinanceGroupId') {
        stateToUpdate.FinanceGroupName = label;
        stateToUpdate.CompanyCode = '';
        stateToUpdate.BankCode = '';
        if (value !== '003') stateToUpdate.BookBankCurrency = 'THB';
        else stateToUpdate.BookBankCurrency = '';
      } else if (name === 'CompanyCode') {
        const s = label.split('|');
        stateToUpdate.CompanyAbbreviate = (s[1] || '').trim();
        stateToUpdate.CompanyName = (s[2] || '').trim();
        stateToUpdate.BankCode = '';
        stateToUpdate.BusinessArea = '';
        if (state.ProductType === 'Forward Special') {
          stateToUpdate.AssignTo = value;
          stateToUpdate.AssignToName = label;
        }
      } else if (name === 'ForwardType') {
        const base = this.calBaseRate(value, state.SpotRate);
        stateToUpdate.BaseRate = base;
        stateToUpdate.AmountProRata = this.calAmountProRata(value, state.Amount, base);
      } else if (name === 'ContractDateStr') {
        if (value === '') {
          stateToUpdate.ContractDateStr = '';
          stateToUpdate.ContractDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.StartDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Contract Date must be less than or equal Start Date.',
            });
            return {
              [name]: state.ContractDateStr,
            };
          }
        }
      } else if (name === 'StartDateStr') {
        if (value === '') {
          stateToUpdate.StartDateStr = '';
          stateToUpdate.StartDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.ContractDateStr, mergeState.StartDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Start Date must be more than or equal Contract Date.',
            });
            return {
              [name]: state.StartDateStr,
            };
          }
          if (compareDate(mergeState.StartDateStr, mergeState.EndDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Start Date must be less than or equal End Date.',
            });
            return {
              [name]: state.StartDateStr,
            };
          }
        }
      } else if (name === 'EndDateStr') {
        if (value === '') {
          stateToUpdate.EndDateStr = '';
          stateToUpdate.EndDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.StartDateStr, mergeState.EndDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'End Date must be more than or equal Start Date.',
            });
            return {
              [name]: state.EndDateStr,
            };
          }
          if (compareDate(mergeState.ContractDateStr, mergeState.EndDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'End Date must be more than or equal Contract Date.',
            });
            return {
              [name]: state.EndDateStr,
            };
          }
        }
      } else if (name === 'Amount') {
        stateToUpdate.AmountFixPar = this.calAmountFixPar(value, state.ForwardRate);
        stateToUpdate.AmountProRata = this.calAmountProRata(
          state.ForwardType,
          value,
          state.BaseRate
        );
      } else if (name === 'SpotRate') {
        const forward = this.calForwardRate(value, state.SwapRate);
        stateToUpdate.ForwardRate = forward;
        stateToUpdate.BaseRate = this.calBaseRate(state.ForwardType, value);
        stateToUpdate.AmountFixPar = this.calAmountFixPar(state.Amount, forward);
        stateToUpdate.AmountProRata = this.calAmountProRata(state.ForwardType, state.Amount, value);
      } else if (name === 'SwapRate') {
        const forward = this.calForwardRate(state.SpotRate, value);
        stateToUpdate.ForwardRate = forward;
        stateToUpdate.AmountFixPar = this.calAmountFixPar(state.Amount, forward);
      } else if (name === 'ForwardRate') {
        stateToUpdate.AmountFixPar = this.calAmountFixPar(state.Amount, value);
      } else if (name === 'BaseRate') {
        stateToUpdate.AmountProRata = this.calAmountProRata(state.ForwardType, state.Amount, value);
      } else if (name === 'DollarLinked')
        stateToUpdate.DollarLinked = value === 'Yes' ? true : value === 'No' ? false : null;
      else if (name === 'CompanyCode') stateToUpdate.CompanyName = label;
      else if (name === 'BankCode') {
        const s = label.split('|');
        stateToUpdate.BankAbbreviate = (s[0] || '').trim();
        stateToUpdate.BankName = (s[1] || '').trim();
      } else if (name === 'AssignTo') {
        stateToUpdate.AssignToName = label;
      } else if (name === 'ProductType') {
        stateToUpdate.AssignToName = '';
        stateToUpdate.AssignTo = '';
      } else if (name === 'BusinessArea') {
        stateToUpdate.BusinessAreaName = label;
        if (value && !label) {
          if (state.mode === 'edit') {
            state.businessAreaList.forEach((m) => {
              if (value === m.businessArea) {
                stateToUpdate.BusinessAreaName = m.businessAreaName;
              }
            });
          } else {
            datas.forEach((m) => {
              if (value === m.businessArea) {
                stateToUpdate.BusinessAreaName = m.businessAreaName;
              }
            });
          }
        }
      } else if (name === 'TransactionType') {
        if (state.Purpose === 'Trade AP' || state.Purpose === 'Trade AR')
          stateToUpdate.Purpose = '';
      } else if (name === 'ForwardCurrency' || name === 'BookBankCurrency') {
        const mergeState = {
          ...state,
          ...stateToUpdate,
        };

        if (
          mergeState.ForwardCurrency &&
          mergeState.BookBankCurrency &&
          mergeState.ForwardCurrency === mergeState.BookBankCurrency
        ) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Forward Currency and Book Bank Currency must not same Currency.',
          });
          return {
            [name]: '',
          };
        }

        if (name === 'ForwardCurrency') {
          stateToUpdate.AmountFixPar = this.calAmountFixPar(state.Amount, state.ForwardRate);
          stateToUpdate.AmountProRata = this.calAmountProRata(
            state.ForwardType,
            state.Amount,
            state.BaseRate
          );
        }
      } else if (name === 'TransactionType') {
        if (value === 'Sell' && state.Purpose === 'Project') {
          this.props.addAlert({
            type: 'error',
            title: 'Error',
            body: 'Cannot select Sell Transaction that use Project Purpose.',
          });
          return state;
        }
      } else if (name === 'Purpose') {
        if (value === 'Project' && state.TransactionType === 'Sell') {
          this.props.addAlert({
            type: 'error',
            title: 'Error',
            body: 'Cannot select Sell Transaction that use Project Purpose.',
          });
          return state;
        }
      }

      return stateToUpdate;
    });
  };

  calForwardRate = (spot, swap) =>
    parseFloat((spot.toString() || '').replace(/,/g, '')) +
    parseFloat((swap.toString() || '').replace(/,/g, ''));

  calBaseRate = (forwardType, spot) => {
    if (forwardType === 'Pro Rata') return spot;
    return '';
  };

  calAmountFixPar = (amount, forward) =>
    parseFloat((amount.toString() || '').replace(/,/g, '')) *
    parseFloat((forward.toString() || '').replace(/,/g, ''));

  calAmountProRata = (forwardType, amount, base) => {
    if (forwardType === 'Pro Rata')
      return (
        parseFloat((amount.toString() || '').replace(/,/g, '')) *
        parseFloat((base.toString() || '').replace(/,/g, ''))
      );
    return '';
  };

  onSubmit = (e) => {
    e.preventDefault();

    let textCheckDay = '';
    if (checkWeekend(this.state.ContractDateStr))
      textCheckDay = 'Contract Date can not be Weekend.';
    else if (checkWeekend(this.state.StartDateStr)) textCheckDay = 'Start Date can not be Weekend.';
    else if (checkWeekend(this.state.EndDateStr)) textCheckDay = 'End Date can not be Weekend.';

    if (textCheckDay !== '') {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: textCheckDay,
      });
      return;
    }

    if (!this.state.Amount || this.state.Amount <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Amount must more than zero',
      });
      return;
    }

    if (!this.state.SpotRate || parseFloat(this.state.SpotRate || 0) === 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Spot Rate must be not equal zero',
      });
      return;
    }

    // if (!this.state.SwapRate || parseFloat(this.state.SwapRate||0) === 0) {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: 'Swap Rate must be not equal zero',
    //     })
    //     return
    // }

    if (!this.state.ForwardRate || parseFloat(this.state.ForwardRate || 0) === 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Forward Rate must be not equal zero',
      });
      return;
    }

    if (!this.state.AmountFixPar || this.state.AmountFixPar <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Amount Book Bank (Fixed, Par) must more than zero',
      });
      return;
    }

    if (this.state.ForwardType === 'Pro Rata') {
      if (!this.state.BaseRate || parseFloat(this.state.BaseRate || 0) === 0) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Base Rate must be not equal zero',
        });
        return;
      }

      if (!this.state.AmountProRata || this.state.AmountProRata <= 0) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Amount Book Bank (Pro Rata) must more than zero',
        });
        return;
      }
    }

    this.props
      .saveFxContract({
        ...this.state,
      })
      .then((response) => {
        if (response.error) return;

        if (this.state.mode === 'create') {
          this.setState((state) => ({
            ...init,
            createHistory: [...state.createHistory, response.payload],
          }));
        } else {
          this.fetchTransaction(this.state.ForwardNo);
        }
      });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,
    };

    return <ForwardDetail {...props} state={this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.forwardDetail,
  }),
  {
    addAlert,
    saveFxContract,
    getManageFxContract,
    getHistoryDetail,
    getCurrencyDigit,
    getBusinessArea,
  }
)(ForwardDetailContainer);
