import { connect } from 'react-redux';
import React, { Component } from 'react';

import BookingForwardDetail from './BookingForwardDetail';
import { addAlert } from '../../../reducers/layout/action';
import { getCurrencyDigit } from '../../../reducers/master/action';
import { toFixed, toUpperKey, compareDate } from '../../../common/helpper';
import {
  getExposureBooingForward,
  saveExposureBooingForward,
} from '../../../reducers/bookingForward/action';

const init = {
  TransactionNo: '',
  FinanceGroupId: '',
  BusinessUnitId: '',
  CompanyCode: '',
  DocumentType: '',
  EntryDateFromStr: '',
  EntryDateToStr: '',
  HedgePortion: '',
  Currency: '',
  EndDateStr: '',
  SoPoNo: '',
  InternalReference: '',
  RequestType: '',
  Department: '',
  Dealer: '',
  Characteristic: '',
  Note1: '',
  Note2: '',
  ProductType: '',
  BankCode: '',

  mode: 'create',
  inputMaskAmount: {
    prefix: '',
    digits: 3,
  },

  idListTable: [],
};

class BookingForwardDetailContainer extends Component {
  state = {
    ...init,
    createHistory: [],
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;
    if (!transactionNo || transactionNo === 'create') return;
    this.fetchTransaction(transactionNo);
  }

  componentDidUpdate(prevProps, prevState) {
    const { Currency, HedgePortion } = this.state;

    if (prevState.Currency !== Currency) {
      this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          HedgePortion: HedgePortion
            ? toFixed(Number((HedgePortion.toString() || '').replace(/,/g, '') || 0), digit)
            : '',
        });
      });
    }
  }

  fetchTransaction(transactionNo) {
    this.props.getExposureBooingForward(transactionNo).then((response) => {
      if (!response.error) {
        this.setState({
          ...init,
          ...toUpperKey(response.payload),
          mode: 'edit',
          IdListTable: [],
        });
      }
    });
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'EntryDateFromStr') {
        if (value === '') {
          stateToUpdate.EntryDateFromStr = '';
          stateToUpdate.EntryDateFrom = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.EntryDateFromStr, mergeState.EntryDateToStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Entry Date (From) must be less than or equal Entry Date (To).',
            });
            return {
              [name]: state.EntryDateFromStr,
            };
          }
        }
      } else if (name === 'EntryDateToStr') {
        if (value === '') {
          stateToUpdate.EntryDateToStr = '';
          stateToUpdate.EntryDateTo = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.EntryDateFromStr, mergeState.EntryDateToStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Entry Date (To) must be more than or equal Entry Date (From).',
            });
            return {
              [name]: state.EntryDateToStr,
            };
          }
        }
      }
      return stateToUpdate;
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.HedgePortion || this.state.HedgePortion <= 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Amount must more than zero',
      });
      return;
    }

    this.props
      .saveExposureBooingForward({
        ...this.state,
      })
      .then((response) => {
        if (response.error || !response.payload) return;
        this.setState((state) => ({
          ...init,
          createHistory: [
            ...state.createHistory,
            {
              ...response.payload,
            },
          ],
        }));
        // this.props.addAlert({
        //     title: 'Success',
        //     type: 'success',
        //     body: 'The record was successfully saved',
        //     buttons: [
        //         <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">Continue</button>
        //     ]
        // })

        // if (response.payload !== this.props.routeProp.match.params.id) {
        //     window.location.href = `/fx-management/exposure-for-booking-forward/${response.payload}`
        // }
        // else {
        //     this.fetchTransaction(response.payload)
        // }
      });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/fx-management/create-exposure-for-booking-forward');
  };

  onClickClear = () => {
    this.setState({
      ...init,
      createHistory: this.state.createHistory,
    });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,
      onClickClear: this.onClickClear,
    };

    return <BookingForwardDetail {...props} state={this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.bookingForward,
  }),
  {
    addAlert,
    getExposureBooingForward,
    saveExposureBooingForward,
    getCurrencyDigit,
  }
)(BookingForwardDetailContainer);
