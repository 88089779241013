import { connect } from 'react-redux';
import React, { Component } from 'react';

import BankDetail from './BankDetail';
import { addAlert } from '../../../reducers/layout/action';
import { toUpperKey, checkAfterValueDate } from '../../../common/helpper';
import {
  getBankDetail,
  saveMasterBank,
  getMasterBankffectiveDate,
} from '../../../reducers/masterBank/action';

const initData = {
  EffectiveDateStr: '',
  TempEffectiveDateStr: 'New',

  BankCode: '',
  BankName: '',
  BankAbbreviate: '',
  BankNameTh: '',

  AddressLocal: '',
  StreetTh: '',
  DistrictTh: '',
  CityTh: '',
  ZipCodeTh: '',
  AddressInter: '',
  Street: '',
  District: '',
  City: '',
  ZipCode: '',

  IsEndingBalanceNoti: false,
  IsFtp: false,
  ExternalRef: '',
  IsPrint: false,
  IsSftp: false,

  EmailForBahtnet: '',
  EmailForCheque: '',
  EmailForQuoteRate: '',
  EmailForPn: '',
  EmailFxMatching: '',

  TaxId: '',

  mode: 'create',
  IsActive: true,
};

class BankDetailContainer extends Component {
  state = {
    ...initData,
    resultEffective: [],
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState({
        ...initData,
      });
    else {
      this.fetchId(id);
    }
  }

  fetchId(id) {
    if (!id) return;

    this.props.getMasterBankffectiveDate({ BankCode: id, Mode: 'date' }).then((response) => {
      if (response.error || !response.payload) return;

      const result = response.payload.map((m) => m.effectiveDateStr);
      this.setState({
        ...initData,
        resultEffective: result,
      });

      const result2 = result.filter((f) => {
        if (!checkAfterValueDate(f, 0)) return true;
        return false;
      });

      if (result2.length === 0) {
        this.setDataBank(result[result.length - 1]);
      } else {
        const effectiveDate = result2[0];
        this.setDataBank(effectiveDate);
      }
    });
  }

  setDataBank(effectiveDateStr) {
    const { id } = this.props.routeProp.match.params;

    this.props
      .getBankDetail({ BankCode: id, EffectiveDateStr: effectiveDateStr })
      .then((response) => {
        if (response.error || !response.payload) return;

        const fetchData = toUpperKey(response.payload);
        this.setState({
          ...fetchData,
          ZipCodeTh: fetchData.ZipCode,
          mode: 'edit',
          TempEffectiveDateStr: fetchData.EffectiveDateStr,
          setOverDueDate: !checkAfterValueDate(fetchData.EffectiveDateStr, 0),
        });
      });
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (~['IsEndingBalanceNoti', 'IsFtp', 'IsPrint', 'IsSftp'].indexOf(name)) {
        stateToUpdate[name] = !this.state[name];
      } else if (name === 'ZipCode' || name === 'ZipCodeTh') {
        stateToUpdate.ZipCode = value;
        stateToUpdate.ZipCodeTh = value;
      }

      return stateToUpdate;
    });

    if (name === 'TempEffectiveDateStr') {
      if (value === 'New') {
        this.setState({
          EffectiveDateStr: '',
          setOverDueDate: false,
          IsActive: true,
        });
      } else {
        this.setDataBank(value);
      }
    }
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitHander = (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formAddressRef,
      this.formConfigRef,
      this.formEmailRef,
    ]);
    if (inValidFormName) return;

    this.props.saveMasterBank(this.state).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        window.location.href = `/master/bank-detail/${response.payload}`;
      } else {
        this.fetchId(response.payload);
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/master/bank');
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,

      tabRef: (e) => (this.tabRef = e),
      formDetailRef: (e) => (this.formDetailRef = e),
      formConfigRef: (e) => (this.formConfigRef = e),
      formAddressRef: (e) => (this.formAddressRef = e),
      formEmailRef: (e) => (this.formEmailRef = e),
    };

    return (
      <div>
        <BankDetail {...props} state={this.state} resultEffective={this.state.resultEffective} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterBank,
  }),
  {
    getMasterBankffectiveDate,
    getBankDetail,
    addAlert,
    saveMasterBank,
  }
)(BankDetailContainer);
