import React from 'react';
import { Link } from 'react-router-dom';

import ModalSell from './ModalSell';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import FormGroup2 from '../../../common/FormGroup2';
import { DATATABLE_ID } from './CreateBondContainer';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import { toBank, toStatus, toNumber, getPermission } from '../../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('Funding&Investment', 'Create Bond');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  columns: [
    {
      orderable: false,
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (r.status) {
          if (d)
            return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
          return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
        }
        return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
      },
    },
    {
      data: 'isSell',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-primary sell"><span class="icon icon-money sq-24"></span></button>';
      },
    },
    {
      data: 'transactionNo',
      render: (d) => `<a target="_blank" href="/loan-investment/bond/${d}"> ${d} </a>`,
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'startDateStr', className: 'text-center', type: 'date-black' },
    { data: 'endDateStr', className: 'text-center', type: 'date-black' },
    { data: 'bondId', className: 'text-center' },
    { data: 'issuer', className: 'text-center' },
    { data: 'bankCode', className: 'text-center' },
    { data: 'partner', render: (d, t, r) => toBank(r.bankAbbreviate, d) },
    {
      data: 'normalAmount',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'currency', className: 'text-center' },
    {
      data: 'outstandingAmount',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'unit', className: 'text-right td-width-date', render: (d, t, r) => toNumber(d, 0) },
    {
      data: 'outstandingUnit',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'pricePerUnitNorminalAmount',
      className: 'text-right',
      render: (d, t, r) => (r.currency === 'THB' ? toNumber(1000, r.currencyDigit) : ''),
    },
    {
      data: 'actualAmount',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'pricePerUnitActualAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(r.actualAmount / r.unit, 6),
    },
    { data: 'eirPerYear', className: 'text-right', render: (d) => toNumber(d, 6) },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  results,
  dataTableRef,

  modalSell,
  modalSellRef,
  onChangeModalSell,
  onSubmitModalSell,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Funding & Investment <span className="icon icon-angle-double-right" /> Create Bond
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroups}
                required={false}
                name="FinanceGroups"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companies}
                saveLocalState
                financeGroupId={criteria.FinanceGroups}
                businessUnitId={criteria.BusinessUnits}
                name="Companies"
              />
            </FormGroup2>

            <FormGroup2 text="Partner">
              <MasterDropdown
                masterType={MASTER_BANK}
                onChange={onChangeSelect2}
                value={criteria.Partner}
                // companyCode={criteria.Companies}
                name="Partner"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Start Date" required={false}>
              <DateRangePicker
                value={criteria.StartDateStr}
                onChange={onChangeSearch}
                required={false}
                name="StartDateStr"
              />
            </FormGroup2>

            <FormGroup2 text="End Date" required={false}>
              <DateRangePicker
                value={criteria.EndDateStr}
                onChange={onChangeSearch}
                required={false}
                name="EndDateStr"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="As of" required={false}>
              <DateRangePicker
                value={criteria.AsOf}
                onChange={onChangeSearch}
                required={false}
                name="AsOf"
              />
            </FormGroup2>

            <FormGroup2 text="Bond No.">
              <input
                className="form-control"
                value={criteria.BondNo}
                onChange={onChangeSearch}
                name="BondNo"
              />
            </FormGroup2>
          </Col2>
        </Col2>
        {/* <br /> */}
        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Link
                  className="btn btn-warning btn-sm btn-labeled"
                  to="/loan-investment/bond"
                  target="_blank"
                >
                  <span className="btn-label">
                    <i className="icon icon-plus-circle icon-fw icon-lg" />
                  </span>
                  CREATE
                </Link>
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Action</th>
            <th>Sell</th>
            <th>Transaction No</th>
            <th>Finance Group</th>
            <th>
              Business Unit
              <br /> Name
            </th>
            <th>
              Company
              <br /> Code
            </th>
            <th>Company Name</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Bond ID</th>
            <th>Issuer</th>
            <th>
              Partner
              <br /> Code
            </th>
            <th>Partner</th>
            <th className="text-center">
              Nominal
              <br />
              Amount
            </th>
            <th>Currency</th>
            <th className="text-center">
              Outstanding
              <br />
              Amount
            </th>
            <th className="text-center">Unit</th>
            <th className="text-center">Outstanding Unit</th>
            <th className="text-center">
              Price Per Unit
              <br />
              (Nominal Amount)
            </th>
            <th className="text-center">Actual Amount</th>
            <th className="text-center">
              Price Per Unit
              <br />
              (Actual Amount)
            </th>
            <th className="text-center">EIR</th>
            <th>Status</th>
            <th>
              Created
              <br />
              Date
            </th>
            <th>
              Created
              <br />
              By
            </th>
            <th>
              Updated
              <br />
              Date
            </th>
            <th>
              Updated
              <br />
              By
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalSell
      text={modalSell.Source}
      modalRef={modalSellRef}
      state={modalSell}
      onSubmit={onSubmitModalSell}
      onChageModal={onChangeModalSell}
    />

    <ModalFunction
      textHeader="Active"
      textContent="active"
      modalRef={modalActiveRef}
      type="info"
      icon="icon-refresh"
      textBtn="Active"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
    />

    <ModalFunction
      textHeader="InActive"
      textContent="inactive"
      modalRef={modalInactiveRef}
      type="danger"
      icon="icon-close"
      textBtn="Yes"
      textBtnBack="No"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
    />
  </div>
);
