import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import InputMask from '../../../common/InputMask';
import { DATATABLE_ID } from './MtmBondContainer';
import FormGroup2 from '../../../common/FormGroup2';
import FormGroup3 from '../../../common/FormGroup3';
import MonthPicker from '../../../common/MonthPicker';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { toNumber, toStatus, getPermission } from '../../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('Funding&Investment', 'MTM Bond');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX2 = ['col-md-4', 'col-md-5'];

const opt = {
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'transactionNo' },
    { data: 'bondId', className: 'text-center' },
    { data: 'mtmDateStr', className: 'text-center', type: 'date-black' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'outstanding',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'pricePerUnit', className: 'text-right', render: (d, t, r) => toNumber(d, 6) },
    {
      data: 'mtmPrice',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'netMtmAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'netMtmCurrency', className: 'text-center' },
    { data: 'status', className: 'text-center', render: (d) => toStatus(d) },
    { data: 'postTransactionStatus', className: 'text-center' },
  ],
};

export default ({
  criteria,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,
  onClickFetchSwap,
  results,
  dataTableRef,

  modalData,
  modalDataRef,
  onChangeModalData,
  onClickSaveModalData,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Loan & Investment <span className="icon icon-angle-double-right" /> Mark To Market Bond
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group">
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroups}
                name="FinanceGroups"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companies}
                financeGroupId={criteria.FinanceGroups}
                businessUnitId={criteria.BusinessUnits}
                name="Companies"
              />
            </FormGroup2>

            <FormGroup2 text="Bond ID">
              <input
                className="form-control"
                onChange={onChangeSearch}
                value={criteria.BondId}
                name="BondId"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="MTM Month From" required>
              <MonthPicker
                onChange={onChangeSearch}
                required
                value={criteria.MtmDateFromStr}
                name="MtmDateFromStr"
              />
            </FormGroup2>

            <FormGroup2 text="MTM Month To" required={false}>
              <MonthPicker
                onChange={onChangeSearch}
                required={false}
                value={criteria.MtmDateToStr}
                name="MtmDateToStr"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Currency">
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.Currency}
                name="Currency"
              />
            </FormGroup2>

            <FormGroup2 text="Status">
              <MasterDropdownUI
                onChange={onChangeSearch}
                value={criteria.Status}
                name="Status"
                isAll
                notMultiple
                options={['Active', 'Inactive']}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Post Transaction Status">
              <MasterDropdownUI
                onChange={onChangeSearch}
                value={criteria.PostTransactionStatus}
                name="PostTransactionStatus"
                isAll
                notMultiple
                options={['New', 'In Progress', 'Completed', 'Failed']}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Edit</th>
            <th>Transaction No.</th>
            <th>Bond ID</th>
            <th>MTM Date</th>
            <th>Finance Group</th>
            <th>Business Unit</th>
            <th>
              Company
              <br /> Code
            </th>
            <th>Company Name</th>
            <th className="text-center">Outstanding</th>
            <th className="text-center">Price Per Unit</th>
            <th className="text-center">MTM Price</th>
            <th className="text-center">Net MTM Amount</th>
            <th>
              Net MTM
              <br /> Currency
            </th>
            <th>Status</th>
            <th>
              Post Transaction
              <br /> Status
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <Modal textHeader="Edit" ref={modalDataRef} bgHeader="bg-primary">
      <form onSubmit={onClickSaveModalData} className="form-horizontal">
        <FormGroup3 text="Payment Date" col={colX2} required>
          <input
            className="form-control"
            onChange={onChangeModalData}
            value={modalData.TransactionNo}
            name="TransactionNo"
            readOnly
            required
          />
        </FormGroup3>

        <FormGroup3 text="MTM Price" col={colX2} required>
          <InputMask
            className="form-control"
            format="currency"
            option={modalData.inputMaskAmount}
            onChange={onChangeModalData}
            value={modalData.MtmPrice}
            required
            name="MtmPrice"
          />
        </FormGroup3>

        <br />
        <div>
          <center>
            <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
          </center>
        </div>
      </form>
    </Modal>
  </div>
);
