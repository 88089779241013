import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_INTEREST_REPORT_CRITERIA = 'EDIT_INTEREST_REPORT_CRITERIA';
export const CLEAR_INTEREST_REPORT_CRITERIA = 'CLEAR_INTEREST_REPORT_CRITERIA';

export const REQUEST_SEARCH_INTEREST_REPORT = '@@request/SARCH_INTEREST_REPORT';
export const RECEIVE_SEARCH_INTEREST_REPORT = '@@receive/SEARCH_INTEREST_REPORT';
export const FAIL_SEARCH_INTEREST_REPORT = '@@fail/SEARCH_INTEREST_REPORT';

export const REQUEST_DOWNLOAD_INTEREST_REPORT = '@@request/DOWNLOAD_INTEREST_REPORT';
export const RECEIVE_DOWNLOAD_INTEREST_REPORT = '@@receive/DOWNLOAD_INTEREST_REPORT';
export const FAIL_DOWNLOAD_INTEREST_REPORT = '@@fail/DOWNLOAD_INTEREST_REPORT';

export const downloadInterestReport = (datas) => ({
  [RSAA]: {
    endpoint: endpoint('/report/ExportInterestReport'),
    method: 'POST',
    body: JSON.stringify(datas),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DOWNLOAD_INTEREST_REPORT,
      {
        type: RECEIVE_DOWNLOAD_INTEREST_REPORT,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_DOWNLOAD_INTEREST_REPORT,
    ],
  },
});

export const searchInterestReport = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/getInterestReport', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_INTEREST_REPORT,
      RECEIVE_SEARCH_INTEREST_REPORT,
      FAIL_SEARCH_INTEREST_REPORT,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_INTEREST_REPORT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_INTEREST_REPORT_CRITERIA,
  payload: {
    name,
    value,
  },
});
