import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_REQUEST_TT_REMITTANCE = '@@request/SEARCH_REQUEST_TT_REMITTANCE';
export const RECEIVE_SEARCH_REQUEST_TT_REMITTANCE = '@@receive/SEARCH_REQUEST_TT_REMITTANCE';
export const FAIL_SEARCH_REQUEST_TT_REMITTANCE = '@@fail/SEARCH_REQUEST_TT_REMITTANCE';

export const UPDATE_SEARCH_RESULT_REQUEST_TT_REMITTANCE =
  'UPDATE_SEARCH_RESULT_REQUEST_TT_REMITTANCE';

export const REQUEST_CHANGE_STATUS_REQUEST_TT_REMITTANCE =
  '@@request/CHANGE_STATUS_REQUEST_TT_REMITTANCE';
export const RECEIVE_CHANGE_STATUS_REQUEST_TT_REMITTANCE =
  '@@receive/CHANGE_STATUS_REQUEST_TT_REMITTANCE';
export const FAIL_CHANGE_STATUS_REQUEST_TT_REMITTANCE =
  '@@fail/CHANGE_STATUS_REQUEST_TT_REMITTANCE';

export const REQUEST_GET_REQUEST_TT_REMITTANCE = '@@request/GET_REQUEST_TT_REMITTANCE';
export const RECEIVE_GET_REQUEST_TT_REMITTANCE = '@@receive/GET_REQUEST_TT_REMITTANCE';
export const FAIL_GET_REQUEST_TT_REMITTANCE = '@@fail/GET_REQUEST_TT_REMITTANCE';

export const EDIT_REQUEST_TT_REMITTANCE_CRITERIA = 'EDIT_REQUEST_TT_REMITTANCE_CRITERIA';
export const CLEAR_REQUEST_TT_REMITTANCE_CRITERIA = 'CLEAR_REQUEST_TT_REMITTANCE_CRITERIA';

export const REQUEST_REQUEST_TT_REMITTANCE_MANUAL = '@@request/REQUEST_TT_REMITTANCE_MANUAL';
export const RECEIVE_REQUEST_TT_REMITTANCE_MANUAL = '@@receive/REQUEST_TT_REMITTANCE_MANUAL';
export const FAIL_REQUEST_TT_REMITTANCE_MANUAL = '@@fail/REQUEST_TT_REMITTANCE_MANUAL';

export const REQUEST_GET_ACCOUNTING_VERIFY = '@@request/GET_ACCOUNTING_VERIFY';
export const RECEIVE_GET_ACCOUNTING_VERIFY = '@@receive/GET_ACCOUNTING_VERIFY';
export const FAIL_GET_ACCOUNTING_VERIFY = '@@fail/GET_ACCOUNTING_VERIFY';

export const getAccoutingVerify = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/accountingverify', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_ACCOUNTING_VERIFY,
      RECEIVE_GET_ACCOUNTING_VERIFY,
      FAIL_GET_ACCOUNTING_VERIFY,
    ],
  },
});

export const saveTtRemittanceManual = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/savettremittancemanual'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_REQUEST_TT_REMITTANCE_MANUAL,
      RECEIVE_REQUEST_TT_REMITTANCE_MANUAL,
      FAIL_REQUEST_TT_REMITTANCE_MANUAL,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_REQUEST_TT_REMITTANCE_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_REQUEST_TT_REMITTANCE_CRITERIA,
  payload: {
    name,
    value,
  },
});

export const getTtRemittance = (transctionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`ttremittance/GetRequestTt/${transctionNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_REQUEST_TT_REMITTANCE,
      RECEIVE_GET_REQUEST_TT_REMITTANCE,
      FAIL_GET_REQUEST_TT_REMITTANCE,
    ],
  },
});

export const changeStatus = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/savettremittancemanual'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_CHANGE_STATUS_REQUEST_TT_REMITTANCE,
      RECEIVE_CHANGE_STATUS_REQUEST_TT_REMITTANCE,
      FAIL_CHANGE_STATUS_REQUEST_TT_REMITTANCE,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_REQUEST_TT_REMITTANCE,
  payload: {
    name,
    value,
    index,
  },
});

export const searchTtRemittance = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/SearchRequestTt', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_REQUEST_TT_REMITTANCE,
      RECEIVE_SEARCH_REQUEST_TT_REMITTANCE,
      FAIL_SEARCH_REQUEST_TT_REMITTANCE,
    ],
  },
});
