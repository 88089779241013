import React from 'react';

import Tab from '../../../common/Tab';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Select2 from '../../../common/Select2';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_BANK_CODE } from '../../../reducers/master/action';
import { toFixed, toLower, getPermission } from '../../../common/helpper';

const permission = getPermission('FX Management', 'Pricing Rate to Bank');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  criteria,
  results,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  dataTableRef,

  bankTab,
  tabRef,

  modalData,
  onClickSwapRate,
  onClickSpotRate,
  onSubmitRate,
  onChangeInput,
  modalSwapRef,
  modalSpotRef,

  onClickExport,
  onClickFetchEmail,
  onClickSendEmail,
  onClickOpenUploadExcel,

  matchingDate,
  matchingTime,
}) => {
  const tabContents = [];

  bankTab.map((bank) =>
    tabContents.push({
      header: <span>{bank.Bank}</span>,
      content: (
        <BodyTab
          Bank={bank.Bank}
          BankCode={bank.BankCode}
          StatusSwap={bank.StatusSwap}
          CurrencyList={bank.CurrencyList}
          onClickSwapRate={onClickSwapRate}
          onClickSpotRate={onClickSpotRate}
        />
      ),
      tabName: bank.Bank,
    })
  );

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" /> Pricing Rate to Bank
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  onChange={onChangeSelect2}
                  value={criteria.BankCode}
                  name="BankCode"
                  isAll
                  isPoSo
                />
              </FormGroup2>

              <FormGroup2 text="Matching Date" required>
                <DatePicker
                  onChange={onChangeSearch}
                  value={criteria.MatchingDateStr}
                  required
                  option={{
                    todayHighlight: true,
                  }}
                  name="MatchingDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Matching Time" required>
                <Select2
                  value={criteria.MatchingTime}
                  notMultiple
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  name="MatchingTime"
                  required
                >
                  {matchingTime.map((m) => (
                    <option value={m.time} key={m.time}>
                      {m.time}
                    </option>
                  ))}
                </Select2>
              </FormGroup2>

              <FormGroup2 text="Entry Date" required={false}>
                <Select2
                  value={criteria.EntryDateStr}
                  notMultiple
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  name="EntryDateStr"
                  required={false}
                >
                  {matchingDate.map((m) => (
                    <option value={m.dateFromTo} key={m.dateFromTo}>
                      {m.dateFromTo}
                    </option>
                  ))}
                </Select2>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div>
              <Button
                txt="Fetch email"
                icon="refresh"
                className="btn-warning"
                onClick={onClickFetchEmail}
                style={{ marginBottom: '15px' }}
              />
              &nbsp;
              <Button
                txt="Upload"
                icon="upload"
                className="btn-info"
                onClick={onClickOpenUploadExcel}
                style={{ marginBottom: '15px' }}
              />
              &nbsp;
              <Button
                txt="Send email"
                icon="envelope-o"
                className="btn-purple"
                onClick={onClickSendEmail}
                style={{ marginBottom: '15px' }}
              />
              &nbsp;
              <Button
                txt="Export"
                icon="credit-card-alt"
                className="btn-default"
                onClick={onClickExport}
                style={{ marginBottom: '15px' }}
              />
            </div>
          </Col2>
        ) : null}

        <Tab tabs={tabContents} ref={tabRef} />
      </Card>

      <Modal textHeader="Swap Rate" bgHeader="bg-primary" size="modal-md" ref={modalSwapRef}>
        <form onSubmit={onSubmitRate} className="form-horizontal">
          <div className="form-group">
            <label className="control-label col-sm-4">Bank</label>
            <div className="col-sm-7" style={{ paddingTop: '7px' }}>
              <p>{modalData.Bank}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4">Currency</label>
            <div className="col-sm-7" style={{ paddingTop: '7px' }}>
              <p>{modalData.BuyCurrency}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4">End Date</label>
            <div className="col-sm-7" style={{ paddingTop: '7px' }}>
              <p>{modalData.EndDateStr}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4">Transaction Type</label>
            <div className="col-sm-7" style={{ paddingTop: '7px' }}>
              <p>{modalData.TransactionType}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4">Swap Rate</label>
            <div className="col-sm-4">
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInput}
                option={{
                  prefix: '',
                  digits: 8,
                  digitsOptional: false,
                  placeholder: '0.00000000',
                }}
                required
                value={modalData.SwapRate}
                name="SwapRate"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal textHeader="Spot Rate" bgHeader="bg-primary" size="modal-md" ref={modalSpotRef}>
        <form onSubmit={onSubmitRate} className="form-horizontal">
          <div className="form-group">
            <label className="control-label col-sm-4">Bank</label>
            <div className="col-sm-7" style={{ paddingTop: '7px' }}>
              <p>{modalData.Bank}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4">Currency</label>
            <div className="col-sm-7" style={{ paddingTop: '7px' }}>
              <p>{modalData.BuyCurrency}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4">Type</label>
            <div className="col-sm-7" style={{ paddingTop: '7px' }}>
              <p>{modalData.Type}</p>
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-sm-4">Spot Rate</label>
            <div className="col-sm-4">
              <InputMask
                className="form-control"
                format="currency"
                onChange={onChangeInput}
                option={{
                  prefix: '',
                  digits: 8,
                  digitsOptional: false,
                  placeholder: '0.00000000',
                }}
                required
                value={modalData.SpotRate}
                name="SpotRate"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

function BodyTab({ Bank, BankCode, StatusSwap, CurrencyList, onClickSwapRate, onClickSpotRate }) {
  // console.log(Bank, CurrencyList)
  return (
    <div>
      <p style={{ fontSize: '14px', marginBottom: 15, fontWeight: 600 }}>
        Swap rate from bank :
        {toLower(StatusSwap) === 'completed' ? (
          <span className="label label-success" style={{ marginLeft: 5 }}>
            {StatusSwap}
          </span>
        ) : (
          <span className="label label-info" style={{ marginLeft: 5 }}>
            {StatusSwap}
          </span>
        )}
      </p>
      {(CurrencyList || []).map((c, indexCurrency) => (
        <Card
          key={indexCurrency}
          textHeader={c.Currency}
          bgHeader="bg-primary"
          cardActions={['toggler']}
        >
          <div className="table-responsive">
            <table className="table table-bordered table-nowrap">
              <thead>
                <tr>
                  {(c.Header || []).map((header, i) => (
                    <th className="th-white" key={i} style={{ minWidth: '80px' }}>
                      {i === 3 ? '' : header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {(c.Import || []).map((body, i) => (
                  <tr key={i}>
                    {body.map((b, j) => (
                      <td
                        key={j}
                        style={{ fontWeight: j == 0 || j == 1 ? 600 : 500 }}
                        align={
                          i === 0
                            ? j <= 2
                              ? 'left'
                              : j === 3
                                ? 'right'
                                : b == null || b === ''
                                  ? 'center'
                                  : 'right'
                            : j <= 1
                              ? 'left'
                              : j === 2
                                ? 'right'
                                : j !== 3
                                  ? 'right'
                                  : b == null || b === ''
                                    ? 'center'
                                    : 'right'
                        }
                      >
                        {i == 0 ? (
                          j == 0 || j == 1 || j == 2 || j == 3 ? (
                            b
                          ) : b !== '' && b !== null ? (
                            <a
                              href="#"
                              onClick={(e) =>
                                onClickSwapRate(
                                  e,
                                  Bank,
                                  BankCode,
                                  c.Currency,
                                  c.Header[j],
                                  'Purchase Document',
                                  b
                                )
                              }
                            >
                              {setNumber(b, 8)}
                            </a>
                          ) : (
                            <button
                              className="btn btn-icon btn-info"
                              onClick={(e) =>
                                onClickSwapRate(
                                  e,
                                  Bank,
                                  BankCode,
                                  c.Currency,
                                  c.Header[j],
                                  'Purchase Document'
                                )
                              }
                            >
                              <span className="icon icon-edit sq-24" />
                            </button>
                          )
                        ) : (i == 1 || i == 2) && j == 3 ? (
                          b !== '' && b !== null ? (
                            <a
                              href="#"
                              onClick={(e) =>
                                onClickSpotRate(
                                  e,
                                  Bank,
                                  BankCode,
                                  c.Currency,
                                  i === 1 ? 'MatchingImport' : 'NetImport',
                                  i === 1 ? 'Matching Import' : 'Net Import',
                                  b
                                )
                              }
                            >
                              {setNumber(b, 8)}
                            </a>
                          ) : c.CheckSpotImport[i - 1] ? (
                            ''
                          ) : (
                            <button
                              className="btn btn-icon btn-success"
                              onClick={(e) =>
                                onClickSpotRate(
                                  e,
                                  Bank,
                                  BankCode,
                                  c.Currency,
                                  i === 1 ? 'MatchingImport' : 'NetImport',
                                  i === 1 ? 'Matching Import' : 'Net Import'
                                )
                              }
                              disabled={c.CheckSpotImport[i - 1]}
                            >
                              <span className="icon icon-edit sq-24" />
                            </button>
                          )
                        ) : j == 0 || j == 1 || j == 3 ? (
                          b
                        ) : b !== '' && b !== null ? (
                          setNumber(b, c.CurrencyDigit)
                        ) : (
                          ''
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
                {(c.Export || []).map((body, i) => (
                  <tr key={i}>
                    {body.map((b, j) => (
                      <td
                        key={j}
                        style={{ fontWeight: j == 0 || j == 1 ? 600 : 500 }}
                        align={
                          i === 0
                            ? j <= 2
                              ? 'left'
                              : j === 3
                                ? 'right'
                                : b == null || b === ''
                                  ? 'center'
                                  : 'right'
                            : j <= 1
                              ? 'left'
                              : j === 2
                                ? 'right'
                                : j !== 3
                                  ? 'right'
                                  : b == null || b === ''
                                    ? 'center'
                                    : 'right'
                        }
                      >
                        {i == 0 ? (
                          j == 0 || j == 1 || j == 2 || j == 3 ? (
                            b
                          ) : b !== '' && b !== null ? (
                            <a
                              href="#"
                              onClick={(e) =>
                                onClickSwapRate(
                                  e,
                                  Bank,
                                  BankCode,
                                  c.Currency,
                                  c.Header[j],
                                  'Sale Document',
                                  b
                                )
                              }
                            >
                              {setNumber(b, 8)}
                            </a>
                          ) : (
                            <button
                              className="btn btn-icon btn-info"
                              onClick={(e) =>
                                onClickSwapRate(
                                  e,
                                  Bank,
                                  BankCode,
                                  c.Currency,
                                  c.Header[j],
                                  'Sale Document'
                                )
                              }
                            >
                              <span className="icon icon-edit sq-24" />
                            </button>
                          )
                        ) : (i == 1 || i == 2) && j == 3 ? (
                          b !== '' && b !== null ? (
                            <a
                              href="#"
                              onClick={(e) =>
                                onClickSpotRate(
                                  e,
                                  Bank,
                                  BankCode,
                                  c.Currency,
                                  i === 1 ? 'MatchingExport' : 'NetExport',
                                  i === 1 ? 'Matching Export' : 'Net Export',
                                  b
                                )
                              }
                            >
                              {setNumber(b, 8)}
                            </a>
                          ) : c.CheckSpotExport[i - 1] ? (
                            ''
                          ) : (
                            <button
                              className="btn btn-icon btn-success"
                              onClick={(e) =>
                                onClickSpotRate(
                                  e,
                                  Bank,
                                  BankCode,
                                  c.Currency,
                                  i === 1 ? 'MatchingExport' : 'NetExport',
                                  i === 1 ? 'Matching Export' : 'Net Export'
                                )
                              }
                              disabled={c.CheckSpotExport[i - 1]}
                            >
                              <span className="icon icon-edit sq-24" />
                            </button>
                          )
                        ) : j == 0 || j == 1 || j == 3 ? (
                          b
                        ) : b !== '' && b !== null ? (
                          setNumber(b, c.CurrencyDigit)
                        ) : (
                          ''
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      ))}
    </div>
  );
}

const setNumber = (number, float = 2) => {
  if (isNaN(number)) return '';
  if (number >= 0) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>{toFixed(number, float)}</span>;
};
