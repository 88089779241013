import { connect } from 'react-redux';
import React, { Component } from 'react';

import Unwind from './Unwind';
import { addAlert } from '../../reducers/layout/action';
import { toLower, checkWeekend } from '../../common/helpper';
import { getUnwind, saveUnwind } from '../../reducers/manageFx/action';

class RolloverContainer extends Component {
  state = {
    criteria: {
      UnwindDateStr: '',
      SpotRate: '',
      UnwindPaymentDateStr: '',
    },
    results: [],
  };

  onChangeHandler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.t;
    if (!transactionNo) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select transaction no for RollOVer',
        type: 'error',
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.routeProp.match.params.t !== this.props.routeProp.match.params.t) {
      this.setState({
        results: [],
      });
    }
  }

  onClickProcessHandler = (e) => {
    e.preventDefault();

    const { t } = this.props.routeProp.match.params;
    if (!t) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'There no transaction no. for Unwind!',
      });
      return;
    }

    const criteria = { ...this.state.criteria };
    if (checkWeekend(criteria.UnwindDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Unwind Date can not be Weekend.',
      });
      return;
    }

    if (checkWeekend(criteria.UnwindPaymentDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Unwind Payment Date can not be Weekend.',
      });
      return;
    }

    if (!criteria.SpotRate || parseFloat(criteria.SpotRate || 0) === 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Spot Rate of must not be zero.',
      });
      return;
    }

    this.props
      .getUnwind({
        ForwardNoList: t.split(','),
        ...criteria,
      })
      .then((response) => {
        if (!response.error)
          this.setState({
            results: response.payload,
          });
      });
  };

  onChangeInputTable = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      results: state.results.map((m, i) => {
        if (i === index) {
          const obj = {
            ...m,
          };
          if (name === 'unwindAmount') {
            if (value <= obj.outstanding) obj[name] = value;
            obj.gainLossAmount = (obj.settlementRate - obj.spotRate) * obj.unwindAmount;
            if (toLower(obj.transactionType) === 'buy') obj.gainLossAmount *= -1;
          } else if (name === 'settlementRate') {
            obj[name] = value;
            obj.gainLossAmount = (obj.settlementRate - obj.spotRate) * obj.unwindAmount;
            if (toLower(obj.transactionType) === 'buy') obj.gainLossAmount *= -1;
          } else obj[name] = value;

          return obj;
        }
        return m;
      }),
    }));
  };

  onClickSaveHandler = (e) => {
    e.preventDefault();
    for (let i = 0; i < this.state.results.length; i++) {
      const row = this.state.results[i];
      if (!row.unwindPaymentDateStr) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Unwind Payment Date of ${row.forwardNo} must not be blank value.`,
        });
        return;
      }

      if (checkWeekend(row.unwindPaymentDateStr)) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Unwind Payment Date of ${row.forwardNo} must not be weekend.`,
        });
        return;
      }

      if (!row.unwindAmount || parseFloat(row.unwindAmount || 0) <= 0) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Unwind Amount of ${row.forwardNo} must be more than zero.`,
        });
        return;
      }

      if (!row.settlementRate || parseFloat(row.settlementRate || 0) === 0) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Settlement Rate of ${row.forwardNo} must not be zero.`,
        });
        return;
      }

      if (!row.gainLossCompanyCode) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Gain Loss Company of ${row.forwardNo} must not be blank value.`,
        });
        return;
      }

      if (!row.gainLossBankAccountNo) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: `Gain Loss Bank Account of ${row.forwardNo} must not be blank value.`,
        });
        return;
      }
    }
    this.props.saveUnwind([...this.state.results]).then((response) => {
      if (!response.error) {
        this.setState({
          results: [],
        });
        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The transaction was successfully saved',
          buttons: [
            <button
              className="btn btn-success"
              onClick={this.onClickBackToFXManagementPage}
              data-dismiss="modal"
            >
              Continue
            </button>,
          ],
        });
      }
    });
  };

  onClickBackToFXManagementPage = () => {
    this.props.routeProp.history.push('/fx-management/manage-fx-contract?s');
  };

  render() {
    const props = {
      onChangeProcess: this.onChangeHandler,
      onClickProcess: this.onClickProcessHandler,

      onChangeInputTable: this.onChangeInputTable,
      onClickSave: this.onClickSaveHandler,
    };

    return <Unwind {...props} {...this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.manageFx,
  }),
  {
    getUnwind,
    addAlert,
    saveUnwind,
  }
)(RolloverContainer);
