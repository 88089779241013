import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import { DATATABLE_ID } from './ApproverContainer';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import { MASTER_FINANCE, MASTER_COMPANY } from '../../reducers/master/action';
import {
  toLower,
  toStatus,
  toNumber,
  toAccount,
  getPermission,
  toStatusApprove,
} from '../../common/helpper';

const permissionBahtnet = getPermission('Approver', 'Task List (Bahtnet)');
const permissionTt = getPermission('Approver', 'Task List (T/T)');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const required = true;
const row = 'row gutter-xs';
const { moment } = window;

const opt = {
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    {
      orderable: false,
      data: 'IsSelected',
      className: 'text-center col-label-checkbox',
      render: (d, t, r) => {
        if (!r.canApprove) {
          r.canSelect = false;
          return '';
        }
        if (toLower(r.transactionNo).includes('bg') || toLower(r.module) === 'poa') {
          r.canSelect = r.canApprove;
          return r.canApprove
            ? `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`
            : '';
        }
        if (
          !moment(r.valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(-1, 'days')) ||
          !r.canApprove
        ) {
          r.canSelect = false;
          return '';
        }
        r.canSelect = r.canApprove;
        return r.canApprove
          ? `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`
          : '';
      },
    },
    { data: 'transactionNo' },
    {
      data: 'newFilename',
      render: (d, r, s) =>
        d
          ? '<button class="btn btn-info btn-sm download-poa"><i class="icon icon-download icon-fw icon-lg"></i></button>'
          : '',
      className: 'text-center',
    },
    {
      data: 'valueDateStr',
      className: 'text-center',
      type: 'date-black',
      render: (d, t, r) => {
        if (toLower(r.transactionNo).includes('bg')) {
          return '';
        }
        return d;
      },
    },
    { data: 'module' },
    { data: 'lgTypeName' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'sendingType' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'applicantBankAccountNo',
      render: (d, r, s) =>
        toAccount(
          s.applicantBankAbbreviate,
          s.applicantBranchName,
          s.applicantAccountTypeCode,
          s.applicantBankCurrency,
          d
        ),
    },
    { data: 'beneficiaryCompanyCode', className: 'text-center' },
    { data: 'beneficiaryCompanyName' },
    {
      data: 'beneficiaryBankAccountNo',
      render: (d, r, s) => {
        if (toLower(s.transactionNo).includes('tt')) return `${d} | ${s.beneficiaryBankName}`;
        return toAccount(
          s.beneficiaryBankAbbreviate,
          s.beneficiaryBranchName,
          s.beneficiaryAccountTypeCode,
          s.beneficiaryBankCurrency,
          d
        );
      },
    },
    { data: 'transferMethod', className: 'text-center' },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
    },
    { data: 'currency', className: 'text-center' },
    { data: 'transactionStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    { data: 'postTransactionStatus', className: 'text-center' },
    { data: 'chequeNo' },
    { data: 'requestorName' },
    { data: 'approverName1' },
    { data: 'approver1Status', render: (d, t, r) => toStatusApprove(r.approverName1, d) },
    { data: 'approverName2' },
    { data: 'approver2Status', render: (d, t, r) => toStatusApprove(r.approverName2, d) },
    { data: 'reviewerName' },
    { data: 'reviewerStatus', render: (d, t, r) => toStatusApprove(r.reviewer, d) },
    { data: 'verifyName' },
    { data: 'verifyStatus', render: (d, t, r) => toStatusApprove(r.verify, d) },
    { data: 'errorMessage', className: 'text-center' },
  ],
};

export default ({
  criteria,
  results,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,

  modalApproveRef,
  onClickOpenModalApprove,
  onClickCloseModalApprove,
  onClickConfirmChangeApproveStatus,

  modalDisapproveRef,
  onClickOpenModalDisapprove,
  onClickCloseModalDisapprove,

  dataTableRef,
}) => {
  const selectedApproveStatusLength = results.filter((f) => f.IsSelected).length;
  const selectedDisApproveStatusLength = results.filter((f) => f.IsSelected).length;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Approver <span className="icon icon-angle-double-right" /> List Approve
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Value Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                  required={false}
                />
              </FormGroup2>

              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={criteria.FinanceGroupId}
                  onChange={onChangeSelect2}
                  name="FinanceGroupId"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  name="Companies"
                  // saveLocalState={true}
                  // financeGroupId={criteria.FinanceGroups}
                  // businessUnitId={criteria.BusinessUnits}
                />
              </FormGroup2>

              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  name="Status"
                >
                  <option value="Wait For Approve">Wait For Approve</option>
                  <option value="Approved">Approved</option>
                  <option value="Disapproved">Disapproved</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>
          <Col2 col={colX[2]}>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permissionBahtnet || permissionTt ? (
          <div className={row}>
            <div className="col-md-12">
              <Button
                txt="APPROVE"
                icon="check"
                className="btn-success"
                onClick={onClickOpenModalApprove}
                disabled={!selectedApproveStatusLength}
              />
              &nbsp;
              <Button
                txt="DISAPPROVE"
                icon="close"
                className="btn-danger"
                onClick={onClickOpenModalDisapprove}
                disabled={!selectedDisApproveStatusLength}
              />
            </div>
          </div>
        ) : null}

        <Table
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
          ref={dataTableRef}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>Transaction No.</th>
              <th>Download</th>
              <th>Value Date</th>
              <th>Module</th>
              <th>
                LG Type
                <br />
                Name
              </th>
              <th>
                Finance
                <br />
                Group
              </th>
              <th>
                Business
                <br />
                Unit
              </th>
              <th>Sending Type</th>
              <th style={{ backgroundColor: '#5C9BD1', color: '#fff' }}>
                Applicant
                <br />
                Company Code
              </th>
              <th style={{ backgroundColor: '#5C9BD1', color: '#fff' }}>
                Applicant
                <br />
                Company Name
              </th>
              <th style={{ backgroundColor: '#5C9BD1', color: '#fff' }}>
                Applicant
                <br />
                Bank Account
              </th>
              <th style={{ backgroundColor: '#3FABA4', color: '#fff' }}>
                Beneficiary
                <br />
                Company Code
              </th>
              <th style={{ backgroundColor: '#3FABA4', color: '#fff' }}>
                Beneficiary
                <br />
                Company Name
              </th>
              <th style={{ backgroundColor: '#3FABA4', color: '#fff' }}>
                Beneficiary
                <br />
                Bank Account
              </th>
              <th>
                Transfer
                <br />
                Method
              </th>
              <th>Amount</th>
              <th>Currency</th>
              <th>
                Transaction
                <br />
                Status
              </th>
              <th>
                Post Transaction
                <br />
                Status
              </th>
              <th>
                Cheque
                <br />
                No.
              </th>
              <th>Requestor</th>
              <th>
                Assign
                <br />
                Approver 1
              </th>
              <th>Status</th>
              <th>
                Assign
                <br />
                Approver 2
              </th>
              <th>Status</th>
              <th>Reviewer</th>
              <th>Status</th>
              <th>Verify</th>
              <th>Status</th>
              <th>
                Error
                <br />
                Message
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-sm" ref={modalApproveRef} modalFooter>
        <div className="text-center">
          <span className="text-success icon icon-check icon-5x" />
          <h3 className="text-success">Approve</h3>
          <p>
            {selectedApproveStatusLength} items selected, <br />
            will you make an Approve?
          </p>
          <div className="m-t-lg">
            <Button
              txt="Approve"
              className="btn-success"
              onClick={(e) => onClickConfirmChangeApproveStatus('Approved')}
            />
            &nbsp;
            <Button txt="Back" className="btn-default" onClick={onClickCloseModalApprove} />
          </div>
        </div>
      </Modal>

      <Modal size="modal-sm" ref={modalDisapproveRef} modalFooter>
        <div className="text-center">
          <span className="text-danger icon icon-close icon-5x" />
          <h3 className="text-danger">Disapprove</h3>
          <p>
            {selectedDisApproveStatusLength} items selected, <br />
            will you make a Disapprove?
          </p>
          <div className="m-t-lg">
            <Button
              txt="Disapprove"
              className="btn-danger"
              onClick={(e) => onClickConfirmChangeApproveStatus('Disapproved')}
            />
            &nbsp;
            <Button txt="Back" className="btn-default" onClick={onClickCloseModalDisapprove} />
          </div>
        </div>
      </Modal>
    </div>
  );
};
