import {
  EDIT_REQUEST_BG_CRITERIA,
  RECEIVE_SEARCH_REQUEST_BG,
  CLEAR_REQUEST_BG_CRITERIA,
  RECEIVE_GET_FACILITY_LIST,
  UPDATE_SEARCH_RESULT_REQUEST_BG,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  Banks: [''],
  Currencies: [''],
  EffectiveDateStr: '',
  RequestDateStr: '',
  ExpireDateStr: '',
  BeneficiaryId: [''],
  LgTypeId: [''],
  FormFormat: '',
  BgFormId: [''],
  StatusLg: '',
  StatusBank: '',
  LgMethod: '',
  IssueDateStr: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
  facilityList: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_GET_FACILITY_LIST:
      return {
        ...state,
        facilityList: [...action.payload],
      };
    case UPDATE_SEARCH_RESULT_REQUEST_BG:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_REQUEST_BG_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_REQUEST_BG_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_REQUEST_BG:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
