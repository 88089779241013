import qs from 'querystring';
import React, { useRef, Fragment, Component, useEffect } from 'react';

import { store } from '../../Root';
import Card from '../../common/Card';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import { addAlert } from '../../reducers/layout/action';
import MasterDropdown from '../../common/MasterDropdown';
import { toNumber, toFixedNumber } from '../../common/helpper';
import { MASTER_COMPANY, MASTER_CURRENCY } from '../../reducers/master/action';
import { progressAutomate, submitProgressAutomate } from '../../reducers/cashPosition/action';

const defaultCriteria = {
  CompanyCode: '',
  Currency: '',
};
const defaultProgressHeader = {
  companyFullName: '',
  minBalance: '',
  netBalance: '',
  progressAmount: '',
  currency: '',
};
export default class AutoMateProgress extends Component {
  state = {
    criteria: { ...defaultCriteria },
    progressHeader: { ...defaultProgressHeader },
    progressDetails: [],
    showResult: false,
  };

  onChangeCriteria = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      criteria: {
        ...state.criteria,
        [name]: value,
      },
    }));
  };

  onClickProgress = async () => {
    const response = await store.dispatch(
      progressAutomate({
        ...this.state.criteria,
        ValueDateStr: this.props.valueDate,
      })
    );
    if (response.error) return;
    const result = response.payload || {};
    this.setState({
      progressHeader: result.summary,
      progressDetails: result.details,
      showResult: true,
    });
  };

  onClickSumitProgress = async () => {
    const filterEmptyAmount = this.state.progressDetails.filter(
      (f) => f.actualProgress && !f.suggestAmount
    );
    if (filterEmptyAmount.length) {
      store.dispatch(
        addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please fill specific suggest amount for selected item.',
        })
      );
      return;
    }

    const data = {
      Details: [...this.state.progressDetails],
      Summary: {
        ...this.state.progressHeader,
      },
    };
    const response = await store.dispatch(submitProgressAutomate(data));
    if (response.error) return;

    // redirect to auto transaction
    const { companyCode, currency, financeGroup, valueDateStr } = this.state.progressHeader;
    const o = {
      cur: currency,
      com: companyCode,
      f: financeGroup,
      v: valueDateStr,
      p: 1,
    };
    const query = qs.stringify(o);
    window.open(`/cash-management/auto-transfer?${query}`, '_blank');
    if (this.props.onSubmit) this.props.onSubmit();
  };

  processData(data = []) {
    const newData = [];
    data.forEach((m, idx) => {
      const isNew = m.type.includes('New');
      let desc = '';
      if (isNew) {
        desc = m.type;
      } else if (m.type === 'Loan') {
        if (m.direction === 'Increase') desc = 'Rollover';
        else if (m.suggestAmount < m.amount) desc = 'Rollover';
        else desc = 'Repay';
      } else if (m.suggestAmount < m.amount) desc = 'Rollover';
      else desc = 'Withdraw';
      newData.push({
        ...m,
        desc,
        idx,
      });
    });

    return newData;
  }

  onChangeInputTable = (e, idx) => {
    let { name, value, type, checked } = e.target;
    this.setState((state) => ({
      ...state,
      progressDetails: state.progressDetails.map((m, index) => {
        if (index !== idx) return m;
        if (name === 'suggestAmount') {
          if (m.direction === 'Decrease' && toFixedNumber(value) > m.amount) {
            value = m.amount;
          } else if (
            m.direction === 'Increase' &&
            m.type === 'Loan' &&
            toFixedNumber(value) > m.facilityOutstanding
          ) {
            value = m.facilityOutstanding;
          }
        }
        if (name === 'termEndStr' && value) {
          const { termStartStr } = m;
          const start = window.moment(termStartStr, 'DD/MM/YYYY');
          const end = window.moment(value, 'DD/MM/YYYY');
          if (end.isSameOrBefore(start)) {
            value = '';
          }
        }

        return {
          ...m,
          [name]: type === 'checkbox' ? checked : value,
        };
      }),
    }));
  };

  renderProgressResult() {
    if (!this.state.showResult) return null;
    const { progressHeader, progressDetails } = this.state;
    const bodyData = this.processData(progressDetails);

    return (
      <Card textHeader="Progress Result" number={2} bgHeader="bg-info" cardActions={['toggler']}>
        <AutoMateProgressHeader progressHeader={progressHeader} />
        <AutoMateProgressDetails
          progressDetails={bodyData}
          onChangeInput={this.onChangeInputTable}
        />
        <div className="row">
          <div className="col-sm-12">
            <Button
              txt="Submit"
              icon="save"
              className="btn-success"
              onClick={this.onClickSumitProgress}
            />
          </div>
        </div>
      </Card>
    );
  }

  render() {
    return (
      <>
        <AutoMateProgressCriteria
          criteria={this.state.criteria}
          onChange={this.onChangeCriteria}
          onClickProgress={this.onClickProgress}
        />
        {this.renderProgressResult()}
      </>
    );
  }
}

const AutoMateProgressDetails = ({ progressDetails = [], onChangeInput }) => {
  const renderBody = () => {
    if (progressDetails.length === 0)
      return (
        <tr>
          <td align="center" colSpan="13">
            No any Action.
          </td>
        </tr>
      );
    return progressDetails.map((m, i) => (
      <tr key={i}>
        <td align="center">
          <input
            id={`choose${i}`}
            type="checkbox"
            className="label-checkbox"
            onChange={(e) => onChangeInput(e, m.idx)}
            checked={m.actualProgress}
            name="actualProgress"
          />
          <label htmlFor={`choose${i}`} className="label-checkbox" />
        </td>
        <td>{m.type}</td>
        <td>{m.facilityName}</td>
        <td>{m.facilityName ? toNumber(m.facilityOutstanding) : null}</td>
        <td>{m.swiftCode}</td>
        <td>{m.bankFullName}</td>
        <td>{m.interestRate}</td>
        <td>{m.termStartStr}</td>
        <td align="right">{toNumber(m.amount)}</td>
        <td>
          {(m.loanType === 'TERM' && (
            <DatePicker
              className="td-width-number-lg"
              name="termEndStr"
              value={m.termEndStr}
              onChange={(e) => onChangeInput(e, m.idx)}
            />
          )) ||
            m.termEndStr}
        </td>
        <td>
          {m.disabled ? (
            toNumber(m.suggestAmount)
          ) : (
            <InputMask
              className="form-control td-width-number-lg"
              format="currency"
              value={m.suggestAmount}
              name="suggestAmount"
              onChange={(e) => onChangeInput(e, m.idx)}
            />
          )}
        </td>
        <td>{toNumber(m.interestAmount)}</td>
        <td>{m.direction}</td>
        <td>{m.desc}</td>
      </tr>
    ));
  };

  return (
    <div className="table-responsive">
      <table className="table table-bordered table-nowrap">
        <thead>
          <tr>
            <th>Choose</th>
            <th>Product</th>
            <th>Facility Name</th>
            <th>Avilable Facility</th>
            <th>Swift Code</th>
            <th>Bank</th>
            <th>Int(%)</th>
            <th>Term Start</th>
            <th>Outstanding</th>
            <th>Term End</th>
            <th>Suggested Amt.</th>
            <th>Interest Amt.</th>
            <th>Direction</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>{renderBody()}</tbody>
      </table>
    </div>
  );
};

const AutoMateProgressHeader = ({ progressHeader = defaultProgressHeader }) => {
  const { companyFullName, minBalance, netBalance, progressAmount, currency } = progressHeader;
  const companyFullNameRef = useRef(null);
  useEffect(() => {
    companyFullNameRef.current.value = companyFullName;
  }, [companyFullName]);
  return (
    <form className="form-horizontal">
      <FormGroup label="Company">
        <input
          ref={companyFullNameRef}
          type="text"
          className="form-control"
          disabled
          defaultValue={companyFullName}
        />
      </FormGroup>
      <FormGroup label="Min. Balance">
        <InputMask className="form-control" format="currency" value={minBalance} readOnly />
      </FormGroup>
      <FormGroup label="Net. Balance">
        <InputMask className="form-control" format="currency" value={netBalance} readOnly />
      </FormGroup>
      <FormGroup label="Cash Surplus/(Shortage)">
        <InputMask
          className="form-control"
          format="currency"
          value={(progressAmount || 0) * -1}
          readOnly
        />
      </FormGroup>
      <FormGroup label="Currency">
        <input type="text" className="form-control" disabled defaultValue={currency} />
      </FormGroup>
    </form>
  );
};

const AutoMateProgressCriteria = ({ criteria, onChange, onClickProgress }) => (
  <Card textHeader="Criteria" number={1} bgHeader="bg-info" cardActions={['toggler']}>
    <form className="form-horizontal">
      <FormGroup label="Company">
        <MasterDropdown
          masterType={MASTER_COMPANY}
          value={criteria.CompanyCode}
          notMultipleSelect2
          onChange={(e) =>
            onChange({
              target: e,
            })
          }
          name="CompanyCode"
          saveLocalState
        />
      </FormGroup>
      <FormGroup label="Currency">
        <MasterDropdown
          masterType={MASTER_CURRENCY}
          value={criteria.Currency}
          notMultipleSelect2
          onChange={(e) =>
            onChange({
              target: e,
            })
          }
          name="Currency"
          saveLocalState
        />
      </FormGroup>
      {/* <FormGroup label="Mock Net. Balance">
                <input type="number" onChange={onChange} value={criteria.MockNetBalance} name="MockNetBalance" className="form-control" />
            </FormGroup> */}
      <div className="row">
        <div className="col-sm-12">
          <Button txt="Process" icon="refresh" className="btn-success" onClick={onClickProgress} />
        </div>
      </div>
    </form>
  </Card>
);

const FormGroup = ({ children, label }) => (
  <div className="form-group">
    <label className="control-label col-md-3">{label}</label>
    <div className="col-md-8">{children}</div>
  </div>
);
