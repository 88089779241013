import React, { Component } from 'react';

class Tab extends Component {
  state = {
    currentTab: '',
    isFadeIn: false,
  };

  timeout;

  speed = 100;

  componentDidMount() {
    const { tabs = [], activeTab } = this.props;
    if (tabs.length > 0) {
      this.setTabActive(!activeTab ? tabs[0].tabName : activeTab);
    }
  }

  setTabActive(name) {
    if (this.timeout) clearTimeout(this.timeout);

    if (this.props.onChangeTab) this.props.onChangeTab(name);
    // deactive fade in
    this.setState({
      isFadeIn: false,
    });
    this.timeout = setTimeout(() => {
      // set tab
      this.setState({
        currentTab: name,
      });

      // active fade in
      this.timeout = setTimeout(() => {
        this.setState({
          isFadeIn: true,
        });
        setTimeout(() => {
          // add resize for dataTable
          window.jQuery(window).trigger('resize');
        }, 10);
      }, this.speed);
    }, this.speed);
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeTab } = this.props;
    if (activeTab !== prevProps.activeTab)
      this.setState({
        currentTab: activeTab,
      });
  }

  render() {
    const { tabs = [], visualTab, children, footer } = this.props;
    const { currentTab, isFadeIn } = this.state;
    return (
      <div className="panel m-b-lg">
        <div className="nav nav-tabs">
          {tabs.map((m) => (
            <li
              key={m.tabName}
              className={m.tabName === currentTab ? 'active' : ''}
              onClick={(e) => this.setTabActive(m.tabName)}
            >
              <a>{m.header}</a>
            </li>
          ))}
        </div>
        <div className="tab-content">
          {visualTab
            ? children
            : tabs.map((m) => (
                <div
                  key={m.tabName}
                  className={`tab-pane fade ${m.tabName === currentTab ? 'active' : ''} ${m.tabName === currentTab && isFadeIn ? 'in' : ''}`}
                >
                  {m.content}
                </div>
              ))}
        </div>
        {footer ? (
          <div className="card-footer">
            <center>{footer}</center>
          </div>
        ) : null}
      </div>
    );
  }
}

export default Tab;
