import {
  RECEIVE_GET_FACILITY_LIST,
  EDIT_CONTRACT_ON_CALL_CRITERIA,
  RECEIVE_SEARCH_CONTRACT_ON_CALL,
  CLEAR_CONTRACT_ON_CALL_CRITERIA,
  UPDATE_SEARCH_RESULT_CONTRACT_ON_CALL,
  RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  Term: '',
  PartnerType: '',
  BusinessPartner: [''],
  ContractDateStr: '',
  TermStartStr: '',
  TermEndStr: '',
  TransactionNo: '',
  ExternalRef: '',
  Status: '',
  Source: 'Contract On Call',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
  template: [],
  facilityList: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_GET_FACILITY_LIST:
      return {
        ...state,
        facilityList: [...action.payload],
      };
    case RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE:
      return {
        ...state,
        template: [...action.payload],
      };
    case UPDATE_SEARCH_RESULT_CONTRACT_ON_CALL:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_CONTRACT_ON_CALL_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_CONTRACT_ON_CALL_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_CONTRACT_ON_CALL:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
