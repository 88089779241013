import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Toggle from '../../common/Toggle';
import Table from '../../common/DataTable';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import AutoMateProgress from './AutoMateProgess';
import MasterDropdown from '../../common/MasterDropdown';
import { DATATABLE_ID_SWEEP } from './CashPositionContainer';
import { toNumber, toAccount, getPermission } from '../../common/helpper';
import MasterDropdownUI, { MASTER_TRANSFER_METHOD } from '../../common/MasterDropdownUI';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_BANK_ACCOUNT,
  MASTER_ACCOUNT_TYPE,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const $ = window.jQuery;
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const required = true;

const permission = getPermission('Cash Management', 'Cash Position');

const opt = {
  colReorder: false,
  order: [[1, 'asc']],
  columns: [
    {
      orderable: false,
      data: null,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission || !d) return '';
        return '<button class="btn btn-icon btn-default edit-item"><span class="icon icon-text-width sq-24"></span></button>';
      },
    },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName', orderable: false },
    {
      orderable: false,
      data: 'bankAccountNo',
      render: (d, r, s) =>
        toAccount(s.bankAbbreviate, s.branchName, s.accountTypeCode, s.bankCurrency, d),
    },
    { data: 'bankCurrency', className: 'text-center', orderable: false },
    {
      data: 'overDraft',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    { data: 'sourceBy', className: 'text-center', orderable: false },
    {
      data: 'different',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    {
      data: 'beginningBalance',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    {
      data: 'inflow',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    {
      data: 'outflow',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(Math.abs(d) * -1, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    {
      data: 'icLoan',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    {
      data: 'investment',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    {
      data: 'externalLoan',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    {
      data: 'sweep',
      className: 'text-right td-width-number',
      render: (d, t, r) =>
        `<a href="javascript:;" class="get-sweep">${toNumber(d, r.currencyDigit, r.IsMillion)}</a>`,
      orderable: false,
    },
    {
      data: 'bankTransfer',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
    {
      data: 'endingBalanceFromSystem',
      className: 'text-right td-width-number',
      render: (d, t, r) => toNumber(d, r.currencyDigit, r.IsMillion),
      orderable: false,
    },
  ],
  rowGroup: {
    dataSrc: 'companyCode',
    startRender: (rows, group) => {
      let beginningBalance = 0;
      let inflow = 0;
      let outflow = 0;
      let loan = 0;
      let investment = 0;
      let externalLoan = 0;
      let sweep = 0;
      let bankTransfer = 0;
      let endingBalanceFromSystem = 0;
      let state = 'close';
      let companyName = '';
      let isMillion = false;
      const currencyPoint = 3;

      rows.data().each((m) => {
        beginningBalance += m.beginningBalance || 0;
        inflow += m.inflow || 0;
        outflow += Math.abs(m.outflow) || 0;
        loan += m.loan || 0;
        investment += m.investment || 0;
        externalLoan += m.externalLoan || 0;
        sweep += m.sweep || 0;
        bankTransfer += m.bankTransfer || 0;
        endingBalanceFromSystem += m.endingBalanceFromSystem || 0;
        state = m.state || 'close';
        companyName = m.companyName;
        isMillion = m.IsMillion;
      });

      return $('<tr></tr>')
        .append(
          `<td align="center" style="cursor:pointer;" class="toggle-row-cash-position" data-company="${group}" data-state=${state}><i class="icon icon-${state === 'open' ? 'minus' : 'plus'} icon-lg icon-fw"></i></td>`
        )
        .append(`<td align="center">${group}</td>`)
        .append(`<td colspan=6">${companyName}</td>`)
        .append(`<td align="right">${toNumber(beginningBalance, currencyPoint, isMillion)}</td>`)
        .append(`<td align="right">${toNumber(inflow, currencyPoint, isMillion)}</td>`)
        .append(`<td align="right">${toNumber(outflow * -1, currencyPoint, isMillion)}</td>`)
        .append(`<td align="right">${toNumber(loan, currencyPoint, isMillion)}</td>`)
        .append(`<td align="right">${toNumber(investment, currencyPoint, isMillion)}</td>`)
        .append(`<td align="right">${toNumber(externalLoan, currencyPoint, isMillion)}</td>`)
        .append(`<td align="right">${toNumber(sweep, currencyPoint, isMillion)}</td>`)
        .append(`<td align="right">${toNumber(bankTransfer, currencyPoint, isMillion)}</td>`)
        .append(
          `<td align="right">${toNumber(endingBalanceFromSystem, currencyPoint, isMillion)}</td>`
        );
    },
  },
  createdRow: (row, data) => {
    const $row = $(row);
    if (data.state === 'open') $row.show();
    else $row.hide();
  },
  drawCallback: () => {
    setTimeout(() => {
      window.$(window).trigger('resize');
    }, 100);
  },
};

export default ({
  criteria,
  results,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,

  ValueDateStr,
  modalData,
  modalRef,
  onClickOpenModalCreate,
  onChangeModal,
  onClickModalSave,
  onClickOpenModalEdit,
  setModalType,

  isMillion,
  onChangeToggleMillion,

  modalSweep,
  modalSweepRef,
  dataTableSweepRef,
  onClickColumnOpenModalSweep,

  modalAutomateRef,
  onClickOpenModalAutomateProgress,
  onSubmitModalAutomateProgress,
}) => {
  const textHeaderModal =
    setModalType === 'create' ? 'Manual Transfer Money' : 'Manual Transfer Money [Fix Beneficiary]';

  const isCreate = setModalType === 'create';

  const currAccFrom = ((modalData.FromBankAccountNo || '').split('|')[1] || '').trim();
  const currAccTo = ((modalData.ToBankAccountNo || '').split('|')[1] || '').trim();
  const reqRate = !currAccFrom || !currAccTo ? false : currAccFrom !== currAccTo;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Cash Management <span className="icon icon-angle-double-right" /> Cash Position
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={criteria.FinanceGroupId}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  name="FinanceGroupId"
                  required={required}
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  value={criteria.BusinessUnitId}
                  onChange={onChangeSelect2}
                  name="BusinessUnitId"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  value={criteria.CompanyCode}
                  onChange={onChangeSelect2}
                  name="CompanyCode"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroupId}
                  businessUnitId={criteria.BusinessUnitId}
                />
              </FormGroup2>

              <FormGroup2 text="Value Date" required>
                <DatePicker
                  value={criteria.ValueDateStr}
                  onChange={onChangeSearch}
                  name="ValueDateStr"
                  required={required}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <div className="row gutter-xs">
            <div className="col-sm-3">
              <FormGroup2 text="Account Type">
                <MasterDropdown
                  masterType={MASTER_ACCOUNT_TYPE}
                  value={criteria.AccountTypeCode}
                  isAll
                  notMultiple
                  onChange={onChangeSearch}
                  name="AccountTypeCode"
                />
              </FormGroup2>
            </div>
            <div className="col-sm-9">
              <FormGroup2 text="&nbsp;">
                <div>
                  <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
                  &nbsp;
                  <Button
                    txt="Clear Search"
                    icon="eraser"
                    className="btn-clear"
                    onClick={onClickClearSearch}
                  />
                </div>
              </FormGroup2>
            </div>
          </div>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Col2 col={colX[2]}>
          <div className="form-group">
            <strong>Value Date: </strong>
            <label className="text-underline">{ValueDateStr}</label>
          </div>
        </Col2>
        {permission ? (
          <Col2 col={colX[2]}>
            <div>
              <Link
                className="btn btn-sm btn-labeled btn-success"
                to={`/cash-management/auto-transfer?v=${criteria.ValueDateStr}&f=${criteria.FinanceGroupId || ''}`}
                target="_blank"
              >
                <span className="btn-label">
                  <i className="icon icon-retweet icon-lg icon-fw" />
                </span>
                AUTO TRANSFER MONEY
              </Link>
              &nbsp;
              <Link
                className="btn btn-warning btn-sm btn-labeled"
                to="/cash-management/sweep-money"
                target="_blank"
              >
                <span className="btn-label">
                  <i className="icon icon-exchange icon-lg icon-fw" />
                </span>
                SWEEP MONEY
              </Link>
              &nbsp;
              <Button
                txt="MANUAL TRANSFER"
                icon="text-width"
                className="btn-default"
                onClick={onClickOpenModalCreate}
              />
              &nbsp;
              <Button
                txt="AutoMate"
                icon="text-height"
                className="btn-info"
                onClick={onClickOpenModalAutomateProgress}
              />
            </div>
          </Col2>
        ) : null}

        <Col2 col={colX[2]}>
          <div className="toggle-million">
            <span>
              <b>Million</b>{' '}
              <Toggle name="isMillion" checked={isMillion} onChange={onChangeToggleMillion} />
            </span>
          </div>
        </Col2>

        <Table
          className="table table-bordered table-nowrap dataTable"
          id="table-result-cash-plane"
          option={opt}
          noColvis
          value={results}
          onClickContext={[
            {
              context: 'button.edit-item',
              onClick: onClickOpenModalEdit,
            },
            {
              context: 'a.get-sweep',
              onClick: onClickColumnOpenModalSweep,
            },
          ]}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>
                Company
                <br />
                Name
              </th>
              <th>
                Bank
                <br />
                Account
              </th>
              <th>Currency</th>
              <th className="text-center">OD</th>
              <th>
                Source of
                <br />
                Beginning
                <br />
                Balance
              </th>
              <th className="text-center">Different</th>
              <th className="text-center">
                Beginning
                <br />
                Balance
              </th>
              <th className="text-center">Inflow</th>
              <th className="text-center">Outflow</th>
              <th className="text-center">
                IC
                <br />
                Loan
              </th>
              <th className="text-center">Investment</th>
              <th className="text-center">
                External
                <br />
                Loan
              </th>
              <th className="text-center">Sweep</th>
              <th className="text-center">
                Bank
                <br />
                Transfer
              </th>
              <th className="text-center">
                Est. Ending
                <br />
                Balance
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader={textHeaderModal} bgHeader="bg-primary" size="modal-xl" ref={modalRef}>
        <form className="form" onSubmit={onClickModalSave}>
          <Col2 col={colX[2]}>
            <div className="form-group">
              <strong>Value Date: </strong>
              <label className="text-underline">{modalData.ValueDateStr}</label>
            </div>
          </Col2>

          {isCreate ? (
            <Col2 col={colX[0]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={modalData.FinanceGroupId}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  required
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Company" required>
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  value={modalData.CompanyCode}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  saveLocalState
                  required
                  financeGroupId={modalData.FinanceGroupId}
                  name="CompanyCode"
                />
              </FormGroup2>
            </Col2>
          ) : (
            <Col2 col={colX[0]}>
              <div className="form-group">
                <strong>Finance Group: </strong>
                <label className="text-underline">{modalData.FinanceGroupName}</label>
              </div>
              <div className="form-group">
                <strong>Company: </strong>
                <label className="text-underline">{modalData.CompanyName}</label>
              </div>
            </Col2>
          )}

          <Col2 col={colX[0]}>
            <Card textHeader="Transfer From" bgHeader="bg-danger" headerNoBold="true">
              <Col2 col={colX[2]}>
                <FormGroup2 text="Bank Account" required>
                  <MasterDropdown
                    masterType={MASTER_BANK_ACCOUNT}
                    value={modalData.FromBankAccountNo}
                    onChange={(e) =>
                      onChangeModal({
                        target: e,
                      })
                    }
                    saveLocalState
                    isChoose
                    required
                    notMultipleSelect2
                    companyCode={modalData.CompanyCode}
                    name="FromBankAccountNo"
                    customeValue={(m) => `${m.bankAccountNo}|${m.currency}`}
                    customeLabel={(m) =>
                      `${m.bankAbbreviate} | ${m.accountTypeCode} | ${m.branchName} | ${m.currency} | ${m.bankAccountNo}`
                    }
                  />
                </FormGroup2>
              </Col2>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Est. Ending Balance" required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 2,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }}
                    value={modalData.FromEndingBalance - modalData.TransferAmount || '0'}
                    name="FromEndingBalance"
                    readOnly
                  />
                </FormGroup2>
                <FormGroup2 text="Currency" required>
                  <input className="form-control" type="text" value={currAccFrom || ''} readOnly />
                </FormGroup2>
              </Col2>
              {modalData.TransferMethod === 'Bahtnet' ||
              modalData.TransferMethod === 'Internet Banking' ? (
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Charge" required>
                    <MasterDropdownUI
                      className="form-control"
                      onChange={(e) =>
                        onChangeModal({
                          target: e,
                        })
                      }
                      isChoose
                      notMultipleSelect2
                      required
                      value={modalData.ApplicantFeeBy || ''}
                      name="ApplicantFeeBy"
                      options={['Applicant', 'Beneficiary']}
                    />
                  </FormGroup2>
                  <FormGroup2 text="Charge Amount" required={false}>
                    <InputMask
                      className="form-control"
                      onChange={onChangeModal}
                      format="currency"
                      option={{
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }}
                      value={modalData.ApplicantFeeAmount || 0}
                      name="ApplicantFeeAmount"
                    />
                  </FormGroup2>
                </Col2>
              ) : null}
            </Card>

            <Card textHeader="Transfer To" bgHeader="bg-primary" headerNoBold="true">
              <Col2 col={colX[2]}>
                <FormGroup2 text="Bank Account" required>
                  <MasterDropdown
                    masterType={MASTER_BANK_ACCOUNT}
                    value={modalData.ToBankAccountNo}
                    onChange={(e) =>
                      onChangeModal({
                        target: e,
                      })
                    }
                    noValidateOption={!isCreate}
                    saveLocalState
                    companyCode={modalData.CompanyCode}
                    isChoose
                    notMultipleSelect2
                    name="ToBankAccountNo"
                    disabled={!isCreate}
                    required
                    customeValue={(m) => `${m.bankAccountNo}|${m.currency}`}
                    customeLabel={(m) =>
                      `${m.bankAbbreviate} | ${m.accountTypeCode} | ${m.branchName} | ${m.currency} | ${m.bankAccountNo}${m.bankAccountNoOfBank ? ` (${m.bankAccountNoOfBank})` : ''}`
                    }
                    isAccountBank
                  />
                </FormGroup2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Est. Ending Balance" required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 2,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }}
                    value={
                      modalData.ToEndingBalance +
                        modalData.TransferAmount * (modalData.ExchangeRate || 1) || '0'
                    }
                    name="ToEndingBalance"
                    readOnly
                  />
                </FormGroup2>
                <FormGroup2 text="Currency" required>
                  <input className="form-control" type="text" value={currAccTo} readOnly />
                </FormGroup2>
              </Col2>
              {modalData.TransferMethod === 'Bahtnet' ||
              modalData.TransferMethod === 'Internet Banking' ? (
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Charge" required>
                    <MasterDropdownUI
                      className="form-control"
                      onChange={(e) =>
                        onChangeModal({
                          target: e,
                        })
                      }
                      isChoose
                      notMultipleSelect2
                      required
                      value={modalData.BeneficiaryFeeBy || ''}
                      name="BeneficiaryFeeBy"
                      options={['Applicant', 'Beneficiary']}
                    />
                  </FormGroup2>
                  <FormGroup2 text="Charge Amount" required={false}>
                    <InputMask
                      className="form-control"
                      onChange={onChangeModal}
                      format="currency"
                      option={{
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }}
                      value={modalData.BeneficiaryFeeAmount || 0}
                      name="BeneficiaryFeeAmount"
                    />
                  </FormGroup2>
                </Col2>
              ) : null}
            </Card>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Transfer Amount" required>
                <InputMask
                  className="form-control"
                  onChange={onChangeModal}
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0.00',
                  }}
                  value={modalData.TransferAmount}
                  name="TransferAmount"
                />
              </FormGroup2>
              <FormGroup2 text="Transfer Method" required>
                <MasterDropdownUI
                  masterType={MASTER_TRANSFER_METHOD}
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  value={modalData.TransferMethod}
                  name="TransferMethod"
                  required
                  options={null}
                />
              </FormGroup2>
            </Col2>
            {reqRate ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Exchange Rate" required>
                  <InputMask
                    className="form-control"
                    onChange={onChangeModal}
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 8,
                      digitsOptional: false,
                      placeholder: '0.00000000',
                    }}
                    value={modalData.ExchangeRate}
                    name="ExchangeRate"
                  />
                </FormGroup2>
              </Col2>
            ) : null}
          </Col2>

          <br />
          <div className="row">
            <center>
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>

      <Modal textHeader="Sweep" bgHeader="bg-primary" size="modal-lg" ref={modalSweepRef}>
        <Table
          ref={dataTableSweepRef}
          ref={dataTableSweepRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID_SWEEP}
          option={optSweep}
          value={modalSweep.results}
        >
          <thead>
            <tr>
              <th>Business Unit</th>
              <th>Company Code</th>
              <th>Company Name</th>
              <th className="text-center">Amount</th>
            </tr>
          </thead>
        </Table>
      </Modal>

      <Modal textHeader="Automate" bgHeader="bg-primary" size="modal-lg" ref={modalAutomateRef}>
        <AutoMateProgress
          valueDate={criteria.ValueDateStr}
          onSubmit={onSubmitModalAutomateProgress}
        />
      </Modal>
    </div>
  );
};

const optSweep = {
  deferRender: true,
  scroller: true,
  paging: false,
  order: [[0, 'desc']],
  columns: [
    { data: 'buName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'amount',
      className: 'text-right',
      type: 'amount-formal',
      render: (d, t, r) => toNumber(d, 2),
    },
  ],
};
