import React from 'react';

import Tab from '../../common/Tab';
import Card from '../../common/Card';
import Link from '../../common/Link';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import { getPermission } from '../../common/helpper';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { MASTER_COMPANY, MASTER_CURRENCY } from '../../reducers/master/action';

const permission = getPermission('T/T Remittance', 'Manage');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,
  onClickCopyCreator,

  tabRef,
  formGeneralRef,
  formCreatorRef,
  formRequestorRef,
  formBeneficiaryRef,
  formAccountingRef,
  formApproveRef,
}) => {
  const textHeader =
    state.mode === 'edit' ? `Transaction No.: ${state.TransactionNo}` : 'Create T/T Remittance';
  const isEdit = state.mode === 'edit';
  const noEdit = state.mode === 'edit';

  const tabContents = [];

  tabContents.push({
    header: <span>General Information</span>,
    content: (
      <GeneralTab
        formRef={formGeneralRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'general',
  });

  tabContents.push({
    header: <span>Creator Information</span>,
    content: (
      <CreatorTab
        formRef={formCreatorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
        onClickCopyCreator={onClickCopyCreator}
      />
    ),
    tabName: 'creator',
  });

  tabContents.push({
    header: <span>Requestor Information</span>,
    content: (
      <RequestorTab
        formRef={formRequestorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'requestor',
  });

  tabContents.push({
    header: <span>Beneficiary Information</span>,
    content: (
      <BeneficiaryTab
        formRef={formBeneficiaryRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'beneficiary',
  });

  tabContents.push({
    header: <span>Accounting</span>,
    content: (
      <AccountingTab
        formRef={formAccountingRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'accounting',
  });

  // tabContents.push({
  //     header: <span>Approver Information</span>,
  //     content: <ApproveTab
  //         formRef={formApproveRef}
  //         state={state}
  //         onChangeInputData={onChangeInputData}
  //         noEdit={noEdit}
  //     />,
  //     tabName: 'approve'
  // })

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            T/T Remittance <span className="icon icon-angle-double-right" />
            <Link txt="Management" href="/tt-remittance/management" />
            <span className="icon icon-angle-double-right" /> View Detail T/T
          </small>
        </p>
      </div>

      {isEdit ? (
        <Card textHeader={textHeader} bgHeader="bg-primary">
          <Col2 col={colX[2]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="V-Pay ID" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.VPayId || ''}
                  name="VPayId"
                  disabled={noEdit}
                />
              </FormGroup2>

              <FormGroup2 text="V-Pay Number" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.VPayNo || ''}
                  name="VPayNo"
                  disabled={noEdit}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </Card>
      ) : (
        <div className="card">
          <div className="card-header bg-primary">
            <strong>{textHeader}</strong>
          </div>
        </div>
      )}

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          state.mode === 'edit' || !permission ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={noEdit}
            />
          )
        }
      />
    </div>
  );
};

function GeneralTab({ formRef, state, onChangeInputData, noEdit }) {
  const reqRemittance = state.Equivalent === 'Equivalent';

  return (
    <form ref={formRef} name="general">
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Company" required>
            <MasterDropdown
              masterType={MASTER_COMPANY}
              noValidateOption
              saveLocalState
              onChange={(e) =>
                onChangeInputData({
                  target: e,
                })
              }
              value={state.CompanyCode}
              isChoose
              notMultipleSelect2
              disabled={noEdit}
              required
              name="CompanyCode"
            />
          </FormGroup2>

          <FormGroup2
            text="Payment By"
            required
            maxLength={30}
            length={(state.PaymentBy || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.PaymentBy || ''}
              name="PaymentBy"
              required
              disabled={noEdit}
              maxLength={30}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2 text="PO No." maxLength={2000} length={(state.PoNo || '').length}>
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.PoNo || ''}
              name="PoNo"
              disabled={noEdit}
              maxLength={2000}
            />
          </FormGroup2>

          <FormGroup2 text="Equivalent" required>
            <MasterDropdownUI
              value={state.Equivalent}
              name="Equivalent"
              isChoose
              notMultipleSelect2
              onChange={(e) =>
                onChangeInputData({
                  target: e,
                })
              }
              required
              disabled={noEdit}
              options={['Normal', 'Equivalent']}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Invoice Number"
            required
            maxLength={500}
            length={(state.InvoiceNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.InvoiceNo || ''}
              name="InvoiceNo"
              disabled={noEdit}
              required
              maxLength={500}
            />
          </FormGroup2>

          <FormGroup2 text="Invoice Amount" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.InvoiceAmount || ''}
                disabled={noEdit}
                name="InvoiceAmount"
              />
            ) : (
              <InputMask
                className="form-control"
                format="currency"
                option={{
                  prefix: '',
                  digits: 2,
                  digitsOptional: false,
                  placeholder: '0.00',
                }}
                onChange={onChangeInputData}
                value={state.InvoiceAmount}
                disabled={noEdit}
                required
                name="InvoiceAmount"
              />
            )}
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2 text="Currency" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.Currency || ''}
                name="Currency"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                value={state.Currency}
                isChoose
                notMultipleSelect2
                disabled={noEdit}
                required
                name="Currency"
              />
            )}
          </FormGroup2>

          <FormGroup2 text="Remitted To Currency" required={reqRemittance}>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.RemittedToCurrency || ''}
                name="RemittedToCurrency"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                value={state.RemittedToCurrency}
                isChoose
                notMultipleSelect2
                disabled={noEdit}
                required={reqRemittance}
                name="RemittedToCurrency"
              />
            )}
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Value Date" required>
            <DatePicker
              onChange={onChangeInputData}
              name="ValueDateStr"
              value={state.ValueDateStr}
              option={{
                // startDate: "d",
                daysOfWeekDisabled: '0,6',
                todayHighlight: true,
              }}
              disabled={noEdit}
              required
            />
          </FormGroup2>

          <FormGroup2 text="Pre-Advice" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.PreAdvice || ''}
                name="PreAdvice"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={state.PreAdvice}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required
                options={['None', '1 Day', '2 Days before Value Date']}
                name="PreAdvice"
              />
            )}
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2 text="Paid for" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.PaidFor || ''}
                name="PaidFor"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={state.PaidFor}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required
                // options={["Service", "Goods", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection", "Other"]}
                options={[
                  'Service',
                  'Goods',
                  'Project',
                  'ST Loan',
                  'LT Loan & Swap',
                  'Capital Injection',
                  'Dividend',
                  'Other',
                ]}
                name="PaidFor"
              />
            )}
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Purpose Detail"
            required
            maxLength={100}
            length={(state.PurposeDetail || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.PurposeDetail || ''}
              name="PurposeDetail"
              required
              disabled={noEdit}
              maxLength={100}
            />
          </FormGroup2>

          <FormGroup2
            text="Q2O Number (SCGT only)"
            maxLength={50}
            length={(state.Q2oNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.Q2oNo || ''}
              name="Q2oNo"
              disabled
              maxLength={50}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="Forward Contract No. / Project Name"
            maxLength={60}
            length={(state.ForwardContractNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              // value={state.ForwardContractNoVpay || state.ForwardContractNo || ''}
              value={state.ForwardContractNoVpay || ''}
              name="ForwardContractNo"
              disabled={noEdit}
              maxLength={60}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="All bank charge outside Company Country For"
            required
            maxLength={60}
            length={(state.BankChargeOutside || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BankChargeOutside || ''}
              name="BankChargeOutside"
              disabled={noEdit}
              required
              maxLength={60}
            />
          </FormGroup2>

          <FormGroup2
            text="All bank charge inside Company Country For"
            required
            maxLength={60}
            length={(state.BankChargeInside || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BankChargeInside || ''}
              name="BankChargeInside"
              disabled={noEdit}
              required
              maxLength={60}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="Special Message (Send to Bank in English)"
            maxLength={2000}
            length={(state.SpecialMessage || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.SpecialMessage || ''}
              name="SpecialMessage"
              disabled={noEdit}
              maxLength={2000}
            />
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function CreatorTab({ formRef, state, onChangeInputData, noEdit, onClickCopyCreator }) {
  return (
    <form ref={formRef} name="creator">
      <Col2 col={colX[2]}>
        <Button
          txt="COPY"
          icon="copy"
          className="btn-default"
          onClick={onClickCopyCreator}
          disabled={noEdit}
        />
      </Col2>
      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Creator Name"
            required
            maxLength={200}
            length={(state.CreatorName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.CreatorName || ''}
              name="CreatorName"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>

          <FormGroup2
            text="Email"
            required={false}
            maxLength={200}
            length={(state.CreatorEmail || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.CreatorEmail || ''}
              name="CreatorEmail"
              disabled={noEdit}
              maxLength={200}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Department"
            required
            maxLength={200}
            length={(state.CreatorDepartment || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.CreatorDepartment || ''}
              name="CreatorDepartment"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>

          <FormGroup2
            text="Tel No."
            required={false}
            maxLength={30}
            length={(state.CreatorTel || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.CreatorTel || ''}
              name="CreatorTel"
              disabled={noEdit}
              maxLength={30}
            />
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function RequestorTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <form ref={formRef} name="requestor">
      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Requestor Name"
            required
            maxLength={200}
            length={(state.RequestorName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.RequestorName || ''}
              name="RequestorName"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>

          <FormGroup2
            text="Email"
            required={false}
            maxLength={200}
            length={(state.RequestorEmail || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.RequestorEmail || ''}
              name="RequestorEmail"
              disabled={noEdit}
              maxLength={200}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Department"
            required
            maxLength={200}
            length={(state.RequestorDepartment || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.RequestorDepartment || ''}
              name="RequestorDepartment"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>

          <FormGroup2
            text="Tel No."
            required={false}
            maxLength={30}
            length={(state.RequestorTel || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.RequestorTel || ''}
              name="RequestorTel"
              disabled={noEdit}
              maxLength={30}
            />
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function BeneficiaryTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <form ref={formRef} name="beneficiary">
      {noEdit ? (
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Vendor Name" required>
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.VendorName || ''}
                name="VendorName"
                disabled={noEdit}
                required
              />
            </FormGroup2>

            <FormGroup2 text="Vendor Code" required>
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.VendorCode || ''}
                name="VendorCode"
                disabled={noEdit}
                required
              />
            </FormGroup2>
          </Col2>
        </Col2>
      ) : null}

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Beneficiary Code"
            required={false}
            maxLength={50}
            length={(state.BeneficiaryCode || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryCode || ''}
              name="BeneficiaryCode"
              disabled={noEdit}
              maxLength={50}
            />
          </FormGroup2>

          <FormGroup2
            text="Beneficiary Name"
            required
            maxLength={200}
            length={(state.BeneficiaryName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryName || ''}
              name="BeneficiaryName"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="Beneficiary Address"
            required
            maxLength={2000}
            length={(state.BeneficiaryAddress || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.BeneficiaryAddress || ''}
              name="BeneficiaryAddress"
              disabled={noEdit}
              required
              maxLength={2000}
            />
          </FormGroup2>

          <FormGroup2
            text="Beneficiary Country"
            required
            maxLength={255}
            length={(state.BeneficiaryCountry || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryCountry || ''}
              name="BeneficiaryCountry"
              disabled={noEdit}
              required
              maxLength={255}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Account No."
            required
            maxLength={100}
            length={(state.AccountNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.AccountNo || ''}
              name="AccountNo"
              disabled={noEdit}
              required
              maxLength={100}
            />
          </FormGroup2>

          <FormGroup2 text="IBAN No." required maxLength={100} length={(state.IbanNo || '').length}>
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IbanNo || ''}
              name="IbanNo"
              disabled={noEdit}
              required
              maxLength={100}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="ABA No."
            required={false}
            maxLength={50}
            length={(state.AbaNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.AbaNo || ''}
              name="AbaNo"
              disabled={noEdit}
              maxLength={50}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="BSB No."
            required={false}
            maxLength={100}
            length={(state.BsbNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BsbNo || ''}
              name="BsbNo"
              disabled={noEdit}
              maxLength={100}
            />
          </FormGroup2>

          <FormGroup2
            text="IFSC Code"
            required={false}
            maxLength={50}
            length={(state.IfscCode || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IfscCode || ''}
              name="IfscCode"
              disabled={noEdit}
              maxLength={50}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Beneficiary's Bank Name"
            required
            maxLength={2000}
            length={(state.BeneficiaryBankName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryBankName || ''}
              name="BeneficiaryBankName"
              disabled={noEdit}
              required
              maxLength={2000}
            />
          </FormGroup2>

          <FormGroup2
            text="Beneficiary's Bank Address"
            required
            maxLength={2000}
            length={(state.BeneficiaryBankAddress || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.BeneficiaryBankAddress || ''}
              name="BeneficiaryBankAddress"
              disabled={noEdit}
              required
              maxLength={2000}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="Beneficiary's Bank SWIFT Code"
            required
            maxLength={50}
            length={(state.BeneficiaryBankSwiftCode || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryBankSwiftCode || ''}
              name="BeneficiaryBankSwiftCode"
              disabled={noEdit}
              required
              maxLength={50}
            />
          </FormGroup2>

          <FormGroup2
            text="Beneficiary's Bank Country"
            required
            maxLength={255}
            length={(state.BeneficiaryBankCountry || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryBankCountry || ''}
              name="BeneficiaryBankCountry"
              disabled={noEdit}
              required
              maxLength={255}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Intermediate's Bank Name"
            required={false}
            maxLength={2000}
            length={(state.IntermediateBankName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IntermediateBankName || ''}
              name="IntermediateBankName"
              disabled={noEdit}
              maxLength={2000}
            />
          </FormGroup2>

          <FormGroup2
            text="Intermediate's Bank Address"
            required={false}
            maxLength={2000}
            length={(state.IntermediateBankAddress || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.IntermediateBankAddress || ''}
              name="IntermediateBankAddress"
              disabled={noEdit}
              maxLength={2000}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="Intermediate's Bank SWIFT Code"
            required={false}
            maxLength={50}
            length={(state.IntermediateBankSwiftCode || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IntermediateBankSwiftCode || ''}
              name="IntermediateBankSwiftCode"
              disabled={noEdit}
              maxLength={50}
            />
          </FormGroup2>

          <FormGroup2
            text="Intermediate's Bank Country"
            required={false}
            maxLength={255}
            length={(state.IntermediateBankCountry || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IntermediateBankCountry || ''}
              name="IntermediateBankCountry"
              disabled={noEdit}
              maxLength={255}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Intenal Message"
            required={false}
            maxLength={500}
            length={(state.InternalMessage || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.InternalMessage || ''}
              name="InternalMessage"
              disabled={noEdit}
              maxLength={500}
            />
          </FormGroup2>

          <FormGroup2
            text="Attach File"
            required
            maxLength={200}
            length={(state.AttachFile || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.AttachFile || ''}
              name="AttachFile"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>
        </Col2>

        {noEdit ? (
          <Col2 col={colX[1]}>
            <FormGroup2
              text="Requestor Remark for Bank"
              required={false}
              maxLength={2000}
              length={(state.RequestorRemark || '').length}
            >
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeInputData}
                value={state.RequestorRemark || ''}
                name="RequestorRemark"
                disabled={noEdit}
                maxLength={2000}
              />
            </FormGroup2>
          </Col2>
        ) : null}
      </Col2>
    </form>
  );
}

function AccountingTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <form ref={formRef} name="accounting">
      {state.mode === 'create' ? (
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Based Amount" required>
              <InputMask
                className="form-control"
                format="currency"
                option={{
                  prefix: '',
                  digits: 2,
                  digitsOptional: false,
                  placeholder: '0.00',
                }}
                onChange={onChangeInputData}
                value={state.BasedAmount}
                disabled={false}
                required
                name="BasedAmount"
              />
            </FormGroup2>
          </Col2>
        </Col2>
      ) : (
        <>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Based Amount" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0.00',
                  }}
                  onChange={onChangeInputData}
                  value={state.BasedAmount}
                  disabled
                  required
                  name="BasedAmount"
                />
              </FormGroup2>

              <FormGroup2 text="WHT Amount" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0.00',
                  }}
                  onChange={onChangeInputData}
                  value={state.WhtAmount}
                  disabled
                  required
                  name="WhtAmount"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="VAT Amount" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0.00',
                  }}
                  onChange={onChangeInputData}
                  value={state.VatAmount}
                  disabled
                  required
                  name="VatAmount"
                />
              </FormGroup2>

              <FormGroup2 text="Net Paid Amount" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 2,
                    digitsOptional: false,
                    placeholder: '0.00',
                  }}
                  onChange={onChangeInputData}
                  value={state.NetPaidAmount}
                  disabled={noEdit}
                  required
                  name="NetPaidAmount"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2
                text="Remark"
                required={false}
                maxLength={2000}
                length={(state.Remark || '').length}
              >
                <textarea
                  className="form-control"
                  rows="3"
                  onChange={onChangeInputData}
                  value={state.Remark || ''}
                  name="Remark"
                  disabled={noEdit}
                  maxLength={2000}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </>
      )}
    </form>
  );
}

function ApproveTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <form ref={formRef} name="approve">
      <Col2 col={colX[1]}>
        <Col2 col={colX[0]}>
          <FormGroup2
            text="Initial Name"
            required
            maxLength={50}
            length={(state.InitialName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.InitialName || ''}
              name="InitialName"
              disabled={noEdit}
              required
              maxLength={50}
            />
          </FormGroup2>

          <FormGroup2
            text="Approver Name"
            required
            maxLength={50}
            length={(state.ApproverName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.ApproverName || ''}
              name="ApproverName"
              disabled={noEdit}
              required
              maxLength={50}
            />
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}
