import { connect } from 'react-redux';
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import Card from '../../common/Card';
import Link from '../../common/Link';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import { addAlert } from '../../reducers/layout/action';
import { toFixed, getPermission } from '../../common/helpper';
import {
  getTtRemittanceForward,
  saveTtRemittanceForward,
} from '../../reducers/ttRemittance/action';

const permission = getPermission('T/T Remittance', 'Manage');

function ForwardTTRemittance({ routeProp, dispatch }) {
  const ttNo = routeProp.match.params.id;
  const onClickBackToSearchTT = useCallback(() => {
    routeProp.history.push('/tt-remittance/management?s');
  }, []);
  const [update, setUpdate] = useState(1);
  const [forward, setForward] = useForwardMapping();
  const exposureMainKey = useMemo(getExposureMainKey, [update]);
  const exposureMainOutstanding = useMemo(getExposureMainOutstanding, [exposureMainKey]);
  const theader = useMemo(createHeader, [exposureMainKey]);
  const totalRemainingExposureAmount = useMemo(getSumTotalRemainingOutstaindExposure, [
    forward,
    exposureMainKey,
    exposureMainOutstanding,
  ]);
  const onSubmit = useCallback(submitForm, [forward, exposureMainKey]);
  return (
    <>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            T/T Remittance <span className="icon icon-angle-double-right" />
            <Link txt="Management" href="/tt-remittance/management" />
            <span className="icon icon-angle-double-right" /> Forward for T/T
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card
          textHeader="Forward Rate"
          bgHeader="bg-primary"
          cardActions={['toggler']}
          footer={
            <div>
              <Button
                txt="Back"
                className="btn-default"
                icon="arrow-left"
                onClick={onClickBackToSearchTT}
              />
              {permission ? (
                <>
                  &nbsp;
                  <Button
                    txt="Save"
                    className="btn-success"
                    icon="save"
                    type="submit"
                    disabled={forward.length === 0}
                  />
                </>
              ) : null}
            </div>
          }
        >
          <div className="table-responsive" style={{ maxHeight: '500px' }}>
            <table className="table table-bordered table-nowrap">
              <thead>
                <tr>
                  <th className="th-success">Bank</th>
                  <th className="th-success">Department</th>
                  <th className="th-success">FWD Request Type</th>
                  <th className="th-success">Ext. Ref (T/T)</th>
                  <th className="th-success">Purpose</th>
                  <th className="th-success">Purpose Detail</th>
                  <th className="th-success">Book Bank Currency</th>
                  <th className="th-success">Currency</th>
                  <th className="th-success">Transaction Forward No.</th>
                  <th className="th-success">Forward Contract No.</th>
                  <th className="th-success">Start Date</th>
                  <th className="th-success">Expire Date</th>
                  <th className="th-success">Map Amount</th>
                  <th className="th-success">Request Amount</th>
                  <th className="th-success">Used Amount</th>
                  <th className="th-success">Remaining Amount</th>
                  <th className="th-success">Original Amount</th>
                  <th className="th-success">Outstanding</th>
                  {theader}
                </tr>
              </thead>
              <tbody>
                {forward.map((m, i) => (
                  <tr key={i}>
                    <td>{m.bankAbbreviate ? `${m.bankAbbreviate} | ${m.bankName}` : ''}</td>
                    <td>{m.department}</td>
                    <td className="text-center">{m.requestType}</td>
                    <td>{m.externalRef}</td>
                    <td className="text-center">{m.purpose}</td>
                    <td className="text-center">{m.purposeDetail}</td>
                    <td className="text-center">{m.bookBankCurrency}</td>
                    <td className="text-center">{m.forwardCurrency}</td>
                    <td>{m.forwardNo}</td>
                    <td>{m.contractNo}</td>
                    <td className="text-center">{m.startDateStr}</td>
                    <td className="text-center">{m.endDateStr}</td>
                    <td className="text-right">{setNumber(m.mapAmount)}</td>
                    <td className="text-right">{setNumber(m.requestAmount)}</td>
                    <td className="text-right">{setNumber(m.amount - m.outstanding)}</td>
                    <td className="text-right">{setNumber(m.remainingAmount || 0)}</td>
                    <td className="text-right">{setNumber(m.amount)}</td>
                    <td className="text-right">{setNumber(m.outstanding)}</td>
                    <ExposureItem
                      forwardItem={m}
                      forward={forward}
                      setForward={setForward}
                      exposureMainKey={exposureMainKey}
                      exposureMainOutstanding={exposureMainOutstanding}
                    />
                  </tr>
                ))}
                {forward.length === 0 ? (
                  <tr>
                    <td colSpan="19" className="text-center">
                      <b>No Data.</b>
                    </td>
                  </tr>
                ) : (
                  <tr>
                    <td colSpan="18" />
                    {totalRemainingExposureAmount}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </Card>
      </form>
    </>
  );

  function getSumTotalRemainingOutstaindExposure() {
    const total = [];
    forward.forEach((row) => {
      exposureMainKey.forEach((key, idx) => {
        if (total[idx] === undefined) total.push(Number(row[`${key}_Amount`]));
        else total[idx] += Number(row[`${key}_Amount`]);
      });
    });

    return exposureMainKey.map((m, idx) => {
      const outstanding = exposureMainOutstanding[m] || 0;
      return (
        <td key={idx} align="right">
          {setNumber(outstanding - total[idx])}
        </td>
      );
    });
  }

  function useForwardMapping() {
    const [forward, setForward] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        const response = await dispatch(getTtRemittanceForward(ttNo));
        if (response.error) return;
        setForward(response.payload);
        setUpdate((update) => update + 1);
      };

      if (!ttNo) {
        setUpdate(update + 1);
        setForward([]);
      } else fetchData();
    }, [ttNo]);
    return [forward, setForward];
  }

  function getExposureMainKey() {
    const mainKey = [];
    const first = forward[0] || {};
    for (const key in first) {
      if (key.indexOf('exposure_') === 0) {
        mainKey.push(key.split('_').slice(0, 3).join('_'));
      }
    }
    return mainKey.filter((f, idx, s) => s.indexOf(f) === idx);
  }
  function getExposureMainOutstanding() {
    const mainOuts = {};
    exposureMainKey.forEach((key) => {
      let found = false;
      for (let i = 0; i < forward.length; i++) {
        const outs = forward[i][`${key}_Outstanding`];
        if (outs) {
          found = true;
          mainOuts[key] = outs;
          i = forward.length;
        }
      }
      if (!found) mainOuts[key] = 0;
    });
    return mainOuts;
  }
  function createHeader() {
    return exposureMainKey.map((m) => (
      <th key={m}>
        {`${m.split('_')[1]}`}
        <br />
        FW Vpay {m.split('_')[2]}
      </th>
    ));
  }
  function submitForm(e) {
    e.preventDefault();
    const submit = async () => {
      const data = [];

      forward.forEach((row) => {
        exposureMainKey.forEach((key) => {
          const canEdit = row[`${key}_CanEdit`];
          if (canEdit)
            data.push({
              forwardNo: row.forwardNo,
              exposureNo: key.split('_')[1],
              amount: row[`${key}_Amount`],
            });
        });
      });
      const response = await dispatch(saveTtRemittanceForward(data));
      if (response.error) return;
      setForward([]);
      dispatch(
        addAlert({
          title: 'Success',
          body: 'The transaction was successfully saved',
          buttons: [
            <button
              className="btn btn-success"
              onClick={onClickBackToSearchTT}
              data-dismiss="modal"
            >
              Continue
            </button>,
          ],
        })
      );
    };
    submit();
  }
}

function ExposureItem({
  forwardItem,
  exposureMainKey,
  exposureMainOutstanding,
  forward,
  setForward,
}) {
  const onChangeInput = useCallback(inputChange, [forwardItem, exposureMainOutstanding, forward]);
  return exposureMainKey.map((mKey) => {
    const keyOutStanding = `${mKey}_Outstanding`;
    const keyAmount = `${mKey}_Amount`;
    const keyCanEdit = `${mKey}_CanEdit`;

    return (
      <td className="text-right" key={`${mKey}_${forwardItem.forwardNo}`}>
        <InputMask
          className={`form-control width-input-table ${forwardItem.isForward ? 'input-bg-coral' : ''}`}
          onChange={(e) => onChangeInput(e, mKey, keyOutStanding, keyAmount)}
          format="currency"
          option={{
            prefix: '',
            digits: 3,
            max: exposureMainOutstanding[mKey],
          }}
          disabled={!forwardItem[keyCanEdit]}
          required={forwardItem[keyCanEdit]}
          value={setNumber(forwardItem[keyAmount])}
        />
      </td>
    );
  });
  function inputChange(e, mKey, keyOutStanding, keyAmount) {
    const { value } = e.target;
    const val = Number(value || 0);
    // check sum
    const totalUsedExposure = forward.reduce((prev, m, idx) => {
      if (m === forwardItem) return prev + val;
      return prev + Number(m[keyAmount] || 0);
    }, 0);

    const totalUsedForward = exposureMainKey.reduce((prev, key, idx) => {
      const k = `${key}_Amount`;
      if (k === keyAmount) return val + prev;
      return Number(forwardItem[k] || 0) + prev;
    }, 0);

    if (
      val > forwardItem.outstanding ||
      totalUsedExposure > exposureMainOutstanding[mKey] ||
      totalUsedForward > forwardItem.outstanding
    ) {
      setForward(forward.slice());
    } else {
      function updateForward(state) {
        return state.map((m, idx) => {
          if (m === forwardItem)
            return {
              ...m,
              [keyAmount]: val,
              remainingAmount: forwardItem.outstanding - totalUsedForward,
            };
          return m;
        });
      }
      setForward(updateForward);
    }
  }
}

export default connect((state) => ({
  ...state.ttRemittance,
}))(ForwardTTRemittance);

const setNumber = (number, float = 3) => {
  if (isNaN(number)) return '';
  if (number >= 0) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>{toFixed(number, float)}</span>;
};
