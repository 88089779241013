import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_DERIVATIVE_CRITERIA = 'EDIT_DERIVATIVE_CRITERIA';
export const CLEAR_DERIVATIVE_CRITERIA = 'CLEAR_DERIVATIVE_CRITERIA';

export const UPDATE_SEARCH_RESULT_DERIVATIVE = 'UPDATE_SEARCH_RESULT_DERIVATIVE';

export const REQUEST_SEARCH_DERIVATIVE = '@@request/SARCH_DERIVATIVE';
export const RECEIVE_SEARCH_DERIVATIVE = '@@receive/SEARCH_DERIVATIVE';
export const FAIL_SEARCH_DERIVATIVE = '@@fail/SEARCH_DERIVATIVE';

export const REQUEST_GET_DERIVATIVE = '@@request/GET_DERIVATIVE';
export const RECEIVE_GET_DERIVATIVE = '@@receive/GET_DERIVATIVE';
export const FAIL_GET_DERIVATIVE = '@@fail/GET_DERIVATIVE';

export const REQUEST_PROCESS_CASH_FLOW = '@@request/PROCESS_CASH_FLOW';
export const RECEIVE_PROCESS_CASH_FLOW = '@@receive/PROCESS_CASH_FLOW';
export const FAIL_PROCESS_CASH_FLOW = '@@fail/PROCESS_CASH_FLOW';

export const REQUEST_SAVE_DERIVATIVE = '@@request/SAVE_DERIVATIVE';
export const RECEIVE_SAVE_DERIVATIVE = '@@receive/SAVE_DERIVATIVE';
export const FAIL_SAVE_DERIVATIVE = '@@fail/SAVE_DERIVATIVE';

export const saveDerivative = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savederivative'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_DERIVATIVE, RECEIVE_SAVE_DERIVATIVE, FAIL_SAVE_DERIVATIVE],
  },
});

export const processCashflow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/ProcessCashflowDerivative'),
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    method: 'POST',
    types: [REQUEST_PROCESS_CASH_FLOW, RECEIVE_PROCESS_CASH_FLOW, FAIL_PROCESS_CASH_FLOW],
  },
});

export const getDerivative = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getDerivative/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_DERIVATIVE, RECEIVE_GET_DERIVATIVE, FAIL_GET_DERIVATIVE],
  },
});

export const searchDerivative = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/searchDerivative', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_DERIVATIVE, RECEIVE_SEARCH_DERIVATIVE, FAIL_SEARCH_DERIVATIVE],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_DERIVATIVE,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_DERIVATIVE_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_DERIVATIVE_CRITERIA,
  payload: {
    name,
    value,
  },
});
