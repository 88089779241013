import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import DatePicker from '../../../common/DatePicker';
import FormGroup2 from '../../../common/FormGroup2';
import { DATATABLE_ID } from './LoanReportContainer';
import MasterDropdown from '../../../common/MasterDropdown';
import { toFixed, toNumber } from '../../../common/helpper';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import {
  MASTER_BANK,
  MASTER_COMPANY,
  MASTER_FINANCE,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[0, 'asc']],
  fixedColumns: {
    leftColumns: 1,
  },
  columns: [
    {
      data: 'transactionNo',
      render: (d) => `<a target="_blank" href="/loan-investment/deposit/${d}"> ${d} </a>`,
    },
    { data: 'transactionType', className: 'text-center' },
    { data: 'term', className: 'text-center' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'businessPartner', className: 'text-center' },
    { data: 'businessPartnerName' },
    { data: 'contractDateStr', className: 'text-center', type: 'date-black' },
    { data: 'termStartStr', className: 'text-center', type: 'date-black' },
    { data: 'termEndStr', className: 'text-center', type: 'date-black' },
    { data: 'calculateMethod' },
    // { data: 'interestRate', className: 'text-right', render: d => toNumber(d, 5) + "%" },
    {
      data: 'interestRate',
      className: 'text-right',
      render: (d, t) => toNumber(d, 5),
    },
    // { data: 'amount', className: 'text-right', render: d => toNumber(d, 3) },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'currency', className: 'text-center' },
    { data: 'externalRef' },
  ],
};

export default ({
  criteria,
  datas,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  showProductType,

  onClickDownloadReportExcel,

  dataTableRef,
  results,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Report <span className="icon icon-angle-double-right" /> External Loan
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroups}
                required={false}
                name="FinanceGroups"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company" required={false}>
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companies}
                financeGroupId={criteria.FinanceGroups}
                businessUnitId={criteria.BusinessUnits}
                name="Companies"
              />
            </FormGroup2>

            <FormGroup2 text="Currency" required={false}>
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.Currencies}
                name="Currencies"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Bank">
              <MasterDropdown
                key="External"
                masterType={MASTER_BANK}
                onChange={onChangeSelect2}
                companyCode={criteria.Companies}
                value={criteria.Banks}
                name="Banks"
              />
            </FormGroup2>

            <FormGroup2 text="Product Type" required>
              <MasterDropdownUI
                onChange={(e) =>
                  onChangeSearch({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                value={criteria.ProductType}
                required
                name="ProductType"
                options={['PN', 'Deposit', 'Contract on Call', 'Contract Loan']}
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Value Date" required>
              <DatePicker
                onChange={onChangeSearch}
                required
                value={criteria.ValueDateStr}
                name="ValueDateStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
            &nbsp;
            <Button
              txt="Export"
              icon="download"
              className="btn-warning"
              onClick={onClickDownloadReportExcel}
              disabled={criteria.ProductType === 'Deposit'}
            />
          </div>
        </Col2>
      </form>
    </Card>

    {showProductType === 'PN' ? (
      datas.map((r, i) => <ReportLoan key={i} data={r} idx={i} />)
    ) : showProductType === 'Contract Loan' ? (
      datas.map((r, i) => <ReportContractLoan key={i} data={r} idx={i} />)
    ) : showProductType === 'Deposit' ? (
      <ReportDeposit data={results} dataTableRef={dataTableRef} />
    ) : null}
  </div>
);

function ReportLoan({ data, idx }) {
  const textHeader = `${data.companyCode} | ${data.companyName}`;

  return (
    <Card
      textHeader={textHeader}
      number={data.companyCode}
      bgHeader="bg-primary"
      cardActions={['toggler']}
    >
      <div className="table-responsive" style={{ maxHeight: '500px' }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                Bank
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                Facility
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '20%' }}>
                Remark
              </th>
              <th colSpan="3" style={{ textAlign: 'center', width: '30%' }}>
                Outstanding
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                Currency
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                All in Rate (%)
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                Term
              </th>
              <th colSpan="2" style={{ textAlign: 'center', width: '20%' }}>
                PERIOD
              </th>
            </tr>
            <tr>
              <th style={{ width: '10%' }}>THB</th>
              <th style={{ width: '10%' }}>Foreign Currency</th>
              <th style={{ width: '10%' }}>THB Equivalent</th>
              <th style={{ width: '10%' }}>BEG</th>
              <th style={{ width: '10%' }}>DUE</th>
            </tr>
          </thead>
          <tbody>
            {!data.results.length ? (
              <tr>
                <td className="text-center" colSpan="9">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {data.results.map((m, i) => (
              <tr key={i}>
                <td>{m.businessPartner}</td>
                <td className="text-right">{setNumber(m.creditLine, m.digit)}</td>
                <td>{m.remark}</td>
                <td className="text-right">{setNumber(m.amount, 2)}</td>
                <td className="text-right">{setNumber(m.outstandingForeign, m.digit)}</td>
                <td className="text-right">{setNumber(m.outstandingThb, 2)}</td>
                <td className="text-center">{m.currency}</td>
                <td className="text-right">{setNumber(m.allInRate, 5)}</td>
                <td className="text-center">{m.term}</td>
                <td className="text-center">{m.beginDateStr}</td>
                <td className="text-center">{m.dueDateStr}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="td-grey">TOTAL</td>
              <td className="text-right td-grey">{setNumber(data.total.totalFacility, 2)}</td>
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey">{setNumber(data.total.totalThb, 2)}</td>
              <td className="text-right td-grey" />
              <td className="text-right td-grey">{setNumber(data.total.totalAllInRate, 5)}</td>
              <td className="text-right td-grey" />
              <td className="text-center td-grey" />
              <td className="text-center td-grey" />
            </tr>
          </tfoot>
        </table>
      </div>
    </Card>
  );
}

function ReportContractLoan({ data, idx }) {
  const textHeader = `${data.companyCode} | ${data.companyName}`;

  return (
    <Card
      textHeader={textHeader}
      number={data.companyCode}
      bgHeader="bg-primary"
      cardActions={['toggler']}
    >
      <div className="table-responsive" style={{ maxHeight: '500px' }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                LENDER
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                DRAW
                <br />& SWAP
                <br />
                F/X RATE
              </th>
              <th colSpan="6" style={{ verticalAlign: 'middle', width: '60%' }}>
                OUTSTANDING
              </th>
              <th colSpan="3" style={{ textAlign: 'center', width: '30%' }}>
                NEXT REPAYMENT
                <br />/ PREPAYMENT
              </th>
              <th colSpan="2" style={{ textAlign: 'center', width: '20%' }}>
                INTEREST
                <br />
                PERIOD
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                INTEREST
                <br />
                RATE
                <br />
                (%)
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                EFFECTIVE
                <br />
                RATE
                <br />
                (%)
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                LOAN
                <br />
                MATURITY
              </th>
              <th rowSpan="2" style={{ verticalAlign: 'middle', width: '10%' }}>
                GUARANTEE
              </th>
            </tr>
            <tr>
              <th style={{ width: '10%' }}>M. USD</th>
              <th style={{ width: '10%' }}>M. JPY</th>
              <th style={{ width: '10%' }}>M. EUR</th>
              <th style={{ width: '10%' }}>M. OTHER(S)</th>
              <th style={{ width: '10%' }}>M. BAHT</th>
              <th style={{ width: '10%' }}>MB. EQUI</th>
              <th style={{ width: '10%' }}>DATE</th>
              <th style={{ width: '10%' }}>(M.)</th>
              <th style={{ width: '10%' }}>CCY</th>
              <th style={{ width: '10%' }}>BEGIN</th>
              <th style={{ width: '10%' }}>DUE</th>
            </tr>
          </thead>
          <tbody>
            {!data.results.length ? (
              <tr>
                <td className="text-center" colSpan="17">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {data.results.map((m, i) => (
              <tr key={i}>
                <td>{m.businessPartner}</td>
                <td>{setNumber(m.exchangeRate, 8)}</td>
                <td className="text-right">{setNumber(m.amountUsd, 2)}</td>
                <td className="text-right">{setNumber(m.amountJpy, 2)}</td>
                <td className="text-right">{setNumber(m.amountEur, 2)}</td>
                <td className="text-right">{setNumber(m.amountOther, 2)}</td>
                <td className="text-right">{setNumber(m.amount, 2)}</td>
                <td className="text-right">{setNumber(m.outstandingThb, 2)}</td>
                <td className="text-center">{m.repaymentDateStr}</td>
                <td className="text-right">{setNumber(m.repaymentAmount, 2)}</td>
                <td className="text-center">{m.currency}</td>
                <td className="text-center">{m.beginDateStr}</td>
                <td className="text-center">{m.dueDateStr}</td>
                <td className="text-right">{setNumber(m.allInRate, 5)}</td>
                <td className="text-right">{setNumber(m.effectiveRate || m.allInRate, 5)}</td>
                <td className="text-center">{m.loanMaturityStr}</td>
                <td className="text-center">{m.guarantor}</td>
              </tr>
            ))}
            <tr>
              <td className="td-grey">TOTAL</td>
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey">{setNumber(data.total.totalThb, 2)}</td>
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey" />
              <td className="text-right td-grey">{setNumber(data.total.totalAllInRate, 5)}</td>
              <td className="text-right td-grey">{setNumber(data.total.totalEffectiveRate, 5)}</td>
              <td className="text-center td-grey" />
              <td className="text-center td-grey" />
            </tr>
          </tbody>
        </table>
      </div>
    </Card>
  );
}

function ReportDeposit({ data, dataTableRef }) {
  return (
    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={data}
      >
        <thead>
          <tr>
            <th>Deposit No.</th>
            <th>
              Transaction
              <br />
              Type
            </th>
            <th>Term</th>
            <th>Finance Group</th>
            <th>Business Unit</th>
            <th>
              Company
              <br />
              Code
            </th>
            <th>Company Name</th>
            <th>
              Business
              <br />
              Partner Code
            </th>
            <th>
              Business
              <br />
              Partner
            </th>
            <th>Contract Date</th>
            <th>Term Start</th>
            <th>Term End</th>
            <th>Cal. Method</th>
            <th className="text-center">Interest Rate (%)</th>
            <th className="text-center">Amount</th>
            <th>Currency</th>
            <th>External Ref</th>
          </tr>
        </thead>
      </Table>
    </Card>
  );
}

export function setNumber(number, float = 2) {
  if (!number) return '';
  if (number >= 0 || !number) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
}
