import React, { Component } from 'react';

const $ = window.jQuery;
class MultipleSelect extends Component {
  $multiSelect;

  componentDidMount() {
    const { option, value } = this.props;
    let opt = {
      afterSelect: (val) => {
        this.onChange(val);
      },
      afterDeselect: (val) => {
        this.onChange(val);
      },
    };
    opt = {
      ...opt,
      ...(option || {}),
    };
    this.$multiSelect = $(this.selectInput).multiSelect(opt);

    if (value) this.$multiSelect.multiSelect('select', value);
    else this.$multiSelect.multiSelect('deselect_all');
  }

  compareArray(a = [], b = []) {
    a = a || [];
    b = b || [];
    if (a.length !== b.length) return false;
    let isEqual = true;
    a.forEach((m, i) => {
      if (b[i] !== m) isEqual = false;
    });

    return isEqual;
  }

  compareChildenArray(cur = [], prev = []) {
    if (cur.length !== prev.length) return false;
    for (let i = 0; i < prev.length; i++) {
      if (cur[i].key !== prev[i].key) {
        i = prev.length;
        return false;
      }
    }

    return true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !this.compareArray(this.props.value, this.$multiSelect.val()) ||
      !this.compareChildenArray(this.props.children, prevProps.children)
    ) {
      // console.log('update')
      this.$multiSelect.val(this.props.value).multiSelect('refresh');
    }
  }

  componentWillUnmount() {
    this.$multiSelect.multiSelect('destroy');
  }

  onChange = (val) => {
    const { onChange, name } = this.props;
    if (onChange)
      onChange({
        name,
        value: this.$multiSelect.val(),
        type: 'select',
      });
  };

  render() {
    const { name, required } = this.props;
    return (
      <div>
        <select
          id="aa"
          className="form-control"
          name={name}
          ref={(e) => (this.selectInput = e)}
          multiple
          required={required}
        >
          {this.props.children}
        </select>
      </div>
    );
  }
}

export default MultipleSelect;
