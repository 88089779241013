import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import InputMask from '../../../common/InputMask';
import { DATATABLE_ID } from './InterestContainer';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import { toNumber } from '../../report/SweepPn/SweepPN';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import { toBank, toStatus, getPermission } from '../../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_ACCOUNT,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('Master Finance', 'Interest Rate');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'effectiveDateStr', className: 'text-center', type: 'date-black' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'bankCode', className: 'text-center' },
    {
      data: 'bankAbbreviate',
      render: (d, t, r) => toBank(d, r.bankName),
    },
    { data: 'bankAccountNo' },
    { data: 'interestRate', className: 'text-right', render: (d, t, r) => toNumber(d, 5) },
    { data: 'currency', className: 'text-center' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalRef,
  modalData,
  onClickOpenModalCreate,
  onChangeModal,
  onChangeSelect2Modal,
  onClickSaveModal,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Mode === 'edit';
  const checkSave = !!((modalData.Mode === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" /> Interest Rate
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required={false}>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroup}
                  name="FinanceGroup"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit" required={false}>
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnit}
                  name="BusinessUnit"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  financeGroupId={criteria.FinanceGroupId}
                  businessUnitId={criteria.BusinessUnit}
                  value={criteria.CompanyCode}
                  name="CompanyCode"
                />
              </FormGroup2>

              <FormGroup2 text="Bank" required={false}>
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.BankCode}
                  companyCode={criteria.CompanyCode}
                  name="BankCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.Currency}
                  name="Currency"
                />
              </FormGroup2>

              <FormGroup2 text="Effective Date" required={false}>
                <DateRangePicker
                  value={criteria.EffectiveDateStr}
                  onChange={onChangeSearch}
                  name="EffectiveDateStr"
                  required={false}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  name="Status"
                >
                  <option value={null}>---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="CREATE INTEREST"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalCreate}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Effective Date</th>
              <th>
                Finance
                <br />
                Group
              </th>
              <th>
                Business
                <br />
                Unit
              </th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>Company Name</th>
              <th>Swift Code</th>
              <th>Bank</th>
              <th>Bank Account No.</th>
              <th>Interest Rate</th>
              <th>Currency</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Interest Rate" bgHeader="bg-primary" size="modal-lg" ref={modalRef}>
        <form onSubmit={onClickSaveModal}>
          <Col2 col={colX[0]}>
            <FormGroup2 text="Effective Date" required>
              <DatePicker
                value={modalData.EffectiveDateStr}
                onChange={onChangeModal}
                name="EffectiveDateStr"
                option={{
                  // startDate: "d",
                  todayHighlight: true,
                }}
                disabled={modeEdit}
                required
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="Finance Group" required>
              {modeEdit ? (
                <input
                  className="form-control"
                  type="text"
                  value={modalData.FinanceGroupName}
                  disabled
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={modalData.FinanceGroupId}
                  isChoose
                  notMultipleSelect2
                  required
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  name="FinanceGroupId"
                />
              )}
            </FormGroup2>
            <FormGroup2 text="Company" required>
              {modeEdit ? (
                <input
                  className="form-control"
                  type="text"
                  value={`${modalData.CompanyCodeResult} | ${modalData.CompanyNameResult}`}
                  disabled
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  value={modalData.CompanyCode}
                  // onChange={onChangeSelect2Modal}
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  name="CompanyCode"
                  financeGroupId={modalData.FinanceGroupId}
                />
              )}
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="Bank" required>
              {modeEdit ? (
                <input
                  className="form-control"
                  type="text"
                  value={`${modalData.BankAbbreviate} | ${modalData.BankName}`}
                  disabled
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_BANK}
                  value={modalData.BankCode}
                  isChoose
                  notMultipleSelect2
                  required
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  saveLocalState
                  companyCode={modalData.CompanyCode}
                  name="BankCode"
                />
              )}
            </FormGroup2>

            <FormGroup2 text="Bank Account No." required>
              {modeEdit ? (
                <input
                  className="form-control"
                  type="text"
                  value={modalData.BankAccountNo || ''}
                  disabled
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_BANK_ACCOUNT}
                  value={modalData.BankAccountNo}
                  isChoose
                  notMultipleSelect2
                  required
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  saveLocalState
                  companyCode={modalData.CompanyCode}
                  bankCode={modalData.BankCode}
                  customeValue={(m) => `${m.bankAccountNo}`}
                  name="BankAccountNo"
                />
              )}
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="Currency" required>
              {modeEdit ? (
                <input className="form-control" type="text" value={modalData.Currency} disabled />
              ) : (
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  value={modalData.Currency}
                  isChoose
                  notMultipleSelect2
                  required
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  name="Currency"
                />
              )}
            </FormGroup2>
            <FormGroup2 text="Interest Rate (%)" required>
              <InputMask
                className="form-control"
                onChange={onChangeModal}
                format="currency"
                option={{
                  prefix: '',
                  digits: 5,
                  digitsOptional: false,
                  placeholder: '0.00000',
                }}
                value={modalData.InterestRate}
                disabled={checkSave}
                name="InterestRate"
              />
            </FormGroup2>
          </Col2>

          <br />
          <div className="row">
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
