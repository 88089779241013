import { connect } from 'react-redux';
import React, { Component } from 'react';

import UserDetailRole from './UserDetailRole';
import { addAlert } from '../../reducers/layout/action';
import { toUpperKey, setCheckedCheckboxDataTableByInputElement } from '../../common/helpper';
import {
  updateResultRole,
  updateResultCompany,
  saveMasterUserDetailGroup,
  getMasterUserDetailGroupDetail,
} from '../../reducers/masterUser/action';

const $ = window.jQuery;
export const DATATABLE_ID_COMPANY = 'table-result-company-userdetail';
export const COL_CHECKBOX_CONTEXT_COMPANY = '.col-label-checkbox.result-company';
export const COL_CHOOSE_ALL_CONTEXT_COMPANY = '.label-checkbox.fix.choose-all.result-company';

export const DATATABLE_ID_ROLE = 'table-result-role-userdetail';
export const COL_CHECKBOX_CONTEXT_ROLE = '.col-label-checkbox.result-role';
export const COL_CHOOSE_ALL_CONTEXT_ROLE = '.label-checkbox.fix.choose-all.result-role';

const initData = {
  Username: '',
  GroupName: '',
  mode: 'create',
};

class UserDetailRoleContainer extends Component {
  state = {
    ...initData,
    resultRole: [],
    resultCompany: [],
  };

  dataTableCompanyRef = null;

  dataTableRoleRef = null;

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    const splitId = id.split('&');
    const username = splitId[0];
    const groupId = splitId[1].slice(3, splitId[1].length);

    if (!groupId || groupId.toString().toLowerCase() === 'create')
      this.props
        .getMasterUserDetailGroupDetail({
          Username: username.replace(/\./g, '_'),
          Id: '',
        })
        .then((res) => {
          if (res.error) return;
          this.setState({
            ...initData,
            Username: username,
          });
        });
    else {
      this.fetchId(username, groupId);
    }

    $('body').on(
      'click',
      `td${COL_CHECKBOX_CONTEXT_COMPANY}`,
      this.onClickColumnLabelCheckboxCompany
    );
    $('body').on(
      'click',
      `th ${COL_CHOOSE_ALL_CONTEXT_COMPANY}`,
      this.onClickCheckboxSelecteAllCompany
    );
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT_ROLE}`, this.onClickColumnLabelCheckboxRole);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT_ROLE}`, this.onClickCheckboxSelecteAllRole);
  }

  componentWillUnmount() {
    $('body').off(
      'click',
      `td${COL_CHECKBOX_CONTEXT_COMPANY}`,
      this.onClickColumnLabelCheckboxCompany
    );
    $('body').off(
      'click',
      `th ${COL_CHOOSE_ALL_CONTEXT_COMPANY}`,
      this.onClickCheckboxSelecteAllCompany
    );
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT_ROLE}`, this.onClickColumnLabelCheckboxRole);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT_ROLE}`, this.onClickCheckboxSelecteAllRole);
  }

  fetchId(username, groupId) {
    if (!groupId) return;
    this.props
      .getMasterUserDetailGroupDetail({
        Username: username,
        Id: groupId,
      })
      .then((res) => {
        if (res.error) return;
        const fetchData = toUpperKey(res.payload);
        this.setState({
          ...fetchData,
          mode: 'edit',
        });
      });
  }

  onClickCheckboxSelecteAllCompany = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateResultCompany('isActiveUserCompany', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckboxCompany = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableCompanyRef.$dataTable
    );
    this.props.resultCompany[result.row].isActiveUserCompany = result.checked;
    this.forceUpdate();
  };

  onClickCheckboxSelecteAllRole = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateResultRole('isActiveUserRole', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckboxRole = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(e, this.dataTableRoleRef.$dataTable);
    this.props.resultRole[result.row].isActiveUserRole = result.checked;
    this.forceUpdate();
  };

  onChangeInputData = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };
      return stateToUpdate;
    });
  };

  onSubmitHander = async (e) => {
    e.preventDefault();

    let error = '';
    // save attachfile
    let fileLength = 0;
    if (this.attachFileRef) {
      try {
        const resp = await this.attachFileRef.submit();
        if (resp.error) {
          error = resp.error;
        }
      } catch (ex) {
        error = 'Error occure please try agian.';
      }

      if (error) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: error,
        });
        return;
      }
      fileLength = this.attachFileRef.fileLength;
    }

    const selectedRoles = this.props.resultRole.filter((f) => f.isActiveUserRole);
    // check approver role
    if (selectedRoles.find((f) => f.roleName === 'Approver 1' || f.roleName === 'Approver 2')) {
      if (!fileLength) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please attachfile.',
        });
        return;
      }

      if (!this.state.Approver1) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please select Approver.',
        });
        return;
      }
    }

    const submitData = {
      ...this.state,
      ResultCompany: this.props.resultCompany.filter((f) => f.isActiveUserCompany),
      ResultRole: selectedRoles,
    };
    submitData.Mode = submitData.mode;
    delete submitData.mode;

    this.props.saveMasterUserDetailGroup(submitData).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (this.state.mode === 'create') {
        window.location.href = `/master/user-detail/${response.payload}`;
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push(`/master/user-detail/${this.state.Username}`);
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,

      dataTableCompanyRef: (e) => (this.dataTableCompanyRef = e),
      dataTableRoleRef: (e) => (this.dataTableRoleRef = e),

      attachFileRef: (e) => (this.attachFileRef = e),
    };

    return (
      <div>
        <UserDetailRole
          {...props}
          state={this.state}
          resultCompany={this.props.resultCompany}
          resultRole={this.props.resultRole}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterUser,
  }),
  {
    updateResultCompany,
    updateResultRole,
    saveMasterUserDetailGroup,
    addAlert,
    getMasterUserDetailGroupDetail,
  }
)(UserDetailRoleContainer);
