import React from 'react';

import { toFixed } from '../../../common/helpper';
import DatePicker from '../../../common/DatePicker';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { MASTER_BANK_ACCOUNT } from '../../../reducers/master/action';

export default ({
  state,
  onChangeInputTable,
  resultType,
  BankCode,
  CompanyCode,
  PartnerType,
  isBen,
  isCharge,
  hasUnit,

  bankAccountNoAll,
  paymentMethodAll,
  onChangeInputHeader,
  isEndOfTerms,

  formRef,
  formName,
  permission,
}) => {
  hasUnit = hasUnit || false;

  return (
    <form ref={formRef} name={formName}>
      <div className="table-responsive">
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th className="th-white">Action</th>
              {!isCharge ? (
                <>
                  <th className="th-white">Period</th>
                  <th className="th-white">Days</th>
                </>
              ) : null}
              <th className="th-white">Payment Date</th>
              <th className="th-white">Direction</th>
              <th className="th-white">Flow Type</th>
              {hasUnit ? <th className="th-white">Unit</th> : null}
              <th className="th-white">Amount</th>
              <th className="th-white">Currency</th>
              <th className="th-white">
                Bank Account<span style={{ color: '#e64a19' }}>*</span>
                <br />
                <div className="td-input-account">
                  {/* <MasterDropdown
                                        noValidateOption={true}
                                        masterType={MASTER_BANK_ACCOUNT}
                                        isChoose={true}
                                        notMultipleSelect2={true}
                                        value={bankAccountNoAll}
                                        onChange={e => onChangeInputHeader({
                                            target: e
                                        }, resultType)}
                                        saveLocalState={true}
                                        bankCode={BankCode}
                                        companyCode={CompanyCode}
                                        name="bankAccountNoAll"
                                        customeValue={m => `${m.bankAccountNo}`}
                                        // disabled={.modeEnd || false} 
                                    /> */}
                  {BankCode ? (
                    <MasterDropdown
                      noValidateOption
                      masterType={MASTER_BANK_ACCOUNT}
                      isChoose
                      notMultipleSelect2
                      value={bankAccountNoAll}
                      onChange={(e) =>
                        onChangeInputHeader(
                          {
                            target: e,
                          },
                          resultType
                        )
                      }
                      saveLocalState
                      bankCode={BankCode}
                      companyCode={CompanyCode}
                      name="bankAccountNoAll"
                      customeValue={(m) => `${m.bankAccountNo}`}
                      // disabled={.modeEnd || false}
                    />
                  ) : (
                    <MasterDropdownUI
                      onChange={(e) =>
                        onChangeInputHeader(
                          {
                            target: e,
                          },
                          resultType
                        )
                      }
                      value={bankAccountNoAll}
                      isChoose
                      notMultipleSelect2
                      name="bankAccountNoAll"
                      options={null}
                    />
                  )}
                </div>
              </th>
              <th className="th-white">
                Payment Method <br />
                <div className="td-input-method" style={{ fontWeight: '0' }}>
                  <MasterDropdownUI
                    onChange={(e) =>
                      onChangeInputHeader(
                        {
                          target: e,
                        },
                        resultType
                      )
                    }
                    value={paymentMethodAll}
                    isChoose
                    notMultipleSelect2
                    name="paymentMethodAll"
                    options={[
                      'Bahtnet',
                      'Cheque',
                      'Internet Banking',
                      'Auto Deduct',
                      'T/T',
                      'T/T Swaper',
                    ]}
                  />
                </div>
              </th>
              {isBen ? <th className="th-white">Beneficary Bank Account</th> : null}
              <th className="th-white">
                Posting
                <br />
                Status
              </th>
              <th className="th-white">Exchange Rate</th>
              <th className="th-white">
                Local Currency
                <br />
                Amount
              </th>
              <th className="th-white">
                Local
                <br />
                Currency
              </th>
              <th className="th-white">Posting Date</th>
              <th className="th-white">Document No.</th>
            </tr>
          </thead>
          <tbody>
            {!(state || []).length ? (
              <tr>
                <td colSpan={isBen ? '16' : '15'} align="center">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {state.map((m, i) => (
              <tr key={i}>
                <td align="center">
                  {m.isManualCreate && permission ? (
                    <button
                      type="button"
                      className="btn btn-icon btn-default delete-result"
                      cashflowid={m.id || ''}
                      index={i}
                      cashflowtype={m.cashFlowType}
                      title="Cancel"
                    >
                      <span className="icon icon-close sq-24" />
                    </button>
                  ) : null}
                </td>
                {!isCharge ? (
                  <>
                    <td align="center">
                      {m.startInterestDateStr} - {m.endInterestDateStr}
                    </td>
                    <td align="center">{m.interestDay}</td>
                  </>
                ) : null}
                <td>
                  <div className="td-input-date">
                    <DatePicker
                      value={m.paymentDateStr}
                      onChange={(e) => onChangeInputTable(e, i, resultType)}
                      option={{
                        daysOfWeekDisabled: '0,6',
                        todayHighlight: true,
                      }}
                      required={!isEndOfTerms}
                      disabled={m.modeEnd || false}
                      name="paymentDateStr"
                    />
                  </div>
                </td>
                <td align="center">{m.direction}</td>
                <td align="center">{m.flowType}</td>
                {hasUnit ? <td align="center">{m.unit}</td> : null}
                <td align="right">{setAmount(m.amount, 2)}</td>
                <td align="center">{m.currency}</td>
                <td>
                  <div className="td-input-account">
                    <MasterDropdown
                      noValidateOption
                      masterType={MASTER_BANK_ACCOUNT}
                      isChoose
                      notMultipleSelect2
                      value={m.bankAccountNo}
                      onChange={(e) =>
                        onChangeInputTable(
                          {
                            target: e,
                          },
                          i,
                          resultType
                        )
                      }
                      saveLocalState
                      bankCode={BankCode}
                      companyCode={CompanyCode}
                      name="bankAccountNo"
                      required
                      customeValue={(m) => `${m.bankAccountNo}`}
                      disabled={m.modeEnd || false}
                    />
                    {/* {
                                                BankCode ? (
                                                    <MasterDropdown
                                                        noValidateOption={true}
                                                        masterType={MASTER_BANK_ACCOUNT}
                                                        isChoose={true}
                                                        notMultipleSelect2={true}
                                                        value={m.bankAccountNo}
                                                        onChange={e => onChangeInputTable({
                                                            target: e
                                                        }, i, resultType)}
                                                        saveLocalState={true}
                                                        bankCode={BankCode}
                                                        companyCode={CompanyCode}
                                                        name="bankAccountNo"
                                                        required={true}
                                                        customeValue={m => `${m.bankAccountNo}`}
                                                        disabled={m.modeEnd || false} />
                                                ) :
                                                <MasterDropdownUI
                                                    onChange={e => onChangeInputTable({
                                                        target: e
                                                    }, i, resultType)}
                                                    value={m.bankAccountNo}
                                                    isChoose={true}
                                                    notMultipleSelect2={true}
                                                    name="bankAccountNo"
                                                    options={null}
                                                />
                                            } */}
                  </div>
                </td>
                <td>
                  <div className="td-input-method">
                    <MasterDropdownUI
                      onChange={(e) =>
                        onChangeInputTable(
                          {
                            target: e,
                          },
                          i,
                          resultType
                        )
                      }
                      required
                      value={m.paymentMethod}
                      isChoose
                      notMultipleSelect2
                      disabled={m.modeEnd || false}
                      name="paymentMethod"
                      options={[
                        'Bahtnet',
                        'Cheque',
                        'Internet Banking',
                        'Auto Deduct',
                        'T/T',
                        'T/T Swaper',
                      ]}
                    />
                  </div>
                </td>
                {isBen ? (
                  m.paymentMethod === 'Bahtnet' && PartnerType === 'External' ? (
                    // && toLower(m.flowType).includes('interest expense')
                    <td>
                      <div className="td-input-account">
                        <MasterDropdown
                          noValidateOption
                          masterType={MASTER_BANK_ACCOUNT}
                          isChoose
                          notMultipleSelect2
                          value={m.beneficiaryAccountNo}
                          onChange={(e) =>
                            onChangeInputTable(
                              {
                                target: e,
                              },
                              i,
                              resultType
                            )
                          }
                          saveLocalState
                          bankCode={BankCode}
                          companyCode={CompanyCode}
                          name="beneficiaryAccountNo"
                          required
                          disabled={m.modeEnd || false}
                          customeValue={(m) => `${m.bankAccountNo}`}
                        />
                      </div>
                    </td>
                  ) : (
                    <td />
                  )
                ) : null}
                <td align="center">{m.postingStatus}</td>
                <td align="right">{m.exchangeRate ? setAmount(m.exchangeRate, 8) : ''}</td>
                <td align="right">
                  {m.localCurrencyAmount ? setAmount(m.localCurrencyAmount, 2) : ''}
                </td>
                <td align="center">{m.localCurrency}</td>
                <td align="center">{m.postingDateStr}</td>
                {
                  // hasUnit = bond
                  hasUnit ? (
                    <td align="center">
                      <a target="_blank" href="/accounting-posting-journal/securities">
                        {m.documentNo}
                      </a>
                    </td>
                  ) : (
                    <td align="center">
                      <a href="#">{m.documentNo}</a>
                    </td>
                  )
                }
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );
};

const setAmount = (number, float) => {
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};
