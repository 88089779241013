import {
  ADD_ALERT,
  SET_LAYOUT,
  SET_LOADING,
  REMOVE_ALERT,
  REMOVE_ALL_ALERT,
  SET_PAGE_NAME_LAYOUT,
} from './action';

const initState = {
  page: '',
  isActiveSidebar: false,
  isActiveSidebarSm: true,
  isLoading: false,
  alerts: [],
};

export default (state = initState, action) => {
  switch (action.type) {
    case SET_LAYOUT: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case SET_PAGE_NAME_LAYOUT: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case ADD_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.payload],
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts.slice(0, action.index), ...state.alerts.slice(action.index + 1)],
      };
    case REMOVE_ALL_ALERT:
      return {
        ...state,
        alerts: [],
      };
    default:
      return state;
  }
};
