import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_ISSUER_CRITERIA = 'EDIT_BOND_CRITERIA';
export const CLEAR_ISSUER_CRITERIA = 'CLEAR_ISSUER_CRITERIA';

export const UPDATE_SEARCH_RESULT_ISSUER = 'UPDATE_SEARCH_RESULT_ISSUER';

export const REQUEST_SEARCH_ISSUER = '@@request/SEARCH_ISSUER';
export const RECEIVE_SEARCH_ISSUER = '@@receive/SEARCH_ISSUER';
export const FAIL_SEARCH_ISSUER = '@@fail/SEARCH_ISSUER';

export const REQUEST_SAVE_ISSUER_RATE = '@@request/SAVE_ISSUER_RATE';
export const RECEIVE_SAVE_ISSUER_RATE = '@@receive/SAVE_ISSUER_RATE';
export const FAIL_SAVE_ISSUER_RATE = '@@fail/SAVE_ISSUER_RATE';

export const searchIssuer = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/searchIssuer', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_ISSUER, RECEIVE_SEARCH_ISSUER, FAIL_SEARCH_ISSUER],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_ISSUER,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_ISSUER_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_ISSUER_CRITERIA,
  payload: {
    name,
    value,
  },
});

export const saveIssuer = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/master/saveissuer'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_ISSUER_RATE, RECEIVE_SAVE_ISSUER_RATE, FAIL_SAVE_ISSUER_RATE],
  },
});
