import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import { DATATABLE_ID } from './MarkToMarketContainer';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toNumber, getPermission } from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('FX Management', 'MTM Forward');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  columns: [
    { data: 'swiftCode' },
    {
      data: 'forwardNo',
      render: (d) => {
        if (!d) return '';
        return `<a target="_blank" href="/fx-management/forward-detail/${d}"> ${d} </a>`;
      },
    },
    { data: 'reportDateStr', className: 'text-center' },
    { data: 'contractNo' },
    {
      data: 'mappingContractNo',
      className: 'text-center',
      render: (d, t, r) =>
        d
          ? `<a class="edit-mapping-contract">${d}</a>`
          : '<button class="btn btn-sm btn-info edit-mapping-contract"><i class="icon icon-fw icon-edit icon-lg"></i></button>',
    },
    { data: 'startDateStr' },
    { data: 'endDateStr' },
    {
      data: 'forwardRate',
      className: 'text-center',
      render: (d, t, r) => toNumber(d, r.mtmCurrencyDigit),
    },
    { data: 'productGroup' },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'netMtmAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.mtmCurrencyDigit),
    },
    { data: 'netMtmCurrency', className: 'text-center' },
    {
      data: 'reversSign',
      className: 'text-center',
      render: (d) => (d ? '<i class="text-success icon icon-check icon-lg icon-fw"></i>' : ''),
    },
    { data: 'transactionType', className: 'text-center' },
    { data: 'ccy1', className: 'text-center' },
    { data: 'amount', className: 'text-right', render: (d, t, r) => toNumber(d, r.currency1Digit) },
    { data: 'ccy2', className: 'text-center' },
    {
      data: 'agiansAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currency2Digit),
    },
    { data: 'marketFxRate', className: 'text-right', render: (d, t, r) => toNumber(d, 8) },
    { data: 'fileName', className: 'text-center' },
    {
      data: 'fileName',
      className: 'text-center',
      render: (d) =>
        // if (!permission) return ''
        '<button class="btn btn-info btn-sm download"><i class="icon icon-download icon-fw icon-lg"></i></button>',
    },
    { data: 'createdDateStr' },
  ],
};

export default ({
  criteria,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,
  onClickFetchForward,
  results,
  dataTableRef,
  importRef,

  exportCriteria,
  exportModalRef,
  onChangeExportCriteria,
  onSubmitExport,
  onClickOpenModalExport,

  canGenFlowFxMonthEnd,
  onClickGenFlowMonthEnd,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          FX Management <span className="icon icon-angle-double-right" /> Mark To Market Forward
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group">
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroups}
                name="FinanceGroups"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companies}
                saveLocalState
                financeGroupId={criteria.FinanceGroups}
                businessUnitId={criteria.BusinessUnits}
                name="Companies"
              />
            </FormGroup2>

            <FormGroup2 text="Bank">
              <MasterDropdown
                masterType={MASTER_BANK}
                onChange={onChangeSelect2}
                value={criteria.Banks}
                companyCode={criteria.Companies}
                name="Banks"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="CCY (1)">
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.ForwardCurrencys}
                name="Currency1"
              />
            </FormGroup2>

            <FormGroup2 text="CCY (2)">
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.AgainstCurrencys}
                name="Currency2"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Report Date" required={false}>
              <DateRangePicker
                onChange={onChangeSearch}
                required={false}
                value={criteria.ReportDateStr}
                name="ReportDateStr"
              />
            </FormGroup2>

            <FormGroup2 text="Transaction Type">
              <MasterDropdownUI
                masterType={MASTER_CURRENCY}
                onChange={onChangeSelect2}
                value={criteria.TransactionType}
                name="MtmType"
                options={['Bought', 'Sold']}
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
            &nbsp;
            <Button
              txt="Import"
              icon="upload"
              className="btn-warning"
              onClick={() => importRef.open()}
            />
            &nbsp;
            <Button
              txt="Export"
              icon="download"
              className="btn-primary"
              onClick={onClickOpenModalExport}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      {permission ? (
        <div className="row">
          <div className="col-sm-12">
            <Button
              txt="Fetch Forward"
              icon="undo"
              className="btn-info"
              onClick={onClickFetchForward}
            />
            &nbsp;
            {canGenFlowFxMonthEnd ? (
              <Button
                txt="Gen Flow"
                icon="save"
                className="btn-warning"
                onClick={onClickGenFlowMonthEnd}
              />
            ) : null}
          </div>
        </div>
      ) : null}
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Bank Swift Code</th>
            <th>Transaction No</th>
            <th>Report Date</th>
            <th>Contract No.</th>
            <th>Mapping Contract No.</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Forward Rate</th>
            <th>Product Group</th>
            <th>Finance Group</th>
            <th>Business Unit</th>
            <th>
              Company
              <br /> Code
            </th>
            <th>Company Name</th>
            <th>Net MTM Amount</th>
            <th>Net MTM Currency</th>
            <th>Validation</th>
            <th>Buy/Sell</th>
            <th>CCY (1)</th>
            <th>Amount (1)</th>
            <th>CCY (2)</th>
            <th>Against Amount (2)</th>
            <th>Market FX Rate</th>
            <th>File Name</th>
            <th>
              Download
              <br />
              Bank’s Data
            </th>
            <th>Create Date</th>
          </tr>
        </thead>
      </Table>
    </Card>

    <Modal ref={exportModalRef} textHeader="Export Outstanding Forward" bgHeader="bg-info">
      <form className="form-horizontal" onSubmit={onSubmitExport}>
        <div className="form-group">
          <label className="control-label col-sm-6 col-md-4">Finance Group</label>
          <div className="col-md-8 col-sm-6">
            <MasterDropdown
              masterType={MASTER_FINANCE}
              onChange={(e) =>
                onChangeExportCriteria({
                  target: e,
                })
              }
              value={exportCriteria.FinanceGroup}
              name="FinanceGroup"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6 col-md-4">Company Code</label>
          <div className="col-md-8 col-sm-6">
            <MasterDropdown
              masterType={MASTER_COMPANY}
              onChange={(e) =>
                onChangeExportCriteria({
                  target: e,
                })
              }
              value={exportCriteria.CompanyCode}
              saveLocalState
              financeGroupId={exportCriteria.FinanceGroup}
              name="CompanyCode"
            />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-sm-6 col-md-4">Bank</label>
          <div className="col-md-8 col-sm-6">
            <MasterDropdown
              notMultipleSelect2
              masterType={MASTER_BANK}
              onChange={(e) =>
                onChangeExportCriteria({
                  target: e,
                })
              }
              value={exportCriteria.BankCode}
              name="BankCode"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-sm-6 col-md-4">Report Date</label>
          <div className="col-md-8 col-sm-6">
            <DatePicker
              required
              option={{ format: 'dd/mm/yyyy' }}
              onChange={onChangeExportCriteria}
              value={exportCriteria.ReportDate}
              name="ReportDate"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="col-sm-12">
            <Button txt="Export" type="submit" icon="download" className="btn-success" />
          </div>
        </div>
      </form>
    </Modal>
  </div>
);
