import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import RadioButton from '../../common/RadioButton';
import { DATATABLE_ID } from './ManagementContainer';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import ButtonDropdown from '../../common/ButtonDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import {
  toLower,
  toNumber,
  toStatus,
  toAccount,
  toBankCode,
  toStatusApprove,
  getNewPermission,
} from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_APPROVER,
  MASTER_BANK_CODE,
  MASTER_BANK_ACCOUNT,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

// let permission = getPermission('T/T Remittance', 'Manage')
// let permission = true
const newPermission = getNewPermission('T/T Remittance', 'Manage');
const { permission } = newPermission;

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX2 = ['col-md-5', 'col-md-6'];
const approverTypes = ['Approver1', 'Approver2', 'Reviewer', 'Verify'];
const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[5, 'asc']],
  fixedColumns: {
    leftColumns: 6,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      data: 'IsSelectedAll',
      className: `text-center col-label-checkbox-by-group pointer-cur`,
      render: (d, t, r) => {
        if (!~newPermission.compAll.indexOf(r.companyCode)) return '';
        if (r.groupTtNo)
          return `<input type="checkbox" class="label-checkbox" ${
            d ? 'checked="true"' : ''
          }/><label class="label_checkbox"></label>`;
        return '';
      },
    },
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        if (r.groupTtNo) {
          r.canSelect = false;
          return '';
        }
        if (!~newPermission.compAll.indexOf(r.companyCode)) {
          r.canSelect = false;
          return '';
        }
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${
          d ? 'checked="true"' : ''
        }/><label class="label_checkbox"></label>`;
      },
    },
    {
      data: 'IsEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!~newPermission.compAll.indexOf(r.companyCode)) return '';
        if (r.groupTtNo)
          return '<button class="btn btn-icon btn-info" disabled="true"><span class="icon icon-edit sq-24"></span></button>';
        if (toLower(r.statusTt) === 'wait for confirm')
          return '<button class="btn btn-icon btn-info edit-result"><span class="icon icon-edit sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info" disabled="true"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    {
      data: 'IsMethod',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!~newPermission.compAll.indexOf(r.companyCode)) return '';
        if (toLower(r.statusTt) === 'wait for confirm')
          return '<button class="btn btn-icon btn-default forward-result"><span class="icon icon-credit-card-alt sq-24"></span></button>';
        return '';
      },
    },
    { data: 'groupTtNo', className: 'text-center' },
    {
      data: 'transactionNo',
      render: (d, t, r) => {
        if (r.refTransactionNo)
          return `<a target="_blank" href="/tt-remittance/create-tt/${r.refTransactionNo}"> ${d} </a>`;
        return `<a target="_blank" href="/tt-remittance/view-detail-tt/${d}"> ${d} </a>`;
      },
    },
    {
      data: 'IsSplit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!~newPermission.compAll.indexOf(r.companyCode)) return '';
        if (toLower(r.statusTt) !== 'wait for confirm') return '';
        if (r.groupTtNo)
          return '<button class="btn btn-icon btn-warning" disabled="true"><span class="icon icon-object-ungroup sq-24"></span></button>';
        return '<button class="btn btn-icon btn-warning split-result"><span class="icon icon-object-ungroup sq-24"></span></button>';
      },
    },
    {
      data: 'IsUndoSplit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!~newPermission.compAll.indexOf(r.companyCode)) return '';
        if (!r.spiltRef) return '';
        return '<button class="btn btn-icon btn-lightbrown undo-split-result"><span class="icon icon-undo sq-24"></span></button>';
      },
    },
    {
      data: 'IsPrint',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!~newPermission.compAll.indexOf(r.companyCode)) return '';
        const method = toLower(r.transferMethod);
        if (!~['paper', 'sftp', 'internet banking'].indexOf(method)) return '';

        const status = toLower(r.statusTt);
        if (~['rejected'].indexOf(status))
          return '<button class="btn btn-icon btn-primary" disabled="true"><span class="icon icon-print sq-24"></span></button>';
        return '<button class="btn btn-icon btn-primary print-result"><span class="icon icon-print sq-24"></span></button>';
      },
    },
    { data: 'transferMethod' },
    { data: 'vPayId' },
    { data: 'vPayNo' },
    { data: 'forwardContractNoVpay' },
    {
      data: 'forwardContractNo',
      render: (d, t, r) => {
        if (t === 'export' || !d) return d;

        const s = d.split(',');
        let html = '';

        s.forEach((item) => {
          const ss = item.split('|');
          if (html) html += `<br/>`;
          html += `${ss[0].trim()} | ${toNumber(Number(ss[1].trim() || 0), r.currencyDigit)}`;
        });
        return html;
      },
    },
    // {
    //     data: 'forwardContractNo', className: 'text-center',
    //     render: (d, t, r) => {
    //         if (d) {
    //             if (toLower(r.statusTt) === 'wait for confirm')
    //                 return `<button class="btn btn-info-reverse forward-result">${d}</button>`
    //             return d
    //         }
    //         else if (r.bankAbbreviate && r.bankAccountNo && toLower(r.statusTt) === 'wait for confirm')
    //             return '<button class="text-center btn btn-icon btn-info forward-result"><span class="icon icon-edit sq-24"></span></button>'
    //         return ''
    //     }
    // },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'bankAbbreviate',
      render: (d, r, s) => toBankCode(s.bankCode, d, s.bankName),
    },
    {
      data: 'bankAccountNo',
      render: (d, r, s) =>
        s.bankAccountNo
          ? toAccount(
              null,
              s.branchName,
              s.accountTypeCode,
              s.bankCurrency,
              d,
              s.bankAccountNoOfBank
            )
          : '<br/><br/>',
    },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'confirmDateStr', className: 'text-center', type: 'date-black' },
    { data: 'paymentBy' },
    { data: 'invoiceNo' },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'currency', className: 'text-center' },
    { data: 'remittedToCurrency', className: 'text-center' },
    { data: 'preAdvice' },
    { data: 'paidFor' },
    { data: 'purposeDetail' },
    { data: 'q2oNo' },
    { data: 'requestorDepartment' },
    { data: 'vendorCode', className: 'text-center' },
    { data: 'vendorName' },
    { data: 'beneficiaryCode' },
    { data: 'beneficiaryName' },
    { data: 'beneficiaryCountry', className: 'text-center' },
    { data: 'accountNo' },
    { data: 'ibanNo' },
    { data: 'abaNo' },
    { data: 'bsbNo' },
    { data: 'ifscCode' },
    { data: 'beneficiaryBankSwiftCode', className: 'text-center' },
    { data: 'beneficiaryBankName' },
    { data: 'beneficiaryBankCountry', className: 'text-center' },
    { data: 'requestorRemark' },
    { data: 'poNo' },
    { data: 'specialMessage' },
    { data: 'errorMessage' },
    {
      data: 'attachFile',
      className: 'text-center',
      render: (d, t, r) => {
        if (!~newPermission.compAll.indexOf(r.companyCode)) return '';
        // if (d) return '<button class="btn btn-icon btn-primary download-file-result"><span class="icon icon-download sq-24"></span></button>'
        return '<button class="btn btn-icon btn-info attach-file-result"><span class="icon icon-cloud-upload sq-24"></span></button>';
      },
    },
    {
      data: 'countFile',
      className: 'text-center',
      render: (d, t, r) => {
        if (!d) return '';
        if (!r.vPayNo) return '';
        if (!r.vPayNo.includes('DTT')) return '';
        return toStatus('Completed');
      },
    },
    {
      data: 'statusAllpay',
      className: 'text-center',
      render: (d) => {
        if (toLower(d).includes('failed')) return toStatus('Failed');
        if (toLower(d).includes('completed')) return toStatus('Completed');
        return '';
      },
    },
    {
      data: 'statusTt',
      className: 'text-center',
      render: (d) => toStatus(d, true),
    },
    {
      data: 'approverName1',
      render: (d, t, r) => {
        if (r.approver1Status && toLower(r.approver1Status) !== 'wait for approve') return d;
        if (r.approver1) return `<a href="javascript:;" class="change-approver-1">${d}</a>`;
        return '';
      },
    },
    {
      data: 'approver1Status',
      render: (d, t, r) => toStatusApprove(r.approverName1, d),
    },
    {
      data: 'approverName2',
      render: (d, t, r) => {
        if (r.approver2Status && toLower(r.approver2Status) !== 'wait for approve') return d;
        if (r.approver2) return `<a href="javascript:;" class="change-approver-2">${d}</a>`;
        return '';
      },
    },
    {
      data: 'approver2Status',
      render: (d, t, r) => toStatusApprove(r.approverName2, d),
    },
    {
      data: 'reviewerName',
      render: (d, t, r) => {
        if (r.reviewerStatus && toLower(r.reviewerStatus) !== 'wait for approve') return d;
        if (r.reviewerName) return `<a href="javascript:;" class="change-reviewer">${d}</a>`;
        return '';
      },
    },
    {
      data: 'reviewerStatus',
      render: (d, t, r) => toStatusApprove(r.reviewer, d),
    },
    {
      data: 'verifyName',
      render: (d, t, r) => {
        if (r.verifyStatus && toLower(r.verifyStatus) !== 'wait for approve') return d;
        if (r.verifyName) return `<a href="javascript:;" class="change-verify">${d}</a>`;
        return '';
      },
    },
    { data: 'verifyStatus', render: (d, t, r) => toStatusApprove(r.verify, d) },
  ],
};

export default ({
  criteria,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,

  results,
  dataTableRef,

  modalEdit,
  modalEditRef,
  onClickOpenModalEdit,
  onChangeModalEdit,
  onClickSubmitModalEdit,
  onClickClearCurrency,

  modalSplitRef,
  modalSplit,
  onClickAddSplit,
  onSubmitModalSplit,
  onChangeSplitAmount,

  modalUndoRef,
  onClickConfirmModalUndo,

  modalUploadFile,
  modalUploadFileRef,
  inputAttachefileRef,
  onChangeAttachFile,
  onSubmitSaveUploadFile,

  onClickOpenModalFunction,

  modalMerge,
  modalMergeRef,
  onChangeModalMerge,
  onClickConfirmModalMerge,
  modalUnmergeRef,
  onClickConfirmModalUnmerge,

  modalChangeStatus,
  modalApproveRef,
  modalConfirmRef,
  modalNotConfirmRef,
  modalUnconfirmRef,
  modalRejectRef,
  onChangeModalChangeStatus,
  onSubmitModalChangeStatus,
  conditionChangeStatus,

  onClickOpenModalImportExcel,
  onClickAssignForward,
  modalSpotRateRef,

  onClickMultiPrint,

  modalClearQuoteRateRef,
  onClickOpenModalClearQuoteRate,
  onClickConfirmClearQuoteRate,

  modalChangeRef,
  modalChangeApprover,
  onChangeModalApproveChange,
  onSubmitModalChangeApprover,

  onClickOpenModalChangeApproverMultiTransaction,
  modalChangeApproverMultiTransRef,
  modalChangeApproverMultiTrans,
  onSubmitChangeApproverMultiTrans,
  onChangeModalApproverMultiTrans,

  onClickAutomate,
  modalCompletedRef,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;
  const selectedConfirmLength = conditionChangeStatus.confirm(results).length;
  const selectedApprovedLength = conditionChangeStatus.approve(results).length;
  const selectedRejectLength = conditionChangeStatus.reject(results).length;
  const selectedNotConfirmLength = conditionChangeStatus.notconfirm(results).length;
  const selectedUnconfirmLength = conditionChangeStatus.unconfirm(results).length;
  const selectedCompletedLength = conditionChangeStatus.completed(results).length;

  const reqEdit = modalEdit.Action !== 'edit';
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            T/T Remittance <span className="icon icon-angle-double-right" /> Management
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  saveLocalState
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                  filter={(m) => ~newPermission.compFilter.indexOf(m.companyCode)}
                  name="Companies"
                />
              </FormGroup2>

              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.Banks}
                  companyCode={criteria.Companies}
                  name="Banks"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.Currencies}
                  name="Currencies"
                />
              </FormGroup2>

              <FormGroup2 text="Pre Advice">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.PreAdvice}
                  name="PreAdvice"
                  options={['None', '1 Day', '2 Days before Value Date']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col="col-sm-6 form-group">
              <FormGroup2 text="Value Date" required>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                />
              </FormGroup2>

              <FormGroup2 text="Confirm Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.ConfirmDateStr}
                  name="ConfirmDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="T/T Status">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.Status}
                  name="Status"
                  options={[
                    'Wait for confirm',
                    'Confirmed',
                    'Rejected',
                    'Wait for approve',
                    'Disapproved',
                    'Wait for generate file',
                    'Wait for bank receive',
                    'Rejected by bank',
                    'Accepted by bank',
                    'Completed',
                    'Failed',
                  ]}
                />
              </FormGroup2>

              <FormGroup2 text="Transfer Method">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.TransferMethod}
                  name="TransferMethod"
                  options={['SFTP', 'Paper', 'Internet Banking']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Equivalent">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.Equivalent}
                  name="Equivalent"
                  options={['Yes', 'No']}
                />
              </FormGroup2>

              <FormGroup2 text="Paid For">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.PaidFor}
                  name="PaidFor"
                  // options={["Service", "Goods", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection", "Other"]} />
                  options={[
                    'Service',
                    'Goods',
                    'Project',
                    'ST Loan',
                    'LT Loan & Swap',
                    'Capital Injection',
                    'Dividend',
                    'Other',
                  ]}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="PO No.">
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeSearch}
                  value={criteria.PoNo}
                  name="PoNo"
                />
              </FormGroup2>
              <FormGroup2 text="Invoice No.">
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeSearch}
                  value={criteria.InvoiceNo}
                  name="InvoiceNo"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Reference No.">
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeSearch}
                  value={criteria.ReferenceNo}
                  name="ReferenceNo"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              {permission ? (
                <>
                  &nbsp;
                  <Button
                    txt="Upload"
                    icon="upload"
                    className="btn-purple"
                    onClick={onClickOpenModalImportExcel}
                  />
                  {/* &nbsp;
                                    <Link className="btn btn-warning btn-sm btn-labeled" to="/tt-remittance/view-detail-tt/create" target="_blank">
                                        <span className="btn-label">
                                            <i className="icon icon-plus-circle icon-fw icon-lg"></i>
                                        </span>
                                        CREATE
                                    </Link> */}
                </>
              ) : null}
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
              &nbsp;
              <Button
                disabled={!results.filter((f) => f.IsSelected).length}
                txt="Automate"
                icon="asterisk"
                className="btn-warning"
                onClick={onClickAutomate}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div>
              <Button
                txt="MERGE"
                icon="plus"
                className="btn-info"
                onClick={(e) => onClickOpenModalFunction('merge')}
              />
              &nbsp;
              <Button
                txt="UNMERGE"
                icon="undo"
                className="btn-lightbrown"
                onClick={(e) => onClickOpenModalFunction('unmerge')}
              />
              &nbsp;
              <Button
                txt="CONFIRM"
                icon="check"
                className="btn-success"
                onClick={(e) => onClickOpenModalFunction('confirm')}
              />
              &nbsp;
              {/* <Button txt="NOT CONFIRM" icon="close" className="btn-default"
                                    onClick={e => onClickOpenModalFunction("not confirm")}
                                />
                                &nbsp; */}
              <Button
                txt="REJECT"
                icon="close"
                className="btn-danger"
                onClick={(e) => onClickOpenModalFunction('reject')}
              />
              &nbsp;
              <Button
                txt="UNCONFIRM"
                icon="refresh"
                className="btn-warning"
                onClick={(e) => onClickOpenModalFunction('unconfirm')}
              />
              &nbsp;
              <Button
                txt="Change Approver"
                icon="edit"
                className="btn-primary"
                onClick={onClickOpenModalChangeApproverMultiTransaction}
              />
              &nbsp;
              <ButtonDropdown
                className="btn-blank"
                text="Option"
                icon="icon-reorder"
                buttons={[
                  {
                    text: 'Attach File',
                    icon: 'icon-cloud-upload',
                    onClick: (e) => {
                      onClickOpenModalFunction('attach file');
                    },
                  },
                  {
                    text: 'Select Bank',
                    icon: 'icon-university',
                    onClick: (e) => {
                      onClickOpenModalEdit('change bank');
                    },
                  },
                  {
                    text: 'Select Method',
                    icon: 'icon-credit-card-alt',
                    onClick: (e) => {
                      onClickOpenModalEdit('change method');
                    },
                  },
                  {
                    text: 'Value Date',
                    icon: 'icon-calendar',
                    onClick: (e) => {
                      onClickOpenModalEdit('change value date');
                    },
                  },
                ]}
              />
              &nbsp;
              <ButtonDropdown
                className="btn-default"
                text="Quote Rate Method"
                icon="icon-credit-card-alt"
                buttons={[
                  {
                    text: 'Spot Rate',
                    icon: 'icon-credit-card-alt',
                    onClick: (e) => {
                      onClickOpenModalFunction('spot');
                    },
                  },
                  {
                    text: 'Forward Rate',
                    icon: 'icon-credit-card-alt',
                    onClick: onClickAssignForward,
                  },
                ]}
              />
              &nbsp;
              <Button
                txt="Multi Print"
                icon="print"
                className="btn-info"
                onClick={onClickMultiPrint}
              />
              <Button
                txt="Manual Completed"
                icon="check"
                className="btn-success"
                onClick={(e) => onClickOpenModalFunction('completed')}
              />
              &nbsp;
              <Button
                txt="Clear Quote Rate"
                icon="eraser"
                className="btn-clear"
                onClick={onClickOpenModalClearQuoteRate}
              />
            </div>
          </Col2>
        ) : null}
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>
                Choose By
                <br />
                Group
              </th>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>Edit</th>
              <th>
                Quote Rate
                <br /> Method
              </th>
              <th>Group ID</th>
              <th>Transaction No.</th>
              <th>Split</th>
              <th>
                Undo
                <br /> Split
              </th>
              <th>Print</th>
              <th>Transfer Method</th>
              <th>V-Pay ID</th>
              <th>Req. Pmt. Ref</th>
              <th>
                Forward Contract No.
                <br /> (V-Pay)
              </th>
              <th>
                Forward Contract No.
                <br /> (Internal)
              </th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br /> Code
              </th>
              <th>Company Name</th>
              <th>Bank</th>
              <th>Bank Account</th>
              <th>Value Date</th>
              <th>Confirm Date</th>
              <th>Payment By</th>
              <th>Invoice No.</th>
              <th>Net Paid Amount</th>
              <th>Currency</th>
              <th>
                Remitted To
                <br /> Currency
              </th>
              <th>Pre-Advice</th>
              <th>Paid For</th>
              <th>Purpose Detail</th>
              <th>Internal Ref. No.</th>
              <th>Department</th>
              <th>Vendor Code</th>
              <th>Vendor Name</th>
              <th>Beneficiary Code</th>
              <th>Beneficiary Name</th>
              <th>
                Beneficiary
                <br /> Country
              </th>
              <th>Account No.</th>
              <th>IBAN No.</th>
              <th>ABA No.</th>
              <th>BSB No.</th>
              <th>IFSC Code</th>
              <th>
                Beneficiary's
                <br /> SWIFT Code
              </th>
              <th>Beneficiary's Bank Name</th>
              <th>
                Beneficiary's
                <br /> Bank Country
              </th>
              <th>Requestor Remark</th>
              <th>PO No.</th>
              <th>Special Message</th>
              <th>Error Message</th>
              <th>Attach File</th>
              <th>
                Receipt
                <br />
                Status
              </th>
              <th>
                Status
                <br />
                Allpay
              </th>
              <th>Status</th>
              <th>
                Assign
                <br />
                Approver 1
              </th>
              <th>Status</th>
              <th>
                Assign
                <br />
                Approver 2
              </th>
              <th>Status</th>
              <th>Reviewer</th>
              <th>Status</th>
              <th>Verify</th>
              <th>Status</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-md" ref={modalEditRef} modalFooter>
        <form onSubmit={onClickSubmitModalEdit} className="form-horizontal">
          <div className="text-center">
            {modalEdit.Action !== 'edit' ? (
              <span className="label label-info">{selectedResultCount} items</span>
            ) : null}
            <h2 className="text-info">
              {modalEdit.Action === 'edit'
                ? 'EDIT'
                : modalEdit.Action === 'change value date'
                  ? 'Change Value Date'
                  : modalEdit.Action === 'change bank'
                    ? 'Change Bank'
                    : 'Change Method'}
            </h2>
          </div>
          <br />
          {modalEdit.Action === 'edit' || modalEdit.Action === 'change value date' ? (
            <div className="form-group">
              <label className="control-label col-md-4">
                Value Date<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <div className="col-md-7">
                <DatePicker
                  onChange={onChangeModalEdit}
                  name="ValueDateStr"
                  value={modalEdit.ValueDateStr}
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                  required
                />
              </div>
            </div>
          ) : null}
          {modalEdit.Action === 'edit' || modalEdit.Action === 'change bank' ? (
            <div className="form-group">
              <label className="control-label col-md-4">
                Bank{reqEdit ? <span style={{ color: '#e64a19' }}>*</span> : ''}
              </label>
              <div className="col-md-7">
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  isChoose
                  notMultipleSelect2
                  value={modalEdit.SwiftCode}
                  onChange={(e) =>
                    onChangeModalEdit({
                      target: e,
                    })
                  }
                  isGetAll
                  isIntersect
                  noValidateOption
                  saveLocalState
                  customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                  companyCode={modalEdit.CompanyCode}
                  required={reqEdit}
                  name="SwiftCode"
                />
              </div>
            </div>
          ) : null}
          {modalEdit.Action === 'edit' ? (
            <div className="form-group">
              <label className="control-label col-md-4">Bank Account</label>
              <div className="col-md-7">
                <MasterDropdown
                  masterType={MASTER_BANK_ACCOUNT}
                  isChoose
                  notMultipleSelect2
                  value={modalEdit.BankAccountNo}
                  onChange={(e) =>
                    onChangeModalEdit({
                      target: e,
                    })
                  }
                  customeValue={(m) => m.bankAccountNo}
                  noValidateOption
                  saveLocalState
                  bankCode={modalEdit.SwiftCode}
                  companyCode={modalEdit.CompanyCode}
                  required={false}
                  isAccountBank
                  name="BankAccountNo"
                />
              </div>
            </div>
          ) : null}
          {modalEdit.Action === 'edit' || modalEdit.Action === 'change method' ? (
            <div className="form-group">
              <label className="control-label col-md-4">
                Transfer Method
                {reqEdit ? <span style={{ color: '#e64a19' }}>*</span> : ''}
              </label>
              <div className="col-md-7">
                <MasterDropdownUI
                  isChoose
                  notMultipleSelect2
                  value={modalEdit.TransferMethod}
                  onChange={(e) =>
                    onChangeModalEdit({
                      target: e,
                    })
                  }
                  required={reqEdit}
                  options={['SFTP', 'Paper', 'Internet Banking']}
                  name="TransferMethod"
                />
              </div>
            </div>
          ) : null}
          {modalEdit.Action === 'edit' ? (
            <>
              <div className="form-group">
                <label className="control-label col-md-4">Paid For</label>
                <div className="col-md-7">
                  <MasterDropdownUI
                    isChoose
                    notMultipleSelect2
                    value={modalEdit.PaidFor}
                    onChange={(e) =>
                      onChangeModalEdit({
                        target: e,
                      })
                    }
                    required={false}
                    // options={["Service", "Goods", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection", "Other"]}
                    options={[
                      'Service',
                      'Goods',
                      'Project',
                      'ST Loan',
                      'LT Loan & Swap',
                      'Capital Injection',
                      'Dividend',
                      'Other',
                    ]}
                    name="PaidFor"
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-4">
                  Pre Advice<span style={{ color: '#e64a19' }}>*</span>
                </label>
                <div className="col-md-7">
                  <MasterDropdownUI
                    isChoose
                    notMultipleSelect2
                    value={modalEdit.PreAdvice}
                    onChange={(e) =>
                      onChangeModalEdit({
                        target: e,
                      })
                    }
                    required
                    options={['None', '1 Day', '2 Days before Value Date']}
                    name="PreAdvice"
                  />
                </div>
              </div>

              <div className="form-group">
                <label className="control-label col-md-4">Remitted To Currency</label>
                <div className="col-md-4">
                  <MasterDropdown
                    masterType={MASTER_CURRENCY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalEdit({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={false}
                    disabled={false}
                    value={modalEdit.RemittedToCurrency}
                    name="RemittedToCurrency"
                  />
                </div>
                <div className="col-md-4">
                  <Button
                    txt="Clear"
                    icon="eraser"
                    className="btn-clear"
                    onClick={onClickClearCurrency}
                  />
                </div>
              </div>
            </>
          ) : null}
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal textHeader="Split" ref={modalSplitRef}>
        <div className="row">
          <div className="col-sm-6">
            <Button
              txt="Split"
              icon="code-fork"
              className="btn-warning"
              onClick={onClickAddSplit}
            />
          </div>
          <div className="col-sm-6">
            <label>Net Paid Amount: {toNumber(modalSplit.originalAmount, 0)}</label>
          </div>
        </div>
        <br />
        <form onSubmit={onSubmitModalSplit}>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Split No.</th>
                  <th>Net Paid Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">{modalSplit.splitNo}</td>
                  <td>
                    <input
                      value={toNumber(modalSplit.amount || 0)}
                      className="form-control text-right"
                      disabled
                    />
                  </td>
                </tr>
                {modalSplit.split.map((m, i) => (
                  <tr key={i}>
                    <td align="center">{m.splitNo}</td>
                    <td>
                      <InputMask
                        required
                        format="currency"
                        name="amount"
                        value={m.amount}
                        onChange={(e) => onChangeSplitAmount(e, i)}
                        className="form-control"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button type="submit" className="btn-success" icon="save" txt="Confirm" />
            </div>
          </div>
        </form>
      </Modal>

      {/* <ModalFunction
                textHeader="Merge"
                textContent="a Merge"
                modalRef={modalMergeRef}
                type="info"
                icon="icon-plus"
                textBtn="Yes"
                textBtnBack="No"
                noSelect={false}
                showFail={false}
                selectedLength={selectedResultCount}
                onClick={onClickConfirmModalMerge}
            /> */}

      <Modal size="modal-md" ref={modalMergeRef} modalFooter>
        <form onSubmit={(e) => onClickConfirmModalMerge(e)} className="form-horizontal">
          <div className="text-center">
            <span className="text-info icon icon-plus icon-5x" />
            <h2 className="text-info">Merge</h2>
            <p>{selectedResultCount} selected, click to merge?</p>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Special Message<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-7">
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeModalMerge}
                name="SpecialMessage"
                required
                value={modalMerge.SpecialMessage}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Unmerge"
        textContent="unmerge"
        modalRef={modalUnmergeRef}
        type="brown"
        icon="icon-undo"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onClickConfirmModalUnmerge}
      />

      <ModalFunction
        textHeader="Confirm"
        textContent="confirm"
        modalRef={modalApproveRef}
        type="success"
        icon="icon-check"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail
        selectedLength={selectedApprovedLength}
        selectedResultCount={selectedResultCount}
        onClick={(e) => onSubmitModalChangeStatus(e, 'confirm')}
      />

      <Modal size="modal-md" ref={modalConfirmRef} modalFooter>
        <form
          onSubmit={(e) =>
            onSubmitModalChangeStatus(e, selectedApprovedLength ? 'confirm&assign' : 'confirm')
          }
          className="form-horizontal"
        >
          <div className="text-center">
            <span className="text-success icon icon-check icon-5x" />
            <h3 className="text-success">Confirm</h3>
            <p>{selectedResultCount} items selected, click to confirm?</p>
            {selectedApprovedLength ? (
              <div>
                <p>assign approver : {selectedConfirmLength} items selected.</p>
                <p>approve : {selectedApprovedLength} items selected.</p>
              </div>
            ) : null}
            {selectedResultCount - selectedApprovedLength - selectedConfirmLength ? (
              <p>
                fail : {selectedResultCount - selectedApprovedLength - selectedConfirmLength} items
                selected.
              </p>
            ) : null}
          </div>
          <br />
          <div>
            {!modalChangeStatus.setAssign2Only ? (
              <div className="form-group">
                <label className="control-label col-md-4">
                  Assign Type<span style={{ color: '#e64a19' }}>*</span>
                </label>
                <div className="col-md-7">
                  <div style={{ paddingTop: '7px' }}>
                    <RadioButton
                      text="Assign Approver"
                      value="Assign Approver"
                      checked={modalChangeStatus.AssignType === 'Assign Approver'}
                      onChange={onChangeModalChangeStatus}
                      name="AssignType"
                      required
                    />
                    <RadioButton
                      text="Assign Verifier"
                      value="Assign Verifier"
                      checked={modalChangeStatus.AssignType === 'Assign Verifier'}
                      onChange={onChangeModalChangeStatus}
                      name="AssignType"
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {modalChangeStatus.AssignType === 'Assign Verifier' ? (
              <div className="form-group">
                <label className="control-label col-md-4">
                  Assign Verifier<span style={{ color: '#e64a19' }}>*</span>
                </label>
                <div className="col-md-7">
                  <MasterDropdown
                    masterType={MASTER_APPROVER}
                    isChoose
                    notMultipleSelect2
                    required
                    value={modalChangeStatus.Verifier}
                    onChange={(e) =>
                      onChangeModalChangeStatus({
                        target: e,
                      })
                    }
                    filter={(m) => m.roleName === 'Verifier'}
                    financeGroupId={modalChangeStatus.FinanceGroupId}
                    name="Verifier"
                  />
                </div>
              </div>
            ) : (
              <div>
                {modalChangeStatus.setReviewer ? (
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      Reviewer<span style={{ color: '#e64a19' }}>*</span>
                    </label>
                    <div className="col-md-7">
                      <MasterDropdown
                        masterType={MASTER_APPROVER}
                        isChoose
                        notMultipleSelect2
                        required
                        value={modalChangeStatus.Reviewer}
                        onChange={(e) =>
                          onChangeModalChangeStatus({
                            target: e,
                          })
                        }
                        filter={(m) => m.roleName === 'Reviewer'}
                        financeGroupId={modalChangeStatus.FinanceGroupId}
                        name="Reviewer"
                      />
                    </div>
                  </div>
                ) : null}
                <div className="form-group">
                  <label className="control-label col-md-4">
                    Assign Approver 1<span style={{ color: '#e64a19' }}>*</span>
                  </label>
                  <div className="col-md-7">
                    <MasterDropdown
                      masterType={MASTER_APPROVER}
                      isChoose
                      notMultipleSelect2
                      required
                      filter={(m) => m.roleName === 'Approver 1'}
                      financeGroupId={modalChangeStatus.FinanceGroupId}
                      value={modalChangeStatus.Approver1}
                      onChange={(e) =>
                        onChangeModalChangeStatus({
                          target: e,
                        })
                      }
                      name="Approver1"
                    />
                  </div>
                </div>
                <div className="row">
                  <label className="control-label col-md-4">
                    Assign Approver 2<span style={{ color: '#e64a19' }}>*</span>
                  </label>
                  <div className="col-md-7">
                    <MasterDropdown
                      masterType={MASTER_APPROVER}
                      isChoose
                      notMultipleSelect2
                      required
                      filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                      financeGroupId={modalChangeStatus.FinanceGroupId}
                      value={modalChangeStatus.Approver2}
                      onChange={(e) =>
                        onChangeModalChangeStatus({
                          target: e,
                        })
                      }
                      name="Approver2"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <br />
          <div className="row" style={{ paddingTop: '10px' }}>
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-success" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Not Confirm"
        textContent="not confirm"
        modalRef={modalNotConfirmRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail
        selectedLength={selectedNotConfirmLength}
        selectedResultCount={selectedResultCount}
        onClick={(e) => onSubmitModalChangeStatus(e, 'not confirm')}
      />

      <ModalFunction
        textHeader="Unconfirm"
        textContent="unconfirm"
        modalRef={modalUnconfirmRef}
        type="warning"
        icon="icon-refresh"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail
        selectedLength={selectedUnconfirmLength}
        selectedResultCount={selectedResultCount}
        onClick={(e) => onSubmitModalChangeStatus(e, 'unconfirm')}
      />

      <ModalFunction
        textHeader="Spot Rate"
        textContent="spot rate"
        modalRef={modalSpotRateRef}
        type="primary"
        icon="icon-credit-card-alt"
        showFail={false}
        selectedLength={selectedNotConfirmLength}
        selectedResultCount={selectedResultCount}
        onClick={(e) => onSubmitModalChangeStatus(e, 'spot')}
      />

      <ModalFunction
        textHeader="Manual Completed"
        textContent="manual completed"
        modalRef={modalCompletedRef}
        type="success"
        icon="icon-check"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail
        selectedLength={selectedCompletedLength}
        selectedResultCount={selectedResultCount}
        onClick={(e) => onSubmitModalChangeStatus(e, 'completed')}
      />

      <Modal size="modal-md" ref={modalRejectRef} modalFooter>
        <form onSubmit={(e) => onSubmitModalChangeStatus(e, 'reject')} className="form-horizontal">
          <div className="text-center">
            <span className="text-danger icon icon-close icon-5x" />
            <h2 className="text-danger">Reject</h2>
            <p>
              {selectedResultCount} {selectedResultCount === 1 ? 'item' : 'items'} selected, click
              to reject?
            </p>
            {selectedResultCount - selectedRejectLength ? (
              <div>
                <p>pass : {selectedRejectLength} items selected.</p>
                <p>fail : {selectedResultCount - selectedRejectLength} items selected.</p>
              </div>
            ) : null}
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Remark<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-7">
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeModalChangeStatus}
                name="Remark"
                required
                value={modalChangeStatus.Remark}
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Yes" className="btn-danger" type="submit" />
              &nbsp;
              <Button txt="No" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Undo Split"
        textContent="undo split"
        modalRef={modalUndoRef}
        type="brown"
        icon="icon-undo"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        showFail={false}
        onClick={onClickConfirmModalUndo}
      />

      <Modal size="modal-md" ref={modalUploadFileRef} modalFooter>
        <form onSubmit={onSubmitSaveUploadFile} className="form-horizontal">
          <div className="text-center">
            <span className="text-info icon icon-cloud-upload icon-5x" />
            <h3 className="text-info">Attach File</h3>
            {modalUploadFile.Action === 'selected' ? (
              <p>{selectedResultCount} items selected, click to attach file?</p>
            ) : null}
            <br />
            <div className="row form-group">
              <label className="control-label col-md-4">
                Select File<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <div className="col-sm-7">
                <input
                  type="file"
                  name="File"
                  className="form-control"
                  onChange={onChangeAttachFile}
                  ref={inputAttachefileRef}
                />
              </div>
            </div>
            <div className="m-t-lg">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Clear Quote Rate"
        textContent="clear quote rate"
        modalRef={modalClearQuoteRateRef}
        type="info"
        icon="icon-eraser"
        textBtn="Yes"
        textBtnBack="No"
        showFail={false}
        selectedLength={selectedNotConfirmLength}
        selectedResultCount={selectedResultCount}
        onClick={onClickConfirmClearQuoteRate}
      />

      <Modal size="modal-md" ref={modalChangeRef} modalFooter>
        <form onSubmit={(e) => onSubmitModalChangeApprover(e)} className="form-horizontal">
          <div className="text-center">
            <span className="text-success icon icon-check icon-5x" />
            <h3 className="text-success">Change Approver</h3>
            <br />
            {modalChangeApprover.Mode === 'approver1' ? (
              <FormGroup3 text="Assign Approver 1" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  key="changeapprover1"
                  isChoose
                  notMultipleSelect2
                  required
                  saveLocalState
                  noValidateOption
                  filter={(m) => m.roleName === 'Approver 1'}
                  financeGroupId={modalChangeApprover.FinanceGroupId}
                  value={modalChangeApprover.Approver1}
                  onChange={(e) =>
                    onChangeModalApproveChange({
                      target: e,
                    })
                  }
                  name="Approver1"
                />
              </FormGroup3>
            ) : null}
            {modalChangeApprover.Mode === 'approver2' ? (
              <FormGroup3 text="Assign Approver 2" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  key="changeapprover2"
                  isChoose
                  notMultipleSelect2
                  required
                  saveLocalState
                  noValidateOption
                  filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                  financeGroupId={modalChangeApprover.FinanceGroupId}
                  value={modalChangeApprover.Approver2}
                  onChange={(e) =>
                    onChangeModalApproveChange({
                      target: e,
                    })
                  }
                  name="Approver2"
                />
              </FormGroup3>
            ) : null}
            {modalChangeApprover.Mode === 'reviewer' ? (
              <FormGroup3 text="Reviewer" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  key="changereviewer"
                  isChoose
                  notMultipleSelect2
                  required
                  saveLocalState
                  noValidateOption
                  filter={(m) => m.roleName === 'Reviewer'}
                  financeGroupId={modalChangeApprover.FinanceGroupId}
                  value={modalChangeApprover.Reviewer}
                  onChange={(e) =>
                    onChangeModalApproveChange({
                      target: e,
                    })
                  }
                  name="Reviewer"
                />
              </FormGroup3>
            ) : null}
            {modalChangeApprover.Mode === 'verify' ? (
              <FormGroup3 text="Verifier" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_APPROVER}
                  key="changeverifier"
                  isChoose
                  notMultipleSelect2
                  required
                  saveLocalState
                  noValidateOption
                  filter={(m) => m.roleName === 'Verifier'}
                  financeGroupId={modalChangeApprover.FinanceGroupId}
                  value={modalChangeApprover.Verifier}
                  onChange={(e) =>
                    onChangeModalApproveChange({
                      target: e,
                    })
                  }
                  name="Verifier"
                />
              </FormGroup3>
            ) : null}
            <div className="m-t-lg">
              <Button txt="Approve" className="btn-success" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
      {renderModalChangeApproverMultiTrans()}
    </div>
  );

  function renderModalChangeApproverMultiTrans() {
    return (
      <Modal size="modal-md" ref={modalChangeApproverMultiTransRef} modalFooter>
        <form onSubmit={(e) => onSubmitChangeApproverMultiTrans(e)} className="form-horizontal">
          <div className="text-center">
            <span className="text-success icon icon-check icon-5x" />
            <h3 className="text-success">Approve</h3>
            <p>
              {modalChangeApproverMultiTrans.transactionsData.length} item(s) selected, click to
              approve?
            </p>
            <div className="text-left">
              {modalChangeApproverMultiTrans.resultFail.map((m, i) => (
                <div className="form-group col-xs-12" key={i}>
                  <div className="col-xs-3 text-right">
                    <span className="text-danger icon icon-close icon-1x" />
                  </div>
                  <div className="col-xs-9">{m}</div>
                </div>
              ))}
            </div>
            <br />
            <FormGroup3 text="Approver Type" col={colX2} required>
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                required
                value={modalChangeApproverMultiTrans.ApproverType}
                onChange={(e) =>
                  onChangeModalApproverMultiTrans({
                    target: e,
                  })
                }
                options={approverTypes}
                name="ApproverType"
              />
            </FormGroup3>
            <FormGroup3 text="Approver" col={colX2} required>
              <MasterDropdown
                masterType={MASTER_APPROVER}
                updateFilterFn
                isChoose
                notMultipleSelect2
                required
                filter={(m) => {
                  const roleNameFilter = [];
                  switch (modalChangeApproverMultiTrans.ApproverType) {
                    case 'Approver2':
                      roleNameFilter.push('Approver 2');
                    case 'Approver1':
                      roleNameFilter.push('Approver 1');
                      break;
                    default:
                      roleNameFilter.push(modalChangeApproverMultiTrans.ApproverType);
                      break;
                  }
                  return roleNameFilter.includes(m.roleName);
                }}
                financeGroupId={modalChangeApproverMultiTrans.FinanceGroupId}
                value={modalChangeApproverMultiTrans.Approver}
                onChange={(e) =>
                  onChangeModalApproverMultiTrans({
                    target: e,
                  })
                }
                name="Approver"
              />
            </FormGroup3>

            <div className="m-t-lg">
              {modalChangeApproverMultiTrans.resultFail.length ? null : (
                <Button txt="Approve" className="btn-success" type="submit" />
              )}
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    );
  }
};
