import React, { Component } from 'react';

const $ = window.jQuery;
const { moment } = window;
export default class DateRangePicker extends Component {
  componentDidMount() {
    moment.locale('th', {
      longDateFormat: {
        LT: 'HH:mm',
        LTS: 'HH:mm:ss',
        L: 'DD/MM/YYYY',
        LL: 'D MMMM YYYY',
        LLL: 'D MMMM YYYY HH:mm',
        LLLL: 'dddd D MMMM YYYY HH:mm',
      },
    });

    this.$input = $(this.InputElement).daterangepicker({
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().subtract(1, 'month').startOf('month'),
          moment().subtract(1, 'month').endOf('month'),
        ],
      },
      open: 'right',
      autoApply: true,
      alwaysShowCalendars: true,
      autoUpdateInput: false,
      format: 'dd/MM/yyyy',
    });

    if (this.props.startDate && this.props.endDate) {
      this.InputElement.value = `${this.props.startDate.format('DD/MM/YYYY')} - ${this.props.endDate.format('DD/MM/YYYY')}`;
      this.onChangeHandler({
        target: {
          name: this.props.name,
          value: this.InputElement.value,
        },
      });
    }

    $(this.InputElement).on('apply.daterangepicker', (e, picker) => {
      this.InputElement.value = `${picker.startDate.format('DD/MM/YYYY')} - ${picker.endDate.format('DD/MM/YYYY')}`;
      this.onChangeHandler(e);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.value !== this.$input.val()) {
      this.$input.val(this.props.value);
    }
  }

  componentWillUnmount() {
    this.$input.off('change', this.onChangeHandler).change();
    this.$input.data('daterangepicker').container.remove();
  }

  onChangeHandler = (e) => {
    this.props.onChange(e);
  };

  render() {
    return (
      <div>
        <input
          type="text"
          autoComplete="off"
          onChange={this.props.onChange}
          className="form-control pull-right"
          ref={(el) => (this.InputElement = el)}
          name={this.props.name}
          required={this.props.required}
          disabled={this.props.disabled}
        />
      </div>
    );
  }
}
