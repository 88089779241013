import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import RadioButton from '../../common/RadioButton';
import MasterDropdownUI, {
  OPTION_FREQUENCY,
  OPTION_WORKING_DAY,
} from '../../common/MasterDropdownUI';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ formRef, state, onChangeInputData, template = [], viewOnly }) => {
  const disabled = false;
  const required = true;
  const { IsThaiSweep } = state;
  const setPeriodEndType = state.PeriodEndType !== 'End of Term';
  const setDueDateType = state.DueDateType !== 'End of Term';
  return (
    <form ref={formRef} name="interest">
      {state.Source === 'PN' || state.Source === 'Deposit' ? (
        <Card>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Template Structure Name" required={false}>
                <select
                  tabIndex="-1"
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={viewOnly || IsThaiSweep}
                  value={state.InterestRateId}
                  name="InterestRateId"
                >
                  <option value="">---Choose---</option>
                  {template.map((m) => (
                    <option value={m.interestRateId} key={m.interestRateId}>
                      {m.interestTemplateName}
                    </option>
                  ))}
                </select>
              </FormGroup2>

              <FormGroup2 text="Interest Rate (%)" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={{
                    prefix: '',
                    digits: 5,
                    digitsOptional: false,
                    placeholder: '0.00000',
                    max: 100,
                    min: 0,
                  }}
                  required={required}
                  disabled={viewOnly}
                  value={state.InterestRate}
                  name="InterestRate"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Cal. Method" required>
                <MasterDropdownUI
                  onChange={onChangeInputData}
                  isChoose
                  notMultiple
                  required={required}
                  disabled={viewOnly || IsThaiSweep}
                  value={state.CalculateMethod}
                  name="CalculateMethod"
                  options={['Act/360', 'Act/365', 'Act/366', 'Actual']}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </Card>
      ) : (
        <Card>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Template Structure Name" required={false}>
                <select
                  tabIndex="-1"
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={viewOnly || IsThaiSweep}
                  value={state.InterestRateId}
                  name="InterestRateId"
                >
                  <option value="">---Choose---</option>
                  {template.map((m) => (
                    <option value={m.interestRateId} key={m.interestRateId}>
                      {m.interestTemplateName}
                    </option>
                  ))}
                </select>
              </FormGroup2>

              <FormGroup2 text="Cal. Method" required>
                <MasterDropdownUI
                  onChange={onChangeInputData}
                  isChoose
                  notMultiple
                  required={required}
                  disabled={viewOnly || IsThaiSweep}
                  value={state.CalculateMethod}
                  name="CalculateMethod"
                  options={['Act/360', 'Act/365', 'Act/366', 'Actual']}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </Card>
      )}

      {viewOnly || IsThaiSweep ? (
        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Period End" required>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="PeriodEndType"
                      value={state.PeriodEndType}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Include" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="IsManualInclude"
                      value={state.IsManualInclude ? 'Yes' : 'No'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Period End" required={setPeriodEndType}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualPeriodEnd"
                      value={state.ManualPeriodEnd || '-'}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Day" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualDay"
                      value={state.ManualDay || '-'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Working Day" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="ManualWorkingDay"
                    value={state.ManualWorkingDay || ''}
                    readOnly
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="DueDateType"
                    value={
                      state.DueDateType === 'End of Term'
                        ? 'End of Term (Shift Back to Term End)'
                        : state.DueDateType
                    }
                    readOnly
                  />
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="ManualDueDate"
                      value={state.ManualDueDate || '-'}
                      readOnly
                    />
                  </FormGroup2>
                  <FormGroup2 text="Day" required={false}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      name="DueDateDay"
                      value={state.DueDateDay || '-'}
                      readOnly
                    />
                  </FormGroup2>
                </Col2>
              </Col2>

              <Col2 col={colX[1]}>
                <FormGroup2 text="Frequency Month(s)" required={false}>
                  <input
                    className="form-control"
                    name="Frequency"
                    defaultValue={state.Frequency || ''}
                    readOnly
                  />
                </FormGroup2>

                <FormGroup2 text="Working Day" required>
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    name="DueDateWorkingDay"
                    value={state.DueDateWorkingDay || ''}
                    readOnly
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>
      ) : (
        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Period End" required>
                  <div>
                    <RadioButton
                      text="End of Term"
                      value="End of Term"
                      checked={state.PeriodEndType === 'End of Term'}
                      onChange={onChangeInputData}
                      name="PeriodEndType"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Period End"
                      value="Manual Period End"
                      checked={state.PeriodEndType === 'Manual Period End'}
                      onChange={onChangeInputData}
                      name="PeriodEndType"
                    />
                    <label className="custom-control">
                      <div>
                        <input
                          id="IsManualInclude"
                          type="checkbox"
                          className="label-checkbox"
                          onChange={onChangeInputData}
                          name="IsManualInclude"
                          checked={state.IsManualInclude}
                          disabled={disabled}
                        />
                        <label htmlFor="IsManualInclude" className="label_checkbox" />
                        <label
                          htmlFor="IsManualInclude"
                          className="control-label text_label_checkbox"
                        >
                          Include
                        </label>
                      </div>
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualPeriodEnd === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEnd"
                      disabled={!setPeriodEndType}
                      required={setPeriodEndType}
                    />

                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualPeriodEnd === 'Day'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEnd"
                      disabled={!setPeriodEndType}
                    />

                    <label className="custom-control">
                      {state.ManualPeriodEnd === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualPeriodEnd === 'Day'}
                          disabled={state.ManualPeriodEnd !== 'Day'}
                          value={state.ManualDay}
                          name="ManualDay"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.ManualDay || ''}
                          name="ManualDay"
                          required={state.ManualPeriodEnd === 'Day'}
                          disabled={state.ManualPeriodEnd !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-8">
                <FormGroup2 text="Working Day" required>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.ManualWorkingDay}
                    name="ManualWorkingDay"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required>
                  <div>
                    <RadioButton
                      text="End of Term (Shift Back to Term End)"
                      value="End of Term"
                      checked={state.DueDateType === 'End of Term'}
                      onChange={onChangeInputData}
                      name="DueDateType"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Due Date"
                      value="Manual Due Date"
                      checked={state.DueDateType === 'Manual Due Date'}
                      onChange={onChangeInputData}
                      name="DueDateType"
                    />
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualDueDate === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualDueDate"
                      disabled={!setDueDateType}
                      required={setDueDateType}
                    />
                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualDueDate === 'Day'}
                      onChange={onChangeInputData}
                      disabled={!setDueDateType}
                      name="ManualDueDate"
                    />

                    <label className="custom-control">
                      {state.ManualDueDate === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualDueDate === 'Day'}
                          disabled={state.ManualDueDate !== 'Day'}
                          value={state.DueDateDay}
                          name="DueDateDay"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.DueDateDay || ''}
                          name="DueDateDay"
                          required={state.ManualDueDate === 'Day'}
                          disabled={state.ManualDueDate !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-6">
                <FormGroup2 text="Frequency Month(s)" required={false}>
                  <MasterDropdownUI
                    masterType={OPTION_FREQUENCY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    disabled={disabled}
                    value={state.Frequency}
                    name="Frequency"
                  />
                </FormGroup2>
                <FormGroup2 text="Working Day" required>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.DueDateWorkingDay}
                    name="DueDateWorkingDay"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>
      )}
    </form>
  );
};
