import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_GL_ACCOUNT_CRITERIA = 'EDIT_MASTER_GL_ACCOUNT_CRITERIA';
export const CLEAR_MASTER_GL_ACCOUNT_CRITERIA = 'CLEAR_MASTER_GL_ACCOUNT_CRITERIA';

export const REQUEST_SEARCH_MASTER_GL_ACCOUNT = '@@request/SEARCH_MASTER_GL_ACCOUNT';
export const RECEIVE_SEARCH_MASTER_GL_ACCOUNT = '@@receive/SEARCH_MASTER_GL_ACCOUNT';
export const FAIL_SEARCH_MASTER_GL_ACCOUNT = '@@fail/SEARCH_MASTER_GL_ACCOUNT';

export const REQUEST_SAVE_MASTER_GL_ACCOUNT = '@@request/SAVE_MASTER_GL_ACCOUNT';
export const RECEIVE_SAVE_MASTER_GL_ACCOUNT =
  '@@receive-The record was successfully saved/SAVE_MASTER_GL_ACCOUNT';
export const FAIL_SAVE_MASTER_GL_ACCOUNT = '@@fail/SAVE_MASTER_GL_ACCOUNT';

export const saveMasterGlAccount = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveMasterGlAccount`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_GL_ACCOUNT,
      RECEIVE_SAVE_MASTER_GL_ACCOUNT,
      FAIL_SAVE_MASTER_GL_ACCOUNT,
    ],
  },
});

export const searchMasterGlAccount = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/GetMasterGlAccount', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_GL_ACCOUNT,
      RECEIVE_SEARCH_MASTER_GL_ACCOUNT,
      FAIL_SEARCH_MASTER_GL_ACCOUNT,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_GL_ACCOUNT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_GL_ACCOUNT_CRITERIA,
  payload: {
    name,
    value,
  },
});
