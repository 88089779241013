import { connect } from 'react-redux';
import React, { Component } from 'react';

import FinanceGroup from './FinanceGroup';
import {
  editCriteria,
  clearCriteria,
  updateSearchResult,
  searchMasterFinanceGroup,
  deleteMasterFinanceGroup,
} from '../../reducers/masterFinanceGroup/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-finance-group';

const initChangeStatus = {
  FinanceGroupId: '',
};

class FinanceGroupContainer extends Component {
  state = {
    modalChangeStatus: {
      ...initChangeStatus,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMasterFinanceGroup({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalChangeStatus: {
        ...initChangeStatus,
        FinanceGroupId: rowData.financeGroupId,
        modal,
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const submitData = {
      financeGroupId: this.state.modalChangeStatus.FinanceGroupId,
      bankGroup: [],
    };
    this.props.deleteMasterFinanceGroup(submitData).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMasterFinanceGroup({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <div>
        <FinanceGroup
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterFinanceGroup,
  }),
  {
    searchMasterFinanceGroup,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    deleteMasterFinanceGroup,
  }
)(FinanceGroupContainer);
