import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import Layout from './pages/layout';
import { pingTest, setPageName } from './reducers/layout/action';

class App extends Component {
  state = {
    isAuthenticated: true,
    hadAuthenticate: true,
    allowPage: true,
  };

  ignore = false;

  componentDidMount() {
    this.props.setPageName(this.props.page);
  }

  componentWillUnmount() {
    this.ignore = true;
  }

  componentDidUpdate(prevProps) {
    const { page, setPageName } = this.props;
    if (page !== prevProps.page) {
      setPageName(page);
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { hadAuthenticate, isAuthenticated, allowPage } = this.state;
    if (isAuthenticated) {
      if (allowPage)
        return (
          <Layout>
            <Component {...rest} />
          </Layout>
        );
      if (allowPage === false) {
        // anti redirect multiple time
        if (this.props.routeProp.match.path !== '/403') return <Redirect to="/403" />;
        return <h1>Redirecting...</h1>;
      }
      return <h1>Checking Page Permission...</h1>;
    }
    if (hadAuthenticate) return <h1>Authentication Failed. ({this.state.error || ''})</h1>;
    return <h1>Authenticating...</h1>;
  }
}

export default connect(
  (state) => ({
    layout: state.layout,
  }),
  {
    setPageName,
    pingTest,
  }
)(App);
