import {
  EDIT_MASTER_PAYMENT_METHOD_CRITERIA,
  RECEIVE_SEARCH_MASTER_PAYMENT_METHOD,
  CLEAR_MASTER_PAYMENT_METHOD_CRITERIA,
} from './action';

const initCriteria = {
  MasterCompanyCode: [''],
  MemberCompanyCode: [''],
  IsActive: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
};

export default (state = init, action) => {
  switch (action.type) {
    case CLEAR_MASTER_PAYMENT_METHOD_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MASTER_PAYMENT_METHOD_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_MASTER_PAYMENT_METHOD:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
