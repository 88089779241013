import { connect } from 'react-redux';
import React, { Component } from 'react';

import BankAccount from './BankAccountOfBank';
import {
  editCriteria,
  clearCriteria,
  updateSearchResult,
  searchMasterBankAccount,
} from '../../../reducers/masterBankAccountOfBank/action';

export const DATATABLE_ID = 'table-result-bank-account-of-bank';

class BankAccountOfBankContainer extends Component {
  state = {};

  dataTableSearchResultRef = null;

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMasterBankAccount({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
    };

    return (
      <div>
        <BankAccount
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterBankAccountOfBank,
  }),
  {
    searchMasterBankAccount,
    editCriteria,
    clearCriteria,
    updateSearchResult,
  }
)(BankAccountOfBankContainer);
