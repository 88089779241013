import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import { DATATABLE_ID } from './BankAccountOfBankContainer';
import { MAIN_MASTER_BANK } from '../../../reducers/master/action';
import { toStatus, toNumber, toBankCode, getPermission } from '../../../common/helpper';

const permission = getPermission('Master Finance', 'Bank Account of Bank');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  deferRender: false,
  scroller: true,
  paging: true,
  order: [[0, 'asc']],
  fixedColumns: {
    leftColumns: 1,
  },
  columns: [
    {
      data: 'bankAccountNo',
      type: 'string',
      render: (d, t, r) => {
        if (t === 'export') return d;
        return `<a target="_blank" href="/master/bank-account-of-bank-detail/${d}">${d}</a>`;
      },
    },
    { data: 'bankAccountNoOfBank' },
    { data: 'bankCode', className: 'text-center' },
    { data: 'bankAbbreviate', render: (d, t, r) => toBankCode(null, d, r.bankName) },
    { data: 'country', className: 'text-center' },
    { data: 'currency', className: 'text-center' },
    {
      data: 'overDraft',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'houseBank', className: 'text-center' },
    { data: 'accountTypeCode', className: 'text-center' },
    { data: 'branchCode', className: 'text-center' },
    { data: 'branchName' },
    { data: 'glAcctBal', className: 'text-center' },
    { data: 'glAcctDep', className: 'text-center' },
    { data: 'glAcctPay', className: 'text-center' },
    // { data: 'remark' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (r.approvalStatus === 'Waiting for Approve') return toStatus('Wait for Approve', true);
        if (r.approvalStatus === 'Rejected') return toStatus('rejected');
        return d ? toStatus('active') : toStatus('inactive');
      },
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Master Finance <span className="icon icon-angle-double-right" /> Bank Account of Bank
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Bank" required={false}>
              <MasterDropdown
                masterType={MAIN_MASTER_BANK}
                onChange={onChangeSelect2}
                value={criteria.BankCode}
                companyCode={criteria.CompanyCode}
                name="BankCode"
              />
            </FormGroup2>

            <FormGroup2 text="Bank Account No.">
              <input
                className="form-control"
                value={criteria.SearchBankAccountNo}
                onChange={onChangeSearch}
                name="SearchBankAccountNo"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Status">
              <select
                className="form-control"
                value={criteria.Status}
                onChange={onChangeSearch}
                name="Status"
              >
                <option value={null}>---All---</option>
                <option value="Waiting for Approve">Waiting for Approve</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Rejected">Rejected</option>
              </select>
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Link
                  className="btn btn-warning btn-sm btn-labeled"
                  to="/master/bank-account-of-bank-detail/create"
                  target="_blank"
                >
                  <span className="btn-label">
                    <i className="icon icon-plus-circle icon-fw icon-lg" />
                  </span>
                  CREATE BANK ACCOUNT
                </Link>
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
        customize={customizeExportDt}
      >
        <thead>
          <tr>
            <th>Account No.</th>
            <th>
              Account No.
              <br />
              of Bank
            </th>
            <th>Swift Code</th>
            <th>Bank</th>
            <th>Country</th>
            <th>Currency</th>
            <th>OD</th>
            <th>House Bank</th>
            <th>Account ID</th>
            <th>Branch No.</th>
            <th>Branch Name</th>
            <th>
              GL Account
              <br /> Balance
            </th>
            <th>
              GL Account
              <br /> Deposit
            </th>
            <th>
              GL Account
              <br /> Pay
            </th>
            {/* <th>Remark</th> */}
            <th>Status</th>
            <th>
              Created
              <br /> By
            </th>
            <th>
              Created
              <br /> Date
            </th>
            <th>
              Updated
              <br /> By
            </th>
            <th>
              Updated
              <br /> Date
            </th>
          </tr>
        </thead>
      </Table>
    </Card>
  </div>
);

const $ = window.jQuery;
const customizeExportDt = (xlsx) => {
  const sheet = xlsx.xl.worksheets['sheet1.xml'];
  $('row c:first-child', sheet)
    .slice(1)
    .each((i, m) => {
      const $m = $(m);
      $m.attr('s', 0);
      $m.attr('t', 'inlineStr');
      const $first = $(':first', m);
      if ($first.length && $first[0].tagName === 'v') {
        const str = $first.eq(0).text();
        $m.html(`<is><t>${str}</t></is>`);
      }
    });
};
