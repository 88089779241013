import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';

export const REQUEST_GET_NOTIFICATION = '@@request-noload/GET_NOTIFICATION';
export const RECEIVE_GET_NOTIFICATION = '@@receive/GET_NOTIFICATION';
export const FAIL_GET_NOTIFICATION = '@@fail/GET_NOTIFICATION';

export const getNotification = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/notification`),
    method: 'GET',
    types: [REQUEST_GET_NOTIFICATION, RECEIVE_GET_NOTIFICATION, FAIL_GET_NOTIFICATION],
  },
});
