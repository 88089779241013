import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import { DATATABLE_ID } from './CurrencyContainer';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_COA } from '../../reducers/master/action';
import { toStatus, getPermission } from '../../common/helpper';

const permission = getPermission('Master Accounting', 'GL Account');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-4', 'col-md-6'];

const opt = {
  order: [[2, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'coa', className: 'text-center' },
    { data: 'glAccount', className: 'text-center' },
    { data: 'shortText' },
    { data: 'longText' },
    { data: 'openItem', className: 'text-center', render: (d) => (d ? 'Yes' : 'No') },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Action === 'edit';
  const checkSave = !!((modalData.Action === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Accounting <span className="icon icon-angle-double-right" /> GL Account
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Chart of Account">
                <MasterDropdown
                  masterType={MASTER_COA}
                  onChange={onChangeSelect2}
                  value={criteria.Coa}
                  name="Coa"
                />
              </FormGroup2>

              <FormGroup2 text="GL Account">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.GlAccount}
                  name="GlAccount"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Short Text">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.ShortText}
                  name="ShortText"
                />
              </FormGroup2>

              <FormGroup2 text="Long Text">
                <input
                  className="form-control"
                  onChange={onChangeSearch}
                  value={criteria.LongText}
                  name="LongText"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Open Item">
                <select
                  className="form-control"
                  value={criteria.OpenItem}
                  onChange={onChangeSearch}
                  name="OpenItem"
                >
                  <option value="">---All---</option>
                  <option value>Yes</option>
                  <option value={false}>No</option>
                </select>
              </FormGroup2>

              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.IsActive}
                  onChange={onChangeSearch}
                  name="IsActive"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="Create GL Account"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalData}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Chart of Account</th>
              <th>GL Account</th>
              <th>Short Text</th>
              <th>Long Text</th>
              <th>Open Item</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal
        textHeader="Master GL Account"
        bgHeader="bg-primary"
        size="modal-md"
        ref={modalDataRef}
      >
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <FormGroup3 text="Chart of Account" col={colX1} required>
            {modeEdit ? (
              <input
                className="form-control"
                onChange={onChangeModalData}
                value={modalData.Coa || ''}
                required
                readOnly={modeEdit}
                name="Coa"
              />
            ) : (
              <MasterDropdown
                masterType={MASTER_COA}
                onChange={(e) =>
                  onChangeModalData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                required
                isActive
                value={modalData.Coa}
                name="Coa"
              />
            )}
          </FormGroup3>

          <FormGroup3 text="GL Account" col={colX1} required>
            <input
              className="form-control"
              onChange={onChangeModalData}
              value={modalData.GlAccount || ''}
              required
              readOnly={modeEdit}
              name="GlAccount"
            />
          </FormGroup3>

          <FormGroup3 text="Short Text" col={colX1} required>
            <textarea
              className="form-control"
              rows="2"
              onChange={onChangeModalData}
              value={modalData.ShortText || ''}
              required
              // readOnly={modeEdit}
              name="ShortText"
            />
          </FormGroup3>

          <FormGroup3 text="Long Text" col={colX1} required>
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeModalData}
              value={modalData.LongText || ''}
              required
              // readOnly={modeEdit}
              name="LongText"
            />
          </FormGroup3>

          <FormGroup3 text="Open Item" col={colX1} required>
            <select
              className="form-control"
              value={modalData.OpenItem}
              onChange={onChangeModalData}
              required
              // readOnly={modeEdit}
              name="OpenItem"
            >
              <option value="">---Choose---</option>
              <option value>Yes</option>
              <option value={false}>No</option>
            </select>
          </FormGroup3>

          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
