import React from 'react';

import Interest from './Interest';
import CashFlow from '../TabCashFlow';
import Tab from '../../../common/Tab';
import Stucture from '../TabStructure';
import ModalSplit from '../ModalSplit';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Link from '../../../common/Link';
import ModalCharge from '../ModalCharge';
import ModalInterest from './ModalInterest';
import Button from '../../../common/Button';
import Administrator from '../TabAdministrator';
import { toLower } from '../../../common/helpper';
import FormGroup2 from '../../../common/FormGroup2';
import SpanStatus from '../../../common/SpanStatus';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { MASTER_FINANCE, MASTER_COMPANY, MASTER_HOLIDAY } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onChangeSelect2,
  onSubmit,
  template = [],
  facilityList = [],

  onChangeInputTable,
  onClickGetCashflow,
  onChangeInputHeader,

  modalInterest,
  modalInterestRef,
  onClickOpenModalInterest,
  onChangeModalInterest,
  onSubmitModalInterest,
  onClickOpenModalDelete,

  modalCharge,
  modalChargeRef,
  onClickOpenModalCharge,
  onChangeModalCharge,
  onSubmitModalCharge,

  modalDeleteRef,
  onClickConfirmModalDeleteCashFlow,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,

  modalSplitRef,
  onChangeModalSplit,
  onSubmitModalSplit,

  modalUndoSplitRef,
  onSubmitModalUndoSplit,

  formDetailRef,
  formStructorRef,
  formInterestRef,
  formAdministratorRef,
  formCashFlowLoanRef,
  formCashFlowDepositRef,
  formResultCashFlowLoanRef,
  formResultCashFlowDepositRef,

  tabRef,
}) => {
  const header =
    state.mode === 'edit' ? `Transaction No.: ${state.TransactionNo}` : 'Create Contract On Call';
  const noEdit = state.mode === 'edit';
  const viewOnly =
    state.mode === 'create' ? false : !!(!state.permission || toLower(state.Status) !== 'active');
  const required = true;

  const tabContents = [];
  // ------------------ Tab Structure ----------------------
  tabContents.push({
    header: <span>Structure</span>,
    content: (
      <Stucture
        formRef={formStructorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        viewOnly={viewOnly}
      />
    ),
    tabName: 'structure',
  });

  // ------------------ Tab Interest ----------------------
  tabContents.push({
    header: <span>Interest</span>,
    content: (
      <Interest
        formRef={formInterestRef}
        template={template}
        state={state}
        onChangeInputData={onChangeInputData}
        onClickOpenModalInterest={onClickOpenModalInterest}
        onClickOpenModalDelete={onClickOpenModalDelete}
        viewOnly={viewOnly}
      />
    ),
    tabName: 'interest',
  });

  // ------------------ Tab Administrator ----------------------
  tabContents.push({
    header: <span>Administrator</span>,
    content: (
      <Administrator
        formRef={formAdministratorRef}
        state={state}
        facilityList={facilityList}
        onChangeInputData={onChangeInputData}
        viewOnly={viewOnly}
      />
    ),
    tabName: 'administrator',
  });

  // ------------------ Tab Cash Flow ----------------------
  tabContents.push({
    header: <span>Cash Flow</span>,
    content: (
      <CashFlow
        formCashFlowLoanRef={formCashFlowLoanRef}
        formCashFlowDepositRef={formCashFlowDepositRef}
        formResultCashFlowLoanRef={formResultCashFlowLoanRef}
        formResultCashFlowDepositRef={formResultCashFlowDepositRef}
        state={state}
        onChangeInputData={onChangeInputData}
        onChangeInputTable={onChangeInputTable}
        onClickGetCashflow={onClickGetCashflow}
        onChangeInputHeader={onChangeInputHeader}
        onClickOpenModalCharge={onClickOpenModalCharge}
        viewOnly={viewOnly}
        onChangeSelect2={onChangeSelect2}
      />
    ),
    tabName: 'cashflow',
  });

  // save btn
  const canSave =
    state.mode === 'create' || ['rollover', 'repay'].includes((state.Status || '').toLowerCase())
      ? false
      : viewOnly;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Funding & Investment <span className="icon icon-angle-double-right" />
            <Link txt="Create Contract on Call" href="/loan-investment/create-contract-on-call" />
            <span className="icon icon-angle-double-right" /> Contract on Call
            {noEdit ? (
              <>
                {' '}
                <span className="icon icon-angle-double-right" /> {state.TransactionNo}{' '}
                <span className="icon icon-angle-double-right" />{' '}
                <SpanStatus status={state.Status} />
              </>
            ) : null}
          </small>
        </p>
      </div>

      <Card textHeader={header} bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onSubmit} name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  noValidateOption={noEdit}
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required={required}
                  disabled={noEdit}
                  value={state.FinanceGroupId}
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Company" required>
                {state.FinanceGroupId ? (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption={noEdit}
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    notMultiple={false}
                    required={required}
                    disabled={noEdit}
                    financeGroupId={state.FinanceGroupId}
                    value={state.CompanyCode}
                    name="CompanyCode"
                  />
                ) : (
                  <MasterDropdownUI
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    value={state.CompanyCode}
                    name="CompanyCode"
                    options={null}
                  />
                )}
              </FormGroup2>
            </Col2>

            <Col2 col={colX[0]}>
              <div className="form-group">
                <div>
                  <input
                    id="IsProject"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeInputData}
                    name="IsProject"
                    checked={state.IsProject}
                  />
                  <label htmlFor="IsProject" className="label_checkbox" />
                  <label htmlFor="IsProject" className="control-label text_label_checkbox">
                    Project
                  </label>
                </div>
              </div>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Calendar" required={false}>
                <MasterDropdown
                  masterType={MASTER_HOLIDAY}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  noValidateOption
                  saveLocalState
                  status
                  isChoose
                  disabled={viewOnly}
                  notMultipleSelect2
                  value={state.CalendarId}
                  name="CalendarId"
                />
              </FormGroup2>

              <FormGroup2
                text="Remark"
                required={false}
                maxLength={2000}
                length={(state.Remark || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  disabled={viewOnly}
                  value={state.Remark || ''}
                  readOnly={noEdit}
                  maxLength={2000}
                  name="Remark"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !state.permission || canSave ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={canSave}
            />
          )
        }
      />

      <ModalCharge
        text={modalCharge.Source}
        modalRef={modalChargeRef}
        state={modalCharge}
        onSubmit={onSubmitModalCharge}
        onChageModal={onChangeModalCharge}
      />

      <ModalInterest
        modalRef={modalInterestRef}
        state={modalInterest}
        onSubmit={onSubmitModalInterest}
        onChageModal={onChangeModalInterest}
      />

      <ModalFunction
        textHeader="Delete"
        textContent="a Delete"
        modalRef={modalDeleteRef}
        type="danger"
        icon="icon-trash-o"
        textBtn="Delete"
        noSelect
        onClick={(e) => onClickConfirmModalDeleteCashFlow(e)}
      />

      <ModalSplit
        modalRef={modalSplitRef}
        state={state.modalSplit}
        onSubmit={onSubmitModalSplit}
        onChageModal={onChangeModalSplit}
      />

      <ModalFunction
        textHeader="Undo Split"
        textContent="an Undo Split"
        modalRef={modalUndoSplitRef}
        type="brown"
        icon="icon-undo"
        textBtn="Confirm"
        noSelect
        onClick={(e) => onSubmitModalUndoSplit(e)}
      />

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
