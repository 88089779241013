import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_TT_REMITTANCE = '@@request/SEARCH_TT_REMITTANCE';
export const RECEIVE_SEARCH_TT_REMITTANCE = '@@receive/SEARCH_TT_REMITTANCE';
export const FAIL_SEARCH_TT_REMITTANCE = '@@fail/SEARCH_TT_REMITTANCE';

export const UPDATE_SEARCH_RESULT_TT_REMITTANCE = 'UPDATE_SEARCH_RESULT_TT_REMITTANCE';

export const REQUEST_UPDATE_TT_REMITTANCE = '@@request/UPDATE_TT_REMITTANCE';
export const RECEIVE_UPDATE_TT_REMITTANCE =
  '@@receive-The transaction was successfully saved/UPDATE_TT_REMITTANCE';
export const FAIL_UPDATE_TT_REMITTANCE = '@@fail/UPDATE_TT_REMITTANCE';

export const REQUEST_SPLIT_TT_REMITTANCE = '@@request/SPLIT_TT_REMITTANCE';
export const RECEIVE_SPLIT_TT_REMITTANCE =
  '@@receive-The transaction was successfully saved/SPLIT_TT_REMITTANCE';
export const FAIL_SPLIT_TT_REMITTANCE = '@@fail/SPLIT_TT_REMITTANCE';

export const REQUEST_UNDO_SPLIT_TT_REMITTANCE = '@@request/UNDO_SPLIT_TT_REMITTANCE';
export const RECEIVE_UNDO_SPLIT_TT_REMITTANCE =
  '@@receive-The transaction was successfully saved/UNDO_SPLIT_TT_REMITTANCE';
export const FAIL_UNDO_SPLIT_TT_REMITTANCE = '@@fail/UNDO_SPLIT_TT_REMITTANCE';

export const REQUEST_CHANGE_STATUS_TT_REMITTANCE = '@@request/CHANGE_STATUS_TT_REMITTANCE';
export const RECEIVE_CHANGE_STATUS_TT_REMITTANCE =
  '@@receive-The transaction was successfully change status/CHANGE_STATUS_TT_REMITTANCE';
export const FAIL_CHANGE_STATUS_TT_REMITTANCE = '@@fail/CHANGE_STATUS_TT_REMITTANCE';

export const REQUEST_SAVE_ATTACH_FILE_TT_REMITTANCE = '@@request/SAVE_ATTACH_FILE_TT_REMITTANCE';
export const RECEIVE_SAVE_ATTACH_FILE_TT_REMITTANCE =
  '@@receive-The transaction was successfully attach file/SAVE_ATTACH_FILE_TT_REMITTANCE';
export const FAIL_SAVE_ATTACH_FILE_TT_REMITTANCE = '@@fail/SAVE_ATTACH_FILE_TT_REMITTANCE';

export const REQUEST_MERGE_TT_REMITTANCE = '@@request/MERGE_TT_REMITTANCE';
export const RECEIVE_MERGE_TT_REMITTANCE =
  '@@receive-The transaction was successfully saved/MERGE_TT_REMITTANCE';
export const FAIL_MERGE_TT_REMITTANCE = '@@fail/MERGE_TT_REMITTANCE';

export const REQUEST_GET_TT_REMITTANCE = '@@request/GET_TT_REMITTANCE';
export const RECEIVE_GET_TT_REMITTANCE = '@@receive/GET_TT_REMITTANCE';
export const FAIL_GET_TT_REMITTANCE = '@@fail/GET_TT_REMITTANCE';

export const REQUEST_EXPORT_PDF_TT_REMITTANCE = '@@request/EXPORT_PDF_TT_REMITTANCE';
export const RECEIVE_EXPORT_PDF_TT_REMITTANCE = '@@receive/EXPORT_PDF_TT_REMITTANCE';
export const FAIL_EXPORT_PDF_TT_REMITTANCE = '@@fail/EXPORT_PDF_TT_REMITTANCE';

export const REQUEST_GET_TT_REMITTANCE_ATTACH_FILE = '@@request/GET_TT_REMITTANCE_ATTACH_FILE';
export const RECEIVE_GET_TT_REMITTANCE_ATTACH_FILE = '@@receive/GET_TT_REMITTANCE_ATTACH_FILE';
export const FAIL_GET_TT_REMITTANCE_ATTACH_FILE = '@@fail/GET_TT_REMITTANCE_ATTACH_FILE';

export const REQUEST_GET_TT_REMITTANCE_FORWARD = '@@request/GET_TT_REMITTANCE_FORWARD';
export const RECEIVE_GET_TT_REMITTANCE_FORWARD = '@@receive/GET_TT_REMITTANCE_FORWARD';
export const FAIL_GET_TT_REMITTANCE_FORWARD = '@@fail/GET_TT_REMITTANCE_FORWARD';

export const REQUEST_SAVE_TT_REMITTANCE_FORWARD = '@@request/SAVE_TT_REMITTANCE_FORWARD';
export const RECEIVE_SAVE_TT_REMITTANCE_FORWARD = '@@receive/SAVE_TT_REMITTANCE_FORWARD';
export const FAIL_SAVE_TT_REMITTANCE_FORWARD = '@@fail/SAVE_TT_REMITTANCE_FORWARD';

export const REQUEST_VIEW_UPLOAD_TT_REMITTANCE = '@@request/VIEW_UPLOAD_TT_REMITTANCE';
export const RECEIVE_VIEW_UPLOAD_TT_REMITTANCE = '@@receive/VIEW_UPLOAD_TT_REMITTANCE';
export const FAIL_VIEW_UPLOAD_TT_REMITTANCE = '@@fail/VIEW_UPLOAD_TT_REMITTANCE';

export const REQUEST_SAVE_UPLOAD_TT_REMITTANCE = '@@request/SAVE_UPLOAD_TT_REMITTANCE';
export const RECEIVE_SAVE_UPLOAD_TT_REMITTANCE =
  '@@receive-The transaction was successfully imported/SAVE_UPLOAD_TT_REMITTANCE';
export const FAIL_SAVE_UPLOAD_TT_REMITTANCE = '@@fail/SAVE_UPLOAD_TT_REMITTANCE';

export const REQUEST_GET_EXPOSURE_ATTACH_FILE = '@@request/GET_EXPOSURE_ATTACH_FILE';
export const RECEIVE_GET_EXPOSURE_ATTACH_FILE = '@@receive/GET_EXPOSURE_ATTACH_FILE';
export const FAIL_GET_EXPOSURE_ATTACH_FILE = '@@fail/GET_EXPOSURE_ATTACH_FILE';

export const REQUEST_SAVE_TT_REMITTANCE_ATTACH_FILE = '@@request/SAVE_TT_REMITTANCE_ATTACH_FILE';
export const RECEIVE_SAVE_TT_REMITTANCE_ATTACH_FILE = '@@receive/SAVE_TT_REMITTANCE_ATTACH_FILE';
export const FAIL_SAVE_TT_REMITTANCE_ATTACH_FILE = '@@fail/SAVE_TT_REMITTANCE_ATTACH_FILE';

export const REQUEST_DOWNLOAD_EXPOSURE_ATTACH_FILE = '@@request/DOWNLOAD_EXPOSURE_ATTACH_FILE';
export const RECEIVE_DOWNLOAD_EXPOSURE_ATTACH_FILE = '@@receive/DOWNLOAD_EXPOSURE_ATTACH_FILE';
export const FAIL_DOWNLOAD_EXPOSURE_ATTACH_FILE = '@@fail/DOWNLOAD_EXPOSURE_ATTACH_FILE';

export const EDIT_TT_REMITTANCE_CRITERIA = 'EDIT_TT_REMITTANCE_CRITERIA';
export const CLEAR_TT_REMITTANCE_CRITERIA = 'CLEAR_TT_REMITTANCE_CRITERIA';

export const REQUEST_TT_REMITTANCE_MANUAL = '@@request/TT_REMITTANCE_MANUAL';
export const RECEIVE_TT_REMITTANCE_MANUAL = '@@receive/TT_REMITTANCE_MANUAL';
export const FAIL_TT_REMITTANCE_MANUAL = '@@fail/TT_REMITTANCE_MANUAL';

export const REQUEST_SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST =
  '@@request/SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST';
export const RECEIVE_SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST =
  '@@receive/SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST';
export const FAIL_SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST =
  '@@fail/SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST';

export const REQUEST_CLEAR_QUOTE_RATE = '@@request/CLEAR_QUOTE_RATE';
export const RECEIVE_CLEAR_QUOTE_RATE =
  '@@receive-The transaction was successfully clear quote rate/CLEAR_QUOTE_RATE';
export const FAIL_CLEAR_QUOTE_RATE = '@@fail/CLEAR_QUOTE_RATE';

export const clearQuoteRate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/SaveClearQuoteRate'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [REQUEST_CLEAR_QUOTE_RATE, RECEIVE_CLEAR_QUOTE_RATE, FAIL_CLEAR_QUOTE_RATE],
  },
});

export const saveAttachFilesRequest = (form, transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/SaveTtRequestAttachFile/${transactionNo}`),
    method: 'POST',
    body: form,
    types: [
      REQUEST_SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST,
      RECEIVE_SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST,
      FAIL_SAVE_TT_REMITTANCE_ATTACH_FILE_REQUEST,
    ],
  },
});

export const saveTtRemittanceManual = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/savettremittancemanual'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_TT_REMITTANCE_MANUAL, RECEIVE_TT_REMITTANCE_MANUAL, FAIL_TT_REMITTANCE_MANUAL],
  },
});

export const saveAttachFiles = (form, transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/SaveExposureAttachFile/${transactionNo}`),
    method: 'POST',
    body: form,
    types: [
      REQUEST_SAVE_TT_REMITTANCE_ATTACH_FILE,
      RECEIVE_SAVE_TT_REMITTANCE_ATTACH_FILE,
      FAIL_SAVE_TT_REMITTANCE_ATTACH_FILE,
    ],
  },
});

export const getAtatchfileList = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(
      `/ttremittance/GetExposureAttachFile?transactionNo=${encodeURIComponent(transactionNo)}`
    ),
    method: 'GET',
    types: [
      REQUEST_GET_EXPOSURE_ATTACH_FILE,
      RECEIVE_GET_EXPOSURE_ATTACH_FILE,
      FAIL_GET_EXPOSURE_ATTACH_FILE,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_TT_REMITTANCE_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_TT_REMITTANCE_CRITERIA,
  payload: {
    name,
    value,
  },
});

export const viewImportExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/TTRemittance'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_VIEW_UPLOAD_TT_REMITTANCE,
      RECEIVE_VIEW_UPLOAD_TT_REMITTANCE,
      FAIL_VIEW_UPLOAD_TT_REMITTANCE,
    ],
  },
});

export const saveImportExcel = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/SaveImportExcelTTRemittance'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_SAVE_UPLOAD_TT_REMITTANCE,
      RECEIVE_SAVE_UPLOAD_TT_REMITTANCE,
      FAIL_SAVE_UPLOAD_TT_REMITTANCE,
    ],
  },
});

export const saveTtRemittanceForward = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/SaveTTRemittanceMapping`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_SAVE_TT_REMITTANCE_FORWARD,
      RECEIVE_SAVE_TT_REMITTANCE_FORWARD,
      FAIL_SAVE_TT_REMITTANCE_FORWARD,
    ],
  },
});

export const getTtRemittanceForward = (exposureNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/GETTTRemittanceMapping/${exposureNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_TT_REMITTANCE_FORWARD,
      RECEIVE_GET_TT_REMITTANCE_FORWARD,
      FAIL_GET_TT_REMITTANCE_FORWARD,
    ],
  },
});

export const getAtatchfile = (filename) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/GetTTRemittanceAttachFile`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify({
      filename,
    }),
    types: [
      REQUEST_GET_TT_REMITTANCE_ATTACH_FILE,
      {
        type: RECEIVE_GET_TT_REMITTANCE_ATTACH_FILE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_GET_TT_REMITTANCE_ATTACH_FILE,
    ],
  },
});

export const downloadAttachFile = (Id) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/DownloadExposureAttachFile`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify({ Id }),
    types: [
      REQUEST_DOWNLOAD_EXPOSURE_ATTACH_FILE,
      {
        type: RECEIVE_DOWNLOAD_EXPOSURE_ATTACH_FILE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_EXPOSURE_ATTACH_FILE,
    ],
  },
});

export const exportPdf = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/ExportTTRemittancePDF`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify({ transactionNo }),
    types: [
      REQUEST_EXPORT_PDF_TT_REMITTANCE,
      {
        type: RECEIVE_EXPORT_PDF_TT_REMITTANCE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_EXPORT_PDF_TT_REMITTANCE,
    ],
  },
});

export const getTtRemittance = (transctionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`ttremittance/get/${transctionNo}`),
    method: 'GET',
    types: [REQUEST_GET_TT_REMITTANCE, RECEIVE_GET_TT_REMITTANCE, FAIL_GET_TT_REMITTANCE],
  },
});

export const saveAttachFile = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/SaveTTRemittanceAttachfile'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_SAVE_ATTACH_FILE_TT_REMITTANCE,
      RECEIVE_SAVE_ATTACH_FILE_TT_REMITTANCE,
      FAIL_SAVE_ATTACH_FILE_TT_REMITTANCE,
    ],
  },
});

export const mergeTtRemittance = (data, mode) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/MergeTTRemettance/${mode || ''}`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [REQUEST_MERGE_TT_REMITTANCE, RECEIVE_MERGE_TT_REMITTANCE, FAIL_MERGE_TT_REMITTANCE],
  },
});

export const splitTtRemittance = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/SplitTTRemettance'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [REQUEST_SPLIT_TT_REMITTANCE, RECEIVE_SPLIT_TT_REMITTANCE, FAIL_SPLIT_TT_REMITTANCE],
  },
});

export const undoSplitTtRemittance = (transctionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/ttremittance/UndoSplitTTRemettance/${transctionNo}`),
    method: 'POST',
    types: [
      REQUEST_UNDO_SPLIT_TT_REMITTANCE,
      RECEIVE_UNDO_SPLIT_TT_REMITTANCE,
      FAIL_UNDO_SPLIT_TT_REMITTANCE,
    ],
  },
});

export const changeStatus = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance/SaveTTRemittanceStatus'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_CHANGE_STATUS_TT_REMITTANCE,
      RECEIVE_CHANGE_STATUS_TT_REMITTANCE,
      FAIL_CHANGE_STATUS_TT_REMITTANCE,
    ],
  },
});
export const updateTtRemittance = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/ttremittance/SaveTTRemittance'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [
        REQUEST_UPDATE_TT_REMITTANCE,
        RECEIVE_UPDATE_TT_REMITTANCE,
        FAIL_UPDATE_TT_REMITTANCE,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(searchTtRemittance(criteria));

  return actionResponse;
};

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_TT_REMITTANCE,
  payload: {
    name,
    value,
    index,
  },
});

export const searchTtRemittance = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/ttremittance', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_TT_REMITTANCE, RECEIVE_SEARCH_TT_REMITTANCE, FAIL_SEARCH_TT_REMITTANCE],
  },
});
