import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { DATATABLE_ID } from './SearchMaintainInterestContainer';
import { toStatus, toNumber, toBankCode, getPermission } from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('Automate', 'Maintain Interest');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return `<a class="btn btn-icon btn-info" target="_blank" href="/automate/maintain-interest-detail/${r.maintainInterestId}"><span class="icon icon-edit sq-24"></span></a>`;
      },
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'bankCode', className: 'text-center' },
    {
      data: 'bankAbbreviate',
      render: (d, t, r) => toBankCode(null, d, r.bankName),
    },
    {
      data: 'interestRateLoan',
      className: 'text-right',
      render: (d, t, r) => (d ? toNumber(d, 5) : ''),
    },
    {
      data: 'interestRateDeposit',
      className: 'text-right',
      render: (d, t, r) => (d ? toNumber(d, 5) : ''),
    },
    { data: 'isPeriod', render: (d) => '' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,

  onClickOpenModalCheckInterest,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Automate <span className="icon icon-angle-double-right" /> Maintain Interest
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroup}
                name="FinanceGroup"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit" required={false}>
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnit}
                name="BusinessUnit"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[1]}>
            <FormGroup2 text="Company" required={false}>
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                financeGroupId={criteria.FinanceGroup}
                businessUnitId={criteria.BusinessUnit}
                value={criteria.CompanyCode}
                name="CompanyCode"
              />
            </FormGroup2>

            <FormGroup2 text="Bank" required={false}>
              <MasterDropdown
                masterType={MASTER_BANK}
                onChange={onChangeSelect2}
                value={criteria.BankCode}
                companyCode={criteria.CompanyCode}
                name="BankCode"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            {permission ? (
              <>
                <Link
                  className="btn btn-warning btn-sm btn-labeled"
                  to="/automate/maintain-interest-detail/create"
                  target="_blank"
                >
                  <span className="btn-label">
                    <i className="icon icon-plus-circle icon-fw icon-lg" />
                  </span>
                  MAINTAIN INTEREST
                </Link>
                &nbsp;
              </>
            ) : null}
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
            &nbsp;
            <Button
              txt="Check Interest"
              className="btn-sm btn-primary"
              onClick={onClickOpenModalCheckInterest}
            />
          </div>
        </Col2>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
      >
        <thead>
          <tr>
            <th>Action</th>
            <th>Edit</th>
            <th>Finance Group</th>
            <th>Business Unit</th>
            <th>
              Company
              <br /> Code
            </th>
            <th>Company Name</th>
            <th>
              Swfit
              <br />
              Code
            </th>
            <th>Bank</th>
            <th>Loan Rate</th>
            <th>Deposit Rate</th>
            <th>Period Cal.</th>
            <th>Status</th>
            <th>
              Created
              <br /> By
            </th>
            <th>
              Created
              <br /> Date
            </th>
            <th>
              Updated
              <br /> By
            </th>
            <th>
              Updated
              <br /> Date
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalFunction
      textHeader="Active"
      textContent="active"
      modalRef={modalActiveRef}
      type="info"
      icon="icon-refresh"
      textBtn="Active"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
    />

    <ModalFunction
      textHeader="InActive"
      textContent="inactive"
      modalRef={modalInactiveRef}
      type="danger"
      icon="icon-close"
      textBtn="Yes"
      textBtnBack="No"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
    />
  </div>
);
