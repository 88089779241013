import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';
import { downloadFile } from '../../common/helpper';

export const REQUEST_SAVE_MASTER_ATTACH_FILE = '@@request/SAVE_MASTER_ATTACH_FILE';
export const RECEIVE_SAVE_MASTER_ATTACH_FILE = '@@receive/SAVE_MASTER_ATTACH_FILE';
export const FAIL_SAVE_MASTER_ATTACH_FILE = '@@fail/SAVE_MASTER_ATTACH_FILE';

export const REQUEST_GET_MASTER_ATTACH_FILE = '@@request/GET_MASTER_ATTACH_FILE';
export const RECEIVE_GET_MASTER_ATTACH_FILE = '@@receive/GET_MASTER_ATTACH_FILE';
export const FAIL_GET_MASTER_ATTACH_FILE = '@@fail/GET_MASTER_ATTACH_FILE';

export const REQUEST_DOWNLOAD_MASTER_ATTACH_FILE = '@@request/DOWNLOAD_MASTER_ATTACH_FILE';
export const RECEIVE_DOWNLOAD_MASTER_ATTACH_FILE = '@@receive/DOWNLOAD_MASTER_ATTACH_FILE';
export const FAIL_DOWNLOAD_MASTER_ATTACH_FILE = '@@fail/DOWNLOAD_MASTER_ATTACH_FILE';

export const downloadAttachFile = (id) => ({
  [RSAA]: {
    endpoint: endpoint(`/Master/DownloadMasterAttachFile/${id}`),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_MASTER_ATTACH_FILE,
      {
        type: RECEIVE_DOWNLOAD_MASTER_ATTACH_FILE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_MASTER_ATTACH_FILE,
    ],
  },
});

export const getMasterAttachFile = (type, key, form) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/GetMasterAttachFile/${type}/${key}`),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_ATTACH_FILE,
      RECEIVE_GET_MASTER_ATTACH_FILE,
      FAIL_GET_MASTER_ATTACH_FILE,
    ],
  },
});

export const saveMasterAttachFile = (type, key, form) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveMasterAttachFile/${type}/${key}`),
    method: 'POST',
    body: form,
    types: [
      REQUEST_SAVE_MASTER_ATTACH_FILE,
      RECEIVE_SAVE_MASTER_ATTACH_FILE,
      FAIL_SAVE_MASTER_ATTACH_FILE,
    ],
  },
});
