import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_CONTRACT_LOAN_CRITERIA = 'EDIT_CONTRACT_LOAN_CRITERIA';
export const CLEAR_CONTRACT_LOAN_CRITERIA = 'CLEAR_CONTRACT_LOAN_CRITERIA';

export const REQUEST_SEARCH_CONTRACT_LOAN = '@@request/SARCH_CONTRACT_LOAN';
export const RECEIVE_SEARCH_CONTRACT_LOAN = '@@receive/SEARCH_CONTRACT_LOAN';
export const FAIL_SEARCH_CONTRACT_LOAN = '@@fail/SEARCH_CONTRACT_LOAN';

export const REQUEST_SAVE_CASHFLOW_CONTRACT_LOAN = '@@request/SAVE_CASHFLOW_CONTRACT_LOAN';
export const RECEIVE_SAVE_CASHFLOW_CONTRACT_LOAN =
  '@@receive-The transaction was successfully saved/SAVE_CASHFLOW_CONTRACT_LOAN';
export const FAIL_SAVE_CASHFLOW_CONTRACT_LOAN = '@@fail/SAVE_CASHFLOW_CONTRACT_LOAN';

export const REQUEST_SAVE_CONTRACT_LOAN = '@@request/SAVE_CONTRACT_LOAN';
export const RECEIVE_SAVE_CONTRACT_LOAN = '@@receive/SAVE_CONTRACT_LOAN';
export const FAIL_SAVE_CONTRACT_LOAN = '@@fail/SAVE_CONTRACT_LOAN';

export const REQUEST_GET_MAINTAIN_INTEREST_RATE = '@@request/GET_MAINTAIN_INTEREST_RATE';
export const RECEIVE_GET_MAINTAIN_INTEREST_RATE = '@@receive/GET_MAINTAIN_INTEREST_RATE';
export const FAIL_GET_MAINTAIN_INTEREST_RATE = '@@fail/GET_MAINTAIN_INTEREST_RATE';

export const REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE = '@@request/GET_TEMPLATE_LIST_INTEREST_RATE';
export const RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE = '@@receive/GET_TEMPLATE_LIST_INTEREST_RATE';
export const FAIL_GET_TEMPLATE_LIST_INTEREST_RATE = '@@fail/GET_TEMPLATE_LIST_INTEREST_RATE';

export const REQUEST_GET_FACILITY_LIST = '@@request-noload/GET_FACILITY_LIST';
export const RECEIVE_GET_FACILITY_LIST = '@@receive/GET_FACILITY_LIST';
export const FAIL_GET_FACILITY_LIST = '@@fail/GET_FACILITY_LIST';

export const REQUEST_PROCESS_CASH_FLOW_CONTRACT_LOAN = '@@request/PROCESS_CASH_FLOW_CONTRACT_LOAN';
export const RECEIVE_PROCESS_CASH_FLOW_CONTRACT_LOAN = '@@receive/PROCESS_CASH_FLOW_CONTRACT_LOAN';
export const FAIL_PROCESS_CASH_FLOW_CONTRACT_LOAN = '@@fail/PROCESS_CASH_FLOW_CONTRACT_LOAN';

export const REQUEST_GET_SUMMARY_CONTRACT_LOAN = '@@request/GET_SUMMARY_CONTRACT_LOAN';
export const RECEIVE_GET_SUMMARY_CONTRACT_LOAN = '@@receive/GET_SUMMARY_CONTRACT_LOAN';
export const FAIL_GET_SUMMARY_CONTRACT_LOAN = '@@fail/GET_SUMMARY_CONTRACT_LOAN';

export const REQUEST_CHANGE_STATUS_CONTRACT_LOAN = '@@request/CHANGE_STATUS_CONTRACT_LOAN';
export const RECEIVE_CHANGE_STATUS_CONTRACT_LOAN = '@@receive/CHANGE_STATUS_CONTRACT_LOAN';
export const FAIL_CHANGE_STATUS_CONTRACT_LOAN = '@@fail/CHANGE_STATUS_CONTRACT_LOAN';

export const REQUEST_SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN =
  '@@request/SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN';
export const RECEIVE_SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN =
  '@@receive/SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN';
export const FAIL_SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN = '@@fail/SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN';

export const saveChargeCashflowContractLoan = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/SaveCashFlowChargeContractLoan'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN,
      RECEIVE_SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN,
      FAIL_SAVE_CHARGE_CASHFLOW_CONTRACT_LOAN,
    ],
  },
});

export const changeStatus = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/changestatusloan', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CHANGE_STATUS_CONTRACT_LOAN,
      RECEIVE_CHANGE_STATUS_CONTRACT_LOAN,
      FAIL_CHANGE_STATUS_CONTRACT_LOAN,
    ],
  },
});

export const getSummaryContractLoan = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/loan/GetSummaryContractLoan/${transactionNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_SUMMARY_CONTRACT_LOAN,
      RECEIVE_GET_SUMMARY_CONTRACT_LOAN,
      FAIL_GET_SUMMARY_CONTRACT_LOAN,
    ],
  },
});

export const processCashflowContractLoan = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/ProcessCashFlowContractLoan'),
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    method: 'POST',
    types: [
      REQUEST_PROCESS_CASH_FLOW_CONTRACT_LOAN,
      RECEIVE_PROCESS_CASH_FLOW_CONTRACT_LOAN,
      FAIL_PROCESS_CASH_FLOW_CONTRACT_LOAN,
    ],
  },
});

export const saveContractLoan = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/SaveloanContractLoan'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_CONTRACT_LOAN, RECEIVE_SAVE_CONTRACT_LOAN, FAIL_SAVE_CONTRACT_LOAN],
  },
});

export const saveCashflowContractLoan = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/SaveCashFlowContractLoan'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_CASHFLOW_CONTRACT_LOAN,
      RECEIVE_SAVE_CASHFLOW_CONTRACT_LOAN,
      FAIL_SAVE_CASHFLOW_CONTRACT_LOAN,
    ],
  },
});

export const searchContractLoan = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/searchloan', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_CONTRACT_LOAN, RECEIVE_SEARCH_CONTRACT_LOAN, FAIL_SEARCH_CONTRACT_LOAN],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_CONTRACT_LOAN_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_CONTRACT_LOAN_CRITERIA,
  payload: {
    name,
    value,
  },
});
