import qs from 'querystring';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import ManageFXContract from './ManageFXContract';
import ImportExcel from '../../common/ImportExcel';
import { addAlert } from '../../reducers/layout/action';
import { toLower, toFixed, clearCheckBoxAllTable } from '../../common/helpper';
import {
  editCriteria,
  clearCriteria,
  saveImportExcel,
  getAttachHistory,
  deleteAttachfile,
  exportFxContract,
  updateAttacheFile,
  updateSearchResult,
  downloadAttachFile,
  setActiveFxContract,
  fetchEmailFxContract,
  importExcelFxContract,
  setInActiveFxContract,
  searchManageFxContract,
  updateFowardNoForUnwind,
  updateAssignToFxContract,
  updateFowardNoForRollOver,
  updateAttacheFileDocument,
  saveUnwindFxContractStatus,
} from '../../reducers/manageFx/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-manage-fx-contract';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

class ManageFXContractContainer extends Component {
  state = {
    modalAssignTo: {
      AssignTo: '',
      ForwardNo: '',
      CompanyCode: '',
    },
    modalUploadFile: {
      UploadType: 'Attach',
      SelectFile: null,
      Description: '',
      ForwardNo: '',
      CanReplace: false,
      Historys: [],
    },
    modalUploadContractFile: {
      ForwardNo: '',
      SelectedFile: null,
    },
    lastSearchCriteria: null,
  };

  cancelGainLossNo = null;

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `button.attach-file`, this.onClickAttachFileHandler);
    $('body').on('click', `button.fx-active`, (e) =>
      this.onClickOpenModalActiveInactive(e, 'active')
    );
    $('body').on('click', `button.fx-inactive`, (e) =>
      this.onClickOpenModalActiveInactive(e, 'inactive')
    );
    $('body').on(
      'click',
      `button.toggle-status-unwind`,
      this.onClickOpenModalConfirmCancelRolloverUnwind
    );

    let query = this.props.routeProp.location.search.replace('?', '');
    query = qs.parse(query);
    if (query.s !== undefined) {
      this.props.searchManageFxContract({ ...this.props.criteria });
    }
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.attach-file`, this.onClickAttachFileHandler);
    $('body').off(
      'click',
      `button.toggle-status-unwind`,
      this.onClickOpenModalConfirmCancelRolloverUnwind
    );
    $('body').off('click', `button.fx-active`, (e) =>
      this.onClickOpenModalActiveInactive(e, 'active')
    );
    $('body').off('click', `button.fx-inactive`, (e) =>
      this.onClickOpenModalActiveInactive(e, 'inactive')
    );
  }

  // search
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
    if (name === 'FinanceGroup') {
      if (value !== '003') this.props.editCriteria('BookBankCurrency', 'THB');
      else this.props.editCriteria('BookBankCurrency', '');
    } else if (name === 'SearchType') {
      this.props.editCriteria('Product', '');
    } else if (name === 'DollarLinked') {
      const v = value === 'Yes' ? true : value === '' ? null : false;
      this.props.editCriteria(name, v);
    }
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.editCriteria('modeResult', this.props.criteria.SearchType);
    this.setState(
      (state) => ({
        lastSearchCriteria: {
          ...this.props.criteria,
        },
      }),
      () => {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.props.searchManageFxContract({ ...this.props.criteria });
      }
    );
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };
  // end search

  // checkbox
  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const $td = $(e.currentTarget);
    const row = $dt.fixedColumns().rowIndex($td.closest('tr'));
    const checkbox = $td.find('input');
    if (!checkbox.length) return;

    const checked = !checkbox[0].checked;
    this.props.searchResult[row].IsSelected = checked;
    $dt.row(row).invalidate().draw(false);
    this.dataTableSearchResultRef.triggerOnDrawChild();
    this.forceUpdate();
  };
  // end checkbox

  // import exccel
  onClickOpenImportExcel = () => {
    this.importExcelRef.open();
  };

  onUploadViewImportExcel = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    this.props.importExcelFxContract(formData);
  };

  onSubmitSaveImportExcel = () => {
    const data = this.props.importExcel.rows;
    this.props.saveImportExcel(data);
  };
  // end import excel

  onClickExportHandler = () => {
    const { lastSearchCriteria } = this.state;
    if (!lastSearchCriteria) {
      this.props.addAlert({
        title: 'Warning',
        type: 'warning',
        body: 'Please search before export.',
      });
      return;
    }
    this.props.exportFxContract({ ...lastSearchCriteria });
  };

  onClickOpenModalAssignTo = (e, cell) => {
    const { row } = cell.index();
    const rowData = cell.row(row).data();
    this.setState(
      {
        modalAssignTo: {
          AssignTo: rowData.assignTo || '',
          CompanyCode: rowData.companyCode,
          ForwardNo: rowData.forwardNo,
        },
      },
      this.modalAssignToRef.open
    );
  };

  onChangeModalAssignTo = (e) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      modalAssignTo: {
        ...state.modalAssignTo,
        [name]: value,
      },
    }));
  };

  onClickSubmitModalAssignTo = (e) => {
    e.preventDefault();

    const submitData = { ...this.state.modalAssignTo };

    this.props.updateAssignToFxContract(submitData).then((response) => {
      if (!response.error) {
        this.setState(
          {
            modalAssignTo: {
              AssignTo: '',
              CompanyCode: '',
              ForwardNo: '',
            },
          },
          this.modalAssignToRef.close
        );
        this.props.searchManageFxContract({ ...this.props.criteria });
      }
    });
  };

  onClickRollOver = (e) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);

    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select transaction for RollOver.',
      });
      return;
    }

    const group1 = [];
    const group2 = [];
    let bank = '';
    let currency = '';
    let checkForwardContract = true;
    let checkStatus = true;

    selected.forEach((m) => {
      if (!m.contractNo) {
        checkForwardContract = false;
        return;
      }

      if ((m.status || '').toLowerCase() === 'inactive') {
        checkStatus = false;
        return;
      }

      if (bank !== '') {
        if (bank === m.bankAbbreviate && currency === m.forwardCurrency) group1.push(m);
        else group2.push(m);
      } else {
        bank = m.bankAbbreviate;
        currency = m.forwardCurrency;
        group1.push(m);
      }
    });

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: "Don't select transaction status is Inactive.",
      });
      return;
    }

    if (!checkForwardContract) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Incomplete filling data "Forward Contract". Please fully fill details into the blank.',
      });
      return;
    }

    if (group2.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'You select transaction different Bank or Forward Currency.',
      });
      return;
    }

    const transactionNo = group1
      .map((m) => m.forwardNo)
      .filter((f, i, self) => self.indexOf(f) === i);
    this.props.routeProp.history.push(`/fx-management/rollover/${transactionNo.join(',')}`);
  };

  onClickUnwind = (e) => {
    const selected = this.props.searchResult.filter((f) => f.IsSelected);

    if (!selected.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select transaction for Unwind.',
      });
      return;
    }

    const group1 = [];
    const group2 = [];
    let bank = '';
    let currency = '';
    let checkForwardContract = true;
    let checkStatus = true;
    let checkAssignTo = true;

    selected.forEach((m) => {
      if (!m.contractNo) {
        checkForwardContract = false;
        return;
      }

      if ((m.status || '').toLowerCase() === 'inactive') {
        checkStatus = false;
        return;
      }

      if (toLower(m.productType) === 'forward special' && !m.assignTo) {
        checkAssignTo = false;
        return;
      }

      if (bank !== '') {
        if (bank === m.bankAbbreviate && currency === m.forwardCurrency) group1.push(m);
        else group2.push(m);
      } else {
        bank = m.bankAbbreviate;
        currency = m.forwardCurrency;
        group1.push(m);
      }
    });

    if (!checkStatus) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: "Don't select transaction status is Inactive.",
      });
      return;
    }

    if (!checkForwardContract) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Incomplete filling data "Forward Contract". Please fully fill details into the blank.',
      });
      return;
    }

    if (!checkAssignTo) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction your selected, Product type: forward special is not assign company.',
      });
      return;
    }

    if (group2.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'You select transaction different Bank or Forward Currency.',
      });
      return;
    }

    const transactionNo = group1
      .map((m) => m.forwardNo)
      .filter((f, i, self) => self.indexOf(f) === i);
    this.props.routeProp.history.push(`/fx-management/unwind/${transactionNo.join(',')}`);
  };

  onClickOpenModalUploadFile = (e, cell) => {
    const { row } = cell.index();
    const rowData = cell.row(row).data();
    this.inputAttachfileRef.value = '';
    this.props.getAttachHistory(rowData.forwardNo).then((response) => {
      if (response.error) return;
      const { payload } = response;
      this.setState(
        {
          modalUploadFile: {
            UploadType: 'Attach',
            SelectFile: null,
            Description: '',
            ForwardNo: rowData.forwardNo,
            CanReplace: payload.canReplace,
            Historys: payload.attachForwardContractHistory || [],
          },
        },
        this.modalUploadFileRef.open
      );
    });
  };

  onChangeModalUploadFile = (e) => {
    this.setState({
      modalUploadFile: {
        ...this.state.modalUploadFile,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitSaveUploadFile = (e) => {
    e.preventDefault();

    const modalUploadFile = { ...this.state.modalUploadFile };
    if (!modalUploadFile.SelectFile) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select file for upload.',
      });
      return;
    }
    const form = new FormData();
    form.append('file', modalUploadFile.SelectFile);
    delete modalUploadFile.SelectFile;
    form.append('data', JSON.stringify(modalUploadFile));

    this.props.updateAttacheFile(form).then((response) => {
      if (!response.error) {
        this.inputAttachfileRef.value = '';
        this.setState(
          {
            modalUploadFile: {
              UploadType: 'Attach',
              SelectFile: null,
              Description: '',
              ForwardNo: '',
              Historys: [],
            },
          },
          this.modalUploadFileRef.close
        );
        this.props.searchManageFxContract({ ...this.props.criteria });
      }
    });
  };

  onChangeAttachFile = (e) => {
    const { files } = e.target;
    const file = files[0];

    const allowUpdate = ['xls', 'xlsb', 'xlsm', 'xlsx', 'csv'];
    const s = file.name.split('.');
    const exe = s[s.length - 1];
    if (!~allowUpdate.indexOf(exe.toLowerCase())) {
      this.props.addAlert({
        title: 'Warning',
        type: 'warning',
        body: 'Please upload Excel file.',
      });
      e.target.value = '';
      return;
    }
    this.setState((state) => ({
      modalUploadFile: {
        ...state.modalUploadFile,
        SelectFile: files[0],
      },
    }));
  };

  onDrawDataTableProduct = (rows, $dt) => {
    const nodes = rows.nodes();
    let last = null;
    rows.data().each((v, i, self) => {
      if ((i !== 0 && last !== v.companyCode) || i === self.length - 1) {
        let financeGroupName = '';
        let businessUnitName = '';
        let companyCode = '';
        let companyName = '';

        // find data in companyCode
        let companygroup = [];
        if (i === self.length - 1)
          companygroup = self.filter((f) => f.companyCode === v.companyCode);
        else companygroup = self.filter((f) => f.companyCode === last);
        const total = {};
        companygroup.each((m) => {
          financeGroupName = m.financeGroupName;
          businessUnitName = m.businessUnitName;
          companyCode = m.companyCode;
          companyName = m.companyName;
          if (
            toLower(m.productType) !== 'forward main' &&
            toLower(m.productType) !== 'forward special'
          ) {
            const t = toLower(m.transactionType);
            const key = `${t}_${toLower(m.forwardCurrency)}`;
            if (!total[key])
              total[key] = {
                totalAmount: 0,
                totalSpot: 0,
                totalSettlement: 0,
                currencyDigit: 0,
              };
            total[key].totalAmount += Math.abs(m.amount || 0);
            total[key].totalSpot += (m.spotRate || 0) * Math.abs(m.amount || 0);
            total[key].totalSettlement += (m.settlementRate || 0) * Math.abs(m.amount || 0);
            total[key].currencyDigit = m.currencyDigit;
          }
        });
        for (const key in total) {
          const s = key.split('_');
          const data = total[key];
          let tr = '<tr class="group group-end">';
          tr += '<td></td>';
          tr += `<td>${financeGroupName}</td>`;
          tr += `<td>${businessUnitName}</td>`;
          tr += `<td align="center">${companyCode}</td>`;
          tr += `<td>${companyName}</td>`;
          tr += `<td align="center"><b>Total ${s[0][0].toUpperCase()}${s[0].substr(1).toLowerCase()} ${s[1].toUpperCase()}</b></td>`;
          tr += `<td colspan="11"></td>`;
          tr += `<td align="right"><b>${toFixed(data.totalAmount, data.currencyDigit)}</b></td>`;
          tr += `<td align="right"><b>${toFixed(data.totalSpot / data.totalAmount, 8)}</b></td>`;
          tr += `<td colspan="3"></td>`;
          tr += `<td align="right"><b>${toFixed(data.totalSettlement / data.totalAmount, 8)}</b></td>`;
          tr += `<td colspan="19"></td>`;
          tr += '</tr>';
          if (i === self.length - 1) $(nodes[i]).next('tr').after(tr);
          else $(nodes[i]).before(tr);
        }
      }
      last = v.companyCode;
    });
  };

  onDrawDataTableDetail = (rows, $dt) => {
    const nodes = rows.nodes();
    let last = null;
    rows.data().each((v, i, self) => {
      if ((i !== 0 && last !== v.companyCode) || i === self.length - 1) {
        let financeGroupName = '';
        let businessUnitName = '';
        let companyCode = '';
        let companyName = '';

        // find data in companyCode
        let companygroup = [];
        if (i === self.length - 1)
          companygroup = self.filter((f) => f.companyCode === v.companyCode);
        else companygroup = self.filter((f) => f.companyCode === last);
        const total = {};
        companygroup.each((m) => {
          financeGroupName = m.financeGroupName;
          businessUnitName = m.businessUnitName;
          companyCode = m.companyCode;
          companyName = m.companyName;
          if (toLower(m.transactionType) === 'buy') {
            const key = `buy_${toLower(m.forwardCurrency)}`;
            total[key] = (total[key] || 0) + m.amount;
          } else if (toLower(m.transactionType) === 'sell') {
            const key = `sell_${toLower(m.forwardCurrency)}`;
            total[key] = (total[key] || 0) + m.amount;
          }
        });
        for (const key in total) {
          const s = key.split('_');
          let tr = '<tr class="group group-end">';
          tr += '<td></td>';
          tr += '<td></td>';
          tr += `<td>${financeGroupName}</td>`;
          tr += `<td>${businessUnitName}</td>`;
          tr += `<td align="center">${companyCode}</td>`;
          tr += `<td>${companyName}</td>`;
          tr += `<td align="center"><b>Total ${s[0][0].toUpperCase()}${s[0].substr(1).toLowerCase()} ${s[1].toUpperCase()}</b></td>`;
          tr += `<td colspan="11"></td>`;
          tr += `<td align="right"><b>${toFixed(total[key], 2)}</b></td>`;
          tr += `<td colspan="24"></td>`;
          tr += '</tr>';
          if (i === self.length - 1) $(nodes[i]).next('tr').after(tr);
          else {
            $(nodes[i]).before(tr);
          }
        }
      }
      last = v.companyCode;
    });
  };

  onDrawDataTableSummary = (rows, $dt) => {
    const nodes = rows.nodes();
    let last = null;
    rows.data().each((v, i, self) => {
      if ((i !== 0 && last !== v.companyCode) || i === self.length - 1) {
        let financeGroupName = '';
        let businessUnitName = '';
        let companyCode = '';
        let companyName = '';

        // find data in companyCode
        let companygroup = [];
        if (i === self.length - 1)
          companygroup = self.filter((f) => f.companyCode === v.companyCode);
        else companygroup = self.filter((f) => f.companyCode === last);
        const total = {};
        companygroup.each((m) => {
          financeGroupName = m.financeGroupName;
          businessUnitName = m.businessUnitName;
          companyCode = m.companyCode;
          companyName = m.companyName;
          if (toLower(m.transactionType) === 'buy') {
            const key = `buy_${toLower(m.forwardCurrency)}`;
            if (!total[key])
              total[key] = {
                amount: 0,
                outstanding: 0,
                currencyDigit: 0,
              };
            total[key].amount = (total[key].amount || 0) + m.amount;
            total[key].outstanding = (total[key].outstanding || 0) + m.outstanding;
            total[key].currencyDigit = m.currencyDigit;
          } else if (toLower(m.transactionType) === 'sell') {
            const key = `sell_${toLower(m.forwardCurrency)}`;
            if (!total[key])
              total[key] = {
                amount: 0,
                outstanding: 0,
                currencyDigit: 0,
              };
            total[key].amount = (total[key].amount || 0) + m.amount;
            total[key].outstanding = (total[key].outstanding || 0) + m.outstanding;
            total[key].currencyDigit = m.currencyDigit;
          }
        });
        for (const key in total) {
          const s = key.split('_');
          let tr = '<tr class="group group-end">';
          tr += '<td></td>';
          tr += '<td></td>';
          tr += '<td></td>';
          tr += `<td>${financeGroupName}</td>`;
          tr += `<td>${businessUnitName}</td>`;
          tr += `<td align="center">${companyCode}</td>`;
          tr += `<td>${companyName}</td>`;
          tr += `<td align="center"><b>Total ${s[0][0].toUpperCase()}${s[0].substr(1).toLowerCase()} ${s[1].toUpperCase()}</b></td>`;
          tr += `<td colspan="10"></td>`;
          tr += `<td align="right"><b>${toFixed(total[key].amount, total[key].currencyDigit)}</b></td>`;
          tr += `<td align="right"><b>${toFixed(total[key].outstanding, total[key].currencyDigit)}</b></td>`;
          tr += `<td colspan="23"></td>`;
          tr += '</tr>';
          if (i === self.length - 1) {
            $(nodes[i]).after(tr);
          } else {
            $(nodes[i]).before(tr);
          }
        }
      }
      last = v.companyCode;
    });
  };

  onClickAttachFileHandler = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    this.inputAttacheContractfileRef.value = '';
    this.setState(
      {
        modalUploadContractFile: {
          ForwardNo: rowData.forwardNo,
        },
      },
      this.modalUploadContractRef.open
    );
  };

  onChangeAttacheContractFile = (e) => {
    const file = e.target.files[0];

    this.setState((state) => ({
      modalUploadContractFile: {
        ...state.modalUploadContractFile,
        SelectedFile: file,
      },
    }));
  };

  onSubmitSaveUploadContractFile = (e) => {
    e.preventDefault();

    const { ForwardNo, SelectedFile } = this.state.modalUploadContractFile;
    const form = new FormData();
    form.append('file', SelectedFile);
    this.props.updateAttacheFileDocument(form, ForwardNo).then((response) => {
      if (!response.error) {
        this.inputAttacheContractfileRef.value = '';
        this.setState(
          {
            modalUploadContractFile: {
              ForwardNo: '',
              SelectedFile: null,
            },
          },
          this.modalUploadContractRef.close
        );
        this.props.searchManageFxContract({ ...this.props.criteria });
      }
    });
  };

  onClickDownloadAttachFile = (filename) => {
    if (!filename) {
      this.props.addAlert({
        title: 'Error',
        body: 'Filename not found.',
        type: 'error',
      });
      return;
    }
    this.props.downloadAttachFile({
      filename,
    });
  };

  onClickDeleteAttachForwardContract = (filename) => {
    this.setState((state) => ({
      modalUploadFile: {
        ...state.modalUploadFile,
        FileName: filename,
      },
    }));
    this.modalDeleteFileRef.open();
  };

  onClickConfirmDeleteAttachForwardContract = () => {
    const { ForwardNo } = this.state.modalUploadFile;
    const { FileName } = this.state.modalUploadFile;
    this.props
      .deleteAttachfile({
        ForwardNo,
        FileName,
      })
      .then((response) => {
        if (!response.error) this.modalUploadFileRef.close();
      });
    this.modalDeleteFileRef.close();
  };

  onClickFetchForward = () => {
    this.props.fetchEmailFxContract();
  };

  onClickOpenModalActiveInactive = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    this.setState(
      {
        selectedRowIdx: row,
      },
      () => {
        if (modal === 'active') this.modalActiveRef.open();
        else if (modal === 'inactive') this.modalInactiveRef.open();
      }
    );
  };

  onClickOpenModalConfirmCancelRolloverUnwind = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    const { gainLossNo } = rowData;

    this.cancelGainLossNo = gainLossNo;
    this.modalCancelRolloverUnwindRef.open();
  };

  onClickSubmitModalActiveInactive = (e, Status) => {
    const { selectedRowIdx } = this.state;
    const { forwardNo } = this.props.searchResult[selectedRowIdx];
    if (Status === 'Active') {
      this.props.setActiveFxContract(forwardNo).then((response) => {
        if (response.error) return;
        this.props.searchManageFxContract({ ...this.props.criteria });
        this.modalActiveRef.close();
      });
    } else if (Status === 'Inactive') {
      this.props.setInActiveFxContract(forwardNo).then((response) => {
        if (response.error) return;
        this.props.searchManageFxContract({ ...this.props.criteria });
        this.modalInactiveRef.close();
      });
    }
  };

  onClickSubmitCancelRolloverUnwind = () => {
    this.props
      .saveUnwindFxContractStatus({
        gainLossNo: this.cancelGainLossNo,
        isActive: true,
      })
      .then((response) => {
        if (response.error) return;
        this.modalCancelRolloverUnwindRef.close();
        this.props.searchManageFxContract({ ...this.props.criteria });
      });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onClickOpenImportExcel: this.onClickOpenImportExcel,

      onClickExport: this.onClickExportHandler,

      modalUploadContractRef: (e) => (this.modalUploadContractRef = e),
      onSubmitSaveUploadContractFile: this.onSubmitSaveUploadContractFile,
      onChangeAttacheContractFile: this.onChangeAttacheContractFile,
      inputAttacheContractfileRef: (e) => (this.inputAttacheContractfileRef = e),

      onClickOpenModalAssignTo: this.onClickOpenModalAssignTo,
      onChangeModalAssignTo: this.onChangeModalAssignTo,
      onClickSubmitModalAssignTo: this.onClickSubmitModalAssignTo,
      results: this.props.searchResult,
      onClickRollOver: this.onClickRollOver,
      onClickUnwind: this.onClickUnwind,

      onClickOpenModalUploadFile: this.onClickOpenModalUploadFile,
      onChangeModalUploadFile: this.onChangeModalUploadFile,
      onSubmitSaveUploadFile: this.onSubmitSaveUploadFile,
      onChangeAttachFile: this.onChangeAttachFile,
      inputAttachfileRef: (e) => (this.inputAttachfileRef = e),
      onDrawDataTableDetail: this.onDrawDataTableDetail,
      onDrawDataTableProduct: this.onDrawDataTableProduct,
      onClickDownloadAttachFile: this.onClickDownloadAttachFile,
      onClickDeleteAttachForwardContract: this.onClickDeleteAttachForwardContract,
      onClickConfirmDeleteAttachForwardContract: this.onClickConfirmDeleteAttachForwardContract,

      onClickFetchForward: this.onClickFetchForward,
      onClickOpenModalActiveInactive: this.onClickOpenModalActiveInactive,
      onClickSubmitModalActiveInactive: this.onClickSubmitModalActiveInactive,

      onDrawDataTableSummary: this.onDrawDataTableSummary,
      modalCancelRolloverUnwindRef: (e) => (this.modalCancelRolloverUnwindRef = e),
      onClickSubmitCancelRolloverUnwind: this.onClickSubmitCancelRolloverUnwind,
    };

    return (
      <div>
        <ManageFXContract
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          modalAssignToRef={(e) => (this.modalAssignToRef = e)}
          modalUploadFileRef={(e) => (this.modalUploadFileRef = e)}
          modalDeleteFileRef={(e) => (this.modalDeleteFileRef = e)}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
        />
        <ImportExcel
          ref={(e) => (this.importExcelRef = e)}
          onUpload={this.onUploadViewImportExcel}
          onClickSaveImport={this.onSubmitSaveImportExcel}
          tableName="import-excel-fx-contract"
          optionDt={opt}
          value={this.props.importExcel}
        >
          <thead>
            <tr>
              <th>CompanyCode</th>
              <th>CompanyName</th>
              <th>Product Type</th>
              <th>Transaction Type</th>
              <th>Business Partner</th>
              <th>FWD Contract Number</th>
              <th>ContractDate</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Forward Type</th>
              <th>SCG BuyCurrency</th>
              <th>SCG SellCurrency</th>
              <th>Book Amount</th>
              <th>Spot Rate</th>
              <th>Base Rate</th>
              <th>Forawrd rate</th>
              <th>Swap Rate</th>
              <th>GVC</th>
              <th>Portfolio</th>
              <th>Assignment</th>
              <th>Characteristic</th>
              <th>Internal Reference</th>
              <th>Request Type</th>
              <th>Contract Detail1</th>
              <th>Contract Detail2</th>
              <th>Contract Detail3</th>
              <th>Contract Detail4</th>
              <th>Dealer</th>
              <th>Department</th>
              <th>Status</th>
              <th>Note 1</th>
              <th>Note 2</th>
              <th>Error</th>
            </tr>
          </thead>
        </ImportExcel>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.manageFx,
  }),
  {
    importExcelFxContract,
    saveImportExcel,
    searchManageFxContract,
    updateSearchResult,
    addAlert,
    updateFowardNoForRollOver,
    updateFowardNoForUnwind,
    updateAttacheFile,
    updateAssignToFxContract,
    updateAttacheFileDocument,
    getAttachHistory,
    downloadAttachFile,
    deleteAttachfile,
    setActiveFxContract,
    setInActiveFxContract,
    exportFxContract,
    editCriteria,
    fetchEmailFxContract,
    clearCriteria,
    saveUnwindFxContractStatus,
  }
)(ManageFXContractContainer);

const opt = {
  rowCallback: (tr, r, idx, cells) => {
    if (r.isError) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    { data: 'companyCode' },
    { data: 'companyName' },
    { data: 'productType' },
    { data: 'transactionType' },
    { data: 'bankCode' },
    { data: 'contractNo' },
    { data: 'contractDateStr' },
    { data: 'startDateStr' },
    { data: 'endDateStr' },
    { data: 'forwardType' },
    { data: 'forwardCurrency' },
    { data: 'bookBankCurrency' },
    {
      data: 'amount',
      className: 'text-right',
      render: (d) => toFixed(d, 2),
    },
    {
      data: 'spotRate',
      className: 'text-right',
      render: (d) => toFixed(d, 5),
    },
    {
      data: 'baseRate',
      className: 'text-right',
      render: (d) => toFixed(d, 5),
    },
    {
      data: 'forwardRate',
      className: 'text-right',
      render: (d) => toFixed(d, 5),
    },
    {
      data: 'swapRate',
      className: 'text-right',
      render: (d) => toFixed(d, 5),
    },
    { data: 'purpose' },
    { data: 'businessArea' },
    {
      data: 'dollarLinked',
      render: (d) => (d ? 'Y' : 'N'),
    },
    { data: 'purposeDetail' },
    { data: 'internalRefNo' },
    { data: 'requestType' },
    { data: 'contractDetail1' },
    { data: 'contractDetail2' },
    { data: 'contractDetail3' },
    { data: 'contractDetail4' },
    { data: 'dealer' },
    { data: 'department' },
    { data: 'statusFile' },
    { data: 'note1' },
    { data: 'note2' },
    { data: 'errorMessage' },
  ],
};
