import { connect } from 'react-redux';
import React, { Component } from 'react';

import MaintainForm from './MaintainForm';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import {
  getMasterLgType,
  saveMaintainForm,
  getMaintainFormHeader,
  getMaintainFormDetail,
  getMasterLgTypeDetail,
} from '../../reducers/maintainForm/action';

const initData = {
  BgFormId: '',
  BgFormName: '',
  FormFormat: '',
  LgTypeId: '',
  Lang: '',
  IsOpenEnd: '',
  IsCloseEnd: '',
  BlockChains: [],
  Action: 'create',
  Remark: '',
  IsBeneficiaryForm: '',

  OldFormName: '',
};

class MaintainFormContainer extends Component {
  state = {
    ...initData,
    commonList: [],
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState(
        {
          ...initData,
        },
        this.getFormDetail('')
      );
    else {
      this.fetchId(id);
    }

    this.props.getMasterLgType();
  }

  componentDidUpdate(prevProps, prevState) {
    const { LgTypeId } = this.state;

    if (prevState.LgTypeId !== LgTypeId && LgTypeId) {
      this.props
        .getMasterLgTypeDetail({
          LgTypeId,
          Mode: 'detail',
        })
        .then((response) => {
          if (response.error && !response.payload) return;

          this.setState({
            ...this.state,
            commonList: response.payload || [],
          });
        });
    }
  }

  fetchId(id) {
    if (!id) return;

    this.props.getMaintainFormHeader({ BgFormId: id }).then((response) => {
      if (response.error || !response.payload) return;
      const fetchData = toUpperKey(response.payload[0]);
      this.setState(
        {
          ...fetchData,
          Action: 'edit',
          OldFormName: fetchData.BgFormName,
        },
        this.getFormDetail(id)
      );
    });
  }

  getFormDetail(id) {
    this.props.getMaintainFormDetail({ BgFormId: id }).then((response) => {
      if (response.error || !response.payload) return;

      this.setState({
        ...this.state,
        BlockChains: response.payload,
      });
    });
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'IsOpenEnd') {
        stateToUpdate.IsOpenEnd = !this.state.IsOpenEnd;
      } else if (name === 'IsCloseEnd') {
        stateToUpdate.IsCloseEnd = !this.state.IsCloseEnd;
      } else if (name === 'IsBeneficiaryForm') {
        stateToUpdate.IsBeneficiaryForm = !this.state.IsBeneficiaryForm;
      } else if (name === 'FormFormat' && value === 'Blockchain') {
        stateToUpdate.IsBeneficiaryForm = '';
      }
      return stateToUpdate;
    });
  };

  onChangeSelect2 = (e) => {
    if (e.value.length < 1) {
      this.setState({
        ...this.state,
        [e.name]: [''],
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        ...this.state,
        [e.name]: e.value.filter((item) => item !== ''),
      });
    } else {
      this.setState({
        ...this.state,
        [e.name]: e.value,
      });
    }
  };

  onChangeInputTable = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      BlockChains: this.state.BlockChains.map((m, i) => {
        if (i === index) {
          const obj = {
            ...m,
          };
          if (name === 'isSelected') {
            obj[name] = !(m.isSelected || false);

            if (!obj[name]) {
              obj.fieldType = '';
              obj[`fieldType${index}`] = '';
              obj.fieldName = '';
              obj[`fieldName${index}`] = '';
              obj.isRequire = false;
            }
          } else if (name.includes('fieldType')) {
            obj.fieldType = value;
            obj[name] = value;

            obj.fieldName = '';
            obj[`fieldName${index}`] = '';
          } else if (name.includes('fieldName')) {
            obj.fieldName = value;
            obj[name] = value;
          } else if (name.includes('isRequire')) {
            obj[name] = !(m.isRequire || false);
          }
          return obj;
        }
        return m;
      }),
    }));
  };

  onSubmitHandler = (e) => {
    e.preventDefault();

    const blockchains = this.state.BlockChains || [];
    const blockchainsFilter = blockchains.filter((f) => f.isSelected);

    const data = {
      BgFormId: this.state.BgFormId,
      BgFormName: this.state.BgFormName,
      FormFormat: this.state.FormFormat,
      LgTypeId: this.state.LgTypeId,
      Lang: this.state.Lang,
      IsOpenEnd: this.state.IsOpenEnd,
      IsCloseEnd: this.state.IsCloseEnd,
      IsBeneficiaryForm: this.state.IsBeneficiaryForm,
      Remark: this.state.Remark,
      Action: this.state.Action,
      BlockChains: blockchainsFilter,
    };

    this.props.saveMaintainForm(data).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The transaction was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      // if ((response.payload).toString() !== this.props.routeProp.match.params.id) {
      //     window.location.href = `/bank-guarantee/maintain-form/${response.payload}`
      // }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/bank-guarantee/search-maintain-form/');
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onChangeSelect2: this.onChangeSelect2,
      onChangeInputTable: this.onChangeInputTable,
      onSubmit: this.onSubmitHandler,

      masterLgTypeList: this.props.masterLgTypeList,
    };

    return (
      <div>
        <MaintainForm {...props} state={this.state} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.maintainForm,
  }),
  {
    getMaintainFormDetail,
    saveMaintainForm,
    addAlert,
    getMasterLgType,
    getMaintainFormHeader,
    getMasterLgTypeDetail,
  }
)(MaintainFormContainer);
