import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_EFORM_GROUPING = '@@request/SARCH_EFORM_GROUPING';
export const RECEIVE_SEARCH_EFORM_GROUPING = '@@receive/SEARCH_EFORM_GROUPING';
export const FAIL_SEARCH_EFORM_GROUPING = '@@fail/SEARCH_EFORM_GROUPING';

export const UPDATE_SEARCH_EFORM_GROUPING_RESULT = 'UPDATE_SEARCH_EFORM_GROUPING_RESULT';

export const REQUEST_EFORM_GROUPING = '@@request/EFORM_GROUPING';
export const RECEIVE_EFORM_GROUPING =
  '@@receive-The transaction was successfully saved/EFORM_GROUPING';
export const FAIL_EFORM_GROUPING = '@@fail/EFORM_GROUPING';

export const REQUEST_SEARCH_EFORM_DETAIL = '@@request/SARCH_EFORM_DETAIL';
export const RECEIVE_SEARCH_EFORM_DETAIL = '@@receive/SEARCH_EFORM_DETAIL';
export const FAIL_SEARCH_EFORM_DETAIL = '@@fail/SEARCH_EFORM_DETAIL';

export const UPDATE_SEARCH_EFORM_DETAIL_RESULT = 'UPDATE_SEARCH_EFORM_DETAIL_RESULT';

export const REQUEST_MAINTAIN_EFORM = '@@request/MAINTAIN_EFORM';
export const RECEIVE_MAINTAIN_EFORM = '@@receive/MAINTAIN_EFORM';
export const FAIL_MAINTAIN_EFORM = '@@fail/MAINTAIN_EFORM';

export const REQUEST_SAVE_MAINTAIN_EFORM_GROUPING = '@@request/SAVE_MAINTAIN_EFORM_GROUPING';
export const RECEIVE_SAVE_MAINTAIN_EFORM_GROUPING =
  '@@receive-The record was successfully saved/SAVE_MAINTAIN_EFORM_GROUPING';
export const FAIL_SAVE_MAINTAIN_EFORM_GROUPING = '@@fail/SAVE_MAINTAIN_EFORM_GROUPING';

export const REQUEST_SAVE_MAINTAIN_EFORM_PERCENTAGE = '@@request/SAVE_MAINTAIN_EFORM_PERCENTAGE';
export const RECEIVE_SAVE_MAINTAIN_EFORM_PERCENTAGE =
  '@@receive-The record was successfully saved/SAVE_MAINTAIN_EFORM_PERCENTAGE';
export const FAIL_SAVE_MAINTAIN_EFORM_PERCENTAGE = '@@fail/SAVE_MAINTAIN_EFORM_PERCENTAGE';

export const REQUEST_SAVE_MAINTAIN_EFORM_ROUNDING = '@@request/SAVE_MAINTAIN_EFORM_ROUNDING';
export const RECEIVE_SAVE_MAINTAIN_EFORM_ROUNDING =
  '@@receive-The record was successfully saved/SAVE_MAINTAIN_EFORM_ROUNDING';
export const FAIL_SAVE_MAINTAIN_EFORM_ROUNDING = '@@fail/SAVE_MAINTAIN_EFORM_ROUNDING';

export const REQUEST_SAVE_MAINTAIN_EFORM_DURATION = '@@request/SAVE_MAINTAIN_EFORM_DURATION';
export const RECEIVE_SAVE_MAINTAIN_EFORM_DURATION =
  '@@receive-The record was successfully saved/SAVE_MAINTAIN_EFORM_DURATION';
export const FAIL_SAVE_MAINTAIN_EFORM_DURATION = '@@fail/SAVE_MAINTAIN_EFORM_DURATION';

export const REQUEST_GET_MAINTAIN_EFORM_GROUPING = '@@request/GET_MAINTAIN_EFORM_GROUPING';
export const RECEIVE_GET_MAINTAIN_EFORM_GROUPING = '@@receive/GET_MAINTAIN_EFORM_GROUPING';
export const FAIL_GET_MAINTAIN_EFORM_GROUPING = '@@fail/GET_MAINTAIN_EFORM_GROUPING';

export const REQUEST_GET_MAINTAIN_EFORM_PERCENT = '@@request/GET_MAINTAIN_EFORM_PERCENT';
export const RECEIVE_GET_MAINTAIN_EFORM_PERCENT = '@@receive/GET_MAINTAIN_EFORM_PERCENT';
export const FAIL_GET_MAINTAIN_EFORM_PERCENT = '@@fail/GET_MAINTAIN_EFORM_PERCENT';

export const REQUEST_GET_MAINTAIN_EFORM_ROUNDING = '@@request/GET_MAINTAIN_EFORM_ROUNDING';
export const RECEIVE_GET_MAINTAIN_EFORM_ROUNDING = '@@receive/GET_MAINTAIN_EFORM_ROUNDING';
export const FAIL_GET_MAINTAIN_EFORM_ROUNDING = '@@fail/GET_MAINTAIN_EFORM_ROUNDING';

export const REQUEST_GET_MAINTAIN_EFORM_DURATION = '@@request/GET_MAINTAIN_EFORM_DURATION';
export const RECEIVE_GET_MAINTAIN_EFORM_DURATION = '@@receive/GET_MAINTAIN_EFORM_DURATION';
export const FAIL_GET_MAINTAIN_EFORM_DURATION = '@@fail/GET_MAINTAIN_EFORM_DURATION';

export const REQUEST_GET_EFORM_DEPARTMENT = '@@request/GET_EFORM_DEPARTMENT';
export const RECEIVE_GET_EFORM_DEPARTMENT = '@@receive/GET_EFORM_DEPARTMENT';
export const FAIL_GET_EFORM_DEPARTMENT = '@@fail/GET_EFORM_DEPARTMENT';

export const REQUEST_GET_EFORM_GROUP_ID = '@@request/GET_EFORM_GROUP_ID';
export const RECEIVE_GET_EFORM_GROUP_ID = '@@receive/GET_EFORM_GROUP_ID';
export const FAIL_GET_EFORM_GROUP_ID = '@@fail/GET_EFORM_GROUP_ID';

export const REQUEST_GET_EXPORT_EFORM = '@@request/REQUEST_GET_EXPORT_EFORM';
export const RECEIVE_GET_EXPORT_EFORM = '@@receive/REQUEST_GET_EXPORT_EFORM';
export const FAIL_GET_EXPORT_EFORM = '@@fail/REQUEST_GET_EXPORT_EFORM';

export const REQUEST_SAVE_EFORM_BANK = '@@request/SAVE_EFORM_BANK';
export const RECEIVE_SAVE_EFORM_BANK =
  '@@receive-The transaction was successfully saved/SAVE_EFORM_BANK';
export const FAIL_SAVE_EFORM_BANK = '@@fail/SAVE_EFORM_BANK';

export const saveEformBank = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/saveeformbank`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_EFORM_BANK, RECEIVE_SAVE_EFORM_BANK, FAIL_SAVE_EFORM_BANK],
  },
});

export const exportEform = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/ExportEForm', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_EXPORT_EFORM,
      {
        type: RECEIVE_GET_EXPORT_EFORM,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_GET_EXPORT_EFORM,
    ],
  },
});

export const getMasterGroupId = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetMaintainEformGrouping', criteria),
    method: 'GET',
    types: [REQUEST_GET_EFORM_GROUP_ID, RECEIVE_GET_EFORM_GROUP_ID, FAIL_GET_EFORM_GROUP_ID],
  },
});

export const getEformDepartment = () => ({
  [RSAA]: {
    endpoint: endpoint('/master/EformDepartment'),
    method: 'GET',
    types: [REQUEST_GET_EFORM_DEPARTMENT, RECEIVE_GET_EFORM_DEPARTMENT, FAIL_GET_EFORM_DEPARTMENT],
  },
});

export const getMaintainEformDuration = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetMaintainEformDuration', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_EFORM_DURATION,
      RECEIVE_GET_MAINTAIN_EFORM_DURATION,
      FAIL_GET_MAINTAIN_EFORM_DURATION,
    ],
  },
});

export const getMaintainEformRounding = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetMaintainEformRounding', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_EFORM_ROUNDING,
      RECEIVE_GET_MAINTAIN_EFORM_ROUNDING,
      FAIL_GET_MAINTAIN_EFORM_ROUNDING,
    ],
  },
});

export const getMaintainEformPercentage = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetMaintainEformPercentage', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_EFORM_PERCENT,
      RECEIVE_GET_MAINTAIN_EFORM_PERCENT,
      FAIL_GET_MAINTAIN_EFORM_PERCENT,
    ],
  },
});

export const getMaintainEformGrouping = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetMaintainEformGrouping', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_EFORM_GROUPING,
      RECEIVE_GET_MAINTAIN_EFORM_GROUPING,
      FAIL_GET_MAINTAIN_EFORM_GROUPING,
    ],
  },
});

export const saveMaintainEformDuration = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/fxmanagement/SaveMaintainEformDuration', data),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_SAVE_MAINTAIN_EFORM_DURATION,
        RECEIVE_SAVE_MAINTAIN_EFORM_DURATION,
        FAIL_SAVE_MAINTAIN_EFORM_DURATION,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(maintainEform(criteria));

  return actionResponse;
};

export const saveMaintainEformRounding = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/fxmanagement/SaveMaintainEformRounding', data),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_SAVE_MAINTAIN_EFORM_ROUNDING,
        RECEIVE_SAVE_MAINTAIN_EFORM_ROUNDING,
        FAIL_SAVE_MAINTAIN_EFORM_ROUNDING,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(maintainEform(criteria));

  return actionResponse;
};

export const saveMaintainEformPercentage = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/fxmanagement/SaveMaintainEformPercentage', data),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_SAVE_MAINTAIN_EFORM_PERCENTAGE,
        RECEIVE_SAVE_MAINTAIN_EFORM_PERCENTAGE,
        FAIL_SAVE_MAINTAIN_EFORM_PERCENTAGE,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(maintainEform(criteria));

  return actionResponse;
};

export const saveMaintainEformGrouping = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/fxmanagement/SaveMaintainEformGrouping', data),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_SAVE_MAINTAIN_EFORM_GROUPING,
        RECEIVE_SAVE_MAINTAIN_EFORM_GROUPING,
        FAIL_SAVE_MAINTAIN_EFORM_GROUPING,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(maintainEform(criteria));

  return actionResponse;
};

export const maintainEform = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/MaintainEform', criteria),
    method: 'GET',
    types: [REQUEST_MAINTAIN_EFORM, RECEIVE_MAINTAIN_EFORM, FAIL_MAINTAIN_EFORM],
  },
});

export const updateSearchResultDetail = (name, value, index) => ({
  type: UPDATE_SEARCH_EFORM_DETAIL_RESULT,
  payload: {
    name,
    value,
    index,
  },
});

export const searchEformDetial = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/searchEform', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_EFORM_DETAIL, RECEIVE_SEARCH_EFORM_DETAIL, FAIL_SEARCH_EFORM_DETAIL],
  },
});

export const eformGrouping = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/fxmanagement/EformGrouping'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_EFORM_GROUPING, RECEIVE_EFORM_GROUPING, FAIL_EFORM_GROUPING],
    },
  });

  if (!actionResponse.error) return await dispatch(searchEformGrouping(criteria));

  return actionResponse;
};

export const updateSearchResultGroup = (name, value, index) => ({
  type: UPDATE_SEARCH_EFORM_GROUPING_RESULT,
  payload: {
    name,
    value,
    index,
  },
});

export const searchEformGrouping = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/searchEform', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_EFORM_GROUPING,
      RECEIVE_SEARCH_EFORM_GROUPING,
      FAIL_SEARCH_EFORM_GROUPING,
    ],
  },
});
