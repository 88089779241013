import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';

const getAction = (patch, type, absolutePath) => (criteria, saveGlobalState) => ({
  [RSAA]: {
    endpoint: endpoint(absolutePath ? patch : `/master${patch}`, criteria),
    method: 'GET',
    types: saveGlobalState ? getTypes(type) : getTypes(`${type}_NO_SAVE`),
  },
});

const getActionLoan = (patch, type) => (criteria, saveGlobalState) => ({
  [RSAA]: {
    endpoint: endpoint(`/loan${patch}`, criteria),
    method: 'GET',
    types: saveGlobalState ? getTypes(type) : getTypes(`${type}_NO_SAVE`),
  },
});

const getActionBg = (patch, type) => (criteria, saveGlobalState) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee${patch}`, criteria),
    method: 'GET',
    types: saveGlobalState ? getTypes(type) : getTypes(`${type}_NO_SAVE`),
  },
});

const getActionPoSo = (patch, type) => (criteria, saveGlobalState) => ({
  [RSAA]: {
    endpoint: endpoint(`/poso${patch}`, criteria),
    method: 'GET',
    types: saveGlobalState ? getTypes(type) : getTypes(`${type}_NO_SAVE`),
  },
});

export const getTypes = (type) => [
  `@@request-noload/${type}`,
  `@@receive/${type}`,
  `@@fail/${type}`,
];

export const MASTER_FINANCE = 'MASTER_FINANCE';
export const MASTER_BUSINESS_UNIT = 'MASTER_BUSINESS_UNIT';
export const MASTER_COMPANY = 'MASTER_COMPANY';
export const MASTER_BANK = 'MASTER_BANK';
export const MASTER_BANK_CODE = 'MASTER_BANK_CODE';
export const MASTER_BRANCH = 'MASTER_BRANCH';
export const MASTER_ACCOUNT_TYPE = 'MASTER_ACCOUNT_TYPE';
export const MASTER_CURRENCY = 'MASTER_CURRENCY';
export const MASTER_TRANSFER_METHOD = 'MASTER_TRANSFER_METHOD';
export const MASTER_BANK_ACCOUNT = 'MASTER_BANK_ACCOUNT';
export const MASTER_USER = 'MASTER_USER';
export const MASTER_BUSINESS_AREA = 'MASTER_BUSINESS_AREA';
export const MASTER_FORWARD = 'MASTER_FORWARD';
export const GET_FACILITY = 'GET_FACILITY';
export const MASTER_ISSUER = 'MASTER_ISSUER';
export const MASTER_APPROVER = 'MASTER_APPROVER';
export const GET_LOAN_REF = 'GET_LOAN_REF';
export const SWAP_LOAN = 'SWAP_LOAN';
export const MASTER_HOLIDAY = 'MASTER_HOLIDAY';
export const MASTER_COUNTRY = 'MASTER_COUNTRY';
export const MASTER_PROVINCE = 'MASTER_PROVINCE';
export const MASTER_BANK_BRANCH = 'MASTER_BANK_BRANCH';
export const MASTER_ROLE = 'MASTER_ROLE';
export const MAIN_MASTER_FINANCE_GROUP = 'MAIN_MASTER_FINANCE_GROUP';
export const MAIN_MASTER_BUSINESS_UNIT = 'MAIN_MASTER_BUSINESS_UNIT';
export const MAIN_MASTER_COMPANY = 'MAIN_MASTER_COMPANY';
export const MAIN_MASTER_BANK = 'MAIN_MASTER_BANK';
export const MAIN_MASTER_BANK_BRANCH = 'MAIN_MASTER_BANK_BRANCH';
export const MAIN_MASTER_BANK_ACCOUNT = 'MAIN_MASTER_BANK_ACCOUNT';
export const MASTER_USER_BG = 'MASTER_USER_BG';
export const MASTER_COA = 'MASTER_COA';
export const MASTER_GL_ACCOUNT = 'MASTER_GL_ACCOUNT';
export const MASTER_FLOW_CONFIG = 'MASTER_FLOW_CONFIG';
export const MASTER_LOGO = 'MASTER_LOGO';
export const MASTER_INTEREST_TEMPLEATE = 'MASTER_INTEREST_TEMPLEATE';
export const MASTER_PRINT_INTEREST_RECEIPT_MAINTAIN = 'MASTER_PRINT_INTEREST_RECEIPT_MAINTAIN';
export const MASTER_INSTAND_CODE = 'MASTER_INSTAND_CODE';
export const GET_MATCHING_FROM_TO = 'GET_MATCHING_FROM_TO';

export const GET_BG_BENEFICIARY = 'GET_BG_BENEFICIARY';
export const GET_BG_LG_TYPE = 'GET_BG_LG_TYPE';
export const GET_BG_FORM = 'GET_BG_FORM';
export const GET_BG_BRANCH = 'GET_BG_BRANCH';
// ถ้ามี master เพิ่มเข้ามา มาเพิ่ม setting ข้างล่างนี้พอ reducer ไม่ต้องไปแก้ไขครับ
export const getMaster = {
  [MASTER_FINANCE]: {
    action: getAction('/finance', MASTER_FINANCE),
    state: 'financeGroups',
    name: 'financeGroup',
  },
  [MASTER_BUSINESS_UNIT]: {
    action: getAction('/businessunit', MASTER_BUSINESS_UNIT),
    state: 'businessUnits',
    name: 'businessUnit',
  },
  [MASTER_COMPANY]: {
    action: getAction('/company', MASTER_COMPANY),
    state: 'companys',
    name: {
      label: 'companyName',
      value: 'companyCode',
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.financeGroupId !== nextProps.financeGroupId ||
      prevProps.businessUnitId !== nextProps.businessUnitId ||
      prevProps.onlyMainCompany !== nextProps.onlyMainCompany ||
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.isGetAll !== nextProps.isGetAll ||
      prevProps.isBusinessPartner !== nextProps.isBusinessPartner ||
      prevProps.country !== nextProps.country ||
      prevProps.isActive !== nextProps.isActive ||
      prevProps.createDateStr !== nextProps.createDateStr ||
      prevProps.matchingDateStr !== nextProps.matchingDateStr ||
      prevProps.matchingTime !== nextProps.matchingTime,
    getPropForFetchData: (props) => ({
      FinanceId: props.financeGroupId,
      BusinessUnitId: props.businessUnitId,
      OnlyMainCompany: props.onlyMainCompany,
      CompanyCode: props.companyCode,
      IsGetAll: props.isGetAll,
      IsBusinessPartner: props.isBusinessPartner,
      Country: props.country,
      IsActive: props.isActive,
    }),
  },
  [MASTER_HOLIDAY]: {
    action: getAction('/getholiday', MASTER_HOLIDAY),
    state: 'holidays',
    name: {
      value: (m) => `${m.holidayId}`,
      label: (m) => `${m.calendarName}`,
    },
    customeKey: (m) => `${m.holidayId}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.status !== nextProps.status,
    getPropForFetchData: (props) => ({
      Status: props.status,
    }),
  },
  [MASTER_BANK]: {
    action: getAction('/bank', MASTER_BANK),
    state: 'banks',
    name: {
      value: (m) => `${m.bankCode}`,
      label: (m) => `${m.bankCode} | ${m.bankName}`,
    },
    customeKey: (m) => `${m.bankCode}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.companyCode !== nextProps.companyCode,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      IsIntersect: props.isIntersect || false,
      IsGetAll: props.isGetAll || true,
      IsMainMaster: props.isMainMaster || false,
    }),
  },
  [MASTER_INSTAND_CODE]: {
    action: getAction('/GetMasterInstance', MASTER_INSTAND_CODE),
    state: 'masterInstance',
    name: {
      value: (m) => `${m.instanceCode}|${m.instanceName}`,
      label: (m) => `${m.instanceCode} | ${m.instanceName}`,
    },
    customeKey: (m) => `${m.instanceId}`,
    getPropForFetchData: (props) => ({
      IsActive: true,
    }),
  },
  [MASTER_BANK_CODE]: {
    action: getAction('/bankCode', MASTER_BANK_CODE),
    state: 'bankCodes',
    name: {
      value: (m) => `${m.bankCode}`,
      label: (m) => `${m.bankCode} | ${m.bankName}`,
    },
    customeKey: (m) => `${m.bankCode}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.isOutFlow !== nextProps.isOutFlow ||
      prevProps.isPoSo !== nextProps.isPoSo,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      IsIntersect: props.isIntersect || false,
      IsGetAll: props.isGetAll || false,
      IsOutFlow: props.isOutFlow || false,
      IsPoSo: props.isPoSo || false,
      IsAllBank: props.isAllBank || false,
    }),
  },
  [SWAP_LOAN]: {
    action: getActionLoan('/GetSwapDropdown', SWAP_LOAN),
    state: 'swapLoan',
    name: {
      value: (m) => `${m.id}`,
      label: (m) => `${m.transactionNo}`,
    },
    customeKey: (m, i) => `${m.id}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.transactionNo !== nextProps.transactionNo ||
      prevProps.currency !== nextProps.currency ||
      prevProps.amount !== nextProps.amount ||
      prevProps.type !== nextProps.type ||
      prevProps.paymentDateStr !== nextProps.paymentDateStr ||
      prevProps.includeId !== nextProps.includeId,
    getPropForFetchData: (props) => ({
      TransactionNo: props.transactionNo,
      Currency: props.currency,
      Amount: props.amount,
      Type: props.type,
      PaymentDateStr: props.paymentDateStr,
      IncludeId: props.includeId,
    }),
  },
  [MASTER_BRANCH]: {
    action: getAction('/branch', MASTER_BRANCH),
    state: 'branchs',
    name: {
      value: (m) => `${m.bankAbbreviate}|${m.branchCode}|${m.country}`,
      label: (m) => `${m.bankAbbreviate} | ${m.branchName} | ${m.country}`,
    },
    customeKey: (m) => `${m.bankAbbreviate}_${m.branchCode}_${m.country}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.bankAbbreviate !== nextProps.bankAbbreviate,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      BankAbbreviate: props.bankAbbreviate,
    }),
  },
  [MASTER_ACCOUNT_TYPE]: {
    action: getAction('/accountType', MASTER_ACCOUNT_TYPE),
    state: 'accountTypes',
    name: 'accountType',
  },
  [MASTER_CURRENCY]: {
    action: getAction('/currency', MASTER_CURRENCY),
    state: 'currencys',
    name: {
      label: 'currency',
      value: 'currency',
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.createDateStr !== nextProps.createDateStr ||
      prevProps.matchingDateStr !== nextProps.matchingDateStr ||
      prevProps.matchingTime !== nextProps.matchingTime,
  },
  [MASTER_BANK_ACCOUNT]: {
    action: getAction('/bankaccount', MASTER_BANK_ACCOUNT),
    state: 'bankAccounts',
    name: {
      value: (m) => `${m.bankAccountNo}|${m.currency}|${m.country}`,
      label: (m) =>
        `${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}${m.bankAccountNoOfBank ? ` (${m.bankAccountNoOfBank})` : ''}`,
    },
    customeKey: (m) => `${m.bankAccountNo}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.bankAbbreviate !== nextProps.bankAbbreviate ||
      prevProps.bankCode !== nextProps.bankCode ||
      prevProps.isOutFlow !== nextProps.isOutFlow ||
      prevProps.isAccountBank !== nextProps.isAccountBank,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      BankAbbreviate: props.bankAbbreviate,
      BankCode: props.bankCode,
      ForUnwindRollOver: props.forUnwindRollOver,
      Currency: props.currency,
      IsOutFlow: props.isOutFlow || false,
      IsAccountBank: props.isAccountBank || false,
    }),
  },
  [MASTER_TRANSFER_METHOD]: {
    action: getAction('/transfermethod', MASTER_TRANSFER_METHOD),
    state: 'transferMethods',
    name: 'transferMethod',
  },
  [MASTER_USER]: {
    action: getAction('/user', MASTER_USER),
    state: 'users',
    name: {
      value: (m) => `${m.username}`,
      label: (m) => `${m.nameEn} ${m.surnameEn}`,
    },
    customeKey: (m) => `${m.username}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.isApprove !== nextProps.isApprove ||
      prevProps.isReviewer !== nextProps.isReviewer ||
      prevProps.isVerifier !== nextProps.isVerifier,
    getPropForFetchData: (props) => ({
      IsApprove: props.isApprove || false,
      IsReviewer: props.isReviewer || false,
      IsVerifier: props.isVerifier || false,
    }),
  },
  [MASTER_APPROVER]: {
    action: getAction('/approver', MASTER_APPROVER),
    state: 'username',
    name: {
      value: (m) => `${m.username}`,
      label: (m) => `${m.nameEn} ${m.surnameEn}`,
    },
    customeKey: (m) => `${m.username}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.financeGroupId !== nextProps.financeGroupId,
    getPropForFetchData: (props) => ({
      FinanceGroupId: props.financeGroupId || false,
    }),
  },
  [MASTER_USER_BG]: {
    action: getAction('/getuserbg', MASTER_USER_BG),
    state: 'usernamebg',
    name: {
      value: (m) => `${m.username}`,
      label: (m) => `${m.nameEn} ${m.surnameEn}`,
    },
    customeKey: (m) => `${m.username}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.action !== nextProps.action,
    getPropForFetchData: (props) => ({
      Action: props.action,
    }),
  },
  [MASTER_BUSINESS_AREA]: {
    action: getAction('/businessArea', MASTER_BUSINESS_AREA),
    state: 'businessAreas',
    name: {
      value: (m) => `${m.businessArea}`,
      label: (m) => `${m.businessArea} | ${m.businessAreaName}`,
    },
    customeKey: (m) => `${m.businessArea}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.companyCode !== nextProps.companyCode,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
    }),
  },
  [GET_LOAN_REF]: {
    action: getActionLoan('/getloanref', GET_LOAN_REF),
    state: 'transactions',
    name: {
      value: (m) => `${m.transactionNo}`,
      label: (m) => `${m.transactionNo}`,
    },
    customeKey: (m) => `${m.transactionNo}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.businessPartnerCode !== nextProps.businessPartnerCode ||
      prevProps.termStartStr !== nextProps.termStartStr ||
      prevProps.termEndStr !== nextProps.termEndStr,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      BusinessPartnerCode: props.businessPartnerCode,
      TermStartStr: props.termStartStr,
      TermEndStr: props.termEndStr,
    }),
  },
  [MASTER_FORWARD]: {
    action: getAction('/forward', MASTER_FORWARD),
    state: 'forwards',
    name: {
      label: 'forwardNo',
      value: 'forwardNo',
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.bankCode !== nextProps.bankCode ||
      prevProps.cashType !== nextProps.cashType ||
      prevProps.exposureType !== nextProps.exposureType ||
      prevProps.bookBankCurrency !== nextProps.bookBankCurrency ||
      prevProps.currency !== nextProps.currency ||
      prevProps.valueDateStr !== nextProps.valueDateStr,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      BankCode: props.bankCode,
      CashType: props.cashType,
      ExposureType: props.exposureType,
      BookBankCurrency: props.bookBankCurrency,
      Currency: props.currency,
      ValueDateStr: props.valueDateStr,
    }),
  },
  [GET_FACILITY]: {
    action: getActionLoan('/getfacilitylist', GET_FACILITY),
    state: 'facilitys',
    name: {
      label: (m) => `${m.facilityName}`,
      value: (m) => `${m.transactionNo}|${m.facilityAssignId}|${m.outstanding}`,
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.businessPartnerCode !== nextProps.businessPartnerCode ||
      prevProps.productType !== nextProps.productType ||
      prevProps.isProject !== nextProps.isProject ||
      prevProps.currency !== nextProps.currency ||
      prevProps.startDateStr !== nextProps.startDateStr ||
      prevProps.endDateStr !== nextProps.endDateStr,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      BusinessPartnerCode: props.businessPartnerCode,
      ProductType: props.productType,
      IsProject: props.isProject,
      StartDateStr: props.startDateStr,
      EndDateStr: props.endDateStr,
      Currency: props.currency,
    }),
  },
  [MASTER_ISSUER]: {
    action: getAction('/getissuer', MASTER_ISSUER),
    state: 'issuer',
    name: {
      label: 'issuerName',
      value: 'issuerId',
    },
  },
  [MASTER_COUNTRY]: {
    action: getAction('/getmastercountry', MASTER_COUNTRY),
    state: 'countrys',
    name: {
      label: (m) => `${m.country} | ${m.countryName}`,
      value: (m) => `${m.country}`,
    },
  },
  [MASTER_PROVINCE]: {
    action: getAction('/getmasterprovince', MASTER_PROVINCE),
    state: 'provinces',
    name: {
      label: (m) => `${m.provinceNameTh}`,
      value: (m) => `${m.provinceNameTh}`,
    },
  },
  [MASTER_BANK_BRANCH]: {
    action: getAction('/branch', MASTER_BANK_BRANCH),
    state: 'branchs',
    name: {
      value: (m) => `${m.branchCode}`,
      label: (m) => `${m.branchCode} | ${m.branchName} | ${m.country}`,
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.bankAbbreviate !== nextProps.bankAbbreviate,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      BankAbbreviate: props.bankAbbreviate,
    }),
  },
  [MASTER_ROLE]: {
    action: getAction('/getmasterrole', MASTER_ROLE),
    state: 'roles',
    name: {
      label: (m) => `${m.roleName}`,
      value: (m) => `${m.roleId}`,
    },
    checkForFetchData: (prevProps, nextProps) => prevProps.status !== nextProps.status,
    getPropForFetchData: (props) => ({
      Status: props.status,
    }),
  },
  [MAIN_MASTER_FINANCE_GROUP]: {
    action: getAction('/getmasterfinancegroup', MAIN_MASTER_FINANCE_GROUP),
    state: 'financeGroupAlls',
    name: {
      label: 'financeGroupName',
      value: 'financeGroupId',
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.financeGroupId !== nextProps.financeGroupId ||
      prevProps.status !== nextProps.status,
    getPropForFetchData: (props) => ({
      FinanceGroupId: props.financeGroupId,
      Status: props.status,
    }),
  },
  [MAIN_MASTER_BUSINESS_UNIT]: {
    action: getAction('/getmasterbusinessunit', MAIN_MASTER_BUSINESS_UNIT),
    state: 'businessUnitsAlls',
    name: {
      label: 'businessUnitName',
      value: 'businessUnitId',
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.businessUnit !== nextProps.businessUnit || prevProps.status !== nextProps.status,
    getPropForFetchData: (props) => ({
      BusinessUnit: props.businessUnit,
      Status: props.status,
    }),
  },
  [MAIN_MASTER_COMPANY]: {
    action: getAction('/getmastercompany', MAIN_MASTER_COMPANY),
    state: 'mastercompanys',
    name: {
      value: (m) => `${m.companyCode}`,
      label: (m) => `${m.companyCode} | ${m.companyAbbreviate} | ${m.companyName}`,
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.financeGroupId !== nextProps.financeGroupId ||
      prevProps.businessUnitId !== nextProps.businessUnitId ||
      prevProps.status !== nextProps.status,
    getPropForFetchData: (props) => ({
      FinanceId: props.financeGroupId,
      BusinessUnitId: props.businessUnitId,
      Status: props.status,
      Mode: 'mainmaster',
    }),
  },
  [MAIN_MASTER_BANK]: {
    action: getAction('/getmasterbank', MAIN_MASTER_BANK),
    state: 'masterbanks',
    name: {
      value: (m) => `${m.bankCode}`,
      label: (m) => `${m.bankCode} | ${m.bankAbbreviate} | ${m.bankName}`,
    },
    customeKey: (m) => `${m.bankCode}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.companyCode !== nextProps.companyCode,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      Mode: 'mainmaster',
    }),
  },
  [MAIN_MASTER_BANK_BRANCH]: {
    action: getAction('/getmasterbankbranch', MAIN_MASTER_BANK_BRANCH),
    state: 'masterbranchs',
    name: {
      value: (m) => `${m.branchCode}`,
      label: (m) => `${m.branchCode} | ${m.branchName} | ${m.country}`,
    },
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode ||
      prevProps.bankCode !== nextProps.bankCode ||
      prevProps.effectiveDateStr !== nextProps.effectiveDateStr,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      BankCode: props.bankCode,
      EffectiveDateStr: props.effectiveDateStr,
    }),
  },
  [MAIN_MASTER_BANK_ACCOUNT]: {
    action: getAction('/getmasterbankaccount', MAIN_MASTER_BANK_ACCOUNT),
    state: 'bankAccounts',
    name: {
      value: (m) => `${m.bankAccountNo}`,
      label: (m) => `${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}`,
    },
    customeKey: (m) => `${m.bankAccountNo}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.companyCode !== nextProps.companyCode || prevProps.bankCode !== nextProps.bankCode,
    getPropForFetchData: (props) => ({
      CompanyCode: props.companyCode,
      BankCode: props.bankCode,
    }),
  },
  [GET_BG_BENEFICIARY]: {
    action: getActionBg('/getmaitainbeneficiary', GET_BG_BENEFICIARY),
    state: 'beneficiaryIds',
    name: {
      value: (m) => `${m.beneficiaryId}`,
      label: (m) => `${m.beneficiaryNameTh}`,
    },
    customeKey: (m) => `${m.beneficiaryId}`,
    getPropForFetchData: (props) => ({
      IsActive: true,
    }),
  },
  [GET_BG_LG_TYPE]: {
    action: getAction('/getmasterlgtype', GET_BG_LG_TYPE),
    state: 'lgTypeIds',
    name: {
      value: (m) => `${m.lgTypeId}`,
      label: (m) => `${m.lgTypeCode} | ${m.lgTypeName}`,
    },
    customeKey: (m) => `${m.lgTypeId}`,
    getPropForFetchData: (props) => ({
      IsActive: '',
    }),
  },
  [GET_BG_FORM]: {
    action: getActionBg('/getmaintainbgform', GET_BG_FORM),
    state: 'bgFormIds',
    name: {
      value: (m) => `${m.bgFormId}`,
      label: (m) => `${m.bgFormName}`,
    },
    customeKey: (m) => `${m.bgFormId}`,
    getPropForFetchData: (props) => ({
      IsActive: props.isActive,
    }),
  },
  [GET_BG_BRANCH]: {
    action: getActionBg('/getmaintainbranch', GET_BG_BRANCH),
    state: 'branchIs',
    name: {
      value: (m) => `${m.branchId}`,
      label: (m) => `${m.branchCode} | ${m.branchName}`,
    },
    customeKey: (m) => `${m.branchId}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.bankCode !== nextProps.bankCode,
    getPropForFetchData: (props) => ({
      BankCode: props.bankCode,
      IsActive: props.isActive || '',
    }),
  },
  [MASTER_COA]: {
    action: getAction('/GetMasterCoa', MASTER_COA),
    state: 'coas',
    name: {
      value: (m) => `${m.chartOfAccount}`,
      label: (m) => `${m.chartOfAccount}`,
    },
    customeKey: (m) => `${m.chartOfAccount}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.isActive !== nextProps.isActive,
    getPropForFetchData: (props) => ({
      Action: 'master',
      IsActive: props.isActive || '',
    }),
  },
  [MASTER_GL_ACCOUNT]: {
    action: getAction('/GetMasterGlAccount', MASTER_GL_ACCOUNT),
    state: 'glAccounts',
    name: {
      value: (m) => `${m.glAccount}`,
      label: (m) => `${m.glAccount} | ${m.longText}`,
    },
    customeKey: (m) => `${m.glAccount}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.isActive !== nextProps.isActive || prevProps.coa !== nextProps.coa,
    getPropForFetchData: (props) => ({
      IsActive: props.isActive || '',
      Coa: props.coa || '',
    }),
  },
  [MASTER_FLOW_CONFIG]: {
    action: getAction('/GetMasterFlowConfig', MASTER_FLOW_CONFIG),
    state: 'flowConfigs',
    name: {
      value: (m) => `${m.flowNo}`,
      label: (m) => `${m.flowNo} | ${m.flow}`,
    },
    customeKey: (m) => `${m.flowNo}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.isActive !== nextProps.isActive,
    getPropForFetchData: (props) => ({
      IsActive: props.isActive || true,
    }),
  },
  [MASTER_LOGO]: {
    action: getAction('/GetMasterLogo', MASTER_LOGO),
    state: 'logos',
    name: {
      value: (m) => `${m.logoId}`,
      label: (m) => `${m.name}`,
    },
    customeKey: (m) => `${m.logoId}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.isActive !== nextProps.isActive || prevProps.type !== nextProps.type,
    getPropForFetchData: (props) => ({
      IsActive: props.isActive || true,
      Type: props.type,
    }),
  },
  [MASTER_INTEREST_TEMPLEATE]: {
    action: getActionLoan('/gettemplateinterestlist', MASTER_INTEREST_TEMPLEATE),
    state: 'interestTempleates',
    name: {
      value: (m) => `${m.interestRateId}`,
      label: (m) => `${m.interestTemplateName}`,
    },
    customeKey: (m) => `${m.interestRateId}`,
    checkForFetchData: (prevProps, nextProps) => prevProps.isActive !== nextProps.isActive,
    getPropForFetchData: (props) => ({
      IsActive: props.isActive || true,
    }),
  },
  [MASTER_PRINT_INTEREST_RECEIPT_MAINTAIN]: {
    action: getAction(
      '/PrintInterestReceiptMaintain/GetDropdown',
      MASTER_PRINT_INTEREST_RECEIPT_MAINTAIN,
      true
    ),
    state: 'printInterestReceiptMaintain',
    name: {
      label: 'name',
      value: 'id',
    },
    customeKey: (m) => `${m.id}`,
  },
  [GET_MATCHING_FROM_TO]: {
    action: getActionPoSo('/GetFxMatchingDate', GET_MATCHING_FROM_TO),
    state: 'matchingFromTo',
    name: {
      label: 'dateFromTo',
      value: 'dateFromTo',
    },
    customeKey: (m) => `${m.dateFromTo}`,
    checkForFetchData: (prevProps, nextProps) =>
      prevProps.type !== nextProps.type ||
      prevProps.matchingDateStr !== nextProps.matchingDateStr ||
      prevProps.matchingTime !== nextProps.matchingTime,
    getPropForFetchData: (props) => ({
      Type: props.type || null,
      MatchingDateStr: props.matchingDateStr || null,
      MatchingTime: props.matchingTime || null,
    }),
  },
};

export const REQUEST_EXCHANGE_RATE = '@@request/EXCHANGE_RATE';
export const RECEIVE_EXCHANGE_RATE = '@@receive/EXCHANGE_RATE';
export const FAIL_EXCHANGE_RATE = '@@fail/EXCHANGE_RATE';
export const REQUEST_HOLIDAY = '@@request/HOLIDAY';
export const RECEIVE_HOLIDAY = '@@receive/HOLIDAY';
export const FAIL_HOLIDAY = '@@fail/HOLIDAY';
export const REQUEST_CURRENCY_DIGIT = '@@request/CURRENCY_DIGIT';
export const RECEIVE_CURRENCY_DIGIT = '@@receive/CURRENCY_DIGIT';
export const FAIL_CURRENCY_DIGIT = '@@fail/CURRENCY_DIGIT';
export const REQUEST_INSTANCE_COMPANY = '@@request/INSTANCE_COMPANY';
export const RECEIVE_INSTANCE_COMPANY = '@@receive/INSTANCE_COMPANY';
export const FAIL_INSTANCE_COMPANY = '@@fail/INSTANCE_COMPANY';
export const REQUEST_BANK_COUNTRY = '@@request/BANK_COUNTRY';
export const RECEIVE_BANK_COUNTRY = '@@receive/BANK_COUNTRY';
export const FAIL_BANK_COUNTRY = '@@fail/BANK_COUNTRY';

export const getBankCountry = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbank', criteria),
    method: 'GET',
    types: [REQUEST_BANK_COUNTRY, RECEIVE_BANK_COUNTRY, FAIL_BANK_COUNTRY],
  },
});

export const getInstanceCompany = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbankaccount', criteria),
    method: 'GET',
    types: [REQUEST_INSTANCE_COMPANY, RECEIVE_INSTANCE_COMPANY, FAIL_INSTANCE_COMPANY],
  },
});

export const getCurrencyDigit = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/currency', criteria),
    method: 'GET',
    types: [REQUEST_CURRENCY_DIGIT, RECEIVE_CURRENCY_DIGIT, FAIL_CURRENCY_DIGIT],
  },
});

export const getExchangeRate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/exchangRate', criteria),
    method: 'GET',
    types: [REQUEST_EXCHANGE_RATE, RECEIVE_EXCHANGE_RATE, FAIL_EXCHANGE_RATE],
  },
});

export const searchHoliday = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getholiday', criteria),
    method: 'GET',
    types: [REQUEST_HOLIDAY, RECEIVE_HOLIDAY, FAIL_HOLIDAY],
  },
});
