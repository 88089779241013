import { Provider } from 'react-redux';
import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import App from './App';
import Test from './pages/test';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import BgPageNotFound from './img/404.png';
import configureStore from './configureStore';
import Bank from './pages/master/BankContainer';
import User from './pages/master/UserContainer';
import MasterCoa from './pages/master/CoaContainer';
import BgAcessDenied from './img/access_denied.jpg';
import Company from './pages/master/CompanyContainer';
import LogError from './pages/interface/LogErrorPage';
import Currency from './pages/master/CurrencyContainer';
import TierRate from './pages/master/TierRateContainer';
import RoleContainer from './pages/master/RoleContainer';
import Approver from './pages/approver/ApproverContainer';
import Unwind from './pages/fx-managemet/UnwindContainer';
import POA from './pages/poa-management/poa/POAContainer';
import Issuer from './pages/master/Issuer/IssuerContainer';
import UserDetail from './pages/master/UserDetailContainer';
import TierDetail from './pages/master/TierDetailContainer';
import CalendarHook from './pages/master/Calendar/Calendar';
import Rollover from './pages/fx-managemet/RolloverContainer';
import BankAccount from './pages/master/BankAccountContainer';
import Bond from './pages/loan-investment/Bond/BondContainer';
import SweepPN from './pages/report/SweepPn/SweepPNContainer';
import MasterInstance from './pages/master/InstanceContainer';
import CreateTt from './pages/tt-remittance/CreateTtContainer';
import FinanceGroup from './pages/master/FinanceGroupContainer';
import ExchangeRate from './pages/master/ExchangeRateContainer';
import Securities from './pages/accounting/SecuritiesContainer';
import MasterGlAccount from './pages/master/GlAccountContainer';
import CashPlan from './pages/cash-management/CashPlanContainer';
import BankDetail from './pages/master/Bank/BankDetailContainer';
import RequestTt from './pages/tt-remittance/RequestTtContainer';
import LoanReport from './pages/report/Loan/LoanReportContainer';
import RequestBg from './pages/bankGuarantee/RequestBgContainer';
import CompanyDetail from './pages/master/CompanyDetailContainer';
import MoneyMarket from './pages/accounting/MoneyMarketContainer';
import ForwardTT from './pages/tt-remittance/ForwardTTRemittance';
import MasterFlowConfig from './pages/master/FlowConfigContainer';
import Management from './pages/tt-remittance/ManagementContainer';
import ForwardRate from './pages/fx-managemet/ForwardRateContainer';
import UserDetailRole from './pages/master/UserDetailRoleContainer';
import WithHoldingTax from './pages/master/WithHoldingTaxContainer';
import FlowAccounting from './pages/master/FlowAccountingContainer';
import PnLoan from './pages/loan-investment/PnLoan/PnLoanContainer';
import OtherReport from './pages/report/Other/OtherReportContainer';
import SweepMoney from './pages/cash-management/SweepMoneyContainer';
import RoleDetailContainer from './pages/master/RoleDetailContainer';
import MarkToMarket from './pages/fx-managemet/MarkToMarketContainer';
import FileUpload from './pages/form/file-upload/FileUploadContainer';
import PlanApprove from './pages/cash-management/PlanApproveContainer';
import BankBranch from './pages/master/BankBranch/BankBranchContainer';
import ViewDetailTT from './pages/tt-remittance/ViewDetailTTContainer';
import Deposit from './pages/loan-investment/Deposit/DepositContainer';
import MtmSwap from './pages/loan-investment/MtmSwap/MtmSwapContainer';
import MtmBond from './pages/loan-investment/MtmBond/MtmBondContainer';
import MastetInterest from './pages/master/Interest/InterestContainer';
import MaintainBank from './pages/bankGuarantee/MaintainBankContainer';
import MaintainForm from './pages/bankGuarantee/MaintainFormContainer';
import ForwardDetail from './pages/fx-managemet/ForwardDetailContainer';
import EformGrouping from './pages/fx-managemet/EformGroupingContainer';
import MasterPaymentMethod from './pages/master/PaymentMethodContainer';
import CashPosition from './pages/cash-management/CashPositionContainer';
import AutoTransfer from './pages/cash-management/AutoTransferContainer';
import CreateExposure from './pages/fx-managemet/CreateExposureContainer';
import BankAccountDetail from './pages/master/BankAccountDetailContainer';
import ForeignExchange from './pages/accounting/ForeignExchangeContainer';
import CreateBond from './pages/loan-investment/Bond/CreateBondContainer';
import DerivativesFlow from './pages/accounting/DerivativesFlowContainer';
import MaintainInterest from './pages/automate/MaintainInterestContainer';
import AutomateExposure from './pages/automate/AutomateExposureContainer';
import MaintainBranch from './pages/bankGuarantee/MaintainBranchContainer';
import ReportBg from './pages/report/BankGuarantee/BankGuaranteeContainer';
import FinanceGroupDetail from './pages/master/FinanceGroupDetailContainer';
import MasterAccountStandard from './pages/master/AccountStandardContainer';
import BusinessUnit from './pages/master/BusinessUnit/BusinessUnitContainer';
import BusinessArea from './pages/master/BusinessArea/BusinessAreaContainer';
import InterestReport from './pages/report/Interest/InterestReportContainer';
import SearchRequestBg from './pages/bankGuarantee/SearchRequestBgContainer';
import ExposurePosition from './pages/fx-managemet/ExposurePositionContainer';
import ManageFXContract from './pages/fx-managemet/ManageFXContractContainer';
import MaintainGrouping from './pages/fx-managemet/MaintainGroupingContainer';
import MasterLogoAndSignature from './pages/master/LogoAndSignatureContainer';
import SearchAutomateExposure from './pages/automate/SearchExposureContainer';
import FxMatching from './pages/fx-managemet/fx-matching/FxMatchingContainer';
import FlowAccountingDetail from './pages/master/FlowAccountingDetailContainer';
import CreatePnLoan from './pages/loan-investment/PnLoan/CreatePnLoanContainer';
import Derivative from './pages/loan-investment/Derivative/DerivativeContainer';
import TransferFeeSetUp from './pages/cash-management/TransferFeeSetUpContainer';
import BankTransferPosting from './pages/accounting/BankTransferPostingContainer';
import BankEndingBalance from './pages/cash-management/BankEndingBalanceContainer';
import CreateDeposit from './pages/loan-investment/Deposit/CreateDepositContainer';
import SearchMaintainForm from './pages/bankGuarantee/SearchMaintainFormContainer';
import ExposureTransaction from './pages/fx-managemet/ExposureTransactionContainer';
import LoanReportIntercom from './pages/report/LoanIntercom/LoanIntercompContainer';
import SearchMaintainBalance from './pages/automate/SearchMaintainBalanceContainer';
import ReportAccountSaving from './pages/report/AccountSaving/AccountSavingContainer';
import SearchMaintainInterest from './pages/automate/SearchMaintainInterestContainer';
import BankTransferSummary from './pages/cash-management/BankTransferSummaryContainer';
import PrintInterestReceiptMaintain from './pages/master/PrintInterestReceiptMaintain';
import PrintInterestReceiptType from './pages/master/PrintInterestReceiptTypeContainer';
import FacilitySetUp from './pages/loan-investment/FacilitySetUp/FacilitySetUpContainer';
import CreateContractLoan from './pages/loan-investment/ContractLoan/CreateContractLoan';
import MasterBankBranchDetail from './pages/master/BankBranch/BankBranchDetailContainer';
import BookingForward from './pages/fx-managemet/booking-forward/BookingForwardContainer';
import FxConfirmation from './pages/fx-managemet/fx-confirmation/FxConfirmationContainer';
import BankAccountOfBank from './pages/master/BankAccountOfBank/BankAccountOfBankContainer';
import ContractOnCall from './pages/loan-investment/ContractOnCall/ContractOnCallContainer';
import CreateDerivative from './pages/loan-investment/Derivative/SearchDerivativeContainer';
import ReportInterestReceipt from './pages/report/InterestReceipt/InterestReceiptContainer';
import InterestRate from './pages/loan-investment/MaintainInterestRate/InterestRateContainer';
import SecuritiesJournal from './pages/accounting-posting-journal/SecuritiesJournalContainer';
import MoneyMarketJournal from './pages/accounting-posting-journal/MoneyMarketJournalContainer';
import DerivativesJournal from './pages/accounting-posting-journal/DerivativesJournalContainer';
import ForwardRolloverUnwindHistory from './pages/fx-managemet/ForwardRolloverUnwindHistoryPage';
import SearchContractLoan from './pages/loan-investment/ContractLoan/SearchContractLoanContainer';
import BookingForwardDetail from './pages/fx-managemet/booking-forward/BookingForwardDetailContainer';
import FxConfirmationDetail from './pages/fx-managemet/fx-confirmation/FxConfirmationDetailContainer';
import BankAccountOfBankDetail from './pages/master/BankAccountOfBank/BankAccountOfBankDetailContainer';
import CreateContractOnCall from './pages/loan-investment/ContractOnCall/CreateContractOnCallContainer';
import ForeignExchangeJournal from './pages/accounting-posting-journal/ForeignExchangeJournalContainer';
import FacilitySetUpDetail from './pages/loan-investment/FacilitySetUpDetail/FacilitySetUpDetailContainer';
import PrintInterestReceiptMaintainSearch from './pages/master/PrintInterestReceiptMaintainSearchContaine';
import MaintainInterestRate from './pages/loan-investment/MaintainInterestRate/MaintainInterestRateContainer';
import BankTransferPostingJournal from './pages/accounting-posting-journal/BankTransferPostingJournalContainer';

export const store = configureStore();

const AppRoute = ({ component, layout, page, noLayout, ...rest }) => {
  if (noLayout) return <Route path="/test" component={component} />;
  return (
    <Route
      {...rest}
      render={(props) => <App component={component} page={page} routeProp={props} />}
    />
  );
};

class Root extends Component {
  render() {
    return (
      <Provider store={store}>
        <Switch>
          <AppRoute path="/test" component={Test} noLayout />
          <Route path="/login" component={Login} />
          <AppRoute path="/" component={Dashboard} exact page="Dashboard" />
          <AppRoute
            path="/cash-management/bank-ending-balance"
            component={BankEndingBalance}
            page="Bank Ending Balance"
          />
          <AppRoute
            path="/cash-management/plan-approve"
            component={PlanApprove}
            page="Plan & Approve Transaction"
          />
          <AppRoute
            path="/cash-management/cash-position"
            component={CashPosition}
            page="Cash Position"
          />
          <AppRoute
            path="/cash-management/bank-transfer-summary"
            component={BankTransferSummary}
            page="Bank Transfer Summary"
          />
          <AppRoute
            path="/cash-management/transfer-fee-set-up"
            component={TransferFeeSetUp}
            page="Transfer Fee Set Up"
          />
          <AppRoute path="/approver/list-approve" component={Approver} page="Approver" />
          <AppRoute
            path="/cash-management/cash-plan/:id?"
            component={CashPlan}
            page="Plan & Approve Transaction"
          />
          <AppRoute
            path="/cash-management/sweep-money"
            component={SweepMoney}
            page="Cash Position"
          />
          <AppRoute
            path="/cash-management/auto-transfer"
            component={AutoTransfer}
            page="Cash Position"
          />

          <AppRoute
            path="/fx-management/create-exposure"
            component={CreateExposure}
            page="Create Exposure"
          />
          <AppRoute
            path="/fx-management/exposure-transaction/:id?"
            component={ExposureTransaction}
            page="Create Exposure"
          />
          <AppRoute
            path="/fx-management/exposure-position"
            component={ExposurePosition}
            page="Exposure Position"
          />
          <AppRoute
            path="/fx-management/forward-rate/:t?"
            component={ForwardRate}
            page="Exposure Position"
          />
          <AppRoute
            path="/fx-management/manage-fx-contract"
            component={ManageFXContract}
            page="Manage FX Contract"
          />
          <AppRoute
            path="/fx-management/forward-detail/:id?"
            component={ForwardDetail}
            page="Manage FX Contract"
          />
          <AppRoute
            path="/fx-management/rollover/:t?"
            component={Rollover}
            page="Manage FX Contract"
          />
          <AppRoute path="/fx-management/unwind/:t?" component={Unwind} page="Manage FX Contract" />
          <AppRoute
            path="/fx-management/e-form-grouping"
            component={EformGrouping}
            page="E-Form Grouping"
          />
          <AppRoute
            path="/fx-management/maintain-grouping"
            component={MaintainGrouping}
            page="E-Form Grouping"
          />
          <AppRoute
            path="/fx-management/mark-to-market"
            component={MarkToMarket}
            page="MTM Forward"
          />
          <AppRoute
            path="/fx-management/rolloverUnwindHistory/:t"
            component={ForwardRolloverUnwindHistory}
            page="Rollover Unwind History"
          />
          <AppRoute
            path="/fx-management/create-exposure-for-booking-forward"
            component={BookingForward}
            page="Create Exposure for Booking Forward"
          />
          <AppRoute
            path="/fx-management/exposure-for-booking-forward/:id?"
            component={BookingForwardDetail}
            page="Create Exposure for Booking Forward"
          />
          <AppRoute
            path="/fx-management/fx-confirmation"
            component={FxConfirmation}
            page="FX Confirmation"
          />
          <AppRoute
            path="/fx-management/fx-confirmation-detail/:id?"
            component={FxConfirmationDetail}
            page="FX Confirmation"
          />
          <AppRoute
            path="/fx-management/pricing-rate-to-bank"
            component={FxMatching}
            page="Pricing Rate to Bank"
          />

          <AppRoute path="/tt-remittance/management" component={Management} page="T/T Manage" />
          <AppRoute
            path="/tt-remittance/view-detail-tt/:id?"
            component={ViewDetailTT}
            page="T/T Manage"
          />
          <AppRoute
            path="/tt-remittance/forward-tt/:id"
            component={ForwardTT}
            page="T/T Manage"
            exact
          />
          <AppRoute path="/tt-remittance/request" component={RequestTt} page="Request" />
          <AppRoute path="/tt-remittance/create-tt/:id?" component={CreateTt} page="Request" />

          <AppRoute
            path="/accounting/bank-transfer-posting"
            component={BankTransferPosting}
            page="Bank Transfer Posting"
          />
          <AppRoute
            path="/accounting/foreign-exchange"
            component={ForeignExchange}
            page="Foreign Exchange"
          />
          <AppRoute path="/accounting/money-market" component={MoneyMarket} page="Money Market" />
          <AppRoute path="/accounting/securities" component={Securities} page="Securities" />
          <AppRoute path="/accounting/derivatives" component={DerivativesFlow} page="Derivatives" />

          <AppRoute
            path="/accounting-posting-journal/bank-transfer-posting"
            component={BankTransferPostingJournal}
            page="Bank Transfer Posting Journal"
          />
          <AppRoute
            path="/accounting-posting-journal/foreign-exchange"
            component={ForeignExchangeJournal}
            page="Foreign Exchange Journal"
          />
          <AppRoute
            path="/accounting-posting-journal/money-market"
            component={MoneyMarketJournal}
            page="Money Market Journal"
          />
          <AppRoute
            path="/accounting-posting-journal/securities"
            component={SecuritiesJournal}
            page="Securities Journal"
          />
          <AppRoute
            path="/accounting-posting-journal/derivatives"
            component={DerivativesJournal}
            page="Derivatives Journal"
          />

          <AppRoute
            path="/loan-investment/facility-set-up"
            component={FacilitySetUp}
            page="Facility Set up"
          />
          <AppRoute
            path="/loan-investment/facility-set-up-detail/:id?"
            component={FacilitySetUpDetail}
            page="Facility Set up"
          />
          <AppRoute
            path="/loan-investment/maintain-interest-rate"
            component={MaintainInterestRate}
            page="Maintain Interest Rate"
          />
          <AppRoute
            path="/loan-investment/interest-rate/:id?"
            component={InterestRate}
            page="Maintain Interest Rate"
          />
          <AppRoute
            path="/loan-investment/create-contract-loan"
            component={SearchContractLoan}
            page="Create Contract Loan"
          />
          <AppRoute
            path="/loan-investment/contract-loan/:id?"
            component={CreateContractLoan}
            page="Create Contract Loan"
          />
          <AppRoute
            path="/loan-investment/create-pn-loan"
            component={CreatePnLoan}
            page="Create Promissory Note"
          />
          <AppRoute
            path="/loan-investment/pn-loan/:id?"
            component={PnLoan}
            page="Create Promissory Note"
          />
          <AppRoute
            path="/loan-investment/create-contract-on-call"
            component={CreateContractOnCall}
            page="Create Contract on Call"
          />
          <AppRoute
            path="/loan-investment/contract-on-call/:id?"
            component={ContractOnCall}
            page="Create Contract on Call"
          />
          <AppRoute
            path="/loan-investment/create-deposit"
            component={CreateDeposit}
            page="Create Deposit"
          />
          <AppRoute
            path="/loan-investment/deposit/:id?"
            component={Deposit}
            page="Create Deposit"
          />
          <AppRoute path="/loan-investment/create-bond" component={CreateBond} page="Create Bond" />
          <AppRoute path="/loan-investment/bond/:id?" component={Bond} page="Create Bond" />
          <AppRoute
            path="/loan-investment/create-derivative"
            component={CreateDerivative}
            page="Create Derivative"
          />
          <AppRoute
            path="/loan-investment/derivative/:id?"
            component={Derivative}
            page="Create Derivative"
          />
          <AppRoute path="/loan-investment/mtm-swap" component={MtmSwap} page="MTM Swap" />
          <AppRoute path="/loan-investment/mtm-bond" component={MtmBond} page="MTM Bond" />
          <AppRoute
            path="/loan-investment/promissory-note-form"
            component={ComingSoon}
            page="Promissory Note Form"
          />

          <AppRoute path="/report/sweep-pn" component={SweepPN} page="Sweep PN" />
          <AppRoute path="/report/interest" component={InterestReport} page="Interest" />

          <AppRoute path="/master/company" component={Company} page="Company" />
          <AppRoute path="/master/bank" component={Bank} page="Bank" />
          <AppRoute path="/master/bank-account" component={BankAccount} page="Bank Account" />
          <AppRoute path="/master/tier-rate" component={TierRate} page="Tier Rate" />
          <AppRoute path="/master/issuer" component={Issuer} page="Issuer" />
          <AppRoute path="/master/calendar" component={CalendarHook} page="Calendar" />
          <AppRoute path="/master/finance-group" component={FinanceGroup} page="Finance Group" />
          <AppRoute
            path="/master/finance-group-detail/:id?"
            component={FinanceGroupDetail}
            page="Finance Group"
          />
          <AppRoute path="/master/exchange-rate" component={ExchangeRate} page="Exchange Rate" />
          <AppRoute path="/master/company-detail/:id?" component={CompanyDetail} page="Company" />
          <AppRoute
            path="/master/bank-account-detail/:id?"
            component={BankAccountDetail}
            page="Bank Account"
          />
          <AppRoute path="/master/role" component={RoleContainer} page="Role" />
          <AppRoute path="/master/role-detail/:id?" component={RoleDetailContainer} page="Role" />
          <AppRoute path="/master/user" component={User} page="User" />
          <AppRoute path="/master/user-detail/:id?" component={UserDetail} page="User" />
          <AppRoute path="/master/user-detail-group/:id?" component={UserDetailRole} page="User" />
          <AppRoute path="/master/currency" component={Currency} page="Currency" />
          <AppRoute
            path="/master/with-holding-tax"
            component={WithHoldingTax}
            page="Withholding Tax"
          />
          <AppRoute
            path="/master/flow-accounting"
            component={FlowAccounting}
            page="Flow Accounting"
          />
          <AppRoute
            path="/master/flow-accounting-detail"
            component={FlowAccountingDetail}
            page="Flow Accounting Detail"
          />
          <AppRoute path="/master/business-unit" component={BusinessUnit} page="Business Unit" />
          <AppRoute path="/master/business-area" component={BusinessArea} page="Business Area" />
          <AppRoute path="/master/bank-branch" component={BankBranch} page="Bank Branch" />
          <AppRoute path="/master/bank-detail/:id?" component={BankDetail} page="Bank" />
          <AppRoute path="/master/tier-detail/:id?" component={TierDetail} page="Tier Rate" />
          <AppRoute path="/master/interest-rate" component={MastetInterest} page="Interest Rate" />
          <AppRoute path="/master/coa" component={MasterCoa} page="COA" />
          <AppRoute path="/master/instance" component={MasterInstance} page="Instance" />
          <AppRoute path="/master/gl-account" component={MasterGlAccount} page="GL Account" />
          <AppRoute path="/master/flow-config" component={MasterFlowConfig} page="Flow Config" />
          <AppRoute
            path="/master/payment-method"
            component={MasterPaymentMethod}
            page="Payment Method"
          />
          <AppRoute
            path="/master/logo-and-signature"
            component={MasterLogoAndSignature}
            page="Logo & Signature"
          />
          <AppRoute
            path="/master/account-standard"
            component={MasterAccountStandard}
            page="Account Standard"
          />
          <AppRoute
            path="/master/bank-branch-detail/:id?"
            component={MasterBankBranchDetail}
            page="Bank Branch"
          />
          <AppRoute
            path="/master/print-interest-receipt-maintain/:id?"
            component={PrintInterestReceiptMaintain}
            page="Print Interest Receipt Maintain"
          />
          <AppRoute
            path="/master/print-interest-receipt-maintain-search"
            component={PrintInterestReceiptMaintainSearch}
            page="Print Interest Receipt Maintain Search"
          />
          <AppRoute
            path="/master/print-interest-receipt-type"
            component={PrintInterestReceiptType}
            page="Print Interest Receipt Type"
          />
          <AppRoute
            path="/master/bank-account-of-bank"
            component={BankAccountOfBank}
            page="Bank Account of Bank"
          />
          <AppRoute
            path="/master/bank-account-of-bank-detail/:id?"
            component={BankAccountOfBankDetail}
            page="Bank Account of Bank"
          />

          <AppRoute
            path="/bank-guarantee/search-request"
            component={SearchRequestBg}
            page="BG Request"
          />
          <AppRoute path="/bank-guarantee/request/:id?" component={RequestBg} page="BG Request" />
          <AppRoute path="/bank-guarantee/amend/:id?" component={RequestBg} page="BG Request" />
          <AppRoute path="/bank-guarantee/manage" component={ComingSoon} page="BG Manage" />
          <AppRoute
            path="/bank-guarantee/maintain-bank"
            component={MaintainBank}
            page="Maintain Beneficiary"
          />
          <AppRoute
            path="/bank-guarantee/maintain-branch"
            component={MaintainBranch}
            page="Maintain Branch"
          />
          <AppRoute
            path="/bank-guarantee/search-maintain-form"
            component={SearchMaintainForm}
            page="Maintain Form"
          />
          <AppRoute
            path="/bank-guarantee/maintain-form/:id?"
            component={MaintainForm}
            page="Maintain Form"
          />

          <AppRoute path="/poa-management/poa" component={POA} page="POA" />

          <AppRoute
            path="/report/cash-management"
            component={ComingSoon}
            page="Report Cash Management"
          />
          <AppRoute
            path="/report/loan-investment"
            component={LoanReport}
            page="Report Loan&Investment"
          />
          <AppRoute
            path="/report/loan-intercom"
            component={LoanReportIntercom}
            page="Report Loan Intercom"
          />
          <AppRoute
            path="/report/fx-management"
            component={ComingSoon}
            page="Report FX Management"
          />
          <AppRoute
            path="/report/bank-guarantee-management"
            component={ReportBg}
            page="Report Bank Guarantee Management"
          />
          <AppRoute
            path="/report/account-saving"
            component={ReportAccountSaving}
            page="Account Saving"
          />
          <AppRoute
            path="/report/interest-receipt"
            component={ReportInterestReceipt}
            page="Interest Receipt"
          />
          <AppRoute path="/report/other" component={OtherReport} page="Other" />
          <AppRoute
            path="/bank-guarantee/report/:id/:reportId?"
            component={RequestBg}
            page="BG Request"
          />

          <AppRoute path="/form/tt-remittance" component={ComingSoon} page="Form T/T Remittance" />
          <AppRoute
            path="/form/bank-guarantee-management"
            component={ComingSoon}
            page="Form Bank Guarantee Management"
          />

          <AppRoute
            path="/automate/maintain-balance"
            component={SearchMaintainBalance}
            page="Maintain Balance"
          />
          <AppRoute
            path="/automate/maintain-interest"
            component={SearchMaintainInterest}
            page="Maintain Interest"
          />
          <AppRoute
            path="/automate/maintain-interest-detail/:id?"
            component={MaintainInterest}
            page="Maintain Interest"
          />
          <AppRoute path="/automate/exposure" component={SearchAutomateExposure} page="Exposure" />
          <AppRoute
            path="/automate/exposure-detail/:id?"
            component={AutomateExposure}
            page="Exposure"
          />

          <AppRoute path="/interface/log-error" component={LogError} page="Log Error" />

          <AppRoute path="/form/file-upload" component={FileUpload} page="File Upload" />

          <AppRoute path="/403" component={AccessDenied} page="403" />
          <Route component={PageNotFound} />
        </Switch>
      </Provider>
    );
  }
}

export default Root;

class AccessDenied extends Component {
  render() {
    return (
      <div style={{ textAlign: 'center' }}>
        <h1 style={{ fontSize: '72px' }}>
          <span style={{ color: 'green' }}>O</span>
          <span style={{ color: 'pink' }}>o</span>
          <span style={{ color: 'blue' }}>p</span>
          <span style={{ color: 'red' }}>s</span>
          <span style={{ color: 'violet' }}>!</span>
        </h1>
        <h2 style={{ color: 'red', fontSize: '64px' }}>Access Denied.</h2>
        <img src={BgAcessDenied} />
      </div>
    );
  }
}

class ComingSoon extends Component {
  render() {
    return (
      <div style={{ textAlign: 'center', paddingTop: '10%', paddingBottom: '10%' }}>
        <h1 style={{ fontSize: '72px' }}>
          <i className="icon icon-lock icon-fw icon-lg" />
        </h1>
        <h2 style={{ fontSize: '45px' }}>Coming soon...</h2>
      </div>
    );
  }
}

function PageNotFound() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: '72px' }}>
        <span style={{ color: 'green' }}>S</span>
        <span style={{ color: 'pink' }}>O</span>
        <span style={{ color: 'blue' }}>R</span>
        <span style={{ color: 'red' }}>R</span>
        <span style={{ color: 'violet' }}>Y</span>
      </h1>
      <h2 style={{ color: 'red', fontSize: '64px' }}>Page Not Found.</h2>
      <img src={BgPageNotFound} />
    </div>
  );
}
