import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_BANK_ENDING_BALANCE = '@@request/BANK_ENDING_BALANCE_SEARCH';
export const RECEIVE_SEARCH_BANK_ENDING_BALANCE = '@@receive/BANK_ENDING_BALANCE_SEARCH';
export const FAIL_SEARCH_BANK_ENDING_BALANCE = '@@fail/BANK_ENDING_BALANCE_SEARCH';

export const REQUEST_IMPORT_EXCEL = '@@request/BANK_ENDING_BALANCE_IMPORT_EXCEL';
export const RECEIVE_IMPORT_EXCEL = '@@receive/BANK_ENDING_BALANCE_IMPORT_EXCEL';
export const FAIL_IMPORT_EXCEL = '@@fail/BANK_ENDING_BALANCE_IMPORT_EXCEL';

export const REQUEST_SUBMIT_ENDING_BALANCE = '@@request/SUBMIT_ENDING_BALANCE';
export const RECEIVE_SUBMIT_ENDING_BALANCE =
  '@@receive-The transaction was successfully saved/SUBMIT_ENDING_BALANCE';
export const FAIL_SUBMIT_ENDING_BALANCE = '@@fail/SUBMIT_ENDING_BALANCE';

export const REQUEST_SUBMIT_BEGINNING_BALANCE = '@@request/SUBMIT_BEGINNING_BALANCE';
export const RECEIVE_SUBMIT_BEGINNING_BALANCE =
  '@@receive-The transaction was successfully saved/SUBMIT_BEGINNING_BALANCE';
export const FAIL_SUBMIT_BEGINNING_BALANCE = '@@fail/SUBMIT_BEGINNING_BALANCE';

export const REQUEST_SAVE_IMPORT_EXCEL = '@@request/SAVE_IMPORT_EXCEL';
export const RECEIVE_SAVE_IMPORT_EXCEL =
  '@@receive-The transaction was successfully imported/SAVE_IMPORT_EXCEL';
export const FAIL_SAVE_IMPORT_EXCEL = '@@fail/SAVE_IMPORT_EXCEL';

export const REQUEST_FETCH_EMAIL_BANK_ENDING_BALANCE = '@@request/FETCH_EMAIL_BANK_ENDING_BALANCE';
export const RECEIVE_FETCH_EMAIL_BANK_ENDING_BALANCE =
  '@@receive-The transaction was successfully fetched/FETCH_EMAIL_BANK_ENDING_BALANCE';
export const FAIL_FETCH_EMAIL_BANK_ENDING_BALANCE = '@@fail/FETCH_EMAIL_BANK_ENDING_BALANCE';

export const EDIT_BANK_ENDING_BALANCE_SEACH_RESULT = 'EDIT_BANK_ENDING_BALANCE_SEACH_RESULT';

export const fetchEmail = (criteria, isMillion) => ({
  [RSAA]: {
    endpoint: endpoint('/BankendingBalance/FetchEmailBankEndingBalance', criteria),
    method: 'GET',
    types: [
      REQUEST_FETCH_EMAIL_BANK_ENDING_BALANCE,
      RECEIVE_FETCH_EMAIL_BANK_ENDING_BALANCE,
      FAIL_FETCH_EMAIL_BANK_ENDING_BALANCE,
    ],
  },
});

export const editSearchResult = (name, value, index) => ({
  type: EDIT_BANK_ENDING_BALANCE_SEACH_RESULT,
  payload: {
    name,
    value,
    index,
  },
});

export const saveImportExcel = (data) => (dispatch) =>
  dispatch({
    [RSAA]: {
      endpoint: endpoint('/BankendingBalance/Save'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [REQUEST_SAVE_IMPORT_EXCEL, RECEIVE_SAVE_IMPORT_EXCEL, FAIL_SAVE_IMPORT_EXCEL],
    },
  });

export const importExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/BBL'),
    method: 'POST',
    body: form,
    types: [REQUEST_IMPORT_EXCEL, RECEIVE_IMPORT_EXCEL, FAIL_IMPORT_EXCEL],
  },
});

export const submitEndingBalance = (data, index, criteria, isMillion) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/BankendingBalance/SubmitEndingBalance'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_SUBMIT_ENDING_BALANCE,
        {
          type: RECEIVE_SUBMIT_ENDING_BALANCE,
          payload: (action) => ({
            ...data,
            index,
          }),
        },
        FAIL_SUBMIT_ENDING_BALANCE,
      ],
    },
  });

  if (!actionResponse.error) return dispatch(searchBankEndingBalance(criteria, isMillion));

  return actionResponse;
};

export const submitBeginningBalance = (data, index, criteria, isMillion) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/BankendingBalance/SubmitBeginningBalance'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_SUBMIT_BEGINNING_BALANCE,
        {
          type: RECEIVE_SUBMIT_BEGINNING_BALANCE,
          payload: (action) => ({
            ...data,
            index,
          }),
        },
        FAIL_SUBMIT_BEGINNING_BALANCE,
      ],
    },
  });

  if (!actionResponse.error) return dispatch(searchBankEndingBalance(criteria, isMillion));

  return actionResponse;
};

export const searchBankEndingBalance = (criteria, isMillion) => ({
  [RSAA]: {
    endpoint: endpoint('/BankendingBalance', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_BANK_ENDING_BALANCE,
      {
        type: RECEIVE_SEARCH_BANK_ENDING_BALANCE,
        payload: (action, state, response) =>
          response.json().then((json) =>
            json.map((m) => ({
              ...m,
              IsMillion: isMillion,
            }))
          ),
      },
      FAIL_SEARCH_BANK_ENDING_BALANCE,
    ],
  },
});
