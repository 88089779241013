import React from 'react';

import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import FormGroup3 from '../../../common/FormGroup3';
import DatePicker from '../../../common/DatePicker';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { MASTER_COMPANY } from '../../../reducers/master/action';

const colX = ['col-md-4', 'col-md-5'];
const colX2 = ['col-md-4', 'col-md-7'];

export default ({ modalRef, state, onSubmit, onChageModal, inputMaskAmount }) => (
  <Modal textHeader="Assign" ref={modalRef} bgHeader="bg-primary">
    <form onSubmit={onSubmit} className="form-horizontal">
      <FormGroup3 text="Company" col={colX2} required>
        <MasterDropdown
          masterType={MASTER_COMPANY}
          noValidateOption
          saveLocalState
          onChange={(e) =>
            onChageModal({
              target: e,
            })
          }
          isChoose
          notMultipleSelect2
          notMultiple={false}
          value={state.Assign}
          name="Assign"
          required
          disabled={false}
        />
      </FormGroup3>

      <FormGroup3 text="Product Type" col={colX} required>
        <MasterDropdownUI
          onChange={(e) =>
            onChageModal({
              target: e,
            })
          }
          value={state.ProductTypes}
          name="ProductTypes"
          required
          disabled={false}
          options={['PN', 'Contract On Call', 'Contract Loan', 'BG']}
        />
      </FormGroup3>

      <FormGroup3 text="Amount" col={colX} required>
        <InputMask
          className="form-control"
          format="currency"
          option={inputMaskAmount}
          onChange={onChageModal}
          value={state.Amount}
          required
          name="Amount"
        />
      </FormGroup3>

      <FormGroup3 text="Valid From" col={colX} required>
        <DatePicker
          value={state.ValidFromStr}
          onChange={onChageModal}
          name="ValidFromStr"
          required
          option={{
            // startDate: "d",
            // daysOfWeekDisabled: "0,6",
            todayHighlight: true,
          }}
        />
      </FormGroup3>

      <FormGroup3 text="Valid To" col={colX} required={false}>
        <DatePicker
          value={state.ValidToStr}
          onChange={onChageModal}
          name="ValidToStr"
          required={false}
          option={{
            // startDate: "d",
            // daysOfWeekDisabled: "0,6",
            todayHighlight: true,
          }}
        />
      </FormGroup3>
      <br />
      <div>
        <center>
          <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
        </center>
      </div>
    </form>
  </Modal>
);
