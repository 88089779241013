import { defaultImportExcelResult } from '../configuration';
import {
  RESET_IMPORT_CHEQUE_EXCEL,
  RECEIVE_IMPORT_Cheque_EXCEL,
  SET_SELECTED_BANK_TRANSFER_SUMMARY,
  EDIT_TRANSFER_SUMMARY_SEACH_RESULT,
  RECEIVE_SEARCH_BANK_TRANSFER_SUMMARY,
  SET_SELECTED_ALL_BANK_TRANSFER_SUMMARY,
  UPDATE_SEARCH_RESULT_BANK_TRANSFER_SUMMARY,
} from './action';

const init = {
  searchResult: [],
  chequeImportExcel: defaultImportExcelResult,
};

export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_RESULT_BANK_TRANSFER_SUMMARY:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case RESET_IMPORT_CHEQUE_EXCEL:
      return {
        ...state,
        chequeImportExcel: defaultImportExcelResult,
      };
    case EDIT_TRANSFER_SUMMARY_SEACH_RESULT:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i)
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          return m;
        }),
      };
    case RECEIVE_SEARCH_BANK_TRANSFER_SUMMARY:
      return {
        ...state,
        searchResult: action.payload,
      };
    case RECEIVE_IMPORT_Cheque_EXCEL:
      return {
        ...state,
        chequeImportExcel: action.payload,
      };
    case SET_SELECTED_BANK_TRANSFER_SUMMARY:
      return {
        ...state,
        searchResult: [
          ...state.searchResult.slice(0, action.payload.index),
          {
            ...state.searchResult[action.payload.index],
            IsSelected: action.payload.isSelected,
          },
          ...state.searchResult.slice(action.payload.index + 1),
        ],
      };
    case SET_SELECTED_ALL_BANK_TRANSFER_SUMMARY:
      return {
        ...state,
        searchResult: [
          ...state.searchResult.map((m) => ({
            ...m,
            IsSelected: action.payload,
          })),
        ],
      };
    default:
      return state;
  }
};
