import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MAINTAIN_FORM_CRITERIA = 'EDIT_MAINTAIN_FORM_CRITERIA';
export const CLEAR_MAINTAIN_FORM_CRITERIA = 'CLEAR_MAINTAIN_FORM_CRITERIA';

export const UPDATE_SEARCH_RESULT_MAINTAIN_FORM = 'UPDATE_SEARCH_RESULT_MAINTAIN_FORM';

export const REQUEST_SEARCH_MAINTAIN_FORM = '@@request/SEARCH_MAINTAIN_FORM';
export const RECEIVE_SEARCH_MAINTAIN_FORM = '@@receive/SEARCH_MAINTAIN_FORM';
export const FAIL_SEARCH_MAINTAIN_FORM = '@@fail/SEARCH_MAINTAIN_FORM';

export const REQUEST_SAVE_MAINTAIN_FORM = '@@request/SAVE_MAINTAIN_FORM';
export const RECEIVE_SAVE_MAINTAIN_FORM = '@@receive/SAVE_MAINTAIN_FORM';
export const FAIL_SAVE_MAINTAIN_FORM = '@@fail/SAVE_MAINTAIN_FORM';

export const REQUEST_DELETE_MAINTAIN_FORM = '@@request/DELETE_MAINTAIN_FORM';
export const RECEIVE_DELETE_MAINTAIN_FORM =
  '@@receive-The record was successfully saved/DELETE_MAINTAIN_FORM';
export const FAIL_DELETE_MAINTAIN_FORM = '@@fail/DELETE_MAINTAIN_FORM';

export const REQUEST_GET_MAINTAIN_FORM_DETAIL = '@@request/GET_MAINTAIN_FORM_DETAIL';
export const RECEIVE_GET_MAINTAIN_FORM_DETAIL = '@@receive/GET_MAINTAIN_FORM_DETAIL';
export const FAIL_GET_MAINTAIN_FORM_DETAIL = '@@fail/GET_MAINTAIN_FORM_DETAIL';

export const REQUEST_GET_MASTER_LG_TYPE = '@@request/GET_MASTER_LG_TYPE';
export const RECEIVE_GET_MASTER_LG_TYPE = '@@receive/GET_MASTER_LG_TYPE';
export const FAIL_GET_MASTER_LG_TYPE = '@@fail/GET_MASTER_LG_TYPE';

export const REQUEST_GET_MAINTAIN_BG_FORM_HEADER = '@@request/GET_MAINTAIN_BG_FORM_HEADER';
export const RECEIVE_GET_MAINTAIN_BG_FORM_HEADER = '@@receive/GET_MAINTAIN_BG_FORM_HEADER';
export const FAIL_GET_MAINTAIN_BG_FORM_HEADER = '@@fail/GET_MAINTAIN_BG_FORM_HEADER';

export const REQUEST_GET_MASTER_LG_TYPE_DETAIL = '@@request/GET_MASTER_LG_TYPE_DETAIL';
export const RECEIVE_GET_MASTER_LG_TYPE_DETAIL = '@@receive/GET_MASTER_LG_TYPE_DETAIL';
export const FAIL_GET_MASTER_LG_TYPE_DETAIL = '@@fail/GET_MASTER_LG_TYPE_DETAIL';

export const getMasterLgTypeDetail = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasterlgtype`, criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_LG_TYPE_DETAIL,
      RECEIVE_GET_MASTER_LG_TYPE_DETAIL,
      FAIL_GET_MASTER_LG_TYPE_DETAIL,
    ],
  },
});

export const getMaintainFormHeader = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getmaintainbgform', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_BG_FORM_HEADER,
      RECEIVE_GET_MAINTAIN_BG_FORM_HEADER,
      FAIL_GET_MAINTAIN_BG_FORM_HEADER,
    ],
  },
});

export const getMasterLgType = (criteria = { Mode: 'master' }) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasterlgtype`, criteria),
    method: 'GET',
    types: [REQUEST_GET_MASTER_LG_TYPE, RECEIVE_GET_MASTER_LG_TYPE, FAIL_GET_MASTER_LG_TYPE],
  },
});

export const getMaintainFormDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/getmaintainbgformdetail/`, data),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_FORM_DETAIL,
      RECEIVE_GET_MAINTAIN_FORM_DETAIL,
      FAIL_GET_MAINTAIN_FORM_DETAIL,
    ],
  },
});

export const deleteMaintainForm = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/deletemaintainform`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [REQUEST_DELETE_MAINTAIN_FORM, RECEIVE_DELETE_MAINTAIN_FORM, FAIL_DELETE_MAINTAIN_FORM],
  },
});

export const saveMaintainForm = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/savemaintainbgform`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MAINTAIN_FORM, RECEIVE_SAVE_MAINTAIN_FORM, FAIL_SAVE_MAINTAIN_FORM],
  },
});

export const searchMaintainForm = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getmaintainbgform', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_MAINTAIN_FORM, RECEIVE_SEARCH_MAINTAIN_FORM, FAIL_SEARCH_MAINTAIN_FORM],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MAINTAIN_FORM,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MAINTAIN_FORM_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MAINTAIN_FORM_CRITERIA,
  payload: {
    name,
    value,
  },
});
