import React, { Component } from 'react';

import Modal from './Modal';
import Button from './Button';

export default class ModalFunction2 extends Component {
  textColor = {
    success: 'text-success',
    warning: 'text-warning',
    info: 'text-info',
    danger: 'text-danger',
    primary: 'text-primary',
    brown: 'text-brown',
  };

  btnColor = {
    success: 'btn btn-success',
    warning: 'btn btn-warning',
    info: 'btn btn-info',
    danger: 'btn btn-danger',
    primary: 'btn btn-primary',
    brown: 'btn btn-lightbrown',
  };

  render() {
    const {
      icon = '',
      type = '',
      textHeader = '',
      textContent = '',
      textBtn = 'Confirm',
      textBtnBack = 'Back',
      selectedLength = 0,
      passLength = 0,
      showFail = false,
      noSelect = false,
      result = [],
    } = this.props;

    const failLength = selectedLength - passLength;
    const size = noSelect ? 'modal-sm' : 'modal-md';

    return (
      <div>
        <Modal size={size} ref={this.props.modalRef} modalFooter>
          <div className="text-center">
            <span className={`${this.textColor[type]} icon ${icon} icon-5x`} />
            <h3 className={this.textColor[type]}>{textHeader}</h3>
            {noSelect ? (
              <p>Do you want to {textContent} this item?</p>
            ) : !showFail || failLength === 0 ? (
              <p>
                {selectedLength} item(s) selected, click to {textContent}?
              </p>
            ) : result.length ? (
              <div style={{ marginTop: 20 }}>
                <p>
                  {selectedLength} item(s) selected, click to {textContent}?
                </p>
                <div className="text-left">
                  <div className="form-group col-xs-12">
                    <div className="col-xs-3 text-right">
                      <span className="text-success icon icon-check icon-1x" />
                    </div>
                    <div className="col-xs-9">pass {passLength} item(s) selected.</div>
                  </div>
                  {result.map((m, i) => (
                    <div className="form-group col-xs-12" key={i}>
                      <div className="col-xs-3 text-right">
                        <span className="text-danger icon icon-close icon-1x" />
                      </div>
                      <div className="col-xs-9">fail {m}</div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div style={{ marginTop: 20 }}>
                <p>
                  {selectedLength} items selected, will you make {textContent}?
                </p>
                <p>
                  <span className="text-success icon icon-check icon-1x" /> pass {passLength} items
                  selected.
                </p>
                <p>
                  <span className="text-danger icon icon-close icon-1x" /> fail {failLength} items
                  selected.
                </p>
              </div>
            )}
            <div className="m-t-lg">
              <Button txt={textBtn} className={this.btnColor[type]} onClick={this.props.onClick} />
              &nbsp;
              <Button txt={textBtnBack} className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
