import React from 'react';

const row = 'row gutter-xs';

export default ({ col, children = [] }) => (
  <div className={row}>
    <div className={col}>{children[0] || children}</div>
    {children[1] ? <div className={col}>{children[1] || children}</div> : ''}
    {children[2] ? <div className={col}>{children[2] || children}</div> : ''}
  </div>
);
