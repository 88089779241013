import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_AUTO_TRANSFER = '@@request/SEARCH_AUTO_TRANSFER';
export const RECEIVE_SEARCH_AUTO_TRANSFER = '@@receive/SEARCH_AUTO_TRANSFER';
export const FAIL_SEARCH_AUTO_TRANSFER = '@@fail/SEARCH_AUTO_TRANSFER';

export const REQUEST_SUBMIT_AUTO_TRANSFER = '@@request/SUBMIT_AUTO_TRANSFER';
export const RECEIVE_SUBMIT_AUTO_TRANSFER =
  '@@receive-The transaction was successfully saved/SUBMIT_AUTO_TRANSFER';
export const FAIL_SUBMIT_AUTO_TRANSFER = '@@fail/SUBMIT_AUTO_TRANSFER';

export const SET_SELECTED_ALL_AUTO_TRANSFER = 'SET_SELECTED_ALL_AUTO_TRANSFER';
export const SET_VALUE_SEARCH_AUTO_TRANSFER = 'SET_VALUE_SEARCH_AUTO_TRANSFER';
export const SET_ALL_TRANSFER_METHOD_VALUE_SEARCH_AUTO_TRANSFER =
  'SET_ALL_TRANSFER_METHOD_VALUE_SEARCH_AUTO_TRANSFER';

export const CLEAR_SEARCH_AUTO_TRANSFER = 'CLEAR_SEARCH_AUTO_TRANSFER';

export const clearResult = () => ({
  type: CLEAR_SEARCH_AUTO_TRANSFER,
});

export const setAllTransferMethod = (value, index) => ({
  type: SET_ALL_TRANSFER_METHOD_VALUE_SEARCH_AUTO_TRANSFER,
  payload: value,
});

export const setValueSearchAutoTransfer = (name, value, index) => ({
  type: SET_VALUE_SEARCH_AUTO_TRANSFER,
  payload: {
    name,
    value,
    index,
  },
});

export const setSelectedAllAutoTransfer = (selected) => ({
  type: SET_SELECTED_ALL_AUTO_TRANSFER,
  payload: {
    isChecked: selected,
  },
});

export const searchAutoTransfer = (criteria) => {
  criteria.Companys = criteria.Companys || [];
  return {
    [RSAA]: {
      endpoint: endpoint('/cashposition/autotransfer'),
      method: 'POST',
      body: JSON.stringify(criteria),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_SEARCH_AUTO_TRANSFER,
        RECEIVE_SEARCH_AUTO_TRANSFER,
        FAIL_SEARCH_AUTO_TRANSFER,
      ],
    },
  };
};

export const submitAutoTransfer = (datas, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/cashposition/submitautotransfer'),
      method: 'POST',
      body: JSON.stringify(datas),
      headers: jsonContentTypeHeader,
      types: [
        REQUEST_SUBMIT_AUTO_TRANSFER,
        RECEIVE_SUBMIT_AUTO_TRANSFER,
        FAIL_SUBMIT_AUTO_TRANSFER,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(searchAutoTransfer(criteria));

  return actionResponse;
};
