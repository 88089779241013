import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_GET_REPORT_TO_BANK = '@@request/GET_REPORT_TO_BANK';
export const RECEIVE_GET_REPORT_TO_BANK = '@@receive/GET_REPORT_TO_BANK';
export const FAIL_GET_REPORT_TO_BANK = '@@fail/GET_REPORT_TO_BANK';

export const REQUEST_GET_BANK_POSO = '@@request/GET_BANK_POSO';
export const RECEIVE_GET_BANK_POSO = '@@receive/GET_BANK_POSO';
export const FAIL_GET_BANK_POSO = '@@fail/GET_BANK_POSO';

export const REQUEST_DOWNLOAD_REPORT_TO_BANK = '@@request/DOWNLOAD_REPORT_TO_BANK';
export const RECEIVE_DOWNLOAD_REPORT_TO_BANK = '@@receive/DOWNLOAD_REPORT_TO_BANK';
export const FAIL_DOWNLOAD_REPORT_TO_BANK = '@@fail/DOWNLOAD_REPORT_TO_BANK';

export const REQUEST_SEND_EMAIL_REPORT_TO_BANK = '@@request/SEND_EMAIL_REPORT_TO_BANK';
export const RECEIVE_SEND_EMAIL_REPORT_TO_BANK = '@@receive/SEND_EMAIL_REPORT_TO_BANK';
export const FAIL_SEND_EMAIL_REPORT_TO_BANK = '@@fail/SEND_EMAIL_REPORT_TO_BANK';

export const REQUEST_UPLOAD_VIEW_REPORT_TO_BANK = '@@request/UPLOAD_VIEW_REPORT_TO_BANK';
export const RECEIVE_UPLOAD_VIEW_REPORT_TO_BANK = '@@receive/UPLOAD_VIEW_REPORT_TO_BANK';
export const FAIL_UPLOAD_VIEW_REPORT_TO_BANK = '@@fail/UPLOAD_VIEW_REPORT_TO_BANK';

export const REQUEST_UPLOAD_SAVE_REPORT_TO_BANK = '@@request/UPLOAD_SAVE_REPORT_TO_BANK';
export const RECEIVE_UPLOAD_SAVE_REPORT_TO_BANK = '@@receive/UPLOAD_SAVE_REPORT_TO_BANK';
export const FAIL_UPLOAD_SAVE_REPORT_TO_BANK = '@@fail/UPLOAD_SAVE_REPORT_TO_BANK';

export const REQUEST_FETCH_EMAIL_REPORT_TO_BANK = '@@request/FETCH_EMAIL_REPORT_TO_BANK';
export const RECEIVE_FETCH_EMAIL_REPORT_TO_BANK = '@@receive/FETCH_EMAIL_REPORT_TO_BANK';
export const FAIL_FETCH_EMAIL_REPORT_TO_BANK = '@@fail/FETCH_EMAIL_REPORT_TO_BANK';

export const uploadSaveReportToBank = (datas) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SaveImportExcelReportToBank'),
    method: 'POST',
    body: JSON.stringify(datas),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_UPLOAD_SAVE_REPORT_TO_BANK,
      RECEIVE_UPLOAD_SAVE_REPORT_TO_BANK,
      FAIL_UPLOAD_SAVE_REPORT_TO_BANK,
    ],
  },
});

export const uploadViewReportToBank = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/ReportToBank'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_UPLOAD_VIEW_REPORT_TO_BANK,
      RECEIVE_UPLOAD_VIEW_REPORT_TO_BANK,
      FAIL_UPLOAD_VIEW_REPORT_TO_BANK,
    ],
  },
});

export const sendEmaileportToBank = (datas) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SendEmailReportToBank'),
    method: 'POST',
    body: JSON.stringify(datas),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SEND_EMAIL_REPORT_TO_BANK,
      RECEIVE_SEND_EMAIL_REPORT_TO_BANK,
      FAIL_SEND_EMAIL_REPORT_TO_BANK,
    ],
  },
});

export const fetchEmailReportToBank = () => ({
  [RSAA]: {
    endpoint: endpoint('/poso/FetchEmailReportToBank'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SEND_EMAIL_REPORT_TO_BANK,
      RECEIVE_SEND_EMAIL_REPORT_TO_BANK,
      FAIL_SEND_EMAIL_REPORT_TO_BANK,
    ],
  },
});

export const getExportReportToBank = (datas) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/ExportReportToBank'),
    method: 'POST',
    body: JSON.stringify(datas),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DOWNLOAD_REPORT_TO_BANK,
      {
        type: RECEIVE_DOWNLOAD_REPORT_TO_BANK,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_DOWNLOAD_REPORT_TO_BANK,
    ],
  },
});

export const getBankPoso = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/bankCode', criteria),
    method: 'GET',
    types: [REQUEST_GET_BANK_POSO, RECEIVE_GET_BANK_POSO, FAIL_GET_BANK_POSO],
  },
});

export const getReportToBank = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/GetReportToBank', criteria),
    method: 'GET',
    types: [REQUEST_GET_REPORT_TO_BANK, RECEIVE_GET_REPORT_TO_BANK, FAIL_GET_REPORT_TO_BANK],
  },
});
