import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toFixed, getPermission } from '../../common/helpper';
import { MASTER_COMPANY, MASTER_BANK_ACCOUNT } from '../../reducers/master/action';

const permission = getPermission('FX Management', 'Manage FX Contract');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const required = true;

export default ({
  criteria,
  onChangeProcess,
  onClickProcess,

  results,
  onChangeInputTable,
  onClickSave,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          FX Management <span className="icon icon-angle-double-right" />
          <Link txt="Manage FX Contract" href="/fx-management/manage-fx-contract" />
          <span className="icon icon-angle-double-right" /> Rollover Forward
        </small>
      </p>
    </div>

    <form onSubmit={onClickProcess}>
      <Card
        textHeader="Rollover Forward"
        bgHeader="bg-primary"
        cardActions={['toggler']}
        footer={
          permission ? (
            <Button txt="Process" className="btn-info" icon="search" type="submit" />
          ) : null
        }
      >
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Rollover Date" required>
              <DatePicker
                value={criteria.RolloverDateStr}
                onChange={onChangeProcess}
                option={{
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                required={required}
                name="RolloverDateStr"
              />
            </FormGroup2>
            <FormGroup2 text="SPOT Rate (Market Rate)" required>
              <InputMask
                className="form-control"
                format="currency"
                option={{
                  prefix: '',
                  digits: 8,
                  digitsOptional: false,
                  placeholder: '0.00000000',
                }}
                onChange={onChangeProcess}
                value={criteria.SpotRate}
                required={required}
                name="SpotRate"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Rollover Payment Date">
              <DatePicker
                value={criteria.RolloverPaymentDateStr}
                onChange={onChangeProcess}
                option={{
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                name="RolloverPaymentDateStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>
        <hr />
        <div className="row gutter-xs">
          <div className="col-md-3 col-sm-6">
            <FormGroup2 text="New Contract Date (Trade Date)" required>
              <DatePicker
                value={criteria.NewContractDateStr}
                onChange={onChangeProcess}
                option={{
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                required={required}
                name="NewContractDateStr"
              />
            </FormGroup2>

            <FormGroup2 text="Start Date" required>
              <DatePicker
                value={criteria.NewStartDateStr}
                onChange={onChangeProcess}
                option={{
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                required={required}
                name="NewStartDateStr"
              />
            </FormGroup2>

            <FormGroup2 text="End Date" required>
              <DatePicker
                value={criteria.NewEndDateStr}
                onChange={onChangeProcess}
                option={{
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                required={required}
                name="NewEndDateStr"
              />
            </FormGroup2>
          </div>

          <div className="col-md-3 col-sm-6">
            <FormGroup2 text="New SPOT Rate" required>
              <InputMask
                className="form-control"
                format="currency"
                option={{
                  prefix: '',
                  digits: 8,
                  digitsOptional: false,
                  placeholder: '0.00000000',
                }}
                onChange={onChangeProcess}
                value={criteria.NewSpotRate}
                required={required}
                name="NewSpotRate"
              />
            </FormGroup2>

            <FormGroup2 text="New SWAP Rate" required>
              <InputMask
                className="form-control"
                format="currency"
                option={{
                  prefix: '',
                  digits: 8,
                  digitsOptional: false,
                  placeholder: '0.00000000',
                }}
                onChange={onChangeProcess}
                value={criteria.NewSwapRate}
                required={required}
                name="NewSwapRate"
              />
            </FormGroup2>

            <FormGroup2 text="New Forward Rate" required>
              <InputMask
                className="form-control"
                format="currency"
                option={{
                  prefix: '',
                  digits: 8,
                  digitsOptional: false,
                  placeholder: '0.00000000',
                }}
                value={criteria.NewForwardRate}
                required={required}
                readOnly
                name="NewForwardRate"
              />
            </FormGroup2>
          </div>
        </div>
      </Card>
    </form>

    <form onSubmit={onClickSave}>
      <Card
        textHeader="Forward Contract List"
        bgHeader="bg-primary"
        cardActions={['toggler']}
        footer={
          <Button
            txt="Save"
            type="submit"
            icon="save"
            className="btn-success"
            disabled={results.length === 0}
          />
        }
      >
        <div className="table-responsive" style={{ maxHeight: '500px' }}>
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th className="th-success">Transaction No.</th>
                <th className="th-primary">New Forward Contract No.</th>
                <th className="th-success">
                  Company
                  <br /> Code
                </th>
                <th className="th-success">Company Name</th>
                <th className="th-success">Bank</th>
                <th className="th-success">Rollover Date</th>
                <th className="th-primary">Rollover Payment Date</th>
                <th className="th-success">Old Contract No.</th>
                <th className="text-center th-success">
                  Old Contract
                  <br /> Forward Rate
                </th>
                <th className="th-success">Old End Date</th>
                <th className="text-center th-success">
                  Old Outstanding
                  <br /> Amount
                </th>
                <th className="th-primary">Rollover Amount</th>
                <th className="th-success">Currency</th>
                <th className="th-primary">Settlement Rate</th>
                <th className="th-success">Start Date</th>
                <th className="th-success">End Date</th>
                <th className="th-success">Spot Rate</th>
                <th className="th-success">Swap Rate</th>
                <th className="th-success">Forward Rate</th>
                <th className="th-success">Product Type</th>
                <th className="th-primary">Forward Type</th>
                <th className="th-success">Purpose</th>
                <th className="th-success">Department</th>
                <th className="th-primary">Gain/Loss</th>
                <th className="th-primary">Gain/Loss Company</th>
                <th className="th-primary">Gain/Loss Bank Account</th>
              </tr>
            </thead>
            <tbody>
              {results.map((m, i) => (
                <tr key={i}>
                  <td className="text-center">{m.forwardNo}</td>
                  <td className="text-center">
                    <input
                      className="form-control"
                      onChange={(e) => onChangeInputTable(e, i)}
                      value={m.newContractNo || ''}
                      name="newContractNo"
                    />
                  </td>
                  <td className="text-center">{m.companyCode}</td>
                  <td>{m.companyName}</td>
                  <td>{m.bankName}</td>
                  <td className="text-center">{m.rollOverDateStr}</td>
                  <td className="text-center">
                    <DatePicker
                      value={m.rollOverPaymentDateStr}
                      onChange={(e) => onChangeInputTable(e, i)}
                      option={{
                        daysOfWeekDisabled: '0,6',
                        todayHighlight: true,
                      }}
                      required
                      name="rollOverPaymentDateStr"
                    />
                  </td>
                  <td>{m.oldContractNo}</td>
                  <td className="text-right">{setNumber(m.oldForwardRate, 8)}</td>
                  <td className="text-center">{m.oldEndDateStr}</td>
                  <td className="text-right">{setNumber(m.outstanding, m.currencyDigit)}</td>
                  <td className="text-right">
                    <InputMask
                      key={`${m.oldForwardNo}${m.outstanding || 0}`}
                      className="form-control width-input-table"
                      onChange={(e) => onChangeInputTable(e, i)}
                      format="currency"
                      option={{
                        prefix: '',
                        digits: m.currencyDigit,
                        max: m.outstanding,
                        digitsOptional: false,
                        placeholder: !m.currencyDigit
                          ? '0'
                          : `0.${`000${m.currencyDigit}`.slice(-3)}`,
                      }}
                      value={m.rollOverAmount}
                      required
                      name="rollOverAmount"
                    />
                  </td>
                  <td>{m.currency}</td>
                  <td className="text-right">
                    <InputMask
                      className="form-control width-input-table"
                      onChange={(e) => onChangeInputTable(e, i)}
                      format="currency"
                      option={{
                        prefix: '',
                        digits: 8,
                        digitsOptional: false,
                        placeholder: '0.00000000',
                      }}
                      value={m.settlementRate}
                      required
                      name="settlementRate"
                    />
                  </td>
                  <td className="text-center">{m.newStartDateStr}</td>
                  <td className="text-center">{m.newEndDateStr}</td>
                  <td className="text-right">{setNumber(m.newSpotRate, 8)}</td>
                  <td className="text-right">{setNumber(m.newSwapRate, 8)}</td>
                  <td className="text-right">{setNumber(m.newForwardRate, 8)}</td>
                  <td className="text-center">{m.productType}</td>
                  <td className="text-center">
                    <MasterDropdownUI
                      className="form-control width-input-table"
                      value={m.forwardType}
                      onChange={(e) => onChangeInputTable(e, i)}
                      notMultiple
                      name="forwardType"
                      options={['Fixed', 'Par', 'Pro Rata']}
                    />
                  </td>
                  <td className="text-center">{m.purpose}</td>
                  <td>{m.department}</td>
                  <td className="text-right">
                    <InputMask
                      className="form-control width-input-table"
                      onChange={(e) => onChangeInputTable(e, i)}
                      format="currency"
                      option={{
                        prefix: '',
                        digits: m.currencyDigit,
                        // max: m.outstanding,
                        digitsOptional: false,
                        placeholder: !m.currencyDigit
                          ? '0'
                          : `0.${`000${m.currencyDigit}`.slice(-3)}`,
                      }}
                      value={m.gainLossAmount}
                      required
                      name="gainLossAmount"
                    />
                  </td>
                  {m.productType === 'Forward Special' ? (
                    <td>
                      <MasterDropdown
                        masterType={MASTER_COMPANY}
                        isChoose
                        noValidateOption
                        notMultipleSelect2
                        value={m.gainLossCompanyCode}
                        onChange={(e) =>
                          onChangeInputTable(
                            {
                              target: e,
                            },
                            i
                          )
                        }
                        required
                        name="gainLossCompanyCode"
                      />
                    </td>
                  ) : (
                    <td>
                      <MasterDropdown
                        masterType={MASTER_COMPANY}
                        notMultipleSelect2
                        value={m.gainLossCompanyCode}
                        disabled
                      />
                    </td>
                  )}
                  <td>
                    <MasterDropdown
                      masterType={MASTER_BANK_ACCOUNT}
                      noValidateOption
                      notMultipleSelect2
                      value={m.gainLossBankAccountNo}
                      onChange={(e) =>
                        onChangeInputTable(
                          {
                            target: e,
                          },
                          i
                        )
                      }
                      customeLabel={(m) =>
                        `${m.bankCode} | ${m.branchName} | ${m.accountTypeCode} | ${m.currency} | ${m.bankAccountNo}`
                      }
                      required
                      saveLocalState
                      forUnwindRollOver
                      bankAbbreviate={m.bankAbbreviate}
                      currency={m.currency}
                      companyCode={m.gainLossCompanyCode}
                      customeValue={(m) => m.bankAccountNo}
                      name="gainLossBankAccountNo"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </form>
  </div>
);

const setNumber = (number, float = 3) => {
  if (isNaN(number)) return '';
  if (number >= 0) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
};
