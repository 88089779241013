import { connect } from 'react-redux';
import React, { Component } from 'react';

import AutomateExposure from './AutomateExposure';
import { addAlert } from '../../reducers/layout/action';
import { toUpperKey, checkAfterValueDate } from '../../common/helpper';
import {
  getAutomateExposure,
  saveAutomateExposure,
  getAutomateExposureffectiveDate,
} from '../../reducers/automateExposure/action';

const $ = window.jQuery;

const initData = {
  EffectiveDateStr: '',
  TempEffectiveDateStr: 'New',

  CashType: '',
  FinanceGroupId: '',
  BuId: '',
  CompanyCode: '',
  CurrencyFrom: '',
  CurrencyTo: '',
  AutoAssignForward: '',
  AutoSplitSpot: '',
  BankSpotCode: '',

  // Equivalent: "",
  MethodEquivalent: '',
  TransferMethodEquivalent: '',
  TimeAutoAssign: '',
  InvoiceNo: '',

  PaidForService: '',
  PaidForGoods: '',
  PaidForProject: '',
  PaidForStLoan: '',
  PaidForLtLoan: '',
  PaidForCapital: '',
  PaidForDividend: '',
  PaidForOther: '',

  TransferMethodService: null,
  TransferMethodGoods: null,
  TransferMethodProject: null,
  TransferMethodStLoan: null,
  TransferMethodLtLoan: null,
  TransferMethodCapital: null,
  TransferMethodDividend: null,
  TransferMethodOther: null,

  IsBankRemarkBen: false,
  IsBankRemarkSha: false,
  IsBankRemarkOur: false,
  IsBankRemarkBlank: false,

  Mode: 'create',
};

class AutomateExposureContainer extends Component {
  state = {
    ...initData,
    resultEffective: [],
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState({
        ...initData,
      });
    else {
      this.fetchId(id);
    }
  }

  fetchId(id) {
    if (!id) return;

    this.props.getAutomateExposureffectiveDate({ BuComp: id, Mode: 'date' }).then((response) => {
      if (response.error || !response.payload) return;

      const result = response.payload.map((m) => m.effectiveDateStr);
      this.setState({
        ...initData,
        resultEffective: result,
        Mode: 'edit',
      });

      const result2 = result.filter((f) => {
        if (!checkAfterValueDate(f, 0)) return true;
        return false;
      });

      if (result2.length === 0) {
        this.setAutomateExposure(result[result.length - 1]);
      } else {
        const effectiveDate = result2[0];
        this.setAutomateExposure(effectiveDate);
      }
    });
  }

  setAutomateExposure(effectiveDateStr) {
    const { id } = this.props.routeProp.match.params;

    this.props
      .getAutomateExposure({
        BuComp: id,
        EffectiveDateStr: effectiveDateStr,
        Mode: 'detail',
      })
      .then((response) => {
        if (response.error || !response.payload) return;

        const fetchData = toUpperKey(response.payload);
        this.setState({
          ...fetchData,
          Mode: 'edit',
          TempEffectiveDateStr: fetchData.EffectiveDateStr,
          setOverDueDate: !checkAfterValueDate(fetchData.EffectiveDateStr, 0),
        });
      });
  }

  onChangeInputData = (e) => {
    const { name, value, checked } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'CashType') {
        if (value === 'Inward') {
          stateToUpdate.PaidForService = '';
          stateToUpdate.PaidForGoods = '';
          stateToUpdate.PaidForProject = '';
          stateToUpdate.PaidForStLoan = '';
          stateToUpdate.PaidForLtLoan = '';
          stateToUpdate.PaidForCapital = '';
          stateToUpdate.PaidForDividend = '';
          stateToUpdate.PaidForOther = '';

          stateToUpdate.TransferMethodService = null;
          stateToUpdate.TransferMethodGoods = null;
          stateToUpdate.TransferMethodProject = null;
          stateToUpdate.TransferMethodStLoan = null;
          stateToUpdate.TransferMethodLtLoan = null;
          stateToUpdate.TransferMethodCapital = null;
          stateToUpdate.TransferMethodDividend = null;
          stateToUpdate.TransferMethodOther = null;

          stateToUpdate.AutoSplitSpot = false;
          stateToUpdate.BankSpotCode = '';
        } else {
          stateToUpdate.TimeAutoAssign = '';
          stateToUpdate.InvoiceNo = '';
          stateToUpdate.IsBankRemarkBen = false;
          stateToUpdate.IsBankRemarkSha = false;
          stateToUpdate.IsBankRemarkOur = false;
          stateToUpdate.IsBankRemarkBlank = false;
        }
      } else if (name === 'AutoAssignForward') {
        stateToUpdate[name] = value === 'true';
      } else if (name === 'AutoSplitSpot') {
        stateToUpdate[name] = value === 'true';
        if (value === 'false') {
          stateToUpdate.BankSpotCode = null;
        }
      } else if (
        ~['IsBankRemarkBen', 'IsBankRemarkSha', 'IsBankRemarkOur', 'IsBankRemarkBlank'].indexOf(
          name
        )
      ) {
        stateToUpdate[name] = checked;
      } else if (
        ~[
          'PaidForService',
          'PaidForGoods',
          'PaidForProject',
          'PaidForStLoan',
          'PaidForLtLoan',
          'PaidForCapital',
          'PaidForDividend',
          'PaidForOther',
        ].indexOf(name)
      ) {
        stateToUpdate[name] = checked;
        if (!checked) {
          const type = `TransferMethod${name.slice(7, name.length)}`;
          stateToUpdate[type] = null;
        }
      } else if (name === 'Equivalent') {
        if (value === 'No Auto') {
          stateToUpdate.MethodEquivalent = null;
          stateToUpdate.TransferMethodEquivalent = null;
        }
      }
      return stateToUpdate;
    });

    if (name === 'TempEffectiveDateStr') {
      if (value === 'New') {
        this.setState({
          EffectiveDateStr: '',
          setOverDueDate: false,
          IsActive: true,
        });
      } else {
        this.setAutomateExposure(value);
      }
    }
  };

  onSubmitHander = (e) => {
    e.preventDefault();

    const state = { ...this.state };

    this.props.saveAutomateExposure(this.state).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        window.location.href = `/automate/exposure-detail/${response.payload}`;
      } else {
        this.fetchId(response.payload);
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/automate/exposure');
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,
    };

    return (
      <div>
        <AutomateExposure
          {...props}
          state={this.state}
          resultEffective={this.state.resultEffective}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.automateExposure,
  }),
  {
    addAlert,
    saveAutomateExposure,
    getAutomateExposureffectiveDate,
    getAutomateExposure,
  }
)(AutomateExposureContainer);
