import { Link } from 'react-router-dom';
import React, { Component } from 'react';

class NavItem extends Component {
  state = {
    active: this.props.pageCurrent === this.props.pageName,
  };

  componentDidMount() {
    this.setActiveSindebar();
  }

  setActiveSindebar() {
    const { pageCurrent, pageName } = this.props;
    this.setState(
      {
        active: pageName === pageCurrent,
      },
      () => {
        if (this.state.active && this.props.setActive) this.props.setActive();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { pageCurrent } = this.props;
    if (pageCurrent !== prevProps.pageCurrent) {
      this.setActiveSindebar();
    }
  }

  setActive = () => {
    this.setState({
      active: true,
    });
  };

  onClickToggleSubmenu = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  render() {
    if ((this.props.subItems || []).length > 0) {
      return (
        <li className={`sidenav-item has-subnav ${this.state.active ? 'open' : ''}`}>
          <a style={{ cursor: 'pointer' }} onClick={this.onClickToggleSubmenu}>
            <span className={`sidenav-icon icon ${this.props.icon}`} />
            <span className="sidenav-label">{this.props.textRender || this.props.text}</span>
          </a>
          <ul
            className="sidenav-subnav collapse"
            style={{ display: this.state.active ? 'block' : 'none' }}
          >
            {this.props.subItems.map((m, idx) => (
              <NavItem
                {...m}
                key={idx}
                pageCurrent={this.props.pageCurrent}
                setActive={this.setActive}
                subs
              />
            ))}
          </ul>
        </li>
      );
    }
    if (this.props.subs) {
      return (
        <li className={`sidenav-item ${this.state.active ? 'active' : ''}`}>
          <Link to={this.props.path}>
            <span
              className={`sidenav-icon icon ${this.props.icon}`}
              style={{ display: this.props.icon ? 'block' : 'none' }}
            />
            {this.props.textRender || this.props.text}
          </Link>
        </li>
      );
    }
    return (
      <li className={`sidenav-item ${this.state.active ? 'active' : ''}`}>
        <Link to={this.props.path}>
          <span
            className={`sidenav-icon icon ${this.props.icon}`}
            style={{ display: this.props.icon ? 'block' : 'none' }}
          />
          <span className="sidenav-label">{this.props.textRender || this.props.text}</span>
        </Link>
      </li>
    );
  }
}

export default NavItem;
