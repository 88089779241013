import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_SWEEP_PN_CRITERIA = 'EDIT_SWEEP_PN_CRITERIA';
export const CLEAR_SWEEP_PN_CRITERIA = 'CLEAR_SWEEP_PN_CRITERIA';

export const UPDATE_SEARCH_RESULT_SWEEP_PN = 'UPDATE_SEARCH_RESULT_SWEEP_PN';

export const REQUEST_SEARCH_SWEEP_PN = '@@request/SEARCH_SWEEP_PN';
export const RECEIVE_SEARCH_SWEEP_PN = '@@receive/SEARCH_SWEEP_PN';
export const FAIL_SEARCH_SWEEP_PN = '@@fail/SEARCH_SWEEP_PN';

export const REQUEST_VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN =
  '@@request/VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN';
export const RECEIVE_VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN =
  '@@receive/VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN';
export const FAIL_VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN = '@@fail/VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN';

export const REQUEST_SAVE_SWEEP_PN_IMPORT_EXCEL = '@@request/SAVE_SWEEP_PN_IMPORT_EXCEL';
export const RECEIVE_SAVE_SWEEP_PN_IMPORT_EXCEL =
  '@@receive-The transaction was successfully imported/SAVE_SWEEP_PN_IMPORT_EXCEL';
export const FAIL_SAVE_SWEEP_PN_IMPORT_EXCEL = '@@fail/SAVE_SWEEP_PN_IMPORT_EXCEL';

export const REQUEST_VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO =
  '@@request/VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO';
export const RECEIVE_VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO =
  '@@receive/VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO';
export const FAIL_VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO =
  '@@fail/VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO';

export const REQUEST_SAVE_SWEEP_INDO_IMPORT_EXCEL = '@@request/SAVE_SWEEP_INDO_IMPORT_EXCEL';
export const RECEIVE_SAVE_SWEEP_INDO_IMPORT_EXCEL =
  '@@receive-The transaction was successfully imported/SAVE_SWEEP_INDO_IMPORT_EXCEL';
export const FAIL_SAVE_SWEEP_INDO_IMPORT_EXCEL = '@@fail/SAVE_SWEEP_INDO_IMPORT_EXCEL';

export const REQUEST_DOWNLOAD_RESPORT_SWEEP_PN = '@@request/DOWNLOAD_RESPORT_SWEEP_PN';
export const RECEIVE_DOWNLOAD_RESPORT_SWEEP_PN = '@@receive/DOWNLOAD_RESPORT_SWEEP_PN';
export const FAIL_DOWNLOAD_RESPORT_SWEEP_PN = '@@fail/DOWNLOAD_RESPORT_SWEEP_PN';

export const downloadSweepReport = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/downloadSweepPnPdf', criteria),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_RESPORT_SWEEP_PN,
      {
        type: RECEIVE_DOWNLOAD_RESPORT_SWEEP_PN,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_DOWNLOAD_RESPORT_SWEEP_PN,
    ],
  },
});

export const downloadSweepReportExcel = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/downloadSweepPnExcel', criteria),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_RESPORT_SWEEP_PN,
      {
        type: RECEIVE_DOWNLOAD_RESPORT_SWEEP_PN,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_DOWNLOAD_RESPORT_SWEEP_PN,
    ],
  },
});

export const saveImportExcel = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/report/SaveImportExcelSweepPn'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_SWEEP_PN_IMPORT_EXCEL,
      RECEIVE_SAVE_SWEEP_PN_IMPORT_EXCEL,
      FAIL_SAVE_SWEEP_PN_IMPORT_EXCEL,
    ],
  },
});

export const viewImportExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/CitiSweep'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN,
      RECEIVE_VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN,
      FAIL_VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN,
    ],
  },
});

export const saveImportExcelIndo = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/report/SaveImportExcelSweepIndo'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_SWEEP_INDO_IMPORT_EXCEL,
      RECEIVE_SAVE_SWEEP_INDO_IMPORT_EXCEL,
      FAIL_SAVE_SWEEP_INDO_IMPORT_EXCEL,
    ],
  },
});

export const viewImportExcelIndo = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/SweepIndo'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO,
      RECEIVE_VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO,
      FAIL_VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO,
    ],
  },
});

export const searchSweep = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/sweeppn', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_SWEEP_PN, RECEIVE_SEARCH_SWEEP_PN, FAIL_SEARCH_SWEEP_PN],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_SWEEP_PN,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_SWEEP_PN_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_SWEEP_PN_CRITERIA,
  payload: {
    name,
    value,
  },
});
