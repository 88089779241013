import React from 'react';

export default ({ text, col = [], required, children }) => (
  <div className="form-group">
    <label className={`control-label ${col[0] || ''} `}>
      {text}
      {required ? <span style={{ color: '#e64a19' }}>*</span> : null}
    </label>
    <div className={col[1] || ''}>{children}</div>
  </div>
);
