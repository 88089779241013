import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import MasterDropdown from '../../../common/MasterDropdown';
import { toFixed, getPermission } from '../../../common/helpper';
import { MASTER_BANK_CODE, MASTER_BANK_ACCOUNT } from '../../../reducers/master/action';
import MasterDropdownUI, { PAYMENT_METHOD_CASH_FLOW } from '../../../common/MasterDropdownUI';

const permission = getPermission('Funding&Investment', 'Facility Set up');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  formRef,
  state,
  onChangeInputData,
  onSubmit,

  onChangeInputDataCashFlow,
  onClickOpenModalCharge,
}) => (
  <>
    {permission ? (
      <>
        <Col2 col={colX[0]}>
          <Button txt="Charge" icon="plus" className="btn-info" onClick={onClickOpenModalCharge} />
        </Col2>
        <br />
      </>
    ) : null}

    <form onSubmit={onSubmit} ref={formRef} name="cashflow">
      <Card textHeader="Cash Flow" cardActions={['toggler']}>
        <Col2 col={colX[0]}>
          <Col2 col="col-sm-10">
            <FormGroup2 text="Bank" required>
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                notMultiple={false}
                isGetAll
                customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                companyCode={state.CompanyCode}
                disabled={false}
                value={state.BankCodeCashFlow}
                name="BankCodeCashFlow"
              />
            </FormGroup2>
          </Col2>
        </Col2>

        <div className="table-responsive">
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th className="th-white">Action</th>
                <th className="th-white">Payment Date</th>
                <th className="th-white">D</th>
                <th className="th-white">Flow Type</th>
                <th className="th-white">Amount</th>
                <th className="th-white">Currency</th>
                <th className="th-white">Bank Account</th>
                <th className="th-white">Payment Method</th>
                <th className="th-white">
                  Posting
                  <br />
                  Status
                </th>
                <th className="th-white">
                  Local Currency
                  <br />
                  Amount
                </th>
                <th className="th-white">
                  Local
                  <br />
                  Currency
                </th>
                <th className="th-white">Posting Date</th>
                <th className="th-white">Document No.</th>
              </tr>
            </thead>
            <tbody>
              {!(state.CashFlow || []).length ? (
                <tr>
                  <td colSpan="12" align="center">
                    No data available in table
                  </td>
                </tr>
              ) : null}
              {state.CashFlow.map((m, i) => (
                <tr key={i}>
                  <td align="center">
                    {permission ? (
                      <button
                        type="button"
                        className="btn btn-icon btn-default delete-result"
                        index={i}
                        title="Cancel"
                      >
                        <span className="icon icon-close sq-24" />
                      </button>
                    ) : null}
                  </td>
                  <td>
                    <div className="td-input-date">
                      <DatePicker
                        className="width-input-table"
                        value={m.paymentDateStr}
                        onChange={(e) => onChangeInputDataCashFlow(e, i)}
                        option={{
                          daysOfWeekDisabled: '0,6',
                          todayHighlight: true,
                        }}
                        required
                        name="paymentDateStr"
                      />
                    </div>
                  </td>
                  <td align="center">{m.direction}</td>
                  <td align="center">{m.flowType}</td>
                  <td align="right">{setAmount(m.amount, 3)}</td>
                  <td align="center">{m.currency}</td>
                  <td>
                    <div className="td-input-account">
                      <MasterDropdown
                        noValidateOption
                        masterType={MASTER_BANK_ACCOUNT}
                        isChoose
                        notMultipleSelect2
                        value={m.bankAccountNo}
                        onChange={(e) =>
                          onChangeInputDataCashFlow(
                            {
                              target: e,
                            },
                            i
                          )
                        }
                        saveLocalState
                        bankCode={state.BankCodeCashFlow}
                        companyCode={state.CompanyCode}
                        name="bankAccountNo"
                        required
                        customeValue={(m) => `${m.bankAccountNo}`}
                        disabled={false}
                      />
                    </div>
                  </td>
                  <td>
                    <div className="td-input-method">
                      <MasterDropdownUI
                        masterType={PAYMENT_METHOD_CASH_FLOW}
                        onChange={(e) =>
                          onChangeInputDataCashFlow(
                            {
                              target: e,
                            },
                            i
                          )
                        }
                        required
                        value={m.paymentMethod}
                        isChoose
                        notMultipleSelect2
                        name="paymentMethod"
                      />
                    </div>
                  </td>
                  <td align="center">{m.postingStatus}</td>
                  <td align="right">{setAmount(m.localCurrencyAmount, 3)}</td>
                  <td align="center">{m.localCurrency}</td>
                  <td align="center">{m.postingDateStr}</td>
                  <td align="center">
                    <a href="#">{m.documentNo}</a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </form>
  </>
);

const setAmount = (number, float) => {
  if (!number) return toFixed(0, float);
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};
