import React, {
  useRef,
  useMemo,
  useState,
  useContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import { store } from '../../../Root';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import DataTableHook from '../../../common/DataTable';
import DrawdownInterestModal from './DrawdownStructure';
import RepaymentInterestModal from './RepaymentStructure';
import { addAlert } from '../../../reducers/layout/action';
import { toFixed, getPermission } from '../../../common/helpper';
import { StateContext, FormRefContext } from './CreateContractLoan';
import { getSummaryContractLoan } from '../../../reducers/contractLoan/action';

const permission = getPermission('Funding&Investment', 'Create Contract Loan');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default function InterestTab(props) {
  const [action, setAction] = useState('Drawdown');
  const drawdownInterestModalRef = useRef(null);
  const repaymentInterestModalref = useRef(null);
  const [editIdx, seteditIdx] = useState(null);
  return (
    <>
      <DrawdownStructure
        modalRef={drawdownInterestModalRef}
        seteditIdx={seteditIdx}
        setAction={setAction}
      />
      <RepayStructure modalRef={repaymentInterestModalref} setAction={setAction} />
      <DrawdownInterestModal action={action} ref={drawdownInterestModalRef} editIndex={editIdx} />
      <RepaymentInterestModal action={action} ref={repaymentInterestModalref} editIndex={editIdx} />
    </>
  );
}

const { moment } = window;

function DrawdownStructure({ modalRef, setAction, seteditIdx }) {
  const summaryRef = useRef(null);
  const { state, dispatch } = useContext(StateContext);

  const { isCreate } = useContext(FormRefContext);
  const [summary, setSummary] = useState([]);
  const onClickOpenCreateModal = useCallback(
    (action) => {
      if (state.InterestStructures && state.InterestStructures.length) {
        store.dispatch(
          addAlert({
            type: 'error',
            title: 'Error',
            body: 'Please delete all Repayment before Add Drawdown',
          })
        );
        return;
      }
      setAction('Drawdown');
      modalRef.current.resetData();
      modalRef.current.open();
      seteditIdx(null);
    },
    [state]
  );
  const onClickGetSummary = useCallback(() => {
    async function getSummary() {
      const response = await store.dispatch(getSummaryContractLoan(state.TransactionNo));
      if (response.error) return;
      setSummary(response.payload || []);
      summaryRef.current.modal.open();
    }

    getSummary();
  }, [state.TransactionNo]);
  const onClickRollOver = useCallback(
    (index) => {
      setAction('Rollover');
      modalRef.current.resetData();

      const selected = state.DrawdownList[index];
      const interest = {
        ...selected,
        RollOverRefId: selected.Id,
        IsRollOver: true,
      };
      delete interest.Id;
      delete interest.ValidFromStr;
      delete interest.ValidToStr;
      delete interest.ContractDateStr;
      delete interest.Amount;
      const interestPeriod = {
        Amount: selected.Amount,
        ValidFromStr: selected.ValidToStr,
        ContractDateStr: selected.ContractDateStr,
      };
      modalRef.current.setInterest(interest);
      modalRef.current.setInterestPeriod(interestPeriod);

      modalRef.current.open();
      seteditIdx(null);
    },
    [state, modalRef]
  );
  const onClickEditStructor = useCallback(
    (index) => {
      if (state.InterestStructures && state.InterestStructures.length) {
        store.dispatch(
          addAlert({
            type: 'error',
            title: 'Error',
            body: 'Please delete all Repayment before Edit Drawdown',
          })
        );
        return;
      }
      setAction('Drawdown');
      modalRef.current.resetData();

      const selected = { ...state.DrawdownList[index] };
      const interestPeriod = {
        ValidFromStr: selected.ValidFromStr,
        ValidToStr: selected.ValidToStr,
        ContractDateStr: selected.ContractDateStr,
        Amount: selected.Amount,
      };

      delete selected.ValidFromStr;
      delete selected.ValidToStr;
      delete selected.ContractDateStr;
      delete selected.Amount;

      modalRef.current.setInterest(selected);
      modalRef.current.setInterestPeriod(interestPeriod);
      seteditIdx(index);
      modalRef.current.open(true);
    },
    [state]
  );
  const onClickRemoveStructor = useCallback((index) => {
    dispatch({
      type: 'REMOVE_STRUCTOR',
      structureType: 'Drawdown',
      index,
    });
  }, []);
  // table body
  const body = useMemo(
    () =>
      (state.DrawdownList || []).map((m, i) => (
        <tr key={i}>
          <td align="center">
            {permission ? (
              <button
                onClick={() => {
                  onClickRemoveStructor(i);
                }}
                type="button"
                className="btn btn-sm btn-warning"
              >
                <i className="icon icon-close icon-fw icon-lg" />
              </button>
            ) : null}
          </td>
          <td align="center">
            {/* {
                            permission ? ( <> */}
            <button
              onClick={() => {
                onClickEditStructor(i);
              }}
              type="button"
              className="btn btn-sm btn-info"
            >
              <i className="icon icon-edit icon-fw icon-lg" />
            </button>
            {/* </> 
                            ) : null
                        } */}
          </td>
          <td align="center">
            {m.Id && permission ? (
              <button
                type="button"
                className="btn btn-icon btn-primary rollover"
                onClick={(e) => onClickRollOver(i)}
              >
                <span className="icon icon-mail-reply-all sq-24" />
              </button>
            ) : null}
          </td>
          <td align="center">{m.ContractDateStr}</td>
          <td align="right">{setAmount(m.Amount, 3)}</td>
          <td align="center">{m.ValidFromStr}</td>
          <td align="center">{m.ValidToStr}</td>
          <td align="right">{setAmount(m.RefInterestRate, 5)}%</td>
          <td align="right">
            {!m.Margin ? '' : m.Margin >= 0 ? '+' : '-'}
            {`${setAmount(Math.abs(m.Margin), 5)}%`}
          </td>
          <td align="right">{setAmount(m.InterestRate, 5)}%</td>
          <td align="right">{setAmount(m.WithholdingTax, 5)}%</td>
          <td align="right">{setAmount(m.InterestAmount, 3)}</td>
          <td align="center">{m.CalculateMethod || ''}</td>
        </tr>
      )),
    [state.DrawdownList]
  );

  return (
    <>
      <Card textHeader="Drawdown Structure" cardActions={['toggler']}>
        <Col2 col={colX[0]}>
          <>
            {permission ? (
              <>
                <Button
                  txt="DRAWDOWN"
                  icon="arrows-v"
                  className="btn-info"
                  onClick={(e) => onClickOpenCreateModal('Drawdown')}
                />
                &nbsp;
              </>
            ) : null}
            <Button
              txt="SUMMARY"
              icon="file"
              className="btn-success"
              disabled={isCreate}
              onClick={onClickGetSummary}
            />
          </>
        </Col2>

        <div className="table-responsive">
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th className="th-white">Remove</th>
                <th className="th-white">Edit</th>
                <th className="th-white">Rollover</th>
                <th className="th-white">Drawdown Date</th>
                <th className="th-white">Drawdown Amount</th>
                <th className="th-white">Valid From</th>
                <th className="th-white">Valid To</th>
                <th className="th-white">Ref. Interest Rate</th>
                <th className="th-white">Margin</th>
                <th className="th-white">Interest</th>
                <th className="th-white">Withholding tax</th>
                <th className="th-white">
                  Interest Amount
                  <br />
                  pay to Bank
                </th>
                <th className="th-white">Cal. Method</th>
              </tr>
            </thead>
            <tbody>
              {!(state.DrawdownList || []).length ? (
                <tr>
                  <td colSpan="13" align="center">
                    No data available in table
                  </td>
                </tr>
              ) : null}
              {body}
            </tbody>
          </table>
        </div>
      </Card>
      <InterestSummary summary={summary} ref={summaryRef} />
    </>
  );
}

// repayment
function RepayStructure({ modalRef, setAction }) {
  const { state, dispatch } = useContext(StateContext);
  const onClickOpenCreateModal = useCallback(
    (action) => {
      if (state.DrawdownList.length === 0) {
        store.dispatch(
          addAlert({
            type: 'error',
            title: 'Error',
            body: 'Cannot Repayment without Drawdown Structure.',
          })
        );
        return;
      }
      setAction('Repayment');
      modalRef.current.resetData();
      modalRef.current.open(null);
    },
    [state]
  );
  const onClickEditStructor = useCallback(
    (index) => {
      const selected = state.RepaymentStructures[index];
      setAction('Repayment');
      modalRef.current.setData(selected);
      modalRef.current.open(index);
    },
    [state.RepaymentStructures]
  );
  const onClickRemoveStructor = useCallback(
    (index) => {
      const totalDrawdown = getOutstanding();
      // immutation
      const repaymentStructures = (state.RepaymentStructures || []).filter((m, i) => i !== index);

      repaymentStructures.forEach((m, i, self) => {
        m.InterestStructures = (m.InterestStructures || []).map((interest, idx) => {
          const passedRepayment = self.reduce((prev, r) => {
            if (
              moment(interest.ValidFromStr, 'DD/MM/YYYY').isSameOrAfter(
                moment(r.RepaymentDateStr, 'DD/MM/YYYY')
              )
            )
              return prev + (Number(r.RepaymentAmount) || 0);
            return prev;
          }, 0);
          const newData = {
            ...interest,
            Outstanding: totalDrawdown - passedRepayment,
          };
          newData.Ordering = i + 1;
          newData.InterestAmount = calInterestAmount(newData);
          return newData;
        });
        m.InterestAmount = m.InterestStructures.reduce(
          (prev, cur) => prev + (Number(cur.InterestAmount) || 0),
          0
        );
      });
      dispatch({
        type: 'REMOVE_REPAYMENT_STRUCTURE',
        index,
      });
      dispatch({
        type: 'SET_REPAYMENT_STRUCTURE',
        payload: repaymentStructures,
      });
    },
    [state.RepaymentStructures, state.DrawdownList]
  );

  const body = useMemo(
    () =>
      (state.RepaymentStructures || []).map((m, i) => (
        <tr key={i}>
          <td align="center">
            {permission ? (
              <button
                onClick={() => {
                  onClickRemoveStructor(i);
                }}
                type="button"
                className="btn btn-sm btn-warning"
              >
                <i className="icon icon-close icon-fw icon-lg" />
              </button>
            ) : null}
          </td>
          <td align="center">
            {/* {
                        permission ? ( */}
            <button
              onClick={() => {
                onClickEditStructor(i);
              }}
              type="button"
              className="btn btn-sm btn-info"
            >
              <i className="icon icon-edit icon-fw icon-lg" />
            </button>
            {/* ) 
                        : null
                    } */}
          </td>
          <td align="center">{m.RepaymentDateStr}</td>
          <td align="right">{setAmount(m.RepaymentAmount, 3)}</td>
          <td align="right">{setAmount(m.InterestAmount, 3)}</td>
        </tr>
      )),
    [state.RepaymentStructures, onClickRemoveStructor]
  );

  return (
    <Card textHeader="Repayment Structure" cardActions={['toggler']}>
      {permission ? (
        <Col2 col={colX[0]}>
          <Button
            txt="REPAYMENT"
            icon="paypal"
            className="btn-warning"
            onClick={onClickOpenCreateModal}
          />
        </Col2>
      ) : null}

      <div className="table-responsive">
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th className="th-white">Remove</th>
              <th className="th-white">Edit</th>
              <th className="th-white">Repayment Date</th>
              <th className="th-white">Repayment Amount</th>
              <th className="th-white">
                Interest Amount
                <br />
                pay to Bank
              </th>
            </tr>
          </thead>
          <tbody>
            {!(state.RepaymentStructures || []).length ? (
              <tr>
                <td colSpan="5" align="center">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {body}
          </tbody>
        </table>
      </div>
    </Card>
  );

  function getOutstanding() {
    return state.DrawdownList.reduce((prev, m) => prev + (Number(m.Amount) || 0), 0);
  }

  function calInterestAmount(data) {
    const {
      InterestRate,
      Outstanding,
      ValidFromStr,
      ValidToStr,
      IsManualInclude,
      CalculateMethod,
    } = data;

    let daysDiff = moment(ValidToStr, 'DD/MM/YYYY').diff(
      moment(ValidFromStr, 'DD/MM/YYYY'),
      'days'
    );
    if (IsManualInclude) daysDiff += 1;
    let totalDayinYear = 0;
    switch (CalculateMethod) {
      case 'Act/360':
        totalDayinYear = 360;
        break;
      case 'Act/366':
        totalDayinYear = 366;
        break;
      case 'Act/365':
        totalDayinYear = 365;
        break;
      default:
        totalDayinYear = isLeapYear(moment(ValidToStr, 'DD/MM/YYYY').year()) ? 366 : 365;
    }
    return (((Number(InterestRate) / 100) * Number(Outstanding)) / totalDayinYear) * daysDiff;
  }

  function isLeapYear(year) {
    return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
  }
}

const InterestSummary = forwardRef(({ summary = [] }, ref) => {
  const modalRef = useRef(null);
  useImperativeHandle(
    ref,
    () => ({
      modal: modalRef.current,
    }),
    [modalRef]
  );
  return (
    <Modal ref={modalRef} size="modal-lg" textHeader="Summary" bgHeader="bg-info">
      <DataTableHook
        id="contract-loan-summary"
        value={summary}
        option={opt}
        className="table table-bordered table-nowrap"
      >
        <thead>
          <tr>
            <th>Drawdown Date</th>
            <th>Drawdown Amount</th>
            <th>Valid From</th>
            <th>Valid To</th>
            <th>Ref. Interest Rate</th>
            <th>Margin</th>
            <th>Interest</th>
            <th>Withholding tax</th>
            <th>
              Interest Amount
              <br />
              pay to Bank
            </th>
            <th>Cal. Method</th>
          </tr>
        </thead>
      </DataTableHook>
    </Modal>
  );
});

const opt = {
  ordering: false,
  columns: [
    {
      className: 'text-center',
      data: 'drawdownDateStr',
      render: (d, t, r) => (r.isRollOver ? 'RollOver' : d),
    },
    {
      className: 'text-right',
      data: 'amount',
      render: (d) => setAmount(d),
    },
    { data: 'validFromStr' },
    { data: 'validToStr' },
    {
      className: 'text-right',
      data: 'refInterestRate',
      render: (d) => d,
    },
    {
      className: 'text-right',
      data: 'margin',
      render: (d) => d,
    },
    {
      className: 'text-right',
      data: 'interestRate',
      render: (d) => d,
    },
    {
      className: 'text-right',
      data: 'withHoldingTax',
      render: (d) => d,
    },
    {
      className: 'text-right',
      data: 'totalInterestAmount',
      render: (d) => setAmount(d),
    },
    { data: 'calMethod' },
  ],
};

const setAmount = (number, float) => {
  if (!number) return toFixed(0, float);
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};
