import {
  EDIT_INTEREST_RECEIPT_CRITERIA,
  RECEIVE_SEARCH_INTEREST_RECEIPT,
  CLEAR_INTEREST_RECEIPT_CRITERIA,
  UPDATE_SEARCH_RESULT_INTEREST_RECEIPT,
} from './action';

const initCriteria = {
  FinanceGroup: [''],
  BusinessUnit: [''],
  CompanyCode: [''],
  PartnerCode: [''],
  TransactionNo: '',
  FisicalYear: '',
  Month: '',
  Source: [''],
  IsSweep: '',
  InstanceName: '',
  Status: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
};
export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_RESULT_INTEREST_RECEIPT:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            if (
              ~action.payload.name.indexOf('IsSelected') &&
              action.payload.name !== 'IsSelected'
            ) {
              const printMode = action.payload.name.replace('IsSelected', '');
              const canSelect = m[`canSelect${printMode}`];
              if (canSelect)
                return {
                  ...m,
                  [action.payload.name]: action.payload.value,
                };
              return m;
            }
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case RECEIVE_SEARCH_INTEREST_RECEIPT:
      return {
        ...state,
        searchResult: action.payload.map((m) => ({
          ...m,
          IsSelected: false,
          IsSelectedOriginalForCustomerHardCopy: false,
          IsSelectedOriginalForCustomerElectronic: false,
          IsSelectedCopyForCustomerHardCopy: false,
          IsSelectedCopyForCustomerElectronic: false,
          IsSelectedCopyForAccountingHardCopy: false,
          IsSelectedCopyForAccountingElectronic: false,
        })),
      };
    case CLEAR_INTEREST_RECEIPT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_INTEREST_RECEIPT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    default:
      return state;
  }
};
