import { defaultImportExcelResult } from '../configuration';
import {
  RECEIVE_IMPORT_EXCEL,
  SET_SELECTED_CASH_PLAN,
  RECEIVE_SEARCH_CASH_PLANT,
  SET_SELECTED_ALL_CASH_PLAN,
  EDIT_CASH_PLAN_SEACH_RESULT,
  FAIL_SAVE_CASH_PLAN_IMPORT_EXCEL,
  RECEIVE_SAVE_CASH_PLAN_IMPORT_EXCEL,
  RECEIVE_GET_TEMPLATE_LIST_CASH_PLAN,
} from './action';

const init = {
  searchResult: [],
  template: [],
  importExcelResult: defaultImportExcelResult,
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_GET_TEMPLATE_LIST_CASH_PLAN:
      return {
        ...state,
        template: [...action.payload],
      };
    case EDIT_CASH_PLAN_SEACH_RESULT:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i)
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          return m;
        }),
      };
    case SET_SELECTED_ALL_CASH_PLAN:
      return {
        ...state,
        searchResult: state.searchResult.map((m) => {
          if (m.CanSelect)
            return {
              ...m,
              IsSelected: action.payload,
            };
          return m;
        }),
      };
    case SET_SELECTED_CASH_PLAN: {
      return {
        ...state,
        searchResult: [
          ...state.searchResult.slice(0, action.payload.index),
          {
            ...state.searchResult[action.payload.index],
            IsSelected: action.payload.isSelected,
          },
          ...state.searchResult.slice(action.payload.index + 1),
        ],
      };
    }

    case RECEIVE_IMPORT_EXCEL:
      return {
        ...state,
        importExcelResult: action.payload,
      };
    case RECEIVE_SEARCH_CASH_PLANT:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    case RECEIVE_SAVE_CASH_PLAN_IMPORT_EXCEL:
      return {
        ...state,
        importExcelResult: defaultImportExcelResult,
      };
    case FAIL_SAVE_CASH_PLAN_IMPORT_EXCEL:
      return {
        ...state,
        importExcelResult: defaultImportExcelResult,
      };
    default:
      return state;
  }
};
