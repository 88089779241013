import { RECEIVE_SEARCH_SWEEP_MONEY } from './action';

const init = {
  searchResult: [],
};
export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_SEARCH_SWEEP_MONEY:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
