import {
  EDIT_MASTER_ROLE_CRITERIA,
  RECEIVE_SEARCH_MASTER_ROLE,
  CLEAR_MASTER_ROLE_CRITERIA,
  RECEIVE_GET_MASTER_PRODUCT,
  UPDATE_SEARCH_RESULT_MASTER_ROLE,
} from './action';

const initCriteria = {
  RoleId: '',
  RoleName: '',
  Status: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
  resultProduct: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_GET_MASTER_PRODUCT:
      return {
        ...state,
        resultProduct: [...action.payload],
      };
    case UPDATE_SEARCH_RESULT_MASTER_ROLE:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_MASTER_ROLE_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MASTER_ROLE_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_MASTER_ROLE:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
