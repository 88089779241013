import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_CONTRACT_ON_CALL_CRITERIA = 'EDIT_CONTRACT_ON_CALL_CRITERIA';
export const CLEAR_CONTRACT_ON_CALL_CRITERIA = 'CLEAR_CONTRACT_ON_CALL_CRITERIA';

export const UPDATE_SEARCH_RESULT_CONTRACT_ON_CALL = 'UPDATE_SEARCH_RESULT_CONTRACT_ON_CALL';

export const REQUEST_SEARCH_CONTRACT_ON_CALL = '@@request/SARCH_CONTRACT_ON_CALL';
export const RECEIVE_SEARCH_CONTRACT_ON_CALL = '@@receive/SEARCH_CONTRACT_ON_CALL';
export const FAIL_SEARCH_CONTRACT_ON_CALL = '@@fail/SEARCH_CONTRACT_ON_CALL';

export const REQUEST_GET_CONTRACT_ON_CALL = '@@request/GET_CONTRACT_ON_CALL';
export const RECEIVE_GET_CONTRACT_ON_CALL = '@@receive/GET_CONTRACT_ON_CALL';
export const FAIL_GET_CONTRACT_ON_CALL = '@@fail/GET_CONTRACT_ON_CALL';

export const REQUEST_GET_MAINTAIN_INTEREST_RATE = '@@request/GET_MAINTAIN_INTEREST_RATE';
export const RECEIVE_GET_MAINTAIN_INTEREST_RATE = '@@receive/GET_MAINTAIN_INTEREST_RATE';
export const FAIL_GET_MAINTAIN_INTEREST_RATE = '@@fail/GET_MAINTAIN_INTEREST_RATE';

export const REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE = '@@request/GET_TEMPLATE_LIST_INTEREST_RATE';
export const RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE = '@@receive/GET_TEMPLATE_LIST_INTEREST_RATE';
export const FAIL_GET_TEMPLATE_LIST_INTEREST_RATE = '@@fail/GET_TEMPLATE_LIST_INTEREST_RATE';

export const REQUEST_PROCESS_CASH_FLOW = '@@request/PROCESS_CASH_FLOW';
export const RECEIVE_PROCESS_CASH_FLOW = '@@receive/PROCESS_CASH_FLOW';
export const FAIL_PROCESS_CASH_FLOW = '@@fail/PROCESS_CASH_FLOW';

export const REQUEST_SAVE_CONTRACT_ON_CALL = '@@request/SAVE_CONTRACT_ON_CALL';
export const RECEIVE_SAVE_CONTRACT_ON_CALL = '@@receive/SAVE_CONTRACT_ON_CALL';
export const FAIL_SAVE_CONTRACT_ON_CALL = '@@fail/SAVE_CONTRACT_ON_CALL';

export const REQUEST_GET_FACILITY_FOR_LOAN = '@@request-noload/GET_FACILITY_FOR_LOAN';
export const RECEIVE_GET_FACILITY_FOR_LOAN = '@@receive/GET_FACILITY_FOR_LOAN';
export const FAIL_GET_FACILITY_FOR_LOAN = '@@fail/GET_FACILITY_FOR_LOAN';

export const REQUEST_GET_FACILITY_LIST = '@@request-noload/GET_FACILITY_LIST';
export const RECEIVE_GET_FACILITY_LIST = '@@receive/GET_FACILITY_LIST';
export const FAIL_GET_FACILITY_LIST = '@@fail/GET_FACILITY_LIST';

export const REQUEST_GET_INTEREST_REPAYMENT = '@@request/GET_INTEREST_REPAYMENT';
export const RECEIVE_GET_INTEREST_REPAYMENT = '@@receive/GET_INTEREST_REPAYMENT';
export const FAIL_GET_INTEREST_REPAYMENT = '@@fail/GET_INTEREST_REPAYMENT';

export const REQUEST_SAVE_LOAN_ROLLOVER_REPAYMENT = '@@request/SAVE_LOAN_ROLLOVER_REPAYMENT';
export const RECEIVE_SAVE_LOAN_ROLLOVER_REPAYMENT =
  '@@receive-The transaction was successfully saved/SAVE_LOAN_ROLLOVER_REPAYMENT';
export const FAIL_SAVE_LOAN_ROLLOVER_REPAYMENT = '@@fail/SAVE_LOAN_ROLLOVER_REPAYMENT';

export const REQUEST_CHANGE_STATUS_CONTRACT_ON_CALL = '@@request/CHANGE_STATUS_CONTRACT_ON_CALL';
export const RECEIVE_CHANGE_STATUS_CONTRACT_ON_CALL = '@@receive/CHANGE_STATUS_CONTRACT_ON_CALL';
export const FAIL_CHANGE_STATUS_CONTRACT_ON_CALL = '@@fail/CHANGE_STATUS_CONTRACT_ON_CALL';

export const REQUEST_SAVE_CONTRACT_INTEREST = '@@request/SAVE_CONTRACT_INTEREST';
export const RECEIVE_SAVE_CONTRACT_INTEREST = '@@receive/SAVE_CONTRACT_INTEREST';
export const FAIL_SAVE_CONTRACT_INTEREST = '@@fail/SAVE_CONTRACT_INTEREST';

export const REQUEST_SPLIT_CASH_FLOW = '@@request/SPLIT_CASH_FLOW';
export const RECEIVE_SPLIT_CASH_FLOW =
  '@@receive-The transaction was successfully saved/SPLIT_CASH_FLOW';
export const FAIL_SPLIT_CASH_FLOW = '@@fail/SPLIT_CASH_FLOW';

export const splitCashFlow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/splitcashflow', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SPLIT_CASH_FLOW, RECEIVE_SPLIT_CASH_FLOW, FAIL_SPLIT_CASH_FLOW],
  },
});

export const changeStatus = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/changestatusloan', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CHANGE_STATUS_CONTRACT_ON_CALL,
      RECEIVE_CHANGE_STATUS_CONTRACT_ON_CALL,
      FAIL_CHANGE_STATUS_CONTRACT_ON_CALL,
    ],
  },
});

export const saveRollOverRepay = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/SaveRollOverRepaymentOnCall', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_LOAN_ROLLOVER_REPAYMENT,
      RECEIVE_SAVE_LOAN_ROLLOVER_REPAYMENT,
      FAIL_SAVE_LOAN_ROLLOVER_REPAYMENT,
    ],
  },
});

export const getInterestRepayment = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/GetInterestRepayment', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_GET_INTEREST_REPAYMENT,
      RECEIVE_GET_INTEREST_REPAYMENT,
      FAIL_GET_INTEREST_REPAYMENT,
    ],
  },
});

export const getFacilityList = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/getfacilitylist', criteria),
    method: 'GET',
    types: [REQUEST_GET_FACILITY_LIST, RECEIVE_GET_FACILITY_LIST, FAIL_GET_FACILITY_LIST],
  },
});

export const getFacilityForLoan = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getfacilityforloan/${transactionNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_FACILITY_FOR_LOAN,
      RECEIVE_GET_FACILITY_FOR_LOAN,
      FAIL_GET_FACILITY_FOR_LOAN,
    ],
  },
});

export const saveLoan = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/saveloan'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_CONTRACT_ON_CALL,
      RECEIVE_SAVE_CONTRACT_ON_CALL,
      FAIL_SAVE_CONTRACT_ON_CALL,
    ],
  },
});

export const processCashflow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/ProcessCashFlowOnCall'),
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    method: 'POST',
    types: [REQUEST_PROCESS_CASH_FLOW, RECEIVE_PROCESS_CASH_FLOW, FAIL_PROCESS_CASH_FLOW],
  },
});

export const getTemplateInterestRateList = () => ({
  [RSAA]: {
    endpoint: endpoint('/loan/gettemplateinterestlist'),
    method: 'GET',
    types: [
      REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE,
      RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE,
      FAIL_GET_TEMPLATE_LIST_INTEREST_RATE,
    ],
  },
});

export const getInterestRate = (interestRateId) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getinterestrate/${interestRateId}`),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_INTEREST_RATE,
      RECEIVE_GET_MAINTAIN_INTEREST_RATE,
      FAIL_GET_MAINTAIN_INTEREST_RATE,
    ],
  },
});

export const saveInterestRate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savecontractinterestrate', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_CONTRACT_INTEREST,
      RECEIVE_SAVE_CONTRACT_INTEREST,
      FAIL_SAVE_CONTRACT_INTEREST,
    ],
  },
});

export const getLoan = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getloan/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_CONTRACT_ON_CALL, RECEIVE_GET_CONTRACT_ON_CALL, FAIL_GET_CONTRACT_ON_CALL],
  },
});

export const searchLoan = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/searchloan', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_CONTRACT_ON_CALL,
      RECEIVE_SEARCH_CONTRACT_ON_CALL,
      FAIL_SEARCH_CONTRACT_ON_CALL,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_CONTRACT_ON_CALL,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_CONTRACT_ON_CALL_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_CONTRACT_ON_CALL_CRITERIA,
  payload: {
    name,
    value,
  },
});
