import { connect } from 'react-redux';
import React, { Component } from 'react';

import CreateContractLoan from './SearchContractLoan';
import {
  editCriteria,
  changeStatus,
  clearCriteria,
  searchContractLoan,
} from '../../../reducers/contractLoan/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-search-contractloan';

const initChangeStatus = {
  transactionNo: '',
  modal: '',
};

class CreateContractLoanContainer extends Component {
  state = {
    modalChangeStatus: {
      ...initChangeStatus,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
  }

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchContractLoan({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalChangeStatus: {
        ...initChangeStatus,
        transactionNo: rowData.transactionNo,
        modal,
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const submitData = {
      TransactionNo: this.state.modalChangeStatus.transactionNo,
    };
    this.props.changeStatus(submitData).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchContractLoan({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <CreateContractLoan
        {...props}
        {...this.state}
        criteria={this.props.criteria}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInactiveRef={(e) => (this.modalInactiveRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.contractLoan,
  }),
  {
    searchContractLoan,
    editCriteria,
    clearCriteria,
    changeStatus,
  }
)(CreateContractLoanContainer);
