import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import InterestStructure from '../TabInterest';
import { toFixed } from '../../../common/helpper';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  formRef,
  state,
  onChangeInputData,
  onClickOpenModalInterest,
  onClickOpenModalDelete,
  template = [],
  viewOnly,
}) => (
  <>
    <InterestStructure
      formRef={formRef}
      template={template}
      state={state}
      onChangeInputData={onChangeInputData}
      viewOnly={viewOnly}
    />

    <Card textHeader="Interest Rate" cardActions={['toggler']}>
      {state.permission && !viewOnly ? (
        <>
          <Col2 col={colX[0]}>
            <Button
              txt="Add Interest Rate"
              icon="plus"
              className="btn-warning"
              onClick={onClickOpenModalInterest}
              disabled={viewOnly}
            />
          </Col2>
          <br />
        </>
      ) : null}

      <div className="table-responsive">
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th className="th-white">Action</th>
              <th className="th-white">Interest Rate (%)</th>
              <th className="th-white">Valid From</th>
              <th className="th-white">Valid To</th>
            </tr>
          </thead>
          <tbody>
            {!(state.resultInterest || []).length ? (
              <tr>
                <td colSpan="4" align="center">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {state.resultInterest.map((m, i) => (
              <tr key={i}>
                <td align="center">
                  {state.permission && !viewOnly ? (
                    <button
                      className="btn btn-icon btn-default cancel-assign"
                      onClick={(e) => onClickOpenModalDelete(e, i, 'Inactive')}
                    >
                      <span className="icon icon-close sq-24" />
                    </button>
                  ) : null}
                </td>
                <td align="right">{setAmount(m.interestRate, 2)}%</td>
                <td align="center">{m.validFromStr}</td>
                <td align="center">{m.validToStr}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  </>
);

const setAmount = (number, float) => {
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};
