import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_FX_CONFIRMATION = '@@request/SEARCH_FX_CONFIRMATION';
export const RECEIVE_SEARCH_FX_CONFIRMATION = '@@receive/SEARCH_FX_CONFIRMATION';
export const FAIL_SEARCH_FX_CONFIRMATION = '@@fail/SEARCH_FX_CONFIRMATION';

export const REQUEST_CREATE_FX_CONFIRMATION = '@@request/CREATE_FX_CONFIRMATION';
export const RECEIVE_CREATE_FX_CONFIRMATION = '@@receive/CREATE_FX_CONFIRMATION';
export const FAIL_CREATE_FX_CONFIRMATION = '@@fail/CREATE_FX_CONFIRMATION';

export const REQUEST_GET_FX_CONFIRMATION = '@@request/GET_FX_CONFIRMATION';
export const RECEIVE_GET_FX_CONFIRMATION = '@@receive/GET_FX_CONFIRMATION';
export const FAIL_GET_FX_CONFIRMATION = '@@fail/GET_FX_CONFIRMATION';

export const UPDATE_SEARCH_RESULT_FX_CONFIRMATION = 'UPDATE_SEARCH_RESULT_FX_CONFIRMATION';

export const REQUEST_GET_BU_MATCHING = '@@request/GET_BU_MATCHING';
export const RECEIVE_GET_BU_MATCHING = '@@receive/GET_BU_MATCHING';
export const FAIL_GET_BU_MATCHING = '@@fail/GET_BU_MATCHING';

export const REQUEST_GET_MATCHING_DATE = '@@request/GET_MATCHING_DATE';
export const RECEIVE_GET_MATCHING_DATE = '@@receive/GET_MATCHING_DATE';
export const FAIL_GET_MATCHING_DATE = '@@fail/GET_MATCHING_DATE';

export const REQUEST_GET_MATCHING_TIME = '@@request/GET_MATCHING_TIME';
export const RECEIVE_GET_MATCHING_TIME = '@@receive/GET_MATCHING_TIME';
export const FAIL_GET_MATCHING_TIME = '@@fail/GET_MATCHING_TIME';

export const REQUEST_EXPORT_FX_CONFIRMATION = '@@request/EXPORT_FX_CONFIRMATION';
export const RECEIVE_EXPORT_FX_CONFIRMATION = '@@receive/EXPORT_FX_CONFIRMATION';
export const FAIL_EXPORT_FX_CONFIRMATION = '@@fail/EXPORT_FX_CONFIRMATION';

export const REQUEST_SEND_EMAIL_FX_CONFIRMATION = '@@request/SEND_EMAIL_FX_CONFIRMATION';
export const RECEIVE_SEND_EMAIL_FX_CONFIRMATION = '@@receive/SEND_EMAIL_FX_CONFIRMATION';
export const FAIL_SEND_EMAIL_FX_CONFIRMATION = '@@fail/SEND_EMAIL_FX_CONFIRMATION';

export const CLEAR_SEARCH_FX_CONFIRMATION = 'CLEAR_SEARCH_FX_CONFIRMATION';

export const REQUEST_CHANGE_DATE_FX_CONFIRMATION = '@@request/CHANGE_DATE_FX_CONFIRMATION';
export const RECEIVE_CHANGE_DATE_FX_CONFIRMATION = '@@receive/CHANGE_DATE_FX_CONFIRMATION';
export const FAIL_CHANGE_DATE_FX_CONFIRMATION = '@@fail/CHANGE_DATE_FX_CONFIRMATION';

export const REQUEST_CREATE_FX_CONFIRMATION_MANUAL = '@@request/CREATE_FX_CONFIRMATION_MANUAL';
export const RECEIVE_CREATE_FX_CONFIRMATION_MANUAL = '@@receive/CREATE_FX_CONFIRMATION_MANUAL';
export const FAIL_CREATE_FX_CONFIRMATION_MANUAL = '@@fail/CREATE_FX_CONFIRMATION_MANUAL';

export const REQUEST_GET_FX_CONFIRMATION_COMP = '@@request/GET_FX_CONFIRMATION_COMP';
export const RECEIVE_GET_FX_CONFIRMATION_COMP = '@@receive/GET_FX_CONFIRMATION_COMP';
export const FAIL_GET_FX_CONFIRMATION_COMP = '@@fail/GET_FX_CONFIRMATION_COMP';

export const getFxConfirmationCompany = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SearchFxMatching', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_FX_CONFIRMATION_COMP,
      RECEIVE_GET_FX_CONFIRMATION_COMP,
      FAIL_GET_FX_CONFIRMATION_COMP,
    ],
  },
});

export const createFxMatching = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/CreateFxMatching'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CREATE_FX_CONFIRMATION_MANUAL,
      RECEIVE_CREATE_FX_CONFIRMATION_MANUAL,
      FAIL_CREATE_FX_CONFIRMATION_MANUAL,
    ],
  },
});

export const changeDateFxMatching = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/ChangeDateFxMatching'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CHANGE_DATE_FX_CONFIRMATION,
      RECEIVE_CHANGE_DATE_FX_CONFIRMATION,
      FAIL_CHANGE_DATE_FX_CONFIRMATION,
    ],
  },
});

export const sendEmailFxMatching = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SendEmailFxConfirmation'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SEND_EMAIL_FX_CONFIRMATION,
      RECEIVE_SEND_EMAIL_FX_CONFIRMATION,
      FAIL_SEND_EMAIL_FX_CONFIRMATION,
    ],
  },
});

export const clearResult = () => ({
  type: CLEAR_SEARCH_FX_CONFIRMATION,
});

export const getExportFxConfirmation = (datas) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/ExportFxConfirmation'),
    method: 'POST',
    body: JSON.stringify(datas),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_EXPORT_FX_CONFIRMATION,
      {
        type: RECEIVE_EXPORT_FX_CONFIRMATION,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_EXPORT_FX_CONFIRMATION,
    ],
  },
});

export const getMatchingDate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/GetFxMatchingDate', criteria),
    method: 'GET',
    types: [REQUEST_GET_MATCHING_DATE, RECEIVE_GET_MATCHING_DATE, FAIL_GET_MATCHING_DATE],
  },
});

export const getMatchingTime = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/GetFxMatchingTime', criteria),
    method: 'GET',
    types: [REQUEST_GET_MATCHING_TIME, RECEIVE_GET_MATCHING_TIME, FAIL_GET_MATCHING_TIME],
  },
});

export const getBuMatching = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SearchFxMatching', criteria),
    method: 'GET',
    types: [REQUEST_GET_BU_MATCHING, RECEIVE_GET_BU_MATCHING, FAIL_GET_BU_MATCHING],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_FX_CONFIRMATION,
  payload: {
    name,
    value,
    index,
  },
});

export const getFxMatching = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`poso/GetFxMatching/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_FX_CONFIRMATION, RECEIVE_GET_FX_CONFIRMATION, FAIL_GET_FX_CONFIRMATION],
  },
});

export const searchFxMatching = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SearchFxMatching', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_FX_CONFIRMATION,
      RECEIVE_SEARCH_FX_CONFIRMATION,
      FAIL_SEARCH_FX_CONFIRMATION,
    ],
  },
});

export const saveFxMatching = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/poso/SaveFxMatching'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CREATE_FX_CONFIRMATION,
      RECEIVE_CREATE_FX_CONFIRMATION,
      FAIL_CREATE_FX_CONFIRMATION,
    ],
  },
});
