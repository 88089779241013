import React from 'react';

import Tab from '../../common/Tab';
import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import CreatorTab from './CreatorTab';
import ManagerTab from './ManagerTab';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import StructureTab from './StructureTab';
import RequestorTab from './RequestorTab';
import AttachmentTab from './AttachmentTab';
import { toLower } from '../../common/helpper';
import FormGroup2 from '../../common/FormGroup2';
import SpanStatus from '../../common/SpanStatus';
import RadioButton from '../../common/RadioButton';
import MasterDropdown from '../../common/MasterDropdown';
import Select2, { optionForAjaxUserFromAd } from '../../common/Select2';
import {
  MASTER_COMPANY,
  MASTER_BANK_CODE,
  MAIN_MASTER_FINANCE_GROUP,
} from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

// let permission = getPermissionStr('Bank Guarantee', 'Request') === 'View' ? false : true
const permission = true;

const jwt = JSON.parse(localStorage.getItem('jwt')) || { username: '' };
const username = jwt.username || '';

export default ({
  state,
  onChangeInputData,
  onChangeBuApprove,
  onClickCopyCreator,
  onSubmit,

  tabRef,
  formDetailRef,
  formStructerRef,
  formContractRef,
  formCreatorRef,
  formRequestorRef,
  formManagerRef,
  formAttachmentRef,
  requestorRef,
  creatorRef,
  approverBuRef,
  initialRef,
  managerRef,

  modalFunctionRef,
  onClickOpenModalFunction,
  onChangeInputModal,
  onSubmitModalFunction,

  facilityList,
}) => {
  const isAmend = window.location.pathname.includes('amend');
  const modeAmend = !permission ? true : isAmend;

  const textHeader = isAmend
    ? 'Amend Transaction'
    : state.Action === 'create'
      ? 'Create Request'
      : `Update Request : ${state.TransactionNo}`;
  const modeEdit = state.Action === 'edit';
  const noEdit = !permission
    ? true
    : !(
        state.Action === 'create' ||
        // || (toLower(state.StatusLg) === 'wait for confirm' && state.IsRoleFinancial)
        (~['created', 'draft'].indexOf(toLower(state.StatusLg)) &&
          (toLower(state.CreatorName) === toLower(username) ||
            toLower(state.RequestorName) === toLower(username)))
      );

  const tabContents = [];
  tabContents.push({
    header: <span>Information</span>,
    content: (
      <StructureTab
        formRef={formStructerRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
        facilityList={facilityList}
        modeAmend={modeAmend}
      />
    ),
    tabName: 'structure',
  });

  // tabContents.push({
  //     header: <span>Contract</span>,
  //     content: <ContractUsTab
  //         formRef={formContractRef}
  //         state={state}
  //         onChangeInputData={onChangeInputData}
  //         noEdit={noEdit}
  //     />,
  //     tabName: 'contract'
  // })

  tabContents.push({
    header: <span>Creator Information</span>,
    content: (
      <CreatorTab
        creatorRef={creatorRef}
        formRef={formCreatorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        onClickCopyCreator={onClickCopyCreator}
        noEdit={noEdit}
        modeAmend={modeAmend}
      />
    ),
    tabName: 'creator',
  });

  tabContents.push({
    header: <span>Requestor Information</span>,
    content: (
      <RequestorTab
        requestorRef={requestorRef}
        formRef={formRequestorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
        modeAmend={modeAmend}
      />
    ),
    tabName: 'requestor',
  });

  tabContents.push({
    header: <span>Manager Information</span>,
    content: (
      <ManagerTab
        requestorRef={managerRef}
        formRef={formManagerRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
        modeAmend={modeAmend}
      />
    ),
    tabName: 'manager',
  });

  tabContents.push({
    header: <span>Attachment</span>,
    content: (
      <AttachmentTab
        onChangeBuApprove={onChangeBuApprove}
        onChangeInputData={onChangeInputData}
        formRef={formAttachmentRef}
        state={state}
        noEdit={noEdit}
        modeAmend={modeAmend}
        showTh
      />
    ),
    tabName: 'attachment',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Bank Guarantee <span className="icon icon-angle-double-right" />
            <Link txt="Request" href="/bank-guarantee/search-request" />
            <span className="icon icon-angle-double-right" /> Request Detail
            {isAmend ? (
              <>
                {' '}
                <span className="icon icon-angle-double-right" /> Amend Transaction
              </>
            ) : modeEdit ? (
              <>
                {' '}
                <span className="icon icon-angle-double-right" /> {state.TransactionNo}{' '}
                <span className="icon icon-angle-double-right" />{' '}
                <SpanStatus status={state.StatusLg} />
              </>
            ) : null}
          </small>
        </p>
      </div>

      {!permission ? null : (toLower(state.StatusLg) === 'wait for initialization' &&
          state.CanInitial) ||
        (toLower(state.StatusLg) === 'wait for bu approve' && state.CanApproverBu) ? (
        <div>
          <Button
            txt="approve"
            icon="check"
            className="btn-success"
            onClick={(e) => onClickOpenModalFunction(e, 'approve')}
          />
          &nbsp;
          <Button
            txt="send back to edit"
            icon="undo"
            className="btn-default"
            onClick={(e) => onClickOpenModalFunction(e, 'send back')}
          />
          &nbsp;
          <Button
            txt="reject"
            icon="close"
            className="btn-danger"
            onClick={(e) => onClickOpenModalFunction(e, 'reject')}
          />
        </div>
      ) : null}

      <Card textHeader={textHeader} bgHeader="bg-primary" cardActions={['toggler']}>
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" textTh="(กลุ่มของการจัดการเงิน)" required>
                <MasterDropdown
                  masterType={MAIN_MASTER_FINANCE_GROUP}
                  onChange={onChangeInputData}
                  isChoose
                  notMultiple
                  required
                  value={state.FinanceGroupId}
                  disabled={modeEdit}
                  status
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Company" textTh="(บริษัท)" required>
                {/* {
                                    state.FinanceGroupId ? (
                                        <MasterDropdown
                                            masterType={MASTER_COMPANY}
                                            noValidateOption={modeEdit}
                                            onChange={e => onChangeInputData({
                                                target: e
                                            })}
                                            isChoose={true}
                                            notMultipleSelect2={true}
                                            notMultiple={false}
                                            value={state.CompanyCode}
                                            name="CompanyCode"
                                            required={true}
                                            saveLocalState={true}
                                            disabled={modeEdit}
                                            isActive={true}
                                            country={'TH'}
                                            isGetAll={true}
                                            financeGroupId={state.FinanceGroupId}
                                        />
                                    ) :
                                        <MasterDropdownUI
                                            isChoose={true}
                                            notMultipleSelect2={true}
                                            value={state.CompanyCode}
                                            onChange={e => onChangeInputData({
                                                target: e
                                            })}
                                            name="CompanyCode"
                                            options={null} />
                                } */}
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  noValidateOption={modeEdit}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  value={state.CompanyCode}
                  name="CompanyCode"
                  required
                  saveLocalState
                  disabled={modeEdit}
                  isActive
                  country="TH"
                  isGetAll
                  financeGroupId={state.FinanceGroupId}
                />
              </FormGroup2>
            </Col2>

            {/* <Col2 col={colX[1]}>
                            <FormGroup2 text="LG Method" required={true}>
                                <MasterDropdownUI
                                    value={state.LgMethod}
                                    onChange={onChangeInputData}
                                    notMultiple={true}
                                    isChoose={true}
                                    required={true}
                                    disabled={noEdit}
                                    name="LgMethod"
                                    options={["Paper", "SFTP"]} />
                            </FormGroup2>
                        </Col2> */}
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Beneficiary Name" textTh="(ชื่อผู้รับผลประโยชน์)" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.BeneficiaryNameTh || ''}
                    name="BeneficiaryNameTh"
                    readOnly
                  />
                ) : (
                  <Select2
                    noValidateOption
                    saveLocalState
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    required
                    value={state.BeneficiaryId}
                    name="BeneficiaryId"
                  >
                    {(state.beneficiaryList || []).map((m) => (
                      <option value={m.beneficiaryId} key={m.beneficiaryId}>
                        {m.beneficiaryNameTh} {m.beneficiaryName ? ` | ${m.beneficiaryName}` : ''}
                      </option>
                    ))}
                  </Select2>
                )}
              </FormGroup2>

              <FormGroup2 text="Form Type" textTh="(ประเภทของหนังสือค้ำประกัน)" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={`${state.LgTypeCode || ''} | ${state.LgTypeName || ''}`}
                    name="LgTypeId"
                    readOnly
                  />
                ) : (
                  <Select2
                    noValidateOption
                    saveLocalState
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    required
                    value={state.LgTypeId}
                    name="LgTypeId"
                  >
                    {(state.lgTypeList || []).map((m) => (
                      <option value={m.lgTypeId} key={m.lgTypeId}>
                        {m.lgTypeCode} | {m.lgTypeName}
                      </option>
                    ))}
                  </Select2>
                )}
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Form No." textTh="(เลขที่ฟอร์มของหนังสือค้ำประกันตาม MOU)" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={state.BgFormName || ''}
                    name="BgFormName"
                    readOnly
                  />
                ) : (
                  <>
                    <Select2
                      noValidateOption
                      saveLocalState
                      notMultiple
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      required
                      value={state.BgFormId}
                      name="BgFormId"
                    >
                      {(state.formNoList || []).map((m) => (
                        <option value={m.bgFormId} key={m.bgFormId}>
                          {m.bgFormName}
                        </option>
                      ))}
                    </Select2>
                    <span style={{ color: 'red' }}>{state.RemarkFormNo}</span>
                  </>
                )}
              </FormGroup2>

              <FormGroup2 text="Lg Format" textTh="(ประเภทการออกเอกสาร)" required>
                {/* <select tabIndex="-1" className="form-control"
                                    value={state.FormFormat}
                                    onChange={onChangeInputData}
                                    required={true}
                                    disabled={true}
                                    name="FormFormat" >
                                    <option value="">---Choose---</option>
                                    <option value="Paper">Paper</option>
                                    <option value="Blockchain">Blockchain</option>
                                </select> */}
                <input
                  className="form-control"
                  value={state.FormFormat || ''}
                  onChange={onChangeInputData}
                  required
                  disabled
                  name="FormFormat"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Language" textTh="(ภาษาของหนังสือค้ำประกัน)" required>
                <input
                  className="form-control"
                  value={state.Lang || ''}
                  onChange={onChangeInputData}
                  required
                  disabled
                  name="Lang"
                />
              </FormGroup2>

              <FormGroup2
                text="Method of receiving the original BG"
                textTh="(สถานที่สำหรับรับต้นฉบับเอกสารหนังสือค้ำประกัน)"
                required={toLower(state.FormFormat) !== 'blockchain'}
              >
                <Select2
                  noValidateOption
                  saveLocalState
                  notMultiple
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  value={state.MethodOfReceiving}
                  required={toLower(state.FormFormat) !== 'blockchain'}
                  disabled={
                    toLower(state.FormFormat) === 'blockchain' ? true : modeAmend ? false : noEdit
                  }
                  name="MethodOfReceiving"
                >
                  <option value="">---Choose---</option>
                  <option value="Pick up at Finance Dept.">
                    Pick up at Finance Dept. (รับที่หน่วยงานการเงิน)
                  </option>
                  <option value="Pick up at Bank branch">
                    Pick up at Bank branch (รับที่สาขาธนาคาร)
                  </option>
                  <option value="Send via EMS">Send via EMS (ส่งทาง EMS)</option>
                </Select2>
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank" textTh="(ธนาคารที่ขอออกหนังสือค้ำประกัน)" required>
                {/* {
                                    noEdit ? (
                                        <input className="form-control"
                                            onChange={onChangeInputData}
                                            value={state.BankCode || ''}
                                            name="BankCode"
                                            readOnly={true}
                                        />
                                    ) : */}
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  // filter={m => {
                  //     return m.bankCode === 'SICOTH' || m.bankCode === 'KASITH'
                  // }}
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={noEdit}
                  value={state.BankCode}
                  isOutFlow
                  isGetAll
                  name="BankCode"
                />
                {/* } */}
              </FormGroup2>

              <FormGroup2 text="Branch" textTh="(รหัส/ชื่อ สาขาที่รับหนังสือค้ำประกัน)" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    value={`${state.BranchCode || ''} | ${state.BranchName || ''}`}
                    name="BranchId"
                    readOnly
                  />
                ) : (
                  <Select2
                    noValidateOption
                    saveLocalState
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    required
                    value={state.BranchId}
                    disabled={toLower(state.FormFormat) === 'blockchain'}
                    name="BranchId"
                  >
                    {(state.branchList || []).map((m) => (
                      <option value={m.branchId} key={m.branchId}>
                        {m.branchCode} | {m.branchName}
                      </option>
                    ))}
                  </Select2>
                )}
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[0]}>
              <FormGroup2
                text="LG No."
                textTh="(เลขที่ LG)"
                required={false}
                maxLength={12}
                length={(state.FormNo || '').length}
              >
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.LgNo || ''}
                  name="LgNo"
                  maxLength={12}
                  disabled
                  // required={state.LgMethod === 'SFTP' ? false : true}
                  // disabled={state.LgMethod === 'SFTP' ? true : false}
                />
              </FormGroup2>

              <FormGroup2 text="Approver Method" textTh="(ประเภทการอนุมัติ)" required>
                {noEdit ? (
                  <input
                    className="form-control"
                    type="text"
                    value={
                      state.IsBuApprove
                        ? 'Approve on AFMS'
                        : 'Approve on paper (Please attached approved memo)'
                    }
                    name="IsBuApprove"
                    required
                    readOnly
                  />
                ) : (
                  <div>
                    <RadioButton
                      text="Approve on AFMS (อนุมัติบนระบบ AFMS)"
                      value
                      checked={state.IsBuApprove}
                      onChange={onChangeBuApprove}
                      name="IsBuApprove"
                      required
                    />
                    <br />
                    <RadioButton
                      text="Approve on paper (Please attached approved memo) (อนุมัติบนกระดาษ (กรุณาแนบเอกสาร memo))"
                      value={false}
                      checked={!state.IsBuApprove}
                      onChange={onChangeBuApprove}
                      name="IsBuApprove"
                    />
                  </div>
                )}
              </FormGroup2>
            </Col2>
            {state.IsBuApprove ? (
              <Col2 col={colX[0]}>
                <FormGroup2 text="Initial" textTh="(ชื่อของ Initial)" required>
                  <Select2
                    ref={initialRef}
                    option={optionForAjaxUserFromAd}
                    value={state.Initial}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    notMultiple
                    required
                    noValidateOption
                    disabled={modeAmend ? false : noEdit}
                    name="Initial"
                  />
                </FormGroup2>

                <FormGroup2 text="Approver BU" textTh="(ชื่อของผู้อนุมัติ (Business))" required>
                  <Select2
                    ref={approverBuRef}
                    option={optionForAjaxUserFromAd}
                    value={state.ApproverBu}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    noValidateOption
                    required
                    disabled={modeAmend ? false : noEdit}
                    notMultiple
                    name="ApproverBu"
                  />
                </FormGroup2>
              </Col2>
            ) : null}
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2
                text="Cost Center"
                textTh="(สถานที่สำหรับเรียกเก็บค่าใช้จ่าย)"
                required
                maxLength={50}
                length={(state.CostCenter || '').length}
              >
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.CostCenter || ''}
                  name="CostCenter"
                  maxLength={50}
                  required
                  disabled={noEdit}
                />
              </FormGroup2>

              <FormGroup2
                text="Internal Order"
                textTh="(รหัสงบประมาณ)"
                required={false}
                maxLength={50}
                length={(state.InternalOrder || '').length}
              >
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.InternalOrder || ''}
                  name="InternalOrder"
                  maxLength={15}
                  required={false}
                  disabled={noEdit}
                />
              </FormGroup2>
            </Col2>
            {toLower(state.MethodOfReceiving) === 'send via ems' && (
              <Col2 col={colX[1]}>
                <FormGroup2
                  text="Address Specify"
                  textTh="(ที่อยู่สำหรับจัดส่ง)"
                  required
                  maxLength={1000}
                  length={(state.AddressSpecify || '').length}
                >
                  <textarea
                    className="form-control"
                    rows="2"
                    onChange={onChangeInputData}
                    value={state.AddressSpecify || ''}
                    name="AddressSpecify"
                    maxLength={1000}
                    required
                    disabled={noEdit}
                  />
                </FormGroup2>
              </Col2>
            )}
          </Col2>
        </form>
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !permission ? (
            ''
          ) : isAmend ? (
            <Button
              txt="SUBMIT"
              icon="check"
              className="btn-success"
              onClick={(e) => onSubmit(e, 'submit')}
              disabled={false}
            />
          ) : noEdit ? (
            ''
          ) : (
            <>
              {state.Action === 'create' || ~['draft'].indexOf(toLower(state.StatusLg)) ? (
                <>
                  <Button
                    txt="SAVE DRAFT"
                    icon="save"
                    className="btn-info"
                    onClick={(e) => onSubmit(e, 'save')}
                    disabled={noEdit}
                  />
                  &nbsp;
                </>
              ) : null}
              {~['wait for confirm'].indexOf(toLower(state.StatusLg)) ? (
                <Button
                  txt="SAVE"
                  icon="save"
                  className="btn-info"
                  onClick={(e) => onSubmit(e, 'submit')}
                  disabled={noEdit}
                />
              ) : (
                <Button
                  txt="SUBMIT"
                  icon="check"
                  className="btn-success"
                  onClick={(e) => onSubmit(e, 'submit')}
                  disabled={noEdit}
                />
              )}
            </>
          )
        }
      />

      {!isAmend && state.Action === 'edit' ? (
        <Card textHeader="History" bgHeader="bg-primary" cardActions={['toggler']}>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-bordered table-nowrap">
                  <thead>
                    <tr>
                      <th>Description</th>
                      <th>By</th>
                      <th>Date</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!(state.HistoryDraft || []).length ? (
                      <tr>
                        <td colSpan="4" align="center">
                          No data available in table
                        </td>
                      </tr>
                    ) : null}
                    {state.HistoryDraft.map((m, i) => (
                      <tr key={i}>
                        <td>{m.description}</td>
                        <td>{m.createdBy}</td>
                        <td>{m.createdDateStr}</td>
                        <td>
                          {m.description.includes('Amend') && m.remark ? (
                            <a
                              target="_blank"
                              href={`/bank-guarantee/request/${m.remark}`}
                              rel="noreferrer"
                            >
                              {m.remark}
                            </a>
                          ) : (
                            m.remark
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>
      ) : null}

      <Modal size="modal-md" ref={modalFunctionRef} modalFooter>
        <form onSubmit={onSubmitModalFunction} className="form-horizontal">
          <div className="text-center">
            <span
              className={`text-${state.modalFunction.type} icon ${state.modalFunction.icon} icon-5x`}
            />
            <h3 className={`text-${state.modalFunction.type}`}>{state.modalFunction.textHeader}</h3>
            <p>Do you want to {state.modalFunction.textContent}?</p>
          </div>
          {state.modalFunction.mode !== 'approve' ? (
            <>
              <br />
              <div className="form-group">
                <label className="control-label col-md-4">
                  Remark<span style={{ color: '#e64a19' }}>*</span>
                </label>
                <div className="col-md-7">
                  <textarea
                    className="form-control"
                    rows="3"
                    onChange={onChangeInputModal}
                    name="Remark"
                    value={state.modalFunction.Remark}
                    required
                  />
                </div>
              </div>
            </>
          ) : null}

          <div className="text-center">
            <div className="m-t-lg">
              <Button txt="Yes" className={`btn btn-${state.modalFunction.type}`} type="submit" />
              &nbsp;
              <Button txt="No" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};
