import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import { DATATABLE_ID } from './BankContainer';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import DatePicker from '../../common/DatePicker';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { MASTER_CURRENCY } from '../../reducers/master/action';
import { toStatus, toNumber, getPermission } from '../../common/helpper';

const permission = getPermission('Master Finance', 'Exchange Rate');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-5', 'col-md-5'];

const opt = {
  order: [[2, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'exchangeRateType', className: 'text-center' },
    { data: 'currencyFrom', className: 'text-center' },
    { data: 'currencyTo', className: 'text-center' },
    { data: 'validFromStr', className: 'text-center', type: 'date-black' },
    { data: 'rate', className: 'text-right', render: (d) => toNumber(d, 8) },
    { data: 'ratioFrom', className: 'text-right', render: (d) => toNumber(d, 0) },
    { data: 'ratioTo', className: 'text-right', render: (d) => toNumber(d, 0) },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Mode === 'edit';
  const checkSave = !!((modalData.Mode === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" /> Exchange Rate
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency From">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.CurrencyFrom}
                  name="CurrencyFrom"
                />
              </FormGroup2>

              <FormGroup2 text="Currency To">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.CurrencyTo}
                  name="CurrencyTo"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Valid Date" required>
                <DateRangePicker
                  value={criteria.ValidDateStr}
                  onChange={onChangeSearch}
                  name="ValidDateStr"
                  required
                />
              </FormGroup2>

              <FormGroup2 text="Exchange Rate Type">
                <MasterDropdownUI
                  onChange={onChangeSelect2}
                  value={criteria.Type}
                  name="Type"
                  options={['B', 'G', 'M', 'KHB1', 'KHG1', 'KHM1', 'VNB1', 'VNG1', 'VNM1']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  name="Status"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="Create Exchange Rate"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalData}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>
                Exchange
                <br /> Rate Type
              </th>
              <th>
                Currency
                <br /> From
              </th>
              <th>
                Currency
                <br /> To
              </th>
              <th>Valid From</th>
              <th className="text-center">Rate</th>
              <th className="text-center">Ratio From</th>
              <th className="text-center">Ratio To</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Exchange Rate" bgHeader="bg-primary" size="modal-md" ref={modalDataRef}>
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <FormGroup3 text="Valid From" col={colX1} required>
            <DatePicker
              value={modalData.ValidFromStr}
              onChange={onChangeModalData}
              name="ValidFromStr"
              required
              option={{
                // startDate: "d",
                // daysOfWeekDisabled: "0,6",
                todayHighlight: true,
              }}
              disabled={modeEdit}
            />
          </FormGroup3>

          <FormGroup3 text="Exchange Rate Type" col={colX1} required>
            <MasterDropdownUI
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              value={modalData.ExchangeRateType}
              isChoose
              notMultipleSelect2
              name="ExchangeRateType"
              disabled={modeEdit}
              required
              options={['B', 'G', 'M', 'KHB1', 'KHG1', 'KHM1', 'VNB1', 'VNG1', 'VNM1']}
            />
          </FormGroup3>

          <FormGroup3 text="Currency From" col={colX1} required>
            {modeEdit ? (
              <input
                className="form-control"
                onChange={onChangeModalData}
                value={modalData.CurrencyFrom}
                required
                readOnly
                name="CurrencyFrom"
              />
            ) : (
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={(e) =>
                  onChangeModalData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                value={modalData.CurrencyFrom}
                required
                disabled={modeEdit}
                name="CurrencyFrom"
              />
            )}
          </FormGroup3>

          <FormGroup3 text="Currency To" col={colX1} required>
            {modeEdit ? (
              <input
                className="form-control"
                onChange={onChangeModalData}
                value={modalData.CurrencyTo}
                required
                readOnly
                name="CurrencyTo"
              />
            ) : (
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                onChange={(e) =>
                  onChangeModalData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                value={modalData.CurrencyTo}
                disabled={modeEdit}
                required
                name="CurrencyTo"
              />
            )}
          </FormGroup3>

          <FormGroup3 text="Rate" col={colX1} required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 8,
                digitsOptional: false,
                placeholder: '0.00000000',
              }}
              onChange={onChangeModalData}
              value={modalData.Rate}
              required
              name="Rate"
            />
          </FormGroup3>

          <FormGroup3 text="Ratio From" col={colX1} required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 0,
                // digitsOptional: false,
                // placeholder: '0'
              }}
              onChange={onChangeModalData}
              value={modalData.RatioFrom}
              required
              name="RatioFrom"
            />
          </FormGroup3>

          <FormGroup3 text="Ratio To" col={colX1} required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 0,
                // digitsOptional: false,
                // placeholder: '0'
              }}
              onChange={onChangeModalData}
              value={modalData.RatioTo}
              required
              name="RatioTo"
            />
          </FormGroup3>

          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
