import { defaultImportExcelResult } from '../configuration';
import {
  RECEIVE_IMPORT_EXCEL,
  FAIL_SAVE_IMPORT_EXCEL,
  RECEIVE_SAVE_IMPORT_EXCEL,
  RECEIVE_SUBMIT_ENDING_BALANCE,
  RECEIVE_SUBMIT_BEGINNING_BALANCE,
  RECEIVE_SEARCH_BANK_ENDING_BALANCE,
  EDIT_BANK_ENDING_BALANCE_SEACH_RESULT,
} from './action';

const init = {
  searchResult: [],
  importExcelResult: defaultImportExcelResult,
};

export default (state = init, action) => {
  switch (action.type) {
    case EDIT_BANK_ENDING_BALANCE_SEACH_RESULT:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i)
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          return m;
        }),
      };
    case RECEIVE_SEARCH_BANK_ENDING_BALANCE:
      return {
        ...state,
        searchResult: action.payload,
      };
    case RECEIVE_IMPORT_EXCEL:
      return {
        ...state,
        importExcelResult: action.payload,
      };
    case RECEIVE_SUBMIT_ENDING_BALANCE:
      return {
        ...state,
        searchResult: [
          ...state.searchResult.slice(0, action.payload.index),
          {
            ...state.searchResult[action.payload.index],
            endingBalanceFromBank: action.payload.Amount,
          },
          ...state.searchResult.slice(action.payload.index + 1),
        ],
      };
    case RECEIVE_SUBMIT_BEGINNING_BALANCE:
      return {
        ...state,
        searchResult: [
          ...state.searchResult.slice(0, action.payload.index),
          {
            ...state.searchResult[action.payload.index],
            beginningBalance: action.payload.Amount,
          },
          ...state.searchResult.slice(action.payload.index + 1),
        ],
      };
    case RECEIVE_SAVE_IMPORT_EXCEL:
      return {
        ...state,
        importExcelResult: defaultImportExcelResult,
      };
    case FAIL_SAVE_IMPORT_EXCEL:
      return {
        ...state,
        importExcelResult: defaultImportExcelResult,
      };
    default:
      return state;
  }
};
