import {
  CLEAR_SEARCH_AUTO_TRANSFER,
  RECEIVE_SEARCH_AUTO_TRANSFER,
  SET_SELECTED_ALL_AUTO_TRANSFER,
  SET_VALUE_SEARCH_AUTO_TRANSFER,
  SET_ALL_TRANSFER_METHOD_VALUE_SEARCH_AUTO_TRANSFER,
} from './action';

const init = {
  searchResult: [],
};
export default (state = init, action) => {
  switch (action.type) {
    case CLEAR_SEARCH_AUTO_TRANSFER:
      return {
        ...state,
        searchResult: [],
      };
    case SET_VALUE_SEARCH_AUTO_TRANSFER: {
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          const { name, value, index } = action.payload;
          let data = m;
          if (i === index)
            data = {
              ...m,
              [name]: value,
            };
          if (name === 'transferMethod')
            data = {
              ...data,
              applicantFeeAmount: 0,
              beneficiaryFeeAmount: 0,
            };
          return data;
        }),
      };
    }
    case SET_ALL_TRANSFER_METHOD_VALUE_SEARCH_AUTO_TRANSFER:
      return {
        ...state,
        searchResult: state.searchResult.map((m) => ({
          ...m,
          transferMethod: action.payload,
          applicantFeeAmount: 0,
          beneficiaryFeeAmount: 0,
        })),
      };
    case RECEIVE_SEARCH_AUTO_TRANSFER:
      return {
        ...state,
        searchResult: action.payload,
      };
    case SET_SELECTED_ALL_AUTO_TRANSFER:
      return {
        ...state,
        searchResult: state.searchResult.map((m) => ({
          ...m,
          isChecked: action.payload.isChecked,
        })),
      };
    default:
      return state;
  }
};
