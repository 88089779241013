import React, { useMemo, useState, Fragment, useEffect } from 'react';

import { store } from '../../Root';
import Card from '../../common/Card';
import InputMask from '../../common/InputMask';
import { toFixed } from '../../common/helpper';
import { getRolloverUnwindHistory } from '../../reducers/manageFx/action';

function ForwardRolloverUnwindHistoryPage({ routeProp }) {
  const [historyArr, setHistoryArr] = useState([]);

  useEffect(() => {
    const transactionNo = routeProp.match.params.t;
    async function fetch() {
      const response = await store.dispatch(getRolloverUnwindHistory(transactionNo));
      if (response.error) return;

      setHistoryArr(response.payload || []);
    }
    fetch();
  }, [routeProp.match.params.t]);

  const historys = useMemo(() => {
    const data = {
      unwind: [],
      rollover: [],
    };
    historyArr.forEach((m) => {
      if (m.historyType === 'Rollover') data.rollover.push(m);
      else data.unwind.push(m);
    });
    return data;
  }, [historyArr]);

  return (
    <>
      <RolloverTable datas={historys.rollover} />
      <UnwindTable datas={historys.unwind} />
    </>
  );
}

function UnwindTable({ datas = [] }) {
  function renderBody() {
    if (datas.length === 0)
      return (
        <tr>
          <td colSpan="18" align="center">
            No Data.
          </td>
        </tr>
      );

    return datas.map((m, i) => (
      <tr key={i}>
        <td className="text-center">{m.forwardNo}</td>
        <td className="text-center">{m.companyCode}</td>
        <td>{m.companyName}</td>
        <td>{m.bankName}</td>
        <td className="text-center">{m.unwindDateStr}</td>
        <td className="text-center">
          <input
            type="text"
            className="form-control"
            value={m.unwindPaymentDateStr || ''}
            readOnly
          />
        </td>
        <td>{m.oldContractNo}</td>
        <td className="text-right">{setNumber(m.oldForwardRate, 8)}</td>
        <td className="text-center">{m.oldEndDateStr}</td>
        <td className="text-right">{setNumber(m.outstanding)}</td>
        <td className="text-right">
          <InputMask
            key={`${m.oldForwardNo}${m.outstanding || 0}`}
            className="form-control width-input-table"
            format="currency"
            option={{
              prefix: '',
              digits: 3,
              max: m.outstanding,
              digitsOptional: false,
              placeholder: '0.000',
            }}
            readOnly
            value={m.unwindAmount || ''}
            name="unwindAmount"
          />
        </td>
        <td className="text-center">{m.forwardCurrency}</td>
        <td className="text-right">
          <InputMask
            className="form-control width-input-table"
            format="currency"
            option={{
              prefix: '',
              digits: 8,
              digitsOptional: false,
              placeholder: '0.00000000',
            }}
            value={m.settlementRate || ''}
            readOnly
            name="settlementRate"
          />
        </td>
        <td className="text-right">{setNumber(m.spotRate, 8)}</td>
        <td className="text-center">{m.productType}</td>
        <td className="text-right">
          <InputMask
            className="form-control width-input-table"
            format="currency"
            option={{
              prefix: '',
              digits: 3,
              digitsOptional: false,
              placeholder: '0.000',
            }}
            value={m.gainLossAmount || ''}
            readOnly
            name="gainLossAmount"
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control width-input-table-account"
            value={m.gainLossCompanyCode || ''}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control width-input-table-account"
            value={m.gainLossBankAccountNo || ''}
            readOnly
          />
        </td>
      </tr>
    ));
  }
  return (
    <Card textHeader="Unwind History" bgHeader="bg-info" number="1" cardActions={['toggler']}>
      <div className="table-responsive" style={{ maxHeight: '500px' }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th className="th-success">
                Transaction
                <br /> No
              </th>
              <th className="th-success">
                Company
                <br /> Code
              </th>
              <th className="th-success">Company Name</th>
              <th className="th-success">Bank</th>
              <th className="th-success">Unwind Date</th>
              <th className="th-primary">Unwind Payment Date</th>
              <th className="th-success">Old Contract No.</th>
              <th className="text-center th-success">
                Old Contract
                <br /> Forward Rate
              </th>
              <th className="th-success">Old End Date</th>
              <th className="text-center th-success">
                Old Outstanding
                <br /> Amount
              </th>
              <th className="th-primary">Unwind Amount</th>
              <th className="th-success">Currency</th>
              <th className="th-primary">Settlement Rate</th>
              <th className="th-success">Spot Rate</th>
              <th className="th-success">Product Type</th>
              <th className="th-primary">Gain/Loss</th>
              <th className="th-primary">Gain/Loss Company</th>
              <th className="th-primary">Gain/Loss Bank Account</th>
            </tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </table>
      </div>
    </Card>
  );
}

function RolloverTable({ datas = [] }) {
  function renderBody() {
    if (datas.length === 0)
      return (
        <tr>
          <td colSpan="18" align="center">
            No Data.
          </td>
        </tr>
      );
    return datas.map((m, i) => (
      <tr key={i}>
        <td className="text-center">{m.forwardNo}</td>
        <td className="text-center">
          <input
            className="form-control"
            value={m.newContractNo || ''}
            name="newContractNo"
            readOnly
          />
        </td>
        <td className="text-center">{m.companyCode}</td>
        <td>{m.companyName}</td>
        <td>{m.bankName}</td>
        <td className="text-center">{m.rollOverDateStr}</td>
        <td className="text-center">
          <input
            type="text"
            className="form-control"
            value={m.rollOverPaymentDateStr || ''}
            readOnly
          />
        </td>
        <td>{m.oldContractNo}</td>
        <td className="text-right">{setNumber(m.oldForwardRate, 8)}</td>
        <td className="text-center">{m.oldEndDateStr}</td>
        <td className="text-right">{setNumber(m.outstanding)}</td>
        <td className="text-right">
          <InputMask
            key={`${m.oldForwardNo}${m.outstanding || 0}`}
            className="form-control width-input-table"
            format="currency"
            option={{
              prefix: '',
              digits: 3,
              max: m.outstanding,
              digitsOptional: false,
              placeholder: '0.000',
            }}
            value={m.rollOverAmount || ''}
            readOnly
            name="rollOverAmount"
          />
        </td>
        <td>{m.currency}</td>
        <td className="text-right">
          <InputMask
            className="form-control width-input-table"
            format="currency"
            option={{
              prefix: '',
              digits: 8,
              digitsOptional: false,
              placeholder: '0.00000000',
            }}
            value={m.settlementRate || ''}
            readOnly
            name="settlementRate"
          />
        </td>
        <td className="text-center">{m.newStartDateStr}</td>
        <td className="text-center">{m.newEndDateStr}</td>
        <td className="text-right">{setNumber(m.spotRate, 8)}</td>
        <td className="text-right">{setNumber(m.newSpotRate, 8)}</td>
        <td className="text-right">{setNumber(m.newSwapRate, 8)}</td>
        <td className="text-right">{setNumber(m.newForwardRate, 8)}</td>
        <td className="text-center">{m.productType}</td>
        <td className="text-center">
          <input type="text" className="form-control" value={m.forwardType} readOnly />
        </td>
        <td className="text-center">{m.purpose}</td>
        <td>{m.department}</td>
        <td className="text-right">
          <InputMask
            className="form-control width-input-table"
            format="currency"
            option={{
              prefix: '',
              digits: 3,
              digitsOptional: false,
              placeholder: '0.000',
            }}
            value={m.gainLossAmount || ''}
            readOnly
            name="gainLossAmount"
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control width-input-table-account"
            value={m.gainLossCompanyCode || ''}
            readOnly
          />
        </td>
        <td>
          <input
            type="text"
            className="form-control width-input-table-account"
            value={m.gainLossBankAccountNo || ''}
            readOnly
          />
        </td>
      </tr>
    ));
  }
  return (
    <Card textHeader="Rollover History" bgHeader="bg-info" number="1" cardActions={['toggler']}>
      <div className="table-responsive" style={{ maxHeight: '500px' }}>
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th className="th-success">Transaction No.</th>
              <th className="th-primary">New Forward Contract No.</th>
              <th className="th-success">
                Company
                <br /> Code
              </th>
              <th className="th-success">Company Name</th>
              <th className="th-success">Bank</th>
              <th className="th-success">Rollover Date</th>
              <th className="th-primary">Rollover Payment Date</th>
              <th className="th-success">Old Contract No.</th>
              <th className="text-center th-success">
                Old Contract
                <br /> Forward Rate
              </th>
              <th className="th-success">Old End Date</th>
              <th className="text-center th-success">
                Old Outstanding
                <br /> Amount
              </th>
              <th className="th-primary">Rollover Amount</th>
              <th className="th-success">Currency</th>
              <th className="th-primary">Settlement Rate</th>
              <th className="th-success">Start Date</th>
              <th className="th-success">End Date</th>
              <th className="th-success">
                Spot Rate
                <br />
                (Market Rate)
              </th>
              <th className="th-success">Spot Rate</th>
              <th className="th-success">Swap Rate</th>
              <th className="th-success">Forward Rate</th>
              <th className="th-success">Product Type</th>
              <th className="th-primary">Forward Type</th>
              <th className="th-success">Purpose</th>
              <th className="th-success">Department</th>
              <th className="th-primary">Gain/Loss</th>
              <th className="th-primary">Gain/Loss Company</th>
              <th className="th-primary">Gain/Loss Bank Account</th>
            </tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </table>
      </div>
    </Card>
  );
}

export default ForwardRolloverUnwindHistoryPage;

const setNumber = (number, float = 3) => {
  if (isNaN(number)) return '';
  if (number >= 0) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
};
