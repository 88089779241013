import {
  EDIT_INTEREST_REPORT_CRITERIA,
  RECEIVE_SEARCH_INTEREST_REPORT,
  CLEAR_INTEREST_REPORT_CRITERIA,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  CompanyCode: [''],
  PartnerType: '',
  Partner: [''],
  Currency: [''],
  ProductType: [''],
  InterestType: [''],
  TransactionType: [''],
  InterestDateStr: '',
  InterestDueDateStr: '',
  IsSweep: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
};

export default (state = init, action) => {
  switch (action.type) {
    case CLEAR_INTEREST_REPORT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_INTEREST_REPORT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_INTEREST_REPORT:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
