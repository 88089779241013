import React from 'react';

export default ({ type = 'button', className, icon, txt, onClick, disabled, ...rest }) => {
  if (icon == null) {
    return (
      <button
        type={`${type}`}
        className={`btn ${className}`}
        onClick={onClick}
        disabled={disabled}
        {...rest}
      >
        {txt}
      </button>
    );
  }
  return (
    <button
      type={`${type}`}
      className={`btn btn-labeled btn-sm ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      <span className="btn-label">
        <i className={`icon icon-${icon} icon-fw icon-lg`} />
      </span>{' '}
      {txt}
    </button>
  );
};
