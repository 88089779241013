import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';

export const REQUEST_SEARCH_BANK_ACCOUNT_SUMMARY = '@@request/SEARCH_BANK_ACCOUNT_SUMMARY';
export const RECEIVE_SEARCH_BANK_ACCOUNT_SUMMARY = '@@receive/SEARCH_BANK_ACCOUNT_SUMMARY';
export const FAIL_SEARCH_BANK_ACCOUNT_SUMMARY = '@@fail/SEARCH_BANK_ACCOUNT_SUMMARY';

export const searchBankAccountSummary = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankAccountSummary', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_BANK_ACCOUNT_SUMMARY,
      RECEIVE_SEARCH_BANK_ACCOUNT_SUMMARY,
      FAIL_SEARCH_BANK_ACCOUNT_SUMMARY,
    ],
  },
});
