import { connect } from 'react-redux';
import React, { Component } from 'react';

import Bond from './Bond';
import Button from '../../../common/Button';
import { addAlert } from '../../../reducers/layout/action';
import { getExchangeRate, getCurrencyDigit } from '../../../reducers/master/action';
import {
  toFixed,
  toUpperKey,
  compareDate,
  getCountMonth,
  getPermission,
} from '../../../common/helpper';
import {
  getBond,
  saveBond,
  saveIssuer,
  splitCashFlow,
  cancelCashflow,
} from '../../../reducers/bond/action';

const permission = getPermission('Funding&Investment', 'Create Bond');

const $ = window.jQuery;

const initInformation = {
  FinanceGroupId: '',
  CompanyCode: '',
  Remark: '',
  Source: 'Bond',
};

const initStructure = {
  BondType: '',
  BondId: '',
  Issuer: '',
  Partner: '',
  Currency: '',
  StartDateStr: '',
  EndDateStr: '',
  Unit: '',
  NominalAmount: '',
  PricePerUnitNormalAmount: '',
  ActualAmount: '',
  PricePerUnitActualAmount: '',
  BaseDay: 365,
  EirPerYear: '',

  inputMaskAmount: {
    prefix: '',
    digits: 3,
    // digitsOptional: false,
    // placeholder: '0.000'
  },
};

const initAdministrator = {
  BusinessArea: '',
  GenValnClass: '',
};

const initCharge = {
  PaymentDateStr: '',
  Direction: '',
  FlowType: '',
  Amount: '',
  Currency: '',
  PaymentMethod: '',
  Source: '',
};

const initCashFlow = {
  BankCodeCashFlow: '',
  resultCashflow: [],
  AccountNoAll: '',
  PaymentMethodAll: '',
};

const initIssuer = {
  IssuerName: '',
  IssuerCountry: '',
  Source: '',
};

const initCancel = {
  CashflowId: '',
};

const initModalSplit = {
  CashflowId: '',
  CashFlowType: '',
  Amount: '',
  OldAmount: '',
  Mode: '',
  Source: '',
};

class BondContainer extends Component {
  state = {
    ...initInformation,
    ...initStructure,
    ...initAdministrator,
    ...initCashFlow,
    mode: 'create',
    permission,

    modalCharge: {
      ...initCharge,
    },
    modalIssuer: {
      ...initIssuer,
    },
    modalCancel: {
      ...initCancel,
    },
    modalSplit: {
      ...initModalSplit,
    },
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;
    if (transactionNo && transactionNo !== 'create') {
      this.props.getBond(transactionNo).then((response) => {
        if (response.error && !response.payload) return;

        const s = response.payload;

        const resultCashFlow = s.cashFlow || [];
        this.setDataCashFlow(resultCashFlow);

        this.setState({
          ...toUpperKey(s),
          mode: 'edit',
          PricePerUnitNormalAmount: s.currency === 'THB' ? 1000 : '',
          PricePerUnitActualAmount: s.actualAmount / s.unit,
        });
      });
    }
    $('body').on('click', `button.delete-result`, (e) => this.onClickOpenModalCancel(e));
    $('body').on('click', `button.split-result`, (e) => this.onClickOpenModalSplit(e));
    $('body').on('click', `button.undo-split-result`, (e) => this.onClickOpenModalUndoSplit(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.delete-result`);
    $('body').off('click', `button.split-result`);
    $('body').off('click', `button.undo-split-result`);
  }

  componentDidUpdate(prevProps, prevState) {
    const { Currency, NominalAmount, PricePerUnitNormalAmount, ActualAmount } = this.state;

    // get currency digit
    if (prevState.Currency !== Currency) {
      this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          inputMaskAmount: {
            prefix: '',
            digits: digit,
            digitsOptional: false,
            placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
          },
          NominalAmount: toFixed(
            Number((NominalAmount.toString() || '').replace(/,/g, '') || 0),
            digit
          ),
          PricePerUnitNormalAmount: toFixed(
            Number((PricePerUnitNormalAmount.toString() || '').replace(/,/g, '') || 0),
            digit
          ),
          ActualAmount: toFixed(
            Number((ActualAmount.toString() || '').replace(/,/g, '') || 0),
            digit
          ),
        });
      });
    }
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      // Information
      if (name === 'CompanyCode') {
        stateToUpdate.BusinessArea = '';
      } else if (name === 'StartDateStr') {
        if (value === '') {
          stateToUpdate.StartDateStr = '';
          stateToUpdate.StartDate = '';
        } else {
          const mergeState = {
            ...state,
            ...stateToUpdate,
          };
          if (compareDate(mergeState.StartDateStr, mergeState.EndDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'Start date must be less than or equal End date.',
            });
            return {
              [name]: state.StartDateStr,
            };
          }
          if (compareDate(mergeState.StartDateStr, mergeState.EndDateStr)) {
            this.props.addAlert({
              title: 'Error',
              type: 'error',
              body: 'End date must be more than or equal Start date.',
            });
            return {
              [name]: state.EndDateStr,
            };
          }
          if (
            // getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) < 3
            getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) <= 3.1
          ) {
            stateToUpdate.GenValnClass = '<= 3 Months';
          } else if (
            // getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) >=
            //   3 &&
            // getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) <= 12
            getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) <= 12.13
          ) {
            stateToUpdate.GenValnClass = '> 3 <= 12 Months';
          } else if (
            // getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) > 12
            getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) > 12.13
          ) {
            stateToUpdate.GenValnClass = '> 12 Months';
          }
        }
      } else if (name === 'EndDateStr') {
        const mergeState = {
          ...state,
          ...stateToUpdate,
        };
        if (compareDate(mergeState.StartDateStr, mergeState.EndDateStr)) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'Start date must be less than or equal End date.',
          });
          return {
            [name]: state.StartDateStr,
          };
        }
        if (compareDate(mergeState.StartDateStr, mergeState.EndDateStr)) {
          this.props.addAlert({
            title: 'Error',
            type: 'error',
            body: 'End date must be more than or equal Start date.',
          });
          return {
            [name]: state.EndDateStr,
          };
        }
        if (
          // getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) < 3
          getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) <= 3.1
        ) {
          stateToUpdate.GenValnClass = '<= 3 Months';
        } else if (
          // getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) >= 3 &&
          // getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) <= 12
          getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) <= 12.13
        ) {
          stateToUpdate.GenValnClass = '> 3 <= 12 Months';
        } else if (
          // getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) > 12
          getCountMonth(mergeState.StartDateStr, mergeState.EndDateStr) > 12.13
        ) {
          stateToUpdate.GenValnClass = '> 12 Months';
        }
      } else if (name === 'Partner') {
        if (stateToUpdate.BankCodeCashFlow === undefined) {
          stateToUpdate.BankCodeCashFlow = value;
        }
      } else if (name === 'Currency') {
        if (value === 'THB') stateToUpdate.PricePerUnitNormalAmount = 1000;
        else stateToUpdate.PricePerUnitNormalAmount = state.NormalAmount / state.Unit;
        stateToUpdate.NormalAmount = stateToUpdate.PricePerUnitNormalAmount * state.Unit;
      } else if (name === 'Unit') {
        if (state.ActualAmount !== '' && value !== '')
          stateToUpdate.PricePerUnitActualAmount = state.ActualAmount / value;
        else stateToUpdate.PricePerUnitActualAmount = '';

        if (state.NormalAmount !== '' && value !== '' && state.Currency !== 'THB')
          stateToUpdate.PricePerUnitNormalAmount = state.NormalAmount / value;
        else if (state.Currency === 'THB') stateToUpdate.PricePerUnitNormalAmount = 1000;
        else stateToUpdate.PricePerUnitNormalAmount = '';
        stateToUpdate.NormalAmount = stateToUpdate.PricePerUnitNormalAmount * value;
        stateToUpdate.OutstandingUnit = value;
      } else if (name === 'ActualAmount') {
        if (value !== '' && state.Unit !== '')
          stateToUpdate.PricePerUnitActualAmount = value / state.Unit;
        else stateToUpdate.PricePerUnitActualAmount = '';
      } else if (name === 'NormalAmount' && state.Currency !== 'THB') {
        if (value !== '' && state.Unit !== '')
          stateToUpdate.PricePerUnitNormalAmount = value / state.Unit;
        else stateToUpdate.PricePerUnitNormalAmount = '';
      }

      // GenValnClass

      return stateToUpdate;
    });

    if (
      name === 'NominalAmount' ||
      name === 'ActualAmount' ||
      name === 'StartDateStr' ||
      name === 'EndDateStr' ||
      name === 'BaseDay' ||
      name === 'Currency'
    ) {
      this.calculateEIR();
    }
  };

  calculateEIR() {
    this.setState((state) => {
      if (
        state.NormalAmount !== '' &&
        state.ActualAmount !== '' &&
        state.StartDateStr !== '' &&
        state.EndDateStr !== '' &&
        state.BaseDay !== ''
      ) {
        const amountOfInterest = state.NormalAmount - state.ActualAmount;
        const { ActualAmount } = state;
        const { BaseDay } = state;
        let daysDifference = window
          .moment(state.EndDateStr, 'DD/MM/YYYY')
          .diff(window.moment(state.StartDateStr, 'DD/MM/YYYY'), 'days', true);
        daysDifference += 1;
        const bondHolders = daysDifference / 100;

        // let eir = (amountOfInterest / ActualAmount) * (BaseDay / bondHolders);
        let eir = state.PricePerUnitNormalAmount / state.PricePerUnitActualAmount;
        // console.log(state.PricePerUnitNormalAmount, state.PricePerUnitActualAmount)
        const a = 1 / daysDifference;
        // console.log(daysDifference)
        // console.log(eir, a)
        eir = (eir ** a - 1) * 365 * 100;

        state.EirPerYear = eir;
      }
      return state;
    });
  }

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (!form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmit = (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formStructorRef,
      this.formAdministratorRef,
      this.formCashFlowRef,
    ]);

    if (inValidFormName) return;

    if (this.state.resultCashflow.length === 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please get cash flow first.',
      });
      return;
    }

    const submitData = {
      ...this.state,
      Source: 'Bond',
      CashFlow: [...this.state.resultCashflow],
    };

    this.props.saveBond(submitData).then((response) => {
      if (!response.error) {
        this.setState({
          ...toUpperKey(response.payload),
          resultCashflow: response.payload.cashFlow,
        });
        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The transaction was successfully saved',
          buttons: [
            <Button
              txt="Continue"
              data-dismiss="modal"
              className="btn-success"
              onClick={(e) => this.props.routeProp.history.push('/loan-investment/create-bond')}
            />,
          ],
        });

        if (response.payload.transactionNo !== this.props.routeProp.match.params.id) {
          window.location.href = `/loan-investment/bond/${response.payload.transactionNo}`;
        }
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/loan-investment/create-bond?s=true');
  };

  onClickOpenModalIssuer = () => {
    this.setState(
      {
        modalIssuer: {
          ...initIssuer,
          mode: 'create',
        },
      },
      this.modalIssuerRef.open
    );
  };

  onChangeModalIssuer = (e) => {
    this.setState({
      modalIssuer: {
        ...this.state.modalIssuer,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalIssuer = (e) => {
    e.preventDefault();
    this.props
      .saveIssuer({
        ...this.state.modalIssuer,
      })
      .then((response) => {
        if (response.error) return;
        this.modalIssuerRef.close();
      });
  };

  onClickOpenModalCancel = (e) => {
    this.setState(
      {
        modalCancel: {
          CashflowId: e.currentTarget.attributes.cashflowid.value,
        },
      },
      this.modalCancelRef.open
    );
  };

  onSubmitModalCancel = (e) => {
    e.preventDefault();
    this.props
      .cancelCashflow({
        ...this.state.modalCancel,
      })
      .then((response) => {
        if (!response.error) {
          this.setState({
            resultCashflow: response.payload,
          });
          this.props.addAlert({
            title: 'Success',
            type: 'success',
            body: 'The transaction was successfully saved',
            buttons: [
              <button className="btn btn-success" data-dismiss="modal">
                Continue
              </button>,
            ],
          });
        }

        this.modalCancelRef.close();
      });
  };

  onClickGetCashflow = () => {
    const inValidFormName = this.validateForms([
      this.formStructorRef,
      this.formAdministratorRef,
      this.formCashFlowRef,
    ]);

    if (inValidFormName) return;

    const cashFlows = [];

    const cashFlowStart = {};
    cashFlowStart.paymentDateStr = this.state.StartDateStr;
    cashFlowStart.direction = '-';
    cashFlowStart.flowType = 'Purchasing Amount';
    cashFlowStart.unit = this.state.Unit;
    cashFlowStart.amount = this.state.ActualAmount;
    cashFlowStart.currency = this.state.Currency;
    cashFlowStart.paymentMethod = 'Auto Deduct';
    cashFlows.push({ ...cashFlowStart });

    const cashFlowEnd = {};
    cashFlowEnd.paymentDateStr = this.state.EndDateStr;
    cashFlowEnd.direction = '+';
    cashFlowEnd.flowType = 'Sale Value';
    cashFlowEnd.unit = this.state.Unit;
    cashFlowEnd.amount = this.state.NormalAmount;
    cashFlowEnd.currency = this.state.Currency;
    cashFlowEnd.paymentMethod = 'Auto Deduct';
    cashFlows.push({ ...cashFlowEnd });

    this.setState({
      resultCashflow: cashFlows,
    });
  };

  onChangeInputTable = (e, index, type) => {
    const { name, value, label } = e.target;
    this.setState((state) => {
      const keyForUpdate = 'resultCashflow';
      const result = state[keyForUpdate];
      return {
        [keyForUpdate]: result.map((m, i) => {
          if (i === index) {
            const obj = {
              ...m,
            };
            if (name === 'bankAccountNo') {
              obj.bankAccountNo = value;
              const bankAccountCurrency = label.split('|')[2].trim();
              obj.localCurrency = bankAccountCurrency;
              if (obj.currency === bankAccountCurrency) {
                obj.localCurrencyAmount = obj.amount;
              } else {
                obj.localCurrencyAmount = 0;
              }
              this.getExchangeRate(obj, index, keyForUpdate);
            }

            obj[name] = value;
            if (name === 'bankAccountNo') {
              const s = label.split('|');
              obj.localCurrency = s[2].trim();
            }
            return obj;
          }
          return m;
        }),
      };
    });
  };

  onChangeInputHeader = (e, type) => {
    const { name, value, label } = e.target;
    this.setState((state) => {
      const keyForUpdate = 'resultCashflow';
      const result = state[keyForUpdate];
      let keyInputAll = '';
      if (name === 'bankAccountNoAll') {
        keyInputAll = 'AccountNoAll';
      } else if (name === 'paymentMethodAll') {
        keyInputAll = 'PaymentMethodAll';
      }

      return {
        [keyInputAll]: value,
        [keyForUpdate]: result.map((m, i) => {
          const obj = {
            ...m,
          };

          if (name === 'bankAccountNoAll') {
            obj.bankAccountNo = value;
            const bankAccountCurrency = label.split('|')[2].trim();
            obj.localCurrency = bankAccountCurrency;
            if (obj.currency === bankAccountCurrency) {
              obj.localCurrencyAmount = obj.amount;
            } else {
              obj.localCurrencyAmount = 0;
            }
            this.getExchangeRate(obj, i, keyForUpdate);
          } else if (name === 'paymentMethodAll') {
            obj.paymentMethod = value;
          }

          return obj;
        }),
      };
    });
  };

  async getExchangeRate(rowData, idx, keyForUpdate) {
    const criteria = {
      CurrencyFrom: rowData.currency,
      CurrencyTo: rowData.localCurrency,
      Type: (rowData.cashFlowType || '').toLowerCase() === 'deposit' ? 'G' : 'B',
    };

    let exchangeRate = 1;
    if (criteria.CurrencyFrom !== criteria.CurrencyTo) {
      const response = await this.props.getExchangeRate(criteria);
      if (response.error) return;
      exchangeRate = response.payload;
    }

    this.setState((state) => ({
      [keyForUpdate]: state[keyForUpdate].map((m, i) => {
        if (i === idx)
          return {
            ...m,
            exchangeRate: exchangeRate || 1,
            localCurrencyAmount: rowData.amount * exchangeRate,
          };
        return m;
      }),
    }));
  }

  // Modal Split
  onClickOpenModalSplit = (e) => {
    this.setState(
      {
        modalSplit: {
          ...initModalSplit,
          CashflowId: e.currentTarget.attributes.cashflowid.value,
          OldAmount: e.currentTarget.attributes.amount.value,
        },
      },
      this.modalSplitRef.open
    );
  };

  onChangeModalSplit = (e) => {
    this.setState({
      modalSplit: {
        ...this.state.modalSplit,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalSplit = (e) => {
    e.preventDefault();

    const modalSplit = { ...this.state.modalSplit };

    if (!modalSplit.Amount || parseFloat(modalSplit.Amount) === 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Split Amount must not be zero.',
      });
      return;
    }
    if (parseFloat(modalSplit.Amount) > parseFloat(modalSplit.OldAmount)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Split Amount must less than Amount.',
      });
      return;
    }

    const submitData = {
      CashflowId: modalSplit.CashflowId,
      Amount: modalSplit.Amount,
      Mode: 'split',
    };

    this.props.splitCashFlow(submitData).then((response) => {
      if (response.error && !response.payload) return;
      const resultCashFlow = response.payload || [];
      this.setDataCashFlow(resultCashFlow);
    });
    this.modalSplitRef.close();
  };

  // Modal Undo Split
  onClickOpenModalUndoSplit = (e) => {
    this.setState(
      {
        modalSplit: {
          ...initModalSplit,
          CashflowId: e.currentTarget.attributes.cashflowid.value,
          Amount: e.currentTarget.attributes.amount.value,
        },
      },
      this.modalUndoSplitRef.open
    );
  };

  onSubmitModalUndoSplit = () => {
    const modalSplit = { ...this.state.modalSplit };

    const submitData = {
      CashflowId: modalSplit.CashflowId,
      Amount: modalSplit.Amount,
      Mode: 'undo',
    };

    this.props.splitCashFlow(submitData).then((response) => {
      if (response.error && !response.payload) return;
      const resultCashFlow = response.payload || [];
      this.setDataCashFlow(resultCashFlow);
    });
    this.modalUndoSplitRef.close();
  };

  async setDataCashFlow(resultCashFlow) {
    this.state.resultCashflow = [];

    for (let i = 0; i < resultCashFlow.length; i++) {
      const r = resultCashFlow[i];
      // r.modeEnd = checkTermStart
      r.modeEnd = false;

      const { currency } = r;
      const localCurrency = r.bankAccountCurrency;

      let exchangeRate = 1;
      if (currency === localCurrency) {
        r.exchangeRate = exchangeRate;
        r.localCurrencyAmount = r.amount;
      } else {
        const criteria = {
          CurrencyFrom: currency,
          CurrencyTo: localCurrency,
          Type: 'B',
        };
        const response = await this.props.getExchangeRate(criteria);
        if (response.error) return;
        exchangeRate = response.payload;

        r.exchangeRate = exchangeRate;
        r.localCurrencyAmount = r.amount * exchangeRate;
      }

      this.state.resultCashflow.push(r);
    }
  }

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmit,

      onClickOpenModalCharge: this.onClickOpenModalCharge,
      onChangeModalCharge: this.onChangeModalCharge,
      onSubmitModalCharge: this.onSubmitModalCharge,

      onClickOpenModalIssuer: this.onClickOpenModalIssuer,
      onChangeModalIssuer: this.onChangeModalIssuer,
      onSubmitModalIssuer: this.onSubmitModalIssuer,
      onSubmitModalCancel: this.onSubmitModalCancel,
      onClickGetCashflow: this.onClickGetCashflow,
      onChangeInputTable: this.onChangeInputTable,
      onChangeInputHeader: this.onChangeInputHeader,

      onChangeModalSplit: this.onChangeModalSplit,
      onSubmitModalSplit: this.onSubmitModalSplit,
      onSubmitModalUndoSplit: this.onSubmitModalUndoSplit,

      formDetailRef: (e) => (this.formDetailRef = e),
      formStructorRef: (e) => (this.formStructorRef = e),
      formAdministratorRef: (e) => (this.formAdministratorRef = e),
      formCashFlowRef: (e) => (this.formCashFlowRef = e),

      tabRef: (e) => (this.tabRef = e),
    };

    return (
      <Bond
        {...props}
        state={this.state}
        modalCharge={this.state.modalCharge}
        modalChargeRef={(e) => (this.modalChargeRef = e)}
        modalIssuer={this.state.modalIssuer}
        modalIssuerRef={(e) => (this.modalIssuerRef = e)}
        modalCancelRef={(e) => (this.modalCancelRef = e)}
        modalSplitRef={(e) => (this.modalSplitRef = e)}
        modalUndoSplitRef={(e) => (this.modalUndoSplitRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.bond,
  }),
  {
    saveBond,
    getBond,
    saveIssuer,
    cancelCashflow,
    addAlert,
    getExchangeRate,
    splitCashFlow,
    getCurrencyDigit,
  }
)(BondContainer);
