import {
  EDIT_MASTER_FLOW_ACCOUNT_CRITERIA,
  RECEIVE_SEARCH_MASTER_FLOW_ACCOUNT,
  CLEAR_MASTER_FLOW_ACCOUNT_CRITERIA,
  UPDATE_SEARCH_RESULT_MASTER_FLOW_ACCOUNT,
} from './action';

const initCriteria = {
  ProductCategory: [''],
  TransactionType: [''],
  FlowType: [''],
  PostingProcess: [''],
  FlowNo: '',
  FlowName: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
};

export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_RESULT_MASTER_FLOW_ACCOUNT:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_MASTER_FLOW_ACCOUNT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MASTER_FLOW_ACCOUNT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_MASTER_FLOW_ACCOUNT:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
