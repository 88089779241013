import { connect } from 'react-redux';
import React, { Component } from 'react';

import { addAlert } from '../../../reducers/layout/action';
import InterestReceipt, { printsSelectedName, checkOtherPritsSelected } from './InterestReceipt';
import {
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../../common/helpper';
import {
  editCriteria,
  clearCriteria,
  updateSearchResult,
  printInterestReceive,
  saveInterestReceiptReport,
  searchInterestReceiptReport,
} from '../../../reducers/reportInterestReceipt/action';

export const DATATABLE_ID = 'table-result-report-interest-receipt1';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';
export const COL_CHECKBOX_CONTEXT_PRINT = '.col-label-checkbox-print';
export const COL_CHOOSE_ALL_CONTEXT_PRINT = '.label-checkbox.fix.choose-all-print';

const jwt = JSON.parse(localStorage.getItem('jwt'));
const USERNAME = jwt ? jwt.username : '';
const $ = window.jQuery;
const initData = {
  FlowNo: '',
  Method: 'Transfer',
  IssuedBy: USERNAME.toUpperCase(),
  ReceivedBy: USERNAME.toUpperCase(),
  FormLang: 'TH',
  ElectronicDocument: '',
  Action: '',
};

class InterestReceiptContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    this.props.editCriteria('FisicalYear', year);
    this.props.editCriteria('Month', month);

    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT_PRINT}`, this.onClickColumnLabelCheckboxPrint);
    $('body').on(
      'click',
      `th ${COL_CHOOSE_ALL_CONTEXT_PRINT}`,
      this.onClickCheckboxSelecteAllPrint
    );
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT_PRINT}`, this.onClickColumnLabelCheckboxPrint);
    $('body').off(
      'click',
      `th ${COL_CHOOSE_ALL_CONTEXT_PRINT}`,
      this.onClickCheckboxSelecteAllPrint
    );
  }

  // Generate
  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    this.props.searchResult[result.row].IsSelected = result.checked;
    this.forceUpdate();
  };

  // Print
  onClickCheckboxSelecteAllPrint = (e) => {
    const checked = !!~e.currentTarget.className.indexOf('checked');
    const printMode = $(e.currentTarget).data('print');
    this.props.updateSearchResult(`IsSelected${printMode}`, !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckboxPrint = (e) => {
    const cl = e.currentTarget.classList;
    const printMode = cl[cl.length - 1];
    console.log(e.currentTarget.classList);
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable,
      `col-label-checkbox-print.${printMode}`
    );
    if (!result) return;
    this.props.searchResult[result.row][`IsSelected${printMode}`] = result.checked;

    this.dataTableSearchResultRef.$dataTable.row(result.row).invalidate().draw(false);
    this.forceUpdate();
  };

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchInterestReceiptReport({ ...this.props.criteria });
    clearCheckBoxAllTable(DATATABLE_ID);
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    this.props.editCriteria('FisicalYear', year);
    this.props.editCriteria('Month', month);
  };

  onClickOpenModalGenerate = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);

    const flowNos = selectedData.map((m) => m.flowNo);
    const flowNo = flowNos.toString();

    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for generate!',
      });
      return;
    }

    this.setState(
      {
        modalData: {
          ...initData,
          FlowNo: flowNo,
          Action: 'Generate No',
        },
      },
      this.modalGenerateRef.open()
    );
    console.log(initData);
  };

  onSubmitModalGenerate = (e) => {
    e.preventDefault();

    this.props.saveInterestReceiptReport({ ...this.state.modalData }).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The transaction was successfully saved',
      });

      this.modalGenerateRef.close();
      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchInterestReceiptReport({ ...this.props.criteria });
    });
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        modalData: {
          ...this.state.modalData,
          [name]: value,
        },
      };

      if (name === 'ElectronicDocument') {
        stateToUpdate.modalData.ElectronicDocument = !state.modalData.ElectronicDocument;
      }

      return stateToUpdate;
    });
  };

  onClickOpenModalPrint = async () => {
    const selectedData = this.props.searchResult.filter((f) => checkOtherPritsSelected('', f));
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for print!',
      });
      return;
    }

    for (var i = 0; i < selectedData.length; i++) {
      const { transactionNo } = selectedData[i];
      if (!transactionNo) continue;

      let printMode = '';
      printsSelectedName.forEach((key) => {
        if (selectedData[i][key]) printMode = key;
      });

      if (printMode)
        await this.props.printInterestReceive({
          transactionNo,
          printMode: printMode.replace('IsSelected', ''),
        });
    }
    this.props.searchInterestReceiptReport({ ...this.props.criteria });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onClickOpenModalGenerate: this.onClickOpenModalGenerate,
      onChangeModalData: this.onChangeModalData,
      onSubmitModalGenerate: this.onSubmitModalGenerate,

      onClickOpenModalPrint: this.onClickOpenModalPrint,
    };

    return (
      <div>
        <InterestReceipt
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalGenerateRef={(e) => (this.modalGenerateRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.reportInterestReceipt,
  }),
  {
    searchInterestReceiptReport,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    saveInterestReceiptReport,
    printInterestReceive,
  }
)(InterestReceiptContainer);
