import React from 'react';

import Tab from '../../common/Tab';
import Link from '../../common/Link';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Select2 from '../../common/Select2';
import InputMask from '../../common/InputMask';
import FormGroup3 from '../../common/FormGroup3';
import MaintainEformTab from './MaintainEformTab';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { MASTER_COMPANY, MASTER_HOLIDAY, MASTER_CURRENCY } from '../../reducers/master/action';

const colX2 = ['col-md-4', 'col-md-5'];

export default ({
  resultGroupings,
  resultPercentRules,
  resultRoundingRules,
  resultForwardDuration,

  dataTableGrouping,
  dataTablePercentage,
  dataTableRounding,
  dataTableDuration,

  modalDataRef,
  modalData,
  onClickOpenModal,
  onChangeModal,
  onChangeModalSelect2,
  onSubmitModal,
  onClickOpenModalEdit,

  modalDeleteRef,
  onClickOpenModalDelete,
  onClickDeleteModal,

  masterDepartment,
  masterGroupId,
}) => {
  const tabContents = [];
  // // ------------------ Tab Grouping ----------------------
  tabContents.push({
    header: <span>Grouping</span>,
    content: (
      <MaintainEformTab
        result={resultGroupings}
        tabName="grouping"
        dataTableRef={dataTableGrouping}
        onClickOpenModal={onClickOpenModal}
        onClickOpenModalEdit={onClickOpenModalEdit}
        onClickOpenModalDelete={onClickOpenModalDelete}
      />
    ),
    tabName: 'grouping',
  });

  // ------------------ Tab Percent ----------------------
  tabContents.push({
    header: <span>Percentage</span>,
    content: (
      <MaintainEformTab
        result={resultPercentRules}
        tabName="percent"
        dataTableRef={dataTablePercentage}
        onClickOpenModal={onClickOpenModal}
        onClickOpenModalEdit={onClickOpenModalEdit}
        onClickOpenModalDelete={onClickOpenModalDelete}
      />
    ),
    tabName: 'percent',
  });

  // ------------------ Tab Rounding ----------------------
  tabContents.push({
    header: <span>Rounding</span>,
    content: (
      <MaintainEformTab
        result={resultRoundingRules}
        tabName="rounding"
        dataTableRef={dataTableRounding}
        onClickOpenModal={onClickOpenModal}
        onClickOpenModalEdit={onClickOpenModalEdit}
        onClickOpenModalDelete={onClickOpenModalDelete}
      />
    ),
    tabName: 'rounding',
  });

  // ------------------ Tab Duration ----------------------
  tabContents.push({
    header: <span>Duration Forward</span>,
    content: (
      <MaintainEformTab
        result={resultForwardDuration}
        tabName="duration"
        dataTableRef={dataTableDuration}
        onClickOpenModal={onClickOpenModal}
        onClickOpenModalEdit={onClickOpenModalEdit}
        onClickOpenModalDelete={onClickOpenModalDelete}
      />
    ),
    tabName: 'duration',
  });

  const optionRequestType = ['With underlying', 'Without underlying', 'Loan'];
  const optionForwardType = ['Forward', 'Dollar Link', 'Loan', 'Other'];
  const modalType = modalData.Type;
  const modalHeadetText =
    modalType === 'grouping'
      ? 'Grouping'
      : modalType === 'percent'
        ? 'Percentage'
        : modalType === 'rounding'
          ? 'Rounding'
          : 'Forward Duration';

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" />
            <Link txt="E-Form Grouping" href="/fx-management/e-form-grouping" />
            <span className="icon icon-angle-double-right" /> Maintain Grouping
          </small>
        </p>
      </div>

      <Tab tabs={tabContents} />

      {modalData.Mode !== 'delete' ? (
        <Modal textHeader={modalHeadetText} ref={modalDataRef} bgHeader="bg-primary">
          <form onSubmit={onSubmitModal} className="form-horizontal">
            {modalType === 'grouping' ? (
              <FormGroup3 text="Group ID" col={colX2} required>
                {/* <Select2
                                        notMultiple={true}
                                        onChange={e => onChangeModal({
                                            target: e
                                        })}
                                        required={false}
                                        value={modalData.GroupId}
                                        name="GroupId"
                                    >
                                        <option value="NEW">New Group</option>
                                        {
                                            masterGroupId.map(m => (
                                                <option value={m.groupId} key={m.groupId}>{m.groupId}</option>
                                            ))
                                        }
                                    </Select2> */}
                <input
                  className="form-control"
                  onChange={onChangeModal}
                  name="GroupId"
                  value={modalData.GroupId === 'NEW' ? 'New Group' : modalData.GroupId}
                  readOnly
                />
              </FormGroup3>
            ) : null}

            {modalType === 'duration' ? (
              <FormGroup3 text="Company" col={['col-md-4', 'col-md-7']} required>
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  isAll
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  value={modalData.CompanyCode}
                  name="CompanyCode"
                />
              </FormGroup3>
            ) : null}

            {modalType === 'rounding' || modalType === 'duration' ? (
              <FormGroup3 text="Currency" col={colX2} required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  isAll
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  value={modalData.Currency}
                  name="Currency"
                />
              </FormGroup3>
            ) : null}

            <FormGroup3 text="Request Type" col={colX2} required>
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                onChange={(e) =>
                  onChangeModal({
                    target: e,
                  })
                }
                value={modalData.RequestType}
                required
                name="RequestType"
                options={optionRequestType}
              />
            </FormGroup3>

            <FormGroup3 text="Forward Type" col={colX2} required>
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                onChange={(e) =>
                  onChangeModal({
                    target: e,
                  })
                }
                value={modalData.ForwardType}
                required
                name="ForwardType"
                options={optionForwardType}
              />
            </FormGroup3>

            {modalType === 'grouping' ? (
              <FormGroup3 text="Department" col={['col-md-4', 'col-md-7']} required>
                <Select2
                  notMultiple={false}
                  onChange={onChangeModalSelect2}
                  required
                  value={modalData.DepartmentCode}
                  name="DepartmentCode"
                >
                  <option key="All" value="All">
                    ---All---
                  </option>
                  {masterDepartment.map((m) => (
                    <option value={m.departmentCode} key={m.departmentCode}>
                      {m.departmentName}
                    </option>
                  ))}
                </Select2>
              </FormGroup3>
            ) : null}

            {modalType === 'percent' ? (
              <>
                <FormGroup3 text="Amount From" col={colX2} required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 2,
                      // min: 0,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }}
                    onChange={onChangeModal}
                    value={modalData.Amount}
                    required
                    name="Amount"
                  />
                </FormGroup3>

                <FormGroup3 text="Percentage (%)" col={colX2} required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 2,
                      // max: 100,
                      // min: 0,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }}
                    onChange={onChangeModal}
                    value={modalData.Percentage}
                    required
                    name="Percentage"
                  />
                </FormGroup3>
              </>
            ) : null}

            {modalType === 'rounding' ? (
              <FormGroup3 text="Rounding" col={colX2} required>
                <Select2
                  isChoose
                  notMultiple
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  required
                  value={modalData.Rounding}
                  name="Rounding"
                >
                  <option value={10} key={10}>
                    10
                  </option>
                  <option value={100} key={100}>
                    100
                  </option>
                  <option value={1000} key={1000}>
                    1,000
                  </option>
                  <option value={10000} key={10000}>
                    10,000
                  </option>
                  <option value={100000} key={100000}>
                    100,000
                  </option>
                  <option value={1000000} key={1000000}>
                    1,000,000
                  </option>
                  <option value={10000000} key={10000000}>
                    10,000,000
                  </option>
                  <option value={100000000} key={100000000}>
                    100,000,000
                  </option>
                  <option value={1000000000} key={1000000000}>
                    1,000,000,000
                  </option>
                  <option value={10000000000} key={10000000000}>
                    10,000,000,000
                  </option>
                </Select2>
              </FormGroup3>
            ) : null}

            {modalType === 'duration' ? (
              <>
                <FormGroup3 text="Forward Duration" col={colX2} required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 2,
                    }}
                    onChange={onChangeModal}
                    value={modalData.Duration}
                    required
                    name="Duration"
                  />
                </FormGroup3>

                <FormGroup3 text="Start Term" col={colX2} required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={{
                      prefix: '',
                      digits: 0,
                    }}
                    onChange={onChangeModal}
                    value={modalData.StartTerm}
                    required
                    name="StartTerm"
                  />
                </FormGroup3>

                <FormGroup3 text="Start Shift" col={colX2} required>
                  <MasterDropdownUI
                    isChoose
                    notMultipleSelect2
                    onChange={(e) =>
                      onChangeModal({
                        target: e,
                      })
                    }
                    value={modalData.StartShift}
                    required
                    name="StartShift"
                    options={[
                      'No Shift',
                      'Next Working Day',
                      'Next Working Day Modified',
                      'Previous Working Day',
                      'Previous Working Day Modified',
                    ]}
                  />
                </FormGroup3>

                <FormGroup3 text="End Shift" col={colX2} required>
                  <MasterDropdownUI
                    isChoose
                    notMultipleSelect2
                    onChange={(e) =>
                      onChangeModal({
                        target: e,
                      })
                    }
                    value={modalData.EndShift}
                    required
                    name="EndShift"
                    options={[
                      'No Shift',
                      'Next Working Day',
                      'Next Working Day Modified',
                      'Previous Working Day',
                      'Previous Working Day Modified',
                    ]}
                  />
                </FormGroup3>

                <FormGroup3 text="Fac. Cal." col={colX2} required>
                  <MasterDropdown
                    masterType={MASTER_HOLIDAY}
                    isChoose
                    notMultipleSelect2
                    onChange={(e) =>
                      onChangeModal({
                        target: e,
                      })
                    }
                    value={modalData.CalendarType}
                    required
                    name="CalendarType"
                    options={[]}
                  />
                </FormGroup3>
              </>
            ) : null}

            <br />
            <div>
              <center>
                <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
              </center>
            </div>
          </form>
        </Modal>
      ) : null}

      <ModalFunction
        textHeader="Delete"
        textContent="Delete"
        modalRef={modalDeleteRef}
        type="danger"
        icon="icon-trash-o"
        textBtn="Delete"
        noSelect
        onClick={onClickDeleteModal}
      />
    </div>
  );
};
