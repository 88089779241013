import React from 'react';

import Col2 from '../../common/Col2';
import FormGroup2 from '../../common/FormGroup2';
import Select2, { optionForAjaxUserFromAd } from '../../common/Select2';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ formRef, state, onChangeInputData, noEdit, managerRef, modeAmend }) => (
  <form ref={formRef} name="manager">
    <Col2 col={colX[2]}>
      <Col2 col={colX[1]}>
        <FormGroup2
          text="Manager Name (ชื่อของหัวหน้าคนที่ต้องการออกหนังสือค้ำประกัน)"
          required={false}
        >
          <Select2
            ref={managerRef}
            notMultiple
            required={false}
            noValidateOption
            disabled={modeAmend ? false : noEdit}
            value={state.ManagerName}
            onChange={(e) =>
              onChangeInputData({
                target: e,
              })
            }
            option={optionForAjaxUserFromAd}
            name="ManagerName"
          />
        </FormGroup2>

        <FormGroup2
          text="Email (อีเมลของหัวหน้าคนที่ต้องการออกหนังสือค้ำประกัน)"
          required={false}
          maxLength={200}
          length={(state.ManagerEmail || '').length}
        >
          <input
            className="form-control"
            onChange={onChangeInputData}
            value={state.ManagerEmail || ''}
            name="ManagerEmail"
            disabled={modeAmend ? false : noEdit}
            required={false}
            maxLength={200}
          />
        </FormGroup2>
      </Col2>
    </Col2>

    <Col2 col={colX[2]}>
      <Col2 col={colX[1]}>
        <FormGroup2
          text="Tel No. (เบอร์ติดต่อของหัวหน้าคนที่ต้องการออกหนังสือค้ำประกัน)"
          required={false}
        >
          <input
            className="form-control"
            onChange={onChangeInputData}
            value={state.ManagerTel || ''}
            name="ManagerTel"
            disabled={modeAmend ? false : noEdit}
            maxLength={30}
          />
        </FormGroup2>

        <FormGroup2
          text="Department (หน่วยงานของหัวหน้าคนที่ต้องการออกหนังสือค้ำประกัน)"
          required={false}
        >
          <textarea
            className="form-control"
            rows="2"
            onChange={onChangeInputData}
            value={state.ManagerDepartment || ''}
            name="ManagerDepartment"
            disabled={modeAmend ? false : noEdit}
            required={false}
            maxLength={200}
          />
        </FormGroup2>
      </Col2>
    </Col2>
  </form>
);
