import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MAINTAIN_INTEREST_CRITERIA = 'EDIT_MAINTAIN_INTEREST_CRITERIA';

export const REQUEST_SEARCH_MAINTAIN_INTEREST = '@@request/SARCH_MAINTAIN_INTEREST';
export const RECEIVE_SEARCH_MAINTAIN_INTEREST = '@@receive/SEARCH_MAINTAIN_INTEREST';
export const FAIL_SEARCH_MAINTAIN_INTEREST = '@@fail/SEARCH_MAINTAIN_INTEREST';

export const REQUEST_SAVE_MAINTAIN_INTEREST_RATE = '@@request/SAVE_MAINTAIN_INTEREST_RATE';
export const RECEIVE_SAVE_MAINTAIN_INTEREST_RATE = '@@receive/SAVE_MAINTAIN_INTEREST_RATE';
export const FAIL_SAVE_MAINTAIN_INTEREST_RATE = '@@fail/SAVE_MAINTAIN_INTEREST_RATE';

export const REQUEST_GET_MAINTAIN_INTEREST_RATE = '@@request/GET_MAINTAIN_INTEREST_RATE';
export const RECEIVE_GET_MAINTAIN_INTEREST_RATE = '@@receive/GET_MAINTAIN_INTEREST_RATE';
export const FAIL_GET_MAINTAIN_INTEREST_RATE = '@@fail/GET_MAINTAIN_INTEREST_RATE';

export const getInterestRate = (interestRateId) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getinterestrate/${interestRateId}`),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_INTEREST_RATE,
      RECEIVE_GET_MAINTAIN_INTEREST_RATE,
      FAIL_GET_MAINTAIN_INTEREST_RATE,
    ],
  },
});

export const saveMaintainInterestRate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savemasterinterestrate'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MAINTAIN_INTEREST_RATE,
      RECEIVE_SAVE_MAINTAIN_INTEREST_RATE,
      FAIL_SAVE_MAINTAIN_INTEREST_RATE,
    ],
  },
});

export const searchMaintainInterestRate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MAINTAIN_INTEREST,
      RECEIVE_SEARCH_MAINTAIN_INTEREST,
      FAIL_SEARCH_MAINTAIN_INTEREST,
    ],
  },
});

export const editCriteria = (name, value) => ({
  type: EDIT_MAINTAIN_INTEREST_CRITERIA,
  payload: {
    name,
    value,
  },
});
