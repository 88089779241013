import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_BANK_ACCOUNT_OF_BANK_CRITERIA =
  'EDIT_MASTER_BANK_ACCOUNT_OF_BANK_CRITERIA';
export const CLEAR_MASTER_BANK_ACCOUNT_OF_BANK_CRITERIA =
  'CLEAR_MASTER_BANK_ACCOUNT_OF_BANK_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_BANK_ACCOUNT_OF_BANK =
  'UPDATE_SEARCH_RESULT_MASTER_BANK_ACCOUNT_OF_BANK';

export const REQUEST_SEARCH_MASTER_BANK_ACCOUNT_OF_BANK =
  '@@request/SARCH_MASTER_BANK_ACCOUNT_OF_BANK';
export const RECEIVE_SEARCH_MASTER_BANK_ACCOUNT_OF_BANK =
  '@@receive/SEARCH_MASTER_BANK_ACCOUNT_OF_BANK';
export const FAIL_SEARCH_MASTER_BANK_ACCOUNT_OF_BANK = '@@fail/SEARCH_MASTER_BANK_ACCOUNT_OF_BANK';

export const REQUEST_GET_BANK_ACCOUNT_OF_BANK_DETAIL =
  '@@request/SARCH_BANK_ACCOUNT_OF_BANK_DETAIL';
export const RECEIVE_GET_BANK_ACCOUNT_OF_BANK_DETAIL = '@@receive/GET_BANK_ACCOUNT_OF_BANK_DETAIL';
export const FAIL_GET_BANK_ACCOUNT_OF_BANK_DETAIL = '@@fail/GET_BANK_ACCOUNT_OF_BANK_DETAIL';

export const REQUEST_SAVE_BANK_ACCOUNT_OF_BANK_DETAIL =
  '@@request/SAVE_BANK_ACCOUNT_OF_BANK_DETAIL';
export const RECEIVE_SAVE_BANK_ACCOUNT_OF_BANK_DETAIL =
  '@@receive/SAVE_BANK_ACCOUNT_OF_BANK_DETAIL';
export const FAIL_SAVE_BANK_ACCOUNT_OF_BANK_DETAIL = '@@fail/SAVE_BANK_ACCOUNT_OF_BANK_DETAIL';

export const REQUEST_DELETE_MASTER_BANK_ACCOUNT_OF_BANK =
  '@@request/DELETE_MASTER_BANK_ACCOUNT_OF_BANK';
export const RECEIVE_DELETE_MASTER_BANK_ACCOUNT_OF_BANK =
  '@@receive-The record was successfully change status/DELETE_MASTER_BANK_ACCOUNT_OF_BANK';
export const FAIL_DELETE_MASTER_BANK_ACCOUNT_OF_BANK = '@@fail/DELETE_MASTER_BANK_ACCOUNT_OF_BANK';

export const REQUEST_GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE =
  '@@request/GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE';
export const RECEIVE_GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE =
  '@@receive/GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE';
export const FAIL_GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE =
  '@@fail/GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE';

export const REQUEST_APPROVE_MASTER_BANK_ACCOUNT_OF_BANK =
  '@@request/APPROVE_MASTER_BANK_ACCOUNT_OF_BANK';
export const RECEIVE_APPROVE_MASTER_BANK_ACCOUNT_OF_BANK =
  '@@receive/APPROVE_MASTER_BANK_ACCOUNT_OF_BANK';
export const FAIL_APPROVE_MASTER_BANK_ACCOUNT_OF_BANK =
  '@@fail/APPROVE_MASTER_BANK_ACCOUNT_OF_BANK';

export const approveMasterBankAccount = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/approvemasterbankaccount`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_APPROVE_MASTER_BANK_ACCOUNT_OF_BANK,
      RECEIVE_APPROVE_MASTER_BANK_ACCOUNT_OF_BANK,
      FAIL_APPROVE_MASTER_BANK_ACCOUNT_OF_BANK,
    ],
  },
});

export const getMasterBankAccountffectiveDate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbankaccount', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE,
      RECEIVE_GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE,
      FAIL_GET_BANK_ACCOUNT_OF_BANK_EFFECTIVE_DATE,
    ],
  },
});

export const deleteMasterBankAccount = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemasterbankaccount`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DELETE_MASTER_BANK_ACCOUNT_OF_BANK,
      RECEIVE_DELETE_MASTER_BANK_ACCOUNT_OF_BANK,
      FAIL_DELETE_MASTER_BANK_ACCOUNT_OF_BANK,
    ],
  },
});

export const saveBankAccountDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterbankaccount`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_BANK_ACCOUNT_OF_BANK_DETAIL,
      RECEIVE_SAVE_BANK_ACCOUNT_OF_BANK_DETAIL,
      FAIL_SAVE_BANK_ACCOUNT_OF_BANK_DETAIL,
    ],
  },
});

export const getBankAccountDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasterbankaccountdetail/`, data),
    method: 'GET',
    types: [
      REQUEST_GET_BANK_ACCOUNT_OF_BANK_DETAIL,
      RECEIVE_GET_BANK_ACCOUNT_OF_BANK_DETAIL,
      FAIL_GET_BANK_ACCOUNT_OF_BANK_DETAIL,
    ],
  },
});

export const searchMasterBankAccount = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbankaccount', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_BANK_ACCOUNT_OF_BANK,
      RECEIVE_SEARCH_MASTER_BANK_ACCOUNT_OF_BANK,
      FAIL_SEARCH_MASTER_BANK_ACCOUNT_OF_BANK,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_BANK_ACCOUNT_OF_BANK,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_BANK_ACCOUNT_OF_BANK_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_BANK_ACCOUNT_OF_BANK_CRITERIA,
  payload: {
    name,
    value,
  },
});
