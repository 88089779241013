import { connect } from 'react-redux';
import React, { Component } from 'react';

import MaintainBank from './MaintainBank';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import {
  editCriteria,
  clearCriteria,
  getMaintainForm,
  saveMaintainBank,
  searchMaintainBank,
  updateSearchResult,
} from '../../reducers/maintainBank/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-maintain-bank';

const initData = {
  BeneficiaryId: '',
  BeneficiaryName: '',
  BeneficiaryNameTh: '',
  TaxId: '',
  FormNoList: [''],
  FormNo: '',
  Action: 'create',
  IsConsole: '',
};

class MaintainBankContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));

    this.props.getMaintainForm({
      // FormFormat: 'Blockchain',
      // IsFormBeneficiary: true,
      IsActive: true,
    });
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMaintainBank({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalData = (e) => {
    this.setState(
      {
        modalData: {
          ...initData,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          FormNoList: rowData.formNo ? (rowData.formNo || '').split(',') : [],
          Action: 'edit',
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;
    if (name === 'IsConsole') {
      this.setState({
        modalData: {
          ...this.state.modalData,
          IsConsole: !this.state.modalData.IsConsole,
        },
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [name]: value,
        },
      });
    }
  };

  onChangeModalSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSaveModalData = (e) => {
    e.preventDefault();

    const data = { ...this.state.modalData };
    data.FormNo = data.FormNoList.toString();

    this.props.saveMaintainBank({ ...data }).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      this.props.searchMaintainBank({ ...this.props.criteria });
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        ...toUpperKey(rowData),
        Action: 'status',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    this.props.saveMaintainBank({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMaintainBank({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onChangeModalData: this.onChangeModalData,
      onChangeModalSelect2: this.onChangeModalSelect2Handler,
      onClickSaveModalData: this.onClickSaveModalData,
      onClickOpenModalData: this.onClickOpenModalData,

      formNoList: this.props.formNoList,
    };

    return (
      <div>
        <MaintainBank
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalDataRef={(e) => (this.modalDataRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.maintainBank,
  }),
  {
    searchMaintainBank,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    saveMaintainBank,
    addAlert,
    getMaintainForm,
  }
)(MaintainBankContainer);
