import { connect } from 'react-redux';
import React, { Component } from 'react';

import Interest from './Interest';
import { toUpperKey } from '../../../common/helpper';
import {
  editCriteria,
  clearCriteria,
  submitInterestRate,
  searchMasterInterest,
} from '../../../reducers/masterInterest/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-interest';

const initModal = {
  FinanceGroupId: '',
  CompanyCode: '',
  BankCode: '',
  BankAccountNo: '',
  Currency: '',
  InterestRate: '',
  EffectiveDateStr: '',
  Mode: 'create',
};

class InterestContainer extends Component {
  state = {
    modalData: {
      ...initModal,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
  }

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMasterInterest({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickOpenModalCreateHandler = (e, cell) => {
    this.setState(
      {
        modalData: {
          ...initModal,
        },
      },
      this.modal.open
    );
  };

  onChangeModalHeadler = (e) => {
    this.setState({
      modalData: {
        ...this.state.modalData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2ModalHandler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalData: {
          ...initModal,
          ...toUpperKey(rowData),
          Mode: 'edit',
        },
      },
      this.modal.open
    );
  };

  onClickSaveModalHandler = (e) => {
    e.preventDefault();

    const { modalData } = this.state;
    modalData.CompanyCode = modalData.CompanyCode.toString();

    this.props.submitInterestRate(modalData).then((response) => {
      if (response.error) return;

      this.modal.close();
      this.props.searchMasterInterest({ ...this.props.criteria });
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initModal,
        ...toUpperKey(rowData),
        Mode: 'change',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    this.props.submitInterestRate({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMasterInterest({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onClickOpenModalCreate: this.onClickOpenModalCreateHandler,
      onChangeModal: this.onChangeModalHeadler,
      onChangeSelect2Modal: this.onChangeSelect2ModalHandler,
      onClickSaveModal: this.onClickSaveModalHandler,

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <Interest
        {...props}
        {...this.state}
        criteria={this.props.criteria}
        results={this.props.searchResult}
        modalRef={(e) => (this.modal = e)}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInactiveRef={(e) => (this.modalInactiveRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterInterest,
  }),
  {
    searchMasterInterest,
    editCriteria,
    clearCriteria,
    submitInterestRate,
  }
)(InterestContainer);
