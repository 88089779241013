import { connect } from 'react-redux';
import React, { Component } from 'react';

import LoanIntercomp from './LoanIntercomp';
import { toLower } from '../../../common/helpper';
import {
  exportReport,
  searchLoanIntercomReport,
} from '../../../reducers/reportLoanIntercom/action';

const initCriteria = {
  Type: '',
  BusinessUnits: '',
  Companies: [''],
  Term: [''],
  Currency: '',
  ProductType: [''],
  ValueDateStr: '',
  BuLabel: '',
};

const initComp = {
  dataBorrowerSweep: [],
  dataBorrowerNoSweep: [],
  dataLenderSweep: [],
  dataLenderNoSweep: [],
  totalBorrower: {
    amountOnCall: '',
    amountTerm: '',
  },
  totalLender: {
    amountOnCall: '',
    amountTerm: '',
  },
  totalAll: {
    amountLender: '',
    amountBorrower: '',
    amountNet: '',
  },
};

class LoanInterReportcompContainer extends Component {
  state = {
    datas: [],
    borrowerList: [],
    totalAll: [],
    scc: {
      lender: 0,
      borrower: 0,
      total: 0,
    },

    showProductType: '',
    showBuLabel: '',
    criteria: {
      ...initCriteria,
    },
  };

  // ---------------- Search ----------------
  componentDidMount() {
    this.setState({
      criteria: {
        ...this.state.criteria,
        Type: 'bu',
        Currency: 'THB',
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchResult !== this.props.searchResult) {
      if (this.state.criteria.Type === 'comp') {
        this.mapData(this.props.searchResult);
      } else {
        this.mapDataBu(this.props.searchResult);
      }
    }
  }

  onChangeSearchHeadler = (e) => {
    const { name, value, label } = e.target;

    if (name === 'Type') {
      if (value === 'bu') {
        this.setState((state) => {
          const updateState = {
            criteria: {
              ...state.criteria,
              Type: 'bu',
              Companies: [''],
            },
          };
          return updateState;
        });
      } else {
        this.setState((state) => {
          const updateState = {
            criteria: {
              ...state.criteria,
              Type: 'comp',
              BusinessUnits: [''],
              Term: [''],
            },
          };
          return updateState;
        });
      }
    } else if (name === 'BusinessUnits') {
      this.setState((state) => {
        const updateState = {
          criteria: {
            ...state.criteria,
            [name]: value,
            BuLabel: label,
          },
        };
        return updateState;
      });
    } else {
      this.setState((state) => {
        const updateState = {
          criteria: {
            ...state.criteria,
            [name]: value,
          },
        };
        return updateState;
      });
    }
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchLoanIntercomReport({ ...this.state.criteria }).then((response) => {
      if (!response.error) {
        this.setState({
          ...this.state,
          showProductType: this.state.criteria.Type,
        });
      }
    });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
    });
  };

  mapDataBu(datas = []) {
    const borrowerList = [
      ...new Set(datas.map((f) => f.borrowerCode && f.borrowerAbbreviate)),
    ].sort();
    const bLength = borrowerList.length + 1;

    const sections = [];
    datas.forEach((m) => {
      const { lenderCode, lenderAbbreviate, loanType, interestRate, isSweep, source } = m;
      const findGroup = sections.find(
        (f) =>
          f.lenderCode === lenderCode &&
          f.loanType === loanType &&
          f.interestRate === interestRate &&
          f.isSweep === isSweep &&
          f.source === source
      );

      if (!findGroup) {
        sections.push({
          lenderCode,
          lenderAbbreviate,
          source,
          isSweep,
          loanType,
          interestRate,
          data: [m],
          dataBorrower: new Array(bLength).fill(0),

          ...initComp,
        });
      } else {
        findGroup.data.push(m);
      }
    });

    sections.sort((a, b) => a.lenderAbbreviate.localeCompare(b.lenderAbbreviate));

    let checkLender = '';
    const totalAll = new Array(bLength).fill(0);
    let sccLender = 0;
    let sccBorrower = 0;

    sections.forEach((s) => {
      if (checkLender === s.lenderAbbreviate) {
        s.lenderAbbreviate = '';
      } else {
        checkLender = s.lenderAbbreviate;
      }

      let total = 0;
      s.data.forEach((m) => {
        const indexBorrower = borrowerList.indexOf(m.borrowerAbbreviate);
        s.dataBorrower[indexBorrower] = (s.dataBorrower[indexBorrower] || 0) + m.amount;
        total += m.amount;

        totalAll[indexBorrower] = (totalAll[indexBorrower] || 0) + m.amount;

        if (s.lenderCode === '0110') {
          sccLender += m.amount;
        }
        if (m.borrowerCode === '0110') {
          sccBorrower += m.amount;
        }
      });
      s.dataBorrower[bLength - 1] = total;
      totalAll[bLength - 1] = totalAll[bLength - 1] + total;
    });

    sections.forEach((s) => {
      let i = 0;
      s.dataBorrower.forEach((m) => {
        s.dataBorrower[i] = m / 1000000;
        i++;
      });
    });

    let j = 0;
    totalAll.forEach((m) => {
      totalAll[j] = m / 1000000;
      j++;
    });

    // console.log(sections)

    this.setState({
      datas: sections,
      borrowerList,
      totalAll,
      scc: {
        lender: sccLender / 1000000,
        borrower: (sccBorrower / 1000000) * -1,
        total: (sccLender - sccBorrower) / 1000000,
      },
      showBuLabel: this.state.criteria.BuLabel,
    });
  }

  mapData(datas = []) {
    const sections = [];
    datas.forEach((m) => {
      const {
        lenderCode,
        borrowerCode,
        source,
        interestRate,
        isSweep,
        lenderAbbreviate,
        lenderName,
        borrowerAbbreviate,
        borrowerName,
        digit,
      } = m;
      const findGroup = sections.find(
        (f) =>
          f.lenderCode === lenderCode &&
          f.borrowerCode === borrowerCode &&
          f.source === source &&
          f.interestRate === interestRate &&
          f.isSweep === isSweep
      );
      if (!findGroup) {
        sections.push({
          lenderCode,
          lenderAbbreviate,
          lenderName,
          borrowerCode,
          borrowerAbbreviate,
          borrowerName,
          source,
          interestRate,
          isSweep,
          digit,
          data: [m],
        });
      } else {
        findGroup.data.push(m);
      }
    });

    sections.forEach((s) => {
      let amountTerm = 0;
      let amountOnCall = 0;
      let checkTerm = false;
      let checkOnCall = false;
      s.data.forEach((m) => {
        if (toLower(m.loanType) === 'term') {
          amountTerm += m.amount;
          checkTerm = true;
        } else {
          amountOnCall += m.amount;
          checkOnCall = true;
        }
      });

      s.amountTerm = amountTerm / 1000000 || 0;
      s.interestRateTerm = checkTerm ? s.interestRate : 0;
      s.amountOnCall = amountOnCall / 1000000 || 0;
      s.interestRateOnCall = checkOnCall ? s.interestRate : 0;
    });

    const result = [];
    const complist = this.state.criteria.Companies;

    complist.forEach((m) => {
      const companyLabel = sections.filter((f) => f.borrowerCode === m || f.lenderCode === m);
      let company = companyLabel[0];
      if (companyLabel.length) {
        const c = companyLabel[0];
        company =
          c.lenderCode === m
            ? `${c.lenderCode} | ${c.lenderAbbreviate} | ${c.lenderName}`
            : `${c.borrowerCode} | ${c.borrowerAbbreviate} | ${c.borrowerName}`;

        // add data
        const dataBorrowerSweep = [];
        const dataBorrowerNoSweep = [];
        const dataLenderSweep = [];
        const dataLenderNoSweep = [];
        let sumBorrowerOnCall = 0;
        let sumBorrowerTerm = 0;
        let sumLenderOnCall = 0;
        let sumLenderTerm = 0;

        sections.forEach((f) => {
          if (f.borrowerCode === m && f.isSweep) {
            dataBorrowerSweep.push(f);
            sumBorrowerOnCall += f.amountOnCall;
            sumBorrowerTerm += f.amountTerm;
          } else if (f.borrowerCode === m && !f.isSweep) {
            dataBorrowerNoSweep.push(f);
            sumBorrowerOnCall += f.amountOnCall;
            sumBorrowerTerm += f.amountTerm;
          } else if (f.lenderCode === m && f.isSweep) {
            dataLenderSweep.push(f);
            sumLenderOnCall += f.amountOnCall;
            sumLenderTerm += f.amountTerm;
          } else if (f.lenderCode === m && !f.isSweep) {
            dataLenderNoSweep.push(f);
            sumLenderOnCall += f.amountOnCall;
            sumLenderTerm += f.amountTerm;
          }
        });

        const amountLender = sumLenderOnCall + sumLenderTerm;
        const amountBorrower = (sumBorrowerOnCall + sumBorrowerTerm) * -1;
        const amountNet = amountLender + amountBorrower;

        result.push({
          company: m,
          companyCard: company,
          dataBorrowerSweep,
          dataBorrowerNoSweep,
          dataLenderSweep,
          dataLenderNoSweep,

          totalBorrower: {
            amountOnCall: sumBorrowerOnCall,
            amountTerm: sumBorrowerTerm,
          },
          totalLender: {
            amountOnCall: sumLenderOnCall,
            amountTerm: sumLenderTerm,
          },
          totalAll: {
            amountLender,
            amountBorrower,
            amountNet,
          },

          dataBorrower: [],
        });
      }
    });
    // console.log(result)

    this.setState({
      datas: result,
    });
  }

  onClickExportReport = () => {
    const criteria = { ...this.state.criteria };
    let error = '';
    if (!criteria.Type) {
      error = 'Please select Type';
    } else if (criteria.Type == 'bu' && !criteria.BusinessUnits) {
      error = 'Please select Business unit';
    } else if (criteria.Type == 'comp' && !criteria.Companies) {
      error = 'Please select company';
    } else if (!criteria.Currency) {
      error = 'Please select currency';
    } else if (!criteria.ValueDateStr) {
      error = 'Please select Value date';
    }

    if (error) {
      alert(error);
      return;
    }

    this.props.exportReport(criteria);
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      onClickExportReport: this.onClickExportReport,
    };

    return (
      <div>
        <LoanIntercomp {...props} {...this.state} criteria={this.state.criteria} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.reportLoanIntercom,
  }),
  {
    searchLoanIntercomReport,
    exportReport,
  }
)(LoanInterReportcompContainer);
