import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import { DATATABLE_ID } from './TransferFeeSetUpContainer';
import { toNumber, toStatus, toBankCode, getPermission } from '../../common/helpper';
import MasterDropdownUI, { MASTER_TRANSFER_METHOD } from '../../common/MasterDropdownUI';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const permission = getPermission('Cash Management', 'Transfer Fee Set Up');

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[3, 'asc']],
  columns: [
    {
      orderable: false,
      className: 'text-center',
      data: null,
      render: (d, t, r) => {
        if (!permission) return '';
        if (!r.isActive) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    {
      orderable: false,
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'effectiveDateStr',
      className: 'text-center td-width-date',
      type: 'date-black',
      render: (d) => d,
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCodeResult', className: 'text-center' },
    { data: 'companyNameResult' },
    { data: 'bankCode', render: (d, t, r) => toBankCode(r.bankCode, r.bankAbbreviate, r.bankName) },
    { data: 'transferMethod', className: 'text-center' },
    {
      data: 'applicantSameRegionTransferFee',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'beneficiarySameRegionTransferFee',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'applicantDifferentRegionTransferFee',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    {
      data: 'beneficiaryDifferentRegionTransferFee',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'currency', className: 'text-center' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => toStatus(d ? 'Active' : 'Inactive'),
    },
    { data: 'createdDateStr', className: 'text-center td-width-date', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center td-width-date', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  selectedRowIdx,
  results,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,

  modalRef,
  modalData,
  onClickOpenModalCreate,
  onClickOpenModalEdit,
  onChangeModal,
  onChangeSelect2Modal,
  onClickSaveModal,

  modalActiveRef,
  modalInActiveRef,
  onClickOpenModalActive,
  onClickOpenModalInActive,
  onClickConfirmChangeStatus,
}) => {
  const isEdit = selectedRowIdx !== null;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Cash Management <span className="icon icon-angle-double-right" /> Transfer Fee Set Up
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={criteria.FinanceGroupId}
                  onChange={onChangeSelect2}
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  value={criteria.BusinessUnitId}
                  onChange={onChangeSelect2}
                  name="BusinessUnitId"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  value={criteria.CompanyCode}
                  onChange={onChangeSelect2}
                  name="CompanyCode"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroupId}
                  businessUnitId={criteria.BusinessUnitId}
                />
              </FormGroup2>

              <FormGroup2 text="Effective Date">
                <DateRangePicker
                  value={criteria.EffectiveDateStr}
                  onChange={onChangeSearch}
                  name="EffectiveDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  value={criteria.BankCode}
                  onChange={onChangeSelect2}
                  saveLocalState
                  companyCode={criteria.Company}
                  name="BankCode"
                />
              </FormGroup2>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  name="Status"
                >
                  <option value={null}>---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
            <Col2 col={colX[2]}>
              <FormGroup2 text="&nbsp;">
                <div>
                  <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
                  &nbsp;
                  {permission ? (
                    <>
                      <Button
                        txt="CREATE"
                        icon="plus-circle"
                        className="btn-warning"
                        onClick={onClickOpenModalCreate}
                      />
                      &nbsp;
                    </>
                  ) : null}
                  <Button
                    txt="Clear Search"
                    icon="eraser"
                    className="btn-clear"
                    onClick={onClickClearSearch}
                  />
                </div>
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
          onClickContext={[
            {
              context: 'button.edit',
              onClick: onClickOpenModalEdit,
            },
            {
              context: 'button.active',
              onClick: onClickOpenModalActive,
            },
            {
              context: 'button.inactive',
              onClick: onClickOpenModalInActive,
            },
          ]}
        >
          <thead>
            <tr>
              <th>Edit</th>
              <th>Action</th>
              <th>
                Effective
                <br />
                Date
              </th>
              <th>
                Finance
                <br />
                Group
              </th>
              <th>
                Business
                <br />
                Unit
              </th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>
                Company
                <br />
                Name
              </th>
              <th>Bank</th>
              <th>
                Transfer
                <br />
                Method
              </th>
              <th className="text-center">
                Applicant
                <br />
                Same Region
                <br />
                Transfer Fee
              </th>
              <th className="text-center">
                Beneficiary
                <br />
                Same Region
                <br />
                Transfer Fee
              </th>
              <th className="text-center">
                Applicant
                <br />
                Different Region
                <br />
                Transfer Fee
              </th>
              <th className="text-center">
                Beneficiary
                <br />
                Different Region
                <br />
                Transfer Fee
              </th>
              <th>Currency</th>
              <th>Status</th>
              <th>
                Created
                <br />
                Date
              </th>
              <th>
                Created
                <br />
                By
              </th>
              <th>
                Updated
                <br />
                Date
              </th>
              <th>
                Updated
                <br />
                By
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Transfer Fee" bgHeader="bg-primary" size="modal-lg" ref={modalRef}>
        <form onSubmit={onClickSaveModal}>
          <Col2 col={colX[0]}>
            <FormGroup2 text="Finance Group" required>
              {isEdit ? (
                <input
                  className="form-control"
                  type="text"
                  value={modalData.FinanceGroupName}
                  disabled
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  value={modalData.FinanceGroupId}
                  isChoose
                  notMultipleSelect2
                  required
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  name="FinanceGroupId"
                />
              )}
            </FormGroup2>
            <FormGroup2 text="Company">
              {isEdit ? (
                <input
                  className="form-control"
                  type="text"
                  value={`${modalData.CompanyCodeResult} | ${modalData.CompanyNameResult}`}
                  disabled
                />
              ) : (
                <MasterDropdown
                  // saveLocalState={true}
                  masterType={MASTER_COMPANY}
                  value={modalData.CompanyCode}
                  onChange={onChangeSelect2Modal}
                  name="CompanyCode"
                  financeGroupId={modalData.FinanceGroupId}
                />
              )}
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="Bank" required>
              {isEdit ? (
                <input
                  className="form-control"
                  type="text"
                  value={`${modalData.BankAbbreviate} | ${modalData.BankName}`}
                  disabled
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_BANK}
                  value={modalData.BankCode}
                  isChoose
                  notMultipleSelect2
                  required
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  saveLocalState
                  companyCode={modalData.CompanyCode}
                  name="BankCode"
                />
              )}
            </FormGroup2>
            <FormGroup2 text="Currency" required>
              {isEdit ? (
                <input className="form-control" type="text" value={modalData.Currency} disabled />
              ) : (
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  value={modalData.Currency}
                  isChoose
                  notMultipleSelect2
                  required
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  name="Currency"
                />
              )}
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="Transfer Method" required>
              {isEdit ? (
                <input
                  className="form-control"
                  type="text"
                  value={modalData.TransferMethod}
                  disabled
                />
              ) : (
                <MasterDropdownUI
                  masterType={MASTER_TRANSFER_METHOD}
                  value={modalData.TransferMethod}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeModal({
                      target: e,
                    })
                  }
                  required
                  options={null}
                  name="TransferMethod"
                />
              )}
            </FormGroup2>
            <FormGroup2 text="Effective Date" required>
              <DatePicker
                value={modalData.EffectiveDateStr}
                onChange={onChangeModal}
                name="EffectiveDateStr"
                option={{
                  startDate: 'd',
                  todayHighlight: true,
                }}
                required
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <Card textHeader="Transfer fee (Applicant-Same Region)" bgHeader="bg-info">
              <label className="control-label">
                Transfer fee<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <InputMask
                className="form-control"
                onChange={onChangeModal}
                format="currency"
                option={modalData.inputMaskAmount}
                value={modalData.ApplicantSameRegionTransferFee}
                name="ApplicantSameRegionTransferFee"
              />
            </Card>
            <Card textHeader="Transfer fee (Applicant-Different Region)" bgHeader="bg-info">
              <label className="control-label">
                Transfer fee<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <InputMask
                className="form-control"
                onChange={onChangeModal}
                format="currency"
                option={modalData.inputMaskAmount}
                value={modalData.ApplicantDifferentRegionTransferFee}
                name="ApplicantDifferentRegionTransferFee"
              />
            </Card>
          </Col2>

          <Col2 col={colX[0]}>
            <Card textHeader="Transfer fee (Beneficiary-Same Region)" bgHeader="bg-primary">
              <label className="control-label">
                Transfer fee<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <InputMask
                className="form-control"
                onChange={onChangeModal}
                format="currency"
                option={modalData.inputMaskAmount}
                value={modalData.BeneficiarySameRegionTransferFee}
                name="BeneficiarySameRegionTransferFee"
              />
            </Card>
            <Card textHeader="Transfer fee (Beneficiary-Different Region)" bgHeader="bg-primary">
              <label className="control-label">
                Transfer fee<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <InputMask
                className="form-control"
                onChange={onChangeModal}
                format="currency"
                option={modalData.inputMaskAmount}
                value={modalData.BeneficiaryDifferentRegionTransferFee}
                name="BeneficiaryDifferentRegionTransferFee"
              />
            </Card>
          </Col2>

          <br />
          <div className="row">
            <center>
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>

      <Modal size="modal-sm" ref={modalActiveRef} modalFooter>
        <div className="text-center">
          <span className="text-info icon icon-refresh icon-5x" />
          <h3 className="text-info">Active</h3>
          <p>Do you want to active this item?</p>
          <div className="m-t-lg">
            <Button
              txt="Active"
              className="btn-info"
              onClick={(e) => onClickConfirmChangeStatus('Active')}
            />
            &nbsp;
            <Button txt="Back" className="btn-default" data-dismiss="modal" />
          </div>
        </div>
      </Modal>

      <Modal size="modal-sm" ref={modalInActiveRef} modalFooter>
        <div className="text-center">
          <span className="text-warning icon icon-close icon-5x" />
          <h3 className="text-warning">Inactive</h3>
          <p>Do you want to inactive this item?</p>
          <div className="m-t-lg">
            <Button
              txt="Inactive"
              className="btn-warning"
              onClick={(e) => onClickConfirmChangeStatus('InActive')}
            />
            &nbsp;
            <Button txt="Back" className="btn-default" data-dismiss="modal" />
          </div>
        </div>
      </Modal>
    </div>
  );
};
