import React from 'react';

import Card from '../../../common/Card';
import Link from '../../../common/Link';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import DatePicker from '../../../common/DatePicker';
import FormGroup2 from '../../../common/FormGroup2';
import SpanStatus from '../../../common/SpanStatus';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { toLower, getPermission } from '../../../common/helpper';
import {
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_AREA,
} from '../../../reducers/master/action';

const permission = getPermission('FX Management', 'FX Confirmation');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ state, onChangeInputData, onSubmit }) => {
  const isEdit = state.mode === 'edit';
  const header = `Transaction No.: ${state.TransactionNo}`;
  const reqConfirm = toLower(state.status) === 'wait for quota rate';
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" />
            <Link txt="FX Confirmation" href="/fx-management/fx-confirmation" />
            <span className="icon icon-angle-double-right" /> FX Confirmation Detail
          </small>{' '}
          <span className="icon icon-angle-double-right" /> <SpanStatus status={state.Status} />
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card
          textHeader={header}
          bgHeader="bg-primary"
          cardActions={['toggler']}
          footer={
            permission ? (
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                // disabled
              />
            ) : null
          }
        >
          <Col2 col={colX[0]}>
            <FormGroup2 text="Bank" required>
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                // isChoose={true}
                notMultipleSelect2
                notMultiple={false}
                // required={true}
                companyCode={state.CompanyCode}
                value={state.BankCode || 'All'}
                isPoSo
                isAll
                // disabled={disable}
                name="BankCode"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.FinanceGroupName || ''}
                  name="FinanceGroupName"
                  disabled
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.BusinessUnitName || ''}
                  name="BusinessUnitName"
                  disabled
                />
              </FormGroup2>
            </Col2>

            <FormGroup2 text="Company" required>
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.CompanyCode ? `${state.CompanyCode} | ${state.CompanyName}` : ''}
                name="CompanyCode"
                disabled
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Product Type" required>
                <MasterDropdownUI
                  value={state.ProductType}
                  // disabled={isEdit}
                  onChange={onChangeInputData}
                  notMultiple
                  isChoose
                  required
                  name="ProductType"
                  options={['60A Foreign Exchange-Main', '60B Foreign Exchange-Special']}
                />
              </FormGroup2>
              <FormGroup2 text="Transaction Type" required>
                {/* <MasterDropdownUI
                                    value={state.TransactionType}
                                    onChange={onChangeInputData}
                                    notMultiple={true}
                                    isChoose={true}
                                    required={true}
                                    name="TransactionType"
                                    disabled={isEdit}
                                    options={["Buy", "Sell"]} /> */}
                <input
                  className="form-control"
                  value={state.TransactionType || ''}
                  onChange={onChangeInputData}
                  name="TransactionType"
                  disabled={isEdit}
                />
              </FormGroup2>
            </Col2>

            <FormGroup2 text="Business Partner">
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                noValidateOption
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                notMultiple={false}
                value={state.BusinessPartnerCode}
                name="BusinessPartnerCode"
                isPoSo
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="FWD Contract No.">
                <input
                  className="form-control"
                  value={state.ContractNo || ''}
                  onChange={onChangeInputData}
                  name="ContractNo"
                  // disabled={disable}
                />
              </FormGroup2>
              <FormGroup2 text="Contract Date" required>
                <DatePicker
                  // disabled={disable}
                  value={state.ContractDateStr}
                  onChange={onChangeInputData}
                  name="ContractDateStr"
                  required
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Start Date" required>
                <DatePicker
                  // disabled={disable}
                  value={state.StartDateStr}
                  onChange={onChangeInputData}
                  name="StartDateStr"
                  required
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>
              <FormGroup2 text="End Date" required>
                <DatePicker
                  // disabled={disable}
                  value={state.EndDateStr}
                  onChange={onChangeInputData}
                  name="EndDateStr"
                  required
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Forward Type" required={reqConfirm}>
                <MasterDropdownUI
                  // disabled={disable}
                  value={state.ForwardType}
                  onChange={onChangeInputData}
                  notMultiple
                  isChoose
                  required={reqConfirm}
                  name="ForwardType"
                  options={['1 Fixed Forward', '2 Par Forward', '3 Pro Rata Forward']}
                />
              </FormGroup2>

              <FormGroup2 text="SCG Buy Currency" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  isChoose
                  notMultipleSelect2
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  noValidateOption
                  required
                  value={state.BuyCurrency}
                  name="BuyCurrency"
                  disabled={isEdit}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="SCG Sell Currency" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  isChoose
                  notMultipleSelect2
                  value={state.BookBankCurrency}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  noValidateOption
                  required
                  value={state.SellCurrency}
                  name="SellCurrency"
                  disabled={isEdit}
                />
              </FormGroup2>

              <FormGroup2 text="Book Amount" required>
                <InputMask
                  className="form-control"
                  // disabled={disable}
                  format="currency"
                  option={state.inputMaskAmount}
                  onChange={onChangeInputData}
                  value={state.Amount}
                  required
                  name="Amount"
                  disabled={isEdit}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Spot Rate" required={reqConfirm}>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 8,
                    digitsOptional: false,
                    placeholder: '0.00000000',
                  }}
                  onChange={onChangeInputData}
                  value={state.SpotRate}
                  required={reqConfirm}
                  // disabled={disable}
                  name="SpotRate"
                />
              </FormGroup2>

              <FormGroup2 text="Base Rate" required={reqConfirm}>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 8,
                    digitsOptional: false,
                    placeholder: '0.00000000',
                  }}
                  onChange={onChangeInputData}
                  value={state.BaseRate}
                  required={reqConfirm}
                  // disabled={disable}
                  name="BaseRate"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Swap Rate (Base)" required={reqConfirm}>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 8,
                    digitsOptional: false,
                    placeholder: '0.00000000',
                  }}
                  onChange={onChangeInputData}
                  value={state.SwapRateBase}
                  required={reqConfirm}
                  // disabled={disable}
                  name="SwapRateBase"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Forward Rate" required={reqConfirm}>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 8,
                    digitsOptional: false,
                    placeholder: '0.00000000',
                  }}
                  onChange={onChangeInputData}
                  value={state.ForwardRate}
                  required={reqConfirm}
                  // disabled={true}
                  name="ForwardRate"
                />
              </FormGroup2>

              <FormGroup2 text="Swap Rate" required={reqConfirm}>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={{
                    prefix: '',
                    digits: 8,
                    digitsOptional: false,
                    placeholder: '0.00000000',
                  }}
                  onChange={onChangeInputData}
                  value={state.SwapRate}
                  required={reqConfirm}
                  // disabled={disable}
                  name="SwapRate"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Purpose">
                <MasterDropdownUI
                  value={state.Gvc}
                  onChange={onChangeInputData}
                  notMultiple
                  isChoose
                  name="Gvc"
                  options={
                    state.TransactionType === '202 Forward Transaction - Sell'
                      ? [
                          'Trade AR',
                          'Project',
                          'ST Loan',
                          'LT Loan & Swap',
                          'Dividend',
                          'Capital Injection',
                          'Other',
                        ]
                      : [
                          'Trade AP',
                          'Project',
                          'ST Loan',
                          'LT Loan & Swap',
                          'Dividend',
                          'Capital Injection',
                          'Other',
                        ]
                  }
                />
              </FormGroup2>
              <FormGroup2 text="Portfolio">
                {/* <input className="form-control"
                                    value={state.Portfolio || ""}
                                    // disabled={disable}
                                    onChange={onChangeInputData}
                                    maxLength={50}
                                    name="Portfolio" /> */}

                <MasterDropdown
                  masterType={MASTER_BUSINESS_AREA}
                  noValidateOption
                  onChange={onChangeInputData}
                  companyCode={state.CompanyCode}
                  notMultiple
                  isChoose={!state.Portfolio}
                  required={state.Portfolio}
                  value={state.Portfolio}
                  name="Portfolio"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Assignment" maxLength={50} length={(state.Assignment || '').length}>
                <input
                  className="form-control"
                  value={state.Assignment || ''}
                  // disabled={disable}
                  onChange={onChangeInputData}
                  maxLength={50}
                  name="Assignment"
                />
              </FormGroup2>
              <FormGroup2
                text="Purpose Detail"
                maxLength={2000}
                length={(state.Characteristic || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.Characteristic || ''}
                  // disabled={disable}
                  onChange={onChangeInputData}
                  maxLength={2000}
                  name="Characteristic"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2
                text="Internal Ref No"
                maxLength={50}
                length={(state.InternalReference || '').length}
              >
                <input
                  className="form-control"
                  value={state.InternalReference || ''}
                  // disabled={disable}
                  onChange={onChangeInputData}
                  maxLength={50}
                  name="InternalReference"
                />
              </FormGroup2>

              <FormGroup2 text="Request Type">
                <MasterDropdownUI
                  value={state.RequestType}
                  onChange={onChangeInputData}
                  // disabled={disable}
                  notMultiple
                  isChoose
                  name="RequestType"
                  options={['With Underlying', 'Without Underlying', 'Loan']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2
                text="Contract Detail 1"
                maxLength={200}
                length={(state.ContractDetail1 || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.ContractDetail1 || ''}
                  onChange={onChangeInputData}
                  maxLength={200}
                  // disabled={disable}
                  name="ContractDetail1"
                />
              </FormGroup2>
              <FormGroup2
                text="Contract Detail 2"
                maxLength={200}
                length={(state.ContractDetail2 || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.ContractDetail2 || ''}
                  onChange={onChangeInputData}
                  maxLength={200}
                  // disabled={disable}
                  name="ContractDetail2"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2
                text="Contract Detail 3"
                maxLength={200}
                length={(state.ContractDetail3 || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.ContractDetail3 || ''}
                  onChange={onChangeInputData}
                  maxLength={200}
                  // disabled={disable}
                  name="ContractDetail3"
                />
              </FormGroup2>
              <FormGroup2
                text="Contract Detail 4"
                maxLength={200}
                length={(state.ContractDetail4 || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.ContractDetail4 || ''}
                  onChange={onChangeInputData}
                  maxLength={200}
                  // disabled={disable}
                  name="ContractDetail4"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Dealer" maxLength={50} length={(state.Dealer || '').length}>
                <input
                  className="form-control"
                  value={state.Dealer || ''}
                  onChange={onChangeInputData}
                  maxLength={50}
                  // disabled={disable}
                  name="Dealer"
                />
              </FormGroup2>

              <FormGroup2 text="Department" maxLength={50} length={(state.Department || '').length}>
                <input
                  className="form-control"
                  value={state.Department || ''}
                  // disabled={disable}
                  onChange={onChangeInputData}
                  maxLength={50}
                  name="Department"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Note 1" maxLength={2000} length={(state.Note1 || '').length}>
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.Note1 || ''}
                  // disabled={disable}
                  onChange={onChangeInputData}
                  maxLength={2000}
                  name="Note1"
                />
              </FormGroup2>

              <FormGroup2 text="Note 2" maxLength={2000} length={(state.Note2 || '').length}>
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.Note2 || ''}
                  // disabled={disable}
                  onChange={onChangeInputData}
                  maxLength={2000}
                  name="Note2"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Status (Matching/Import/Export)">
                <MasterDropdownUI
                  value={state.StatusFile}
                  onChange={onChangeInputData}
                  notMultiple
                  disabled={isEdit}
                  isChoose
                  name="StatusFile"
                  options={['Matching', 'Import', 'Export']}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </Card>
      </form>
    </div>
  );
};
