import { connect } from 'react-redux';
import React, { Component } from 'react';

import AccountSaving from './AccountSaving';
import {
  getExportAccountSaving,
  searchReportAccountSaving,
} from '../../../reducers/reportAccountSaving/action';

const initCriteria = {
  FinanceGroup: [''],
  BusinessUnit: [''],
  CompanyCode: [''],
  BankCode: [''],
  Currency: [''],
  ValueDateStr: '',
};

class AccountSavingContainer extends Component {
  state = {
    datas: [],
    criteria: {
      ...initCriteria,
    },
  };

  dataTableSearchResultRef = null;

  // ---------------- Search ----------------
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchResult !== this.props.searchResult) {
      this.setData(this.props.searchResult);
    }
  }

  onChangeSearchHeadler = (e) => {
    const { name, value, label } = e.target;
    this.setState((state) => {
      const updateState = {
        criteria: {
          ...state.criteria,
          [name]: value,
        },
      };
      return updateState;
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchReportAccountSaving({ ...this.state.criteria }).then((response) => {
      if (!response.error) {
        this.setState({
          ...this.state,
        });
      }
    });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
    });
  };

  setData(datas) {
    const groups = [];

    datas.forEach((m) => {
      const { bankAccountNo } = m;
      const findGroup = groups.find((f) => f.bankAccountNo === bankAccountNo);

      if (!findGroup) {
        groups.push({
          bankAccountNo,
          items: [m],
        });
      } else {
        findGroup.items.push(m);
      }
    });

    // console.log(groups)

    groups.forEach((f) => {
      let sumInterestAmount = 0;
      let sumNetInterestAmount = 0;
      let digit = 0;
      f.items.forEach((m) => {
        m.interestAmount = Number((m.interestAmount || 0).toFixed(m.digit));
        m.netInterestAmount = Number((m.netInterestAmount || 0).toFixed(m.digit));
        m.withHoldingTax = Number((m.withHoldingTax || 0).toFixed(m.digit));

        sumInterestAmount += m.interestAmount;
        sumNetInterestAmount += m.netInterestAmount;
        digit = m.digit;
      });

      f.items.push({
        total: true,
        interestAmount: sumInterestAmount,
        netInterestAmount: sumNetInterestAmount,
        digit,
      });
    });

    this.setState({
      datas: groups,
    });
  }

  onClickExport = (e) => {
    const data = [];
    this.state.datas.forEach((m) => {
      (m.items || []).forEach((n) => {
        data.push(n);
      });
    });
    this.props.getExportAccountSaving(data);
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      onClickExport: this.onClickExport,
    };

    return (
      <div>
        <AccountSaving {...props} {...this.state} criteria={this.state.criteria} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.reportAccountSaving,
  }),
  {
    searchReportAccountSaving,
    getExportAccountSaving,
  }
)(AccountSavingContainer);
