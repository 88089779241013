import { connect } from 'react-redux';
import React, { Component } from 'react';

import EformGrouping from './EformGrouping';
import { addAlert } from '../../reducers/layout/action';
import {
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByRowIndex,
  setCheckedCheckboxDataTableByInputElement,
} from '../../common/helpper';
import {
  exportEform,
  eformGrouping,
  saveEformBank,
  searchEformDetial,
  searchEformGrouping,
  updateSearchResultGroup,
  updateSearchResultDetail,
} from '../../reducers/eFormGrouping/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-eform';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox.result-group';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all.result-group';

export const DATATABLE_ID_DETAIL = 'table-result-detail';
export const COL_CHECKBOX_CONTEXT_DETAIL = '.col-label-checkbox.result-detail';
export const COL_CHOOSE_ALL_CONTEXT_DETAIL = '.label-checkbox.fix.choose-all.result-detail';

const initCriteria = {
  FinanceGroups: '',
  BusinessUnits: '',
  Companies: [''],
  BankCode: [''],
  Currency: [''],
  ForwardType: [''],
  RequestType: [''],
  Practice: [''],
  Direction: [''],
  ValueDateStr: '',
  CreatedDateStr: '',
  AsOfDateStr: '',
  Status: [''],
  IsAmountZero: '',
  UpdateDateStr: '',

  Mode: 'all',
};

const initModalData = {
  BankCode: '',
  ForwardRequestNo: '',
  InternalReference: '',
  Id: '',
  action: '',
};

class EformGroupingContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    resultDetails: [],
    mode: 'all',
    modalData: {
      ...initModalData,
    },
  };

  dataTableRef = null;

  dataTableDetailRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on(
      'click',
      `td${COL_CHECKBOX_CONTEXT_DETAIL}`,
      this.onClickColumnLabelCheckboxDetail
    );
    $('body').on(
      'click',
      `th ${COL_CHOOSE_ALL_CONTEXT_DETAIL}`,
      this.onClickCheckboxSelecteAllDetail
    );

    $('body').on('click', `a.show-detail`, this.onClickOpenModalDetail);
    $('body').on('click', `button.edit`, this.onClickOpenModalEditColumn);
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off(
      'click',
      `td${COL_CHECKBOX_CONTEXT_DETAIL}`,
      this.onClickColumnLabelCheckboxDetail
    );
    $('body').off(
      'click',
      `th ${COL_CHOOSE_ALL_CONTEXT_DETAIL}`,
      this.onClickCheckboxSelecteAllDetail
    );

    $('body').off('click', `a.show-detail`);
    $('body').off('click', `button.edit`);
  }

  // Grouping
  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResultGroup('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    if (this.state.mode === 'detail') {
      const $dt = this.dataTableRef.$dataTable;
      const result = setCheckedCheckboxDataTableByInputElement(e, $dt, 'col-label-checkbox');
      this.props.searchResult[result.row].IsSelected = result.checked;

      const { forwardRequestNo } = $dt.row(result.row).data();
      this.props.searchResult.forEach((m, i) => {
        if (m.forwardRequestNo === forwardRequestNo && forwardRequestNo.includes('FWO')) {
          m.IsSelected = result.checked;
          m.IsSelectedAll = result.checked;

          if (i !== result.row) {
            setCheckedCheckboxDataTableByRowIndex(i, $dt, 'col-label-checkbox', result.checked);
          }
        }
      });
      this.forceUpdate();
    } else {
      const result = setCheckedCheckboxDataTableByInputElement(e, this.dataTableRef.$dataTable);
      this.props.searchResult[result.row].IsSelected = result.checked;
      this.forceUpdate();
    }
  };

  // Detail
  onClickCheckboxSelecteAllDetail = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResultDetail('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckboxDetail = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(e, this.dataTableDetailRef.$dataTable);
    this.props.eformDetailResult[result.row].IsSelected = result.checked;
    this.forceUpdate();
  };

  // - - - Search - - -
  onChangeSearchHeadler = (e) => {
    if (e.target.name === 'IsAmountZero') {
      this.setState({
        criteria: {
          ...this.state.criteria,
          IsAmountZero: !this.state.criteria.IsAmountZero,
          UpdateDateStr: '',
        },
      });
    } else if (e.target.name === 'Mode') {
      if (e.target.value === 'detail')
        this.setState({
          criteria: {
            ...this.state.criteria,
            [e.target.name]: e.target.value,
            Status: ['Active'],
          },
        });
      else
        this.setState({
          criteria: {
            ...this.state.criteria,
            [e.target.name]: e.target.value,
            Status: [''],
          },
        });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();

    this.setState({
      mode: this.state.criteria.Mode,
    });

    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchEformGrouping(
      {
        ...this.state.criteria,
      },
      window.jQuery(window).trigger('resize')
    );
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
    });
  };

  onClickExport = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for export!',
      });
      return;
    }

    this.props.exportEform({
      InternalReference: selectedData.map((m) => m.internalReference),
    });
  };

  // - - - - - - Result Grouping - - - - - -
  onClickOpenModalDetail = (e) => {
    const row = this.dataTableRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];

    const data = {
      InternalReference: selectedData.internalReference,
      Mode: 'detail',
    };
    this.props
      .searchEformDetial({
        ...data,
      })
      .then((response) => {
        if (response.error && !response.payload) return;
      });

    $(`#${DATATABLE_ID_DETAIL} ${COL_CHOOSE_ALL_CONTEXT_DETAIL}`)[0].classList.remove('checked');

    this.setState(
      {
        modalData: {
          ...initModalData,
          InternalReference: selectedData.internalReference,
        },
      },
      this.modalDetailRef.open
    );
  };

  onClickUnGrouping = () => {
    const selectedData = this.props.eformDetailResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for ungrouping!',
      });
      return;
    }

    const submit = {
      EformIds: selectedData.map((m) => m.id),
      Mode: 'ungroup',
    };
    this.props.eformGrouping(
      {
        ...submit,
      },
      { ...this.state.criteria }
    );

    this.modalDetailRef.close();
  };
  // - - - - - - End Result Grouping - - - - - -

  // - - - - - - Result Ungrouping - - - - - -
  onClickGrouping = (grouping) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length && grouping === 'Manual') {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for manual grouping!',
      });
      return;
    }

    if (grouping === 'Auto') {
      const submit = {
        EformIds: this.props.searchResult.map((m) => m.id),
        Mode: 'group',
      };
      this.props.eformGrouping(
        {
          ...submit,
        },
        { ...this.state.criteria }
      );
    } else if (grouping === 'Manual') {
      const submit = {
        EformIds: selectedData.map((m) => m.id),
        Mode: 'group',
      };
      this.props.eformGrouping(
        {
          ...submit,
        },
        { ...this.state.criteria }
      );
    }
  };

  // - - - - - - End Result Ungrouping - - - - - -
  onClickOpenModalEditColumn = (e) => {
    const row = this.dataTableRef.$dataTable
      .fixedColumns()
      .rowIndex($(e.currentTarget).closest('tr'));
    const selectedData = this.props.searchResult[row];

    let forwardRequestNoF = '';
    let idF = '';

    if (selectedData.forwardRequestNo.includes('FWO'))
      forwardRequestNoF = selectedData.forwardRequestNo;
    else idF = selectedData.id;

    this.setState(
      {
        modalData: {
          ...initModalData,
          BankCode: selectedData.bankCode,
          CompanyCode: selectedData.companyCode,
          ForwardRequestNo: forwardRequestNoF,
          Id: idF,
          action: 'column',
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEdit = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for change bank!',
      });
      return;
    }

    const comp = selectedData[0].companyCode;
    const companys = selectedData.filter((f) => f.companyCode !== comp);
    if (companys.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item that in same company!',
      });
      return;
    }

    const forwardRequestNoF = selectedData.filter((f) => f.forwardRequestNo.includes('FWO'));
    const idF = selectedData.filter((f) => !f.forwardRequestNo.includes('FWO'));

    this.setState(
      {
        modalData: {
          ...initModalData,
          BankCode: '',
          CompanyCode: comp,
          ForwardRequestNo: forwardRequestNoF.map((m) => m.forwardRequestNo).toString(),
          Id: idF.map((m) => m.id).toString(),
          action: 'all',
        },
      },
      this.modalDataRef.open
    );
  };

  onChageModalData = (e) => {
    this.setState({
      modalData: {
        ...this.state.modalData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModal = (e) => {
    e.preventDefault();

    this.props.saveEformBank({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchEformGrouping(
        {
          ...this.state.criteria,
        },
        window.jQuery(window).trigger('resize')
      );
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      result: this.props.searchResult,
      dataTableRef: (e) => (this.dataTableRef = e),

      onClickExport: this.onClickExport,
      eformDetail: this.props.eformDetailResult,
      dataTableDetailRef: (e) => (this.dataTableDetailRef = e),
      onClickUnGrouping: this.onClickUnGrouping,

      onClickGrouping: this.onClickGrouping,
      onClickOpenModalEdit: this.onClickOpenModalEdit,
      onChageModalData: this.onChageModalData,
      onSubmitModal: this.onSubmitModal,
    };

    return (
      <EformGrouping
        {...props}
        {...this.state}
        modalDetailRef={(e) => (this.modalDetailRef = e)}
        modalDataRef={(e) => (this.modalDataRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.eFormGrouping,
  }),
  {
    searchEformGrouping,
    updateSearchResultGroup,
    eformGrouping,
    searchEformDetial,
    updateSearchResultDetail,
    addAlert,
    exportEform,
    saveEformBank,
  }
)(EformGroupingContainer);
