import React from 'react';

import Card from '../../common/Card';
import DataTable from '../../common/DataTable';

const dtId = 'dt-search-interest-receipt-maintain';
export default ({ searchResults = [], dataTableRef }) => (
  <div>
    <Card
      textHeader="Interest Receipt Maintain"
      number={1}
      cardActions={['toggler']}
      bgHeader="bg-primary"
    >
      <div className="row">
        <div className="col-sm-12">
          <a
            href="/master/print-interest-receipt-maintain"
            target="_blank"
            className="btn btn-sm btn-labeled btn-success"
          >
            <span className="btn-label">
              <i className="icon icon-fw icon-lg icon-plus" />
            </span>
            New Maintain
          </a>
        </div>
      </div>
      <DataTable
        ref={dataTableRef}
        id={dtId}
        className="table table-bordered table-nowrap dataTable"
        value={searchResults}
        option={opt}
      >
        <thead>
          <tr>
            <th>
              Active
              <br />
              /InActive
            </th>
            <th>Edit</th>
            <th>Type</th>
            <th className="bg-info">
              Master
              <br />
              Print
            </th>
            <th className="bg-info">
              IC
              <br />
              Master
            </th>
            <th className="bg-info">
              DMS
              <br />
              Master
            </th>
            <th className="bg-info">
              Master
              <br />
              auto Intr.
            </th>
            <th className="bg-info">
              Original
              <br />
              HC.
            </th>
            <th className="bg-info">
              Original
              <br />
              ELC.
            </th>
            <th className="bg-info">
              Copy Cus
              <br />
              HC.
            </th>
            <th className="bg-info">
              Copy Cus
              <br />
              ELC.
            </th>
            <th className="bg-info">
              Copy Acc
              <br />
              HC.
            </th>
            <th className="bg-info">
              Copy Acc
              <br />
              ELC.
            </th>
            <th className="bg-primary">
              Member
              <br />
              Print
            </th>
            <th className="bg-primary">
              IC
              <br />
              Member
            </th>
            <th className="bg-primary">
              DMS
              <br />
              Member
            </th>
            <th className="bg-primary">
              Member
              <br />
              auto Intr.
            </th>
            <th className="bg-primary">
              Original
              <br />
              HC.
            </th>
            <th className="bg-primary">
              Original
              <br />
              ELC.
            </th>
            <th className="bg-primary">
              Copy Cus
              <br />
              HC.
            </th>
            <th className="bg-primary">
              Copy Cus
              <br />
              ELC.
            </th>
            <th className="bg-primary">
              Copy Acc
              <br />
              HC.
            </th>
            <th className="bg-primary">
              Copy Acc
              <br />
              ELC.
            </th>
            <th>Status</th>
            <th>Created By</th>
            <th>Created Date</th>
            <th>Updated By</th>
            <th>Updted Date</th>
          </tr>
        </thead>
      </DataTable>
    </Card>
  </div>
);

const opt = {
  columns: [
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) =>
        d
          ? '<button class="btn btn-sm btn-warning change-status"><i class="icon icon-fw icon-lg icon-close"></i></button>'
          : '<button class="btn btn-sm btn-primary change-status"><i class="icon icon-fw icon-lg icon-refresh"></i></button>',
    },
    {
      data: 'id',
      render: (d) =>
        `<a target="_blank" href="/master/print-interest-receipt-maintain/${d}" class="btn btn-sm btn-info"><i class="icon icon-fw icon-lg icon-edit"></i></a>`,
      className: 'text-center',
    },
    { data: 'type' },
    { data: 'isMasterPrint', render: (d, t) => toCheckbox(d, t), className: 'text-center' },
    { data: 'isMasterIc', render: (d, t) => toCheckbox(d, t), className: 'text-center' },
    { data: 'isMasterDms', render: (d, t) => toCheckbox(d, t), className: 'text-center' },
    {
      data: 'isMasterAutoInterestIncome',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMasterOriginalHardCopy',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMasterOriginalElectronic',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMasterCopyCusHardCopy',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMasterCopyCusElectronic',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMasterCopyAccHardCopy',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMasterCopyAccElectronic',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    { data: 'isMemberPrint', render: (d, t) => toCheckbox(d, t), className: 'text-center' },
    { data: 'isMemberIc', render: (d, t) => toCheckbox(d, t), className: 'text-center' },
    { data: 'isMemberDms', render: (d, t) => toCheckbox(d, t), className: 'text-center' },
    {
      data: 'isMemberAutoInterestIncome',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMemberOriginalHardCopy',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMemberOriginalElectronic',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMemberCopyCusHardCopy',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMemberCopyCusElectronic',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMemberCopyAccHardCopy',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isMemberCopyAccElectronic',
      render: (d, t) => toCheckbox(d, t),
      className: 'text-center',
    },
    {
      data: 'isActive',
      render: (d) =>
        d
          ? '<span class="label label-success">Active</span>'
          : '<span class="label label-warning">InActive</span>',
      className: 'text-center',
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', type: 'date-black' },
  ],
};

const toCheckbox = (checked, type) => {
  if (type === 'export') {
    return checked ? 1 : 0;
  }
  if (checked) return '<label class="label-checkbox fix checked" style="cursor:auto;"></label>';
  return '<label class="label-checkbox fix" style="cursor:auto;"></label>';
};
