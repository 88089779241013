import React from 'react';

import Card from '../../common/Card';
import Link from '../../common/Link';
import Button from '../../common/Button';
import { toFixed, getPermission } from '../../common/helpper';

const permission = getPermission('FX Management', 'Exposure Position');

export default ({
  results,
  theads,
  renderTd,
  onChangeInputTable,
  onClickSave,
  sumOutstandingExposure,
  onClickBackToSearchExposurePosition,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          FX Management <span className="icon icon-angle-double-right" />
          <Link txt="Exposure Position" href="/fx-management/exposure-position" />
          <span className="icon icon-angle-double-right" /> Forward Rate
        </small>
      </p>
    </div>

    <form onSubmit={onClickSave}>
      <Card
        textHeader="Forward Rate"
        bgHeader="bg-primary"
        cardActions={['toggler']}
        footer={
          <div>
            <Button
              txt="Back"
              className="btn-default"
              icon="arrow-left"
              onClick={onClickBackToSearchExposurePosition}
            />
            {permission ? (
              <>
                &nbsp;
                <Button txt="Save" className="btn-success" icon="save" type="submit" />
              </>
            ) : null}
          </div>
        }
      >
        <div className="table-responsive" style={{ maxHeight: '500px' }}>
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th className="th-success">Department</th>
                <th className="th-success">FWD Request Type</th>
                <th className="th-success">Ext. Ref (T/T)</th>
                <th className="th-success">Purpose</th>
                <th className="th-success">Purpose Detail</th>
                <th className="th-success">Currency</th>
                <th className="th-success">Transaction Forward No.</th>
                <th className="th-success">Forward Contract No.</th>
                <th className="th-success">Start Date</th>
                <th className="th-success">Expire Date</th>
                <th className="th-success">Map Amount</th>
                <th className="th-success">Request Amount</th>
                <th className="th-success">Internal Ref. No.</th>
                <th className="th-success">Used Amount</th>
                <th className="th-success">Remaining Amount</th>
                <th className="th-success">Original Amount</th>
                <th className="th-success">Outstanding</th>
                {theads}
              </tr>
            </thead>
            <tbody>
              {results.map((m, i) => (
                <tr key={i}>
                  <td>{m.departmentName}</td>
                  <td className="text-center">{m.forwardType}</td>
                  <td>{m.externalRef}</td>
                  <td className="text-center">{m.purpose}</td>
                  <td className="text-center">{m.purposeDetail}</td>
                  <td className="text-center">{m.forwardCurrency}</td>
                  <td>{m.forwardNo}</td>
                  <td>{m.contractNo}</td>
                  <td className="text-center">{m.startDateStr}</td>
                  <td className="text-center">{m.endDateStr}</td>
                  <td className="text-right">{setNumber(m.mappedAmount, m.digit)}</td>
                  <td className="text-right">{setNumber(m.requestAmount, m.digit)}</td>
                  <td>{m.internalReference}</td>
                  <td className="text-right">{setNumber(m.usedAmount || 0, m.digit)}</td>
                  <td className="text-right">{setNumber(m.outstanding - m.usedAmount, m.digit)}</td>
                  <td className="text-right">{setNumber(m.amount, m.digit)}</td>
                  <td className="text-right">{setNumber(m.outstanding, m.digit)}</td>
                  {renderTd(m, i)}
                </tr>
              ))}
              <tr>
                <td colSpan="17" />
                {sumOutstandingExposure.map((m, i) => (
                  <td className="text-right" key={i}>
                    {setNumber(m)}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </Card>
    </form>
  </div>
);

const setNumber = (number, float = 3) => {
  if (isNaN(number)) return '';
  if (number >= 0) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return <span style={{ color: '#e64a19' }}>{toFixed(number, float)}</span>;
};
