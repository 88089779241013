import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../common/Card';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import FormGroup2 from '../../../common/FormGroup2';
import ModalFunction from '../../../common/ModalFunction';
import { DATATABLE_ID } from './MaintainInterestRateContainer';
import { toStatus, getPermission } from '../../../common/helpper';

const permission = getPermission('Funding&Investment', 'Maintain Interest Rate');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[1, 'asc']],
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'interestRateId',
      render: (d, t, r) =>
        `<a target="_blank" href="/loan-investment/interest-rate/${d}"> ${r.interestRateNo} </a>`,
    },
    { data: 'interestTemplateName' },
    { data: 'calculateMethod' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (d) return toStatus('Active');
        return toStatus('Inactive');
      },
    },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onClickSearch,

  results,
  dataTableRef,

  modalActiveRef,
  modalInactiveRef,
  onClickOpenModalChangeStatus,
  onSubmitModalChangeStatus,
}) => (
  <div>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Funding & Investment <span className="icon icon-angle-double-right" /> Maintain Interest
          Rate
        </small>
      </p>
    </div>

    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <div className="row gutter-xs">
          <div className="col-sm-3">
            <FormGroup2 text="Status">
              <select
                className="form-control"
                value={criteria.IsActive}
                onChange={onChangeSearch}
                name="IsActive"
              >
                <option value={null}>---All---</option>
                <option value>Active</option>
                <option value={false}>Inactive</option>
              </select>
            </FormGroup2>
          </div>
          <div className="col-sm-9">
            <FormGroup2 text="&nbsp;">
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              {permission ? (
                <>
                  &nbsp;
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/loan-investment/interest-rate"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    MAINTAIN INTEREST RATE
                  </Link>
                </>
              ) : null}
            </FormGroup2>
          </div>
        </div>
      </form>
    </Card>

    <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
      <Table
        ref={dataTableRef}
        className="table table-bordered table-nowrap dataTable"
        id={DATATABLE_ID}
        option={opt}
        value={results}
        onClickContext={[
          {
            context: 'button.active',
            onClick: (e) => onClickOpenModalChangeStatus(e, 'Active'),
          },
          {
            context: 'button.inactive',
            onClick: (e) => onClickOpenModalChangeStatus(e, 'Inactive'),
          },
        ]}
      >
        <thead>
          <tr>
            <th>Action</th>
            <th>Interest Template Code</th>
            <th>Interest Template Name</th>
            <th>Cal. Method</th>
            <th>Status</th>
            <th>
              Created
              <br />
              Date
            </th>
            <th>
              Created
              <br />
              By
            </th>
            <th>
              Updated
              <br />
              Date
            </th>
            <th>
              Updated
              <br />
              By
            </th>
          </tr>
        </thead>
      </Table>
    </Card>

    <ModalFunction
      textHeader="Active"
      textContent="active"
      modalRef={modalActiveRef}
      type="info"
      icon="icon-refresh"
      textBtn="Active"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
    />

    <ModalFunction
      textHeader="InActive"
      textContent="inactive"
      modalRef={modalInactiveRef}
      type="danger"
      icon="icon-close"
      textBtn="Yes"
      textBtnBack="No"
      noSelect
      onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
    />
  </div>
);
