import React, { Component } from 'react';

import Select2 from './Select2';

export const MASTER_TRANSFER_METHOD = 'MASTER_TRANSFER_METHOD';
export const MASTER_PAYMENT_METHOD = 'MASTER_PAYMENT_METHOD';
export const MASTER_SENDING_TYPE = 'MASTER_SENDING_TYPE';
export const MASTER_EXPOSURE_TYPE = 'MASTER_EXPOSURE_TYPE';
export const PAYMENT_METHOD_CASH_FLOW = 'PAYMENT_METHOD_CASH_FLOW';
export const OPTION_WORKING_DAY = 'OPTION_WORKING_DAY';
export const OPTION_FREQUENCY = 'OPTION_FREQUENCY';
export const ACCOUNT_STANDARD = 'ACCOUNT_STANDARD';
export const INSTANCE_CODE = 'INSTANCE_CODE';

class MasterDropdownUI extends Component {
  render() {
    const { masterType } = this.props;
    let { options } = this.props;
    const optionlist = [];

    if (options == null) {
      if (masterType === MASTER_TRANSFER_METHOD) {
        options = ['Bahtnet', 'Cheque', 'Auto Deduct', 'Internet Banking'];
      } else if (masterType === MASTER_PAYMENT_METHOD) {
        options = ['DA', 'DP', 'LC', 'Auto Deduct', 'T/T'];
      } else if (masterType === MASTER_SENDING_TYPE) {
        options = ['SFTP', 'Paper', 'Paper with Signature'];
      } else if (masterType === MASTER_EXPOSURE_TYPE) {
        // options = ["Trade AR", "Trade AP", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection"]
        options = [
          'Trade AR',
          'Trade AP',
          'Project',
          'ST Loan',
          'LT Loan & Swap',
          'Capital Injection',
          'Dividend',
        ];
      } else if (masterType === PAYMENT_METHOD_CASH_FLOW) {
        options = ['Bahtnet', 'Cheque', 'Internet Banking', 'Auto Deduct', 'T/T', 'T/T Swaper'];
      } else if (masterType === OPTION_WORKING_DAY) {
        options = [
          '0: No Shift',
          '1: Next Working Day',
          '2: Next Working Day Modified',
          '3: Previous Working Day',
          '4: Previous Working Day Modified',
          '5: Next Month',
          '6: Next Month Modified',
        ];
      } else if (masterType === OPTION_FREQUENCY) {
        options = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];
      } else if (masterType === ACCOUNT_STANDARD) {
        options = ['SCG Standard', 'IAS39', 'IN GAAP', 'JP GAAP', 'MM GAAP', 'US GAAP', 'VN GAAP'];
      } else if (masterType === INSTANCE_CODE) {
        options = [
          'SAP 4.7',
          'SAP ECC CERAMICS',
          'SAP ECC CBMR',
          'SAP ECC PACKAGING',
          'SAP ECC CHEMICAL',
        ];
      }
    }

    if (this.props.isChoose) {
      optionlist.push(
        <option key="" value="">
          --Choose--
        </option>
      );
    }

    if (this.props.isAll) {
      optionlist.push(
        <option key="" value="">
          --All--
        </option>
      );
    }

    if (options != null) {
      for (let i = 0; i < options.length; i++) {
        optionlist.push(
          <option key={i} value={options[i]}>
            {options[i]}
          </option>
        );
      }
    }

    if (this.props.notMultiple) {
      return (
        <select
          className={this.props.className || 'form-control'}
          tabIndex={this.props.tabIndex || '-1'}
          name={this.props.name}
          onChange={this.props.onChange}
          value={this.props.value || ''}
          required={this.props.required}
          disabled={this.props.disabled}
        >
          {optionlist}
        </select>
      );
    }
    return (
      <div>
        <Select2
          name={this.props.name}
          onChange={this.props.onChange}
          value={this.props.value}
          required={this.props.required}
          disabled={this.props.disabled}
          ref={(e) => (this.select2 = e)}
          isChoose={this.props.isChoose}
          notMultiple={this.props.notMultipleSelect2}
          isTransfermethod={this.props.isTransfermethod}
          noValidateOption={this.props.noValidateOption || false}
        >
          {optionlist}
        </Select2>
      </div>
    );
  }
}

export default MasterDropdownUI;
