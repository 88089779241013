import React from 'react';

import Col2 from '../../../common/Col2';
import Card from '../../../common/Card';
import Button from '../../../common/Button';
import { toFixed } from '../../../common/helpper';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ state, onClickSwapSchedule, onViewModalSchedule, onDeleteSchedule }) => (
  <>
    {state.DerivativeType === 'POS' ? (
      <>
        {state.permission ? (
          <>
            <Col2 col={colX[0]}>
              <Button
                txt="Swap Schedule"
                icon="plus"
                className="btn-warning"
                onClick={(e) => onClickSwapSchedule(e, 'POS')}
              />
            </Col2>
            <br />
          </>
        ) : null}

        <div className="table-responsive">
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th className="th-white">Action</th>
                <th className="th-white">Ref. Amount</th>
                <th className="th-white">Ref. Currency</th>
                <th className="th-white">Swap Amount</th>
                <th className="th-white">Swap Currency</th>
                <th className="th-white">Valid From</th>
                <th className="th-white">Valid To</th>
                <th className="th-white">Premium Discount</th>
                <th className="th-white">Cal. Method</th>
                <th className="th-white">Period</th>
              </tr>
            </thead>
            <tbody>
              {!(state.resultSchedule || []).length ? (
                <tr>
                  <td colSpan="10" align="center">
                    No data available in table
                  </td>
                </tr>
              ) : (
                state.resultSchedule.map((m, i) => (
                  <tr key={i}>
                    <td align="center">
                      {state.permission ? (
                        <button
                          className="btn btn-icon btn-default"
                          onClick={(e) => onDeleteSchedule(e, i)}
                        >
                          <span className="icon icon-close sq-24" />
                        </button>
                      ) : null}
                    </td>
                    <td align="right">{setAmount(m.RefAmount, m.RefDigit)}</td>
                    <td align="center">{m.RefCurrency}</td>
                    <td align="right">{setAmount(m.SwapAmount, m.SwapDigit)}</td>
                    <td align="center">{m.SwapCurrency}</td>
                    <td align="center">{m.ValidFromStr}</td>
                    <td align="center">{m.ValidToStr}</td>
                    <td align="right">{setAmount(m.PremiumDiscount, 5)}</td>
                    <td align="center">{m.CalculateMethod}</td>
                    <td align="center">
                      <button
                        className="btn btn-icon btn-success"
                        onClick={(e) => onViewModalSchedule(e, i)}
                      >
                        <span className="icon icon-eye sq-24" />
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </>
    ) : (
      <>
        {state.permission ? (
          <>
            <Col2 col={colX[0]}>
              <Button
                txt="Schedule"
                icon="plus"
                className="btn-warning"
                onClick={(e) => onClickSwapSchedule(e, 'IRS/CCS')}
              />
            </Col2>
            <br />
          </>
        ) : null}

        <Card textHeader="Reference Schedule" cardActions={['toggler']}>
          <div className="table-responsive">
            <table className="table table-bordered table-nowrap">
              <thead>
                <tr>
                  <th className="th-white">Action</th>
                  <th className="th-white">Amount</th>
                  <th className="th-white">Currency</th>
                  <th className="th-white">Valid From</th>
                  <th className="th-white">Valid To</th>
                  <th className="th-white">Ref. Interest Name</th>
                  <th className="th-white">Ref. Interest</th>
                  <th className="th-white">Margin</th>
                  <th className="th-white">Interest</th>
                  <th className="th-white">Cal. Method</th>
                  <th className="th-white">Period</th>
                </tr>
              </thead>
              <tbody>
                {!(state.resultSchedule || []).length ? (
                  <tr>
                    <td colSpan="11" align="center">
                      No data available in table
                    </td>
                  </tr>
                ) : (
                  state.resultSchedule.map((m, i) => (
                    <tr key={i}>
                      <td align="center">
                        {state.permission ? (
                          <button
                            className="btn btn-icon btn-default"
                            onClick={(e) => onDeleteSchedule(e, i)}
                          >
                            <span className="icon icon-close sq-24" />
                          </button>
                        ) : null}
                      </td>
                      <td align="right">{setAmount(m.RefAmount, m.RefDigit)}</td>
                      <td align="center">{m.RefCurrency}</td>
                      <td align="center">{m.ValidFromStr}</td>
                      <td align="center">{m.ValidToStr}</td>
                      <td align="center">{m.RefInterestRateName}</td>
                      <td align="right">{setAmount(m.RefInterestRate, 5)}</td>
                      <td align="right">{setAmount(m.RefMargin, 2)}</td>
                      <td align="right">{setAmount(m.RefInterest, 5)}</td>
                      <td align="center">{m.RefCalculateMethod}</td>
                      <td align="center">
                        <button
                          className="btn btn-icon btn-success"
                          onClick={(e) => onViewModalSchedule(e, i)}
                        >
                          <span className="icon icon-eye sq-24" />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Card>

        <Card textHeader="Swap Schedule" cardActions={['toggler']}>
          <div className="table-responsive">
            <table className="table table-bordered table-nowrap">
              <thead>
                <tr>
                  <th className="th-white">Action</th>
                  <th className="th-white">Amount</th>
                  <th className="th-white">Currency</th>
                  <th className="th-white">Valid From</th>
                  <th className="th-white">Valid To</th>
                  <th className="th-white">Ref. Interest Name</th>
                  <th className="th-white">Ref. Interest</th>
                  <th className="th-white">Margin</th>
                  <th className="th-white">Interest</th>
                  <th className="th-white">Cal. Method</th>
                  <th className="th-white">Period</th>
                </tr>
              </thead>
              <tbody>
                {!(state.resultSchedule || []).length ? (
                  <tr>
                    <td colSpan="11" align="center">
                      No data available in table
                    </td>
                  </tr>
                ) : (
                  state.resultSchedule.map((m, i) => (
                    <tr key={i}>
                      <td align="center">
                        {state.permission ? (
                          <button
                            className="btn btn-icon btn-default"
                            onClick={(e) => onDeleteSchedule(e, i)}
                          >
                            <span className="icon icon-close sq-24" />
                          </button>
                        ) : null}
                      </td>
                      <td align="right">{setAmount(m.SwapAmount, m.SwapDigit)}</td>
                      <td align="center">{m.SwapCurrency}</td>
                      <td align="center">{m.ValidFromStr}</td>
                      <td align="center">{m.ValidToStr}</td>
                      <td align="center">{m.SwapInterestRateName}</td>
                      <td align="right">{setAmount(m.SwapInterestRate, 5)}</td>
                      <td align="right">{setAmount(m.SwapMargin, 2)}</td>
                      <td align="right">{setAmount(m.SwapInterest, 5)}</td>
                      <td align="center">
                        {state.DerivativeType === 'POS' ? m.CalculateMethod : m.SwapCalculateMethod}
                      </td>
                      <td align="center">
                        <button
                          className="btn btn-icon btn-success"
                          onClick={(e) => onViewModalSchedule(e, i)}
                        >
                          <span className="icon icon-eye sq-24" />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Card>
      </>
    )}
  </>
);

const setAmount = (number, float) => {
  if (float === null || float === undefined) float = 3;
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};
