import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import MasterDropdownUI, { MASTER_EXPOSURE_TYPE } from '../../common/MasterDropdownUI';
import { toBank, toLower, toNumber, toStatus, getPermissionStr } from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermissionStr('FX Management', 'Create Exposure');
const viewInward = toLower(permission) === 'view (inward)';
const viewOutward = toLower(permission) === 'view (outward)';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    {
      orderable: false,
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission || toLower(permission).includes('view')) return '';
        if (r.isInactive) {
          if (d)
            return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
          return '';
          // return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>'
        }
        return '<button class="btn btn-icon btn-info" disabled style="opacity:0"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'exposureNo',
      render: (d) =>
        `<a target="_blank" href="/fx-management/exposure-transaction/${d}" class="edit-exposure"> ${d} </a>`,
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'customerName' },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'informDateStr', className: 'text-center', type: 'date-black' },
    { data: 'cashType', className: 'text-center' },
    { data: 'transactionType', className: 'text-center' },
    { data: 'transactionDetail' },
    { data: 'swiftCode' },
    {
      data: 'bankAbbreviate',
      render: (d, r, s) => toBank(d, s.bankName),
    },
    {
      data: 'amount',
      className: 'text-right td-width-number',
      render: (d, t, r) => {
        if (toLower(r.cashType) === 'outward') return toNumber(d * -1, r.currencyDigit);
        return toNumber(d, r.currencyDigit);
      },
    },
    {
      data: 'invoiceAmount',
      className: 'text-right',
      render: (d, t, r) => (d ? toNumber(Number(d), r.currencyDigit) : ''),
    },
    { data: 'currency', className: 'text-center' },
    { data: 'equivalent', className: 'text-center' },
    { data: 'invoiceNo' },
    {
      data: 'paymentType',
      render: (d) => {
        if (toLower(d) === 'tt') return 'T/T';
        return d;
      },
    },
    { data: 'exposureType' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('InActive')),
    },
    { data: 'projectName' },
    { data: 'referenceNo' },
    { data: 'q2oNo' },
    { data: 'bankRemark' },
    { data: 'bankFileNameWithOutGuid' },
    {
      data: 'importExposureStatus',
      className: 'text-center',
      render: (d) => {
        if (toLower(d) === 'success') return `<span class="label label-success">${d}</span>`;
        if (toLower(d) === 'error') return `<span class="label label-danger">${d}</span>`;
        return d;
      },
    },
    { data: 'importExposureMessage' },
    { data: 'department' },
    { data: 'paymentTerm' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    {
      data: 'attachFileExposure',
      render: (d) =>
        '<button class="btn btn-info btn-sm download"><i class="icon icon-download icon-fw icon-lg"></i></button>',
      className: 'text-center',
    },
  ],
};

export default ({
  criteria,
  results,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,

  onClickFetch,

  dataTableRef,
  onSubmitModalChangeStatusHandler,
  modalActiveRef,
  modalCancelRef,

  onClickOpenModalImportExcel,

  onClickDownloadAttachFile,
}) => {
  const setDisabledValueDate = !(
    criteria.ValueDateStr !== '' ||
    (criteria.ValueDateStr === '' && criteria.InformDateStr === '')
  );
  const setDisabledInformDate = !(
    criteria.InformDateStr !== '' ||
    (criteria.ValueDateStr === '' && criteria.InformDateStr === '')
  );

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" /> Create Exposure
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  name="Companies"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                />
              </FormGroup2>

              <FormGroup2 text="Inform Date" required={!setDisabledInformDate}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  // disabled={setDisabledInformDate}
                  required={!setDisabledInformDate}
                  value={criteria.InformDateStr}
                  name="InformDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.Currencies}
                  name="Currencies"
                />
              </FormGroup2>

              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.Banks}
                  name="Banks"
                  saveLocalState
                  companyCode={criteria.Companies}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Exposure Type">
                <MasterDropdownUI
                  masterType={MASTER_EXPOSURE_TYPE}
                  notMultiple
                  value={criteria.ExposureType}
                  onChange={onChangeSearch}
                  isAll
                  name="ExposureType"
                  options={null}
                />
              </FormGroup2>

              <FormGroup2 text="Value Date" required={!setDisabledValueDate}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  // disabled={setDisabledValueDate}
                  required={!setDisabledValueDate}
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Cash Type">
                <MasterDropdownUI
                  value={criteria.CashTypes}
                  onChange={onChangeSelect2}
                  name="CashTypes"
                  disabled={viewInward || viewOutward}
                  options={['Inward', 'Outward']}
                />
              </FormGroup2>
              <FormGroup2 text="Transaction Type">
                <MasterDropdownUI
                  value={criteria.TransactionTypes}
                  onChange={onChangeSelect2}
                  name="TransactionTypes"
                  options={['Plan', 'From Bank']}
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.IsActive}
                  onChange={onChangeSearch}
                  name="IsActive"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {toLower(permission) === 'create' || toLower(permission) === 'all' ? (
                <>
                  <Button
                    txt="UPLOAD EXCEL"
                    icon="upload"
                    className="btn-success"
                    onClick={onClickOpenModalImportExcel}
                  />
                  &nbsp;
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/fx-management/exposure-transaction/create"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    CREATE
                  </Link>
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {toLower(permission) === 'all' ? (
          <Col2 col={colX[2]}>
            <div>
              <Button
                txt="FETCH EMAIL EXPOSURE"
                icon="undo"
                className="btn-warning"
                onClick={onClickFetch}
              />
            </div>
          </Col2>
        ) : null}

        <Table
          className="table table-bordered table-nowrap dataTable"
          id="table-result-create-exposure"
          ref={dataTableRef}
          option={opt}
          value={results}
          onClickContext={[
            {
              onClick: onClickDownloadAttachFile,
              context: 'button.download',
            },
          ]}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Exposure No.</th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>
                Company
                <br />
                Name
              </th>
              <th>Vendor/Customer Name</th>
              <th>Value date</th>
              <th>Inform Date</th>
              <th>Cash Type</th>
              <th>
                Transaction
                <br /> Type
              </th>
              <th>Transaction Detail</th>
              <th>Swift Code</th>
              <th>Bank</th>
              <th className="text-center">Amount</th>
              <th className="text-center">Invoice Amount</th>
              <th>Currency</th>
              <th>Equivalent</th>
              <th>Invoice No.</th>
              <th>Payment Type</th>
              <th>Exposure Type</th>
              <th>Status</th>
              <th>Project Name</th>
              <th>Bank Reference No.</th>
              <th>Q2O No.</th>
              <th>Bank Remark</th>
              <th>Bank File Name</th>
              <th>Sent Auto Incoming</th>
              <th>Error Message</th>
              <th>Department</th>
              <th>Payment Term</th>
              <th>Created Date</th>
              <th>Created By</th>
              <th>Updated Date</th>
              <th>Updated By</th>
              <th>File</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatusHandler(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalCancelRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatusHandler(e, 'Cancel')}
      />
    </div>
  );
};
