import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_USER_CRITERIA = 'EDIT_MASTER_USER_CRITERIA';
export const CLEAR_MASTER_USER_CRITERIA = 'CLEAR_MASTER_USER_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_USER = 'UPDATE_SEARCH_RESULT_MASTER_USER';

export const REQUEST_SEARCH_MASTER_USER = '@@request/SEARCH_MASTER_USER';
export const RECEIVE_SEARCH_MASTER_USER = '@@receive/SEARCH_MASTER_USER';
export const FAIL_SEARCH_MASTER_USER = '@@fail/SEARCH_MASTER_USER';

export const REQUEST_DELETE_MASTER_USER = '@@request/DELETE_MASTER_USER';
export const RECEIVE_DELETE_MASTER_USER =
  '@@receive-The record was successfully saved/DELETE_MASTER_USER';
export const FAIL_DELETE_MASTER_USER = '@@fail/DELETE_MASTER_USER';

export const REQUEST_SAVE_MASTER_USER = '@@request/SAVE_MASTER_USER';
export const RECEIVE_SAVE_MASTER_USER = '@@receive/SAVE_MASTER_USER';
export const FAIL_SAVE_MASTER_USER = '@@fail/SAVE_MASTER_USER';

export const REQUEST_GET_MASTER_USER_DETAIL = '@@request/GET_MASTER_USER_DETAIL';
export const RECEIVE_GET_MASTER_USER_DETAIL = '@@receive/GET_MASTER_USER_DETAIL';
export const FAIL_GET_MASTER_USER_DETAIL = '@@fail/GET_MASTER_USER_DETAIL';

export const REQUEST_GET_MASTER_USER_DETAIL_GROUP = '@@request/GET_MASTER_USER_DETAIL_GROUP';
export const RECEIVE_GET_MASTER_USER_DETAIL_GROUP = '@@receive/GET_MASTER_USER_DETAIL_GROUP';
export const FAIL_GET_MASTER_USER_DETAIL_GROUP = '@@fail/GET_MASTER_USER_DETAIL_GROUP';

export const REQUEST_GET_MASTER_USER_DETAIL_GROUP_DETAIL =
  '@@request/GET_MASTER_USER_DETAIL_GROUP_DETAIL';
export const RECEIVE_GET_MASTER_USER_DETAIL_GROUP_DETAIL =
  '@@receive/GET_MASTER_USER_DETAIL_GROUP_DETAIL';
export const FAIL_GET_MASTER_USER_DETAIL_GROUP_DETAIL =
  '@@fail/GET_MASTER_USER_DETAIL_GROUP_DETAIL';

export const UPDATE_RESULT_MASTER_COMPANY = 'UPDATE_RESULT_MASTER_COMPANY';
export const UPDATE_RESULT_MASTER_ROLE = 'UPDATE_RESULT_MASTER_ROLE';

export const REQUEST_GET_USER_COPY_FROM = '@@request/GET_USER_COPY_FROM';
export const RECEIVE_GET_USER_COPY_FROM = '@@receive/GET_USER_COPY_FROM';
export const FAIL_GET_USER_COPY_FROM = '@@fail/GET_USER_COPY_FROM';

export const REQUEST_APPROVE_MASTER_USER = '@@request/APPROVE_MASTER_USER';
export const RECEIVE_APPROVE_MASTER_USER = '@@receive/APPROVE_MASTER_USER';
export const FAIL_APPROVE_MASTER_USER = '@@fail/APPROVE_MASTER_USER';

export const UPDATE_COPY_FROM = 'UPDATE_COPY_FROM';

export const REQUEST_SAVE_MASTER_USER_GROUP = '@@request/SAVE_MASTER_USER_GROUP';
export const RECEIVE_SAVE_MASTER_USER_GROUP = '@@receive/SAVE_MASTER_USER_GROUP';
export const FAIL_SAVE_MASTER_USER_GROUP = '@@fail/SAVE_MASTER_USER_GROUP';

export const REQUEST_SAVE_MASTER_USER_COPY = '@@request/SAVE_MASTER_USER_COPY';
export const RECEIVE_SAVE_MASTER_USER_COPY = '@@receive/SAVE_MASTER_USER_COPY';
export const FAIL_SAVE_MASTER_USER_COPY = '@@fail/SAVE_MASTER_USER_COPY';

export const saveMasterUserCopy = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterusergroupcopy`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_USER_COPY,
      RECEIVE_SAVE_MASTER_USER_COPY,
      FAIL_SAVE_MASTER_USER_COPY,
    ],
  },
});

export const saveMasterUserDetailGroup = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterusergroup`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_USER_GROUP,
      RECEIVE_SAVE_MASTER_USER_GROUP,
      FAIL_SAVE_MASTER_USER_GROUP,
    ],
  },
});

export const approveMasterUser = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/approvemasteruser`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_APPROVE_MASTER_USER, RECEIVE_APPROVE_MASTER_USER, FAIL_APPROVE_MASTER_USER],
  },
});

export const updateCopyFrom = (resultCompany, resultRole) => ({
  type: UPDATE_COPY_FROM,
  payload: {
    resultCompany,
    resultRole,
  },
});

export const getUserCopyFrom = (username) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasteruserdetail?username=${encodeURIComponent(username)}`),
    method: 'GET',
    types: [REQUEST_GET_USER_COPY_FROM, RECEIVE_GET_USER_COPY_FROM, FAIL_GET_USER_COPY_FROM],
  },
});

export const updateResultRole = (name, value, index) => ({
  type: UPDATE_RESULT_MASTER_ROLE,
  payload: {
    name,
    value,
    index,
  },
});

export const updateResultCompany = (name, value, index) => ({
  type: UPDATE_RESULT_MASTER_COMPANY,
  payload: {
    name,
    value,
    index,
  },
});

export const getMasterUserDetail = (username) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasteruserdetail?username=${encodeURIComponent(username)}`),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_USER_DETAIL,
      RECEIVE_GET_MASTER_USER_DETAIL,
      FAIL_GET_MASTER_USER_DETAIL,
    ],
  },
});

export const getMasterUserDetailGroup = (username) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasteruserdetailgroup/${username}`),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_USER_DETAIL_GROUP,
      RECEIVE_GET_MASTER_USER_DETAIL_GROUP,
      FAIL_GET_MASTER_USER_DETAIL_GROUP,
    ],
  },
});

export const getMasterUserDetailGroupDetail = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasteruserdetailgroupdetail`, criteria),
    method: 'GET',
    types: [
      REQUEST_GET_MASTER_USER_DETAIL_GROUP_DETAIL,
      RECEIVE_GET_MASTER_USER_DETAIL_GROUP_DETAIL,
      FAIL_GET_MASTER_USER_DETAIL_GROUP_DETAIL,
    ],
  },
});

export const saveMasterUserDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasteruser`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MASTER_USER, RECEIVE_SAVE_MASTER_USER, FAIL_SAVE_MASTER_USER],
  },
});

export const deleteMasterUser = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemasteruser`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [REQUEST_DELETE_MASTER_USER, RECEIVE_DELETE_MASTER_USER, FAIL_DELETE_MASTER_USER],
  },
});

export const searchMasterUser = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasteruser', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_MASTER_USER, RECEIVE_SEARCH_MASTER_USER, FAIL_SEARCH_MASTER_USER],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_USER,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_USER_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_USER_CRITERIA,
  payload: {
    name,
    value,
  },
});
