import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';
import { downloadFile } from '../../common/helpper';

export const EDIT_LOAN_REPORT_CRITERIA = 'EDIT_LOAN_REPORT_CRITERIA';
export const CLEAR_LOAN_REPORT_CRITERIA = 'CLEAR_LOAN_REPORT_CRITERIA';

export const UPDATE_SEARCH_RESULT_LOAN_REPORT = 'UPDATE_SEARCH_RESULT_LOAN_REPORT';

export const REQUEST_SEARCH_LOAN_REPORT = '@@request/SEARCH_LOAN_REPORT';
export const RECEIVE_SEARCH_LOAN_REPORT = '@@receive/SEARCH_LOAN_REPORT';
export const FAIL_SEARCH_LOAN_REPORT = '@@fail/SEARCH_LOAN_REPORT';

export const REQUEST_SEARCH_DEPOSIT_REPORT = '@@request/SEARCH_DEPOSIT_REPORT_REPORT';
export const RECEIVE_SEARCH_DEPOSIT_REPORT = '@@receive/SEARCH_DEPOSIT_REPORT';
export const FAIL_SEARCH_DEPOSIT_REPORT = '@@fail/SEARCH_DEPOSIT_REPORT';

export const REQUEST_DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT =
  '@@request/DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT';
export const RECEIVE_DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT =
  '@@receive/DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT';
export const FAIL_DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT =
  '@@fail/DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT';

export const exportReport = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/ExportLoanReport', criteria),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT,
      {
        type: RECEIVE_DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_EXPORT_REPORT_LOAN_INVESTMENT,
    ],
  },
});

export const searchLoanReport = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/report/getLoanReport', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_LOAN_REPORT, RECEIVE_SEARCH_LOAN_REPORT, FAIL_SEARCH_LOAN_REPORT],
  },
});

export const searchDeposit = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/searchloan', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_DEPOSIT_REPORT,
      RECEIVE_SEARCH_DEPOSIT_REPORT,
      FAIL_SEARCH_DEPOSIT_REPORT,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_LOAN_REPORT,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_LOAN_REPORT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_LOAN_REPORT_CRITERIA,
  payload: {
    name,
    value,
  },
});
