import React from 'react';

import Card from '../../common/Card';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import DataTable from '../../common/DataTable';
import DatePicker from '../../common/DatePicker';
import MasterDropdown from '../../common/MasterDropdown';
import {
  MASTER_COMPANY,
  MASTER_PRINT_INTEREST_RECEIPT_MAINTAIN,
} from '../../reducers/master/action';

const dtId = 'dt-search-interest-receipt-type';
export default ({
  searchResults = [],
  maintainData = {},
  criteria = {},
  dataTableRef,
  modalMaintainRef,
  onClickOpenModalCreate,
  onChangeCriteria,
  onChangeMaintainData,
  onSubmitMaintainFrom,
  onSubmitSearchForm,
}) => {
  return (
    <div>
      {renderCriteria()}
      {renderDataTable()}
      {renderModalMaintian()}
    </div>
  );

  function renderCriteria() {
    return (
      <Card textHeader="Criteria" number={1} cardActions={['toggler']} bgHeader="bg-primary">
        <form onSubmit={onSubmitSearchForm}>
          <div className="row">
            <div className="form-group col-md-4">
              <label className="control-label col-md-4 col-sm-6">Master Company</label>
              <div className="col-md-8 col-sm-6">
                <MasterDropdown
                  isAll
                  notMultipleSelect2
                  masterType={MASTER_COMPANY}
                  value={criteria.MasterCompanyCode}
                  onChange={(e) => onChangeCriteria({ target: e })}
                  name="MasterCompanyCode"
                />
              </div>
            </div>
            <div className="form-group col-md-4">
              <label className="control-label col-md-4 col-sm-6">Member Company</label>
              <div className="col-md-8 col-sm-6">
                <MasterDropdown
                  isAll
                  notMultipleSelect2
                  masterType={MASTER_COMPANY}
                  value={criteria.MemberCompanyCode}
                  onChange={(e) => onChangeCriteria({ target: e })}
                  name="MemberCompanyCode"
                />
              </div>
            </div>
            <div className="form-group col-md-4">
              <label className="control-label col-md-4 col-sm-6">Status</label>
              <div className="col-md-8 col-sm-6">
                <select
                  name="IsActive"
                  value={criteria.IsActive}
                  onChange={onChangeCriteria}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value>Active</option>
                  <option value={false}>InActive</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-4">
              <label className="control-label col-md-4 col-sm-6">As Of</label>
              <div className="col-md-8 col-sm-6">
                <DatePicker
                  option={{ format: 'dd/mm/yyyy' }}
                  className="form-control"
                  name="AsOf"
                  value={criteria.AsOf}
                  onChange={onChangeCriteria}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <Button txt="Search" className="btn-warning" icon="search" type="submit" />
              &nbsp;
              <Button
                txt="new maintain"
                className="btn-success"
                icon="plus"
                onClick={onClickOpenModalCreate}
              />
            </div>
          </div>
        </form>
      </Card>
    );
  }

  function renderDataTable() {
    return (
      <Card textHeader="Search Results" number={2} cardActions={['toggler']} bgHeader="bg-primary">
        <DataTable
          ref={dataTableRef}
          id={dtId}
          className="table table-bordered table-nowrap dataTable"
          value={searchResults}
          option={opt}
        >
          <thead>
            <tr>
              <th>
                Active
                <br />
                /Inactive
              </th>
              <th>Edit</th>
              <th>Valid From</th>
              <th>Master Company</th>
              <th>Member Company</th>
              <th>Type</th>
              <th>Status</th>
              <th>Created By</th>
              <th>Created Date</th>
              <th>Updated By</th>
              <th>Updted Date</th>
            </tr>
          </thead>
        </DataTable>
      </Card>
    );
  }

  function renderModalMaintian() {
    const disabled = !!maintainData.Id;
    return (
      <Modal
        ref={modalMaintainRef}
        textHeader="Print Int. Receipt Type"
        bgHeader="bg-info"
        size="modal-md"
      >
        <form className="form-horizontal" onSubmit={onSubmitMaintainFrom}>
          <div className="form-group">
            <label className="control-label col-md-4">Valid From</label>
            <div className="col-md-8">
              <DatePicker
                required
                option={{ format: 'dd/mm/yyyy' }}
                className="form-control"
                name="ValidFromStr"
                value={maintainData.ValidFromStr}
                onChange={onChangeMaintainData}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-md-4">Master Company</label>
            <div className="col-md-8">
              <MasterDropdown
                disabled={disabled}
                required
                noValidateOption
                notMultipleSelect2
                masterType={MASTER_COMPANY}
                value={maintainData.MasterCompanyCode}
                onChange={(e) => onChangeMaintainData({ target: e })}
                name="MasterCompanyCode"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-md-4">Member Company</label>
            <div className="col-md-8">
              <MasterDropdown
                disabled={disabled}
                required
                noValidateOption
                notMultipleSelect2
                masterType={MASTER_COMPANY}
                value={maintainData.MemberCompanyCode}
                onChange={(e) => onChangeMaintainData({ target: e })}
                name="MemberCompanyCode"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-md-4">Type</label>
            <div className="col-md-8">
              <MasterDropdown
                required
                noValidateOption
                notMultipleSelect2
                masterType={MASTER_PRINT_INTEREST_RECEIPT_MAINTAIN}
                value={maintainData.MaintainId}
                onChange={(e) => onChangeMaintainData({ target: e })}
                name="MaintainId"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Save" icon="save" className="btn-success" type="submit" />
            </div>
          </div>
        </form>
      </Modal>
    );
  }
};

const opt = {
  columns: [
    {
      data: 'isActive',
      render: (d) =>
        `<button class="btn btn-sm ${d ? 'btn-warning' : 'btn-info'} status"><i class="icon icon-fw icon-lg ${d ? 'icon-close' : 'icon-refresh'}"></i></button>`,
      className: 'text-center',
    },
    {
      data: 'id',
      render: (d) =>
        `<button class="btn btn-sm btn-info edit"><i class="icon icon-fw icon-lg icon-edit"></i></button>`,
      className: 'text-center',
    },
    { data: 'validFromStr' },
    { data: 'masterCompanyName', render: (d, t, r) => `${r.masterCompanyCode} | ${d}` },
    { data: 'memberCompanyName', render: (d, t, r) => `${r.memberCompanyCode} | ${d}` },
    { data: 'type' },
    {
      data: 'isActive',
      render: (d) =>
        d
          ? '<span class="label label-success">Active</span>'
          : '<span class="label label-warning">InActive</span>',
      className: 'text-center',
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', type: 'date-black' },
  ],
};
