import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import Select2 from '../../../common/Select2';
import FormGroup2 from '../../../common/FormGroup2';
import { DATATABLE_ID } from './BankBranchContainer';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import { toStatus, getPermission } from '../../../common/helpper';
import { MASTER_COUNTRY, MAIN_MASTER_BANK } from '../../../reducers/master/action';

const permission = getPermission('Master Finance', 'Bank Branch');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  order: [[0, 'asc']],
  columns: [
    // {
    //     data: 'isActive', orderable: false, className: 'text-center',
    //     render: (d, t, r) => {
    //         if (!permission) return ''
    //         if (d) return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>'
    //         return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>'
    //     }
    // },
    // {
    //     data: 'isEdit', orderable: false, className: 'text-center',
    //     render: (d, t, r) => {
    //         if (!permission) return ''
    //         return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>'
    //     }
    // },
    { data: 'bankCode', className: 'text-center' },
    {
      data: 'branchCode',
      render: (d, t, r) =>
        `<a target="_blank" href="/master/bank-branch-detail/${r.bankCode}&${r.branchCode}&${r.country}">${d}</a>`,
    },
    { data: 'branchCodeTh', className: 'text-center' },
    { data: 'country', className: 'text-center' },
    { data: 'branchName' },
    { data: 'branchNameTh' },
    { data: 'houseBank' },
    {
      data: 'instanceCode',
      render: (d, t, r) => {
        if (!d) return '';
        return `${d} | ${r.instanceName}`;
      },
    },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

const instanceList = [
  { Instance: 'CEP', Text: 'CEP | SAP ECC CHEMICAL' },
  { Instance: 'DynamicAX', Text: 'DynamicAX | DynamicAX' },
  { Instance: 'FocusOne', Text: 'FocusOne | FocusOne' },
  { Instance: 'NetSuite', Text: 'NetSuite | NetSuite' },
  { Instance: 'NonSAP', Text: 'NonSAP | NonSAP' },
  { Instance: 'SAPHANA', Text: 'SAPHANA | SAPHANA' },
  { Instance: 'PD1', Text: 'PD1 | SAP 4.7' },
  { Instance: 'PEP', Text: 'PEP | SAP ECC PACKAGING' },
  { Instance: 'REP', Text: 'REP | SAP ECC CBMR' },
  { Instance: 'SAP Outside', Text: 'SAP Outside | SAP Outside' },
  { Instance: 'TEP', Text: 'TEP | SAP ECC CERAMICS' },
  { Instance: 'WINSpeed', Text: 'WINSpeed | WINSpeed' },
  { Instance: 'PRD', Text: 'PRD | HANA-NPI' },
  { Instance: 'TSP', Text: 'TSP | HANA-TMMA' },
];

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,

  onClickClearInstance,
}) => {
  const modeEdit = modalData.Mode === 'edit';
  const noEdit = !!((modalData.Mode === 'edit') & !modalData.IsActive);

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" /> Bank Branch
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Branch Code">
                <input
                  className="form-control"
                  value={criteria.SearchBranchCode}
                  onChange={onChangeSearch}
                  name="SearchBranchCode"
                />
              </FormGroup2>

              <FormGroup2 text="Branch Code TH">
                <input
                  className="form-control"
                  value={criteria.BranchCodeTh}
                  onChange={onChangeSearch}
                  name="BranchCodeTh"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Branch Name">
                <input
                  className="form-control"
                  value={criteria.BranchName}
                  onChange={onChangeSearch}
                  name="BranchName"
                />
              </FormGroup2>

              <FormGroup2 text="Bank Code">
                <MasterDropdown
                  masterType={MAIN_MASTER_BANK}
                  onChange={onChangeSelect2}
                  value={criteria.BankCode}
                  name="BankCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Country">
                <MasterDropdown
                  masterType={MASTER_COUNTRY}
                  onChange={onChangeSelect2}
                  value={criteria.Country}
                  name="Country"
                />
              </FormGroup2>

              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  name="Status"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Link
                    className="btn btn-warning btn-sm btn-labeled"
                    to="/master/bank-branch-detail/create"
                    target="_blank"
                  >
                    <span className="btn-label">
                      <i className="icon icon-plus-circle icon-fw icon-lg" />
                    </span>
                    CREATE BANK BRANCH
                  </Link>
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              {/* <th>Action</th>
                            <th>Edit</th> */}
              <th>Bank Code</th>
              <th>Branch Code</th>
              <th>
                Branch
                <br /> Code TH
              </th>
              <th>Country</th>
              <th>Branch Name</th>
              <th>Branch Name TH</th>
              <th>House Bank</th>
              <th>Instance</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal
        textHeader="Master Bank Branch"
        bgHeader="bg-primary"
        size="modal-lg"
        ref={modalDataRef}
      >
        <form onSubmit={(e) => onClickSaveModalData(e)}>
          <Col2 col={colX[0]}>
            <FormGroup2 text="Branch Code" required>
              <input
                className="form-control"
                type="text"
                onChange={onChangeModalData}
                required
                disabled={modeEdit}
                value={modalData.BranchCode || ''}
                name="BranchCode"
              />
            </FormGroup2>

            <FormGroup2 text="Branch Code TH" required>
              <input
                className="form-control"
                type="text"
                onChange={onChangeModalData}
                required
                disabled={noEdit}
                value={modalData.BranchCodeTh || ''}
                name="BranchCodeTh"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="Bank Code" required>
              {modeEdit ? (
                <MasterDropdown
                  masterType={MAIN_MASTER_BANK}
                  key="bankcode1"
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled
                  value={modalData.BankCode}
                  isMainMaster
                  name="BankCode"
                />
              ) : (
                <MasterDropdown
                  masterType={MAIN_MASTER_BANK}
                  key="bankcode2"
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={false}
                  value={modalData.BankCodeCountry}
                  customeValue={(m) => `${m.bankCode}|${m.country}`}
                  isMainMaster
                  name="BankCodeCountry"
                />
              )}
            </FormGroup2>

            <FormGroup2 text="Country" required>
              <MasterDropdown
                masterType={MASTER_COUNTRY}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeModalData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                required
                disabled
                value={modalData.Country}
                name="Country"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="Branch Name" required>
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeModalData}
                required
                disabled={noEdit}
                value={modalData.BranchName || ''}
                name="BranchName"
              />
            </FormGroup2>

            <FormGroup2 text="Branch Name TH" required={false}>
              <textarea
                className="form-control"
                rows="3"
                onChange={onChangeModalData}
                required={false}
                disabled={noEdit}
                value={modalData.BranchNameTh || ''}
                name="BranchNameTh"
              />
            </FormGroup2>
          </Col2>

          <Col2 col={colX[0]}>
            <FormGroup2 text="House Bank" required={false}>
              <textarea
                className="form-control"
                rows="2"
                onChange={onChangeModalData}
                required={false}
                disabled={noEdit}
                value={modalData.HouseBank || ''}
                name="HouseBank"
              />
            </FormGroup2>

            <FormGroup2 text="Instance Code" required={false}>
              <div className="row gutter-xs">
                <div className="col-xs-9">
                  <Select2
                    value={modalData.InstanceCode || ''}
                    notMultiple
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    disabled={noEdit}
                    isChoose
                    name="InstanceCode"
                    required={false}
                  >
                    {instanceList.map((m) => (
                      <option value={m.Instance} key={m.Instance}>
                        {m.Text}
                      </option>
                    ))}
                  </Select2>
                </div>
                <div className="col-xs-3">
                  <button className="btn btn-sm btn-clear" onClick={(e) => onClickClearInstance(e)}>
                    <i className="icon icon-eraser icon-fw icon-lg" />
                  </button>
                </div>
              </div>
            </FormGroup2>
          </Col2>

          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={noEdit}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
