import { connect } from 'react-redux';
import React, { Component } from 'react';

import PaymentMethod from './PaymentMethod';
import { addAlert } from '../../reducers/layout/action';
import { toLower, toUpperKey } from '../../common/helpper';
import {
  editCriteria,
  clearCriteria,
  saveMasterPaymentMethod,
  searchMasterPaymentMethod,
} from '../../reducers/masterPaymentMethod/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-PaymentMethod';

const initData = {
  MasterCompanyCode: '',
  MasterPaymentMethod: '',
  MasterIsAutoInterestIncome: '',
  MasterIsAutoInterestPayment: '',
  MemberCompanyCode: '',
  MemberPaymentMethod: '',
  MemberIsAutoInterestIncome: '',
  MemberIsAutoInterestPayment: '',
  PnOncallSweepTh: '',
  PnOncallSweepOv: '',
  PnTerm: '',
  PnOncallSweepThC: '',
  PnOncallSweepOvC: '',
  PnTermC: '',
  Action: 'create',
};

class PaymentMethodContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMasterPaymentMethod({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalData = (e) => {
    this.setState(
      {
        modalData: {
          ...initData,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          Action: 'edit',
          PnOncallSweepThC: toLower(rowData.pnOncallSweepTh) === 'y' ? true : '',
          PnOncallSweepOvC: toLower(rowData.pnOncallSweepOv) === 'y' ? true : '',
          PnTermC: toLower(rowData.pnTerm) === 'y' ? true : '',
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;

    if (name === 'MasterCompanyCode') {
      if (this.state.modalData.MemberCompanyCode === value) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Master Company must not equal Member Company.',
        });
        return;
      }
    }

    if (name === 'MemberCompanyCode') {
      if (this.state.modalData.MasterCompanyCode === value) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Member Company must not equal Master Company.',
        });
        return;
      }
    }

    if (
      ~[
        'MasterIsAutoInterestIncome',
        'MasterIsAutoInterestPayment',
        'MemberIsAutoInterestIncome',
        'MemberIsAutoInterestPayment',
        'PnOncallSweepThC',
        'PnOncallSweepOvC',
        'PnTermC',
      ].indexOf(name)
    ) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [name]: !this.state.modalData[name],
        },
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [name]: value,
        },
      });
    }
  };

  onClickSaveModalData = (e) => {
    e.preventDefault();

    const data = this.state.modalData;
    data.PnOncallSweepTh = data.PnOncallSweepThC ? 'Y' : 'N';
    data.PnOncallSweepOv = data.PnOncallSweepOvC ? 'Y' : 'N';
    data.PnTerm = data.PnTermC ? 'Y' : 'N';

    this.props.saveMasterPaymentMethod({ ...data }).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      this.props.searchMasterPaymentMethod({ ...this.props.criteria });
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        ...toUpperKey(rowData),
        Action: 'status',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    this.props.saveMasterPaymentMethod({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMasterPaymentMethod({ ...this.props.criteria });
    });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onChangeModalData: this.onChangeModalData,
      onClickSaveModalData: this.onClickSaveModalData,
      onClickOpenModalData: this.onClickOpenModalData,
    };

    return (
      <div>
        <PaymentMethod
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalDataRef={(e) => (this.modalDataRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterPaymentMethod,
  }),
  {
    searchMasterPaymentMethod,
    editCriteria,
    clearCriteria,
    addAlert,
    saveMasterPaymentMethod,
  }
)(PaymentMethodContainer);
