import { connect } from 'react-redux';
import React, { Component } from 'react';

import UserDetail from './UserDetail';
import { addAlert } from '../../reducers/layout/action';
import { toUpperKey, setCheckedCheckboxDataTableByInputElement } from '../../common/helpper';
import {
  updateCopyFrom,
  getUserCopyFrom,
  updateResultRole,
  approveMasterUser,
  saveMasterUserCopy,
  updateResultCompany,
  saveMasterUserDetail,
  getMasterUserDetailGroup,
  saveMasterUserDetailGroup,
  getMasterUserDetailGroupDetail,
} from '../../reducers/masterUser/action';

const $ = window.jQuery;
export const DATATABLE_ID_COMPANY = 'table-result-company-userdetail';
export const COL_CHECKBOX_CONTEXT_COMPANY = '.col-label-checkbox.result-company';
export const COL_CHOOSE_ALL_CONTEXT_COMPANY = '.label-checkbox.fix.choose-all.result-company';

export const DATATABLE_ID_ROLE = 'table-result-role-userdetail';
export const COL_CHECKBOX_CONTEXT_ROLE = '.col-label-checkbox.result-role';
export const COL_CHOOSE_ALL_CONTEXT_ROLE = '.label-checkbox.fix.choose-all.result-role';

export const DATATABLE_GROUP = 'table-result-group-authorize';

const initData = {
  Username: '',
  NameEn: '',
  SurnameEn: '',
  NameTh: '',
  SurnameTh: '',
  Email: '',
  CopyFrom: '',
  Approver1: '',
  ApproveStatus: '',
  Approver1Status: '',
  mode: 'create',
};

const initModal = {
  Id: '',
  Mode: 'change',
};

class UserDetailContainer extends Component {
  state = {
    ...initData,
    resultRole: [],
    resultCompany: [],
    modalData: {
      ...initModal,
    },
    modalCopy: {
      UsernameCopy: '',
    },
  };

  dataTableCompanyRef = null;

  dataTableRoleRef = null;

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState({
        mode: 'create',
      });
    else {
      this.fetchId(id.replace(/\./g, '_'));
    }

    $('body').on(
      'click',
      `td${COL_CHECKBOX_CONTEXT_COMPANY}`,
      this.onClickColumnLabelCheckboxCompany
    );
    $('body').on(
      'click',
      `th ${COL_CHOOSE_ALL_CONTEXT_COMPANY}`,
      this.onClickCheckboxSelecteAllCompany
    );
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT_ROLE}`, this.onClickColumnLabelCheckboxRole);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT_ROLE}`, this.onClickCheckboxSelecteAllRole);

    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
  }

  componentWillUnmount() {
    $('body').off(
      'click',
      `td${COL_CHECKBOX_CONTEXT_COMPANY}`,
      this.onClickColumnLabelCheckboxCompany
    );
    $('body').off(
      'click',
      `th ${COL_CHOOSE_ALL_CONTEXT_COMPANY}`,
      this.onClickCheckboxSelecteAllCompany
    );
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT_ROLE}`, this.onClickColumnLabelCheckboxRole);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT_ROLE}`, this.onClickCheckboxSelecteAllRole);

    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
  }

  fetchId(id) {
    if (!id) return;

    this.props.getMasterUserDetailGroup(id).then((response) => {
      if (response.error || !response.payload) return;

      // console.log(response.payload)
      const fetchData = toUpperKey(response.payload);
      this.setState({
        ...fetchData,
        mode: 'edit',
      });
    });
  }

  onClickCheckboxSelecteAllCompany = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateResultCompany('isActiveUserCompany', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckboxCompany = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableCompanyRef.$dataTable
    );
    this.props.resultCompany[result.row].isActiveUserCompany = result.checked;
    this.forceUpdate();
  };

  onClickCheckboxSelecteAllRole = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateResultRole('isActiveUserRole', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckboxRole = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(e, this.dataTableRoleRef.$dataTable);
    this.props.resultRole[result.row].isActiveUserRole = result.checked;
    this.forceUpdate();
  };

  onChangeInputData = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };
      return stateToUpdate;
    });
  };

  onSubmitHander = async (e) => {
    e.preventDefault();

    const submitData = {
      ...this.state,
      ResultCompany: this.props.resultCompany.filter((f) => f.isActiveUserCompany),
      ResultRole: this.props.resultRole.filter((f) => f.isActiveUserRole),
    };
    submitData.Mode = submitData.mode;
    delete submitData.mode;

    if (this.attachFileRef) {
      if (
        submitData.Mode === 'edit' &&
        !!submitData.ResultRole.find(
          (f) => f.roleName === 'Approver 1' || f.roleName === 'Approver 2'
        ) &&
        !this.attachFileRef.fileLength
      ) {
        this.props.addAlert({
          title: 'Error',
          type: 'error',
          body: 'Please select attach file.',
        });
        return;
      }

      const res = await this.attachFileRef.submit();
      if (res.error) {
        return;
      }
    }

    this.props.saveMasterUserDetail(submitData).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (this.state.mode === 'create') {
        window.location.href = `/master/user-detail/${response.payload}`;
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/master/user');
  };

  onChangeCopyFrom = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };
      return stateToUpdate;
    });

    this.props.getUserCopyFrom(value).then((response) => {
      if (response.error || !response.payload) return;

      const username = this.state.Username;
      const r = response.payload;

      const companys = r.resultCompany;
      companys.forEach((m) => {
        m.username = username;
      });

      const roles = r.resultRole;
      roles.forEach((m) => {
        m.username = username;
      });

      this.props.updateCopyFrom(companys, roles);
    });
  };

  onClickClearCopyFrom = (e) => {
    e.preventDefault();
    this.setState({
      ...this.state,
      CopyFrom: '',
    });

    const companys = this.props.resultCompany;
    companys.forEach((m) => {
      m.isActiveUserCompany = false;
    });

    const roles = this.props.resultRole;
    roles.forEach((m) => {
      m.isActiveUserRole = false;
    });
    this.props.updateCopyFrom(companys, roles);
  };

  onClickApprove = (approve) => {
    this.props
      .approveMasterUser({
        Username: this.state.Username,
        IsApprove: approve,
      })
      .then((res) => {
        if (res.error) return;

        window.location.reload();
      });
  };

  onClickOpenModalData = (e) => {
    this.setState(
      {
        modalData: {
          ...initData,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableGroupRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    window.location.href = `/master/user-detail-group/${rowData.username}&Id=${rowData.id}`;
    // this.props.getMasterUserDetailGroupDetail({
    //     Username: rowData.username,
    //     Id: rowData.id
    // }).then(res => {
    //     if (res.error) return
    //     console.log(res.payload)
    //     this.setState({
    //         modalData: {
    //             ...initModal,
    //             ...toUpperKey(res.payload),
    //             Mode: 'edit'
    //         }
    //     }, this.modalDataRef.open)
    // })
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;
    this.setState({
      modalData: {
        ...this.state.modalData,
        [name]: value,
      },
    });
  };

  onClickSaveModalData = (e) => {
    e.preventDefault();
    this.props.saveMasterBusinessUnit({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      this.props.searchMasterBusinessUnit({ ...this.props.criteria });
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $td = this.dataTableGroupRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState({
      modalData: {
        ...initModal,
        Id: rowData.id,
        Username: rowData.username,
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    this.props
      .saveMasterUserDetailGroup({
        Mode: this.state.modalData.Mode,
        Id: this.state.modalData.Id,
        Username: this.state.modalData.Username,
        ResultRole: [],
        ResultGroup: [],
        ResultCompany: [],
      })
      .then((response) => {
        if (response.error) return;

        this.modalActiveRef.close();
        this.modalInactiveRef.close();

        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The record was successfully saved',
        });

        this.fetchId(this.state.modalData.Username);
      });
  };

  onClickCopyUser = () => {
    this.setState(
      {
        modalCopy: {
          UsernameCopy: '',
        },
      },
      this.modalCopyRef.open
    );
  };

  onChangeInputDataModal = (e) => {
    const { name, value } = e.target;

    this.setState({
      ...this.state,
      modalCopy: {
        [name]: value,
      },
    });
  };

  onSubmitCopyUser = () => {
    const { id } = this.props.routeProp.match.params;
    this.props
      .saveMasterUserCopy({
        Username: id,
        UsernameCopy: this.state.modalCopy.UsernameCopy,
      })
      .then((response) => {
        if (response.error) return;
        this.modalCopyRef.close();
        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body: 'The record was successfully saved',
        });

        this.fetchId(id);
      });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,

      dataTableCompanyRef: (e) => (this.dataTableCompanyRef = e),
      dataTableRoleRef: (e) => (this.dataTableRoleRef = e),
      dataTableGroupRef: (e) => (this.dataTableGroupRef = e),

      onChangeCopyFrom: this.onChangeCopyFrom,
      onClickClearCopyFrom: this.onClickClearCopyFrom,

      onClickApprove: this.onClickApprove,

      attachFileRef: (e) => (this.attachFileRef = e),

      onChangeModalData: this.onChangeModalData,
      onClickSaveModalData: this.onClickSaveModalData,
      onClickOpenModalData: this.onClickOpenModalData,
      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,

      onClickCopy: this.onClickCopyUser,
      onChangeInputDataModal: this.onChangeInputDataModal,
      onSubmitCopyUser: this.onSubmitCopyUser,
    };

    return (
      <div>
        <UserDetail
          {...props}
          state={this.state}
          resultCompany={this.props.resultCompany}
          resultRole={this.props.resultRole}
          resultGroup={this.props.resultGroup}
          modalData={this.state.modalData}
          modalCopy={this.state.modalCopy}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalDataRef={(e) => (this.modalDataRef = e)}
          modalCopyRef={(e) => (this.modalCopyRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterUser,
  }),
  {
    getMasterUserDetailGroup,
    updateResultCompany,
    updateResultRole,
    saveMasterUserDetail,
    addAlert,
    getUserCopyFrom,
    updateCopyFrom,
    approveMasterUser,
    getMasterUserDetailGroupDetail,
    saveMasterUserDetailGroup,
    saveMasterUserCopy,
  }
)(UserDetailContainer);
