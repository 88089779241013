import React from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import FormGroup3 from '../../common/FormGroup3';
import DatePicker from '../../common/DatePicker';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_CURRENCY } from '../../reducers/master/action';
import MasterDropdownUI, { PAYMENT_METHOD_CASH_FLOW } from '../../common/MasterDropdownUI';

const colX = ['col-md-4', 'col-md-5'];

export default ({ modalRef, state, onSubmit, onChageModal, text }) => {
  const options =
    (state.Source || '') === 'Facility'
      ? ['Cancellation Fee']
      : ['Upfront Fee', 'Commitment Fee', 'Stamp Duty Fee', 'Delay Payment Fee', 'Prepayment Fee'];
  return (
    <Modal
      textHeader={text ? `Add Charge for ${text}` : 'Add Charge'}
      ref={modalRef}
      bgHeader="bg-primary"
    >
      <form onSubmit={onSubmit} className="form-horizontal">
        <FormGroup3 text="Payment Date" col={colX} required>
          <DatePicker
            value={state.PaymentDateStr}
            onChange={onChageModal}
            name="PaymentDateStr"
            required
            option={{
              // startDate: "d",
              // daysOfWeekDisabled: "0,6",
              todayHighlight: true,
            }}
          />
        </FormGroup3>

        <FormGroup3 text="Direction" col={colX} required>
          <MasterDropdownUI
            value={state.Direction}
            name="Direction"
            isChoose
            notMultipleSelect2
            onChange={(e) =>
              onChageModal({
                target: e,
              })
            }
            required
            disabled={false}
            options={['+', '-']}
          />
        </FormGroup3>

        <FormGroup3 text="Flow Type" col={colX} required>
          <MasterDropdownUI
            value={state.FlowType}
            name="FlowType"
            isChoose
            notMultipleSelect2
            onChange={(e) =>
              onChageModal({
                target: e,
              })
            }
            required
            disabled={false}
            options={options}
          />
        </FormGroup3>

        <FormGroup3 text="Amount" col={colX} required>
          <InputMask
            className="form-control"
            format="currency"
            option={
              state.inputMaskAmount || {
                prefix: '',
                digits: 2,
                // digitsOptional: false,
                // placeholder: '0.000'
              }
            }
            onChange={onChageModal}
            value={state.Amount}
            required
            name="Amount"
          />
        </FormGroup3>

        <FormGroup3 text="Currency" col={colX} required>
          <MasterDropdown
            masterType={MASTER_CURRENCY}
            isChoose
            notMultipleSelect2
            onChange={(e) =>
              onChageModal({
                target: e,
              })
            }
            value={state.Currency}
            name="Currency"
            required
            disabled={false}
          />
        </FormGroup3>

        <FormGroup3 text="Payment Method" col={colX} required>
          <MasterDropdownUI
            masterType={PAYMENT_METHOD_CASH_FLOW}
            value={state.PaymentMethod}
            name="PaymentMethod"
            isChoose
            notMultipleSelect2
            onChange={(e) =>
              onChageModal({
                target: e,
              })
            }
            required
            disabled={false}
          />
        </FormGroup3>
        <br />
        <div>
          <center>
            <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
          </center>
        </div>
      </form>
    </Modal>
  );
};
