import { defaultImportExcelResult } from '../configuration';
import {
  FAIL_SUBMIT_IMPORT_INWARD_EXCEL,
  EDIT_EXPOSURE_POSITION_CRITERIA,
  RECEIVE_SEARCH_EXPOSURE_POSITION,
  RECEIVE_VIEW_IMPORT_INWARD_EXCEL,
  CLEAR_EXPOSURE_POSITION_CRITERIA,
  RECEIVE_SUBMIT_IMPORT_INWARD_EXCEL,
  FAIL_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE,
  UPDATE_SEARCH_EXPOSURE_PORISION_RESULT,
  RECEIVE_UPLOAD_VIEW_QUOTE_RATE_EXPOSURE,
  RECEIVE_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE,
  UPDATE_EXPOSUREID_FOR_EXPOSURE_FORWARD_RATE,
} from './action';

const initCriteria = {
  FinanceGroup: '',
  BusinessUnits: [''],
  Companies: [''],
  ValueDateStr: '',
  Currencies: [''],
  Banks: [''],
  CashTypes: [''],
  TransactionTypes: [''],
  Status: '',
  SourceType: '',
  InformDateStr: '',
  ExposureType: [''],
  QuoteRateMethods: '',
  PaymentType: [''],
};
const init = {
  searchResult: [],
  importExcelQuoteRate: defaultImportExcelResult,
  importExcelInward: defaultImportExcelResult,
  exposureIdForwardRate: [],
  criteria: {
    ...initCriteria,
  },
};

export default (state = init, action) => {
  switch (action.type) {
    case CLEAR_EXPOSURE_POSITION_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_EXPOSURE_POSITION_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case FAIL_SUBMIT_IMPORT_INWARD_EXCEL:
    case RECEIVE_SUBMIT_IMPORT_INWARD_EXCEL:
      return {
        ...state,
        importExcelInward: defaultImportExcelResult,
      };
    case RECEIVE_VIEW_IMPORT_INWARD_EXCEL:
      return {
        ...state,
        importExcelInward: action.payload,
      };
    case FAIL_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE:
    case RECEIVE_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE:
      return {
        ...state,
        importExcelQuoteRate: defaultImportExcelResult,
      };
    case RECEIVE_UPLOAD_VIEW_QUOTE_RATE_EXPOSURE:
      return {
        ...state,
        importExcelQuoteRate: action.payload,
      };
    case UPDATE_EXPOSUREID_FOR_EXPOSURE_FORWARD_RATE:
      return {
        ...state,
        exposureIdForwardRate: action.payload,
      };
    case UPDATE_SEARCH_EXPOSURE_PORISION_RESULT: {
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) {
              return m;
            }
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    }
    case RECEIVE_SEARCH_EXPOSURE_POSITION:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
