import React from 'react';

export default ({ type = 'button', className, icon, txt, href, disabled }) => {
  const text = ` ${txt} `;
  if (icon == null) {
    return (
      <a className={className} href={href} disabled={disabled}>
        {text}
      </a>
    );
  }
  return (
    <a
      type={`${type}`}
      className={`btn btn-labeled btn-sm ${className}`}
      href={href}
      disabled={disabled}
    >
      <span className="btn-label">
        <i className={`icon icon-${icon} icon-fw icon-lg`} />
      </span>{' '}
      {txt}
    </a>
  );
};
