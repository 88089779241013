import { defaultImportExcelResult } from '../configuration';
import {
  EDIT_SWEEP_PN_CRITERIA,
  RECEIVE_SEARCH_SWEEP_PN,
  CLEAR_SWEEP_PN_CRITERIA,
  UPDATE_SEARCH_RESULT_SWEEP_PN,
  RECEIVE_SAVE_SWEEP_PN_IMPORT_EXCEL,
  RECEIVE_SAVE_SWEEP_INDO_IMPORT_EXCEL,
  RECEIVE_VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN,
  RECEIVE_VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO,
} from './action';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  BusinessPartner: '',
  ReportingDateStr: '',
};

const init = {
  searchResult: [],
  citiResult: [],
  criteria: { ...initCriteria },
  importExcel: defaultImportExcelResult,
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_SAVE_SWEEP_PN_IMPORT_EXCEL:
      return {
        ...state,
        importExcel: {
          ...defaultImportExcelResult,
        },
      };
    case RECEIVE_SAVE_SWEEP_INDO_IMPORT_EXCEL:
      return {
        ...state,
        importExcelIndo: {
          ...defaultImportExcelResult,
        },
      };
    case RECEIVE_VIEW_IMPORT_EXCEL_REPORT_SWEEP_PN:
      return {
        ...state,
        importExcel: {
          ...action.payload,
        },
      };
    case RECEIVE_VIEW_IMPORT_EXCEL_REPORT_SWEEP_INDO:
      return {
        ...state,
        importExcelIndo: {
          ...action.payload,
        },
      };
    case RECEIVE_SEARCH_SWEEP_PN:
      return {
        ...state,
        searchResult: action.payload.sweepPns,
        citiResult: action.payload.sweepCitis,
      };
    case CLEAR_SWEEP_PN_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_SWEEP_PN_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case UPDATE_SEARCH_RESULT_SWEEP_PN:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => m),
      };
    default:
      return state;
  }
};
