import React from 'react';
import { Link } from 'react-router-dom';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import { DATATABLE_ID } from './ManagementContainer';
import MasterDropdown from '../../common/MasterDropdown';
import ModalFunction2 from '../../common/ModalFunction2';
import DateRangePicker from '../../common/DateRangePicker';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import {
  toLower,
  toNumber,
  toStatus,
  toStatusApprove,
  getNewPermission,
} from '../../common/helpper';
import {
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MAIN_MASTER_FINANCE_GROUP,
  MAIN_MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

// let permission = getPermissionStr('T/T Remittance', 'Request') === 'View' ? false : true
const newPermission = getNewPermission('T/T Remittance', 'Request');
const permission = true;

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[1, 'desc']],
  fixedColumns: {
    leftColumns: 2,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    // {
    //     orderable: false,
    //     colReorder: false,
    //     data: 'IsSelected',
    //     className: `text-center col-label-checkbox`,
    //     render: (d, t, r) => {
    //         // if (toLower(r.statusTt) === 'rejected') {
    //         //     r.canSelect = false;
    //         //     return ''
    //         // }
    //         r.canSelect = true;
    //         return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`
    //     },
    // },
    {
      orderable: false,
      data: 'IsAction',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (
          ~[
            'wait for accounting verifying',
            'wait for accounting approval',
            'wait for financial approval',
            'completed',
          ].indexOf(toLower(r.statusTt))
        )
          return '<button class="btn btn-icon btn-primary action-duplicate"><span class="icon icon-copy sq-24"></span></button>';
        return '';
      },
    },
    {
      data: 'transactionNo',
      render: (d) => `<a target="_blank" href="/tt-remittance/create-tt/${d}"> ${d} </a>`,
    },
    {
      data: 'forwardContractNo',
      // render: (d, t, r) => {
      //     if (t === 'export' || !d) return d

      //     let s = d.split(',')
      //     let html = ''

      //     s.forEach(item => {
      //         let ss = item.split('|')
      //         if (html)
      //             html += `<br/>`
      //         html += `${ss[0].trim()} | ${toNumber(Number(ss[1].trim() || 0), r.currencyDigit)}`
      //     });
      //     return html
      // }
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'valueDateStr', className: 'text-center', type: 'date-black' },
    { data: 'refTransactionNo' },
    { data: 'paymentBy' },
    { data: 'invoiceNo' },
    {
      data: 'netPaidAmount',
      className: 'text-right',
      render: (d, t, r) => toNumber(d, r.currencyDigit),
    },
    { data: 'currency', className: 'text-center' },
    { data: 'remittedToCurrency', className: 'text-center' },
    { data: 'preAdvice' },
    { data: 'paidFor' },
    { data: 'purposeDetail' },
    { data: 'requestorDepartment' },
    { data: 'beneficiaryCode' },
    { data: 'beneficiaryName' },
    { data: 'beneficiaryCountry', className: 'text-center' },
    { data: 'accountNo' },
    { data: 'ibanNo' },
    { data: 'abaNo' },
    { data: 'bsbNo' },
    { data: 'ifscCode' },
    { data: 'beneficiaryBankSwiftCode', className: 'text-center' },
    { data: 'beneficiaryBankName' },
    { data: 'beneficiaryBankCountry', className: 'text-center' },
    { data: 'poNo' },
    { data: 'specialMessage' },
    {
      data: 'attachFile',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (!r.refTransactionNo) return '';
        return '<button class="btn btn-icon btn-info attach-file-result"><span class="icon icon-cloud-upload sq-24"></span></button>';
      },
    },
    {
      data: 'countFile',
      className: 'text-center',
      render: (d) => {
        if (!d) return '';
        return toStatus('Completed');
      },
    },
    {
      data: 'statusTt',
      className: 'text-center',
      render: (d) => {
        if (toLower(d).includes('wait')) {
          if (toLower(d) === 'wait for initialization')
            return `<span class="badge badge-info" style="background-color:#2980B9">${d}</span>`;
          if (toLower(d) === 'wait for approval')
            return `<span class="badge badge-info" style="background-color:#3498DB">${d}</span>`;
          if (toLower(d) === 'wait for accounting verifying')
            return `<span class="badge badge-info" style="background-color:#16A085">${d}</span>`;
          if (toLower(d) === 'wait for accounting approval')
            return `<span class="badge badge-info" style="background-color:#1ABC9C">${d}</span>`;
        }
        return toStatus(d, true);
      },
    },
    { data: 'createdDateStr', className: 'text-center', type: 'date-time' },
    { data: 'createdBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-time' },
    { data: 'updatedBy' },
    { data: 'initialName' },
    {
      data: 'initialStatus',
      render: (d, t, r) => toStatusApprove(r.initial, d, true),
    },
    { data: 'initialDateStr', className: 'text-center', type: 'date-time' },
    { data: 'doaName' },
    { data: 'doaStatus', render: (d, t, r) => toStatusApprove(r.doa, d, true) },
    { data: 'doaDateStr', className: 'text-center', type: 'date-time' },
    { data: 'approverName' },
    {
      data: 'approverStatus',
      render: (d, t, r) => toStatusApprove(r.approver, d, true),
    },
    { data: 'approverDateStr', className: 'text-center', type: 'date-time' },
    { data: 'accountVerifierName' },
    {
      data: 'accountVerifierStatus',
      render: (d, t, r) => toStatusApprove(r.accountVerifier, d, true),
    },
    {
      data: 'accountVerifierDateStr',
      className: 'text-center',
      type: 'date-time',
    },
    { data: 'accountApproverName' },
    {
      data: 'accountApproverStatus',
      render: (d, t, r) => toStatusApprove(r.accountApprover, d, true),
    },
    {
      data: 'accountApproverDateStr',
      className: 'text-center',
      type: 'date-time',
    },
  ],
};

export default ({
  criteria,
  onChangeSelect2,
  onChangeSearch,
  onClickSearch,
  onClickClearSearch,

  results,
  dataTableRef,

  modalFunction,
  modalFunctionRef,
  onClickOpenModalFunction,
  onSubmitModalChangeStatus,

  modalDuplicateRef,
  modalDuplicate,
  duplicateTransaction,
  onClickConfirmDuplicate,
  onChangeInputData,

  attachFiles,
  modalAttachFileRef,
  onClickDownloadFile,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            T/T Remittance <span className="icon icon-angle-double-right" /> Request
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group">
                <MasterDropdown
                  masterType={MAIN_MASTER_FINANCE_GROUP}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  status
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MAIN_MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnits}
                  status
                  name="BusinessUnits"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companies}
                  saveLocalState
                  financeGroupId={criteria.FinanceGroups}
                  businessUnitId={criteria.BusinessUnits}
                  isGetAll
                  name="Companies"
                />
              </FormGroup2>

              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  value={criteria.Currencies}
                  name="Currencies"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Pre Advice">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.PreAdvice}
                  name="PreAdvice"
                  options={['None', '1 Day', '2 Days before Value Date']}
                />
              </FormGroup2>

              <FormGroup2 text="Value Date">
                <DateRangePicker
                  onChange={onChangeSearch}
                  value={criteria.ValueDateStr}
                  name="ValueDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="T/T Status">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.Status}
                  name="Status"
                  options={[
                    'Created',
                    'Wait for creator editing',
                    'Wait for initialization',
                    'Wait for DOA approval',
                    'Wait for approval',
                    'Wait for accounting verifying',
                    'Wait for accounting approval',
                    'Wait for financial approval',
                    'Rejected',
                  ]}
                />
              </FormGroup2>

              <FormGroup2 text="Equivalent">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.Equivalent}
                  name="Equivalent"
                  options={['Yes', 'No']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Paid For">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.PaidFor}
                  name="PaidFor"
                  // options={["Service", "Goods", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection", "Other"]} />
                  options={[
                    'Service',
                    'Goods',
                    'Project',
                    'ST Loan',
                    'LT Loan & Swap',
                    'Capital Injection',
                    'Dividend',
                    'Other',
                  ]}
                />
              </FormGroup2>

              <FormGroup2 text="PO No.">
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeSearch}
                  value={criteria.PoNo}
                  name="PoNo"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Invoice No.">
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeSearch}
                  value={criteria.InvoiceNo}
                  name="InvoiceNo"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {/* {
                                permission ? ( <> */}
              <Link
                className="btn btn-warning btn-sm btn-labeled"
                to="/tt-remittance/create-tt/create"
                target="_blank"
              >
                <span className="btn-label">
                  <i className="icon icon-plus-circle icon-fw icon-lg" />
                </span>
                CREATE
              </Link>
              &nbsp;
              {/* </> ) : null
                            } */}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {/* {
                    permission ? (
                        <Col2 col={colX[2]}>
                            <div>
                                <Button txt="APPROVE" icon="check" className="btn-success"
                                    onClick={e => onClickOpenModalFunction("approve")}
                                />
                                &nbsp;
                                <Button txt="REJECT" icon="close" className="btn-danger"
                                    onClick={e => onClickOpenModalFunction("reject")}
                                />
                                &nbsp;
                                <Button txt="DISAPPROVE" icon="refresh" className="btn-warning"
                                    onClick={e => onClickOpenModalFunction("disapprove")}
                                />
                            </div>
                        </Col2>
                    ) : null
                } */}
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              {/* <th>Choose <label className="label-checkbox fix choose-all" ></label></th> */}
              <th>Action</th>
              <th>Transaction No.</th>
              <th>
                Forward Contract No.
                <br /> (Internal)
              </th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br /> Code
              </th>
              <th>Company Name</th>
              <th>Value Date</th>
              <th>T/T Transaction No.</th>
              <th>Payment By</th>
              <th>Invoice No.</th>
              <th>Net Paid Amount</th>
              <th>Currency</th>
              <th>
                Remitted To
                <br /> Currency
              </th>
              <th>Pre-Advice</th>
              <th>Paid For</th>
              <th>Purpose Detail</th>
              <th>Department</th>
              <th>Beneficiary Code</th>
              <th>Beneficiary Name</th>
              <th>
                Beneficiary
                <br /> Country
              </th>
              <th>Account No.</th>
              <th>IBAN No.</th>
              <th>ABA No.</th>
              <th>BSB No.</th>
              <th>IFSC Code</th>
              <th>
                Beneficiary's
                <br /> SWIFT Code
              </th>
              <th>Beneficiary's Bank Name</th>
              <th>
                Beneficiary's
                <br /> Bank Country
              </th>
              <th>PO No.</th>
              <th>Special Message</th>
              <th>
                Debit
                <br />
                Advice
              </th>
              <th>
                Receipt
                <br />
                Status (TT)
              </th>
              <th>Status</th>
              <th>
                Created
                <br />
                Date
              </th>
              <th>
                Created
                <br />
                By
              </th>
              <th>
                Updated
                <br />
                Date
              </th>
              <th>
                Updated
                <br />
                By
              </th>
              <th>
                Initial
                <br /> Name
              </th>
              <th>
                Initial
                <br /> Status
              </th>
              <th>
                Initial
                <br /> Date
              </th>
              <th>
                DOA
                <br /> Name
              </th>
              <th>
                DOA
                <br /> Status
              </th>
              <th>
                DOA
                <br /> Date
              </th>
              <th>
                Approver
                <br /> Name
              </th>
              <th>
                Approver
                <br /> Status
              </th>
              <th>
                Approver
                <br /> Date
              </th>
              <th>
                Account Verify
                <br /> Name
              </th>
              <th>
                Account Verify
                <br /> Status
              </th>
              <th>
                Account Verify
                <br /> Date
              </th>
              <th>
                Account Approver
                <br /> Name
              </th>
              <th>
                Account Approver
                <br /> Status
              </th>
              <th>
                Account Approver
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <ModalFunction2
        textHeader={modalFunction.textHeader}
        textContent={modalFunction.textContent}
        modalRef={modalFunctionRef}
        type={modalFunction.type}
        icon={modalFunction.icon}
        textBtn={modalFunction.textBtn}
        textBtnBack={modalFunction.textBtnBack}
        noSelect={false}
        showFail={modalFunction.showFail}
        selectedLength={selectedResultCount}
        passLength={modalFunction.passLength}
        result={modalFunction.resultFail}
        onClick={(e) => onSubmitModalChangeStatus(e)}
      />

      <Modal size="modal-md" ref={modalDuplicateRef} modalFooter>
        <form onSubmit={onClickConfirmDuplicate} className="form-horizontal">
          <div className="text-center">
            <span className="text-primary icon icon-copy icon-5x" />
            <h3 className="text-primary">Duplicate</h3>
            <p>Do you want to duplicate beneficiary information of {duplicateTransaction}?</p>
          </div>
          <br />

          <div className="form-group">
            <label className="control-label col-md-4">
              Company<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-7">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                isChoose
                notMultipleSelect2
                value={modalDuplicate.CompanyCode}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                noValidateOption
                saveLocalState
                required
                isGetAll
                name="CompanyCode"
              />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-4">
              Value Date<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-7">
              <DatePicker
                onChange={onChangeInputData}
                name="ValueDateStr"
                value={modalDuplicate.ValueDateStr}
                option={{
                  // startDate: "d",
                  // daysOfWeekDisabled: "0,6",
                  todayHighlight: true,
                }}
                required
              />
            </div>
          </div>

          <div className="text-center">
            <div className="m-t-lg">
              <Button txt="Yes" className="btn btn-primary" type="submit" />
              &nbsp;
              <Button txt="No" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal ref={modalAttachFileRef} textHeader="Debit Advice" bgHeader="bg-info" size="modal-xl">
        <div className="table-responsive">
          <table className="table table-bordered table-nowarp">
            <thead>
              <tr>
                <th>Download</th>
                <th>File Name</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              {attachFiles.length ? (
                attachFiles.map((m, i) => (
                  <tr key={i}>
                    <td className="text-center">
                      <button
                        onClick={(e) => onClickDownloadFile(e, m.id)}
                        className="btn btn-info btn-sm"
                      >
                        <i className="icon icon-download icon-lg icon-fw" />
                      </button>
                    </td>
                    <td>{m.fileName}</td>
                    <td>{m.remark}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="text-center" colSpan={3}>
                    No data available in table
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};
