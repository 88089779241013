import { connect } from 'react-redux';
import React, { Component } from 'react';

import SweepPN from './SweepPN';
import { toFixed } from '../../../common/helpper';
import ImportExcelModal from '../../../common/ImportExcel';
import {
  searchSweep,
  editCriteria,
  clearCriteria,
  viewImportExcel,
  saveImportExcel,
  updateSearchResult,
  viewImportExcelIndo,
  saveImportExcelIndo,
  downloadSweepReport,
  downloadSweepReportExcel,
} from '../../../reducers/reportSweepPn/action';

export const DATATABLE_ID = 'table-result-sweep-pn';

class SweepPNContainer extends Component {
  dataTableSearchResultRef = null;

  state = {
    datas: [],
    datasCiti: [],
  };

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchSweep({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  onClickUploadExcel = () => {
    this.importExcel.open();
  };

  onClickUploadExcelIndo = () => {
    this.importExcelIndo.open();
  };

  onClickSubmitViewImportExcel = (file) => {
    const form = new FormData();
    form.append('file', file);
    this.props.viewImportExcel(form);
  };

  onClickSubmitViewImportExcelIndo = (file) => {
    const form = new FormData();
    form.append('file', file);
    this.props.viewImportExcelIndo(form);
  };

  onClickSubmitSaveImportExcel = () => {
    const data = this.props.importExcel;
    let error = '';
    if (data.hasError) error = 'There are error in this result.';
    else if (!data.rows.length) error = 'There no data for save';

    if (error) {
      alert(error);
      return;
    }

    this.props.saveImportExcel(data.rows).then((response) => {
      if (response.error) return;
      this.importExcel.close();
    });
  };

  onClickSubmitSaveImportExcelIndo = () => {
    const data = this.props.importExcelIndo;
    let error = '';
    if (data.hasError) error = 'There are error in this result.';
    else if (!data.rows.length) error = 'There no data for save';

    if (error) {
      alert(error);
      return;
    }

    this.props.saveImportExcelIndo(data.rows).then((response) => {
      if (response.error) return;
      this.importExcelIndo.close();
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.searchResult !== this.props.searchResult) {
      this.mapData(this.props.searchResult);
    }

    if (prevProps.citiResult !== this.props.citiResult) {
      this.mapDataCiti(this.props.citiResult);
    }
  }

  mapData(datas = []) {
    const groups = [];
    const { moment } = window;
    datas.forEach((m) => {
      const {
        companyCode,
        companyName,
        partnerCode,
        partnerCompanyName,
        businessUnit,
        valueDateStr,
        bankAbbreviate,
        accountNo,
        partnerAccountNo,
      } = m;
      const findGroup = groups.find(
        (f) =>
          f.companyCode === companyCode &&
          f.partnerCode === partnerCode &&
          f.businessUnit === businessUnit
      );
      if (!findGroup)
        groups.push({
          companyName,
          partnerCompanyName,
          companyCode,
          partnerCode,
          businessUnit,
          reportDateFromStr: valueDateStr,
          reportDateToStr: valueDateStr,
          items: [m],
          banks: [
            {
              // use BankAbbreviate  only เพราะ BankAbbreviate กับ partner BankAbbreviate จะเหมือนกันเสมอ
              bankAbbreviate,
              accountNo,
              partnerAccountNo,
            },
          ],
        });
      else {
        findGroup.items.push(m);
        const valueDate = moment(valueDateStr, 'DD/MM/YYYY');
        if (valueDate.isBefore(moment(findGroup.reportDateFromStr, 'DD/MM/YYYY'), 'days'))
          findGroup.reportDateFromStr = valueDateStr;
        else if (valueDate.isAfter(moment(findGroup.reportDateToStr, 'DD/MM/YYYY'), 'days'))
          findGroup.reportDateToStr = valueDateStr;

        // find pair bank
        // always have 2 bank or once
        // if (findGroup.banks.length < 2) {
        const findBanks = findGroup.banks.find(
          (f) =>
            f.bankAbbreviate === bankAbbreviate &&
            f.accountNo === accountNo &&
            f.partnerAccountNo === partnerAccountNo
        );

        if (!findBanks)
          findGroup.banks.push({
            bankAbbreviate,
            accountNo,
            partnerAccountNo,
          });
        // }
      }
    });

    // group row
    groups.forEach((m) => {
      const { items } = m;
      const rows = [];

      items.forEach((r) => {
        const findRow = rows.find(
          (f) => f.loanType === r.loanType && f.valueDateStr === r.valueDateStr
        );
        if (!findRow) {
          rows.push({
            companyName: r.companyCode,
            partnerCompanyName: r.partnerCode,
            loanType: r.loanType,
            valueDateStr: r.valueDateStr,
            cumulativeOutstand: r.sumAmount,
            oldPn: r.oldPn,
            newPn: r.newPn,
            remark: r.remark,
            diffAmount: r.diffAmount,
            isBeginning: r.isBeginning,
            interest: r.interest,
            bankData: [
              {
                eodAmount: r.eodAmount,
                adjAmount: r.adjAmount,
                sumAmount: r.sumAmount,
                bankAbbreviate: r.bankAbbreviate,
              },
            ],
          });
        } else {
          // if (findRow.bankData.length >= 2)
          //     return
          // check same bankData
          if (findRow.bankData[0].bankAbbreviate === r.bankAbbreviate) return;

          findRow.cumulativeOutstand += r.sumAmount;
          findRow.bankData.push({
            eodAmount: r.eodAmount,
            adjAmount: r.adjAmount,
            sumAmount: r.sumAmount,
            bankAbbreviate: r.bankAbbreviate,
          });
        }
      });
      m.rows = rows;
    });
    this.setState({
      datas: groups,
    });
  }

  mapDataCiti(datas = []) {
    const groups = [];
    const { moment } = window;
    datas.forEach((m) => {
      const {
        childAccountNo,
        childCompanyCode,
        childCompanyName,
        masterAccountNo,
        masterCompanyCode,
        masterCompanyName,
        valueDateStr,
        businessUnit,
        bankAbbreviate = 'CITI',
      } = m;
      const findGroup = groups.find(
        (f) =>
          f.masterCompanyCode === masterCompanyCode &&
          f.childCompanyCode === childCompanyCode &&
          f.businessUnit === businessUnit
      );
      if (!findGroup)
        groups.push({
          masterCompanyName,
          childCompanyName,
          masterCompanyCode,
          childCompanyCode,
          businessUnit,
          reportDateFromStr: valueDateStr,
          reportDateToStr: valueDateStr,
          items: [m],
          banks: [
            {
              bankAbbreviate,
              masterAccountNo,
              childAccountNo,
            },
          ],
        });
      else {
        findGroup.items.push(m);
        const valueDate = moment(valueDateStr, 'DD/MM/YYYY');
        if (valueDate.isBefore(moment(findGroup.reportDateFromStr, 'DD/MM/YYYY'), 'days'))
          findGroup.reportDateFromStr = valueDateStr;
        else if (valueDate.isAfter(moment(findGroup.reportDateToStr, 'DD/MM/YYYY'), 'days'))
          findGroup.reportDateToStr = valueDateStr;

        // find pair bank
        // always have 2 bank or once
        if (findGroup.banks.length < 2) {
          const findBanks = findGroup.banks.find(
            (f) =>
              f.bankAbbreviate === bankAbbreviate &&
              f.masterAccountNo === masterAccountNo &&
              f.childAccountNo === childAccountNo
          );

          if (!findBanks)
            findGroup.banks.push({
              bankAbbreviate,
              masterAccountNo,
              childAccountNo,
            });
        }
      }
    });
    groups.forEach((m) => {
      const { items } = m;
      const rows = [];

      items.forEach((r) => {
        const findRow = rows.find((f) => f.valueDateStr === r.valueDateStr);
        if (!findRow) {
          rows.push({
            masterAccountNo: r.masterAccountNo,
            masterCompanyCode: r.masterCompanyCode,
            childAccountNo: r.childAccountNo,
            childCompanyCode: r.childCompanyCode,
            valueDateStr: r.valueDateStr,
            transactionType: r.transactionType,
            currency: r.currency,
            inward: r.inward,
            outward: r.outward,
            balance: r.balance,
            interestIncome: r.interestIncome,
            interestLoan: r.interestLoan,
            days: r.days,
            interestByContract: r.interestByContract,
            netInterest: r.netInterest,
            wht: r.wht,
            oldPn: r.oldPn,
            newPn: r.newPn,
          });
        }
      });
      m.rows = rows;
    });

    this.setState({
      datasCiti: groups,
    });
  }

  onClickDownloadSweep = (type) => {
    if (type === 'pdf') this.props.downloadSweepReport(this.props.criteria);
    else this.props.downloadSweepReportExcel(this.props.criteria);
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      onClickUploadExcel: this.onClickUploadExcel,
      onClickUploadExcelIndo: this.onClickUploadExcelIndo,
      onClickDownloadSweep: this.onClickDownloadSweep,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
    };
    return (
      <>
        <SweepPN {...props} {...this.state} criteria={this.props.criteria} />
        <ImportExcelModal
          ref={(e) => (this.importExcel = e)}
          value={this.props.importExcel}
          optionDt={opt}
          onUpload={this.onClickSubmitViewImportExcel}
          onClickSaveImport={this.onClickSubmitSaveImportExcel}
          tableName="sweep-pn-import-excel"
        >
          <thead>
            <tr>
              <th>Account No.</th>
              <th>Member</th>
              <th>Account No.</th>
              <th>Master</th>
              <th>Currency</th>
              <th />
              <th>Value Date</th>
              <th>Inward</th>
              <th>Outward</th>
              <th>Balance</th>
              <th>Interest Income</th>
              <th>Interest Loan</th>
              <th>Days</th>
              <th>Interest By Contract</th>
              <th>Net Interest</th>
              <th>Wht</th>
              <th>Old PN</th>
              <th>New PN</th>
            </tr>
          </thead>
        </ImportExcelModal>
        <ImportExcelModal
          ref={(e) => (this.importExcelIndo = e)}
          value={this.props.importExcelIndo}
          optionDt={optIndo}
          onUpload={this.onClickSubmitViewImportExcelIndo}
          onClickSaveImport={this.onClickSubmitSaveImportExcelIndo}
          tableName="sweep-indo-import-excel"
        >
          <thead>
            <tr>
              <th>Value Date</th>
              <th>Sub Account number</th>
              <th>Sub Account Name</th>
              <th>Sweep Amount</th>
              <th>Closing Intercompany Position in Base Currency</th>
              <th>Currency</th>
              <th>Counter Party Account number</th>
              <th>Counter Party Account Name</th>
            </tr>
          </thead>
        </ImportExcelModal>
      </>
    );
  }
}

export default connect(
  (state) => ({
    ...state.sweeppn,
  }),
  {
    searchSweep,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    viewImportExcel,
    saveImportExcel,
    viewImportExcelIndo,
    saveImportExcelIndo,
    downloadSweepReport,
    downloadSweepReportExcel,
  }
)(SweepPNContainer);

const opt = {
  columns: [
    { data: 'bankAccountMember' },
    { data: 'member' },
    { data: 'bankAccountMaster' },
    { data: 'master' },
    { data: 'currency' },
    { data: 'currencyDesc' },
    { data: 'valueDateStr' },
    { data: 'inward' },
    { data: 'outward' },
    { data: 'balance' },
    { data: 'interestIncome' },
    { data: 'interestLoan' },
    { data: 'day' },
    { data: 'interestByConract' },
    { data: 'netInterest' },
    { data: 'wht' },
    {
      data: null,
      render: (d, t, r) => r.oldPn || '',
    },
    {
      data: null,
      render: (d, t, r) => r.newPn || '',
    },
  ],
};

const optIndo = {
  columns: [
    { data: 'valueDateToString', className: 'text-center' },
    { data: 'childAccountNo', className: 'text-center' },
    { data: 'childAccountName' },
    {
      data: 'endSweepAmount',
      className: 'text-right',
      render: (d, t, r) => toFixed(r.endSweepAmount, 2),
    },
    {
      data: 'sweepAccuBalance',
      className: 'text-right',
      render: (d, t, r) => toFixed(r.sweepAccuBalance, 2),
    },
    { data: 'currency', className: 'text-center', render: (d, t, r) => 'IDR' },
    { data: 'masterAccountNo', className: 'text-center' },
    { data: 'masterAccountName' },
  ],
};
