import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_SECURITIES = '@@request/SEARCH_SECURITIES';
export const RECEIVE_SEARCH_SECURITIES = '@@receive/SEARCH_SECURITIES';
export const FAIL_SEARCH_SECURITIES = '@@fail/SEARCH_SECURITIES';

export const UPDATE_SEARCH_RESULT_SECURITIES = 'UPDATE_SEARCH_RESULT_SECURITIES';

export const REQUEST_UPDATE_SECURITIES = '@@request/UPDATE_SECURITIES';
export const RECEIVE_UPDATE_SECURITIES =
  '@@receive-The transaction was successfully saved/UPDATE_SECURITIES';
export const FAIL_UPDATE_SECURITIES = '@@fail/UPDATE_SECURITIES';

export const REQUEST_CHANGE_POSTING_DATE = '@@request/CHANGE_POSTING_DATE';
export const RECEIVE_CHANGE_POSTING_DATE =
  '@@receive-The transaction was successfully saved/CHANGE_POSTING_DATE';
export const FAIL_CHANGE_POSTING_DATE = '@@fail/CHANGE_POSTING_DATE';

export const changePostingDate = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/accounting/ChangePostingDate', data),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_CHANGE_POSTING_DATE, RECEIVE_CHANGE_POSTING_DATE, FAIL_CHANGE_POSTING_DATE],
    },
  });

  if (!actionResponse.error) return await dispatch(searchSecurities(criteria));

  return actionResponse;
};

export const updateSecurities = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/accounting/savemoneymarket'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [REQUEST_UPDATE_SECURITIES, RECEIVE_UPDATE_SECURITIES, FAIL_UPDATE_SECURITIES],
    },
  });

  if (!actionResponse.error) return await dispatch(searchSecurities(criteria));

  return actionResponse;
};

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_SECURITIES,
  payload: {
    name,
    value,
    index,
  },
});

export const searchSecurities = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/SearchAccountSecurities', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_SECURITIES, RECEIVE_SEARCH_SECURITIES, FAIL_SEARCH_SECURITIES],
  },
});
