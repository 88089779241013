import { connect } from 'react-redux';
import React, { Component } from 'react';

import Approver from './Approver';
import { downloadAttachFile } from '../../reducers/poa/action';
import { setCheckedCheckboxDataTableByInputElement } from '../../common/helpper';
import {
  changeStatus,
  searchTaskList,
  editSearchResult,
  updateSearchResult,
} from '../../reducers/taskList/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-approver-tasklish';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

class ApproverContainer extends Component {
  state = {
    criteria: {
      Companies: [''],
      FinanceGroupId: [''],
      ValueDateStr: '',
      Status: 'Wait For Approve',
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);

    $('body').on('click', `button.download-poa`, this.onClickDownloadAttachFile);
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);

    $('body').off('click', `button.download-poa`, this.onClickDownloadAttachFile);
  }

  onClickDownloadAttachFile = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    rowData.status = 'wait for approve';
    this.props.downloadAttachFile(rowData);
  };

  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchTaskList({ ...this.state.criteria });
  };

  onClickOpenModalApproveHandler = (e) => {
    this.modalApproveRef.open();
  };

  onClickCloseModalApproveHandler = (e) => {
    this.modalApproveRef.close();
  };

  onClickOpenModalDisapproveHandler = (e) => {
    this.modalDisapproveRef.open();
  };

  onClickCloseModalDisapproveHandler = (e) => {
    this.modalDisapproveRef.close();
  };

  onClickConfirmChangeApproveStatus = (status) => {
    let selected = [];

    selected = this.props.searchResult.filter(
      (f) =>
        f.IsSelected && f.transactionStatus.toLowerCase() === 'wait for approve' && f.canApprove
    );

    const submitData = {
      TransactionNo: [],
      Status: status,
      Action: 'tasklist',
      TransactionDatas: [],
    };
    let error = '';
    selected.forEach((m) => {
      if (!m.canApprove) error = `,Transaction No ${m.transactionNo} is not your responsed.`;
      else {
        submitData.TransactionNo.push(m.transactionNo);
        submitData.TransactionDatas.push({
          Approver1: m.approver1,
          Approver2: m.approver2,
          ValueDateStr: m.valueDateStr,
          SendEmailToApprover: m.isReviewer,
          TransactionNo: m.transactionNo,
          Module: m.module,
        });
      }
    });
    if (error) {
      alert(error);
      return;
    }

    this.props.changeStatus(submitData, this.state.criteria).then(
      (response) => {
        if (!response.error) {
          this.modalApproveRef.close();
          this.modalDisapproveRef.close();
          this.props.searchTaskList({ ...this.state.criteria });
        }
      },
      { ...this.state.criteria }
    );
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    if (result) {
      this.props.searchResult[result.row].IsSelected = result.checked;
      this.forceUpdate();
    }
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,

      onClickOpenModalApprove: this.onClickOpenModalApproveHandler,
      onClickCloseModalApprove: this.onClickCloseModalApproveHandler,
      onClickConfirmChangeApproveStatus: this.onClickConfirmChangeApproveStatus,

      onClickOpenModalDisapprove: this.onClickOpenModalDisapproveHandler,
      onClickCloseModalDisapprove: this.onClickCloseModalDisapproveHandler,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onClickColumnLabelCheckbox: this.onClickColumnLabelCheckbox,
      onClickCheckboxSelecteAll: this.onClickCheckboxSelecteAll,
      results: this.props.searchResult,
    };

    return (
      <div>
        <Approver
          {...props}
          {...this.state}
          modalApproveRef={(e) => (this.modalApproveRef = e)}
          modalDisapproveRef={(e) => (this.modalDisapproveRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.taskList,
  }),
  {
    searchTaskList,
    changeStatus,
    editSearchResult,
    updateSearchResult,
    downloadAttachFile,
  }
)(ApproverContainer);
