import { connect } from 'react-redux';
import React, { useRef, forwardRef, useCallback, useImperativeHandle } from 'react';

import { toNumber } from '../../common/helpper';
import ImportExcel from '../../common/ImportExcel';
import { addAlert } from '../../reducers/layout/action';
import { viewImportExcel, saveImportExcel } from '../../reducers/ttRemittance/action';

function ImportExcelTTRemittance({ importExcel, dispatch, myRef, ...rest }, a) {
  const importExcelRef = useRef();
  useImperativeHandle(myRef, () => ({
    importExcelRef: importExcelRef.current,
  }));

  const onClickSaveImport = useCallback(() => {
    if (!importExcel.rows.length) {
      dispatch(
        addAlert({
          title: 'Warning',
          type: 'warning',
          body: 'No data for import!',
        })
      );
      return;
    }

    dispatch(saveImportExcel(importExcel.rows)).then((response) => {
      if (!response.error) importExcelRef.current.close();
    });
  }, [importExcel]);

  const onUpload = useCallback((file) => {
    const form = new FormData();
    form.append('file', file);

    dispatch(viewImportExcel(form));
  }, []);

  return (
    <ImportExcel
      optionDt={opt}
      ref={importExcelRef}
      tableName="dt-import-tt-remittance"
      value={importExcel}
      onClickSaveImport={onClickSaveImport}
      onUpload={onUpload}
    >
      <thead>
        <tr>
          <th>Company</th>
          <th>Payment By</th>
          <th>Value Date</th>
          <th>Currency</th>
          <th>Invoice Amount</th>
          <th>Net Paid Amount</th>
          <th>Remitted Currency</th>
          <th>Beneficiary's Name</th>
          <th>Beneficiary's Address</th>
          <th>Beneficiary's Country</th>
          <th>Beneficiary's Account/IBAN No.</th>
          <th>Beneficiary's IBAN No.</th>
          <th>Beneficiary Bank's Name</th>
          <th>Beneficiary Bank's Address</th>
          <th>Beneficiary Bank's Country</th>
          <th>Beneficiary Bank's SWIFT Code</th>
          <th>Intermediate Bank's Name</th>
          <th>Intermediate Bank’s Address</th>
          <th>Intermediate Bank’s Country</th>
          <th>Intermediate Bank’s SWIFT Code</th>
          <th>PO Number</th>
          <th>Paid For</th>
          <th>Purpose Detail</th>
          <th>Invoice Number</th>
          <th>All Bank Charge in TH</th>
          <th>All Bank Charge out TH</th>
          <th>Message</th>
          <th>Pre-advice</th>
          <th>Error</th>
        </tr>
      </thead>
    </ImportExcel>
  );
}

const ConnectImportExcelTTRemittance = connect((state) => ({
  importExcel: state.ttRemittance.importExcel,
}))(ImportExcelTTRemittance);

export default forwardRef((props, ref) => (
  <ConnectImportExcelTTRemittance {...props} myRef={ref} />
));

const opt = {
  createdRow: (row, data) => {
    if (data.isError) row.classList.add('bg-danger');
    else row.classList.remove('bg-danger');
  },
  columns: [
    { data: 'companyCode' },
    { data: 'paymentBy' },
    { data: 'valueDateStr' },
    { data: 'currency' },
    {
      data: 'invoiceAmount',
    },
    {
      data: 'netPaidAmount',
      className: 'text-right',
      render: (d) => toNumber(d || 0, 3),
    },
    { data: 'remittedCurrency' },
    { data: 'beneficiaryName' },
    { data: 'beneficiaryAddress' },
    { data: 'beneficiaryCountry' },
    { data: 'beneficiaryAccountNo' },
    { data: 'beneficiaryIbanNo' },
    { data: 'beneficiaryBankName' },
    { data: 'beneficiaryBankAddress' },
    { data: 'beneficiaryBankCountry' },
    { data: 'beneficiaryBankCode' },
    { data: 'intermediateBankName' },
    { data: 'intermediateBankAddress' },
    { data: 'intermediateBankCountry' },
    { data: 'intermediateBankCode' },
    { data: 'poNumber' },
    { data: 'paidFor' },
    { data: 'purposeDetail' },
    { data: 'invoiceNumber' },
    { data: 'allBankChargeInTH' },
    { data: 'allBankChargeOutTH' },
    { data: 'message' },
    { data: 'preAdvice' },
    { data: 'errorMessage' },
  ],
};
