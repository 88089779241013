import React from 'react';

import Tab from '../../common/Tab';
import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Link from '../../common/Link';
import AttachFile from './AttachFile';
import Button from '../../common/Button';
import Select2 from '../../common/Select2';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import RadioButton from '../../common/RadioButton';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toLower, getUsername, getPermission } from '../../common/helpper';
import {
  MASTER_COUNTRY,
  MASTER_CURRENCY,
  MASTER_APPROVER,
  MASTER_PROVINCE,
  MAIN_MASTER_BANK,
  MAIN_MASTER_COMPANY,
  MASTER_INSTAND_CODE,
  MAIN_MASTER_BANK_BRANCH,
  MAIN_MASTER_BANK_ACCOUNT,
} from '../../reducers/master/action';

const permission = getPermission('Master Finance', 'Bank Account');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,

  tabRef,
  formDetailRef,
  formSetDefaultRef,
  formConfigRef,

  resultEffective,

  onClickClearTransferTo,

  onChangeApprover,
  formApproveRef,
  onClickApprove,

  attachFileRef,
}) => {
  const textHeader =
    state.mode === 'create' ? 'Create Bank Account' : `Bank Account No.: ${state.BankAccountNo}`;
  const modeEdit = state.mode === 'edit';
  // let noEdit = !permission ? true : state.mode === 'create' ? false : state.setOverDueDate
  const noEdit = !permission;

  const instanceList = [
    { Instance: 'CEP', Text: 'CEP | SAP ECC CHEMICAL' },
    { Instance: 'DynamicAX', Text: 'DynamicAX | DynamicAX' },
    { Instance: 'FocusOne', Text: 'FocusOne | FocusOne' },
    { Instance: 'NetSuite', Text: 'NetSuite | NetSuite' },
    { Instance: 'NonSAP', Text: 'NonSAP | NonSAP' },
    { Instance: 'SAPHANA', Text: 'SAPHANA | SAPHANA' },
    { Instance: 'PD1', Text: 'PD1 | SAP 4.7' },
    { Instance: 'PEP', Text: 'PEP | SAP ECC PACKAGING' },
    { Instance: 'REP', Text: 'REP | SAP ECC CBMR' },
    { Instance: 'SAP Outside', Text: 'SAP Outside | SAP Outside' },
    { Instance: 'TEP', Text: 'TEP | SAP ECC CERAMICS' },
    { Instance: 'WINSpeed', Text: 'WINSpeed | WINSpeed' },
    { Instance: 'PRD', Text: 'PRD | HANA-NPI' },
    { Instance: 'TSP', Text: 'TSP | HANA-TMMA' },
  ];

  const tabContents = [];
  tabContents.push({
    header: <span>Configuration</span>,
    content: (
      <ConfigTab
        formRef={formConfigRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'configuration',
  });

  tabContents.push({
    header: <span>Set Default</span>,
    content: (
      <SetDefaultTab
        formRef={formSetDefaultRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'default',
  });

  tabContents.push({
    header: <span>Approval</span>,
    content: (
      <ApprovalTab
        onChangeApprover={onChangeApprover}
        formApproveRef={formApproveRef}
        noEdit={noEdit}
        state={state}
      />
    ),
    tabName: 'approval',
  });
  tabContents.push({
    header: <span>Attach File</span>,
    content: <AttachFileTab noEdit={noEdit} state={state} attachFileRef={attachFileRef} />,
    tabName: 'attachFile',
  });

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Finance <span className="icon icon-angle-double-right" />
            <Link txt="Bank Account" href="/master/bank-account" />
            <span className="icon icon-angle-double-right" /> Bank Account Detail{' '}
          </small>
        </p>
      </div>

      <Card textHeader={textHeader} bgHeader="bg-primary" cardActions={['toggler']}>
        <form name="detail" ref={formDetailRef}>
          <Col2 col={colX[0]}>
            {state.mode === 'create' ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <DatePicker
                    value={state.EffectiveDateStr}
                    onChange={onChangeInputData}
                    name="EffectiveDateStr"
                    required
                    option={{
                      // startDate: "+1d",
                      // daysOfWeekDisabled: "0,6",
                      todayHighlight: true,
                    }}
                  />
                </FormGroup2>
              </Col2>
            ) : (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Effective Date" required>
                  <Select2
                    value={state.TempEffectiveDateStr}
                    notMultiple
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="TempEffectiveDateStr"
                    required={false}
                  >
                    {permission ? (
                      <option value="New" key="New">
                        New Effective Date
                      </option>
                    ) : null}
                    {resultEffective.map((m) => (
                      <option value={m} key={m}>
                        {m}
                      </option>
                    ))}
                  </Select2>
                </FormGroup2>
                {state.TempEffectiveDateStr === 'New' ? (
                  <FormGroup2 text="New Effective Date" required>
                    <DatePicker
                      value={state.EffectiveDateStr}
                      onChange={onChangeInputData}
                      name="EffectiveDateStr"
                      required
                      option={{
                        // startDate: "+1d",
                        // daysOfWeekDisabled: "0,6",
                        todayHighlight: true,
                      }}
                    />
                  </FormGroup2>
                ) : null}
              </Col2>
            )}

            {modeEdit ? (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Status" required>
                  <select
                    className="form-control"
                    value={state.IsActive}
                    onChange={onChangeInputData}
                    name="IsActive"
                    disabled={noEdit}
                  >
                    <option value>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                </FormGroup2>
              </Col2>
            ) : null}
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank Account No" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={modeEdit}
                  value={state.BankAccountNo}
                  name="BankAccountNo"
                />
              </FormGroup2>

              <FormGroup2 text="Company" required>
                <MasterDropdown
                  // key={noEdit ? "comp1" : "comp2"}
                  masterType={MAIN_MASTER_COMPANY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={noEdit}
                  value={state.CompanyCode}
                  filter={(m) => m.isActive || m.companyCode === state.CompanyCode}
                  name="CompanyCode"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank" required>
                <MasterDropdown
                  // key={noEdit ? "bank1" : "bank2"}
                  masterType={MAIN_MASTER_BANK}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={noEdit}
                  value={state.BankCode}
                  isMainMaster
                  filter={(m) => m.isActive || m.bankCode === state.BankCode}
                  name="BankCode"
                />
              </FormGroup2>

              <FormGroup2 text="Branch" required>
                <MasterDropdown
                  masterType={MAIN_MASTER_BANK_BRANCH}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={noEdit}
                  value={state.BranchCode}
                  bankCode={state.BankCode}
                  companyCode={state.CompanyCode}
                  // filter={m => {
                  //     if (m.branchCode === state.BranchCode)
                  //         return true

                  //     return m.isActive
                  //         && m.bankCode === state.BankCode
                  //         && (state.Instance === 'PD1' && m.instanceCode === 'PD1' || state.Instance !== 'PD1')
                  // }}
                  effectiveDateStr={state.EffectiveDateStr}
                  name="BranchCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Instance" required>
                <MasterDropdown
                  masterType={MASTER_INSTAND_CODE}
                  value={state.InstanceCode || ''}
                  notMultiple
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  name="InstanceCode"
                  required
                  disabled
                />
              </FormGroup2>

              <FormGroup2 text="Currency" required>
                <MasterDropdown
                  key={noEdit ? 'currency1' : 'currency2'}
                  masterType={MASTER_CURRENCY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={noEdit}
                  value={state.Currency}
                  name="Currency"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Country" required>
                <MasterDropdown
                  masterType={MASTER_COUNTRY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled
                  value={state.Country}
                  name="Country"
                />
              </FormGroup2>

              <FormGroup2 text="Region" required>
                {state.Country === 'TH' ? (
                  <MasterDropdown
                    masterType={MASTER_PROVINCE}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    // disabled={true}
                    value={state.Region}
                    name="Region"
                  />
                ) : (
                  <input
                    className="form-control"
                    onChange={onChangeInputData}
                    required
                    disabled={noEdit}
                    value={state.Region || ''}
                    name="Region"
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="House Bank" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.HouseBank || ''}
                  name="HouseBank"
                />
              </FormGroup2>

              <FormGroup2 text="GL Account Balance" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.GlAcctBal || ''}
                  name="GlAcctBal"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="GL Account Deposit" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.GlAcctDep || ''}
                  name="GlAcctDep"
                />
              </FormGroup2>

              <FormGroup2 text="GL Account Pay" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.GlAcctPay || ''}
                  name="GlAcctPay"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Account Type" required>
                <MasterDropdownUI
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  disabled={noEdit}
                  value={state.AccountType}
                  name="AccountType"
                  options={['CUR', 'SAV', 'FCD', 'FIX']}
                />
              </FormGroup2>

              <FormGroup2 text="Account ID" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.AccountTypeCode || ''}
                  name="AccountTypeCode"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Transfer To" required={false}>
                <MasterDropdown
                  key={noEdit ? 'transfer1' : 'transfer2'}
                  masterType={MAIN_MASTER_BANK_ACCOUNT}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required={false}
                  disabled={noEdit}
                  value={state.TransferTo}
                  companyCode={state.CompanyCode}
                  bankCode={state.BankCode}
                  filter={(m) =>
                    (m.isActive || m.bankAccountNo === state.TransferTo) &&
                    m.bankAccountNo !== state.BankAccountNo
                  }
                  name="TransferTo"
                />
              </FormGroup2>

              <FormGroup2 text="&nbsp;">
                <Button
                  txt="Clear Transfer To"
                  icon="eraser"
                  className="btn-clear"
                  onClick={onClickClearTransferTo}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Bank Account No. (BANK)" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.BankAccountNoSap || ''}
                  name="BankAccountNoSap"
                />
              </FormGroup2>

              <div className="form-group">
                <div>
                  <input
                    id="IsElectronicBank"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeInputData}
                    name="IsElectronicBank"
                    checked={state.IsElectronicBank}
                    disabled={noEdit}
                  />
                  <label htmlFor="IsElectronicBank" className="label_checkbox" />
                  <label htmlFor="IsElectronicBank" className="control-label text_label_checkbox">
                    Electronic Bank Reconciliation
                  </label>
                </div>
              </div>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Remark" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeInputData}
                  required={false}
                  disabled={noEdit}
                  value={state.Remark || ''}
                  name="Remark"
                />
              </FormGroup2>

              {state.Country === 'TH' && (
                <FormGroup2 text="Same Region Fund Transfer Fee">
                  <div>
                    <RadioButton
                      text="Yes"
                      value="Yes"
                      checked={state.SameRegionFundTransferFee === true}
                      onChange={onChangeInputData}
                      name="SameRegionFundTransferFee"
                    />
                    <RadioButton
                      text="No"
                      value="No"
                      checked={state.SameRegionFundTransferFee === false}
                      onChange={onChangeInputData}
                      name="SameRegionFundTransferFee"
                    />
                  </div>
                </FormGroup2>
              )}
            </Col2>
          </Col2>
        </form>

        {renderApproveButtonGroup()}
      </Card>

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          !permission ? (
            ''
          ) : (
            <Button
              txt="SAVE"
              icon="save"
              className="btn-success"
              onClick={onSubmit}
              disabled={noEdit}
            />
          )
        }
      />
    </div>
  );

  function renderApproveButtonGroup() {
    if (
      state.ApprovalStatus === 'Waiting for Approve' &&
      toLower(state.Approver1Status) === 'waiting for approve' &&
      toLower(state.Approver1) === toLower(getUsername())
    )
      return (
        <div className="row">
          <div className="col-sm-12">
            <Button
              txt="Approve"
              icon="check"
              className="btn-success"
              onClick={() => onClickApprove(true)}
            />
            &nbsp;
            <Button
              txt="Reject"
              icon="close"
              className="btn-warning"
              onClick={() => onClickApprove(false)}
            />
          </div>
        </div>
      );

    return null;
  }
};

function SetDefaultTab({ formRef, state, onChangeInputData, noEdit }) {
  return (
    <Card textHeader="Set Default">
      <form ref={formRef} name="default">
        <Col2 col="col-md-8">
          <Col2 col={colX[1]}>
            <div className="form-group">
              <div>
                <input
                  id="IsDefaultMainSweep"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsDefaultMainSweep"
                  checked={state.IsDefaultMainSweep}
                  disabled={noEdit}
                />
                <label htmlFor="IsDefaultMainSweep" className="label_checkbox" />
                <label htmlFor="IsDefaultMainSweep" className="control-label text_label_checkbox">
                  Main Sweep Bank Account
                </label>
              </div>
            </div>

            <div className="form-group">
              <div>
                <input
                  id="IsDefaultMainAutoTransfer"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsDefaultMainAutoTransfer"
                  checked={state.IsDefaultMainAutoTransfer}
                  disabled={noEdit}
                />
                <label htmlFor="IsDefaultMainAutoTransfer" className="label_checkbox" />
                <label
                  htmlFor="IsDefaultMainAutoTransfer"
                  className="control-label text_label_checkbox"
                >
                  Main Auto Transfer Bank Account
                </label>
              </div>
            </div>
          </Col2>
        </Col2>

        <Col2 col="col-md-8">
          <Col2 col={colX[1]}>
            <div className="form-group">
              <div>
                <input
                  id="IsGainLossFx"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsGainLossFx"
                  checked={state.IsGainLossFx}
                  disabled={noEdit}
                />
                <label htmlFor="IsGainLossFx" className="label_checkbox" />
                <label htmlFor="IsGainLossFx" className="control-label text_label_checkbox">
                  Gain Loss FX Bank Account
                </label>
              </div>
            </div>

            <div className="form-group">
              <div>
                <input
                  id="IsInwardExposure"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsInwardExposure"
                  checked={state.IsInwardExposure}
                  disabled={noEdit}
                />
                <label htmlFor="IsInwardExposure" className="label_checkbox" />
                <label htmlFor="IsInwardExposure" className="control-label text_label_checkbox">
                  Inward Exposure Bank Account
                </label>
              </div>
            </div>
          </Col2>
        </Col2>

        <Col2 col="col-md-8">
          <Col2 col={colX[1]}>
            <div className="form-group">
              <div>
                <input
                  id="IsTt"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsTt"
                  checked={state.IsTt}
                  disabled={noEdit}
                />
                <label htmlFor="IsTt" className="label_checkbox" />
                <label htmlFor="IsTt" className="control-label text_label_checkbox">
                  T/T Bank Account
                </label>
              </div>
            </div>

            <div className="form-group">
              <div>
                <input
                  id="IsPnLoan"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsPnLoan"
                  checked={state.IsPnLoan}
                  disabled={noEdit}
                />
                <label htmlFor="IsPnLoan" className="label_checkbox" />
                <label htmlFor="IsPnLoan" className="control-label text_label_checkbox">
                  PN Bank Account
                </label>
              </div>
            </div>
          </Col2>
        </Col2>

        <Col2 col="col-md-8">
          <Col2 col={colX[1]}>
            <div className="form-group">
              <div>
                <input
                  id="IsContractLoan"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsContractLoan"
                  checked={state.IsContractLoan}
                  disabled={noEdit}
                />
                <label htmlFor="IsContractLoan" className="label_checkbox" />
                <label htmlFor="IsContractLoan" className="control-label text_label_checkbox">
                  Contract Loan Bank Account
                </label>
              </div>
            </div>

            <div className="form-group">
              <div>
                <input
                  id="IsBond"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsBond"
                  checked={state.IsBond}
                  disabled={noEdit}
                />
                <label htmlFor="IsBond" className="label_checkbox" />
                <label htmlFor="IsBond" className="control-label text_label_checkbox">
                  Bond Bank Account
                </label>
              </div>
            </div>
          </Col2>
        </Col2>

        <Col2 col="col-md-8">
          <Col2 col={colX[1]}>
            <div className="form-group">
              <div>
                <input
                  id="IsDepositLoan"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsDepositLoan"
                  checked={state.IsDepositLoan}
                  disabled={noEdit}
                />
                <label htmlFor="IsDepositLoan" className="label_checkbox" />
                <label htmlFor="IsDepositLoan" className="control-label text_label_checkbox">
                  Deposit Bank Account
                </label>
              </div>
            </div>

            <div className="form-group">
              <div>
                <input
                  id="IsOncallLoan"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsOncallLoan"
                  checked={state.IsOncallLoan}
                  disabled={noEdit}
                />
                <label htmlFor="IsOncallLoan" className="label_checkbox" />
                <label htmlFor="IsOncallLoan" className="control-label text_label_checkbox">
                  Contract On Call Bank Account
                </label>
              </div>
            </div>
          </Col2>
        </Col2>

        <Col2 col="col-md-8">
          <Col2 col={colX[1]}>
            <div className="form-group">
              <div>
                <input
                  id="IsBg"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={onChangeInputData}
                  name="IsBg"
                  checked={state.IsBg}
                  disabled={noEdit}
                />
                <label htmlFor="IsBg" className="label_checkbox" />
                <label htmlFor="IsBg" className="control-label text_label_checkbox">
                  BG Bank Account
                </label>
              </div>
            </div>
          </Col2>
        </Col2>
      </form>
    </Card>
  );
}

function ConfigTab({ formRef, state, onChangeInputData, noEdit }) {
  const setSweep = state.CanSweep;
  if (!setSweep) {
    state.IsMainSweep = false;
    state.MinBalanceSweep = 0;
  }

  const setAutoTransfer = state.CanAutoTransfer;
  if (!setAutoTransfer) {
    state.IsMainAutoTransfer = false;
    state.MinimumBalanceAutoTransfer = 0;
    state.MinimumAutoTransfer = 0;
  }

  return (
    <Card textHeader="Configuration">
      <form ref={formRef} name="configuration">
        <Col2 col="col-md-8">
          {noEdit ? (
            <Col2 col={colX[1]}>
              <FormGroup2 text="Display on Cash Position" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled={noEdit}
                  value={state.DisplayCashPosition ? 'Yes' : 'No'}
                  name="DisplayCashPosition"
                />
              </FormGroup2>
            </Col2>
          ) : (
            <div className="row gutter-xs">
              <div className="col-md-12">
                <div className="form-group">
                  <label className="control-label">
                    Display on Cash Position
                    <span style={{ color: '#e64a19', marginRight: '5px' }}>*</span>
                  </label>
                  <RadioButton
                    text="Yes"
                    value
                    checked={state.DisplayCashPosition}
                    onChange={onChangeInputData}
                    name="DisplayCashPosition"
                    required
                  />
                  <RadioButton
                    text="No"
                    value={false}
                    checked={!state.DisplayCashPosition}
                    onChange={onChangeInputData}
                    name="DisplayCashPosition"
                  />
                </div>
              </div>
            </div>
          )}
        </Col2>

        <Col2 col="col-md-8">
          <Col2 col={colX[1]}>
            <div className="row gutter-xs">
              <div className="col-md-12">
                {noEdit ? (
                  <FormGroup2 text="Sweep" required>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      required
                      disabled={noEdit}
                      value={state.CanSweep ? 'Yes' : 'No'}
                      name="CanSweep"
                    />
                  </FormGroup2>
                ) : (
                  <div className="form-group">
                    <label className="control-label">
                      Sweep<span style={{ color: '#e64a19', marginRight: '5px' }}>*</span>
                    </label>
                    <RadioButton
                      text="Yes"
                      value
                      checked={state.CanSweep}
                      onChange={onChangeInputData}
                      name="CanSweep"
                      required
                    />
                    <RadioButton
                      text="No"
                      value={false}
                      checked={!state.CanSweep}
                      onChange={onChangeInputData}
                      name="CanSweep"
                    />
                  </div>
                )}

                {noEdit ? (
                  <FormGroup2 text="Main Account for Sweep" required={setSweep}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      required={setSweep}
                      disabled={noEdit}
                      value={!setSweep ? '' : state.IsMainSweep ? 'Yes' : 'No'}
                      name="IsMainSweep"
                    />
                  </FormGroup2>
                ) : (
                  <div className="form-group">
                    <label className="control-label">
                      Main Account for Sweep
                      {setSweep ? (
                        <span style={{ color: '#e64a19', marginRight: '5px' }}>*</span>
                      ) : null}
                    </label>
                    {setSweep ? (
                      <>
                        <RadioButton
                          text="Yes"
                          value
                          checked={state.IsMainSweep && state.CanSweep}
                          onChange={onChangeInputData}
                          name="IsMainSweep"
                          required={setSweep}
                          disabled={!setSweep}
                        />
                        <RadioButton
                          text="No"
                          value={false}
                          checked={!state.IsMainSweep && state.CanSweep}
                          onChange={onChangeInputData}
                          name="IsMainSweep"
                          disabled={!setSweep}
                        />
                      </>
                    ) : null}
                  </div>
                )}

                <FormGroup2 text="Min Balance for Sweep" required={setSweep}>
                  {setSweep ? (
                    <InputMask
                      className="form-control"
                      format="currency"
                      option={state.inputMaskAmount}
                      onChange={onChangeInputData}
                      required={setSweep}
                      disabled={noEdit || !setSweep}
                      value={state.MinimumBalanceSweep}
                      name="MinimumBalanceSweep"
                    />
                  ) : (
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      value=""
                      disabled={noEdit}
                      name="MinimumBalanceSweep"
                    />
                  )}
                </FormGroup2>

                <FormGroup2 text="OD" required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    option={state.inputMaskAmount}
                    onChange={onChangeInputData}
                    required
                    disabled={noEdit}
                    value={state.OverDraft}
                    name="OverDraft"
                  />
                </FormGroup2>
              </div>
            </div>

            <div className="row gutter-xs">
              <div className="col-md-12">
                {noEdit ? (
                  <FormGroup2 text="Auto Transfer" required>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      required
                      disabled={noEdit}
                      value={state.CanAutoTransfer ? 'Yes' : 'No'}
                      name="CanAutoTransfer"
                    />
                  </FormGroup2>
                ) : (
                  <div className="form-group">
                    <label className="control-label">
                      Auto Transfer<span style={{ color: '#e64a19', marginRight: '5px' }}>*</span>
                    </label>
                    <RadioButton
                      text="Yes"
                      value
                      checked={state.CanAutoTransfer}
                      onChange={onChangeInputData}
                      name="CanAutoTransfer"
                      required
                    />
                    <RadioButton
                      text="No"
                      value={false}
                      checked={!state.CanAutoTransfer}
                      onChange={onChangeInputData}
                      name="CanAutoTransfer"
                    />
                  </div>
                )}

                {noEdit ? (
                  <FormGroup2 text="Main Account for Auto Transfer" required={setAutoTransfer}>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      required={setAutoTransfer}
                      disabled={noEdit}
                      value={!setAutoTransfer ? '' : state.IsMainAutoTransfer ? 'Yes' : 'No'}
                      name="IsMainAutoTransfer"
                    />
                  </FormGroup2>
                ) : (
                  <div className="form-group">
                    <label className="control-label">
                      Main Account for Auto Transfer
                      {setAutoTransfer ? (
                        <span style={{ color: '#e64a19', marginRight: '5px' }}>*</span>
                      ) : null}
                    </label>
                    {setAutoTransfer ? (
                      <>
                        <RadioButton
                          text="Yes"
                          value
                          checked={state.IsMainAutoTransfer && state.CanAutoTransfer}
                          onChange={onChangeInputData}
                          name="IsMainAutoTransfer"
                          required={setAutoTransfer}
                          disabled={!setAutoTransfer}
                        />
                        <RadioButton
                          text="No"
                          value={false}
                          checked={!state.IsMainAutoTransfer && state.CanAutoTransfer}
                          onChange={onChangeInputData}
                          name="IsMainAutoTransfer"
                          disabled={!setAutoTransfer}
                        />
                      </>
                    ) : null}
                  </div>
                )}

                <FormGroup2 text="Min Balance for Auto Transfer" required={setAutoTransfer}>
                  {setAutoTransfer ? (
                    <InputMask
                      className="form-control"
                      format="currency"
                      option={state.inputMaskAmount}
                      onChange={onChangeInputData}
                      required={setAutoTransfer}
                      disabled={noEdit || !setAutoTransfer}
                      value={state.MinimumBalanceAutoTransfer}
                      name="MinimumBalanceAutoTransfer"
                    />
                  ) : (
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      value=""
                      disabled={noEdit}
                      name="MinimumBalanceAutoTransfer"
                    />
                  )}
                </FormGroup2>

                <FormGroup2 text="Min Transfer" required={setAutoTransfer}>
                  {setAutoTransfer ? (
                    <InputMask
                      className="form-control"
                      format="currency"
                      option={state.inputMaskAmount}
                      onChange={onChangeInputData}
                      required={setAutoTransfer}
                      disabled={noEdit || !setAutoTransfer}
                      value={state.MinimumAutoTransfer}
                      name="MinimumAutoTransfer"
                    />
                  ) : (
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      value=""
                      disabled={noEdit}
                      name="MinimumAutoTransfer"
                    />
                  )}
                </FormGroup2>
              </div>
            </div>
          </Col2>
        </Col2>

        <Col2 col="col-md-8">
          <Col2 col={colX[1]}>
            <Card
              textHeader="Method Auto Transfer(Main Account to Member Account)"
              headerNoBold={false}
            >
              <MasterDropdownUI
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                required
                disabled={noEdit}
                value={state.TransferMethodMainToMember}
                name="TransferMethodMainToMember"
                options={['Cheque', 'Bahtnet', 'Internet Banking', 'Auto Deduct']}
              />
            </Card>

            <Card
              textHeader="Method Auto Transfer(Member Account to Main Account)"
              headerNoBold={false}
            >
              <MasterDropdownUI
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                isChoose
                notMultipleSelect2
                required
                disabled={noEdit}
                value={state.TransferMethodMemberToMain}
                name="TransferMethodMemberToMain"
                options={['Cheque', 'Bahtnet', 'Internet Banking', 'Auto Deduct']}
              />
            </Card>
          </Col2>
        </Col2>
      </form>
    </Card>
  );
}

function ApprovalTab({ onChangeApprover, formApproveRef, state, noEdit }) {
  const checkMode = state.mode !== 'edit';
  return (
    <form ref={formApproveRef} name="approval">
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Approver1" required={checkMode}>
            {noEdit ? (
              <input className="form-control" disabled value={state.Approver1 || ''} />
            ) : (
              <MasterDropdown
                masterType={MASTER_APPROVER}
                isChoose
                notMultipleSelect2
                value={state.Approver1}
                onChange={(e) =>
                  onChangeApprover({
                    target: e,
                  })
                }
                filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                noValidateOption
                saveLocalState
                required={checkMode}
                financeGroupId="all"
                name="Approver1"
              />
            )}
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function AttachFileTab({ state, noEdit, attachFileRef }) {
  return (
    <AttachFile
      ref={attachFileRef}
      limitFileCount={1}
      masterKey={state.BankAccountNo}
      notFetch={state.mode === 'create'}
      masterType="bankaccount"
    />
  );
}
