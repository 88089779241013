import React from 'react';

import Tab from '../../common/Tab';
import Link from '../../common/Link';
import Col2 from '../../common/Col2';
import Card from '../../common/Card';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import SpanStatus from '../../common/SpanStatus';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toLower, getNewPermission } from '../../common/helpper';
import Select2, { optionForAjaxUserFromAd } from '../../common/Select2';
import { MASTER_COMPANY, MASTER_COUNTRY, MASTER_CURRENCY } from '../../reducers/master/action';

// let permission = getPermissionStr('T/T Remittance', 'Request') === 'View' ? false : true
const newPermission = getNewPermission('T/T Remittance', 'Request');
const permission = true;

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onSubmit,
  onClickCopyCreator,
  onClickClearInitial,
  onClickClearDoa,
  onClickClearBankCountryIntermediate,

  tabRef,
  formGeneralRef,
  formCreatorRef,
  formRequestorRef,
  formBeneficiaryRef,
  formAccountingRef,
  formApproveRef,
  requestorRef,
  creatorRef,
  initialRef,
  doaRef,
  approverRef,

  modalFunctionRef,
  onClickOpenModalFunction,
  onChangeInputModal,
  onSubmitModalFunction,
  onClickSelectFile,

  onCheckHoliday,
  onClickDownloadDebit,
}) => {
  const textHeader =
    state.mode === 'edit' ? `Transaction No.: ${state.TransactionNo}` : 'Create T/T Remittance';
  const noEdit = !(
    state.mode === 'create' ||
    toLower(state.StatusTt) === 'created' ||
    toLower(state.StatusTt) === 'wait for creator editing'
  );
  const isDup = state.DuplicateTransaction || noEdit;
  const tabContents = [];

  tabContents.push({
    header: <span>General Information</span>,
    content: (
      <GeneralTab
        formRef={formGeneralRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'general',
  });

  tabContents.push({
    header: <span>Creator Information</span>,
    content: (
      <CreatorTab
        creatorRef={creatorRef}
        formRef={formCreatorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
        onClickCopyCreator={onClickCopyCreator}
      />
    ),
    tabName: 'creator',
  });

  tabContents.push({
    header: <span>Requestor Information</span>,
    content: (
      <RequestorTab
        requestorRef={requestorRef}
        formRef={formRequestorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'requestor',
  });

  tabContents.push({
    header: <span>Approver Information</span>,
    content: (
      <ApproveTab
        initialRef={initialRef}
        doaRef={doaRef}
        approverRef={approverRef}
        formRef={formApproveRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
        onClickClearInitial={onClickClearInitial}
        onClickClearDoa={onClickClearDoa}
      />
    ),
    tabName: 'approve',
  });

  tabContents.push({
    header: <span>Beneficiary Information</span>,
    content: (
      <BeneficiaryTab
        formRef={formBeneficiaryRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={isDup}
        onClickClearBankCountryIntermediate={onClickClearBankCountryIntermediate}
      />
    ),
    tabName: 'beneficiary',
  });

  tabContents.push({
    header: <span>Accounting</span>,
    content: (
      <AccountingTab
        formRef={formAccountingRef}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
      />
    ),
    tabName: 'accounting',
  });

  tabContents.push({
    header: <span>File</span>,
    content: (
      <FileTab
        formRef={null}
        state={state}
        onChangeInputData={onChangeInputData}
        noEdit={noEdit}
        onClickSelectFile={onClickSelectFile}
      />
    ),
    tabName: 'file',
  });

  if (state.RefTransactionNo) {
    tabContents.push({
      header: <span>Debit Advice</span>,
      content: (
        <DebitFileTab formRef={null} state={state} onClickDownloadDebit={onClickDownloadDebit} />
      ),
      tabName: 'debit',
    });
  }

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            T/T Remittance <span className="icon icon-angle-double-right" />
            <Link txt="Request" href="/tt-remittance/request" />
            {state.mode === 'edit' ? (
              <>
                <span className="icon icon-angle-double-right" /> Update T/T{' '}
                <span className="icon icon-angle-double-right" /> {state.TransactionNo}{' '}
                <span className="icon icon-angle-double-right" />{' '}
                <SpanStatus status={state.StatusTt} />
              </>
            ) : (
              <>
                <span className="icon icon-angle-double-right" /> Create T/T{' '}
              </>
            )}
          </small>
        </p>
      </div>

      <div className="card">
        <div className="card-header bg-primary">
          <strong>{textHeader}</strong>
        </div>
      </div>

      {state.showFunction ||
      (toLower(state.StatusTt) === 'wait for accounting verifying' && state.isAccountingVerify) ||
      (toLower(state.StatusTt) === 'wait for accounting approval' && state.isAccountApprove) ? (
        <Col2 col={colX[2]}>
          <div style={{ paddingBottom: '5px' }}>
            <Button
              txt="APPROVE"
              icon="check"
              className="btn-success"
              onClick={(e) => onClickOpenModalFunction(e, 'approve')}
            />
            &nbsp;
            <Button
              txt="REJECT"
              icon="close"
              className="btn-danger"
              onClick={(e) => onClickOpenModalFunction(e, 'reject')}
            />
            &nbsp;
            <Button
              txt="SEND BACK TO EDIT"
              icon="refresh"
              className="btn-warning"
              onClick={(e) => onClickOpenModalFunction(e, 'send back')}
            />
          </div>
        </Col2>
      ) : null}

      {noEdit && !state.DuplicateTransaction ? (
        <div style={{ paddingTop: 5, paddingBottom: 5 }}>
          <Col2 col={colX[2]}>
            <label>
              Creator confirmed beneficiary information by the payee via telephone:{' '}
              {state.IsConfirmCreator ? (
                <span style={{ color: 'green' }}>Yes</span>
              ) : (
                <span style={{ color: 'red' }}>No</span>
              )}
            </label>
          </Col2>

          {!state.IsConfirmCreator ? (
            <Col2 col={colX[2]}>
              <Col2 col={colX[1]}>
                <p>{state.CommentBox}</p>
              </Col2>
            </Col2>
          ) : null}

          {toLower(state.StatusTt) === 'wait for doa approval' && state.showFunction ? (
            <Col2 col={colX[2]}>
              <label className="custom-control">
                <div>
                  <input
                    id="IsConfirmDoa"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={(e) =>
                      onChangeInputData({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                    name="IsConfirmDoa"
                    checked={state.IsConfirmDoa}
                    // disabled={noEdit}
                  />
                  <label htmlFor="IsConfirmDoa" className="label_checkbox" />
                  <label htmlFor="IsConfirmDoa" className="control-label text_label_checkbox">
                    <p>
                      I hearby confirm that the beneficiary information provided above has been
                      checked and confirmed by the payee via telephone.
                    </p>
                    <p>
                      (ขอรับรองว่าข้อมูลผู้รับเงินปลายทางที่ปรากฎข้างต้นทั้งหมดนี้
                      ได้ทำการตรวจสอบและยืนยันกับผู้รับเงินปลายทางทางโทรศัพท์เรียบร้อยแล้ว)
                    </p>
                  </label>
                </div>
              </label>
            </Col2>
          ) : null}

          {toLower(state.StatusTt) === 'wait for approval' && state.showFunction ? (
            <Col2 col={colX[2]}>
              <label className="custom-control">
                <div>
                  <input
                    id="IsConfirmApprover"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={(e) =>
                      onChangeInputData({
                        target: {
                          name: e.target.name,
                          value: e.target.checked,
                        },
                      })
                    }
                    name="IsConfirmApprover"
                    checked={state.IsConfirmApprover}
                    // disabled={noEdit}
                  />
                  <label htmlFor="IsConfirmApprover" className="label_checkbox" />
                  <label htmlFor="IsConfirmApprover" className="control-label text_label_checkbox">
                    <p>
                      I hearby confirm that the beneficiary information provided above has been
                      checked and confirmed by the payee via telephone.
                    </p>
                    <p>
                      (ขอรับรองว่าข้อมูลผู้รับเงินปลายทางที่ปรากฎข้างต้นทั้งหมดนี้
                      ได้ทำการตรวจสอบและยืนยันกับผู้รับเงินปลายทางทางโทรศัพท์เรียบร้อยแล้ว)
                    </p>
                  </label>
                </div>
              </label>
            </Col2>
          ) : null}
        </div>
      ) : null}

      <Tab
        tabs={tabContents}
        ref={tabRef}
        footer={
          // noEdit || !permission ? "" :
          noEdit ? (
            ''
          ) : (
            <>
              <Button
                txt="SAVE DRAFT"
                icon="save"
                className="btn-info"
                onClick={(e) => onCheckHoliday(e, 'save')}
                disabled={false}
              />
              &nbsp;
              <Button
                txt="SUBMIT"
                icon="check"
                className="btn-success"
                onClick={(e) => onCheckHoliday(e, 'submit')}
                disabled={false}
              />
              {state.mode === 'create' ||
              toLower(state.StatusTt) === 'created' ||
              toLower(state.StatusTt) === 'wait for creator editing' ? (
                <>
                  &nbsp;
                  <Button
                    txt="Send to accounting"
                    icon="arrow-right"
                    className="btn-primary"
                    onClick={(e) => onCheckHoliday(e, 'send')}
                    disabled={false}
                  />
                </>
              ) : null}
            </>
          )
        }
      />

      {state.mode === 'edit' ? (
        <Card textHeader="History" bgHeader="bg-primary" cardActions={['toggler']}>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <table className="table table-bordered table-nowrap">
                  <thead>
                    <tr>
                      <th>Description</th>
                      {/* <th>Status</th> */}
                      <th>By</th>
                      <th>Date</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!(state.HistoryDraftTt || []).length ? (
                      <tr>
                        <td colSpan="5" align="center">
                          No data available in table
                        </td>
                      </tr>
                    ) : null}
                    {state.HistoryDraftTt.map((m, i) => (
                      <tr key={i}>
                        <td>{m.description}</td>
                        {/* <td className="text-center">{setStatus2(m.status)}</td> */}
                        <td>{m.createdByFullName}</td>
                        <td>{m.createdDateStr}</td>
                        <td>{m.remark}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>
      ) : null}

      <Modal size="modal-md" ref={modalFunctionRef} modalFooter>
        <form onSubmit={onSubmitModalFunction} className="form-horizontal">
          <div className="text-center">
            <span
              className={`text-${state.modalFunction.type} icon ${state.modalFunction.icon} icon-5x`}
            />
            <h3 className={`text-${state.modalFunction.type}`}>{state.modalFunction.textHeader}</h3>
            <p>Do you want to {state.modalFunction.textContent}?</p>
          </div>
          {state.modalFunction.mode !== 'approve' ? (
            <>
              <br />
              <div className="form-group">
                <label className="control-label col-md-4">
                  Remark<span style={{ color: '#e64a19' }}>*</span>
                </label>
                <div className="col-md-7">
                  <textarea
                    className="form-control"
                    rows="3"
                    onChange={onChangeInputModal}
                    name="Remark"
                    value={state.modalFunction.Remark}
                    required
                  />
                </div>
              </div>
            </>
          ) : null}

          <div className="text-center">
            <div className="m-t-lg">
              <Button txt="Yes" className={`btn btn-${state.modalFunction.type}`} type="submit" />
              &nbsp;
              <Button txt="No" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

function GeneralTab({ formRef, state, onChangeInputData, noEdit }) {
  const reqRemittance = state.Equivalent === 'Equivalent';
  const reqPurpose = toLower(state.PaidFor) === 'goods' || toLower(state.PaidFor) === 'service';
  let editVerify = !permission;
  if (permission) {
    const { StatusTt, mode, isAccountingVerify, IsCreator } = state;
    if (
      isAccountingVerify &&
      (['created', 'wait for creator editing', 'wait for accounting verifying'].includes(
        toLower(StatusTt)
      ) ||
        mode === 'create')
    ) {
      editVerify = false;
    } else if (IsCreator && ['created', 'wait for creator editing'].includes(toLower(StatusTt)))
      editVerify = false;
    else editVerify = true;
  }
  return (
    <form ref={formRef} name="general">
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Company" required>
            <MasterDropdown
              masterType={MASTER_COMPANY}
              noValidateOption
              saveLocalState
              onChange={(e) =>
                onChangeInputData({
                  target: e,
                })
              }
              value={state.CompanyCode}
              isChoose
              isGetAll
              notMultipleSelect2
              disabled={state.mode === 'edit'}
              required
              status
              name="CompanyCode"
            />
          </FormGroup2>

          <FormGroup2 text="Payment By" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.PaymentBy || ''}
                name="PaymentBy"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={state.PaymentBy}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required
                options={['Telegraphic transfer', 'Demand draft', 'LC']}
                name="PaymentBy"
              />
            )}
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2 text="PO No." maxLength={2000} length={(state.PoNo || '').length}>
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.PoNo || ''}
              name="PoNo"
              disabled={noEdit}
              maxLength={2000}
            />
          </FormGroup2>

          <FormGroup2 text="Equivalent" required>
            <MasterDropdownUI
              value={state.Equivalent}
              name="Equivalent"
              isChoose
              notMultipleSelect2
              onChange={(e) =>
                onChangeInputData({
                  target: e,
                })
              }
              required
              disabled={noEdit}
              options={['Normal', 'Equivalent']}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Invoice Number"
            required
            maxLength={500}
            length={(state.InvoiceNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.InvoiceNo || ''}
              name="InvoiceNo"
              disabled={noEdit}
              required
              maxLength={500}
            />
          </FormGroup2>

          <FormGroup2 text="Invoice Amount" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.InvoiceAmount || ''}
                disabled={noEdit}
                name="InvoiceAmount"
              />
            ) : (
              <InputMask
                className="form-control"
                format="currency"
                option={{
                  prefix: '',
                  digits: 2,
                  digitsOptional: false,
                  placeholder: '0.00',
                }}
                onChange={onChangeInputData}
                value={state.InvoiceAmount}
                disabled={noEdit}
                required
                name="InvoiceAmount"
              />
            )}
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2 text="Currency" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.Currency || ''}
                name="Currency"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdown
                masterType={MASTER_CURRENCY}
                noValidateOption
                saveLocalState
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                value={state.Currency}
                isChoose
                notMultipleSelect2
                disabled={noEdit}
                required
                name="Currency"
              />
            )}
          </FormGroup2>

          {state.Equivalent === 'Equivalent' ? (
            <FormGroup2 text="Remitted To Currency" required={reqRemittance}>
              {noEdit ? (
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.RemittedToCurrency || ''}
                  name="RemittedToCurrency"
                  disabled={noEdit}
                />
              ) : (
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  value={state.RemittedToCurrency}
                  isChoose
                  notMultipleSelect2
                  disabled={noEdit}
                  required={reqRemittance}
                  name="RemittedToCurrency"
                />
              )}
            </FormGroup2>
          ) : null}
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Value Date" required>
            {editVerify && state.mode !== 'create' ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.ValueDateStr || ''}
                name="ValueDateStr"
                disabled
              />
            ) : (
              <DatePicker
                onChange={onChangeInputData}
                name="ValueDateStr"
                value={state.ValueDateStr}
                // checkHoliday={true}
                option={{
                  startDate: 'd',
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                required
              />
            )}
          </FormGroup2>

          <FormGroup2 text="Pre-Advice" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.PreAdvice || ''}
                name="PreAdvice"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={state.PreAdvice}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required
                options={['None', '1 Day', '2 Days before Value Date']}
                name="PreAdvice"
              />
            )}
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2 text="Paid for" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.PaidFor || ''}
                name="PaidFor"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={state.PaidFor}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required
                // options={["Service", "Goods", "Project", "ST Loan", "LT Loan & Swap", "Dividend & Capital & Injection", "Other"]}
                options={[
                  'Service',
                  'Goods',
                  'Project',
                  'ST Loan',
                  'LT Loan & Swap',
                  'Capital Injection',
                  'Dividend',
                  'Other',
                ]}
                name="PaidFor"
              />
            )}
          </FormGroup2>

          <FormGroup2
            text="Purpose Detail"
            required={!reqPurpose}
            maxLength={100}
            length={(state.PurposeDetail || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.PurposeDetail || ''}
              name="PurposeDetail"
              required={!reqPurpose}
              disabled={noEdit}
              maxLength={100}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Forward Contract No. / Project Name"
            required={!reqPurpose}
            maxLength={60}
            length={(state.ForwardContractNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.ForwardContractNo || ''}
              name="ForwardContractNo"
              disabled={noEdit}
              required={!reqPurpose}
              maxLength={60}
            />
          </FormGroup2>

          <FormGroup2 text="All bank charge outside Company Country For" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.BankChargeOutside || ''}
                name="BankChargeOutside"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={state.BankChargeOutside}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required
                options={['Our Account', 'Beneficiary Account']}
                name="BankChargeOutside"
              />
            )}
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2 text="All bank charge inside Company Country For" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.BankChargeInside || ''}
                name="BankChargeInside"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={state.BankChargeInside}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required
                options={['Our Account', 'Beneficiary Account']}
                name="BankChargeInside"
              />
            )}
          </FormGroup2>

          <FormGroup2
            text="Special Message (Send to Bank in English)"
            maxLength={2000}
            length={(state.SpecialMessage || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.SpecialMessage || ''}
              name="SpecialMessage"
              disabled={noEdit}
              maxLength={2000}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Absorb WHT" required>
            {noEdit ? (
              <input
                className="form-control"
                onChange={onChangeInputData}
                value={state.AbsorbWht || ''}
                name="AbsorbWht"
                disabled={noEdit}
              />
            ) : (
              <MasterDropdownUI
                isChoose
                notMultipleSelect2
                value={state.AbsorbWht}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                required
                options={['Our Company', 'Beneficiary Company']}
                name="AbsorbWht"
              />
            )}
          </FormGroup2>

          {/* <FormGroup2 text="Calendar" required={true}>
                    <MasterDropdown
                        masterType={MASTER_HOLIDAY}
                        onChange={e => onChangeInputData({
                            target: e
                        })}
                        noValidateOption={true}
                        saveLocalState={true}
                        status={true}
                        isChoose={true}
                        disabled={noEdit}
                        required={true}
                        notMultipleSelect2={true}
                        value={state.CalendarId}
                        name="CalendarId"
                    />
                </FormGroup2> */}
        </Col2>
      </Col2>
    </form>
  );
}

function CreatorTab({ formRef, state, onChangeInputData, noEdit, onClickCopyCreator, creatorRef }) {
  return (
    <form ref={formRef} name="creator">
      {!noEdit ? (
        <Col2 col={colX[2]}>
          <Button
            txt="COPY TO REQUESTOR"
            icon="copy"
            className="btn-default"
            onClick={onClickCopyCreator}
            disabled={noEdit}
          />
        </Col2>
      ) : null}
      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Creator Name" required>
            <input
              type="text"
              disabled
              className="form-control"
              ref={creatorRef}
              defaultValue={state.CreatorFullName}
            />
          </FormGroup2>

          <FormGroup2
            text="Email"
            required={false}
            maxLength={200}
            length={(state.CreatorEmail || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.CreatorEmail || ''}
              name="CreatorEmail"
              disabled
              maxLength={200}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Department"
            required
            maxLength={200}
            length={(state.CreatorDepartment || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.CreatorDepartment || ''}
              name="CreatorDepartment"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>

          <FormGroup2 text="Tel No." required={false}>
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.CreatorTel || ''}
              name="CreatorTel"
              disabled={noEdit}
              maxLength={30}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      {!noEdit && !state.DuplicateTransaction ? (
        <>
          <hr />
          <Col2 col={colX[2]}>
            <label className="custom-control">
              <div>
                <input
                  id="IsConfirmCreator"
                  type="checkbox"
                  className="label-checkbox"
                  onChange={(e) =>
                    onChangeInputData({
                      target: {
                        name: e.target.name,
                        value: e.target.checked,
                      },
                    })
                  }
                  name="IsConfirmCreator"
                  checked={state.IsConfirmCreator}
                  disabled={noEdit}
                />
                <label htmlFor="IsConfirmCreator" className="label_checkbox" />
                <label htmlFor="IsConfirmCreator" className="control-label text_label_checkbox">
                  <p>
                    I hearby confirm that the beneficiary information provided above has been
                    checked and confirmed by the payee via telephone.
                  </p>
                  <p>
                    (ขอรับรองว่าข้อมูลผู้รับเงินปลายทางที่ปรากฎข้างต้นทั้งหมดนี้
                    ได้ทำการตรวจสอบและยืนยันกับผู้รับเงินปลายทางทางโทรศัพท์เรียบร้อยแล้ว)
                  </p>
                </label>
              </div>
            </label>
          </Col2>

          {!state.IsConfirmCreator ? (
            <Col2 col={colX[2]}>
              <Col2 col={colX[1]}>
                <FormGroup2
                  text={
                    <span style={{ color: 'red' }}>
                      Please provide reasons for sending without checking beneficiary information
                      via telephone.
                    </span>
                  }
                  required={!state.IsConfirmCreator}
                  maxLength={500}
                  length={(state.CommentBox || '').length}
                >
                  <textarea
                    className="form-control"
                    rows="3"
                    onChange={onChangeInputData}
                    value={state.CommentBox || ''}
                    name="CommentBox"
                    disabled={noEdit}
                    required={!state.IsConfirmCreator}
                    maxLength={500}
                  />
                </FormGroup2>
              </Col2>
            </Col2>
          ) : null}
        </>
      ) : null}
    </form>
  );
}

function RequestorTab({ formRef, state, onChangeInputData, noEdit, requestorRef }) {
  return (
    <form ref={formRef} name="requestor">
      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Requestor Name" required>
            <Select2
              ref={requestorRef}
              notMultiple
              required
              noValidateOption
              disabled={noEdit}
              value={state.RequestorName}
              onChange={(e) =>
                onChangeInputData({
                  target: e,
                })
              }
              option={optionForAjaxUserFromAd}
              name="RequestorName"
            />
          </FormGroup2>

          <FormGroup2
            text="Email"
            required={false}
            maxLength={200}
            length={(state.RequestorEmail || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.RequestorEmail || ''}
              name="RequestorEmail"
              disabled={noEdit}
              maxLength={200}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[2]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Department"
            required
            maxLength={200}
            length={(state.RequestorDepartment || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.RequestorDepartment || ''}
              name="RequestorDepartment"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>

          <FormGroup2 text="Tel No." required={false}>
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.RequestorTel || ''}
              name="RequestorTel"
              disabled={noEdit}
              maxLength={30}
            />
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function BeneficiaryTab({
  formRef,
  state,
  onChangeInputData,
  noEdit,
  onClickClearBankCountryIntermediate,
}) {
  const reqAccount = state.AccountNo || (!state.AccountNo && !state.IbanNo);
  const reqIban = state.IbanNo || (!state.AccountNo && !state.IbanNo);
  const reqIntermedia =
    state.IntermediateBankName ||
    state.IntermediateBankAddress ||
    state.IntermediateBankSwiftCode ||
    state.IntermediateBankCountry;

  return (
    <form ref={formRef} name="beneficiary">
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Beneficiary Code"
            required={false}
            maxLength={50}
            length={(state.BeneficiaryCode || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryCode || ''}
              name="BeneficiaryCode"
              disabled={noEdit}
              maxLength={50}
            />
          </FormGroup2>

          <FormGroup2
            text="Beneficiary Name"
            required
            maxLength={200}
            length={(state.BeneficiaryName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryName || ''}
              name="BeneficiaryName"
              disabled={noEdit}
              required
              maxLength={200}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="Beneficiary Address"
            required
            maxLength={500}
            length={(state.BeneficiaryAddress || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.BeneficiaryAddress || ''}
              name="BeneficiaryAddress"
              disabled={noEdit}
              required
              maxLength={500}
            />
          </FormGroup2>

          <FormGroup2 text="Beneficiary Country" required>
            <MasterDropdown
              masterType={MASTER_COUNTRY}
              noValidateOption
              saveLocalState
              onChange={(e) =>
                onChangeInputData({
                  target: e,
                })
              }
              isChoose
              notMultipleSelect2
              required
              disabled={noEdit}
              value={state.BeneficiaryCountry}
              name="BeneficiaryCountry"
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Account No."
            required={reqAccount}
            maxLength={100}
            length={(state.AccountNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.AccountNo || ''}
              name="AccountNo"
              disabled={noEdit}
              required={reqAccount}
              maxLength={100}
            />
          </FormGroup2>

          <FormGroup2
            text="IBAN No."
            required={reqIban}
            maxLength={100}
            length={(state.IbanNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IbanNo || ''}
              name="IbanNo"
              disabled={noEdit}
              required={reqIban}
              maxLength={100}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="ABA No."
            required={false}
            maxLength={50}
            length={(state.AbaNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.AbaNo || ''}
              name="AbaNo"
              disabled={noEdit}
              maxLength={50}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="BSB No."
            required={false}
            maxLength={100}
            length={(state.BsbNo || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BsbNo || ''}
              name="BsbNo"
              disabled={noEdit}
              maxLength={100}
            />
          </FormGroup2>

          <FormGroup2
            text="IFSC Code"
            required={false}
            maxLength={50}
            length={(state.IfscCode || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IfscCode || ''}
              name="IfscCode"
              disabled={noEdit}
              maxLength={50}
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Beneficiary's Bank Name"
            required
            maxLength={100}
            length={(state.BeneficiaryBankName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryBankName || ''}
              name="BeneficiaryBankName"
              disabled={noEdit}
              required
              maxLength={100}
            />
          </FormGroup2>

          <FormGroup2
            text="Beneficiary's Bank Address"
            required
            maxLength={500}
            length={(state.BeneficiaryBankAddress || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.BeneficiaryBankAddress || ''}
              name="BeneficiaryBankAddress"
              disabled={noEdit}
              required
              maxLength={500}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="Beneficiary's Bank SWIFT Code"
            required
            maxLength={11}
            length={(state.BeneficiaryBankSwiftCode || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.BeneficiaryBankSwiftCode || ''}
              name="BeneficiaryBankSwiftCode"
              disabled={noEdit}
              required
              maxLength={11}
            />
          </FormGroup2>

          <FormGroup2 text="Beneficiary's Bank Country" required>
            <MasterDropdown
              masterType={MASTER_COUNTRY}
              noValidateOption
              saveLocalState
              onChange={(e) =>
                onChangeInputData({
                  target: e,
                })
              }
              isChoose
              notMultipleSelect2
              required
              disabled={noEdit}
              value={state.BeneficiaryBankCountry}
              name="BeneficiaryBankCountry"
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Intermediate's Bank Name"
            required={reqIntermedia}
            maxLength={100}
            length={(state.IntermediateBankName || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IntermediateBankName || ''}
              name="IntermediateBankName"
              disabled={noEdit}
              maxLength={100}
              required={reqIntermedia}
            />
          </FormGroup2>

          <FormGroup2
            text="Intermediate's Bank Address"
            required={reqIntermedia}
            maxLength={500}
            length={(state.IntermediateBankAddress || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.IntermediateBankAddress || ''}
              name="IntermediateBankAddress"
              disabled={noEdit}
              maxLength={500}
              required={reqIntermedia}
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2
            text="Intermediate's Bank SWIFT Code"
            required={reqIntermedia}
            maxLength={11}
            length={(state.IntermediateBankSwiftCode || '').length}
          >
            <input
              className="form-control"
              onChange={onChangeInputData}
              value={state.IntermediateBankSwiftCode || ''}
              name="IntermediateBankSwiftCode"
              disabled={noEdit}
              maxLength={11}
              required={reqIntermedia}
            />
          </FormGroup2>

          <FormGroup2 text="Intermediate's Bank Country" required={reqIntermedia}>
            <div className="row gutter-xs">
              <div className="col-xs-9">
                <MasterDropdown
                  masterType={MASTER_COUNTRY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required={reqIntermedia}
                  disabled={noEdit}
                  value={state.IntermediateBankCountry}
                  name="IntermediateBankCountry"
                />
              </div>
              <div className="col-xs-3">
                <button
                  className="btn btn-sm btn-clear"
                  onClick={onClickClearBankCountryIntermediate}
                >
                  <i className="icon icon-eraser icon-fw icon-lg" />
                </button>
              </div>
            </div>
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Intenal Message"
            required={false}
            maxLength={500}
            length={(state.InternalMessage || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.InternalMessage || ''}
              name="InternalMessage"
              disabled={noEdit}
              maxLength={500}
            />
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function AccountingTab({ formRef, state, onChangeInputData, noEdit }) {
  const editVerify = !permission
    ? true
    : !(
        (state.mode === 'create' ||
          toLower(state.StatusTt) === 'created' ||
          toLower(state.StatusTt) === 'wait for creator editing' ||
          toLower(state.StatusTt) === 'wait for accounting verifying') &&
        state.isAccountingVerify
      );
  const editBased = !permission
    ? true
    : toLower(state.StatusTt) === 'created' ||
        toLower(state.StatusTt) === 'wait for creator editing' ||
        state.mode === 'create'
      ? false
      : editVerify;
  const editRemark = !permission
    ? true
    : state.mode === 'create'
      ? false
      : state.isAccountApprove && toLower(state.StatusTt) === 'wait for accounting approval'
        ? false
        : editVerify;

  return (
    <form ref={formRef} name="accounting">
      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2 text="Based Amount" required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }}
              onChange={onChangeInputData}
              value={state.BasedAmount}
              disabled={editBased}
              required
              name="BasedAmount"
            />
          </FormGroup2>

          <FormGroup2 text="WHT Amount" required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }}
              onChange={onChangeInputData}
              value={state.WhtAmount}
              disabled={editVerify}
              required
              name="WhtAmount"
            />
          </FormGroup2>
        </Col2>

        <Col2 col={colX[1]}>
          <FormGroup2 text="VAT Amount" required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }}
              onChange={onChangeInputData}
              value={state.VatAmount}
              disabled={editVerify}
              required
              name="VatAmount"
            />
          </FormGroup2>

          <FormGroup2 text="Net Paid Amount" required>
            <InputMask
              className="form-control"
              format="currency"
              option={{
                prefix: '',
                digits: 2,
                digitsOptional: false,
                placeholder: '0.00',
              }}
              onChange={onChangeInputData}
              value={state.NetPaidAmount}
              disabled
              required
              name="NetPaidAmount"
            />
          </FormGroup2>
        </Col2>
      </Col2>

      <Col2 col={colX[0]}>
        <Col2 col={colX[1]}>
          <FormGroup2
            text="Remark"
            required={false}
            maxLength={2000}
            length={(state.Remark || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.Remark || ''}
              name="Remark"
              disabled={editRemark}
              maxLength={2000}
            />
          </FormGroup2>

          <FormGroup2
            text="FI Doc."
            required={false}
            maxLength={2000}
            length={(state.FiDoc || '').length}
          >
            <textarea
              className="form-control"
              rows="3"
              onChange={onChangeInputData}
              value={state.FiDoc || ''}
              name="FiDoc"
              disabled={editRemark}
              maxLength={2000}
            />
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function ApproveTab({
  formRef,
  state,
  onChangeInputData,
  noEdit,
  onClickClearInitial,
  onClickClearDoa,
  initialRef,
  doaRef,
  approverRef,
}) {
  return (
    <form ref={formRef} name="approve">
      {state.DuplicateTransaction ? (
        <Col2 col={colX[1]}>
          <Col2 col={colX[0]}>
            <FormGroup2 text="Initial Name" required={false}>
              {noEdit && !state.Initial ? (
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.Initial || ''}
                  name="Initial"
                  disabled
                />
              ) : (
                <div className="row gutter-xs">
                  <div className="col-xs-9">
                    <Select2
                      ref={initialRef}
                      notMultiple
                      required={false}
                      noValidateOption
                      disabled={noEdit}
                      value={state.Initial}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      option={optionForAjaxUserFromAd}
                      name="Initial"
                    />
                  </div>
                  <div className="col-xs-3">
                    <button className="btn btn-sm btn-clear" onClick={onClickClearInitial}>
                      <i className="icon icon-eraser icon-fw icon-lg" />
                    </button>
                  </div>
                </div>
              )}
            </FormGroup2>

            <FormGroup2 text="Approver Name" required>
              <Select2
                ref={approverRef}
                notMultiple
                noValidateOption
                required
                disabled={noEdit}
                value={state.Approver}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                option={optionForAjaxUserFromAd}
                name="Approver"
              />
            </FormGroup2>
          </Col2>
        </Col2>
      ) : (
        <Col2 col={colX[1]}>
          <Col2 col={colX[0]}>
            <FormGroup2 text="Initial Name" required={false}>
              {noEdit && !state.Initial ? (
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  value={state.Initial || ''}
                  name="Initial"
                  disabled
                />
              ) : (
                <div className="row gutter-xs">
                  <div className="col-xs-9">
                    <Select2
                      ref={initialRef}
                      notMultiple
                      required={false}
                      noValidateOption
                      disabled={noEdit}
                      value={state.Initial}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      option={optionForAjaxUserFromAd}
                      name="Initial"
                    />
                  </div>
                  <div className="col-xs-3">
                    <button className="btn btn-sm btn-clear" onClick={onClickClearInitial}>
                      <i className="icon icon-eraser icon-fw icon-lg" />
                    </button>
                  </div>
                </div>
              )}
            </FormGroup2>

            <FormGroup2 text="DOA Name (ระดับ จ. ขึ้นไป)" required>
              <Select2
                ref={doaRef}
                notMultiple
                noValidateOption
                required
                disabled={noEdit}
                value={state.Doa}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                option={optionForAjaxUserFromAd}
                name="Doa"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[0]}>
            <FormGroup2 text="Approver Name" required>
              <Select2
                ref={approverRef}
                notMultiple
                noValidateOption
                required
                disabled={noEdit}
                value={state.Approver}
                onChange={(e) =>
                  onChangeInputData({
                    target: e,
                  })
                }
                option={optionForAjaxUserFromAd}
                name="Approver"
              />
            </FormGroup2>
          </Col2>
        </Col2>
      )}

      <Col2 col={colX[2]}>
        <Col2 col={colX[0]}>
          <FormGroup2 text="Accounting Verifier Name" required={false}>
            <div className="table-responsive">
              <table className="table table-bordered table-nowrap">
                <thead>
                  <tr>
                    <th className="th-white">No.</th>
                    <th className="th-white">Name</th>
                    <th className="th-white">Surname</th>
                    <th className="th-white">Email</th>
                  </tr>
                </thead>
                <tbody>
                  {!(state.ResultAccountingVerify || []).length ? (
                    <tr>
                      <td colSpan="4" align="center">
                        No data available in table
                      </td>
                    </tr>
                  ) : null}
                  {state.ResultAccountingVerify.map((m, i) => (
                    <tr key={i}>
                      <td align="center">{i + 1}</td>
                      <td>{m.nameEn}</td>
                      <td>{m.surnameEn}</td>
                      <td>{m.email}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </FormGroup2>
        </Col2>
      </Col2>
    </form>
  );
}

function FileTab({ formRef, state, noEdit, onClickSelectFile }) {
  return (
    <form ref={formRef} name="file">
      <Col2 col={colX[0]}>
        <div>
          <Button
            txt="Upload/Download File"
            icon="upload"
            className="btn-info"
            onClick={onClickSelectFile}
          />
        </div>
      </Col2>
      <Col2 col={colX[2]}>
        <div className="table-responsive">
          <table className="table table-bordered table-nowrap">
            <thead>
              <tr>
                <th>No.</th>
                <th>File Name</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              {!(state.attachfilelist || []).length ? (
                <tr>
                  <td colSpan="3" align="center">
                    No data available in table
                  </td>
                </tr>
              ) : null}
              {state.attachfilelist.map((m, i) => (
                <tr key={i}>
                  <td align="center">{i + 1}</td>
                  <td>{m.fileName}</td>
                  <td>{m.remark}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Col2>
    </form>
  );
}

function DebitFileTab({ formRef, state, onClickDownloadDebit }) {
  return (
    // <form ref={formRef} name="debit">
    <Col2 col={colX[2]}>
      <div className="table-responsive">
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th>Download</th>
              <th>File Name</th>
              <th>Remark</th>
            </tr>
          </thead>
          <tbody>
            {!(state.debitFiles || []).length ? (
              <tr>
                <td colSpan="3" align="center">
                  No data available in table
                </td>
              </tr>
            ) : null}
            {state.debitFiles.map((m, i) => (
              <tr key={i}>
                <td className="text-center">
                  <button
                    onClick={(e) => onClickDownloadDebit(e, m.id)}
                    className="btn btn-info btn-sm"
                  >
                    <i className="icon icon-download icon-lg icon-fw" />
                  </button>
                </td>
                <td>{m.fileName}</td>
                <td>{m.remark}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Col2>
    // </form>
  );
}
