import React, { Component } from 'react';

import { endpoint } from '../reducers/configuration';

const $ = window.jQuery;
export default class Select2 extends Component {
  componentDidMount() {
    $(this.SelectElement).val(this.props.value);
    this.$select2 = $(this.SelectElement).select2(this.getOptionSelect2());
    this.$select2.on('change', this.onChangeHandler);
  }

  getOptionSelect2() {
    let text = 'Select a value';
    if (this.props.isChoose) {
      text = '--Choose--';
    }
    if (this.props.isTransfermethod) {
      text = '--Transfer Method--';
    }

    const defaultOpt = {
      placeholder: text,
    };
    const opt = this.props.option || {
      closeOnSelect: true,
    };

    return {
      ...defaultOpt,
      ...opt,
    };
  }

  compareArray(a = [], b = []) {
    // ทำให้ เท่ากับค่าว่างของ props เพราะตอนนี้เวลาไม่ได้เลือกอะไร ค่าว่างจะเป็น [""] แทนที่จะเป็น []
    if (b.length === 0) b = [''];
    if (a.length === 0) a = [''];

    if (a.length !== b.length) return false;
    let isEqual = true;
    a.forEach((m, i) => {
      if (b[i] !== m) isEqual = false;
    });

    return isEqual;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.notMultiple) {
      const isEqual = this.compareArray(this.props.value, this.$select2.val());
      if (!isEqual) {
        this.$select2.val(this.props.value).trigger('change.select2');
        // check after selected value
        // ว่ามี selected value มี value ที่ props มีไหม ถ้าไม่มีเรียก onChange โดยยึด value จาก select
        if (
          !this.compareArray(this.props.value, this.$select2.val()) &&
          !this.props.noValidateOption
        ) {
          this.onChangeHandler({
            target: this.SelectElement,
          });
        }
      }
    } else if (this.props.value !== this.SelectElement.value) {
      this.$select2.val(this.props.value).trigger('change.select2');
      // check after selected value
      // ว่ามี selected value มี value ที่ props มีไหม ถ้าไม่มีเรียก onChange โดยยึด value จาก select
      if (this.props.value !== this.SelectElement.value && !this.props.noValidateOption) {
        // console.log(this.props.value ,this.SelectElement.value ,this.props.noValidateOption)
        this.onChangeHandler({
          target: this.SelectElement,
        });
      }
    }
  }

  componentWillUnmount() {
    // destroy
    this.$select2.off('change', this.onChangeHandler);
    this.$select2.select2('destroy');
    this.$select2.off('select2:select');
  }

  onChangeHandler = (e) => {
    // console.log('oncahnge',e.target.name,e.target.value)
    this.props.onChange({
      name: e.target.name,
      value: this.$select2.val(),
      type: e.target.type,
      label: window.$(e.target).find('option:selected').text(),
    });
  };

  render() {
    return (
      <div>
        <select
          tabIndex="1"
          ref={(el) => (this.SelectElement = el)}
          onChange={() => {}}
          className="form-control"
          multiple={this.props.notMultiple ? '' : 'multiple'}
          name={this.props.name}
          style={this.props.style || {}}
          disabled={!!this.props.disabled}
          required={!!this.props.required}
        >
          {this.props.children}
        </select>
      </div>
    );
  }
}

export const optionForAjaxUserFromAd = {
  ajax: {
    url: endpoint('/Master/GetMasterUserFromAd'),
    data(params) {
      const query = {
        cn: params.term,
      };
      return query;
    },
    processResults(data) {
      return {
        results: data.map((m) => ({
          id: m.sAMAccountName,
          text: m.displayName,
        })),
      };
    },
  },
  minimumInputLength: 2,
};
