import React from 'react';

import Card from '../../common/Card';
import Link from '../../common/Link';
import Col2 from '../../common/Col2';
import AttachFile from './AttachFile';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import MasterDropdown from '../../common/MasterDropdown';
import { toStatus, getPermission } from '../../common/helpper';
import { MASTER_APPROVER } from '../../reducers/master/action';
import { DATATABLE_ID_ROLE, DATATABLE_ID_COMPANY } from './UserDetailContainer';

const permission = getPermission('Common Master', 'User');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

const optCompany = {
  order: [[3, 'asc']],
  rowCallback: (tr, r, idx, cells) => {
    if (r.isActiveUserCompany) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      data: 'isActiveUserCompany',
      orderable: false,
      colReorder: false,
      className: `text-center col-label-checkbox result-company`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${
          d ? 'checked="true"' : ''
        }/><label class="label_checkbox"></label>`;
      },
    },
    { data: 'financeGroupName' },
    { data: 'businessUnitName' },
    {
      data: 'companyCode',
      className: 'text-center',
      render: (d) => `<a target="_blank" href="/master/company-detail/${d}">${d}</a>`,
    },
    { data: 'companyAbbreviate', className: 'text-center' },
    { data: 'companyName' },
    {
      data: 'isActiveCompany',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
  ],
};

const optRole = {
  order: [[1, 'asc']],
  rowCallback: (tr, r, idx, cells) => {
    if (r.isActiveUserRole) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      data: 'isActiveUserRole',
      orderable: false,
      colReorder: false,
      className: `text-center col-label-checkbox result-role`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${
          d ? 'checked="true"' : ''
        }/><label class="label_checkbox"></label>`;
      },
    },
    { data: 'roleId', className: 'text-center' },
    {
      data: 'roleName',
      render: (d, t, r) => `<a target="_blank" href="/master/role-detail/${r.roleId}">${d}</a>`,
    },
    {
      data: 'isActiveRole',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
  ],
};

export default ({
  state,
  onChangeInputData,
  onSubmit,

  dataTableCompanyRef,
  resultCompany,

  dataTableRoleRef,
  resultRole,

  attachFileRef,
}) => {
  const text = state.mode === 'edit' ? `Username: ${state.Username}` : 'Create User';
  const checkSave = !!(
    state.mode === 'edit' &&
    !state.IsActive &&
    state.ApproveStatus !== 'Rejected'
  );
  const isApproverRole = Boolean(
    resultRole.find(
      (f) => f.isActiveUserRole && (f.roleName === 'Approver 1' || f.roleName === 'Approver 2')
    )
  );

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Common Master <span className="icon icon-angle-double-right" />
            <Link txt="User" href="/master/user" />
            <span className="icon icon-angle-double-right" />
            <Link txt="User Detail" href={`/master/user-detail/${state.Username}`} />
            <span className="icon icon-angle-double-right" /> {state.Username}{' '}
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card
          textHeader={text}
          bgHeader="bg-primary"
          number="1"
          cardActions={['toggler']}
          footer={
            !permission ? (
              ''
            ) : (
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            )
          }
        >
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Username" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  disabled
                  value={state.Username || ''}
                  name="Username"
                />
              </FormGroup2>

              <FormGroup2 text="Group Name" required>
                <input
                  className="form-control"
                  onChange={onChangeInputData}
                  required
                  value={state.GroupName || ''}
                  name="GroupName"
                />
              </FormGroup2>
            </Col2>

            {isApproverRole && (
              <Col2 col={colX[1]}>
                <FormGroup2 text="Approver" required>
                  <MasterDropdown
                    masterType={MASTER_APPROVER}
                    isChoose
                    notMultipleSelect2
                    value={state.Approver1 || ''}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    filter={(m) => m.roleName === 'Approver 1' || m.roleName === 'Approver 2'}
                    financeGroupId="all"
                    noValidateOption
                    saveLocalState
                    required
                    name="Approver1"
                  />
                </FormGroup2>
              </Col2>
            )}
          </Col2>
          {renderAttachFile()}
        </Card>
      </form>

      <Card
        textHeader="Authorize Company"
        number="2"
        bgHeader="bg-primary"
        cardActions={['toggler']}
      >
        <Table
          ref={dataTableCompanyRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID_COMPANY}
          option={optCompany}
          value={resultCompany}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all result-company" />
              </th>
              <th>
                Finance
                <br />
                Group
              </th>
              <th>
                Business
                <br />
                Unit
              </th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>
                Company
                <br />
                Short Name
              </th>
              <th>Company Name</th>
              <th>Status</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Card textHeader="Authorize Role" number="3" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRoleRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID_ROLE}
          option={optRole}
          value={resultRole}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all result-role" />
              </th>
              <th>
                Role
                <br /> ID
              </th>
              <th>Role Name</th>
              <th>Status</th>
            </tr>
          </thead>
        </Table>
      </Card>
    </div>
  );

  function renderAttachFile() {
    return (
      <Card textHeader="Attach Files">
        <AttachFile
          ref={attachFileRef}
          limitFileCount={-1}
          masterType="masteruser"
          masterKey={state.Username.replace(/\./g, '_')}
        />
      </Card>
    );
  }
};
