import React from 'react';

import Modal from '../../common/Modal';
import Button from '../../common/Button';
import FormGroup3 from '../../common/FormGroup3';

const colX = ['col-md-5', 'col-md-5'];

export default ({ modalRef, state, onSubmit, onChageModal }) => {
  const textHeader =
    state.Type === 'post'
      ? 'Manual Post'
      : state.Type === 'reverse'
        ? 'Manual Reverse'
        : 'Manual Clearing';
  return (
    <Modal textHeader={textHeader} ref={modalRef} bgHeader="bg-primary">
      <form onSubmit={onSubmit} className="form-horizontal">
        {state.Type === 'post' ? (
          <>
            <FormGroup3 text="Posting Document No" col={colX} required>
              <input
                className="form-control"
                onChange={onChageModal}
                value={state.PostingDocumentNo}
                required
                name="PostingDocumentNo"
              />
            </FormGroup3>

            <FormGroup3 text="Posting Message" col={colX} required>
              <textarea
                className="form-control"
                rows="2"
                onChange={onChageModal}
                value={state.PostingMessage}
                required
                name="PostingMessage"
              />
            </FormGroup3>
          </>
        ) : null}

        {state.Type === 'reverse' ? (
          <>
            <FormGroup3 text="Reverse Document No" col={colX} required>
              <input
                className="form-control"
                onChange={onChageModal}
                value={state.ReverseDocumentNo}
                required
                name="ReverseDocumentNo"
              />
            </FormGroup3>

            <FormGroup3 text="Reverse Message" col={colX} required>
              <textarea
                className="form-control"
                rows="2"
                onChange={onChageModal}
                value={state.ReverseMessage}
                required
                name="ReverseMessage"
              />
            </FormGroup3>
          </>
        ) : null}

        {state.Type === 'clearing' || state.Type === 'reverse' ? (
          <>
            <FormGroup3 text="Clearing Document No" col={colX} required>
              <input
                className="form-control"
                onChange={onChageModal}
                value={state.ClearingDocumentNo}
                required
                name="ClearingDocumentNo"
              />
            </FormGroup3>

            <FormGroup3 text="Clearing Message" col={colX} required>
              <textarea
                className="form-control"
                rows="2"
                onChange={onChageModal}
                value={state.ClearingMessage}
                required
                name="ClearingMessage"
              />
            </FormGroup3>
          </>
        ) : null}

        <br />
        <div>
          <center>
            <Button txt="Confirm" icon="save" className="btn-success" type="submit" />
          </center>
        </div>
      </form>
    </Modal>
  );
};
