import { connect } from 'react-redux';
import React, { Component } from 'react';

import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import SearchMaintainBalance from './SearchMaintainBalance';
import {
  editCriteria,
  clearCriteria,
  updateSearchResult,
  saveMaintainBalance,
  searchMaintainBalance,
} from '../../reducers/automateBalance/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-automate-balance';

const initData = {
  MaintainBalanceId: '',
  CompanyCode: '',
  MinBalanceAmount: '',
  Currency: 0,
  IntgralMultiple: '',
  Action: 'create',
};

class SearchMaintainBalanceContainer extends Component {
  state = {
    modalData: {
      ...initData,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'Active'));
    $('body').on('click', `button.inactive`, (e) =>
      this.onClickOpenModalChangeStatus(e, 'Inactive')
    );
    $('body').on('click', `button.edit`, (e) => this.onClickOpenModalEditData(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
    $('body').off('click', `button.edit`);
  }

  // ---------------- Search ----------------
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) this.props.editCriteria(e.name, ['']);
    else if (e.value.indexOf('') !== -1)
      this.props.editCriteria(
        e.name,
        e.value.filter((item) => item !== '')
      );
    else this.props.editCriteria(e.name, e.value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchMaintainBalance({ ...this.props.criteria });
  };

  onClickClearSearch = () => {
    this.props.clearCriteria();
  };

  // ---------------- Modal ----------------
  onClickOpenModalData = (e) => {
    this.setState(
      {
        modalData: {
          ...initData,
        },
      },
      this.modalDataRef.open
    );
  };

  onClickOpenModalEditData = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();
    this.setState(
      {
        modalData: {
          ...initData,
          ...toUpperKey(rowData),
          Action: 'edit',
        },
      },
      this.modalDataRef.open
    );
  };

  onChangeModalData = (e) => {
    const { name, value } = e.target;
    this.setState({
      modalData: {
        ...this.state.modalData,
        [name]: value,
      },
    });
  };

  onClickSaveModalData = (e) => {
    e.preventDefault();
    const d = { ...this.state.modalData };
    if (Number(d.MinBalanceAmount || 0) <= 0) {
      this.props.addAlert({
        title: 'Error',
        body: 'Min balance amount must more than zero.',
        type: 'error',
      });
      return;
    }

    if (Number(d.IntgralMultiple || 0) <= 0) {
      this.props.addAlert({
        title: 'Error',
        body: 'Intgral multiple must more than zero.',
        type: 'error',
      });
      return;
    }

    this.props.saveMaintainBalance({ ...this.state.modalData }).then((response) => {
      if (response.error) return;

      this.modalDataRef.close();
      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchMaintainBalance({ ...this.props.criteria });
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      modalData: {
        ...initData,
        ...toUpperKey(rowData),
        Action: 'status',
      },
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onChangeModalData: this.onChangeModalData,
      onClickSaveModalData: this.onClickSaveModalData,
      onClickOpenModalData: this.onClickOpenModalData,
    };

    return (
      <div>
        <SearchMaintainBalance
          {...props}
          {...this.state}
          criteria={this.props.criteria}
          results={this.props.searchResult}
          modalData={this.state.modalData}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalInactiveRef={(e) => (this.modalInactiveRef = e)}
          modalDataRef={(e) => (this.modalDataRef = e)}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.automateBalance,
  }),
  {
    searchMaintainBalance,
    editCriteria,
    clearCriteria,
    updateSearchResult,
    addAlert,
    saveMaintainBalance,
  }
)(SearchMaintainBalanceContainer);
