import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_CURRENCY_CRITERIA = 'EDIT_MASTER_CURRENCY_CRITERIA';
export const CLEAR_MASTER_CURRENCY_CRITERIA = 'CLEAR_MASTER_CURRENCY_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_CURRENCY = 'UPDATE_SEARCH_RESULT_MASTER_CURRENCY';

export const REQUEST_SEARCH_MASTER_CURRENCY = '@@request/SARCH_MASTER_CURRENCY';
export const RECEIVE_SEARCH_MASTER_CURRENCY = '@@receive/SEARCH_MASTER_CURRENCY';
export const FAIL_SEARCH_MASTER_CURRENCY = '@@fail/SEARCH_MASTER_CURRENCY';

export const REQUEST_SAVE_MASTER_CURRENCY = '@@request/SAVE_MASTER_CURRENCY';
export const RECEIVE_SAVE_MASTER_CURRENCY =
  '@@receive-The record was successfully saved/SAVE_MASTER_CURRENCY';
export const FAIL_SAVE_MASTER_CURRENCY = '@@fail/SAVE_MASTER_CURRENCY';

export const REQUEST_DELETE_MASTER_CURRENCY = '@@request/DELETE_MASTER_CURRENCY';
export const RECEIVE_DELETE_MASTER_CURRENCY =
  '@@receive-The record was successfully saved/DELETE_MASTER_CURRENCY';
export const FAIL_DELETE_MASTER_CURRENCY = '@@fail/DELETE_MASTER_CURRENCY';

export const deleteMasterCurrency = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemastercurrency`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DELETE_MASTER_CURRENCY,
      RECEIVE_DELETE_MASTER_CURRENCY,
      FAIL_DELETE_MASTER_CURRENCY,
    ],
  },
});

export const saveMasterCurrency = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemastercurrency`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MASTER_CURRENCY, RECEIVE_SAVE_MASTER_CURRENCY, FAIL_SAVE_MASTER_CURRENCY],
  },
});

export const searchMasterCurrency = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmastercurrency', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_CURRENCY,
      RECEIVE_SEARCH_MASTER_CURRENCY,
      FAIL_SEARCH_MASTER_CURRENCY,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_CURRENCY,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_CURRENCY_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_CURRENCY_CRITERIA,
  payload: {
    name,
    value,
  },
});
