import { defaultImportExcelResult } from '../configuration';
import {
  UPDATE_FOWARDNO_FOR_UNWIND,
  UPDATE_FOWARDNO_FOR_ROLLOVER,
  EDIT_MANAGE_FX_CONTRACT_CRITERIA,
  RECEIVE_SEARCH_MANAGE_FX_CONTRACT,
  CLEAR_MANAGE_FX_CONTRACT_CRITERIA,
  FAIL_SAVE_IMPORT_EXCEL_FX_CONTRACT,
  RECEIVE_SET_ACTIVE_FORWARD_CONTRACT,
  RECEIVE_VIEW_IMPORT_EXCEL_FX_CONTRACT,
  RECEIVE_SAVE_IMPORT_EXCEL_FX_CONTRACT,
  RECEIVE_SET_INACTIVE_FORWARD_CONTRACT,
  UPDATE_SEARCH_RESULT_MANAGE_FX_CONTRACT,
} from './action';

const initCriteria = {
  FinanceGroup: '',
  BusinessUnits: [''],
  Companies: [''],
  ProductType: '',
  TransactionType: '',
  ForwardType: '',
  Banks: [''],
  ContractNo: '',
  ContractDateStr: '',
  StartDateStr: '',
  EndDateStr: '',
  ForwardCurrency: '',
  BookBankCurrency: '',
  Purposes: [''],
  DollarLinked: null,
  RequestType: '',
  ReportDateStr: '',
  Status: 'Active',
  SearchType: 'Summary',
  Product: '',
  TransactionNo: '',
  AssignTo: [''],
  ValueDateStr: '',

  modeResult: '',
};
const init = {
  searchResult: [],
  importExcel: {
    ...defaultImportExcelResult,
  },
  fowardNoForRollOver: [],
  forwardNoForUnwind: [],
  criteria: {
    ...initCriteria,
  },
};

export default (state = init, action) => {
  switch (action.type) {
    case CLEAR_MANAGE_FX_CONTRACT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_MANAGE_FX_CONTRACT_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SET_INACTIVE_FORWARD_CONTRACT:
    case RECEIVE_SET_ACTIVE_FORWARD_CONTRACT:
      return {
        ...state,
        searchResult: state.searchResult.map((m) => {
          if (m.forwardNo === action.payload.forwardNo)
            return {
              ...m,
              status: action.payload.status,
            };
          return m;
        }),
      };
    case RECEIVE_VIEW_IMPORT_EXCEL_FX_CONTRACT:
      return {
        ...state,
        importExcel: action.payload,
      };
    case FAIL_SAVE_IMPORT_EXCEL_FX_CONTRACT:
    case RECEIVE_SAVE_IMPORT_EXCEL_FX_CONTRACT:
      return {
        ...state,
        importExcel: defaultImportExcelResult,
      };
    case RECEIVE_SEARCH_MANAGE_FX_CONTRACT:
      return {
        ...state,
        searchResult: action.payload,
      };
    case UPDATE_FOWARDNO_FOR_ROLLOVER:
      return {
        ...state,
        fowardNoForRollOver: action.payload,
      };
    case UPDATE_FOWARDNO_FOR_UNWIND:
      return {
        ...state,
        forwardNoForUnwind: action.payload,
      };
    case UPDATE_SEARCH_RESULT_MANAGE_FX_CONTRACT:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    default:
      return state;
  }
};
