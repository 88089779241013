import React, { useRef, useEffect, forwardRef, useImperativeHandle } from 'react';

const $ = window.jQuery;
export default forwardRef(({ option }, ref) => {
  const containerRef = useRef(null);
  const caledarApi = useRef(null);
  useImperativeHandle(
    ref,
    () => ({
      caledarApi: caledarApi.current,
    }),
    [caledarApi.current]
  );
  useEffect(() => {
    const opt = {
      ...(option || {}),
    };
    const $calEle = $('<div></div>');
    $calEle.appendTo(containerRef.current);
    caledarApi.current = $calEle.calendar(opt);

    return () => {
      $calEle.remove();
    };
  }, []);

  return <div ref={containerRef} />;
});
