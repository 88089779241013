import React, { Component } from 'react';

export default class SpanStatus extends Component {
  render() {
    const { status } = this.props;
    let s = '';
    if (status && status !== '') s = (status || '').toLowerCase();

    let text = '';
    if (!status || status === '') text = '';
    else if (~['wait for cheque no.', 'in progress', 'wait for doa approval'].indexOf(s))
      text = status;
    else text = `${s[0].toUpperCase()}${s.substr(1)}`;

    return (
      <>
        {!text || !s ? null : ~[
            'repay',
            'withdraw',
            'rollover',
            'repayment',
            'new',
            'currently using',
          ].indexOf(s) ? (
          <span className="label label-info">{text}</span>
        ) : ~['active', 'approved', 'completed', 'accepted by bank', 'confirmed', 'yes'].indexOf(
            s
          ) ? (
          <span className="badge badge-success">{text}</span>
        ) : ~['inactive', 'disapprove', 'rejected', 'rejected by bank', 'failed', 'no'].indexOf(
            s
          ) ? (
          <span className="badge badge-danger">{text}</span>
        ) : ~['already send to bank', 'in progress'].indexOf(s) || s.includes('wait') ? (
          <span className="badge badge-primary">{text}</span>
        ) : ~['withdraw', 'create', 'created'].indexOf(s) ? (
          <span className="badge badge-warning">{text}</span>
        ) : ~['cancel', 'exported', 'voided', 'deleted', 'cancelled'].indexOf(s) ? (
          <span className="badge badge-default">{text}</span>
        ) : (
          <span className="badge badge-default">{text}</span>
        )}
      </>
    );
  }
}
