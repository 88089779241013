import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import { store } from '../../Root';
import Alert from '../../common/ModalAlert';
import { toLower } from '../../common/helpper';
import { downloadAttachment } from '../../reducers/requestBg/action';

// let permission = getPermissionStr('Bank Guarantee', 'Request') === 'View' ? false : true
const permission = true;

const LimitFileSize = 1024 * 1024 * 5;
const typeName = (name, required, show) => {
  name = name || '';
  if (!name) return '';
  if (!required) return name;

  return (
    <span>
      {name}{' '}
      {show && (
        <>
          {name === 'Approved Memo'
            ? '(บันทึกที่ได้รับการอนุมัติ)'
            : name === 'Supporting Document'
              ? '(เอกสารประกอบ)'
              : ''}
        </>
      )}
      <span style={{ color: 'red' }}>*</span>
    </span>
  );
};

let id = 1;

const AttachmentList = (
  {
    values = [],
    requiredTypes = [],
    statusLg = '',
    isModal = false,
    isBuApprove = false,
    canAttachFile = false,
    showTh = false,
    modeAmend = false,
  },
  ref
) => {
  const fileRef = useRef(null);
  const alert = useRef(null);
  const [currentSelectFileIdx, setCurrentSelectFileIdx] = useState(null);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    // comput new data
    const newAttachments = [];

    values.forEach((m) => {
      const findType = requiredTypes.find((f) => f.type === m.type) || {};
      newAttachments.push({
        ...m,
        required: !!findType.required,
        tempId: id++,
      });
    });

    requiredTypes.forEach((m) => {
      const findValue = values.find((f) => f.type === m.type);
      if (!findValue)
        newAttachments.push({
          type: m.type,
          required: m.required,
          tempId: id++,
        });
    });

    setAttachments(newAttachments);
    setCurrentSelectFileIdx(null);
  }, [values]);

  useEffect(() => {
    setAttachments((state) =>
      state.map((m) => {
        const findRequired = requiredTypes.find((f) => f.type === m.type);
        if (findRequired)
          return {
            ...m,
            required: findRequired.required,
          };
        return m;
      })
    );
  }, [requiredTypes]);

  const validate = useCallback(() => {
    let msg = '';
    const errorRequired = attachments.find((f) => {
      if (!f.required) return false;
      if (!f.id && !f.file) return true;
      return false;
    });
    if (errorRequired) msg = `Attachment Type: ${errorRequired.type} is required.`;

    if (msg)
      alert.current.open({
        type: 'error',
        title: 'Error',
        body: msg,
      });
    return !msg;
  }, [attachments]);
  const onFileChange = useCallback(
    (e) => {
      const { files } = e.target;
      if (!files.length) return;
      const file = files[0];
      const exe = (file.name.split('.').slice(-1)[0] || '').toLowerCase();
      if (exe !== 'pdf') {
        alert.current.open({
          type: 'warning',
          title: 'Warning',
          body: 'Please upload pdf file.',
        });
        return;
      }

      if (file.size > LimitFileSize) {
        alert.current.open({
          type: 'warning',
          title: 'Warning',
          body: 'This file has over 5 MB.',
        });
        return;
      }

      setAttachments((state) =>
        state.map((m, i) => {
          if (i === currentSelectFileIdx)
            return {
              ...m,
              file,
              fileName: file.name,
            };
          return m;
        })
      );

      e.target.value = '';
    },
    [attachments, currentSelectFileIdx]
  );

  const onClickSelectFile = useCallback((i) => {
    setCurrentSelectFileIdx(i);
    fileRef.current.click();
  }, []);
  const onClickRemove = useCallback((i) => {
    setAttachments((state) => {
      const newState = state
        .map((m, idx) => {
          if (i === idx)
            return {
              tempId: m.tempId,
              type: m.type,
              required: m.required,
            };
          return m;
        })
        .filter((f, idx, s) => {
          // remove empty file of type that have aother same type that have the file attahc
          if (idx === i) {
            const findSameType = s.find((s) => s !== f && s.type === f.type);
            return !findSameType;
          }
          return true;
        });

      return newState;
    });
  }, []);

  const onChangeInput = useCallback((e, i) => {
    const { name, value } = e.target;
    setAttachments((state) =>
      state.map((m, idx) => {
        if (i === idx)
          return {
            ...m,
            [name]: value,
          };
        return m;
      })
    );
  });
  const onClickDownload = useCallback(
    (i) => {
      const item = attachments[i];

      if (!item && !item.id) return;
      store.dispatch(downloadAttachment(item.id));
    },
    [attachments]
  );

  const addFileType = useCallback(
    (type) => {
      const required = requiredTypes.find((f) => f.type === type);
      setAttachments((state) => [
        ...state,
        {
          type: required.type,
          required: required.required,
          tempId: id++,
        },
      ]);
      setCurrentSelectFileIdx(null);
    },
    [requiredTypes]
  );

  useImperativeHandle(
    ref,
    () => ({
      checkValidity: validate,
      getAttachments: () => attachments,
      addFileType,
      attachments,
      name: 'attachment',
      reportValidity: () => {},
    }),
    [validate, attachments, addFileType]
  );

  return (
    <div>
      <Alert ref={alert} />
      <input type="file" ref={fileRef} style={{ display: 'none' }} onChange={onFileChange} />
      {renderTableList()}
    </div>
  );

  function renderTableList() {
    return (
      <div className="table-responsive">
        <table className="table table-bordered table-nowrap">
          <thead>
            <tr>
              <th>
                Remove{' '}
                {showTh && (
                  <>
                    <br />
                    (ปุ่มสำหรับลบไฟล์)
                  </>
                )}
              </th>
              <th>
                Upload/Replace{' '}
                {showTh && (
                  <>
                    <br />
                    (ปุ่มสำหรับอัพโหลดไฟล์)
                  </>
                )}
              </th>
              <th>
                Download{' '}
                {showTh && (
                  <>
                    <br />
                    (ปุ่มสำหรับดาวน์โหลดไฟล์)
                  </>
                )}
              </th>
              <th>
                Name{' '}
                {showTh && (
                  <>
                    <br />
                    (ชื่อไฟล์)
                  </>
                )}
              </th>
              <th>
                Type{' '}
                {showTh && (
                  <>
                    <br />
                    (ประเภทของไฟล์)
                  </>
                )}
              </th>
              <th>
                Remark{' '}
                {showTh && (
                  <>
                    <br />
                    (หมายเหตุของไฟล์)
                  </>
                )}
              </th>
            </tr>
          </thead>
          <tbody>{renderTableBody()}</tbody>
        </table>
      </div>
    );
  }

  function renderTableBody() {
    const checkStatus = modeAmend
      ? true
      : (!statusLg || ~['wait for confirm', 'created', 'draft'].indexOf(toLower(statusLg))) &&
        !isModal;
    const checkTicket = toLower(statusLg) === 'confirmed' && isModal && canAttachFile;
    return attachments.map((m, i) => (
      <tr key={i}>
        <td align="center">
          {!permission ? null : (m.id || m.file) &&
            ((m.type === 'Copy BG' && checkTicket) || checkStatus || m.type === 'Others') ? (
            <BtnIcon className="btn-warning" icon="close" onClick={(e) => onClickRemove(i)} />
          ) : null}
        </td>
        <td align="center">
          {!permission ? null : (m.type === 'Copy BG' && checkTicket) ||
            checkStatus ||
            m.type === 'Others' ? (
            <BtnIcon className="btn-info" icon="upload" onClick={(e) => onClickSelectFile(i)} />
          ) : null}
        </td>
        <td align="center">
          {m.id ? (
            <BtnIcon className="btn-success" icon="download" onClick={(e) => onClickDownload(i)} />
          ) : null}
        </td>
        <td align="center">{m.fileName}</td>
        <td align="center">
          {typeName(m.type, isBuApprove && m.type === 'Approved Memo' ? false : m.required, showTh)}
        </td>
        <td align="center">
          {!permission ? null : (m.type === 'Copy BG' && checkTicket) ||
            checkStatus ||
            m.type === 'Others' ? (
            <input
              className="form-control"
              type="text"
              name="remark"
              value={m.remark || ''}
              onChange={(e) => onChangeInput(e, i)}
            />
          ) : (
            m.remark
          )}
        </td>
      </tr>
    ));
  }
};

function BtnIcon({ icon, className, ...rest }) {
  return (
    <button type="button" className={`btn btn-sm ${className}`} {...rest}>
      <i className={`icon icon-fw icon-lg icon-${icon}`} />
    </button>
  );
}

export default forwardRef(AttachmentList);

export const appendForm = (form = new FormData(), attachments = []) => {
  const obj = [];
  attachments.forEach((m) => {
    if (m.file) form.append(`atFile_${m.tempId}`, m.file);
    const x = {
      ...m,
    };

    delete x.file;
    obj.push(x);
  });
  form.append('attache_json', JSON.stringify(obj));
};
