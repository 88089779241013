const PREFIX_PATH = '/api/master';

const getmasteruser = {
  path: `${PREFIX_PATH}/getmasteruser`,
  payload: [
    {
      email: 'gg@gg.gg',
    },
  ],
};

const mockup = [getmasteruser];

export default mockup;
