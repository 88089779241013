import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import { store } from '../../../Root';
import Card from '../../../common/Card';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import {} from '../../../reducers/common/action';
import InputMask from '../../../common/InputMask';
import DatePicker from '../../../common/DatePicker';
import FormGroup3 from '../../../common/FormGroup3';
import { setAmount } from '../../../common/helpper';
import { StateContext } from './CreateContractLoan';
import { addAlert } from '../../../reducers/layout/action';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { saveChargeCashflowContractLoan } from '../../../reducers/contractLoan/action';
import {
  SWAP_LOAN,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BANK_ACCOUNT,
} from '../../../reducers/master/action';

const colX2 = ['col-md-4', 'col-md-7'];
export default ({}) => {
  const { state, dispatch } = useContext(StateContext);
  const [chargeAction, setChargeAction] = useState(null);
  const chargeModalRef = useRef(null);
  // add charge
  const onClickOpenChargeModal = useCallback((action) => {
    setChargeAction(action);
    chargeModalRef.current.modal.open();
  }, []);
  const onSubmitAddCharge = useCallback(
    (charge) => {
      const { TransactionNo, PartnerType, CompanyCurrency } = state;

      dispatch({
        type: 'ADD_CASHFLOW_CHARGE',
        payload: {
          ...charge,
          CashFlowType: chargeAction,
          Source: 'Contract Loan',
          TransactionNo,
          IsManualCreate: true,
          LocalCurrency: CompanyCurrency,
        },
        cashFlowType: chargeAction,
      });
      if (PartnerType === 'Intercompany')
        dispatch({
          type: 'ADD_CASHFLOW_CHARGE',
          payload: {
            ...charge,
            CashFlowType: chargeAction === 'Loan' ? 'Deposit' : 'Loan',
            Source: 'Contract Loan',
            TransactionNo,
            IsManualCreate: true,
            Direction: charge.Direction === '+' ? '-' : '+',
            LocalCurrency: CompanyCurrency,
          },
          cashFlowType: chargeAction === 'Loan' ? 'Deposit' : 'Loan',
        });
      chargeModalRef.current.modal.close();
    },
    [state, chargeAction]
  );
  // add charge

  // save
  const onSubmitSaveCharge = useCallback(
    (e) => {
      e.preventDefault();

      async function submit() {
        const { BankCodeLoanCharge, BankCodeDepositCharge, TransactionNo } = state;
        const submitData = {
          BankCodeLoanCharge,
          BankCodeDepositCharge,
          CashFlows: [...state.CashFlowChargeLoan, ...state.CashFlowChargeDeposit],
          TransactionNo,
        };
        const response = await store.dispatch(saveChargeCashflowContractLoan(submitData));
        if (response.error) return;

        store.dispatch(
          addAlert({
            title: 'Success',
            body: 'Save Charge Success',
            buttons: [
              <Button
                txt="Continue"
                className="btn-success"
                onClick={(e) => window.location.reload()}
              />,
            ],
          })
        );
      }
      submit();
    },
    [state]
  );

  return (
    <>
      <ChargeModal ref={chargeModalRef} onConfirm={onSubmitAddCharge} />
      {renderCharge()}
    </>
  );

  function renderCharge() {
    const cashFlowTypeContent = [renderCashFlowType('Loan')];
    if (state.PartnerType !== 'External') cashFlowTypeContent.push(renderCashFlowType('Deposit'));

    return (
      <form onSubmit={onSubmitSaveCharge}>
        {cashFlowTypeContent}
        <div className="row">
          <div className="col-sm-12 text-center">
            <Button txt="Save Charge" type="submit" icon="save" className="btn-success" />
          </div>
        </div>
      </form>
    );
  }

  function renderCashFlowType(type) {
    return (
      <Card key={type} textHeader={type} bgHeader="bg-primary" cardActions={['toggler']}>
        <Button
          txt="Add Charge"
          icon="plus"
          className="btn-warning"
          onClick={(e) => onClickOpenChargeModal(type)}
        />
        <br />

        {renderBankCodeDropdown(type)}
        <ChargeTableCashFlow type={type} />
      </Card>
    );
  }

  function renderBankCodeDropdown(type) {
    let companyCodeBank = '';
    if (state.TransactionType === 'Deposit')
      companyCodeBank = type === 'Loan' ? state.BusinessPartner : state.CompanyCode;
    else companyCodeBank = type === 'Loan' ? state.CompanyCode : state.BusinessPartner;

    const bankCodeKey = `BankCode${type}Charge`;
    const bankCode = state[bankCodeKey] || '';
    const onChangeBank = useCallback((e) => {
      dispatch({
        type: 'UPDATE',
        name: bankCodeKey,
        value: e.value,
      });
    });
    return (
      <div className="row">
        <div className="form-group col-sm-6">
          <label>Bank</label>
          <MasterDropdown
            masterType={MASTER_BANK_CODE}
            noValidateOption
            notMultipleSelect2
            saveLocalState
            onChange={onChangeBank}
            isChoose
            required
            isGetAll
            customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
            companyCode={companyCodeBank}
            value={bankCode}
            name={bankCodeKey}
          />
        </div>
      </div>
    );
  }
};

function ChargeTableCashFlow({ type }) {
  const { state, dispatch } = useContext(StateContext);
  const cashFlows = state[`CashFlowCharge${type}`] || [];
  const bankCode = state[`BankCode${type}Charge`] || '';
  let companyCode = '';
  if (state.TransactionType === 'Deposit')
    companyCode = type === 'Deposit' ? state.CompanyCode : state.BusinessPartner;
  else companyCode = type === 'Loan' ? state.CompanyCode : state.BusinessPartner;

  const [bankAccountNoAll, setbankAccountNoAll] = useState('');
  const onChange = useCallback((e, index) => {
    const { name, value, datas } = e.target;
    dispatch({
      type: 'UPDATE_CASHFLOW_CHARGE',
      index,
      name,
      value,
      datas,
      cashFlowType: type,
    });
  }, []);

  const onChangeBankAccountAll = useCallback((e) => {
    setbankAccountNoAll(e.value);
    onChange(
      {
        target: {
          name: 'BankAccountNo',
          value: e.value,
          datas: e.datas,
        },
      },
      null
    );
  }, []);

  return (
    <div className="table-responsive">
      <table className="table table-bordered table-nowrap">
        <thead>
          <tr>
            <th className="th-white">Payment Date</th>
            <th className="th-white">Direction</th>
            <th className="th-white">Flow Type</th>
            <th className="th-white">Amount</th>
            <th className="th-white">Currency</th>
            <th className="th-white">
              Bank Account<span style={{ color: '#e64a19' }}>*</span>
              {cashFlows.length ? (
                <MasterDropdown
                  noValidateOption
                  masterType={MASTER_BANK_ACCOUNT}
                  isChoose
                  notMultipleSelect2
                  onChange={onChangeBankAccountAll}
                  saveLocalState
                  bankCode={bankCode}
                  companyCode={companyCode}
                  name="BankAccountNo"
                  value={bankAccountNoAll}
                  customeValue={(m) => `${m.bankAccountNo}`}
                />
              ) : null}
            </th>
            <th className="th-white">Payment Method</th>
            <th className="th-white">Swap Ref</th>
            <th className="th-white">
              Posting
              <br />
              Status
            </th>
            <th className="th-white">Exchange Rate</th>
            <th className="th-white">
              Local Currency
              <br />
              Amount
            </th>
            <th className="th-white">
              Local
              <br />
              Currency
            </th>
            <th className="th-white">Posting Date</th>
            <th className="th-white">Document No.</th>
          </tr>
        </thead>
        <tbody>
          {!(cashFlows || []).length ? (
            <tr>
              <td colSpan="17" align="center">
                No data available in table
              </td>
            </tr>
          ) : (
            renderBody()
          )}
        </tbody>
      </table>
    </div>
  );

  function renderBody() {
    return cashFlows.map((m, idx) => {
      const { digit } = m;
      return (
        <tr key={idx}>
          <td>
            <div className="td-input-date">
              <DatePicker
                value={m.PaymentDateStr}
                onChange={(e) => onChange(e, idx)}
                option={{
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                required
                name="PaymentDateStr"
              />
            </div>
          </td>
          <td align="center">{m.Direction}</td>
          <td align="center">{m.FlowType}</td>
          <td align="right">{setAmount(m.Amount, digit)}</td>
          <td align="center">{m.Currency}</td>
          <td>
            <div className="td-input-account">
              {bankCode ? (
                <MasterDropdown
                  noValidateOption
                  masterType={MASTER_BANK_ACCOUNT}
                  isChoose
                  notMultipleSelect2
                  value={m.BankAccountNo || ''}
                  onChange={(e) =>
                    onChange(
                      {
                        target: e,
                      },
                      idx
                    )
                  }
                  saveLocalState
                  bankCode={bankCode}
                  companyCode={companyCode}
                  name="BankAccountNo"
                  required
                  customeValue={(m) => `${m.bankAccountNo}`}
                />
              ) : null}
            </div>
          </td>
          <td>
            <div className="td-input-method">
              <MasterDropdownUI
                onChange={(e) =>
                  onChange(
                    {
                      target: e,
                    },
                    idx
                  )
                }
                required
                value={m.PaymentMethod || ''}
                isChoose
                notMultipleSelect2
                name="PaymentMethod"
                options={[
                  'Bahtnet',
                  'Cheque',
                  'Internet Banking',
                  'Auto Deduct',
                  'T/T',
                  'T/T Swaper',
                ]}
              />
            </div>
          </td>
          <td>
            {m.PaymentMethod === 'T/T Swaper' ? (
              <MasterDropdown
                noValidateOption
                masterType={SWAP_LOAN}
                isChoose
                notMultipleSelect2
                onChange={(e) =>
                  onChange(
                    {
                      target: e,
                    },
                    idx
                  )
                }
                includeId={m.OldSwapTransactionNo}
                saveLocalState
                transactionNo={state.TransactionNo}
                currency={m.Currency}
                type={m.FlowType}
                amount={m.Amount}
                paymentDateStr={m.PaymentDateStr}
                name="SwapTransactionNo"
                value={m.SwapTransactionNo || ''}
              />
            ) : null}
          </td>
          <td align="center">{m.PostingStatus}</td>
          <td align="right">{setAmount(m.ExchangeRate, 8)}</td>
          <td align="right">{setAmount(m.LocalCurrencyAmount, 3)}</td>
          <td align="center">{m.LocalCurrency}</td>
          <td align="center">{m.PostingDateStr}</td>
          <td align="center">
            <a href="#">{m.DocumentNo}</a>
          </td>
        </tr>
      );
    });
  }
}

const ChargeModal = forwardRef(({ onConfirm }, ref) => {
  const modalRef = useRef(null);
  useImperativeHandle(
    ref,
    () => ({
      modal: modalRef.current,
    }),
    [modalRef]
  );
  const [state, dispatch] = useReducer(chargeReducer, initCharge);
  const onChange = useCallback((e) => {
    const { name, value } = e.target;
    dispatch({
      type: 'UPDATE',
      name,
      value,
    });
  });
  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      onConfirm(state);
    },
    [state, onConfirm]
  );
  return (
    <Modal ref={modalRef} textHeader="Charge" bgHeader="bg-warning">
      <form className="form-horizontal" onSubmit={onSubmit}>
        <FormGroup3 text="Payment Date" col={colX2} required>
          <DatePicker
            value={state.PaymentDateStr}
            onChange={onChange}
            name="PaymentDateStr"
            required
            option={{
              todayHighlight: true,
            }}
          />
        </FormGroup3>
        <FormGroup3 text="Direction" col={colX2} required>
          <MasterDropdownUI
            value={state.Direction}
            name="Direction"
            isChoose
            notMultipleSelect2
            onChange={(e) =>
              onChange({
                target: e,
              })
            }
            required
            disabled={false}
            options={['+', '-']}
          />
        </FormGroup3>
        <FormGroup3 text="Flow Type" col={colX2} required>
          <MasterDropdownUI
            value={state.FlowType}
            name="FlowType"
            isChoose
            notMultipleSelect2
            onChange={(e) =>
              onChange({
                target: e,
              })
            }
            required
            disabled={false}
            options={[
              'Upfront Fee',
              'Commitment Fee',
              'Stamp Duty Fee',
              'Delay Payment Fee',
              'Prepayment Fee',
            ]}
          />
        </FormGroup3>
        <FormGroup3 text="Amount" col={colX2} required>
          <InputMask
            className="form-control"
            format="currency"
            option={{
              prefix: '',
              digits: 2,
            }}
            onChange={onChange}
            value={state.Amount}
            required
            name="Amount"
          />
        </FormGroup3>
        <FormGroup3 text="Currency" col={colX2} required>
          <MasterDropdown
            masterType={MASTER_CURRENCY}
            isChoose
            notMultipleSelect2
            onChange={(e) =>
              onChange({
                target: e,
              })
            }
            value={state.Currency}
            name="Currency"
            required
            disabled={false}
          />
        </FormGroup3>
        <FormGroup3 text="Payment Method" col={colX2} required>
          <MasterDropdownUI
            value={state.PaymentMethod}
            name="PaymentMethod"
            isChoose
            notMultipleSelect2
            onChange={(e) =>
              onChange({
                target: e,
              })
            }
            required
            disabled={false}
            options={['Bahtnet', 'Cheque', 'Internet Banking', 'Auto Deduct', 'T/T', 'T/T Swaper']}
          />
        </FormGroup3>
        <div className="row">
          <div className="col-sm-12 text-center">
            <Button txt="Add" icon="plus" className="btn-success" type="submit" />
          </div>
        </div>
      </form>
    </Modal>
  );
});

const initCharge = {
  PaymentDateStr: '',
  Direction: '',
  FlowType: '',
  Amount: '',
  Currency: '',
  PaymentMethod: '',
};
const chargeReducer = (state = initCharge, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        [action.name]: action.value,
      };
    default:
      return state;
  }
};
