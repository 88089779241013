import { UPDATE_TRANSFER_FEE_SETUP, RECEIVE_SEARCH_TRANSFER_FEE_SET_UP } from './action';

const init = {
  searchResult: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_SEARCH_TRANSFER_FEE_SET_UP:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    case UPDATE_TRANSFER_FEE_SETUP:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (i === action.payload.index)
            return {
              ...action.payload.data,
            };
          return m;
        }),
      };

    default:
      return state;
  }
};
