import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_BANK_BRANCH_CRITERIA = 'EDIT_MASTER_BANK_BRANCH_CRITERIA';
export const CLEAR_MASTER_BANK_BRANCH_CRITERIA = 'CLEAR_MASTER_BANK_BRANCH_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_BANK_BRANCH = 'UPDATE_SEARCH_RESULT_MASTER_BANK_BRANCH';

export const REQUEST_SEARCH_MASTER_BANK_BRANCH = '@@request/SARCH_MASTER_BANK_BRANCH';
export const RECEIVE_SEARCH_MASTER_BANK_BRANCH = '@@receive/SEARCH_MASTER_BANK_BRANCH';
export const FAIL_SEARCH_MASTER_BANK_BRANCH = '@@fail/SEARCH_MASTER_BANK_BRANCH';

export const REQUEST_SAVE_MASTER_BANK_BRANCH = '@@request/SAVE_MASTER_BANK_BRANCH';
export const RECEIVE_SAVE_MASTER_BANK_BRANCH = '@@receive/SAVE_MASTER_BANK_BRANCH';
export const FAIL_SAVE_MASTER_BANK_BRANCH = '@@fail/SAVE_MASTER_BANK_BRANCH';

export const REQUEST_DELETE_MASTER_BANK_BRANCH = '@@request/DELETE_MASTER_BANK_BRANCH';
export const RECEIVE_DELETE_MASTER_BANK_BRANCH =
  '@@receive-The record was successfully change status/DELETE_MASTER_BANK_BRANCH';
export const FAIL_DELETE_MASTER_BANK_BRANCH = '@@fail/DELETE_MASTER_BANK_BRANCH';

export const REQUEST_GET_BANK_BRANCH_EFFECTIVE_DATE = '@@request/GET_BANK_BRANCH_EFFECTIVE_DATE';
export const RECEIVE_GET_BANK_BRANCH_EFFECTIVE_DATE = '@@receive/GET_BANK_BRANCH_EFFECTIVE_DATE';
export const FAIL_GET_BANK_BRANCH_EFFECTIVE_DATE = '@@fail/GET_BANK_BRANCH_EFFECTIVE_DATE';

export const REQUEST_GET_BANK_BRANCH_DETAIL = '@@request/GET_BANK_BRANCH_DETAIL';
export const RECEIVE_GET_BANK_BRANCH_DETAIL = '@@receive/GET_BANK_BRANCH_DETAIL';
export const FAIL_GET_BANK_BRANCH_DETAIL = '@@fail/GET_BANK_BRANCH_DETAIL';

export const getBankBranchDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasterbankbranchdetail/`, data),
    method: 'GET',
    types: [
      REQUEST_GET_BANK_BRANCH_DETAIL,
      RECEIVE_GET_BANK_BRANCH_DETAIL,
      FAIL_GET_BANK_BRANCH_DETAIL,
    ],
  },
});

export const getMasterBankBranchffectiveDate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbankbranch', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_BANK_BRANCH_EFFECTIVE_DATE,
      RECEIVE_GET_BANK_BRANCH_EFFECTIVE_DATE,
      FAIL_GET_BANK_BRANCH_EFFECTIVE_DATE,
    ],
  },
});

export const deleteMasterBankBranch = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemasterbankbranch`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DELETE_MASTER_BANK_BRANCH,
      RECEIVE_DELETE_MASTER_BANK_BRANCH,
      FAIL_DELETE_MASTER_BANK_BRANCH,
    ],
  },
});

export const saveMasterBankBranch = (data, criteria) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterbankbranch`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_BANK_BRANCH,
      RECEIVE_SAVE_MASTER_BANK_BRANCH,
      FAIL_SAVE_MASTER_BANK_BRANCH,
    ],
  },
});

export const searchMasterBankBranch = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbankbranch', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_BANK_BRANCH,
      RECEIVE_SEARCH_MASTER_BANK_BRANCH,
      FAIL_SEARCH_MASTER_BANK_BRANCH,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_BANK_BRANCH,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_BANK_BRANCH_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_BANK_BRANCH_CRITERIA,
  payload: {
    name,
    value,
  },
});
