import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import RadioButton from '../../../common/RadioButton';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_CURRENCY } from '../../../reducers/master/action';
import MasterDropdownUI, { OPTION_WORKING_DAY } from '../../../common/MasterDropdownUI';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ state, modalRef, onSubmitModal, onChangeInputData, template = [] }) => {
  const required = true;
  const disabled = false;
  const setPeriodEndType = state.PeriodEndType !== 'End of Term';
  const setDueDateType = state.DueDateType !== 'End of Term';

  return (
    <Modal
      textHeader={`Swap Schedule ${state.Action === 'POS' ? 'POS' : 'IRS/CCS'}`}
      size="modal-xl"
      ref={modalRef}
      bgHeader="bg-primary"
    >
      <form onSubmit={onSubmitModal}>
        {state.Action === 'POS' ? (
          <Card>
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Template Structure Name" required={false}>
                  <select
                    tabIndex="-1"
                    className="form-control"
                    onChange={onChangeInputData}
                    required={false}
                    disabled={disabled}
                    value={state.InterestRateId}
                    name="InterestRateId"
                  >
                    <option value="">---Choose---</option>
                    {template.map((m) => (
                      <option value={m.interestRateId} key={m.interestRateId}>
                        {m.interestTemplateName}
                      </option>
                    ))}
                  </select>
                </FormGroup2>
              </Col2>
            </Col2>

            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Cal. Method" required>
                  <MasterDropdownUI
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    value={state.CalculateMethod}
                    name="CalculateMethod"
                    options={['Act/360', 'Act/365', 'Act/366', 'Actual']}
                  />
                </FormGroup2>

                <FormGroup2 text="Premium/Discount (%)" required>
                  <InputMask
                    className="form-control"
                    format="currency"
                    onChange={onChangeInputData}
                    option={{
                      prefix: '',
                      digits: 2,
                      digitsOptional: false,
                      placeholder: '0.00',
                    }}
                    required={required}
                    disabled={disabled}
                    value={state.PremiumDiscount}
                    name="PremiumDiscount"
                  />
                </FormGroup2>
              </Col2>
            </Col2>
          </Card>
        ) : (
          <Card>
            <Col2 col={colX[0]}>
              <Col2 col={colX[1]}>
                <FormGroup2 text="Template Structure Name" required={false}>
                  <select
                    tabIndex="-1"
                    className="form-control"
                    onChange={onChangeInputData}
                    required={false}
                    disabled={disabled}
                    value={state.InterestRateId}
                    name="InterestRateId"
                  >
                    <option value="">---Choose---</option>
                    {template.map((m) => (
                      <option value={m.interestRateId} key={m.interestRateId}>
                        {m.interestTemplateName}
                      </option>
                    ))}
                  </select>
                </FormGroup2>

                <FormGroup2 text="Interest Type" required>
                  <div>
                    <RadioButton
                      text="Fix Rate"
                      value="Fix Rate"
                      checked={state.InterestType === 'Fix Rate'}
                      onChange={onChangeInputData}
                      name="InterestType"
                      required={required}
                    />
                    <RadioButton
                      text="Float Rate"
                      value="Float Rate"
                      checked={state.InterestType === 'Float Rate'}
                      onChange={onChangeInputData}
                      name="InterestType"
                    />
                  </div>
                </FormGroup2>
              </Col2>

              {state.InterestType !== 'Fix Rate' ? (
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Ref Interest Rate Name" required>
                    <input
                      className="form-control"
                      onChange={onChangeInputData}
                      required={required}
                      disabled={disabled}
                      value={state.RefInterestRateName}
                      name="RefInterestRateName"
                    />
                  </FormGroup2>
                </Col2>
              ) : null}
            </Col2>

            {state.InterestType !== 'Fix Rate' ? (
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Ref Interest Rate" required>
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChangeInputData}
                      option={{
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }}
                      required={required}
                      disabled={disabled}
                      value={state.RefInterestRate}
                      name="RefInterestRate"
                    />
                  </FormGroup2>

                  <FormGroup2 text="+/-" required>
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChangeInputData}
                      option={{
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }}
                      required={required}
                      disabled={disabled}
                      value={state.Margin}
                      name="Margin"
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[1]}>
                  <FormGroup2 text="Interest" required>
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChangeInputData}
                      option={{
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }}
                      required={required}
                      disabled={disabled}
                      value={state.Interest}
                      name="Interest"
                    />
                  </FormGroup2>
                </Col2>
              </Col2>
            ) : (
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Interest" required>
                    <InputMask
                      className="form-control"
                      format="currency"
                      onChange={onChangeInputData}
                      option={{
                        prefix: '',
                        digits: 2,
                        digitsOptional: false,
                        placeholder: '0.00',
                      }}
                      required={required}
                      disabled={disabled}
                      value={state.Interest}
                      name="Interest"
                    />
                  </FormGroup2>
                </Col2>
              </Col2>
            )}
          </Card>
        )}

        <Col2 col={colX[0]}>
          <Col2 col={colX[2]}>
            <Card textHeader="Interest Period End" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Period End" required>
                  <div>
                    <RadioButton
                      text="End of Term"
                      value="End of Term"
                      checked={state.PeriodEndType === 'End of Term'}
                      onChange={onChangeInputData}
                      name="PeriodEndType"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Period End"
                      value="Manual Period End"
                      checked={state.PeriodEndType === 'Manual Period End'}
                      onChange={onChangeInputData}
                      name="PeriodEndType"
                    />
                    <label className="custom-control">
                      <div>
                        <input
                          id="IsManualInclude"
                          type="checkbox"
                          className="label-checkbox"
                          onChange={onChangeInputData}
                          name="IsManualInclude"
                          checked={state.IsManualInclude}
                          disabled={disabled}
                        />
                        <label htmlFor="IsManualInclude" className="label_checkbox" />
                        <label
                          htmlFor="IsManualInclude"
                          className="control-label text_label_checkbox"
                        >
                          Include
                        </label>
                      </div>
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Period End" required={setPeriodEndType}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualPeriodEnd === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEnd"
                      disabled={!setPeriodEndType}
                      required={setPeriodEndType}
                    />

                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualPeriodEnd === 'Day'}
                      onChange={onChangeInputData}
                      name="ManualPeriodEnd"
                      disabled={!setPeriodEndType}
                    />

                    <label className="custom-control">
                      {state.ManualPeriodEnd === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualPeriodEnd === 'Day'}
                          disabled={state.ManualPeriodEnd !== 'Day'}
                          value={state.ManualDay}
                          name="ManualDay"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.ManualDay || ''}
                          name="ManualDay"
                          required={state.ManualPeriodEnd === 'Day'}
                          disabled={state.ManualPeriodEnd !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-8">
                <FormGroup2 text="Working Day" required>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.ManualWorkingDay}
                    name="ManualWorkingDay"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>

          <Col2 col={colX[2]}>
            <Card textHeader="Interest Due Date" cardActions={['toggler']}>
              <Col2 col={colX[2]}>
                <FormGroup2 text="Due Date" required>
                  <div>
                    <RadioButton
                      text="End of Term (Shift Back to Term End)"
                      value="End of Term"
                      checked={state.DueDateType === 'End of Term'}
                      onChange={onChangeInputData}
                      name="DueDateType"
                      required={required}
                    />
                    <RadioButton
                      text="Manual Due Date"
                      value="Manual Due Date"
                      checked={state.DueDateType === 'Manual Due Date'}
                      onChange={onChangeInputData}
                      name="DueDateType"
                    />
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col={colX[2]}>
                <FormGroup2 text="Manual Due Date" required={setDueDateType}>
                  <div>
                    <RadioButton
                      text="Month End"
                      value="Month End"
                      checked={state.ManualDueDate === 'Month End'}
                      onChange={onChangeInputData}
                      name="ManualDueDate"
                      disabled={!setDueDateType}
                      required={setDueDateType}
                    />
                    <RadioButton
                      text="Day"
                      value="Day"
                      checked={state.ManualDueDate === 'Day'}
                      onChange={onChangeInputData}
                      disabled={!setDueDateType}
                      name="ManualDueDate"
                    />

                    <label className="custom-control">
                      {state.ManualDueDate === 'Day' ? (
                        <InputMask
                          className="form-control"
                          format="currency"
                          onChange={onChangeInputData}
                          option={{
                            prefix: '',
                            digits: 0,
                            max: 31,
                            min: 1,
                          }}
                          required={state.ManualDueDate === 'Day'}
                          disabled={state.ManualDueDate !== 'Day'}
                          value={state.DueDateDay}
                          name="DueDateDay"
                        />
                      ) : (
                        <input
                          className="form-control"
                          onChange={onChangeInputData}
                          value={state.DueDateDay || ''}
                          name="DueDateDay"
                          required={state.ManualDueDate === 'Day'}
                          disabled={state.ManualDueDate !== 'Day'}
                        />
                      )}
                    </label>
                  </div>
                </FormGroup2>
              </Col2>

              <Col2 col="col-md-8">
                <FormGroup2 text="Working Day" required>
                  <MasterDropdownUI
                    masterType={OPTION_WORKING_DAY}
                    onChange={onChangeInputData}
                    isChoose
                    notMultiple
                    required={required}
                    disabled={disabled}
                    value={state.DueDateWorkingDay}
                    name="DueDateWorkingDay"
                  />
                </FormGroup2>
              </Col2>
            </Card>
          </Col2>
        </Col2>

        <Card textHeader="Swap Structure" cardActions={['toggler']}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Amount" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={{
                    prefix: '',
                    digits: 3,
                    // digitsOptional: false,
                    // placeholder: '0.000'
                  }}
                  required={required}
                  disabled={disabled}
                  value={state.Amount}
                  name="Amount"
                />
              </FormGroup2>

              <FormGroup2 text="Currency" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required={required}
                  disabled={disabled}
                  value={state.Currency}
                  name="Currency"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Valid From" required>
                <DatePicker
                  onChange={onChangeInputData}
                  option={{
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                  required={required}
                  disabled={false}
                  value={state.ValidFromStr}
                  name="ValidFromStr"
                />
              </FormGroup2>
              <FormGroup2 text="Valid To" required>
                <DatePicker
                  onChange={onChangeInputData}
                  option={{
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                  required={required}
                  disabled={false}
                  value={state.ValidToStr}
                  name="ValidToStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </Card>

        <div className="row">
          <div className="col-sm-12 text-center">
            <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
          </div>
        </div>
      </form>
    </Modal>
  );
};
