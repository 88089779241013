import React, { Component } from 'react';
import { createPortal } from 'react-dom';

const $ = window.jQuery;
export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    this.state = {
      isOpen: false,
    };
    this.el.setAttribute('tabIndex', '-1');
    this.el.setAttribute('role', 'dialog');
  }

  componentDidMount() {
    document.body.appendChild(this.el);

    this.$modal = $(this.modalElement);
    this.$modal.on('click', this.onClickModal);
    this.$modal.on('click', 'button[data-dismiss=modal]', (e) => {
      // ต้อง settimeout ไม่งั้น ไม่ tigger onClick ที่ติดมาจาก dom
      setTimeout(this.close);
    });
  }

  onClickModal = (e) => {
    if (e.target === this.$modal[0]) {
      if (this.props.onClose) this.props.onClose();
      else this.close();
    }
  };

  open = () => {
    this.setState({
      isOpen: true,
    });
  };

  close = () => {
    if (this.props.onClose) this.props.onClose();
    else
      this.setState({
        isOpen: false,
      });
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  componentWillUnmount() {
    this.$modal.off('click', this.onClickModal);
    document.body.removeChild(this.el);
  }

  render() {
    return createPortal(
      <div
        tabIndex="-1"
        role="dialog"
        className={`modal fade ${this.state.isOpen ? 'in' : null}`}
        style={{
          overflow: 'scroll',
          display: this.state.isOpen ? 'block' : 'none',
          background: 'rgba(0, 0, 0, 0.18)',
          zIndex: this.props.zIndex || 1050,
        }}
        ref={(el) => (this.modalElement = el)}
      >
        <div className={`modal-dialog ${this.props.size || ''}`}>
          <div className={`modal-content ${this.props.bg}`}>
            <div className={`modal-header ${this.props.bgHeader}`}>
              <button type="button" className="close" onClick={this.close}>
                <span aria-hidden="true">×</span>
                <span className="sr-only">Close</span>
              </button>
              <h4 className="modal-title">{this.props.textHeader}</h4>
            </div>
            <div className="modal-body">{this.props.children}</div>
            {this.props.modalFooter ? (
              <div className="modal-footer">
                <center>{this.props.modalFooter}</center>
              </div>
            ) : null}
          </div>
        </div>
      </div>,
      this.el
    );
  }
}
