import {
  EDIT_AUTOMATE_EXPOSURE_CRITERIA,
  RECEIVE_SEARCH_AUTOMATE_EXPOSURE,
  CLEAR_AUTOMATE_EXPOSURE_CRITERIA,
  UPDATE_SEARCH_RESULT_AUTOMATE_EXPOSURE,
} from './action';

const initCriteria = {
  FinanceGroup: [''],
  BusinessUnit: [''],
  CompanyCode: [''],
  Status: '',
  CashType: '',
};

const init = {
  searchResult: [],
  criteria: { ...initCriteria },
};

export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_RESULT_AUTOMATE_EXPOSURE:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case CLEAR_AUTOMATE_EXPOSURE_CRITERIA:
      return {
        ...state,
        criteria: {
          ...initCriteria,
        },
      };
    case EDIT_AUTOMATE_EXPOSURE_CRITERIA:
      return {
        ...state,
        criteria: {
          ...state.criteria,
          [action.payload.name]: action.payload.value,
        },
      };
    case RECEIVE_SEARCH_AUTOMATE_EXPOSURE:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
