import echarts from 'echarts';
import React, { useRef, useState, useEffect } from 'react';

import Card from '../common/Card';

export default (props) => {
  const [companies, setCompanies] = useState({});
  const [buPie, setbuPie] = useState({});
  const [buBar, setbuBar] = useState({});
  useEffect(() => {
    const group = {};

    sData.forEach((m) => {
      const find = group[m.company_name];
      if (!find) group[m.company_name] = m.amount;
      else group[m.company_name] += Number(m.amount);

      if (group[m.company_name] > 5000000) group[m.company_name] = 5000000;
      if (group[m.company_name] < -5000000) group[m.company_name] = -5000000;
    });

    const data = [];
    const dataAxis = [];
    for (const key in group) {
      data.push(group[key]);
      dataAxis.push(key);
    }

    const opt = {
      color: ['#3398DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        lang: 'en',
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar', 'stack', 'tiled'] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          data: dataAxis,
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: 'Total',
          type: 'bar',
          barWidth: '60%',
          data,
          label: {
            normal: {
              show: true,
              position: 'insideBottom',
              distance: 15,
              align: 'left',
              verticalAlign: 'middle',
              rotate: '90',
            },
          },
          itemStyle: {
            normal: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#83bff6' },
                { offset: 0.5, color: '#188df0' },
                { offset: 1, color: '#188df0' },
              ]),
            },
            emphasis: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#2378f7' },
                { offset: 0.7, color: '#2378f7' },
                { offset: 1, color: '#83bff6' },
              ]),
            },
          },
        },
      ],
    };

    setCompanies(opt);
  }, []);

  useEffect(() => {
    const group = {};

    sData.forEach((m) => {
      const find = group[m.bu_name];
      if (!find) group[m.bu_name] = m.amount;
      else group[m.bu_name] += Number(m.amount);
    });

    const data = [];
    const legendData = [];
    const seriesData = [];
    for (const key in group) {
      data.push(group[key]);
      legendData.push(key);
      seriesData.push({
        name: key,
        value: group[key],
      });
    }

    const optPie = {
      title: {
        text: 'Business Unit',
        x: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        type: 'scroll',
        orient: 'vertical',
        right: 10,
        top: 20,
        bottom: 20,
        data: legendData,
      },
      series: [
        {
          name: 'Business Unit',
          type: 'pie',
          radius: '55%',
          center: ['40%', '50%'],
          data: seriesData,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        },
      ],
    };

    const optBar = {
      title: {
        text: 'Business Unit',
        x: 'center',
      },
      color: ['#6598DB'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true,
      },
      yAxis: [
        {
          type: 'category',
          data: legendData,
          axisTick: {
            alignWithLabel: true,
          },
        },
      ],
      xAxis: [
        {
          type: 'value',
        },
      ],
      series: [
        {
          name: 'Business Unit',
          type: 'bar',
          barWidth: '60%',
          data,
        },
      ],
    };

    setbuBar(optBar);
    setbuPie(optPie);
  }, []);

  return (
    <>
      <Card textHeader="Business Unit Summary" bgHeader="bg-primary" cardActions={['toggler']}>
        <div className="row">
          <div className="col-sm-4">
            <Echart height={600} width="auto" option={buPie} />
          </div>
          <div className="col-sm-8">
            <Echart height={600} width="auto" option={buBar} />
          </div>
        </div>
      </Card>
      <Card textHeader="Companies Summary" bgHeader="bg-primary" cardActions={['toggler']}>
        <Echart height={600} width="auto" option={companies} />
      </Card>
    </>
  );
};

function Echart({ height, width, option }) {
  const conEl = useRef(null);
  const echartRef = useRef(null);
  const resizeRef = useRef(null);
  useEffect(() => {
    if (!conEl.current) return;

    echartRef.current = echarts.init(conEl.current);
    echartRef.current.setOption(option);

    return () => {
      if (echartRef.current) echarts.dispose(echartRef.current);
    };
  }, [option]);

  useEffect(() => {
    const $ = window.jQuery;
    let id = '';
    const s = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    for (let i = 0; i < 20; i++) {
      id = `${id}${s[Math.floor(Math.random() * s.length)]}`;
    }

    $(window).on(`resize.${id}`, () => {
      if (echartRef.current) echartRef.current.resize();
    });
    resizeRef.current = id;
    return () => {
      $(window).off(`resize.${resizeRef.current}`);
    };
  }, []);

  return <div ref={conEl} style={{ height, width }} />;
}

const sData = [
  {
    bu_name: 'Corporate',
    company_name: 'SCG FOUNDATION',
    amount: 499072.5,
  },
  {
    bu_name: 'Corporate',
    company_name: 'THE SIAM CEMENT PUBLIC COMPANY LIMITED',
    amount: 7601053177,
  },
  {
    bu_name: 'SCG CBM',
    company_name: 'SCG CEMENT CO., LTD.',
    amount: 240209.76,
  },
  {
    bu_name: 'SCG CBM',
    company_name: 'SCG DISTRIBUTION CO., LTD.',
    amount: 10000,
  },
  {
    bu_name: 'SCG CBM',
    company_name: 'THE CONCRETE PRODUCTS AND AGGREGATE CO., LTD.',
    amount: -29000000,
  },
  {
    bu_name: 'SCG CBM',
    company_name: 'THE SIAM CEMENT (KAENG KHOI) CO., LTD.',
    amount: 12513.85,
  },
  {
    bu_name: 'SCG CBM',
    company_name: 'THE SIAM CEMENT (TA LUANG) CO., LTD.',
    amount: 57303.93,
  },
  {
    bu_name: 'SCG CBM',
    company_name: 'THE SIAM CEMENT (THUNG SONG) CO., LTD.',
    amount: 155695.78,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'MAP TA PHUT OLEFINS CO., LTD.',
    amount: 2403071.42,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'MAP TA PHUT TANK TERMINAL CO., LTD.',
    amount: 42888.51,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'NAWAPLASTIC INDUSTRIES CO., LTD.',
    amount: -13121786.55,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'RAYONG ENGINEERING AND PLANT SERVICE CO., LTD.',
    amount: 594889.82,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'RAYONG OLEFINS CO., LTD.',
    amount: 1084546.25,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'RAYONG PIPELINE CO., LTD.',
    amount: 98450,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'RAYONG TERMINAL CO., LTD.',
    amount: 2800,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'RIL 1996 CO., LTD.',
    amount: 126294.28,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'SCG CHEMICALS CO.,LTD.',
    amount: -19965848.11,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'SCG ICO POLYMERS CO.,LTD.',
    amount: 504459.56,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'SCG PERFORMANCE CHEMICALS CO., LTD.',
    amount: 509129.32,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'SCG PLASTICS CO.,LTD.',
    amount: 526436.18,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'SIAM TPC CO., LTD.',
    amount: 119161.96,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'SMH CO., LTD.',
    amount: 19700,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'TEXPLORE CO., LTD.',
    amount: 19700,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'THAI PLASTIC AND CHEMICALS PUBLIC COMPANY LIMITED',
    amount: 2075559.78,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'THAI POLYETHYLENE CO.,LTD',
    amount: 4510510.54,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'THE NAWAPLASTIC INDUSTRIES (SARABURI) CO., LTD.',
    amount: 27768350.26,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'TOTAL PLANT SERVICE CO.LTD.',
    amount: 224790.33,
  },
  {
    bu_name: 'SCG Chemicals',
    company_name: 'TPC PASTE RESIN CO., LTD.',
    amount: 2555771.72,
  },
  {
    bu_name: 'SCG Fibrous',
    company_name: 'PHOENIX PULP & PAPER PUBLIC COMPANY LIMITED',
    amount: 6238378678,
  },
  {
    bu_name: 'SCG Fibrous',
    company_name: 'THAI PAPER CO., LTD.',
    amount: 0,
  },
  {
    bu_name: 'SCG Packaging',
    company_name: 'D-IN PACK CO., LTD.',
    amount: -383.35,
  },
  {
    bu_name: 'SCG Packaging',
    company_name: 'SCG PACKAGING PUBLIC COMPANY LIMITED',
    amount: 3995921.7,
  },
  {
    bu_name: 'SCG Packaging',
    company_name: 'SIAM KRAFT INDUSTRY CO., LTD.',
    amount: -138525152.8,
  },
  {
    bu_name: 'SCG Packaging',
    company_name: 'THAI CANE PAPER PUBLIC COMPANY LIMITED',
    amount: 258853497.9,
  },
];
