import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_BANK_CRITERIA = 'EDIT_MASTER_BANK_CRITERIA';
export const CLEAR_MASTER_BANK_CRITERIA = 'CLEAR_MASTER_BANK_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_BANK = 'UPDATE_SEARCH_RESULT_MASTER_BANK';

export const REQUEST_SEARCH_MASTER_BANK = '@@request/SEARCH_MASTER_BANK';
export const RECEIVE_SEARCH_MASTER_BANK = '@@receive/SEARCH_MASTER_BANK';
export const FAIL_SEARCH_MASTER_BANK = '@@fail/SEARCH_MASTER_BANK';

export const REQUEST_SAVE_MASTER_BANK = '@@request/SAVE_MASTER_BANK';
export const RECEIVE_SAVE_MASTER_BANK = '@@receive/SAVE_MASTER_BANK';
export const FAIL_SAVE_MASTER_BANK = '@@fail/SAVE_MASTER_BANK';

export const REQUEST_DELETE_MASTER_BANK = '@@request/DELETE_MASTER_BANK';
export const RECEIVE_DELETE_MASTER_BANK =
  '@@receive-The record was successfully change status/DELETE_MASTER_BANK';
export const FAIL_DELETE_MASTER_BANK = '@@fail/DELETE_MASTER_BANK';

export const REQUEST_GET_BANK_DETAIL = '@@request/GET_BANK_DETAIL';
export const RECEIVE_GET_BANK_DETAIL = '@@receive/GET_BANK_DETAIL';
export const FAIL_GET_BANK_DETAIL = '@@fail/GET_BANK_DETAIL';

export const REQUEST_GET_BANK_EFFECTIVE_DATE = '@@request/GET_BANK_EFFECTIVE_DATE';
export const RECEIVE_GET_BANK_EFFECTIVE_DATE = '@@receive/GET_BANK_EFFECTIVE_DATE';
export const FAIL_GET_BANK_EFFECTIVE_DATE = '@@fail/GET_BANK_EFFECTIVE_DATE';

export const getMasterBankffectiveDate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbank', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_BANK_EFFECTIVE_DATE,
      RECEIVE_GET_BANK_EFFECTIVE_DATE,
      FAIL_GET_BANK_EFFECTIVE_DATE,
    ],
  },
});

export const getBankDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmasterbankdetail/`, data),
    method: 'GET',
    types: [REQUEST_GET_BANK_DETAIL, RECEIVE_GET_BANK_DETAIL, FAIL_GET_BANK_DETAIL],
  },
});

export const deleteMasterBank = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemasterbank`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [REQUEST_DELETE_MASTER_BANK, RECEIVE_DELETE_MASTER_BANK, FAIL_DELETE_MASTER_BANK],
  },
});

export const saveMasterBank = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemasterbank`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MASTER_BANK, RECEIVE_SAVE_MASTER_BANK, FAIL_SAVE_MASTER_BANK],
  },
});

export const searchMasterBank = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmasterbank', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_MASTER_BANK, RECEIVE_SEARCH_MASTER_BANK, FAIL_SEARCH_MASTER_BANK],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_BANK,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_BANK_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_BANK_CRITERIA,
  payload: {
    name,
    value,
  },
});
