import { connect } from 'react-redux';
import React, { Component } from 'react';

import SecuritiesJournal from './SecuritiesJournal';
import { addAlert } from '../../reducers/layout/action';
import { saveManual } from '../../reducers/foreignExchange/action';
import {
  updateSearchResult,
  searchSecuritiesJournal,
} from '../../reducers/journalSecurities/action';
import {
  toLower,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../common/helpper';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-journal-securities';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const initCriteria = {
  FinanceGroup: [''],
  BusinessUnits: [''],
  Companies: [''],
  TransactionCurrency: [''],
  ProductType: 'Bond',
  TransactionType: [''],
  Partner: [''],
  PostingProcess: '',
  GenValnClassTerm: '',
  ValueDateStr: '',
  PostingDateStr: '',
  TransactionNo: '',
  ContractNo: '',
  AccountingStandard: '',
  InstanceCode: [''],
  PostGLStatus: '',
};

const initModalManual = {
  PostingDocumentNo: '',
  PostingMessage: '',
  ReverseDocumentNo: '',
  ReverseMessage: '',
  ClearingDocumentNo: '',
  ClearingMessage: '',

  FlowNos: [],
  TransactionIds: [],
  Type: '',
  Source: 'bd',
};

class SecuritiesJournalContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    modalManual: {
      ...initModalManual,
    },
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
  }

  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchSecuritiesJournal({ ...this.state.criteria });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
    });
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    this.props.searchResult[result.row].IsSelected = result.checked;
    this.forceUpdate();
  };

  // Manual
  onClickOpenModalManual = (type) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Please select item for Manual ${type[0].toUpperCase()}${type.substr(1)}!`,
      });
      return;
    }

    let checkStatusClearing = true;
    let checkClearing = true;
    selectedData.forEach((m) => {
      if (m.clearingDocumentNo || m.clearingMessage) checkClearing = false;
      if (toLower(m.clearingStatus) !== 'completed') checkStatusClearing = false;
    });

    if (type === 'clearing' && !checkStatusClearing) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected, Clearing Status must be Completed!',
      });
      return;
    }

    if (type === 'clearing' && !checkClearing) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected, Clearing Document No. and Clearing Message must be blank!',
      });
      return;
    }

    this.setState(
      {
        modalManual: {
          ...initModalManual,
          FlowNos: selectedData.map((m) => m.flowNo),
          TransactionIds: selectedData.map((m) => m.transactionId),
          Type: type,
        },
      },
      this.modalManualRef.open
    );
  };

  onChangeModalManual = (e) => {
    this.setState({
      modalManual: {
        ...this.state.modalManual,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalManual = (e) => {
    e.preventDefault();
    this.props.saveManual({ ...this.state.modalManual }).then(() => {
      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchSecuritiesJournal({ ...this.state.criteria });
      this.modalManualRef.close();
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onClickOpenModalManual: this.onClickOpenModalManual,
      onChangeModalManual: this.onChangeModalManual,
      onSubmitModalManual: this.onSubmitModalManual,
    };

    return (
      <SecuritiesJournal
        {...props}
        {...this.state}
        modalManualRef={(e) => (this.modalManualRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.journalSecurities,
  }),
  {
    searchSecuritiesJournal,
    updateSearchResult,
    addAlert,
    saveManual,
  }
)(SecuritiesJournalContainer);
