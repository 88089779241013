import React, { Fragment } from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import FormGroup2 from '../../../common/FormGroup2';
import MasterDropdown from '../../../common/MasterDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import { toFixed, getPermission } from '../../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('Report', 'Sweep PN');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12', 'col-sm-3', 'col-md-3'];

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,
  onClickUploadExcel,
  onClickUploadExcelIndo,
  datas,
  datasCiti,
  onClickDownloadSweep,
}) => (
  <>
    <div className="title-bar">
      <p className="title-bar-description">
        <small>
          Report <span className="icon icon-angle-double-right" /> Sweep PN
        </small>
      </p>
    </div>

    <ReportSweepCriteria
      criteria={criteria}
      onChangeSearch={onChangeSearch}
      onChangeSelect2={onChangeSelect2}
      onClickSearch={onClickSearch}
      onClickClearSearch={onClickClearSearch}
      onClickUploadExcel={onClickUploadExcel}
      onClickUploadExcelIndo={onClickUploadExcelIndo}
      onClickDownloadSweep={onClickDownloadSweep}
    />

    {datas.map((r, i) => (
      <CardReportSweepResult key={i} data={r} />
    ))}

    {datasCiti.map((r, i) => (
      <CardReportSweepStatement key={i} data={r} />
    ))}
  </>
);

function ReportSweepCriteria({
  onClickSearch,
  onChangeSearch,
  criteria = {},
  onChangeSelect2,
  onClickClearSearch,
  onClickUploadExcel = { onClickUploadExcel },
  onClickUploadExcelIndo = { onClickUploadExcelIndo },
  onClickDownloadSweep,
}) {
  const required = false;
  return (
    <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
      <form onSubmit={onClickSearch}>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Finance Group" required={false}>
              <MasterDropdown
                masterType={MASTER_FINANCE}
                onChange={onChangeSelect2}
                value={criteria.FinanceGroups}
                required={false}
                name="FinanceGroups"
              />
            </FormGroup2>

            <FormGroup2 text="Business Unit">
              <MasterDropdown
                masterType={MASTER_BUSINESS_UNIT}
                onChange={onChangeSelect2}
                value={criteria.BusinessUnits}
                name="BusinessUnits"
              />
            </FormGroup2>
          </Col2>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Company">
              <MasterDropdown
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.Companies}
                saveLocalState
                financeGroupId={criteria.FinanceGroups}
                businessUnitId={criteria.BusinessUnits}
                name="Companies"
              />
            </FormGroup2>
          </Col2>
        </Col2>
        <Col2 col={colX[0]}>
          <Col2 col={colX[1]}>
            <FormGroup2 text="Business Partner">
              <MasterDropdown
                key="Intercompany"
                masterType={MASTER_COMPANY}
                onChange={onChangeSelect2}
                value={criteria.BusinessPartner}
                isBusinessPartner
                name="BusinessPartner"
              />
            </FormGroup2>

            <FormGroup2 text="Reporting Date" required>
              <DateRangePicker
                onChange={onChangeSearch}
                required
                value={criteria.ReportingDateStr}
                name="ReportingDateStr"
              />
            </FormGroup2>
          </Col2>
        </Col2>
        <Col2 col={colX[2]}>
          <div>
            <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
            &nbsp;
            <Button
              txt="Clear Search"
              icon="eraser"
              className="btn-clear"
              onClick={onClickClearSearch}
            />
            &nbsp;
            <Button
              txt="Download PDF"
              icon="download"
              className="btn-clear"
              onClick={(e) => onClickDownloadSweep('pdf')}
            />
            &nbsp;
            <Button
              txt="Download Excel"
              icon="download"
              className="btn-clear"
              onClick={(e) => onClickDownloadSweep('excel')}
            />
            {permission ? (
              <>
                &nbsp;
                <Button
                  txt="Upload Excel"
                  icon="upload"
                  className="btn-warning"
                  onClick={onClickUploadExcel}
                />
              </>
            ) : null}
            {permission ? (
              <>
                &nbsp;
                <Button
                  txt="Upload Excel Indo"
                  icon="upload"
                  className="btn-warning"
                  onClick={onClickUploadExcelIndo}
                />
              </>
            ) : null}
          </div>
        </Col2>
      </form>
    </Card>
  );
}

function CardReportSweepResult({ data = {} }) {
  return (
    <Card
      textHeader={data.companyName}
      number={data.companyCode}
      bgHeader="bg-primary"
      cardActions={['toggler']}
    >
      <ReportSweepHeader data={data} />

      <div className="col-md-12" style={{ overflow: 'scroll', paddingLeft: 0, paddingTop: 10 }}>
        <ReportSweepTable data={data} />
      </div>
    </Card>
  );
}

function ReportSweepHeader({ data = {} }) {
  return (
    <>
      <Col2 col="col-md-5">
        <div className="row gutter-xs" style={{ marginBottom: 15 }}>
          <div className="col-md-3">
            <FormGroup2 text="Business Unit" styleDisplay={{ marginBottom: '-5px' }} />
          </div>
          <div className="col-md-6">
            <span>{data.businessUnit}</span>
          </div>
        </div>
      </Col2>

      <Col2 col="col-md-5">
        <div className="row gutter-xs" style={{ marginBottom: 10 }}>
          <div className="col-md-3">
            <FormGroup2 text="Date" styleDisplay={{ marginBottom: '-5px' }} />
          </div>
          <div className="col-md-6">
            <span>
              {data.reportDateFromStr}-{data.reportDateToStr}
            </span>
          </div>
        </div>

        <Col2 col={colX[3]}>
          {data.banks.map((m, i) => (
            <FormGroup2 key={i} text={m.bankAbbreviate} />
          ))}
        </Col2>
      </Col2>

      <div style={{ marginBottom: 10 }}>
        <Col2 col="col-md-5">
          <div className="row gutter-xs" style={{ marginBottom: 10 }}>
            <div className="col-md-3">
              <FormGroup2 text="Company" styleDisplay={{ marginBottom: '-5px' }} />
            </div>
            <div className="col-md-6">
              <span>
                {data.companyCode} | {data.companyName}
              </span>
            </div>
          </div>

          <Col2 col={colX[3]}>
            {data.banks.map((m, i) => (
              <span key={i}>{m.partnerAccountNo}</span>
            ))}
          </Col2>
        </Col2>
      </div>

      <Col2 col="col-md-5">
        <div className="row gutter-xs" style={{ marginBottom: 10 }}>
          <div className="col-md-3">
            <FormGroup2 text="Partner" styleDisplay={{ marginBottom: '-5px' }} />
          </div>
          <div className="col-md-6">
            <span>
              {data.partnerCode} | {data.partnerCompanyName}
            </span>
          </div>
        </div>

        <Col2 col={colX[3]}>
          {data.banks.map((m, i) => (
            <span key={i}>{m.accountNo}</span>
          ))}
        </Col2>
      </Col2>
    </>
  );
}

function ReportSweepTable({ data = {} }) {
  const headerStyle = { textAlign: 'center', verticalAlign: 'inherit' };
  return (
    <table className="table table-bordered table-nowrap">
      <thead>
        <tr>
          <th rowSpan="2" style={headerStyle}>
            Company
          </th>
          <th rowSpan="2" style={headerStyle}>
            Partner
          </th>
          <th rowSpan="2" style={headerStyle}>
            Type
          </th>
          <th rowSpan="2" style={headerStyle}>
            Value date
          </th>
          {data.banks.map((m, i) => (
            <th colSpan="3" className="th-primary" key={i}>
              {m.bankAbbreviate}
            </th>
          ))}
          <th rowSpan="2" style={headerStyle}>
            Cumulative Outstand
          </th>
          <th colSpan="2" className="th-primary">
            PN No.
          </th>
          <th rowSpan="2" style={headerStyle}>
            Remark
          </th>
          <th rowSpan="2" style={headerStyle}>
            Diff Amt.
          </th>
          <th rowSpan="2" style={headerStyle}>
            Interest %
          </th>
        </tr>
        <tr>
          {data.banks.map((m, i) => (
            <Fragment key={i}>
              <th>EOD-SWEEP Amt.</th>
              <th>Adjust Amt.</th>
              <th>Sum Amt.</th>
            </Fragment>
          ))}
          <th>Old</th>
          <th>New</th>
        </tr>
      </thead>
      <tbody>
        {!(data.rows || []).length ? (
          <tr>
            <td colSpan="18" align="center">
              No data available in table
            </td>
          </tr>
        ) : null}
        {data.rows.map((m, i) => (
          <tr
            key={i}
            style={{
              backgroundColor: m.diffAmount && !m.isBeginning ? 'red' : 'none',
              color: m.diffAmount && !m.isBeginning ? 'white' : 'none',
            }}
          >
            <td>{m.companyName}</td>
            <td>{m.partnerCompanyName}</td>
            <td>{m.loanType}</td>
            <td>{m.isBeginning ? 'Beginning' : m.valueDateStr}</td>
            {data.banks.map((b, bi) => {
              const bankData = m.bankData.find((f) => f.bankAbbreviate === b.bankAbbreviate) || {};
              return (
                <Fragment key={bi}>
                  <td className="text-right">
                    {m.isBeginning
                      ? toNumber(0, 2)
                      : toNumber(bankData.eodAmount, 2, m.diffAmount) || toNumber(0, 2)}
                  </td>
                  <td className="text-right">
                    {toNumber(bankData.adjAmount, 2, m.diffAmount) || toNumber(0, 2)}
                  </td>
                  <td className="text-right">
                    {toNumber(bankData.sumAmount, 2, m.diffAmount) || toNumber(0, 2)}
                  </td>
                </Fragment>
              );
            })}
            <td className="text-right">{toNumber(m.cumulativeOutstand, 2, m.diffAmount)}</td>
            <td className="text-center">
              <a target="_blank" href={`/loan-investment/pn-loan/${m.oldPn}`} rel="noreferrer">
                {m.oldPn}
              </a>
            </td>
            <td className="text-center">
              <a target="_blank" href={`/loan-investment/pn-loan/${m.newPn}`} rel="noreferrer">
                {m.newPn}
              </a>
            </td>

            <td>{m.remark}</td>
            <td className="text-right">
              {m.isBeginning ? 0 : toNumber(m.diffAmount, 2, m.diffAmount)}
            </td>
            <td className="text-right">{toNumber(m.interest, 2, m.diffAmount)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function CardReportSweepStatement({ data = {} }) {
  return (
    <Card
      textHeader={data.childCompanyName}
      number={data.childCompanyCode}
      bgHeader="bg-info"
      cardActions={['toggler']}
    >
      <ReportSweepHeaderStament data={data} />

      <div className="col-md-12" style={{ overflow: 'scroll', paddingLeft: 0 }}>
        <ReportSweepStatementTable data={data} />
      </div>
    </Card>
  );
}

function ReportSweepHeaderStament({ data = {} }) {
  return (
    <>
      <Col2 col="col-md-5">
        <div className="row gutter-xs">
          <div className="col-md-3">
            <FormGroup2 text="Business Unit" />
          </div>
          <div className="col-md-6">
            <span>{data.businessUnit}</span>
          </div>
        </div>
      </Col2>

      <Col2 col="col-md-5">
        <div className="row gutter-xs">
          <div className="col-md-3">
            <FormGroup2 text="Date" />
          </div>
          <div className="col-md-6">
            <span>
              {data.reportDateFromStr}-{data.reportDateToStr}
            </span>
          </div>
        </div>

        <Col2 col={colX[3]}>
          {data.banks.map((m, i) => (
            <FormGroup2 key={i} text={m.bankAbbreviate} />
          ))}
        </Col2>
      </Col2>

      <Col2 col="col-md-5">
        <div className="row gutter-xs">
          <div className="col-md-3">
            <FormGroup2 text="Master" />
          </div>
          <div className="col-md-6">
            <span>
              {data.masterCompanyCode} | {data.masterCompanyName}
            </span>
          </div>
        </div>

        <Col2 col={colX[3]}>
          {data.banks.map((m, i) => (
            <span key={i}>{m.masterAccountNo}</span>
          ))}
        </Col2>
      </Col2>

      <Col2 col="col-md-5">
        <div className="row gutter-xs">
          <div className="col-md-3">
            <FormGroup2 text="Member" />
          </div>
          <div className="col-md-6">
            <span>
              {data.childCompanyCode} | {data.childCompanyName}
            </span>
          </div>
        </div>

        <Col2 col={colX[3]}>
          {data.banks.map((m, i) => (
            <span key={i}>{m.childAccountNo}</span>
          ))}
        </Col2>
      </Col2>
    </>
  );
}

function ReportSweepStatementTable({ data = {} }) {
  const headerStyle = { textAlign: 'center', verticalAlign: 'inherit' };
  return (
    <table className="table table-bordered table-nowrap">
      <thead>
        <tr>
          <th style={headerStyle}>Account No.</th>
          <th style={headerStyle}>Member</th>
          <th style={headerStyle}>Account No.</th>
          <th style={headerStyle}>Master</th>
          <th style={headerStyle}>Type</th>
          <th style={headerStyle}>Currency</th>
          <th style={headerStyle}>Date</th>
          <th style={headerStyle}>Inward</th>
          <th style={headerStyle}>Outward</th>
          <th style={headerStyle}>Balance</th>
          <th style={headerStyle}>
            Interest
            <br />
            Income
          </th>
          <th style={headerStyle}>
            Interest
            <br />
            Loan
          </th>
          <th style={headerStyle}>Days</th>
          <th style={headerStyle}>
            Interest by
            <br />
            Contract
          </th>
          <th style={headerStyle}>Net Interest</th>
          <th style={headerStyle}>WHT</th>
          <th style={headerStyle}>Old PN</th>
          <th style={headerStyle}>New PN</th>
        </tr>
      </thead>
      <tbody>
        {!(data.rows || []).length ? (
          <tr>
            <td colSpan="15" align="center">
              No data available in table
            </td>
          </tr>
        ) : null}
        {data.rows.map((m, i) => (
          <tr key={i}>
            <td>{m.childAccountNo}</td>
            <td className="text-center">{m.childCompanyCode}</td>
            <td>{m.masterAccountNo}</td>
            <td className="text-center">{m.masterCompanyCode}</td>
            <td className="text-center">{m.transactionType}</td>
            <td className="text-center">{m.currency}</td>
            <td className="text-center">{m.valueDateStr}</td>
            <td className="text-right">{toNumber(m.inward, 2)}</td>
            <td className="text-right">{toNumber(m.outward, 2)}</td>
            <td className="text-right">{toNumber(m.balance, 2)}</td>
            <td className="text-right">{toNumber(m.interestIncome, 2)}</td>
            <td className="text-right">{toNumber(m.interestLoan, 2)}</td>
            <td className="text-right">{toNumber(m.days, 0)}</td>
            <td className="text-right">{toNumber(m.interestByContract, 2)}</td>
            <td className="text-right">{toNumber(m.netInterest, 2)}</td>
            <td className="text-right">{toNumber(m.wht, 2)}</td>
            <td className="text-center">
              <a target="_blank" href={`/loan-investment/pn-loan/${m.oldPn}`} rel="noreferrer">
                {m.oldPn}
              </a>
            </td>
            <td className="text-center">
              <a target="_blank" href={`/loan-investment/pn-loan/${m.newPn}`} rel="noreferrer">
                {m.newPn}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function toNumber(number, float = 2, diffAmount) {
  if (number >= 0 || !number) {
    return toFixed(number, float);
  }
  number = Math.abs(number);
  return (
    <span style={{ color: diffAmount ? 'white' : '#e64a19' }}>({toFixed(number, float)})</span>
  );
}
