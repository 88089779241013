import React from 'react';

import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import FormGroup2 from '../../common/FormGroup2';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  formRef,
  state,
  onChangeInputData,
  noEdit,
  onClickCopyCreator,
  creatorRef,
  modeAmend,
}) => (
  <form ref={formRef} name="creator">
    {modeAmend ? (
      <Col2 col={colX[2]}>
        <Button
          txt="COPY TO REQUESTOR (ปุ่มสำหรับคัดลอดชื่อไปยัง Requestor Name)"
          icon="copy"
          className="btn-default"
          onClick={onClickCopyCreator}
          disabled={false}
        />
      </Col2>
    ) : !noEdit ? (
      <Col2 col={colX[2]}>
        <Button
          txt="COPY TO REQUESTOR (ปุ่มสำหรับคัดลอดชื่อไปยัง Requestor Name)"
          icon="copy"
          className="btn-default"
          onClick={onClickCopyCreator}
          disabled={noEdit}
        />
      </Col2>
    ) : null}
    <Col2 col={colX[2]}>
      <Col2 col={colX[1]}>
        <FormGroup2 text="Creator Name (ชื่อของผู้สร้างรายการ)" required>
          <input
            type="text"
            disabled
            className="form-control"
            ref={creatorRef}
            defaultValue={state.CreatorFullName}
          />
        </FormGroup2>

        <FormGroup2
          text="Email (อีเมลของผู้สร้างรายการ)"
          required
          maxLength={200}
          length={(state.CreatorEmail || '').length}
        >
          <input
            className="form-control"
            onChange={onChangeInputData}
            value={state.CreatorEmail || ''}
            name="CreatorEmail"
            disabled
            required
            maxLength={200}
          />
        </FormGroup2>
      </Col2>
    </Col2>

    <Col2 col={colX[2]}>
      <Col2 col={colX[1]}>
        <FormGroup2 text="Tel No. (เบอร์ติดต่อของผู้สร้าง)" required={false}>
          <input
            className="form-control"
            onChange={onChangeInputData}
            value={state.CreatorTel || ''}
            name="CreatorTel"
            disabled={modeAmend ? false : noEdit}
            maxLength={30}
          />
        </FormGroup2>

        <FormGroup2 text="Department (หน่วยงานของผู้สร้างรายการ)" required>
          <textarea
            className="form-control"
            rows="2"
            onChange={onChangeInputData}
            value={state.CreatorDepartment || ''}
            name="CreatorDepartment"
            disabled={modeAmend ? false : noEdit}
            required
            maxLength={200}
          />
        </FormGroup2>
      </Col2>
    </Col2>
  </form>
);
