import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_PN_LOAN_CRITERIA = 'EDIT_PN_LOAN_CRITERIA';
export const CLEAR_PN_LOAN_CRITERIA = 'CLEAR_PN_LOAN_CRITERIA';

export const UPDATE_SEARCH_RESULT_PN_LOAN = 'UPDATE_SEARCH_RESULT_PN_LOAN';

export const REQUEST_SEARCH_PN_LOAN = '@@request/SARCH_PN_LOAN';
export const RECEIVE_SEARCH_PN_LOAN = '@@receive/SEARCH_PN_LOAN';
export const FAIL_SEARCH_PN_LOAN = '@@fail/SEARCH_PN_LOAN';

export const REQUEST_GET_LOAN_RATE = '@@request/GET_LOAN_RATE';
export const RECEIVE_GET_LOAN_RATE = '@@receive/GET_LOAN_RATE';
export const FAIL_GET_LOAN_RATE = '@@fail/GET_LOAN_RATE';

export const REQUEST_GET_MAINTAIN_INTEREST_RATE = '@@request/GET_MAINTAIN_INTEREST_RATE';
export const RECEIVE_GET_MAINTAIN_INTEREST_RATE = '@@receive/GET_MAINTAIN_INTEREST_RATE';
export const FAIL_GET_MAINTAIN_INTEREST_RATE = '@@fail/GET_MAINTAIN_INTEREST_RATE';

export const REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE = '@@request/GET_TEMPLATE_LIST_INTEREST_RATE';
export const RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE = '@@receive/GET_TEMPLATE_LIST_INTEREST_RATE';
export const FAIL_GET_TEMPLATE_LIST_INTEREST_RATE = '@@fail/GET_TEMPLATE_LIST_INTEREST_RATE';

export const REQUEST_PROCESS_CASH_FLOW = '@@request/PROCESS_CASH_FLOW';
export const RECEIVE_PROCESS_CASH_FLOW = '@@receive/PROCESS_CASH_FLOW';
export const FAIL_PROCESS_CASH_FLOW = '@@fail/PROCESS_CASH_FLOW';

export const REQUEST_SAVE_LOAN = '@@request/SAVE_LOAN';
export const RECEIVE_SAVE_LOAN = '@@receive/SAVE_LOAN';
export const FAIL_SAVE_LOAN = '@@fail/SAVE_LOAN';

export const REQUEST_GET_FACILITY_FOR_LOAN = '@@request-noload/GET_FACILITY_FOR_LOAN';
export const RECEIVE_GET_FACILITY_FOR_LOAN = '@@receive/GET_FACILITY_FOR_LOAN';
export const FAIL_GET_FACILITY_FOR_LOAN = '@@fail/GET_FACILITY_FOR_LOAN';

export const REQUEST_GET_FACILITY_LIST = '@@request-noload/GET_FACILITY_LIST';
export const RECEIVE_GET_FACILITY_LIST = '@@receive/GET_FACILITY_LIST';
export const FAIL_GET_FACILITY_LIST = '@@fail/GET_FACILITY_LIST';

export const REQUEST_GET_INTEREST_REPAYMENT = '@@request/GET_INTEREST_REPAYMENT';
export const RECEIVE_GET_INTEREST_REPAYMENT = '@@receive/GET_INTEREST_REPAYMENT';
export const FAIL_GET_INTEREST_REPAYMENT = '@@fail/GET_INTEREST_REPAYMENT';

export const REQUEST_SAVE_LOAN_ROLLOVER_REPAYMENT = '@@request/SAVE_LOAN_ROLLOVER_REPAYMENT';
export const RECEIVE_SAVE_LOAN_ROLLOVER_REPAYMENT =
  '@@receive-The transaction was successfully saved/SAVE_LOAN_ROLLOVER_REPAYMENT';
export const FAIL_SAVE_LOAN_ROLLOVER_REPAYMENT = '@@fail/SAVE_LOAN_ROLLOVER_REPAYMENT';

export const REQUEST_SAVE_FACILITY_CASH_FLOW = '@@request/SAVE_FACILITY_CASH_FLOW';
export const RECEIVE_SAVE_FACILITY_CASH_FLOW =
  '@@receive-The transaction was successfully saved/SAVE_FACILITY_CASH_FLOW';
export const FAIL_SAVE_FACILITY_CASH_FLOW = '@@fail/SAVE_FACILITY_CASH_FLOW';

export const REQUEST_CHANGE_STATUS_LOAN = '@@request/CHANGE_STATUS_LOAN';
export const RECEIVE_CHANGE_STATUS_LOAN = '@@receive/CHANGE_STATUS_LOAN';
export const FAIL_CHANGE_STATUS_LOAN = '@@fail/CHANGE_STATUS_LOAN';

export const REQUEST_SPLIT_CASH_FLOW = '@@request/SPLIT_CASH_FLOW';
export const RECEIVE_SPLIT_CASH_FLOW =
  '@@receive-The transaction was successfully saved/SPLIT_CASH_FLOW';
export const FAIL_SPLIT_CASH_FLOW = '@@fail/SPLIT_CASH_FLOW';

export const REQUEST_DOWNLOAD_PROMISSORY_NOTE = '@@request/DOWNLOAD_PROMISSORY_NOTE';
export const RECEIVE_DOWNLOAD_PROMISSORY_NOTE = '@@receive/DOWNLOAD_PROMISSORY_NOTE';
export const FAIL_DOWNLOAD_PROMISSORY_NOTE = '@@fail/DOWNLOAD_PROMISSORY_NOTE';

export const REQUEST_GETREPAY_HISTORY = '@@request/GETREPAY_HISTORY';
export const RECEIVE_GETREPAY_HISTORY = '@@receive/GETREPAY_HISTORY';
export const FAIL_GETREPAY_HISTORY = '@@fail/GETREPAY_HISTORY';

export const getRepayRolloverHistory = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/loan/GetRepayRolloverHistory/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GETREPAY_HISTORY, RECEIVE_GETREPAY_HISTORY, FAIL_GETREPAY_HISTORY],
  },
});

export const downloadPromissoryNote = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/loan/DownloadPromissoryNote/${transactionNo}`),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_PROMISSORY_NOTE,
      {
        type: RECEIVE_DOWNLOAD_PROMISSORY_NOTE,
        payload: (action, state, res) => {
          downloadFile(res);
        },
      },
      FAIL_DOWNLOAD_PROMISSORY_NOTE,
    ],
  },
});

export const splitCashFlow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/splitcashflow', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SPLIT_CASH_FLOW, RECEIVE_SPLIT_CASH_FLOW, FAIL_SPLIT_CASH_FLOW],
  },
});

export const changeStatus = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/changestatusloan', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_CHANGE_STATUS_LOAN, RECEIVE_CHANGE_STATUS_LOAN, FAIL_CHANGE_STATUS_LOAN],
  },
});

export const saveCashFlow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savecashflow', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_FACILITY_CASH_FLOW,
      RECEIVE_SAVE_FACILITY_CASH_FLOW,
      FAIL_SAVE_FACILITY_CASH_FLOW,
    ],
  },
});

export const saveRollOverRepay = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/SaveRollOverRepayment', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_LOAN_ROLLOVER_REPAYMENT,
      RECEIVE_SAVE_LOAN_ROLLOVER_REPAYMENT,
      FAIL_SAVE_LOAN_ROLLOVER_REPAYMENT,
    ],
  },
});

export const getInterestRepayment = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/GetInterestRepayment', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_GET_INTEREST_REPAYMENT,
      RECEIVE_GET_INTEREST_REPAYMENT,
      FAIL_GET_INTEREST_REPAYMENT,
    ],
  },
});

export const getFacilityList = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/getfacilitylist', criteria),
    method: 'GET',
    types: [REQUEST_GET_FACILITY_LIST, RECEIVE_GET_FACILITY_LIST, FAIL_GET_FACILITY_LIST],
  },
});

export const getFacilityForLoan = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getfacilityforloan/${transactionNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_FACILITY_FOR_LOAN,
      RECEIVE_GET_FACILITY_FOR_LOAN,
      FAIL_GET_FACILITY_FOR_LOAN,
    ],
  },
});

export const saveLoan = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/saveloan'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_LOAN, RECEIVE_SAVE_LOAN, FAIL_SAVE_LOAN],
  },
});

export const processCashflow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/processCashFlow'),
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    method: 'POST',
    types: [REQUEST_PROCESS_CASH_FLOW, RECEIVE_PROCESS_CASH_FLOW, FAIL_PROCESS_CASH_FLOW],
  },
});

export const getTemplateInterestRateList = () => ({
  [RSAA]: {
    endpoint: endpoint('/loan/gettemplateinterestlist'),
    method: 'GET',
    types: [
      REQUEST_GET_TEMPLATE_LIST_INTEREST_RATE,
      RECEIVE_GET_TEMPLATE_LIST_INTEREST_RATE,
      FAIL_GET_TEMPLATE_LIST_INTEREST_RATE,
    ],
  },
});

export const getInterestRate = (interestRateId) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getinterestrate/${interestRateId}`),
    method: 'GET',
    types: [
      REQUEST_GET_MAINTAIN_INTEREST_RATE,
      RECEIVE_GET_MAINTAIN_INTEREST_RATE,
      FAIL_GET_MAINTAIN_INTEREST_RATE,
    ],
  },
});

export const getLoan = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getloan/${transactionNo}`),
    method: 'GET',
    types: [REQUEST_GET_LOAN_RATE, RECEIVE_GET_LOAN_RATE, FAIL_GET_LOAN_RATE],
  },
});

export const searchLoan = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/searchloan', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_PN_LOAN, RECEIVE_SEARCH_PN_LOAN, FAIL_SEARCH_PN_LOAN],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_PN_LOAN,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_PN_LOAN_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_PN_LOAN_CRITERIA,
  payload: {
    name,
    value,
  },
});
