import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_BANK_TRANSFER_POSTING = '@@request/SEARCH_BANK_TRANSFER_POSTING';
export const RECEIVE_SEARCH_BANK_TRANSFER_POSTING = '@@receive/SEARCH_BANK_TRANSFER_POSTING';
export const FAIL_SEARCH_BANK_TRANSFER_POSTING = '@@fail/SEARCH_BANK_TRANSFER_POSTING';

export const UPDATE_SEARCH_RESULT_BANK_TRANSFER_POSTING =
  'UPDATE_SEARCH_RESULT_BANK_TRANSFER_POSTING';

export const REQUEST_CHANGE_POSTING_DATE = '@@request/CHANGE_POSTING_DATE';
export const RECEIVE_CHANGE_POSTING_DATE =
  '@@receive-The transaction was successfully saved/CHANGE_POSTING_DATE';
export const FAIL_CHANGE_POSTING_DATE = '@@fail/CHANGE_POSTING_DATE';

export const changePostingDate = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/accounting/ChangePostingDate'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_CHANGE_POSTING_DATE, RECEIVE_CHANGE_POSTING_DATE, FAIL_CHANGE_POSTING_DATE],
    },
  });

  if (!actionResponse.error) return await dispatch(searchBankTransferPosting(criteria));

  return actionResponse;
};

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_BANK_TRANSFER_POSTING,
  payload: {
    name,
    value,
    index,
  },
});

export const searchBankTransferPosting = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankTransferPosting', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_BANK_TRANSFER_POSTING,
      RECEIVE_SEARCH_BANK_TRANSFER_POSTING,
      FAIL_SEARCH_BANK_TRANSFER_POSTING,
    ],
  },
});
