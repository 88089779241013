import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_FOREIGN_EXCHANGE = '@@request/SEARCH_FOREIGN_EXCHANGE';
export const RECEIVE_SEARCH_FOREIGN_EXCHANGE = '@@receive/SEARCH_FOREIGN_EXCHANGE';
export const FAIL_SEARCH_FOREIGN_EXCHANGE = '@@fail/SEARCH_FOREIGN_EXCHANGE';

export const UPDATE_SEARCH_RESULT_FOREIGN_EXCHANGE = 'UPDATE_SEARCH_RESULT_FOREIGN_EXCHANGE';

export const REQUEST_UPDATE_FOREIGN_EXCHANGE = '@@request/UPDATE_FOREIGN_EXCHANGE';
export const RECEIVE_UPDATE_FOREIGN_EXCHANGE =
  '@@receive-The transaction was successfully saved/UPDATE_FOREIGN_EXCHANGE';
export const FAIL_UPDATE_FOREIGN_EXCHANGE = '@@fail/UPDATE_FOREIGN_EXCHANGE';

export const REQUEST_CHANGE_POSTING_DATE = '@@request/CHANGE_POSTING_DATE';
export const RECEIVE_CHANGE_POSTING_DATE =
  '@@receive-The transaction was successfully saved/CHANGE_POSTING_DATE';
export const FAIL_CHANGE_POSTING_DATE = '@@fail/CHANGE_POSTING_DATE';

export const REQUEST_FI_POSTING = '@@request/FI_POSTING';
export const RECEIVE_FI_POSTING = '@@receive/FI_POSTING';
export const FAIL_FI_POSTING = '@@fail/FI_POSTING';

export const REQUEST_CLEARING = '@@request/CLEARING';
export const RECEIVE_CLEARING = '@@receive/CLEARING';
export const FAIL_CLEARING = '@@fail/CLEARING';

export const REQUEST_FI_REVERSE = '@@request/FI_REVERSE';
export const RECEIVE_FI_REVERSE = '@@receive/FI_REVERSE';
export const FAIL_FI_REVERSE = '@@fail/FI_REVERSE';

export const REQUEST_GEN_XML = '@@request/GEN_XML';
export const RECEIVE_GEN_XML = '@@receive-The transaction was successfully saved/GEN_XML';
export const FAIL_GEN_XML = '@@fail/GEN_XML';

export const REQUEST_SAVE_MANUAL = '@@request/SAVE_MANUAL';
export const RECEIVE_SAVE_MANUAL = '@@receive-The transaction was successfully saved/SAVE_MANUAL';
export const FAIL_SAVE_MANUAL = '@@fail/SAVE_MANUAL';

export const REQUEST_CHEQUE_UPDATE = '@@request/CHEQUE_UPDATE';
export const RECEIVE_CHEQUE_UPDATE = '@@receive/CHEQUE_UPDATE';
export const FAIL_CHEQUE_UPDATE = '@@fail/CHEQUE_UPDATE';

export const REQUEST_GET_ACCOUNTING_CLEARING = '@@request/GET_ACCOUNTING_CLEARING';
export const RECEIVE_GET_ACCOUNTING_CLEARING = '@@receive/GET_ACCOUNTING_CLEARING';
export const FAIL_GET_ACCOUNTING_CLEARING = '@@fail/GET_ACCOUNTING_CLEARING';

export const UPDATE_RESULT_CLEARING = 'UPDATE_RESULT_CLEARING';

export const REQUEST_SAVE_ACCOUNTING_CLEARING = '@@request/SAVE_ACCOUNTING_CLEARING';
export const RECEIVE_SAVE_ACCOUNTING_CLEARING =
  '@@receive-The transaction was successfully saved/SAVE_ACCOUNTING_CLEARING';
export const FAIL_SAVE_ACCOUNTING_CLEARING = '@@fail/SAVE_ACCOUNTING_CLEARING';

export const REQUEST_FLAG_UNFLAG = '@@request/FLAG_UNFLAG';
export const RECEIVE_FLAG_UNFLAG = '@@receive/FLAG_UNFLAG';
export const FAIL_FLAG_UNFLAG = '@@fail/FLAG_UNFLAG';

export const saveAccountingFlag = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/SaveAccountingFlag'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_FLAG_UNFLAG, RECEIVE_FLAG_UNFLAG, FAIL_FLAG_UNFLAG],
  },
});

export const submitClearing = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/SaveAccountingClearing'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_ACCOUNTING_CLEARING,
      RECEIVE_SAVE_ACCOUNTING_CLEARING,
      FAIL_SAVE_ACCOUNTING_CLEARING,
    ],
  },
});

export const updateResultClearing = (name, value, index) => ({
  type: UPDATE_RESULT_CLEARING,
  payload: {
    name,
    value,
    index,
  },
});

export const getAccountingClearing = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/GetAccountingClearing', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_ACCOUNTING_CLEARING,
      RECEIVE_GET_ACCOUNTING_CLEARING,
      FAIL_GET_ACCOUNTING_CLEARING,
    ],
  },
});

export const chequeUpdate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/sapinterface/checkupdate', data),
    method: 'GET',
    types: [REQUEST_CHEQUE_UPDATE, RECEIVE_CHEQUE_UPDATE, FAIL_CHEQUE_UPDATE],
  },
});

export const saveManual = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/SaveAccountingManualPost'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_MANUAL, RECEIVE_SAVE_MANUAL, FAIL_SAVE_MANUAL],
  },
});

export const genXml = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/sapinterface/genxml'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_GEN_XML, RECEIVE_GEN_XML, FAIL_GEN_XML],
  },
});

export const fiReverse = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/sapinterface/fireverse'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_FI_REVERSE, RECEIVE_FI_REVERSE, FAIL_FI_REVERSE],
  },
});

export const fiPosting = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/sapinterface/fiposting'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_FI_POSTING, RECEIVE_FI_POSTING, FAIL_FI_POSTING],
  },
});

export const Clearing = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/sapinterface/clearing'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_CLEARING, RECEIVE_CLEARING, FAIL_CLEARING],
  },
});

export const changePostingDate = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/accounting/ChangePostingDate'),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_CHANGE_POSTING_DATE, RECEIVE_CHANGE_POSTING_DATE, FAIL_CHANGE_POSTING_DATE],
    },
  });

  if (!actionResponse.error) return await dispatch(searchForeignExchange(criteria));

  return actionResponse;
};

export const updateForeignExchange = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/accounting/saveforeignexchange'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [
        REQUEST_UPDATE_FOREIGN_EXCHANGE,
        RECEIVE_UPDATE_FOREIGN_EXCHANGE,
        FAIL_UPDATE_FOREIGN_EXCHANGE,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(searchForeignExchange(criteria));

  return actionResponse;
};

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_FOREIGN_EXCHANGE,
  payload: {
    name,
    value,
    index,
  },
});

export const searchForeignExchange = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/accounting/searchforeignexchange', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_FOREIGN_EXCHANGE,
      RECEIVE_SEARCH_FOREIGN_EXCHANGE,
      FAIL_SEARCH_FOREIGN_EXCHANGE,
    ],
  },
});
