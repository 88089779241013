import React, { Component } from 'react';

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remove: false,
      toggler: this.props.collapse || false,
    };
  }

  toggleEventHandler = (e) => {
    const state = { ...this.state };
    state[e] = !state[e];
    this.setState(state);
    if (e === 'reload' && this.props.onClickReload) this.props.onClickReload();
    else if (e === 'remove' && this.props.onClickRemove) this.props.onClickRemove();
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.collapse !== this.props.collapse) {
      this.setState({
        toggler: this.props.collapse,
      });
    }
  }

  render() {
    const {
      bgHeader = '',
      textHeader = '',
      cardActions = [],
      children,
      number,
      footer,
      headerNoBold,
    } = this.props;
    const actionTitles = { toggler: 'Collapse', reload: 'Reload', remove: 'Remove' };
    return (
      <div
        className={`card ${this.state.toggler ? 'card-collapsed' : ''}`}
        // style={{ display: this.state.remove ? 'none' : 'block' }}
      >
        {textHeader ? (
          <div className={`card-header ${bgHeader}`}>
            <div className="card-actions">
              {cardActions.map((item) => (
                <button
                  type="button"
                  key={item}
                  className={`card-action card-${item}`}
                  onClick={() => this.toggleEventHandler(item)}
                  title={actionTitles[item]}
                  aria-expanded="true"
                />
              ))}
            </div>
            {headerNoBold ? (
              <span> {textHeader} </span>
            ) : (
              <strong>
                <span
                  className="badge badge-default"
                  style={{ display: number ? 'inline' : 'none' }}
                >
                  {number}
                </span>{' '}
                {textHeader}{' '}
              </strong>
            )}
          </div>
        ) : null}

        {/* <div className={`card-body ${this.state.toggler ? 'card-in' : ''}`} ref={el => this.bodyRef = el}>
                    {children}
                </div>

                {
                    footer ? (
                    <div className="card-footer">
                        <center>
                            {footer}
                        </center>
                    </div>
                    ) : null
                } */}

        <div className={`${this.state.toggler ? 'card-in' : ''}`} ref={(el) => (this.bodyRef = el)}>
          <div className="card-body">{children}</div>

          {footer ? (
            <div className="card-footer">
              <center>{footer}</center>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
