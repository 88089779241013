import { connect } from 'react-redux';
import React, { Component } from 'react';

import CreateExposure from './CreateExposure';
import ImportExcel from '../../common/ImportExcel';
import ModalReport from '../../common/ModalReport';
import { addAlert } from '../../reducers/layout/action';
import { fetchEmailInward } from '../../reducers/exposurePosition/action';
import { toFixed, toLower, toNumber, getPermissionStr } from '../../common/helpper';
import {
  cancelExposure,
  activeExposure,
  uploadImportExcel,
  searchCreateExposure,
  createExposureImportExcel,
  downloadAttachFileExposure,
} from '../../reducers/createExposure/action';

const permission = getPermissionStr('FX Management', 'Create Exposure');
const viewInward = toLower(permission) === 'view (inward)';
const viewOutward = toLower(permission) === 'view (outward)';

const $ = window.jQuery;

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companies: [''],
  ValueDateStr: '',
  Currencies: [''],
  Banks: [''],
  CashTypes: [''],
  TransactionTypes: [''],
  InformDateStr: '',
  Status: '',
  IsActive: '',
  ExposureType: '',
};

class CreateExposureContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    selectedRowIdx: null,
    reportFetchEmail: {},
  };

  dtReportRef = null;

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.active`, (e) => this.onClickOpenModalChangeStatus(e, 'active'));
    $('body').on('click', `button.inactive`, (e) => this.onClickOpenModalChangeStatus(e, 'cancel'));

    if (toLower(permission).includes('view')) {
      this.setState({
        criteria: {
          ...initCriteria,
          CashTypes: viewInward ? ['Inward'] : viewOutward ? ['Outward'] : [''],
        },
      });
    }
  }

  componentWillUnmount() {
    $('body').off('click', `button.active`);
    $('body').off('click', `button.inactive`);
  }

  // search
  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchCreateExposure({
      ...this.state.criteria,
    });
  };

  onClickClearSearch = () => {
    if (toLower(permission).includes('view')) {
      this.setState({
        criteria: {
          ...initCriteria,
          CashTypes: viewInward ? ['Inward'] : viewOutward ? ['Outward'] : [''],
        },
      });
    } else {
      this.setState({
        criteria: {
          ...initCriteria,
        },
      });
    }
  };
  // end search

  onClickFetchHandler = () => {
    this.props.fetchEmailInward().then((response) => {
      if (response.error) return;
      this.setState(
        {
          reportFetchEmail: response.payload,
        },
        this.dtReportRef.open
      );
    });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    this.setState(
      {
        selectedRowIdx: row,
      },
      () => {
        if (modal === 'active') this.modalActiveRef.open();
        else if (modal === 'cancel') this.modalCancelRef.open();
      }
    );
  };

  onSubmitModalChangeStatusHandler = (e, Status) => {
    const { selectedRowIdx } = this.state;
    const { exposureNo } = this.props.searchResult[selectedRowIdx];
    if (toLower(Status) === 'active') {
      this.props.activeExposure(exposureNo, selectedRowIdx).then((response) => {
        if (response.error) return;
        this.props.searchCreateExposure({ ...this.state.criteria });
        this.modalActiveRef.close();
      });
    } else if (toLower(Status) === 'cancel') {
      this.props.cancelExposure(exposureNo, selectedRowIdx).then((response) => {
        if (response.error) return;
        this.props.searchCreateExposure({ ...this.state.criteria });
        this.modalCancelRef.close();
      });
    }
  };

  onClickOpenModalImportExcel = () => {
    this.importExcelRef.open();
  };

  onClickUploadImportExcel = (file) => {
    const form = new FormData();
    form.append('file', file);
    this.props.uploadImportExcel(form);
  };

  onSubmitUploadImportExcel = () => {
    const { importExcelResult } = this.props;
    if (!importExcelResult.rows.length || importExcelResult.hasError) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Data not avaliable.',
      });
      return;
    }
    this.props.createExposureImportExcel(importExcelResult.rows);
  };

  onClickDownloadAttachFile = (e, cell) => {
    const fileName = cell.data();
    if (!fileName) return;
    this.props.downloadAttachFileExposure(fileName);
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      onClickFetch: this.onClickFetchHandler,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onClickOpenModalImportExcel: this.onClickOpenModalImportExcel,

      onClickOpenModalChangeStatus: this.onClickOpenModalChangeStatus,
      onSubmitModalChangeStatusHandler: this.onSubmitModalChangeStatusHandler,
      results: this.props.searchResult,
      onClickDownloadAttachFile: this.onClickDownloadAttachFile,
    };

    return (
      <div>
        <CreateExposure
          {...props}
          {...this.state}
          modalActiveRef={(e) => (this.modalActiveRef = e)}
          modalCancelRef={(e) => (this.modalCancelRef = e)}
        />
        <ImportExcel
          ref={(e) => (this.importExcelRef = e)}
          tableName="dt-import-excel-exposure"
          optionDt={opt}
          value={this.props.importExcelResult}
          onClickSaveImport={this.onSubmitUploadImportExcel}
          onUpload={this.onClickUploadImportExcel}
        >
          <thead>
            <tr>
              <th>Company Code</th>
              <th>Cash Type</th>
              <th>Transaction Type</th>
              <th>Inform Date</th>
              <th>Value Date</th>
              <th>Swife Code (6 digits)</th>
              <th>Bank Account No.</th>
              <th>Amount</th>
              <th>Exposure Currency</th>
              <th>Equivalent</th>
              <th>Payment Type</th>
              <th>Exposure Type</th>
              <th>Vendor/Customer Name</th>
              <th>Reference No.</th>
              <th>Transaction Detail</th>
              <th>Forward Contract No.</th>
              <th>Project Name</th>
              <th>Error</th>
            </tr>
          </thead>
        </ImportExcel>
        <ModalReport
          dataTableId="dt-report-fetch-inward"
          opt={optIw}
          value={this.state.reportFetchEmail}
          ref={(e) => (this.dtReportRef = e)}
        >
          <thead>
            <tr>
              <th>Bank Inform Date</th>
              <th>Company Code</th>
              <th>Customer/Vendor name</th>
              <th>External Doc. No.</th>
              <th>Payment Type</th>
              <th>Bank Ref. No.</th>
              <th>
                Transaction <br />
                Currency
              </th>
              <th>Invoice Amount</th>
              <th>Transaction Amount</th>
              <th>Bank Remark</th>
              <th>Bank Swift Code</th>
              <th>Department</th>
              <th>Payment Terms</th>
              <th>FileName</th>
              <th>Error</th>
            </tr>
          </thead>
        </ModalReport>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.createExposure,
  }),
  {
    searchCreateExposure,
    cancelExposure,
    activeExposure,
    uploadImportExcel,
    createExposureImportExcel,
    addAlert,
    fetchEmailInward,
    downloadAttachFileExposure,
  }
)(CreateExposureContainer);

const opt = {
  columns: [
    { data: 'companyCode', className: 'text-center' },
    { data: 'cashType' },
    { data: 'transactionType' },
    { data: 'informDateStr', className: 'text-center' },
    { data: 'valueDateStr', className: 'text-center' },
    { data: 'swiftCode' },
    { data: 'bankAccountNo' },
    { data: 'amount', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'currency', className: 'text-center' },
    { data: 'equivalent' },
    { data: 'paymentType' },
    { data: 'exposureType' },
    { data: 'customerName' },
    { data: 'referenceNo' },
    { data: 'transactionDetail' },
    { data: 'forwardNo' },
    { data: 'projectName' },
    { data: 'errorMessage' },
  ],
};

const optIw = {
  columns: [
    { data: 'bankInformDateStr', className: 'text-center' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'customerName' },
    { data: 'externalDocNo' },
    { data: 'paymentType' },
    { data: 'bankReference' },
    { data: 'transactionCurrency', className: 'text-center' },
    { data: 'invoiceAmount', className: 'text-right' },
    {
      data: 'transactionAmount',
      className: 'text-right',
      render: (d) => toFixed(d, 2),
    },
    { data: 'bankRemark' },
    { data: 'swiftCode' },
    { data: 'department' },
    { data: 'paymentTerm' },
    { data: 'fileName' },
    { data: 'errorMessage' },
  ],
};
