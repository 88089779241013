import { connect } from 'react-redux';
import React, { Component } from 'react';

import Issuer from './Issuer';
import { addAlert } from '../../../reducers/layout/action';
import {
  saveIssuer,
  searchIssuer,
  editCriteria,
  clearCriteria,
} from '../../../reducers/issuer/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-issuer';

const initModal = {
  issuerNameHeader: '',
  issuerName: '',
  issuerCountry: '',
  status: '',
};

class IssuerContainer extends Component {
  state = {
    modalData: {
      ...initModal,
    },
  };

  dataTableSearchResultRef = null;

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.props.editCriteria(name, value);
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchIssuer({ ...this.props.criteria });
  };

  onClickOpenModalChangeStatus = (e, modal) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();

    this.setState({
      issuer: rowData,
      modal,
    });

    if (modal === 'Active') this.modalActiveRef.open();
    else if (modal === 'Inactive') this.modalInactiveRef.open();
  };

  onSubmitModalChangeStatus = (e, modal) => {
    const { issuer } = this.state;
    issuer.isActive = modal.toLowerCase() === 'active';
    this.props.saveIssuer({ ...issuer }).then((response) => {
      if (response.error) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
      this.modalActiveRef.close();
      this.modalInactiveRef.close();
      this.props.searchIssuer({ ...this.props.criteria });
    });
  };

  onClickOpenModalHandler = (e, cell) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    if (typeof cell === 'string') {
      this.setState({
        modalData: {
          ...initModal,
          issuerNameHeader: 'Create Issuer',
        },
      });
    } else {
      this.setState({
        modalData: {
          ...rowData,
          issuerNameHeader: rowData.issuerName,
        },
      });
    }

    this.modal.open();
  };

  onChangeModalHeadler = (e) => {
    this.setState({
      modalData: {
        ...this.state.modalData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onClickSaveModalHandler = (e) => {
    e.preventDefault();
    this.props
      .saveIssuer({
        ...this.state.modalData,
      })
      .then((response) => {
        if (response.error) return;
        this.modal.close();
        this.props.searchIssuer({ ...this.props.criteria });
      });
  };

  render() {
    const props = {
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),

      onClickOpenModal: this.onClickOpenModalHandler,
      onChangeModal: this.onChangeModalHeadler,
      onClickSaveModal: this.onClickSaveModalHandler,
      results: this.props.searchResult,

      onClickOpenModalChangeStatus: this.onClickOpenModalChangeStatus,
      onSubmitModalChangeStatus: this.onSubmitModalChangeStatus,
    };

    return (
      <Issuer
        {...props}
        {...this.state}
        criteria={this.props.criteria}
        modalData={this.state.modalData}
        modalRef={(e) => (this.modal = e)}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInactiveRef={(e) => (this.modalInactiveRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.issuer,
  }),
  {
    searchIssuer,
    editCriteria,
    clearCriteria,
    saveIssuer,
    addAlert,
  }
)(IssuerContainer);
