import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MAINTAIN_BENEFICIARY_CRITERIA = 'EDIT_MAINTAIN_BENEFICIARY_CRITERIA';
export const CLEAR_MAINTAIN_BENEFICIARY_CRITERIA = 'CLEAR_MAINTAIN_BENEFICIARY_CRITERIA';

export const UPDATE_SEARCH_RESULT_MAINTAIN_BENEFICIARY =
  'UPDATE_SEARCH_RESULT_MAINTAIN_BENEFICIARY';

export const REQUEST_SEARCH_MAINTAIN_BENEFICIARY = '@@request/SEARCH_MAINTAIN_BENEFICIARY';
export const RECEIVE_SEARCH_MAINTAIN_BENEFICIARY = '@@receive/SEARCH_MAINTAIN_BENEFICIARY';
export const FAIL_SEARCH_MAINTAIN_BENEFICIARY = '@@fail/SEARCH_MAINTAIN_BENEFICIARY';

export const REQUEST_SAVE_MAINTAIN_BENEFICIARY = '@@request/SAVE_MAINTAIN_BENEFICIARY';
export const RECEIVE_SAVE_MAINTAIN_BENEFICIARY =
  '@@receive-The record was successfully saved/SAVE_MAINTAIN_BENEFICIARY';
export const FAIL_SAVE_MAINTAIN_BENEFICIARY = '@@fail/SAVE_MAINTAIN_BENEFICIARY';

export const REQUEST_GET_MAINTAIN_FORM = '@@request/GET_MAINTAIN_FORM';
export const RECEIVE_GET_MAINTAIN_FORM = '@@receive/GET_MAINTAIN_FORM';
export const FAIL_GET_MAINTAIN_FORM = '@@fail/GET_MAINTAIN_FORM';

export const getMaintainForm = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getmaintainbgform', criteria),
    method: 'GET',
    types: [REQUEST_GET_MAINTAIN_FORM, RECEIVE_GET_MAINTAIN_FORM, FAIL_GET_MAINTAIN_FORM],
  },
});

export const saveMaintainBank = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/bankguarantee/savemaintainbeneficiary`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MAINTAIN_BENEFICIARY,
      RECEIVE_SAVE_MAINTAIN_BENEFICIARY,
      FAIL_SAVE_MAINTAIN_BENEFICIARY,
    ],
  },
});

export const searchMaintainBank = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/bankguarantee/getmaitainbeneficiary', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MAINTAIN_BENEFICIARY,
      RECEIVE_SEARCH_MAINTAIN_BENEFICIARY,
      FAIL_SEARCH_MAINTAIN_BENEFICIARY,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MAINTAIN_BENEFICIARY,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MAINTAIN_BENEFICIARY_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MAINTAIN_BENEFICIARY_CRITERIA,
  payload: {
    name,
    value,
  },
});
