import { connect } from 'react-redux';
import React, { Component } from 'react';

import CompanyDetail from './CompanyDetail';
import { addAlert } from '../../reducers/layout/action';
import { toUpperKey, checkAfterValueDate } from '../../common/helpper';
import {
  saveCompany,
  getCompanyDetail,
  getMasterCompanyAdd,
  approveMasterCompany,
  getMasterCompanyEffectiveDate,
} from '../../reducers/masterCompany/action';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-companydetail';

const initData = {
  EffectiveDateStr: '',
  TempEffectiveDateStr: 'New',

  FinanceGroupId: '',
  FinanceGroupName: '',
  BuId: '',
  BusinessUnitName: '',
  CompanyCode: '',
  CompanyAbbreviate: '',
  CompanyName: '',
  CoaId: ['SCG ECC6.0'],
  Instance: 'NonSAP|NonSAP',
  InstanceCode: 'NonSAP',
  InstanceName: 'NonSAP',
  Vendor: '',
  Customer: '',
  TaxId: '',
  DefaultBg: '',

  Address: '',
  AddressTh: '',
  SubDistrict: '',
  SubDistrictTh: '',
  District: '',
  DistrictTh: '',
  Province: '',
  ProvinceTh: '',
  PostCode: '',
  PostCodeTh: '',
  CountryCode: '',
  CountryCodeTh: '',

  resultLedger: [],
  mode: 'create',
  setOverDueDate: false,
  MainCompanyCode: '',
  IsActive: true,

  IsFetchEmailForward: '',
  LogoId: '',
  SignatureId: '',
  Approver1: '',
  Approver1Status: '',
  ApprovalStatus: '',
  IsConsole: '',
  Remark: '',
  CompanyType: '',
};

class CompanyDetailContainer extends Component {
  state = {
    ...initData,
    modalData: {
      CompanyCode: '',
    },
    resultEffective: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;
    if (!id || id.toString().toLowerCase() === 'create')
      this.setState({
        mode: 'create',
      });
    else {
      this.fetchId(id);
    }

    this.props.getMasterCompanyAdd({ CompanyCode: id, Mode: 'get' });
    $('body').on('click', `button.delete-ledger`, (e) => this.onClickDeleteLedger(e));
  }

  componentWillUnmount() {
    $('body').off('click', `button.delete-ledger`);
  }

  fetchId(id) {
    if (!id) return;

    this.props.getMasterCompanyEffectiveDate({ CompanyCode: id, Mode: 'date' }).then((response) => {
      if (response.error || !response.payload) return;

      const result = response.payload.map((m) => m.effectiveDateStr);
      this.setState({
        ...initData,
        resultEffective: result,
      });

      const result2 = result.filter((f) => {
        if (!checkAfterValueDate(f, 0)) return true;
        return false;
      });

      if (result2.length === 0) {
        this.setDataCompany(result[result.length - 1]);
      } else {
        const effectiveDate = result2[0];
        this.setDataCompany(effectiveDate);
      }
    });
  }

  setDataCompany(effectiveDateStr) {
    const { id } = this.props.routeProp.match.params;

    this.props
      .getCompanyDetail({ CompanyCode: id, EffectiveDateStr: effectiveDateStr })
      .then((response) => {
        if (response.error || !response.payload) return;

        const fetchData = toUpperKey(response.payload);
        if (fetchData.CoaId) fetchData.CoaId = fetchData.CoaId.split(',') || [];
        this.setState({
          ...fetchData,
          PostCodeTh: fetchData.PostCode,
          CountryCodeTh: fetchData.CountryCode,
          mode: 'edit',
          TempEffectiveDateStr: fetchData.EffectiveDateStr,
          Instance: `${fetchData.InstanceCode}|${fetchData.InstanceName}`,
          setOverDueDate: !checkAfterValueDate(fetchData.EffectiveDateStr, 0),
        });
        this.setLedger(fetchData);
      });
  }

  setLedger(data) {
    const result = [];

    if (data.LedgerId1 || data.Currency1) {
      result.push({
        Ledger: data.LedgerId1,
        Currency: data.Currency1,
      });
    }

    if (data.LedgerId2 || data.Currency2) {
      result.push({
        Ledger: data.LedgerId2,
        Currency: data.Currency2,
      });
    }

    if (data.LedgerId3 || data.Currency3) {
      result.push({
        Ledger: data.LedgerId3,
        Currency: data.Currency3,
      });
    }

    if (data.LedgerId4 || data.Currency4) {
      result.push({
        Ledger: data.LedgerId4,
        Currency: data.Currency4,
      });
    }

    this.setState({
      ...this.state,
      resultLedger: result,
    });
  }

  onChangeInputData = (e) => {
    const { name, value, label } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };

      if (name === 'CompanyCode') {
        stateToUpdate.CompanyCode = value.trim();
        if (value.length > 4 || value.includes('.')) {
          return state.CompanyCode;
        }
      } else if (name === 'Instance' && value) {
        stateToUpdate.InstanceCode = (label || '').split('|')[0].trim() || '';
        stateToUpdate.InstanceName = (label || '').split('|')[1].trim() || '';
      } else if (name === 'Vendor') {
        stateToUpdate.Vendor = value.trim();
        if (value.length > 4 || value.includes('.')) {
          return state.Vendor;
        }
        stateToUpdate.Customer = value.trim();
      } else if (name === 'PostCode' || name === 'PostCodeTh') {
        stateToUpdate.PostCode = value;
        stateToUpdate.PostCodeTh = value;
      } else if (name === 'CountryCode' || name === 'CountryCodeTh') {
        stateToUpdate.CountryCode = value;
        stateToUpdate.CountryCodeTh = value;
      } else if (name === 'IsFetchEmailForward') {
        stateToUpdate.IsFetchEmailForward = !this.state.IsFetchEmailForward;
      } else if (name === 'IsConsole') {
        stateToUpdate.IsConsole = !this.state.IsConsole;
      }

      return stateToUpdate;
    });

    if (name === 'TempEffectiveDateStr') {
      if (value === 'New') {
        this.setState({
          EffectiveDateStr: '',
          setOverDueDate: false,
          IsActive: true,
        });
      } else {
        this.setDataCompany(value);
      }
    }
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitHander = async (e) => {
    const inValidFormName = this.validateForms([
      this.formDetailRef,
      this.formLedgerRef,
      this.formAddressRef,
      this.formReportRef,
      this.formApproveRef,
    ]);
    if (inValidFormName) return;

    const state = { ...this.state };
    if (state.CompanyCode.length !== 4) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Company code must be 4 characters.',
      });
      return;
    }

    if (state.Vendor && state.Vendor.length !== 4) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Vendor code must be 4 characters.',
      });
      return;
    }

    // if (!state.resultLedger.length) {
    //     this.props.addAlert({
    //         title: 'Error',
    //         type: 'error',
    //         body: 'Please add Ledger and Currency',
    //     })
    //     return
    // }

    if (state.mode === 'create' && !this.attachFileRef.fileLength) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please attach file.',
      });
      this.tabRef.setTabActive('attachFile');
      return;
    }

    const submitData = {
      ...this.state,
    };
    submitData.Mode = submitData.mode;

    delete submitData.resultLedger;
    delete submitData.modalData;
    delete submitData.resultEffective;
    delete submitData.mode;

    if (submitData.CoaId && Array.isArray(submitData.CoaId))
      submitData.CoaId = submitData.CoaId.join(',');

    const res = await this.attachFileRef.submit();
    if (res.error) {
      return;
    }
    this.props.saveCompany(submitData).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        window.location.href = `/master/company-detail/${response.payload}`;
      } else {
        this.fetchId(response.payload);
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/master/company');
  };

  onClickAddLedger = (e) => {
    this.setState({
      ...this.state,
      resultLedger: [
        ...this.state.resultLedger,
        {
          Ledger: '',
          Currency: '',
        },
      ],
    });
  };

  onClickDeleteLedger = (e) => {
    const index = parseInt(e.currentTarget.attributes.index.value);
    const newResult = this.state.resultLedger.filter((item, i) => i !== index);
    const ledger = `LedgerId${index + 1}`;
    const currency = `Currency${index + 1}`;
    this.setState({
      ...this.state,
      [ledger]: '',
      [currency]: '',
      resultLedger: newResult,
    });
  };

  onChangeInputLedger = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => {
      if (name === 'Ledger') {
        const ledger = `LedgerId${index + 1}`;
        state[ledger] = value;
      } else if (name === 'Currency') {
        const currency = `Currency${index + 1}`;
        state[currency] = value;
      }
      return {
        resultLedger: this.state.resultLedger.map((m, i) => {
          if (i === index) {
            const obj = {
              ...m,
            };
            obj[name] = value;
            return obj;
          }
          return m;
        }),
      };
    });
  };

  onClickClearCompany = (e) => {
    this.setState({
      ...this.state,
      MainCompanyCode: '',
    });
  };

  onClickClearDefaultBg = (e) => {
    this.setState({
      ...this.state,
      DefaultBg: '',
    });
  };

  onChangeModalApprove = (e) => {
    const { name, value } = e.target;

    const { Approver1, Approver2 } = this.state;
    const approverToCheck = name === 'Approver2' ? Approver1 : Approver2;
    // ceck dupicate
    if (approverToCheck === value) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Dupicate approver',
      });
      return;
    }

    this.setState({
      [name]: value,
    });
  };

  onClickApprove = (approve) => {
    this.props
      .approveMasterCompany({
        CompanyCode: this.state.CompanyCode,
        IsApprove: approve,
      })
      .then((res) => {
        if (res.error) return;

        window.location.reload();
      });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,

      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      resultCompanySub: this.props.resultCompanySub,
      resultCompanyAdd: this.props.resultCompanyAdd,

      onClickAddLedger: this.onClickAddLedger,
      onChangeInputLedger: this.onChangeInputLedger,

      onClickClearCompany: this.onClickClearCompany,
      onClickClearDefaultBg: this.onClickClearDefaultBg,

      tabRef: (e) => (this.tabRef = e),
      formDetailRef: (e) => (this.formDetailRef = e),
      formLedgerRef: (e) => (this.formLedgerRef = e),
      formAddressRef: (e) => (this.formAddressRef = e),
      formReportRef: (e) => (this.formReportRef = e),
      formApproveRef: (e) => (this.formApproveRef = e),

      onChangeModalApprove: this.onChangeModalApprove,
      onClickApprove: this.onClickApprove,

      attachFileRef: (e) => (this.attachFileRef = e),
    };

    return (
      <div>
        <CompanyDetail {...props} state={this.state} resultEffective={this.state.resultEffective} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterCompany,
  }),
  {
    getMasterCompanyEffectiveDate,
    getCompanyDetail,
    getMasterCompanyAdd,
    addAlert,
    saveCompany,
    approveMasterCompany,
  }
)(CompanyDetailContainer);
