import React from 'react';

export function toFixed(number, float = 3, noComma, noFixZero) {
  if (number === null || number === undefined) return null;
  if (isNaN(number)) number = 0;
  let floating = 0;
  // if not fix floating = number of float
  if (noFixZero) {
    const s = number.toString().split('.');
    if (s[1] && s[1].length > float) {
      floating = Number(number).toFixed(float);
    } else {
      floating = Number(number);
    }
  } else floating = Number(number).toFixed(float);

  const parts = floating.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // if (!Number(parts[1]))
  //     parts = parts.splice(0, 1)
  if (noComma) return parts.join('.').replace(/,/g, '');
  return parts.join('.');
}

export function toFixedNumber(number, float = 3) {
  if (float === null || float === '' || float === undefined) float = 0;
  if (isNaN(float)) float = 0;
  if (!number) return 0;
  if (isNaN(number)) return 0;

  number = Number(number);
  return Number(number.toFixed(float));
}

export function toBank(bankShortName, bankName) {
  if (bankShortName === '' || bankShortName == null) return '';
  return `${bankShortName} | ${bankName}`;
}

export function toBankCode(bankCode, bankShortName, bankName) {
  if (bankCode) return `${bankCode} | ${bankShortName} | ${bankName}`;
  if (bankShortName) return `${bankShortName} | ${bankName}`;
  if (bankName) return bankName;
  return '';
}

export function toAccount(
  bankShortName,
  bankBranch,
  bankType,
  bankCurrency,
  bankAccount,
  bankAccountNoOfBank
) {
  if (!bankAccount) return '';
  if (bankAccountNoOfBank) {
    return (
      `${bankBranch} | ${bankType} | ${bankCurrency}<br />${bankAccount}<br />` +
      `(${bankAccountNoOfBank})`
    );
  }
  if (bankShortName == null) {
    if ((bankAccount || '').length <= 13)
      return `${bankBranch} | ${bankType} | ${bankCurrency}<br />${bankAccount}`;
    return `${bankBranch} | ${bankType} | ${bankCurrency}<br />${bankAccount.substring(
      0,
      13
    )}<br />${bankAccount.substring(13, bankAccount.length)}`;
  }
  if ((bankAccount || '').length <= 13)
    return `${bankShortName} | ${bankBranch} | ${bankType} | ${bankCurrency}<br />${bankAccount}`;
  return `${bankShortName} | ${bankBranch} | ${bankType} | ${
    bankCurrency
  }<br />${bankAccount.substring(0, 13)}<br />${bankAccount.substring(13, bankAccount.length)}`;
}

export function toStatus(status, defaultElse) {
  let s = '';
  if (status != null && status !== '') {
    s = status.toLowerCase();
  } else {
    return '';
  }

  let text = '';
  if (~['wait for cheque no.', 'in progress', 'no post'].indexOf(s)) text = status;
  else text = `${s[0].toUpperCase()}${s.substr(1)}`;

  if (~['repay', 'withdraw', 'rollover', 'repayment', 'new', 'matching'].indexOf(s))
    return `<span class="label label-info">${text}</span>`;
  if (
    ~[
      'active',
      'approved',
      'completed',
      'accepted by bank',
      'confirmed',
      'wait for bu completed',
      'completed expire',
    ].indexOf(s)
  )
    return `<span class="badge badge-success">${text}</span>`;
  if (
    ~[
      'inactive',
      'disapprove',
      'rejected',
      'rejected by bank',
      'failed',
      'validation failed by bank',
      'inactive completed',
    ].indexOf(s)
  )
    return `<span class="badge badge-danger">${text}</span>`;
  if (~['already send to bank', 'in progress'].indexOf(s) || s.includes('wait'))
    return `<span class="badge badge-primary">${text}</span>`;
  if (~['withdraw', 'create', 'created'].indexOf(s))
    return `<span class="badge badge-warning">${text}</span>`;
  if (~['cancel', 'exported', 'voided', 'deleted', 'cancelled', 'no post'].indexOf(s))
    return `<span class="badge badge-default">${text}</span>`;
  if (defaultElse) return `<span class="badge badge-default">${text}</span>`;
  return text;

  // else if (s === 'wait for quote rate' || s === 'wait for confirm quote rate' || s === 'wait for contract no' || s === 'wait for confirm' || s === 'wait for cheque no.')
  //     return '<span class="badge badge-primary">' + status + '</span>'
}
export const setAmount = (number, float) => {
  if (number < 0) {
    number = Math.abs(number);
    return <span style={{ color: '#e64a19' }}>({toFixed(number, float)})</span>;
  }
  return toFixed(number, float);
};

export function toNumber(number, float = 2, million) {
  // if (isNaN(number)) return ''
  if (number >= 0 || !number) {
    if (million) return toMillion(number);
    return toFixed(number, float);
  }
  number = Math.abs(number);
  if (million) return `<span style="color:#e64a19">(${toMillion(number)})</span>`;
  return `<span style="color:#e64a19">(${toFixed(number, float)})</span>`;
}

export function findIndex(arr = [], compare) {
  let index = -1;
  for (let i = 0; i < arr.length; i++) {
    if (compare(arr[i])) {
      index = i;
      i = arr.length;
    }
  }

  return index;
}

export function toMillion(number) {
  if (isNaN(number)) return 0;

  let million = number / 1000000;
  million = toFixed(million, 2);

  return `<span >${million}</span>`;
}

export function downloadFile(fetchResponse) {
  fetchResponse.arrayBuffer().then((arrayBuffer) => {
    const blob = new Blob(
      [arrayBuffer]
      //     , {
      //     type: "application/pdf"
      // }
    );
    const url = URL.createObjectURL(blob);
    const fileName = fetchResponse.headers.get('file-name');
    if (!fileName) return;
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.href = url;
    a.download = decodeURIComponent(fileName || '');
    a.click();
    a.remove();
  });
}

export function toLower(text) {
  return (text || '').toLowerCase();
}

export function checkAfterValueDate(valueDateStr, nday = -1) {
  const { moment } = window;
  if (!valueDateStr) return false;
  return moment(valueDateStr, 'DD/MM/YYYY').isAfter(moment().add(nday, 'days'), 'day');
}

export function compareDate(firstDay, endDay) {
  if (!firstDay || !endDay) return false;
  const { moment } = window;
  if (firstDay && endDay) {
    if (moment(endDay, 'DD/MM/YYYY').isBefore(moment(firstDay, 'DD/MM/YYYY'), 'day')) return true;
  }
  return false;
}

export function compareDataNotEqual(firstDay, endDay) {
  if (!firstDay || !endDay) return false;
  const { moment } = window;
  if (firstDay && endDay) {
    if (moment(firstDay, 'DD/MM/YYYY').diff(moment(endDay, 'DD/MM/YYYY'), 'day') >= 0) return true;
  }
  return false;
}

export function checkBetween(start, end, checkDay) {
  const { moment } = window;
  if (end) {
    if (
      moment(start, 'DD/MM/YYYY') <= moment(checkDay, 'DD/MM/YYYY') &&
      moment(checkDay, 'DD/MM/YYYY') <= moment(end, 'DD/MM/YYYY')
    )
      return true;
  } else if (moment(start, 'DD/MM/YYYY') <= moment(checkDay, 'DD/MM/YYYY')) return true;
  return false;
}

export function getCountMonth(start, end) {
  return window
    .moment(end, 'DD/MM/YYYY')
    .add(0, 'days')
    .diff(window.moment(start, 'DD/MM/YYYY'), 'months', true);
}

export function getCountDay(start, end) {
  return window
    .moment(end, 'DD/MM/YYYY')
    .add(0, 'days')
    .diff(window.moment(start, 'DD/MM/YYYY'), 'days', true);
}

export function checkWeekend(date) {
  const { moment } = window;
  if (date && date !== '') {
    const day = moment(date, 'DD/MM/YYYY').format('dddd');
    if (day === 'Saturday' || day === 'Sunday') return true;
  }
  return false;
}

export function toUpperKey(obj) {
  const newObj = {};
  for (const key in obj) {
    newObj[`${key[0].toUpperCase()}${key.substr(1)}`] = obj[key];
  }

  return newObj;
}
export function toupperKey(key) {
  return `${key[0].toUpperCase()}${key.substr(1)}`;
}
export function tolowerKey(key) {
  return `${key[0].toLowerCase()}${key.substr(1)}`;
}

export function setCheckedCheckboxDataTableByInputElement(
  e,
  $dt,
  tdClassName = 'col-label-checkbox',
  checked
) {
  const $ = window.jQuery;
  const row = $dt.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
  return setCheckedCheckboxDataTableByRowIndex(row, $dt, tdClassName, checked);
}

export function setCheckedCheckboxDataTableByRowIndex(
  row,
  $dt,
  tdClassName = 'col-label-checkbox',
  checked
) {
  const $ = window.jQuery;

  const trMain = $dt.row(row).node();
  const $trFixed = $(trMain)
    .closest('.dataTables_wrapper')
    .find('.DTFC_LeftBodyLiner table')
    .find(`tr[data-dt-row=${row}]`);
  const $tdFixedColumnCheckbox = $trFixed.find(`td.${tdClassName}`);
  const $checkbox = $(trMain).find(`td.${tdClassName}`).find('input');
  if (!$checkbox.length) return;
  checked = checked === undefined ? !$checkbox[0].checked : checked;

  if (checked) {
    trMain.classList.add('checked-row');
    if ($trFixed[0]) $trFixed[0].classList.add('checked-row');
  } else {
    trMain.classList.remove('checked-row');
    if ($trFixed[0]) $trFixed[0].classList.remove('checked-row');
  }
  if ($tdFixedColumnCheckbox.length)
    $tdFixedColumnCheckbox.find('input:checkbox')[0].checked = checked;
  $checkbox[0].checked = checked;

  return {
    checked,
    row,
  };
}

export function isLeapYear(year) {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
}

export function toCheckBox(d, t) {
  if (t === 'export') {
    if (d) return 'Y';
    return 'N';
  }
  if (d)
    return '<input type="checkbox" class="label-checkbox" checked disabled /><label class="label_checkbox"></label>';
  return '<input type="checkbox" class="label-checkbox" disabled /><label class="label_checkbox"></label>';
}

export function getPermission(moduleName, pageName) {
  // return false
  const jwt = JSON.parse(localStorage.getItem('jwt'));
  if (!jwt) return false;

  const adUser = JSON.parse(jwt.ad_user);
  const pagesPermissions = adUser.PagesPermissions || [];

  const result = pagesPermissions.filter(
    (f) => f.Product === moduleName && f.SubProduct === pageName
  );
  if (!result.length) return false;

  // let findPermissionAll = result.find(f => f.Permission === 'All')
  // if (findPermissionAll) {
  //     return true
  // }
  // return false

  const permission = result[0].Permission;
  if (permission === 'All') return true;
  return false;
}

export function getNewPermission(moduleName, pageName) {
  const jwt = JSON.parse(localStorage.getItem('jwt'));
  if (!jwt)
    return {
      permission: false,
      compFilter: [],
      compAll: [],
      compView: [],
    };

  const adUser = JSON.parse(jwt.ad_user);
  const pagesPermissions = adUser.PagesPermissionsNew || [];

  const result = pagesPermissions.filter(
    (f) => f.Product === moduleName && f.SubProduct === pageName
  );

  if (moduleName === 'T/T Remittance' && pageName === 'Request' && !result.length)
    return {
      permission: true,
      compFilter: [],
      compAll: [],
      compView: [],
    };
  if (!result.length)
    return {
      permission: false,
      compFilter: [],
      compAll: [],
      compView: [],
    };

  let permission = false;
  let compAll = [];
  const filterPermissionAll = result.filter((f) => f.Permission === 'All');
  if (filterPermissionAll.length) {
    permission = true;
    compAll = filterPermissionAll.map((m) => m.CompanyCode);
  }

  let compView = [];
  const filterPermissionView = result.filter((f) => f.Permission === 'View');
  if (filterPermissionView.length) {
    compView = filterPermissionView.map((m) => m.CompanyCode);
  }

  const compFilter = result.map((m) => m.CompanyCode);
  if (moduleName === 'T/T Remittance' && pageName === 'Request') {
    if (!compAll.length && !compView.length) {
      permission = true;
    }
  }

  const data = {
    permission,
    compFilter,
    compAll,
    compView,
  };
  return data;
}

export function getUsername() {
  // return false
  const jwt = JSON.parse(localStorage.getItem('jwt'));
  if (!jwt) return null;

  return jwt.username;
}

export function getPermissionStr(moduleName, pageName) {
  const jwt = JSON.parse(localStorage.getItem('jwt'));
  if (!jwt) return false;

  const adUser = JSON.parse(jwt.ad_user);
  const pagesPermissions = adUser.PagesPermissions || [];

  const result = pagesPermissions.filter(
    (f) => f.Product === moduleName && f.SubProduct === pageName
  );
  if (!result.length) return 'View';

  // let permission = result[0].Permission || ''
  // return permission
  const filterPermissionAll = result.filter((f) => f.Permission === 'All');
  if (filterPermissionAll.length) {
    return filterPermissionAll[0].Permission;
  }

  const filterPermissionCreate = result.filter((f) => f.Permission === 'Create');
  if (filterPermissionCreate.length) {
    return filterPermissionCreate[0].Permission;
  }

  const filterPermissionViewAll = result.filter((f) => f.Permission === 'View (All)');
  if (filterPermissionViewAll.length) {
    return filterPermissionViewAll[0].Permission;
  }

  const filterPermissionViewInward = result.filter((f) => f.Permission === 'View (Inward)');
  if (filterPermissionViewInward.length) {
    return filterPermissionViewInward[0].Permission;
  }

  const filterPermissionViewOutward = result.filter((f) => f.Permission === 'View (Outward)');
  if (filterPermissionViewOutward.length) {
    return filterPermissionViewOutward[0].Permission;
  }

  const filterPermissionViewInflow = result.filter((f) => f.Permission === 'View (Inflow)');
  if (filterPermissionViewInflow.length) {
    return filterPermissionViewInflow[0].Permission;
  }

  const filterPermissionViewOutflow = result.filter((f) => f.Permission === 'View (Outflow)');
  if (filterPermissionViewOutflow.length) {
    return filterPermissionViewOutflow[0].Permission;
  }

  return 'View';
}

export function clearCheckBoxAllTable(
  DATATABLE_ID,
  thClassName = 'label.label-checkbox.fix.choose-all'
) {
  const $ = window.jQuery;
  $(`#${DATATABLE_ID}_wrapper`)
    .find(`div.DTFC_LeftHeadWrapper thead th ${thClassName}`)
    .removeClass('checked');
}

export function toStatusApprove(name, status, tt) {
  const s = toLower(status);

  if (!name) return '';

  if (s === 'approved') return '<span class="label label-success">Approved</span>';
  if (s === 'disapproved' && tt) return '<span class="label label-warning">Disapproved</span>';
  if (s === 'disapproved') return '<span class="label label-warning">Rejected</span>';
  return '<span class="label label-info">Waiting</span>';
}
export function removeFeild(obj, feild = []) {
  const newObj = {};

  for (const key in obj) {
    if (feild.includes(key)) continue;

    newObj[key] = obj[key];
  }
  return newObj;
}

export function isEmptyArray(arr = []) {
  if (!arr) return true;
  if (!arr.length) return true;
  if (arr[0] === '') return true;

  return false;
}

export function validateCashflow(cashflows = []) {
  console.log(cashflows);
  const interestCashflows = cashflows.filter((f) => f.flowType.toLowerCase().includes('interest'));

  let error = false;
  interestCashflows.forEach((m, i, s) => {
    if (error) return;
    const samePaymentDate = s.filter(
      (f) =>
        f !== m &&
        f.paymentDateStr === m.paymentDateStr &&
        f.cashFlowType === m.cashFlowType &&
        f.isManualCreate === m.isManualCreate
    );
    if (samePaymentDate.length) {
      const notSameAccountAndPaymentMethod = samePaymentDate.filter(
        (f) => f.paymentMethod !== m.paymentMethod || f.bankAccountNo !== m.bankAccountNo
      );
      if (notSameAccountAndPaymentMethod.length) {
        error = `At cashflow that payment date is ${m.paymentDateStr},The payment method and bankaccount must be the same.`;
      }
    }
  });
  return error;
}
