import { connect } from 'react-redux';
import React, { Component } from 'react';

import Rollover from './Rollover';
import { addAlert } from '../../reducers/layout/action';
import { getRollOver, saveRollOver } from '../../reducers/manageFx/action';
import { toLower, findIndex, compareDate, checkWeekend } from '../../common/helpper';

class RolloverContainer extends Component {
  state = {
    criteria: {
      RolloverDateStr: '',
      SpotRate: '0',
      RolloverPaymentDateStr: '',
      NewContractDateStr: '',
      NewStartDateStr: '',
      NewEndDateStr: '',
      NewSpotRate: '0',
      NewSwapRate: '0',
      NewForwardRate: '0',
    },
    results: [],
  };

  onChangeHandler = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const criteria = {
        ...state.criteria,
        [name]: value,
      };
      if (name === 'NewSwapRate' || name === 'NewSpotRate')
        criteria.NewForwardRate =
          parseFloat(criteria.NewSpotRate) + parseFloat(criteria.NewSwapRate);
      else if (name === 'NewContractDateStr') {
        if (compareDate(criteria.NewContractDateStr, criteria.NewStartDateStr)) {
          this.props.addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'Please select Contract Date must be more than or equal Start Date.',
          });
          return;
        }
        if (compareDate(criteria.NewContractDateStr, criteria.NewEndDateStr)) {
          this.props.addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'Please select Contract Date must be more than or equal End Date.',
          });
          return;
        }
      } else if (name === 'NewStartDateStr') {
        if (compareDate(criteria.NewContractDateStr, criteria.NewStartDateStr)) {
          this.props.addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'Please select Start Date must be less than or equal Contract Date.',
          });
          return;
        }
        if (compareDate(criteria.NewStartDateStr, criteria.NewEndDateStr)) {
          this.props.addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'Please select Start Date must be more than or equal End Date.',
          });
          return;
        }
      } else if (name === 'NewEndDateStr') {
        if (compareDate(criteria.NewStartDateStr, criteria.NewEndDateStr)) {
          this.props.addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'Please select End Date must be more than or equal Start Date.',
          });
          return;
        }
        if (compareDate(criteria.NewContractDateStr, criteria.NewEndDateStr)) {
          this.props.addAlert({
            title: 'Warning',
            type: 'warning',
            body: 'Please select End Date must be more than or equal Contract Date.',
          });
          return;
        }
      }
      return {
        criteria,
      };
    });
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.t;
    if (!transactionNo) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please select transaction no for RollOVer',
        type: 'error',
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.routeProp.match.params.t !== this.props.routeProp.match.params.t) {
      this.setState({
        results: [],
      });
    }
  }

  onClickProcessHandler = (e) => {
    e.preventDefault();

    const { t } = this.props.routeProp.match.params;
    if (!t) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'There no transaction no. for Roll Over!',
      });
      return;
    }

    const criteria = { ...this.state.criteria };
    if (checkWeekend(criteria.RolloverDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Roll Over Date can not be Weekend.',
      });
      return;
    }

    if (checkWeekend(criteria.RolloverPaymentDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Roll Over Payment Date can not be Weekend.',
      });
      return;
    }

    if (checkWeekend(criteria.NewContractDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Contract Date can not be Weekend.',
      });
      return;
    }

    if (checkWeekend(criteria.NewStartDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Start Date can not be Weekend.',
      });
      return;
    }

    if (checkWeekend(criteria.NewEndDateStr)) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'End Date can not be Weekend.',
      });
      return;
    }

    if (!criteria.SpotRate || parseFloat(criteria.SpotRate || 0) === 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'SPOT Rate (Market Rate) must be no zero.',
      });
      return;
    }

    if (!criteria.NewSpotRate || parseFloat(criteria.NewSpotRate || 0) === 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'New SPOT Rate must be no zero.',
      });
      return;
    }

    if (!criteria.NewSwapRate) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: "New SWAP Rate can't be empty.",
      });
      return;
    }

    if (!criteria.NewForwardRate || parseFloat(criteria.NewForwardRate || 0) === 0) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'New Forward Rate must be no zero.',
      });
      return;
    }

    this.props
      .getRollOver({
        ForwardNoList: t.split(','),
        ...criteria,
      })
      .then((response) => {
        if (!response.error)
          this.setState({
            results: response.payload,
          });
      });
  };

  onChangeInputTable = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      results: state.results.map((m, i) => {
        if (i === index) {
          const obj = {
            ...m,
          };
          if (name === 'rollOverAmount') {
            if (value <= obj.outstanding) obj[name] = value;
            obj.gainLossAmount = (obj.settlementRate - obj.spotRate) * obj.rollOverAmount;
            if (toLower(obj.transactionType) === 'buy') obj.gainLossAmount *= -1;
          } else if (name === 'settlementRate') {
            obj[name] = value;
            obj.gainLossAmount = (obj.settlementRate - obj.spotRate) * obj.rollOverAmount;
            if (toLower(obj.transactionType) === 'buy') obj.gainLossAmount *= -1;
          } else obj[name] = value;

          return obj;
        }
        return m;
      }),
    }));
  };

  onClickSaveHandler = (e) => {
    e.preventDefault();
    const findDupicate = this.state.results.filter((f, i, s) => {
      if (!f.newContractNo) return false;
      return findIndex(s, (m, index) => m.newContractNo === f.newContractNo) !== i;
    });
    if (findDupicate.length) {
      this.props.addAlert({
        title: 'Warning',
        type: 'warning',
        body: `Contract No: ${findDupicate[0].newContractNo} Dupicate in forward No : ${findDupicate[0].forwardNo}`,
      });
      return;
    }
    this.props.saveRollOver([...this.state.results]).then((response) => {
      if (!response.error) {
        this.setState({
          results: [],
        });
        let body = 'The transaction was successfully saved';
        if (response.payload) {
          body = (
            <>
              <span>Rollover Success</span>
              {response.payload.split(',').map((m, i) => {
                const s = m.split(':');
                return (
                  <p key={i}>
                    Old {s[0]} : New {s[1] || ''}
                  </p>
                );
              })}
            </>
          );
        }
        this.props.addAlert({
          title: 'Success',
          type: 'success',
          body,
          buttons: [
            <button
              className="btn btn-success"
              onClick={this.onClickBackToFXManagementPage}
              data-dismiss="modal"
            >
              Continue
            </button>,
          ],
        });
      }
    });
  };

  onClickBackToFXManagementPage = () => {
    this.props.routeProp.history.push('/fx-management/manage-fx-contract?s');
  };

  render() {
    const props = {
      onChangeProcess: this.onChangeHandler,
      onClickProcess: this.onClickProcessHandler,

      onChangeInputTable: this.onChangeInputTable,
      onClickSave: this.onClickSaveHandler,
    };

    return <Rollover {...props} {...this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.manageFx,
  }),
  {
    getRollOver,
    addAlert,
    saveRollOver,
  }
)(RolloverContainer);
