import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import InputMask from '../../common/InputMask';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { DATATABLE_ID } from './FlowAccountingContainer';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { toNumber, toStatus, getPermission } from '../../common/helpper';
import {
  MASTER_COA,
  MASTER_COUNTRY,
  MASTER_COMPANY,
  MASTER_GL_ACCOUNT,
  MASTER_FLOW_CONFIG,
} from '../../reducers/master/action';

const permission = getPermission('Master Accounting', 'Withholding Tax');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12', 'col-sm-4'];
const opt = {
  deferRender: false,
  scroller: true,
  paging: true,
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'validFromStr', className: 'text-center', type: 'date-black' },
    { data: 'coa' },
    { data: 'companyCountry', className: 'text-center' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'partnerType' },
    { data: 'partnerCode', className: 'text-center' },
    { data: 'partnerCountry', className: 'text-center' },
    { data: 'productType' },
    { data: 'gvc' },
    { data: 'flowNo' },
    { data: 'flowName' },
    { data: 'whtType' },
    { data: 'whtTypeDescription' },
    { data: 'whtCode' },
    { data: 'whtCodeDescription' },
    { data: 'whtValue', className: 'text-right', render: (d) => toNumber(d, 2) },
    { data: 'glAccount' },
    { data: 'assignment' },
    { data: 'remark' },
    { data: 'text' },
    // { data: 'isAbsorb' },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Mode === 'edit';
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Accounting <span className="icon icon-angle-double-right" /> Withholding Tax
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Chart of Account" required={false}>
                <MasterDropdownUI
                  value={criteria.Coa}
                  onChange={onChangeSelect2}
                  options={['SCG 4.7', 'SCG ECC6.0', 'SCG ECC6.0 VAS']}
                  name="Coa"
                />
              </FormGroup2>

              <FormGroup2 text="Company Country" required={false}>
                <MasterDropdown
                  masterType={MASTER_COUNTRY}
                  onChange={onChangeSelect2}
                  value={criteria.CompanyCountry}
                  name="CompanyCountry"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Partner Type" required={false}>
                <MasterDropdownUI
                  value={criteria.PartnerType}
                  onChange={onChangeSelect2}
                  options={['External', 'Intercompany']}
                  name="PartnerType"
                />
              </FormGroup2>

              <FormGroup2 text="Partner Country" required={false}>
                <MasterDropdown
                  masterType={MASTER_COUNTRY}
                  onChange={onChangeSelect2}
                  value={criteria.PartnerCountry}
                  name="PartnerCountry"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="Create Withholding Tax"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalData}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Valid From</th>
              <th>
                Chart of
                <br /> Account
              </th>
              <th>
                Company
                <br /> Country
              </th>
              <th>
                Company
                <br /> Code
              </th>
              <th>Partner Type</th>
              <th>
                Partner
                <br /> Code
              </th>
              <th>
                Partner
                <br /> Country
              </th>
              <th>Product Type</th>
              <th>GVC</th>
              <th>Flow No</th>
              <th>Flow Name</th>
              <th>Wht Type</th>
              <th>Wht Type Description</th>
              <th>Wht Code</th>
              <th>Wht Code Description</th>
              <th className="text-center">Wht Value</th>
              <th>GL Account</th>
              <th>Assignment</th>
              <th>Remark</th>
              <th>Text</th>
              {/* <th>Absorb</th> */}
              <th>Status</th>
              <th>
                Created
                <br />
                By
              </th>
              <th>
                Created
                <br />
                Date
              </th>
              <th>
                Updated
                <br />
                By
              </th>
              <th>
                Updated
                <br />
                Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal
        textHeader="Master Withholding Tax"
        bgHeader="bg-primary"
        size="modal-xl"
        ref={modalDataRef}
      >
        <form onSubmit={onClickSaveModalData}>
          <Col2 col={colX[2]}>
            <Col2 col={colX[3]}>
              <FormGroup2 text="Valid From" required>
                <DatePicker
                  onChange={onChangeModalData}
                  name="NewValidFromStr"
                  value={modalData.NewValidFromStr}
                  option={{
                    // startDate: "d",
                    // daysOfWeekDisabled: "0,6",
                    todayHighlight: true,
                  }}
                  required
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <Col2 col={colX[3]}>
              <FormGroup2 text="COA" required>
                <MasterDropdown
                  masterType={MASTER_COA}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  // disabled={noEdit}
                  value={modalData.Coa}
                  name="Coa"
                />
              </FormGroup2>

              <FormGroup2 text="Company" required>
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  isAll
                  notMultipleSelect2
                  required
                  // disabled={noEdit}
                  value={modalData.CompanyCode}
                  name="CompanyCode"
                />
              </FormGroup2>

              <FormGroup2 text="Company Country" required>
                {modalData.CompanyCode === 'All' ? (
                  <MasterDropdown
                    key="compcountry1"
                    masterType={MASTER_COUNTRY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    value={modalData.CompanyCountry}
                    name="CompanyCountry"
                  />
                ) : (
                  <MasterDropdown
                    key="compcountry2"
                    masterType={MASTER_COUNTRY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    disabled
                    value={modalData.CompanyCountry}
                    name="CompanyCountry"
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <Col2 col={colX[3]}>
              <FormGroup2 text="Partner Type" required>
                <MasterDropdownUI
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  // disabled={noEdit}
                  value={modalData.PartnerType}
                  name="PartnerType"
                  options={['Intercompany', 'External']}
                />
              </FormGroup2>

              <FormGroup2 text="Partner" required>
                {modalData.PartnerType === 'External' ? (
                  <input
                    className="form-control"
                    onChange={onChangeModalData}
                    value={modalData.PartnerCode === 'All' ? '---All---' : ''}
                    name="PartnerCode"
                    required={false}
                    disabled
                  />
                ) : (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isAll
                    notMultipleSelect2
                    required
                    // disabled={noEdit}
                    value={modalData.PartnerCode}
                    name="PartnerCode"
                  />
                )}
              </FormGroup2>

              <FormGroup2 text="Partner Country" required>
                {modalData.PartnerCode === 'All' ? (
                  <MasterDropdown
                    key="partnercountry1"
                    masterType={MASTER_COUNTRY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    value={modalData.PartnerCountry}
                    name="PartnerCountry"
                  />
                ) : (
                  <MasterDropdown
                    key="partnercountry2"
                    masterType={MASTER_COUNTRY}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required
                    disabled
                    value={modalData.PartnerCountry}
                    name="PartnerCountry"
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <Col2 col={colX[3]}>
              <FormGroup2 text="Product Type" required>
                <MasterDropdownUI
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  // disabled={noEdit}
                  value={modalData.ProductType}
                  name="ProductType"
                  options={['All', 'Deposit', 'PN', 'Contract Loan']}
                />
              </FormGroup2>

              <FormGroup2 text="GVC" required>
                <MasterDropdownUI
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  // disabled={noEdit}
                  value={modalData.Gvc}
                  name="Gvc"
                  options={['All', 'On Call', 'Term']}
                />
              </FormGroup2>

              <FormGroup2 text="Flow No." required>
                <MasterDropdown
                  masterType={MASTER_FLOW_CONFIG}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeModalData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  required
                  isActive
                  value={modalData.FlowNo}
                  name="FlowNo"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <Col2 col={colX[3]}>
              <FormGroup2 text="WHT Type" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeModalData}
                  value={modalData.WhtType || ''}
                  name="WhtType"
                  required={false}
                />
              </FormGroup2>

              <FormGroup2 text="WHT Type Description" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeModalData}
                  value={modalData.WhtTypeDescription || ''}
                  name="WhtTypeDescription"
                  required={false}
                />
              </FormGroup2>

              <FormGroup2 text="WHT Value" required={false}>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeModalData}
                  option={{
                    prefix: '',
                    digits: 2,
                    // digitsOptional: false,
                    // placeholder: '0.00'
                  }}
                  required={false}
                  value={modalData.WhtValue}
                  name="WhtValue"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <Col2 col={colX[3]}>
              <FormGroup2 text="WHT Code" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeModalData}
                  value={modalData.WhtCode || ''}
                  name="WhtCode"
                  required={false}
                />
              </FormGroup2>

              <FormGroup2 text="WHT Code Description" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeModalData}
                  value={modalData.WhtCodeDescription || ''}
                  name="WhtCodeDescription"
                  required={false}
                />
              </FormGroup2>

              <FormGroup2 text="GL Account" required={false}>
                {modalData.Coa ? (
                  <MasterDropdown
                    masterType={MASTER_GL_ACCOUNT}
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={false}
                    isActive
                    coa={modalData.Coa}
                    value={modalData.GlAccount}
                    name="GlAccount"
                  />
                ) : (
                  <MasterDropdownUI
                    noValidateOption
                    saveLocalState
                    onChange={(e) =>
                      onChangeModalData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    required={false}
                    // disabled={noEdit}
                    value={modalData.GlAccount}
                    name="GlAccount"
                    options={[]}
                  />
                )}
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <Col2 col={colX[3]}>
              <FormGroup2 text="Assignment" required={false}>
                <input
                  className="form-control"
                  onChange={onChangeModalData}
                  value={modalData.Assignment || ''}
                  name="Assignment"
                  required={false}
                />
              </FormGroup2>

              <FormGroup2 text="Remark" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeModalData}
                  value={modalData.Remark || ''}
                  name="Remark"
                  required={false}
                />
              </FormGroup2>

              <FormGroup2 text="Text" required={false}>
                <textarea
                  className="form-control"
                  rows="2"
                  onChange={onChangeModalData}
                  value={modalData.Text || ''}
                  name="Text"
                  required={false}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <br />
          <div>
            <center>
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
