import { connect } from 'react-redux';
import React, { Component } from 'react';

import ViewDetailTT from './ViewDetailTT';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import { getTtRemittance, saveTtRemittanceManual } from '../../reducers/ttRemittance/action';

const init = {
  VPayId: '',
  VPayNo: '',

  CompanyCode: '',
  PaymentBy: '',
  PoNo: '',
  InvoiceNo: '',
  InvoiceAmount: '',
  Currency: '',
  ValueDateStr: '',
  Equivalent: '',
  RemittedToCurrency: '',
  PreAdvice: '',
  PaidFor: '',
  PurposeDetail: '',
  Q2oNo: '',
  ForwardContractNo: '',
  BankChargeOutside: '',
  BankChargeInside: '',
  SpecialMessage: '',

  CreatorName: '',
  CreatorDepartment: '',
  CreatorTel: '',
  CreatorEmail: '',

  RequestorName: '',
  RequestorDepartment: '',
  RequestorTel: '',
  RequestorEmail: '',

  VenderName: '',
  VendorCode: '',
  BeneficiaryCode: '',
  BeneficiaryName: '',
  BeneficiaryAddress: '',
  BeneficiaryCountry: '',
  AccountNo: '',
  IbanNo: '',
  AbaNo: '',
  BsbNo: '',
  IfscCode: '',
  BeneficiaryBankName: '',
  BeneficiaryBankAddress: '',
  BeneficiaryBankSwiftCode: '',
  BeneficiaryBankCountry: '',
  IntermediateBankName: '',
  IntermediateBankAddress: '',
  IntermediateBankSwiftCode: '',
  IntermediateBankCountry: '',
  RequestorRemarkForBank: '',
  IntenalMessage: '',
  AttachFile: '',

  BasedAmount: 0,
  WhtAmount: 0,
  VatAmount: 0,
  NetPaidAmount: 0,
  Remark: '',

  InitialName: '',
  ApproverName: '',
  ForwardContractNoVpay: '',

  Methods: [],
  mode: 'create',
};

class ViewDetailTTContainer extends Component {
  state = {
    ...init,
  };

  componentDidMount() {
    const transactionNo = this.props.routeProp.match.params.id;
    if (!transactionNo || transactionNo === 'create') return;
    this.props.getTtRemittance(transactionNo).then((response) => {
      if (!response.error) {
        this.setState({
          ...toUpperKey(response.payload),
          mode: 'edit',
        });
      }
    });
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;
    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };
      return stateToUpdate;
    });
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();
    return formName;
  }

  onSubmitHander = (e) => {
    const inValidFormName = this.validateForms([
      this.formGeneralRef,
      this.formCreatorRef,
      this.formRequestorRef,
      this.formBeneficiaryRef,
      this.formAccountingRef,
      this.formApproveRef,
    ]);
    if (inValidFormName) return;

    this.props.saveTtRemittanceManual(this.state).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (response.payload !== this.props.routeProp.match.params.id) {
        window.location.href = `/tt-remittance/view-detail-tt/${response.payload}`;
      } else {
        this.fetchId(response.payload);
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/tt-remittance/management');
  };

  onClickCopyCreator = (e) => {
    this.setState({
      ...this.state,
      RequestorName: this.state.CreatorName,
      RequestorDepartment: this.state.CreatorDepartment,
      RequestorTel: this.state.CreatorTel,
      RequestorEmail: this.state.CreatorEmail,
    });
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,
      onClickCopyCreator: this.onClickCopyCreator,

      tabRef: (e) => (this.tabRef = e),
      formGeneralRef: (e) => (this.formGeneralRef = e),
      formCreatorRef: (e) => (this.formCreatorRef = e),
      formRequestorRef: (e) => (this.formRequestorRef = e),
      formBeneficiaryRef: (e) => (this.formBeneficiaryRef = e),
      formAccountingRef: (e) => (this.formAccountingRef = e),
      formApproveRef: (e) => (this.formApproveRef = e),
    };

    return <ViewDetailTT {...props} state={this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.ttRemittance,
  }),
  {
    getTtRemittance,
    saveTtRemittanceManual,
    addAlert,
  }
)(ViewDetailTTContainer);
