import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_FACILITY_SET_UP_CRITERIA = 'EDIT_FACILITY_SET_UP_CRITERIA';
export const CLEAR_FACILITY_SET_UP_CRITERIA = 'CLEAR_FACILITY_SET_UP_CRITERIA';

export const UPDATE_SEARCH_RESULT_FACILITY_SET_UP = 'UPDATE_SEARCH_RESULT_FACILITY_SET_UP';

export const REQUEST_SEARCH_FACILITY_SET_UP = '@@request/SARCH_FACILITY_SET_UP';
export const RECEIVE_SEARCH_FACILITY_SET_UP = '@@receive/SEARCH_FACILITY_SET_UP';
export const FAIL_SEARCH_FACILITY_SET_UP = '@@fail/SEARCH_FACILITY_SET_UP';

export const REQUEST_SAVE_FACILITY_SET_UP_DETAIL = '@@request/SAVE_FACILITY_SET_UP_DETAIL';
export const RECEIVE_SAVE_FACILITY_SET_UP_DETAIL =
  '@@receive-The transaction was successfully saved/SAVE_FACILITY_SET_UP_DETAIL';
export const FAIL_SAVE_FACILITY_SET_UP_DETAIL = '@@fail/SAVE_FACILITY_SET_UP_DETAIL';

export const REQUEST_GET_FACILITY_SET_UP_DETAIL = '@@request/GET_FACILITY_SET_UP_DETAIL';
export const RECEIVE_GET_FACILITY_SET_UP_DETAIL = '@@receive/GET_FACILITY_SET_UP_DETAIL';
export const FAIL_GET_FACILITY_SET_UP_DETAIL = '@@fail/GET_FACILITY_SET_UP_DETAIL';

export const REQUEST_SAVE_FACILITY_ASSIGN = '@@request/SAVE_FACILITY_ASSIGN';
export const RECEIVE_SAVE_FACILITY_ASSIGN =
  '@@receive-The transaction was successfully saved/SAVE_FACILITY_ASSIGN';
export const FAIL_SAVE_FACILITY_ASSIGN = '@@fail/SAVE_FACILITY_ASSIGN';

export const REQUEST_SAVE_FACILITY_SET_UP_DETAIL_ALL = '@@request/SAVE_FACILITY_SET_UP_DETAIL_ALL';
export const RECEIVE_SAVE_FACILITY_SET_UP_DETAIL_ALL = '@@receive/SAVE_FACILITY_SET_UP_DETAIL_ALL';
export const FAIL_SAVE_FACILITY_SET_UP_DETAIL_ALL = '@@fail/SAVE_FACILITY_SET_UP_DETAIL_ALL';

export const REQUEST_SAVE_FACILITY_CASH_FLOW = '@@request/SAVE_FACILITY_CASH_FLOW';
export const RECEIVE_SAVE_FACILITY_CASH_FLOW =
  '@@receive-The transaction was successfully saved/SAVE_FACILITY_CASH_FLOW';
export const FAIL_SAVE_FACILITY_CASH_FLOW = '@@fail/SAVE_FACILITY_CASH_FLOW';

export const REQUEST_SAVE_FACILITY_CREDIT_LINE = '@@request/SAVE_FACILITY_CREDIT_LINE';
export const RECEIVE_SAVE_FACILITY_CREDIT_LINE = '@@receive/SAVE_FACILITY_CREDIT_LINE';
export const FAIL_SAVE_FACILITY_CREDIT_LINE = '@@fail/SAVE_FACILITY_CREDIT_LINE';

export const saveCreditLine = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savefacilitycreditline', data),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_FACILITY_CREDIT_LINE,
      RECEIVE_SAVE_FACILITY_CREDIT_LINE,
      FAIL_SAVE_FACILITY_CREDIT_LINE,
    ],
  },
});

export const saveCashFlow = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savecashflow'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_FACILITY_CASH_FLOW,
      RECEIVE_SAVE_FACILITY_CASH_FLOW,
      FAIL_SAVE_FACILITY_CASH_FLOW,
    ],
  },
});

export const saveFacilitySetUpDetailAll = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/updatefacility'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_FACILITY_SET_UP_DETAIL_ALL,
      RECEIVE_SAVE_FACILITY_SET_UP_DETAIL_ALL,
      FAIL_SAVE_FACILITY_SET_UP_DETAIL_ALL,
    ],
  },
});

export const saveFacilityAssign = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savefacilityassign'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_FACILITY_ASSIGN, RECEIVE_SAVE_FACILITY_ASSIGN, FAIL_SAVE_FACILITY_ASSIGN],
  },
});

export const getFacilitySetUpDetail = (transactionNo) => ({
  [RSAA]: {
    endpoint: endpoint(`loan/getfacility/${transactionNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_FACILITY_SET_UP_DETAIL,
      RECEIVE_GET_FACILITY_SET_UP_DETAIL,
      FAIL_GET_FACILITY_SET_UP_DETAIL,
    ],
  },
});

export const saveFacilitySetUpDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/savefacility'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_FACILITY_SET_UP_DETAIL,
      RECEIVE_SAVE_FACILITY_SET_UP_DETAIL,
      FAIL_SAVE_FACILITY_SET_UP_DETAIL,
    ],
  },
});

export const searchFacilitySetUp = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/loan/searchfacility', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_FACILITY_SET_UP,
      RECEIVE_SEARCH_FACILITY_SET_UP,
      FAIL_SEARCH_FACILITY_SET_UP,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_FACILITY_SET_UP,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_FACILITY_SET_UP_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_FACILITY_SET_UP_CRITERIA,
  payload: {
    name,
    value,
  },
});
