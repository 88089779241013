import React from 'react';

import Charge from '../TabCharge';
import Interest from '../TabInterest';
import CashFlow from '../TabCashFlow';
import Tab from '../../../common/Tab';
import Stucture from '../TabStructure';
import ModalSplit from '../ModalSplit';
import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Link from '../../../common/Link';
import ModalCharge from '../ModalCharge';
import Button from '../../../common/Button';
import CashFlowSweep from './TabCashFlowSweep';
import Administrator from '../TabAdministrator';
import { toLower } from '../../../common/helpper';
import FormGroup2 from '../../../common/FormGroup2';
import SpanStatus from '../../../common/SpanStatus';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { MASTER_FINANCE, MASTER_COMPANY, MASTER_HOLIDAY } from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({
  state,
  onChangeInputData,
  onChangeSelect2,
  onSubmit,
  template = [],
  facilityList = [],

  onChangeInputTable,
  onClickGetCashflow,
  onChangeInputHeader,

  onChangeInputTableCharge,
  onChangeInputHeaderCharge,

  modalCharge,
  modalChargeRef,
  onClickOpenModalCharge,
  onChangeModalCharge,
  onSubmitModalCharge,

  modalDeleteRef,
  onClickConfirmModalDeleteCashFlow,

  modalSplitRef,
  onChangeModalSplit,
  onSubmitModalSplit,

  modalUndoSplitRef,
  onSubmitModalUndoSplit,

  formDetailRef,
  formStructorRef,
  formInterestRef,
  formAdministratorRef,
  formCashFlowLoanRef,
  formCashFlowDepositRef,
  formResultCashFlowLoanRef,
  formResultCashFlowDepositRef,

  formCashFlowLoanChargeRef,
  formCashFlowDepositChargeRef,
  formResultCashFlowLoanChargeRef,
  formResultCashFlowDepositChargeRef,

  tabRef,
}) => {
  const header =
    state.mode === 'edit' ? `Loan No.: ${state.TransactionNo}` : 'Create Promissory Note';
  const noEdit = state.mode === 'edit';
  const viewOnly =
    state.mode === 'create' ? false : !!(!state.permission || toLower(state.Status) !== 'active');
  const required = true;

  const tabContents = [];
  // ------------------ Tab Structure ----------------------
  tabContents.push({
    header: <span>Structure</span>,
    content: (
      <Stucture
        formRef={formStructorRef}
        state={state}
        onChangeInputData={onChangeInputData}
        viewOnly={viewOnly}
      />
    ),
    tabName: 'structure',
  });

  // ------------------ Tab Interest ----------------------
  tabContents.push({
    header: <span>Interest</span>,
    content: (
      <Interest
        formRef={formInterestRef}
        template={template}
        state={state}
        onChangeInputData={onChangeInputData}
        viewOnly={viewOnly}
      />
    ),
    tabName: 'interest',
  });

  // ------------------ Tab Administrator ----------------------
  tabContents.push({
    header: <span>Administrator</span>,
    content: (
      <Administrator
        formRef={formAdministratorRef}
        state={state}
        facilityList={facilityList}
        onChangeInputData={onChangeInputData}
        viewOnly={viewOnly}
      />
    ),
    tabName: 'administrator',
  });

  if (!state.IsSweep) {
    // ------------------ Tab Cash Flow ----------------------
    tabContents.push({
      header: <span>Cash Flow</span>,
      content: (
        <CashFlow
          formCashFlowLoanRef={formCashFlowLoanRef}
          formCashFlowDepositRef={formCashFlowDepositRef}
          formResultCashFlowLoanRef={formResultCashFlowLoanRef}
          formResultCashFlowDepositRef={formResultCashFlowDepositRef}
          state={state}
          onChangeInputData={onChangeInputData}
          onChangeInputTable={onChangeInputTable}
          onClickGetCashflow={onClickGetCashflow}
          onChangeInputHeader={onChangeInputHeader}
          viewOnly={viewOnly}
          onChangeSelect2={onChangeSelect2}
        />
      ),
      tabName: 'cashflow',
    });

    // ------------------ Tab Charge ----------------------
    tabContents.push({
      header: <span>Charge</span>,
      content: (
        <Charge
          formCashFlowLoanRef={formCashFlowLoanChargeRef}
          formCashFlowDepositRef={formCashFlowDepositChargeRef}
          formResultCashFlowLoanRef={formResultCashFlowLoanChargeRef}
          formResultCashFlowDepositRef={formResultCashFlowDepositChargeRef}
          state={state}
          onChangeInputData={onChangeInputData}
          onChangeInputTable={onChangeInputTableCharge}
          onChangeInputHeader={onChangeInputHeaderCharge}
          onClickOpenModalCharge={onClickOpenModalCharge}
          viewOnly={viewOnly}
        />
      ),
      tabName: 'charge',
    });
  }

  if (state.IsSweep) {
    // ------------------ Tab Cash Flow Sweep ----------------------
    tabContents.push({
      header: <span>Cash Flow</span>,
      content: <CashFlowSweep state={state} onChangeInputData={onChangeInputData} />,
      tabName: 'cash flow sweep',
    });
  }

  // save btn
  let canSave =
    state.mode === 'create' || ['rollover', 'repay'].includes((state.Status || '').toLowerCase())
      ? false
      : viewOnly;
  canSave = canSave || state.needReProcess;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Funding & Investment <span className="icon icon-angle-double-right" />
            <Link txt="Create Promissory Note" href="/loan-investment/create-pn-loan" />
            <span className="icon icon-angle-double-right" /> PN Detail
            {noEdit ? (
              <>
                {' '}
                <span className="icon icon-angle-double-right" /> {state.TransactionNo}{' '}
                <span className="icon icon-angle-double-right" />{' '}
                <SpanStatus status={state.Status} />
              </>
            ) : null}
          </small>
        </p>
      </div>

      {!state.permission && state.mode === 'create' ? (
        <div className="row text-center" style={{ height: '300px', marginTop: '15%' }}>
          <h2>YOUR PERMISSION CAN VIEW TRANSACTION ONLY!</h2>
        </div>
      ) : (
        <>
          <Card textHeader={header} bgHeader="bg-primary" cardActions={['toggler']}>
            <form name="detail" ref={formDetailRef}>
              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Finance Group" required>
                    {noEdit ? (
                      <input
                        type="text"
                        className="form-control"
                        value={state.FinanceGroupName || ''}
                        disabled
                      />
                    ) : (
                      <MasterDropdown
                        masterType={MASTER_FINANCE}
                        noValidateOption={noEdit}
                        saveLocalState
                        onChange={(e) =>
                          onChangeInputData({
                            target: e,
                          })
                        }
                        isChoose
                        notMultipleSelect2
                        required={required}
                        disabled={noEdit}
                        value={state.FinanceGroupId}
                        name="FinanceGroupId"
                      />
                    )}
                  </FormGroup2>

                  <FormGroup2 text="Company" required>
                    {noEdit ? (
                      <input
                        className="form-control"
                        value={`${state.CompanyCode} | ${state.CompanyName}`}
                        disabled
                      />
                    ) : state.FinanceGroupId ? (
                      <MasterDropdown
                        masterType={MASTER_COMPANY}
                        noValidateOption={noEdit}
                        saveLocalState
                        onChange={(e) =>
                          onChangeInputData({
                            target: e,
                          })
                        }
                        isChoose
                        notMultipleSelect2
                        notMultiple={false}
                        required={required}
                        disabled={noEdit}
                        financeGroupId={state.FinanceGroupId}
                        value={state.CompanyCode}
                        name="CompanyCode"
                      />
                    ) : (
                      <MasterDropdownUI
                        onChange={(e) =>
                          onChangeInputData({
                            target: e,
                          })
                        }
                        isChoose
                        notMultipleSelect2
                        value={state.CompanyCode}
                        name="CompanyCode"
                        options={null}
                      />
                    )}
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[0]}>
                  {noEdit ? (
                    <FormGroup2 text="Project" required={false}>
                      <input
                        className="form-control"
                        onChange={onChangeInputData}
                        value={state.IsProject ? 'Yes' : 'No'}
                        name="IsProject"
                        readOnly
                      />
                    </FormGroup2>
                  ) : (
                    <div className="form-group">
                      <div>
                        <input
                          id="IsProject"
                          type="checkbox"
                          className="label-checkbox"
                          onChange={onChangeInputData}
                          name="IsProject"
                          checked={state.IsProject}
                        />
                        <label htmlFor="IsProject" className="label_checkbox" />
                        <label htmlFor="IsProject" className="control-label text_label_checkbox">
                          Project
                        </label>
                      </div>
                    </div>
                  )}
                </Col2>
              </Col2>

              <Col2 col={colX[0]}>
                <Col2 col={colX[1]}>
                  <FormGroup2 text="Calendar" required={false}>
                    <MasterDropdown
                      masterType={MASTER_HOLIDAY}
                      onChange={(e) =>
                        onChangeInputData({
                          target: e,
                        })
                      }
                      noValidateOption
                      saveLocalState
                      status
                      isChoose
                      disabled={viewOnly}
                      notMultipleSelect2
                      value={state.CalendarId}
                      name="CalendarId"
                    />
                  </FormGroup2>

                  <FormGroup2
                    text="Remark"
                    required={false}
                    maxLength={2000}
                    length={(state.Remark || '').length}
                  >
                    <textarea
                      className="form-control"
                      rows="2"
                      onChange={onChangeInputData}
                      required={false}
                      value={state.Remark || ''}
                      readOnly={viewOnly}
                      maxLength={2000}
                      name="Remark"
                    />
                  </FormGroup2>
                </Col2>
              </Col2>
            </form>
          </Card>

          <Tab
            tabs={tabContents}
            ref={tabRef}
            footer={
              !state.permission || canSave ? (
                ''
              ) : (
                <Button
                  txt="SAVE"
                  icon="save"
                  className="btn-success"
                  onClick={onSubmit}
                  disabled={canSave}
                />
              )
            }
          />
        </>
      )}

      <ModalCharge
        text={modalCharge.Source}
        modalRef={modalChargeRef}
        state={modalCharge}
        onSubmit={onSubmitModalCharge}
        onChageModal={onChangeModalCharge}
      />

      <ModalFunction
        textHeader="Delete"
        textContent="a Delete"
        modalRef={modalDeleteRef}
        type="danger"
        icon="icon-trash-o"
        textBtn="Delete"
        noSelect
        onClick={(e) => onClickConfirmModalDeleteCashFlow(e)}
      />

      <ModalSplit
        modalRef={modalSplitRef}
        state={state.modalSplit}
        onSubmit={onSubmitModalSplit}
        onChageModal={onChangeModalSplit}
      />

      <ModalFunction
        textHeader="Undo Split"
        textContent="an Undo Split"
        modalRef={modalUndoSplitRef}
        type="brown"
        icon="icon-undo"
        textBtn="Confirm"
        noSelect
        onClick={(e) => onSubmitModalUndoSplit(e)}
      />
    </div>
  );
};
