import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_AUTOMATE_INTEREST_CRITERIA = 'EDIT_AUTOMATE_INTEREST_CRITERIA';
export const CLEAR_AUTOMATE_INTEREST_CRITERIA = 'CLEAR_AUTOMATE_INTEREST_CRITERIA';
export const UPDATE_SEARCH_RESULT_AUTOMATE_INTEREST = 'UPDATE_SEARCH_RESULT_AUTOMATE_INTEREST';

export const REQUEST_SEARCH_AUTOMATE_INTEREST = '@@request/SARCH_AUTOMATE_INTEREST';
export const RECEIVE_SEARCH_AUTOMATE_INTEREST = '@@receive/SEARCH_AUTOMATE_INTEREST';
export const FAIL_SEARCH_AUTOMATE_INTEREST = '@@fail/SEARCH_AUTOMATE_INTEREST';

export const REQUEST_SAVE_AUTOMATE_INTEREST = '@@request/SAVE_AUTOMATE_INTEREST';
export const RECEIVE_SAVE_AUTOMATE_INTEREST = '@@receive/SAVE_AUTOMATE_INTEREST';
export const FAIL_SAVE_AUTOMATE_INTEREST = '@@fail/SAVE_AUTOMATE_INTEREST';

export const REQUEST_DELETE_AUTOMATE_INTEREST = '@@request/DELETE_AUTOMATE_INTEREST';
export const RECEIVE_DELETE_AUTOMATE_INTEREST =
  '@@receive-The record was successfully saved/DELETE_AUTOMATE_INTEREST';
export const FAIL_DELETE_AUTOMATE_INTEREST = '@@fail/DELETE_AUTOMATE_INTEREST';

export const REQUEST_GET_AUTOMATE_INTEREST = '@@request/SARCH_AUTOMATE_INTEREST';
export const RECEIVE_GET_AUTOMATE_INTEREST = '@@receive/GET_AUTOMATE_INTEREST';
export const FAIL_GET_AUTOMATE_INTEREST = '@@fail/GET_AUTOMATE_INTEREST';

export const REQUEST_GET_CHECK_INTEREST = '@@request/CHECK_INTEREST';
export const RECEIVE_GET_CHECK_INTEREST = '@@receive/CHECK_INTEREST';
export const FAIL_GET_CHECK_INTEREST = '@@fail/CHECK_INTEREST';

export const checkInterest = () => ({
  [RSAA]: {
    endpoint: endpoint('/automate/check-interest'),
    method: 'GET',
    types: [REQUEST_GET_CHECK_INTEREST, RECEIVE_GET_CHECK_INTEREST, FAIL_GET_CHECK_INTEREST],
  },
});

export const getMaintainInterest = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/automate/getmaintaininterest', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_AUTOMATE_INTEREST,
      RECEIVE_GET_AUTOMATE_INTEREST,
      FAIL_GET_AUTOMATE_INTEREST,
    ],
  },
});

export const deleteMaintainInterest = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/automate/deletemaintaininterest`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DELETE_AUTOMATE_INTEREST,
      RECEIVE_DELETE_AUTOMATE_INTEREST,
      FAIL_DELETE_AUTOMATE_INTEREST,
    ],
  },
});

export const saveMaintainInterest = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/automate/savemaintaininterest`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_AUTOMATE_INTEREST,
      RECEIVE_SAVE_AUTOMATE_INTEREST,
      FAIL_SAVE_AUTOMATE_INTEREST,
    ],
  },
});

export const searchMaintainInterest = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/automate/getmaintaininterest', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_AUTOMATE_INTEREST,
      RECEIVE_SEARCH_AUTOMATE_INTEREST,
      FAIL_SEARCH_AUTOMATE_INTEREST,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_AUTOMATE_INTEREST,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_AUTOMATE_INTEREST_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_AUTOMATE_INTEREST_CRITERIA,
  payload: {
    name,
    value,
  },
});
