import React from 'react';

import Card from '../../../common/Card';
import Link from '../../../common/Link';
import Col2 from '../../../common/Col2';
import Button from '../../../common/Button';
import InputMask from '../../../common/InputMask';
import DatePicker from '../../../common/DatePicker';
import FormGroup2 from '../../../common/FormGroup2';
import TablePaging from '../../../common/TablePaging';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { toFixed, getPermission } from '../../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('FX Management', 'Manage FX Contract');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];

export default ({ state, onChangeInputData, onSubmit, onClickClear }) => {
  const isEdit = state.mode === 'edit';
  const header =
    state.mode === 'edit'
      ? `Transaction No.: ${state.PosoNo}`
      : 'Create Exposure for Booking Forward';
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" />
            <Link
              txt="Create Exposure for Booking Forward"
              href="/fx-management/create-exposure-for-booking-forward"
            />
            <span className="icon icon-angle-double-right" /> Exposure for Booking Forward
          </small>
        </p>
      </div>

      <form onSubmit={onSubmit}>
        <Card
          textHeader={header}
          bgHeader="bg-primary"
          cardActions={['toggler']}
          footer={
            permission ? (
              <>
                <Button txt="CLEAR" icon="eraser" className="btn-clear" onClick={onClickClear} />
                &nbsp;
                <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
              </>
            ) : null
          }
        >
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  value={state.FinanceGroupId}
                  disabled={isEdit}
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit" required>
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  value={state.BusinessUnitId}
                  disabled={isEdit}
                  name="BusinessUnitId"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company" required>
                {state.FinanceGroupId ? (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption={isEdit}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    notMultiple={false}
                    value={state.CompanyCode}
                    name="CompanyCode"
                    required
                    saveLocalState
                    disabled={isEdit}
                    financeGroupId={state.FinanceGroupId}
                    businessUnitId={state.BusinessUnitId}
                  />
                ) : (
                  <MasterDropdownUI
                    isChoose
                    notMultipleSelect2
                    value={state.CompanyCode}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    name="CompanyCode"
                    options={null}
                  />
                )}
              </FormGroup2>

              <FormGroup2 text="Bank" required>
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  // isChoose={true}
                  notMultipleSelect2
                  notMultiple={false}
                  // required={true}
                  companyCode={state.CompanyCode}
                  value={state.BankCode || 'All'}
                  isPoSo
                  isAll
                  // disabled={disable}
                  name="BankCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Document Type" required>
                <MasterDropdownUI
                  value={state.DocumentType}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  name="DocumentType"
                  options={['Sale Document', 'Purchase Document']}
                />
              </FormGroup2>

              <FormGroup2 text="Product Type">
                <MasterDropdownUI
                  value={state.ProductType}
                  onChange={onChangeInputData}
                  notMultiple
                  isChoose
                  name="ProductType"
                  options={['60A Foreign Exchange-Main', '60B Foreign Exchange-Special']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  isChoose
                  notMultipleSelect2
                  value={state.Currency}
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  noValidateOption
                  required
                  name="Currency"
                />
              </FormGroup2>

              <FormGroup2 text="Amount" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={state.inputMaskAmount}
                  onChange={onChangeInputData}
                  value={state.HedgePortion}
                  required
                  name="HedgePortion"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Purpose">
                <MasterDropdownUI
                  value={state.Gvc}
                  onChange={onChangeInputData}
                  notMultiple
                  isChoose
                  name="Gvc"
                  options={
                    state.DocumentType === 'Sale Document'
                      ? [
                          'Trade AR',
                          'Project',
                          'ST Loan',
                          'LT Loan & Swap',
                          'Dividend',
                          'Capital Injection',
                          'Other',
                        ]
                      : [
                          'Trade AP',
                          'Project',
                          'ST Loan',
                          'LT Loan & Swap',
                          'Dividend',
                          'Capital Injection',
                          'Other',
                        ]
                  }
                />
              </FormGroup2>

              <FormGroup2
                text="Purpose Detail"
                maxLength={2000}
                length={(state.Characteristic || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.Characteristic || ''}
                  onChange={onChangeInputData}
                  maxLength={2000}
                  name="Characteristic"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Note 1" maxLength={2000} length={(state.Note1 || '').length}>
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.Note1 || ''}
                  onChange={onChangeInputData}
                  maxLength={2000}
                  name="Note1"
                />
              </FormGroup2>

              <FormGroup2 text="Note 2" maxLength={2000} length={(state.Note2 || '').length}>
                <textarea
                  className="form-control"
                  rows="2"
                  value={state.Note2 || ''}
                  onChange={onChangeInputData}
                  maxLength={2000}
                  name="Note2"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2
                text="Internal Ref. No."
                maxLength={50}
                length={(state.InternalReference || '').length}
              >
                <input
                  className="form-control"
                  value={state.InternalReference || ''}
                  onChange={onChangeInputData}
                  name="InternalReference"
                  maxLength={50}
                />
              </FormGroup2>

              <FormGroup2 text="End Date" required>
                <DatePicker
                  value={state.EndDateStr}
                  onChange={onChangeInputData}
                  name="EndDateStr"
                  required
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Entry Date (From)">
                <DatePicker
                  value={state.EntryDateFromStr}
                  onChange={onChangeInputData}
                  name="EntryDateFromStr"
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>

              <FormGroup2 text="Entry Date (To)">
                <DatePicker
                  value={state.EntryDateToStr}
                  onChange={onChangeInputData}
                  name="EntryDateToStr"
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </Card>
      </form>

      {state.createHistory.length ? (
        <Card textHeader="Create History" bgHeader="bg-primary" cardActions={['toggler']}>
          <TablePaging colNumber={19} renderRow={renderRow()} value={state.createHistory}>
            <thead>
              <tr>
                <th className="th-success">Transaction No.</th>
                <th className="th-success">Finance Group</th>
                <th className="th-success">Business Unit</th>
                <th className="th-success">
                  Company
                  <br />
                  Code
                </th>
                <th className="th-success">
                  Company
                  <br />
                  Name
                </th>
                <th className="th-success">Bank</th>
                <th className="th-success">Product Type</th>
                <th className="th-success">Document Type</th>
                <th className="th-success">Entry Date (From)</th>
                <th className="th-success">Entry Date (To)</th>
                <th className="th-success">Amount</th>
                <th className="th-success">Currency</th>
                <th className="th-success">End Date</th>
                <th className="th-success">Internal Ref. No.</th>
                <th className="th-success">Request Type</th>
                <th className="th-success">Purpose</th>
                <th className="th-success">Purpose Detail</th>
                <th className="th-success">Note 1</th>
                <th className="th-success">Filename</th>
                <th className="th-success">Created Date</th>
                <th className="th-success">Created By</th>
                <th className="th-success">Updated Date</th>
                <th className="th-success">Updated By</th>
              </tr>
            </thead>
          </TablePaging>
        </Card>
      ) : null}
    </div>
  );
};

const renderRow = () => (m, i) => (
  <tr key={i}>
    <td>
      <a
        href={`/fx-management/exposure-for-booking-forward/${m.posoNo}`}
        target="_blank"
        rel="noreferrer"
      >
        {m.posoNo}
      </a>
    </td>
    <td>{m.financeGroupName}</td>
    <td>{m.businessUnitName}</td>
    <td style={{ textAlign: 'center' }}>{m.companyCode}</td>
    <td>{m.companyName}</td>
    <td>{m.bankCode ? `${m.bankCode} | ${m.bankAbbreviate} | ${m.bankName}` : 'Any'}</td>
    <td>{m.productType}</td>
    <td>{m.documentType}</td>
    <td style={{ textAlign: 'center' }}>{m.entryDateFromStr}</td>
    <td style={{ textAlign: 'center' }}>{m.entryDateToStr}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.hedgePortion, m.currencyDigit)}</td>
    <td style={{ textAlign: 'center' }}>{m.currency}</td>
    <td style={{ textAlign: 'center' }}>{m.endDateStr}</td>
    <td>{m.internalReference}</td>
    <td>{m.requestType}</td>
    <td>{m.gvc}</td>
    <td>{m.characteristic}</td>
    <td>{m.note1}</td>
    <td>{m.filename}</td>
    <td style={{ textAlign: 'center' }}>{m.createdDateStr}</td>
    <td>{m.createdBy}</td>
    <td style={{ textAlign: 'center' }}>{m.updatedDateStr}</td>
    <td>{m.updatedBy}</td>
  </tr>
);

const setAmount = (number, digit) => {
  if (number == null || number === '') return '';

  number = Math.abs(number);
  return toFixed(number, digit);
};
