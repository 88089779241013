import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_VIEW_IMPORT_EXCEL_FX_CONTRACT = '@@request/VIEW_IMPORT_EXCEL_FX_CONTRACT';
export const RECEIVE_VIEW_IMPORT_EXCEL_FX_CONTRACT = '@@receive/VIEW_IMPORT_EXCEL_FX_CONTRACT';
export const FAIL_VIEW_IMPORT_EXCEL_FX_CONTRACT = '@@fail/VIEW_IMPORT_EXCEL_FX_CONTRACT';

export const REQUEST_SAVE_IMPORT_EXCEL_FX_CONTRACT = '@@request/SAVE_IMPORT_EXCEL_FX_CONTRACT';
export const RECEIVE_SAVE_IMPORT_EXCEL_FX_CONTRACT =
  '@@receive-Import Success/SAVE_IMPORT_EXCEL_FX_CONTRACT';
export const FAIL_SAVE_IMPORT_EXCEL_FX_CONTRACT = '@@fail/SAVE_IMPORT_EXCEL_FX_CONTRACT';

export const REQUEST_SUBMIT_FX_CONTRACT = '@@request/SUBMIT_FX_CONTRACT';
export const RECEIVE_SUBMIT_FX_CONTRACT =
  '@@receive-The transaction was successfully saved/SUBMIT_FX_CONTRACT';
export const FAIL_SUBMIT_FX_CONTRACT = '@@fail/SUBMIT_FX_CONTRACT';

export const REQUEST_SEARCH_MANAGE_FX_CONTRACT = '@@request/SEARCH_MANAGE_FX_CONTRACT';
export const RECEIVE_SEARCH_MANAGE_FX_CONTRACT = '@@receive/SEARCH_MANAGE_FX_CONTRACT';
export const FAIL_SEARCH_MANAGE_FX_CONTRACT = '@@fail/SEARCH_MANAGE_FX_CONTRACT';

export const REQUEST_GET_MANGE_FX_CONTRACT = '@@request/GET_MANGE_FX_CONTRACT';
export const RECEIVE_GET_MANGE_FX_CONTRACT = '@@receive/GET_MANGE_FX_CONTRACT';
export const FAIL_GET_MANGE_FX_CONTRACT = '@@fail/GET_MANGE_FX_CONTRACT';

export const REQUEST_GET_ROLLOVER_FX_CONTRACT = '@@request/GET_ROLLOVER_FX_CONTRACT';
export const RECEIVE_GET_ROLLOVER_FX_CONTRACT = '@@receive/GET_ROLLOVER_FX_CONTRACT';
export const FAIL_GET_ROLLOVER_FX_CONTRACT = '@@fail/GET_ROLLOVER_FX_CONTRACT';

export const REQUEST_GET_UNWIND_FX_CONTRACT = '@@request/GET_UNWINDR_FX_CONTRACT';
export const RECEIVE_GET_UNWIND_FX_CONTRACT = '@@receive/GET_UNWIND_FX_CONTRACT';
export const FAIL_GET_UNWIND_FX_CONTRACT = '@@fail/GET_UNWIND_FX_CONTRACT';

export const REQUEST_SAVE_UNWIND_FX_CONTRACT = '@@request/SAVE_UNWIND_FX_CONTRACT';
export const RECEIVE_SAVE_UNWIND_FX_CONTRACT = '@@receive/SAVE_UNWIND_FX_CONTRACT';
export const FAIL_SAVE_UNWIND_FX_CONTRACT = '@@fail/SAVE_UNWIND_FX_CONTRACT';

export const REQUEST_SAVE_ROLLOVER_FX_CONTRACT = '@@request/SAVE_ROLLOVER_FX_CONTRACT';
export const RECEIVE_SAVE_ROLLOVER_FX_CONTRACT = '@@receive/SAVE_ROLLOVER_FX_CONTRACT';
export const FAIL_SAVE_ROLLOVER_FX_CONTRACT = '@@fail/SAVE_ROLLOVER_FX_CONTRACT';

export const REQUEST_UPDATE_FX_CONTRACT_ATTACH_FILE = '@@request/UPDATE_FX_CONTRACT_ATTACH_FILE';
export const RECEIVE_UPDATE_FX_CONTRACT_ATTACH_FILE =
  '@@receive-The transaction was successfully saved/UPDATE_FX_CONTRACT_ATTACH_FILE';
export const FAIL_UPDATE_FX_CONTRACT_ATTACH_FILE = '@@fail/UPDATE_FX_CONTRACT_ATTACH_FILE';

export const REQUEST_UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT =
  '@@request/UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT';
export const RECEIVE_UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT =
  '@@receive-The transaction was successfully saved/UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT';
export const FAIL_UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT =
  '@@fail/UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT';

export const REQUEST_UPDATE_ASSIGNTO_FX_CONTRACT = '@@request/UPDATE_ASSIGNTO_FX_CONTRACT';
export const RECEIVE_UPDATE_ASSIGNTO_FX_CONTRACT =
  '@@receive-The transaction was successfully saved/UPDATE_ASSIGNTO_FX_CONTRACT';
export const FAIL_UPDATE_ASSIGNTO_FX_CONTRACT = '@@fail/UPDATE_ASSIGNTO_FX_CONTRACT';

export const REQUEST_GET_FORWARD_CONTRACT_ATTACH_HISTORY =
  '@@request/GET_FORWARD_CONTRACT_ATTACH_HISTORY';
export const RECEIVE_GET_FORWARD_CONTRACT_ATTACH_HISTORY =
  '@@receive/GET_FORWARD_CONTRACT_ATTACH_HISTORY';
export const FAIL_GET_FORWARD_CONTRACT_ATTACH_HISTORY =
  '@@fail/GET_FORWARD_CONTRACT_ATTACH_HISTORY';

export const REQUEST_DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE =
  '@@request/DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE';
export const RECEIVE_DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE =
  '@@receive/DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE';
export const FAIL_DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE =
  '@@fail/DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE';

export const REQUEST_SET_INACTIVE_ATTACH_FORWARD_CONTRACT =
  '@@request/SET_INACTIVE_ATTACH_FORWARD_CONTRACT';
export const RECEIVE_SET_INACTIVE_ATTACH_FORWARD_CONTRACT =
  '@@receive-Delete Success/SET_INACTIVE_ATTACH_FORWARD_CONTRACT';
export const FAIL_SET_INACTIVE_ATTACH_FORWARD_CONTRACT =
  '@@fail/SET_INACTIVE_ATTACH_FORWARD_CONTRACT';

export const REQUEST_SET_ACTIVE_FORWARD_CONTRACT = '@@request/SET_ACTIVE_FORWARD_CONTRACT';
export const RECEIVE_SET_ACTIVE_FORWARD_CONTRACT = '@@receive/SET_ACTIVE_FORWARD_CONTRACT';
export const FAIL_SET_ACTIVE_FORWARD_CONTRACT = '@@fail/SET_ACTIVE_FORWARD_CONTRACT';

export const REQUEST_SET_INACTIVE_FORWARD_CONTRACT = '@@request/SET_INACTIVE_FORWARD_CONTRACT';
export const RECEIVE_SET_INACTIVE_FORWARD_CONTRACT = '@@receive/SET_INACTIVE_FORWARD_CONTRACT';
export const FAIL_SET_INACTIVE_FORWARD_CONTRACT = '@@fail/SET_INACTIVE_FORWARD_CONTRACT';

export const REQUEST_EXPORT_FX_CONTRACT = '@@request/EXPORT_FX_CONTRACT';
export const RECEIVE_EXPORT_FX_CONTRACT = '@@receive/EXPORT_FX_CONTRACT';
export const FAIL_EXPORT_FX_CONTRACT = '@@fail/EXPORT_FX_CONTRACT';

export const REQUEST_FETCH_FOWARD_CONTRACT = '@@request/FETCH_FOWARD_CONTRACT';
export const RECEIVE_FETCH_FOWARD_CONTRACT =
  '@@receive-The transaction was successfully fetched/FETCH_FOWARD_CONTRACT';
export const FAIL_FETCH_FOWARD_CONTRACT = '@@fail/FETCH_FOWARD_CONTRACT';

export const UPDATE_FOWARDNO_FOR_ROLLOVER = 'UPDATE_FOWARD_NO_FOR_ROLLOVER';
export const UPDATE_FOWARDNO_FOR_UNWIND = 'UPDATE_FOWARD_NO_FOR_UNWIND';

export const UPDATE_SEARCH_RESULT_MANAGE_FX_CONTRACT = 'UPDATE_SEARCH_RESULT_MANAGE_FX_CONTRACT';

export const EDIT_MANAGE_FX_CONTRACT_CRITERIA = 'EDIT_MANAGE_FX_CONTRACT_CRITERIA';
export const CLEAR_MANAGE_FX_CONTRACT_CRITERIA = 'CLEAR_MANAGE_FX_CONTRACT_CRITERIA';

export const REQUEST_GET_HISTORY_DETAIL = '@@request/GET_HISTORY_DETAIL';
export const RECEIVE_GET_HISTORY_DETAIL = '@@receive/GET_HISTORY_DETAIL';
export const FAIL_GET_HISTORY_DETAIL = '@@fail/GET_HISTORY_DETAIL';

export const REQUEST_GET_FORWARD_ROLLOVER_UNWIND_HISTORY =
  '@@request/GET_FORWARD_ROLLOVER_UNWIND_HISTORY';
export const RECEIVE_GET_FORWARD_ROLLOVER_UNWIND_HISTORY =
  '@@receive/GET_FORWARD_ROLLOVER_UNWIND_HISTORY';
export const FAIL_GET_FORWARD_ROLLOVER_UNWIND_HISTORY =
  '@@fail/GET_FORWARD_ROLLOVER_UNWIND_HISTORY';

export const REQUEST_SAVE_UNWIND_FX_CONTRACT_STATUS = '@@request/SAVE_UNWIND_FX_CONTRACT_STATUS';
export const RECEIVE_SAVE_UNWIND_FX_CONTRACT_STATUS =
  '@@receive-The transaction was successfully saved./SAVE_UNWIND_FX_CONTRACT_STATUS';
export const FAIL_SAVE_UNWIND_FX_CONTRACT_STATUS = '@@fail/SAVE_UNWIND_FX_CONTRACT_STATUS';

export const REQUEST_GET_BUSINESS_AREA = '@@request/GET_BUSINESS_AREA';
export const RECEIVE_GET_BUSINESS_AREA = '@@receive/GET_BUSINESS_AREA';
export const FAIL_GET_BUSINESS_AREA = '@@fail/GET_BUSINESS_AREA';

export const getBusinessArea = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/businessArea', criteria),
    method: 'GET',
    types: [REQUEST_GET_BUSINESS_AREA, RECEIVE_GET_BUSINESS_AREA, FAIL_GET_BUSINESS_AREA],
  },
});

export const saveUnwindFxContractStatus = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/SaveUnwindFxContractStatus`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_SAVE_UNWIND_FX_CONTRACT_STATUS,
      RECEIVE_SAVE_UNWIND_FX_CONTRACT_STATUS,
      FAIL_SAVE_UNWIND_FX_CONTRACT_STATUS,
    ],
  },
});

export const getRolloverUnwindHistory = (forwardNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/GetRolloverUnwindHistory/${forwardNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_FORWARD_ROLLOVER_UNWIND_HISTORY,
      RECEIVE_GET_FORWARD_ROLLOVER_UNWIND_HISTORY,
      FAIL_GET_FORWARD_ROLLOVER_UNWIND_HISTORY,
    ],
  },
});

export const getHistoryDetail = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/searchfxcontract', criteria),
    method: 'GET',
    types: [REQUEST_GET_HISTORY_DETAIL, RECEIVE_GET_HISTORY_DETAIL, FAIL_GET_HISTORY_DETAIL],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MANAGE_FX_CONTRACT_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MANAGE_FX_CONTRACT_CRITERIA,
  payload: {
    name,
    value,
  },
});

export const exportFxContract = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/ExportFxContract', criteria),
    method: 'GET',
    types: [
      REQUEST_EXPORT_FX_CONTRACT,
      {
        type: RECEIVE_EXPORT_FX_CONTRACT,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_EXPORT_FX_CONTRACT,
    ],
  },
});

export const setActiveFxContract = (forwardNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/FxContractChangeToActive/${forwardNo}`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SET_ACTIVE_FORWARD_CONTRACT,
      {
        type: RECEIVE_SET_ACTIVE_FORWARD_CONTRACT,
        payload: (action) => ({
          forwardNo,
          status: 'Active',
        }),
      },
      FAIL_SET_ACTIVE_FORWARD_CONTRACT,
    ],
  },
});

export const setInActiveFxContract = (forwardNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/FxContractChangeToInActive/${forwardNo}`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SET_INACTIVE_FORWARD_CONTRACT,
      {
        type: RECEIVE_SET_INACTIVE_FORWARD_CONTRACT,
        payload: (action) => ({
          forwardNo,
          status: 'InActive',
        }),
      },
      FAIL_SET_INACTIVE_FORWARD_CONTRACT,
    ],
  },
});

export const deleteAttachfile = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/inActiveAttachForwardContract`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SET_INACTIVE_ATTACH_FORWARD_CONTRACT,
      RECEIVE_SET_INACTIVE_ATTACH_FORWARD_CONTRACT,
      FAIL_SET_INACTIVE_ATTACH_FORWARD_CONTRACT,
    ],
  },
});

export const downloadAttachFile = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/getForwardContractExcelAttach'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE,
      {
        type: RECEIVE_DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_DOWNLOAD_FORWARD_CONTRACT_ATTACH_FILE,
    ],
  },
});

export const fetchEmailFxContract = () => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/FetchEmailFxContract`),
    method: 'GET',
    types: [
      REQUEST_FETCH_FOWARD_CONTRACT,
      RECEIVE_FETCH_FOWARD_CONTRACT,
      FAIL_FETCH_FOWARD_CONTRACT,
    ],
  },
});

export const getAttachHistory = (forwardNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/getFxContractHistory/${forwardNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_FORWARD_CONTRACT_ATTACH_HISTORY,
      RECEIVE_GET_FORWARD_CONTRACT_ATTACH_HISTORY,
      FAIL_GET_FORWARD_CONTRACT_ATTACH_HISTORY,
    ],
  },
});

export const updateAttacheFile = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/updateAttachFileFxContract'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_UPDATE_FX_CONTRACT_ATTACH_FILE,
      RECEIVE_UPDATE_FX_CONTRACT_ATTACH_FILE,
      FAIL_UPDATE_FX_CONTRACT_ATTACH_FILE,
    ],
  },
});

export const updateAttacheFileDocument = (form, forwardNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/UpdateAttacheFileDocument/${forwardNo}`),
    method: 'POST',
    body: form,
    types: [
      REQUEST_UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT,
      RECEIVE_UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT,
      FAIL_UPDATE_FX_CONTRACT_ATTACH_FILE_DOCUMENT,
    ],
  },
});

export const updateFowardNoForRollOver = (fowardNoArry) => ({
  type: UPDATE_FOWARDNO_FOR_ROLLOVER,
  payload: fowardNoArry,
});

export const updateFowardNoForUnwind = (fowardNoArry) => ({
  type: UPDATE_FOWARDNO_FOR_UNWIND,
  payload: fowardNoArry,
});

export const getRollOver = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetRollOverFxContract', data),
    method: 'GET',
    types: [
      REQUEST_GET_ROLLOVER_FX_CONTRACT,
      RECEIVE_GET_ROLLOVER_FX_CONTRACT,
      FAIL_GET_ROLLOVER_FX_CONTRACT,
    ],
  },
});

export const getUnwind = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/GetUnwindFxContract', data),
    method: 'GET',
    types: [
      REQUEST_GET_UNWIND_FX_CONTRACT,
      RECEIVE_GET_UNWIND_FX_CONTRACT,
      FAIL_GET_UNWIND_FX_CONTRACT,
    ],
  },
});

export const saveUnwind = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/unwindfxcontract', data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_SAVE_UNWIND_FX_CONTRACT,
      RECEIVE_SAVE_UNWIND_FX_CONTRACT,
      FAIL_SAVE_UNWIND_FX_CONTRACT,
    ],
  },
});

export const saveRollOver = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/rolloverfxcontract', data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_SAVE_ROLLOVER_FX_CONTRACT,
      RECEIVE_SAVE_ROLLOVER_FX_CONTRACT,
      FAIL_SAVE_ROLLOVER_FX_CONTRACT,
    ],
  },
});

export const getManageFxContract = (fowardNo) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/getfxcontract/${fowardNo}`),
    method: 'GET',
    types: [
      REQUEST_GET_MANGE_FX_CONTRACT,
      RECEIVE_GET_MANGE_FX_CONTRACT,
      FAIL_GET_MANGE_FX_CONTRACT,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MANAGE_FX_CONTRACT,
  payload: {
    name,
    value,
    index,
  },
});

export const searchManageFxContract = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/searchfxcontract', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MANAGE_FX_CONTRACT,
      RECEIVE_SEARCH_MANAGE_FX_CONTRACT,
      FAIL_SEARCH_MANAGE_FX_CONTRACT,
    ],
  },
});

export const importExcelFxContract = (formData) => ({
  [RSAA]: {
    endpoint: endpoint('/importExcel/fxContract'),
    method: 'POST',
    body: formData,
    types: [
      REQUEST_VIEW_IMPORT_EXCEL_FX_CONTRACT,
      RECEIVE_VIEW_IMPORT_EXCEL_FX_CONTRACT,
      FAIL_VIEW_IMPORT_EXCEL_FX_CONTRACT,
    ],
  },
});

export const saveImportExcel = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/saveImportExcelFxContract'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_IMPORT_EXCEL_FX_CONTRACT,
      RECEIVE_SAVE_IMPORT_EXCEL_FX_CONTRACT,
      FAIL_SAVE_IMPORT_EXCEL_FX_CONTRACT,
    ],
  },
});

export const saveFxContract = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/savefxcontract'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SUBMIT_FX_CONTRACT, RECEIVE_SUBMIT_FX_CONTRACT, FAIL_SUBMIT_FX_CONTRACT],
  },
});

export const updateAssignToFxContract = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/UpdateAssignToFxContract'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_UPDATE_ASSIGNTO_FX_CONTRACT,
      RECEIVE_UPDATE_ASSIGNTO_FX_CONTRACT,
      FAIL_UPDATE_ASSIGNTO_FX_CONTRACT,
    ],
  },
});
