import { RSAA } from 'redux-api-middleware';

import { endpoint } from '../configuration';

export const EDIT_MASTER_LOGO_CRITERIA = 'EDIT_MASTER_LOGO_CRITERIA';
export const CLEAR_MASTER_LOGO_CRITERIA = 'CLEAR_MASTER_LOGO_CRITERIA';

export const REQUEST_SEARCH_MASTER_LOGO = '@@request/SEARCH_MASTER_LOGO';
export const RECEIVE_SEARCH_MASTER_LOGO = '@@receive/SEARCH_MASTER_LOGO';
export const FAIL_SEARCH_MASTER_LOGO = '@@fail/SEARCH_MASTER_LOGO';

export const REQUEST_SAVE_MASTER_LOGO = '@@request/SAVE_MASTER_LOGO';
export const RECEIVE_SAVE_MASTER_LOGO =
  '@@receive-The record was successfully saved/SAVE_MASTER_LOGO';
export const FAIL_SAVE_MASTER_LOGO = '@@fail/SAVE_MASTER_LOGO';

export const REQUEST_DOWNLOAD_LOGO = '@@request/DOWNLOAD_LOGO';
export const RECEIVE_DOWNLOAD_LOGO = '@@receive/DOWNLOAD_LOGO';
export const FAIL_DOWNLOAD_LOGO = '@@fail/DOWNLOAD_LOGO';

export const downloadLogo = (filename) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/DownloadLogo?filename=${filename}`),
    method: 'GET',
    types: [
      REQUEST_DOWNLOAD_LOGO,
      {
        type: RECEIVE_DOWNLOAD_LOGO,
        payload: (action, state, res) => res,
      },
      FAIL_DOWNLOAD_LOGO,
    ],
  },
});

export const saveMasterLogo = (form) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveMasterLogo`),
    method: 'POST',
    body: form,
    types: [REQUEST_SAVE_MASTER_LOGO, RECEIVE_SAVE_MASTER_LOGO, FAIL_SAVE_MASTER_LOGO],
  },
});

export const searchMasterLogo = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/GetMasterLogo', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_MASTER_LOGO, RECEIVE_SEARCH_MASTER_LOGO, FAIL_SEARCH_MASTER_LOGO],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_LOGO_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_LOGO_CRITERIA,
  payload: {
    name,
    value,
  },
});
