import InputMask from 'inputmask';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import { toFixed } from '../../common/helpper';
import ImportExcel from '../../common/ImportExcel';
import BankEndingBalance from './BankEndingBalance';
import { addAlert } from '../../reducers/layout/action';
import {
  fetchEmail,
  importExcel,
  saveImportExcel,
  editSearchResult,
  submitEndingBalance,
  submitBeginningBalance,
  searchBankEndingBalance,
} from '../../reducers/bankEndingBalance/action';

const $ = window.jQuery;
export const POPOVER_ENDING_BALANCE_CONTEXT = 'a.ending_balance';
export const POPOVER_ADJUST_BALANCE_CONTEXT = 'a.adjust_balance';
export const BTN_CONFIRM_ENDING_BALANCE_CLASS = 'btn-cf-ending-balance';
export const BTN_CONFIRM_ADJUST_BALANCE_CLASS = 'btn-cf-adjust-balance';
export const DATATABLE_ID = 'table-result-bank-ending-balance';

const initCriteria = {
  FinanceGroups: [''],
  BusinessUnits: [''],
  Companys: [''],
  Banks: [''],
  Branchs: [''],
  AccountTypes: [''],
  EndingDate: [''],
  EndingBalanceFromBank: '',
};

class BankEndingBalanceContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    isMillion: false,
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    this.createPopover();
  }

  createPopover() {
    // called popover ending
    $('body').on('click', POPOVER_ENDING_BALANCE_CONTEXT, function () {
      if (!$(this).data()['bs.popover'])
        $(this)
          .popover({
            html: true,
            container: 'body',
            title: 'Credit Amount',
            content() {
              const $tr = $(this).closest('tr');
              const $dataTableApi = $(`#${DATATABLE_ID}`).DataTable();
              const $row = $dataTableApi.row($tr);
              const rowData = $row.data();
              const $form =
                $(`<form class="form-inline" data-row="${$row.index()}"><div class="input-group">
                        <input class="form-control input-sm in-pop" type="text" style="padding-right:24px" value="${rowData.endingBalanceFromBank}"/>
                        <div class="input-group-btn">
                            <button type="button" class="btn btn-primary btn-sm ${BTN_CONFIRM_ENDING_BALANCE_CLASS}" style="margin-left:7px" aria-label="Help"><i class="icon icon-check"></i></button>
                            <button type="button" class="btn btn-default btn-sm dismise-popover" style="margin-left:7px"><i class="icon icon-close"></i></button> 
                        </div>
                    </div></form>`);
              InputMask('currency', { prefix: '', digits: rowData.currencyDigit }).mask(
                $form.find('input')
              );
              return $form;
            },
          })
          .popover('show');
    });
    $('body').on('click', `.${BTN_CONFIRM_ENDING_BALANCE_CLASS}`, this.onClickConfirmEndingBalance);

    // called popover adjust
    $('body').on('click', POPOVER_ADJUST_BALANCE_CONTEXT, function () {
      if (!$(this).data()['bs.popover'])
        $(this)
          .popover({
            html: true,
            container: 'body',
            title: 'Adjust',
            content() {
              const $tr = $(this).closest('tr');
              const $dataTableApi = $(`#${DATATABLE_ID}`).DataTable();
              const $row = $dataTableApi.row($tr);
              const rowData = $row.data();
              const $form =
                $(`<form class="form-inline" data-row="${$row.index()}"><div class="input-group">
                        <input class="form-control input-sm in-pop" type="text" style="padding-right:24px" value="${rowData.beginningBalanceAdjust}"/>
                        <div class="input-group-btn">
                            <button type="button" class="btn btn-primary btn-sm ${BTN_CONFIRM_ADJUST_BALANCE_CLASS}" style="margin-left:7px" aria-label="Help"><i class="icon icon-check"></i></button>
                            <button type="button" class="btn btn-default btn-sm dismise-popover" style="margin-left:7px"><i class="icon icon-close"></i></button> 
                        </div>
                    </div></form>`);
              InputMask('currency', { prefix: '', digits: rowData.currencyDigit }).mask(
                $form.find('input')
              );

              return $form;
            },
          })
          .popover('show');
    });
    $('body').on('click', `.${BTN_CONFIRM_ADJUST_BALANCE_CLASS}`, this.onClickConfirmAdjustBalance);
  }

  componentWillUnmount() {
    // cancel called popover ending balance
    $(`${POPOVER_ENDING_BALANCE_CONTEXT}`).popover('destroy');
    $('body').off(
      'click',
      `.${BTN_CONFIRM_ENDING_BALANCE_CLASS}`,
      this.onClickConfirmEndingBalance
    );
    // cancel called popover adjust balance
    $(`${POPOVER_ADJUST_BALANCE_CONTEXT}`).popover('destroy');
    $('body').off(
      'click',
      `.${BTN_CONFIRM_ADJUST_BALANCE_CLASS}`,
      this.onClickConfirmAdjustBalance
    );
  }

  onClickConfirmEndingBalance = (e) => {
    // click confifm
    const $e = $(e.currentTarget);
    const $ctx = $e.closest('div.popover');
    const $form = $e.closest('form');
    const rowIndex = $form.data().row;
    const $input = $form.find('input');

    const currentData = this.props.searchResult[rowIndex];
    const amount = $input[0].inputmask.unmaskedvalue();

    if (!amount) {
      this.props.addAlert({
        type: 'error',
        body: 'Amount cannot be empty.',
        title: 'Error',
      });
    } else if (isNaN(amount)) {
      this.props.addAlert({
        type: 'error',
        body: 'Amount must be number',
        title: 'Error',
      });
    } else
      this.props.submitEndingBalance(
        {
          BankAccountNo: currentData.bankAccountNo,
          ValueDateStr: currentData.valueDateStr,
          Amount: amount,
        },
        rowIndex,
        this.state.criteria,
        this.state.isMillion
      );

    // close popover
    $ctx.popover('hide');
  };

  onClickConfirmAdjustBalance = async (e) => {
    const $e = $(e.currentTarget);
    const $ctx = $e.closest('div.popover');
    const $form = $e.closest('form');
    const rowIndex = $form.data().row;
    const $input = $form.find('input');

    const currentData = this.props.searchResult[rowIndex];
    const amount = $input[0].inputmask.unmaskedvalue();

    if (!amount) {
      this.props.addAlert({
        type: 'error',
        body: 'Amount cannot be empty.',
        title: 'Error',
      });
    } else if (isNaN(amount)) {
      this.props.addAlert({
        type: 'error',
        body: 'Amount must be number',
        title: 'Error',
      });
    } else
      this.props.submitBeginningBalance(
        {
          BankAccountNo: currentData.bankAccountNo,
          ValueDateStr: currentData.valueDateStr,
          Amount: amount,
        },
        rowIndex,
        this.state.criteria,
        this.state.isMillion
      );

    $ctx.popover('hide');
  };
  // end popover

  onClickOpenPopoverEndingBalance = (e, cell, data) => {};

  onClickOpenPopoverAdjustBalance = (e, cell, data) => {};

  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchBankEndingBalance(
      {
        ...this.state.criteria,
      },
      this.state.isMillion
    );
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
    });
  };

  onClickUploadHandler = () => {
    this.importExcel.open();
  };

  onClickFetchEndingBalanceHandler = () => {
    this.props.fetchEmail();
  };

  onUploadImportExcel = (file) => {
    const form = new FormData();
    if (!file) return;
    form.append('file', file);
    this.props.importExcel(form);
  };

  onClickSaveImportExcel = () => {
    if (
      !this.props.importExcelResult ||
      !this.props.importExcelResult.rows ||
      !this.props.importExcelResult.rows.length
    ) {
      this.props.addAlert({
        title: 'Error',
        body: 'Data Empty',
        type: 'error',
      });
      return;
    }
    this.props.saveImportExcel(this.props.importExcelResult.rows).then((response) => {
      this.importExcel.close();
    });
  };

  onChangeToggleMillion = (e) => {
    const { checked } = e.target;
    this.setState(
      {
        [e.target.name]: checked,
      },
      () => {
        this.props.editSearchResult('IsMillion', checked, null);
      }
    );
  };

  render() {
    const props = {
      onChangeToggleMillion: this.onChangeToggleMillion,
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      onClickUpload: this.onClickUploadHandler,
      onClickFetchEndingBalance: this.onClickFetchEndingBalanceHandler,
      dataTableSearchResultRef: (e) => (this.dataTableSearchResultRef = e),
      onClickOpenPopoverEndingBalance: this.onClickOpenPopoverEndingBalance,
      onClickOpenPopoverAdjustBalance: this.onClickOpenPopoverAdjustBalance,
      results: this.props.searchResult,
    };

    return (
      <div>
        <BankEndingBalance {...props} {...this.state} />
        <ImportExcel
          ref={(e) => (this.importExcel = e)}
          tableName="bbl-import-excel"
          optionDt={optExcel}
          onUpload={this.onUploadImportExcel}
          value={this.props.importExcelResult || {}}
          onClickSaveImport={this.onClickSaveImportExcel}
        >
          <thead>
            <tr>
              <th>Row</th>
              <th>Row Type</th>
              <th>Account</th>
              <th>Currency</th>
              <th>Account Type</th>
              <th>Company</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Error Message</th>
            </tr>
          </thead>
        </ImportExcel>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.bankEndingBalance,
  }),
  {
    addAlert,
    importExcel,
    searchBankEndingBalance,
    submitEndingBalance,
    submitBeginningBalance,
    saveImportExcel,
    editSearchResult,
    fetchEmail,
  }
)(BankEndingBalanceContainer);

const optExcel = {
  columns: [
    { data: 'row' },
    { data: 'rowType' },
    { data: 'account' },
    {
      data: 'currency',
      render: (d, t, r) => (r.rowType === 'T' ? '' : d),
    },
    { data: 'accountType' },
    { data: 'company' },
    {
      data: 'amount',
      render: (d, t, r) => (r.rowType === 'T' ? '' : toFixed(d)),
    },
    {
      data: 'date',
      render: (d, t, r) => (r.rowType === 'T' ? '' : d),
    },
    { data: 'errorMessage' },
  ],
};
