import { RSAA } from 'redux-api-middleware';

import { downloadFile } from '../../common/helpper';
import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_EXPOSURE_POSITION = '@@request/SARCH_EXPOSURE_POSITION';
export const RECEIVE_SEARCH_EXPOSURE_POSITION = '@@receive/SEARCH_EXPOSURE_POSITION';
export const FAIL_SEARCH_EXPOSURE_POSITION = '@@fail/SEARCH_EXPOSURE_POSITION';

export const REQUEST_UPDATE_EXPOSURE_POSITION = '@@request/UPDATE_EXPOSURE_POSITION';
export const RECEIVE_UPDATE_EXPOSURE_POSITION =
  '@@receive-The transaction was successfully saved/UPDATE_EXPOSURE_POSITION';
export const FAIL_UPDATE_EXPOSURE_POSITION = '@@fail/UPDATE_EXPOSURE_POSITION';

export const UPDATE_SEARCH_EXPOSURE_PORISION_RESULT = 'UPDATE_SEARCH_EXPOSURE_PORISION_RESULT';

export const REQUEST_SPLIT_EXPOSURE = '@@request/SPLIT_EXPOSURE';
export const RECEIVE_SPLIT_EXPOSURE =
  '@@receive-The transaction was successfully split/SPLIT_EXPOSURE';
export const FAIL_SPLIT_EXPOSURE = '@@fail/SPLIT_EXPOSURE';

export const REQUEST_CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT =
  '@@request/CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT';
export const RECEIVE_CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT =
  '@@receive-The transaction was successfully saved/CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT';
export const FAIL_CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT =
  '@@fail/CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT';

export const REQUEST_CONFIRM_METHOD_EXPOSURE = '@@request/CONFIRM_METHOD_EXPOSURE';
export const RECEIVE_CONFIRM_METHOD_EXPOSURE =
  '@@receive-The transaction was successfully saved/CONFIRM_METHOD_EXPOSURE';
export const FAIL_CONFIRM_METHOD_EXPOSURE = '@@fail/CONFIRM_METHOD_EXPOSURE';

export const REQUEST_EXPORT_EXCEL_EXPOSURE = '@@request/EXPORT_EXCEL_EXPOSURE';
export const RECEIVE_EXPORT_EXCEL_EXPOSURE = '@@receive/EXPORT_EXCEL_EXPOSURE';
export const FAIL_EXPORT_EXCEL_EXPOSURE = '@@fail/EXPORT_EXCEL_EXPOSURE';

export const REQUEST_SEND_EMAIL_TO_BANK_EXPOSURE = '@@request/SEND_EMAIL_TO_BANK_EXPOSURE';
export const RECEIVE_SEND_EMAIL_TO_BANK_EXPOSURE = '@@receive/SEND_EMAIL_TO_BANK_EXPOSURE';
export const FAIL_SEND_EMAIL_TO_BANK_EXPOSURE = '@@fail/SEND_EMAIL_TO_BANK_EXPOSURE';

export const REQUEST_VIEW_UPLOAD_QUOTE_RATE_EXPOSURE = '@@request/VIEW_UPLOAD_QUOTE_RATE_EXPOSURE';
export const RECEIVE_VIEW_UPLOAD_QUOTE_RATE_EXPOSURE = '@@receive/VIEW_UPLOAD_QUOTE_RATE_EXPOSURE';
export const FAIL_VIEW_UPLOAD_QUOTE_RATE_EXPOSURE = '@@fail/VIEW_UPLOAD_QUOTE_RATE_EXPOSURE';

export const UPDATE_EXPOSUREID_FOR_EXPOSURE_FORWARD_RATE =
  'UPDATE_EXPOSUREID_FOR_EXPOSURE_FORWARD_RATE';

export const REQUEST_GET_EXPOSURE_FORWARD_RATE = '@@request/GET_EXPOSURE_FORWARD_RATE';
export const RECEIVE_GET_EXPOSURE_FORWARD_RATE = '@@receive/GET_EXPOSURE_FORWARD_RATE';
export const FAIL_GET_EXPOSURE_FORWARD_RATE = '@@fail/GET_EXPOSURE_FORWARD_RATE';

export const REQUEST_SAVE_EXPOSURE_FORWARD_RATE = '@@request/SAVE_EXPOSURE_FORWARD_RATE';
export const RECEIVE_SAVE_EXPOSURE_FORWARD_RATE = '@@receive/SAVE_EXPOSURE_FORWARD_RATE';
export const FAIL_SAVE_EXPOSURE_FORWARD_RATE = '@@fail/SAVE_EXPOSURE_FORWARD_RATE';

export const REQUEST_UPLOAD_VIEW_QUOTE_RATE_EXPOSURE = '@@request/UPLOAD_VIEW_QUOTE_RATE_EXPOSURE';
export const RECEIVE_UPLOAD_VIEW_QUOTE_RATE_EXPOSURE = '@@receive/UPLOAD_VIEW_QUOTE_RATE_EXPOSURE';
export const FAIL_UPLOAD_VIEW_QUOTE_RATE_EXPOSURE = '@@fail/UPLOAD_VIEW_QUOTE_RATE_EXPOSURE';

export const REQUEST_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE = '@@request/UPLOAD_SAVE_QUOTE_RATE_EXPOSURE';
export const RECEIVE_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE =
  '@@receive-The transaction was successfully imported/UPLOAD_SAVE_QUOTE_RATE_EXPOSURE';
export const FAIL_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE = '@@fail/UPLOAD_SAVE_QUOTE_RATE_EXPOSURE';

export const REQUEST_CHANGE_EXPOSURE_STATUS_TO_WITHDRAW =
  '@@request/CHANGE_EXPOSURE_STATUS_TO_WITHDRAW';
export const RECEIVE_CHANGE_EXPOSURE_STATUS_TO_WITHDRAW =
  '@@receive/CHANGE_EXPOSURE_STATUS_TO_WITHDRAW';
export const FAIL_CHANGE_EXPOSURE_STATUS_TO_WITHDRAW = '@@fail/CHANGE_EXPOSURE_STATUS_TO_WITHDRAW';

export const REQUEST_SAVE_EXPOSURE_QUOTE_RATE = '@@request/SAVE_EXPOSURE_QUOTE_RATE';
export const RECEIVE_SAVE_EXPOSURE_QUOTE_RATE = '@@receive/SAVE_EXPOSURE_QUOTE_RATE';
export const FAIL_SAVE_EXPOSURE_QUOTE_RATE = '@@fail/SAVE_EXPOSURE_QUOTE_RATE';

export const REQUEST_VIEW_IMPORT_INWARD_EXCEL = '@@request/VIEW_IMPORT_INWARD_EXCEL';
export const RECEIVE_VIEW_IMPORT_INWARD_EXCEL = '@@receive/VIEW_IMPORT_INWARD_EXCEL';
export const FAIL_VIEW_IMPORT_INWARD_EXCEL = '@@fail/VIEW_IMPORT_INWARD_EXCEL';

export const REQUEST_SUBMIT_IMPORT_INWARD_EXCEL = '@@request/SUBMIT_IMPORT_INWARD_EXCEL';
export const RECEIVE_SUBMIT_IMPORT_INWARD_EXCEL =
  '@@receive-The transaction was successfully imported/SUBMIT_IMPORT_INWARD_EXCEL';
export const FAIL_SUBMIT_IMPORT_INWARD_EXCEL = '@@fail/SUBMIT_IMPORT_INWARD_EXCEL';

export const REQUEST_UNDO_EXPOSURE_POSITION = '@@request/UNDO_EXPOSURE_POSITION';
export const RECEIVE_UNDO_EXPOSURE_POSITION = '@@receive/UNDO_EXPOSURE_POSITION';
export const FAIL_UNDO_EXPOSURE_POSITION = '@@fail/UNDO_EXPOSURE_POSITION';

export const REQUEST_EXPORT_EXPOSURE_SPOT_SUMMARY = '@@request/EXPORT_EXPOSURE_SPOT_SUMMARY';
export const RECEIVE_EXPORT_EXPOSURE_SPOT_SUMMARY = '@@receive/EXPORT_EXPOSURE_SPOT_SUMMARY';
export const FAIL_EXPORT_EXPOSURE_SPOT_SUMMARY = '@@fail/EXPORT_EXPOSURE_SPOT_SUMMARY';

export const REQUEST_FETCH_EMAIL_QUOTE_RATE = '@@request/FETCH_EMAIL_QUOTE_RATE';
export const RECEIVE_FETCH_EMAIL_QUOTE_RATE =
  '@@receive-The transaction was successfully fetched/FETCH_EMAIL_QUOTE_RATE';
export const FAIL_FETCH_EMAIL_QUOTE_RATE = '@@fail/FETCH_EMAIL_QUOTE_RATE';

export const REQUEST_FETCH_EMAIL_INWARD = '@@request/FETCH_EMAIL_INWARD';
export const RECEIVE_FETCH_EMAIL_INWARD = '@@receive/FETCH_EMAIL_INWARD';
export const FAIL_FETCH_EMAIL_INWARD = '@@fail/FETCH_EMAIL_INWARD';

export const EDIT_EXPOSURE_POSITION_CRITERIA = 'EDIT_EXPOSURE_POSITION_CRITERIA';
export const CLEAR_EXPOSURE_POSITION_CRITERIA = 'CLEAR_EXPOSURE_POSITION_CRITERIA';

export const REQUEST_MANUAL_AUTO_INCOME = '@@request/MANUAL_AUTO_INCOME';
export const RECEIVE_MANUAL_AUTO_INCOME = '@@receive/MANUAL_AUTO_INCOME';
export const FAIL_MANUAL_AUTO_INCOME = '@@fail/MANUAL_AUTO_INCOME';

export const REQUEST_EXPOSUREPOSITION_AUTOMATE = '@@request/EXPOSUREPOSITION_AUTOMATE';
export const RECEIVE_EXPOSUREPOSITION_AUTOMATE =
  '@@receive-Exposure Position Automate Success/EXPOSUREPOSITION_AUTOMATE';
export const FAIL_EXPOSUREPOSITION_AUTOMATE = '@@fail/EXPOSUREPOSITION_AUTOMATE';

export const automate = (transactions) => ({
  [RSAA]: {
    endpoint: endpoint(`/automate/exposure-position-automate`),
    method: 'POST',
    body: JSON.stringify(transactions),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_EXPOSUREPOSITION_AUTOMATE,
      RECEIVE_EXPOSUREPOSITION_AUTOMATE,
      FAIL_EXPOSUREPOSITION_AUTOMATE,
    ],
  },
});

export const manualAutoIncome = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/sapinterface/exposureimport/`, data),
    method: 'GET',
    types: [
      REQUEST_FETCH_EMAIL_QUOTE_RATE,
      RECEIVE_FETCH_EMAIL_QUOTE_RATE,
      FAIL_FETCH_EMAIL_QUOTE_RATE,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_EXPOSURE_POSITION_CRITERIA,
});

export const fetchEmailQuoteRate = () => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/FetchEmailQuoteRate/'),
    method: 'GET',
    types: [
      REQUEST_FETCH_EMAIL_QUOTE_RATE,
      RECEIVE_FETCH_EMAIL_QUOTE_RATE,
      FAIL_FETCH_EMAIL_QUOTE_RATE,
    ],
  },
});

export const fetchEmailInward = () => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/FetchEmailInward/'),
    method: 'GET',
    types: [REQUEST_FETCH_EMAIL_INWARD, RECEIVE_FETCH_EMAIL_INWARD, FAIL_FETCH_EMAIL_INWARD],
  },
});

export const editCriteria = (name, value) => ({
  type: EDIT_EXPOSURE_POSITION_CRITERIA,
  payload: {
    name,
    value,
  },
});

export const undoExposure = (exposureId) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/ExposureUndo/${exposureId}`),
    method: 'POST',
    types: [
      REQUEST_UNDO_EXPOSURE_POSITION,
      RECEIVE_UNDO_EXPOSURE_POSITION,
      FAIL_UNDO_EXPOSURE_POSITION,
    ],
  },
});

export const exportSpotSummary = (FinanceGroupId) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/GetExposureSpotSummary/${FinanceGroupId}`),
    method: 'GET',
    types: [
      REQUEST_EXPORT_EXPOSURE_SPOT_SUMMARY,
      {
        type: RECEIVE_EXPORT_EXPOSURE_SPOT_SUMMARY,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_EXPORT_EXPOSURE_SPOT_SUMMARY,
    ],
  },
});

export const uplodaSaveInwardExcel = (datas) => ({
  [RSAA]: {
    endpoint: endpoint(`/fxmanagement/ExposureSaveInwardExcel`),
    method: 'POST',
    body: JSON.stringify(datas),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SUBMIT_IMPORT_INWARD_EXCEL,
      RECEIVE_SUBMIT_IMPORT_INWARD_EXCEL,
      FAIL_SUBMIT_IMPORT_INWARD_EXCEL,
    ],
  },
});

export const uplodaViewInwardExcel = (form) => ({
  [RSAA]: {
    endpoint: endpoint(`/ImportExcel/Inward`),
    method: 'POST',
    body: form,
    types: [
      REQUEST_VIEW_IMPORT_INWARD_EXCEL,
      RECEIVE_VIEW_IMPORT_INWARD_EXCEL,
      FAIL_VIEW_IMPORT_INWARD_EXCEL,
    ],
  },
});

export const saveExposureQuoteRate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/exposureSaveQuoteRate'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_EXPOSURE_QUOTE_RATE,
      RECEIVE_SAVE_EXPOSURE_QUOTE_RATE,
      FAIL_SAVE_EXPOSURE_QUOTE_RATE,
    ],
  },
});

export const uploadSaveQuoteRate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/saveImportExcelQuoteRate'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE,
      RECEIVE_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE,
      FAIL_UPLOAD_SAVE_QUOTE_RATE_EXPOSURE,
    ],
  },
});

export const uploadViewQuoteRate = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/ImportExcel/QuoteRate'),
    method: 'POST',
    body: form,
    types: [
      REQUEST_UPLOAD_VIEW_QUOTE_RATE_EXPOSURE,
      RECEIVE_UPLOAD_VIEW_QUOTE_RATE_EXPOSURE,
      FAIL_UPLOAD_VIEW_QUOTE_RATE_EXPOSURE,
    ],
  },
});

export const saveExposureForwardRate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/saveExposureForwardRate'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_SAVE_EXPOSURE_FORWARD_RATE,
      RECEIVE_SAVE_EXPOSURE_FORWARD_RATE,
      FAIL_SAVE_EXPOSURE_FORWARD_RATE,
    ],
  },
});

export const getExposureForwardRate = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/getExposureFrowardRate'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_GET_EXPOSURE_FORWARD_RATE,
      RECEIVE_GET_EXPOSURE_FORWARD_RATE,
      FAIL_GET_EXPOSURE_FORWARD_RATE,
    ],
  },
});

export const UPDATE_FOWARD_RATE = 'UPDATE_FOWARD_RATE';

export const updateExposureIdForForwardRate = (data) => ({
  type: UPDATE_EXPOSUREID_FOR_EXPOSURE_FORWARD_RATE,
  payload: data,
});

export const viewUploadQuoteRate = (form) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/ExposureUploadQuoteRate'),
  },
});

export const sendEmailToBank = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/ExposureSendEmailToBank'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SEND_EMAIL_TO_BANK_EXPOSURE,
      RECEIVE_SEND_EMAIL_TO_BANK_EXPOSURE,
      FAIL_SEND_EMAIL_TO_BANK_EXPOSURE,
    ],
  },
});

export const changeStatusToWithdraw = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/ExposureWithdraw'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CHANGE_EXPOSURE_STATUS_TO_WITHDRAW,
      RECEIVE_CHANGE_EXPOSURE_STATUS_TO_WITHDRAW,
      FAIL_CHANGE_EXPOSURE_STATUS_TO_WITHDRAW,
    ],
  },
});

export const exportExcel = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/ExposureExportExcel'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_EXPORT_EXCEL_EXPOSURE,
      {
        type: RECEIVE_EXPORT_EXCEL_EXPOSURE,
        payload: (action, state, res) => {
          downloadFile(res);
          return true;
        },
      },
      FAIL_EXPORT_EXCEL_EXPOSURE,
    ],
  },
});

export const changeQuoteRateMethodToSpot = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/ExposureChangeMethodToSpot'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT,
      RECEIVE_CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT,
      FAIL_CHANGE_EXPOSURE_QUOTE_RATE_METHOD_TO_SPOT,
    ],
  },
});

export const confirmMethod = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/ExposureConfirmMethod'),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_CONFIRM_METHOD_EXPOSURE,
      RECEIVE_CONFIRM_METHOD_EXPOSURE,
      FAIL_CONFIRM_METHOD_EXPOSURE,
    ],
  },
});

export const splitExposure = (data) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/SplitExposure'),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [REQUEST_SPLIT_EXPOSURE, RECEIVE_SPLIT_EXPOSURE, FAIL_SPLIT_EXPOSURE],
  },
});

export const updateExposure = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/fxmanagement/saveexposureposition'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [
        REQUEST_UPDATE_EXPOSURE_POSITION,
        RECEIVE_UPDATE_EXPOSURE_POSITION,
        FAIL_UPDATE_EXPOSURE_POSITION,
      ],
    },
  });

  if (!actionResponse.error) return await dispatch(searchExposurePosition(criteria));

  return actionResponse;
};

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_EXPOSURE_PORISION_RESULT,
  payload: {
    name,
    value,
    index,
  },
});

export const searchExposurePosition = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/fxmanagement/searchexposureposition', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_EXPOSURE_POSITION,
      RECEIVE_SEARCH_EXPOSURE_POSITION,
      FAIL_SEARCH_EXPOSURE_POSITION,
    ],
  },
});
