import { connect } from 'react-redux';
import React, { Component } from 'react';

import PrintInterestReceiptMaintainSearch from './PrintInterestReceiptMaintainSearch';
import {
  searchInterestReceiptMaintain,
  changeStatusInterestReceiptMaintain,
} from '../../reducers/printInterestReceipt/action';

const $ = window.jQuery;
class PrintInterestReceiptMaintainSearchContainer extends Component {
  state = {
    searchResults: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `button.change-status`, this.onClickColumnChangeStatus);

    this.search();
  }

  search() {
    this.props.dispatch(searchInterestReceiptMaintain()).then((response) => {
      if (response.error) return;
      this.setState({
        searchResults: response.payload || [],
      });
    });
  }

  componentWillUnmount() {
    $('body').off('click', `button.change-status`);
  }

  onClickColumnChangeStatus = (e) => {
    const $dt = this.dataTableSearchResultRef.$dataTable;
    const row = $dt.fixedColumns().rowIndex($(e.target).closest('tr'));
    const rowData = $dt.row(row).data();
    if (!rowData) return;
    this.props
      .dispatch(
        changeStatusInterestReceiptMaintain({
          Id: rowData.id,
          IsActive: !rowData.isActive,
        })
      )
      .then((response) => {
        if (response.error) return;

        this.search();
      });
  };

  render() {
    return (
      <PrintInterestReceiptMaintainSearch
        {...this.state}
        dataTableRef={(e) => (this.dataTableSearchResultRef = e)}
      />
    );
  }
}
export default connect()(PrintInterestReceiptMaintainSearchContainer);
