import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const REQUEST_SEARCH_TASK_LIST = '@@request/SEARCH_TASK_LIST';
export const RECEIVE_SEARCH_TASK_LIST = '@@receive/SEARCH_TASK_LIST';
export const FAIL_SEARCH_TASK_LIST = '@@fail/SEARCH_TASK_LIST';

export const REQUEST_CHANGE_STATUS_TASK_LIST = '@@request/CHANGE_STATUS_TASK_LIST';
export const RECEIVE_CHANGE_STATUS_TASK_LIST =
  '@@receive-The transaction was successfully change status/CHANGE_STATUS_TASK_LIST';
export const FAIL_CHANGE_STATUS_TASK_LIST = '@@fail/CHANGE_STATUS_TASK_LIST';

export const EDIT_SEARCH_TASK_LIST = 'EDIT_SEARCH_TASK_LIST';

export const UPDATE_SEARCH_RESULT_TASK_LIST = 'UPDATE_SEARCH_RESULT_TASK_LIST';

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_TASK_LIST,
  payload: {
    name,
    value,
    index,
  },
});

export const editSearchResult = (name, value, index) => ({
  type: EDIT_SEARCH_TASK_LIST,
  payload: {
    name,
    value,
    index,
  },
});

export const searchTaskList = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/BankSummary/Tasklist', criteria),
    method: 'GET',
    types: [REQUEST_SEARCH_TASK_LIST, RECEIVE_SEARCH_TASK_LIST, FAIL_SEARCH_TASK_LIST],
  },
});

export const changeStatus = (data, criteria) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint('/BankSummary/ApproverChangeStatus'),
      method: 'POST',
      headers: jsonContentTypeHeader,
      body: JSON.stringify(data),
      types: [
        REQUEST_CHANGE_STATUS_TASK_LIST,
        RECEIVE_CHANGE_STATUS_TASK_LIST,
        FAIL_CHANGE_STATUS_TASK_LIST,
      ],
    },
  });

  if (!actionResponse.error) return await searchTaskList(criteria);

  return actionResponse;
};
