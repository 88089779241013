import { connect } from 'react-redux';
import React, { Component } from 'react';

import RoleDetail from './RoleDetail';
import { toUpperKey } from '../../common/helpper';
import { addAlert } from '../../reducers/layout/action';
import {
  getMasterProduct,
  getMasterRoleDetail,
  saveMasterRoleDetail,
} from '../../reducers/masterRole/action';

const initData = {
  RoleId: '',
  RoleName: '',
  ResultProduct: [],
  mode: 'create',
};

class RoleDetailContainer extends Component {
  state = {
    ...initData,
  };

  componentDidMount() {
    const { id } = this.props.routeProp.match.params;

    if (!id || id.toString().toLowerCase() === 'create') {
      this.props.getMasterProduct({ RoleId: id }).then((response) => {
        if (response.error || !response.payload) return;
        this.setState({
          ...initData,
          ResultProduct: response.payload,
        });
      });
    } else {
      this.fetchId(id);
    }
  }

  fetchId(id) {
    if (!id) return;

    this.props.getMasterRoleDetail({ RoleId: id }).then((response) => {
      if (response.error || !response.payload) return;

      const fetchData = toUpperKey(response.payload);
      this.setState({
        ...this.state,
        ...fetchData,
        mode: 'edit',
      });
    });

    this.props.getMasterProduct({ RoleId: id }).then((response) => {
      if (response.error || !response.payload) return;
      this.setState({
        ...this.state,
        ResultProduct: response.payload,
      });
    });
  }

  onChangeInputData = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const stateToUpdate = {
        [name]: value,
      };
      return stateToUpdate;
    });
  };

  validateForms(forms = []) {
    let formEle = null;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form && !form.checkValidity()) {
        i = forms.lengths;
        formEle = form;
      }
    }
    if (!formEle) return;

    const formName = formEle.name;
    if (formName === 'detail' || formName === 'configuration') {
      formEle.reportValidity();
    } else if (this.tabRef.state.currentTab !== formName) {
      this.tabRef.setTabActive(formName);
      setTimeout(() => {
        formEle.reportValidity();
      }, 500);
    } else formEle.reportValidity();

    return formName;
  }

  onSubmitHander = (e) => {
    const inValidFormName = this.validateForms([this.formDetailRef, this.formConfigRef]);
    if (inValidFormName) return;

    const result = this.state.ResultProduct.filter((f) => f.isActive);
    const submitData = {
      RoleId: this.state.RoleId,
      RoleName: this.state.RoleName,
      Mode: this.state.mode,
      ResultProduct: result,
    };

    this.props.saveMasterRoleDetail(submitData).then((response) => {
      if (response.error || !response.payload) return;

      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
        buttons: [
          <button className="btn btn-success" onClick={this.onClickBackToPage} data-dismiss="modal">
            Continue
          </button>,
        ],
      });

      if (this.state.mode === 'create') {
        window.location.href = `/master/role-detail/${response.payload}`;
      }
    });
  };

  onClickBackToPage = () => {
    this.props.routeProp.history.push('/master/role');
  };

  onChangeInputTable = (e, index) => {
    const { name, value } = e.target;
    this.setState((state) => ({
      ResultProduct: this.state.ResultProduct.map((m, i) => {
        if (i === index) {
          const obj = {
            ...m,
          };
          if (name === 'isActive') {
            obj[name] = !(m.isActive || false);
          } else if (name.includes('permission')) {
            obj.permission = value;
            obj[name] = value;
          }
          return obj;
        }
        return m;
      }),
    }));
  };

  render() {
    const props = {
      onChangeInputData: this.onChangeInputData,
      onSubmit: this.onSubmitHander,
      onChangeInputTable: this.onChangeInputTable,

      tabRef: (e) => (this.tabRef = e),
      formDetailRef: (e) => (this.formDetailRef = e),
      formConfigRef: (e) => (this.formConfigRef = e),
    };

    return (
      <div>
        <RoleDetail {...props} state={this.state} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    ...state.masterRole,
  }),
  {
    getMasterRoleDetail,
    saveMasterRoleDetail,
    getMasterProduct,
    addAlert,
  }
)(RoleDetailContainer);
