import React, { Fragment } from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Select2 from '../../../common/Select2';
import Table from '../../../common/DataTable';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import ButtonDropdown from '../../../common/ButtonDropdown';
import DateRangePicker from '../../../common/DateRangePicker';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import { DATATABLE_ID, DATATABLE_ID_BU, DATATABLE_ID_SUM } from './FxConfirmationContainer';
import {
  toBank,
  toLower,
  toFixed,
  toNumber,
  toStatus,
  getPermission,
} from '../../../common/helpper';
import {
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_UNIT,
} from '../../../reducers/master/action';

const permission = getPermission('FX Management', 'FX Confirmation');

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const $ = window.jQuery;

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[3, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  // colReorder: false,
  // ordering: false,
  // rowGroup: {
  //     startRender: null,
  //     endRender: function (rows, group) {
  //         let $tr = $('<tr />')
  //         let datas = rows.data().toArray()
  //         let totalAmount = 0,
  //             transactionType,
  //             sellCurrency,
  //             buyCurrency,
  //             statusFile,
  //             digit
  //         datas.forEach((m ,i) => {
  //             transactionType = m.transactionType ? m.transactionType : transactionType
  //             sellCurrency = m.sellCurrency ? m.sellCurrency : sellCurrency
  //             buyCurrency = m.buyCurrency ? m.buyCurrency : buyCurrency
  //             statusFile = m.statusFile ? m.statusFile : statusFile
  //             digit = m.currencyDigit
  //             totalAmount += m.amount || 0
  //         })
  //         $tr.append(`<td colspan="10" align="right">Total</td>`)
  //             .append(`<td>${transactionType}</td>`)
  //             .append(`<td colspan="6"></td>`)
  //             .append(`<td align="center">${buyCurrency}</td>`)
  //             .append(`<td align="center">${sellCurrency}</td>`)
  //             .append(`<td align="right">${toNumber(totalAmount, digit)}</td>`)
  //             .append(`<td colspan="17"></td>`)
  //             .append(`<td align="center">${statusFile}</td>`)
  //             .append(`<td colspan="5"></td>`)
  //         return $tr;
  //     },
  //     dataSrc: `mapGroup`
  // },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        if (!r.isActive) {
          r.canSelect = false;
          return '';
        }
        if (
          toLower(r.status) === 'wait for confirm' ||
          toLower(r.status) === 'wait for send email to bank' ||
          toLower(r.status) === 'completed'
        ) {
          r.canSelect = true;
          return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
        }
        if (
          toLower(r.status) === 'wait for quote rate' &&
          (r.swapRateBbl != null ||
            r.swapRateKbank != null ||
            r.swapRateScb != null ||
            r.swapRateSmbc != null)
        ) {
          r.canSelect = true;
          return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
        }
        r.canSelect = false;
        return '';
      },
    },
    {
      orderable: false,
      data: 'isActive',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (r.isGroup) return '';
        if (toLower(r.matchingStatus) !== 'wait for confirm') return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      orderable: false,
      data: 'isUnGroup',
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (
          toLower(r.status) !== 'wait for confirm' &&
          toLower(r.status) !== 'wait for send email to bank'
        )
          return '';
        if (r.isGroup)
          return '<button class="btn btn-icon btn-warning ungroup"><span class="icon icon-refresh sq-24"></span></button>';
        return '';
      },
    },
    {
      data: 'transactionNo',
      render: (d, r, s) =>
        `<a target="_blank" href="/fx-management/fx-confirmation-detail/${s.id}" class="edit-fx-matching"> ${d} </a>`,
    },
    {
      data: 'bankCode',
      render: (d, r, s) => {
        if (!d) return 'Any';
        return d;
      },
    },
    {
      data: 'bankAbbreviate',
      render: (d, r, s) => toBank(s.bankAbbreviate, s.bankName),
    },
    { data: 'financeGroupName' },
    // { data: 'id' },
    { data: 'businessUnitName' },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    { data: 'productType', className: 'text-center' },
    { data: 'transactionType', className: 'text-center' },
    { data: 'businessPartnerCode', className: 'text-center' },
    // { data: 'businessPartnerAbbreviate', render: (d, r, s) => { return toBank(d, s.businessPartnerName) } },
    { data: 'contractNo' },
    { data: 'contractDateStr', className: 'text-center', type: 'date-black' },
    { data: 'startDateStr', className: 'text-center', type: 'date-black' },
    { data: 'endDateStr', className: 'text-center', type: 'date-black' },
    { data: 'forwardType' },
    { data: 'buyCurrency', className: 'text-center' },
    { data: 'sellCurrency', className: 'text-center' },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => (d ? toNumber(Number(d), r.currencyDigit) : ''),
    },
    {
      data: 'spotRate',
      className: 'text-right',
      render: (d, t, r) => (d != null ? toNumber(Number(d), 8) : ''),
    },
    {
      data: 'baseRate',
      className: 'text-right',
      render: (d, t, r) => (d != null ? toNumber(Number(d), 8) : ''),
    },
    {
      data: 'swapRateBase',
      className: 'text-right',
      render: (d, t, r) => (d != null ? toNumber(Number(d), 8) : ''),
    },
    {
      data: 'forwardRate',
      className: 'text-right',
      render: (d, t, r) => (d != null ? toNumber(Number(d), 8) : ''),
    },
    {
      data: 'swapRate',
      className: 'text-right',
      render: (d, t, r) => (d != null ? toNumber(Number(d), 8) : ''),
    },
    { data: 'gvc' },
    { data: 'portfolio' },
    { data: 'assignment' },
    { data: 'characteristic' },
    { data: 'internalReference' },
    { data: 'requestType' },
    { data: 'contractDetail1' },
    { data: 'contractDetail2' },
    { data: 'contractDetail3' },
    { data: 'contractDetail4' },
    { data: 'dealer' },
    { data: 'department' },
    { data: 'statusFile', className: 'text-center' },
    { data: 'note1' },
    { data: 'note2' },
    {
      data: 'status',
      className: 'text-center',
      render: (d, t, r) => (r.isActive ? toStatus(d, true) : toStatus('Inactive', true)),
    },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
  ],
  // drawCallback: () => {
  //     setTimeout(() => {
  //         $(window).trigger('resize');
  //     }, 1000)
  // }
};

const optBu = {
  order: [[0, 'asc']],
  columns: [
    { data: 'businessUnitName' },
    { data: 'currency', className: 'text-center' },
    { data: 'statusFile', className: 'text-center' },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => (d ? toNumber(Number(d), r.currencyDigit) : ''),
    },
  ],
};

const optSum = {
  order: [[1, 'asc']],
  columns: [
    { data: 'transactionType' },
    { data: 'currency', className: 'text-center' },
    { data: 'statusFile', className: 'text-center' },
    {
      data: 'amount',
      className: 'text-right',
      render: (d, t, r) => (d ? toNumber(Number(d), r.currencyDigit) : ''),
    },
  ],
};

export default ({
  criteria,
  results,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,
  dataTableRef,
  onClickConfirm,
  modalConfirmBankRef,
  onSubmitConfirm,
  onClickSelectBank,
  modalEditRef,
  onSubmitSelectBank,
  onChangeInput,
  modalData,
  onClickWithdraw,
  modalWithdrawRef,
  onClickBuMatching,
  modalBuRef,
  buMatching,
  modalUnmatchRef,
  onClickUnMatch,
  matchingDate,
  matchingTime,
  modalActiveRef,
  modalInactiveRef,
  modalBuisnessPartnerRef,
  onClickSelectBusinessPartner,
  onSubmitSelectBusinessPartner,
  onSaveBusinessPartner,
  modalAlertRef,
  onClickSendEmail,
  modalEmailRef,
  summaryList,
  onClickExport,

  modalDateRef,
  modalDate,
  onClickSelectDate,
  onChangeDateModal,
  onSaveSelectDate,

  onCreateFxMatching,
  modalCreateRef,
  modalCreate,
  onSaveCreateFxConfirmation,
  onChangeCreateModal,
  compFilter,
  currencyFilter,

  modalGroup,
  onClickGroup,
  onSaveGrouping,
  modalGroupRef,
  modalUngroupRef,

  modalAutoGroupRef,
  onSaveAutoGrouping,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;
  let showConfirmBank = false;
  let showWithdraw = false;
  let showUnmatch = false;
  let showBusinessPartner = false;
  if (results.length) {
    showConfirmBank = toLower(results[0].matchingStatus) === 'wait for confirm';
    showWithdraw = toLower(results[0].matchingStatus) !== 'wait for confirm';
    showUnmatch = toLower(results[0].matchingStatus) === 'wait for confirm';
    showBusinessPartner = toLower(results[0].matchingStatus) !== 'wait for confirm';
  }

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            FX Management <span className="icon icon-angle-double-right" /> FX Confirmation
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required={false}>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  // isChoose={true}
                  // notMultipleSelect2={true}
                  // onChange={e => onChangeSearch({
                  //     target: e
                  // })}
                  // required={true}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroup}
                  name="FinanceGroup"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit">
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnit}
                  name="BusinessUnit"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Company}
                  name="Company"
                  saveLocalState
                  financeGroupId={criteria.FinanceGroup}
                  businessUnitId={criteria.BusinessUnit}
                  matchingDateStr={criteria.MatchingDateStr}
                  matchingTime={criteria.MatchingTime}
                  filter={(m) => ~compFilter.indexOf(m.companyCode)}
                />
              </FormGroup2>
              <FormGroup2 text="Bank">
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  onChange={onChangeSelect2}
                  // isAll={true}
                  isPoSo
                  isSearch
                  // notMultipleSelect2={true}
                  value={criteria.Bank}
                  name="Bank"
                  companyCode={criteria.Company}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Product Type">
                <MasterDropdownUI
                  value={criteria.ProductType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="ProductType"
                  options={['60A Foreign Exchange-Main', '60B Foreign Exchange-Special']}
                />
              </FormGroup2>
              <FormGroup2 text="Transaction Type">
                <MasterDropdownUI
                  value={criteria.TransactionType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="TransactionType"
                  options={['Buy', 'Sell']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Forward Type">
                <MasterDropdownUI
                  value={criteria.ForwardType}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="ForwardType"
                  options={['1 Fixed Forward', '2 Par Forward', '3 Pro Rata Forward']}
                />
              </FormGroup2>
              <FormGroup2 text="Status">
                <MasterDropdownUI
                  value={criteria.Status}
                  onChange={onChangeSearch}
                  notMultiple
                  isAll
                  name="Status"
                  options={[
                    'Wait for confirm',
                    'Wait for quote rate',
                    'Wait for send email to bank',
                    'Completed',
                    'Inactive',
                  ]}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Forward Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={onChangeSelect2}
                  // isAll={true}
                  // notMultipleSelect2={true}
                  value={criteria.Currency}
                  disabled={!criteria.TransactionType}
                  matchingDateStr={criteria.MatchingDateStr}
                  matchingTime={criteria.MatchingTime}
                  filter={(m) => ~currencyFilter.indexOf(m.currency)}
                  name="Currency"
                />
              </FormGroup2>
              <FormGroup2 text="Status Matching">
                <MasterDropdownUI
                  value={criteria.StatusFile}
                  onChange={onChangeSelect2}
                  // notMultiple={true}
                  // isAll={true}
                  name="StatusFile"
                  options={['Matching', 'Import', 'Export']}
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Matching Date" required>
                <DatePicker
                  onChange={onChangeSearch}
                  value={criteria.MatchingDateStr}
                  required
                  name="MatchingDateStr"
                />
              </FormGroup2>
              <FormGroup2 text="Matching Time" required>
                <Select2
                  value={criteria.MatchingTime}
                  notMultiple
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  name="MatchingTime"
                  required
                >
                  {matchingTime.map((m) => (
                    <option value={m.time} key={m.time}>
                      {m.time}
                    </option>
                  ))}
                </Select2>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[1]}>
            <Col2 col={colX[0]}>
              <FormGroup2 text="Entry Date" required={false}>
                <Select2
                  value={criteria.EntryDateStr}
                  notMultiple
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  name="EntryDateStr"
                  required={false}
                >
                  {matchingDate.map((m) => (
                    <option value={m.dateFromTo} key={m.dateFromTo}>
                      {m.dateFromTo}
                    </option>
                  ))}
                </Select2>
              </FormGroup2>

              <FormGroup2 text="End Date" required={false}>
                <DateRangePicker
                  onChange={onChangeSearch}
                  required={false}
                  value={criteria.EndDateStr}
                  name="EndDateStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[0]}>
              <FormGroup2 text="&nbsp;">
                <div>
                  <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
                  &nbsp;
                  <Button
                    txt="Clear Search"
                    icon="eraser"
                    className="btn-clear"
                    onClick={onClickClearSearch}
                  />
                </div>
              </FormGroup2>
            </Col2>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <Col2 col={colX[2]}>
            <div>
              <Button
                txt="BU Matching"
                icon="credit-card-alt"
                className="btn-lightbrown"
                onClick={onClickBuMatching}
              />
              &nbsp;
              {permission ? (
                <>
                  {showUnmatch ? (
                    <>
                      <Button
                        txt="Unmatch [All]"
                        icon="refresh"
                        className="btn-purple"
                        onClick={onClickUnMatch}
                      />
                      &nbsp;
                    </>
                  ) : null}
                  {showConfirmBank ? (
                    <>
                      <ButtonDropdown
                        className="btn-info"
                        dropdownMenu="left"
                        text="Edit"
                        icon="icon-edit"
                        buttons={[
                          {
                            text: 'Bank',
                            icon: 'icon-university',
                            onClick: (e) => onClickSelectBank(),
                          },
                          {
                            text: 'Start Date',
                            icon: 'icon-calendar',
                            onClick: (e) => onClickSelectDate(e, 'start date'),
                          },
                          {
                            text: 'End Date',
                            icon: 'icon-calendar',
                            onClick: (e) => onClickSelectDate(e, 'end date'),
                          },
                        ]}
                      />
                      {/* <Button txt="Select Bank" icon="edit" className="btn-info" onClick={onClickSelectBank}></Button> */}
                      &nbsp;
                      <Button
                        txt="Confirm Bank [All]"
                        icon="check"
                        className="btn-success"
                        onClick={onClickConfirm}
                      />
                      &nbsp;
                      <Button
                        txt="Create FX Confirmation"
                        icon="plus"
                        className="btn-warning"
                        onClick={onCreateFxMatching}
                      />
                      &nbsp;
                    </>
                  ) : null}
                  {showWithdraw ? (
                    <>
                      <Button
                        txt="Withdraw [All]"
                        icon="refresh"
                        className="btn-warning"
                        onClick={onClickWithdraw}
                      />
                      &nbsp;
                    </>
                  ) : null}
                  {showBusinessPartner ? (
                    <>
                      <ButtonDropdown
                        className="btn-info"
                        dropdownMenu="left"
                        text="Edit"
                        icon="icon-edit"
                        buttons={[
                          {
                            text: 'Business Partner',
                            icon: 'icon-university',
                            onClick: (e) => onClickSelectBusinessPartner(),
                          },
                          {
                            text: 'Start Date',
                            icon: 'icon-calendar',
                            onClick: (e) => onClickSelectDate(e, 'start date'),
                          },
                          {
                            text: 'End Date',
                            icon: 'icon-calendar',
                            onClick: (e) => onClickSelectDate(e, 'end date'),
                          },
                        ]}
                      />
                      {/* <Button txt="Select Business Partner" icon="edit" className="btn-info" onClick={onClickSelectBusinessPartner}></Button> */}
                      &nbsp;
                      <Button
                        txt="Send email"
                        icon="envelope-o"
                        className="btn-purple"
                        onClick={onClickSendEmail}
                      />
                      &nbsp;
                      <Button
                        txt="Export"
                        icon="credit-card-alt"
                        className="btn-default"
                        onClick={onClickExport}
                      />
                      &nbsp;
                    </>
                  ) : null}
                </>
              ) : null}
              <Button txt="Grouping" icon="refresh" className="btn-info" onClick={onClickGroup} />
            </div>
          </Col2>
        ) : null}

        <Table
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          ref={dataTableRef}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>Action</th>
              <th>Ungroup</th>
              <th>Transaction No.</th>
              <th>
                Bank
                <br />
                Code
              </th>
              <th>Bank Name</th>
              <th>Finance Group</th>
              <th>Business Unit</th>
              <th>
                Company
                <br />
                Code
              </th>
              <th>
                Company
                <br />
                Name
              </th>
              <th>Product Type</th>
              <th>Transaction Type</th>
              <th>Business Partner</th>
              <th>
                FWD
                <br />
                Contract No.
              </th>
              <th>Contract Date</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Forward Type</th>
              <th>
                SCG Buy
                <br />
                Currency
              </th>
              <th>
                SCG Sell
                <br />
                Currency
              </th>
              <th>Book Amount</th>
              <th>Spot Rate</th>
              <th>Base Rate</th>
              <th>Swap Rate (Base)</th>
              <th>Forward Rate</th>
              <th>Swap Rate</th>
              <th>Purpose</th>
              <th>Portfolio</th>
              <th>Assignment</th>
              <th>Purpose Detail</th>
              <th>Internal Ref. No.</th>
              <th>Request Type</th>
              <th>Contract Detail 1</th>
              <th>Contract Detail 2</th>
              <th>Contract Detail 3</th>
              <th>Contract Detail 4</th>
              <th>Dealer</th>
              <th>Department</th>
              <th>
                Status
                <br />
                (Matching/
                <br />
                Import/ Export)
              </th>
              <th>Note</th>
              <th>Note 2</th>
              <th>Status</th>
              <th>Updated Date</th>
              <th>Updated By</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Card textHeader="Summary Result" number="3" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID_SUM}
          option={optSum}
          value={summaryList}
        >
          <thead>
            <tr>
              <th>Transaction Type</th>
              <th>Currency</th>
              <th>Status</th>
              <th>Amount</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-md" ref={modalEditRef} modalFooter>
        <form onSubmit={onSubmitSelectBank} className="form-horizontal">
          <div className="text-center">
            {/* <span className="label label-info">{selectedResultCount} items</span> */}
            <h2 className="text-info">Change Bank</h2>
            <p>{selectedResultCount} items selected for select bank.</p>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-3">
              Bank<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-8">
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                notMultipleSelect2
                value={modalData.bankCode}
                onChange={(e) =>
                  onChangeInput({
                    target: e,
                  })
                }
                noValidateOption
                saveLocalState
                isAll
                isPoSo
                name="bankCode"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalBuisnessPartnerRef} modalFooter>
        <form onSubmit={onSubmitSelectBusinessPartner} className="form-horizontal">
          <div className="text-center">
            {/* <span className="label label-info">{selectedResultCount} items</span> */}
            <h2 className="text-info">Change Business Partner</h2>
            <p>{selectedResultCount} items selected for select business partner.</p>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Business Partner<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-7">
              <MasterDropdown
                masterType={MASTER_BANK_CODE}
                notMultipleSelect2
                value={modalData.bankCode}
                onChange={(e) =>
                  onChangeInput({
                    target: e,
                  })
                }
                noValidateOption
                saveLocalState
                // isAll={true}
                isPoSo
                filter={(m) => ~modalData.bankFilter.indexOf(m.bankCode)}
                name="bankCode"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal size="modal-md" ref={modalAlertRef} modalFooter>
        <form onSubmit={onSaveBusinessPartner} className="form-horizontal">
          <div className="text-center">
            <h2 className="text-info">Change Business Partner</h2>
            <br />
            <p>
              {selectedResultCount} items selected for select business partner is{' '}
              {modalData.bankName}.
            </p>
            <br />
            <p>
              <span className="text-success icon icon-check icon-1x" /> pass{' '}
              {modalData.successList.length} items selected.
            </p>
            <p>
              <span className="text-danger icon icon-close icon-1x" /> fail{' '}
              {modalData.errorList.length} items selected.
            </p>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        textHeader="Business Unit Matching"
        bgHeader="bg-primary"
        size="modal-md"
        ref={modalBuRef}
      >
        <Table
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID_BU}
          option={optBu}
          value={buMatching}
        >
          <thead>
            <tr>
              <th>Business Unit</th>
              <th>Currency</th>
              <th>Status</th>
              <th>Amount</th>
            </tr>
          </thead>
        </Table>
        <div className="row">
          <div className="col-sm-12 text-center">
            <Button txt="Close" className="btn-default" data-dismiss="modal" />
          </div>
        </div>
      </Modal>

      <Modal size="modal-md" ref={modalDateRef} modalFooter>
        <form onSubmit={onSaveSelectDate} className="form-horizontal">
          <div className="text-center">
            {/* <span className="label label-info">{selectedResultCount} items</span> */}
            <h2 className="text-info">Change Date</h2>
            <p>{selectedResultCount} items selected for select date.</p>
          </div>
          <br />
          {modalDate.Mode === 'start date' && (
            <div className="form-group">
              <label className="control-label col-md-3">
                Start Date<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <div className="col-md-8">
                <DatePicker
                  onChange={onChangeDateModal}
                  value={modalDate.StartDateStr}
                  required
                  name="StartDateStr"
                />
              </div>
            </div>
          )}
          {modalDate.Mode === 'end date' && (
            <div className="form-group">
              <label className="control-label col-md-3">
                End Date<span style={{ color: '#e64a19' }}>*</span>
              </label>
              <div className="col-md-8">
                <DatePicker
                  onChange={onChangeDateModal}
                  value={modalDate.EndDateStr2}
                  required
                  name="EndDateStr2"
                />
              </div>
            </div>
          )}
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <Modal
        textHeader="Create FX Confirmation"
        bgHeader="bg-primary"
        size="modal-xl"
        ref={modalCreateRef}
      >
        <form onSubmit={onSaveCreateFxConfirmation}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={(e) =>
                    onChangeCreateModal({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  value={modalCreate.FinanceGroupId}
                  name="FinanceGroupId"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit" required>
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={(e) =>
                    onChangeCreateModal({
                      target: e,
                    })
                  }
                  isChoose
                  notMultipleSelect2
                  notMultiple={false}
                  required
                  value={modalCreate.BusinessUnitId}
                  name="BusinessUnitId"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Company" required>
                {modalCreate.FinanceGroupId ? (
                  <MasterDropdown
                    masterType={MASTER_COMPANY}
                    noValidateOption
                    onChange={(e) =>
                      onChangeCreateModal({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    notMultiple={false}
                    value={modalCreate.CompanyCode}
                    name="CompanyCode"
                    required
                    saveLocalState
                    financeGroupId={modalCreate.FinanceGroupId}
                    businessUnitId={modalCreate.BusinessUnitId}
                  />
                ) : (
                  <MasterDropdownUI
                    isChoose
                    notMultipleSelect2
                    value={modalCreate.CompanyCode}
                    onChange={(e) =>
                      onChangeCreateModal({
                        target: e,
                      })
                    }
                    name="CompanyCode"
                    options={null}
                  />
                )}
              </FormGroup2>

              <FormGroup2 text="Bank" required>
                <MasterDropdown
                  masterType={MASTER_BANK_CODE}
                  noValidateOption
                  saveLocalState
                  onChange={(e) =>
                    onChangeCreateModal({
                      target: e,
                    })
                  }
                  notMultipleSelect2
                  notMultiple={false}
                  companyCode={modalCreate.CompanyCode}
                  value={modalCreate.BankCode || 'All'}
                  isPoSo
                  isAll
                  disabled
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Transaction Type" required>
                <input
                  className="form-control"
                  value={modalCreate.TransactionType2 || ''}
                  onChange={onChangeCreateModal}
                  name="TransactionType2"
                  disabled
                />
              </FormGroup2>

              <FormGroup2 text="Product Type">
                <MasterDropdownUI
                  value={modalCreate.ProductType}
                  onChange={onChangeCreateModal}
                  notMultiple
                  isChoose
                  name="ProductType"
                  options={['60A Foreign Exchange-Main', '60B Foreign Exchange-Special']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Currency" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  isChoose
                  notMultipleSelect2
                  value={modalCreate.Currency}
                  onChange={(e) =>
                    onChangeCreateModal({
                      target: e,
                    })
                  }
                  noValidateOption
                  required
                  disabled
                  name="Currency"
                />
              </FormGroup2>

              <FormGroup2 text="Amount" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  option={modalCreate.inputMaskAmount}
                  onChange={onChangeCreateModal}
                  value={modalCreate.Amount}
                  required
                  disabled
                  name="Amount"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2
                text="Internal Ref. No."
                maxLength={30}
                length={(modalCreate.InternalReference || '').length}
              >
                <input
                  className="form-control"
                  value={modalCreate.InternalReference || ''}
                  onChange={onChangeCreateModal}
                  name="InternalReference"
                  maxLength={30}
                />
              </FormGroup2>

              <FormGroup2 text="Contract Date" required>
                <DatePicker
                  value={modalCreate.ContractDateStr}
                  onChange={onChangeCreateModal}
                  name="ContractDateStr"
                  required
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Start Date" required>
                <DatePicker
                  value={modalCreate.StartDateStr}
                  onChange={onChangeCreateModal}
                  name="StartDateStr"
                  required
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>

              <FormGroup2 text="End Date" required>
                <DatePicker
                  value={modalCreate.EndDateStr}
                  onChange={onChangeCreateModal}
                  name="EndDateStr"
                  required
                  option={{
                    // startDate: "d",
                    daysOfWeekDisabled: '0,6',
                    todayHighlight: true,
                  }}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Purpose">
                <MasterDropdownUI
                  value={modalCreate.Gvc}
                  onChange={onChangeCreateModal}
                  notMultiple
                  isChoose
                  name="Gvc"
                  options={
                    modalCreate.TransactionType === 'Sale Document'
                      ? [
                          'Trade AR',
                          'Project',
                          'ST Loan',
                          'LT Loan & Swap',
                          'Dividend & Capital Injection',
                          'Other',
                        ]
                      : [
                          'Trade AP',
                          'Project',
                          'ST Loan',
                          'LT Loan & Swap',
                          'Dividend & Capital Injection',
                          'Other',
                        ]
                  }
                />
              </FormGroup2>

              <FormGroup2
                text="Purpose Detail"
                maxLength={2000}
                length={(modalCreate.Characteristic || '').length}
              >
                <textarea
                  className="form-control"
                  rows="2"
                  value={modalCreate.Characteristic || ''}
                  onChange={onChangeCreateModal}
                  maxLength={50}
                  name="Characteristic"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Note 1" maxLength={2000} length={(modalCreate.Note1 || '').length}>
                <textarea
                  className="form-control"
                  rows="2"
                  value={modalCreate.Note1 || ''}
                  onChange={onChangeCreateModal}
                  maxLength={2000}
                  name="Note1"
                />
              </FormGroup2>
            </Col2>
          </Col2>
          <br />
          <div>
            <center>
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Confirm Bank"
        textContent={`confirm bank between ${criteria.EntryDateStr}`}
        modalRef={modalConfirmBankRef}
        type="success"
        icon="icon-check"
        showFail
        noShowCount
        onClick={onSubmitConfirm}
      />

      <ModalFunction
        textHeader="Withdraw"
        textContent={`withdraw between ${criteria.EntryDateStr}`}
        modalRef={modalWithdrawRef}
        type="warning"
        icon="icon-refresh"
        showFail
        noShowCount
        onClick={onSubmitConfirm}
      />

      <ModalFunction
        textHeader="Unmatch"
        textContent={`unmatch between ${criteria.EntryDateStr}`}
        modalRef={modalUnmatchRef}
        type="warning"
        icon="icon-refresh"
        showFail
        noShowCount
        onClick={onSubmitConfirm}
      />

      <ModalFunction
        textHeader="Grouping"
        textContent={`grouping total amount is ${toFixed(modalGroup.Amount, modalGroup.CurrencyDigit)}`}
        modalRef={modalGroupRef}
        type="info"
        icon="icon-refresh"
        showFail
        noShowCount
        onClick={onSaveGrouping}
      />

      <ModalFunction
        textHeader="Inactive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        noSelect
        onClick={onSubmitConfirm}
      />

      <ModalFunction
        textHeader="Auto Grouping"
        textContent="auto grouping"
        modalRef={modalAutoGroupRef}
        type="info"
        icon="icon-refresh"
        showFail
        noShowCount
        onClick={onSaveAutoGrouping}
      />

      <ModalFunction
        textHeader="Ungroup"
        textContent="ungroup"
        modalRef={modalUngroupRef}
        type="warning"
        icon="icon-refresh"
        noSelect
        onClick={onSubmitConfirm}
      />

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        noSelect
        onClick={onSubmitConfirm}
      />

      <ModalFunction
        textHeader="Send email"
        textContent="send email to bank"
        modalRef={modalEmailRef}
        type="info"
        icon="icon-credit-card-alt"
        selectedResultCount={selectedResultCount}
        selectedLength={selectedResultCount}
        onClick={onSubmitConfirm}
      />
    </div>
  );
};
