import qs from 'querystring';
import { connect } from 'react-redux';
import React, { Component } from 'react';

import AutoTransfer from './AutoTransfer';
import { addAlert } from '../../reducers/layout/action';
import {
  clearResult,
  searchAutoTransfer,
  submitAutoTransfer,
  setAllTransferMethod,
  setSelectedAllAutoTransfer,
  setValueSearchAutoTransfer,
} from '../../reducers/autoTransfer/action';

class AutoTransferContainer extends Component {
  state = {
    criteria: {
      BusinessUnitId: [''],
      ValueDateStr: '',
      Currency: '',
      FinanceGroup: '',
      Companys: [],
    },

    isCheckAll: false,
    transferMethodAll: '',
    isMillion: false,
  };

  componentDidMount() {
    const queryString = qs.parse((this.props.routeProp.location.search || '').substr(1));
    if (!queryString) return;
    this.setState(
      (state) => ({
        criteria: {
          ...state.criteria,
          ValueDateStr: queryString.v || '',
          FinanceGroup: queryString.f || '',
          Companys: queryString.com ? [queryString.com] : [],
          Currency: queryString.cur ? queryString.cur.toUpperCase() : '',
        },
      }),
      () => {
        if (queryString.p) {
          // auto progress
          this.onClickProcessHandler({ preventDefault: () => {} });
        }
      }
    );
  }

  onChangeHeadler = (e) => {
    if (e.target.name === 'FinanceGroup') {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.target.name]: e.target.value,
          Companys: [],
        },
      });
    } else
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.target.name]: e.target.value,
        },
      });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickProcessHandler = (e) => {
    e.preventDefault();

    this.props.searchAutoTransfer(this.state.criteria).then((response) => {
      if (!response.error)
        this.setState({
          isCheckAll: false,
        });
    });
  };

  onChangeCheckAll = (e) => {
    const { checked } = e.target;
    this.props.setSelectedAllAutoTransfer(checked);
    this.setState({
      isCheckAll: checked,
    });
  };

  onChangeTransferMethodHeader = (e) => {
    const { value } = e.target;
    this.setState(
      {
        transferMethodAll: value,
      },
      () => {
        this.props.setAllTransferMethod(value);
      }
    );
  };

  onChangeInputTable = (e, index) => {
    let { name, value, checked, type } = e.target;
    if (this.state.isMillion && name.toLowerCase().includes('amount') && value && !isNaN(value)) {
      value = Number(value) * 1000000;
    }
    this.props.setValueSearchAutoTransfer(name, type === 'checkbox' ? checked : value, index);
  };

  onClickSaveHandler = (e, index) => {
    const selectedData = this.props.searchResult.filter((f) => f.isChecked);

    for (let i = 0; i < selectedData.length; i++) {
      const r = selectedData[i];

      if (parseFloat(r.amount || 0) <= 0) {
        this.props.addAlert({
          title: 'Error',
          body: 'Transfer amount must more than zero.',
          type: 'error',
        });
        return;
      }

      if (parseFloat(r.applicantFeeAmount || 0) < 0) {
        this.props.addAlert({
          title: 'Error',
          body: 'Applicant fee amount must more than or equal zero.',
          type: 'error',
        });
        return;
      }

      if (parseFloat(r.beneficiaryFeeAmount || 0) < 0) {
        this.props.addAlert({
          title: 'Error',
          body: 'Beneficiary fee amount must more than or equal zero.',
          type: 'error',
        });
        return;
      }
    }

    this.props.submitAutoTransfer(selectedData, this.state.criteria).then((response) => {
      if (!response.error) this.props.clearResult();
      this.props.searchAutoTransfer(this.state.criteria).then((response) => {
        if (!response.error)
          this.setState({
            isCheckAll: false,
          });
      });
    });
  };

  componentDidUpdate(prevProps, prevState) {
    // console.log('finish')
  }

  onChangeToggleMillion = (e) => {
    const { name, type, checked } = e.target;
    this.setState({
      isMillion: checked,
    });
  };

  render() {
    const props = {
      onChange: this.onChangeHeadler,
      onChangeSelect2: this.onChangeSelect2Handler,
      onClickProcess: this.onClickProcessHandler,

      onChangeTransferMethodHeader: this.onChangeTransferMethodHeader,
      onChangeCheckAll: this.onChangeCheckAll,
      onChangeInputTable: this.onChangeInputTable,
      onClickSave: this.onClickSaveHandler,

      results: this.props.searchResult,
      onChangeToggleMillion: this.onChangeToggleMillion,
    };

    return <AutoTransfer {...props} {...this.state} />;
  }
}

export default connect(
  (state) => ({
    ...state.autoTransfer,
  }),
  {
    searchAutoTransfer,
    submitAutoTransfer,
    setSelectedAllAutoTransfer,
    setAllTransferMethod,
    setValueSearchAutoTransfer,
    addAlert,
    clearResult,
  }
)(AutoTransferContainer);
