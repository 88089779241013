import {
  RECEIVE_MAINTAIN_EFORM,
  RECEIVE_GET_EFORM_GROUP_ID,
  RECEIVE_SEARCH_EFORM_DETAIL,
  RECEIVE_GET_EFORM_DEPARTMENT,
  RECEIVE_SEARCH_EFORM_GROUPING,
  UPDATE_SEARCH_EFORM_DETAIL_RESULT,
  UPDATE_SEARCH_EFORM_GROUPING_RESULT,
} from './action';

const init = {
  eformGroupingResult: [],
  eformUnGroupingResult: [],
  eformDetailResult: [],
  searchResult: [],

  resultGroupings: [],
  resultPercentRules: [],
  resultRoundingRules: [],
  resultForwardDuration: [],

  masterDepartment: [],
  masterGroupId: [],
};

export default (state = init, action) => {
  switch (action.type) {
    case RECEIVE_GET_EFORM_GROUP_ID:
      return {
        ...state,
        masterGroupId: action.payload,
      };
    case RECEIVE_GET_EFORM_DEPARTMENT:
      return {
        ...state,
        masterDepartment: action.payload,
      };
    case RECEIVE_MAINTAIN_EFORM:
      return {
        ...state,
        resultGroupings: action.payload.eformGrouping,
        resultPercentRules: action.payload.eformPercent,
        resultRoundingRules: action.payload.eformRounding,
        resultForwardDuration: action.payload.eformDuration,
      };
    case UPDATE_SEARCH_EFORM_DETAIL_RESULT: {
      return {
        ...state,
        eformDetailResult: state.eformDetailResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) {
              return m;
            }
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    }
    case RECEIVE_SEARCH_EFORM_DETAIL:
      return {
        ...state,
        eformDetailResult: action.payload,
      };
    case RECEIVE_SEARCH_EFORM_GROUPING:
      return {
        ...state,
        searchResult: action.payload,
      };
    case UPDATE_SEARCH_EFORM_GROUPING_RESULT: {
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) {
              return m;
            }
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    }
    default:
      return state;
  }
};
