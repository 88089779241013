import React, { Component } from 'react';

import Modal from './Modal';

export default class ModalAlert extends Component {
  state = {};

  open = (option) => {
    this.setState(
      {
        buttons: [],
        ...option,
      },
      () => {
        this.modal.open();
      }
    );
  };

  close = () => {
    if (this.props.onClose) {
      this.modal.close();
      this.props.onClose();
    } else this.modal.close();
  };

  componentDidMount() {
    const { open, type, body, title, buttons } = this.props;
    if (open)
      this.setState(
        {
          type,
          body,
          title,
          buttons,
        },
        () => {
          this.modal.open();
        }
      );
  }

  textColor = {
    success: 'text-success',
    warning: 'text-warning',
    info: 'text-info',
    danger: 'text-danger',
    error: 'text-danger',
    wating: 'text-coral',
  };

  btnColor = {
    success: 'btn btn-success',
    warning: 'btn btn-warning',
    info: 'btn btn-info',
    danger: 'btn btn-danger',
    error: 'btn btn-danger',
    wating: 'btn btn-coral',
  };

  icon = {
    success: <span className="text-success icon icon-check icon-5x" />,
    warning: <span className="text-warning icon icon-exclamation-triangle icon-5x" />,
    info: <span className="text-info icon icon-info-circle icon-5x" />,
    danger: <span className="text-danger icon icon-times-circle icon-5x" />,
    error: <span className="text-danger icon icon-times-circle icon-5x" />,
    wating: <span className="text-coral icon icon-clock-o icon-5x" />,
  };

  render() {
    const { type = 'success', body = '', title = 'Success', buttons = [] } = this.state;
    return (
      <div>
        <Modal
          zIndex={1051}
          onClose={this.props.onClose}
          modalFooter
          /* bg={`bg-${type === "error" ? "danger" : type}`} */ ref={(el) => (this.modal = el)}
        >
          <div className="text-center">
            {this.icon[type] || this.icon.success}
            <h3 className={this.textColor[type]}>{title}</h3>
            <p>{body}</p>
            <div className="m-t-lg">
              {buttons.length === 0 ? (
                <button onClick={this.close} className={this.btnColor[type]} type="button">
                  Continue
                </button>
              ) : (
                buttons.map((m, idx) => <span key={idx}>{m}&nbsp;</span>)
              )}
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}
