import { connect } from 'react-redux';
import React, { Component } from 'react';

import Select2 from './Select2';
import MultipleSelect from './MultipleSelect';
import { getMaster, MASTER_BUSINESS_AREA } from '../reducers/master/action';

class MasterDropdown extends Component {
  state = {
    data: [],
    isUpdateData: false,
  };

  provider;

  promiseFetchData;

  unmounted = false;

  constructor(props) {
    super(props);
    const { masterType } = props;
    this.provider = getMaster[masterType];
  }

  componentDidMount() {
    const { state } = this.provider;
    if (!this.props[state].isFetch || this.props.saveLocalState) this.fetchData();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.additionOptions !== nextProps.additionOptions) return true;
    // if sigle select and update value from contrainer
    if (this.props.notMultiple || this.props.notMultipleSelect2) {
      if (this.props.notMultiple && this.select.value !== nextProps.value) return true;
      if (
        this.props.notMultipleSelect2 &&
        this.select2 &&
        this.select2.$select2.val() !== nextProps.value
      )
        return true;
    }
    if (this.props.value !== nextProps.value) return true;

    const { state } = this.provider;
    // check filter props
    if (this.checkNeedUpdateProps(this.props, nextProps)) return true;

    // check data
    if (this.props.saveLocalState) {
      if (nextState.data !== this.state.data) {
        return true;
      }
    } else if (this.props[state].datas !== nextProps[state].datas) {
      return true;
    }

    if (this.props.disabled !== nextProps.disabled || this.props.required !== nextProps.required)
      return true;
    // check filter fn
    if (this.props.updateFilterFn && this.props.filter !== nextProps.filter) return true;

    return false;
  }

  checkNeedUpdateProps(prevProps, nextProps) {
    let { checkForFetchData } = this.provider;
    checkForFetchData = nextProps.checkForFetchData || checkForFetchData;

    if (checkForFetchData && checkForFetchData(prevProps, nextProps)) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.checkNeedUpdateProps(prevProps, this.props)) {
      this.fetchData();
    }

    // when data change
    if (this.multipleSelect) this.multipleSelect.$multiSelect.multiSelect('refresh');
  }

  getData() {
    if (this.props.saveLocalState) return this.state.data;
    return this.props[this.provider.state].datas;
  }

  fetchData() {
    const { action, getPropForFetchData } = this.provider;
    // is save to redux state
    const { saveLocalState } = this.props;
    const criteria = getPropForFetchData ? getPropForFetchData(this.props) : null;

    const currentPromise = this.props.dispatch(action(criteria, !saveLocalState));
    this.promiseFetchData = currentPromise;
    currentPromise.then((response) => {
      if (this.unmounted) return;
      if (currentPromise !== this.promiseFetchData) return;
      if (saveLocalState && !response.error) {
        this.setState({
          data: response.payload,
        });
      }

      if (this.props.masterType === MASTER_BUSINESS_AREA) {
        // set defalt option
        const findDefault = (response.payload || []).filter((f) => f.isDefault);
        if (!findDefault.length) return;
        this.onChange({
          target: {
            type: 'select',
            label: findDefault[0].businessAreaName,
            value: findDefault[0].businessArea,
            name: this.props.name,
          },
        });
      }
    });
  }

  onChange = (e) => {
    const { name, value, type, label } = e.target;
    const target = {
      name,
      value,
      type,
      datas: this.getData(),
    };
    if (this.props.notMultiple)
      this.props.onChange({
        target: {
          ...target,
          label: window.$(e.target).find('option:selected').text(),
        },
      });
    else
      this.props.onChange({
        ...target,
        label,
      });
  };

  render() {
    let {
      filter,
      isMultileSelect,
      notMultiple,
      isChoose,
      isAll,
      className,
      value,
      customeKey,
      customeValue,
      customeLabel,
      required,
      disabled,
      notMultipleSelect2,
      noValidateOption,
      style,
      isSearch,
      isPoSo,
    } = this.props;
    const { name } = this.provider;

    customeKey = this.provider.customeKey || customeKey;
    let inputLabel;
    let inputValue;
    let inputValue2;
    if (typeof name === 'object') {
      inputLabel = name.label;
      inputValue = name.value;
      inputValue2 = name.value;
    } else {
      inputLabel = `${name}Name`;
      inputValue = `${name}Id`;
      inputValue2 = `${name}Code`;
    }

    let choose = null;
    if (isChoose) {
      choose = (
        <option key="" value="">
          --Choose--
        </option>
      );
    } else if (isAll && !notMultipleSelect2) {
      choose = (
        <option key="" value="">
          --All--
        </option>
      );
    } else if (isAll && notMultipleSelect2) {
      choose = (
        <option key="All" value="All">
          --All--
        </option>
      );
    } else if (isSearch && isPoSo) {
      choose = (
        <option key="Any" value="Any">
          Any
        </option>
      );
    }

    let data = this.getData();
    if (this.props.additionOptions) data = [...data, ...this.props.additionOptions];
    const options = data
      .filter((f) => {
        if (filter) return filter(f, this.props);
        return true;
      })
      .map((m, i) => {
        const val = customeValue
          ? customeValue(m)
          : typeof inputValue === 'function'
            ? inputValue(m)
            : m[inputValue] || m[inputValue2];
        const label = customeLabel
          ? customeLabel(m)
          : typeof inputLabel === 'function'
            ? inputLabel(m)
            : m[inputLabel];
        return (
          <option key={customeKey ? customeKey(m, i) : val} value={val}>
            {label}
          </option>
        );
      });
    // render
    if (notMultiple) {
      return (
        <select
          name={this.props.name}
          ref={(e) => (this.select = e)}
          className={className || 'form-control'}
          onChange={this.onChange}
          value={value || ''}
          required={required}
          disabled={disabled}
        >
          {choose}
          {options}
        </select>
      );
    }
    if (isMultileSelect) {
      return (
        <div>
          <MultipleSelect
            name={this.props.name}
            onChange={(e) => this.onChange({ target: e })}
            value={value}
            ref={(e) => (this.multipleSelect = e)}
            required={required}
            disabled={disabled}
          >
            {options}
          </MultipleSelect>
        </div>
      );
    }
    return (
      <div>
        <Select2
          noValidateOption={noValidateOption}
          notMultiple={notMultipleSelect2}
          isChoose={isChoose}
          name={this.props.name}
          onChange={(e) => this.onChange({ target: e })}
          value={value}
          ref={(e) => (this.select2 = e)}
          required={required}
          disabled={disabled}
          style={style}
        >
          {choose}
          {options}
        </Select2>
      </div>
    );
  }
}

export default connect((state) => ({
  ...state.master,
}))(MasterDropdown);
