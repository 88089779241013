import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_PAYMENT_METHOD_CRITERIA = 'EDIT_MASTER_PAYMENT_METHOD_CRITERIA';
export const CLEAR_MASTER_PAYMENT_METHOD_CRITERIA = 'CLEAR_MASTER_PAYMENT_METHOD_CRITERIA';

export const REQUEST_SEARCH_MASTER_PAYMENT_METHOD = '@@request/SEARCH_MASTER_PAYMENT_METHOD';
export const RECEIVE_SEARCH_MASTER_PAYMENT_METHOD = '@@receive/SEARCH_MASTER_PAYMENT_METHOD';
export const FAIL_SEARCH_MASTER_PAYMENT_METHOD = '@@fail/SEARCH_MASTER_PAYMENT_METHOD';

export const REQUEST_SAVE_MASTER_PAYMENT_METHOD = '@@request/SAVE_MASTER_PAYMENT_METHOD';
export const RECEIVE_SAVE_MASTER_PAYMENT_METHOD =
  '@@receive-The record was successfully saved/SAVE_MASTER_PAYMENT_METHOD';
export const FAIL_SAVE_MASTER_PAYMENT_METHOD = '@@fail/SAVE_MASTER_PAYMENT_METHOD';

export const saveMasterPaymentMethod = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/SaveMasterPaymentMethod`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_SAVE_MASTER_PAYMENT_METHOD,
      RECEIVE_SAVE_MASTER_PAYMENT_METHOD,
      FAIL_SAVE_MASTER_PAYMENT_METHOD,
    ],
  },
});

export const searchMasterPaymentMethod = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/GetMasterPaymentMethod', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_PAYMENT_METHOD,
      RECEIVE_SEARCH_MASTER_PAYMENT_METHOD,
      FAIL_SEARCH_MASTER_PAYMENT_METHOD,
    ],
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_PAYMENT_METHOD_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_PAYMENT_METHOD_CRITERIA,
  payload: {
    name,
    value,
  },
});
