import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import InputMask from '../../../common/InputMask';
import FormGroup2 from '../../../common/FormGroup2';
import DatePicker from '../../../common/DatePicker';
import MasterDropdown from '../../../common/MasterDropdown';
import MasterDropdownUI from '../../../common/MasterDropdownUI';
import {
  GET_LOAN_REF,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BUSINESS_AREA,
} from '../../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
export default ({ state, onChangeInputData, formRef }) => {
  const noEdit = state.mode === 'edit';
  const disabled = false;
  const required = true;
  return (
    <div>
      <form ref={formRef} name="structure">
        <Card textHeader="Detail" cardActions={['toggler']}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Derivative Type" required>
                <MasterDropdownUI
                  onChange={onChangeInputData}
                  isChoose
                  notMultiple
                  required={required}
                  disabled={state.resultSchedule.length > 0}
                  value={state.DerivativeType}
                  name="DerivativeType"
                  options={['POS', 'IRS', 'CCS']}
                />
              </FormGroup2>

              <FormGroup2 text="Business Partner" required>
                <MasterDropdown
                  key="External"
                  masterType={MASTER_BANK_CODE}
                  noValidateOption={noEdit}
                  saveLocalState
                  onChange={(e) =>
                    onChangeInputData({
                      target: e,
                    })
                  }
                  isChoose
                  isGetAll
                  notMultipleSelect2
                  notMultiple={false}
                  required={required}
                  customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                  // companyCode={state.CompanyCode}
                  disabled={disabled}
                  value={state.Partner}
                  name="Partner"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="External Ref." required>
                <input
                  type="text"
                  className="form-control"
                  name="ExternalRef"
                  value={state.ExternalRef || ''}
                  onChange={onChangeInputData}
                  required
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Contract Date" required>
                <DatePicker
                  onChange={onChangeInputData}
                  option={{
                    // daysOfWeekDisabled: "0,6",
                    todayHighlight: true,
                  }}
                  required={required}
                  disabled={noEdit}
                  value={state.ContractDateStr}
                  name="ContractDateStr"
                />
              </FormGroup2>

              <FormGroup2 text="Term Start" required>
                <DatePicker
                  onChange={onChangeInputData}
                  option={{
                    // daysOfWeekDisabled: "0,6",
                    todayHighlight: true,
                  }}
                  required={required}
                  disabled={noEdit}
                  value={state.TermStartStr}
                  name="TermStartStr"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Term End" required>
                <DatePicker
                  onChange={onChangeInputData}
                  option={{
                    // daysOfWeekDisabled: "0,6",
                    todayHighlight: true,
                  }}
                  required={required}
                  // disabled={disabled}
                  value={state.TermEndStr}
                  name="TermEndStr"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Loan Ref" required>
                {state.CompanyCode ? (
                  <MasterDropdown
                    masterType={GET_LOAN_REF}
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    notMultiple={false}
                    // required={required}
                    required={false}
                    disabled={false}
                    value={state.LoanRef}
                    companyCode={state.CompanyCode}
                    saveLocalState={false}
                    noValidateOption
                    name="LoanRef"
                  />
                ) : (
                  <MasterDropdownUI
                    onChange={(e) =>
                      onChangeInputData({
                        target: e,
                      })
                    }
                    isChoose
                    notMultipleSelect2
                    notMultiple={false}
                    // required={required}
                    required
                    disabled={false}
                    value={state.LoanRef}
                    name="LoanRef"
                  />
                )}
              </FormGroup2>
              <FormGroup2 text="Business Area" required={state.BusinessArea}>
                <MasterDropdown
                  masterType={MASTER_BUSINESS_AREA}
                  noValidateOption={noEdit}
                  onChange={onChangeInputData}
                  companyCode={state.CompanyCode}
                  notMultiple
                  isChoose={!state.BusinessArea}
                  required={state.BusinessArea}
                  value={state.BusinessArea}
                  name="BusinessArea"
                  disabled={disabled}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Swap Desc." required>
                <input
                  type="text"
                  className="form-control"
                  name="SwapDesc"
                  value={state.SwapDesc || ''}
                  onChange={onChangeInputData}
                  required
                />
              </FormGroup2>
            </Col2>
          </Col2>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Ref. Outstanding" required>
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={
                    state.inputMaskLoanRefOpt || {
                      prefix: '',
                      digits: 3,
                    }
                  }
                  required={required}
                  disabled={disabled}
                  value={state.RefOutstanding}
                  name="RefOutstanding"
                />
              </FormGroup2>
              <FormGroup2 text="Loan Currency">
                <input className="form-control" disabled value={state.LoanRefCurrency || ''} />
              </FormGroup2>
            </Col2>
          </Col2>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Swap Outstanding">
                <InputMask
                  className="form-control"
                  format="currency"
                  onChange={onChangeInputData}
                  option={
                    state.inputMaskOpt || {
                      prefix: '',
                      digits: 3,
                    }
                  }
                  required={required}
                  disabled={disabled}
                  value={state.SwapOutstanding}
                  name="SwapOutstanding"
                />
              </FormGroup2>
              <FormGroup2 text="Swap Currency" required>
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  noValidateOption
                  onChange={onChangeInputData}
                  companyCode={state.SwapCurrency}
                  notMultiple
                  isChoose
                  required={required}
                  value={state.SwapCurrency}
                  name="SwapCurrency"
                  disabled={disabled}
                />
              </FormGroup2>
            </Col2>
          </Col2>
        </Card>
      </form>
    </div>
  );
};
