import { connect } from 'react-redux';
import React, { Component } from 'react';

import FxMatching from './FxMatching';
import ImportExcel from './ImportExcel';
import { toLower } from '../../../common/helpper';
import { addAlert } from '../../../reducers/layout/action';
import {
  saveFxMatching,
  getMatchingDate,
  getMatchingTime,
} from '../../../reducers/fxConfirmation/action';
import {
  getBankPoso,
  getReportToBank,
  sendEmaileportToBank,
  getExportReportToBank,
  uploadViewReportToBank,
  uploadSaveReportToBank,
  fetchEmailReportToBank,
} from '../../../reducers/reportToBank/action';

const { moment } = window;

const initCriteria = {
  BankCode: [''],
  MatchingDateStr: '',
  EntryDateStr: '',
  MatchingTime: '',
};

const initModal = {
  BankCode: '',
  Bank: '',
  BuyCurrency: '',
  EndDateStr: '',
  TransactionType: '',
  StatusFile: '',
  Type: '',
  SwapRate: '',
  SpotRate: '',
  Mode: '',
  idListTable: [],
};

class FxMatchingContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    bankTab: [],
    bankList: [],
    modalData: {
      ...initModal,
    },
    transactionNo: '',
    isSearch: false,
    matchingDate: [],
    matchingTime: [],
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    this.props.getBankPoso({ IsPoSo: true, IsAll: true }).then((res) => {
      this.setState({
        ...this.state,
        bankList: res.payload,
      });
    });
  }

  componentWillUnmount() {}

  // search
  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });

    if (name === 'MatchingDateStr') {
      this.props.getMatchingTime({ Type: 'report', MatchingDateStr: value }).then((res) => {
        const _matchingTime = res.payload || [];
        const defaultTime = _matchingTime.length ? _matchingTime[0].time || '' : '';

        this.props
          .getMatchingDate({
            Type: 'report',
            MatchingDateStr: value,
            MatchingTime: defaultTime,
          })
          .then((res2) => {
            const _matchingDate = res2.payload || [];
            const defaultDate = _matchingDate.length ? _matchingDate[0].dateFromTo || '' : '';
            this.setState({
              ...this.state,
              matchingTime: _matchingTime,
              matchingDate: _matchingDate,
              criteria: {
                ...this.state.criteria,
                EntryDateStr: defaultDate,
                MatchingTime: defaultTime,
              },
            });
          });
      });
    } else if (name === 'MatchingTime') {
      this.props
        .getMatchingDate({
          Type: null,
          MatchingDateStr: this.state.criteria.MatchingDateStr,
          MatchingTime: value,
        })
        .then((res2) => {
          const _matchingDate = res2.payload || [];
          const defaultDate = _matchingDate.length ? _matchingDate[0].dateFromTo || '' : '';
          this.setState({
            ...this.state,
            matchingDate: _matchingDate,
            criteria: {
              ...this.state.criteria,
              EntryDateStr: defaultDate,
            },
          });
        });
    }
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.getReportToBank({ ...this.state.criteria }).then((res) => {
      this.setResult(res.payload);
      this.setState({
        ...this.state,
        isSearch: true,
      });
    });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
    });
  };
  // end search

  setResult = (data) => {
    let bankList = [];
    if (this.state.criteria.BankCode[0] === '') {
      bankList = this.state.bankList;
    } else {
      bankList = this.state.bankList.filter(
        (f) => ~this.state.criteria.BankCode.indexOf(f.bankCode)
      );
    }

    const { matchingMain } = data;

    let statusBbl = '';
    let statusKbank = '';
    let statusScb = '';
    let statusSmbc = '';
    if (matchingMain.length) {
      const main = matchingMain[0];
      statusBbl = main.statusSwapBbl;
      statusKbank = main.statusSwapKbank;
      statusScb = main.statusSwapScb;
      statusSmbc = main.statusSwapSmbc;
    }

    let result = [];
    bankList.map((bank) => {
      const d = {};
      d.Bank = bank.bankAbbreviate;
      d.BankCode = bank.bankCode;
      d.StatusSwap = '';
      if (d.BankCode === 'BKKBTH') {
        d.StatusSwap = statusBbl;
      } else if (d.BankCode === 'KASITH') {
        d.StatusSwap = statusKbank;
      } else if (d.BankCode === 'SICOTH') {
        d.StatusSwap = statusScb;
      } else if (d.BankCode === 'SMBCTH') {
        d.StatusSwap = statusSmbc;
      }

      const CurrencyList = [];

      const currencyFilter = matchingMain.filter(
        (f) => f.bankCode === bank.bankCode || !f.bankCode
      );
      let currencyMap = currencyFilter.map((m) => m.currency);
      currencyMap = [...new Set(currencyMap)];
      currencyMap = currencyMap.sort();
      currencyMap.map((currency) => {
        const { currencyDigit } = currencyFilter.find((f) => f.currency === currency);
        CurrencyList.push({
          Currency: currency,
          CurrencyDigit: currencyDigit,
        });
      });
      d.CurrencyList = CurrencyList;
      result.push(d);
    });

    result = this.setRowMatching(data, result);

    let transactionNo = '';
    if (matchingMain.length) {
      transactionNo = matchingMain[0].transactionNo;
    }

    this.setState({
      ...this.state,
      bankTab: result,
      transactionNo,
    });
  };

  setRowMatching(data, result) {
    const { posoSwap, fxMatching, posoSpot } = data;
    result.map((r) => {
      const bank = r.BankCode;
      r.CurrencyList.map((c) => {
        const currency = c.Currency;
        let Import = [];
        let Export = [];

        // end date
        const fxMatchingFilter = [];
        fxMatching.map((m) => {
          if (m.currency === currency) {
            if (m.businessPartnerCode === bank) {
              fxMatchingFilter.push(m);
            } else if (m.bankCode === bank || !m.bankCode) {
              fxMatchingFilter.push(m);
            }
          }
        });
        // let fxMatchingFilter = fxMatching.filter(f => (f.bankCode === bank|| !f.bankCode) && f.currency === currency)
        let endDateMap = fxMatchingFilter.map((m) => m.endDateStr);
        endDateMap = [...new Set(endDateMap)];
        endDateMap = endDateMap.sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY'));
        const header = ['', '', '', '', ...endDateMap];

        const SwapBuyRow = ['', '', 'Bank Quota', 'Swap'];
        const SwapSellRow = ['', '', 'Bank Quota', 'Swap'];

        const MatchingImport = ['Matching', 'Import', 0, ''];
        const NetImport = ['', 'Net Import', 0, ''];
        const MatchingExport = ['Matching', 'Export', 0, ''];
        const NetExport = ['', 'Net Export', 0, ''];

        let totalMatImport = 0;
        let totalImport = 0;
        let totalMatExport = 0;
        let totalExport = 0;

        let i = 4;
        endDateMap.map((endDate) => {
          const swaplist = posoSwap.filter(
            (f) => f.endDateStr === endDate && f.bankCode === bank && f.currency === currency
          );

          const swapBuy = (
            swaplist.find((f) => toLower(f.transactionType) === 'purchase document') || {
              swapRate: null,
            }
          ).swapRate;
          const swapSell = (
            swaplist.find((f) => toLower(f.transactionType) === 'sale document') || {
              swapRate: null,
            }
          ).swapRate;
          SwapBuyRow[i] = swapBuy;
          SwapSellRow[i] = swapSell;

          const matchingImport = fxMatchingFilter.filter(
            (f) =>
              toLower(f.statusFile) === 'matching' &&
              toLower(f.transactionType) === 'buy' &&
              f.endDateStr === endDate
          );
          const netImport = fxMatchingFilter.filter(
            (f) => toLower(f.statusFile) === 'import' && f.endDateStr === endDate
          );
          const matchingExport = fxMatchingFilter.filter(
            (f) =>
              toLower(f.statusFile) === 'matching' &&
              toLower(f.transactionType) === 'sell' &&
              f.endDateStr === endDate
          );
          const netExport = fxMatchingFilter.filter(
            (f) => toLower(f.statusFile) === 'export' && f.endDateStr === endDate
          );

          const calMatImport = this.calMatching(matchingImport, bank);
          const calImport = this.calMatching(netImport, bank);
          const calMatExport = this.calMatching(matchingExport, bank);
          const calExport = this.calMatching(netExport, bank);

          totalMatImport += calMatImport;
          totalImport += calImport;
          totalMatExport += calMatExport;
          totalExport += calExport;

          MatchingImport[i] = calMatImport;
          NetImport[i] = calImport;
          MatchingExport[i] = calMatExport;
          NetExport[i] = calExport;

          i++;
        });

        MatchingImport[2] = totalMatImport;
        NetImport[2] = totalImport;
        MatchingExport[2] = totalMatExport;
        NetExport[2] = totalExport;

        const spotMatchingImport = this.findSpot(posoSpot, bank, currency, 'matchingimport');
        const spotImport = this.findSpot(posoSpot, bank, currency, 'netimport');
        const spotMatchingExport = this.findSpot(posoSpot, bank, currency, 'matchingexport');
        const spotExport = this.findSpot(posoSpot, bank, currency, 'netexport');

        MatchingImport[3] = spotMatchingImport;
        NetImport[3] = spotImport;
        MatchingExport[3] = spotMatchingExport;
        NetExport[3] = spotExport;

        Import = [SwapBuyRow, MatchingImport, NetImport];
        Export = [SwapSellRow, MatchingExport, NetExport];

        c.Header = header;
        c.Import = Import;
        c.Export = Export;

        // let disMatImport = this.checkSpot(fxMatchingFilter.filter(f => toLower(f.statusFile) === 'matching' && toLower(f.transactionType) === 'buy'), bank)
        // let disImport = this.checkSpot(fxMatchingFilter.filter(f => toLower(f.statusFile) === 'import'), bank)
        // let disMatExport = this.checkSpot(fxMatchingFilter.filter(f => toLower(f.statusFile) === 'matching' && toLower(f.transactionType) === 'sell'), bank)
        // let disExport = this.checkSpot(fxMatchingFilter.filter(f => toLower(f.statusFile) === 'export'), bank)
        console.log(bank, currency);
        const disMatImport = this.checkSpotNew(
          Import[0],
          fxMatchingFilter.filter(
            (f) => toLower(f.statusFile) === 'matching' && toLower(f.transactionType) === 'buy'
          ),
          endDateMap
        );
        const disImport = this.checkSpotNew(
          Import[0],
          fxMatchingFilter.filter((f) => toLower(f.statusFile) === 'import'),
          endDateMap
        );
        const disMatExport = this.checkSpotNew(
          Export[0],
          fxMatchingFilter.filter(
            (f) => toLower(f.statusFile) === 'matching' && toLower(f.transactionType) === 'sell'
          ),
          endDateMap
        );
        const disExport = this.checkSpotNew(
          Export[0],
          fxMatchingFilter.filter((f) => toLower(f.statusFile) === 'export'),
          endDateMap
        );
        c.CheckSpotImport = [disMatImport, disImport];
        c.CheckSpotExport = [disMatExport, disExport];
        console.log('------------');
      });
    });
    return result;
  }

  findSpot(posoSpot, bank, currency, statusfile) {
    const find = posoSpot.find(
      (f) =>
        toLower(f.bankCode) === toLower(bank) &&
        toLower(f.currency) === toLower(currency) &&
        toLower(f.statusFile) === statusfile
    );
    if (find) {
      return find.spotRate;
    }
    return null;
  }

  calMatching(data, bankCode) {
    let total = 0;
    // data.map(m => total += m.amount)
    data.map((m) => {
      if (m.businessPartnerCode === bankCode) {
        total += m.amount;
      } else if (!m.businessPartnerCode) {
        total += m.amount;
      }
    });
    return total;
  }

  checkSpot(data, bankCode) {
    if (!data.length) return true;
    const d = data.filter((f) => !f.businessPartnerCode);
    if (d.length) {
      return true;
    }
    const n = data.filter((f) => f.businessPartnerCode === bankCode);
    if (n.length) {
      return false;
    }
    return true;
  }

  checkSpotNew(dataSwap, dataRow = [], endDateList = []) {
    if (!dataRow.length) {
      return true;
    }
    const newEndDate = [...new Set(dataRow.map((m) => m.endDateStr))];
    console.log(dataSwap, endDateList, newEndDate);
    const checkList = [...endDateList];
    newEndDate.map((e) => {
      checkList.map((m, i) => {
        if (e === m) {
          checkList[i] = 'check';
        }
      });
    });

    let canNotKeySpot = false;
    for (let i = 4; i < dataSwap.length; i++) {
      if (dataSwap[i] == null && checkList[i - 4] === 'check') {
        canNotKeySpot = true;
      }
    }
    console.log(checkList, canNotKeySpot);
    return canNotKeySpot;
  }

  setRowMatching2(data, result) {
    const { posoSwap, posoSpot, fxMatching } = data;
    result.map((r) => {
      const bank = r.BankCode;
      r.CurrencyList.map((c) => {
        const currency = c.Currency;
        let Import = [];
        let Export = [];

        // end end
        const endDateFilter = posoSwap.filter(
          (f) => f.bankCode === bank && f.currency === currency
        );
        let endDateMap = endDateFilter.map((m) => m.endDateStr);
        endDateMap = [...new Set(endDateMap)];
        endDateMap = endDateMap.sort((a, b) => moment(a, 'DD/MM/YYYY') - moment(b, 'DD/MM/YYYY'));
        const header = ['', '', '', '', ...endDateMap];

        // swap import
        const swapBuyList = posoSwap.filter(
          (f) =>
            toLower(f.transactionType) === 'purchase document' &&
            f.bankCode === bank &&
            f.currency === currency
        );
        const swapBuyMap = swapBuyList.map((m) => (m.swapRate == null ? '' : m.swapRate));
        const swapBuy = ['', '', 'Bank Quota', 'Swap', ...swapBuyMap];
        Import = [swapBuy];

        // swap export
        const swapSellList = posoSwap.filter(
          (f) =>
            toLower(f.transactionType) === 'sale document' &&
            f.bankCode === bank &&
            f.currency === currency
        );
        const swapSellMap = swapSellList.map((m) => (m.swapRate == null ? '' : m.swapRate));
        const swapSell = ['', '', 'Bank Quota', 'Swap', ...swapSellMap];
        Export = [swapSell];

        // matching import
        const fxMatchingImportList = fxMatching.filter(
          (f) =>
            toLower(f.statusFile) === 'matching' &&
            toLower(f.transactionType) === 'buy' &&
            f.currency === currency &&
            (f.bankCode === bank || !f.bankCode)
        );

        const spotMatchingImport = posoSpot.find(
          (f) => f.bankCode === bank && f.currency === currency && f.statusFile === 'MatchingImport'
        );

        Import.push(
          this.calFxMatcing('matchingImport', spotMatchingImport, endDateMap, fxMatchingImportList)
        );

        // net import
        const fxNetImportList = fxMatching.filter(
          (f) =>
            toLower(f.statusFile) === 'import' &&
            f.currency === currency &&
            (f.bankCode === bank || !f.bankCode)
        );

        const spotNetImport = posoSpot.find(
          (f) => f.bankCode === bank && f.currency === currency && f.statusFile === 'NetImport'
        );

        Import.push(this.calFxMatcing('netImport', spotNetImport, endDateMap, fxNetImportList));

        // matching export
        const fxMatchingExportList = fxMatching.filter(
          (f) =>
            toLower(f.statusFile) === 'matching' &&
            toLower(f.transactionType) === 'sell' &&
            f.currency === currency &&
            (f.bankCode === bank || !f.bankCode)
        );

        const spotMatchingExport = posoSpot.find(
          (f) => f.bankCode === bank && f.currency === currency && f.statusFile === 'MatchingExport'
        );

        Export.push(
          this.calFxMatcing('matchingExport', spotMatchingExport, endDateMap, fxMatchingExportList)
        );

        // net export
        const fxNetExportList = fxMatching.filter(
          (f) =>
            toLower(f.statusFile) === 'export' &&
            f.currency === currency &&
            (f.bankCode === bank || !f.bankCode)
        );

        const spotNetExport = posoSpot.find(
          (f) => f.bankCode === bank && f.currency === currency && f.statusFile === 'NetExport'
        );

        Export.push(this.calFxMatcing('netExport', spotNetExport, endDateMap, fxNetExportList));

        c.Header = header;
        c.Import = Import;
        c.Export = Export;
      });
    });
    return result;
  }

  calFxMatcing(type, spot, endDate, data) {
    let totalAll = 0;

    let startDate = '';
    const columnEndDate = [];
    endDate.map((e) => {
      let total = 0;
      // let dataEndDate = data.filter(f => this.getBetweenDate(startDate, e, f.endDateStr))
      const dataEndDate = data.filter((f) => f.endDateStr === e);
      dataEndDate.map((d) => {
        total += d.amount;
      });

      columnEndDate.push(total);
      totalAll += total;

      startDate = e;
    });
    const { spotRate } = spot;

    let column = [];
    if (type === 'matchingImport')
      column = ['Matching', 'Import', totalAll, spotRate, ...columnEndDate];
    else if (type === 'netImport')
      column = ['', 'Net Import', totalAll, spotRate, ...columnEndDate];
    else if (type === 'matchingExport')
      column = ['Matching', 'Export', totalAll, spotRate, ...columnEndDate];
    else if (type === 'netExport')
      column = ['', 'Net Export', totalAll, spotRate, ...columnEndDate];

    return column;
  }

  getBetweenDate = (start, end, date) => {
    if (!start) {
      const endDate = moment(end, 'DD/MM/YYYY').add(1, 'days');
      const mDate = moment(date, 'DD/MM/YYYY');
      if (mDate.isBefore(endDate)) {
        return true;
      }
    } else {
      const startDate = moment(start, 'DD/MM/YYYY');
      const endDate = moment(end, 'DD/MM/YYYY').add(1, 'days');
      const mDate = moment(date, 'DD/MM/YYYY');
      if (moment(mDate).isBetween(startDate, endDate)) {
        return true;
      }
    }
    return false;
  };

  onClickSwapRate = (e, bank, bankCode, currency, endDate, transactionType, swapRate = 0) => {
    this.setState(
      {
        modalData: {
          ...initModal,
          Mode: 'swap rate',
          TransactionNo: this.state.transactionNo,
          Bank: bank,
          BankCode: bankCode,
          BuyCurrency: currency,
          EndDateStr: endDate,
          TransactionType: transactionType,
          SwapRate: swapRate,
        },
      },
      this.modalSwapRef.open
    );
  };

  onClickSpotRate = (e, bank, bankCode, currency, statusFile, type, spotRate = 0) => {
    this.setState(
      {
        modalData: {
          ...initModal,
          Mode: 'spot rate',
          TransactionNo: this.state.transactionNo,
          Bank: bank,
          BankCode: bankCode,
          BuyCurrency: currency,
          StatusFile: statusFile,
          Type: type,
          SpotRate: spotRate,
        },
      },
      this.modalSpotRef.open
    );
  };

  onChangeInput = (e) => {
    this.setState({
      modalData: {
        ...this.state.modalData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitRate = (e) => {
    e.preventDefault();
    this.props.saveFxMatching(this.state.modalData).then(() => {
      this.modalSwapRef.close();
      this.modalSpotRef.close();
      this.props.addAlert({
        title: 'Success',
        type: 'success',
        body: 'The record was successfully saved',
      });
      this.props.getReportToBank({ ...this.state.criteria }).then((res) => {
        this.setResult(res.payload);
      });
    });
  };

  getExportEmailData = () => {
    if (!this.state.isSearch) {
      return null;
    }

    const data = [];

    const TransactionNo = this.state.transactionNo;
    const EntryDate = this.state.criteria.EntryDateStr;

    const dateSpit = EntryDate.split('-');
    const dateFrom = dateSpit[0].trim();
    const dateTo = dateSpit[1].trim();
    const splitFrom = dateFrom.split('/');
    const splitTo = dateTo.split('/');
    const dateFromStr = `${splitFrom[2]}${splitFrom[1]}${splitFrom[0]}`;
    const dateToStr = `${splitTo[2]}${splitTo[1]}${splitTo[0]}`;
    const EntryDateStr = `${dateFromStr}_${dateToStr}`;

    const newbankTab = this.state.bankTab;

    newbankTab.forEach((m) => {
      const CurrencyList = [];
      m.CurrencyList.forEach((c) => {
        const header = c.Header;
        header[3] = 'Spot Rate';

        const Matching = [...c.Import, ...c.Export];
        const currency = {
          Currency: c.Currency,
          CurrencyDigit: c.CurrencyDigit,
          Header: header,
          Matching,
        };
        CurrencyList.push(currency);
      });

      const datas = {
        TransactionNo,
        EntryDateStr,
        Bank: m.Bank,
        BankCode: m.BankCode,
        CurrencyList,
      };

      data.push(datas);
    });

    return data.filter((f) => f.CurrencyList.length);
  };

  onClickExport = (e) => {
    if (!this.state.isSearch) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please search from Entry Date',
      });
      return;
    }

    const datas = this.getExportEmailData();

    if (datas)
      datas.forEach((m) => {
        this.props.getExportReportToBank(m);
      });
  };

  onClickSendEmail = () => {
    if (!this.state.isSearch) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please search from Entry Date',
      });
      return;
    }

    const datas = this.getExportEmailData();

    if (datas)
      datas.forEach((m) => {
        this.props.sendEmaileportToBank(m);
      });
  };

  onClickFetchEmail = () => {
    this.props.fetchEmailReportToBank();
  };

  onClickOpenUploadExcel = () => {
    this.importExcelRef.open();
  };

  onClickUploadImportExcel = (file) => {
    const form = new FormData();
    form.append('file', file);
    this.props.uploadViewReportToBank(form);
  };

  onClickSubmitImportExcel = () => {
    const { rows } = this.props.importExcelReportToBank;
    if (!rows || !rows.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'There no data for import',
      });
      return;
    }
    this.props.uploadSaveReportToBank(rows);
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,
      onClickSwapRate: this.onClickSwapRate,
      onSubmitRate: this.onSubmitRate,
      onChangeInput: this.onChangeInput,
      onClickSpotRate: this.onClickSpotRate,
      tabRef: (e) => (this.tabRef = e),
      onClickExport: this.onClickExport,
      onClickSendEmail: this.onClickSendEmail,
      onClickFetchEmail: this.onClickFetchEmail,
      onClickOpenUploadExcel: this.onClickOpenUploadExcel,
    };
    console.log(this.props.importExcelReportToBank);
    return (
      <div>
        <FxMatching
          {...props}
          {...this.state}
          modalSwapRef={(e) => (this.modalSwapRef = e)}
          modalSpotRef={(e) => (this.modalSpotRef = e)}
        />

        <ImportExcel
          ref={(e) => (this.importExcelRef = e)}
          tableName="dt-import-excel-report-to-bank"
          optionDt={opt}
          value={this.props.importExcelReportToBank}
          onClickSaveImport={this.onClickSubmitImportExcel}
          onUpload={this.onClickUploadImportExcel}
        >
          <thead>
            <tr>
              <th>Transaction No.</th>
              <th>BankCode</th>
              <th>Currency</th>
              <th>End Date</th>
              <th>Purchase Swap Rate</th>
              <th>Sale Swap Rate</th>
            </tr>
          </thead>
        </ImportExcel>
      </div>
    );
  }
}

const opt = {
  columns: [
    { data: 'transactionNo' },
    { data: 'bankCode' },
    { data: 'currency' },
    { data: 'entryDateStr' },
    { data: 'purchaseSwapRate', className: 'text-right' },
    { data: 'saleSwapRate', className: 'text-right' },
  ],
};

export default connect(
  (state) => ({
    ...state.reportToBank,
  }),
  {
    getReportToBank,
    getBankPoso,
    saveFxMatching,
    getExportReportToBank,
    uploadViewReportToBank,
    addAlert,
    sendEmaileportToBank,
    uploadSaveReportToBank,
    getMatchingDate,
    getMatchingTime,
    fetchEmailReportToBank,
  }
)(FxMatchingContainer);
