import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import ModalManualPost from './ModalManualPost';
import FormGroup2 from '../../common/FormGroup2';
import DatePicker from '../../common/DatePicker';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import ModalFunction2 from '../../common/ModalFunction2';
import ButtonDropdown from '../../common/ButtonDropdown';
import DateRangePicker from '../../common/DateRangePicker';
import { DATATABLE_ID } from './BankTransferPostingContainer';
import MasterDropdownUI, { INSTANCE_CODE } from '../../common/MasterDropdownUI';
import { toLower, toNumber, toStatus, getPermission } from '../../common/helpper';
import {
  MASTER_BANK,
  MASTER_FINANCE,
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_ACCOUNT,
  MASTER_BUSINESS_UNIT,
} from '../../reducers/master/action';

const permission = getPermission('Accounting', 'Bank Transfer Posting');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const row = 'row';
const required = true;

const opt = {
  deferRender: true,
  scroller: true,
  paging: true,
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  rowCallback: (tr, r, idx, cells) => {
    if (r.IsSelected) tr.classList.add('checked-row');
    else tr.classList.remove('checked-row');
  },
  columns: [
    {
      orderable: false,
      colReorder: false,
      data: 'IsSelected',
      className: `text-center col-label-checkbox`,
      render: (d, t, r) => {
        r.canSelect = true;
        return `<input type="checkbox" class="label-checkbox" ${d ? 'checked="true"' : ''}/><label class="label_checkbox"></label>`;
      },
    },
    { data: 'companyCode', className: 'text-center' },
    { data: 'companyName' },
    // { data: 'valueDateString', className: 'text-center', type: 'date-black' },
    { data: 'documentDateString', className: 'text-center', type: 'date-black' },
    { data: 'postingDateString', className: 'text-center', type: 'date-black' },
    { data: 'currency', className: 'text-center' },
    { data: 'referenceDocumentNo', className: 'text-center' },
    { data: 'coaName' },
    { data: 'instanceCode' },
    // { data: 'ledgerId', className: 'text-center' },
    // { data: 'ledgerName' },
    // { data: 'fisicalYear', className: 'text-center' },
    { data: 'beneficiaryPostingKey', className: 'text-center' },
    { data: 'beneficiaryDepositGlAccount', className: 'text-center' },
    {
      data: 'beneficiaryDepositAmount',
      className: 'text-right',
      render: (d, t, r) => {
        if (!d || d === 0) return '';
        if (r.beneficiaryPostingKey === '50') return toNumber(d * -1, 2);
        return toNumber(d, 2);
      },
    },
    { data: 'beneficiaryPaymentPostingKey', className: 'text-center' },
    { data: 'beneficiaryPaymentGlAccount', className: 'text-center' },
    {
      data: 'beneficiaryPaymentAmount',
      className: 'text-right',
      render: (d, t, r) => {
        if (!d || d === 0) return '';
        if (r.beneficiaryPaymentPostingKey === '50') return toNumber(d * -1, 2);
        return toNumber(d, 2);
      },
    },
    { data: 'beneficiaryAssignment' },
    { data: 'beneficiaryLineItemText' },
    { data: 'beneficiaryFeeBy' },
    { data: 'applicantPostingKey', className: 'text-center' },
    { data: 'applicantDepositGlAccount', className: 'text-center' },
    {
      data: 'applicantDepositAmount',
      className: 'text-right',
      render: (d, t, r) => {
        if (!d || d === 0) return '';
        if (r.applicantPostingKey === '50') return toNumber(d * -1, 2);
        return toNumber(d, 2);
      },
    },
    { data: 'applicantPaymentPostingKey', className: 'text-center' },
    { data: 'applicantPaymentGlAccount', className: 'text-center' },
    {
      data: 'applicantPaymentAmount',
      className: 'text-right',
      render: (d, t, r) => {
        if (!d || d === 0) return '';
        if (r.applicantPaymentPostingKey === '50') return toNumber(d * -1, 2);
        return toNumber(d, 2);
      },
    },
    { data: 'applicantAssignment' },
    { data: 'applicantLineItemText' },
    { data: 'applicantFeeBy' },
    { data: 'isTransferSameRegion', render: (d) => (d ? 'Same Region' : 'Different Region') },
    { data: 'chequeNo' },
    { data: 'referenceTransactionId' },
    { data: 'postGlStatus', className: 'text-center', render: (d) => toStatus(d) },
    { data: 'transferMethod' },
    {
      data: 'chequeStatus',
      className: 'text-center',
      render: (d, t, r) => {
        if (d) return toStatus(d);
        if (toLower(r.transferMethod) === 'cheque') return toStatus('Wait for Cheque No.');
        return '';
      },
    },
    { data: 'errorMessage' },
    { data: 'referenceTransactionId', render: (d) => `AFMS-${d}` },
    { data: 'reverseStatus', className: 'text-center', render: (d) => toStatus(d, true) },
    { data: 'reverseDocumentNo' },
    {
      orderable: false,
      data: null,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission || r.isReverse) return '';
        return '<button class="btn btn-icon btn-warning action-reverse"><span class="icon icon-undo sq-24"></span></button>';
      },
    },
  ],
};

export default ({
  criteria,
  results,
  dataTableRef,
  onClickSearch,
  onChangeSelect2,
  onChangeSearch,
  onClickClearSearch,

  modalPostRef,
  onClickOpenModalPost,
  onClickConfirmModalPost,
  modalPost,

  modalReverseRef,
  onClickConfirmModalReverse,

  modalManual,
  modalManualRef,
  onClickOpenModalManual,
  onChangeModalManual,
  onSubmitModalManual,

  modalChequeRef,
  onClickOpenModalCheque,
  onClickConfirmModalCheque,

  modalEdit,
  modalEditRef,
  onClickOpenModalEdit,
  onChangeModalEdit,
  onSubmitModalEdit,

  onClickOpenModalFlag,
  onClickOpenModalUnFlag,
  onClickSubmitModalFlag,
  modalFlagRef,
  modalUnFlagRef,

  isAdmin,
}) => {
  const selectedResultCount = results.filter((f) => f.IsSelected).length;

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Accounting <span className="icon icon-angle-double-right" /> Bank Transfer Posting
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required={false}>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroup}
                  name="FinanceGroup"
                />
              </FormGroup2>

              <FormGroup2 text="Business Unit" required={false}>
                <MasterDropdown
                  masterType={MASTER_BUSINESS_UNIT}
                  onChange={onChangeSelect2}
                  value={criteria.BusinessUnit}
                  name="BusinessUnit"
                />
              </FormGroup2>
            </Col2>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Company">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  onChange={onChangeSelect2}
                  value={criteria.Companys}
                  saveLocalState
                  financeGroupId={criteria.FinanceGroup}
                  businessUnitId={criteria.BusinessUnit}
                  name="Companys"
                />
              </FormGroup2>

              <FormGroup2 text="Currency">
                <MasterDropdown
                  masterType={MASTER_CURRENCY}
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  isAll
                  notMultipleSelect2
                  value={criteria.Currency}
                  name="Currency"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Applicant Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  isAll
                  notMultipleSelect2
                  value={
                    criteria.ApplicantBank === '' || !criteria.ApplicantBank
                      ? 'all'
                      : criteria.ApplicantBank
                  }
                  saveLocalState
                  companyCode={criteria.Companys}
                  name="ApplicantBank"
                />
              </FormGroup2>

              <FormGroup2 text="Applicant Bank Account">
                <MasterDropdown
                  masterType={MASTER_BANK_ACCOUNT}
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  isAll
                  notMultipleSelect2
                  saveLocalState
                  companyCode={criteria.Companys}
                  bankAbbreviate={criteria.ApplicantBank === 'all' ? '' : criteria.ApplicantBank}
                  customeValue={(m) => `${m.bankAccountNo}`}
                  value={
                    criteria.ApplicantAccountNo === '' || !criteria.ApplicantAccountNo
                      ? 'all'
                      : criteria.ApplicantAccountNo
                  }
                  name="ApplicantAccountNo"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Beneficiary Bank">
                <MasterDropdown
                  masterType={MASTER_BANK}
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  isAll
                  notMultipleSelect2
                  saveLocalState
                  companyCode={criteria.Companys}
                  value={
                    criteria.BeneficiaryBank === '' || !criteria.BeneficiaryBank
                      ? 'all'
                      : criteria.BeneficiaryBank
                  }
                  name="BeneficiaryBank"
                />
              </FormGroup2>

              <FormGroup2 text="Beneficiary Bank Account">
                <MasterDropdown
                  masterType={MASTER_BANK_ACCOUNT}
                  onChange={(e) =>
                    onChangeSearch({
                      target: e,
                    })
                  }
                  isAll
                  notMultipleSelect2
                  saveLocalState
                  companyCode={criteria.Companys}
                  bankAbbreviate={
                    criteria.BeneficiaryBank === 'all' ? '' : criteria.BeneficiaryBank
                  }
                  customeValue={(m) => `${m.bankAccountNo}`}
                  value={
                    criteria.BeneficiaryAccountNo === '' || !criteria.BeneficiaryAccountNo
                      ? 'all'
                      : criteria.BeneficiaryAccountNo
                  }
                  name="BeneficiaryAccountNo"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Transfer Method">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.TransferMethod}
                  name="TransferMethod"
                  options={['Bahtnet', 'Cheque', 'Auto Deduct', 'Internet Banking', 'Sweep']}
                />
              </FormGroup2>

              <FormGroup2 text="Sending Type">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.SendingType}
                  name="SendingType"
                  options={['N/A', 'SFTP', 'Paper', 'Paper with Signature']}
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Post G/L Status">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.PostGLStatus}
                  name="PostGLStatus"
                  options={['New', 'Waiting', 'Failed', 'Completed', 'No Post']}
                />
              </FormGroup2>

              <FormGroup2 text="Cheque Status">
                <MasterDropdownUI
                  onChange={onChangeSearch}
                  isAll
                  notMultiple
                  value={criteria.ChequeStatus}
                  name="ChequeStatus"
                  options={['Wait for Cheque No.', 'Failed', 'Completed']}
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Value Date" required>
                <DateRangePicker
                  startDate={window.moment().add(0, 'days')}
                  endDate={window.moment().add(0, 'days')}
                  onChange={onChangeSearch}
                  required={required}
                  value={criteria.ValueDate}
                  name="ValueDate"
                />
              </FormGroup2>

              <FormGroup2 text="Instance" required={false}>
                <MasterDropdownUI
                  masterType={INSTANCE_CODE}
                  onChange={onChangeSelect2}
                  required={false}
                  value={criteria.InstanceCode}
                  name="InstanceCode"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        {permission ? (
          <div className={row}>
            <div className="col-md-12">
              <Button
                txt="Post"
                icon="retweet"
                className="btn-primary"
                onClick={onClickOpenModalPost}
                disabled={false}
              />
              &nbsp;
              <Button
                txt="Change Posting Date"
                icon="calendar"
                className="btn-info"
                onClick={onClickOpenModalEdit}
                disabled={false}
              />
              &nbsp;
              <Button
                txt="CHEQUE UPDATE"
                icon="credit-card-alt"
                className="btn-default"
                onClick={onClickOpenModalCheque}
                disabled={false}
              />
              &nbsp;
              <ButtonDropdown
                className="btn-blank"
                dropdownMenu="left"
                text="Manual"
                icon="icon-reorder"
                buttons={[
                  {
                    text: 'Post',
                    icon: 'icon-retweet',
                    disabled: true,
                    onClick: (e) => onClickOpenModalManual('post'),
                  },
                  {
                    text: 'Clearing',
                    icon: 'icon-eraser',
                    disabled: true,
                    onClick: (e) => onClickOpenModalManual('clearing'),
                  },
                  {
                    text: 'Reverse',
                    icon: 'icon-undo',
                    disabled: true,
                    onClick: (e) => onClickOpenModalManual('reverse'),
                  },
                ]}
              />
              &nbsp;
              {isAdmin ? (
                <>
                  <Button
                    txt="Flag No Post"
                    icon="flag"
                    className="btn-success"
                    onClick={onClickOpenModalFlag}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Unflag"
                icon="undo"
                className="btn-warning"
                onClick={onClickOpenModalUnFlag}
                disabled={false}
              />
            </div>
          </div>
        ) : null}

        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>
                Choose <label className="label-checkbox fix choose-all" />
              </th>
              <th>
                Company
                <br /> Code
              </th>
              <th>
                Company
                <br /> Name
              </th>
              {/* <th>Value Date</th> */}
              <th>Document Date</th>
              <th>Posting Date</th>
              <th>Currency</th>
              <th>Ref. Doc. No.</th>
              <th>COA Name</th>
              <th>Instance Code</th>
              {/* <th>Ledger ID</th>
                            <th>Ledger Name</th>
                            <th>Fiscal Year</th> */}
              <th className="th-primary">
                Beneficiary
                <br /> Pstky
              </th>
              <th className="th-primary">
                Beneficiary
                <br /> Deposit
                <br /> GL Account
              </th>
              <th className="th-primary">
                Beneficiary
                <br /> Amount
              </th>
              <th className="th-primary">
                Beneficiary
                <br /> Pstky
              </th>
              <th className="th-primary">
                Beneficiary
                <br /> Payment
                <br /> GL Account
              </th>
              <th className="th-primary">
                Beneficiary
                <br /> Amount
              </th>
              <th className="th-primary">
                Beneficiary
                <br /> Assignment
              </th>
              <th className="th-primary">
                Beneficiary
                <br /> Line Item Text
              </th>
              <th className="th-primary">
                Beneficiary
                <br /> Fee By
              </th>
              <th className="th-success">
                Applicant
                <br /> Pstky
              </th>
              <th className="th-success">
                Applicant
                <br /> Deposit
                <br /> GL Account
              </th>
              <th className="th-success">
                Applicant
                <br /> Amount
              </th>
              <th className="th-success">
                Applicant
                <br /> Pstky
              </th>
              <th className="th-success">
                Applicant
                <br /> Payment
                <br /> GL Account
              </th>
              <th className="th-success">
                Applicant
                <br /> Amount
              </th>
              <th className="th-success">
                Applicant
                <br /> Assignment
              </th>
              <th className="th-success">
                Applicant
                <br /> Line Item Text
              </th>
              <th className="th-success">
                Applicant
                <br /> Fee By
              </th>
              <th>Region</th>
              <th>Cheque No.</th>
              <th>Ref. Key No.</th>
              <th>Post G/L Status</th>
              <th>Transfer Method</th>
              <th>Cheque Status</th>
              <th>Error Message</th>
              <th>AFMS Ref. No</th>
              <th>
                Reverse
                <br />
                Status
              </th>
              <th>
                Reverse
                <br />
                Doc. No.
              </th>
              <th>Reverse</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal size="modal-md" ref={modalEditRef} modalFooter>
        <form onSubmit={onSubmitModalEdit} className="form-horizontal">
          <div className="text-center">
            <span className="label label-info">{selectedResultCount} items</span>
            <h2 className="text-info">Change Posting Date</h2>
          </div>
          <br />
          <div className="form-group">
            <label className="control-label col-md-4">
              Posting Date<span style={{ color: '#e64a19' }}>*</span>
            </label>
            <div className="col-md-7">
              <DatePicker
                onChange={onChangeModalEdit}
                name="PostingDateStr"
                value={modalEdit.PostingDateStr}
                option={{
                  // startDate: "d",
                  daysOfWeekDisabled: '0,6',
                  todayHighlight: true,
                }}
                required
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-sm-12 text-center">
              <Button txt="Confirm" className="btn-info" type="submit" />
              &nbsp;
              <Button txt="Back" className="btn-default" data-dismiss="modal" />
            </div>
          </div>
        </form>
      </Modal>

      <ModalFunction2
        textHeader="Post"
        textContent="post"
        modalRef={modalPostRef}
        type="primary"
        icon="icon-retweet"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail
        selectedLength={selectedResultCount}
        passLength={modalPost.passLength}
        result={modalPost.resultFail}
        onClick={onClickConfirmModalPost}
      />

      <ModalFunction
        textHeader="Reverse"
        textContent="reverse"
        modalRef={modalReverseRef}
        type="warning"
        icon="icon-undo"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={onClickConfirmModalReverse}
      />

      <ModalManualPost
        modalRef={modalManualRef}
        state={modalManual}
        onSubmit={onSubmitModalManual}
        onChageModal={onChangeModalManual}
      />

      <ModalFunction
        textHeader="Cheque Update"
        textContent="cheque update"
        modalRef={modalChequeRef}
        type="info"
        icon="icon-credit-card-alt"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onClickConfirmModalCheque}
      />

      <ModalFunction
        textHeader="Flag no post"
        textContent="flag no post"
        modalRef={modalFlagRef}
        type="success"
        icon="icon-flag"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onClickSubmitModalFlag}
      />

      <ModalFunction
        textHeader="Unflag"
        textContent="unflag"
        modalRef={modalUnFlagRef}
        type="warning"
        icon="icon-undo"
        textBtn="Yes"
        textBtnBack="No"
        noSelect={false}
        showFail={false}
        selectedLength={selectedResultCount}
        onClick={onClickSubmitModalFlag}
      />
    </div>
  );
};
