import { connect } from 'react-redux';
import React, { Component } from 'react';

import CashPosition from './CashPosition';
import { addAlert } from '../../reducers/layout/action';
import { searchBankAccountSummary } from '../../reducers/bankAccountSummary/action';
import {
  getBankCharge,
  editSearchResult,
  searchCashPosition,
  submitManualTransfer,
  getSweepCashPosition,
  getManualTransferFee,
} from '../../reducers/cashPosition/action';

export const DATATABLE_ID_SWEEP = 'table-result-get-sweep';

const $ = window.jQuery;

const initManualTransfer = {
  FinanceGroupId: '',
  FinanceGroupName: '',
  CompanyCode: '',

  FromBankAccountNo: '',
  FromEndingBalance: 0,

  ToBankAccountNo: 0,
  ToEndingBalance: 0,

  TransferAmount: 0,
  TransferMethod: '',
  ExchangeRate: 0,

  ApplicantFeeBy: 'Applicant',
  ApplicantFeeAmount: '',
  BeneficiaryFeeBy: 'Beneficiary',
  BeneficiaryFeeAmount: '',
};
class CashPositionContainer extends Component {
  state = {
    criteria: {
      FinanceGroupId: '',
      BusinessUnitId: [''],
      CompanyCode: [''],
      ValueDateStr: window.moment().add(0, 'days').format('DD/MM/YYYY'),
      AccountTypeCode: '',
    },
    isMillion: true,
    modalData: {
      ...initManualTransfer,
      ValueDateStr: window.moment().add(0, 'days').format('DD/MM/YYYY'),
    },
    setModalType: '',
    ValueDateStr: '',
    modalSweep: {
      results: [],
    },
  };

  dataTableSweepRef = null;

  componentDidMount() {
    $(document).on('click', '.toggle-row-cash-position', function () {
      const $this = $(this);
      let { company, state } = $this.data();
      if (state === 'open') {
        state = 'close';
        $this.find('i').removeClass('icon-minus').addClass('icon-plus');
      } else {
        state = 'open';
        $this.find('i').removeClass('icon-plus').addClass('icon-minus');
      }

      const dataTableApi = $this.closest('table').DataTable();
      dataTableApi
        .rows((idx, data) => data.companyCode === company.toString())
        .every(function () {
          this.data().state = state;
          if (state === 'close') $(this.node()).hide();
          else $(this.node()).show();
          return null;
        });

      $this.data('state', state);
      window.$(window).trigger('resize');
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { CompanyCode, FromBankAccountNo, ToBankAccountNo, TransferMethod } =
      this.state.modalData;
    if (TransferMethod === 'Bahtnet' && CompanyCode && FromBankAccountNo && ToBankAccountNo) {
      const prev = prevState.modalData;
      if (
        prev.CompanyCode !== CompanyCode ||
        prev.FromBankAccountNo !== FromBankAccountNo ||
        prev.ToBankAccountNo !== ToBankAccountNo ||
        prev.TransferMethod !== TransferMethod
      ) {
        this.props
          .getBankCharge({
            ApplicantBankAccountNo: FromBankAccountNo.split('|')[0],
            BeneficiaryBankAccountNo: ToBankAccountNo.split('|')[0],
            TransferMethod: 'Bahtnet',
          })
          .then((response) => {
            if (response.error) return;
            let {
              beneficiaryChargeAmount = 0,
              applicantChargeAmount = 0,
              isSameRegion,
            } = response.payload;
            let beneficiaryChargeIncludExtra = 0;

            applicantChargeAmount = applicantChargeAmount || 0;
            beneficiaryChargeAmount = beneficiaryChargeAmount || 0;

            if (isSameRegion === false && this.state.modalData.BeneficiaryFeeBy === 'Beneficiary')
              beneficiaryChargeIncludExtra =
                beneficiaryChargeAmount + (this.state.modalData.TransferAmount || 0) * 0.001;
            else beneficiaryChargeIncludExtra = beneficiaryChargeAmount;

            this.setState((state) => ({
              modalData: {
                ...state.modalData,
                ApplicantFeeAmount: applicantChargeAmount.toString(),
                BeneficiaryFeeAmount: (beneficiaryChargeIncludExtra > 850
                  ? 850
                  : beneficiaryChargeIncludExtra
                ).toString(),
                IsTransferSameRegion: isSameRegion,
                BeneficiaryChargeAmount: beneficiaryChargeAmount,
              },
            }));
          });
      }
    }
  }

  componentWillUnmount() {
    $(document).off('click', '.toggle-row-cash-position');
  }

  onChangeSearchHeadler = (e) => {
    const { name, value } = e.target;

    this.setState((state) => {
      const updateState = {
        criteria: {
          ...state.criteria,
          [name]: value,
        },
      };

      if (name === 'ValueDateStr')
        updateState.modalData = {
          ...state.modalData,
          ValueDateStr: value,
        };

      return updateState;
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();

    this.setState({
      ...this.state,
      ValueDateStr: this.state.criteria.ValueDateStr,
    });

    this.props
      .searchCashPosition({
        ...this.state.criteria,
      })
      .then((response) => {
        if (!response.error) this.props.editSearchResult('IsMillion', this.state.isMillion, null);
      });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        FinanceGroupId: '',
        BusinessUnitId: [''],
        CompanyCode: [''],
        ValueDateStr: '',
        AccountTypeCode: '',
      },
    });
  };

  onClickOpenModalEditHandler = (e, cell) => {
    const index = cell.index().row;
    const selectedData = this.props.searchResult[index];
    this.setState(
      {
        setModalType: 'edit',
        modalData: {
          ValueDateStr: selectedData.valueDateStr,
          FinanceGroupId: selectedData.financeGroupId,
          FinanceGroupName: selectedData.financeGroupName,
          CompanyCode: selectedData.companyCode,
          CompanyName: `${selectedData.companyCode}|${selectedData.companyName}`,

          FromBankAccountNo: '',
          FromEndingBalance: 0,
          ApplicantFeeBy: 'Applicant',
          ApplicantFeeAmount: 0,

          ToBankAccountNo: `${selectedData.bankAccountNo}|${selectedData.bankCurrency}`,
          ToEndingBalance: selectedData.endingBalance || 0,
          BeneficiaryFeeBy: 'Beneficiary',
          BeneficiaryFeeAmount: 0,

          TransferAmount: 0,
          TransferMethod: '',
          ExchangeRate: 0,
        },
      },
      () => {
        this.modalRef.open();
      }
    );
  };

  onClickOpenModalCreateHandler = (e, cell) => {
    this.setState(
      (state) => ({
        setModalType: 'create',
        modalData: {
          ...initManualTransfer,
          ValueDateStr: state.criteria.ValueDateStr,
          FinanceGroupId: state.criteria.FinanceGroupId || '',
        },
      }),
      this.modalRef.open
    );
  };

  onChangeModalHeadler = (e) => {
    const { name, value } = e.target;

    if ((name === 'FromBankAccountNo' || name === 'ToBankAccountNo') && value) {
      this.props
        .searchBankAccountSummary({
          AccountNo: value.split('|')[0],
          ValueDateStr: this.state.modalData.ValueDateStr,
        })
        .then((response) => {
          this.setState((state) => {
            const stateToUpdate = {
              ...state.modalData,
              ExchangeRate: 0,
              [name]: value,
            };

            let estEndingBlanace = 0;
            if (!response.error && response.payload && response.payload.length !== 0) {
              estEndingBlanace = response.payload[0].endingBalance || 0;
            }

            if (name === 'FromBankAccountNo') stateToUpdate.FromEndingBalance = estEndingBlanace;
            else stateToUpdate.ToEndingBalance = estEndingBlanace;
            return {
              modalData: stateToUpdate,
            };
          });
        });
    } else if (name === 'TransferAmount') {
      this.setState((state) => {
        const {
          BeneficiaryChargeAmount = 0,
          IsTransferSameRegion,
          BeneficiaryFeeBy,
        } = state.modalData;

        if (IsTransferSameRegion === false && BeneficiaryFeeBy === 'Beneficiary') {
          const chargeIncludExtra = BeneficiaryChargeAmount + (Number(value) || 0) * 0.001;
          return {
            modalData: {
              ...state.modalData,
              [name]: value,
              BeneficiaryFeeAmount: chargeIncludExtra > 850 ? 850 : chargeIncludExtra,
            },
          };
        }
        return {
          modalData: {
            ...state.modalData,
            [name]: value,
          },
        };
      });
    } else if (name === 'TransferMethod')
      this.setState((state) => ({
        modalData: {
          ...state.modalData,
          [name]: value,
          ApplicantFeeAmount: null,
          BeneficiaryFeeAmount: null,
          ApplicantFeeBy: value === 'Bahtnet' ? 'Applicant' : null,
          BeneficiaryFeeBy: value === 'Bahtnet' ? 'Beneficiary' : null,
        },
      }));
    // default
    else
      this.setState((state) => ({
        modalData: {
          ...state.modalData,
          [name]: value,
        },
      }));
  };

  onClickModalSaveHeadler = (e) => {
    e.preventDefault();
    const data = { ...this.state.modalData };
    const accountSplit = data.FromBankAccountNo.split('|');
    const baccountSplit = data.ToBankAccountNo.split('|');
    if (accountSplit[0] === baccountSplit[0]) {
      this.props.addAlert({
        title: 'Error',
        body: 'Please selected different bank account no.',
        type: 'error',
      });
      return;
    }

    if (parseFloat(data.TransferAmount || 0) <= 0) {
      this.props.addAlert({
        title: 'Error',
        body: 'Transfer amount must more than zero.',
        type: 'error',
      });
      return;
    }

    if (parseFloat(data.ApplicantFeeAmount || 0) < 0) {
      this.props.addAlert({
        title: 'Error',
        body: 'Applicant fee amount must more than or equal zero.',
        type: 'error',
      });
      return;
    }

    if (parseFloat(data.BeneficiaryFeeAmount || 0) < 0) {
      this.props.addAlert({
        title: 'Error',
        body: 'Beneficiary fee amount must more than or equal zero.',
        type: 'error',
      });
      return;
    }

    if (accountSplit[1] !== baccountSplit[1] && parseFloat(data.ExchangeRate || 0) <= 0) {
      this.props.addAlert({
        title: 'Error',
        body: 'Exchange rate must more than zero.',
        type: 'error',
      });
      return;
    }

    const submitData = {
      ApplicantBankAccountNo: accountSplit[0].trim(),
      ApplicantCompanyCode: data.CompanyCode,
      ApplicantFeeBy: data.ApplicantFeeBy,
      ApplicantFeeAmount: data.ApplicantFeeAmount,
      Currency: (accountSplit[1] || '').trim(),
      BeneficiaryCompanyCode: data.CompanyCode,
      BeneficiaryBankAccountNo: baccountSplit[0].trim(),
      BeneficiaryFeeBy: data.BeneficiaryFeeBy,
      BeneficiaryFeeAmount: data.BeneficiaryFeeAmount,
      ValueDateStr: data.ValueDateStr,
      Amount: data.TransferAmount,
      TransferMethod: data.TransferMethod,
      ExchangeRate: data.ExchangeRate,
      IsTransferSameRegion: data.IsTransferSameRegion,
    };

    this.props.submitManualTransfer(submitData).then((response) => {
      if (!response.error) this.modalRef.close();
    });
  };

  onChangeToggleMillion = (e) => {
    const { checked } = e.target;
    this.setState(
      {
        [e.target.name]: checked,
      },
      () => {
        this.props.editSearchResult('IsMillion', checked, null);
      }
    );
  };

  onClickColumnOpenModalSweep = (e, cell) => {
    const index = cell.index().row;
    const selectedData = this.props.searchResult[index];

    this.props
      .getSweepCashPosition({
        CompanyCode: selectedData.companyCode,
        ValueDate: selectedData.valueDateStr,
        BankAccountNo: selectedData.bankAccountNo,
      })
      .then((response) => {
        if (response.error) return;

        this.setState(
          {
            modalSweep: {
              results: response.payload,
            },
          },
          () => {
            window.jQuery(window).trigger('resize');
          }
        );
        this.modalSweepRef.open();
      });
  };

  onClickOpenModalAutomateProgress = () => {
    this.modalAutomateRef.open();
  };

  onSubmitModalAutomateProgress = () => {
    this.modalAutomateRef.close();
  };

  render() {
    const props = {
      onChangeToggleMillion: this.onChangeToggleMillion,
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      onClickOpenModalEdit: this.onClickOpenModalEditHandler,
      onClickOpenModalCreate: this.onClickOpenModalCreateHandler,
      onChangeModal: this.onChangeModalHeadler,
      onClickModalSave: this.onClickModalSaveHeadler,

      results: this.props.searchResult,
      onClickColumnOpenModalSweep: this.onClickColumnOpenModalSweep,
      dataTableSweepRef: (e) => (this.dataTableSweepRef = e),

      onClickOpenModalAutomateProgress: this.onClickOpenModalAutomateProgress,
      onSubmitModalAutomateProgress: this.onSubmitModalAutomateProgress,
      modalAutomateRef: (e) => (this.modalAutomateRef = e),
    };

    return (
      <CashPosition
        {...props}
        {...this.state}
        modalRef={(e) => (this.modalRef = e)}
        modalSweepRef={(e) => (this.modalSweepRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.cashPosition,
  }),
  {
    searchCashPosition,
    submitManualTransfer,
    editSearchResult,
    searchBankAccountSummary,
    addAlert,
    getBankCharge,
    getSweepCashPosition,
    getManualTransferFee,
  }
)(CashPositionContainer);
