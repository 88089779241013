import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import { DATATABLE_ID } from './CurrencyContainer';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import { MASTER_COMPANY } from '../../reducers/master/action';
import { toLower, toStatus, toCheckBox, getPermission } from '../../common/helpper';

const permission = getPermission('Master Accounting', 'Payment Method');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-5', 'col-md-7'];

const opt = {
  paging: true,
  deferRender: true,
  scroller: true,
  order: [[2, 'asc']],
  fixedColumns: {
    leftColumns: 3,
  },
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-close sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'masterCompanyCode', className: 'text-center' },
    { data: 'masterCompanyName' },
    { data: 'masterPaymentMethod' },
    {
      data: 'masterIsAutoInterestIncome',
      orderable: false,
      className: 'text-center td-width-check',
      render: (d, t, r) => {
        if (t === 'export') return d ? '1' : '';
        return toCheckBox(d);
      },
    },
    {
      data: 'masterIsAutoInterestPayment',
      orderable: false,
      className: 'text-center td-width-check',
      render: (d, t, r) => {
        if (t === 'export') return d ? '1' : '';
        return toCheckBox(d);
      },
    },
    { data: 'memberCompanyCode', className: 'text-center' },
    { data: 'memberCompanyName' },
    { data: 'memberPaymentMethod' },
    {
      data: 'memberIsAutoInterestIncome',
      orderable: false,
      className: 'text-center td-width-check',
      render: (d) => toCheckBox(d),
    },
    {
      data: 'memberIsAutoInterestPayment',
      orderable: false,
      className: 'text-center td-width-check',
      render: (d) => toCheckBox(d),
    },
    {
      data: 'pnOncallSweepTh',
      className: 'text-center',
      render: (d) => (toLower(d) === 'y' ? 'Yes' : ''),
    },
    {
      data: 'pnOncallSweepOv',
      className: 'text-center',
      render: (d) => (toLower(d) === 'y' ? 'Yes' : ''),
    },
    { data: 'pnTerm', className: 'text-center', render: (d) => (toLower(d) === 'y' ? 'Yes' : '') },
    {
      data: 'isActive',
      className: 'text-center',
      render: (d) => (d ? toStatus('Active') : toStatus('Inactive')),
    },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const modeEdit = modalData.Action === 'edit';
  const checkSave = !!((modalData.Action === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Accounting <span className="icon icon-angle-double-right" /> Payment Method
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Master Company Code">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  value={criteria.MasterCompanyCode}
                  onChange={onChangeSelect2}
                  name="MasterCompanyCode"
                  saveLocalState
                />
              </FormGroup2>

              <FormGroup2 text="Member Company Code">
                <MasterDropdown
                  masterType={MASTER_COMPANY}
                  value={criteria.MemberCompanyCode}
                  onChange={onChangeSelect2}
                  name="MemberCompanyCode"
                  saveLocalState
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              <FormGroup2 text="Status">
                <select
                  className="form-control"
                  value={criteria.IsActive}
                  onChange={onChangeSearch}
                  name="IsActive"
                >
                  <option value="">---All---</option>
                  <option value>Active</option>
                  <option value={false}>Inactive</option>
                </select>
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="Create Payment Method"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalData}
                    disabled={false}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>
                Master
                <br /> Company
                <br /> Code
              </th>
              <th>
                Master
                <br /> Company Name
              </th>
              <th>
                Master
                <br /> Payment Method
              </th>
              <th>
                Master
                <br /> Auto Interest
                <br /> Income
              </th>
              <th>
                Master
                <br /> Auto Interest
                <br /> Payment
              </th>
              <th>
                Member
                <br /> Company
                <br /> Code
              </th>
              <th>
                Member
                <br /> Company Name
              </th>
              <th>
                Member
                <br /> Payment Method
              </th>
              <th>
                Member
                <br /> Auto Interest
                <br /> Income
              </th>
              <th>
                Member
                <br /> Auto Interest
                <br /> Payment
              </th>
              <th>
                PN Oncall <br />
                Sweep TH
              </th>
              <th>
                PN Oncall <br />
                Sweep OV
              </th>
              <th>PN Term</th>
              <th>Status</th>
              <th>
                Created
                <br /> By
              </th>
              <th>
                Created
                <br /> Date
              </th>
              <th>
                Updated
                <br /> By
              </th>
              <th>
                Updated
                <br /> Date
              </th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal
        textHeader="Master Payment Method"
        bgHeader="bg-primary"
        size="modal-lg"
        ref={modalDataRef}
      >
        <form onSubmit={onClickSaveModalData}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[2]}>
              <Card textHeader="Master" bgHeader="bg-info">
                <Col2 col={colX[2]}>
                  <FormGroup2 text="Master Company" required>
                    <MasterDropdown
                      masterType={MASTER_COMPANY}
                      noValidateOption={modeEdit}
                      saveLocalState
                      onChange={(e) =>
                        onChangeModalData({
                          target: e,
                        })
                      }
                      isChoose
                      notMultipleSelect2
                      value={modalData.MasterCompanyCode}
                      name="MasterCompanyCode"
                      required
                      disabled={modeEdit}
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[2]}>
                  <FormGroup2 text="Payment Method" required>
                    <input
                      className="form-control"
                      onChange={onChangeModalData}
                      value={modalData.MasterPaymentMethod || ''}
                      // required={true}
                      maxLength={1}
                      // readOnly={modeEdit}
                      name="MasterPaymentMethod"
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[2]}>
                  <div className="form-group">
                    <div>
                      <input
                        id="MasterIsAutoInterestIncome"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeModalData}
                        value={modalData.MasterIsAutoInterestIncome}
                        name="MasterIsAutoInterestIncome"
                        checked={modalData.MasterIsAutoInterestIncome}
                      />
                      <label htmlFor="MasterIsAutoInterestIncome" className="label_checkbox" />
                      <label
                        htmlFor="MasterIsAutoInterestIncome"
                        className="control-label text_label_checkbox"
                      >
                        Auto Interest Income
                      </label>
                    </div>
                  </div>
                </Col2>

                <Col2 col={colX[2]}>
                  <div className="form-group">
                    <div>
                      <input
                        id="MasterIsAutoInterestPayment"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeModalData}
                        value={modalData.MasterIsAutoInterestPayment}
                        name="MasterIsAutoInterestPayment"
                        checked={modalData.MasterIsAutoInterestPayment}
                      />
                      <label htmlFor="MasterIsAutoInterestPayment" className="label_checkbox" />
                      <label
                        htmlFor="MasterIsAutoInterestPayment"
                        className="control-label text_label_checkbox"
                      >
                        Auto Interest Payment
                      </label>
                    </div>
                  </div>
                </Col2>
              </Card>
            </Col2>

            <Col2 col={colX[2]}>
              <Card textHeader="Member" bgHeader="bg-warning">
                <Col2 col={colX[2]}>
                  <FormGroup2 text="Member Company" required>
                    <MasterDropdown
                      masterType={MASTER_COMPANY}
                      noValidateOption={modeEdit}
                      saveLocalState
                      onChange={(e) =>
                        onChangeModalData({
                          target: e,
                        })
                      }
                      isChoose
                      notMultipleSelect2
                      value={modalData.MemberCompanyCode}
                      name="MemberCompanyCode"
                      required
                      disabled={modeEdit}
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[2]}>
                  <FormGroup2 text="Payment Method" required>
                    <input
                      className="form-control"
                      onChange={onChangeModalData}
                      value={modalData.MemberPaymentMethod || ''}
                      // required={true}
                      maxLength={1}
                      // readOnly={modeEdit}
                      name="MemberPaymentMethod"
                    />
                  </FormGroup2>
                </Col2>

                <Col2 col={colX[2]}>
                  <div className="form-group">
                    <div>
                      <input
                        id="MemberIsAutoInterestIncome"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeModalData}
                        value={modalData.MemberIsAutoInterestIncome}
                        name="MemberIsAutoInterestIncome"
                        checked={modalData.MemberIsAutoInterestIncome}
                      />
                      <label htmlFor="MemberIsAutoInterestIncome" className="label_checkbox" />
                      <label
                        htmlFor="MemberIsAutoInterestIncome"
                        className="control-label text_label_checkbox"
                      >
                        Auto Interest Income
                      </label>
                    </div>
                  </div>
                </Col2>

                <Col2 col={colX[2]}>
                  <div className="form-group">
                    <div>
                      <input
                        id="MemberIsAutoInterestPayment"
                        type="checkbox"
                        className="label-checkbox"
                        onChange={onChangeModalData}
                        value={modalData.MemberIsAutoInterestPayment}
                        name="MemberIsAutoInterestPayment"
                        checked={modalData.MemberIsAutoInterestPayment}
                      />
                      <label htmlFor="MemberIsAutoInterestPayment" className="label_checkbox" />
                      <label
                        htmlFor="MemberIsAutoInterestPayment"
                        className="control-label text_label_checkbox"
                      >
                        Auto Interest Payment
                      </label>
                    </div>
                  </div>
                </Col2>
              </Card>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <div className="form-group">
                <div>
                  <input
                    id="PnOncallSweepThC"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeModalData}
                    value={modalData.PnOncallSweepThC}
                    name="PnOncallSweepThC"
                    checked={modalData.PnOncallSweepThC}
                  />
                  <label htmlFor="PnOncallSweepThC" className="label_checkbox" />
                  <label htmlFor="PnOncallSweepThC" className="control-label text_label_checkbox">
                    PN Oncall Sweep TH
                  </label>
                </div>
              </div>

              <div className="form-group">
                <div>
                  <input
                    id="PnOncallSweepOvC"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeModalData}
                    value={modalData.PnOncallSweepOvC}
                    name="PnOncallSweepOvC"
                    checked={modalData.PnOncallSweepOvC}
                  />
                  <label htmlFor="PnOncallSweepOvC" className="label_checkbox" />
                  <label htmlFor="PnOncallSweepOvC" className="control-label text_label_checkbox">
                    PN Oncall Sweep OV
                  </label>
                </div>
              </div>
            </Col2>

            <Col2 col={colX[1]}>
              <div className="form-group">
                <div>
                  <input
                    id="PnTermC"
                    type="checkbox"
                    className="label-checkbox"
                    onChange={onChangeModalData}
                    value={modalData.PnTermC}
                    name="PnTermC"
                    checked={modalData.PnTermC}
                  />
                  <label htmlFor="PnTermC" className="label_checkbox" />
                  <label htmlFor="PnTermC" className="control-label text_label_checkbox">
                    PN Term
                  </label>
                </div>
              </div>
            </Col2>
          </Col2>

          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
