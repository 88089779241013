import { connect } from 'react-redux';
import React, { Component } from 'react';

import { toFixed } from '../../common/helpper';
import TransferFeeSetUp from './TransferFeeSetUp';
import { getCurrencyDigit } from '../../reducers/master/action';
import {
  changeStatus,
  searchTransferFeeSetup,
  submitTransferFeeSetup,
} from '../../reducers/transferFeeSetup/action';

export const DATATABLE_ID = 'table-result-transfer-setup';

const initModal = {
  TransferFeeId: '',
  FinanceGroupId: '',
  CompanyCode: [''],
  BankCode: '',
  Currency: '',
  TransferMethod: '',
  EffectiveDateStr: '',
  ApplicantSameRegionTransferFee: 0,
  ApplicantDifferentRegionTransferFee: 0,
  BeneficiarySameRegionTransferFee: 0,
  BeneficiaryDifferentRegionTransferFee: 0,

  inputMaskAmount: {
    prefix: '',
    digits: 2,
    digitsOptional: false,
    placeholder: '0.00',
  },
};

class TransferFeeSetUpContainer extends Component {
  state = {
    criteria: {
      FinanceGroupId: [''],
      BusinessUnitId: [''],
      CompanyCode: [''],
      EffectiveDateStr: '',
      BankCode: [''],
      Status: true,
    },
    modalData: {
      ...initModal,
    },
    selectedRowIdx: null,
  };

  dataTableSearchResultRef = null;

  componentDidUpdate(prevProps, prevState) {
    // get currency digit

    const {
      Currency,
      ApplicantSameRegionTransferFee,
      ApplicantDifferentRegionTransferFee,
      BeneficiarySameRegionTransferFee,
      BeneficiaryDifferentRegionTransferFee,
      TransferFeeId,
    } = this.state.modalData;

    if (
      (prevState.modalData.Currency !== Currency ||
        prevState.modalData.TransferFeeId !== TransferFeeId) &&
      Currency
    ) {
      this.props.getCurrencyDigit({ criteria: Currency }).then((response) => {
        if (response.error) return;

        const { digit } = response.payload[0];
        this.setState({
          modalData: {
            ...this.state.modalData,
            inputMaskAmount: {
              prefix: '',
              digits: digit,
              digitsOptional: false,
              placeholder: !digit ? '0' : `0.${`000${digit}`.slice(-3)}`,
            },
            ApplicantSameRegionTransferFee: toFixed(
              Number((ApplicantSameRegionTransferFee.toString() || '').replace(/,/g, '') || 0),
              digit
            ),
            ApplicantDifferentRegionTransferFee: toFixed(
              Number((ApplicantDifferentRegionTransferFee.toString() || '').replace(/,/g, '') || 0),
              digit
            ),
            BeneficiarySameRegionTransferFee: toFixed(
              Number((BeneficiarySameRegionTransferFee.toString() || '').replace(/,/g, '') || 0),
              digit
            ),
            BeneficiaryDifferentRegionTransferFee: toFixed(
              Number(
                (BeneficiaryDifferentRegionTransferFee.toString() || '').replace(/,/g, '') || 0
              ),
              digit
            ),
          },
        });
      });
    }
  }

  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    this.props.searchTransferFeeSetup({
      ...this.state.criteria,
    });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        FinanceGroupId: [''],
        BusinessUnitId: [''],
        CompanyCode: [''],
        EffectiveDateStr: '',
        BankCode: [''],
        Status: '',
      },
    });
  };

  onClickOpenModalCreateHandler = (e, cell) => {
    this.setState(
      {
        modalData: {
          ...initModal,
        },
        selectedRowIdx: null,
      },
      this.modal.open
    );
  };

  toUpperCaseKey(obj) {
    const newObj = {};
    for (const key in obj) {
      newObj[`${key[0].toUpperCase()}${key.substr(1)}`] = obj[key];
    }
    return newObj;
  }

  onClickOpenModalEditHandler = (e, cell) => {
    const { row } = cell.index();

    this.setState(
      {
        modalData: {
          ...this.toUpperCaseKey(this.props.searchResult[row]),
        },
        selectedRowIdx: row,
      },
      this.modal.open
    );
  };

  onChangeModalHeadler = (e) => {
    this.setState({
      modalData: {
        ...this.state.modalData,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2ModalHandler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        modalData: {
          ...this.state.modalData,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSaveModalHandler = (e) => {
    e.preventDefault();
    const data = { ...this.state.modalData };
    this.props.submitTransferFeeSetup(data).then((response) => {
      if (!response.error)
        this.setState(
          {
            modalData: {
              ...initModal,
            },
            selectedRowIdx: null,
          },
          () => {
            this.modal.close();
            this.props.searchTransferFeeSetup({
              ...this.state.criteria,
            });
          }
        );
    });
  };

  onClickConfirmChangeStatusHandler = (status) => {
    const { selectedRowIdx } = this.state;
    if (status === 'InActive') {
      this.props
        .changeStatus(
          {
            ...this.props.searchResult[selectedRowIdx],
            isActive: false,
          },
          selectedRowIdx,
          'inactive'
        )
        .then(() => {
          this.setState(
            {
              selectedRowIdx: null,
            },
            this.modalInActiveRef.close
          );
        });
    } else {
      this.props
        .changeStatus(
          {
            ...this.props.searchResult[selectedRowIdx],
            isActive: true,
          },
          selectedRowIdx,
          'active'
        )
        .then(() => {
          this.setState(
            {
              selectedRowIdx: null,
            },
            this.modalActiveRef.close
          );
        });
    }
  };

  onClickOpenModalActive = (e, cell) => {
    const { row } = cell.index();
    this.setState(
      {
        selectedRowIdx: row,
      },
      this.modalActiveRef.open
    );
  };

  onClickOpenModalInActive = (e, cell) => {
    const { row } = cell.index();
    this.setState(
      {
        selectedRowIdx: row,
      },
      this.modalInActiveRef.open
    );
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,

      onClickOpenModalCreate: this.onClickOpenModalCreateHandler,
      onClickOpenModalEdit: this.onClickOpenModalEditHandler,
      onChangeModal: this.onChangeModalHeadler,
      onChangeSelect2Modal: this.onChangeSelect2ModalHandler,
      onClickSaveModal: this.onClickSaveModalHandler,

      dataTableSearchResultRef: (e) => (this.dataTableSearchResultRef = e),

      results: this.props.searchResult,

      onClickOpenModalActive: this.onClickOpenModalActive,
      onClickOpenModalInActive: this.onClickOpenModalInActive,
      onClickConfirmChangeStatus: this.onClickConfirmChangeStatusHandler,
    };

    return (
      <TransferFeeSetUp
        {...props}
        {...this.state}
        modalRef={(e) => (this.modal = e)}
        modalActiveRef={(e) => (this.modalActiveRef = e)}
        modalInActiveRef={(e) => (this.modalInActiveRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.transferFeeSetup,
  }),
  {
    searchTransferFeeSetup,
    submitTransferFeeSetup,
    changeStatus,
    getCurrencyDigit,
  }
)(TransferFeeSetUpContainer);
