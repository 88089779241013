import React from 'react';

import Card from '../../common/Card';
import Link from '../../common/Link';
import Col2 from '../../common/Col2';
import Button from '../../common/Button';
import Select2 from '../../common/Select2';
import InputMask from '../../common/InputMask';
import DatePicker from '../../common/DatePicker';
import FormGroup2 from '../../common/FormGroup2';
import SpanStatus from '../../common/SpanStatus';
import RadioButton from '../../common/RadioButton';
import TablePaging from '../../common/TablePaging';
import MasterDropdown from '../../common/MasterDropdown';
import { toFixed, toLower, toNumber, getPermissionStr } from '../../common/helpper';
import MasterDropdownUI, {
  MASTER_PAYMENT_METHOD,
  MASTER_TRANSFER_METHOD,
} from '../../common/MasterDropdownUI';
import {
  MASTER_COMPANY,
  MASTER_CURRENCY,
  MASTER_BANK_CODE,
  MASTER_BANK_ACCOUNT,
} from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const row = 'row';
const required = true;

const permission = getPermissionStr('Cash Management', 'Plan & Approve Transaction');

const optRate = {
  prefix: '',
  digits: 8,
  digitsOptional: false,
  placeholder: '0.00000000',
};

export default ({ state, onChangeInputData, onSubmitCreateHandler, template = [] }) => {
  const pageTitle = state.mode === 'edit' ? 'Edit Cash Plan' : 'Create Cash Plan';
  const headerTitle =
    state.mode === 'edit' ? `Transaction No.: ${state.TransactionNo}` : 'Create Cash Plan';
  const setReadOnly = state.mode === 'edit';
  const statusApproved =
    !permission || toLower(permission) === 'view'
      ? true
      : state.mode === 'create'
        ? false
        : toLower(state.SourceType) !== 'cash plan'
          ? true
          : toLower(state.Status) !== 'wait for approve';

  let amountInBankCurrency = '';
  if (state.Amount && state.ExchangeRate)
    amountInBankCurrency =
      (state.Amount.toString() || '').replace(/,/g, '') *
      (state.ExchangeRate.toString() || '').replace(/,/g, '');

  let intercomAmountInBankCurrency = '';
  if (state.Amount && state.IntercomExchangeRate)
    intercomAmountInBankCurrency =
      (state.Amount.toString() || '').replace(/,/g, '') *
      (state.IntercomExchangeRate.toString() || '').replace(/,/g, '');

  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Cash Management <span className="icon icon-angle-double-right" />
            <Link txt="Plan & Approve Transaction" href="/cash-management/plan-approve" />
            <span className="icon icon-angle-double-right" /> {pageTitle}
            {setReadOnly ? (
              <>
                {' '}
                <span className="icon icon-angle-double-right" /> {state.TransactionNo}{' '}
                <span className="icon icon-angle-double-right" />{' '}
                <SpanStatus status={state.Status} />
              </>
            ) : null}
          </small>
        </p>
      </div>

      {toLower(permission).includes('view') && state.mode === 'create' ? (
        <div className="row text-center" style={{ height: '300px', marginTop: '15%' }}>
          <h2>YOUR PERMISSION CAN VIEW TRANSACTION ONLY!</h2>
        </div>
      ) : (
        <>
          <Card textHeader={headerTitle} number="1" bgHeader="bg-primary" cardActions={['toggler']}>
            <form onSubmit={onSubmitCreateHandler}>
              {state.mode === 'create' ? (
                <Card textHeader="Template" bgHeader="bg-primary">
                  <Col2 col={colX[0]}>
                    <Col2 col={colX[1]}>
                      <FormGroup2 text="From" required>
                        <Select2
                          value={state.NewTemplate}
                          notMultiple
                          onChange={(e) =>
                            onChangeInputData({
                              target: e,
                            })
                          }
                          name="NewTemplate"
                          required={false}
                          disabled={setReadOnly}
                        >
                          <option value="##__MANUAL__##">Manual</option>
                          {template.map((m) => (
                            <option value={m} key={m}>
                              {m}
                            </option>
                          ))}
                          <option value="New Template">New Template</option>
                        </Select2>
                      </FormGroup2>
                      {state.NewTemplate === 'New Template' ? (
                        <FormGroup2 text="Template Name" required>
                          <input
                            className="form-control"
                            type="text"
                            value={state.TemplateName}
                            onChange={onChangeInputData}
                            name="TemplateName"
                            required={required}
                          />
                        </FormGroup2>
                      ) : null}
                    </Col2>
                  </Col2>
                </Card>
              ) : null}

              <Card textHeader="Information" bgHeader="bg-info">
                <Col2 col={colX[0]}>
                  <Col2 col={colX[1]}>
                    <FormGroup2 text="Cash Type" required>
                      {setReadOnly ? (
                        <input
                          className="form-control"
                          type="text"
                          value={state.CashType || ''}
                          name="CashType"
                          required={required}
                          readOnly={setReadOnly}
                        />
                      ) : (
                        <div>
                          <RadioButton
                            text="Inflow"
                            value="Inflow"
                            checked={state.CashType === 'Inflow'}
                            onChange={onChangeInputData}
                            name="CashType"
                            required={required}
                          />
                          <RadioButton
                            text="Outflow"
                            value="Outflow"
                            checked={state.CashType === 'Outflow'}
                            onChange={onChangeInputData}
                            name="CashType"
                          />
                        </div>
                      )}
                    </FormGroup2>

                    <FormGroup2 text="Value Date" required>
                      {setReadOnly ? (
                        <input
                          className="form-control"
                          type="text"
                          value={state.ValueDateStr}
                          name="ValueDateStr"
                          required={required}
                          readOnly
                        />
                      ) : (
                        <DatePicker
                          value={state.ValueDateStr}
                          onChange={onChangeInputData}
                          name="ValueDateStr"
                          required={required}
                          checkHoliday
                          option={{
                            startDate: 'd',
                            daysOfWeekDisabled: '0,6',
                            todayHighlight: true,
                          }}
                          disabled={setReadOnly}
                        />
                      )}
                    </FormGroup2>
                  </Col2>

                  <Col2 col={colX[1]}>
                    <FormGroup2 text="Company" required>
                      <MasterDropdown
                        masterType={MASTER_COMPANY}
                        isChoose
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        onChange={(e) =>
                          onChangeInputData({
                            target: e,
                          })
                        }
                        name="CompanyCode"
                        value={state.CompanyCode}
                        required={required}
                        disabled={setReadOnly}
                      />
                    </FormGroup2>
                  </Col2>
                </Col2>

                <Col2 col={colX[0]}>
                  <Col2 col={colX[1]}>
                    <FormGroup2 text="Bank" required>
                      {state.CompanyCode ? (
                        <MasterDropdown
                          masterType={MASTER_BANK_CODE}
                          isChoose
                          noValidateOption
                          saveLocalState
                          notMultipleSelect2
                          onChange={(e) =>
                            onChangeInputData({
                              target: e,
                            })
                          }
                          value={state.BankCode}
                          name="BankCode"
                          isGetAll
                          customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                          companyCode={state.CompanyCode}
                          required={required}
                          disabled={setReadOnly}
                        />
                      ) : (
                        <MasterDropdownUI
                          isChoose
                          notMultipleSelect2
                          value={state.BankCode}
                          onChange={(e) =>
                            onChangeInputData({
                              target: e,
                            })
                          }
                          name="BankCode"
                          required
                          options={null}
                        />
                      )}
                    </FormGroup2>

                    <FormGroup2 text="Bank Account" required>
                      {state.BankCode ? (
                        <MasterDropdown
                          masterType={MASTER_BANK_ACCOUNT}
                          isChoose
                          noValidateOption
                          saveLocalState
                          notMultipleSelect2
                          onChange={(e) =>
                            onChangeInputData({
                              target: e,
                            })
                          }
                          customeValue={(m) => `${m.bankAccountNo}`}
                          value={state.BankAccountNo}
                          name="BankAccountNo"
                          bankCode={state.BankCode}
                          companyCode={state.CompanyCode}
                          required={required}
                          disabled={setReadOnly}
                        />
                      ) : (
                        <MasterDropdownUI
                          isChoose
                          notMultipleSelect2
                          value={state.BankAccountNo}
                          onChange={(e) =>
                            onChangeInputData({
                              target: e,
                            })
                          }
                          name="BankAccountNo"
                          required
                          options={null}
                        />
                      )}
                    </FormGroup2>
                  </Col2>

                  <Col2 col={colX[1]}>
                    <FormGroup2 text="Amount" required>
                      <InputMask
                        className="form-control"
                        format="currency"
                        option={state.inputMaskAmount}
                        onChange={onChangeInputData}
                        value={state.Amount}
                        required={required}
                        readOnly={statusApproved}
                        name="Amount"
                      />
                    </FormGroup2>

                    <FormGroup2 text="Currency" required>
                      <MasterDropdown
                        masterType={MASTER_CURRENCY}
                        isChoose
                        noValidateOption
                        saveLocalState
                        notMultipleSelect2
                        onChange={(e) =>
                          onChangeInputData({
                            target: e,
                          })
                        }
                        value={state.Currency}
                        name="Currency"
                        disabled={statusApproved}
                        required={required}
                      />
                    </FormGroup2>
                  </Col2>
                </Col2>

                {state.BankCurrency && state.Currency && state.BankCurrency !== state.Currency ? (
                  <Col2 col={colX[0]}>
                    <Col2 col={colX[1]}>
                      <FormGroup2 text="Exchange Rate" required>
                        <InputMask
                          className="form-control"
                          format="currency"
                          option={optRate}
                          onChange={onChangeInputData}
                          value={state.ExchangeRate}
                          name="ExchangeRate"
                          readOnly={statusApproved}
                          required={required}
                        />
                      </FormGroup2>

                      <FormGroup2 text="Amount in Bank Currency" required>
                        <div className="input-group">
                          <InputMask
                            className="form-control"
                            format="currency"
                            option={state.inputMaskBankCurrency}
                            value={amountInBankCurrency}
                            name="AmountInBankCurrency"
                            readOnly
                          />
                          <span className="input-group-addon">{state.BankCurrency}</span>
                        </div>
                      </FormGroup2>
                    </Col2>
                  </Col2>
                ) : null}
              </Card>

              <Card textHeader="Business Partner" bgHeader="bg-warning">
                <Col2 col={colX[0]}>
                  <Col2 col={colX[1]}>
                    <FormGroup2 text="Partner Type" required>
                      {setReadOnly ? (
                        <input
                          className="form-control"
                          type="text"
                          value={state.PartnerType || ''}
                          name="PartnerType"
                          required={required}
                          readOnly={setReadOnly}
                        />
                      ) : (
                        <div>
                          <RadioButton
                            text="Other"
                            value="Other"
                            checked={state.PartnerType === 'Other'}
                            onChange={onChangeInputData}
                            name="PartnerType"
                            required={required}
                          />
                          <RadioButton
                            text="Intercom"
                            value="Intercom"
                            checked={state.PartnerType === 'Intercom'}
                            onChange={onChangeInputData}
                            name="PartnerType"
                          />
                        </div>
                      )}
                    </FormGroup2>

                    <div>
                      {state.PartnerType === 'Other' ? (
                        <FormGroup2
                          text="Vendor/Customer Name"
                          required
                          maxLength={200}
                          length={(state.IntercomCompanyName || '').length}
                        >
                          <input
                            className="form-control"
                            type="text"
                            value={state.IntercomCompanyName || ''}
                            onChange={onChangeInputData}
                            name="IntercomCompanyName"
                            required={required}
                            disabled={setReadOnly}
                            maxLength={200}
                          />
                        </FormGroup2>
                      ) : (
                        <FormGroup2 text="Company" required>
                          <MasterDropdown
                            masterType={MASTER_COMPANY}
                            isChoose
                            noValidateOption
                            saveLocalState
                            notMultipleSelect2
                            onChange={(e) =>
                              onChangeInputData({
                                target: e,
                              })
                            }
                            name="IntercomCompanyCode"
                            isGetAll={toLower(state.CashType) === 'outflow'}
                            value={state.IntercomCompanyCode}
                            isBusinessPartner
                            required={required}
                            disabled={setReadOnly}
                          />
                        </FormGroup2>
                      )}
                    </div>
                  </Col2>

                  {state.PartnerType === 'Intercom' ? (
                    <Col2 col={colX[1]}>
                      <FormGroup2 text="Bank Intercom" required>
                        {state.IntercomCompanyCode ? (
                          <MasterDropdown
                            masterType={MASTER_BANK_CODE}
                            isChoose
                            noValidateOption
                            saveLocalState
                            notMultipleSelect2
                            onChange={(e) =>
                              onChangeInputData({
                                target: e,
                              })
                            }
                            value={state.IntercomBankCode}
                            name="IntercomBankCode"
                            isGetAll
                            customeLabel={(m) => `${m.bankCode} | ${m.bankName}`}
                            companyCode={state.IntercomCompanyCode}
                            isOutFlow={toLower(state.CashType) === 'outflow'}
                            required={required}
                            disabled={setReadOnly}
                          />
                        ) : (
                          <MasterDropdownUI
                            isChoose
                            notMultipleSelect2
                            value={state.IntercomBankCode}
                            onChange={(e) =>
                              onChangeInputData({
                                target: e,
                              })
                            }
                            name="IntercomBankCode"
                            options={null}
                          />
                        )}
                      </FormGroup2>

                      <FormGroup2 text="Bank Account Intercom" required>
                        {state.IntercomBankCode ? (
                          <MasterDropdown
                            masterType={MASTER_BANK_ACCOUNT}
                            isChoose
                            noValidateOption
                            saveLocalState
                            notMultipleSelect2
                            onChange={(e) =>
                              onChangeInputData({
                                target: e,
                              })
                            }
                            customeValue={(m) => `${m.bankAccountNo}`}
                            value={state.IntercomBankAccountNo}
                            name="IntercomBankAccountNo"
                            bankCode={state.IntercomBankCode}
                            companyCode={state.IntercomCompanyCode}
                            isOutFlow={toLower(state.CashType) === 'outflow'}
                            required={required}
                            disabled={setReadOnly}
                          />
                        ) : (
                          <MasterDropdownUI
                            isChoose
                            notMultipleSelect2
                            value={state.IntercomBankAccountNo}
                            onChange={(e) =>
                              onChangeInputData({
                                target: e,
                              })
                            }
                            name="IntercomBankAccountNo"
                            options={null}
                          />
                        )}
                      </FormGroup2>
                    </Col2>
                  ) : null}
                </Col2>

                <div className="row gutter-xs">
                  {state.PartnerType === 'Intercom' &&
                  state.IntercomBankCurrency &&
                  state.Currency &&
                  state.IntercomBankCurrency !== state.Currency ? (
                    <div className="col-sm-12 col-md-6">
                      <div className="row gutter-xs">
                        <div className="col-sm-6">
                          <FormGroup2 text="Exchange Rate Intercom" required>
                            <InputMask
                              className="form-control"
                              format="currency"
                              option={optRate}
                              onChange={onChangeInputData}
                              value={state.IntercomExchangeRate}
                              name="IntercomExchangeRate"
                              required={required}
                              readOnly={statusApproved}
                            />
                          </FormGroup2>
                        </div>

                        <div className="col-sm-6">
                          <FormGroup2 text="Amount In Bank Currency Intercom" required>
                            <div className="input-group">
                              <InputMask
                                className="form-control"
                                format="currency"
                                option={state.inputMaskIntercom}
                                value={intercomAmountInBankCurrency}
                                name="IntercomAmountInBankCurrency"
                                readOnly
                              />
                              <span className="input-group-addon">
                                {state.IntercomBankCurrency}
                              </span>
                            </div>
                          </FormGroup2>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-sm-12 col-md-6">
                    <div className="row gutter-xs">
                      <div className="col-sm-6">
                        {(state.PartnerType === 'Intercom' &&
                          state.Currency &&
                          state.BankCurrency &&
                          state.IntercomBankCurrency &&
                          (state.Currency !== state.BankCurrency ||
                            state.Currency !== state.IntercomBankCurrency)) ||
                        (state.PartnerType === 'Other' &&
                          state.Currency &&
                          state.BankCurrency &&
                          state.Currency !== state.BankCurrency) ? (
                          <FormGroup2 text="Payment Method" required>
                            <MasterDropdownUI
                              masterType={MASTER_PAYMENT_METHOD}
                              isChoose
                              notMultiple
                              value={state.PaymentMethod}
                              onChange={onChangeInputData}
                              name="PaymentMethod"
                              required={required}
                              disabled={statusApproved}
                              options={null}
                            />
                          </FormGroup2>
                        ) : (
                          <FormGroup2 text="Transfer Method" required>
                            <MasterDropdownUI
                              masterType={MASTER_TRANSFER_METHOD}
                              isChoose
                              notMultiple
                              value={state.TransferMethod}
                              onChange={onChangeInputData}
                              name="TransferMethod"
                              required={required}
                              disabled={statusApproved}
                              options={[
                                'Bahtnet',
                                'Cheque',
                                'Bank Transfer',
                                'Auto Deduct',
                                'Internet Banking',
                              ]}
                            />
                          </FormGroup2>
                        )}
                      </div>
                      <div className="col-sm-6">
                        <FormGroup2
                          text="Transaction Details"
                          required
                          maxLength={200}
                          length={(state.TransactionDetail || '').length}
                        >
                          <textarea
                            className="form-control"
                            rows="3"
                            value={state.TransactionDetail}
                            onChange={onChangeInputData}
                            name="TransactionDetail"
                            maxLength={200}
                            readOnly={statusApproved}
                            required={required}
                          />
                        </FormGroup2>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>

              {!statusApproved ? (
                <div className="row gutter-xs">
                  <br />
                  <center>
                    <Button txt="SAVE" icon="save" type="submit" className="btn-success" />
                  </center>
                </div>
              ) : null}
            </form>
          </Card>

          {!statusApproved && (state.createHistory || []).length ? (
            <Card
              textHeader="Create History"
              number="2"
              bgHeader="bg-primary"
              cardActions={['toggler']}
            >
              <TablePaging colNumber={17} renderRow={renderRow()} value={state.createHistory}>
                <thead>
                  <tr>
                    <th className="th-success">Transaction No.</th>
                    <th className="th-success">Finance Group</th>
                    <th className="th-success">Business Unit</th>
                    <th className="th-success">Company Code</th>
                    <th className="th-success">Company Name</th>
                    <th className="th-success">Value Date</th>
                    <th className="th-success">Cash Type</th>
                    <th className="th-success">
                      Vendor/Customer
                      <br /> Name/Intercom
                    </th>
                    <th className="th-success">Transaction Detail</th>
                    <th className="th-success">Bank</th>
                    <th className="th-success">Bank Account</th>
                    <th className="th-success">
                      Transfer/Payment
                      <br /> Method
                    </th>
                    <th className="th-success">Amount</th>
                    <th className="th-success">Currency</th>
                    <th className="th-success">Exchange Rate</th>
                    <th className="th-success">
                      Amount in
                      <br /> Bank Currency
                    </th>
                    <th className="th-success">Create Date</th>
                  </tr>
                </thead>
              </TablePaging>
            </Card>
          ) : null}
        </>
      )}
    </div>
  );
};

const renderRow = () => (m, i) => (
  <tr key={i}>
    <td>
      <a href={`/cash-management/cash-plan/${m.transactionNo}`} target="_blank" rel="noreferrer">
        {m.transactionNo}
      </a>
    </td>
    <td>{m.financeGroupName}</td>
    <td>{m.businessUnitName}</td>
    <td style={{ textAlign: 'center' }}>{m.companyCode}</td>
    <td>{m.companyName}</td>
    <td style={{ textAlign: 'center' }}>{m.valueDateStr}</td>
    <td>{m.cashType}</td>
    <td>{m.intercomCompanyName}</td>
    <td>{m.transactionDetail}</td>
    <td>
      {m.bankAbbreviate} | {m.bankName}
    </td>
    <td>
      {m.branchName} | {m.accountTypeCode} | {m.bankCurrency}
      <br />
      {m.bankAccountNo}
    </td>
    <td>{!m.transferMethod ? m.paymentMethod : m.transferMethod}</td>
    <td style={{ textAlign: 'right' }}>{setAmount(m.cashType, m.amount, m.currencyDigit)}</td>
    <td style={{ textAlign: 'center' }}>{m.currency}</td>
    <td style={{ textAlign: 'right' }}>{setExchangeRate(m.exchangeRate)}</td>
    <td style={{ textAlign: 'right' }}>
      {setAmountInCurrency(
        m.cashType,
        m.amountWithExchangeRate,
        m.bankCurrencyDigit,
        m.exchangeRate
      )}
    </td>
    <td style={{ textAlign: 'center' }}>{m.createdDateStr}</td>
  </tr>
);

const setAmount = (cashtype, number, digit) => {
  number = Math.abs(number);
  if (toLower(cashtype) === 'outflow')
    return <span style={{ color: '#e64a19' }}>({toFixed(number, digit)})</span>;
  return toFixed(number, digit);
};

const setExchangeRate = (rate) => {
  if (rate === 0 || isNaN(rate) || rate == null) return '-';
  return toNumber(Math.abs(rate), 8);
};

const setAmountInCurrency = (cashtype, amount, digit, rate) => {
  if (rate === 0 || isNaN(rate) || rate == null) return '-';
  return setAmount(cashtype, amount, digit);
};
