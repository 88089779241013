import { defaultImportExcelResult } from '../configuration';
import {
  UPDATE_SEARCH_RESULT_BOOKING_FORWARD,
  RECEIVE_SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD,
  RECEIVE_VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD,
} from './action';

const init = {
  searchResult: [],
  importExcelResult: defaultImportExcelResult,
};

export default (state = init, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_RESULT_BOOKING_FORWARD:
      return {
        ...state,
        searchResult: state.searchResult.map((m, i) => {
          if (action.payload.index === null || action.payload.index === i) {
            if (action.payload.name === 'IsSelected' && !m.canSelect) return m;
            return {
              ...m,
              [action.payload.name]: action.payload.value,
            };
          }
          return m;
        }),
      };
    case RECEIVE_VIEW_IMPORTEXCEL_EXPOSURE_BOOKING_FORWARD:
      return {
        ...state,
        importExcelResult: action.payload,
      };
    case RECEIVE_SEARCH_CREATE_EXPOSURE_BOOKING_FORWARD:
      return {
        ...state,
        searchResult: [...action.payload],
      };
    default:
      return state;
  }
};
