import { RSAA } from 'redux-api-middleware';

import { endpoint, jsonContentTypeHeader } from '../configuration';

export const EDIT_MASTER_COMPANY_CRITERIA = 'EDIT_MASTER_COMPANY_CRITERIA';
export const CLEAR_MASTER_COMPANY_CRITERIA = 'CLEAR_MASTER_COMPANY_CRITERIA';

export const UPDATE_SEARCH_RESULT_MASTER_COMPANY = 'UPDATE_SEARCH_RESULT_MASTER_COMPANY';

export const REQUEST_SEARCH_MASTER_COMPANY = '@@request/SEARCH_MASTER_COMPANY';
export const RECEIVE_SEARCH_MASTER_COMPANY = '@@receive/SEARCH_MASTER_COMPANY';
export const FAIL_SEARCH_MASTER_COMPANY = '@@fail/SEARCH_MASTER_COMPANY';

export const REQUEST_GET_COMPANY_DETAIL = '@@request/GET_COMPANY_DETAIL';
export const RECEIVE_GET_COMPANY_DETAIL = '@@receive/GET_COMPANY_DETAIL';
export const FAIL_GET_COMPANY_DETAIL = '@@fail/GET_COMPANY_DETAIL';

export const REQUEST_GET_COMPANY_SUB = '@@request/GET_COMPANY_SUB';
export const RECEIVE_GET_COMPANY_SUB = '@@receive/GET_COMPANY_SUB';
export const FAIL_GET_COMPANY_SUB = '@@fail/GET_COMPANY_SUB';

export const REQUEST_GET_COMPANY_ADD = '@@request/GET_COMPANY_ADD';
export const RECEIVE_GET_COMPANY_ADD = '@@receive/GET_COMPANY_ADD';
export const FAIL_GET_COMPANY_ADD = '@@fail/GET_COMPANY_ADD';

export const REQUEST_SAVE_COMPANY = '@@request/SAVE_COMPANY';
export const RECEIVE_SAVE_COMPANY = '@@receive/SAVE_COMPANY';
export const FAIL_SAVE_COMPANY = '@@fail/SAVE_COMPANY';

export const REQUEST_ADD_SUB_COMPANY = '@@request/ADD_SUB_COMPANY';
export const RECEIVE_ADD_SUB_COMPANY =
  '@@receive-The record was successfully saved/ADD_SUB_COMPANY';
export const FAIL_ADD_SUB_COMPANY = '@@fail/ADD_SUB_COMPANY';

export const REQUEST_DELETE_MASTER_COMPANY = '@@request/DELETE_MASTER_COMPANY';
export const RECEIVE_DELETE_MASTER_COMPANY =
  '@@receive-The record was successfully change status/DELETE_MASTER_COMPANY';
export const FAIL_DELETE_MASTER_COMPANY = '@@fail/DELETE_MASTER_COMPANY';

export const REQUEST_GET_COMPANY_EFFECTIVE_DATE = '@@request/GET_COMPANY_EFFECTIVE_DATE';
export const RECEIVE_GET_COMPANY_EFFECTIVE_DATE = '@@receive/GET_COMPANY_EFFECTIVE_DATE';
export const FAIL_GET_COMPANY_EFFECTIVE_DATE = '@@fail/GET_COMPANY_EFFECTIVE_DATE';

export const REQUEST_APPROVE_MASTER_COMPANY = '@@request/APPROVE_MASTER_COMPANY';
export const RECEIVE_APPROVE_MASTER_COMPANY = '@@receive/APPROVE_MASTER_COMPANY';
export const FAIL_APPROVE_MASTER_COMPANY = '@@fail/APPROVE_MASTER_COMPANY';

export const approveMasterCompany = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/approvemastercompany`),
    method: 'POST',
    headers: jsonContentTypeHeader,
    body: JSON.stringify(data),
    types: [
      REQUEST_APPROVE_MASTER_COMPANY,
      RECEIVE_APPROVE_MASTER_COMPANY,
      FAIL_APPROVE_MASTER_COMPANY,
    ],
  },
});

export const getMasterCompanyEffectiveDate = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmastercompany', criteria),
    method: 'GET',
    types: [
      REQUEST_GET_COMPANY_EFFECTIVE_DATE,
      RECEIVE_GET_COMPANY_EFFECTIVE_DATE,
      FAIL_GET_COMPANY_EFFECTIVE_DATE,
    ],
  },
});

export const deleteMasterCompany = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/deletemastercompany`, data),
    method: 'POST',
    headers: jsonContentTypeHeader,
    types: [
      REQUEST_DELETE_MASTER_COMPANY,
      RECEIVE_DELETE_MASTER_COMPANY,
      FAIL_DELETE_MASTER_COMPANY,
    ],
  },
});

export const saveSubCompany = (data, company) => async (dispatch) => {
  const actionResponse = await dispatch({
    [RSAA]: {
      endpoint: endpoint(`/master/savemastercompany`),
      method: 'POST',
      body: JSON.stringify(data),
      headers: jsonContentTypeHeader,
      types: [REQUEST_ADD_SUB_COMPANY, RECEIVE_ADD_SUB_COMPANY, FAIL_ADD_SUB_COMPANY],
    },
  });

  if (!actionResponse.error) {
    dispatch(
      getMasterCompanyAdd({
        CompanyCode: company,
        Mode: 'get',
      })
    );
    dispatch(
      getMasterCompanySub({
        CompanyCode: company,
        Mode: 'sub',
      })
    );
  }

  return actionResponse;
};

export const saveCompany = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/savemastercompany`),
    method: 'POST',
    body: JSON.stringify(data),
    headers: jsonContentTypeHeader,
    types: [REQUEST_SAVE_COMPANY, RECEIVE_SAVE_COMPANY, FAIL_SAVE_COMPANY],
  },
});

export const getMasterCompanyAdd = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmastercompanySub', criteria),
    method: 'GET',
    types: [REQUEST_GET_COMPANY_ADD, RECEIVE_GET_COMPANY_ADD, FAIL_GET_COMPANY_ADD],
  },
});

export const getMasterCompanySub = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmastercompanysub', criteria),
    method: 'GET',
    types: [REQUEST_GET_COMPANY_SUB, RECEIVE_GET_COMPANY_SUB, FAIL_GET_COMPANY_SUB],
  },
});

export const getCompanyDetail = (data) => ({
  [RSAA]: {
    endpoint: endpoint(`/master/getmastercompanydetail/`, data),
    method: 'GET',
    types: [REQUEST_GET_COMPANY_DETAIL, RECEIVE_GET_COMPANY_DETAIL, FAIL_GET_COMPANY_DETAIL],
  },
});

export const searchMasterCompany = (criteria) => ({
  [RSAA]: {
    endpoint: endpoint('/master/getmastercompany', criteria),
    method: 'GET',
    types: [
      REQUEST_SEARCH_MASTER_COMPANY,
      RECEIVE_SEARCH_MASTER_COMPANY,
      FAIL_SEARCH_MASTER_COMPANY,
    ],
  },
});

export const updateSearchResult = (name, value, index) => ({
  type: UPDATE_SEARCH_RESULT_MASTER_COMPANY,
  payload: {
    name,
    value,
    index,
  },
});

export const clearCriteria = () => ({
  type: CLEAR_MASTER_COMPANY_CRITERIA,
});

export const editCriteria = (name, value) => ({
  type: EDIT_MASTER_COMPANY_CRITERIA,
  payload: {
    name,
    value,
  },
});
