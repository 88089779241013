import { connect } from 'react-redux';
import React, { Component } from 'react';

import Securities from './Securities';
import { addAlert } from '../../reducers/layout/action';
import { getMasterUserDetail } from '../../reducers/masterUser/action';
import {
  genXml,
  fiPosting,
  saveManual,
  saveAccountingFlag,
} from '../../reducers/foreignExchange/action';
import {
  searchSecurities,
  updateSecurities,
  changePostingDate,
  updateSearchResult,
} from '../../reducers/securities/action';
import {
  toLower,
  checkWeekend,
  clearCheckBoxAllTable,
  setCheckedCheckboxDataTableByInputElement,
} from '../../common/helpper';

const $ = window.jQuery;
export const DATATABLE_ID = 'table-result-accounting-securities';
export const COL_CHECKBOX_CONTEXT = '.col-label-checkbox';
export const COL_CHOOSE_ALL_CONTEXT = '.label-checkbox.fix.choose-all';

const jwt = JSON.parse(localStorage.getItem('jwt')) || { username: '' };
const username = jwt.username || '';

const initCriteria = {
  FinanceGroup: [''],
  BusinessUnits: [''],
  Companies: [''],
  TransactionCurrency: [''],
  ProductType: 'Bond',
  TransactionType: [''],
  Partner: [''],
  PostingProcess: '',
  GenValnClassTerm: '',
  ValueDateStr: '',
  PostingDateStr: '',
  TransactionNo: '',
  ContractNo: '',
  AccountingStandard: '',
  InstanceCode: [''],
  PostGLStatus: '',
  LineItem: 1,
};

const initModalFunciton = {
  transaction: [],
  catergory: 'bd',
  flowType: '',
  flowNo: '',
  status: '',
};

const initModalEdit = {
  PostingDateStr: '',
  FlowNos: [],
  Source: 'bd',
};

const initModalManual = {
  PostingDocumentNo: '',
  PostingMessage: '',
  ReverseDocumentNo: '',
  ReverseMessage: '',
  ClearingDocumentNo: '',
  ClearingMessage: '',

  FlowNos: [],
  TransactionIds: [],
  Type: '',
  Source: 'bd',
};

const initPost = {
  resultFail: [],
  passLength: 0,
};

class SecuritiesContainer extends Component {
  state = {
    criteria: {
      ...initCriteria,
    },
    modalEdit: {
      ...initModalEdit,
    },
    modalFunction: {
      ...initModalFunciton,
    },
    modalManual: {
      ...initModalManual,
    },
    modalPost: {
      ...initPost,
    },
    isAdmin: false,
  };

  dataTableSearchResultRef = null;

  componentDidMount() {
    $('body').on('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').on('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').on('click', `button.action-reverse`, this.onClickOpenModalReverse);

    this.props.getMasterUserDetail(username).then((response) => {
      if (response.error || !response.payload) return;
      const roles = response.payload.resultRole || [];
      const findAdmin = roles.find((f) => f.roleName === 'Admin Accounting');
      let isAdmin = false;
      if (findAdmin) {
        isAdmin = findAdmin.isActiveUserRole || false;
      }

      this.setState({
        ...this.state,
        isAdmin,
      });
    });
  }

  componentWillUnmount() {
    $('body').off('click', `td${COL_CHECKBOX_CONTEXT}`, this.onClickColumnLabelCheckbox);
    $('body').off('click', `th ${COL_CHOOSE_ALL_CONTEXT}`, this.onClickCheckboxSelecteAll);
    $('body').off('click', `button.action-reverse`);
  }

  onChangeSearchHeadler = (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [e.target.name]: e.target.value,
      },
    });
  };

  onChangeSelect2Handler = (e) => {
    if (e.value.length < 1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: [''],
        },
      });
    } else if (e.value.indexOf('') !== -1) {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value.filter((item) => item !== ''),
        },
      });
    } else {
      this.setState({
        criteria: {
          ...this.state.criteria,
          [e.name]: e.value,
        },
      });
    }
  };

  onClickSearchHandler = (e) => {
    e.preventDefault();
    clearCheckBoxAllTable(DATATABLE_ID);
    this.props.searchSecurities({ ...this.state.criteria });
  };

  onClickClearSearch = () => {
    this.setState({
      criteria: {
        ...initCriteria,
      },
    });
  };

  onClickCheckboxSelecteAll = (e) => {
    const checked = !!~e.target.className.indexOf('checked');
    this.props.updateSearchResult('IsSelected', !checked, null);
    if (checked) e.target.classList.remove('checked');
    else e.target.classList.add('checked');
  };

  onClickColumnLabelCheckbox = (e) => {
    const result = setCheckedCheckboxDataTableByInputElement(
      e,
      this.dataTableSearchResultRef.$dataTable
    );
    this.props.searchResult[result.row].IsSelected = result.checked;
    this.forceUpdate();
  };

  // Post
  onClickOpenModalPost = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Post!',
      });
      return;
    }

    const passData = selectedData.filter((f) => toLower(f.postGlStatus) !== 'completed');
    const failLength = selectedData.length - passData.length;

    const transactionNos = passData.map((m) => m.transactionNo);
    this.setState(
      {
        modalFunction: {
          ...initModalFunciton,
          transaction: transactionNos.join(','),
          flowType: 'normal',
          flowNo: selectedData.map((m) => m.flowNo).join(','),
        },
        modalPost: {
          ...initPost,
          resultFail: failLength
            ? [
                <span>
                  {failLength} item(s) selected,
                  <br />
                  because the transaction has "Completed" post G/L status.
                </span>,
              ]
            : [],
          passLength: passData.length,
        },
      },
      this.modalPostRef.open
    );
  };

  onClickConfirmModalPost = () => {
    this.props.fiPosting({ ...this.state.modalFunction }).then((response) => {
      if (response.error) return;

      this.props.addAlert({
        type: 'success',
        title: 'Success',
        body: 'The transaction was successfully posted',
      });

      this.props.searchSecurities({ ...this.state.criteria });
      clearCheckBoxAllTable(DATATABLE_ID);
      this.modalPostRef.close();
    });
  };

  // Reverse
  onClickOpenModalReverse = (e) => {
    const $td = this.dataTableSearchResultRef.$dataTable;
    const row = $td.fixedColumns().rowIndex($(e.currentTarget).closest('tr'));
    const rowData = $td.row(row).data();

    this.setState(
      {
        modalFunction: {
          ...initModalFunciton,
          transaction: rowData.transactionNo,
          flowType: 'reverse',
          flowNo: rowData.flowNo,
        },
      },
      this.modalReverseRef.open
    );
  };

  onClickConfirmModalReverse = () => {
    this.props.fiPosting({ ...this.state.modalFunction }).then((response) => {
      if (response.error) return;

      this.props.addAlert({
        type: 'wating',
        title: 'In progress',
        body: 'Reverse In progress.',
      });

      this.props.searchSecurities({ ...this.state.criteria });
      clearCheckBoxAllTable(DATATABLE_ID);
      this.modalReverseRef.close();
    });
  };

  // Gen Xml
  onClickOpenModalGenXml = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Gen XML!',
      });
      return;
    }

    const transactionNos = selectedData.map((m) => m.transactionNo);
    this.setState(
      {
        modalFunction: {
          ...initModalFunciton,
          transaction: transactionNos.join(','),
        },
      },
      this.modalGenXmlRef.open
    );
  };

  onClickConfirmModalGenXml = () => {
    this.props.genXml({ ...this.state.modalFunction }).then((response) => {
      if (response.error) return;
      this.props.searchSecurities({ ...this.state.criteria });
      clearCheckBoxAllTable(DATATABLE_ID);
      this.modalGenXmlRef.close();
    });
  };

  // Edit
  onClickOpenModalEdit = () => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Change Posting Date!',
      });
      return;
    }

    this.setState(
      {
        modalEdit: {
          ...initModalEdit,
          FlowNos: selectedData.map((m) => m.flowNo),
        },
      },
      this.modalEditRef.open
    );
  };

  onChangeModalEdit = (e) => {
    this.setState({
      modalEdit: {
        ...this.state.modalEdit,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalEdit = (e) => {
    e.preventDefault();

    if (checkWeekend(this.state.modalEdit.PostingDate)) {
      this.props.addAlert({
        title: 'Error',
        body: 'Posting Date can not be Weekend.',
        type: 'warning',
      });
      return;
    }

    this.props
      .changePostingDate(
        {
          ...this.state.modalEdit,
        },
        { ...this.state.criteria }
      )
      .then(() => {
        clearCheckBoxAllTable(DATATABLE_ID);
        this.modalEditRef.close();
      });
  };

  // Manual
  onClickOpenModalManual = (type) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: `Please select item for Manual ${type[0].toUpperCase()}${type.substr(1)}!`,
      });
      return;
    }

    let checkStatusPost = true;
    let checkStatusReverse = true;
    let checkStatusClearing = true;
    let checkPost = true;
    let checkReverse = true;
    let checkClearing = true;
    selectedData.forEach((m) => {
      if (type === 'post') {
        if (m.postingDocumentNo || m.postingMessage) checkPost = false;
        if (toLower(m.postingStatus) !== 'completed') checkStatusPost = false;
      } else if (type === 'reverse') {
        if (m.reverseDocumentNo || m.reverseMessage || m.clearingDocumentNo || m.clearingMessage)
          checkReverse = false;
        if (toLower(m.reverseStatus) !== 'completed') checkStatusReverse = false;
      } else if (type === 'clearing') {
        if (m.clearingDocumentNo || m.clearingMessage) checkClearing = false;
        if (toLower(m.clearingStatus) !== 'completed') checkStatusClearing = false;
      }
    });

    if (type === 'post' && !checkStatusPost) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected, Posting Status must be Completed!',
      });
      return;
    }
    if (type === 'reverse' && !checkStatusReverse) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected, Reverse Status must be Completed!',
      });
      return;
    }
    if (type === 'clearing' && !checkStatusClearing) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected, Clearing Status must be Completed!',
      });
      return;
    }

    if (type === 'post' && !checkPost) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected, Posting Document No. and Posting Message must be blank!',
      });
      return;
    }
    if (type === 'reverse' && !checkReverse) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected, Reverse Document No., Reverse Message, Clearing Document No. and Clearing Message must be blank!',
      });
      return;
    }
    if (type === 'clearing' && !checkClearing) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction you selected, Clearing Document No. and Clearing Message must be blank!',
      });
      return;
    }

    this.setState(
      {
        modalManual: {
          ...initModalManual,
          FlowNos: selectedData.map((m) => m.flowNo),
          TransactionIds: selectedData.map((m) => m.transactionId),
          Type: type,
        },
      },
      this.modalManualRef.open
    );
  };

  onChangeModalManual = (e) => {
    this.setState({
      modalManual: {
        ...this.state.modalManual,
        [e.target.name]: e.target.value,
      },
    });
  };

  onSubmitModalManual = (e) => {
    e.preventDefault();
    this.props.saveManual({ ...this.state.modalManual }).then(() => {
      clearCheckBoxAllTable(DATATABLE_ID);
      this.props.searchSecurities({ ...this.state.criteria });
      this.modalManualRef.close();
    });
  };

  // Flag
  onClickOpenModalFlag = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Flag No Post!',
      });
      return;
    }

    let check = true;
    selectedData.forEach((m) => {
      if (!m.isNoPost) {
        check = false;
      }
    });
    if (!check) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction your selected can not flag!',
      });
      return;
    }

    const flowNos = selectedData.map((m) => m.flowNo);
    const transactionNos = selectedData.map((m) => m.transactionId);
    this.setState(
      {
        modalFunction: {
          ...initModalFunciton,
          transaction: transactionNos.join(','),
          flowNo: flowNos.join(','),
          status: 'No Post',
        },
      },
      this.modalFlagRef.open
    );
  };

  onClickOpenModalUnFlag = (e) => {
    const selectedData = this.props.searchResult.filter((f) => f.IsSelected);
    if (!selectedData.length) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Please select item for Unflag!',
      });
      return;
    }

    let check = true;
    selectedData.forEach((m) => {
      if (!m.isPost) {
        check = false;
      }
    });
    if (!check) {
      this.props.addAlert({
        title: 'Error',
        type: 'error',
        body: 'Transaction your selected can not unflag!',
      });
      return;
    }

    const flowNos = selectedData.map((m) => m.flowNo);
    const transactionNos = selectedData.map((m) => m.transactionId);
    this.setState(
      {
        modalFunction: {
          ...initModalFunciton,
          transaction: transactionNos.join(','),
          flowNo: flowNos.join(','),
          status: 'New',
        },
      },
      this.modalUnFlagRef.open
    );
  };

  onClickSubmitModalFlag = (e) => {
    this.props.saveAccountingFlag({ ...this.state.modalFunction }).then((response) => {
      if (response.error) return;

      this.props.addAlert({
        type: 'success',
        title: 'Success',
        body: 'The transaction was successfully posted',
      });

      this.props.searchSecurities({ ...this.state.criteria });
      clearCheckBoxAllTable(DATATABLE_ID);
      this.modalFlagRef.close();
      this.modalUnFlagRef.close();
    });
  };

  render() {
    const props = {
      onChangeSelect2: this.onChangeSelect2Handler,
      onChangeSearch: this.onChangeSearchHeadler,
      onClickSearch: this.onClickSearchHandler,
      onClickClearSearch: this.onClickClearSearch,
      dataTableRef: (e) => (this.dataTableSearchResultRef = e),
      results: this.props.searchResult,

      onClickOpenModalPost: this.onClickOpenModalPost,
      onClickConfirmModalPost: this.onClickConfirmModalPost,

      onClickConfirmModalReverse: this.onClickConfirmModalReverse,

      onClickOpenModalGenXml: this.onClickOpenModalGenXml,
      onClickConfirmModalGenXml: this.onClickConfirmModalGenXml,

      onClickOpenModalEdit: this.onClickOpenModalEdit,
      onChangeModalEdit: this.onChangeModalEdit,
      onSubmitModalEdit: this.onSubmitModalEdit,

      onClickOpenModalManual: this.onClickOpenModalManual,
      onChangeModalManual: this.onChangeModalManual,
      onSubmitModalManual: this.onSubmitModalManual,

      onClickOpenModalFlag: this.onClickOpenModalFlag,
      onClickOpenModalUnFlag: this.onClickOpenModalUnFlag,
      onClickSubmitModalFlag: this.onClickSubmitModalFlag,
    };

    return (
      <Securities
        {...props}
        {...this.state}
        modalPostRef={(e) => (this.modalPostRef = e)}
        modalReverseRef={(e) => (this.modalReverseRef = e)}
        modalGenXmlRef={(e) => (this.modalGenXmlRef = e)}
        modalEditRef={(e) => (this.modalEditRef = e)}
        modalManualRef={(e) => (this.modalManualRef = e)}
        modalFlagRef={(e) => (this.modalFlagRef = e)}
        modalUnFlagRef={(e) => (this.modalUnFlagRef = e)}
      />
    );
  }
}

export default connect(
  (state) => ({
    ...state.securities,
  }),
  {
    searchSecurities,
    updateSearchResult,
    updateSecurities,
    addAlert,
    changePostingDate,
    fiPosting,
    genXml,
    saveManual,
    saveAccountingFlag,
    getMasterUserDetail,
  }
)(SecuritiesContainer);
