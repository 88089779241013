import React from 'react';

import Card from '../../../common/Card';
import Col2 from '../../../common/Col2';
import Modal from '../../../common/Modal';
import Button from '../../../common/Button';
import Table from '../../../common/DataTable';
import FormGroup2 from '../../../common/FormGroup2';
import FormGroup3 from '../../../common/FormGroup3';
import { DATATABLE_ID } from './OtherReportContainer';
import { getPermission } from '../../../common/helpper';
import ModalFunction from '../../../common/ModalFunction';
import MasterDropdown from '../../../common/MasterDropdown';
import { MASTER_FINANCE } from '../../../reducers/master/action';

const permission = getPermission('Report', 'Other');
const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-5', 'col-md-6'];

const opt = {
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 2,
  },
  columns: [
    {
      data: 'isActive',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        if (d)
          return '<button class="btn btn-icon btn-warning inactive"><span class="icon icon-trash sq-24"></span></button>';
        return '<button class="btn btn-icon btn-info active"><span class="icon icon-refresh sq-24"></span></button>';
      },
    },
    {
      data: 'isEdit',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) => {
        if (!permission) return '';
        return '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>';
      },
    },
    { data: 'financeGroupName' },
    { data: 'name' },
    {
      data: 'link',
      render: (d) => `<a target="_blank" onclick="window.open('${d}')">${d}</a>`,
    },
    // { data: 'isActive', className: 'text-center', render: d => d ? toStatus('Active') : toStatus('Inactive') },
    { data: 'createdBy' },
    { data: 'createdDateStr', className: 'text-center', type: 'date-black' },
    { data: 'updatedBy' },
    { data: 'updatedDateStr', className: 'text-center', type: 'date-black' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalRef,
  modalData,
  onClickOpenModalCreate,
  onChangeModal,
  onClickSaveModal,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  const checkSave = !!((modalData.Mode === 'edit') & !modalData.IsActive);
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Report <span className="icon icon-angle-double-right" /> Other
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Finance Group" required={false}>
                <MasterDropdown
                  masterType={MASTER_FINANCE}
                  onChange={onChangeSelect2}
                  value={criteria.FinanceGroups}
                  name="FinanceGroups"
                />
              </FormGroup2>

              <FormGroup2 text="Name">
                <input
                  className="form-control"
                  type="text"
                  onChange={onChangeSearch}
                  value={criteria.Name}
                  name="Name"
                />
              </FormGroup2>
            </Col2>

            {/* <Col2 col={colX[1]}>
                            <FormGroup2 text="Status">
                                <select className="form-control"
                                    value={criteria.IsActive}
                                    onChange={onChangeSearch}
                                    name="IsActive" >
                                    <option value={null}>---All---</option>
                                    <option value={true}>Active</option>
                                    <option value={false}>Inactive</option>
                                </select>
                            </FormGroup2>
                        </Col2> */}
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              &nbsp;
              {permission ? (
                <>
                  <Button
                    txt="CREATE OTHER"
                    icon="plus-circle"
                    className="btn-warning"
                    onClick={onClickOpenModalCreate}
                  />
                  &nbsp;
                </>
              ) : null}
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Edit</th>
              <th>Finance Group</th>
              <th>Name</th>
              <th>Link</th>
              {/* <th>Status</th> */}
              <th>Created By</th>
              <th>Created Date</th>
              <th>Updated By</th>
              <th>Updated Date</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Other" bgHeader="bg-primary" size="modal-md" ref={modalRef}>
        <form onSubmit={onClickSaveModal} className="form-horizontal">
          <FormGroup3 text="Finance Group" col={colX1} required>
            <MasterDropdown
              masterType={MASTER_FINANCE}
              value={modalData.FinanceGroupId}
              isChoose
              notMultipleSelect2
              required
              onChange={(e) =>
                onChangeModal({
                  target: e,
                })
              }
              name="FinanceGroupId"
            />
          </FormGroup3>

          <FormGroup3 text="Name" col={colX1} required>
            <input
              className="form-control"
              type="text"
              onChange={onChangeModal}
              value={modalData.Name}
              name="Name"
              required
            />
          </FormGroup3>

          <FormGroup3 text="Link" col={colX1} required>
            <input
              className="form-control"
              type="text"
              onChange={onChangeModal}
              value={modalData.Link}
              name="Link"
              required
            />
          </FormGroup3>

          <br />
          <div className="row">
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={checkSave}
              />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="Delete"
        textContent="delete"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />
    </div>
  );
};
