import React from 'react';

import Card from '../../common/Card';
import Col2 from '../../common/Col2';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import Table from '../../common/DataTable';
import FormGroup2 from '../../common/FormGroup2';
import FormGroup3 from '../../common/FormGroup3';
import DatePicker from '../../common/DatePicker';
import ModalFunction from '../../common/ModalFunction';
import MasterDropdown from '../../common/MasterDropdown';
import MasterDropdownUI from '../../common/MasterDropdownUI';
import { DATATABLE_ID } from './FlowAccountingDetailContainer';
import { MASTER_GL_ACCOUNT } from '../../reducers/master/action';

const colX = ['col-md-6', 'col-sm-6', 'col-xs-12'];
const colX1 = ['col-md-4', 'col-md-5'];
const colX2 = ['col-md-4', 'col-md-7'];

const opt = {
  deferRender: false,
  scroller: true,
  paging: true,
  order: [[1, 'asc']],
  fixedColumns: {
    leftColumns: 4,
  },
  columns: [
    // {
    //     data: 'isActive', orderable: false, className: 'text-center',
    //     render: (d, t, r) => {
    //         if (d) return '<button class="btn btn-icon btn-warning inactive" disabled="true"><span class="icon icon-close sq-24"></span></button>'
    //         return '<button class="btn btn-icon btn-info active" disabled="true"><span class="icon icon-refresh sq-24"></span></button>'
    //     }
    // },
    {
      data: 'isAction',
      orderable: false,
      className: 'text-center',
      render: (d, t, r) =>
        '<button class="btn btn-icon btn-info edit"><span class="icon icon-edit sq-24"></span></button>',
    },
    { data: 'ledger' },
    { data: 'coa' },
    { data: 'flowNo' },
    { data: 'flowName' },
    { data: 'productType' },
    { data: 'purpose' },
    { data: 'partnerType' },
    { data: 'partnerCountry' },
    { data: 'documentType' },
    { data: 'lineItem' },
    { data: 'validFromStr', className: 'text-center', type: 'date-black' },
    { data: 'hasWithholdingtax' },
    { data: 'direction' },
    { data: 'directionType' },
    { data: 'postingKey' },
    { data: 'glAccount' },
    { data: 'glAccountDescription' },
    { data: 'accountType' },
    { data: 'specialGlIndicator' },
    { data: 'reverseGlAccount' },
    { data: 'reverseGlAccountDescription' },
    { data: 'reverseAccountType' },
    { data: 'reverseSpecialGlIndicator' },
    { data: 'postingAt' },
    { data: 'amountLogicTypeNo' },
    { data: 'amountLogic' },
    { data: 'documentAmount' },
    { data: 'documentCurrency' },
    { data: 'localAmount' },
    { data: 'localCurrency' },
    { data: 'assignment' },
    { data: 'referenceKey1' },
    { data: 'referenceKey2' },
    { data: 'referenceKey3' },
    { data: 'text1' },
    { data: 'remark' },
  ],
};

export default ({
  criteria,
  onChangeSearch,
  onChangeSelect2,
  onClickSearch,
  onClickClearSearch,

  dataTableRef,
  results,

  modalData,
  modalDataRef,
  onClickOpenModalData,
  onChangeModalData,
  onClickSaveModalData,

  modalActiveRef,
  modalInactiveRef,
  onSubmitModalChangeStatus,
}) => {
  // let noEdit = !modalData.IsActive & modalData.Mode === 'edit' ? true : false
  const noEdit = false;
  return (
    <div>
      <div className="title-bar">
        <p className="title-bar-description">
          <small>
            Master Accounting <span className="icon icon-angle-double-right" /> Flow Accounting
            Detail
          </small>
        </p>
      </div>

      <Card textHeader="Criteria" number="1" bgHeader="bg-primary" cardActions={['toggler']}>
        <form onSubmit={onClickSearch}>
          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Chart of Account" required={false}>
                <MasterDropdownUI
                  value={criteria.Coa}
                  onChange={onChangeSelect2}
                  options={['SCG 4.7', 'SCG ECC6.0', 'SCG ECC6.0 VAS']}
                  name="Coa"
                />
              </FormGroup2>

              <FormGroup2 text="Product Type" required={false}>
                <MasterDropdownUI
                  value={criteria.ProductType}
                  onChange={onChangeSelect2}
                  options={[
                    'PN',
                    'Deposit',
                    'Contract Loan',
                    'Contract Loan on Call',
                    'Bond',
                    'CCS',
                    'POS',
                    'IRS',
                    'Main',
                    'Main|Special',
                  ]}
                  name="ProductType"
                />
              </FormGroup2>
            </Col2>

            <Col2 col={colX[1]}>
              {/* <FormGroup2 text="Purpose" required={false}>
                                <MasterDropdownUI
                                    value={criteria.Purpose}
                                    onChange={onChangeSelect2}
                                    options={["Main", "Additional"]}
                                    name="Purpose" />
                            </FormGroup2> */}

              <FormGroup2 text="Partner Type" required={false}>
                <MasterDropdownUI
                  value={criteria.PartnerType}
                  onChange={onChangeSelect2}
                  options={['External', 'Intercompany']}
                  name="PartnerType"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[0]}>
            <Col2 col={colX[1]}>
              <FormGroup2 text="Flow No." required={false}>
                <input
                  className="form-control"
                  value={criteria.FlowNo}
                  onChange={onChangeSearch}
                  name="FlowNo"
                />
              </FormGroup2>

              <FormGroup2 text="Flow Name" required={false}>
                <input
                  className="form-control"
                  value={criteria.FlowName}
                  onChange={onChangeSearch}
                  name="FlowName"
                />
              </FormGroup2>
            </Col2>
          </Col2>

          <Col2 col={colX[2]}>
            <div>
              <Button txt="SEARCH" icon="search" className="btn-info" type="submit" />
              {/* &nbsp;
                            <Button txt="Create Currency" icon="plus-circle" className="btn-warning" onClick={onClickOpenModalData} disabled={true}></Button> */}
              &nbsp;
              <Button
                txt="Clear Search"
                icon="eraser"
                className="btn-clear"
                onClick={onClickClearSearch}
              />
            </div>
          </Col2>
        </form>
      </Card>

      <Card textHeader="Result" number="2" bgHeader="bg-primary" cardActions={['toggler']}>
        <Table
          ref={dataTableRef}
          className="table table-bordered table-nowrap dataTable"
          id={DATATABLE_ID}
          option={opt}
          value={results}
        >
          <thead>
            <tr>
              <th>Action</th>
              <th>Ledger</th>
              <th>Coa</th>
              <th>Flow No</th>
              <th>Flow Name</th>
              <th>Product Type</th>
              <th>Purpose</th>
              <th>Partner Type</th>
              <th>Partner Country</th>
              <th>Document Type</th>
              <th>Line Item</th>
              <th>Valid From</th>
              <th>Has Withholdingtax</th>
              <th>Direction</th>
              <th>Direction Type</th>
              <th>Posting Key</th>
              <th>GL Account</th>
              <th>GL Account Description</th>
              <th>Account Type</th>
              <th>Special GL Indicator</th>
              <th>Reverse GL Account</th>
              <th>Reverse GL Account Description</th>
              <th>Reverse Account Type</th>
              <th>Reverse Special GL Indicator</th>
              <th>Posting At</th>
              <th>Amount Logic Type No</th>
              <th>Amount Logic</th>
              <th>Document Amount</th>
              <th>Document Currency</th>
              <th>Local Amount</th>
              <th>Local Currency</th>
              <th>Assignment</th>
              <th>Reference Key 1</th>
              <th>Reference Key 2</th>
              <th>Reference Ke y3</th>
              <th>Text 1</th>
              <th>Remark</th>
            </tr>
          </thead>
        </Table>
      </Card>

      <Modal textHeader="Master Currency" bgHeader="bg-primary" size="modal-md" ref={modalDataRef}>
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <br />
          <div>
            <center>
              <Button txt="SAVE" icon="save" className="btn-success" type="submit" />
            </center>
          </div>
        </form>
      </Modal>

      <ModalFunction
        textHeader="Active"
        textContent="active"
        modalRef={modalActiveRef}
        type="info"
        icon="icon-refresh"
        textBtn="Active"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Active')}
      />

      <ModalFunction
        textHeader="InActive"
        textContent="inactive"
        modalRef={modalInactiveRef}
        type="danger"
        icon="icon-close"
        textBtn="Yes"
        textBtnBack="No"
        noSelect
        onClick={(e) => onSubmitModalChangeStatus(e, 'Inactive')}
      />

      <Modal
        textHeader="Master Flow Accounting Detail"
        bgHeader="bg-primary"
        size="modal-md"
        ref={modalDataRef}
      >
        <form onSubmit={onClickSaveModalData} className="form-horizontal">
          <FormGroup3 text="Valid From" col={colX1} required>
            <DatePicker
              onChange={onChangeModalData}
              name="NewValidFromStr"
              value={modalData.NewValidFromStr}
              option={{
                // startDate: "d",
                // daysOfWeekDisabled: "0,6",
                todayHighlight: true,
              }}
              required
            />
          </FormGroup3>

          <FormGroup3 text="GL Account" col={colX2} required>
            <MasterDropdown
              masterType={MASTER_GL_ACCOUNT}
              noValidateOption
              saveLocalState
              onChange={(e) =>
                onChangeModalData({
                  target: e,
                })
              }
              isChoose
              notMultipleSelect2
              required
              isActive
              coa={modalData.Coa}
              value={modalData.GlAccount}
              name="GlAccount"
            />
          </FormGroup3>
          <br />
          <div>
            <center>
              <Button
                txt="SAVE"
                icon="save"
                className="btn-success"
                type="submit"
                disabled={noEdit}
              />
            </center>
          </div>
        </form>
      </Modal>
    </div>
  );
};
